import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import { useStyles } from './style';

export const Loader: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress size={60} thickness={2} value={100} />
    </div>
  );
};
