import React from 'react';

import { ThemedButton } from '../themed-button';
import classes from './style.module.scss';

export const ErrorFallback: React.FC = () => {
  return (
    <div className={classes.box}>
      <div>
        Hmmmm it looks like something went wrong. <br />
        Please refresh your browser or try again later [500] <br />
      </div>
      <ThemedButton
        onClick={() => {
          window.location.reload();
          window.location.replace('/');
        }}
        title='Refresh'
        width={190}
      />
    </div>
  );
};
