import { Roles } from '../../api/user/types';
import { currencies } from '../../shared/constants/currencies';
import { faqsListRestaurant } from '../../shared/constants/faqsRestaurant';
import { faqsListSupplier } from '../../shared/constants/faqsSupplier';
import { helpCenterGeneral } from '../../shared/constants/helpCenterGeneral';
import { helpCenterRestaurant } from '../../shared/constants/helpCenterRestaurant';
import { helpCenterSupplier } from '../../shared/constants/helpCenterSupplier';
import { MCCOST, RPSCOST } from '../../shared/constants/planCost';
import { replaceTags } from '../../shared/helpers/replaceTags';
import { roundNumbers } from '../../shared/helpers/roundNumbers';
import { RootState } from '../index';

export const getIsRestaurant = (state: RootState) => state.user.role === Roles.CUSTOMER;

export const getMyId = (state: RootState) => state.user.userId;

export const getIsAdmin = (state: RootState) => state.user.isAdmin;

export const getAdminCountryCode = (state: RootState) => state.user.adminCountryCode;

export const getMySuccessMsg = (state: RootState) => state.user.successMsg;

export const getIsUseDesktopForToolsShown = (state: RootState) => state.user.useDesktopForToolsOpened;

export const getIsLearningMode = (state: RootState) => state.user.learningMode;

export const getIsMainMenuOpened = (state: RootState) => state.user.isMainMenuOpened;

export const getIsAccountMenuOpened = (state: RootState) => state.user.isAccountMenuOpened;

export const getMySubscriptionType = (state: RootState) => state.user.subscriptionType;

export const getAppError = (state: RootState) => state.user.errorToastText;

export const getAppSuccess = (state: RootState) => state.user.successToastText;

export const getAppToastTitle = (state: RootState) => state.user.toastTitle;

export const getCartPendingRequests = (state: RootState) => state.user.cartPendingRequests;

export const getIsImpersonated = (state: RootState) => state.user.impersonated;

export const getCartDeliveryLoading = (state: RootState) => state.user.cartDeliveryLoading;

export const getSameProductPopup = (state: RootState) => state.user.sameProductPopup;

export const getHelpKeywords = (state: RootState) => ({
  faq: state.user.faqSearch,
  general: state.user.helpSearch,
  helpCenter: state.user.helpCenterSearch,
});

export const getHelpInfo = (pick: 'faq' | 'help' | 'all') => (state: RootState) => {
  const keyword = pick === 'faq' ? state.user.faqSearch : pick === 'help' ? state.user.helpCenterSearch : state.user.helpSearch;
  if (!keyword.trim() || keyword.trim().length < 2) {
    return [];
  }
  const isRestaurant = state.user.role === Roles.CUSTOMER;
  const data = isRestaurant
    ? pick === 'faq'
      ? faqsListRestaurant
      : pick === 'help'
      ? [...helpCenterRestaurant, ...helpCenterGeneral]
      : [...faqsListRestaurant, ...helpCenterRestaurant, ...helpCenterGeneral]
    : pick === 'faq'
    ? faqsListSupplier
    : pick === 'help'
    ? [...helpCenterSupplier, ...helpCenterGeneral]
    : [...faqsListSupplier, ...helpCenterSupplier, ...helpCenterGeneral];
  return data.reduce<{ id: number; title: string; content: string; path: string }[]>((acc, el) => {
    if (el.content.toLowerCase().includes(keyword.toLowerCase())) {
      const matchAll = replaceTags(el.content).match(new RegExp(`[^.]*${keyword}[^.]*.`, 'gi'));
      acc.push({
        ...el,
        content: matchAll?.join(' ') || '',
      });
      return acc;
    }
    if (el.title.toLowerCase().includes(keyword.toLowerCase())) {
      acc.push({
        ...el,
        content: replaceTags(el.content).substr(0, 300) + '...',
      });
    }
    return acc;
  }, []);
};

export const getImpersonatedCustomer = (state: RootState) => state.user.impersonatedCustomer;

export const getCostPlansInfo = (state: RootState) => {
  const plan1 = state.user.plans.find((p: any) => p.name === 'Menu Costing')?.plan_items.find((el: any) => el.interval === 'year');
  const yearlyCost1 = (plan1?.price_cents || MCCOST) / 100;
  const currency1 = currencies.find((c) => c.currencyCode === plan1?.currency);

  const plan2 = state.user.plans?.find((p: any) => p.name === 'Restaurant Pro Suite')?.plan_items.find((el: any) => el.interval === 'year');
  const yearlyCost2 = (plan2?.price_cents || RPSCOST) / 100;
  const currency2 = currencies.find((c) => c.currencyCode === plan2?.currency);

  return [
    {
      cost: yearlyCost1,
      monthlyCost: roundNumbers(yearlyCost1 / 12),
      lifeTimeCost: roundNumbers(yearlyCost1 * 5),
      currencySymbol: currency1?.currencySymbol || '$',
      currencyCode: currency1?.currencyCode || 'AUD',
    },
    {
      cost: yearlyCost2,
      monthlyCost: roundNumbers(yearlyCost2 / 12),
      lifeTimeCost: roundNumbers(yearlyCost2 * 5),
      currencySymbol: currency2?.currencySymbol || '$',
      currencyCode: currency2?.currencyCode || 'AUD',
    },
  ];
};
