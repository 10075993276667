import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../../styles/colorVariables';
import { Sizes } from '../../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    modalRoot1: {
      width: 780,
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: 500,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 350,
        paddingLeft: 5,
        paddingRight: 5,
      },
    },
    modalRoot2: {
      width: 1065,
      paddingLeft: 0,
      paddingRight: 0,
      minHeight: '50vh',
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: '90vw',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 350,
        paddingLeft: 5,
        paddingRight: 5,
      },
    },
    box: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    title: {
      fontSize: 20,
      lineHeight: '28px',
      fontWeight: 700,
      textAlign: 'center',
      marginBottom: 20,
      [theme.breakpoints.down(Sizes.mobile)]: {
        fontSize: 18,
        lineHeight: '22px',
        marginTop: 10,
      },
    },
    subTitle: {
      textAlign: 'center',
      marginBottom: 30,
      color: colorVariables.grey80,
    },
    uploadContainerWrapM: {
      [theme.breakpoints.down(Sizes.mobile)]: {
        backgroundColor: '#F5F6F8',
        padding: '50px 10px 25px 10px',
        borderRadius: 8,
        border: '1px solid rgba(152, 165, 183, 0.1)',
        margin: 2,
        width: 280,
      },
    },
    uploadContainer: {
      border: '1px dashed #152144',
      borderRadius: 12,
      width: 600,
      height: 170,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 30,
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 255,
        height: 153,
        margin: 0,
        backgroundColor: colorVariables.white,
        border: `2px dashed ${colorVariables.grey60}`,
      },
    },
    uploadIcon: {
      width: 60,
      height: 60,
    },
    uploadDescription: {
      fontSize: 14,
      color: colorVariables.navy,
      marginTop: 10,
      fontWeight: 700,
      textAlign: 'center',
    },
    focused: {
      border: `2px dashed ${colorVariables.green}`,
    },
    link: {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    downTemplateBtn: {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      fontSize: 14,
      lineHeight: '25px',
      height: 25,
      marginBottom: 30,
      marginTop: 20,
      [theme.breakpoints.down(Sizes.mobile)]: {
        color: colorVariables.blue,
        marginBottom: 20,
        marginTop: 15,
      },
    },
    formats: {
      display: 'flex',
      marginBottom: 30,
      '& div': {
        width: 75,
        height: 30,
        borderRadius: 15,
        backgroundColor: colorVariables.steelGrey15,
        fontSize: 12,
        fontWeight: 700,
        lineHeight: '30px',
        textAlign: 'center',
        '&:first-child': {
          marginRight: 15,
        },
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginBottom: 20,
        marginTop: -15,
      },
    },
    uploadProgressContainer: {
      width: 885,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      marginBottom: 10,
      '&>div': {
        flexGrow: 1,
        marginLeft: 25,
      },
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: 710,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        width: 520,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        '&>div': {
          flexGrow: 1,
          marginLeft: 0,
        },
      },
    },
    fileIcon: {
      width: 32,
      height: 44,
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    doneIcon: {
      fontSize: 18,
      color: colorVariables.blue,
      marginBottom: -2,
    },

    error: {
      color: colorVariables.red,
    },
    errorsBlock: {
      maxWidth: 850,
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 15,
      fontStyle: 'italic',
      fontSize: 12,
      fontWeight: 600,
      color: '#EA6363',
      flexGrow: 0,
      [theme.breakpoints.down(Sizes.desktop)]: {
        maxWidth: 650,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        maxWidth: 545,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 'calc(100% - 45px)',
        flexShrink: 0,
      },
    },
    errorsBlockWrap: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    topBox: {
      display: 'flex',
      width: '100%',
      marginBottom: 5,
      justifyContent: 'space-between',
    },
    boldText: {
      fontWeight: 700,
    },
    btnsBox: {
      marginTop: 20,
      display: 'flex',
      justifyContent: 'center',
    },
    previewWrap: {
      width: '100%',
      backgroundColor: '#F2F4F8',
      padding: '20px 30px',
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: 15,
        borderRadius: 15,
      },
    },
  }),
);
