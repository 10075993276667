import { createStyles, makeStyles } from '@material-ui/core';

import AuthDots from '../../../assets/images/bg/Auth_bg_dots.svg';
import AuthLines from '../../../assets/images/bg/Auth_bg_lines.svg';

import { Sizes } from '../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      flex: 1,
      minHeight: '100vh',
      height: '100%',
      padding: '50px 80px 20px 80px',
      position: 'relative',
      backgroundColor: 'rgba(207, 215, 230, 0.3)',
      background: 'linear-gradient rgba(255, 255, 255, 1) 100%, rgba(255, 255, 255, 0) 0%)',
      backgroundImage: `url(${AuthLines}), url(${AuthDots})`,
      backgroundRepeat: 'no-repeat, no-repeat',
      backgroundPosition: 'top 126px left 0px, bottom 250px right 0px',
      [theme.breakpoints.down(Sizes.desktop)]: {
        overflowY: 'scroll',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        backgroundPosition: 'top 60px left 0px, bottom 60px right 0px',
        padding: 20,
      },
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: 30,
      [theme.breakpoints.down(Sizes.desktop)]: {
        paddingTop: 10,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        paddingTop: 40,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        paddingTop: 60,
      },
    },
    logoBox: {
      position: 'absolute',
      top: 33,
      left: '50%',
      transform: 'translateX(-50%)',
      [theme.breakpoints.down(Sizes.desktop)]: {
        top: 15,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        top: 20,
      },
    },
    logo: {
      width: 178,
      height: 34,
    },
    progressWrap: {
      width: 260,
      marginBottom: 40,
      [theme.breakpoints.down(Sizes.desktop)]: {
        marginBottom: 20,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        marginBottom: 20,
      },
    },
    backToAdmin: {
      position: 'fixed',
      top: 15,
      left: 20,
    },
  }),
);
