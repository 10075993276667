import { HTTP, rootApi, SuccessResponse } from '../index';
import {
  AddSubMeasureRes,
  ExportStockTakeRes,
  SetupCloseBalanceRes,
  StartStockTakeRes,
  StockTakeHistoryParams,
  StockTakeHistoryRes,
  StockTakesListRes,
} from './types';
import { inventoryApi } from '../inventory';
import store from '../../store';
import { setAppSuccessToast } from '../../store/user';
import { generateStockTakeSheet } from '../../shared/helpers/generateStockTakeSheet';
import { StocktakeMode } from '../inventory/types';

export const stockTakeApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getStockTakes: builder.query<StockTakesListRes, void>({
      query: () => {
        return {
          url: `/stock_takes`,
          method: HTTP.GET,
        };
      },
      providesTags: ['StockTake'],
    }),

    getStockTakeHistory: builder.query<StockTakeHistoryRes, StockTakeHistoryParams>({
      query: ({ id, ...params }) => {
        return {
          url: `/stock_takes/${id}`,
          method: HTTP.GET,
          params,
        };
      },
      providesTags: ['StockTake'],
    }),

    startStockTake: builder.mutation<StartStockTakeRes, void>({
      query: () => {
        return {
          url: `/stock_takes`,
          method: HTTP.POST,
          body: {
            mode: store.getState().stocktakeBarcode.mode as string || StocktakeMode.regularStocktake
          }
        };
      },
      invalidatesTags: ['StockTake'],
    }),

    stopStockTake: builder.mutation<SuccessResponse, number>({
      query: (id) => {
        return {
          url: `/stock_takes/${id}/stop_stocktake`,
          method: HTTP.POST,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const res = await queryFulfilled;
          if (res.data?.success) {
            dispatch(stockTakeApi.endpoints.exportStockTake.initiate(args));
          }
        } catch (e) {
          console.log(e);
        }
      },
    }),

    setCloseBalance: builder.mutation<
      SetupCloseBalanceRes,
      { id: number; close_balance: number; stocktake_id: number; invalidate?: boolean; add_more?: boolean }
    >({
      query: ({ id, ...body }) => {
        return {
          url: `/inventories/${id}/setup_close_balance`,
          method: HTTP.PATCH,
          body,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const name = store.getState().inventory.selectedStorage;
        const recently_purchased = store.getState().inventory.filterBy?.recently;

        let patchResult;
        let patchResult1;
        try {
          const res = await queryFulfilled;
          if (res?.data?.success) {
            dispatch(setAppSuccessToast({
              title: 'Stock Added',
              message: 'Products successfully added',
            }));
          }
          patchResult = dispatch(
            inventoryApi.util.updateQueryData(
              'getInventoryStorageProducts',
              {
                name: name || undefined,
                recently_purchased,
              },
              (draft) => {
                const idx = draft.storages[0]?.inventory_products?.findIndex((el) => el.id === args.id);
                if (idx !== -1 && draft.storages[0]?.inventory_products[idx] && res?.data?.inventory_product) {
                  draft.storages[0].inventory_products[idx] = res?.data?.inventory_product;
                }
              },
            ),
          );
          patchResult1 = dispatch(
            inventoryApi.util.updateQueryData('getInventoryStorages', undefined, (draft) => {
              const idx = draft.storages[0]?.inventory_products?.findIndex((el) => el.id === args.id);
              if (idx !== -1 && draft.storages[0]?.inventory_products[idx] && res?.data?.inventory_product) {
                draft.storages[0].inventory_products[idx] = res?.data?.inventory_product;
              }
            }),
          );
        } catch {
          patchResult?.undo();
          patchResult1?.undo();
        }
      },
    }),

    addSubMeasure: builder.mutation<AddSubMeasureRes, { id: number; qty: number; measure: string; barcode: string }>({
      query: ({ id, qty, measure, barcode }) => {
        return {
          url: `/inventories/${id}/add_sub_measurement`,
          method: HTTP.PATCH,
          body: {
            inventory_product: {
              sub_measurement: measure,
              sub_measurement_value: qty,
              barcode: barcode,
            },
          },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const name = store.getState().inventory.selectedStorage;
        const recently_purchased = store.getState().inventory.filterBy?.recently;

        let patchResult;
        let patchResult1;
        try {
          const res = await queryFulfilled;
          patchResult = dispatch(
            inventoryApi.util.updateQueryData(
              'getInventoryStorageProducts',
              {
                name: name || undefined,
                recently_purchased,
              },
              (draft) => {
                const idx = draft.storages[0]?.inventory_products?.findIndex((el) => el.id === args.id);
                if (idx !== -1 && draft.storages[0]?.inventory_products[idx] && res?.data?.inventory_product) {
                  draft.storages[0].inventory_products[idx] = res?.data?.inventory_product;
                }
              },
            ),
          );
          patchResult1 = dispatch(
            inventoryApi.util.updateQueryData('getInventoryStorages', undefined, (draft) => {
              const idx = draft.storages[0]?.inventory_products?.findIndex((el) => el.id === args.id);
              if (idx !== -1 && draft.storages[0]?.inventory_products[idx] && res?.data?.inventory_product) {
                draft.storages[0].inventory_products[idx] = res?.data?.inventory_product;
              }
            }),
          );
        } catch {
          patchResult?.undo();
          patchResult1?.undo();
        }
      },
    }),

    deleteSubMeasure: builder.mutation<AddSubMeasureRes, number>({
      query: (id) => {
        return {
          url: `/inventories/${id}/delete_sub_measurement`,
          method: HTTP.PATCH,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const name = store.getState().inventory.selectedStorage;
        const recently_purchased = store.getState().inventory.filterBy?.recently;

        let patchResult;
        let patchResult1;
        try {
          const res = await queryFulfilled;
          patchResult = dispatch(
            inventoryApi.util.updateQueryData(
              'getInventoryStorageProducts',
              {
                name: name || undefined,
                recently_purchased,
              },
              (draft) => {
                const idx = draft.storages[0]?.inventory_products?.findIndex((el) => el.id === args);
                if (idx !== -1 && draft.storages[0]?.inventory_products[idx] && res?.data?.inventory_product) {
                  draft.storages[0].inventory_products[idx] = res?.data?.inventory_product;
                }
              },
            ),
          );
          patchResult1 = dispatch(
            inventoryApi.util.updateQueryData('getInventoryStorages', undefined, (draft) => {
              const idx = draft.storages[0]?.inventory_products?.findIndex((el) => el.id === args);
              if (idx !== -1 && draft.storages[0]?.inventory_products[idx] && res?.data?.inventory_product) {
                draft.storages[0].inventory_products[idx] = res?.data?.inventory_product;
              }
            }),
          );
        } catch {
          patchResult?.undo();
          patchResult1?.undo();
        }
      },
    }),

    exportStockTake: builder.mutation<ExportStockTakeRes, number>({
      query: (id) => {
        return {
          url: `/stock_takes/${id}/export_stocktake`,
          method: HTTP.GET,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const res = await queryFulfilled;
          if (res?.data?.success) {
            await generateStockTakeSheet(res.data);
            dispatch(setAppSuccessToast('Stock report has been exported'));
          }
        } catch (e) {
          console.log(e);
        }
      },
      invalidatesTags: ['StockTake'],
    }),
  }),

  overrideExisting: true,
});

export const {
  usePrefetch,
  useStartStockTakeMutation,
  useGetStockTakesQuery,
  useStopStockTakeMutation,
  useSetCloseBalanceMutation,
  useAddSubMeasureMutation,
  useDeleteSubMeasureMutation,
  useGetStockTakeHistoryQuery,
  useExportStockTakeMutation,
} = stockTakeApi;
