import * as React from 'react';

function SvgUploadIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 33 31' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M17.883.627C17.506.258 16.94 0 16.412 0a1.99 1.99 0 00-1.472.627L7.395 8.12a2.015 2.015 0 00-.604 1.44c0 .553.226 1.033.641 1.439.83.775 2.15.775 2.943-.037l3.962-3.95V19.93c0 1.107.943 2.03 2.075 2.03 1.132 0 2.075-.923 2.075-2.03V7.012l3.961 3.949c.793.812 2.113.812 2.943.037.83-.775.83-2.067.038-2.879L17.883.627z'
        fill='#51D9B0'
      />
      <path
        d='M30.748 17.64c-1.131 0-2.075.923-2.075 2.03v7.27H4.15v-7.27c0-1.107-.943-2.03-2.075-2.03C.943 17.64 0 18.563 0 19.67v9.3C0 30.077.943 31 2.075 31h28.673c1.132 0 2.076-.923 2.076-2.03v-9.3c0-1.144-.944-2.03-2.075-2.03z'
        fill='#51D9B0'
      />
    </svg>
  );
}

export default SvgUploadIcon;
