import React, { useState } from 'react';

import { KeyboardArrowDown } from '@material-ui/icons';
import Add from '@material-ui/icons/Add';
import clsx from 'clsx';

import { useGeIsTeammateAdmin, useGetTeammatesList } from '../../../api/teammates/hooks';
import { TeammatesSortBy } from '../../../api/teammates/types';
import { ThemedButton } from '../../../shared/components/themed-button';
import { DropdownMenu } from '../../../shared/components/dropdown-menu';
import { NavbarTitle } from '../../../shared/components/navbar-title';
import { SearchField } from '../../../shared/components/search-field';
import { useScreenSize } from '../../../shared/hooks/use-screen-size';
import { ToastService } from '../../../shared/services/toastService';
import { useAppDispatch, useAppSelector } from '../../../store';
import { getTeammatesKeyword, setTeammatesKeyword, setTeammatesSort } from '../../../store/teammates';
import { openAccountMenu, openMainMenu } from '../../../store/user';
import { getIsRestaurant } from '../../../store/user';
import { MainNavBar } from '../../AppDrawer/MainNavBar';
import { TopNavBar } from '../../AppDrawer/MainNavBar/top-nav-bar';
import { AccountMenu } from '../account-menu';
import { CustomPermissionModal, CustomPermissionOption } from './custom-permission-modal';
import { InviteTeammateModal } from './invite-teammate-modal';
import { TeammateListItem } from './teammate-list-item';

import { useStyles } from './style';

export interface CustomPermissionValues {
  pantry_lists?: CustomPermissionOption;
  supplier_and_categories?: CustomPermissionOption;
  order_history?: CustomPermissionOption;
  dashboard?: CustomPermissionOption;
  teammates?: CustomPermissionOption;
  vanue_info?: CustomPermissionOption;
  billing?: CustomPermissionOption;
  menu_planning?: CustomPermissionOption;
  sales_and_wastages?: CustomPermissionOption;
  automated_ordering?: CustomPermissionOption;
  inventory?: CustomPermissionOption;
  reports?: CustomPermissionOption;
  meat: boolean;
  dry_and_frozen_goods: boolean;
  poultry: boolean;
  fruits_and_vegetables: boolean;
  seafood: boolean;
  bakery: boolean;
  coffee_and_tea: boolean;
  dairy: boolean;
  alchohol_and_beverages: boolean;
  small_goods: boolean;
  hospitality_supplies: boolean;
}

export const Teammates: React.FC = () => {
  const classes = useStyles();
  const [inviteModalOpened, setInviteModalOpened] = useState(false);
  const [customValues, setCustomValues] = useState<CustomPermissionValues | null>(null);

  const { list } = useGetTeammatesList();

  const { isMobile } = useScreenSize();
  const dispatch = useAppDispatch();
  const isRestaurant = useAppSelector(getIsRestaurant);
  const keyword = useAppSelector(getTeammatesKeyword);
  const isViewer = !useGeIsTeammateAdmin();

  const openMenus = () => {
    dispatch(openMainMenu());
    dispatch(openAccountMenu());
  };

  const onSearch = (searchReq: string) => {
    dispatch(setTeammatesKeyword(searchReq));
  };

  const onApplySort = (sort: TeammatesSortBy) => {
    dispatch(setTeammatesSort(sort));
  };

  const showDeniedWarning = () => {
    ToastService.error('You have been restricted from making edits.');
  };

  return (
    <>
      <TopNavBar
        isAccount={true}
        leftComponent={<NavbarTitle title='Teammates' showBackBtn={true} backHandler={openMenus} />}
        bottomComponent={isMobile ? <NavbarTitle title='Teammates' showBackBtn={true} backHandler={openMenus} /> : undefined}
      />
      <MainNavBar isAccount={true} />
      <div className={clsx(classes.accountContainer, isRestaurant && classes.accountContainerCustomer)}>
        <div className={classes.accountHeader}>
          <div className={classes.accountTitle}>My Account</div>
        </div>
        <div className={classes.accountBody}>
          <AccountMenu showBack={!isRestaurant} />
          <div className={classes.accountContent}>
            <div className={classes.titleBox}>
              <div className={classes.title}>Teammates</div>
              <div className={classes.searchWrap}>
                <SearchField placeholder={'Start typing name..'} onChange={onSearch} searchValue={keyword} />
              </div>
              <div className={classes.titleBoxBtns}>
                <ThemedButton
                  onClick={isViewer ? showDeniedWarning : setInviteModalOpened.bind(null, true)}
                  title={isMobile ? 'Add new' : 'Add teammate'}
                  buttonStyle='icon'
                  customClass={classes.importBtn}
                  width={isMobile ? 90 : 140}
                  startIcon={<Add />}
                />
              </div>
            </div>
            <div className={classes.tableHead}>
              <div className={classes.name}>
                <DropdownMenu
                  items={[
                    <div key='asc' onClick={onApplySort.bind(null, ['name', 'asc'])}>
                      Sort A - Z
                    </div>,
                    <div key='desc' onClick={onApplySort.bind(null, ['name', 'desc'])}>
                      Sort Z - A
                    </div>,
                  ]}
                  triggerBtn={
                    <div className={classes.sortTrigger}>
                      <span className={classes.colHeadText}>Name</span>
                      <KeyboardArrowDown />
                    </div>
                  }
                />
              </div>
              <div className={classes.lastActive}>
                <DropdownMenu
                  items={[
                    <div key='asc' onClick={onApplySort.bind(null, ['last_active', 'desc'])}>
                      Most Recent to Old
                    </div>,
                    <div key='desc' onClick={onApplySort.bind(null, ['last_active', 'asc'])}>
                      Old to Most Recent
                    </div>,
                  ]}
                  triggerBtn={
                    <div className={classes.sortTrigger}>
                      <span className={classes.colHeadText}>Last Active</span>
                      <KeyboardArrowDown />
                    </div>
                  }
                />
              </div>
              <div className={classes.permission}>
                <DropdownMenu
                  items={[
                    <div key='asc' onClick={onApplySort.bind(null, ['permissions', 'asc'])}>
                      Sort A - Z
                    </div>,
                    <div key='desc' onClick={onApplySort.bind(null, ['permissions', 'desc'])}>
                      Sort Z - A
                    </div>,
                  ]}
                  triggerBtn={
                    <div className={classes.sortTrigger}>
                      <span className={classes.colHeadText}>Permissions</span>
                      <KeyboardArrowDown />
                    </div>
                  }
                />
              </div>
              <div className={classes.actions} />
            </div>
            <div>
              {list?.map((el) => (
                <TeammateListItem item={el} key={el.id} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <InviteTeammateModal
        isOpen={inviteModalOpened}
        onClose={setInviteModalOpened.bind(null, false)}
        customValues={customValues}
        setCustomValues={(values: CustomPermissionValues | null) => setCustomValues(values)}
      />
      <CustomPermissionModal
        customValues={customValues}
        setCustomValues={(values: CustomPermissionValues | null) => setCustomValues(values)}
      />
    </>
  );
};
