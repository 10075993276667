import * as React from 'react';

function SvgBackArrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M16 6c0-.656-.56-1.188-1.251-1.188H4.179L6.94 2.07c.513-.439.555-1.19.093-1.677a1.299 1.299 0 00-1.894.038L.346 5.181a1.143 1.143 0 000 1.638l4.792 4.75c.44.505 1.228.577 1.761.16a1.147 1.147 0 00.04-1.8L4.18 7.188h10.57c.691 0 1.25-.531 1.25-1.187z'
        fill='#152144'
      />
    </svg>
  );
}

export default SvgBackArrow;
