import { createReducer } from '@reduxjs/toolkit';

import isEqual from 'lodash/isEqual';
import { AdminOverdueSortBy } from '../../../api/admin-overdue-exports/types';
import { logoutThunk } from '../../user';
import { setAdminOverdueKeyword, setAdminOverduePage, setAdminOverdueSort } from './overdue.actions';

interface State {
  sortBy?: AdminOverdueSortBy;
  keyword?: string;
  currentPage: number;
}

const initialState: State = {
  sortBy: undefined,
  keyword: undefined,
  currentPage: 1,
};

export const adminOverdueReducer = createReducer(initialState, (builder) => {
  builder.addCase(logoutThunk.fulfilled, () => {
    return initialState;
  });
  builder.addCase(setAdminOverdueSort, (state, { payload }) => {
    if (isEqual(state.sortBy, payload)) {
      return;
    }
    state.sortBy = payload.sort;
    state.currentPage = 1;
  });

  builder.addCase(setAdminOverdueKeyword, (state, { payload }) => {
    if (state.keyword === payload) {
      return;
    }
    state.keyword = payload;
    state.currentPage = 1;
  });

  builder.addCase(setAdminOverduePage, (state, { payload }) => {
    state.currentPage = payload;
  });
});
