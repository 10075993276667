import { createStyles, makeStyles } from '@material-ui/core';

import { Sizes } from '../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: 500,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      '& > div:last-child': {
        width: 260,
        marginLeft: 10,
      },
      [theme.breakpoints.down(Sizes.desktop)]: {
        position: 'absolute',
        bottom: 10,
        right: 0,
        width: 400,
        '& > div:last-child': {
          width: 220,
        },
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        position: 'static',
        width: '90vw',
        '& > div:last-child': {
          width: '100%',
          marginLeft: 0,
          marginTop: 5,
        },
      },
    },
    boldText: {
      marginRight: 5,
      fontWeight: 700,
    },
  }),
);
