import { suppliersSortParams } from '../../../components/AdminDrawer/Suppliers/SuppliersList/suppliers-filter';
import { RootState } from '../../index';

export const getSuppliersSortFilters = (state: RootState) => ({
  sort: state.suppliers.sortBy,
  keyword: state.suppliers.keyword,
  currentPage: state.suppliers.currentPage,
});

export const getSuppliersChips = (state: RootState) => {
  const sortLabel = suppliersSortParams.find((param) => param.value === state.suppliers.sortBy?.join(' '))?.label || '';

  const sortChip = {
    label: sortLabel,
    key: sortLabel ? 'sort' : '',
  };

  return sortChip.label && sortChip.key ? [sortChip] : [];
};

export const getSelectedSuppliers = (state: RootState) => state.suppliers.selectedUsers;
