import { getWastageQueryParamsHelper, setSuccessToast } from '../helpers';
import { HTTP, rootApi, SuccessResponse } from '../index';
import {
  CreateWastageReq,
  CreateWastageRes,
  ExportWastagesParams,
  ExportWastagesResponse,
  GetWastagesReqParams,
  GetWastagesResponse,
  UpdateWastageReq,
  WastageGraphReqParams,
  WastageReportResponse,
} from './types';

export const wastagesApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getWastages: builder.query<GetWastagesResponse, GetWastagesReqParams>({
      query: (args) => {
        const params = {
          ...getWastageQueryParamsHelper(args),
          page: args.page,
          per_page: 20,
        };
        return {
          url: `/wastages`,
          method: HTTP.GET,
          params,
        };
      },
      keepUnusedDataFor: 0,
      providesTags: ['Wastages'],
    }),

    getAllWastages: builder.query<GetWastagesResponse, void>({
      query: () => {
        const params = {
          page: 1,
          per_page: 1,
        };
        return {
          url: `/wastages`,
          method: HTTP.GET,
          params,
        };
      },
      providesTags: ['Wastages'],
    }),

    createWastage: builder.mutation<CreateWastageRes, CreateWastageReq>({
      query: (body) => {
        return {
          url: `/wastages`,
          method: HTTP.POST,
          body,
        };
      },
      invalidatesTags: ['Wastages', 'Inventories', 'Run_Out_Product'],
    }),

    updateWastage: builder.mutation<CreateWastageRes, UpdateWastageReq>({
      query: ({ id, ...body }) => {
        return {
          url: `/wastages/${id}`,
          method: HTTP.PATCH,
          body,
        };
      },
      invalidatesTags: ['Inventories', 'Run_Out_Product'],
    }),

    deleteWastage: builder.mutation<SuccessResponse, number>({
      query: (id) => {
        return {
          url: `/wastages/${id}`,
          method: HTTP.DELETE,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'Wastage has been deleted');
      },
      invalidatesTags: ['Wastages', 'Inventories', 'Run_Out_Product'],
    }),

    getWastageReport: builder.query<WastageReportResponse, WastageGraphReqParams>({
      query: (params) => {
        return {
          url: `reports/wastage`,
          method: HTTP.GET,
          params,
        };
      },
      providesTags: ['Wastages'],
      keepUnusedDataFor: 0,
    }),

    exportWastages: builder.mutation<ExportWastagesResponse, ExportWastagesParams | void>({
      query: (params) => {
        return {
          url: `/export_wastage`,
          method: HTTP.POST,
          params,
        };
      },
    }),
  }),

  overrideExisting: true,
});

export const {
  useGetWastagesQuery,
  useCreateWastageMutation,
  useUpdateWastageMutation,
  useDeleteWastageMutation,
  useGetWastageReportQuery,
  useExportWastagesMutation,
  useGetAllWastagesQuery,
} = wastagesApi;
