import React, { useCallback, useEffect, useRef, useState } from 'react';

import DoneIcon from '@material-ui/icons/Done';
import clsx from 'clsx';
import ReactDropzone from 'react-dropzone';
import { useDebouncedCallback } from 'use-debounce';

import { useImportSalesMutation } from '../../../../../../../api/sales';
import { useGetImportSalesTemplate } from '../../../../../../../api/sales/hooks';
import SvgFile from '../../../../../../../assets/icons/File';
import SvgUploadIcon from '../../../../../../../assets/icons/UploadIcon';
import SvgPosImport from '../../../../../../../assets/images/svg-components/PosImport';
import { ThemedButton } from '../../../../../../../shared/components/themed-button';
import { OverlayModal } from '../../../../../../../shared/components/overlay-modal';
import { ProgressBar } from '../../../../../../../shared/components/progress-bar';
import { useScreenSize } from '../../../../../../../shared/hooks/use-screen-size';
import { ToastService } from '../../../../../../../shared/services/toastService';

import { useStyles } from './style';

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

enum ImportTab {
  pos,
  xlsx,
}

export const ImportSalesModal: React.FC<Props> = ({ onClose, isOpen }) => {
  const classes = useStyles();
  const { isMobile } = useScreenSize();

  const [activeTab, setActiveTab] = useState<ImportTab>(ImportTab.pos);
  const [fileName, setFileName] = useState('');
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [uploadPercent, setUploadPercent] = useState(0);
  const [uploaded, setUploaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const [importDisabled, setImportDisabled] = useState(false);
  const timerRef = useRef<any>();

  const { onDownload, loading } = useGetImportSalesTemplate();
  const [importSales, { isLoading: importLoading, data, error }] = useImportSalesMutation();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setUploadedFile(acceptedFiles[0]);
    setFileName(acceptedFiles[0].name);
  }, []);

  const closeModalDeb = useDebouncedCallback(() => {
    onClose();
  }, 100);

  const uploadFile = () => {
    if (!uploadedFile) {
      return;
    }
    const formData = new FormData();
    formData.append('file', uploadedFile);
    setImportDisabled(true);
    importSales(formData)
      .then((res) => {
        if ('error' in res) {
          setIsError(true);
          setUploadedFile(null);
          setFileName('');
          setUploadPercent(0);
          setImportDisabled(false);
          clearInterval(timerRef.current);
        }
        if ('data' in res && res.data.success) {
          setIsError(false);
          setUploadPercent(100);
          clearInterval(timerRef.current);
          closeModalDeb();
        }
      })
      .catch((e: any) => {
        ToastService.error('Error in XLSX file');
        setIsError(true);
        console.log(e);
      });
    setUploaded(true);
  };

  const uploadAgain = () => {
    setIsError(false);
    setUploaded(false);
  };

  useEffect(() => {
    setFileName('');
    setUploadedFile(null);
  }, [activeTab]);

  useEffect(() => {
    if (!importLoading && data?.success) {
      setUploadPercent(100);
    }
    if (!importLoading && data && !data.success) {
      setUploadPercent(0);
    }
    if (importLoading) {
      timerRef.current = setInterval(() => {
        setUploadPercent((state) => {
          if (state < 90) {
            return state + 5;
          } else {
            clearInterval(timerRef.current);
            return 100;
          }
        });
      }, 500);
    }
  }, [importLoading]);

  return (
    <OverlayModal onClose={onClose} isOpen={isOpen} contentWrapClassName={classes.root}>
      <div>
        <div className={classes.title}>How would you like to import your sales information?</div>
        <div className={classes.tabsBox}>
          <div
            className={clsx(classes.tabItem, activeTab === ImportTab.pos && classes.tabItemActive)}
            onClick={setActiveTab.bind(null, ImportTab.pos)}
          >
            POS Integration
          </div>
          <div
            className={clsx(classes.tabItem, activeTab === ImportTab.xlsx && classes.tabItemActive)}
            onClick={setActiveTab.bind(null, ImportTab.xlsx)}
          >
            Upload via Spreadsheet
          </div>
        </div>
        {activeTab === ImportTab.pos && (
          <div className={classes.box}>
            <div className={classes.posSubTitle}>Connect your point of sale to our system to manage your back of house with ease.</div>
            <SvgPosImport width={190} height={160} />
            <div className={classes.posBtns}>
              <ThemedButton onClick={setActiveTab.bind(null, ImportTab.xlsx)} title={'Import Sales'} width={isMobile ? 130 : 160} />
              <ThemedButton onClick={onClose} title={'Cancel'} buttonStyle='secondary' width={isMobile ? 130 : 160} />
            </div>
          </div>
        )}
        {activeTab === ImportTab.xlsx && (
          <div className={classes.box}>
            <div className={classes.formatsBlock}>
              <div className={classes.formatLabel}>.CSV</div>
              <div className={classes.formatLabel}>.XLSX</div>
            </div>
            {!uploaded ? (
              <ReactDropzone onDrop={onDrop} accept='.xlsx'>
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <div className={clsx(classes.uploadContainer, isDragActive && classes.focused)} {...getRootProps()}>
                    <SvgUploadIcon className={classes.uploadIcon} />
                    <input {...getInputProps()} />
                    <div className={classes.uploadDescription}>
                      {!fileName ? (
                        isMobile ? (
                          <span>
                            <b className={classes.link}>Choose a file</b>
                          </span>
                        ) : (
                          <span>
                            Drop file here to upload or <b className={classes.link}>choose a file</b>
                          </span>
                        )
                      ) : (
                        <span>{fileName}</span>
                      )}
                    </div>
                  </div>
                )}
              </ReactDropzone>
            ) : (
              <div className={classes.uploadWrap}>
                <div className={classes.uploadProgressContainer}>
                  <SvgFile className={classes.fileIcon} />
                  <div>
                    <div className={classes.topBox}>
                      <div className={classes.boldText}>Preparing your list</div>
                      {uploadPercent < 100 || importLoading ? (
                        <div>{uploadPercent === 100 ? 99 : uploadPercent}%</div>
                      ) : (
                        <div>
                          <span>Sales Loaded Successfully</span>
                          <DoneIcon className={classes.doneIcon} />
                        </div>
                      )}
                    </div>
                    <ProgressBar percent={uploadPercent} />
                  </div>
                </div>
                {error && isError ? (
                  <div className={classes.error}>
                    <span>File error: </span>
                    {'data' in error ? error.data.message : 'Errors in XLSX'}
                  </div>
                ) : null}
              </div>
            )}
            <div className={classes.xlsxBtns}>
              <ThemedButton
                onClick={onDownload}
                title={loading ? 'Loading...' : 'Download template'}
                buttonStyle='blueTransparent'
                width={160}
                className={classes.downloadBtn}
                disabled={loading}
              />
              <div>
                <ThemedButton
                  onClick={isError ? uploadAgain : uploadFile}
                  title={isError ? 'Upload New' : 'Upload'}
                  width={isMobile ? 130 : 160}
                  className={classes.actionBtn1}
                  disabled={isError ? false : !uploadedFile || importLoading || importDisabled}
                />
                <ThemedButton
                  onClick={onClose}
                  title={'Cancel'}
                  buttonStyle='secondary'
                  width={isMobile ? 130 : 160}
                  className={classes.actionBtn2}
                />
              </div>
              <div className={classes.empty} />
            </div>
          </div>
        )}
      </div>
    </OverlayModal>
  );
};
