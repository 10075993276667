import * as React from 'react';

function SvgProfile(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M18.242 2.383H1.758A1.76 1.76 0 000 4.14v11.718c0 .97.789 1.758 1.758 1.758h16.484A1.76 1.76 0 0020 15.86V4.141a1.76 1.76 0 00-1.758-1.758zm.586 13.476a.587.587 0 01-.586.586H1.758a.587.587 0 01-.586-.586V4.141c0-.323.263-.586.586-.586h16.484c.323 0 .586.263.586.586v11.718z'
        fill='currentColor'
      />
      <path
        d='M5.86 9.414a3.52 3.52 0 00-3.516 3.516v.586c0 .969.788 1.757 1.758 1.757h3.515a1.76 1.76 0 001.758-1.757v-.586a3.52 3.52 0 00-3.516-3.516zm2.343 4.102a.587.587 0 01-.586.586H4.102a.587.587 0 01-.586-.586v-.586a2.346 2.346 0 012.343-2.344 2.346 2.346 0 012.344 2.344v.586zM5.86 4.727A2.346 2.346 0 003.515 7.07a2.346 2.346 0 002.343 2.344A2.346 2.346 0 008.203 7.07 2.346 2.346 0 005.86 4.727zm0 3.515A1.173 1.173 0 014.686 7.07c0-.646.526-1.172 1.172-1.172.647 0 1.172.526 1.172 1.172 0 .646-.525 1.172-1.172 1.172zM17.07 12.93h-5.86a.586.586 0 100 1.172h5.86a.586.586 0 000-1.172zM17.07 10.586h-5.86a.586.586 0 000 1.172h5.86a.586.586 0 100-1.172zM17.07 8.242h-5.86a.586.586 0 000 1.172h5.86a.586.586 0 000-1.172zM15.898 5.898h-3.515a.586.586 0 000 1.172h3.515a.586.586 0 100-1.172z'
        fill='currentColor'
      />
    </svg>
  );
}

export default SvgProfile;
