import * as React from 'react';

function SvgCalculateIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g>
        <rect x={6} y={4} width={8} height={3} rx={0.2} stroke='currentColor' strokeWidth={1.2} />
        <rect x={6} y={9} width={2} height={2} rx={1} fill='currentColor' />
        <rect x={6} y={12} width={2} height={2} rx={1} fill='currentColor' />
        <rect x={6} y={15} width={2} height={2} rx={1} fill='currentColor' />
        <rect x={9} y={9} width={2} height={2} rx={1} fill='currentColor' />
        <rect x={9} y={12} width={2} height={2} rx={1} fill='currentColor' />
        <rect x={9} y={15} width={2} height={2} rx={1} fill='currentColor' />
        <rect x={12} y={9} width={2} height={2} rx={1} fill='currentColor' />
        <rect x={12} y={12} width={2} height={2} rx={1} fill='currentColor' />
        <rect x={12} y={15} width={2} height={2} rx={1} fill='currentColor' />
        <rect x={3.6} y={0.6} width={12.8} height={18.8} rx={1.4} stroke='currentColor' strokeWidth={1.2} />
      </g>
    </svg>
  );
}

export default SvgCalculateIcon;
