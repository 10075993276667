import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';
import { Sizes } from '../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: -20,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down(Sizes.tablet)]: {
        marginTop: 0,
      },
    },
    logoBox: {
      maxWidth: 300,
      maxHeight: 100,
    },
    logo: {
      marginBottom: 30,
      objectFit: 'contain',
      maxWidth: '100%',
      maxHeight: '100%',
      [theme.breakpoints.down(Sizes.desktop)]: {
        marginBottom: 20,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        marginBottom: 50,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginBottom: 30,
      },
    },
    signUpTitle: {
      fontSize: 40,
      fontWeight: 700,
      marginBottom: 35,
      [theme.breakpoints.down(Sizes.desktop)]: {
        fontSize: 36,
        marginBottom: 25,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        marginBottom: 40,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        fontSize: 30,
        width: '95%',
        textAlign: 'center',
        marginBottom: 25,
      },
    },
    authFormBox: {
      backgroundColor: colorVariables.white,
      boxShadow: '1px 2px 15px rgba(12, 82, 112, 0.15)',
      borderRadius: 10,
      width: 400,
      padding: '30px 35px',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 330,
        padding: '25px 15px',
      },
    },
    fieldBox: {
      marginBottom: 25,
      [theme.breakpoints.down(Sizes.desktop)]: {
        marginBottom: 15,
      },
    },
    orText: {
      textTransform: 'uppercase',
      fontSize: 14,
      textAlign: 'center',
      marginBottom: 10,
    },
    btnBox: {
      textAlign: 'center',
    },
    bottomBox: {
      marginTop: 30,
      opacity: 0.7,
      textAlign: 'center',
      lineHeight: '30px',
      [theme.breakpoints.down(Sizes.desktop)]: {
        marginTop: 20,
      },
    },
    link: {
      fontWeight: 700,
      color: 'inherit',
      '&:hover': {
        color: colorVariables.blue,
      },
    },
    errorText: {
      fontSize: 12,
      lineHeight: '20px',
      color: colorVariables.red,
      fontStyle: 'italic',
      textAlign: 'center',
    },
    terms: {
      marginTop: 30,
      fontSize: 12,
      opacity: 0.7,
      textAlign: 'center',
      lineHeight: '30px',
      [theme.breakpoints.down(Sizes.desktop)]: {
        marginTop: 15,
        lineHeight: '20px',
        paddingBottom: 10,
      },
    },
    termsLinksBox: {
      fontWeight: 700,
    },
    nameFields: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      '&>div': {
        width: '48%',
      },
    },
    checkEmailBox: {
      height: 264,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    checkEmailContent: {
      opacity: 0.7,
      textAlign: 'center',
      lineHeight: '24px',
      marginBottom: 30,
    },
    blueLink: {
      textAlign: 'center',
      color: colorVariables.blue,
      lineHeight: '24px',
      width: '75%',
      fontWeight: 400,
      cursor: 'pointer',
    },
    errorBox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 5,
    },
    closeErrorBtn: {
      marginRight: 8,
      width: 20,
      height: 20,
      borderRadius: '50%',
      backgroundColor: '#FD0C0C',
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:hover': {
        backgroundColor: '#FD0C0C',
        opacity: 0.8,
      },
    },
    closeError: {
      fontSize: 16,
      color: colorVariables.white,
    },
    gBtnBox: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 25,
      marginTop: 10,
      '& iframe': {
        scale: 1.25,
        [theme.breakpoints.down(Sizes.mobile)]: {
          scale: 1.16,
        },
      },
    },
    poweredBox: {
      paddingTop: 25,
      display: 'flex',
      alignItems: 'center',
      gap: 10,
      '& span': {
        opacity: 0.7,
      },
    },
  }),
);
