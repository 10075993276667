import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { Sizes } from '../../../styles/sizes';

interface StyleProps {
  progress: number;
  color?: string;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    loadingBox: {
      width: 369,
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 300,
      },
    },
    progress: {
      width: '100%',
      height: 9,
      background: '#DADADA',
      borderRadius: 4,
      overflow: 'hidden',
    },
    filter: {
      background: ({ color }) => color || 'linear-gradient(90deg, #51D9B0 0%, #33A8E2 100% )',
      height: 'inherit',
      borderRadius: 'inherit',
      width: ({ progress }) => `${progress}%`,
      transition: 'width ease-in-out 0.2s',
    },
    logoBoxWrap: {
      width: 140,
      margin: '0 auto 35px auto',
      [theme.breakpoints.down(Sizes.mobile)]: {
        margin: '0 auto 20px auto',
      },
    },
    logoBox: {
      width: ({ progress }) => (progress <= 80 ? `${progress}%` : '100%'),
      opacity: ({ progress }) => (progress < 50 ? 0.45 : progress < 96 ? 0.65 : 1),
      transition: 'all ease-in-out 0.2s',
      overflow: 'hidden',
    },
  }),
);
