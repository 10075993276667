import React, { useEffect, useState } from 'react';

import clsx from 'clsx';
import { FastField, FieldProps, Form, Formik, FormikHelpers } from 'formik';

import { CloseButton } from '../../../../../shared/components/close-button';
import { ThemedButton } from '../../../../../shared/components/themed-button';
import { CommonCheckbox } from '../../../../../shared/components/common-checkbox';
import { FormikDropdown } from '../../../../../shared/components/formik-dropdown';
import { ProductsCategories, productsCategoriesList } from '../../../../../shared/constants/products';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { getSelectedCategory, setSelectedCategory } from '../../../../../store/suppliers-categories';
import { setMySuppliersSort } from '../../../../../store/suppliers-categories/suppliers-categories.actions';
import { getSuppliersSort } from '../../../../../store/suppliers-categories/suppliers-categories.selectors';
import { mySupplierSortOptions } from '../../SuppliersTab';

import { useStyles } from './style';

interface Props {
  isOpened: boolean;
  closeModal: () => void;
}

export const FilterByCategoryMobile: React.FC<Props> = ({ isOpened, closeModal }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [category, setCategory] = useState<ProductsCategories | null>(null);
  const selectedCategory = useAppSelector(getSelectedCategory);
  const { mySuppliersSort } = useAppSelector(getSuppliersSort);

  const checkBoxHandler = (cat: ProductsCategories | null) => {
    setCategory(cat);
  };

  const onSubmit = () => {
    // dispatch(setSelectedCategory(category));
    // closeModal();
  };

  const onClear = () => {
    setCategory(null);
    dispatch(setSelectedCategory(null));
  };

  const onSubmitSort = (values: { sort: string }, formikHelpers: FormikHelpers<any>) => {
    formikHelpers.setSubmitting(false);
    dispatch(setMySuppliersSort(values.sort));
    dispatch(setSelectedCategory(category));
    closeModal();
  };

  useEffect(() => {
    setCategory(selectedCategory);
  }, [selectedCategory, isOpened]);

  return (
    <div className={clsx(classes.root, !isOpened && classes.rootClosed)}>
      <Formik onSubmit={onSubmitSort} enableReinitialize={true} initialValues={{ sort: mySuppliersSort || 'most_purchased desc' }}>
        <Form>
          <div className={classes.head}>
            <CloseButton onClick={closeModal} />
            <h3 className={classes.modalTitle}>Filters</h3>
            <div className={classes.clearBtn} onClick={onClear}>
              Clear
            </div>
          </div>
          <div className={classes.box}>
            <h4 className={classes.subTitle}>SORT BY</h4>
            <div className={classes.sortBox}>
              <FastField name='sort'>
                {(fieldProps: FieldProps) => (
                  <FormikDropdown options={mySupplierSortOptions} placeholder='Sort suppliers' {...fieldProps} />
                )}
              </FastField>
            </div>

            <h4 className={classes.subTitle}>FILTER BY CATEGORY</h4>
            <div>
              <div key='All'>
                <CommonCheckbox
                  formControlClassName={classes.checkboxControl}
                  checked={!category}
                  name='All'
                  onChange={checkBoxHandler.bind(null, null)}
                  labelPlace='start'
                />
              </div>
              {productsCategoriesList.map((catItem) => (
                <div key={catItem.title}>
                  <CommonCheckbox
                    formControlClassName={classes.checkboxControl}
                    checked={category === catItem.title}
                    name={catItem.title}
                    onChange={checkBoxHandler.bind(null, catItem.title)}
                    labelPlace='start'
                  />
                </div>
              ))}
            </div>
          </div>
          <div className={classes.btnBox}>
            <ThemedButton onClick={closeModal} title='Cancel' buttonStyle='secondary' isSmall={true} width={140} type='reset' />
            <ThemedButton onClick={onSubmit} title='Apply' isSmall={true} width={140} type='submit' />
          </div>
        </Form>
      </Formik>
    </div>
  );
};
