import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../../../../styles/colorVariables';
import { Sizes } from '../../../../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    formBox: {
      height: 'calc(100% - 88px)',
      [theme.breakpoints.up(Sizes.mobile)]: {
        width: 375,
      },
    },
    root: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    sortBlock: {
      width: '90%',
      margin: '20px auto',
    },
    blockTitle: {
      fontSize: 12,
      fontWeight: 700,
      color: colorVariables.navy,
      marginBottom: 12,
      textTransform: 'uppercase',
    },
    filterBlock: {
      width: '90%',
      margin: '0 auto',
    },
    btnBlock: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 20,
      padding: 20,
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: '40px 20px',
      },
      [theme.breakpoints.down(400)]: {
        padding: '30px 10px',
        '& button': {
          minWidth: '40%',
        },
      },
    },
    btnBlockFullScreen: {
      marginTop: 'auto',
    },
    checkbox: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    statusField: {
      marginBottom: 25,
      '&+div': {
        paddingRight: 15,
      },
    },
  }),
);
