import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import * as Yup from 'yup';

import clsx from 'clsx';
import { FastField, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { useDebouncedCallback } from 'use-debounce';
import { useGetConnectionRequestsQuery } from '../../../../api/company';
import { useAddSupplierMutation } from '../../../../api/supplier';
import SvgCar from '../../../../assets/icons/Car';
import SvgShop from '../../../../assets/icons/Shop';
import { BackArrowButton } from '../../../../shared/components/back-arrrow-button';
import { ThemedButton } from '../../../../shared/components/themed-button';
import { FormikInput } from '../../../../shared/components/formik-input';
import { InfoTooltipComponent } from '../../../../shared/components/info-tooltip-component';
import { ProductsCategories } from '../../../../shared/constants/products';
import { getCategoryId } from '../../../../shared/helpers/getCategoryId';
import useInterval from '../../../../shared/hooks/use-interval';
import { useScreenSize } from '../../../../shared/hooks/use-screen-size';
import { LocalStorageService } from '../../../../shared/services/localStorage.service';
import { ToastService } from '../../../../shared/services/toastService';
import { useAppDispatch } from '../../../../store';
import { setSelectedSupplier } from '../../../../store/suppliers-categories/suppliers-categories.actions';
import { colorVariables } from '../../../../styles/colorVariables';
import { ConnectionDropdownNew } from '../../SuppliersCategories/common/connection-dropdown-new';
import { BusinessDropdown } from '../business-dropdown';

import { useStyles } from './style';

interface Props {
  category: ProductsCategories | null;
  location?: {
    lat: number;
    lng: number;
  } | null;
  onboarded?: boolean;
  openNextCategory?: () => void;
  isSelfManaged?: boolean;
  backHandler?: () => void;
  setManualSupplier?: () => void;
  onOnboardRestaurant?: () => void;
  categories: ProductsCategories[];
}

interface Values {
  name: string;
  email: string;
  phone_number?: string;
}

export const AddSuppliersNew: React.FC<Props> = ({
  location,
  category,
  onboarded,
  backHandler,
  categories,
  openNextCategory = () => {},
  onOnboardRestaurant = () => {},
  setManualSupplier = () => {},
}) => {
  const classes = useStyles();
  const { isMobile, isDesktop } = useScreenSize();
  const formRef = useRef<HTMLFormElement | null>(null);
  const dispatch = useAppDispatch();

  const [supplierName, setSupplierName] = useState<string>('');
  const [isSelfManaged, setIsSelfManaged] = useState<boolean | undefined>(undefined);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const [localCat, setLocalCat] = useState<ProductsCategories | null>(null);

  const { data: connectionRequests = [] } = useGetConnectionRequestsQuery(undefined, { refetchOnFocus: true });

  const [addSupplier, { isLoading: inviteLoading }] = useAddSupplierMutation();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Enter correct email!'),
  });

  const onSetSupplierName = () => {
    const name = document.getElementById('search-sale-box')?.querySelector('input')?.value;
    setSupplierName(name?.trim() || '');
  };

  const handleClose = useCallback(() => {
    isDesktop ? setAnchorEl(null) : setIsDropdownOpened(false);
    dispatch(setSelectedSupplier(null));
  }, [isDesktop]);

  const handleClick = useCallback(
    (event: React.MouseEvent<any>) => {
      isDesktop ? setAnchorEl(event.currentTarget) : setIsDropdownOpened(true);
    },
    [isDesktop],
  );

  const debouncedSubmit = useDebouncedCallback(() => {
    document.getElementById('submit-btn')?.click();
  }, 200);

  const onSetCategory = (cat: ProductsCategories | null) => {
    setLocalCat(cat);
    isSelfManaged !== false && LocalStorageService.setItem('manualSupplierCategory', cat);
    if (isSelfManaged) {
      LocalStorageService.setItem('manualSupplierName', supplierName);
      setManualSupplier();
    } else {
      cat && debouncedSubmit();
    }
  };

  const onInvite = ({ name, email, phone_number = '' }: Values, formikHelpers: FormikHelpers<any>) => {
    formikHelpers.setSubmitting(false);
    if (!supplierName || !email) {
      return;
    }
    addSupplier({
      company: {
        name: supplierName,
        suppliers_attributes: [
          {
            first_name: name,
            email,
            phone_number,
          },
        ],
        company_categories_attributes: [
          {
            category_id: getCategoryId(onboarded ? (localCat as ProductsCategories) : (category as ProductsCategories)),
          },
        ],
      },
    }).then((res) => {
      if ('data' in res && res.data?.success) {
        ToastService.success(`You have added ${supplierName}`, supplierName, 'Supplier invite sent');
        openNextCategory();
      }
    });
  };

  useInterval(onSetSupplierName, 1500);

  const uniqSuppliersDone = useMemo(() => {
    return categories.reduce((acc, cat) => {
      const reqCount = connectionRequests.filter((req) => req.connection_categories[0]?.category_id === getCategoryId(cat))?.length;
      acc += reqCount >= 1 ? 1 : 0;
      return acc;
    }, 0);
  }, [categories, connectionRequests]);

  useEffect(() => {
    formRef?.current?.reset();
    setIsSelfManaged(undefined);
  }, [category]);

  return (
    <div className={classes.rootBox}>
      <Formik
        initialValues={{
          email: '',
          name: '',
          phone_number: '',
        }}
        validationSchema={validationSchema}
        onSubmit={onInvite}
        enableReinitialize={true}
      >
        {({ submitForm, errors, values }) => (
          <Form ref={formRef}>
            <div className={clsx(classes.row, supplierName && classes.rowCompleted)}>
              <BusinessDropdown
                category={category}
                location={location}
                openNextCategory={openNextCategory}
                onboarded={onboarded}
                handleClick={handleClick}
              />
            </div>
            <div
              className={clsx(
                classes.row,
                typeof isSelfManaged === 'boolean' && classes.rowCompleted,
                !supplierName && classes.rowInactive,
              )}
            >
              <div className={classes.label}>Who will be managing your pricing? *</div>
              <div className={classes.typeBtnsBox}>
                <div className={classes.supplierManagedWrap}>
                  <div
                    onClick={supplierName ? setIsSelfManaged.bind(null, false) : undefined}
                    className={clsx(classes.selfBtn, isSelfManaged === false && classes.selfBtnActive)}
                  >
                    Supplier managed
                    <SvgCar color={isSelfManaged === false ? colorVariables.white : colorVariables.grey60} />
                  </div>
                  <div className={classes.recommendedText}>Recommended</div>
                </div>
                {supplierName ? (
                  <InfoTooltipComponent
                    className={classes.selfBtnInfo}
                    width={isMobile ? 170 : 200}
                    text={'We will ask you to add further supplier details and to upload your products and prices in the next step.'}
                  >
                    <div
                      className={clsx(classes.selfBtn, isSelfManaged && classes.selfBtnActive)}
                      onClick={supplierName ? setIsSelfManaged.bind(null, true) : undefined}
                    >
                      Self managed
                      <SvgShop color={isSelfManaged ? colorVariables.white : colorVariables.grey60} />
                    </div>
                  </InfoTooltipComponent>
                ) : (
                  <div
                    className={clsx(classes.selfBtn, isSelfManaged && classes.selfBtnActive)}
                    onClick={supplierName ? setIsSelfManaged.bind(null, true) : undefined}
                  >
                    Self managed
                    <SvgShop color={isSelfManaged ? colorVariables.white : colorVariables.grey60} />
                  </div>
                )}
              </div>
            </div>
            {isSelfManaged === false && (
              <>
                <div className={classes.infoFormText}>
                  We will work with your supplier to set up this for you. Could you please add their contact details?
                </div>
                <div
                  className={clsx(classes.row, values.name.trim() && classes.rowCompleted, !supplierName && classes.rowInactive)}
                  key={supplierName}
                >
                  <FastField name='name'>
                    {(fieldProps: FieldProps) => (
                      <FormikInput {...fieldProps} label='Contact Name' placeholder='Enter Name' height={isMobile ? 41 : 48} />
                    )}
                  </FastField>
                </div>
                <div
                  className={clsx(
                    classes.row,
                    values.email.trim() && !errors.email && classes.rowCompleted,
                    (!supplierName || !values.name) && classes.rowInactive,
                  )}
                >
                  <FastField name='email'>
                    {(fieldProps: FieldProps) => (
                      <FormikInput
                        type='email'
                        {...fieldProps}
                        label='Contact Email'
                        placeholder='Enter Email'
                        height={isMobile ? 41 : 48}
                      />
                    )}
                  </FastField>
                </div>
                <div
                  className={clsx(
                    classes.row,
                    values.phone_number.trim() && classes.rowCompleted,
                    (!supplierName || !values.name || !values.email || !!errors.email) && classes.rowInactive,
                  )}
                >
                  <FastField name='phone_number'>
                    {(fieldProps: FieldProps) => (
                      <FormikInput
                        {...fieldProps}
                        label='Contact Phone Number'
                        placeholder='Enter Phone Number'
                        height={isMobile ? 41 : 48}
                      />
                    )}
                  </FastField>
                </div>
              </>
            )}
            {onboarded ? (
              <div className={classes.btnsBox}>
                <div />
                <ThemedButton
                  id={'submit-btn'}
                  onClick={(e:any) => {
                    if (!localCat) {
                      handleClick(e);
                    } else {
                      isSelfManaged ? setManualSupplier() : submitForm();
                    }
                  }}
                  title='Add Supplier'
                  width={isMobile ? 160 : 215}
                  disabled={
                    !supplierName ||
                    typeof isSelfManaged !== 'boolean' ||
                    (!isSelfManaged && (!values.name.trim() || !values.email.trim() || !values.phone_number.trim() || !!errors.email)) ||
                    inviteLoading
                  }
                />
              </div>
            ) : (
              <div className={classes.btnsBox}>
                <BackArrowButton onClick={backHandler} />
                {uniqSuppliersDone >= categories.length ? (
                  <ThemedButton onClick={onOnboardRestaurant} title='Next' width={isMobile ? 150 : 215} />
                ) : (
                  <ThemedButton
                    onClick={isSelfManaged ? setManualSupplier : submitForm}
                    title='Add Supplier'
                    width={isMobile ? 160 : 215}
                    disabled={
                      !supplierName ||
                      typeof isSelfManaged !== 'boolean' ||
                      (!isSelfManaged && (!values.name.trim() || !values.email.trim() || !values.phone_number.trim() || !!errors.email)) ||
                      inviteLoading
                    }
                  />
                )}
              </div>
            )}
          </Form>
        )}
      </Formik>
      {!onboarded && (
        <div className={classes.completeSuppliers}>
          {uniqSuppliersDone}/{categories.length} Suppliers Complete
        </div>
      )}
      {onboarded && (
        <ConnectionDropdownNew
          isDropdownOpened={isDropdownOpened}
          handleClose={handleClose}
          anchorEl={anchorEl}
          setCategoty={onSetCategory}
        />
      )}
    </div>
  );
};
