import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import SvgPointer from '../../../../assets/icons/Pointer';
import { BackButton } from '../../../../shared/components/back-button';
import { NavbarTitle } from '../../../../shared/components/navbar-title';
import { helpCenterGeneral } from '../../../../shared/constants/helpCenterGeneral';
import { helpCenterRestaurant } from '../../../../shared/constants/helpCenterRestaurant';
import { helpCenterSupplier } from '../../../../shared/constants/helpCenterSupplier';
import { TopNavBar } from '../../../AppDrawer/MainNavBar/top-nav-bar';

import { useStyles } from './style';

export const HelpDetails: React.FC = () => {
  const classes = useStyles();
  const { goBack, push } = useHistory();
  const { id } = useParams<{ id?: string }>();

  const article = useMemo(() => {
    return [...helpCenterSupplier, ...helpCenterRestaurant, ...helpCenterGeneral].find((el) => id && el.id === +id);
  }, [id]);

  useEffect(() => {
    if (!article) {
      push('/account/help/help-center');
    }
  }, [!!article]);

  if (!article) {
    return null;
  }

  return (
    <>
      <TopNavBar isAccount={true} leftComponent={<NavbarTitle title='Help' showBackBtn={true} />} />
      <div className={classes.root}>
        <BackButton onClickHandler={goBack} />
        <div className={classes.mobileHead}>
          <NavbarTitle title='' showBackBtn={true} />
          <SvgPointer width={32} height={32} />
        </div>
        <h2 className={classes.title}>{article.title}</h2>
        <SvgPointer className={classes.icon} width={32} height={32} />
        <div className={classes.content} dangerouslySetInnerHTML={{ __html: article.content }}></div>
      </div>
    </>
  );
};
