import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../styles/colorVariables';
import { Sizes } from '../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: 'relative',
      paddingBottom: 80,
      width: 860,
      margin: '0 auto',
      [theme.breakpoints.down(Sizes.desktop)]: {
        paddingBottom: 40,
        width: '100%',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        paddingBottom: 30,
        marginTop: -50,
      },
    },
    title: {
      marginTop: 20,
      marginBottom: 25,
      fontSize: 28,
      lineHeight: '50px',
      fontWeight: 700,
      '& span': {
        fontWeight: 400,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        fontSize: 20,
        lineHeight: '24px',
        marginBottom: 10,
      },
    },
    icon: {
      position: 'absolute',
      top: 10,
      right: 10,
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    mobileHead: {
      display: 'none',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'flex',
        marginBottom: 30,
        justifyContent: 'space-between',
      },
    },
    content: {
      '& img': {
        maxWidth: '100%',
        marginRight: 10,
      },
      '& p': {
        paddingTop: 10,
        paddingBottom: 10,
        lineHeight: '22px',
        maxWidth: '100%',
      },
      '& a': {
        color: colorVariables.blue,
      },
      '& h3': {
        fontSize: 16,
        fontWeight: 700,
      },
      '& b': {
        fontWeight: 700,
      },
      '& ul': {
        paddingLeft: 20,
        listStyleType: 'disc',
        marginBottom: 10,
        lineHeight: '22px',
      },
      '& ol': {
        paddingLeft: 20,
        marginBottom: 10,
        lineHeight: '22px',
      },
    },
    downIcon: {
      fontSize: 30,
      position: 'absolute',
      right: 26,
      top: 25,
      transition: 'transform ease-in-out 0.5s',
    },
    downIconRotated: {
      transform: 'rotate(180deg)',
    },
  }),
);
