import { useGetStockTakeHistoryQuery, useGetStockTakesQuery } from './index';
import { useMemo } from 'react';
import { StockTakeReportFilter } from '../../components/AppDrawer/RestaurantDrawer/Tools/Inventory/StockTakeHistoryReport';
import { useParams } from 'react-router';
import { StockTakeHistoryParams } from './types';

export const useStockTakesList = (skipRequest?: boolean) => {
  const { data, isLoading, isFetching } = useGetStockTakesQuery(undefined, {
    skip: skipRequest
  });
  return useMemo(
    () => ({
      list: data?.stocktakes || [],
      loading: isLoading,
      fetching: isFetching,
    }),
    [data, isLoading, isFetching],
  );
};

export const useGetActiveStockTake = (skipRequest?: boolean) => {
  const { list } = useStockTakesList(skipRequest);

  return useMemo(() => {
    if(list.length === 0) {
      return undefined;
    }

    return list?.find((el) => el.items_with_variance === null);
  }, [list]);
};

export const useGetStockTakeReport = ({ keyword, filter }: { keyword?: string; filter?: StockTakeReportFilter }) => {
  const { id } = useParams<{ id: string }>();
  const { list } = useStockTakesList();
  const currentStockTake = useMemo(() => {
    return list?.find((el) => el.id === +id);
  }, [list, id]);

  const queryParams: Omit<StockTakeHistoryParams, 'id'> = {};
  if (keyword?.trim()) {
    queryParams.keyword = keyword;
  }
  if (filter !== 'all') {
    queryParams.filter_by = filter;
  }

  const { data } = useGetStockTakeHistoryQuery({ id: parseFloat(id), ...queryParams }, { skip: !id });

  return {
    created: currentStockTake?.created_at,
    user: currentStockTake?.user_name,
    data,
    id: +id,
  };
};
