import { createAction } from '@reduxjs/toolkit';
import { AdminConnectionsFilters, AdminConnectionsSortBy } from '../../../api/admin-connections/types';

export const setAdminConnectionsSortFilters = createAction<{ sort?: AdminConnectionsSortBy; filter?: AdminConnectionsFilters }>(
  'SET_ADMIN_CONNECTIONS_SORT_FILTERS',
);

export const setAdminConnectionsPage = createAction<number>('SET_ADMIN_CONNECTIONS_PAGE');

export const setAdminConnectionsKeyword = createAction<string | undefined>('SET_ADMIN_CONNECTIONS_KEYWORD');
