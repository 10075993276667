import { createReducer } from '@reduxjs/toolkit';

import { CustomerPerformanceFilters, CustomerPerformanceSortBy } from '../../api/dashboard/types';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { logoutThunk } from '../user';
import { setCustomerPerformanceSortFilter, setCustomersPerformanceKeyword, setCustomersPerformancePage } from './customers-perf.actions';

interface State {
  keyword?: string;
  currentPage: number;
  filter?: CustomerPerformanceFilters;
  sort?: CustomerPerformanceSortBy;
}

const initialState: State = {
  keyword: undefined,
  currentPage: 1,
  filter: LocalStorageService.getItem('performance_filter') || {
    risk_sensitivity: 2.5,
    days: 90,
  },
};

export const customersPerformanceReducer = createReducer(initialState, (builder) => {
  builder.addCase(logoutThunk.fulfilled, () => {
    return initialState;
  });
  builder.addCase(setCustomersPerformanceKeyword, (state, { payload }) => {
    if (state.keyword === payload.keyword) {
      return;
    }
    state.keyword = payload.keyword;
    state.currentPage = 1;
  });
  builder.addCase(setCustomersPerformancePage, (state, { payload }) => {
    state.currentPage = payload;
  });
  builder.addCase(setCustomerPerformanceSortFilter, (state, { payload }) => {
    state.filter = payload.filter;
    state.sort = payload.sort;
  });
});
