import { createAction } from '@reduxjs/toolkit';

import { ProductFilterValues, ProductsSortBy } from '../../api/product/types';

export const setRecipeProductsSortBy = createAction<ProductsSortBy | undefined>('SET_RECIPE_PRODUCTS_SORT_BY');

export const setRecipeProductsFilterBy = createAction<ProductFilterValues | undefined>('SET_RECIPE_PRODUCTS_FILTER_BY');

export const setRecipeProductsPage = createAction<number>('SET_RECIPE_PRODUCTS_PAGE');

export const setRecipeProductsKeyword = createAction<{ keyword?: string }>('SET_RECIPE_PRODUCTS_KEYWORD');

export const clearRecipeProductsList = createAction('CLEAR_RECIPE_PRODUCTS_LIST');
