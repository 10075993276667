import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../styles/colorVariables';
import { Sizes } from '../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    modalRoot: {
      padding: 40,
      paddingTop: 50,
      width: 750,
      zIndex: 991,
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '90vw',
        padding: '40px 20px 20px 20px',
      },
    },
    head: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 32,
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'block',
      },
    },
    title: {
      fontSize: 20,
      fontWeight: 700,
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginBottom: 5,
      },
    },
    userName: {
      fontSize: 16,
      fontWeight: 600,
      color: colorVariables.grey80,
      [theme.breakpoints.down(Sizes.mobile)]: {
        color: colorVariables.navy,
      },
    },
    sectionHead: {
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 5,
      },
    },
    sectionTitle: {
      fontSize: 12,
      textTransform: 'uppercase',
      fontWeight: 700,
      color: colorVariables.userGrey,
      marginBottom: 16,
    },
    label: {
      fontWeight: 600,
      color: colorVariables.userNavy,
    },
    section: {
      marginBottom: 22,
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginBottom: 20,
        paddingTop: 5,
        borderBottom: `1px solid ${colorVariables.steelGrey15}`,
      },
    },
    sectionContent: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'block',
      },
    },
    contentCol: {
      width: '45%',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
      },
    },
    contentCell: {
      display: 'flex',
      marginBottom: 5,
      justifyContent: 'space-between',
      alignItems: 'center',
      '&>div': {
        width: 'auto',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        minHeight: 32,
      },
    },
    mobileSelect: {
      maxWidth: 100,
      '&>div>div': {
        color: colorVariables.grey80,
        fontWeight: 400,
      },
    },
    sectionSubTitle: {
      marginTop: -5,
      marginBottom: 10,
      color: colorVariables.grey60,
    },
    categoriesBox: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    categoryItem: {
      width: '33%',
      paddingRight: 20,
      flexShrink: 0,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        paddingRight: 0,
      },
    },
    btnsBlock: {
      paddingTop: 10,
      display: 'flex',
      justifyContent: 'flex-end',
      [theme.breakpoints.down(Sizes.mobile)]: {
        justifyContent: 'center',
      },
    },
    expandTrigger: {
      display: 'none',
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginTop: -12,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        '&>b': {
          color: colorVariables.navy,
          fontWeight: 600,
        },
        '&>span': {
          color: colorVariables.blue,
        },
        '& svg': {
          color: colorVariables.grey60,
        },
      },
    },
    lockIcon: {
      color: colorVariables.blue,
      cursor: 'pointer',
    },
  }),
);
