import React, { useEffect, useRef, useState } from 'react';

import { useJsApiLoader } from '@react-google-maps/api';

import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { useUpdateSupplierProfileMutation } from '../../../../api/company';
import { Address } from '../../../../api/company/types';
import { AddressAutocomplete } from '../../../../shared/components/address-autocomplete';
import { ThemedButton } from '../../../../shared/components/themed-button';
import { FormikInput } from '../../../../shared/components/formik-input';
import { OverlayModal } from '../../../../shared/components/overlay-modal';
import { PostalCodePlaceAutocomplete } from '../../../../shared/components/postal-code-place-autocomplete';
import { ProfileModals } from '../index';

import { useStyles } from './style';

interface Values {
  street_address1?: string;
  street_address2?: string;
  city?: string;
  state: string;
  postcode: string;
}

const initialValues: Values = {
  postcode: '',
  state: '',
};

interface Props {
  values?: Partial<Address>;
  onClose: () => void;
  onSubmit: (formValues: any) => void;
  isModalOpen: boolean;
  isOnBoarding: boolean;
  setNextStep: (step: ProfileModals) => void;
  companyId?: number;
}

export const AddressForm: React.FC<Props> = ({ values, companyId, onClose, isModalOpen, isOnBoarding, setNextStep }) => {
  const classes = useStyles();
  const ref = useRef<HTMLFormElement | null>(null);

  const [postalData, setPostalData] = useState<Values>(initialValues);

  const [updateProfile, { isSuccess, isLoading }] = useUpdateSupplierProfileMutation();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
    libraries: ['places'],
  });

  const onSubmitForm = (formValues: Values, formikHelpers: FormikHelpers<any>) => {
    if (isOnBoarding) {
      setNextStep(ProfileModals.COMPANY_DETAILS);
    } else {
      const formData = new FormData();
      values?.id && formData.append('company[addresses_attributes][0][id]', values.id.toString());
      formData.append('company[addresses_attributes][0][street_address1]', formValues?.street_address1 || '');
      formData.append('company[addresses_attributes][0][street_address2]', formValues?.street_address2 || '');
      formData.append(
        'company[addresses_attributes][0][full_address]',
        `${formValues?.street_address1}, ${formValues.city}, ${formValues.state}, ${postalData.postcode}`,
      );
      postalData?.postcode && formData.append('company[addresses_attributes][0][postcode]', postalData.postcode);
      formValues?.city && formData.append('company[addresses_attributes][0][city]', formValues.city);
      formData.append('company[addresses_attributes][0][state]', formValues.state || ' ');
      companyId &&
        updateProfile({
          formData,
          id: companyId,
        });
    }
    formikHelpers.setSubmitting(false);
  };

  const closeHandler = () => {
    isOnBoarding ? setNextStep(ProfileModals.UPLOAD_LOGO) : onClose();
  };

  const onSetPostCodeLoc = (place: google.maps.places.PlaceResult, formValues: Values) => {
    const postcode = place.address_components?.find((el) => el.types.includes('postal_code'))?.long_name || '';
    if (!postcode) {
      return;
    }
    const state = place.address_components?.find((el) => el.types.includes('administrative_area_level_1'))?.long_name || '';
    const suburb = place.address_components?.find((el) => el.types.includes('locality'))?.long_name || '';
    setPostalData({ ...formValues, postcode, state, city: suburb });
  };

  const onSetAddress = (place: google.maps.places.PlaceResult, formValues: Values) => {
    const postcode = place.address_components?.find((el) => el.types.includes('postal_code'))?.long_name || postalData.postcode;
    const state = place.address_components?.find((el) => el.types.includes('administrative_area_level_1'))?.long_name || '';
    const suburb = place.address_components?.find((el) => el.types.includes('locality'))?.long_name || '';
    const street_number = place.address_components?.find((el) => el.types.includes('street_number'))?.long_name || '';
    const route = place.address_components?.find((el) => el.types.includes('route'))?.long_name || '';
    setPostalData({
      ...formValues,
      postcode,
      state,
      city: suburb,
      street_address1: `${street_number} ${route}`.trim() || place.name,
    });
  };

  useEffect(() => {
    values &&
      isModalOpen &&
      setPostalData({
        state: values?.state || '',
        postcode: values?.postcode || '',
        street_address1: values.street_address1 || '',
        street_address2: values.street_address2 || '',
        city: values?.city || '',
      });
  }, [values, isModalOpen]);

  useEffect(() => {
    if (!isModalOpen) {
      ref?.current?.reset();
      setPostalData(initialValues);
    }
  }, [isModalOpen]);

  useEffect(() => {
    isSuccess && onClose();
  }, [isSuccess]);

  return (
    <OverlayModal onClose={onClose} isOpen={isModalOpen} title='Edit Address' animate={!isOnBoarding}>
      <Formik initialValues={postalData} onSubmit={onSubmitForm} enableReinitialize={true}>
        {({ submitForm, isValid, setFieldError, values: formValues, setFieldValue }) => (
          <Form className={classes.formBox} ref={ref}>
            <div className={classes.fieldWrap}>
              {isLoaded && (
                <AddressAutocomplete
                  initialValue={formValues.street_address1}
                  label='Address Line 1'
                  placeholder='Address Line 1'
                  autoComplete='chrome-off'
                  setPlace={(gData) => onSetAddress(gData, formValues)}
                />
              )}
            </div>
            <div className={classes.fieldWrap}>
              <Field name='street_address2'>
                {(fieldProps: FieldProps) => (
                  <FormikInput {...fieldProps} label='Address Line 2' placeholder='Address Line 2' autoComplete='chrome-off' />
                )}
              </Field>
            </div>
            <div className={classes.smallFieldsBox}>
              <div className={classes.smallFieldWrap}>
                {isLoaded && (
                  <PostalCodePlaceAutocomplete
                    initialValue={postalData.postcode}
                    setFieldError={setFieldError}
                    placeholder='Postcodе'
                    setPlace={(gData) => onSetPostCodeLoc(gData, formValues)}
                    label='Postcodе'
                  />
                )}
              </div>
              <div className={classes.smallFieldWrap}>
                <Field name='city'>
                  {(fieldProps: FieldProps) => (
                    <FormikInput {...fieldProps} label='Suburb' placeholder='Suburb' autoComplete='chrome-off' />
                  )}
                </Field>
              </div>
              <div className={classes.smallFieldWrap}>
                <Field name='state'>
                  {(fieldProps: FieldProps) => <FormikInput placeholder='State/City' label='State/City' {...fieldProps} />}
                </Field>
              </div>
            </div>
            <div className={classes.btnBlock}>
              <ThemedButton title={isOnBoarding ? 'Back' : 'Cancel'} onClick={closeHandler} buttonStyle='secondary' />
              <ThemedButton
                title={isOnBoarding ? 'Next' : 'Update'}
                onClick={submitForm}
                buttonStyle='primary'
                disabled={(!postalData.postcode || !isValid || isLoading) && !isOnBoarding}
                customClass={classes.saveBtn}
              />
            </div>
            <input type='text' name='postcode' placeholder='postcode' className='hiddenInput' />
          </Form>
        )}
      </Formik>
    </OverlayModal>
  );
};
