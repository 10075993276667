import { createReducer } from '@reduxjs/toolkit';

import { DriversSortBy } from '../../api/drivers/types';
import { logoutThunk } from '../user';
import { setAddDriverMode, setDriversKeyword, setDriversSort } from './drivers.actions';

interface State {
  sortBy?: DriversSortBy;
  keyword?: string;
  addDriverMode: boolean;
}

const initialState: State = {
  sortBy: undefined,
  keyword: undefined,
  addDriverMode: false,
};

export const driversReducer = createReducer(initialState, (builder) => {
  builder.addCase(logoutThunk.fulfilled, () => {
    return { ...initialState, filterBy: undefined };
  });
  builder.addCase(setDriversKeyword, (state, { payload }) => {
    if (state.keyword === payload) {
      return;
    }
    state.keyword = payload;
  });
  builder.addCase(setDriversSort, (state, { payload }) => {
    state.sortBy = payload;
  });
  builder.addCase(setAddDriverMode, (state, { payload }) => {
    state.addDriverMode = payload;
  });
});
