import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useMeQuery } from '../../../../../api/user';
import { RestaurantUser } from '../../../../../api/user/types';
import SvgSelfManagedModal from '../../../../../assets/images/svg-components/SelfManagedModal';
import { ThemedButton } from '../../../../../shared/components/themed-button';
import { OverlayModal } from '../../../../../shared/components/overlay-modal';

import { useStyles } from './style';

export const SetupManualSuppliersModal: React.FC = () => {
  const classes = useStyles();
  const { push } = useHistory();
  const { pathname } = useLocation();

  const { data } = useMeQuery();
  const user = data as RestaurantUser;

  const [isOpen, setIsOpen] = useState(false);
  const [listShown, setListShown] = useState(false);

  const onSetUpSupplier = (id: number) => {
    push(`/account/edit-manual-supplier/${id}`, { setup: true });
  };

  useEffect(() => {
    (pathname.endsWith('/account/suppliers') || pathname.endsWith('/account/suppliers/add') || pathname.includes('/browse_by_supplier')) &&
      setIsOpen(!!user?.incomplete_setup_self_managed_suppliers?.supplier_count);
  }, [user?.incomplete_setup_self_managed_suppliers?.supplier_count, pathname]);

  useEffect(() => {
    isOpen && setListShown(false);
  }, [isOpen]);

  return (
    <OverlayModal isOpen={isOpen} onClose={setIsOpen.bind(null, false)} boxClassName={classes.listModal}>
      {!listShown ? (
        <div className={classes.modalRoot}>
          <div className={classes.modalWrap}>
            <div className={classes.title}>Lets complete the set up of your self managed suppliers</div>
            <div className={classes.subtitle}>
              You still have <span>{`[${user?.incomplete_setup_self_managed_suppliers?.supplier_count} Suppliers]`}</span> you need to set
              up. <br />
              Would you like to set those up now?
            </div>
            <SvgSelfManagedModal className={classes.svg} />
            <ThemedButton onClick={setListShown.bind(null, true)} width={215} title='Next' customClass={classes.nextBtn} />
          </div>
        </div>
      ) : (
        <div className={classes.modalRoot}>
          <div>
            <div className={classes.greenText}>SELF MANAGED SUPPLIERS</div>
            <div className={classes.listTitle}>Lets complete the setup for your self managed suppliers</div>
            <div className={classes.listBox}>
              {user?.incomplete_setup_self_managed_suppliers.suppliers_data.map((supplier) => (
                <div key={supplier.supplier_id} className={classes.row}>
                  <div>
                    <div className={classes.supplierName}>{supplier.name}</div>
                    <div className={classes.supplierEmail}>{supplier.order_email}</div>
                  </div>
                  <div>
                    <ThemedButton
                      onClick={onSetUpSupplier.bind(null, supplier.supplier_id)}
                      title='Complete setup'
                      customClass={classes.completeBtn}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </OverlayModal>
  );
};
