import { format } from 'date-fns';

import { PriceExceptionFilter } from '../../api/price-exception/types';
import { priceExceptionSortParams } from '../../components/AppDrawer/SupplierDrawer/Product/PriceExceptions/price-exception-filter';
import { formatCurrency } from '../../shared/helpers/format-currency';
import { RootState } from '../index';

export const priceExceptionFilters = (state: RootState) => ({
  keyword: state.priceException.keyword,
  currentPage: state.priceException.currentPage,
  filter: state.priceException.filter,
  sort: state.priceException.sort,
});

export const getPriceExceptionChips = (state: RootState) => {
  const sortLabel =
    priceExceptionSortParams.find(
      (param) => param.value === state.priceException.sort?.join(' ') && param.value !== 'user_company_name asc',
    )?.label || '';

  const sortChip = {
    label: sortLabel,
    key: sortLabel ? 'sort' : '',
  };
  const filterChips = Object.entries(state.priceException.filter || {}).reduce((acc, [key, value]: [string, string]) => {
    switch (key as keyof PriceExceptionFilter) {
      case 'product_name': {
        if (!value) {
          return acc;
        }
        acc.push({
          label: `Product name - ${value}`,
          key: `product_name`,
        });
        break;
      }
      case 'from_date': {
        if (!value || !state.priceException.filter?.to_date) {
          return acc;
        }
        acc.push({
          label: `From ${format(new Date(value), 'd MMM yy')} to ${format(new Date(state.priceException.filter.to_date), 'd MMM yy')}`,
          key: `date`,
        });
        break;
      }
      case 'min_total_price': {
        if (!value) {
          return acc;
        }
        acc.push({
          label: `More than ${formatCurrency(value)}`,
          key: `min`,
        });
        break;
      }
      case 'max_total_price': {
        if (!value) {
          return acc;
        }
        acc.push({
          label: `Less than ${formatCurrency(value)}`,
          key: `max`,
        });
        break;
      }
    }
    return acc;
  }, [] as { label: string; key: string }[]);
  return [sortChip, ...filterChips].filter((chip) => !!chip.label && !!chip.key);
};
