export const mainCurrencies = [
  {
    countryName: 'United States',
    countryCode: 'US',
    currencyName: 'United States Dollar',
    currencyCode: 'USD',
    currencySymbol: '$',
    grouped: false,
  },
  {
    countryName: 'Australia',
    countryCode: 'AU',
    currencyName: 'Australian Dollar',
    currencyCode: 'AUD',
    currencySymbol: '$',
    grouped: false,
  },
  {
    countryName: 'New Zealand',
    countryCode: 'NZ',
    currencyName: 'New Zealand Dollar',
    currencyCode: 'NZD',
    currencySymbol: 'NZ$',
    grouped: false,
  },
  {
    countryName: 'Canada',
    countryCode: 'CA',
    currencyName: 'Canadian Dollar',
    currencyCode: 'CAD',
    currencySymbol: 'CA$',
    grouped: false,
  },
  {
    countryName: 'United Kingdom',
    countryCode: 'GB',
    currencyName: 'British Pound Sterling',
    currencyCode: 'GBP',
    currencySymbol: '£',
    grouped: false,
  },
  {
    countryName: 'Philippines',
    countryCode: 'PH',
    currencyName: 'Philippine Peso',
    currencyCode: 'PHP',
    currencySymbol: '₱',
    grouped: false,
  },
  {
    countryName: 'Indonesia',
    countryCode: 'ID',
    currencyName: 'Indonesian Rupiah',
    currencyCode: 'IDR',
    currencySymbol: 'Rp',
    grouped: false,
  },
  {
    countryName: 'Singapore',
    countryCode: 'SG',
    currencyName: 'Singapore Dollar',
    currencyCode: 'SGD',
    currencySymbol: '$',
    grouped: false,
  },
  {
    countryName: 'Malaysia',
    countryCode: 'MY',
    currencyName: 'Malaysian Ringgit',
    currencyCode: 'MYR',
    currencySymbol: 'RM',
    grouped: false,
  },
  {
    countryName: 'China',
    countryCode: 'CN',
    currencyName: 'Hong Kong Dollar',
    currencyCode: 'HKD',
    currencySymbol: '$',
    grouped: false,
  },
  {
    countryName: 'India',
    countryCode: 'IN',
    currencyName: 'Indian Rupee',
    currencyCode: 'INR',
    currencySymbol: '₹',
    grouped: false,
  },
  {
    countryName: 'Thailand',
    countryCode: 'TH',
    currencyName: 'Thai Baht',
    currencyCode: 'THB',
    currencySymbol: '฿',
    grouped: false,
  },
  {
    countryName: 'Vietnam',
    countryCode: 'VN',
    currencyName: 'Vietnamese Dong',
    currencyCode: 'VND',
    currencySymbol: '₫',
    grouped: false,
  },
];

export const euCurrencies = [
  {
    countryName: 'EU countries',
    countryCode: 'EU',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    currencySymbol: '€',
    grouped: false,
  },
  {
    countryName: 'Bulgaria',
    countryCode: 'BG',
    currencyName: 'Bulgarian Lev',
    currencyCode: 'BGN',
    currencySymbol: 'лв',
    grouped: true,
  },
  {
    countryName: 'Croatia',
    countryCode: 'HR',
    currencyName: 'Croatian Kuna',
    currencyCode: 'HRK',
    currencySymbol: 'kn',
    grouped: true,
  },
  {
    countryName: 'Hungary',
    countryCode: 'HU',
    currencyName: 'Hungarian Forint',
    currencyCode: 'HUF',
    currencySymbol: 'Ft',
    grouped: true,
  },
  {
    countryName: 'Poland',
    countryCode: 'PL',
    currencyName: 'Polish Złoty',
    currencyCode: 'PLN',
    currencySymbol: 'zł',
    grouped: true,
  },
  {
    countryName: 'Romania',
    countryCode: 'RO',
    currencyName: 'Romanian Leu',
    currencyCode: 'RON',
    currencySymbol: 'lei',
    grouped: true,
  },
  {
    countryName: 'Sweden',
    countryCode: 'SE',
    currencyName: 'Swedish Krona',
    currencyCode: 'SEK',
    currencySymbol: 'kr',
    grouped: true,
  },
  {
    countryName: 'Czechia',
    countryCode: 'CZ',
    currencyName: 'Czech Koruna',
    currencyCode: 'CZK',
    currencySymbol: 'Kč',
    grouped: true,
  },
  {
    countryName: 'Denmark',
    countryCode: 'DK',
    currencyName: 'Danish Krone',
    currencyCode: 'DKK',
    currencySymbol: 'kr',
    grouped: true,
  },
  {
    countryName: 'United States',
    countryCode: 'US',
    currencyName: 'United States Dollar',
    currencyCode: 'USD',
    currencySymbol: '$',
    grouped: true,
  },
];

export const currencies = [...mainCurrencies, ...euCurrencies];
