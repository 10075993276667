import { LocalStorageService } from '../../shared/services/localStorage.service';
import { SessionStorageService } from '../../shared/services/sessionStorage.service';
import { TokenService } from '../../shared/services/token.service';
import { RootState } from '../../store';
import { setSelectedUsers } from '../../store/admin/users';
import { logoutThunk, setAppSuccessToast, setImpersonated } from '../../store/user';
import { LoginResponse } from '../auth/types';
import { getUsersListQueryParamsHelper, setSuccessToast } from '../helpers';
import { HTTP, rootApi, SuccessResponse } from '../index';
import { userApi } from '../user';
import { Roles } from '../user/types';
import {
  CreateUserRequest,
  ExportUsersRequest,
  ExportUsersResponse,
  GetUsersForAdminResponse,
  PostSetUpRequest,
  SendPaymentLinkReq,
  UpdateUserRequest,
  UserForAdmin,
  UsersListReqParams,
} from './types';

export const adminUsersApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<GetUsersForAdminResponse, UsersListReqParams>({
      query(args) {
        const params = {
          ...getUsersListQueryParamsHelper(args),
          per_page: 30,
          page: args?.currentPage || 1,
        };
        return {
          url: `/admin/users`,
          method: HTTP.GET,
          params,
        };
      },
      providesTags: ['Users'],
    }),

    searchUsers: builder.query<GetUsersForAdminResponse, { keyword?: string; role?: Roles }>({
      query(args) {
        const params = {
          ...getUsersListQueryParamsHelper({ keyword: args.keyword, filter: { company_type: args.role } }),
          per_page: 20,
          page: 1,
        };
        return {
          url: `/admin/users`,
          method: HTTP.GET,
          params,
        };
      },
      providesTags: ['Users'],
    }),

    getUserById: builder.query<UserForAdmin, number>({
      query(id) {
        return {
          url: `/admin/users/${id}`,
          method: HTTP.GET,
        };
      },
      providesTags: ['Users'],
    }),

    createUser: builder.mutation<SuccessResponse, CreateUserRequest>({
      query(body) {
        return {
          url: `/admin/users`,
          method: HTTP.POST,
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'User has been created');
      },
      invalidatesTags: ['Users'],
    }),

    updateUser: builder.mutation<SuccessResponse, UpdateUserRequest>({
      query({ id, ...body }) {
        return {
          url: `/admin/users/${id}`,
          method: HTTP.PATCH,
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'User has been updated');
      },
      invalidatesTags: ['Suppliers', 'Users'],
    }),

    verifyUserEmail: builder.mutation<SuccessResponse, number>({
      query(id) {
        return {
          url: `/admin/users/${id}/confrim_user_email`,
          method: HTTP.PATCH,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'User email has been verified');
      },
      invalidatesTags: ['Users'],
    }),

    deleteUser: builder.mutation<SuccessResponse, number>({
      query(args) {
        return {
          url: `/admin/users/${args}`,
          method: HTTP.DELETE,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'User has been deleted');
      },
      invalidatesTags: ['Users'],
    }),

    restoreUser: builder.mutation<SuccessResponse, number>({
      query(args) {
        return {
          url: `/admin/users/${args}/restore_user`,
          method: HTTP.PATCH,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'User has been restored');
      },
      invalidatesTags: ['Users'],
    }),

    hardDeleteUser: builder.mutation<SuccessResponse, number>({
      query(args) {
        return {
          url: `/admin/users/${args}`,
          method: HTTP.DELETE,
          body: {
            hard_delete: true,
          },
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'User has been deleted');
      },
      invalidatesTags: ['Users'],
    }),

    deleteUsers: builder.mutation<SuccessResponse, number[]>({
      query(args) {
        return {
          url: `/admin/users/delete_multiple_users`,
          method: HTTP.DELETE,
          body: { user_ids: args },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, `${args.length} users have been deleted`);
        dispatch(setSelectedUsers([]));
      },
      invalidatesTags: ['Users'],
    }),

    deleteUnverifiedUsers: builder.mutation<SuccessResponse, void>({
      query() {
        return {
          url: `/admin/users/delete_unverified_users`,
          method: HTTP.PATCH,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          userApi.util.updateQueryData('me', undefined, (draft) => {
            draft.auto_verify = !draft.auto_verify;
          }),
        );
        try {
          await setSuccessToast(dispatch, queryFulfilled, `Updated`);
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: ['Me'],
    }),

    hardDeleteUsers: builder.mutation<SuccessResponse, number[]>({
      query(args) {
        return {
          url: `/admin/users/delete_multiple_users`,
          method: HTTP.DELETE,
          body: { user_ids: args, hard_delete: true },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, `${args.length} users have been deleted`);
        dispatch(setSelectedUsers([]));
      },
      invalidatesTags: ['Users'],
    }),

    impersonateUser: builder.mutation<LoginResponse, number>({
      query(id) {
        return {
          url: `/admin/users/${id}/impersonate_user`,
          method: HTTP.POST,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.auth_token) {
            const adminId = (getState() as RootState).user.userId;
            const usersState = (getState() as RootState).users;
            SessionStorageService.setItem('usersState', { state: usersState, adminId });
            const adminToken = LocalStorageService.getItem('auth_token');
            await dispatch(logoutThunk());
            LocalStorageService.setItem('a_token', adminToken);
            TokenService.setToken(data.auth_token);
            window.history.replaceState(null, 'state', '/');
            dispatch(userApi.endpoints.me.initiate(undefined, { forceRefetch: true }));
            dispatch(setImpersonated(true));
          }
        } catch (err) {
          console.log(err);
        }
      },
    }),

    exportUsers: builder.query<ExportUsersResponse, ExportUsersRequest>({
      query: (params) => {
        return {
          url: `admin/users/export_users_xls`,
          method: HTTP.GET,
          params,
        };
      },
      keepUnusedDataFor: 0,
    }),

    sendWelcomeEmail: builder.mutation<SuccessResponse, { id: number; inviterId: number }>({
      query: ({ id, inviterId: invited_by }) => {
        return {
          url: `admin/users/${id}/send_welcome_email`,
          method: HTTP.POST,
          body: {
            invited_by,
          },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, `Welcome email has been sent`);
      },
    }),

    sendPaymentLink: builder.mutation<SuccessResponse, SendPaymentLinkReq>({
      query: ({ userId, ...body }) => {
        return {
          url: `admin/users/${userId}/send_payment_link`,
          method: HTTP.POST,
          body,
        };
      },
    }),

    getPreviousInvoice: builder.mutation<{ success: boolean; pdf_file: string }, number>({
      query: (id) => {
        return {
          url: `admin/users/${id}/previous_invoice`,
          method: HTTP.GET,
        };
      },
    }),

    switchPosSetUp: builder.mutation<SuccessResponse, PostSetUpRequest>({
      query: ({ id, ...body }) => {
        return {
          url: `admin/users/${id}/pos_setup`,
          method: HTTP.PATCH,
          body,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setAppSuccessToast(args?.user?.pos_set_up ? `POS option has been turned on` : `POS option has been turned off`));
        } catch (e) {
          console.log(e);
        }
      },
      invalidatesTags: ['Users'],
    }),

    switchPaymentReceipt: builder.mutation<SuccessResponse, number>({
      query: (id) => {
        return {
          url: `admin/users/${id}/payment_receipts`,
          method: HTTP.PATCH,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setAppSuccessToast('User payment receipts updated successfully'));
        } catch (e) {
          console.log(e);
        }
      },
      invalidatesTags: ['Users'],
    }),
  }),

  overrideExisting: true,
});

export const {
  useGetUsersQuery,
  useDeleteUserMutation,
  useHardDeleteUserMutation,
  useRestoreUserMutation,
  useDeleteUsersMutation,
  useHardDeleteUsersMutation,
  useLazyExportUsersQuery,
  useGetUserByIdQuery,
  useUpdateUserMutation,
  useCreateUserMutation,
  useImpersonateUserMutation,
  useVerifyUserEmailMutation,
  useDeleteUnverifiedUsersMutation,
  useSearchUsersQuery,
  useSendWelcomeEmailMutation,
  useSendPaymentLinkMutation,
  useSwitchPosSetUpMutation,
  useSwitchPaymentReceiptMutation,
  useGetPreviousInvoiceMutation,
} = adminUsersApi;
