import { createReducer } from '@reduxjs/toolkit';
import { logoutThunk } from '../../user';
import { setAdminErrorsPage, setAdminErrorStatus } from './errors.actions';

interface State {
  currentPage: number;
  status?: '1' | '0';
}

const initialState: State = {
  currentPage: 1,
};

export const adminErrorsReducer = createReducer(initialState, (builder) => {
  builder.addCase(logoutThunk.fulfilled, () => {
    return initialState;
  });

  builder.addCase(setAdminErrorsPage, (state, { payload }) => {
    state.currentPage = payload;
  });
  builder.addCase(setAdminErrorStatus, (state, { payload }) => {
    state.status = payload;
  });
});
