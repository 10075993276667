import { Roles } from '../../api/user/types';
import store from '../../store';

declare global {
  interface Window {
    dataLayer: any[];
    onGoogleLibraryLoad: () => void;
  }
}
export const setGtmDatalayer = (params: {
  event: string;
  eventCategory: string;
  eventName?: string;
  userId?: number;
  userType?: Roles;
  eventAction?: string;
}) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    eventAction: window.location.href,
    userId: store.getState().user.userId,
    userType: store.getState().user.role,
    ...params,
  });
};
