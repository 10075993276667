import { createStyles, makeStyles } from '@material-ui/core';
import { colorVariables } from '../../../../../../styles/colorVariables';
import { Sizes } from '../../../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    idCol: {
      width: '10%',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    unitCol: {
      width: '12%',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    actionCol: {
      width: '15%',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 150,
      },
    },
    actionHead: {
      color: colorVariables.grey60,
      textTransform: 'uppercase',
      fontSize: 11,
      fontWeight: 700,
    },
    nameCol: {
      width: '20%',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 60,
        paddingRight: 30,
        textAlign: 'center',
        position: 'relative',
      },
    },
    nameHead: {
      color: colorVariables.grey60,
      fontSize: 12,
      fontWeight: 400,
    },
    msgCol: {
      width: '38%',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    rowCol: {
      width: '5%',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    boxContent: {
      height: 205,
      overflowY: 'scroll',
    },
    rowContent: {
      fontSize: 12,
      color: colorVariables.grey60,
      padding: 5,
      width: '100%',
      display: 'flex',
      backgroundColor: 'rgba(152, 165, 183, 0.05)',
    },
    rowContentError: {
      backgroundColor: 'rgba(252, 217, 217, 0.15)',
    },
    rowCollCentered: {
      paddingLeft: 3,
      paddingRight: 3,
      textAlign: 'right',
    },
  }),
);
