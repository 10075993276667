import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';

export const useStyles = makeStyles(() =>
  createStyles({
    addBtn: {
      width: '100%',
      height: 75,
      borderRadius: 8,
      border: `2px dashed ${colorVariables.green}`,
      marginTop: 20,
      padding: '14px 20px',
      alignItems: 'center',
      '& button': {
        boxShadow: 'none !important',
        width: 47,
        height: 47,
        '& svg': {
          fontSize: 36,
        },
      },
    },
    addBtnText: {
      textTransform: 'uppercase',
      fontWeight: 700,
      marginLeft: 18,
    },
  }),
);
