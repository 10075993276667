import * as React from 'react';

function SvgReportIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M3.636 20a1.818 1.818 0 001.819-1.818v-7.273a1.818 1.818 0 00-1.819-1.818H1.818A1.818 1.818 0 000 10.909v7.273C0 19.186.814 20 1.818 20h1.818zm-1.818-9.09h1.818v7.272H1.818v-7.273zM10.91 20a1.818 1.818 0 001.817-1.818V1.818A1.818 1.818 0 0010.91 0H9.091a1.818 1.818 0 00-1.818 1.818v16.364c0 1.004.814 1.818 1.818 1.818h1.818zM9.09 1.818h1.82v16.364H9.09V1.818zM18.182 20A1.818 1.818 0 0020 18.182V7.272a1.818 1.818 0 00-1.818-1.817h-1.818a1.818 1.818 0 00-1.819 1.818v10.909c0 1.004.815 1.818 1.819 1.818h1.818zM16.364 7.273h1.818v10.909h-1.818V7.272z'
        fill='currentColor'
      />
    </svg>
  );
}

export default SvgReportIcon;
