export class SessionStorageService {
  static setItem(key: string, value: any) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  static getItem(key: string) {
    try {
      const value = sessionStorage.getItem(key);
      return value && JSON.parse(value);
    } catch (e) {
      console.log(e);
    }
  }

  static clear(key: string) {
    sessionStorage.removeItem(key);
  }

  static clearAll() {
    sessionStorage.clear();
  }
}
