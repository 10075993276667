import React from 'react';

import clsx from 'clsx';

import { AddButton } from '../add-button';

import { useRippleStyles } from '../../../styles/customRipple';
import { useStyles } from './style';

interface Props {
  title: string;
  onClick?: () => void;
}

export const AddButtonDashed: React.FC<Props> = React.memo(({ onClick, title }) => {
  const classes = useStyles();
  const rippleClass = useRippleStyles();

  return (
    <div className={clsx(classes.addBtn, rippleClass.ripple)} onClick={onClick}>
      <AddButton />
      <span className={classes.addBtnText}>{title}</span>
    </div>
  );
});
