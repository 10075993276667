import React, { memo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { IconButton } from '@material-ui/core';
import LinesEllipsis from 'react-lines-ellipsis';

import { useDeletePantryListMutation } from '../../../../../../api/pantry-list';
import { PantryList, SupplierInPantryList } from '../../../../../../api/pantry-list/types';
import { useGeIsTeammateViewer } from '../../../../../../api/teammates/hooks';
import SvgEditIcon from '../../../../../../assets/icons/EditIcon';
import SvgTrash from '../../../../../../assets/icons/Trash';
import { ThemedButton } from '../../../../../../shared/components/themed-button';
import { Dialog } from '../../../../../../shared/components/dialog';
import { useScreenSize } from '../../../../../../shared/hooks/use-screen-size';
import { ToastService } from '../../../../../../shared/services/toastService';
import { useAppDispatch } from '../../../../../../store';
import { setSelectedPantryListId } from '../../../../../../store/pantry-lists';

import { colorVariables } from '../../../../../../styles/colorVariables';
import { useStyles } from './style';

interface Props {
  item: PantryList;
}

const Component: React.FC<Props> = ({ item }) => {
  const classes = useStyles();
  const { isMobile } = useScreenSize();
  const { push } = useHistory();
  const dispatch = useAppDispatch();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [deletePantryList, { isLoading }] = useDeletePantryListMutation();
  const isViewer = useGeIsTeammateViewer('pantry_lists');

  const suppliers =
    item.suppliers_company.reduce<SupplierInPantryList[]>((acc, el, idx, arr) => {
      const index = arr.findIndex((arrItem) => arrItem.id === el.id);
      if (index === idx) {
        acc.push(el);
      }
      return acc;
    }, []) || [];

  const openDialog = () => {
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const openDetails = () => {
    push('/pantry_list/details', item.id);
  };

  const onEdit = () => {
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    dispatch(setSelectedPantryListId(item.id));
    push({
      pathname: '/pantry_list/edit',
      state: {
        pantryListId: item.id,
      },
    });
  };

  const onDelete = () => {
    deletePantryList(item.id);
    closeDialog();
  };

  return (
    <div className={classes.box}>
      <h4 className={classes.title}>
        <LinesEllipsis text={item.name} ellipsis='...' maxLine={2} />
      </h4>
      <div className={classes.itemsCountBox}>
        items <span>{item.items_total}</span>
      </div>
      <div className={classes.suppliersBox}>
        {suppliers.slice(0, isMobile ? 5 : 12).map(({ id, picture_url, name }) => (
          <div className={classes.supplierLogo} key={id}>
            {!!picture_url && <img src={picture_url} alt={name} />}
          </div>
        ))}
        {suppliers.length > (isMobile ? 5 : 12) && <span>...</span>}
      </div>
      <div className={classes.actionsBox}>
        {isMobile ? (
          <div className={classes.iconBtnsWrap}>
            <IconButton className={classes.iconBtn} onClick={openDialog}>
              <SvgTrash width={19} height={19} color={colorVariables.navy} />
            </IconButton>
            <IconButton className={classes.iconBtn} onClick={onEdit}>
              <SvgEditIcon width={16} height={16} color={colorVariables.navy} />
            </IconButton>
          </div>
        ) : (
          <div>
            <ThemedButton
              title='Delete'
              onClick={openDialog}
              width={136}
              buttonStyle='secondary'
              customClass={classes.btn}
              disabled={isLoading}
              startIcon={<SvgTrash width={19} height={19} color={colorVariables.navy} />}
            />
            <ThemedButton
              title='Edit'
              onClick={onEdit}
              width={136}
              buttonStyle='secondary'
              customClass={classes.btn}
              startIcon={<SvgEditIcon width={16} height={16} color={colorVariables.navy} />}
            />
          </div>
        )}
        <ThemedButton title='View' onClick={openDetails} width={isMobile ? 130 : 280} customClass={classes.btn} />
      </div>
      {isDialogOpen && (
        <Dialog
          title={`Are you sure you want to remove the '${item.name}' from Pantry List`}
          onCancel={closeDialog}
          onConfirm={onDelete}
          customClass={classes.dialog}
        />
      )}
    </div>
  );
};

export const PantryListItem = memo(Component);
