import * as React from 'react';

function SvgProductIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M17.496 0h-6.844C9.984 0 9.358.25 8.9.751L.845 8.764c-1.127 1.126-1.127 3.004 0 4.131l6.218 6.26c1.127 1.127 3.005 1.127 4.132 0l8.054-8.054c.459-.46.751-1.085.751-1.753V2.504A2.511 2.511 0 0017.496 0zm.835 9.306a.824.824 0 01-.25.584l-8.055 8.096c-.5.501-1.294.501-1.753 0l-6.26-6.218c-.5-.5-.5-1.293 0-1.752l8.055-8.055a.824.824 0 01.584-.25h6.844c.46 0 .835.376.835.835v6.76z'
        fill='currentColor'
      />
      <path
        d='M14.575 4.173a1.23 1.23 0 00-1.252 1.252c0 .71.542 1.252 1.252 1.252a1.23 1.23 0 001.252-1.252 1.23 1.23 0 00-1.252-1.252z'
        fill='currentColor'
      />
    </svg>
  );
}

export default SvgProductIcon;
