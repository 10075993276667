import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../styles/colorVariables';
import { Sizes } from '../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      paddingTop: 40,
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down(Sizes.desktop)]: {
        paddingTop: 0,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        paddingTop: 20,
      },
    },
    addPaymentBtn: {
      marginLeft: 'auto',
      [theme.breakpoints.down(Sizes.desktop)]: {
        display: 'none',
      },
    },
    paymentMethodsList: {
      paddingTop: 30,
      [theme.breakpoints.down(Sizes.desktop)]: {
        paddingTop: 0,
      },
    },
    paymentMethod: {
      fontSize: 16,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '20px 0',
      marginBottom: 10,
      borderBottom: `1px solid ${colorVariables.steelGrey}`,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    paymentMethodContent: {},
    paymentMethodName: {
      fontSize: 16,
      lineHeight: '24px',
    },
    paymentMethodInfo: {
      display: 'flex',
    },
    greyText: {
      color: colorVariables.grey60,
      fontSize: 12,
      marginRight: 25,
    },
    defaultBadge: {
      display: 'inline-block',
      fontSize: 11,
      textTransform: 'uppercase',
      color: colorVariables.grey80,
      fontWeight: 600,
      width: 74,
      height: 24,
      lineHeight: '24px',
      borderRadius: 12,
      textAlign: 'center',
      border: `1px solid ${colorVariables.steelGrey}`,
    },
    addPaymentMobile: {
      display: 'none',
      [theme.breakpoints.down(Sizes.desktop)]: {
        display: 'flex',
        marginBottom: 10,
      },
    },
    mobileDivider: {
      display: 'none',
      [theme.breakpoints.down(Sizes.desktop)]: {
        display: 'block',
        height: 1,
        position: 'absolute',
        left: 0,
        right: 0,
        backgroundColor: colorVariables.steelGrey,
        top: 155,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        top: 195,
      },
    },
    head: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 20,
      alignItems: 'center',
      [theme.breakpoints.down(Sizes.mobile)]: {},
    },
    methodTypes: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderRadius: 8,
      padding: '10px 10px 10px 20px',
      backgroundColor: 'rgba(207, 215, 230, 0.3)',
      [theme.breakpoints.down(Sizes.mobile)]: {
        flexDirection: 'column',
        alignItems: 'center',
        padding: 10,
        marginBottom: 20,
        textAlign: 'center',
      },
    },
    radioRootPlan: {
      flexDirection: 'row',
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginTop: 5,
      },
    },
    checkedBox: {
      '& .MuiFormControlLabel-label': {
        color: colorVariables.navy,
      },
    },
    checkedBoxPlan: {
      '& .MuiFormControlLabel-label': {
        color: colorVariables.navy,
      },
      '&>div:last-child': {
        color: colorVariables.grey80,
      },
    },
    radio: {
      color: colorVariables.grey60,
    },
    checked: {
      color: colorVariables.navy,
      '&+span': {
        color: `${colorVariables.navy} !important`,
      },
    },
    radioWrap: {
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      '& .MuiFormControlLabel-label': {
        fontSize: 11,
        textTransform: 'uppercase',
        fontWeight: 700,
        color: colorVariables.grey60,
      },
    },
    radioWrapPlan: {
      position: 'relative',
      '& .MuiFormControlLabel-label': {
        fontSize: 11,
        textTransform: 'uppercase',
        fontWeight: 700,
        color: colorVariables.grey60,
      },
    },
    headtitle: {
      fontWeight: 700,
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    deletePaymentMethod: {
      marginRight: 10,
      marginLeft: 10,
      cursor: 'pointer',
      flexShrink: 0,
      '&:hover': {
        opacity: 0.8,
      },
    },
    dialog: {
      position: 'fixed',
      top: '30%',
      right: 100,
      [theme.breakpoints.down(Sizes.mobile)]: {
        right: 20,
      },
    },
    action: {
      width: 140,
      flexShrink: 0,
      marginLeft: 'auto',
      display: 'flex',
      justifyContent: 'center',
      marginRight: 20,
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginRight: 0,
      },
    },
  }),
);
