import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../styles/colorVariables';
import { Sizes } from '../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    row: {
      display: 'flex',
      padding: '24px 12px',
      borderBottom: `1px solid ${colorVariables.steelGrey}`,
      position: 'relative',
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: 16,
      },
    },
    cell: {
      paddingRight: 5,
    },
    name: {
      width: '50%',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '45%',
      },
    },
    lastActive: {
      width: '20%',
      color: colorVariables.grey60,
      paddingTop: 8,
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    permission: {
      width: '25%',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'flex',
        justifyContent: 'center',
        width: '45%',
      },
    },
    actions: {
      width: '5%',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '10%',
      },
    },
    createdDate: {
      fontSize: 12,
      color: colorVariables.grey60,
      marginTop: 3,
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    nameText: {
      fontWeight: 600,
      marginBottom: 3,
    },
    emailText: {
      color: colorVariables.blueGrey,
      fontSize: 12,
    },
    moreEllipsis: {
      '& svg': {
        color: colorVariables.steelGrey,
        '&:hover': {
          color: colorVariables.grey60,
        },
      },
    },
    dialog: {
      right: 0,
      top: 0,
      width: 300,
    },
  }),
);
