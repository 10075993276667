import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { Form, Formik, FormikHelpers } from 'formik';

import { useGetSupplierListQuery } from '../../../../api/supplier';
import SvgFilterIconCustomer from '../../../../assets/icons/FilterIconCustomer';
import { AddButton } from '../../../../shared/components/add-button';
import { ThemedButton } from '../../../../shared/components/themed-button';
import { CommonChip } from '../../../../shared/components/common-chip';
import { FilterByCategory } from '../../../../shared/components/filter-by-category';
import { FormikSortDropdown } from '../../../../shared/components/formik-sort-dropdown';
import { OpLoading } from '../../../../shared/components/op-loading';
import { SearchField } from '../../../../shared/components/search-field';
import { ProductsCategories } from '../../../../shared/constants/products';
import { getCategoryId } from '../../../../shared/helpers/getCategoryId';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { getSelectedCategory, getSupplierKeyword, setSelectedCategory, setSupplierKeyword } from '../../../../store/suppliers-categories';
import { setMySuppliersSort } from '../../../../store/suppliers-categories/suppliers-categories.actions';
import { getSuppliersSort } from '../../../../store/suppliers-categories/suppliers-categories.selectors';
import { colorVariables } from '../../../../styles/colorVariables';
import { useRippleStyles } from '../../../../styles/customRipple';
import { FilterByCategoryMobile } from '../common/filter-by-category-mobile';
import { NoSuppliers } from '../common/no-suppliers';
import { SupplierItem } from '../common/supplier-item';

import { useStyles } from './style';

interface Props {
  handleClick: (event: React.MouseEvent<any>) => void;
  openAddSupplier: () => void;
}

export const mySupplierSortOptions = [
  { label: 'Most Purchased', value: 'most_purchased desc' },
  { label: 'Company name (A-Z)', value: 'company_name asc' },
  { label: 'Company name (Z-A)', value: 'company_name desc' },
];

export const SuppliersTab: React.FC<Props> = ({ handleClick, openAddSupplier }) => {
  const classes = useStyles();
  const rippleClasses = useRippleStyles();
  const { push } = useHistory();
  const dispatch = useAppDispatch();
  const [isModalOpened, setIsModalOpened] = useState(false);

  const keyword = useAppSelector(getSupplierKeyword);
  const selectedCategory = useAppSelector(getSelectedCategory);
  const { mySuppliersSort } = useAppSelector(getSuppliersSort);

  const { connectedSuppliers, connectedLoading, connectedSuccess } = useGetSupplierListQuery(
    {
      ...(selectedCategory ? { category_id: getCategoryId(selectedCategory) } : {}),
      keyword,
      per_page: 200,
      sort_by: mySuppliersSort,
    },
    {
      selectFromResult: ({ data, isFetching, isSuccess }) => ({
        connectedSuppliers: data?.suppliers || [],
        connectedLoading: isFetching,
        connectedSuccess: isSuccess,
      }),
    },
  );

  const { pendingSuppliers, pendingLoading, pendingSuccess } = useGetSupplierListQuery(
    {
      ...(selectedCategory ? { category_id: getCategoryId(selectedCategory) } : {}),
      pending_supplier: true,
      keyword,
      per_page: 200,
      sort_by: mySuppliersSort,
    },
    {
      selectFromResult: ({ data, isFetching, isSuccess }) => ({
        pendingSuppliers: data?.suppliers || [],
        pendingLoading: isFetching,
        pendingSuccess: isSuccess,
      }),
    },
  );

  const suppliers = useMemo(() => [...connectedSuppliers, ...pendingSuppliers], [connectedSuppliers, pendingSuppliers]);

  const addSupplier = () => {
    push('/account/suppliers/add');
  };

  const setCategory = (category: ProductsCategories | null) => {
    dispatch(setSelectedCategory(category));
  };

  const toggleModal = () => {
    setIsModalOpened((prev) => !prev);
  };

  const onRemoveCategory = () => {
    dispatch(setSelectedCategory(null));
  };

  const onSubmitSort = (values: { sort: string }, formikHelpers: FormikHelpers<any>) => {
    formikHelpers.setSubmitting(false);
    dispatch(setMySuppliersSort(values.sort));
  };

  const onSearchSupplier = (searchString: string) => {
    dispatch(setSupplierKeyword(searchString));
  };

  useEffect(() => {
    dispatch(setSelectedCategory(null));
  }, []);

  return (
    <div>
      <div className={classes.searchFilterBox}>
        <div className={classes.searchWrap}>
          <SearchField placeholder='Search supplier' onChange={onSearchSupplier} searchValue={keyword} />
        </div>
        <div className={classes.filterIconMobile}>
          <IconButton className={classes.iconButton} onClick={toggleModal}>
            <SvgFilterIconCustomer width={22} height={22} color={selectedCategory ? colorVariables.green : colorVariables.userNavy} />
          </IconButton>
        </div>
        <div className={classes.filterBlock}>
          <div className={clsx(classes.filterWrap, selectedCategory && classes.filterWrapCategorySelected)}>
            <div className={classes.filterTitle}>Category</div>
            <FilterByCategory selectedCategory={selectedCategory} onApply={setCategory} />
          </div>
          <div className={classes.filterWrap}>
            <div className={classes.sortTitle}>Sort by</div>
            <Formik onSubmit={onSubmitSort} enableReinitialize={true} initialValues={{ sort: mySuppliersSort || 'most_purchased desc' }}>
              <Form>
                <FormikSortDropdown name='sort' options={mySupplierSortOptions} submitOnChange={true} />
              </Form>
            </Formik>
          </div>
        </div>
        {selectedCategory && (
          <div className={classes.chipsBox}>
            <CommonChip title={selectedCategory} onClick={onRemoveCategory} />
          </div>
        )}
      </div>
      <div className={classes.suppliersBox}>
        {keyword && suppliers.length === 0 && !connectedLoading && !pendingLoading ? (
          <div className={classes.noResWrap}>
            <div className={clsx(rippleClasses.ripple, classes.supplierWrap, classes.addSupplier)} onClick={openAddSupplier}>
              <AddButton />
              <div>
                <div className={classes.addSupplierText}>Invite Your Supplier</div>
                <div className={classes.addSubText}>Its free for them too</div>
              </div>
            </div>
            <NoSuppliers onInvite={openAddSupplier} />
          </div>
        ) : suppliers.length === 0 && !connectedLoading && !pendingLoading ? (
          <div className={classes.emptyWrap}>
            <h3 className={classes.emptyTitle}>
              {!selectedCategory ? `You haven't added suppliers yet` : `You haven't added suppliers for this category yet`}
            </h3>
            <p className={classes.emptyText}>Go to the 'Add Supplier' page to find suppliers or use the button</p>
            <ThemedButton onClick={addSupplier} title='Add Supplier' />
          </div>
        ) : (
          <OpLoading
            loading={connectedLoading || pendingLoading}
            dataLoaded={pendingSuccess && connectedSuccess}
            resetProgress={false}
            boxClasses={classes.loadingBox}
          >
            <>
              {suppliers.map((item) => (
                <div key={`${item.id}-${item.company?.id}`} className={classes.supplierWrap}>
                  <SupplierItem supplier={item} handleClick={handleClick} productsAvailable={true} isSuppliersListPage={true} />
                </div>
              ))}
            </>
          </OpLoading>
        )}
      </div>
      <FilterByCategoryMobile isOpened={isModalOpened} closeModal={toggleModal} />
    </div>
  );
};
