import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../styles/colorVariables';
import { Sizes } from '../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    accountContainer: {
      paddingTop: 63,
      backgroundColor: colorVariables.white,
      [theme.breakpoints.down(Sizes.desktop)]: {
        padding: '70px 0 0 94px',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: '110px 0 80px 0',
      },
    },
    accountContainerCustomer: {
      [theme.breakpoints.between(Sizes.mobile, Sizes.desktop)]: {
        padding: '50px 0 0 0',
      },
    },
    accountHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      backgroundColor: colorVariables.white,
      height: 127,
      borderBottom: `1px solid ${colorVariables.steelGrey}`,
      paddingBottom: 30,
      paddingLeft: 105,
      paddingRight: 105,
      [theme.breakpoints.down(Sizes.desktop)]: {
        display: 'none',
      },
    },
    accountTitle: {
      fontSize: 32,
      lineHeight: '50px',
      fontWeight: 700,
    },
    accountBody: {
      display: 'flex',
    },
    accountContent: {
      width: '80%',
      padding: '25px 200px 50px 50px',
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: '100%',
        padding: '40px 50px',
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        padding: 40,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: 24,
        paddingTop: 0,
      },
    },
    tabletTitleCustomer: {
      paddingBottom: 20,
      marginBottom: 30,
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        width: '100vw',
        height: 1,
        right: 0,
        left: 0,
        transform: 'translateY(20px)',
        backgroundColor: colorVariables.steelGrey,
      },
    },
    titleBox: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    title: {
      fontSize: 24,
      lineHeight: '36px',
      fontWeight: 700,
      marginBottom: 30,
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    formBox: {
      width: '100%',
      marginTop: 30,
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginTop: 10,
      },
    },
    fullWidthFieldWrap: {
      marginBottom: 15,
      width: '100%',
    },
    fieldsBox: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    fieldWrap: {
      marginBottom: 15,
      width: '48%',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
      },
    },
    submitBtn: {
      marginTop: 30,
      marginBottom: 40,
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginBottom: 20,
        marginTop: 20,
      },
    },
    btnBlockMobile: {
      display: 'none',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'flex',
        paddingTop: 10,
      },
    },
    setDefaultBtn: {
      backgroundColor: colorVariables.blue,
      '&:hover': {
        backgroundColor: colorVariables.blue,
      },
      '&:active': {
        backgroundColor: colorVariables.blue,
        borderColor: colorVariables.blueLight,
      },
      '&:disabled': {
        backgroundColor: colorVariables.steelGrey,
        color: colorVariables.white,
      },
    },

    smallFieldsBox: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      marginBottom: 20,
      width: '100%',
    },
    centeredWrap: {
      width: '48%',
      marginBottom: 15,
      [theme.breakpoints.down(Sizes.desktop)]: {
        marginBottom: 10,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        marginBottom: 20,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        marginBottom: 15,
      },
    },
    smallField: {
      width: '24%',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        marginBottom: 15,
      },
    },
  }),
);
