import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';
import { Sizes } from '../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    accountMenu: {
      width: '20%',
      paddingLeft: 30,
      paddingTop: 37,
      [theme.breakpoints.down(1405)]: {
        paddingLeft: 0,
      },
      [theme.breakpoints.down(Sizes.desktop)]: {
        display: 'none',
      },
    },
    backLink: {
      display: 'inline-flex',
      alignItems: 'center',
      color: colorVariables.navy,
      fontSize: 14,
      fontWeight: 600,
      marginBottom: 32,
      paddingLeft: 20,
      '&:hover, &:hover *': {
        color: colorVariables.grey60,
      },
      [theme.breakpoints.down(Sizes.desktop)]: {
        display: 'none',
      },
    },
    backChevron: {
      fontSize: 18,
      marginRight: 8,
      color: colorVariables.darkGrey,
    },
    menuLink: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: 16,
      color: colorVariables.navy,
      padding: '12px 24px',
      backgroundColor: colorVariables.white,
      marginBottom: 1,
      '&:hover': {
        borderRadius: 6,
        backgroundColor: colorVariables.blueLight,
        opacity: 0.6,
        color: colorVariables.blue,
      },
      [theme.breakpoints.down(Sizes.desktop)]: {
        fontSize: 14,
      },
    },
    disabled: {
      cursor: 'default',
      color: colorVariables.grey60,
      '&:hover': {
        backgroundColor: colorVariables.white,
        opacity: 1,
        color: colorVariables.grey60,
      },
    },
    menuLinkActive: {
      backgroundColor: colorVariables.blueLight,
      color: colorVariables.blue,
      borderRadius: 6,
    },
    lockIcon: {
      color: colorVariables.grey60,
      cursor: 'pointer',
    },
    lockIconWrap: {
      height: 20,
      width: 40,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);
