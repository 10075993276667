import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';

import { LocalStorageService } from '../shared/services/localStorage.service';
import { setAppErrorToast } from './user';

export const rtkQueryErrorLogger: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    const excludedEndpoints = [
      'login',
      'signUp',
      'fetchSocialContacts',
      'fetchSocialOutlookContacts',
      'acceptConnectionRequest',
      'verifySupplier',
      'updateQuantityInCart',
      'getAutoDiscount',
      'getCurrentTheme',
    ];
    if (isRejectedWithValue(action)) {
      console.error('RTK Middleware: rejected', action.payload?.status);
      if (
        action.payload?.status === 'FETCH_ERROR' &&
        (action.meta.arg.endpointName === 'me' ||
          action.meta.arg.endpointName === 'login' ||
          action.meta.arg.endpointName === 'signUp' ||
          action.meta.arg.endpointName === 'googleLogin') &&
        !LocalStorageService.getItem('outlook_fetching')
      ) {
        dispatch(setAppErrorToast(`Hmmmm it looks like something went wrong. Please refresh your browser or try again later [500]`));
      } else if (action.payload?.status === 500 && action.meta.arg.endpointName !== 'updateQuantityInCart') {
        dispatch(setAppErrorToast(`Hmmmm it looks like something went wrong. Please refresh your browser or try again later [500]`));
      } else if (!excludedEndpoints.includes(action.meta.arg.endpointName)) {
        dispatch(setAppErrorToast(action.payload?.data?.message || ''));
      }
    }
    return next(action);
  };
