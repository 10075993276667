import React from 'react';
import { useHistory } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';

import SvgBackArrow from '../../../assets/icons/BackArrow';

import { useStyles } from './style';

interface Props {
  title: string;
  showBackBtn?: boolean;
  classNames?: string;
  backHandler?: () => void;
}

export const NavbarTitle: React.FC<Props> = ({ title, showBackBtn, classNames = '', backHandler }) => {
  const classes = useStyles();
  const { goBack } = useHistory();

  return (
    <div className={clsx([classes.root, classNames])}>
      {showBackBtn && (
        <IconButton className={classes.backArrow} onClick={backHandler ? backHandler : goBack}>
          <SvgBackArrow height={18} width={18} />
        </IconButton>
      )}
      <div className={classes.title}>{title}</div>
    </div>
  );
};
