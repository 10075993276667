import { createAction } from '@reduxjs/toolkit';

import { ProductFilterValues, ProductsSortBy } from '../../api/product/types';

export const setProductsSortBy = createAction<ProductsSortBy | undefined>('SET_PRODUCTS_SORT_BY');

export const setProductsFilterBy = createAction<ProductFilterValues | undefined>('SET_PRODUCTS_FILTER_BY');

export const setProductsPage = createAction<number>('SET_PRODUCTS_PAGE');

export const setLastAddedProductId = createAction<number | undefined>('SET_LAST_ADDED_PRODUCT_ID');

export const setProductsKeyword = createAction<{ keyword?: string }>('SET_PRODUCTS_KEYWORD');

export const setProductsByCategoryKeyword = createAction<{ keyword?: string }>('SET_PRODUCTS_BY_CATEGORY_KEYWORD');

export const clearProductsList = createAction('CLEAR_PRODUCTS_LIST');

export const setPriceListActionsDisabled = createAction<boolean>('SET_PRICE_LIST_ACTIONS_DISABLED');

export const setImportProductsLoading = createAction<boolean>('SET_IMPORT_PRODUCTS_LOADING');

export const setAddPriceListLoading = createAction<boolean>('SET_ADD_PRICE_LIST_LOADING');

export const setDeletePriceListMode = createAction<boolean>('SET_DELETE_PRICE_LIST_MODE');
