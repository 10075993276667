import { SessionStorageService } from '../../shared/services/sessionStorage.service';
import { setCostPlans } from '../../store/user/user.actions';
import remove from 'lodash/remove';
import { setSuccessToast } from '../helpers';
import { HTTP, rootApi, SuccessResponse } from '../index';
import { CreateTaxRateReq, GetCountriesDataResponse, UpdateTaxRateReq } from './types';
import { euCountriesList } from '../../shared/constants/countries';

export const adminCountriesApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getCountriesData: builder.query<GetCountriesDataResponse, void>({
      query() {
        return {
          url: `/admin/countries/get_country`,
          method: HTTP.GET,
        };
      },
      providesTags: ['Country_Data'],
    }),

    getCountriesDataForUser: builder.query<GetCountriesDataResponse, string>({
      query(code) {
        const countryCode = euCountriesList.some(({ value }) => value === code.toUpperCase()) ? 'eu' : code.toLowerCase();
        return {
          url: `/admin/countries/get_country`,
          method: HTTP.GET,
          headers: {
            'Country-Code': countryCode,
            Authorization: null,
          },
        };
      },
      async onQueryStarted(args, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.country) {
            SessionStorageService.setItem('country_currency', data?.country);
          }
          if (Array.isArray(data?.country?.plans)) {
            dispatch(setCostPlans(data.country.plans));
          }
        } catch (e) {
          console.log(e);
        }
      },
    }),

    updateCountryCurrency: builder.mutation<SuccessResponse, string>({
      query(currency) {
        return {
          url: `/admin/countries/update_currency`,
          method: HTTP.PATCH,
          body: {
            currency,
          },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        let patchResult;

        try {
          patchResult = dispatch(
            adminCountriesApi.util.updateQueryData('getCountriesData', undefined, (draft) => {
              draft.country.currency = args;
            }),
          );
          await setSuccessToast(dispatch, queryFulfilled, 'Currency has been updated');
        } catch {
          patchResult?.undo();
        }
      },
      invalidatesTags: ['Country_Data', 'Me'],
    }),

    changeSpecificCurrency: builder.mutation<SuccessResponse, void>({
      query() {
        return {
          url: `/admin/countries/update_specific_currency`,
          method: HTTP.PATCH,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        let patchResult;
        try {
          patchResult = dispatch(
            adminCountriesApi.util.updateQueryData('getCountriesData', undefined, (draft) => {
              draft.country.specific_country = !draft.country?.specific_country;
            }),
          );
          await queryFulfilled;
        } catch {
          patchResult?.undo();
        }
      },
      invalidatesTags: ['Country_Data'],
    }),

    changeTaxRate: builder.mutation<SuccessResponse, void>({
      query() {
        return {
          url: `/admin/countries/update_tax_rate`,
          method: HTTP.PATCH,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        let patchResult;
        try {
          patchResult = dispatch(
            adminCountriesApi.util.updateQueryData('getCountriesData', undefined, (draft) => {
              draft.country.tx_rates = !draft.country?.tx_rates;
            }),
          );
          await queryFulfilled;
        } catch {
          patchResult?.undo();
        }
      },
      invalidatesTags: ['Country_Data'],
    }),

    addSpecificCurrency: builder.mutation<SuccessResponse, { name: string; currency: string; tax_name?: string }>({
      query(sub_country) {
        return {
          url: `/admin/countries/add_specific_country`,
          method: HTTP.POST,
          body: {
            sub_country,
          },
        };
      },
      invalidatesTags: ['Country_Data'],
    }),

    addTaxRate: builder.mutation<SuccessResponse, CreateTaxRateReq>({
      query(tax_rate) {
        return {
          url: `/admin//tax_rates`,
          method: HTTP.POST,
          body: {
            tax_rate,
          },
        };
      },
      invalidatesTags: ['Country_Data'],
    }),

    updateTaxRate: builder.mutation<SuccessResponse, UpdateTaxRateReq>({
      query({ id, ...tax_rate }) {
        return {
          url: `/admin//tax_rates/${id}`,
          method: HTTP.PATCH,
          body: {
            tax_rate,
          },
        };
      },
      invalidatesTags: ['Country_Data'],
    }),

    deleteTaxRate: builder.mutation<SuccessResponse, number>({
      query(tax_rate) {
        return {
          url: `/admin//tax_rates/${tax_rate}`,
          method: HTTP.DELETE,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        let patchResult;
        try {
          patchResult = dispatch(
            adminCountriesApi.util.updateQueryData('getCountriesData', undefined, (draft) => {
              remove(draft.country.tax_rates, (el) => el.id === args);
            }),
          );
          await setSuccessToast(dispatch, queryFulfilled, 'Tax info have been deleted');
        } catch {
          patchResult?.undo();
        }
      },
      invalidatesTags: ['Country_Data'],
    }),

    editSpecificCurrency: builder.mutation<
      SuccessResponse,
      {
        sub_country_id: number;
        name: string;
        currency: string;
        tax_name?: string;
      }
    >({
      query({ sub_country_id, ...sub_country }) {
        return {
          url: `/admin/countries/update_sub_country`,
          method: HTTP.PATCH,
          body: {
            sub_country_id,
            sub_country,
          },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        let patchResult;
        try {
          patchResult = dispatch(
            adminCountriesApi.util.updateQueryData('getCountriesData', undefined, (draft) => {
              const idx = draft.country.sub_countries.findIndex((el) => el.id === args.sub_country_id);
              if (idx !== -1) {
                draft.country.sub_countries[idx].tax_name = args.tax_name || null;
                draft.country.sub_countries[idx].name = args.name;
                draft.country.sub_countries[idx].currency = args.currency;
              }
            }),
          );
          await setSuccessToast(dispatch, queryFulfilled, 'Country has been updated');
        } catch {
          patchResult?.undo();
        }
      },
      invalidatesTags: ['Country_Data'],
    }),

    deleteSubCountry: builder.mutation<SuccessResponse, number>({
      query(sub_country_id) {
        return {
          url: `/admin/countries/delete_sub_country`,
          method: HTTP.DELETE,
          body: {
            sub_country_id,
          },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        let patchResult;
        try {
          patchResult = dispatch(
            adminCountriesApi.util.updateQueryData('getCountriesData', undefined, (draft) => {
              remove(draft.country.sub_countries, (el) => el.id === args);
            }),
          );
          await setSuccessToast(dispatch, queryFulfilled, 'Country has been deleted');
        } catch {
          patchResult?.undo();
        }
      },
      invalidatesTags: ['Country_Data'],
    }),

    updatePlanCost: builder.mutation<SuccessResponse, { plan_id: number; plan_item_id: number; price_cents: number }>({
      query(body) {
        return {
          url: `/admin/countries/update_plan_cost`,
          method: HTTP.PATCH,
          body,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'Plan cost updated');
      },
      invalidatesTags: ['Country_Data'],
    }),
  }),

  overrideExisting: true,
});

export const {
  useGetCountriesDataQuery,
  useUpdateCountryCurrencyMutation,
  useChangeSpecificCurrencyMutation,
  useAddSpecificCurrencyMutation,
  useChangeTaxRateMutation,
  useDeleteSubCountryMutation,
  useEditSpecificCurrencyMutation,
  useUpdatePlanCostMutation,
  useAddTaxRateMutation,
  useDeleteTaxRateMutation,
  useUpdateTaxRateMutation,
  useGetCountriesDataForUserQuery,
} = adminCountriesApi;
