import { createStyles, makeStyles } from '@material-ui/core';
import { colorVariables } from '../../../../styles/colorVariables';
import { Sizes } from '../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: 'relative',
      paddingBottom: 80,
      width: 860,
      margin: '0 auto',
      [theme.breakpoints.down(Sizes.desktop)]: {
        paddingBottom: 40,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        width: '100%',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        paddingBottom: 30,
        marginTop: -50,
      },
    },
    title: {
      marginTop: 15,
      marginBottom: 25,
      fontSize: 28,
      lineHeight: '50px',
      fontWeight: 700,
      '& span': {
        fontWeight: 400,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    icon: {
      position: 'absolute',
      top: 10,
      right: 10,
      [theme.breakpoints.down(Sizes.mobile)]: {
        top: 0,
        right: 0,
      },
    },
    searchWrap: {
      width: 820,
      margin: '40px auto',
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: 760,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        width: '100%',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginTop: 20,
      },
    },
    tilesBox: {},
    tileRow: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      marginBottom: 40,
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'block',
      },
    },
    tileRowBlock: {
      display: 'block',
      width: '45%',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
      },
    },
    rowTitle: {
      width: '100%',
      fontSize: 12,
      lineHeight: '22px',
      textTransform: 'uppercase',
      color: colorVariables.grey40,
      fontWeight: 700,
      marginBottom: -5,
    },
    rowItem: {
      paddingTop: 24,
      width: '45%',
      display: 'flex',
      justifyContent: 'space-between',
      color: 'inherit',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        paddingTop: 23,
        paddingBottom: 23,
        borderBottom: `1px solid ${colorVariables.steelGrey}`,
      },
    },
    rowItemFullWidth: {
      width: '100%',
    },
    tileTitle: {
      fontSize: 16,
      fontWeight: 600,
      cursor: 'pointer',
      lineHeight: '22px',
      paddingRight: 15,
      maxWidth: 300,
    },
    greyTile: {
      backgroundColor: 'rgba(152, 165, 183, 0.15)',
    },
    link: {},
    mobileHead: {
      display: 'none',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'flex',
        marginBottom: 30,
      },
    },
    mobileTitle: {
      fontWeight: 700,
      fontSize: 20,
      lineHeight: '40px',
      '& span': {
        fontWeight: 400,
      },
    },
    marked: {
      fontWeight: 700,
      background: 'transparent !important',
    },
    tilesWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'block',
      },
    },
  }),
);
