import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';

export const useStyles = makeStyles(() =>
  createStyles({
    box: {
      position: 'relative',
      width: '100%',
      height: '100%',
      flexShrink: 0,
      zIndex: 0,
    },
    fullImage: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      zIndex: 0,
    },
    thumbnail: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      backgroundColor: colorVariables.white,
      zIndex: -1,
    },
  }),
);
