import { createStyles, makeStyles } from '@material-ui/core';
import { Sizes } from '../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    formBox: {
      paddingTop: 20,
      display: 'flex',
      width: 600,
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down(Sizes.tablet)]: {
        width: 500,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        maxWidth: '100%',
      },
    },
    fieldWrap: {
      width: '100%',
      marginBottom: 15,
      [theme.breakpoints.down(Sizes.desktop)]: {
        marginBottom: 10,
      },
    },
    smallFieldsBox: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      marginBottom: 20,
      width: '100%',
    },
    smallFieldWrap: {
      width: '48%',
      marginBottom: 15,
      [theme.breakpoints.down(Sizes.desktop)]: {
        marginBottom: 10,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        marginBottom: 20,
      },
    },
    btnBlock: {
      paddingTop: 20,
      alignSelf: 'flex-start',
      display: 'flex',
      [theme.breakpoints.down(Sizes.mobile)]: {
        justifyContent: 'center',
      },
      '& button': {
        minWidth: 120,
        [theme.breakpoints.down(Sizes.mobile)]: {
          minWidth: 110,
        },
      },
    },
    saveBtn: {
      marginLeft: 15,
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginLeft: 7,
      },
    },
  }),
);
