import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../../styles/colorVariables';
import { Sizes } from '../../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    modalRoot: {
      width: 720,
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: 500,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
      },
    },
    modalWrap: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      fontSize: 30,
      lineHeight: '36px',
      fontWeight: 700,
      textAlign: 'center',
      padding: '30px 40px',
      [theme.breakpoints.down(Sizes.mobile)]: {
        fontSize: 24,
        lineHeight: '30px',
        padding: '20px 10px',
      },
    },
    subtitle: {
      color: colorVariables.grey80,
      textAlign: 'center',
      '& span': {
        fontWeight: 700,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        '& br': {
          display: 'none',
        },
      },
    },
    svg: {
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '80%',
      },
    },
    nextBtn: {
      marginTop: 20,
      marginBottom: 20,
    },
    listBox: {
      height: 400,
      overflowY: 'scroll',
      borderBottom: `1px solid ${colorVariables.grey60}`,
      [theme.breakpoints.down(Sizes.mobile)]: {
        height: '60vh',
      },
    },
    greenText: {
      color: colorVariables.green,
      textTransform: 'uppercase',
      fontSize: 11,
      fontWeight: 700,
      marginBottom: 16,
    },
    listModal: {
      padding: 40,
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: 20,
        '&>div': {
          width: '100%',
        },
      },
    },
    listTitle: {
      fontSize: 20,
      lineHeight: '28px',
      fontWeight: 700,
      marginBottom: 20,
      [theme.breakpoints.down(Sizes.mobile)]: {
        fontSize: 18,
        lineHeight: '24px',
      },
    },
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: `1px solid ${colorVariables.steelGrey}`,
      padding: '16px 12px',
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: '14px 5px',
        overflow: 'hidden',
        justifyContent: 'flex-start',
        '&>div:first-child': {
          width: 'calc(100% - 130px)',
        },
        '&>div:last-child': {
          width: 120,
        },
      },
    },
    supplierName: {
      fontWeight: 600,
      marginBottom: 7,
    },
    supplierEmail: {
      color: colorVariables.grey60,
    },
    completeBtn: {
      height: 30,
      width: 137,
      marginLeft: 10,
      padding: 5,
      fontSize: 14,
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginLeft: 5,
        width: 125,
        marginTop: -5,
      },
    },
  }),
);
