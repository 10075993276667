import { createAction } from '@reduxjs/toolkit';
import { OrderFilters, OrderSortBy } from '../../api/order/types';

export const setOrdersPage = createAction<number>('SET_ORDERS_PAGE');

export const setOrdersKeyword = createAction<string>('SET_ORDERS_KEYWORD');

export const setSelectedOrders = createAction<{ id: number; deleted: boolean }[]>('SET_SELECTED_ORDERS');

export const setCheckOffIdsForOrder = createAction<{ approved?: number; requested?: number }>('SET_CHECK_OFF_IDS_FOR_ORDER');

export const clearCheckOffIdsForOrder = createAction('CLEAR_CHECK_OFF_IDS_FOR_ORDER');

export const setOrdersSortFilter = createAction<{ filter?: OrderFilters; sort?: OrderSortBy }>('SET_ORDERS_SORT_FILTER');
