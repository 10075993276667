import { createStyles, makeStyles } from '@material-ui/core';
import { colorVariables } from '../../../styles/colorVariables';

export const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: 'relative',
    },
    dropdown: {
      position: 'absolute',
      padding: '5px  0',
      background: colorVariables.white,
      zIndex: 1,
      boxShadow: '0 4px  10px  rgba(0, 0, 0, 0.1)',
      borderRadius: 4,
      border: '1px  solid #F2F3F7',
      display: 'block',
      whiteSpace: 'nowrap',
      minWidth: '100%',
    },
    item: {
      overflow: 'hidden',
      boxSizing: 'border-box',
      whiteSpace: 'nowrap',
      fontSize: 14,
      padding: '10px 15px',
      fontWeight: 'normal',
      textTransform: 'none',
      color: colorVariables.navy,
      '&:hover': {
        background: colorVariables.blueLight,
        cursor: 'pointer',
      },
      '&:focus': {
        background: colorVariables.blueLight,
        cursor: 'pointer',
      },
    },
    checkbox: {
      padding: '0 10px 0 5px',
    },
  }),
);
