import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import SvgFaqIcon from '../../../../assets/icons/Faq';
import { BackButton } from '../../../../shared/components/back-button';
import { NavbarTitle } from '../../../../shared/components/navbar-title';
import { faqsListRestaurant } from '../../../../shared/constants/faqsRestaurant';
import { faqsListSupplier } from '../../../../shared/constants/faqsSupplier';
import { useAppSelector } from '../../../../store';
import { getIsRestaurant } from '../../../../store/user';
import { TopNavBar } from '../../../AppDrawer/MainNavBar/top-nav-bar';

import { useStyles } from './style';

export const FaqsDetails: React.FC = () => {
  const classes = useStyles();
  const { goBack, push } = useHistory();
  const { id } = useParams<{ id?: string }>();
  const isRestaurant = useAppSelector(getIsRestaurant);

  const article = useMemo(() => {
    return (isRestaurant ? faqsListRestaurant : faqsListSupplier).find((el) => id && el.id === +id);
  }, [id, isRestaurant]);

  useEffect(() => {
    if (!article) {
      push('/account/help/faqs');
    }
  }, [!!article]);

  if (!article) {
    return null;
  }

  return (
    <>
      <TopNavBar isAccount={true} leftComponent={<NavbarTitle title='Help' showBackBtn={true} />} />
      <div className={classes.root}>
        <BackButton onClickHandler={goBack} />
        <div className={classes.mobileHead}>
          <NavbarTitle title='' showBackBtn={true} />
          <SvgFaqIcon width={32} height={32} />
        </div>
        <h2 className={classes.title}>{article.title}</h2>
        <SvgFaqIcon className={classes.icon} width={32} height={32} />
        <div className={classes.content} dangerouslySetInnerHTML={{ __html: article.content }}/>
      </div>
    </>
  );
};
