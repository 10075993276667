import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colorVariables } from '../../../styles/colorVariables';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'relative',
      display: 'flex',
    },
    container: {
      padding: 0,
      width: 255,
      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    },
    dropdownItem: {
      marginBottom: 3,
      overflow: 'hidden',
      fontSize: 14,
      padding: '8px 30px',
      lineHeight: '22px',
      '&:hover, &:focus': {
        backgroundColor: colorVariables.blueLight,
      },
    },
    customRangeItem: {
      marginBottom: 5,
      borderTop: `10px solid ${colorVariables.white}`,
      boxShadow: `0 -1px ${colorVariables.steelGrey}`,
      marginTop: 5,
    },
    arrow: {
      color: colorVariables.green,
      fontSize: 22,
      marginLeft: 10,
    },
    triggerWrap: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    rangeName: {
      fontWeight: 700,
      padding: '8px 30px',
    },
    rangeDates: {
      color: colorVariables.grey60,
      padding: '8px 30px',
      paddingTop: 0,
      marginBottom: 3,
      borderBottom: `1px solid ${colorVariables.steelGrey}`,
    },
    trigger: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: colorVariables.greenLight,
      height: 30,
      fontSize: 13,
      fontWeight: 700,
      color: colorVariables.green,
      padding: '0 15px',
      borderRadius: 15,
    },
  }),
);
