import { SuccessResponse } from '../index';

export interface AdminTheme {
  id: number;
  name: string;
  logo_url: string | null;
  theme_colors: ThemeColor[];
}

export interface ThemeDomain {
  id: number;
  domain_url: string;
  theme_id: number;
}

export interface ThemeColor {
  id: number;
  name: ThemeColors;
  color_code: string;
}

export interface CreateThemeResponse extends SuccessResponse {
  theme: AdminTheme;
}

export interface GetThemesRes extends SuccessResponse {
  themes: AdminTheme[];
}

export interface GetDomainsRes extends SuccessResponse {
  domains: ThemeDomain[];
}

export interface GetThemeRes extends SuccessResponse {
  theme: AdminTheme;
}

export enum ThemeColors {
  btnPrimaryBg = 'primary_btnBg',
  btnPrimaryText = 'primary_btnText',
  primaryBorderedBg = 'primaryBordered_btnBg',
  primaryBorderedText = 'primaryBordered_btnText',
  btnSecondaryBg = 'secondary_btnBg',
  btnSecondaryText = 'secondary_btnText',
  navBarBg = 'navBarBg',
  navBarText = 'navBarText',
}
