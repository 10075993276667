import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../styles/colorVariables';
import { Sizes } from '../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      paddingBottom: 80,
      [theme.breakpoints.down(Sizes.desktop)]: {
        paddingBottom: 40,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        paddingBottom: 30,
      },
    },
    title: {
      marginTop: 15,
      marginBottom: 25,
      fontSize: 32,
      lineHeight: '50px',
      fontWeight: 700,
      [theme.breakpoints.down(Sizes.desktop)]: {
        fontSize: 24,
        lineHeight: '36px',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        fontSize: 20,
        lineHeight: '24px',
        marginBottom: 15,
      },
    },
    subTitle: {
      fontSize: 16,
      [theme.breakpoints.down(Sizes.desktop)]: {
        fontSize: 14,
      },
    },
    formBox: {
      width: '100%',
      paddingTop: 40,
      [theme.breakpoints.down(Sizes.desktop)]: {
        paddingTop: 30,
      },
    },
    fieldsBox: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    fieldWrap: {
      width: '48%',
      marginBottom: 15,
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: '100%',
      },
    },
    fieldWrapCustomer: {
      width: '48%',
      marginBottom: 15,
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: '48%',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
      },
    },
    fullWidthFieldWrap: {
      marginBottom: 15,
    },
    formFooter: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: 30,
      [theme.breakpoints.down(Sizes.desktop)]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    contactBox: {
      padding: '0 25px',
      '& a': {
        color: colorVariables.navy,
      },
      [theme.breakpoints.down(Sizes.desktop)]: {
        marginTop: 40,
      },
    },
    contactBoxTitle: {
      fontSize: 16,
      fontWeight: 600,
      marginBottom: 5,
      [theme.breakpoints.down(Sizes.desktop)]: {
        fontSize: 14,
      },
    },
    address: {
      marginTop: 30,
      [theme.breakpoints.down(Sizes.desktop)]: {
        marginTop: 20,
      },
    },
    sendButton: {
      [theme.breakpoints.down(Sizes.desktop)]: {
        alignSelf: 'center',
      },
    },
  }),
);
