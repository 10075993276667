import * as React from 'react';

function SvgCategoryDairy(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M13.45 19.04c0-1.19-.992-2.16-2.225-2.16s-2.226.97-2.226 2.16h4.451zm0 0a.33.33 0 01-.015.127l.015-.127zM9 19.1v0zm0 0v0m0 0v0m0 0v0m0 0v0m0 0v0m0 0v0m0 0v-.001m0 0v0m0 0v0m0 0v0m0 0v0m0 0v0m0 0v-.001m0 0v0m0 0v0m0 0v0m0 0v0m0 0v0m0 0v0m0 0v0m0 0v-.001m0 0v0m0 0v0m0 0v0m0 0v0m0 0v0m0 0v-.001m0 0v0m0 0v0m0 0v0m0 0v0m0 0v0m0 0v-.001m0 0v0m0 0v0m0 0v0m0 0v0m0 0v0m0 0v0m0 0v0m0 0v-.001m0 0v0m0 0v0m0 0v0m0 0v0m0 0v0m0 0v-.001m0 0v0m0 0v0m0 0v0m0 0v0m0 0v0m0 0v-.001m0 0v0m0 0v0m0 0v0m0 0v0m0 0v0m0 0v0m0 0v0m0 0v-.001m0 0v0m0 0v0m0 0v0m0 0v0m0 0v0m0 0v-.001m0 0v0m0 0v0m0 0v0m0 0v0m0 0v0m0 0v-.001m0 0v0m0 0v0m0 0v0m0 0v0m0 0v0m0 0v0m0 0v0m0 0v-.001m0 0v0m0 0v0m0 0v0m0 0v0m0 0v0m0 0v-.001m0 0v0m0 0v0m0 0v0m0 0v0m0 0v0m0 0v-.001m0 0v0m0 0v0m0 0v0m0 0v0m0 0v0m0 0v0m0 0v0m0 0v-.001m0 0v0m0 0v0m0 0v0m0 0v0m0 0v0m0 0v-.001m0 0v0m0 0v0m0 0v0m0 0v0m0 0v0m0 0v-.001m0 0v0m0 0v0m0 0v0m0 0v0m0 0v0m0 0v-.001m0 0v0m0 0v0m0 0v0m0 0v0m0 0v0m0 0v0m0 0v0m0 0v-.001m0 0v0m0 0v0zM22.146 7.788l-.054.056v-.003l.048-.058-4.753-3.886h-.001a2.352 2.352 0 00-2.152-.394h0-.001L2.009 7.7h-.025l-.018.007a.414.414 0 00-.266.39v9.594c0 1.228 1.006 2.2 2.26 2.222h16.078c1.256 0 2.262-.974 2.262-2.202V8.118a.444.444 0 00-.153-.33zm-1.373-.107H4.71l10.766-3.412s0 0 0 0c.48-.15.995-.056 1.376.259h0l3.92 3.153zm-1.448 7.315c0 1.195.953 2.15 2.162 2.2v.496c0 .775-.646 1.407-1.45 1.407h-5.776v0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0c0-1.644-1.353-2.955-3.037-2.955-1.685 0-3.038 1.331-3.038 2.955V19.099H3.962c-.804 0-1.45-.632-1.45-1.407V8.476h18.976v4.321c-1.21.05-2.162 1.005-2.162 2.2z'
        fill='#fff'
        stroke='#fff'
        strokeWidth={0.2}
      />
    </svg>
  );
}

export default SvgCategoryDairy;
