import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';
import { Sizes } from '../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    accountContainer: {
      paddingTop: 63,
      backgroundColor: colorVariables.white,
      [theme.breakpoints.down(Sizes.desktop)]: {
        padding: '70px 0 0 94px',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: '110px 0 80px 0',
      },
    },
    accountContainerCustomer: {
      [theme.breakpoints.between(Sizes.mobile, Sizes.desktop)]: {
        padding: '50px 0 0 0',
      },
    },
    accountHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      backgroundColor: colorVariables.white,
      height: 127,
      borderBottom: `1px solid ${colorVariables.steelGrey}`,
      paddingBottom: 30,
      paddingLeft: 55,
      paddingRight: 55,
      [theme.breakpoints.down(Sizes.desktop)]: {
        display: 'none',
      },
    },
    accountTitle: {
      fontSize: 32,
      lineHeight: '50px',
      fontWeight: 700,
    },
    accountBody: {
      display: 'flex',
    },
    accountContent: {
      width: '80%',
      padding: '25px 200px 50px 50px',
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: '100%',
        padding: '40px 50px',
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        padding: 40,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: 24,
        paddingTop: 0,
      },
    },
    tabletTitleCustomer: {
      paddingBottom: 20,
      marginBottom: 30,
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        width: '100vw',
        height: 1,
        right: 0,
        left: 0,
        transform: 'translateY(20px)',
        backgroundColor: colorVariables.steelGrey,
      },
    },
    titleBox: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down(Sizes.desktop)]: {
        display: 'none',
      },
    },
    title: {
      fontSize: 24,
      lineHeight: '36px',
      fontWeight: 700,
      marginBottom: 30,
      [theme.breakpoints.down(Sizes.desktop)]: {
        display: 'none',
      },
    },
    manageAddressBox: {},
    sectionHead: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 25,
      [theme.breakpoints.down(Sizes.mobile)]: {
        height: 20,
        marginTop: 10,
        backgroundColor: 'rgba(242, 243, 247, 0.6)',
        boxShadow: '0 0 0 25px rgba(242, 243, 247, 0.6)',
      },
    },
    sectionTitle: {
      textTransform: 'uppercase',
      fontSize: 12,
      lineHeight: '22px',
      fontWeight: 700,
      [theme.breakpoints.down(Sizes.mobile)]: {
        lineHeight: '20px',
        color: colorVariables.userGrey,
      },
    },
    addressItem: {
      fontSize: 16,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '20px 0',
      marginBottom: 10,
      borderBottom: `1px solid ${colorVariables.steelGrey}`,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    chevron: {
      fontSize: 26,
      color: colorVariables.navy,
    },
    addressLineName: {
      fontSize: 16,
      lineHeight: '24px',
      marginBottom: 3,
    },
    addressLineInfo: {
      display: 'flex',
    },
    greyText: {
      color: colorVariables.grey60,
      fontSize: 12,
    },
    defaultBadge: {
      display: 'inline-block',
      fontSize: 11,
      textTransform: 'uppercase',
      color: colorVariables.grey80,
      fontWeight: 600,
      width: 74,
      height: 24,
      lineHeight: '24px',
      borderRadius: 12,
      textAlign: 'center',
      border: `1px solid ${colorVariables.steelGrey}`,
      marginLeft: 10,
    },
    mobileAddBtn: {
      display: 'none',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'flex',
        marginTop: 35,
        marginBottom: 10,
      },
    },
    deliveryBox: {
      marginTop: 50,
    },
    dayRow: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 15,
      '&>*:last-child': {
        marginLeft: 20,
      },
    },
    dayName: {
      width: 150,
      marginRight: 10,
      textTransform: 'capitalize',
      fontSize: 14,
      lineHeight: '16px',
      fontWeight: 700,
      color: colorVariables.grey60,
      [theme.breakpoints.down(Sizes.mobile)]: {
        flexShrink: 0,
        width: 90,
      },
    },
    daysHead: {
      display: 'flex',
      paddingLeft: 160,
      '&>div': {
        width: 185,
        fontSize: 11,
        fontWeight: 700,
        color: colorVariables.grey60,
        textTransform: 'uppercase',
        marginBottom: 10,
        [theme.breakpoints.down(Sizes.mobile)]: {
          width: '50%',
          maxWidth: 185,
        },
      },
      '&>div:last-child': {
        marginLeft: 20,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginTop: 50,
        paddingLeft: 100,
      },
    },
    updateBtn: {
      marginTop: 30,
      marginBottom: 40,
      display: 'flex',
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginBottom: 20,
        justifyContent: 'center',
      },
    },
    disabledTime: {
      width: 170,
      height: 40,
      border: `1px solid ${colorVariables.grey60}`,
      borderRadius: 5,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: colorVariables.grey60,
      marginRight: 15,
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginRight: 5,
      },
    },
  }),
);
