import * as React from 'react';

function SvgLetterIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M18.125 2.5H1.875A1.877 1.877 0 000 4.375v11.25C0 16.659.841 17.5 1.875 17.5h16.25A1.877 1.877 0 0020 15.625V4.375A1.877 1.877 0 0018.125 2.5zm0 1.25a.62.62 0 01.24.048L10 11.048l-8.365-7.25a.622.622 0 01.24-.048h16.25zm0 12.5H1.875a.625.625 0 01-.625-.625V5.119l8.34 7.228a.624.624 0 00.82 0l8.34-7.228v10.506c0 .345-.28.625-.625.625z'
        fill='currentColor'
      />
    </svg>
  );
}

export default SvgLetterIcon;
