import React, { useEffect, useState } from 'react';

import { Close } from '@material-ui/icons';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import clsx from 'clsx';
import { format, isToday } from 'date-fns';
import { FastField, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import isEqual from 'lodash/isEqual';

import { DeliverStatusFilter, DeliverySortBy } from '../../../../../../api/delivery/types';
import { useGetDriversList } from '../../../../../../api/drivers/hooks';
import { ThemedButton } from '../../../../../../shared/components/themed-button';
import { DatePickerComponent } from '../../../../../../shared/components/date-picker';
import { FormikCheckbox } from '../../../../../../shared/components/formik-checkbox';
import { FormikDropdown } from '../../../../../../shared/components/formik-dropdown';
import { useScreenSize } from '../../../../../../shared/hooks/use-screen-size';
import { useAppDispatch, useAppSelector } from '../../../../../../store';
import { getDeliveriesFilter, getDeliveriesSort, setDeliveriesFilter, setDeliveriesSortFilter } from '../../../../../../store/deliveries';
import { DriverSelect } from '../driver-select';

import { useStyles } from './style';

export const deliverySortParams = [
  { label: 'Customer name (A - Z)', value: 'customer_name asc' },
  { label: 'Customer name (Z - A)', value: 'customer_name desc' },
  { label: 'Postcode  (Low to High)', value: 'postcode asc' },
  { label: 'Postcode (High to Low)', value: 'postcode desc' },
  { label: 'Status (‘Delivered’ first)', value: 'delivery_status asc' },
  { label: 'Sort (‘For Delivered’ first)', value: 'delivery_status desc' },
  { label: 'Delivery Number  (Low to High)', value: 'delivery_number asc' },
  { label: 'Delivery Number (High to Low)', value: 'delivery_number desc' },
  { label: 'Driver (A - Z)', value: 'driver_name asc' },
  { label: 'Driver (Z - A)', value: 'driver_name desc' },
];

interface Values {
  status?: string[];
  sortBy?: string;
}

interface Props {
  handleCloseModal: () => void;
  isFullScreen?: boolean;
}

export const DeliveryFilter: React.FC<Props> = ({ handleCloseModal, isFullScreen }) => {
  const classes = useStyles();
  const { isMobile, isDesktop } = useScreenSize();
  const dispatch = useAppDispatch();

  const [selectedDeliveryDate, setSelectedDeliveryDate] = useState<Date | null>(null);
  const [selectedDriver, setSelectedDriver] = useState(-1);

  const filter = useAppSelector(getDeliveriesFilter);
  const sort = useAppSelector(getDeliveriesSort);
  const { list: drivers = [] } = useGetDriversList();

  const onSetDriver = (id: number) => {
    setSelectedDriver(id);
  };

  const onSubmit = (values: Values, formikHelpers: FormikHelpers<any>) => {
    if (isDesktop) {
      dispatch(
        setDeliveriesFilter({
          ...filter,
          delivery_status: values.status as DeliverStatusFilter[],
          driver: selectedDriver,
        }),
      );
    } else {
      dispatch(
        setDeliveriesSortFilter({
          sort: values.sortBy?.split(' ') as DeliverySortBy,
          filter: isMobile
            ? {
                delivery_status: values.status as DeliverStatusFilter[],
                delivery_date:
                  selectedDeliveryDate && !isToday(selectedDeliveryDate) ? format(selectedDeliveryDate, 'yyyy-MM-dd') : undefined,
                driver: selectedDriver,
              }
            : {
                delivery_status: values.status as DeliverStatusFilter[],
                driver: selectedDriver,
                delivery_date: filter?.delivery_date,
              },
        }),
      );
    }
    handleCloseModal();
    formikHelpers.resetForm();
  };

  const resetFields = () => {
    dispatch(setDeliveriesSortFilter({ filter: undefined, sort: undefined }));
  };

  const onSetDeliveryDate = (date: Date | null) => {
    setSelectedDeliveryDate(date);
  };

  useEffect(() => {
    isMobile && setSelectedDeliveryDate(filter?.delivery_date ? new Date(filter.delivery_date) : new Date());
    setSelectedDriver(filter?.driver || -1);
  }, [filter?.delivery_date, isMobile, filter?.driver]);

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        status: filter?.delivery_status,
        sortBy: Array.isArray(sort) ? (isEqual(sort, ['', 'asc']) ? ' ' : sort.join(' ').toLowerCase()) : ' ',
      }}
      enableReinitialize={true}
    >
      {({ submitForm }) => (
        <Form className={classes.formBox}>
          <div className={classes.root}>
            <div className={classes.sortBlock}>
              <div className={classes.blockTitle}>SORT BY</div>
              <FastField name='sortBy'>
                {(fieldProps: FieldProps) => (
                  <FormikDropdown
                    defaultValue={deliverySortParams[8].value}
                    placeholder='Select sort option'
                    options={deliverySortParams}
                    {...fieldProps}
                    height={36}
                    inputFontSize={14}
                  />
                )}
              </FastField>
            </div>
            <div className={classes.filterBlock}>
              <div className={classes.blockTitle}>FILTER BY</div>
              {isMobile && (
                <div className={classes.driversBox}>
                  <div className={classes.blockSubTitle}>DELIVERY DATE</div>
                  <DatePickerComponent
                    onDateChange={onSetDeliveryDate}
                    selectedDate={selectedDeliveryDate}
                    minDate={new Date('2022-01-01')}
                    trigger={
                      <div className={classes.dateTrigger}>
                        {selectedDeliveryDate
                          ? isToday(selectedDeliveryDate)
                            ? 'Today'
                            : format(selectedDeliveryDate, 'd/MM/y')
                          : 'Today'}
                        <KeyboardArrowDownIcon />
                      </div>
                    }
                  />
                </div>
              )}
              <div className={classes.driversBox}>
                <div className={classes.blockSubTitle}>Drivers</div>
                <DriverSelect options={drivers} onSetDriver={onSetDriver} selectedDriver={selectedDriver} />
              </div>
              <div className={classes.blockSubTitle}>STATUS</div>
              <div className={classes.statusBlock}>
                <FastField type='checkbox' name='status' value='-1'>
                  {(fieldProps: FieldProps) => <FormikCheckbox {...fieldProps} labelPlace='end' label='All' />}
                </FastField>
                <FastField type='checkbox' name='status' value='2'>
                  {(fieldProps: FieldProps) => <FormikCheckbox {...fieldProps} labelPlace='end' label='For Delivery' />}
                </FastField>
                <FastField type='checkbox' name='status' value='1'>
                  {(fieldProps: FieldProps) => <FormikCheckbox {...fieldProps} labelPlace='end' label='ETA' />}
                </FastField>{' '}
                <FastField type='checkbox' name='status' value='0'>
                  {(fieldProps: FieldProps) => <FormikCheckbox {...fieldProps} labelPlace='end' label='Delivered' />}
                </FastField>
              </div>
            </div>
            <div className={clsx([classes.btnBlock, isFullScreen && classes.btnBlockFullScreen])}>
              <ThemedButton onClick={submitForm} title='Apply' buttonStyle='primary' isSmall={isMobile} />
              <ThemedButton
                type='reset'
                onClick={resetFields}
                title='CLEAR FILTERS'
                buttonStyle='icon'
                startIcon={<Close color={'inherit'} />}
                isSmall={isMobile}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
