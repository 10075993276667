import { ContactType, SupplierProfile } from '../../api/company/types';

export const calculateProfileProgress = (profile: Partial<SupplierProfile>) => {
  let progress = 10;
  if (profile.picture_url) {
    progress += 10;
  }
  if (profile.contacts?.some((el) => el.contact_type === ContactType.ORDER && el.email)) {
    progress += 5;
  }
  if (profile.contacts?.some((el) => el.contact_type === ContactType.ORDER && el.phone_number)) {
    progress += 5;
  }
  if (profile.contacts?.some((el) => el.contact_type === ContactType.OFFICE && el.email)) {
    progress += 5;
  }
  if (profile.contacts?.some((el) => el.contact_type === ContactType.OFFICE && el.phone_number)) {
    progress += 5;
  }
  if (profile.min_order_value) {
    progress += 10;
  }
  if (profile.cutoff) {
    progress += 10;
  }
  if (profile.delivery_fee) {
    progress += 10;
  }
  if (profile.delivery_radius) {
    progress += 10;
  }
  if (profile.address?.city) {
    progress += 10;
  }
  if (profile.categories?.length) {
    progress += 10;
  }
  return progress;
};
