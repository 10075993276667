import React from 'react';

import { Close } from '@material-ui/icons';
import clsx from 'clsx';
import { FastField, FieldProps, Form, Formik, FormikHelpers } from 'formik';

import { SuppliersSortBy } from '../../../../../api/admin-suppliers/types';
import { ThemedButton } from '../../../../../shared/components/themed-button';
import { FormikDropdown } from '../../../../../shared/components/formik-dropdown';
import { useScreenSize } from '../../../../../shared/hooks/use-screen-size';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { getSuppliersSortFilters, setASuppliersSort } from '../../../../../store/admin/suppliers';

import { useStyles } from './style';

export const suppliersSortParams = [
  { label: 'Supplier name (A - Z)', value: 'name asc' },
  { label: 'Supplier name (Z - A)', value: 'name desc' },
  { label: 'Customers (High to Low)', value: 'customer_count desc' },
  { label: 'Customers (Low to High)', value: 'customer_count asc' },
  { label: 'Sales (High to Low)', value: 'sales desc' },
  { label: 'Sales (Low to High)', value: 'sales asc' },
  { label: 'Last order received (Most recent to Old)', value: 'last_order desc' },
  { label: 'Last order received (Old to Most recent)', value: 'last_order asc' },
  { label: 'Last price list change (Most recent to Old)', value: 'last_price_change desc' },
  { label: 'Last price list change (Old to Most recent)', value: 'last_price_change asc' },
  { label: 'Onboarding (High to Low)', value: 'on_boarding asc' },
  { label: 'Onboarding (Low to High)', value: 'on_boarding desc' },
  { label: 'Verify supplier (A - Z)', value: 'verified asc' },
  { label: 'Verify supplier (Z - A)', value: 'verified desc' },
  { label: 'Last login (Most recent to Old)', value: 'last_login desc' },
  { label: 'Last login (Old to Most recent)', value: 'last_login asc' },
  { label: 'Created (Most recent to Old)', value: 'created_at desc' },
  { label: 'Created (Old to Most recent)', value: 'created_at asc' },
  { label: 'Product photos (High to Low)', value: 'product_photos desc' },
  { label: 'Product photos (Low to High)', value: 'product_photos asc' },
];

interface Values {
  sortBy: string;
}

interface Props {
  handleCloseModal: () => void;
  isFullScreen?: boolean;
}

export const SuppliersFilter: React.FC<Props> = ({ handleCloseModal, isFullScreen }) => {
  const classes = useStyles();
  const { isMobile } = useScreenSize();
  const dispatch = useAppDispatch();

  const { sort } = useAppSelector(getSuppliersSortFilters);

  const onSubmit = ({ sortBy }: Values, formikHelpers: FormikHelpers<any>) => {
    handleCloseModal();
    dispatch(
      setASuppliersSort({
        sort: sortBy.trim() ? (sortBy.split(' ') as SuppliersSortBy) : undefined,
      }),
    );
    formikHelpers.resetForm();
  };

  const resetFields = () => {
    dispatch(setASuppliersSort({}));
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        sortBy: sort ? sort?.join(' ') : suppliersSortParams[16].value,
      }}
      enableReinitialize={true}
    >
      {({ submitForm }) => (
        <Form className={classes.formBox}>
          <div className={classes.root}>
            <div className={classes.sortBlock}>
              <div className={classes.blockTitle}>SORT BY</div>
              <FastField name='sortBy'>
                {(fieldProps: FieldProps) => (
                  <FormikDropdown
                    defaultValue={suppliersSortParams[0].value}
                    placeholder='Select sort option'
                    options={suppliersSortParams}
                    {...fieldProps}
                    height={36}
                    inputFontSize={14}
                  />
                )}
              </FastField>
            </div>
            <div className={clsx([classes.btnBlock, isFullScreen && classes.btnBlockFullScreen])}>
              <ThemedButton onClick={submitForm} title='Apply' buttonStyle='primary' isSmall={isMobile} />
              <ThemedButton
                type='reset'
                onClick={resetFields}
                title='CLEAR'
                buttonStyle='icon'
                startIcon={<Close color={'inherit'} />}
                isSmall={isMobile}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
