import { createStyles, makeStyles } from '@material-ui/core';
import { colorVariables } from '../../../../../styles/colorVariables';
import { Sizes } from '../../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      maxWidth: '100%',
      overflowX: 'scroll',
      overflowY: 'hidden',
      margin: '0 auto',
      minHeight: 180,
      padding: 15,
      borderRadius: 4,
      backgroundColor: colorVariables.white,
      '&::-webkit-scrollbar-track': {
        backgroundColor: colorVariables.transparent,
      },

      '&::-webkit-scrollbar': {
        height: 8,
        backgroundColor: colorVariables.transparent,
      },

      '&::-webkit-scrollbar-thumb': {
        borderRadius: 4,
        height: 8,
        backgroundColor: colorVariables.steelGrey,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        padding: '15px 8px',
      },
    },
    table: {
      marginBottom: 10,
      '& th': {
        textAlign: 'left',
        fontWeight: 700,
        padding: 5,
        fontSize: 12,
        minWidth: 100,
        [theme.breakpoints.down(Sizes.tablet)]: {
          fontSize: 10,
          minWidth: 50,
          padding: 3,
        },
      },
      '& td': {
        textAlign: 'left',
        padding: 5,
        color: colorVariables.grey60,
        fontSize: 12,
        minWidth: 65,
        [theme.breakpoints.down(Sizes.tablet)]: {
          fontSize: 10,
          minWidth: 50,
          padding: 2,
        },
      },
      '& tbody tr:nth-child(2)': {
        opacity: 0.6,
      },
      '& tbody tr:nth-child(3)': {
        opacity: 0.4,
      },
      '& tbody tr:nth-child(4)': {
        opacity: 0.2,
      },
    },
    previewBoxMobile: {
      padding: '20px 15px',
      margin: '0 10px',
      border: '1px solid #DADADA',
      borderRadius: 21,
    },
    previewDesc: {
      fontWeight: 700,
      marginBottom: 12,
    },
    previewTopBox: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      paddingBottom: 10,
      borderBottom: `1px solid ${colorVariables.steelGrey}`,
    },
    previewTopItem: {
      display: 'flex',
      width: '50%',
      marginBottom: 8,
    },
    previewItemTitle: {
      fontSize: 12,
      fontWeight: 600,
      color: colorVariables.navy,
      marginRight: 10,
      width: '30%',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 120,
      },
    },
    previewItemText: {
      fontSize: 12,
      fontWeight: 400,
      color: colorVariables.grey60,
      width: '65%',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 'calc(100% - 125px)',
      },
    },
    previewBottomBox: {
      display: 'flex',
      paddingTop: 15,
      justifyContent: 'space-between',
    },
    previewBottomItem: {
      width: '20%',
      '&>div:first-child': {
        marginBottom: 8,
      },
    },
    previewInputStageBox: {
      marginTop: 10,
      backgroundColor: colorVariables.white,
      width: 520,
      borderRadius: 4,
      padding: '20px 30px',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        padding: '20px 10px 10px',
        height: 260,
        overflowY: 'scroll',
      },
    },
    previewInputStageRow: {
      display: 'flex',
      marginBottom: 15,
      justifyContent: 'space-between',
    },
    thWrap: {
      display: 'flex',
      alignItems: 'center',
    },
    nameCell: {
      width: 180,
    },
    cell: {
      width: 100,
    },
  }),
);
