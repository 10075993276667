import * as React from 'react';

function SvgFaqIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M20.667 28a1.666 1.666 0 10-.001 3.333 1.666 1.666 0 000-3.332zM21.858 8.109A6.666 6.666 0 0014 14.667a1.666 1.666 0 103.333 0c0-.988.435-1.924 1.192-2.558a3.334 3.334 0 113.75 5.483A6.067 6.067 0 0019 23a1.608 1.608 0 001.624 1.593l.043-.001a1.733 1.733 0 001.666-1.733 2.733 2.733 0 011.55-2.359 6.667 6.667 0 00-2.025-12.4v.009z'
        fill='#98A5B7'
      />
      <path
        d='M19.989 0C8.951.007.009 8.966.015 20.01a20 20 0 002.677 9.988L.086 37.805a1.667 1.667 0 002.107 2.108l7.803-2.608c9.553 5.534 21.78 2.272 27.31-7.287 5.532-9.558 2.271-21.793-7.281-27.327A19.982 19.982 0 0019.989 0zm0 36.664a16.648 16.648 0 01-8.886-2.592 1.73 1.73 0 00-.89-.258c-.181.002-.36.03-.534.083l-5.38 1.8 1.8-5.383c.16-.476.095-1-.175-1.425-4.908-7.784-2.579-18.076 5.201-22.986 7.78-4.91 18.065-2.58 22.973 5.205 4.907 7.784 2.578 18.076-5.202 22.986a16.64 16.64 0 01-8.907 2.57z'
        fill='#98A5B7'
      />
    </svg>
  );
}

export default SvgFaqIcon;
