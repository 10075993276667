import React from 'react';

import SvgNoSuppliers from '../../../../../assets/images/svg-components/NoSuppliers';
import { ThemedButton } from '../../../../../shared/components/themed-button';
import { useScreenSize } from '../../../../../shared/hooks/use-screen-size';

import { useStyles } from './style';

interface Props {
  onInvite: () => void;
}

export const NoSuppliers: React.FC<Props> = ({ onInvite }) => {
  const classes = useStyles();
  const { isDesktop } = useScreenSize();

  return (
    <div className={classes.noResBlock}>
      <h3 className={classes.noResTitle}>Can't find the supplier you are looking for?</h3>
      <p className={classes.noResText}>Invite them manually. It's free for them too.</p>
      <SvgNoSuppliers width={isDesktop ? 200 : 160} height={isDesktop ? 200 : 160} />
      <ThemedButton customClass={classes.inviteBtn} onClick={onInvite} title='Invite Supplier' />
    </div>
  );
};
