import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';
import { Sizes } from '../../../styles/sizes';

export interface StyleProps {
  height?: number;
  labelColor?: string;
  inputFontSize?: number;
  labelFontSize?: number;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    rootField: {
      width: '100%',
      height: ({ height }) => height || 48,
      position: 'relative',
      backgroundColor: colorVariables.white,
      color: colorVariables.navy,
      borderRadius: 4,
      overflow: 'hidden',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: colorVariables.steelGrey,
      },
      '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        borderColor: `${colorVariables.steelGrey} !important`,
      },
      '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        borderColor: colorVariables.blue,
        borderWidth: 1,
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
        borderColor: colorVariables.blue,
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: colorVariables.blue,
        borderWidth: 1,
      },
      '& .MuiSelect-nativeInput': {
        border: 'none !important',
      },
    },
    rootFieldDefault: {
      width: '100%',
      position: 'relative',
      backgroundColor: colorVariables.transparent,
      color: colorVariables.navy,
      overflow: 'hidden',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
        border: 'none',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .MuiSelect-nativeInput': {
        border: 'none !important',
      },
    },
    select: {
      padding: '5px 15px',
      display: 'block',
      boxSizing: 'border-box',
      height: ({ height }) => height || 48,
      fontSize: ({ inputFontSize }) => inputFontSize || 16,
      fontWeight: 400,
      color: colorVariables.navy,
      lineHeight: ({ height }) => `${(height || 48) - 10}px`,
      backgroundColor: colorVariables.transparent,
      '&:hover': {
        backgroundColor: 'rgba(152, 165, 183, 0.06)',
      },
      '&:focus': {
        backgroundColor: 'rgba(152, 165, 183, 0.06)',
      },
    },
    menuItem: {
      fontSize: 14,
      fontWeight: 400,
      color: colorVariables.navy,
      padding: '5px 10px',
      marginTop: 2,
      minHeight: ({ height }) => (height || 48) - 5,
      backgroundColor: colorVariables.transparent,
      '&:hover': {
        backgroundColor: colorVariables.blueLight,
      },
      '&:focus': {
        backgroundColor: colorVariables.blueLight,
      },
      '&:active': {
        backgroundColor: colorVariables.blueLight,
      },
    },
    selected: {
      backgroundColor: colorVariables.blueLight,
      '&:hover': {
        backgroundColor: colorVariables.blueLight,
      },
      '&:focus': {
        backgroundColor: colorVariables.blueLight,
      },
      '&:active': {
        backgroundColor: colorVariables.blueLight,
      },
    },
    empty: {
      backgroundColor: 'transparent !important',
      zIndex: -1,
      position: 'absolute',
    },
    unselect: {
      fontStyle: 'italic',
      color: colorVariables.grey60,
    },
    icon: {
      fill: colorVariables.steelGrey,
    },
    iconDefault: {
      fill: 'inherit',
    },
    box: {
      width: '100%',
      position: 'relative',
    },
    label: {
      marginBottom: 10,
      marginTop: 4,
      fontSize: ({ labelFontSize }) => labelFontSize || 12,
      lineHeight: '16px',
      color: ({ labelColor }) => labelColor || colorVariables.navy,
    },
    itemText: {
      marginTop: 0,
    },
    logo: {
      width: 26,
      height: 26,
      borderRadius: '50%',
      marginRight: 6,
      float: 'left',
    },
    placeholder: {
      position: 'absolute',
      right: 35,
      left: 0,
      top: 0,
      bottom: 0,
      lineHeight: ({ height }) => `${height || 48}px`,
      fontSize: ({ inputFontSize }) => inputFontSize || 16,
      paddingLeft: 20,
      color: colorVariables.grey60,
    },
    toolTip: {
      display: 'block',
      minWidth: 125,
      maxWidth: '100%',
      minHeight: 24,
      borderRadius: 4,
      textAlign: 'center',
      fontSize: 11,
      lineHeight: '24px',
      padding: '0 5px',
      color: `${colorVariables.white} !important`,
      backgroundColor: colorVariables.navy,
      position: 'absolute',
      bottom: ({ height }) => (height || 50) + 10,
      left: 0,
      [theme.breakpoints.down(Sizes.mobile)]: {
        bottom: ({ height }) => (height || 48) + 10,
      },

      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: -15,
        left: 24,
        borderWidth: 8,
        borderStyle: 'solid',
        borderColor: `${colorVariables.navy} transparent transparent transparent`,
      },
    },
  }),
);
