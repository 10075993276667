import { createStyles, makeStyles } from '@material-ui/core';
import { colorVariables } from '../../../../styles/colorVariables';
import { Sizes } from '../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    rootBox: {
      width: 500,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
      },
    },
    row: {
      paddingLeft: 34,
      position: 'relative',
      minHeight: 70,
      marginBottom: 25,
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        backgroundColor: '#98A5B7',
        width: 3,
        borderRadius: 3,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        paddingLeft: 15,
      },
    },
    rowCompleted: {
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        backgroundColor: '#51D9B0',
        width: 3,
        borderRadius: 3,
      },
    },
    btnsBox: {
      justifyContent: 'center',
      marginBottom: 32,
      marginTop: 40,
      width: 500,
      display: 'grid',
      gridTemplateColumns: '1fr 230px 1fr',
      '&>*:first-child': {
        transform: 'translateX(-25px)',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        gridTemplateColumns: '1fr 160px 1fr',
        '&>*:first-child': {
          transform: 'translateX(5px)',
        },
      },
    },
    completeSuppliers: {
      textAlign: 'center',
      fontSize: 20,
      fontWeight: 700,
      color: '#98A5B7',
    },
    typeBtnsBox: {
      display: 'flex',
      paddingBottom: 15,
    },
    recommendedText: {
      position: 'absolute',
      width: 97,
      height: 17,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      background: 'linear-gradient(right, #33A8E2, #51D9B0)',
      paddingTop: 2,
      fontSize: 10,
      fontWeight: 600,
      color: colorVariables.white,
      textAlign: 'center',
      bottom: -17,
      left: '50%',
      transform: 'translateX(-50%)',
    },
    supplierManagedWrap: {
      position: 'relative',
      marginRight: 20,
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginRight: 5,
      },
    },
    selfBtn: {
      backgroundColor: colorVariables.white,
      minWidth: 181,
      height: 42,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 24,
      border: '1px solid #CFD7E6',
      fontWeight: 600,
      fontSize: 16,
      padding: '0 18px',
      color: colorVariables.grey60,
      cursor: 'pointer',
      '& svg': {
        marginLeft: 10,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        minWidth: 'auto',
        fontSize: 12,
        height: 38,
        padding: '0 10px',
        '& svg': {
          marginLeft: 5,
        },
      },
    },
    selfBtnInfo: {
      [theme.breakpoints.down(Sizes.mobile)]: {
        minWidth: 130,
      },
    },
    selfBtnActive: {
      backgroundColor: '#51D9B0',
      color: colorVariables.white,
      border: '1px solid #51D9B0',
    },
    label: {
      fontSize: 12,
      lineHeight: '16px',
      marginBottom: 10,
    },
    rowInactive: {
      opacity: 0.28,
    },
    infoFormText: {
      opacity: 0.7,
      marginBottom: 25,
    },
    nextBtn: {
      display: 'flex',
      justifyContent: 'center',
    },
  }),
);
