import { createStyles, makeStyles } from '@material-ui/core';
import { Sizes } from '../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    formContainer: {
      paddingTop: 20,
      width: 600,
      [theme.breakpoints.down(Sizes.tablet)]: {
        width: 500,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        maxWidth: '100%',
      },
    },
    fieldWrap: {
      marginBottom: 20,
    },
    btnBlock: {
      paddingTop: 20,
      alignSelf: 'flex-start',
      display: 'flex',
      [theme.breakpoints.down(Sizes.mobile)]: {
        justifyContent: 'center',
      },
      '& button': {
        minWidth: 120,
        [theme.breakpoints.down(Sizes.mobile)]: {
          minWidth: 110,
        },
      },
    },
    saveBtn: {
      marginLeft: 15,
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginLeft: 7,
      },
    },
    tooltipWrap: {
      width: 220,
      visibility: 'hidden',
      position: 'relative',
      zIndex: 300,
      transform: 'translateY(-100px)',
      opacity: 0,
      transition: 'all ease-in-out 0.7s',
    },
    abnField: {
      '&:hover + div': {
        transition: 'all ease-in-out 0.5s',
        visibility: 'visible',
        opacity: 1,
      },
    },
  }),
);
