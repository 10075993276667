import { createStyles, makeStyles } from '@material-ui/core';
import { Sizes } from '../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: 860,
      margin: '0 auto',
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: 780,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        width: '100%',
      },
    },
    searchWrap: {
      width: 820,
      margin: '40px auto',
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: 760,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        width: '100%',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginTop: 20,
      },
    },
    title: {
      fontSize: 24,
      lineHeight: '36px',
      fontWeight: 700,
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    tilesBox: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      [theme.breakpoints.down(Sizes.tablet)]: {
        justifyContent: 'center',
      },
    },
    tile: {
      width: 420,
      height: 148,
      borderRadius: 8,
      backgroundColor: '#F2F3F7',
      marginBottom: 24,
      padding: '24px 32px',
      cursor: 'pointer',
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: 380,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        width: '100%',
        maxWidth: 400,
        height: 132,
        marginBottom: 14,
        padding: 24,
      },
    },
    greenTile: {
      backgroundColor: '#E4FCF5',
    },
    tileTitleRow: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 5,
    },
    tileTitle: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '20px',
    },
    tileText: {
      fontSize: 13,
      lineHeight: '22px',
      color: 'rgba(21, 33, 68, 0.7)',
    },
  }),
);
