import React, { useEffect, useRef, useState } from 'react';

import { InputProps } from '@material-ui/core';
import { Autocomplete } from '@react-google-maps/api';

import { LocalStorageService } from '../../services/localStorage.service';

import { StyleProps, useStyles } from './style';

interface Props {
  setPlace: (place: google.maps.places.PlaceResult) => void;
  label?: string;
  setFieldError?: (field: string, message: string | undefined) => void;
  initialValue?: string;
  externalError?: string;
  countryCode?: string;
}

export const PostalCodePlaceAutocomplete: React.FC<InputProps & StyleProps & Props> = ({
  placeholder,
  inputFontSize,
  height,
  setPlace,
  label,
  setFieldError,
  initialValue,
  externalError,
  countryCode,
}) => {
  const classes = useStyles({ inputFontSize, height });
  const [instance, setInstance] = useState<google.maps.places.Autocomplete | null>(null);
  const [error, setError] = useState<string | null>(null);

  const ref = useRef<HTMLInputElement | null>(null);

  const onLoad = (autocomplete: google.maps.places.Autocomplete) => {
    setInstance(autocomplete);
  };

  useEffect(() => {
    document.body.classList.add('postal-code-search');
    return () => {
      document.body.classList.remove('postal-code-search');
    };
  }, []);

  const onPlaceChanged = () => {
    if (!instance) return;
    const postcode = instance.getPlace().address_components?.find((el) => el.types.includes('postal_code'))?.long_name;
    if (!postcode) {
      setError('Select postal code');
      return;
    }
    if (ref.current) {
      ref.current.value = postcode;
      setPlace(instance.getPlace());
      setError(null);
    }
  };

  const onBlur = () => {
    if (!ref.current) {
      return;
    }
    if (instance && instance.getPlace() === undefined) {
      setPlace({ name: ref.current.value });
      setError('Select postal code');
      return;
    }
    if (ref.current.value.trim() === '') {
      setError('Incorrect postal code');
      return;
    }
    if (instance && instance.getPlace().name !== ref.current?.value) {
      setError('Select postal code');
      return;
    }
    setError(null);
  };

  useEffect(() => {
    setFieldError && setFieldError('postcode', error || undefined);
  }, [error]);

  useEffect(() => {
    if (ref.current && initialValue) {
      ref.current.value = initialValue;
      setError(null);
    }
  }, [initialValue]);

  useEffect(() => {
    setError(externalError || null);
  }, [externalError]);

  return (
    <div className={classes.root}>
      <label htmlFor={label} className={classes.label}>
        {label}
      </label>
      {error && <div className={classes.toolTip}>{error}</div>}
      <Autocomplete
        onLoad={onLoad}
        onPlaceChanged={onPlaceChanged}
        className={classes.root}
        restrictions={{
          country: countryCode || LocalStorageService.getItem('country') || [],
        }}
        options={{
          types: ['postal_code'],
        }}
      >
        <input
          className={classes.input}
          autoComplete='chrome-off'
          type={'search'}
          placeholder={placeholder}
          id={label}
          ref={ref}
          onBlur={onBlur}
        />
      </Autocomplete>
    </div>
  );
};
