import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';
import { Sizes } from '../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    accountContainer: {
      paddingTop: 63,
      backgroundColor: colorVariables.white,
      [theme.breakpoints.down(Sizes.desktop)]: {
        padding: '70px 0 0 94px',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: '110px 0 60px 0',
      },
    },
    accountContainerCustomer: {
      [theme.breakpoints.between(Sizes.mobile, Sizes.desktop)]: {
        padding: '50px 0 0 0',
      },
    },
    accountHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      backgroundColor: colorVariables.white,
      height: 127,
      borderBottom: `1px solid ${colorVariables.steelGrey}`,
      paddingBottom: 30,
      paddingLeft: 55,
      paddingRight: 55,
      [theme.breakpoints.down(Sizes.desktop)]: {
        display: 'none',
      },
    },
    accountHeaderNoBorder: {
      border: 'none',
    },
    accountTitle: {
      fontSize: 32,
      lineHeight: '50px',
      fontWeight: 700,
    },
    accountBody: {
      display: 'flex',
    },
    accountContent: {
      width: '80%',
      padding: '25px 200px 50px 50px',
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: '100%',
        padding: '40px 50px',
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        padding: 40,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: 24,
        paddingTop: 0,
      },
    },
    topNavBarTitle: {
      marginLeft: 40,
      [theme.breakpoints.down(Sizes.desktop)]: {
        marginLeft: 0,
        '& button': {
          height: 25,
        },
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        '& button': {
          marginRight: 0,
        },
      },
    },
    title: {
      fontSize: 24,
      lineHeight: '36px',
      fontWeight: 700,
      marginBottom: 30,
      [theme.breakpoints.down(Sizes.desktop)]: {
        display: 'none',
      },
    },
    tabs: {
      display: 'flex',
      borderBottom: `1px solid ${colorVariables.steelGrey15}`,
      [theme.breakpoints.down(Sizes.desktop)]: {
        display: 'none',
      },
    },
    tabsCustomer: {
      display: 'flex',
      borderBottom: `1px solid ${colorVariables.steelGrey15}`,
      [theme.breakpoints.down(Sizes.desktop)]: {
        display: 'flex',
        marginBottom: 30,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    tabLink: {
      display: 'block',
      width: 196,
      color: colorVariables.navy,
      fontSize: 14,
      lineHeight: '22px',
      textAlign: 'center',
      paddingBottom: 10,
      borderBottom: `3px solid ${colorVariables.transparent}`,
      marginBottom: -1,
      transition: 'all ease-in-out 0.2s',
    },
    tabLinkDisabled: {
      opacity: 0.5,
      cursor: 'default',
      pointerEvents: 'none',
    },
    tabLinkActive: {
      borderBottomColor: colorVariables.green,
      fontWeight: 700,
    },
    headerNavBox: {
      marginTop: 7,
      height: 30,
      display: 'flex',
    },
    headerLink: {
      display: 'block',
      marginRight: 20,
      fontSize: 12,
      lineHeight: '30px',
      fontWeight: 600,
      color: colorVariables.grey60,
    },
    headerLinkActive: {
      color: colorVariables.navy,
      borderBottom: `2px solid ${colorVariables.green}`,
    },
    titleBox: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down(Sizes.desktop)]: {
        display: 'none',
      },
    },
    titleWrap: {
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'flex',
      },
    },
    defaultBadge: {
      display: 'inline-block',
      fontSize: 11,
      textTransform: 'uppercase',
      color: colorVariables.grey80,
      fontWeight: 600,
      width: 74,
      height: 24,
      lineHeight: '24px',
      borderRadius: 12,
      textAlign: 'center',
      border: `1px solid ${colorVariables.steelGrey}`,
      marginLeft: 10,
      transform: 'translateX(10px)',
    },
    tabletTitleCustomer: {
      paddingBottom: 20,
      marginBottom: 30,
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        width: '100vw',
        height: 1,
        right: 0,
        left: 0,
        transform: 'translateY(23px)',
        backgroundColor: colorVariables.steelGrey,
      },
    },
    supplierPmBox: {
      paddingTop: 60,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    connectStripBtn: {
      textAlign: 'center',
      width: 200,
      textTransform: 'none',
      fontSize: 14,
      fontWeight: 600,
      height: 40,
      padding: '10px 15px',
      borderRadius: 24,
      borderWidth: 2,
      borderStyle: 'solid',
      lineHeight: '16px',
      marginLeft: 5,
      marginRight: 5,
      color: colorVariables.white,
      backgroundColor: colorVariables.green,
    },
    loginStripBtn: {
      textAlign: 'center',
      display: 'inline-flex',
      width: 200,
      textTransform: 'none',
      alignItems: 'center',
      fontSize: 16,
      fontWeight: 600,
      height: 48,
      padding: '5px 15px',
      borderRadius: 8,
      borderWidth: 2,
      borderStyle: 'solid',
      lineHeight: '16px',
      marginLeft: 5,
      marginRight: 5,
      color: colorVariables.white,
      backgroundColor: '#635bff',
      cursor: 'pointer',
      transition: 'all ease-in-out 0.5s',
      '& span': {
        paddingLeft: 20,
      },
      '&:hover': {
        scale: 1.06,
        opacity: 0.8,
      },
    },
    stripeTitle: {
      fontSize: 18,
      lineHeight: '28px',
      fontWeight: 700,
      marginBottom: 20,
      textAlign: 'center',
    },
    stripeSubText: {
      color: colorVariables.grey80,
      marginBottom: 40,
      textAlign: 'center',
    },
    logo: {
      marginBottom: 30,
    },
    hiddenLink: {
      opacity: 0,
      fontSize: 0,
    },
  }),
);
