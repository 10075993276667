import * as React from 'react';

function SvgProductsNoRes(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 180 180' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        opacity={0.08}
        d='M116.78 134.008c27.092-16.215 33.884-70.8 17.111-90.397-39.673-46.35-52.346 22.116-101.096 24.9-21.062 8.366-34.243 20.906-20.801 43.364 13.442 22.458 77.703 38.342 104.786 22.133z'
        fill='url(#ProductsNoRes_svg__paint0_linear_3559_151321)'
      />
      <g clipPath='url(#ProductsNoRes_svg__clip0_3559_151321)'>
        <path
          opacity={0.42}
          d='M171.968 114.205l-61.251 35.376a5.953 5.953 0 01-6.024 0l-37.882-21.873 73.379-42.37 31.778 18.355c4.043 2.304 4.043 8.167 0 10.512z'
          fill='#CFE3E3'
        />
        <path
          d='M94.667 94.637h-.243a3.2 3.2 0 01-3.194-3.194V48.67a3.2 3.2 0 013.194-3.194h.243a3.2 3.2 0 013.194 3.194v42.774c0 1.739-1.456 3.194-3.194 3.194z'
          fill='#0B6C4F'
        />
        <path
          d='M57.755 87.926l58.219-34.608 43.381 21.59-4.084 33.394-24.824 8.571s-61.978 9.137-63.96 7.925c-2.061-1.213-8.732-36.872-8.732-36.872z'
          fill='#16926C'
        />
        <path
          d='M86.863 138.26c-10.188-5.215-11.846-19.082-3.153-26.522l2.951-1.859 32.667-19.487 27.897 17.546c3.396 2.426 3.072 7.601-.647 9.541l-39.338 20.822c-6.226 3.315-13.706 3.355-19.972.161l-.405-.202z'
          fill='#16926C'
        />
        <path
          d='M117.185 52.59L67.82 79.394a6.653 6.653 0 00-.889.567l-5.458 2.95c-2.345 1.295-4.326 3.72-4.326 5.418-.04 1.699 1.9 2.062 4.245.769l49.405-26.765c.324-.161.607-.364.89-.566l5.458-2.951c2.345-1.294 4.326-3.72 4.326-5.418 0-1.738-1.9-2.062-4.286-.808z'
          fill='#0B6C4F'
        />
        <path
          d='M156.403 77.575L121.674 58.29c-1.658-.93-2.951-3.072-2.83-4.77.121-1.698 1.536-2.345 3.194-1.415l34.729 19.325c1.657.93 2.951 3.073 2.83 4.77-.081 1.658-1.536 2.305-3.194 1.375z'
          fill='#0B6C4F'
        />
        <path d='M92.807 143.112s4.043 3.073 9.339.364c5.417-2.79-5.66-6.105-5.66-6.105l-3.68 5.741z' fill='#15C5E8' />
        <path
          d='M124.018 100.62a3.33 3.33 0 01-3.315-3.315V65.77a3.33 3.33 0 013.315-3.315 3.33 3.33 0 013.316 3.315v31.535a3.304 3.304 0 01-3.316 3.315z'
          fill='#0B6C4F'
        />
        <path
          d='M96.93 46.445l29.474 17.02c1.334.769 1.172 2.144-.364 3.073-1.577.89-3.962 1.011-5.296.203L93.817 51.215l3.114-4.77z'
          fill='#0B6C4F'
        />
        <path
          d='M105.463 115.66c1.576 1.819.889 1.981 2.021 3.356 1.092 1.374.849 1.859-1.294 2.627-2.142.769-2.749.041-4.851-.08-2.103-.162-3.477-1.052-3.032-2.224.444-1.172-2.467-2.143-1.86-2.87 1.657-1.901 7.439-2.628 9.016-.809z'
          fill='#3BC48D'
        />
        <path
          d='M97.578 122.493l.04-11.725-.04-.04-39.823-22.803 7.035 35.578c.485 2.426 1.98 4.529 4.123 5.741l23.894 13.787.162.081c2.022 1.172 4.61-.283 4.61-2.628l.04-14.151.04.041-.04-2.588-.04-1.293zm-25.309 2.062c-2.102-.89-4.043-3.518-4.326-5.863l-1.496-12.897c-.283-2.345 1.213-3.558 3.356-2.668 2.102.889 4.043 3.517 4.326 5.862l1.496 12.897c.242 2.345-1.254 3.518-3.356 2.669zm17.991 5.741c0 2.385-1.617 3.396-3.638 2.264-1.981-1.132-3.639-3.962-3.639-6.348v-12.978c0-2.385 1.617-3.396 3.639-2.264 1.98 1.132 3.638 3.962 3.638 6.348v12.978z'
          fill='url(#ProductsNoRes_svg__paint1_linear_3559_151321)'
        />
        <path
          d='M95.637 112.83L60.18 92.939c-1.698-.97-2.992-3.114-2.91-4.812.12-1.698 1.576-2.304 3.274-1.374l35.457 19.891c1.698.97 2.992 3.113 2.91 4.811-.12 1.698-1.576 2.305-3.274 1.375z'
          fill='#0B6C4F'
        />
        <path
          d='M97.618 110.768v5.903h-.04v23.975c0 2.425 2.627 3.921 4.689 2.708l26.239-15.161v.081l22.681-13.099c2.143-1.253 3.598-3.437 3.881-5.903l4.367-32.829-61.817 34.325zm14.635 19.811c-1.981 1.132-3.639.121-3.639-2.264l-.08-12.978c0-2.386 1.617-5.216 3.598-6.348 1.981-1.132 3.638-.121 3.638 2.264l.081 12.978c0 2.345-1.617 5.216-3.598 6.348zm19.042-14.191c-.04 2.385-1.738 5.175-3.759 6.226-2.022 1.051-3.639 0-3.558-2.345l.323-12.978c.041-2.385 1.739-5.175 3.76-6.226 2.022-1.051 3.639 0 3.558 2.345l-.324 12.978zm16.253-22.156l-.808 12.938c-.162 2.345-1.941 5.094-4.003 6.105-2.062.97-3.639-.122-3.477-2.507l.809-12.937c.161-2.345 1.94-5.094 4.002-6.105 2.062-1.011 3.639.121 3.477 2.506z'
          fill='url(#ProductsNoRes_svg__paint2_linear_3559_151321)'
        />
        <path
          d='M155.23 75.716l-49.405 26.764a6.714 6.714 0 00-.889.566l-5.458 2.952c-2.345 1.293-4.326 3.719-4.326 5.417-.04 1.698 1.9 2.062 4.245.768l49.405-26.764c.324-.162.607-.364.89-.566l5.458-2.951c2.345-1.294 4.326-3.72 4.326-5.418.04-1.698-1.901-2.062-4.246-.768z'
          fill='#0B6C4F'
        />
      </g>
      <defs>
        <linearGradient
          id='ProductsNoRes_svg__paint0_linear_3559_151321'
          x1={23.582}
          y1={131.236}
          x2={143.733}
          y2={59.324}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#33A8E2' />
          <stop offset={1} stopColor='#51D9B0' />
        </linearGradient>
        <linearGradient
          id='ProductsNoRes_svg__paint1_linear_3559_151321'
          x1={98.687}
          y1={143.526}
          x2={57.752}
          y2={143.522}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#1AA37D' />
          <stop offset={1} stopColor='#51D9B0' />
        </linearGradient>
        <linearGradient
          id='ProductsNoRes_svg__paint2_linear_3559_151321'
          x1={161.028}
          y1={143.784}
          x2={97.573}
          y2={143.777}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#1AA37D' />
          <stop offset={1} stopColor='#51D9B0' />
        </linearGradient>
        <clipPath id='ProductsNoRes_svg__clip0_3559_151321'>
          <path fill='#fff' transform='translate(49.345 43.534)' d='M0 0h125.655v106.815H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgProductsNoRes;
