import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Collapse } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { useJsApiLoader } from '@react-google-maps/api';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import clsx from 'clsx';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { useGeIsTeammateAdmin } from '../../../../../api/teammates/hooks';
import {
  useAttachPaymentMethodMutation,
  useMeQuery,
  useSetDefaultPaymentMethodMutation,
  useUpdateStripeCardMutation,
} from '../../../../../api/user';
import { RestaurantUser } from '../../../../../api/user/types';
import { AddressAutocomplete } from '../../../../../shared/components/address-autocomplete';
import { BackButton } from '../../../../../shared/components/back-button';
import { ThemedButton } from '../../../../../shared/components/themed-button';
import { Dialog } from '../../../../../shared/components/dialog';
import { FormikInput } from '../../../../../shared/components/formik-input';
import { PostalCodePlaceAutocomplete } from '../../../../../shared/components/postal-code-place-autocomplete';
import { useScreenSize } from '../../../../../shared/hooks/use-screen-size';
import { LocalStorageService } from '../../../../../shared/services/localStorage.service';
import { ToastService } from '../../../../../shared/services/toastService';
import { useAppSelector } from '../../../../../store';
import { getIsRestaurant } from '../../../../../store/user';
import { colorVariables } from '../../../../../styles/colorVariables';

import { useStyles } from './style';
import { ButtonLoader } from '../../../../../shared/components/button-loader';

export interface Values {
  address_1: string;
  address_2: string;
  city: string;
  state: string;
  postcode: string;
  firstName: string;
  lastName: string;
}

const initValues: Values = {
  address_1: '',
  address_2: '',
  city: '',
  state: '',
  postcode: '',
  firstName: '',
  lastName: '',
};

export const AddPaymentMethod: React.FC = () => {
  const { goBack, push } = useHistory();
  const classes = useStyles();
  const { isMobile } = useScreenSize();
  const isRestaurant = useAppSelector(getIsRestaurant);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
    libraries: ['places'],
  });

  const [cardError, setCardError] = useState<string | null>(null);
  const [isNewAddressOpened, setIsNewAddressOpened] = useState(true);
  const [defaultId, setDefaultId] = useState<null | string>(null);
  const [saveDisabled, setSaveDisabled] = useState(false);

  const { data: user } = useMeQuery();
  const stripe_id = isRestaurant ? (user as RestaurantUser)?.stripe_id : null;
  const isAdminTeammate = useGeIsTeammateAdmin();
  const isViewer = !isAdminTeammate;

  const [updateCard, { isLoading: updateLoading }] = useUpdateStripeCardMutation();
  const [attachCard, { isLoading: attachLoading }] = useAttachPaymentMethodMutation();
  const [setDefault] = useSetDefaultPaymentMethodMutation();

  const stripe = useStripe();
  const elements = useElements();

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Required field'),
    lastName: Yup.string().required('Required field'),
    address_1: Yup.string().required('Required field'),
    city: Yup.string().required('Required field'),
    state: Yup.string().required('Required field'),
    postcode: Yup.string().required('Required field'),
  });

  const openNewAddress = () => {
    setIsNewAddressOpened(!isNewAddressOpened);
  };

  const handleCardChange = (event: any) => {
    if (event.error) {
      setCardError(event.error.message);
    } else {
      setCardError(null);
    }
  };

  const handleSubmit = async (values: Values, formikHelpers: FormikHelpers<any>) => {
    formikHelpers.setSubmitting(false);
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    if (!stripe || !elements) {
      return;
    }
    const cardNumber = elements.getElement(CardNumberElement);
    if (cardError || !cardNumber) {
      return;
    }
    setSaveDisabled(true);
    if (stripe_id) {
      const { paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumber,
        billing_details: {
          name: `${values.firstName} ${values.lastName}`,
          address: {
            city: values.city,
            state: values.state,
            line1: values.address_1,
            line2: values.address_2,
            postal_code: values.postcode,
            country: LocalStorageService.getItem('country') || 'AU',
          },
        },
      });
      paymentMethod &&
        stripe_id &&
        attachCard({ method_id: paymentMethod.id, stripe_id }).then(() => {
          setDefaultId(paymentMethod.id);
        });
      setSaveDisabled(false);
      return;
    }

    const result = await stripe.createToken(cardNumber, {
      name: `${values.firstName} ${values.lastName}`,
      address_line1: values.address_1,
      address_line2: values.address_2,
      address_city: values.city,
      address_state: values.state,
      address_zip: values.postcode,
      address_country: LocalStorageService.getItem('country') as string,
    });

    if (result.error) {
      ToastService.error(result.error.message || 'Invalid account');
      return;
    }

    if (result.token) {
      updateCard({
        stripe_token: result.token.id,
      }).then((res) => {
        if ('data' in res && res.data?.success) {
          push('/account/billing/payment');
        }
      });
    }
    setSaveDisabled(false);
  };

  const baseStyles = {
    fontSize: '16px',
    color: colorVariables.navy,
    fontWeight: '400',
    fontFamily: 'Open Sans, sans-serif',
    '::placeholder': {
      color: colorVariables.steelGrey,
      fontWeight: '400',
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '16px',
    },
  };

  const onSetAddress = (place: google.maps.places.PlaceResult, setFieldValue: any) => {
    const postcode = place.address_components?.find((el) => el.types.includes('postal_code'))?.long_name || '';
    const state = place.address_components?.find((el) => el.types.includes('administrative_area_level_1'))?.long_name || '';
    const city = place.address_components?.find((el) => el.types.includes('locality'))?.long_name || '';
    const street_number = place.address_components?.find((el) => el.types.includes('street_number'))?.long_name || '';
    const route = place.address_components?.find((el) => el.types.includes('route'))?.long_name || '';
    setFieldValue('postcode', postcode);
    setFieldValue('state', state);
    setFieldValue('city', city);
    setFieldValue('address_1', `${street_number} ${route}`.trim() || place.name || '');
  };

  const onSetPostCodeLoc = (place: google.maps.places.PlaceResult, setFieldValue: any) => {
    const postcode = place.address_components?.find((el) => el.types.includes('postal_code'))?.long_name || '';
    if (!postcode) {
      return;
    }
    const state = place.address_components?.find((el) => el.types.includes('administrative_area_level_1'))?.long_name || '';
    const city = place.address_components?.find((el) => el.types.includes('locality'))?.long_name || '';
    setFieldValue('postcode', postcode);
    setFieldValue('state', state);
    setFieldValue('city', city);
  };

  const onConfirmDefault = () => {
    setDefaultId(null);
    stripe_id &&
      defaultId &&
      setDefault({
        stripe_id,
        method_id: defaultId,
      });
    push('/account/billing/payment');
  };

  const onCancelDefault = () => {
    setDefaultId(null);
    push('/account/billing/payment');
  };

  return (
    <div className={classes.root}>
      <BackButton onClickHandler={goBack} isRestaurant={isRestaurant} />
      <div className={classes.addPaymentHead}>
        <div className={classes.addPaymentTitle}>Add payment method</div>
      </div>
      <Formik initialValues={initValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
        {({ submitForm, values, setFieldError, setFieldValue, errors }) => (
          <Form className={classes.formBox}>
            <div className={classes.fieldsBox}>
              <div className={classes.fieldWrap}>
                <div>
                  <div className={classes.label}>Card Number</div>
                  <CardNumberElement
                    onChange={handleCardChange}
                    options={{
                      showIcon: true,
                      style: {
                        base: baseStyles,
                      },
                      classes: {
                        base: classes.baseClass,
                        focus: classes.focus,
                        invalid: classes.invalid,
                      },
                    }}
                  />
                </div>
              </div>
              <div className={clsx(classes.fieldWrap, classes.fieldWrapFlex)}>
                <div>
                  <div className={classes.label}>Expiration Date</div>
                  <CardExpiryElement
                    onChange={handleCardChange}
                    options={{
                      style: {
                        base: baseStyles,
                      },
                      classes: {
                        base: clsx(classes.baseClass, classes.baseClassExp),
                        focus: classes.focus,
                        invalid: classes.invalid,
                      },
                    }}
                  />
                </div>
                <div>
                  <div className={classes.label}>CVC</div>
                  <CardCvcElement
                    onChange={handleCardChange}
                    options={{
                      style: {
                        base: baseStyles,
                      },
                      classes: {
                        base: clsx(classes.baseClass, classes.baseClassCvc),
                        focus: classes.focus,
                        invalid: classes.invalid,
                      },
                    }}
                  />
                </div>
              </div>
              <div className={classes.fieldWrap}>
                <Field name='firstName'>
                  {(fieldProps: FieldProps) => <FormikInput {...fieldProps} label='First Name' placeholder='First Name' />}
                </Field>
              </div>
              <div className={classes.fieldWrap}>
                <Field name='lastName'>
                  {(fieldProps: FieldProps) => <FormikInput {...fieldProps} label='Last Name' placeholder='Last Name' />}
                </Field>
              </div>
            </div>
            <div className={classes.billingAddressBox}>
              <div className={classes.billingAddressTitle}>Select a billing address</div>
              <div className={classes.billingAddressNew} onClick={openNewAddress}>
                <div className={classes.itemTitle}>Add new address</div>
                <ChevronRight className={clsx([classes.chevron, isNewAddressOpened && classes.chevronRotated])} />
              </div>
            </div>
            <Collapse in={isNewAddressOpened} timeout={500}>
              <>
                <div className={classes.fullWidthFieldWrap}>
                  {isLoaded && (
                    <AddressAutocomplete
                      initialValue={values.address_1}
                      label='Address Line 1'
                      placeholder='Address Line 1'
                      autoComplete='chrome-off'
                      setPlace={(gData) => onSetAddress(gData, setFieldValue)}
                      externalError={errors.address_1}
                    />
                  )}
                </div>
                <div className={classes.fullWidthFieldWrap}>
                  <Field name='address_2'>
                    {(fieldProps: FieldProps) => (
                      <FormikInput
                        {...fieldProps}
                        label='Address line 2 (Optional)'
                        placeholder='Address Line 2'
                        autoComplete='chrome-off'
                      />
                    )}
                  </Field>
                </div>
                <div className={classes.smallFieldsBox}>
                  <div className={classes.smallField}>
                    {isLoaded && (
                      <PostalCodePlaceAutocomplete
                        initialValue={values.postcode}
                        setFieldError={setFieldError}
                        placeholder='Postcodе'
                        setPlace={(gData) => onSetPostCodeLoc(gData, setFieldValue)}
                        label='Postcodе'
                        externalError={errors.postcode}
                      />
                    )}
                  </div>
                  <div className={classes.centeredWrap}>
                    <Field name='city'>
                      {(fieldProps: FieldProps) => (
                        <FormikInput {...fieldProps} label='City' placeholder='City' autoComplete='chrome-off' />
                      )}
                    </Field>
                  </div>
                  <div className={classes.smallField}>
                    <Field name='state'>
                      {(fieldProps: FieldProps) => <FormikInput placeholder='State/City' label='State/City' {...fieldProps} />}
                    </Field>
                  </div>
                </div>
                <input type='text' name='postcode' placeholder='postcode' className='hiddenInput' />
              </>
            </Collapse>
            <div className={classes.btnBlock}>
              <ThemedButton
                onClick={submitForm}
                title='Save card'
                width={isMobile ? 150 : 280}
                disabled={!!cardError || updateLoading || attachLoading || saveDisabled}
                endIcon={saveDisabled || updateLoading || attachLoading ? <ButtonLoader /> : null}
              />
            </div>
          </Form>
        )}
      </Formik>
      {!!defaultId && (
        <Dialog
          title={'Would you like to set this card as a default payment method?'}
          onConfirm={onConfirmDefault}
          onCancel={onCancelDefault}
          customClass={classes.dialog}
        />
      )}
    </div>
  );
};
