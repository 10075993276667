import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
    },
    toastTitle: {
      fontSize: 14,
      fontWeight: 700,
      color: colorVariables.white,
      marginBottom: 6,
      fontFamily: '"Open Sans", sans-serif',
    },
    iconWrap: {
      width: 28,
      height: 28,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
      borderRadius: '50%',
      marginRight: 15,
      marginLeft: 4,
    },
    iconWrapSuccess: {
      backgroundColor: colorVariables.green,
    },
    iconWrapError: {
      backgroundColor: '#EA6363',
    },
    iconWrapWarning: {
      backgroundColor: '#FFD54F',
    },
    iconWrapInfo: {
      backgroundColor: '#33A8E2',
    },
    icon: {
      color: colorVariables.navy,
      fontSize: 20,
    },
    toastText: {
      color: colorVariables.grey60,
      lineHeight: '22px',
      fontFamily: '"Open Sans", sans-serif',
    },
    border: {
      position: 'absolute',
      width: 4,
      top: 0,
      bottom: 0,
      left: 0,
    },
    borderSuccess: {
      backgroundColor: colorVariables.green,
    },
    borderError: {
      backgroundColor: '#EA6363',
    },
    borderWarning: {
      backgroundColor: '#FFD54F',
    },
    borderInfo: {
      backgroundColor: '#33A8E2',
    },
    iconRotated: {
      transform: 'rotate(180deg)',
    },
  }),
);
