import * as React from 'react';

function SvgCartIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 31 30' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M12.694 26.188a1.52 1.52 0 100-3.04 1.52 1.52 0 000 3.04z' fill='currentColor' />
      <path d='M12.694 26.188a1.52 1.52 0 100-3.04 1.52 1.52 0 000 3.04z' stroke='currentColor' />
      <path d='M20.88 26.188a1.52 1.52 0 100-3.04 1.52 1.52 0 000 3.04z' fill='currentColor' />
      <path d='M20.88 26.188a1.52 1.52 0 100-3.04 1.52 1.52 0 000 3.04z' stroke='currentColor' />
      <path
        d='M11.974 19.798l-.145-.93h11.047c.41 0 .774-.296.864-.705h0l1.694-8.814a.777.777 0 00-.182-.73.854.854 0 00-.682-.318H10.254l-.147-.986a2.102 2.102 0 00-2.102-1.793H5.66a.865.865 0 100 1.73h2.345c.198 0 .353.139.372.312h0v.002l1.89 12.483h0a2.585 2.585 0 002.557 2.206h9.032a.865.865 0 100-1.73h-9.032c-.413 0-.767-.31-.85-.727zm11.549-9.745l-1.353 7.085H11.578l-1.073-7.085h13.018z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth={0.08}
      />
    </svg>
  );
}

export default SvgCartIcon;
