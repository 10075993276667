import { createStyles, makeStyles } from '@material-ui/core';

import Bg from '../../../../assets/images/bg/Setup_Bg.png';
import { Sizes } from '../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: 780,
      height: 300,
      backgroundImage: `url(${Bg})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top center',
      backgroundSize: 'contain',
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: 500,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 260,
        height: 270,
      },
    },
    box: {
      padding: 0,
    },
    wrap: {
      padding: 50,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '& svg': {
        width: 70,
        height: 67,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: '30px 10px',
      },
    },
    title: {
      fontSize: 20,
      fontWeight: 700,
      marginTop: 20,
      marginBottom: 30,
      maxWidth: '80%',
      textAlign: 'center',
      [theme.breakpoints.down(Sizes.mobile)]: {
        fontSize: 16,
      },
    },
  }),
);
