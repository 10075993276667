import React, { memo, MouseEvent, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import clsx from 'clsx';
import { format, subDays } from 'date-fns';
import { FastField, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import LinesEllipsis from 'react-lines-ellipsis';

import { useGeIsTeammateViewer } from '../../../../../../../api/teammates/hooks';
import { useDeleteWastageMutation, useUpdateWastageMutation } from '../../../../../../../api/wastages';
import { Wastage, WastageReason } from '../../../../../../../api/wastages/types';
import SvgIngredientIcon from '../../../../../../../assets/icons/IngredientIcon';
import SvgSubRecipeIcon from '../../../../../../../assets/icons/SubRecipeIcon';
import { ThemedButton } from '../../../../../../../shared/components/themed-button';
import { DatePickerComponent } from '../../../../../../../shared/components/date-picker';
import { Dialog } from '../../../../../../../shared/components/dialog';
import { DropdownMenu } from '../../../../../../../shared/components/dropdown-menu';
import { FormikInput } from '../../../../../../../shared/components/formik-input';
import { FormikSortDropdown } from '../../../../../../../shared/components/formik-sort-dropdown';
import { MoreButton } from '../../../../../../../shared/components/more-button';
import { formatCurrency } from '../../../../../../../shared/helpers/format-currency';
import { useScreenSize } from '../../../../../../../shared/hooks/use-screen-size';
import { ToastService } from '../../../../../../../shared/services/toastService';
import { wastageReasons } from '../index';

import { useStyles } from './style';

interface Props {
  item: Wastage;
}

export const getWastageReasonCorrectName = (key: string) => {
  switch (key) {
    case 'expired': {
      return wastageReasons[1];
    }
    case 'personal_use': {
      return wastageReasons[2];
    }
    case 'on_the_house': {
      return wastageReasons[4];
    }
    case 'dropped': {
      return wastageReasons[0];
    }
    case 'testing': {
      return wastageReasons[3];
    }
    default: {
      return { label: '', value: ' ' };
    }
  }
};

const Component: React.FC<Props> = ({ item }) => {
  const classes = useStyles();
  const formref = useRef<HTMLFormElement | null>(null);
  const { isMobile } = useScreenSize();

  const [isEditMode, setIsEditMode] = useState(false);
  const [deleteDialogPosition, setDeleteDialogPosition] = useState<{ x: number; y: number } | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const [updateWastage, { isLoading: updateLoading }] = useUpdateWastageMutation();
  const [deleteWastage] = useDeleteWastageMutation();
  const isViewer = useGeIsTeammateViewer('sales_and_wastages');

  const isProduct = item.wastage_type === 'product';

  const onCancelEdit = () => {
    setIsEditMode(false);
    setSelectedDate(null);
    formref.current?.reset();
  };

  const onDeleteWastage = () => {
    deleteWastage(item.id);
    setDeleteDialogPosition(null);
  };

  const deleteOptionHandler = (e: MouseEvent<any>) => {
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    setDeleteDialogPosition({
      x: e.clientX,
      y: e.clientY,
    });
  };

  const onSaveWastage = (
    { qty, reason, reporter }: { qty: number; reason: string; reporter: string },
    formikHelpers: FormikHelpers<any>,
  ) => {
    formikHelpers.setSubmitting(false);
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    updateWastage({
      id: item.id,
      wastage: {
        waste_id: item.waste_item.id,
        quantity: qty,
        wastage_date: selectedDate ? selectedDate.toString() : undefined,
        reason: +reason as WastageReason,
        reporter_name: reporter,
      },
    }).then(() => {
      setIsEditMode(false);
    });
  };

  useEffect(() => {
    setSelectedDate(new Date(item.wastage_date));
  }, [item.wastage_date]);

  return isMobile ? (
    <div className={classes.row}>
      <div className={classes.dateRow}>
        <div className={classes.saleDateText}>{!!item.wastage_date && format(new Date(item.wastage_date), 'd/MM/y')}</div>
        <div className={classes.wasteType}>{isProduct ? 'Product' : 'Recipe'}</div>
        <div className={classes.recipeIdText}>{item.waste_item.code}</div>
        <DropdownMenu
          items={[
            <div key='delete' onClick={deleteOptionHandler}>
              Delete
            </div>,
          ]}
          triggerBtn={<MoreButton customClass={classes.moreDots} />}
        />
      </div>
      <div className={classes.wasteName}>
        <LinesEllipsis text={item.waste_item.name || ''} maxLine={1} />
      </div>
      {isProduct ? (
        <div className={clsx(classes.productInfo, classes.greyText)}>
          <div>{'supplier_name' in item.waste_item && item.waste_item.supplier_name}</div>
          <span>|</span>
          <div>{'price_per_unit' in item.waste_item && formatCurrency(item.waste_item.price_per_unit || 0)}</div>
          <span>|</span>
          <div>{'unit' in item.waste_item && item.waste_item.unit}</div>
        </div>
      ) : (
        <div className={classes.greyText}>{'menu_category' in item.waste_item && item.waste_item.menu_category}</div>
      )}
      <div className={classes.wasteInfo}>
        <div>
          <div className={classes.wasteInfoLabel}>QTY</div>
          <div className={classes.qtyTextM}>{item.quantity}</div>
        </div>
        <div>
          <div className={classes.wasteInfoLabel}>Reason</div>
          <div>{getWastageReasonCorrectName(item.reason).label}</div>
        </div>
        <div>
          <div className={classes.wasteInfoLabel}>REporter</div>
          <div>{item.reporter_name || ''}</div>
        </div>
      </div>
      {deleteDialogPosition &&
        createPortal(
          <Dialog
            style={{
              left: 20,
              top: deleteDialogPosition ? deleteDialogPosition.y - 140 : '45%',
              width: 320,
              minHeight: 140,
            }}
            onCancel={setDeleteDialogPosition.bind(null, null)}
            onConfirm={onDeleteWastage}
            title='Are you sure you want to delete this wastage?'
          />,
          document.body,
        )}
    </div>
  ) : (
    <Formik
      initialValues={{
        qty: item.quantity || 0,
        reason: getWastageReasonCorrectName(item.reason).value,
        reporter: item.reporter_name || '',
      }}
      onSubmit={onSaveWastage}
      enableReinitialize={true}
    >
      {({ submitForm, values }) => (
        <Form ref={formref}>
          <div className={clsx(classes.row, isEditMode && classes.rowEditMode)}>
            <div className={classes.date}>
              <div>
                {isEditMode ? (
                  <div className={classes.dateWrap}>
                    <DatePickerComponent
                      onDateChange={(date) => {
                        setSelectedDate(date);
                      }}
                      minDate={subDays(new Date(), 360)}
                      selectedDate={selectedDate}
                      trigger={
                        <div className={classes.dateTrigger}>
                          {selectedDate ? format(selectedDate, 'd/MM/y') : format(new Date(item.wastage_date), 'd/MM/y')}
                          <KeyboardArrowDownIcon />
                        </div>
                      }
                    />
                  </div>
                ) : (
                  <div className={classes.saleDateText}>{!!item.wastage_date && format(new Date(item.wastage_date), 'd/MM/y')}</div>
                )}
              </div>
            </div>
            <div className={classes.recipe}>
              <div className={classes.recipeName}>{item.waste_item.name}</div>
              {isProduct ? (
                <div className={clsx(classes.productInfo, classes.greyText)}>
                  <div>{'supplier_name' in item.waste_item && item.waste_item.supplier_name}</div>
                  <span>|</span>
                  <div>{'price_per_unit' in item.waste_item && formatCurrency(item.waste_item.price_per_unit || 0)}</div>
                  <span>|</span>
                  <div>{'unit' in item.waste_item && item.waste_item.unit}</div>
                </div>
              ) : (
                <div className={classes.greyText}>{'menu_category' in item.waste_item && item.waste_item.menu_category}</div>
              )}
            </div>
            <div className={classes.recipeId}>
              <div className={classes.recipeIdText}>{item.waste_item.code}</div>
            </div>
            <div className={classes.sold}>
              {isEditMode ? (
                <div className={classes.cellInputWrap}>
                  <FastField name='qty'>
                    {(fieldProps: FieldProps) => <FormikInput {...fieldProps} placeholder={`0`} label='' height={40} type='number' />}
                  </FastField>
                </div>
              ) : (
                <div className={classes.soldText}>{item.quantity}</div>
              )}
            </div>
            <div className={classes.reason}>
              {isEditMode ? (
                <FormikSortDropdown
                  name='reason'
                  options={wastageReasons}
                  customSelectFormControlClass={classes.formControl}
                  placeholder={'Select Reason'}
                  submitOnChange={false}
                />
              ) : (
                <div className={classes.reasonText}>{getWastageReasonCorrectName(item.reason).label}</div>
              )}
            </div>
            <div className={classes.reporter}>
              {isEditMode ? (
                <div className={classes.cellInputWrapReporter}>
                  <FastField name='reporter'>
                    {(fieldProps: FieldProps) => (
                      <FormikInput {...fieldProps} placeholder={`Enter Name`} label='' height={24} inputFontSize={14} />
                    )}
                  </FastField>
                </div>
              ) : (
                <div>{item.reporter_name || ''}</div>
              )}
            </div>

            <div className={classes.type}>
              {isEditMode ? (
                <div className={classes.addBtnCell}>
                  <ThemedButton
                    onClick={onCancelEdit}
                    title='Cancel'
                    buttonStyle='secondary'
                    className={classes.addActionBtn}
                    width={98}
                    type='reset'
                  />
                  <ThemedButton
                    onClick={submitForm}
                    title='Save'
                    className={classes.addActionBtn}
                    width={98}
                    disabled={updateLoading || !+values.qty}
                  />
                </div>
              ) : (
                <>
                  {isProduct ? (
                    <div className={classes.typeBox}>
                      <SvgIngredientIcon width={24} height={24} />
                      <span>Product</span>
                    </div>
                  ) : (
                    <div className={classes.typeBox}>
                      <SvgSubRecipeIcon width={24} height={24} />
                      <span>Recipe</span>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className={classes.actions}>
              {!isEditMode && (
                <DropdownMenu
                  items={[
                    <div
                      key='edit'
                      onClick={() => {
                        if (isViewer) {
                          ToastService.error('You have been restricted from making edits.');
                          return;
                        }
                        setIsEditMode(true);
                      }}
                    >
                      Edit
                    </div>,
                    <div key='delete' onClick={deleteOptionHandler}>
                      Delete
                    </div>,
                  ]}
                  triggerBtn={<MoreButton customClass={classes.moreDots} />}
                />
              )}
              {deleteDialogPosition &&
                createPortal(
                  <Dialog
                    style={{
                      left: deleteDialogPosition ? deleteDialogPosition.x - 300 : 20,
                      top: deleteDialogPosition ? deleteDialogPosition.y - 140 : '45%',
                      width: 320,
                      minHeight: 140,
                    }}
                    onCancel={setDeleteDialogPosition.bind(null, null)}
                    onConfirm={onDeleteWastage}
                    title='Are you sure you want to delete this wastage?'
                  />,
                  document.body,
                )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export const WastageTableRow = memo(Component);
