import React from 'react';

import { TimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import clsx from 'clsx';

import { useStyles } from './style';

interface Props {
  time?: Date | null;
  onSetTime: (time: Date) => void;
  error?: string;
}

export const CustomTimePicker: React.FC<Props> = ({ time, onSetTime, error }) => {
  const classes = useStyles();

  const handleChangeTime = (value: MaterialUiPickersDate) => {
    onSetTime(value as Date);
  };
  return (
    <div className={clsx(classes.root, !time && classes.rootEmpty)}>
      {error && <div className={classes.toolTip}>{error}</div>}
      <TimePicker clearable={false} ampm={false} value={time} onChange={handleChangeTime} inputVariant='outlined' emptyLabel='--:--' />
    </div>
  );
};
