import { createStyles, makeStyles } from '@material-ui/core';

import { Sizes } from '../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    modalRoot: {
      width: 780,
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: 500,
        minHeight: 600,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 300,
        minHeight: 550,
      },
    },
    modalWrap: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    doneIconModal: {
      width: 48,
      height: 48,
    },
    modalTitle: {
      fontSize: 20,
      fontWeight: 700,
      marginTop: 20,
      marginBottom: 25,
      maxWidth: '80%',
      textAlign: 'center',
      [theme.breakpoints.down(Sizes.mobile)]: {
        fontSize: 16,
      },
    },
    modalDesc: {
      marginBottom: 30,
      textAlign: 'center',
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginBottom: 20,
      },
    },
    btnBox: {
      marginTop: 30,
      display: 'flex',
      [theme.breakpoints.down(Sizes.mobile)]: {
        flexDirection: 'column-reverse',
        '& button': {
          marginBottom: 20,
        },
      },
    },
    selectWrap: {
      width: 300,
    },
  }),
);
