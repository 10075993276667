import * as React from 'react';

function SvgHospitalityCategory(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 23 40' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M17.043 13.331l.002-.035c-1.091-.13-1.943-.597-2.522-1.352-.58-.757-.89-1.807-.89-3.105 0-1.722.53-3.477 1.307-4.8.389-.663.838-1.215 1.311-1.601.474-.386.969-.604 1.45-.604.5 0 1.003.218 1.48.604.477.386.923.938 1.307 1.6.768 1.323 1.28 3.079 1.28 4.8 0 1.3-.292 2.35-.854 3.106-.56.755-1.393 1.223-2.484 1.352h0l-.009.002a.975.975 0 00-.32.156c-.09.07-.157.165-.157.284v.001l.662 23.71v.001a.66.66 0 01-.268.547c-.162.123-.38.186-.601.186-.221 0-.44-.063-.602-.185a.661.661 0 01-.267-.548s0 0 0 0l.588-23.71h0v-.002c0-.229-.173-.372-.403-.44l-.01.033zM22.75 8.84c0-1.805-.535-3.774-1.431-5.292C20.424 2.03 19.16.95 17.7.95c-1.423 0-2.686 1.08-3.59 2.596-.906 1.519-1.46 3.487-1.46 5.293 0 1.182.259 2.368.872 3.325.608.95 1.563 1.671 2.95 1.941l-.587 23.277s0 0 0 0c-.018.555.205.974.55 1.253.343.278.805.415 1.265.415.46 0 .922-.137 1.265-.415.346-.28.569-.698.55-1.253 0 0 0 0 0 0L19 14.105c1.388-.27 2.325-.992 2.915-1.941.594-.958.834-2.144.834-3.325zM6.696 1.392v.05h0v7.604h-.934V1.392a.4.4 0 00-.17-.334.613.613 0 00-.357-.108.613.613 0 00-.358.108.4.4 0 00-.17.334v7.654H3.695V1.392a.411.411 0 00-.156-.333.536.536 0 00-.332-.109.536.536 0 00-.332.109.411.411 0 00-.155.333v7.654H1.705V1.392a.4.4 0 00-.17-.334.613.613 0 00-.358-.108.613.613 0 00-.357.108.4.4 0 00-.17.334v10.055c0 1.191.879 2.244 2.226 2.648l-.635 23.336s0 0 0 0c-.02.541.223.948.595 1.218.371.268.87.401 1.364.401.496 0 .994-.133 1.365-.401.372-.27.615-.677.594-1.218 0 0 0 0 0 0l-.635-23.336c1.348-.405 2.226-1.457 2.226-2.648V1.392a.4.4 0 00-.17-.334.613.613 0 00-.357-.108.613.613 0 00-.358.108.4.4 0 00-.17.334zM4.907 13.357h0-.004a.678.678 0 00-.311.186.399.399 0 00-.123.255h0l.636 23.636h0v.003a.59.59 0 01-.244.544 1.07 1.07 0 01-.63.185c-.236 0-.472-.063-.647-.186a.646.646 0 01-.288-.546s0 0 0 0l.636-23.635h0v-.002a.435.435 0 00-.11-.251.64.64 0 00-.239-.187h0l-.01-.003c-1.096-.192-1.868-1.025-1.868-1.91V9.93h4.991v1.517c0 .885-.773 1.717-1.789 1.91z'
        fill='#51D9B0'
        stroke='#51D9B0'
        strokeWidth={0.1}
      />
    </svg>
  );
}

export default SvgHospitalityCategory;
