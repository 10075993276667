import { createReducer } from '@reduxjs/toolkit';

import { AdminDiscountSortBy, DiscountFilter } from '../../../api/admin-discounts/types';
import { logoutThunk } from '../../user';
import { setAdminDiscountsKeyword, setAdminDiscountsSortFilter, setSelectedDiscountsIds } from './discounts.actions';

interface State {
  keyword?: string;
  selectedIds: number[];
  filter?: DiscountFilter;
  sort?: AdminDiscountSortBy;
}

const initialState: State = {
  keyword: undefined,
  selectedIds: [],
};

export const adminDiscountsReducer = createReducer(initialState, (builder) => {
  builder.addCase(logoutThunk.fulfilled, () => {
    return initialState;
  });
  builder.addCase(setAdminDiscountsKeyword, (state, { payload }) => {
    if (state.keyword === payload) {
      return;
    }
    state.keyword = payload;
  });
  builder.addCase(setSelectedDiscountsIds, (state, { payload }) => {
    state.selectedIds = payload;
  });
  builder.addCase(setAdminDiscountsSortFilter, (state, { payload }) => {
    state.filter = payload.filter;
    state.sort = payload.sort;
  });
});
