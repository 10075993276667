import * as React from 'react';

function SvgBakery(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 38 35' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M27.37 34.105H3.79a.689.689 0 01-.69-.69v-21.18C1.043 11.537 0 10.394 0 8.828 0 4.87 6.844 1.772 15.579 1.772c8.736 0 15.581 3.1 15.581 7.056 0 1.564-1.043 2.71-3.1 3.407v21.181c0 .381-.308.69-.69.69zm-22.89-1.38h22.2V11.728c0-.309.205-.579.502-.664 2.33-.66 2.598-1.591 2.598-2.236 0-2.74-5.706-5.675-14.2-5.675-8.495 0-14.2 2.934-14.2 5.675 0 .645.27 1.577 2.599 2.236a.69.69 0 01.501.664v20.999z'
        fill='#51D9B0'
      />
      <path
        d='M27.37 34.105a.69.69 0 01-.309-1.306l3.161-1.58V9.954a.69.69 0 01.501-.664c2.33-.659 2.6-1.591 2.6-2.235 0-2.742-5.706-5.676-14.2-5.676-4.6 0-8.957.936-11.655 2.506a.69.69 0 01-.693-1.194C9.718.98 14.218 0 19.123 0c8.735 0 15.579 3.098 15.579 7.055 0 1.566-1.043 2.71-3.1 3.408v21.18c0 .262-.148.5-.381.618l-3.542 1.771a.671.671 0 01-.308.073zM34.013 30.141a.69.69 0 01-.69-.69v-1.897a.69.69 0 111.38 0v1.897a.69.69 0 01-.69.69z'
        fill='#51D9B0'
      />
      <path
        d='M34.013 25.484a.69.69 0 01-.69-.69V11.69c0-.29.18-.548.452-.648 2.968-1.09 2.848-3.764 2.841-3.877a.691.691 0 01.644-.73.672.672 0 01.733.637c.086 1.247-.51 3.828-3.29 5.082v12.642c0 .38-.31.69-.69.69zM17.484 27.64a.689.689 0 01-.305-1.307c.671-.33 3.046-2.556 3.253-3.36-.186-.042-.698-.072-1.903.218-5.345 1.28-7.122 1.339-7.629.241-.4-.87.177-2.006 4.277-4.973 2.749-1.987 3.573-2.914 3.822-3.298-.527-.059-2.129.053-6.778 1.45-1.48.446-2.915.731-3.383-.195-.213-.422-.858-1.706 6.708-7.348a.684.684 0 01.965.14.689.689 0 01-.14.965c-2.603 1.942-5.44 4.415-6.117 5.47.278-.023.76-.108 1.57-.352 6.511-1.959 8.067-1.778 8.547-.802.431.872-.15 2.028-4.384 5.089-2.73 1.975-3.502 2.872-3.722 3.23.512.088 1.955-.002 5.942-.959 1.425-.343 3.123-.587 3.548.588.637 1.763-3.437 4.872-3.964 5.132a.688.688 0 01-.307.072z'
        fill='#51D9B0'
      />
    </svg>
  );
}

export default SvgBakery;
