import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import MuiTextField from '@material-ui/core/TextField';
import { Close } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import uniqBy from 'lodash/uniqBy';
import { useDebouncedCallback } from 'use-debounce';
import { useSendConnectionRequestMutation } from '../../../../api/company';
import { useGetSupplierListQuery } from '../../../../api/supplier';
import { AddButton } from '../../../../shared/components/add-button';
import { BlurredImage } from '../../../../shared/components/blurred-image';
import { ProductsCategories } from '../../../../shared/constants/products';
import { getCategoryId } from '../../../../shared/helpers/getCategoryId';
import { LocalStorageService } from '../../../../shared/services/localStorage.service';
import { ToastService } from '../../../../shared/services/toastService';
import { useAppDispatch } from '../../../../store';
import { setSelectedSupplier } from '../../../../store/suppliers-categories/suppliers-categories.actions';

import { useStyles } from './style';

interface Props {
  category: ProductsCategories | null;
  onboarded?: boolean;
  location?: {
    lat: number;
    lng: number;
  } | null;
  openNextCategory?: () => void;
  handleClick?: (e: any) => void;
}

export const BusinessDropdown: React.FC<Props> = ({
  category,
  location,
  onboarded,
  openNextCategory = () => {},
  handleClick = () => {},
}) => {
  const classes = useStyles();
  const ref = useRef<HTMLDivElement | null>(null);
  const divRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();

  const [inputValue, setInputValue] = useState(LocalStorageService.getItem('manualSupplierName') || '');
  const [reqValue, setReqValue] = useState('');
  const [selectedValue, setSelectedValue] = useState<number | null>(null);
  const [list, setList] = useState<{ id: number; name: string; address?: string; logoUrl?: string; manual?: boolean }[]>([]);
  const [loading, setLoading] = useState(false);

  const [sendRequest] = useSendConnectionRequestMutation();

  const { data, isFetching, isSuccess } = useGetSupplierListQuery(
    {
      category_id: !category ? undefined : getCategoryId(category),
      keyword: reqValue,
      sort_by: 'most_sales desc',
      lat: location?.lat,
      lng: location?.lng,
      all_supplier: true,
      page: 1,
      per_page: 50,
    },
    { skip: !reqValue },
  );

  const onSendRequest = (supplierId: number, companyName?: string) => {
    sendRequest({
      showSuccessModal: false,
      connection_request: {
        supplier_id: supplierId,
        connection_categories_attributes: [
          {
            category_id: category ? getCategoryId(category) : undefined,
          },
        ],
      },
    }).then((res) => {
      if ('data' in res && res.data?.success) {
        openNextCategory();
        ToastService.success(`You have added ${companyName}`, companyName, 'Supplier invite sent');
        setInputValue('');
        setSelectedValue(null);
      } else {
        setInputValue('');
        setSelectedValue(null);
      }
    });
  };

  const onInputChange = (event: ChangeEvent<{}>, newInputValue: string) => {
    setInputValue(newInputValue);
  };

  const onSelect = (event: any, newValue: string | null) => {
    const supplier = list?.find((el) => newValue === el.name);
    setSelectedValue(supplier?.id || null);
    setInputValue(supplier?.name || '');
    if (supplier?.manual === false && supplier.id > 0 && !onboarded) {
      onSendRequest(supplier.id, supplier?.name);
    }
    if (onboarded && newValue) {
      dispatch(setSelectedSupplier(data?.suppliers?.find((el) => el.id === supplier?.id) || null));
      divRef?.current?.click();
      supplier && LocalStorageService.setItem('manualSupplierInfo', supplier);
    }
  };

  const onDebouncedSearch = useDebouncedCallback((search: string) => {
    setList([]);
    setReqValue(search);
  }, 300);

  useEffect(() => {
    if (!reqValue.trim() || reqValue.length < 2) {
      return;
    }
    try {
      setLoading(true);
      fetch(`https://places.googleapis.com/v1/places:searchText`, {
        method: 'POST',
        body: JSON.stringify({
          textQuery: reqValue,
          regionCode: LocalStorageService.getItem('country') || 'AU',
        }),
        headers: {
          'X-Goog-FieldMask':
            'places.displayName.text,places.shortFormattedAddress,places.addressComponents,places.formattedAddress,places.types',
          'X-Goog-Api-Key': process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res?.places?.length) {
            const notOnSystemSuppliers =
              res.places.reduce(
                (
                  acc: { id: number; name: string; address?: string }[],
                  el: { displayName: { text: string }; formattedAddress?: string; addressComponents?: any[]; types?: string[] },
                  idx: number,
                ) => {
                  const countryFromApiItem = el.addressComponents?.find((item) => item.types?.includes('country'))?.shortText;
                  if (
                    countryFromApiItem === LocalStorageService.getItem('country') &&
                    (el.types?.includes('wholesaler') || el.types?.includes('establishment') || el.types?.includes('market'))
                  ) {
                    acc.push({
                      id: -1 - idx,
                      name: el.displayName?.text,
                      address: el.formattedAddress,
                    });
                  }
                  return acc;
                },
                [],
              ) || [];

            setList((prev) => uniqBy([...prev, ...notOnSystemSuppliers], 'name'));
          }
          setLoading(false);
        });
    } catch (e) {
      console.log(e);
    }
  }, [reqValue]);

  useEffect(() => {
    onDebouncedSearch(inputValue);
  }, [inputValue]);

  useEffect(() => {
    setInputValue('');
    setSelectedValue(null);
    setReqValue('');
    document.getElementById('search-sale-box')?.querySelector('input')?.focus();
  }, [category]);
  useEffect(() => {
    const onSystemSuppliers =
      data?.suppliers
        ?.map((el) => ({
          id: el.id,
          name: el.company?.name,
          logoUrl: el.company?.picture_url || undefined,
          address: `${el.company_addresses[0]?.city || ''} ${el.company_addresses[0]?.state || ''} ${
            el.company_addresses[0]?.postcode || ''
          }`,
          manual: el.manual_supplier,
        }))
        ?.filter((s) => !s.manual) || [];
    setList((prev) => uniqBy([...onSystemSuppliers, ...prev], 'name'));
  }, [data, inputValue]);

  return (
    <div className={classes.searchContainer} id='search-sale-box'>
      <div className={classes.searchWrap}>
        <div className={classes.label}>Supplier Company Name*</div>
        <Autocomplete
          clearOnBlur={false}
          clearOnEscape={false}
          blurOnSelect={true}
          loading={loading || isFetching || !isSuccess}
          filterOptions={() => {
            return list.map((el) => el.name);
          }}
          popupIcon={null}
          onBlur={() => {
            LocalStorageService.setItem('manualSupplierName', inputValue.trim());
          }}
          value={list?.find((el) => el.id === selectedValue)?.name}
          onChange={onSelect}
          inputValue={inputValue}
          closeIcon={
            <div ref={ref} className={classes.closeIcon}>
              <Close />
            </div>
          }
          onInputChange={onInputChange}
          renderOption={(option) => {
            const supplier = list?.find((rec) => rec.name === option);
            return supplier ? (
              <div className={classes.optionWrap} key={supplier.id}>
                <div>
                  <div className={classes.nameSection}>{supplier.name}</div>
                  <div className={classes.addressSection}>{supplier.address}</div>
                </div>
                {supplier.logoUrl && (
                  <div className={classes.imgWrap}>
                    <BlurredImage src={supplier.logoUrl} />
                  </div>
                )}
                <div className={classes.addBtnSupplier}>
                  <AddButton
                    isDark={true}
                    onClick={
                      onboarded
                        ? () => {
                            dispatch(setSelectedSupplier(data?.suppliers?.find((el) => el.id === supplier.id) || null));
                            divRef?.current?.click();
                          }
                        : undefined
                    }
                  />
                </div>
              </div>
            ) : null;
          }}
          classes={{
            root: classes.rootField,
            paper: clsx(classes.searchBox, !inputValue && !loading && classes.searchBoxHidden),
            noOptions: classes.noOptions,
            loading: classes.noOptions,
            option: classes.option,
          }}
          noOptionsText={
            !loading && !isFetching ? (
              inputValue ? (
                <div className={classes.noResblock}>
                  <span>{`No Results - Use ${inputValue}`}</span>
                  <AddButton isDark={true} />
                </div>
              ) : (
                'Please type supplier name'
              )
            ) : null
          }
          options={list?.map((el) => el.name) || []}
          renderInput={(params) => (
            <MuiTextField
              {...params}
              placeholder={'Supplier name'}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <svg
                    width='22'
                    height='22'
                    viewBox='0 0 22 22'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    className={classes.searchIcon}
                  >
                    <path
                      d='M10.1643 18.3287C5.37892 18.3287 1.5 14.4497 1.5 9.66433C1.5 4.87892 5.37892 1 10.1643 1C14.9497 1 18.8287 4.87892 18.8287 9.66433C18.8287 14.4497 14.9497 18.3287 10.1643 18.3287Z'
                      stroke='#98A5B7'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M21.0001 20.5001L16.668 16.168'
                      stroke='#98A5B7'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                ),
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
      </div>
      <div onClick={handleClick} ref={divRef} />
    </div>
  );
};
