import { createReducer } from '@reduxjs/toolkit';

import { CustomerFilterValues, CustomersSortBy } from '../../api/customer/types';
import { logoutThunk } from '../user';
import {
  resetCustomersFilters,
  setCustomersFilterBy,
  setCustomersKeyword,
  setCustomersPage,
  setCustomersSortBy,
  setCustomersState,
} from './customers.actions';

interface State {
  sortBy?: CustomersSortBy;
  filterBy?: CustomerFilterValues;
  keyword?: string;
  currentPage: number;
}

const initialState: State = {
  sortBy: undefined,
  filterBy: undefined,
  keyword: undefined,
  currentPage: 1,
};

export const customersReducer = createReducer(initialState, (builder) => {
  builder.addCase(logoutThunk.fulfilled, () => {
    return initialState;
  });
  builder.addCase(setCustomersState, (state, { payload }) => {
    return payload as State;
  });
  builder.addCase(setCustomersSortBy, (state, { payload }) => {
    state.sortBy = payload;
    state.currentPage = 1;
  });
  builder.addCase(setCustomersFilterBy, (state, { payload }) => {
    state.filterBy = payload;
    state.currentPage = 1;
  });
  builder.addCase(setCustomersKeyword, (state, { payload }) => {
    if (state.keyword === payload.keyword) {
      return;
    }
    state.keyword = payload.keyword;
    state.currentPage = 1;
  });
  builder.addCase(setCustomersPage, (state, { payload }) => {
    state.currentPage = payload;
  });
  builder.addCase(resetCustomersFilters, (state, { payload }) => {
    if (payload.page) {
      state.currentPage = 1;
    }
    if (payload.filter) {
      state.filterBy = undefined;
    }
    if (payload.sort) {
      state.sortBy = undefined;
    }
  });
});
