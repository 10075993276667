import { useMediaQuery } from 'react-responsive';

export const useScreenSize = (): {
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;
  isTabletPortrait: boolean;
  isTabletLandscape: boolean;
} => {
  const isMobile = useMediaQuery({ maxWidth: 699 });
  const isTablet = useMediaQuery({ minWidth: 700, maxWidth: 1279 });
  const isTabletPortrait = useMediaQuery({ minWidth: 700, maxWidth: 979 });
  const isTabletLandscape = useMediaQuery({ minWidth: 980, maxWidth: 1279 });
  const isDesktop = useMediaQuery({ minWidth: 1280 });
  return {
    isDesktop,
    isTablet,
    isMobile,
    isTabletPortrait,
    isTabletLandscape,
  };
};
