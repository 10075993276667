import { createEntityAdapter, createReducer, EntityState } from '@reduxjs/toolkit';

import isEqual from 'lodash/isEqual';
import { wastagesApi } from '../../api/wastages';
import { Wastage, WastageFilters, WastageSortBy } from '../../api/wastages/types';
import { RangeOptions } from '../../shared/components/range-dropdown';
import { logoutThunk } from '../user/user.actions';
import { clearWastageList, setWastageKeyword, setWastagePage, setWastageSortFilters } from './wastage.actions';

export const wastageAdapter = createEntityAdapter<Wastage>();

interface State {
  list: EntityState<Wastage>;
  currentPage: number;
  sortBy?: WastageSortBy;
  filter?: WastageFilters;
  keyword?: string;
}

const initialState: State = {
  list: wastageAdapter.getInitialState(),
  currentPage: 1,
  sortBy: undefined,
  keyword: undefined,
  filter: {
    selectedRange: RangeOptions.TODAY,
  },
};

export const wastageReducer = createReducer(initialState, (builder) => {
  builder.addCase(logoutThunk.fulfilled, () => {
    return initialState;
  });
  builder.addCase(setWastagePage, (state, { payload }) => {
    state.currentPage = payload;
  });
  builder.addCase(setWastageSortFilters, (state, { payload }) => {
    if (!isEqual(payload.filter, state.filter) || !isEqual(payload.sortBy, state.sortBy)) {
      state.list = wastageAdapter.getInitialState();
      state.sortBy = payload.sortBy;
      state.filter = payload.filter;
      state.currentPage = 1;
    }
  });
  builder.addCase(setWastageKeyword, (state, { payload }) => {
    if (state.keyword === payload) {
      return;
    }
    state.keyword = payload;
    state.currentPage = 1;
    state.list = wastageAdapter.getInitialState();
  });
  builder.addCase(clearWastageList, (state, { payload }) => {
    state.currentPage = 1;
    state.list = wastageAdapter.getInitialState();
    state.filter = payload ? { date: new Date().toString() } : { selectedRange: undefined };
    state.sortBy = undefined;
  });
  builder.addMatcher(wastagesApi.endpoints.getWastages.matchFulfilled, (state, action) => {
    state.list = wastageAdapter.setMany(state.list, action.payload.wastages);
  });
  builder.addMatcher(wastagesApi.endpoints.createWastage.matchFulfilled, (state) => {
    state.list = wastageAdapter.getInitialState();
    state.currentPage = 1;
  });
  builder.addMatcher(wastagesApi.endpoints.deleteWastage.matchFulfilled, (state, action) => {
    state.list = wastageAdapter.removeOne(state.list, action.meta.arg.originalArgs);
  });
  builder.addMatcher(wastagesApi.endpoints.updateWastage.matchFulfilled, (state, action) => {
    state.list = wastageAdapter.setOne(state.list, action.payload.wastage);
  });
});
