import React from 'react';

import clsx from 'clsx';

import { useStyles } from './style';

interface Props {
  text: string;
  className?: string;
  right?: boolean;
  width?: number;
  rightSide?: boolean;
  topArrow?: boolean;
}

export const InfoTooltipComponent: React.FC<Props> = ({ text, right, rightSide, children, width, topArrow, className }) => {
  const classes = useStyles({ right, width });

  return (
    <div className={clsx(classes.box, className)}>
      <div className={classes.trigger}>{children}</div>
      <div className={classes.root}>
        <div className={clsx(topArrow ? classes.toolTipTop : classes.toolTip, rightSide && classes.rightSideArrow)}>{text}</div>
      </div>
    </div>
  );
};
