import { createAction } from '@reduxjs/toolkit';

import { CustomerFilterValues, CustomersSortBy } from '../../api/customer/types';

export const setCustomersSortBy = createAction<CustomersSortBy | undefined>('SET_CUSTOMERS_SORT_BY');

export const setCustomersFilterBy = createAction<CustomerFilterValues | undefined>('SET_CUSTOMERS_FILTER_BY');

export const setCustomersPage = createAction<number>('SET_CUSTOMERS_PAGE');

export const setCustomersKeyword = createAction<{ keyword?: string }>('SET_CUSTOMERS_KEYWORD');

export const setCustomersState = createAction<any>('SET_CUSTOMERS_STATE');

export const resetCustomersFilters = createAction<{ page?: boolean; filter?: boolean; sort?: boolean }>('RESET_CUSTOMERS_FILTERS');
