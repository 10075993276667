import * as React from 'react';

function SvgProfileIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M10.476 4.497h0v0c0 1.055-.87 1.924-1.971 1.924-1.083 0-1.972-.852-1.972-1.923 0-1.055.872-1.924 1.972-1.924s1.989.87 1.971 1.923zm-5.223 0h0v0c0 1.759 1.454 3.177 3.252 3.177 1.797 0 3.252-1.418 3.252-3.159 0-1.758-1.473-3.176-3.252-3.176-1.78 0-3.234 1.418-3.252 3.158zM14.154 14.037h0v-.002c0-3.208-2.653-5.797-5.936-5.797-3.282 0-5.935 2.607-5.935 5.797 0 .343.292.626.64.626.349 0 .64-.283.64-.626 0-2.504 2.088-4.544 4.655-4.544 2.568 0 4.655 2.04 4.655 4.544 0 .343.292.626.64.626.35 0 .622-.267.64-.624z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth={0.1}
      />
    </svg>
  );
}

export default SvgProfileIcon;
