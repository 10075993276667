import { CustomPermissionOption } from '../../components/AccountDrawer/Teammates/custom-permission-modal';

export const extractPermissionOption = (inputString?: string | null): CustomPermissionOption => {
  if (!inputString) {
    return CustomPermissionOption.VIEW;
  }
  // Use the regex pattern to match the last word in the inputString
  const regexPattern: RegExp = /[^_]+$/;

  // Use the exec method to extract the last word
  const match: RegExpExecArray | null = regexPattern.exec(inputString);

  // Check if a match is found and return the last word (group 0) or an empty string if no match is found
  return match ? (match[0] as CustomPermissionOption) : CustomPermissionOption.VIEW;
};

export const getOptionForRequest = (option?: CustomPermissionOption) => {
  switch (option) {
    case CustomPermissionOption.VIEW: {
      return 0;
    }
    case CustomPermissionOption.EDIT: {
      return 1;
    }
    case CustomPermissionOption.HIDDEN: {
      return 2;
    }
    default: {
      return 0;
    }
  }
};
