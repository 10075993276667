import { createStyles, makeStyles } from '@material-ui/core';

import AuthDots from '../../../assets/images/bg/Auth_bg_dots.svg';
import AuthLines from '../../../assets/images/bg/Auth_bg_lines.svg';

import { colorVariables } from '../../../styles/colorVariables';
import { Sizes } from '../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flex: 1,
      minHeight: '100vh',
      height: '100%',
      padding: '50px 80px 20px 80px',
      position: 'relative',
      backgroundColor: 'rgba(207, 215, 230, 0.3)',
      background: 'linear-gradient rgba(255, 255, 255, 1) 100%, rgba(255, 255, 255, 0) 0%)',
      backgroundImage: `url(${AuthLines}), url(${AuthDots})`,
      backgroundRepeat: 'no-repeat, no-repeat',
      backgroundPosition: 'top 126px left 0px, bottom 250px right 0px',
      [theme.breakpoints.down(Sizes.desktop)]: {
        overflowY: 'scroll',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        backgroundPosition: 'top 60px left 0px, bottom 60px right 0px',
        padding: '20px 0',
      },
    },
    root: {
      width: 480,
      paddingTop: 50,
      paddingBottom: 40,
      flexShrink: 0,
      [theme.breakpoints.down(Sizes.desktop)]: {
        paddingTop: 10,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        paddingTop: 40,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        padding: '60px 20px',
      },
    },
    title: {
      fontSize: 36,
      fontWeight: 700,
      textAlign: 'center',
      marginBottom: 40,
      [theme.breakpoints.down(Sizes.mobile)]: {
        fontSize: 26,
        marginBottom: 20,
      },
    },
    logoBox: {
      maxWidth: 300,
      maxHeight: 100,
      position: 'absolute',
      top: 33,
      left: '50%',
      transform: 'translateX(-50%)',
      [theme.breakpoints.down(Sizes.desktop)]: {
        top: 15,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        top: 20,
      },
    },
    logo: {
      objectFit: 'contain',
      maxWidth: '100%',
      maxHeight: '100%',
      marginBottom: 40,
      [theme.breakpoints.down(Sizes.tablet)]: {
        marginBottom: 50,
      },
    },
    backToAdmin: {
      position: 'fixed',
      top: 15,
      left: 20,
    },
    btnsBlock: {
      display: 'flex',
      justifyContent: 'space-around',
      paddingTop: 40,
      [theme.breakpoints.down(Sizes.mobile)]: {
        flexDirection: 'column-reverse',
        alignItems: 'center',
        rowGap: 15,
        '&>*': {
          width: 215,
        },
      },
    },
    fieldWrap: {
      width: 332,
      flexShrink: 0,
      marginBottom: 20,
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 'calc(100% - 80px)',
        marginBottom: 12,
      },
    },
    businessRow: {
      display: 'flex',
      position: 'relative',
      '&>div': {
        flexShrink: 0,
      },
    },
    track: {
      backgroundColor: colorVariables.steelGrey,
      opacity: 1,
    },
    thumb: {
      backgroundColor: colorVariables.white,
      width: 20,
      height: 20,
    },
    checked: {
      '&.MuiSwitch-switchBase + .MuiSwitch-track': {
        backgroundColor: '#44B5E6',
        opacity: 1,
      },
    },
    orderEmailWrap: {
      width: 332,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      transform: 'translateY(10px)',
      '&>span:first-child': {
        fontWeight: 700,
        fontSize: 12,
        textTransform: 'uppercase',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginTop: -10,
        width: '100%',
        '&>span:first-child': {
          fontSize: 10,
        },
      },
    },
    deliveryBox: {
      marginTop: 32,
      backgroundColor: 'rgba(152, 165, 183, 0.15)',
      borderRadius: 20,
      padding: 26,
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: 15,
        borderRadius: 15,
      },
    },
    deliveryTitle: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '22px',
      marginBottom: 8,
    },
    deliverySubtitle: {
      fontSize: 12,
      opacity: 0.7,
    },
    weekDaysBox: {
      display: 'flex',
      paddingTop: 20,
      marginBottom: 30,
      columnGap: 11,
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginBottom: 25,
        columnGap: 1,
        width: '100%',
      },
    },
    weekDayItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: 52,
      height: 84,
      borderRadius: 10,
      backgroundColor: colorVariables.white,
      boxShadow: '1px 1px 15px rgba(12, 82, 112, 0.08)',
      paddingTop: 15,
      textTransform: 'uppercase',
      border: '1px solid transparent',
      cursor: 'pointer',
      '& svg': {
        color: colorVariables.steelGrey15,
      },
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: 45,
        height: 72,
        fontSize: 11,
        marginRight: 6,
      },
    },
    weekDayItemSelected: {
      border: `1px solid ${colorVariables.green}`,
      backgroundColor: colorVariables.greenLight,
      '& svg': {
        color: colorVariables.green,
      },
    },
    checkIcon: {
      fontSize: 22,
      marginBottom: 8,
      color: colorVariables.transparent,
      [theme.breakpoints.down(Sizes.desktop)]: {
        fontSize: 16,
      },
    },
    deliveryDetailsRow: {
      display: 'flex',
      columnGap: 20,
      '& input': {
        textAlign: 'center',
      },
      '&>*': {
        flex: 1,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        columnGap: 10,
        alignItems: 'flex-end',
      },
    },
    cutOffLabel: {
      fontSize: 12,
      marginBottom: 10,
      lineHeight: '16px',
      marginTop: 4,
    },
    imgWrap: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      flexShrink: 0,
      right: 0,
      top: 20,
      width: 112,
      height: 112,
      borderRadius: 10,
      zIndex: 1,
      overflow: 'hidden',
      '& img': {
        width: '100%',
        height: '100%',
      },
      '&:hover': {
        cursor: 'pointer',
        '&>svg': {
          scale: 1.1,
        },
        '& > div:first-child': {
          display: 'flex',
        },
      },
      boxShadow: '1px 1px 15px rgba(12, 82, 112, 0.08)',
      backgroundColor: colorVariables.white,
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 80,
        height: 80,
        right: -10,
        top: 10,
        '& svg': {
          scale: 0.8,
        },
        '&:hover': {
          cursor: 'pointer',
          '& svg': {
            scale: 'none',
          },
          '& > div:first-child': {
            display: 'flex',
          },
        },
      },
    },
    addPhotoBg: {
      width: '100%',
      height: '100%',
      zIndex: 2,
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(116,124,129,0.5)',
      '& img': {
        width: 25,
        height: 25,
      },
      '& svg': {
        scale: 1.5,
      },
    },
    uploadPhoto: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: 12,
      fontWeight: 700,
      '& svg': {
        width: 41,
        height: 41,
        marginBottom: 10,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        fontSize: 10,
        '& svg': {
          marginBottom: 5,
        },
      },
    },
    uploadBox: {
      width: 450,
      minHeight: 160,
      marginTop: 20,
      display: 'flex',
      alignItems: 'center',
      marginBottom: 40,
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 300,
      },
    },
    uploadTitle: {
      fontSize: 20,
      fontWeight: 700,
      textAlign: 'center',
    },
    cancelUpload: {
      margin: '20px 150px 0 150px',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        margin: '20px auto 0 auto',
      },
    },
    ordersEmailNote: {
      fontSize: 12,
      marginTop: 8,
    },
  }),
);
