import React, { memo, useState, useEffect } from 'react';
import { useStyles } from './style';
import { differenceInMinutes } from 'date-fns';
import { useScreenSize } from '../../../../shared/hooks/use-screen-size';
import { useGetActiveStockTake } from '../../../../api/stocktake/hooks';
import { StocktakeMode } from '../../../../api/inventory/types';
import { Cart } from '../../MainNavBar/top-nav-bar/cart';
import { SubscriptionPlanEnum } from '../../../../api/user/types';
import { useAppSelector } from '../../../../store';
import { getMySubscriptionType } from '../../../../store/user';

const Component: React.FC = () => {
  const classes = useStyles();
  const { isMobile } = useScreenSize();
  const plan = useAppSelector(getMySubscriptionType);
  const activeStockTake = useGetActiveStockTake(plan !== SubscriptionPlanEnum.HOSPO);
  const [timer, setTimer] = useState<string>('');
  
  useEffect(() => {
    if(!isMobile || !activeStockTake?.created_at) {
      return;
    }

    const interval = setInterval(() => {
      const minutes = differenceInMinutes(
        new Date(),
        new Date(activeStockTake?.created_at),
      );
      setTimer(`${Math.floor(minutes/60).toString().padStart(2, '0')}:${Math.floor(minutes%60).toString().padStart(2, '0')}`);
    }, 1000);

    return () => clearInterval(interval);
  }, [isMobile, activeStockTake]);

  return (
    <>
      {activeStockTake && activeStockTake.mode === StocktakeMode.scanBarcode && isMobile
          ? <div className={classes.stockTakeProgress}>
              Stocktake {timer}
            </div>
          : <Cart />}
    </>
  );
};

export const RightNavBar = memo(Component);
