import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Close } from '@material-ui/icons';

import { useDeletePostCodesMutation, useGetPostcodesQuery } from '../../../../api/company';
import { ChevronButton } from '../../../../shared/components/chevron-button';
import { ThemedButton } from '../../../../shared/components/themed-button';
import { Dialog } from '../../../../shared/components/dialog';
import { NavbarTitle } from '../../../../shared/components/navbar-title';
import { MainNavBar } from '../../../AppDrawer/MainNavBar';
import { TopNavBar } from '../../../AppDrawer/MainNavBar/top-nav-bar';

import { useStyles } from './style';

export const ViewPostCodesPage: React.FC = () => {
  const classes = useStyles();
  const { goBack } = useHistory();
  const { state: isExcluded } = useLocation<boolean | undefined>();

  const [postCodesToDelete, setPostCodesToDelete] = useState<number[]>([]);
  const [isRemoveWarningShown, setIsRemoveWarningShown] = useState(false);

  const { data: currentPostcodes } = useGetPostcodesQuery({ current_postcode: true }, { skip: !!isExcluded });
  const { data: excludedPostcodes } = useGetPostcodesQuery({}, { skip: !isExcluded });
  const [deletePostCodes, { isLoading: deleteLoading }] = useDeletePostCodesMutation();

  const onDeletePostcode = (id: number) => {
    setPostCodesToDelete((prev) => [...prev, id]);
  };

  const onRemoveAll = () => {
    setPostCodesToDelete(((isExcluded ? excludedPostcodes?.postcodes : currentPostcodes?.postcodes) || []).map((code) => code.id));
    setIsRemoveWarningShown(false);
  };

  const onCancelViewAll = () => {
    setPostCodesToDelete([]);
    goBack();
  };

  const onSaveViewAll = () => {
    if (deleteLoading) {
      return;
    }
    if (!postCodesToDelete.length) {
      onCancelViewAll();
      return;
    }
    deletePostCodes({ ids: postCodesToDelete });
    onCancelViewAll();
  };

  return (
    <div className={classes.root}>
      <TopNavBar
        isAccount={true}
        bottomComponent={
          <div className={classes.bottomNavWrap}>
            <NavbarTitle title={isExcluded ? 'Excluded Post Codes' : 'Current Post Codes'} showBackBtn={true} />
            <ChevronButton onClick={onSaveViewAll} title='Save' />
          </div>
        }
      />
      <MainNavBar isAccount={true} />
      <div className={classes.viewAllHeader}>
        <div className={classes.allCodesText}>Postcodes</div>
        <div>{!isExcluded ? currentPostcodes?.postcodes?.length || 0 : excludedPostcodes?.postcodes?.length || 0}</div>
      </div>
      <div className={classes.postCodesList}>
        {(!isExcluded && currentPostcodes?.postcodes
          ? currentPostcodes.postcodes
          : isExcluded && excludedPostcodes?.postcodes
          ? excludedPostcodes.postcodes
          : []
        ).map((codeItem) =>
          !postCodesToDelete.includes(codeItem.id) ? (
            <div key={codeItem.id} className={classes.postCodeTableCell}>
              {codeItem.area_code}
              <div className={classes.closeIconWrap} onClick={onDeletePostcode.bind(null, codeItem.id)}>
                <Close className={classes.closeIcon} />
              </div>
            </div>
          ) : null,
        )}
      </div>
      <div className={classes.viewAllBtnBox}>
        <ThemedButton
          onClick={setIsRemoveWarningShown.bind(null, true)}
          title='Remove all'
          buttonStyle='primaryBordered'
          isSmall={true}
          bordered={true}
        />
        <ThemedButton onClick={onCancelViewAll} title='Cancel' isSmall={true} buttonStyle='secondary' width={100} />
        <ThemedButton onClick={onSaveViewAll} title='Save' isSmall={true} width={100} disabled={deleteLoading} />
        {isRemoveWarningShown && (
          <Dialog
            title='Are you sure you want to delete all post codes'
            onConfirm={onRemoveAll}
            onCancel={setIsRemoveWarningShown.bind(null, false)}
            customClass={classes.removeWarning}
          />
        )}
      </div>
    </div>
  );
};
