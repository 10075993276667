import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';
import { Sizes } from '../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    uploadLogoContainer: {
      border: '1px dashed #152144',
      borderRadius: 12,
      margin: '15px 0',
      width: 450,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 30,
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 280,
        margin: '10px auto',
        textAlign: 'center',
      },
    },
    uploadIcon: {
      width: 40,
      height: 40,
    },
    uploadDescription: {
      fontSize: 14,
      color: colorVariables.navy,
      marginTop: 10,
      fontWeight: 700,
    },
    uploadDescriptionBtn: {
      textDecoration: 'underline',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    cropTitle: {
      fontSize: 16,
      marginBottom: 10,
    },
    cropContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      maxHeight: 500,
      overflow: 'scroll',
      [theme.breakpoints.down(Sizes.mobile)]: {
        maxHeight: 300,
        width: 300,
      },
      '& img': {
        width: '100%',
        maxWidth: 450,
        [theme.breakpoints.down(Sizes.mobile)]: {
          maxWidth: 250,
        },
      },
    },
    btnBlock: {
      width: '100%',
      justifyContent: 'center',
      display: 'flex',
      marginTop: 30,
    },
    boldText: {
      fontWeight: 700,
    },
    topBox: {
      display: 'flex',
      width: '100%',
      marginBottom: 5,
      justifyContent: 'space-between',
    },
  }),
);
