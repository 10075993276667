import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Close } from '@material-ui/icons';
import clsx from 'clsx';
import { format, isSameDay } from 'date-fns';

import { useAddHolidaysMutation, useDeleteHolidaysMutation } from '../../../../api/company';
import { useMeQuery } from '../../../../api/user';
import { HolidayDate } from '../../../../api/user/types';
import { ChevronButton } from '../../../../shared/components/chevron-button';
import { ThemedButton } from '../../../../shared/components/themed-button';
import { MultiDatePicker } from '../../../../shared/components/multi-date-picker';
import { NavbarTitle } from '../../../../shared/components/navbar-title';
import { MainNavBar } from '../../../AppDrawer/MainNavBar';
import { TopNavBar } from '../../../AppDrawer/MainNavBar/top-nav-bar';

import { useStyles } from './style';

export const AddHolidaysPage: React.FC = () => {
  const classes = useStyles();
  const { goBack } = useHistory();

  const [localHolidays, setLocalHolidays] = useState<Partial<HolidayDate>[]>([]);

  const { data: user } = useMeQuery();
  const [onDeleteHolidayDate, { isLoading: deleteLoading }] = useDeleteHolidaysMutation();
  const [onAddHolidayDate, { isLoading: addLoading }] = useAddHolidaysMutation();

  const onCancel = () => {
    goBack();
  };

  const onSetHolidays = (dates: string[] | Date[]) => {
    setLocalHolidays(
      dates.map((el) => ({
        holiday_date: el.toString(),
      })),
    );
  };

  const onSaveHolidays = () => {
    if (user?.holidays?.length) {
      const holidaysToDelete = user.holidays.filter(
        (el) => !localHolidays.some((item) => isSameDay(new Date(item.holiday_date as string), new Date(el.holiday_date))),
      );
      const holidaysToAdd = localHolidays.filter(
        (el) => !user?.holidays?.some((item) => isSameDay(new Date(item.holiday_date), new Date(el.holiday_date as string))),
      );
      holidaysToAdd.length &&
        onAddHolidayDate(holidaysToAdd.map((day) => format(new Date(day.holiday_date as string), 'dd/MM/yyyy')).join(','));
      holidaysToDelete.length && onDeleteHolidayDate(holidaysToDelete.map((day) => day.id));
    } else {
      onAddHolidayDate(localHolidays.map((day) => format(new Date(day.holiday_date as string), 'dd/MM/yyyy')).join(','));
    }
    onCancel();
  };

  const onRemoveHoliday = (day?: string) => {
    setLocalHolidays((prev) => prev.filter((el) => el.holiday_date !== day));
  };

  useEffect(() => {
    user?.holidays && setLocalHolidays(user.holidays);
  }, [user?.holidays]);

  return (
    <div className={classes.root}>
      <TopNavBar
        isAccount={true}
        bottomComponent={
          <div className={classes.bottomNavWrap}>
            <NavbarTitle title='Add Holidays' showBackBtn={true} />
            <ChevronButton
              onClick={addLoading || deleteLoading ? () => {} : onSaveHolidays}
              title='Save'
              className={clsx(classes.saveBtn, (addLoading || deleteLoading) && classes.saveBtnDisabled)}
            />
          </div>
        }
      />
      <MainNavBar isAccount={true} />
      <div className={classes.header}>
        There will be no delivery on the specified days <br /> for the year 2023
      </div>
      <div className={classes.calendarWrap}>
        <MultiDatePicker dates={localHolidays.map((el) => el.holiday_date as string)} onSetDates={onSetHolidays} />
      </div>
      <div className={classes.daysListHead}>
        <div className={classes.daysListHeadTitle}>SPECIFIED DAYS</div>
        <div>{localHolidays.length}</div>
      </div>
      <div className={classes.daysList}>
        {localHolidays.map((day, idx) => (
          <div key={idx} className={classes.holidaysCell}>
            {day.holiday_date ? format(new Date(day.holiday_date), 'dd.LL') : ''}
            <div className={classes.closeIconWrap} onClick={onRemoveHoliday.bind(null, day.holiday_date)}>
              <Close className={classes.closeIcon} />
            </div>
          </div>
        ))}
      </div>
      <div className={classes.addBtnBox}>
        <ThemedButton onClick={onCancel} title='Cancel' isSmall={true} buttonStyle='secondary' width={100} />
        <ThemedButton onClick={onSaveHolidays} title='Save' isSmall={true} width={100} disabled={addLoading || deleteLoading} />
      </div>
    </div>
  );
};
