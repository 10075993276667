import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';

export const useStyles = makeStyles(() =>
  createStyles({
    button: {
      width: 34,
      height: 34,
      backgroundColor: colorVariables.greenLight,
      '&:hover': {
        backgroundColor: colorVariables.greenLight,
        boxShadow: `0 0 3px ${colorVariables.greenDark}`,
      },
    },
    icon: {
      color: colorVariables.green,
    },
    buttonDark: {
      borderColor: colorVariables.transparent,
      backgroundColor: colorVariables.green,
      '&:hover': {
        backgroundColor: colorVariables.greenDark,
      },
    },
    iconDark: {
      color: colorVariables.white,
    },
  }),
);
