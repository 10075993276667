import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';

export const useStyles = makeStyles(() =>
  createStyles({
    button: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 30,
      height: 30,
      '&>span': {
        display: 'flex',
        width: 30,
        height: 30,
      },
      '&:hover': {
        boxShadow: `0 0 3px ${colorVariables.grey60}`,
      },
    },
    icon: {
      width: 18,
      height: 18,
    },
  }),
);
