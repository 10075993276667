import * as React from 'react';

function SvgDashboardIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.7 1.7v5.6h5.6V1.7H1.7zM1 0a1 1 0 00-1 1v7a1 1 0 001 1h7a1 1 0 001-1V1a1 1 0 00-1-1H1zM12.7 1.7v5.6h5.6V1.7h-5.6zM12 0a1 1 0 00-1 1v7a1 1 0 001 1h7a1 1 0 001-1V1a1 1 0 00-1-1h-7zM1.7 12.7v5.6h5.6v-5.6H1.7zM1 11a1 1 0 00-1 1v7a1 1 0 001 1h7a1 1 0 001-1v-7a1 1 0 00-1-1H1zM12.7 12.7v5.6h5.6v-5.6h-5.6zM12 11a1 1 0 00-1 1v7a1 1 0 001 1h7a1 1 0 001-1v-7a1 1 0 00-1-1h-7z'
        fill='currentColor'
      />
    </svg>
  );
}

export default SvgDashboardIcon;
