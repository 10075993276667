import React from 'react';

import { useStyles } from './style';

interface Props {
  onClick?: () => void;
}

export const Overlay: React.FC<Props> = ({ onClick }) => {
  const classes = useStyles();
  return <div className={classes.root} onClick={onClick} />;
};
