import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../styles/colorVariables';
import { Sizes } from '../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    modalBox: {
      padding: 40,
      overflow: 'hidden',
      width: '70vw',
      maxWidth: 1200,
      [theme.breakpoints.down(Sizes.desktop)]: {
        padding: 25,
        width: '90vw',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '98vw',
        height: '90vh',
        padding: '20px 0 0 0',
        overflowY: 'hidden',
      },
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(80vh - 60px)',
      [theme.breakpoints.down(Sizes.mobile)]: {
        height: 'auto',
      },
    },
    header: {
      marginBottom: 10,
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 200,
        marginLeft: 16,
      },
    },
    table: {
      width: '100%',
      height: 'calc(80vh - 195px)',
      overflowY: 'scroll',
      padding: '0 10px',
      [theme.breakpoints.down(Sizes.mobile)]: {
        height: 'calc(90vh - 100px)',
        paddingBottom: 40,
        paddingTop: 10,
        backgroundColor: 'rgba(152, 165, 183, 0.15)',
      },
    },
    titleText: {
      textTransform: 'uppercase',
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 700,
      color: colorVariables.green,
      marginBottom: 28,
      [theme.breakpoints.down(Sizes.mobile)]: {
        fontSize: 18,
        color: colorVariables.navy,
        textTransform: 'none',
        marginTop: 5,
        marginBottom: 15,
      },
    },
    headerTitle: {
      fontSize: 20,
      lineHeight: '28px',
      fontWeight: 700,
      [theme.breakpoints.down(Sizes.mobile)]: {
        fontSize: 14,
        lineHeight: '20px',
        fontWeight: 400,
        paddingRight: 20,
      },
    },
    btnBox: {
      display: 'flex',
      marginTop: 'auto',
      justifyContent: 'flex-end',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    modalContent: {
      [theme.breakpoints.down(Sizes.desktop)]: {
        maxHeight: '100%',
        maxWidth: '100%',
        overflowY: 'hidden',
        overflowX: 'hidden',
      },
    },
    acceptAllMobile: {
      display: 'none',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'inline-flex',
        position: 'absolute',
        right: 16,
        top: 62,
      },
    },
    subTitle: {
      opacity: 0.7,
      paddingTop: 12,
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
  }),
);
