import React, { ChangeEvent, useEffect, useState } from 'react';

import { MenuItem, Select } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';

import { useGetDriversList } from '../../../../../../api/drivers/hooks';
import { Driver } from '../../../../../../api/drivers/types';

import { useStyles } from './style';

interface Props {
  options: Driver[];
  rootFieldClassName?: string;
  addMode?: boolean;
  onSetDriver: (id: number) => void;
  selectedDriver?: number;
}

export const DriverSelect: React.FC<Props> = ({ options, rootFieldClassName = '', addMode, onSetDriver, selectedDriver }) => {
  const classes = useStyles();
  const [selected, setSelected] = useState<number>(-1);

  const { list: drivers = [] } = useGetDriversList();

  const handleChange = (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    event.persist();
    const selectedValue = event.target.value as number;
    onSetDriver(selectedValue || -1);
    setSelected(selectedValue || -1);
  };

  useEffect(() => {
    !addMode && setSelected(selectedDriver || -1);
  }, [selectedDriver, addMode]);

  return (
    <div className={classes.box}>
      <FormControl
        variant='outlined'
        classes={{
          root: clsx(classes.rootField, rootFieldClassName),
        }}
      >
        <Select
          inputProps={{
            classes: {
              icon: classes.icon,
            },
          }}
          renderValue={(value: any) => (
            <div className={classes.selectWrap}>
              {(!addMode || (addMode && selected !== -1)) && (
                <span
                  className={classes.driverColor}
                  style={{
                    backgroundColor: drivers.find((el) => el.id === value)?.color || '#CFD7E6',
                    display: 'inline-block',
                  }}
                >
                  &nbsp; &nbsp; &nbsp;
                </span>
              )}
              <span>{drivers.find((el) => el.id === value)?.name || `${addMode ? 'Select Driver' : 'All Drivers'}`}</span>
            </div>
          )}
          IconComponent={ExpandMoreIcon}
          classes={{
            root: clsx(classes.select, rootFieldClassName),
          }}
          value={selected}
          onChange={handleChange}
          MenuProps={{
            anchorOrigin: {
              vertical: 40,
              horizontal: 0,
            },
            getContentAnchorEl: null,
            disableScrollLock: true,
          }}
        >
          {!addMode && (
            <MenuItem classes={{ root: clsx([classes.menuItem, classes.unselect]) }} key='all' value={-1}>
              <span className={classes.driverColor} style={{ backgroundColor: '#CFD7E6' }}/>
              <span>All Drivers</span>
            </MenuItem>
          )}
          {options.map(({ name, id }) => (
            <MenuItem
              key={name}
              value={id}
              classes={{
                root: classes.menuItem,
                selected: classes.selected,
              }}
            >
              <span className={classes.driverColor} style={{ backgroundColor: drivers.find((el) => el.id === id)?.color }}/>
              <span>{name}</span>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
