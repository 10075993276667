import * as React from 'react';

function SvgWarning(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M11.65 0H2.332A2.336 2.336 0 000 2.333v9.334A2.336 2.336 0 002.333 14h9.334A2.336 2.336 0 0014 11.667V2.333A2.362 2.362 0 0011.65 0zM2.332.6h9.334c.943 0 1.715.773 1.715 1.733v9.334c0 .943-.772 1.715-1.732 1.715H2.332C1.373 13.382.6 12.61.6 11.65V2.332C.6 1.373 1.373.6 2.333.6zm4.65 8.081a.344.344 0 01-.343-.343V3.483c0-.189.154-.343.343-.343.189 0 .343.154.343.343V8.32c0 .206-.137.36-.343.36zm.463 1.51c0 .24-.206.446-.446.446a.454.454 0 01-.446-.446c0-.24.206-.446.446-.446.257 0 .446.189.446.446z'
        fill='#51D9B0'
      />
    </svg>
  );
}

export default SvgWarning;
