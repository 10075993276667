import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';

export const useStyles = makeStyles(() =>
  createStyles({
    box: {
      width: '100%',
      position: 'relative',
    },
    rootField: {
      padding: 0,
      width: '100%',
      margin: 0,
      '& label': {
        display: 'none',
      },
      '& .MuiInputBase-formControl.MuiInput-formControl': {
        margin: 0,
        padding: '5px 5px 5px 10px',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: colorVariables.steelGrey,
        borderRadius: 5,
        '& input::placeholder': {
          opacity: 1,
          color: colorVariables.grey60,
        },
      },
      '& .MuiInputBase-formControl.MuiInput-formControl:hover': {
        borderColor: colorVariables.blue,
      },
      '& .MuiInputBase-formControl.MuiInput-formControl.Mui-disabled': {
        color: colorVariables.steelGrey,
      },
      '& .MuiInputBase-formControl.MuiInput-formControl.Mui-disabled:hover': {
        borderColor: colorVariables.steelGrey,
      },
      '& .MuiInputBase-formControl.MuiInput-formControl.Mui-focused': {
        borderColor: colorVariables.blue,
      },
    },
    root: {
      fontSize: 16,
      fontWeight: 400,
      color: colorVariables.navy,
      lineHeight: '24px',
      height: 48,
      backgroundColor: colorVariables.white,
      '&::before, &::after': {
        display: 'none',
      },
      '& input[type=number]': {
        '-moz-appearance': 'textfield',
      },
      '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
    rootMultiline: {
      height: 'auto',
    },
  }),
);
