import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import clsx from 'clsx';

import { StyleProps, useStyles } from './style';

interface Props extends StyleProps {
  onClick?: () => void;
  horizontal?: boolean;
  customClass?: string;
}

export const MoreButton: React.FC<Props> = React.memo(({ onClick, customClass = '', horizontal = false, ...styleProps }) => {
  const classes = useStyles(styleProps);
  return (
    <IconButton className={clsx([classes.button, customClass])} onClick={onClick}>
      {horizontal ? <MoreHorizIcon className={classes.icon} /> : <MoreVertIcon className={classes.icon} />}
    </IconButton>
  );
});
