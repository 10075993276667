import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../../../styles/colorVariables';
import { Sizes } from '../../../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    formBox: {
      height: 'calc(100% - 88px)',
      [theme.breakpoints.up(Sizes.mobile)]: {
        width: 425,
      },
    },
    root: {
      position: 'relative',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    sortBlock: {
      width: '90%',
      margin: '20px auto',
      [theme.breakpoints.up(Sizes.desktop)]: {
        display: 'none',
      },
    },
    blockTitle: {
      fontSize: 12,
      fontWeight: 700,
      color: colorVariables.navy,
      marginBottom: 8,
      textTransform: 'uppercase',
      [theme.breakpoints.up(Sizes.desktop)]: {
        marginBottom: 25,
      },
    },
    blockSubTitle: {
      color: colorVariables.grey60,
      [theme.breakpoints.up(Sizes.desktop)]: {
        marginBottom: 10,
      },
    },
    stockBlock: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: 15,
      '& label': {
        width: '30%',
        marginRight: 0,
        flexShrink: 0,
        marginLeft: 0,
      },
      '& label:nth-child(2)': {
        width: '40%',
      },
    },
    statusBlock: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: 15,
      '& label': {
        width: '50%',
        marginRight: 0,
        flexShrink: 0,
        marginLeft: 0,
      },
    },
    gstBlock: {
      display: 'flex',
      marginBottom: 15,
      '& label': {
        width: '30%',
        marginRight: 0,
        flexShrink: 0,
        marginLeft: 0,
      },
    },
    filterBlock: {
      width: '90%',
      margin: '0 auto',
    },
    btnBlock: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 20,
      padding: 20,
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: '40px 20px',
      },
      [theme.breakpoints.down(400)]: {
        padding: '30px 10px',
        '& button': {
          minWidth: '40%',
        },
      },
    },
    btnBlockFullScreen: {
      marginTop: 'auto',
    },
    actions: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.up(Sizes.desktop)]: {
        display: 'none',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    actionsText: {
      textTransform: 'uppercase',
      fontSize: 11,
      fontWeight: 700,
      color: colorVariables.grey80,
    },
    actionsIcon: {
      fontSize: 16,
      color: colorVariables.navy,
      opacity: 0.5,
    },
    triggerBox: {
      [theme.breakpoints.between(Sizes.mobile, Sizes.desktop)]: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
      },
    },
    costBlock: {
      '&>div:first-child': {
        marginBottom: 0,
      },
    },
    costFields: {
      display: 'flex',
      alignItems: 'center',
    },
    costFieldWrap: {
      width: 80,
    },
    divider: {
      width: 32,
      textAlign: 'center',
      color: colorVariables.grey60,
    },
  }),
);
