import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../styles/colorVariables';
import { Sizes } from '../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: 20,
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: 0,
      },
    },
    resCount: {
      fontSize: 24,
      lineHeight: '30px',
      color: colorVariables.grey40,
      marginBottom: 25,
      [theme.breakpoints.down(Sizes.mobile)]: {
        fontSize: 18,
        lineHeight: '22px',
        marginTop: 20,
        marginBottom: 20,
      },
    },
    searchResItem: {
      padding: 10,
      marginBottom: 20,
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginBottom: 10,
        borderBottom: `1px solid ${colorVariables.steelGrey}`,
      },
    },
    title: {
      display: 'block',
      color: 'inherit',
      width: '100%',
    },
    linkHead: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 10,
    },
    marked: {
      fontWeight: 700,
    },
    titleText: {
      fontSize: 16,
      lineHeight: '28px',
    },
    text: {
      fontSize: 16,
      color: colorVariables.grey60,
      paddingRight: 25,
      lineHeight: '28px',
      [theme.breakpoints.down(Sizes.mobile)]: {
        fontSize: 14,
        lineHeight: '22px',
      },
    },
  }),
);
