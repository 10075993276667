import React from 'react';

import { IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';

import { useStyles } from './style';

interface Props {
  isActive?: boolean;
  onClick?: () => void;
}

export const CustomMenuButton: React.FC<Props> = ({ onClick, isActive = false }) => {
  const classes = useStyles();
  return (
    <IconButton className={clsx(classes.menu, isActive && classes.menuActive)} onClick={onClick ? onClick : undefined}>
      <MenuIcon className={classes.icon} />
    </IconButton>
  );
};
