import React from 'react';

import { ProgressBar } from '../../../../shared/components/progress-bar';

import { useStyles } from './style';

interface Props {
  progress: number;
}

export const ProfileProgressBar: React.FC<Props> = ({ progress }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div>
        <span className={classes.boldText}>{progress}%</span>
        <span>Complete</span>
      </div>
      <ProgressBar percent={progress} />
    </div>
  );
};
