import { getRecOrderFrequency, getRecOrdersQueryParamsHelper, setSuccessToast } from '../helpers';
import { HTTP, rootApi, SuccessResponse } from '../index';
import {
  CreateRecOrderRequest,
  CreateRecOrderResponse,
  GetRecOrdersReqParams,
  RecOrder,
  RecOrdersListResponse,
  UpdateRecOrderQuantityRequest,
  UpdateRecOrderRequest,
  UpdateRecOrderResponse,
} from './types';

export const recOrderApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getRecOrders: builder.query<RecOrdersListResponse, GetRecOrdersReqParams>({
      query: (args) => {
        const params = {
          ...getRecOrdersQueryParamsHelper(args),
          page: args.currentPage,
          per_page: 20,
        };
        return {
          url: `/recurring_orders`,
          method: HTTP.GET,
          params,
        };
      },
      keepUnusedDataFor: 0,
    }),

    getRecOrderById: builder.query<RecOrder, number>({
      query: (id) => {
        return {
          url: `/recurring_orders/${id}/get_order`,
          method: HTTP.GET,
        };
      },
      providesTags: (result, _, args) => {
        return result ? [{ type: 'Rec_Order' as const, id: args }] : [];
      },
    }),

    createRecOrder: builder.mutation<CreateRecOrderResponse, CreateRecOrderRequest>({
      query: ({ frequency, ...body }) => {
        return {
          url: `/recurring_orders`,
          method: HTTP.POST,
          body: {
            ...body,
            frequency: getRecOrderFrequency(frequency),
          },
        };
      },
    }),

    updateRecOrder: builder.mutation<UpdateRecOrderResponse, UpdateRecOrderRequest>({
      query: ({ id, frequency, ...body }) => {
        return {
          url: `/recurring_orders/${id}`,
          method: HTTP.PATCH,
          body: {
            recurring_order: {
              ...body,
              frequency: frequency ? getRecOrderFrequency(frequency) : undefined,
            },
          },
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'Recurring Order Name Saved');
      },
      invalidatesTags: (result, error, arg) => [{ type: 'Rec_Order', id: arg.id }],
    }),

    updateRecOrderQuantity: builder.mutation<UpdateRecOrderResponse, UpdateRecOrderQuantityRequest>({
      query: ({ id, ...body }) => {
        return {
          url: `/recurring_orders/${id}/add_product_to_order`,
          method: HTTP.PATCH,
          body,
        };
      },
      invalidatesTags: (result, error, arg) => [{ type: 'Rec_Order', id: arg.id }],
    }),

    updateRecOrderData: builder.mutation<UpdateRecOrderResponse, UpdateRecOrderRequest>({
      query: ({ id, frequency, ...body }) => {
        return {
          url: `/recurring_orders/${id}`,
          method: HTTP.PATCH,
          body: {
            recurring_order: {
              ...body,
              frequency: frequency ? getRecOrderFrequency(frequency) : undefined,
            },
          },
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'Recurring Order Saved');
      },
      invalidatesTags: (result, error, arg) => [{ type: 'Rec_Order', id: arg.id }],
    }),

    deleteRecOrder: builder.mutation<SuccessResponse, number>({
      query(id) {
        return {
          url: `/recurring_orders/${id}`,
          method: HTTP.DELETE,
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'Deleted');
      },
    }),
  }),

  overrideExisting: true,
});

export const {
  useCreateRecOrderMutation,
  useGetRecOrdersQuery,
  useGetRecOrderByIdQuery,
  useDeleteRecOrderMutation,
  useUpdateRecOrderMutation,
  useUpdateRecOrderDataMutation,
  useUpdateRecOrderQuantityMutation,
} = recOrderApi;
