import React, { memo, SetStateAction, useCallback, useEffect, useState } from 'react';

import Collapse from '@material-ui/core/Collapse/Collapse';
import { ChevronRight } from '@material-ui/icons';
import clsx from 'clsx';
import uniq from 'lodash/uniq';
import LinesEllipsis from 'react-lines-ellipsis';

import { useDebouncedCallback } from 'use-debounce';
import {
  useArchiveManualProductMutation,
  useDeleteManualProductMutation,
  useRestoreManualProductMutation,
  useUpdateManualProductsMutation,
} from '../../../../../api/product';
import { Product, UpdateManualSupplierProduct } from '../../../../../api/product/types';
import SvgArchive from '../../../../../assets/icons/Archive';
import SvgEditIcon from '../../../../../assets/icons/EditIcon';
import SvgTrash from '../../../../../assets/icons/Trash';
import { BlurredImage } from '../../../../../shared/components/blurred-image';
import { ThemedButton } from '../../../../../shared/components/themed-button';
import { CommonCheckbox } from '../../../../../shared/components/common-checkbox';
import { Dialog } from '../../../../../shared/components/dialog';
import { DropdownMenu } from '../../../../../shared/components/dropdown-menu';
import { formatCurrency } from '../../../../../shared/helpers/format-currency';
import { roundNumbers } from '../../../../../shared/helpers/roundNumbers';
import { ToastService } from '../../../../../shared/services/toastService';
import { useAppDispatch } from '../../../../../store';
import { clearManualProductsUpdateData, setManualProductsUpdateData } from '../../../../../store/manual-products';
import { colorVariables } from '../../../../../styles/colorVariables';
import { UploadProductLogo } from '../../../../AppDrawer/SupplierDrawer/Product/common/upload-product-logo';

import { useStyles } from './style';

interface Props {
  item: Product;
  lockedSupplier: boolean;
  setEditedRows: React.Dispatch<SetStateAction<number[]>>;
  currentRowDisabled: boolean;
}

const Component: React.FC<Props> = ({ item, lockedSupplier, setEditedRows, currentRowDisabled }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [isImageUploadModalOpen, setIsImageUploadModalOpen] = useState(false);
  const [isDeleteWarningShown, setIsDeleteWarningShown] = useState(false);
  const [isArchiveWarningShown, setIsArchiveWarningShown] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const [rowData, setRowData] = useState<UpdateManualSupplierProduct | Omit<UpdateManualSupplierProduct, 'id'>>({
    unit: '',
    name: '',
  });

  const [deleteProduct] = useDeleteManualProductMutation();
  const [restoreProduct] = useRestoreManualProductMutation();
  const [archiveProduct] = useArchiveManualProductMutation();
  const [updateProducts, { isLoading: updateLoading }] = useUpdateManualProductsMutation();

  const onUpdateProducts = () => {
    if (item.supplier?.id) {
      const formData = new FormData();
      formData.append('supplier_id', item.supplier.id.toString());
      formData.append(`supplier[products_attributes][0][id]`, item.id.toString());
      formData.append(`supplier[products_attributes][0][name]`, rowData.name);
      formData.append(`supplier[products_attributes][0][unit]`, rowData.unit);
      rowData.product_id && formData.append(`supplier[products_attributes][0][product_id]`, rowData.product_id);
      formData.append(`supplier[products_attributes][0][gst]`, rowData.gst?.toString() || 'false');
      rowData.brand && formData.append(`supplier[products_attributes][0][brand]`, rowData.brand);
      rowData.sub_category && formData.append(`supplier[products_attributes][0][sub_category]`, rowData.sub_category);
      rowData.barcode && formData.append(`supplier[products_attributes][0][barcode]`, rowData.barcode);
      rowData.picture &&
        typeof rowData.picture !== 'string' &&
        formData.append(`supplier[products_attributes][0][picture]`, rowData.picture);
      formData.append(`supplier[products_attributes][0][price_per_unit]`, rowData.product_price?.toString() || '0');
      updateProducts(formData);
      setIsEditMode(false);
      dispatch(clearManualProductsUpdateData());
    }
  };

  const openImageUpload = useCallback(() => {
    setIsImageUploadModalOpen(true);
  }, []);

  const onConfirmDelete = () => {
    deleteProduct({ productId: item.id, supplierId: item.supplier.id });
    setIsDeleteWarningShown(false);
  };

  const toggleExpanded = () => {
    setExpanded((prev) => !prev);
  };

  const onConfirmArchive = () => {
    archiveProduct({ productId: item.id, supplierId: item.supplier.id });
    setIsArchiveWarningShown(false);
  };

  const closeImageUpload = useCallback(() => {
    setIsImageUploadModalOpen(false);
  }, []);

  const onImageUploaded = (blob: Blob) => {
    setRowData((prev) => ({
      ...prev,
      ...{
        id: item.id,
        picture: new File([blob], item.name),
      },
    }));
    setEditedRows((prevState) => uniq([...prevState, item.id]));
  };

  const showLockedWarning = () => {
    ToastService.warning('Action is not allowed');
  };

  const onChange = (newValue: string, field: 'name' | 'product_id' | 'brand' | 'product_price' | 'unit' | 'sub_category' | 'barcode') => {
    setRowData((prev) => ({
      ...prev,
      ...{
        id: item.id,
        [field]: field === 'product_price' ? (newValue === '0' ? '0' : roundNumbers(newValue)) : newValue,
      },
    }));
  };

  const onUpdateGst = () => {
    setRowData((prev) => ({
      ...prev,
      ...{
        id: item.id,
        gst: !prev.gst,
      },
    }));
  };

  const debouncedStoreUpdate = useDebouncedCallback(() => {
    if ('id' in rowData) {
      dispatch(setManualProductsUpdateData(rowData));
    }
  }, 1000);

  useEffect(() => {
    debouncedStoreUpdate();
  }, [rowData]);

  useEffect(() => {
    setRowData({
      name: item.name,
      brand: item.brand,
      unit: item.unit,
      product_id: item.product_id,
      gst: item.gst,
      picture: item.picture_url,
      product_price: item?.product_price || 0,
      sub_category: item?.sub_category,
      barcode: item?.barcode,
    });
  }, [item]);

  useEffect(() => {
    // @ts-ignore
    if (!rowData.id) {
      return;
    }
    if (
      rowData.gst !== item.gst ||
      (rowData.name !== item.name && rowData.name) ||
      rowData.brand !== item.brand ||
      (rowData.unit !== item.unit && rowData.unit) ||
      rowData.product_id !== item.product_id ||
      rowData.product_price !== item.product_price ||
      rowData.barcode !== item.barcode ||
      rowData.picture !== null ||
      rowData.sub_category !== item.sub_category
    ) {
      setEditedRows((prevState) => uniq([...prevState, item.id]));
    } else {
      setEditedRows((prevState) => prevState.filter((rowId) => rowId !== item.id));
    }
  }, [rowData]);

  return (
    <div className={classes.row}>
      <div className={classes.head}>
        <div>
          <DropdownMenu
            items={[
              <div onClick={openImageUpload} key={'Upload Image'}>
                Upload Image
              </div>,
            ]}
            triggerBtn={
              <div
                className={clsx([
                  classes.productImgBox,
                  item.picture_url && classes.productImage,
                  item.archived_at && classes.productImgBoxArchived,
                ])}
              >
                {rowData.picture ? (
                  <>
                    <BlurredImage
                      src={typeof rowData.picture === 'string' ? rowData.picture : URL.createObjectURL(rowData.picture)}
                      alt={``}
                    />
                    <div className={clsx(classes.editImage)}>
                      <SvgEditIcon className={classes.editIcon} />
                    </div>
                  </>
                ) : (
                  <SvgEditIcon className={classes.editIcon} />
                )}
              </div>
            }
          />
        </div>
        {isEditMode && !lockedSupplier ? (
          <textarea
            rows={2}
            className={classes.textarea}
            value={rowData?.name}
            onChange={(e) => {
              onChange(e.target.value, 'name');
            }}
          />
        ) : (
          <div onClick={toggleExpanded} className={classes.nameField}>
            <LinesEllipsis text={rowData?.name || ''} maxLine={2} />
          </div>
        )}
        <ChevronRight className={clsx(classes.chevron, expanded && classes.chevronDown)} onClick={toggleExpanded} />
      </div>
      <Collapse in={expanded} timeout={500}>
        <div className={classes.expandedContent}>
          <div className={classes.detailsRow}>
            <div className={classes.detailLabel}>PRODUCT ID</div>
            {isEditMode && !lockedSupplier ? (
              <div>
                <input
                  className={classes.input}
                  value={rowData?.product_id || ''}
                  onChange={(e) => {
                    onChange(e.target.value, 'product_id');
                  }}
                />
              </div>
            ) : (
              <div>{rowData.product_id}</div>
            )}
          </div>
          <div className={classes.detailsRow}>
            <div className={classes.detailLabel}>BRAND</div>
            {isEditMode && !lockedSupplier ? (
              <div>
                <input
                  className={classes.input}
                  value={rowData?.brand || ''}
                  onChange={(e) => {
                    onChange(e.target.value, 'brand');
                  }}
                />
              </div>
            ) : (
              <div>{rowData.brand}</div>
            )}
          </div>
          <div className={classes.detailsRow}>
            <div className={classes.detailLabel}>UNIT OF MEASURE</div>
            {isEditMode && !lockedSupplier ? (
              <div>
                <input
                  className={classes.input}
                  value={rowData?.unit || ''}
                  onChange={(e) => {
                    onChange(e.target.value, 'unit');
                  }}
                />
              </div>
            ) : (
              <div>{rowData.unit}</div>
            )}
          </div>
          <div className={classes.detailsRow}>
            <div className={classes.detailLabel}>BARCODE</div>
            {isEditMode && !lockedSupplier ? (
              <div>
                <input
                  className={classes.input}
                  value={rowData?.barcode || ''}
                  onChange={(e) => {
                    onChange(e.target.value, 'barcode');
                  }}
                />
              </div>
            ) : (
              <div>{rowData.barcode}</div>
            )}
          </div>
          <div className={classes.detailsRow}>
            <div className={classes.detailLabel}>SUB CATEGORY</div>
            {isEditMode && !lockedSupplier ? (
              <div>
                <input
                  className={classes.input}
                  value={rowData?.sub_category || ''}
                  onChange={(e) => {
                    onChange(e.target.value, 'sub_category');
                  }}
                />
              </div>
            ) : (
              <div>{rowData.sub_category}</div>
            )}
          </div>
          <div className={classes.detailsRow}>
            <div className={classes.priceBox}>
              <div className={classes.detailLabel}>PRICE</div>
              {isEditMode ? (
                <div>
                  <input
                    type={'number'}
                    className={clsx(classes.input, classes.priceInput)}
                    value={rowData?.product_price || ''}
                    onChange={(e) => {
                      onChange(e.target.value, 'product_price');
                    }}
                  />
                </div>
              ) : (
                <div className={classes.priceCell}>{formatCurrency(rowData.product_price || 0)}</div>
              )}
            </div>
            <div className={classes.gstBox}>
              <CommonCheckbox checked={!!rowData.gst} name={'TAX'} onChange={onUpdateGst} disabled={lockedSupplier} />
            </div>
          </div>
          {isEditMode ? (
            <div className={classes.btnsEdit}>
              <ThemedButton onClick={setIsEditMode.bind(null, false)} title='Cancel' buttonStyle='secondary' isSmall={true} width={145} />
              <ThemedButton
                onClick={onUpdateProducts}
                title='Save changes'
                isSmall={true}
                width={145}
                disabled={updateLoading || currentRowDisabled}
              />
            </div>
          ) : (
            <div className={classes.btns}>
              <ThemedButton
                title='DELETE'
                onClick={lockedSupplier ? showLockedWarning : setIsDeleteWarningShown.bind(null, true)}
                buttonStyle={'icon'}
                startIcon={<SvgTrash />}
                customClass={classes.actionBtn}
              />
              {item.archived_at ? (
                <ThemedButton
                  title='RESTORE'
                  onClick={
                    lockedSupplier
                      ? showLockedWarning
                      : () => {
                          restoreProduct({ productId: item.id, supplierId: item.supplier.id });
                        }
                  }
                  buttonStyle={'icon'}
                  startIcon={<SvgArchive />}
                  customClass={classes.actionBtn}
                />
              ) : (
                <ThemedButton
                  title='ARCHIVE'
                  onClick={lockedSupplier ? showLockedWarning : setIsArchiveWarningShown.bind(null, true)}
                  buttonStyle={'icon'}
                  startIcon={<SvgArchive />}
                  customClass={classes.actionBtn}
                />
              )}
              <ThemedButton
                title='EDIT'
                onClick={setIsEditMode.bind(null, true)}
                buttonStyle={'icon'}
                startIcon={<SvgEditIcon color={colorVariables.green} />}
                customClass={classes.actionBtn}
              />
            </div>
          )}
        </div>
      </Collapse>
      {isDeleteWarningShown && (
        <Dialog
          title='Are you sure you want to delete this product?'
          onCancel={setIsDeleteWarningShown.bind(null, false)}
          onConfirm={onConfirmDelete}
          customClass={classes.dialog}
        />
      )}
      {isArchiveWarningShown && (
        <Dialog
          title='Are you sure you want to archive this product?'
          onCancel={setIsArchiveWarningShown.bind(null, false)}
          onConfirm={onConfirmArchive}
          customClass={classes.dialog}
        />
      )}
      <UploadProductLogo isModalOpen={isImageUploadModalOpen} onClose={closeImageUpload} onSubmit={onImageUploaded} />
    </div>
  );
};

export const ManualProductItemMobile = memo(Component);
