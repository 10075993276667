import React from 'react';

import SvgComputer from '../../../../assets/icons/Computer';
import { ThemedButton } from '../../../../shared/components/themed-button';
import { OverlayModal } from '../../../../shared/components/overlay-modal';

import { useStyles } from './style';

interface Props {
  onClose: () => void;
  onSetOnBoarding: () => void;
  isModalOpen: boolean;
}

export const InitialSetUp: React.FC<Props> = ({ onClose, isModalOpen, onSetOnBoarding }) => {
  const classes = useStyles();

  return (
    <OverlayModal
      onClose={onClose}
      isOpen={isModalOpen}
      boxClassName={classes.box}
      showClose={false}
      animate={true}
      closeOnClickOutside={true}
    >
      <div className={classes.root}>
        <div className={classes.wrap}>
          <SvgComputer />
          <div className={classes.title}>Let your customers know who you are</div>
          <ThemedButton title='Set up your profile' onClick={onSetOnBoarding} />
        </div>
      </div>
    </OverlayModal>
  );
};
