import React, { memo, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Collapse } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import clsx from 'clsx';
import { useInView } from 'react-intersection-observer';

import {
  ManualProductPreview,
  ManualProductPreviewWithError,
  ProductPreview,
  ProductPreviewWithError,
} from '../../../../../../api/product/types';
import { useScreenSize } from '../../../../../../shared/hooks/use-screen-size';
import { PreviewProductItem } from '../preview-product-item';

import { useStyles } from './style';

interface Props {
  rowData: ProductPreview[] | ProductPreviewWithError[] | ManualProductPreview[] | ManualProductPreviewWithError[];
  title: string;
  isError?: boolean;
  manualProducts?: boolean;
}

const Component: React.FC<Props> = ({ rowData, title, isError, manualProducts }) => {
  const classes = useStyles();
  const { isMobile } = useScreenSize();
  const { push } = useHistory();
  const [inViewLastRef, inViewLast] = useInView();

  const [isRowExpanded, setIsRowExpanded] = useState(false);
  const [page, setPage] = useState(1);

  const toggleExpanded = () => {
    setIsRowExpanded((prev) => !prev);
  };

  const openPreviewDetails = (key: string) => {
    !manualProducts && push('/products/import/preview-details', key);
  };

  useEffect(() => {
    inViewLast && setPage((prev) => prev + 1);
  }, [inViewLast]);

  const displayedItems = useMemo(() => rowData.slice(0, page * 20), [page, rowData]);

  return (
    <div>
      <div
        className={clsx(classes.row, isRowExpanded && classes.expandedRow, isError && classes.errorRow)}
        onClick={isMobile ? openPreviewDetails.bind(null, title) : rowData.length ? toggleExpanded : undefined}
      >
        <div className={classes.idCol}>
          <ArrowForwardIosIcon className={clsx([classes.chevronRight, isRowExpanded && classes.chevronDown])} />
        </div>
        <div className={clsx(classes.actionCol, classes.actionHead)}>{title}</div>
        <div className={clsx(classes.nameCol, classes.nameHead)}>
          {rowData.length} {isMobile ? '' : 'items'}
          {isMobile && <ArrowForwardIosIcon className={classes.chevronRight} />}
        </div>
        <div className={classes.msgCol} />
        <div className={classes.rowCol} />
      </div>
      {isMobile ? null : (
        <Collapse in={isRowExpanded} timeout={500}>
          {rowData.length ? (
            <>
              {displayedItems.map((el, idx) => (
                <PreviewProductItem key={idx} el={el} title={title} isError={isError} />
              ))}
              <div ref={inViewLastRef} className={classes.lastRow}>
                {displayedItems.length === rowData.length ? '' : 'Loading...'}
              </div>
            </>
          ) : null}
        </Collapse>
      )}
    </div>
  );
};

export const PreviewProductRow = memo(Component);
