import { createAction } from '@reduxjs/toolkit';
import { CustomerPerformanceFilters, CustomerPerformanceSortBy } from '../../api/dashboard/types';

export const setCustomersPerformancePage = createAction<number>('SET_CUSTOMERS_PERFORMANCE_PAGE');

export const setCustomersPerformanceKeyword = createAction<{ keyword?: string }>('SET_CUSTOMERS_PERFORMANCE_KEYWORD');

export const setCustomerPerformanceSortFilter = createAction<{ filter?: CustomerPerformanceFilters; sort?: CustomerPerformanceSortBy }>(
  'SET_CUSTOMERS_PERFORMANCE_SORT_FILTER',
);
