import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../styles/colorVariables';
import { Sizes } from '../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: 'relative',
      paddingBottom: 80,
      width: 860,
      margin: '0 auto',
      [theme.breakpoints.down(Sizes.desktop)]: {
        paddingBottom: 40,
        width: '100%',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        paddingBottom: 30,
        marginTop: -50,
      },
    },
    title: {
      marginTop: 15,
      marginBottom: 25,
      fontSize: 28,
      lineHeight: '50px',
      fontWeight: 700,
      '& span': {
        fontWeight: 400,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    icon: {
      position: 'absolute',
      top: 10,
      right: 10,
      [theme.breakpoints.down(Sizes.mobile)]: {
        top: 0,
        right: 0,
      },
    },
    searchWrap: {
      width: 820,
      margin: '40px auto 20px auto',
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: '100%',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginTop: 20,
        marginBottom: 10,
      },
    },
    rowItem: {
      paddingTop: 32,
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      color: 'inherit',
      [theme.breakpoints.down(Sizes.mobile)]: {
        paddingTop: 28,
        paddingBottom: 16,
        borderBottom: `1px solid ${colorVariables.steelGrey}`,
      },
    },
    tileTitle: {
      fontSize: 16,
      fontWeight: 600,
      cursor: 'pointer',
      lineHeight: '22px',
      paddingRight: 15,
    },
    greyTile: {
      backgroundColor: 'rgba(152, 165, 183, 0.15)',
    },
    link: {},
    mobileHead: {
      display: 'none',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'flex',
        marginBottom: 30,
        paddingRight: 30,
      },
    },
    mobileTitle: {
      fontWeight: 700,
      fontSize: 20,
      lineHeight: '40px',
      '& span': {
        fontWeight: 400,
      },
    },
    marked: {
      fontWeight: 700,
      background: 'transparent !important',
    },
    tilesWrap: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);
