import React, { useState } from 'react';

import Collapse from '@material-ui/core/Collapse/Collapse';
import { KeyboardArrowRight } from '@material-ui/icons';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Field, FieldProps, Form, Formik } from 'formik';

import { useUpdateCustomPermissionsMutation, useUpdateTeammateMutation } from '../../../../api/teammates';
import { useGeIsTeammateAdmin, useSelectedTeammateById } from '../../../../api/teammates/hooks';
import { TeammatePermission } from '../../../../api/teammates/types';
import { SubscriptionPlanEnum } from '../../../../api/user/types';
import SvgLockIcon from '../../../../assets/icons/LockIcon';
import { ThemedButton } from '../../../../shared/components/themed-button';
import { CommonSwitch } from '../../../../shared/components/common-switch';
import { FormikSortDropdown } from '../../../../shared/components/formik-sort-dropdown';
import { OverlayModal } from '../../../../shared/components/overlay-modal';
import { UpgradeSubscriptionTooltip } from '../../../../shared/components/upgrade-subscription-tooltip';
import { categoriesForPermission } from '../../../../shared/constants/products';
import { extractPermissionOption, getOptionForRequest } from '../../../../shared/helpers/extract-permission-option';
import { useScreenSize } from '../../../../shared/hooks/use-screen-size';
import { ToastService } from '../../../../shared/services/toastService';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { setCustomTeammatePermissionModal } from '../../../../store/teammates/teammates.actions';
import { getCustomPermissionModalShown } from '../../../../store/teammates/teammates.selectors';
import { getMySubscriptionType } from '../../../../store/user';
import { CustomPermissionValues } from '../index';

import { useStyles } from './style';

export enum CustomPermissionOption {
  VIEW = 'view',
  EDIT = 'edit',
  HIDDEN = 'hidden',
}

const customPermissionsOptions = [
  { label: 'View', value: CustomPermissionOption.VIEW },
  { label: 'Edit', value: CustomPermissionOption.EDIT },
  { label: 'Hidden', value: CustomPermissionOption.HIDDEN },
];

const customPermissionsOptions1 = [
  { label: 'View', value: CustomPermissionOption.VIEW },
  { label: 'Hidden', value: CustomPermissionOption.HIDDEN },
];

interface Props {
  customValues: CustomPermissionValues | null;
  setCustomValues: (values: CustomPermissionValues | null) => void;
}

export const CustomPermissionModal: React.FC<Props> = ({ customValues, setCustomValues }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { isMobile } = useScreenSize();

  const [generalExpanded, setGeneralExpanded] = useState(false);
  const [accountExpanded, setAccountExpanded] = useState(false);
  const [toolsExpanded, setToolsExpanded] = useState(false);
  const [catExpanded, setCatExpanded] = useState(false);

  const id = useAppSelector(getCustomPermissionModalShown);
  const teammate = useSelectedTeammateById(id);
  const plan = useAppSelector(getMySubscriptionType);
  const isViewer = !useGeIsTeammateAdmin();

  const [updatePermission, { isLoading: updateLoading }] = useUpdateCustomPermissionsMutation();
  const [updateTeammate, { isLoading }] = useUpdateTeammateMutation();

  const onClose = (reset: boolean) => {
    dispatch(setCustomTeammatePermissionModal(null));
    reset && setCustomValues(customValues);
  };

  const toggleGeneral = () => {
    setGeneralExpanded((prev) => !prev);
  };

  const toggleAccount = () => {
    setAccountExpanded((prev) => !prev);
  };

  const toggleTools = () => {
    setToolsExpanded((prev) => !prev);
  };

  const toggleCategories = () => {
    setCatExpanded((prev) => !prev);
  };

  const onSubmit = async (values: CustomPermissionValues) => {
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    if (id === -1) {
      setCustomValues(values);
      onClose(false);
      return;
    }
    if (id && teammate) {
      await updateTeammate({
        id,
        skipInvalidation: true,
        permission: TeammatePermission.CUSTOM,
        teammate: {
          first_name: teammate.first_name,
          email: teammate.email,
        },
      });
      updatePermission({
        id,
        custom_permission: {
          pantry_lists: getOptionForRequest(values.pantry_lists),
          supplier_and_categories: getOptionForRequest(values.supplier_and_categories),
          order_history: getOptionForRequest(values.order_history),
          dashboard: getOptionForRequest(values.dashboard),
          teammates: getOptionForRequest(values.teammates),
          vanue_info: getOptionForRequest(values.vanue_info),
          billing: getOptionForRequest(values.billing),
          menu_planning: getOptionForRequest(values.menu_planning),
          sales_and_wastages: getOptionForRequest(values.sales_and_wastages),
          automated_ordering: getOptionForRequest(values.automated_ordering),
          inventory: getOptionForRequest(values.inventory),
          reports: getOptionForRequest(values.reports),
          product_type_categories: {
            meat: values.meat,
            dry_and_frozen_goods: values.dry_and_frozen_goods,
            poultry: values.poultry,
            fruits_and_vegetables: values.fruits_and_vegetables,
            seafood: values.seafood,
            bakery: values.bakery,
            coffee_and_tea: values.coffee_and_tea,
            dairy: values.dairy,
            alchohol_and_beverages: values.alchohol_and_beverages,
            small_goods: values.small_goods,
            hospitality_supplies: values.hospitality_supplies,
          },
        },
      });
      onClose(false);
    }
  };

  const customPermission = teammate?.custom_permission;
  const customPermissionForCategory = teammate?.custom_permission?.product_type_categories;

  return (
    <OverlayModal isOpen={!!id} onClose={onClose.bind(null, true)} boxClassName={classes.modalRoot}>
      <>
        <div className={classes.head}>
          <div className={classes.title}>Custom Permissions</div>
          <div className={classes.userName}>
            {teammate?.first_name || ''} {teammate?.last_name || ''}
          </div>
        </div>
        <Formik
          initialValues={
            customValues || {
              pantry_lists: extractPermissionOption(customPermission?.pantry_lists),
              supplier_and_categories: extractPermissionOption(customPermission?.supplier_and_categories),
              order_history: extractPermissionOption(customPermission?.order_history),
              dashboard: extractPermissionOption(customPermission?.dashboard),
              teammates: extractPermissionOption(customPermission?.teammates),
              vanue_info: extractPermissionOption(customPermission?.vanue_info),
              billing: extractPermissionOption(customPermission?.billing),
              menu_planning: extractPermissionOption(customPermission?.menu_planning),
              sales_and_wastages: extractPermissionOption(customPermission?.sales_and_wastages),
              automated_ordering: extractPermissionOption(customPermission?.automated_ordering),
              inventory: extractPermissionOption(customPermission?.inventory),
              reports: extractPermissionOption(customPermission?.reports),
              dry_and_frozen_goods: !!customPermissionForCategory?.dry_and_frozen_goods,
              meat: !!customPermissionForCategory?.meat,
              poultry: !!customPermissionForCategory?.poultry,
              fruits_and_vegetables: !!customPermissionForCategory?.fruits_and_vegetables,
              seafood: !!customPermissionForCategory?.seafood,
              bakery: !!customPermissionForCategory?.bakery,
              coffee_and_tea: !!customPermissionForCategory?.coffee_and_tea,
              dairy: !!customPermissionForCategory?.dairy,
              alchohol_and_beverages: !!customPermissionForCategory?.alchohol_and_beverages,
              small_goods: !!customPermissionForCategory?.small_goods,
              hospitality_supplies: !!customPermissionForCategory?.hospitality_supplies,
            }
          }
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {({ submitForm }) => (
            <Form>
              <div className={classes.section}>
                <div className={classes.sectionHead}>
                  <div className={classes.sectionTitle}>general</div>
                  {generalExpanded ? (
                    <div className={classes.expandTrigger} onClick={toggleGeneral}>
                      <span>Hide</span>
                      <KeyboardArrowDownIcon />
                    </div>
                  ) : (
                    <div className={classes.expandTrigger} onClick={toggleGeneral}>
                      <b>Show</b>
                      <KeyboardArrowRight />
                    </div>
                  )}
                </div>
                <Collapse in={!isMobile || generalExpanded} timeout={300}>
                  <div className={classes.sectionContent}>
                    <div className={classes.contentCol}>
                      <div className={classes.contentCell}>
                        <span className={classes.label}>Pantry Lists</span>
                        <FormikSortDropdown
                          name='pantry_lists'
                          options={customPermissionsOptions}
                          submitOnChange={false}
                          customSelectFormControlClass={classes.mobileSelect}
                        />
                      </div>
                      <div className={classes.contentCell}>
                        <span className={classes.label}>Suppliers & Categories</span>
                        <FormikSortDropdown
                          name='supplier_and_categories'
                          options={customPermissionsOptions}
                          submitOnChange={false}
                          customSelectFormControlClass={classes.mobileSelect}
                        />
                      </div>
                    </div>
                    <div className={classes.contentCol}>
                      <div className={classes.contentCell}>
                        <span className={classes.label}>Order History</span>
                        <FormikSortDropdown
                          name='order_history'
                          options={customPermissionsOptions}
                          submitOnChange={false}
                          customSelectFormControlClass={classes.mobileSelect}
                        />
                      </div>
                      <div className={classes.contentCell}>
                        <span className={classes.label}>Dashboard</span>
                        <FormikSortDropdown
                          name='dashboard'
                          options={customPermissionsOptions}
                          submitOnChange={false}
                          customSelectFormControlClass={classes.mobileSelect}
                        />
                      </div>
                    </div>
                  </div>
                </Collapse>
              </div>
              <div className={classes.section}>
                <div className={classes.sectionHead}>
                  <div className={classes.sectionTitle}>Account</div>
                  {accountExpanded ? (
                    <div className={classes.expandTrigger} onClick={toggleAccount}>
                      <span>Hide</span>
                      <KeyboardArrowDownIcon />
                    </div>
                  ) : (
                    <div className={classes.expandTrigger} onClick={toggleAccount}>
                      <b>Show</b>
                      <KeyboardArrowRight />
                    </div>
                  )}
                </div>
                <Collapse in={!isMobile || accountExpanded} timeout={300}>
                  <div className={classes.sectionContent}>
                    <div className={classes.contentCol}>
                      <div className={classes.contentCell}>
                        <span className={classes.label}>Teammates</span>
                        {plan === SubscriptionPlanEnum.HOSPO ? (
                          <FormikSortDropdown
                            name='teammates'
                            options={customPermissionsOptions1}
                            submitOnChange={false}
                            customSelectFormControlClass={classes.mobileSelect}
                          />
                        ) : (
                          <UpgradeSubscriptionTooltip
                            text='Upgrade your subscription plan to unlock ‘Teammates’ options'
                            placement={'bottom'}
                          >
                            <div>
                              Not available <SvgLockIcon className={classes.lockIcon} />
                            </div>
                          </UpgradeSubscriptionTooltip>
                        )}
                      </div>
                      <div className={classes.contentCell}>
                        <span className={classes.label}>Billing</span>
                        <FormikSortDropdown
                          name='billing'
                          options={customPermissionsOptions1}
                          submitOnChange={false}
                          customSelectFormControlClass={classes.mobileSelect}
                        />
                      </div>
                    </div>
                    <div className={classes.contentCol}>
                      <div className={classes.contentCell}>
                        <span className={classes.label}>Venue Info</span>
                        <FormikSortDropdown
                          name='vanue_info'
                          options={customPermissionsOptions1}
                          submitOnChange={false}
                          customSelectFormControlClass={classes.mobileSelect}
                        />
                      </div>
                    </div>
                  </div>
                </Collapse>
              </div>
              <div className={classes.section}>
                <div className={classes.sectionHead}>
                  <div className={classes.sectionTitle}>Tools</div>
                  {toolsExpanded ? (
                    <div className={classes.expandTrigger} onClick={toggleTools}>
                      <span>Hide</span>
                      <KeyboardArrowDownIcon />
                    </div>
                  ) : (
                    <div className={classes.expandTrigger} onClick={toggleTools}>
                      <b>Show</b>
                      <KeyboardArrowRight />
                    </div>
                  )}
                </div>
                <Collapse in={!isMobile || toolsExpanded} timeout={300}>
                  <div className={classes.sectionContent}>
                    <div className={classes.contentCol}>
                      <div className={classes.contentCell}>
                        <span className={classes.label}>Menu costing</span>
                        <FormikSortDropdown
                          name='menu_planning'
                          options={customPermissionsOptions}
                          submitOnChange={false}
                          customSelectFormControlClass={classes.mobileSelect}
                        />
                      </div>
                      <div className={classes.contentCell}>
                        <span className={classes.label}>Sales & Wastage</span>
                        <FormikSortDropdown
                          name='sales_and_wastages'
                          options={customPermissionsOptions}
                          submitOnChange={false}
                          customSelectFormControlClass={classes.mobileSelect}
                        />
                      </div>
                      <div className={classes.contentCell}>
                        <span className={classes.label}>Automated Ordering </span>
                        <FormikSortDropdown
                          name='automated_ordering'
                          options={customPermissionsOptions}
                          submitOnChange={false}
                          customSelectFormControlClass={classes.mobileSelect}
                        />
                      </div>
                    </div>
                    <div className={classes.contentCol}>
                      <div className={classes.contentCell}>
                        <span className={classes.label}>Inventory</span>
                        <FormikSortDropdown
                          name='inventory'
                          options={customPermissionsOptions}
                          submitOnChange={false}
                          customSelectFormControlClass={classes.mobileSelect}
                        />
                      </div>
                      <div className={classes.contentCell}>
                        <span className={classes.label}>Reports</span>
                        <FormikSortDropdown
                          name='reports'
                          options={customPermissionsOptions}
                          submitOnChange={false}
                          customSelectFormControlClass={classes.mobileSelect}
                        />
                      </div>
                    </div>
                  </div>
                </Collapse>
              </div>
              <div className={classes.section}>
                <div className={classes.sectionHead}>
                  <div className={classes.sectionTitle}>product type categories</div>
                  {catExpanded ? (
                    <div className={classes.expandTrigger} onClick={toggleCategories}>
                      <span>Hide</span>
                      <KeyboardArrowDownIcon />
                    </div>
                  ) : (
                    <div className={classes.expandTrigger} onClick={toggleCategories}>
                      <b>Show</b>
                      <KeyboardArrowRight />
                    </div>
                  )}
                </div>
                <div className={classes.sectionSubTitle}>Only suppliers in these categories will be visible to this user</div>
                <Collapse in={!isMobile || catExpanded} timeout={300}>
                  <div className={classes.categoriesBox}>
                    {categoriesForPermission.map((el) => (
                      <div className={classes.categoryItem} key={el.value}>
                        <span>{el.label}</span>
                        <Field name={el.value}>
                          {(fieldProps: FieldProps) => <CommonSwitch {...fieldProps} skipSubmitOnChange={true} />}
                        </Field>
                      </div>
                    ))}
                  </div>
                </Collapse>
              </div>
              <div className={classes.btnsBlock}>
                <ThemedButton
                  onClick={onClose.bind(null, true)}
                  title='Cancel'
                  type='reset'
                  buttonStyle='secondary'
                  width={isMobile ? 136 : 90}
                />
                <ThemedButton onClick={submitForm} title='Apply' width={isMobile ? 136 : 166} disabled={isLoading || updateLoading} />
              </div>
            </Form>
          )}
        </Formik>
      </>
    </OverlayModal>
  );
};
