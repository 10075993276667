import { createReducer } from '@reduxjs/toolkit';
import { logoutThunk } from '../../user';
import { setDefaultColors, setDemoColor, setDemoColors } from './themes.actions';
import { ThemeColors } from '../../../api/admin-themes/types';
import { colorVariables } from '../../../styles/colorVariables';

interface State {
  demoColors: { [key: string]: string };
}

const initialState: State = {
  demoColors: {
    [ThemeColors.btnPrimaryBg]: colorVariables.green,
    [ThemeColors.btnPrimaryText]: colorVariables.white,
    [ThemeColors.primaryBorderedBg]: colorVariables.greenLight,
    [ThemeColors.primaryBorderedText]: colorVariables.green,
    [ThemeColors.btnSecondaryBg]: colorVariables.grey40,
    [ThemeColors.btnSecondaryText]: colorVariables.navy,
    [ThemeColors.navBarBg]: colorVariables.navy,
    [ThemeColors.navBarText]: colorVariables.grey60,
  },
};

export const adminThemesReducer = createReducer(initialState, (builder) => {
  builder.addCase(logoutThunk.fulfilled, () => {
    return initialState;
  });

  builder.addCase(setDemoColor, (state, { payload }) => {
    state.demoColors[payload.name] = payload.color_code;
  });
  builder.addCase(setDemoColors, (state, { payload }) => {
    state.demoColors = Object.fromEntries(payload.map((el: any) => [el.name, el.color_code]) || []);
  });
  builder.addCase(setDefaultColors, (state) => {
    state.demoColors = initialState.demoColors;
  });
});
