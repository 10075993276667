import React, { useEffect, useRef } from 'react';

import clsx from 'clsx';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { useUpdateAbnMutation, useUpdateSupplierProfileMutation } from '../../../../api/company';
import { ThemedButton } from '../../../../shared/components/themed-button';
import { FormikInput } from '../../../../shared/components/formik-input';
import { InfoTooltip } from '../../../../shared/components/info-tooltip';
import { OverlayModal } from '../../../../shared/components/overlay-modal';
import { setGtmDatalayer } from '../../../../shared/helpers/setGtmDatalayer';
import { LocalStorageService } from '../../../../shared/services/localStorage.service';
import { ProfileModals } from '../index';

import { useStyles } from './style';

interface Values {
  name: string;
  bio?: string;
  website?: string;
  abn?: string | null;
}

interface Props {
  values: Values;
  onClose: () => void;
  onSubmit: (formValues: Values) => void;
  isModalOpen: boolean;
  isOnBoarding: boolean;
  setNextStep: (step: ProfileModals) => void;
  companyId?: number;
  setAbnValue: (abn: string) => void;
}

const BusinessDetailsSchema = Yup.object().shape({
  name: Yup.string().min(3, 'Too Short!').max(50, 'Too Long!').required('Required Field'),
  bio: Yup.string().min(10, 'Too Short!'),
  website: Yup.string().matches(
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    'Enter correct url!',
  ),
});

export const BusinessDetailsForm: React.FC<Props> = ({
  companyId,
  values,
  onClose,
  onSubmit,
  isModalOpen,
  isOnBoarding,
  setNextStep,
  setAbnValue,
}) => {
  const classes = useStyles();
  const ref = useRef<HTMLFormElement | null>(null);
  const abnText = LocalStorageService.getItem('country') === 'AU' ? 'Business ID/Number' : 'Company registration number (Optional)';

  const [updateProfile, { isSuccess, isLoading }] = useUpdateSupplierProfileMutation();
  const [updateAbn] = useUpdateAbnMutation();

  const onSubmitForm = ({ name, bio, website, abn }: Values, formikHelpers: FormikHelpers<Values>) => {
    if (isOnBoarding) {
      onSubmit({ name, bio, website });
      setNextStep(ProfileModals.UPLOAD_LOGO);
      abn && setAbnValue(abn);
    } else {
      updateAbn(abn || '');
      const formData = new FormData();
      formData.append('company[name]', name);
      formData.append('company[bio]', bio || '');
      formData.append('company[website]', website || '');
      companyId && updateProfile({ formData, id: companyId });
      values.bio &&
        bio !== values.bio &&
        setGtmDatalayer({
          event: 'profile',
          eventCategory: 'description_update',
        });
    }
    formikHelpers.setSubmitting(false);
  };

  useEffect(() => {
    isSuccess && onClose();
  }, [isSuccess]);

  useEffect(() => {
    !isModalOpen && !isOnBoarding && ref?.current?.reset();
  }, [isModalOpen, isOnBoarding]);

  return (
    <OverlayModal onClose={onClose} isOpen={isModalOpen} title='Edit Business Details' animate={!isOnBoarding}>
      <Formik initialValues={values} onSubmit={onSubmitForm} validationSchema={BusinessDetailsSchema} enableReinitialize={true}>
        {({ submitForm }) => (
          <Form className={classes.formContainer} ref={ref}>
            <div className={classes.fieldWrap}>
              <Field component={FormikInput} name='name' label='Company Name' placeholder='Company Name' />
            </div>
            <div className={classes.fieldWrap}>
              <Field
                multiline={true}
                minRows={4}
                maxRows={7}
                component={FormikInput}
                name='bio'
                label='Description'
                placeholder='Write a description of your business'
              />
            </div>
            <div className={clsx(classes.fieldWrap, classes.abnField)}>
              <Field component={FormikInput} name='abn' type='number' label={abnText} placeholder={`Enter ${abnText}`} />
            </div>
            <div className={classes.tooltipWrap}>
              <InfoTooltip text='Required for Tax Invoices' hideIcon={true} />
            </div>
            <div className={classes.fieldWrap}>
              <Field component={FormikInput} name='website' type='url' label='Website' placeholder='your-website.com' />
            </div>
            <div className={classes.btnBlock}>
              <ThemedButton title='Cancel' onClick={onClose} buttonStyle='secondary' />
              <ThemedButton
                title={isOnBoarding ? 'Next' : 'Update'}
                onClick={submitForm}
                buttonStyle='primary'
                disabled={isLoading}
                customClass={classes.saveBtn}
              />
            </div>
          </Form>
        )}
      </Formik>
    </OverlayModal>
  );
};
