import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';

import MuiTextField from '@material-ui/core/TextField';
import { Close } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import Highlighter from 'react-highlight-words';
import LinesEllipsis from 'react-lines-ellipsis';
import { useDebouncedCallback } from 'use-debounce';
import { useGetAllInventories } from '../../../../../../../api/inventory/hooks';
import { useGetActiveRecipes } from '../../../../../../../api/menu/hooks';
import { WastageType } from '../../../../../../../api/wastages/types';
import SvgIngredientIcon from '../../../../../../../assets/icons/IngredientIcon';
import SvgSubRecipeIcon from '../../../../../../../assets/icons/SubRecipeIcon';
import recipePlaceholder from '../../../../../../../assets/images/recipe-placeholder.png';
import { BlurredImage } from '../../../../../../../shared/components/blurred-image';
import { formatCurrency } from '../../../../../../../shared/helpers/format-currency';

import { useStyles } from './style';

interface Props {
  setRecipe: (value: { id: number; type: WastageType; name: string; code: string } | null) => void;
  selectedRecipe: { id: number; type: WastageType; name: string; code: string } | null;
}

export const SearchWastageItem: React.FC<Props> = ({ setRecipe, selectedRecipe }) => {
  const classes = useStyles();
  const ref = useRef<HTMLDivElement | null>(null);

  const [inputValue, setInputValue] = useState('');
  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  const { list: recipesRes = [], loading } = useGetActiveRecipes(true);
  const productsRes = useGetAllInventories();

  const list = useMemo(() => {
    return recipesRes && productsRes
      ? [
          ...recipesRes.map((el) => ({
            name: el.name,
            id: el.id,
            img: el.picture_url,
            cat: el.menu.name,
            extra: 'Menu',
            code: el.recipe_number?.toString(),
            type: WastageType.recipe,
          })),
          ...productsRes.map((el) => ({
            name: el.product_name,
            id: el.product_id,
            img: el.picture_url,
            cat: el.supplier.name,
            code: el.code?.toString(),
            extra: `${formatCurrency(el.product_price || 0)} ${el.unit}`,
            type: WastageType.product,
          })),
        ]
      : [];
  }, [recipesRes, productsRes]);

  const onInputChange = (event: ChangeEvent<{}>, newInputValue: string) => {
    setInputValue(newInputValue);
  };

  const debouncedClear = useDebouncedCallback(() => {
    ref.current?.click();
  }, 100);

  const onSelect = (event: ChangeEvent<any>, newValue: string | null) => {
    const recipe = list?.find((el) => newValue === el.name);
    setSelectedValue(recipe ? `${recipe.type}_${recipe.id}` : null);
    setRecipe(recipe ? { id: recipe.id, type: recipe.type, name: recipe.name, code: recipe.code || '' } : null);
  };

  useEffect(() => {
    if (selectedRecipe) {
      setInputValue(selectedRecipe.name);
      setSelectedValue(`${selectedRecipe.type}_${selectedRecipe.id}`);
    } else {
      debouncedClear();
    }
  }, [selectedRecipe]);

  return (
    <Autocomplete
      loading={loading}
      blurOnSelect={true}
      clearOnBlur={true}
      popupIcon={null}
      closeIcon={
        <div ref={ref} className={classes.closeIcon}>
          <Close />
        </div>
      }
      value={list?.find((el) => `${el.type}_${el.id}` === selectedValue)?.name}
      onChange={onSelect}
      inputValue={inputValue}
      onInputChange={onInputChange}
      renderOption={(option) => {
        const recipe = list?.find((rec) => rec.name === option);
        return recipe ? (
          <div className={classes.optionWrap}>
            <div className={classes.nameSection}>
              <div className={classes.imgWrap}>
                <BlurredImage src={recipe.img || recipePlaceholder} />
              </div>
              <div className={classes.recipeName}>
                <Highlighter
                  highlightTag={'b'}
                  highlightClassName={classes.marked}
                  searchWords={[inputValue]}
                  autoEscape={true}
                  textToHighlight={recipe.name}
                />
              </div>
            </div>
            <div className={classes.type}>
              {recipe.type === WastageType.product ? (
                <SvgIngredientIcon width={24} height={24} />
              ) : (
                <SvgSubRecipeIcon width={24} height={24} />
              )}
            </div>
            <div className={classes.categorySection}>
              <LinesEllipsis text={recipe.cat || ''} maxLine={1} />
            </div>
            <div className={classes.extraSection}>
              <LinesEllipsis text={recipe.extra} maxLine={1} />
            </div>
            <div className={classes.idSection}>{recipe.code}</div>
          </div>
        ) : null;
      }}
      classes={{
        root: classes.rootField,
        paper: clsx(classes.searchBox, !inputValue && !loading && classes.searchBoxHidden),
        noOptions: classes.noOptions,
        loading: classes.noOptions,
        option: classes.option,
      }}
      noOptionsText={inputValue ? 'No Search Results' : 'Please type Recipe or Product name'}
      options={list?.map((el) => el.name) || []}
      renderInput={(params) => (
        <MuiTextField
          {...params}
          placeholder={'Start typing Recipe or Product name..'}
          InputProps={{
            ...params.InputProps,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    />
  );
};
