import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';

export const useStyles = makeStyles(() =>
  createStyles({
    track: {
      backgroundColor: colorVariables.steelGrey,
      opacity: 1,
    },
    thumb: {
      backgroundColor: colorVariables.white,
      width: 20,
      height: 20,
    },
    checked: {
      '&.MuiSwitch-switchBase + .MuiSwitch-track': {
        backgroundColor: '#44B5E6',
        opacity: 1,
      },
    },
  }),
);
