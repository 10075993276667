import { createReducer } from '@reduxjs/toolkit';

import { TeammatesSortBy } from '../../api/teammates/types';
import { logoutThunk } from '../user/user.actions';
import { setCustomTeammatePermissionModal, setTeammatesKeyword, setTeammatesSort } from './teammates.actions';

interface State {
  sortBy?: TeammatesSortBy;
  keyword?: string;
  customPermissionFor: number | null;
}

const initialState: State = {
  sortBy: undefined,
  keyword: undefined,
  customPermissionFor: null,
};

export const teammatesReducer = createReducer(initialState, (builder) => {
  builder.addCase(logoutThunk.fulfilled, () => {
    return { ...initialState };
  });
  builder.addCase(setTeammatesKeyword, (state, { payload }) => {
    if (state.keyword === payload) {
      return;
    }
    state.keyword = payload;
  });
  builder.addCase(setTeammatesSort, (state, { payload }) => {
    state.sortBy = payload;
  });
  builder.addCase(setCustomTeammatePermissionModal, (state, { payload }) => {
    state.customPermissionFor = payload;
  });
});
