import React, { ChangeEvent, useEffect, useState } from 'react';

import { Switch } from '@material-ui/core';
import { SwitchProps } from 'formik-material-ui';

import { useStyles } from './style';

interface Props extends SwitchProps {
  skipSubmitOnChange?: boolean;
}

export const CommonSwitch: React.FC<Props> = (props) => {
  const {
    form: { setFieldValue, submitForm },
    field: { name, value },
    disabled,
    id,
    skipSubmitOnChange,
  } = props;
  const classes = useStyles();

  const [checked, setChecked] = useState(true);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    setFieldValue(name, event.target.checked);
    !skipSubmitOnChange && submitForm();
  };

  useEffect(() => {
    setChecked(value);
  }, []);

  return (
    <Switch
      id={id}
      disabled={disabled}
      checked={checked}
      onChange={handleChange}
      color='primary'
      classes={{
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
    />
  );
};
