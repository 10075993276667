import { createEntityAdapter, createReducer, EntityState } from '@reduxjs/toolkit';

import { pantryListApi } from '../../api/pantry-list';
import { PantryList, PantryListSortBy, SavedPantryListProdFilters } from '../../api/pantry-list/types';
import { ProductsCategories } from '../../shared/constants/products';
import { logoutThunk, setImpersonated } from '../user';
import {
  deletePantryList,
  hideCreatePantryListModal,
  hidePantryListExitWarning,
  setPantryListKeyword,
  setPantryListPage,
  setPantryListProductFilter,
  setPantryListsSortFilters,
  setSelectedPantryListId,
  showCreatePantryListModal,
  showPantryListExitWarning,
} from './pantry-lists.actions';

export const pantryListAdapter = createEntityAdapter<PantryList>();

interface State {
  list: EntityState<PantryList>;
  selectedPantryListId?: number;
  isCreateModalShown: boolean;
  exitWarning: {
    handler: (() => void) | null;
  };
  keyword?: string;
  currentPage: number;
  sortBy?: PantryListSortBy;
  filterBy: ProductsCategories | null;
  detailFilter: SavedPantryListProdFilters;
}

const initialState: State = {
  list: pantryListAdapter.getInitialState(),
  isCreateModalShown: false,
  exitWarning: {
    handler: null,
  },
  keyword: '',
  currentPage: 1,
  sortBy: undefined,
  filterBy: null,
  detailFilter: {},
};

export const pantryListReducer = createReducer(initialState, (builder) => {
  builder.addCase(logoutThunk.fulfilled, () => {
    return initialState;
  });
  builder.addCase(setImpersonated, () => {
    return initialState;
  });
  builder.addCase(showCreatePantryListModal, (state) => {
    state.isCreateModalShown = true;
  });
  builder.addCase(hideCreatePantryListModal, (state) => {
    state.isCreateModalShown = false;
  });
  builder.addCase(showPantryListExitWarning, (state, { payload }) => {
    state.exitWarning = payload;
  });
  builder.addCase(setSelectedPantryListId, (state, { payload }) => {
    state.selectedPantryListId = payload;
  });
  builder.addCase(setPantryListProductFilter, (state, { payload }) => {
    state.detailFilter = payload;
  });
  builder.addCase(setPantryListPage, (state, { payload }) => {
    state.currentPage = payload;
  });
  builder.addCase(setPantryListsSortFilters, (state, { payload }) => {
    state.list = pantryListAdapter.getInitialState();
    state.sortBy = payload.sort;
    state.filterBy = payload.filter;
    state.currentPage = 1;
  });
  builder.addCase(setPantryListKeyword, (state, { payload }) => {
    if (state.keyword === payload) {
      return;
    }
    state.keyword = payload;
    state.currentPage = 1;
    state.list = pantryListAdapter.getInitialState();
  });
  builder.addCase(hidePantryListExitWarning, (state) => {
    state.exitWarning = {
      handler: null,
    };
  });
  builder.addCase(deletePantryList, (state, { payload }) => {
    state.list = pantryListAdapter.removeOne(state.list, payload);
  });
  builder.addMatcher(pantryListApi.endpoints.getPantryLists.matchFulfilled, (state, action) => {
    state.list = pantryListAdapter.setMany(state.list, action.payload.pantry_lists);
  });
  builder.addMatcher(pantryListApi.endpoints.createPantryList.matchFulfilled, (state, { payload }) => {
    state.selectedPantryListId = payload.pantry_list.id;
    state.list = pantryListAdapter.getInitialState();
    state.currentPage = 1;
  });
  builder.addMatcher(pantryListApi.endpoints.renamePantryList.matchFulfilled, (state, { payload }) => {
    state.list = pantryListAdapter.upsertOne(state.list, payload.pantry_list);
  });
  builder.addMatcher(pantryListApi.endpoints.addToPantryList.matchFulfilled, (state) => {
    state.list = pantryListAdapter.getInitialState();
    state.currentPage = 1;
  });
  builder.addMatcher(pantryListApi.endpoints.removeFromPantryList.matchFulfilled, (state) => {
    state.list = pantryListAdapter.getInitialState();
    state.currentPage = 1;
  });
});
