import React, { memo } from 'react';

import clsx from 'clsx';
import LinesEllipsis from 'react-lines-ellipsis';

import {
  ManualProductPreview,
  ManualProductPreviewWithError,
  ProductPreview,
  ProductPreviewWithError,
} from '../../../../../../api/product/types';

import { useStyles } from './style';

interface Props {
  el: ProductPreview | ProductPreviewWithError | ManualProductPreview | ManualProductPreviewWithError;
  title: string;
  isError?: boolean;
}

const Component: React.FC<Props> = ({ el, title, isError }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.rowContent, isError && classes.rowContentError)}>
      <div className={classes.idCol}>{el.product_id}</div>
      <div className={classes.actionCol}>{title}</div>
      <div className={classes.nameCol}>
        <LinesEllipsis text={el.name || ''} ellipsis='...' maxLine={1} />
      </div>
      <div className={classes.unitCol}>
        <LinesEllipsis text={el.unit || ''} ellipsis='...' maxLine={1} />
      </div>
      <div className={classes.msgCol}>
        <LinesEllipsis
          text={
            'field_errors' in el
              ? Object.entries(Object.values((el as ProductPreviewWithError).field_errors)[0]).reduce((acc, [field, error], i, arr) => {
                  acc += `${field} - ${error}${i === arr.length - 1 ? '' : ', '}`;
                  return acc;
                }, '')
              : ''
          }
          maxLine={1}
          ellipsis='...'
        />
      </div>
      <div className={clsx(classes.rowCol, classes.rowCollCentered)}>
        {'field_errors' in el ? Object.keys((el as ProductPreviewWithError).field_errors)[0]?.replace('Line number ', '') : ''}
      </div>
    </div>
  );
};

export const PreviewProductItem = memo(Component);
