import React, { useEffect, useState } from 'react';

import clsx from 'clsx';
import { FastField, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { useResetTeammatePasswordMutation, useUpdateTeammateMutation } from '../../../../api/teammates';
import { useGeIsTeammateAdmin } from '../../../../api/teammates/hooks';
import { Teammate, TeammatePermission } from '../../../../api/teammates/types';
import { ThemedButton } from '../../../../shared/components/themed-button';
import { FormikInput } from '../../../../shared/components/formik-input';
import { OverlayModal } from '../../../../shared/components/overlay-modal';
import { useScreenSize } from '../../../../shared/hooks/use-screen-size';
import { ToastService } from '../../../../shared/services/toastService';
import { SubmitPermissionDropdown } from '../submit-permission-dropdown';

import { useStyles } from './style';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  item: Teammate;
}

interface Values {
  firstName: string;
  lastName?: string;
  email: string;
  permission?: TeammatePermission;
  phone?: string;
}

export const EditTeammateModal: React.FC<Props> = ({ isOpen, onClose, item }) => {
  const classes = useStyles();
  const { isMobile } = useScreenSize();

  const [editMode, setEditMode] = useState(false);
  const [currentPermission, setCurrentPermission] = useState<TeammatePermission>(item.permission);

  const [updateTeammate, { isLoading }] = useUpdateTeammateMutation();
  const [resetPassword, { isLoading: resetLoading }] = useResetTeammatePasswordMutation();
  const isViewer = !useGeIsTeammateAdmin();

  const schema = Yup.object().shape({
    email: Yup.string().required('Required field').email('Enter correct email!'),
    firstName: Yup.string().required('Required field'),
  });

  const onSetCurrentPermission = (option: TeammatePermission) => {
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    setCurrentPermission(option);
  };

  const onSubmit = async ({ firstName, lastName, email, phone }: Values, formikHelpers: FormikHelpers<any>) => {
    formikHelpers.setSubmitting(false);
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    updateTeammate({
      id: item.id,
      teammate: {
        first_name: firstName,
        last_name: lastName,
        email,
        phone_number: phone,
      },
      permission: currentPermission,
    }).then((res) => {
      if ('data' in res && res.data?.success) {
        onClose();
      }
    });
  };

  const onResetPassword = () => {
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    resetPassword(item.id).then((res) => {
      if ('data' in res && res.data.success) {
        ToastService.success(
          `You have reset the password for [${item.first_name} ${item.last_name || ''}] please check email: [${
            item.email
          }] for further instructions.`,
          item.email,
          'Teammate Password Reset',
        );
      }
    });
  };

  useEffect(() => {
    !isOpen && setEditMode(false);
  }, [isOpen]);

  useEffect(() => {
    setCurrentPermission(item.permission);
  }, [item.permission]);

  return (
    <OverlayModal isOpen={isOpen} onClose={onClose} boxClassName={classes.modalRoot}>
      <div className={classes.modalHead}>
        <div className={classes.title}>View Teammate Profile</div>
        <div className={classes.dropdownWrapM}>
          <div className={classes.permLabel}>Permissions</div>
          {editMode ? (
            <SubmitPermissionDropdown item={item} setPermission={onSetCurrentPermission} />
          ) : (
            <div className={classes.permissionText}>{item.permission}</div>
          )}
        </div>
      </div>
      <div className={classes.subTitle}>Basic information</div>

      <Formik
        initialValues={{
          firstName: item.first_name,
          lastName: item.last_name || '',
          email: item.email,
          phone: item.phone_number || '',
        }}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={schema}
      >
        {({ submitForm, isValid, values }) => (
          <Form>
            <div className={classes.nameFields}>
              <div className={clsx(classes.fieldWrap, editMode && classes.fieldWrapDisabled)}>
                <FastField component={FormikInput} name='firstName' label='First Name' placeholder='First Name' disabled={true} />
              </div>
              <div className={clsx(classes.fieldWrap, editMode && classes.fieldWrapDisabled)}>
                <FastField component={FormikInput} name='lastName' label='Last Name' placeholder='Last Name' disabled={true} />
              </div>
            </div>
            <div className={classes.nameFields}>
              <div className={classes.fieldWrap}>
                {editMode ? (
                  <FastField component={FormikInput} name='email' label='Email address' placeholder='Email' />
                ) : (
                  <FastField component={FormikInput} name='email' label='Email address' placeholder='Email' disabled={true} />
                )}
              </div>
              <div className={classes.fieldWrap}>
                {editMode ? (
                  <FastField component={FormikInput} name='phone' label='Phone' placeholder='Phone' />
                ) : (
                  <FastField component={FormikInput} name='phone' label='Phone' placeholder='Phone' disabled={true} />
                )}
              </div>
            </div>
            <div className={classes.bottomRow}>
              {!editMode ? (
                <div className={classes.btnsBox}>
                  <ThemedButton onClick={onResetPassword} title='Reset Password' buttonStyle='blueTransparent' disabled={resetLoading} />
                  <ThemedButton onClick={setEditMode.bind(null, true)} title='Edit' buttonStyle='blueTransparent' />
                </div>
              ) : (
                <div />
              )}
              <div className={classes.btnsBox}>
                <ThemedButton onClick={onClose} title='Cancel' buttonStyle='secondary' width={isMobile ? 136 : undefined} />
                {editMode && (
                  <ThemedButton
                    onClick={submitForm}
                    title={'Save Changes'}
                    disabled={!values.firstName || !values.email || !isValid || isLoading}
                    width={isMobile ? 136 : undefined}
                  />
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </OverlayModal>
  );
};
