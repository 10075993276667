import React, { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import MuiTextField from '@material-ui/core/TextField';
import { Close } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useDebouncedCallback } from 'use-debounce';

import { useLazyGetProductsSuggestionsQuery } from '../../../../../api/product';
import { CustomerSearchIcon } from '../../../../../shared/components/customer-search-icon';
import { setGtmDatalayer } from '../../../../../shared/helpers/setGtmDatalayer';
import { useScreenSize } from '../../../../../shared/hooks/use-screen-size';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { getProductSortFilters, setProductsByCategoryKeyword } from '../../../../../store/products';
import { getRecipeProductSortFilters, setRecipeProductsKeyword } from '../../../../../store/recipe-products';

import { useStyles } from './style';

interface Props {
  placeholder: string;
  redirect?: boolean;
  redirectOnApply?: boolean;
  supplierMode?: boolean;
  recipeProducts?: boolean;
}

export const SearchProductsField: React.FC<Props> = ({ placeholder, redirect, redirectOnApply, supplierMode, recipeProducts }) => {
  const classes = useStyles();
  const { isDesktop, isMobile } = useScreenSize();
  const dispatch = useAppDispatch();
  const inputRef = useRef<any>(null);
  const hiddenDiv = useRef<HTMLInputElement | null>(null);
  const { push, replace } = useHistory();
  const { state: navState } = useLocation<{ keepKeyword?: boolean }>();

  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [searchOptions, setSearchOptions] = useState<string[]>([]);

  const { filter: productFilter, keyword: productKeyword } = useAppSelector(
    recipeProducts ? getRecipeProductSortFilters : getProductSortFilters,
  );

  const [getSuggestions, { suggestions, suggestionsReceived, suggestionLoading, products }] = useLazyGetProductsSuggestionsQuery({
    selectFromResult: ({ data, isSuccess, isFetching }) => ({
      suggestions: data?.data.products?.length
        ? (data?.data.products || []).map((el) => `${el.brand || ''} ${el.name} ${el.unit}`.trimStart())
        : data?.data.suggestions || [],
      suggestionsReceived: isSuccess,
      suggestionLoading: isFetching,
      products: data?.data.products || [],
    }),
  });

  const debouncedHandleOnChange = useDebouncedCallback((fieldValue: string) => {
    fieldValue.trim().length > 1 && getSuggestions({ filter: productFilter, keyword: fieldValue, supplierMode });
  }, 300);

  const onInputChange = (event: ChangeEvent<{}>, newInputValue: string) => {
    setInputValue(newInputValue);
    if (newInputValue.trim().length > 1 && selectedValue !== newInputValue) {
      setLoading(true);
      debouncedHandleOnChange(newInputValue);
      setIsOpen(true);
    }
  };

  const openCategories = () => {
    push('/categories', { keepKeyword: true });
  };

  const onSelect = (event: ChangeEvent<any>, newValue: string | null) => {
    const product = products.find((el) => newValue === `${el.brand || ''} ${el.name} ${el.unit}`.trimStart());
    const searchByCode = product?.product_id?.includes(inputValue);
    setSelectedValue(product ? (searchByCode ? inputValue : product.name) : newValue);
    if (newValue) {
      dispatch(
        recipeProducts
          ? setRecipeProductsKeyword({ keyword: product ? (searchByCode ? inputValue : product.name) : newValue })
          : setProductsByCategoryKeyword({ keyword: product ? (searchByCode ? inputValue : product.name) : newValue }),
      );
      setGtmDatalayer({
        event: 'search',
        eventCategory: newValue,
      });
    }
    setIsOpen(false);
    inputValue && redirectOnApply && openCategories();
  };

  const onEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      hitSearchWithCurrentValue();
      const input = inputRef?.current?.querySelector('input') as HTMLInputElement;
      input?.blur();
      hiddenDiv?.current?.focus();
    }
  };

  const onClear = () => {
    setSelectedValue(null);
    resetSearch();
    setInputValue('');
    setIsOpen(false);
    const input = inputRef?.current?.querySelector('input') as HTMLInputElement;
    input?.focus();
  };

  const clearInput = () => {
    setSelectedValue(null);
    resetSearch();
    setInputValue('');
    setIsOpen(false);
  };

  const hitSearchWithCurrentValue = () => {
    setIsOpen(false);
    setSelectedValue(inputValue);
    const product = products.find((el) => inputValue === `${el.brand || ''} ${el.name} ${el.unit}`.trimStart());
    dispatch(
      recipeProducts
        ? setRecipeProductsKeyword({ keyword: product ? product.name : inputValue.trim() ? inputValue : undefined })
        : setProductsByCategoryKeyword({ keyword: product ? product.name : inputValue.trim() ? inputValue : undefined }),
    );
    (product || inputValue) &&
      setGtmDatalayer({
        event: 'search',
        eventCategory: product ? product.name : inputValue.trim(),
      });
    inputValue && redirectOnApply && openCategories();
  };

  const setValueOnClear = (event: ChangeEvent<any>, reason: string) => {
    if (event.target.value && reason === 'blur') {
      setInputValue(event.target.value);
    }
    setIsOpen(false);
  };

  const resetSearch = () => {
    recipeProducts
      ? dispatch(setRecipeProductsKeyword({ keyword: undefined }))
      : dispatch(setProductsByCategoryKeyword({ keyword: undefined }));
  };

  useEffect(() => {
    suggestionsReceived && !suggestionLoading && setLoading(false);
  }, [suggestionsReceived, suggestionLoading]);

  useEffect(() => {
    productKeyword && productKeyword !== selectedValue && setSelectedValue(productKeyword);
    !productKeyword && setSelectedValue(null);
  }, [productKeyword]);

  useEffect(() => {
    if (!isMobile) {
      return;
    }
    if (redirect) {
      setSelectedValue(null);
      resetSearch();
      setInputValue('');
    }
    const input = inputRef?.current?.querySelector('input') as HTMLInputElement;
    redirect && input?.focus();
  }, [redirect, isMobile]);

  useEffect(() => {
    if (!inputValue) {
      setIsOpen(false);
    }
    if (suggestionLoading) {
      setSearchOptions([]);
      return;
    }
    setSearchOptions(!inputValue ? [] : suggestions);
  }, [inputValue, suggestionLoading]);

  useEffect(() => {
    if (!inputValue && productKeyword && !navState?.keepKeyword) {
      dispatch(recipeProducts ? setRecipeProductsKeyword({ keyword: undefined }) : setProductsByCategoryKeyword({ keyword: undefined }));
    }
    if (!!navState?.keepKeyword) {
      replace('/categories');
    }
  }, [inputValue]);

  useEffect(() => {
    hitSearchWithCurrentValue();
  }, [productFilter?.supplier_id]);

  return (
    <div className={classes.box}>
      <Autocomplete
        filterOptions={(options, state) => {
          return state.inputValue.trim().length > 1 ? options : [];
        }}
        open={isOpen}
        loading={loading}
        blurOnSelect={true}
        clearOnBlur={true}
        onClose={setValueOnClear}
        popupIcon={null}
        value={selectedValue}
        onChange={onSelect}
        inputValue={inputValue}
        onInputChange={onInputChange}
        onKeyPress={onEnter}
        classes={{
          root: classes.rootField,
          paper: classes.paper,
          option: classes.option,
          noOptions: classes.noOptions,
          loading: classes.noOptions,
        }}
        noOptionsText='Products not found'
        options={searchOptions}
        renderInput={(params) => (
          <MuiTextField
            {...params}
            ref={inputRef}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              endAdornment: inputValue && !selectedValue ? <Close className={classes.closeIcon} onClick={clearInput} /> : null,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
      />
      <div className={classes.searchBtn}>
        <CustomerSearchIcon
          isSearchOpened={!!selectedValue}
          toggleSearch={selectedValue ? onClear : hitSearchWithCurrentValue}
          size={isDesktop ? 30 : 36}
          iconFontSize={isDesktop ? 20 : 24}
          disabled={!inputValue}
        />
      </div>
      <input
        tabIndex={11}
        className={classes.hidden}
        ref={hiddenDiv}
        onFocus={() => {
          hiddenDiv.current?.blur();
        }}
      />
    </div>
  );
};
