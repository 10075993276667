import { createReducer } from '@reduxjs/toolkit';

import { UsersFilters, UsersSortBy } from '../../../api/admin-users/types';
import { logoutThunk } from '../../user';
import { resetUsersParams, setSelectedUserId, setSelectedUsers, setUsersKeyword, setUsersPage, setUsersSortFilters } from './users.actions';

interface State {
  sortBy?: UsersSortBy;
  filterBy?: UsersFilters;
  keyword?: string;
  currentPage: number;
  selectedUsers: number[];
  selectedUserId: number | null;
}

const initialState: State = {
  sortBy: undefined,
  filterBy: undefined,
  keyword: undefined,
  currentPage: 1,
  selectedUsers: [],
  selectedUserId: null,
};

export const usersReducer = createReducer(initialState, (builder) => {
  builder.addCase(logoutThunk.fulfilled, () => {
    return initialState;
  });
  builder.addCase(setUsersKeyword, (state, { payload }) => {
    if (state.keyword === payload) {
      return;
    }
    state.keyword = payload;
    state.currentPage = 1;
  });
  builder.addCase(setUsersPage, (state, { payload }) => {
    state.currentPage = payload;
    state.selectedUsers = [];
  });
  builder.addCase(setUsersSortFilters, (state, { payload }) => {
    state.filterBy = payload.filter;
    state.sortBy = payload.sort;
    state.currentPage = 1;
  });
  builder.addCase(setSelectedUsers, (state, { payload }) => {
    state.selectedUsers = payload;
  });
  builder.addCase(setSelectedUserId, (state, { payload }) => {
    state.selectedUserId = payload;
  });
  builder.addCase(resetUsersParams, () => {
    return initialState;
  });
});
