import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../../styles/colorVariables';
import { Sizes } from '../../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    supplierItem: {
      position: 'relative',
      display: 'flex',
      height: 119,
      width: 258,
      alignItems: 'center',
      backgroundColor: colorVariables.white,
      borderRadius: 8,
      border: '1px solid #F2F3F7',
      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.05)',
      padding: '15px 10px 40px 15px',
      '&:hover': {
        borderColor: colorVariables.green,
        cursor: 'pointer',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        '&:hover': {
          borderColor: '#F2F3F7',
        },
      },
    },
    supplierItemSelected: {
      borderColor: colorVariables.green,
    },
    supplierItemOnboarding: {
      [theme.breakpoints.down(Sizes.mobile)]: {
        height: 87,
        padding: '15px 10px 15px 15px',
      },
    },
    supplierInfo: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    supplierDetailsBox: {
      flex: 1,
      overflow: 'hidden',
    },
    avatarWrap: {
      width: 47,
      height: 47,
      borderRadius: '50%',
      overflow: 'hidden',
      flexShrink: 0,
      marginRight: 15,
      boxShadow: `0 0 0 1px ${colorVariables.steelGrey}`,
    },
    avatarWrapBig: {
      width: 67,
      height: 67,
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 57,
        height: 57,
      },
    },
    supplierName: {
      flexGrow: 1,
      fontSize: 16,
      lineHeight: '20px',
      fontWeight: 600,
      marginBottom: 3,
    },
    supplierAddress: {
      fontSize: 13,
      lineHeight: '16px',
      color: colorVariables.grey60,
    },
    reqBtn: {
      width: 120,
      minWidth: 'auto',
      padding: '0 5px',
      fontSize: 13,
      height: 30,
      lineHeight: '30px',
      flexShrink: 0,
      position: 'absolute',
      bottom: 8,
      right: 3,
      '& .MuiButton-endIcon': {
        marginLeft: 0,
      },
    },
    reqBtnOnBoarding: {
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    reqBtnPrimary: {
      backgroundColor: colorVariables.greenLight,
      color: colorVariables.green,
      '&:hover': {
        backgroundColor: 'rgb(188,241,225)',
      },
    },
    reqBtnSecondary: {
      color: colorVariables.grey60,
      backgroundColor: colorVariables.grey20,
      '&:hover': {
        backgroundColor: colorVariables.steelGrey,
      },
    },
    reqBtnAdd: {
      transition: 'all ease-in-out 0.3s',
      '&:hover': {
        borderColor: colorVariables.green,
        color: colorVariables.white,
        backgroundColor: colorVariables.green,
        '&:active': {
          backgroundColor: colorVariables.grey10,
          borderColor: colorVariables.greenLight,
        },
        '&:disabled': {
          backgroundColor: colorVariables.grey40,
        },
      },
    },
    addBtnWrap: {
      display: 'none',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'flex',
        position: 'absolute',
        top: -5,
        right: -5,
      },
    },
    tickWrap: {
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 34,
        height: 34,
        backgroundColor: colorVariables.greenLight,
        borderRadius: 17,
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    doneIcon: {
      color: colorVariables.green,
      fontSize: 22,
    },
    managePricingBox: {
      position: 'absolute',
      left: 8,
      bottom: 12,
    },
    managePricingTrigger: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 12,
      color: colorVariables.blue,
      fontWeight: 600,
      cursor: 'pointer',
      userSelect: 'none',
      '&:hover': {
        scale: 1.03,
      },
      '& svg': {
        fontSize: 16,
        marginTop: 2,
      },
    },
  }),
);
