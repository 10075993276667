import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../styles/colorVariables';
import { Sizes } from '../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: '102px 0 70px 0',
    },
    postCodesTable: {
      display: 'flex',
      flexWrap: 'wrap',
      height: '60vh',
      overflowY: 'scroll',
      [theme.breakpoints.down(Sizes.desktop)]: {
        height: '45vh',
      },
    },
    postCodeTableCell: {
      minWidth: 50,
      width: '25%',
      textAlign: 'right',
      paddingRight: 30,
      margin: '8px 0',
      position: 'relative',
      flexShrink: 0,
      overflow: 'hidden',
    },
    closeIconWrap: {
      width: 18,
      height: 18,
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0,
      border: '1px solid #DADADA',
      cursor: 'pointer',
      position: 'absolute',
      right: 5,
      top: 1,
      backgroundColor: colorVariables.white,
      boxShadow: '0 0 0 6px #ffffff',
    },
    closeIcon: {
      color: '#DADADA',
      fontSize: 14,
    },
    bottomNavWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    viewAllHeader: {
      display: 'flex',
      justifyContent: 'flex-end',
      height: 46,
      padding: '15px 20px',
      backgroundColor: 'rgba(152, 165, 183, 0.15)',
      textTransform: 'uppercase',
      fontWeight: 700,
      fontSize: 12,
    },
    allCodesText: {
      fontWeight: 700,
      marginRight: 15,
      fontSize: 11,
      color: colorVariables.grey60,
    },
    postCodesList: {
      display: 'flex',
      flexWrap: 'wrap',
      alignContent: 'flex-start',
      height: 'calc(100vh - 280px)',
      overflowY: 'scroll',
      padding: '10px 10px 10px 5px',
      borderBottom: `1px solid ${colorVariables.steelGrey}`,
      marginBottom: 10,
    },
    viewAllBtnBox: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: 15,
    },
    removeWarning: {
      position: 'fixed',
      bottom: 80,
      right: 10,
    },
  }),
);
