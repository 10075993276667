export const faqsListRestaurant = [
  {
    id: 1,
    path: 'faqs',
    title: 'Is Open Pantry free to use?',
    content: `
<p>Open Pantry is a completely free ordering platform you to use & joining is easy! Simply click “Get Open Pantry Free” and follow the onboarding prompts. Set up takes just a few minutes. It's free for suppliers too, so connect with your ones and invite any not on the platform to do all of your ordering in one place.</p>
`,
  },
  {
    id: 2,
    path: 'faqs',
    title: 'What suppliers can I order with?',
    content: `
<p>Open Pantry is the ideal wholesale ordering app for all things hospitality, connecting venues with suppliers of every category. </p>
<p>Open Pantry  provides access to  a huge variety of suppliers such as Fresh Produce - Fruit & Vegetables, Meats & Butchers, Poultry, eggs, Chicken, Seafood, Dry & Frozen Goods, Pantry Goods, Food Service,  Delicatessen - Small Goods, Dairy - Cheese, Dairy Milk & Alternative Milks, Bakery goods from Bread to Cakes & Sweets, Beer, Wine, Spirits, Alcohol & Beverages - Soft Drinks, Juices, Kombucha, Coffee & Tea - Chai & Hot Chocolate, Lollies, Ice Cream & Gelato suppliers, Gluten Free suppliers, Sustainability & Vegan focussed suppliers. Hospitality Supplies - Packaging & Paper Products, Cleaning and Chemical suppliers, Basically any food and beverage suppliers.</p>
`,
  },
  {
    id: 3,
    path: 'faqs',
    title: 'How do I get set up?',
    content: `
<p>Simply head to our website  <a href="https://www.app.theopenpantry.com">https://www.app.theopenpantry.com</a> and click the button “get open pantry free”. Follow our easy onboarding steps, connect with your suppliers and that’s it. You can now place all of your orders from your phone. Setup takes only minutes! </p>
`,
  },
  {
    id: 4,
    path: 'faqs',
    title: 'What if a supplier I want to order with isn’t on Open Pantry?',
    content: `
<p>Joining is easy for suppliers, if you have a supplier you'd like to order from on the open pantry platform, select add supplier & invite them! (Its free for them too). We will walk them through the set up process and will let you know when you are ready to go!</p>
`,
  },
  {
    id: 5,
    path: 'faqs',
    title: 'Is there a feature for menu planning / menu costing?',
    content: `
<p>Open Pantry is a constantly evolving platform working with customers to meet all of their business needs. We are busy working on amazing features to simplify your business. We are currently building in a feature where you can plan your menus and cost them in our system for free. This will be accessible to all customers from mid to late 2023.<p/>
`,
  },
  {
    id: 6,
    path: 'faqs',
    title: 'Do you have Open Pantry near me?',
    content: `
<p>Open Pantry’s wholesale stock ordering system services the whole of Australia. This includes Melbourne and servicing the whole of Victoria (VIC) including Geelong. We are also connecting wholesale food suppliers with their customers in New South Wales (NSW) which includes Sydney, Newcastle, Central Coast, Wollongong.</p>
<p>We also work in (QLD) Queensland servicing Brisbane, Gold Coast - Tweed Heads, Townsville, Cairns Toowoomba and also (NT) Northern Territory - Alice Springs and Darwin. We also service Perth and Tasmania.
</p>
`,
  },
  {
    id: 7,
    path: 'faqs',
    title: 'Who should use Open Pantry?',
    content: `
<p>All hospitality venues will benefit from using Open Pantry! Some examples are:</p>
    <p>Fast food</p>
    <p>Restaurants</p>
    <p>Cafes</p>
    <p>Pubs</p>
    <p>Bars</p>
    <p>Clubs</p>
    <p>Hospitals</p>
    <p>Aged care</p>
    <p>Defence</p>
    <p>Correctional</p>
    <p>Corporate</p>
    <p>Educational</p>
    <p>Accomodation</p>
    <p>Hotels</p>
    <p>Events</p>
    <p>Stadiums</p>
    <p>Leisure</p>
    <p>Travel</p>
`,
  },
  {
    id: 8,
    path: 'faqs',
    title: 'Why choose OP to be your ‘go to’ platform?',
    content: `
<p>We are a platform made by chefs for chefs, managers & beyond, enabling hospitality businesses to order from all of their suppliers as well as engage with new suppliers all in the one place. We are a free to use platform with an entire team dedicated to ensuring the platform meets your needs & evolves with your requirements.! </p>`,
  },
  {
    id: 9,
    path: 'faqs',
    title: 'How do I pay for Open Pantry orders?',
    content: `
<p>Paying for orders has never been easier. You can pay at the time of ordering thorough Open Pantry or order on account with your supplier - this method is set as the default. We send through the orders to the supplier and they will fulfil the order for you.  
</p>`,
  },
  {
    id: 10,
    path: 'faqs',
    title: 'How do deliveries work?',
    content: `
<p>Think of Open Pantry as a replacement for your current method of ordering. Instead of emailing an order or phoning in an order, Open Pantry will generate a purchase order and send it off to each supplier.</p>
<p>Each supplier has their own existing cut off times for next day delivery and minimum order amounts for free delivery, these are visible on the platform for each supplier. </p>
<p>When a supplier receives an order they will pick/pack and deliver that order individually. You can select your preferred delivery date upon checkout and add any notes you might have.
</p>
`,
  },
];
