import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';
import { Sizes } from '../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    backLink: {
      color: colorVariables.navy,
      fontWeight: 600,
      display: 'inline-flex',
      alignItems: 'center',
      cursor: 'pointer',
      userSelect: 'none',
      '&:hover *': {
        color: colorVariables.grey60,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    visible: {
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'inline-flex',
      },
    },
    backLinkCustomer: {
      [theme.breakpoints.down(Sizes.desktop)]: {
        display: 'none',
      },
    },
    backChevron: {
      color: colorVariables.navy,
      fontSize: 18,
      marginRight: 5,
    },
  }),
);
