import { SuccessResponse } from '../index';
import { SortDir } from '../product/types';

export enum TeammatePermission {
  ADMIN = 'admin',
  EDITOR = 'editor',
  VIEWER = 'viewer',
  CUSTOM = 'custom',
}

export interface Teammate {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string | null;
  last_login_at: string | null;
  created_at: string;
  permission: TeammatePermission;
  custom_permission: CustomPermission | null;
}

export interface CustomPermission {
  id: number;
  automated_ordering: string;
  billing: string;
  dashboard: string;
  inventory: string;
  menu_planning: string;
  order_history: string;
  pantry_lists: string;
  reports: string;
  sales_and_wastages: string; // +
  supplier_and_categories: string;
  teammates: string;
  vanue_info: string;
  product_type_categories: {
    alchohol_and_beverages: boolean;
    bakery: boolean;
    coffee_and_tea: boolean;
    dairy: boolean;
    dry_and_frozen_goods: boolean;
    fruits_and_vegetables: boolean;
    hospitality_supplies: boolean;
    meat: boolean;
    poultry: boolean;
    seafood: boolean;
    small_goods: boolean;
  };
}

export interface GetTeammatesResponse extends SuccessResponse {
  teammates: Teammate[];
}

export type TeammateSortField = 'name' | 'last_active' | 'permissions';

export type TeammatesSortBy = [TeammateSortField, SortDir];

export interface GetTeammatesListReqParams {
  keyword?: string;
  sort?: TeammatesSortBy;
}

export interface TeammatesListQueryParams {
  'q[keyword]'?: string;
  'q[sort_by]'?: string;
}

export interface InviteTeammateReq {
  teammate: {
    first_name: string;
    last_name?: string;
    email: string;
    phone_number?: string;
  };
  permission?: TeammatePermission;
  skipInvalidation?: boolean;
}

export interface InviteTeammateRes extends SuccessResponse {
  teammate: Teammate;
}

export interface UpdateTeammateReq extends InviteTeammateReq {
  id: number;
  skipInvalidation?: boolean;
}

export enum CustomPermissionForReq {
  view = 0,
  edit = 1,
  hidden = 2,
}

export interface UpdateCustomPermission {
  id: number;
  custom_permission: CustomPermissionForRequest;
}

export interface CustomPermissionForRequest {
  pantry_lists?: CustomPermissionForReq;
  order_history?: CustomPermissionForReq;
  supplier_and_categories?: CustomPermissionForReq;
  dashboard?: CustomPermissionForReq;
  teammates?: CustomPermissionForReq;
  vanue_info?: CustomPermissionForReq;
  billing?: CustomPermissionForReq;
  menu_planning?: CustomPermissionForReq;
  inventory?: CustomPermissionForReq;
  sales_and_wastages?: CustomPermissionForReq;
  reports?: CustomPermissionForReq;
  automated_ordering?: CustomPermissionForReq;
  product_type_categories?: {
    meat?: boolean;
    dairy?: boolean;
    bakery?: boolean;
    poultry?: boolean;
    seafood?: boolean;
    small_goods?: boolean;
    coffee_and_tea?: boolean;
    dry_and_frozen_goods?: boolean;
    hospitality_supplies?: boolean;
    fruits_and_vegetables?: boolean;
    alchohol_and_beverages?: boolean;
  };
}
