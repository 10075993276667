import { DeliveryDay } from '../../api/company/types';

export const week = [
  { title: 'MON', longTitle: 'monday', key: DeliveryDay.MONDAY },
  { title: 'TUE', longTitle: 'tuesday', key: DeliveryDay.TUESDAY },
  { title: 'WED', longTitle: 'wednesday', key: DeliveryDay.WEDNESDAY },
  { title: 'THU', longTitle: 'thursday', key: DeliveryDay.THURSDAY },
  { title: 'FRI', longTitle: 'friday', key: DeliveryDay.FRIDAY },
  { title: 'SAT', longTitle: 'saturday', key: DeliveryDay.SATURDAY },
  { title: 'SUN', longTitle: 'sunday', key: DeliveryDay.SUNDAY },
];
