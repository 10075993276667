import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { FastField, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { useMeQuery, useSendReferralMutation } from '../../../api/user';
import Logo from '../../../assets/images/logo/Logo_White.svg';
import { ThemedButton } from '../../../shared/components/themed-button';
import { FormikInput } from '../../../shared/components/formik-input';
import { useScreenSize } from '../../../shared/hooks/use-screen-size';
import { LocalStorageService } from '../../../shared/services/localStorage.service';
import { useAppSelector } from '../../../store';
import { getIsRestaurant } from '../../../store/user';

import { useStyles } from './style';
import { useCurrentTheme } from '../../../api/admin-themes/hooks';

export const Referral: React.FC = () => {
  const classes = useStyles();
  const { isMobile } = useScreenSize();
  const { replace, push } = useHistory();
  const { search } = useLocation();
  const userId = search.split('user_id=')[1];

  const initialValues = Object.assign(
    {},
    ...new Array(5).fill(1).map((_, idx) => ({
      [`name-${idx}`]: '',
      [`email-${idx}`]: '',
    })),
  );

  const [formData, setFormData] = useState<any>({});

  const isRestaurant = useAppSelector(getIsRestaurant);
  const { data: user } = useMeQuery();
  const { logoUrl } = useCurrentTheme();

  const [fieldsCount, setFieldsCount] = useState(5);

  const [validationSchema, setValidationSchema] = useState(
    Yup.object().shape(
      Object.assign(
        {},
        ...new Array(5).fill(1).map((_, idx) => ({
          [`email-${idx}`]: Yup.string()
            .ensure()
            .when(`name-${idx}`, {
              is: (name: string) => !!name?.trim(),
              then: Yup.string().required('Required field'),
            })
            .email('Enter correct email!'),
        })),
      ),
    ),
  );

  const [sendRef, { isSuccess, isLoading }] = useSendReferralMutation();

  const onAddRow = (values: any) => {
    setFormData({ ...values, [`email-${fieldsCount}`]: '', [`name-${fieldsCount}`]: '' });
    setFieldsCount(fieldsCount + 1);
  };

  const onSubmit = (values: any, formikHelpers: FormikHelpers<any>) => {
    formikHelpers.setSubmitting(false);
    const refData = new Array(fieldsCount)
      .fill(1)
      .map((_, idx) => ({
        name: values[`name-${idx}`],
        email: values[`email-${idx}`],
      }))
      .filter((el) => el.email);
    if (!refData.length) {
      formikHelpers.setFieldError('email-0', 'Required field');
      return;
    }
    if (!isLoading) {
      sendRef(refData);
    }
  };

  const goHome = () => {
    push(!isRestaurant ? '/dashboard' : isRestaurant ? '/browse_by_supplier' : '/');
  };

  useEffect(() => {
    setValidationSchema(
      Yup.object().shape(
        Object.assign(
          {},
          ...new Array(fieldsCount).fill(1).map((_, idx) => ({
            [`email-${idx}`]: Yup.string()
              .ensure()
              .when(`name-${idx}`, {
                is: (name: string) => !!name?.trim(),
                then: Yup.string().required('Required field'),
              })
              .email('Enter correct email!'),
          })),
        ),
      ),
    );
  }, [fieldsCount]);

  useEffect(() => {
    if (isSuccess) {
      replace('/');
    }
  }, [isSuccess]);

  useEffect(() => {
    return () => {
      LocalStorageService.clear('referral_supplier');
      LocalStorageService.clear('referral_restaurant');
    };
  }, []);

  useEffect(() => {
    if ((!userId && user) || (userId && user && +userId !== user.id)) {
      replace('/');
    }
  }, [user?.id]);

  return (
    <div className={classes.container}>
      <div className={classes.logoWrap}>
        <img
          src={logoUrl || Logo}
          alt='OpenPantry'
          className={classes.logo}
          onClick={goHome}
          style={{ objectFit: 'contain', maxHeight: 70 }}
        />
      </div>
      {isMobile ? (
        <h1 className={classes.title}>
          {isRestaurant
            ? 'Refer 5 or more other Restaurants that might benefit from our services'
            : 'Refer 5 or more other Suppliers that might benefit from our services'}
        </h1>
      ) : (
        <h1 className={classes.title}>Share the love!</h1>
      )}
      {isRestaurant ? (
        <p className={classes.subTitle}>
          {isMobile
            ? 'All we need is their email and we will send them an invite to join.'
            : `Refer 5 or more other Restaurants that might benefit from our service. All we need is their email and we will send them an invite
              to join :)`}
        </p>
      ) : (
        <p className={classes.subTitle}>
          {isMobile
            ? 'All we need is their email and we will send them an invite to join.'
            : `Refer 5 or more other Suppliers that might benefit from our service. All we need is their email and we will send them an invite to join :)`}
        </p>
      )}
      <div className={classes.fieldsBox}>
        <Formik
          initialValues={{ ...initialValues, ...formData }}
          onSubmit={onSubmit}
          enableReinitialize={true}
          validationSchema={validationSchema}
        >
          {({ submitForm, values }) => (
            <Form>
              {new Array(fieldsCount).fill(1).map((el, idx) => (
                <div key={idx}>
                  <div className={classes.fieldTitle}>{`${isRestaurant ? 'RESTAURANT' : 'SUPPLIER'} ${idx + 1}`}</div>
                  <div className={classes.fieldsLine}>
                    <div className={classes.nameWrap}>
                      <FastField component={FormikInput} name={`name-${idx}`} label='Contact Name' placeholder='Contact Name' />
                    </div>
                    <div className={classes.emailWrap}>
                      <FastField component={FormikInput} name={`email-${idx}`} label='Email Address *' placeholder='hello@email.com' />
                    </div>
                  </div>
                </div>
              ))}
              <div onClick={onAddRow.bind(null, values)} className={classes.addAnother}>
                <div className={classes.plus}>+</div>
                <div className={classes.addText}>ADD ANOTHER</div>
              </div>
              <ThemedButton onClick={submitForm} title='Refer' width={280} customClass={classes.submitBtn} disabled={isLoading} />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
