import * as React from 'react';

function SvgEditPen(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.08 3.285a.58.58 0 010 .822l-1.067 1.068-2.187-2.188L9.893 1.92a.58.58 0 01.823 0l1.365 1.365zM1.75 11.959v-1.774c0-.081.03-.151.087-.21l6.365-6.364L10.389 5.8l-6.37 6.364a.274.274 0 01-.204.088H2.042a.289.289 0 01-.292-.292z'
        fill='#33A8E2'
      />
    </svg>
  );
}

export default SvgEditPen;
