import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { ChevronRight } from '@material-ui/icons';
import clsx from 'clsx';

import SvgPointer from '../../../../assets/icons/Pointer';
import { BackButton } from '../../../../shared/components/back-button';
import { NavbarTitle } from '../../../../shared/components/navbar-title';
import { SearchField } from '../../../../shared/components/search-field';
import { helpCenterGeneral } from '../../../../shared/constants/helpCenterGeneral';
import { helpCenterRestaurant } from '../../../../shared/constants/helpCenterRestaurant';
import { helpCenterSupplier } from '../../../../shared/constants/helpCenterSupplier';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { getIsRestaurant, setHelpCenterKeyword } from '../../../../store/user';
import { getHelpInfo, getHelpKeywords } from '../../../../store/user/user.selectors';
import { TopNavBar } from '../../../AppDrawer/MainNavBar/top-nav-bar';
import { HelpInfoSearchResults } from '../help-info-search-results';

import { useStyles } from './style';

export const HelpCenter: React.FC = () => {
  const classes = useStyles();
  const { goBack } = useHistory();
  const dispatch = useAppDispatch();

  const isRestaurant = useAppSelector(getIsRestaurant);
  const { helpCenter } = useAppSelector(getHelpKeywords);

  const onKeywordChange = (value: string) => {
    dispatch(setHelpCenterKeyword(value));
  };

  const searchList = useAppSelector(getHelpInfo('help'));

  return (
    <>
      <TopNavBar isAccount={true} leftComponent={<NavbarTitle title='Help' showBackBtn={true} />} />
      <div className={classes.root}>
        <BackButton onClickHandler={goBack} />
        <div className={classes.mobileHead}>
          <NavbarTitle title='' showBackBtn={true} />
          <div className={classes.mobileTitle}>
            Help Centre. <span>Full Guide</span>
          </div>
        </div>
        <div className={classes.title}>
          Help Centre. <span>Full Guide</span>
        </div>
        <SvgPointer className={classes.icon} width={32} height={32} />
        <div className={classes.searchWrap}>
          <SearchField placeholder={'Search help articles...'} onChange={onKeywordChange} searchValue={helpCenter} clearIcon={true} />
        </div>
        {helpCenter ? (
          <HelpInfoSearchResults list={searchList} request={helpCenter} />
        ) : (
          <>
            {isRestaurant ? (
              <div className={classes.tilesBox}>
                <div className={classes.tileRow}>
                  <div className={classes.rowTitle}>Ordering</div>
                  <Link to={`/account/help/help-center/${helpCenterRestaurant[21].id}`} className={clsx(classes.rowItem)}>
                    <h2 className={classes.tileTitle}>{helpCenterRestaurant[21].title}</h2>
                    <ChevronRight />
                  </Link>
                  <Link to={`/account/help/help-center/${helpCenterRestaurant[0].id}`} className={clsx(classes.rowItem)}>
                    <h2 className={classes.tileTitle}>{helpCenterRestaurant[0].title}</h2>
                    <ChevronRight />
                  </Link>
                  <Link to={`/account/help/help-center/${helpCenterRestaurant[1].id}`} className={clsx(classes.rowItem)}>
                    <h2 className={classes.tileTitle}>{helpCenterRestaurant[1].title}</h2>
                    <ChevronRight />
                  </Link>
                  <Link to={`/account/help/help-center/${helpCenterRestaurant[10].id}`} className={clsx(classes.rowItem)}>
                    <h2 className={classes.tileTitle}>{helpCenterRestaurant[10].title}</h2>
                    <ChevronRight />
                  </Link>
                  <Link to={`/account/help/help-center/${helpCenterRestaurant[11].id}`} className={clsx(classes.rowItem)}>
                    <h2 className={classes.tileTitle}>{helpCenterRestaurant[11].title}</h2>
                    <ChevronRight />
                  </Link>
                </div>
                <div className={classes.tileRow}>
                  <div className={classes.rowTitle}>How to set up menu costing</div>
                  <Link to={`/account/help/help-center/${helpCenterRestaurant[22].id}`} className={clsx(classes.rowItem)}>
                    <h2 className={classes.tileTitle}>{helpCenterRestaurant[22].title}</h2>
                    <ChevronRight />
                  </Link>
                  <Link to={`/account/help/help-center/${helpCenterRestaurant[23].id}`} className={clsx(classes.rowItem)}>
                    <h2 className={classes.tileTitle}>{helpCenterRestaurant[23].title}</h2>
                    <ChevronRight />
                  </Link>
                  <Link to={`/account/help/help-center/${helpCenterRestaurant[24].id}`} className={clsx(classes.rowItem)}>
                    <h2 className={classes.tileTitle}>{helpCenterRestaurant[24].title}</h2>
                    <ChevronRight />
                  </Link>
                  <Link to={`/account/help/help-center/${helpCenterRestaurant[25].id}`} className={clsx(classes.rowItem)}>
                    <h2 className={classes.tileTitle}>{helpCenterRestaurant[25].title}</h2>
                    <ChevronRight />
                  </Link>
                  <Link to={`/account/help/help-center/${helpCenterRestaurant[26].id}`} className={clsx(classes.rowItem)}>
                    <h2 className={classes.tileTitle}>{helpCenterRestaurant[26].title}</h2>
                    <ChevronRight />
                  </Link>
                  <Link to={`/account/help/help-center/${helpCenterRestaurant[27].id}`} className={clsx(classes.rowItem)}>
                    <h2 className={classes.tileTitle}>{helpCenterRestaurant[27].title}</h2>
                    <ChevronRight />
                  </Link>
                  <Link to={`/account/help/help-center/${helpCenterRestaurant[28].id}`} className={clsx(classes.rowItem)}>
                    <h2 className={classes.tileTitle}>{helpCenterRestaurant[28].title}</h2>
                    <ChevronRight />
                  </Link>
                  <Link to={`/account/help/help-center/${helpCenterRestaurant[29].id}`} className={clsx(classes.rowItem)}>
                    <h2 className={classes.tileTitle}>{helpCenterRestaurant[29].title}</h2>
                    <ChevronRight />
                  </Link>
                  <Link to={`/account/help/help-center/${helpCenterRestaurant[30].id}`} className={clsx(classes.rowItem)}>
                    <h2 className={classes.tileTitle}>{helpCenterRestaurant[30].title}</h2>
                    <ChevronRight />
                  </Link>
                </div>
                <div className={classes.tileRow}>
                  <div className={classes.rowTitle}>Setting up Restaurant Pro Suite</div>
                  <Link to={`/account/help/help-center/${helpCenterRestaurant[31].id}`} className={clsx(classes.rowItem)}>
                    <h2 className={classes.tileTitle}>{helpCenterRestaurant[32].title}</h2>
                    <ChevronRight />
                  </Link>
                  <Link to={`/account/help/help-center/${helpCenterRestaurant[33].id}`} className={clsx(classes.rowItem)}>
                    <h2 className={classes.tileTitle}>{helpCenterRestaurant[33].title}</h2>
                    <ChevronRight />
                  </Link>
                  <Link to={`/account/help/help-center/${helpCenterRestaurant[34].id}`} className={clsx(classes.rowItem)}>
                    <h2 className={classes.tileTitle}>{helpCenterRestaurant[34].title}</h2>
                    <ChevronRight />
                  </Link>
                  <Link to={`/account/help/help-center/${helpCenterRestaurant[35].id}`} className={clsx(classes.rowItem)}>
                    <h2 className={classes.tileTitle}>{helpCenterRestaurant[35].title}</h2>
                    <ChevronRight />
                  </Link>
                  <Link to={`/account/help/help-center/${helpCenterRestaurant[36].id}`} className={clsx(classes.rowItem)}>
                    <h2 className={classes.tileTitle}>{helpCenterRestaurant[36].title}</h2>
                    <ChevronRight />
                  </Link>
                </div>
                <div className={classes.tilesWrap}>
                  <div className={clsx(classes.tileRow, classes.tileRowBlock)}>
                    <div className={classes.rowTitle}>Recurring OrderS</div>
                    <Link
                      to={`/account/help/help-center/${helpCenterRestaurant[5].id}`}
                      className={clsx(clsx(classes.rowItem, classes.rowItemFullWidth))}
                    >
                      <h2 className={classes.tileTitle}>{helpCenterRestaurant[5].title}</h2>
                      <ChevronRight />
                    </Link>
                    <Link
                      to={`/account/help/help-center/${helpCenterRestaurant[18].id}`}
                      className={clsx(clsx(classes.rowItem, classes.rowItemFullWidth))}
                    >
                      <h2 className={classes.tileTitle}>{helpCenterRestaurant[18].title}</h2>
                      <ChevronRight />
                    </Link>
                    <Link
                      to={`/account/help/help-center/${helpCenterRestaurant[6].id}`}
                      className={clsx(clsx(classes.rowItem, classes.rowItemFullWidth))}
                    >
                      <h2 className={classes.tileTitle}>{helpCenterRestaurant[6].title}</h2>
                      <ChevronRight />
                    </Link>
                  </div>
                  <div className={clsx(classes.tileRow, classes.tileRowBlock)}>
                    <div className={classes.rowTitle}>Pantry listS</div>
                    <Link
                      to={`/account/help/help-center/${helpCenterRestaurant[4].id}`}
                      className={clsx(clsx(classes.rowItem, classes.rowItemFullWidth))}
                    >
                      <h2 className={classes.tileTitle}>{helpCenterRestaurant[4].title}</h2>
                      <ChevronRight />
                    </Link>
                    <Link
                      to={`/account/help/help-center/${helpCenterRestaurant[2].id}`}
                      className={clsx(clsx(classes.rowItem, classes.rowItemFullWidth))}
                    >
                      <h2 className={classes.tileTitle}>{helpCenterRestaurant[2].title}</h2>
                      <ChevronRight />
                    </Link>
                    <Link
                      to={`/account/help/help-center/${helpCenterRestaurant[3].id}`}
                      className={clsx(clsx(classes.rowItem, classes.rowItemFullWidth))}
                    >
                      <h2 className={classes.tileTitle}>{helpCenterRestaurant[3].title}</h2>
                      <ChevronRight />
                    </Link>
                  </div>
                </div>
                <div className={classes.tileRow}>
                  <div className={classes.rowTitle}>SUPPLIER LIST & categories</div>
                  <Link to={`/account/help/help-center/${helpCenterRestaurant[7].id}`} className={clsx(classes.rowItem)}>
                    <h2 className={classes.tileTitle}>{helpCenterRestaurant[7].title}</h2>
                    <ChevronRight />
                  </Link>
                  <Link to={`/account/help/help-center/${helpCenterRestaurant[8].id}`} className={clsx(classes.rowItem)}>
                    <h2 className={classes.tileTitle}>{helpCenterRestaurant[8].title}</h2>
                    <ChevronRight />
                  </Link>
                  <Link to={`/account/help/help-center/${helpCenterRestaurant[9].id}`} className={clsx(classes.rowItem)}>
                    <h2 className={classes.tileTitle}>{helpCenterRestaurant[9].title}</h2>
                    <ChevronRight />
                  </Link>
                  <Link to={`/account/help/help-center/${helpCenterRestaurant[37].id}`} className={clsx(classes.rowItem)}>
                    <h2 className={classes.tileTitle}>{helpCenterRestaurant[37].title}</h2>
                    <ChevronRight />
                  </Link>
                </div>
                <div className={classes.tileRow}>
                  <div className={classes.rowTitle}>Account / profile settings</div>
                  <Link to={`/account/help/help-center/${helpCenterRestaurant[12].id}`} className={clsx(classes.rowItem)}>
                    <h2 className={classes.tileTitle}>{helpCenterRestaurant[12].title}</h2>
                    <ChevronRight />
                  </Link>
                  <Link to={`/account/help/help-center/${helpCenterRestaurant[15].id}`} className={clsx(classes.rowItem)}>
                    <h2 className={classes.tileTitle}>{helpCenterRestaurant[15].title}</h2>
                    <ChevronRight />
                  </Link>
                  <Link to={`/account/help/help-center/${helpCenterRestaurant[13].id}`} className={clsx(classes.rowItem)}>
                    <h2 className={classes.tileTitle}>{helpCenterRestaurant[13].title}</h2>
                    <ChevronRight />
                  </Link>
                  <Link to={`/account/help/help-center/${helpCenterRestaurant[19].id}`} className={clsx(classes.rowItem)}>
                    <h2 className={classes.tileTitle}>{helpCenterRestaurant[19].title}</h2>
                    <ChevronRight />
                  </Link>
                  <Link to={`/account/help/help-center/${helpCenterRestaurant[14].id}`} className={clsx(classes.rowItem)}>
                    <h2 className={classes.tileTitle}>{helpCenterRestaurant[14].title}</h2>
                    <ChevronRight />
                  </Link>
                  <Link to={`/account/help/help-center/${helpCenterRestaurant[20].id}`} className={clsx(classes.rowItem)}>
                    <h2 className={classes.tileTitle}>{helpCenterRestaurant[20].title}</h2>
                    <ChevronRight />
                  </Link>
                </div>
                <div className={classes.tileRow}>
                  <div className={classes.rowTitle}>Help</div>
                  <Link to={`/account/help/help-center/${helpCenterRestaurant[17].id}`} className={clsx(classes.rowItem)}>
                    <h2 className={classes.tileTitle}>{helpCenterRestaurant[17].title}</h2>
                    <ChevronRight />
                  </Link>
                  <Link to={`/account/help/help-center/${helpCenterRestaurant[16].id}`} className={clsx(classes.rowItem)}>
                    <h2 className={classes.tileTitle}>{helpCenterRestaurant[16].title}</h2>
                    <ChevronRight />
                  </Link>
                </div>
                <div className={classes.tileRow}>
                  <div className={clsx(classes.tileRow, classes.tileRowBlock)}>
                    <div className={classes.rowTitle}>General</div>
                    <Link
                      to={`/account/help/help-center/${helpCenterGeneral[0].id}`}
                      className={clsx(clsx(classes.rowItem, classes.rowItemFullWidth))}
                    >
                      <h2 className={classes.tileTitle}>{helpCenterGeneral[0].title}</h2>
                      <ChevronRight />
                    </Link>
                    <Link
                      to={`/account/help/help-center/${helpCenterGeneral[1].id}`}
                      className={clsx(clsx(classes.rowItem, classes.rowItemFullWidth))}
                    >
                      <h2 className={classes.tileTitle}>{helpCenterGeneral[1].title}</h2>
                      <ChevronRight />
                    </Link>
                  </div>
                  <div className={clsx(classes.tileRow, classes.tileRowBlock)}>
                    <div className={classes.rowTitle}>Password</div>
                    <Link
                      to={`/account/help/help-center/${helpCenterGeneral[2].id}`}
                      className={clsx(clsx(classes.rowItem, classes.rowItemFullWidth))}
                    >
                      <h2 className={classes.tileTitle}>{helpCenterGeneral[2].title}</h2>
                      <ChevronRight />
                    </Link>
                    <Link
                      to={`/account/help/help-center/${helpCenterGeneral[3].id}`}
                      className={clsx(clsx(classes.rowItem, classes.rowItemFullWidth))}
                    >
                      <h2 className={classes.tileTitle}>{helpCenterGeneral[3].title}</h2>
                      <ChevronRight />
                    </Link>
                  </div>
                </div>
              </div>
            ) : (
              <div className={classes.tilesBox}>
                <div className={classes.tilesWrap}>
                  <div className={clsx(classes.tileRow, classes.tileRowBlock)}>
                    <div className={classes.rowTitle}>Customers</div>
                    <Link
                      to={`/account/help/help-center/${helpCenterSupplier[1].id}`}
                      className={clsx(clsx(classes.rowItem, classes.rowItemFullWidth))}
                    >
                      <h2 className={classes.tileTitle}>{helpCenterSupplier[1].title}</h2>
                      <ChevronRight />
                    </Link>
                    <Link
                      to={`/account/help/help-center/${helpCenterSupplier[12].id}`}
                      className={clsx(clsx(classes.rowItem, classes.rowItemFullWidth))}
                    >
                      <h2 className={classes.tileTitle}>{helpCenterSupplier[12].title}</h2>
                      <ChevronRight />
                    </Link>
                    <Link
                      to={`/account/help/help-center/${helpCenterSupplier[13].id}`}
                      className={clsx(clsx(classes.rowItem, classes.rowItemFullWidth))}
                    >
                      <h2 className={classes.tileTitle}>{helpCenterSupplier[13].title}</h2>
                      <ChevronRight />
                    </Link>
                    <Link
                      to={`/account/help/help-center/${helpCenterSupplier[14].id}`}
                      className={clsx(clsx(classes.rowItem, classes.rowItemFullWidth))}
                    >
                      <h2 className={classes.tileTitle}>{helpCenterSupplier[14].title}</h2>
                      <ChevronRight />
                    </Link>
                  </div>
                  <div className={clsx(classes.tileRow, classes.tileRowBlock)}>
                    <div className={classes.rowTitle}>Products</div>
                    <Link
                      to={`/account/help/help-center/${helpCenterSupplier[17].id}`}
                      className={clsx(clsx(classes.rowItem, classes.rowItemFullWidth))}
                    >
                      <h2 className={classes.tileTitle}>{helpCenterSupplier[17].title}</h2>
                      <ChevronRight />
                    </Link>
                    <Link
                      to={`/account/help/help-center/${helpCenterSupplier[15].id}`}
                      className={clsx(clsx(classes.rowItem, classes.rowItemFullWidth))}
                    >
                      <h2 className={classes.tileTitle}>{helpCenterSupplier[15].title}</h2>
                      <ChevronRight />
                    </Link>
                    <Link
                      to={`/account/help/help-center/${helpCenterSupplier[2].id}`}
                      className={clsx(clsx(classes.rowItem, classes.rowItemFullWidth))}
                    >
                      <h2 className={classes.tileTitle}>{helpCenterSupplier[2].title}</h2>
                      <ChevronRight />
                    </Link>
                    <Link
                      to={`/account/help/help-center/${helpCenterSupplier[16].id}`}
                      className={clsx(clsx(classes.rowItem, classes.rowItemFullWidth))}
                    >
                      <h2 className={classes.tileTitle}>{helpCenterSupplier[16].title}</h2>
                      <ChevronRight />
                    </Link>
                  </div>
                </div>
                <div className={classes.tilesWrap}>
                  <div className={clsx(classes.tileRow, classes.tileRowBlock)}>
                    <div className={classes.rowTitle}>Price Lists</div>
                    <Link
                      to={`/account/help/help-center/${helpCenterSupplier[18].id}`}
                      className={clsx(clsx(classes.rowItem, classes.rowItemFullWidth))}
                    >
                      <h2 className={classes.tileTitle}>{helpCenterSupplier[18].title}</h2>
                      <ChevronRight />
                    </Link>
                    <Link
                      to={`/account/help/help-center/${helpCenterSupplier[19].id}`}
                      className={clsx(clsx(classes.rowItem, classes.rowItemFullWidth))}
                    >
                      <h2 className={classes.tileTitle}>{helpCenterSupplier[19].title}</h2>
                      <ChevronRight />
                    </Link>
                  </div>
                  <div className={clsx(classes.tileRow, classes.tileRowBlock)}>
                    <div className={classes.rowTitle}>Price Exceptions</div>
                    <Link
                      to={`/account/help/help-center/${helpCenterSupplier[3].id}`}
                      className={clsx(clsx(classes.rowItem, classes.rowItemFullWidth))}
                    >
                      <h2 className={classes.tileTitle}>{helpCenterSupplier[3].title}</h2>
                      <ChevronRight />
                    </Link>
                    <Link
                      to={`/account/help/help-center/${helpCenterSupplier[4].id}`}
                      className={clsx(clsx(classes.rowItem, classes.rowItemFullWidth))}
                    >
                      <h2 className={classes.tileTitle}>{helpCenterSupplier[4].title}</h2>
                      <ChevronRight />
                    </Link>
                  </div>
                </div>
                <div className={classes.tileRow}>
                  <div className={classes.rowTitle}>Orders</div>
                  <Link to={`/account/help/help-center/${helpCenterSupplier[5].id}`} className={clsx(classes.rowItem)}>
                    <h2 className={classes.tileTitle}>{helpCenterSupplier[5].title}</h2>
                    <ChevronRight />
                  </Link>
                  <Link to={`/account/help/help-center/${helpCenterSupplier[20].id}`} className={clsx(classes.rowItem)}>
                    <h2 className={classes.tileTitle}>{helpCenterSupplier[20].title}</h2>
                    <ChevronRight />
                  </Link>
                </div>
                <div className={classes.tileRow}>
                  <div className={classes.rowTitle}>Dashboard & Tools</div>
                  <Link to={`/account/help/help-center/${helpCenterSupplier[0].id}`} className={clsx(classes.rowItem)}>
                    <h2 className={classes.tileTitle}>{helpCenterSupplier[0].title}</h2>
                    <ChevronRight />
                  </Link>
                  <Link to={`/account/help/help-center/${helpCenterSupplier[6].id}`} className={clsx(classes.rowItem)}>
                    <h2 className={classes.tileTitle}>{helpCenterSupplier[6].title}</h2>
                    <ChevronRight />
                  </Link>
                  <Link to={`/account/help/help-center/${helpCenterSupplier[7].id}`} className={clsx(classes.rowItem)}>
                    <h2 className={classes.tileTitle}>{helpCenterSupplier[7].title}</h2>
                    <ChevronRight />
                  </Link>
                </div>

                <div className={classes.tileRow}>
                  <div className={clsx(classes.tileRow, classes.tileRowBlock)}>
                    <div className={classes.rowTitle}>Account / Profile settings</div>
                    <Link
                      to={`/account/help/help-center/${helpCenterSupplier[8].id}`}
                      className={clsx(clsx(classes.rowItem, classes.rowItemFullWidth))}
                    >
                      <h2 className={classes.tileTitle}>{helpCenterSupplier[8].title}</h2>
                      <ChevronRight />
                    </Link>
                    <Link
                      to={`/account/help/help-center/${helpCenterSupplier[9].id}`}
                      className={clsx(clsx(classes.rowItem, classes.rowItemFullWidth))}
                    >
                      <h2 className={classes.tileTitle}>{helpCenterSupplier[9].title}</h2>
                      <ChevronRight />
                    </Link>
                    <Link
                      to={`/account/help/help-center/${helpCenterSupplier[21].id}`}
                      className={clsx(clsx(classes.rowItem, classes.rowItemFullWidth))}
                    >
                      <h2 className={classes.tileTitle}>{helpCenterSupplier[21].title}</h2>
                      <ChevronRight />
                    </Link>
                  </div>
                  <div className={clsx(classes.tileRow, classes.tileRowBlock)}>
                    <div className={classes.rowTitle}>Help</div>
                    <Link
                      to={`/account/help/help-center/${helpCenterSupplier[10].id}`}
                      className={clsx(clsx(classes.rowItem, classes.rowItemFullWidth))}
                    >
                      <h2 className={classes.tileTitle}>{helpCenterSupplier[10].title}</h2>
                      <ChevronRight />
                    </Link>
                    <Link
                      to={`/account/help/help-center/${helpCenterSupplier[11].id}`}
                      className={clsx(clsx(classes.rowItem, classes.rowItemFullWidth))}
                    >
                      <h2 className={classes.tileTitle}>{helpCenterSupplier[11].title}</h2>
                      <ChevronRight />
                    </Link>
                  </div>
                </div>

                <div className={classes.tileRow}>
                  <div className={clsx(classes.tileRow, classes.tileRowBlock)}>
                    <div className={classes.rowTitle}>General</div>
                    <Link
                      to={`/account/help/help-center/${helpCenterGeneral[0].id}`}
                      className={clsx(clsx(classes.rowItem, classes.rowItemFullWidth))}
                    >
                      <h2 className={classes.tileTitle}>{helpCenterGeneral[0].title}</h2>
                      <ChevronRight />
                    </Link>
                    <Link
                      to={`/account/help/help-center/${helpCenterGeneral[1].id}`}
                      className={clsx(clsx(classes.rowItem, classes.rowItemFullWidth))}
                    >
                      <h2 className={classes.tileTitle}>{helpCenterGeneral[1].title}</h2>
                      <ChevronRight />
                    </Link>
                  </div>
                  <div className={clsx(classes.tileRow, classes.tileRowBlock)}>
                    <div className={classes.rowTitle}>Password</div>
                    <Link
                      to={`/account/help/help-center/${helpCenterGeneral[2].id}`}
                      className={clsx(clsx(classes.rowItem, classes.rowItemFullWidth))}
                    >
                      <h2 className={classes.tileTitle}>{helpCenterGeneral[2].title}</h2>
                      <ChevronRight />
                    </Link>
                    <Link
                      to={`/account/help/help-center/${helpCenterGeneral[3].id}`}
                      className={clsx(clsx(classes.rowItem, classes.rowItemFullWidth))}
                    >
                      <h2 className={classes.tileTitle}>{helpCenterGeneral[3].title}</h2>
                      <ChevronRight />
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
