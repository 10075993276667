import uniqBy from 'lodash/uniqBy';
import { RootState } from '../index';

export const getRecipeKeyword = (state: RootState) => state.menu.keyword;

export const getIsEditRecipeIngredientsMode = (state: RootState) => state.menu.editIngredientsMode;

export const getEditedRecipeId = (state: RootState) => state.menu.editedRecipeId;

export const getRecipeRecipeBatchSize = (state: RootState) => state.menu.batchSize;

export const getNewIngredientsIds = (state: RootState) => uniqBy(state.menu.newIngredientsIds, (item) => `${item.id}-${item.type}`);

export const getSelectedRecipeTagsIds = (state: RootState) => state.menu.selectedTagsIds;

export const getIsIngredientsQtyRounded = (state: RootState) => state.menu.quantityRounded;

export const getIsMenuCostingVideoModalShown = (state: RootState) => state.menu.videoModalShown;

export const getIsRpsVideoModalShown = (state: RootState) => state.menu.rpsVideoModalShown;

export const getNestedQtyRoundStatus = (state: RootState) => state.menu.nestedQuantityRoundStatus;

export const getSelectedSubRecipeTagsIds = (state: RootState) => state.menu.selectedSubRecTagsIds;

export const getSelectedArchivedRecipeTagsIds = (state: RootState) => state.menu.selectedArchivedRecTagsIds;

export const getSearchRecipeByTagState = (state: RootState) => state.menu.searchByTagState;

export const getIsSelectAlternativeMode = (state: RootState) => state.menu.selectAlternativeModeFor;

export const getReplaceModeData = (state: RootState) => state.menu.replaceModeData;

export const getIsSubRecipesExpanded = (state: RootState) => state.menu.subRecipesExpanded;

export const getExpandedIngredient = (state: RootState) => state.menu.expandedIngredient;
