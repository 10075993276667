import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../styles/colorVariables';
import { Sizes } from '../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: 600,
      [theme.breakpoints.down(Sizes.tablet)]: {
        width: 500,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        maxWidth: '100%',
      },
    },
    productsBox: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      marginBottom: 20,
      [theme.breakpoints.down(Sizes.mobile)]: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginBottom: 0,
      },
    },
    productItem: {
      display: 'flex',
      alignItems: 'center',
      width: 276,
      height: 64,
      backgroundColor: colorVariables.white,
      boxShadow: '1px 1px 15px rgba(12, 82, 112, 0.08)',
      border: `1px solid ${colorVariables.transparent}`,
      borderRadius: 10,
      padding: '10px 20px',
      fontSize: 16,
      marginBottom: 20,
      cursor: 'pointer',
      transition: 'all ease-in-out 0.5s',
      '&:hover': {
        boxShadow: '1px 1px 15px 5px rgba(12, 82, 112, 0.08)',
        borderColor: colorVariables.steelGrey,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        width: 240,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '90%',
        maxWidth: 360,
        marginBottom: 10,
        padding: 10,
        fontSize: 14,
        marginRight: 10,
        marginLeft: 15,
      },
    },
    productItemSelected: {
      border: `1px solid ${colorVariables.green}`,
      backgroundColor: colorVariables.greenLight,
      '& svg': {
        color: colorVariables.green,
      },
      '&:hover': {
        boxShadow: '1px 1px 15px 5px rgba(12, 82, 112, 0.08)',
      },
    },
    checkIcon: {
      marginLeft: 'auto',
      color: colorVariables.transparent,
    },
    subTitle: {
      fontSize: 16,
      marginBottom: 40,
      [theme.breakpoints.down(Sizes.mobile)]: {
        fontSize: 14,
        marginBottom: 20,
      },
    },
    productIcon: {
      marginRight: 10,
      display: 'flex',
      '&>svg': {
        width: 40,
        height: 40,
      },
    },
  }),
);
