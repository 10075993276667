import { createStyles, makeStyles } from '@material-ui/core';
import { colorVariables } from '../../../../../../../styles/colorVariables';
import { Sizes } from '../../../../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    row: {
      display: 'flex',
      width: '100%',
      padding: '18px 0',
      borderBottom: `1px solid ${colorVariables.steelGrey}`,
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: 10,
        display: 'block',
      },
    },
    rowEditMode: {
      backgroundColor: 'rgba(228, 252, 245, 0.5)',
    },
    sale: {
      width: '10%',
      paddingLeft: 20,
    },
    recipe: {
      width: '18%',
      paddingLeft: 5,
    },
    recipeName: {
      fontWeight: 600,
    },
    recipeId: {
      width: '13%',
    },
    menu: {
      width: '10%',
    },
    sold: {
      width: '9%',
      display: 'flex',
      justifyContent: 'center',
    },
    cost: {
      width: '9%',
      textAlign: 'center',
    },
    price: {
      width: '9%',
      display: 'flex',
      justifyContent: 'center',
    },
    tax: {
      width: '9%',
    },
    total: {
      width: '9%',
    },
    actions: {
      width: '4%',
      position: 'relative',
    },
    addActionBtn: {
      height: 28,
      fontSize: 14,
      padding: 0,
      margin: 0,
    },
    recipeIdText: {
      color: colorVariables.grey60,
    },
    soldText: {
      textAlign: 'center',
      fontSize: 16,
      fontWeight: 600,
    },
    sellPriceText: {
      textAlign: 'center',
      fontSize: 16,
      fontWeight: 600,
    },
    taxText: {
      textAlign: 'center',
    },
    totalText: {
      textAlign: 'center',
      fontWeight: 600,
    },
    moreDots: {
      transform: 'translate(10px, -8px)',
      '& svg': {
        color: colorVariables.grey40,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        transform: 'translate(5px, -2px)',
      },
    },
    saleDateText: {
      color: colorVariables.userNavy,
      marginBottom: 5,
    },
    addBtnCell: {
      paddingTop: 10,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    addBtnCellEnd: {
      justifyContent: 'flex-end',
    },
    dateTrigger: {
      width: 117,
      height: 26,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: 'pointer',
      paddingLeft: 5,
      '& svg': {
        color: colorVariables.grey60,
      },
    },
    dateWrap: {
      '&>div': {
        width: '98%',
        maxWidth: 100,
      },
    },
    cellInputWrap: {
      transform: 'translateY(-10px)',
      width: 72,
      '& input': {
        textAlign: 'center',
        fontWeight: 600,
        '&::placeholder': {
          color: `${colorVariables.steelGrey} !important`,
        },
      },
      '& .MuiInputBase-formControl.MuiInput-formControl': {
        borderColor: `${colorVariables.green} !important`,
        padding: 5,
      },
      '& .MuiInputBase-formControl.MuiInput-formControl:hover': {
        [theme.breakpoints.up(Sizes.desktop)]: {
          borderColor: `${colorVariables.blue} !important`,
        },
      },
      '& .MuiInputBase-formControl.MuiInput-formControl.Mui-focused': {
        borderColor: `${colorVariables.blue} !important`,
      },
    },
    cellInputWrapPrice: {
      width: 90,
    },
    rowHead: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    menuName: {
      width: '60%',
      paddingLeft: 10,
      color: colorVariables.grey60,
    },
    menuId: {
      width: '40%',
      paddingLeft: 10,
      textAlign: 'right',
      color: colorVariables.grey60,
    },
    rowHeadInfo: {
      flexGrow: 1,
      display: 'flex',
      marginTop: -5,
    },
    recipeNameM: {
      fontSize: 16,
      fontWeight: 600,
      marginBottom: 7,
    },
    rowTotalsM: {
      display: 'flex',
      justifyContent: 'space-between',
      '&>div': {
        width: '32%',
      },
    },
    rowTotalsMLabel: {
      fontSize: 12,
      fontWeight: 700,
      textTransform: 'uppercase',
      color: colorVariables.grey60,
      marginRight: 7,
    },
    boldText: {
      fontWeight: 700,
    },
  }),
);
