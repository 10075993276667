import { useAppSelector } from '../../store';
import { getDriversKeyword, getDriversSort } from '../../store/drivers';
import { useGetDriversQuery } from './index';

export const useGetDriversList = () => {
  const sort = useAppSelector(getDriversSort);
  const keyword = useAppSelector(getDriversKeyword);
  const { list, loading } = useGetDriversQuery(
    { keyword, sort },
    {
      selectFromResult: ({ data, isFetching }) => ({
        list: data?.drivers,
        loading: isFetching,
      }),
    },
  );
  return { list, loading };
};
