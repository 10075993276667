import * as React from 'react';

function SvgIngredientIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx={12} cy={12} r={11.5} fill='#fff' stroke='#CFD7E6' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.714 6.858c0 .314.257.571.571.571h.572l2.057 4.337-.772 1.395a1.145 1.145 0 001 1.697h6.286a.573.573 0 00.572-.572.573.573 0 00-.572-.571H9.142l.629-1.143h4.257c.429 0 .806-.234 1-.588l2.046-3.709a.57.57 0 00-.497-.846H8.12l-.383-.817a.567.567 0 00-.515-.326h-.937a.573.573 0 00-.571.572zm3.428 8.571c-.628 0-1.137.514-1.137 1.143s.509 1.143 1.137 1.143c.629 0 1.143-.514 1.143-1.143 0-.628-.514-1.143-1.143-1.143zm4.578 1.143c0-.628.508-1.143 1.137-1.143.628 0 1.143.514 1.143 1.143s-.515 1.143-1.143 1.143a1.141 1.141 0 01-1.137-1.143z'
        fill='#CFD7E6'
      />
      <mask
        id='IngredientIcon_svg__a'
        style={{
          maskType: 'luminance',
        }}
        maskUnits='userSpaceOnUse'
        x={5}
        y={6}
        width={13}
        height={12}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M5.714 6.858c0 .314.257.571.571.571h.572l2.057 4.337-.772 1.395a1.145 1.145 0 001 1.697h6.286a.573.573 0 00.572-.572.573.573 0 00-.572-.571H9.142l.629-1.143h4.257c.429 0 .806-.234 1-.588l2.046-3.709a.57.57 0 00-.497-.846H8.12l-.383-.817a.567.567 0 00-.515-.326h-.937a.573.573 0 00-.571.572zm3.428 8.571c-.628 0-1.137.514-1.137 1.143s.509 1.143 1.137 1.143c.629 0 1.143-.514 1.143-1.143 0-.628-.514-1.143-1.143-1.143zm4.578 1.143c0-.628.508-1.143 1.137-1.143.628 0 1.143.514 1.143 1.143s-.515 1.143-1.143 1.143a1.141 1.141 0 01-1.137-1.143z'
          fill='#fff'
        />
      </mask>
      <g mask='url(#IngredientIcon_svg__a)'>
        <path fill='#CFD7E6' d='M5.143 5.144h13.714v13.714H5.143z' />
      </g>
    </svg>
  );
}

export default SvgIngredientIcon;
