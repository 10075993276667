import Button, { ButtonProps } from '@material-ui/core/Button';
import React from 'react';
import { useStyles } from './style';

interface Props extends ButtonProps {
  title?: string;
}

export const BackArrowButton: React.FC<Props> = React.memo((btnProps) => {
  const classes = useStyles();
  return (
    <Button
      classes={{
        root: classes.root,
      }}
      {...btnProps}
    >
      <svg width='10' height='9' viewBox='0 0 10 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M9.49996 4.60021C9.49996 4.14102 9.17337 3.76909 8.77014 3.76909L2.6049 3.7691L4.21414 1.84922C4.51373 1.54212 4.53818 1.01685 4.26888 0.675679C3.99921 0.334506 3.53797 0.306664 3.23838 0.613346C3.21137 0.640772 3.18656 0.670693 3.16357 0.702275L0.368368 4.02674C0.0994302 4.34797 0.0994302 4.85246 0.368368 5.17368L3.16357 8.49815C3.4201 8.8522 3.88025 8.90249 4.19116 8.61035C4.50206 8.31821 4.54621 7.79419 4.28968 7.44014C4.26669 7.40855 4.24151 7.37863 4.21487 7.35121L2.6049 5.43133L8.77014 5.43133C9.17337 5.43133 9.49996 5.0594 9.49996 4.60021Z'
          fill='#5B6578'
        />
      </svg>
      {btnProps.title || 'Back'}
    </Button>
  );
});
