import React from 'react';
import { useHistory } from 'react-router-dom';

import notFound from '../../../assets/images/404.png';
import Logo from '../../../assets/images/logo/Logo_White.svg';
import { useScreenSize } from '../../hooks/use-screen-size';
import { ThemedButton } from '../themed-button';

import { useStyles } from './style';
import { useCurrentTheme } from '../../../api/admin-themes/hooks';

export const NotFoundPage: React.FC = () => {
  const classes = useStyles();
  const { push, goBack } = useHistory();
  const { logoUrl } = useCurrentTheme();

  const { isMobile } = useScreenSize();

  const returnHome = () => {
    push('/');
  };

  return (
    <div className={classes.root}>
      <img src={logoUrl || Logo} alt='OpenPantry' width={140} style={{ objectFit: 'contain', maxHeight: 70 }} />
      <h2 className={classes.title}>
        What a shame, we no longer have that.
        <br /> But we’re here to say…
      </h2>
      <img src={notFound} alt='OpenPantry' width={isMobile ? 300 : 338} />
      <div className={classes.btnBlock}>
        <ThemedButton onClick={goBack} title='Go back' buttonStyle='secondary' width={69} />
        <ThemedButton onClick={returnHome} title='Return to home' />
      </div>
    </div>
  );
};
