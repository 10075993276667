import * as React from 'react';

function SvgCategoryCoffee(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M7.43 22.892l.018.007H7.466l.015.007.018.007a.362.362 0 00.14.029h5.356c.021 0 .051 0 .088-.007a.496.496 0 00.123-.045c2.253-.87 4.114-2.685 5.332-5.012h1.969a2.997 2.997 0 002.993-3c0-1.657-1.34-3-2.993-3h-.416c.003-.093.01-.186.019-.282v-.002c.01-.129.022-.262.022-.396V9.207a.533.533 0 00-.148-.385.531.531 0 00-.384-.148H1.032a.531.531 0 00-.384.148.533.533 0 00-.148.385v1.991c0 5.397 2.78 10.117 6.93 11.694zM20.407 12.9v.043h.1a1.93 1.93 0 011.93 1.934 1.93 1.93 0 01-1.93 1.935h-1.492c.482-1.2.845-2.517.979-3.912h.413zm-1.889 2.077c-.917 3.245-2.989 5.84-5.63 6.9H7.744c-2.641-1.06-4.713-3.655-5.63-6.9h16.404zM1.564 9.74h17.504v1.458c0 .92-.081 1.839-.282 2.757H1.846a12.809 12.809 0 01-.282-2.757V9.74zM8.968 7.419a.522.522 0 00.38-.149.536.536 0 00.146-.384.536.536 0 00-.146-.383.522.522 0 00-.38-.15.624.624 0 01-.624-.634c0-.375.293-.636.624-.636.953 0 1.677-.783 1.677-1.7 0-.915-.765-1.743-1.677-1.743a.522.522 0 00-.38.149.536.536 0 00-.146.383c0 .152.05.287.146.384.096.097.23.149.38.149.366 0 .625.296.625.635 0 .335-.298.635-.625.635-.953 0-1.676.783-1.676 1.7 0 .914.72 1.744 1.676 1.744zM12.606 7.419a.522.522 0 00.38-.149.535.535 0 00.146-.384.535.535 0 00-.146-.383.522.522 0 00-.38-.15.624.624 0 01-.624-.634c0-.375.294-.636.624-.636.954 0 1.677-.783 1.677-1.7 0-.915-.764-1.743-1.677-1.743a.523.523 0 00-.38.149.536.536 0 00-.146.383c0 .152.05.287.146.384.096.097.23.149.38.149.366 0 .625.296.625.635 0 .335-.298.635-.625.635-.953 0-1.676.783-1.676 1.7 0 .914.72 1.744 1.676 1.744z'
        fill='#fff'
        stroke='#fff'
        strokeWidth={0.2}
      />
    </svg>
  );
}

export default SvgCategoryCoffee;
