import { setGtmDatalayer } from '../../shared/helpers/setGtmDatalayer';
import { ToastService } from '../../shared/services/toastService';
import { TokenService } from '../../shared/services/token.service';
import { setAppSuccessToast } from '../../store/user';
import { HTTP, rootApi, SuccessResponse } from '../index';
import { userApi } from '../user';
import {
  ConfirmEmailRequest,
  ConfirmEmailResponse,
  LoginRequest,
  LoginResponse,
  RegisterRequest,
  RegisterResponse,
  ResetPasswordEmailRequest,
  ResetPasswordRequest,
  ResetPasswordResponse,
} from './types';

export const authApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginRequest>({
      query(body) {
        return {
          url: `/login`,
          method: HTTP.POST,
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.auth_token) {
            TokenService.setToken(data.auth_token);
            dispatch(userApi.endpoints.me.initiate(undefined, { forceRefetch: true })).then((res) => {
              res.data &&
                setGtmDatalayer({
                  event: 'sign_in',
                  eventName: 'login_status',
                  eventCategory: 'sign_in',
                  userId: res.data.id,
                  userType: res.data?.company?.company_type || 'admin',
                });
            });
          }
        } catch (err) {
          console.log(err);
        }
      },
    }),

    signUp: builder.mutation<RegisterResponse, RegisterRequest>({
      query(body) {
        return {
          url: `/signup`,
          method: HTTP.POST,
          body,
        };
      },
    }),

    signUpMcFree: builder.mutation<SuccessResponse, { email: string; first_name?: string; last_name?: string }>({
      query(body) {
        return {
          url: `/mc_free`,
          method: HTTP.POST,
          body,
        };
      },
      invalidatesTags: ['Me'],
    }),

    setPassword: builder.mutation<LoginResponse, LoginRequest>({
      query(body) {
        return {
          url: `/set_password`,
          method: HTTP.POST,
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.auth_token) {
            TokenService.setToken(data.auth_token);
            dispatch(userApi.endpoints.me.initiate(undefined, { forceRefetch: true }));
          }
        } catch (err) {
          console.log(err);
        }
      },
    }),

    claimAccount: builder.mutation<LoginResponse, Omit<RegisterRequest, 'phone_number'>>({
      query(body) {
        return {
          url: `/claim_account`,
          method: HTTP.POST,
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.auth_token) {
            TokenService.setToken(data.auth_token);
            dispatch(userApi.endpoints.me.initiate(undefined, { forceRefetch: true }));
          }
        } catch (err) {
          console.log(err);
        }
      },
    }),

    resetPasswordEmail: builder.mutation<ResetPasswordResponse, ResetPasswordEmailRequest>({
      query(body) {
        return {
          url: `/send_password_reset_instruction`,
          method: HTTP.POST,
          body,
        };
      },
    }),

    resetPassword: builder.mutation<ResetPasswordResponse, ResetPasswordRequest>({
      query(body) {
        return {
          url: `/reset_password`,
          method: HTTP.POST,
          body,
        };
      },
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          const {
            data: { auth_token },
          } = await queryFulfilled;
          TokenService.setToken(auth_token);
          dispatch(userApi.endpoints.me.initiate(undefined, { forceRefetch: true }));
        } catch (err) {
          console.log(err);
        }
      },
    }),

    googleLogin: builder.mutation<LoginResponse, { id_token: string }>({
      query(body) {
        return {
          url: `/social_login`,
          method: HTTP.POST,
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.auth_token) {
            TokenService.setToken(data.auth_token);
            if (!TokenService.getUserId()) {
              ToastService.error('Email has been already taken');
            } else {
              dispatch(userApi.endpoints.me.initiate(undefined, { forceRefetch: true }));
            }
          }
        } catch (err) {
          console.log(err);
        }
      },
    }),

    confirm: builder.mutation<ConfirmEmailResponse, ConfirmEmailRequest>({
      query(args) {
        return {
          url: `/confirm`,
          method: HTTP.GET,
          params: {
            confirmation_token: args.token,
          },
        };
      },
      async onQueryStarted(args, { queryFulfilled, dispatch }) {
        try {
          const {
            data: { auth_token, success },
          } = await queryFulfilled;
          TokenService.setToken(auth_token);
          dispatch(userApi.endpoints.me.initiate(undefined, { forceRefetch: true }));
          success && args.changeEmail && dispatch(setAppSuccessToast('Your email has been changed'));
        } catch (err) {
          console.log(err);
        }
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useLoginMutation,
  useSignUpMutation,
  useConfirmMutation,
  useGoogleLoginMutation,
  useResetPasswordEmailMutation,
  useResetPasswordMutation,
  useClaimAccountMutation,
  useSetPasswordMutation,
  useSignUpMcFreeMutation,
} = authApi;
