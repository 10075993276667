import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';
import { Sizes } from '../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    accountContainer: {
      paddingTop: 63,
      backgroundColor: colorVariables.white,
      [theme.breakpoints.down(Sizes.desktop)]: {
        padding: '70px 0 0 94px',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: '150px 0 0 0',
      },
    },
    accountContainerProfileCompleted: {
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: '110px 0 0 0',
      },
    },
    accountHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      backgroundColor: colorVariables.white,
      height: 127,
      borderBottom: `1px solid ${colorVariables.steelGrey}`,
      paddingBottom: 30,
      paddingLeft: 55,
      paddingRight: 55,
      [theme.breakpoints.down(Sizes.desktop)]: {
        display: 'none',
      },
    },
    accountTitle: {
      fontSize: 32,
      lineHeight: '50px',
      fontWeight: 700,
    },
    accountBody: {
      display: 'flex',
    },
    accountContent: {
      width: '80%',
      display: 'flex',
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: '100%',
      },
    },
    root: {
      backgroundColor: 'rgba(152, 165, 183, 0.15)',
      minHeight: 'calc(100vh - 190px)',
      width: '100%',
      borderRadius: 20,
      padding: '60px 100px 100px 55px',
      [theme.breakpoints.down(Sizes.desktop)]: {
        minHeight: 'calc(100vh - 70px)',
        padding: '60px 40px',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: 20,
        paddingBottom: 80,
      },
    },
    titleBox: {
      display: 'flex',
      alignItems: 'flex-start',
      marginBottom: 15,
      [theme.breakpoints.up(Sizes.desktop)]: {
        '& button': {
          marginTop: 7,
        },
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        marginBottom: 55,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginBottom: 45,
      },
    },
    titleText: {
      marginRight: 15,
      fontSize: 30,
      fontWeight: 700,
      [theme.breakpoints.down(Sizes.desktop)]: {
        fontSize: 20,
        lineHeight: '24px',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        fontSize: 18,
        marginRight: 5,
      },
    },
    subTitleText: {
      fontSize: 12,
      lineHeight: '22px',
      fontWeight: 700,
      textTransform: 'uppercase',
      marginRight: 10,
    },
    subTitleWrap: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginBottom: 12,
      [theme.breakpoints.down(Sizes.desktop)]: {
        marginBottom: 0,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginBottom: 5,
      },
    },
    contentText: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '24px',
    },
    websiteText: {
      '& a': {
        fontSize: 14,
        fontWeight: 700,
        color: colorVariables.blue,
      },
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      marginBottom: 50,
      [theme.breakpoints.down(Sizes.desktop)]: {
        marginBottom: 30,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        justifyContent: 'flex-end',
        flexDirection: 'row-reverse',
        marginBottom: 20,
      },
    },
    topBox: {
      borderBottom: `1px solid ${colorVariables.grey20}`,
      paddingBottom: 30,
      [theme.breakpoints.down(Sizes.mobile)]: {
        paddingBottom: 10,
      },
    },
    contactsBlock: {
      borderBottom: `1px solid ${colorVariables.grey20}`,
    },
    personalBlock: {
      borderBottom: `1px solid ${colorVariables.grey20}`,
    },
    mainBoxRow: {
      display: 'flex',
      padding: '35px 0',
      [theme.breakpoints.down(Sizes.mobile)]: {
        flexWrap: 'wrap',
        padding: '25px 0',
      },
    },
    mainBoxRowTitle: {
      fontSize: 12,
      fontWeight: 700,
      color: colorVariables.grey60,
      textTransform: 'uppercase',
      width: '30%',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '70%',
      },
    },
    mainBoxRowText: {
      color: colorVariables.grey60,
      [theme.breakpoints.down(Sizes.tablet)]: {
        paddingLeft: 10,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        paddingLeft: 0,
        order: 1,
        marginTop: 12,
      },
    },
    categoriesText: {
      width: 400,
      color: colorVariables.green,
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 300,
      },
    },
    actionsText: {
      textTransform: 'uppercase',
      fontSize: 11,
      fontWeight: 700,
      marginRight: 5,
    },
    actionsTextExpanded: {
      color: colorVariables.blue,
    },
    actionsIcon: {
      fontSize: 16,
      color: colorVariables.grey60,
      transition: 'transform ease-in-out 0.5s',
    },
    actionsIconRotated: {
      transform: 'rotate(90deg)',
    },
    rowAction: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
    },
    contactsBlockContent: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: 35,
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'block',
        paddingBottom: 25,
      },
    },
    contactBlockItem: {
      width: '30%',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        marginBottom: 5,
      },
    },
    textWrap: {
      paddingRight: 70,
      [theme.breakpoints.down(Sizes.desktop)]: {
        paddingRight: 40,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        paddingRight: 30,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        paddingRight: 0,
      },
    },
    imgWrap: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      flexShrink: 0,
      width: 112,
      height: 112,
      borderRadius: 10,
      overflow: 'hidden',
      '& img': {
        width: '100%',
        height: '100%',
      },
      '&:hover': {
        backgroundColor: 'rgba(12, 82, 112, 0.08)',
        cursor: 'pointer',
        '& > div:first-child': {
          display: 'flex',
        },
      },
      boxShadow: '1px 1px 15px rgba(12, 82, 112, 0.08)',
      backgroundColor: colorVariables.white,
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: 88,
        height: 88,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        width: 80,
        height: 80,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 75,
        height: 75,
        marginRight: 20,
      },
    },
    description: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      [theme.breakpoints.down(Sizes.tablet)]: {
        width: 'calc(100% + 110px)',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginLeft: -80,
        width: 'calc(100% + 80px)',
      },
      '& button': {
        marginLeft: 10,
      },
    },
    mainInfoBox: {
      display: 'flex',
      justifyContent: 'flex-start',
      [theme.breakpoints.down(Sizes.tablet)]: {
        flexWrap: 'wrap',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        borderTop: `1px solid ${colorVariables.steelGrey}`,
        paddingTop: 20,
        width: '100%',
        display: 'block',
      },
    },
    orderInfoWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'block',
      },
    },
    businessInfoItem: {
      width: '33%',
      marginRight: 20,
      flexGrow: 0,
      overflow: 'hidden',
      [theme.breakpoints.down(Sizes.tablet)]: {
        width: '45%',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        marginBottom: 25,
      },
    },
    mainInfoItem: {
      width: '45%',
      marginRight: 20,
      flexGrow: 0,
      overflow: 'hidden',
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: '50%',
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        width: '100%',
        marginBottom: 0,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        marginBottom: 25,
      },
    },
    emptyItem: {
      marginBottom: 0,
    },
    deliveryBox: {
      paddingBottom: 40,
      display: 'flex',
      [theme.breakpoints.down(Sizes.tablet)]: {
        display: 'block',
      },
    },
    leftWrap: {
      borderRight: `2px solid ${colorVariables.steelGrey}`,
      width: '65%',
      [theme.breakpoints.down(Sizes.tablet)]: {
        width: '100%',
        borderRight: 0,
        marginBottom: 30,
      },
    },
    rightWrap: {
      width: '35%',
      paddingLeft: 40,
      [theme.breakpoints.down(Sizes.desktop)]: {
        paddingLeft: 20,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        width: '60%',
        paddingLeft: 0,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
    },
    weekDaysBox: {
      display: 'flex',
      paddingTop: 10,
      marginBottom: 30,
      [theme.breakpoints.down(Sizes.desktop)]: {
        marginBottom: 20,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        marginBottom: 35,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginBottom: 25,
      },
    },
    weekDayItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: 52,
      height: 84,
      borderRadius: 10,
      backgroundColor: colorVariables.white,
      boxShadow: '1px 1px 15px rgba(12, 82, 112, 0.08)',
      marginRight: 11,
      paddingTop: 15,
      textTransform: 'uppercase',
      border: '1px solid transparent',
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: 40,
        height: 72,
        fontSize: 11,
      },
      [theme.breakpoints.down(Sizes.desktop)]: {
        marginRight: 6,
        fontSize: 11,
      },
    },
    weekDayItemSelected: {
      border: `1px solid ${colorVariables.green}`,
      backgroundColor: colorVariables.greenLight,
      '& svg': {
        color: colorVariables.green,
      },
    },
    checkIcon: {
      fontSize: 22,
      marginBottom: 8,
      color: colorVariables.transparent,
      [theme.breakpoints.down(Sizes.desktop)]: {
        fontSize: 16,
      },
    },
    radiusBox: {
      fontSize: 14,
      fontWeight: 400,
      color: colorVariables.steelGrey,
      lineHeight: '48px',
      padding: '0 12px',
      height: 48,
      borderWidth: 1,
      borderRadius: 4,
      backgroundColor: colorVariables.white,
      borderColor: colorVariables.steelGrey,
      borderStyle: 'solid',
      marginTop: 20,
      maxWidth: 300,
      [theme.breakpoints.down(Sizes.tablet)]: {
        marginTop: 10,
      },
      '&:hover': {
        cursor: 'pointer',
        borderColor: colorVariables.blue,
      },
      '&:hover + div': {
        display: 'block',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        order: 2,
      },
    },
    radiusTitle: {
      [theme.breakpoints.down(Sizes.mobile)]: {
        order: 1,
        marginTop: 25,
      },
    },
    productsBox: {
      marginBottom: 40,
    },
    productsList: {
      display: 'flex',
      flexWrap: 'wrap',
      paddingTop: 10,
      [theme.breakpoints.down(Sizes.desktop)]: {
        justifyContent: 'space-between',
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        justifyContent: 'center',
      },
    },
    productItem: {
      display: 'flex',
      alignItems: 'center',
      minWidth: 257,
      width: '29%',
      height: 64,
      backgroundColor: colorVariables.white,
      marginRight: '5%',
      marginBottom: 28,
      boxShadow: '1px 1px 15px rgba(12, 82, 112, 0.08)',
      borderRadius: 10,
      padding: '10px 20px',
      fontSize: 16,
      border: '1px solid transparent',
      '&:nth-child(3n)': {
        marginRight: '0px !important',
      },
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: '45%',
        marginRight: 0,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        minWidth: 290,
        width: '55%',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        minWidth: 280,
        width: '100%',
        marginBottom: 20,
      },
    },
    productItemSelected: {
      border: `1px solid ${colorVariables.green}`,
      backgroundColor: colorVariables.greenLight,
      '& svg': {
        color: colorVariables.green,
      },
    },
    productIcon: {
      marginRight: 10,
      display: 'flex',
      '&>svg': {
        width: 40,
        height: 40,
      },
    },
    editText: {
      display: 'none',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'block',
        marginRight: 5,
        marginLeft: 'auto',
      },
    },
    contactLabel: {
      fontStyle: 'italic',
      color: colorVariables.grey60,
      marginLeft: 15,
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    addText: {
      display: 'inline-block',
      marginLeft: 15,
      marginTop: 20,
      marginBottom: 20,
      color: colorVariables.grey60,
      opacity: 0.7,
    },
    addDeliveryWrap: {
      marginBottom: 30,
    },
    addDescription: {
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginLeft: -95,
      },
    },
    uploadPhoto: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: 12,
      fontWeight: 700,
      '& svg': {
        width: 41,
        height: 41,
        marginBottom: 10,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        fontSize: 10,
        '& svg': {
          marginBottom: 5,
        },
      },
    },
    addPhotoBg: {
      display: 'none',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      position: 'absolute',
      backgroundColor: 'rgba(116,124,129,0.8)',
      '& img': {
        width: 25,
        height: 25,
      },
    },
    mobileProgress: {
      display: 'none',
      [theme.breakpoints.down(Sizes.mobile)]: {
        position: 'relative',
        height: 30,
        transform: 'translateY(-65px)',
        display: 'block',
      },
    },
    deliveryFeeBox: {
      marginTop: 40,
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginTop: 5,
      },
    },
    deliveryFeeAtBox: {
      marginTop: 40,
    },
    changePasswordText: {
      color: colorVariables.blue,
      cursor: 'pointer',
    },
    mobileInfoTitle: {
      display: 'none',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'inline-block',
        marginRight: 15,
        textTransform: 'uppercase',
        fontSize: 11,
        fontWeight: 700,
        color: colorVariables.grey60,
        width: 60,
      },
    },
    phoneBlock: {
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginTop: 20,
      },
    },
    advancedBoxWrap: {
      position: 'relative',
      opacity: 0.4,
    },
    advancedBoxOverlay: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      zIndex: 10,
    },
    lockIcon: {
      color: colorVariables.grey60,
      marginRight: 6,
    },
    upgradeBlock: {
      padding: '13px 20px',
      minHeight: 60,
      backgroundColor: colorVariables.blueLight,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderRadius: 6,
      marginBottom: 35,
      [theme.breakpoints.down(Sizes.mobile)]: {
        flexDirection: 'column',
        marginBottom: 25,
        borderRadius: 21,
      },
    },
    upgradeBoxContent: {
      marginRight: 'auto',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginBottom: 15,
      },
    },
    lockBox: {
      width: 34,
      height: 34,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      flexShrink: 0,
      backgroundColor: colorVariables.blue,
      marginRight: 20,
    },
  }),
);
