import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import clsx from 'clsx';
import { format } from 'date-fns';

import { rootApi } from '../../../../../api';
import { useGetCartDeliveryFeeQuery } from '../../../../../api/cart';
import { useGetCart } from '../../../../../api/cart/hooks';
import SvgCartIcon from '../../../../../assets/icons/CartIcon';
import CartSpinner from '../../../../../assets/icons/CartSpinner';
import { formatCurrency } from '../../../../../shared/helpers/format-currency';
import useInterval from '../../../../../shared/hooks/use-interval';
import { LocalStorageService } from '../../../../../shared/services/localStorage.service';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { getReplenishLoading } from '../../../../../store/inventory/inventory.selectors';
import { showPantryListExitWarning } from '../../../../../store/pantry-lists';
import { showRecOrderExitWarning } from '../../../../../store/recurring-order';
import { getCartDeliveryLoading } from '../../../../../store/user/user.selectors';
import { colorVariables } from '../../../../../styles/colorVariables';

import { useRippleStyles } from '../../../../../styles/customRipple';
import { useStyles } from './style';
import { useCurrentTheme } from '../../../../../api/admin-themes/hooks';
import { ThemeColors } from '../../../../../api/admin-themes/types';

export const Cart = () => {
  const { colors } = useCurrentTheme();
  const classes = useStyles({ textColor: colors[ThemeColors.navBarText], bgColor: colors[ThemeColors.navBarBg] });
  const rippleClasses = useRippleStyles();
  const { push } = useHistory();
  const {
    state,
    pathname,
  }: {
    state: { recOrderId?: number; pantryListId?: number } | null;
    pathname: string;
  } = useLocation();
  const dispatch = useAppDispatch();
  const isCartOpened = pathname.includes('/cart');

  const { total, totalCount, loading, cartInfo } = useGetCart();
  const { data: cartTotal } = useGetCartDeliveryFeeQuery(
    {
      date: format(new Date(Date.now()), 'yyyy-MM-dd'),
    },
    {
      skip: isCartOpened || !LocalStorageService.getItem('replenish_cart'),
    },
  );
  const cartDeliveryLoading = useAppSelector(getCartDeliveryLoading);
  const replenishLoading = useAppSelector(getReplenishLoading);

  useInterval(
    () => {
      if (
        cartInfo?.finalTotal !== 0 &&
        cartTotal?.final_total !== cartInfo?.finalTotal &&
        !loading &&
        !cartDeliveryLoading &&
        !cartDeliveryLoading
      ) {
        dispatch(rootApi.util.invalidateTags(['Cart_Delivery']));
      }
    },
    !!LocalStorageService.getItem('replenish_cart') && !isCartOpened ? 2000 : null,
  );

  const openCart = () => {
    if (!state?.pantryListId && !state?.recOrderId) {
      push('/cart');
    }
    if (state?.recOrderId) {
      dispatch(
        showRecOrderExitWarning({
          handler: () => {
            push('/cart');
          },
        }),
      );
    }
    if (state?.pantryListId) {
      dispatch(
        showPantryListExitWarning({
          handler: () => {
            push('/cart');
          },
        }),
      );
    }
  };

  return (
    <div className={clsx(classes.cart, rippleClasses.ripple, isCartOpened && classes.cartActive)} onClick={openCart}>
      {loading || cartDeliveryLoading || replenishLoading ? (
        <CartSpinner className={classes.rotation} />
      ) : (
        !!totalCount && <span className={classes.cartAmount}>{Math.round(totalCount)}</span>
      )}

      <SvgCartIcon width={30} height={25} color={colors[ThemeColors.navBarText] || colorVariables.steelGrey} />
      <div className={classes.cartTotal}>{formatCurrency(total)}</div>
    </div>
  );
};
