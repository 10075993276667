import * as React from 'react';

function SvgLogout(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <rect x={11} y={1} width={11} height={2} rx={1} transform='rotate(90 11 1)' fill='currentColor' />
      <path
        d='M19 10.817c0-3.064-1.49-5.915-3.985-7.626a1.087 1.087 0 00-1.527.305 1.137 1.137 0 00.3 1.558 6.973 6.973 0 013.012 5.763c0 3.826-3.05 6.938-6.8 6.938s-6.8-3.112-6.8-6.938A6.976 6.976 0 016.2 5.064a1.137 1.137 0 00.296-1.559 1.087 1.087 0 00-1.528-.302C2.483 4.916 1 7.762 1 10.817 1 15.881 5.037 20 10 20s9-4.12 9-9.183z'
        fill='currentColor'
      />
    </svg>
  );
}

export default SvgLogout;
