import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../styles/colorVariables';
import { Sizes } from '../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: 600,
      [theme.breakpoints.down(Sizes.tablet)]: {
        width: 500,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        maxWidth: '100%',
      },
    },
    uploadPhoto: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: 12,
      fontWeight: 700,
      '& svg': {
        width: 41,
        height: 41,
      },
    },
    imgWrap: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      flexShrink: 0,
      width: 112,
      height: 112,
      borderRadius: 10,
      marginRight: 30,
      overflow: 'hidden',
      '& img': {
        width: '100%',
        height: '100%',
      },
      '&:hover': {
        backgroundColor: 'rgba(12, 82, 112, 0.08)',
        cursor: 'pointer',
      },
      boxShadow: '1px 1px 15px rgba(12, 82, 112, 0.08)',
      backgroundColor: colorVariables.white,
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 70,
        height: 70,
        marginRight: 10,
      },
    },
    uploadBox: {
      width: '100%',
      minHeight: 160,
      marginTop: 20,
      display: 'flex',
      alignItems: 'center',
      marginBottom: 40,
    },
    uploadButton: {
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: 16,
      },
    },
    btnBlock: {
      paddingTop: 20,
      alignSelf: 'flex-start',
      display: 'flex',
      [theme.breakpoints.down(Sizes.mobile)]: {
        justifyContent: 'center',
      },
      '& button': {
        minWidth: 120,
        [theme.breakpoints.down(Sizes.mobile)]: {
          minWidth: 110,
        },
      },
    },
    saveBtn: {
      marginLeft: 15,
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginLeft: 7,
      },
    },
  }),
);
