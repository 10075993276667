import React, { ChangeEvent, useState } from 'react';

import { ClickAwayListener } from '@material-ui/core';
import MuiTextField from '@material-ui/core/TextField';
import { KeyboardArrowDown } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';

import { useStyles } from './style';

interface Props {
  options: string[];
  setValue: (value: string | null) => void;
}

export const PostCodeAutoComplete: React.FC<Props> = ({ options, setValue }) => {
  const classes = useStyles();

  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const onInputChange = (event: ChangeEvent<{}>, newInputValue: string) => {
    setInputValue(newInputValue);
    if (newInputValue.trim() && selectedValue !== newInputValue) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  const onSelect = (event: ChangeEvent<{}>, newValue: string | null) => {
    setSelectedValue(newValue);
    setValue(newValue);
    setIsOpen(false);
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <ClickAwayListener onClickAway={setIsOpen.bind(null, false)}>
      <div className={classes.box}>
        <div className={classes.label}>POST CODE</div>
        <Autocomplete
          open={isOpen}
          blurOnSelect={true}
          popupIcon={null}
          value={selectedValue}
          onChange={onSelect}
          inputValue={inputValue}
          onInputChange={onInputChange}
          classes={{
            root: classes.rootField,
            clearIndicator: classes.clearIndicator,
            paper: classes.paper,
            option: classes.option,
            noOptions: classes.noOptions,
          }}
          noOptionsText='Not found'
          options={options}
          renderInput={(params) => (
            <MuiTextField
              {...params}
              variant='outlined'
              InputProps={{
                ...params.InputProps,
                className: params.InputProps.className,
                endAdornment: !selectedValue ? (
                  <KeyboardArrowDown onClick={toggleOpen} className={clsx(classes.openBtn)} />
                ) : (
                  params.InputProps.endAdornment
                ),
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
      </div>
    </ClickAwayListener>
  );
};
