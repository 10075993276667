import React from 'react';

import { Close } from '@material-ui/icons';
import ReactFlagsSelect from 'react-flags-select';

import { countries } from '../../constants/countries';

import { useStyles } from './style';

interface Props {
  onSelect: (code: string) => void;
  selected: string;
  clearable?: boolean;
}

export const CountrySelect: React.FC<Props> = React.memo(({ onSelect, selected, clearable }) => {
  const classes = useStyles();

  const onClear = () => {
    onSelect('');
  };

  return (
    <div className={clearable ? classes.wrapClearable : classes.wrap} id={'coSelect'}>
      <ReactFlagsSelect
        className={clearable ? classes.countrySearchClearable : classes.countrySearch}
        selectButtonClassName={clearable ? classes.btnClearable : classes.btn}
        placeholder={clearable ? 'All countries' : 'Select Country'}
        selected={selected}
        onSelect={onSelect}
        countries={countries.map((el) => el.value)}
        searchable={true}
        searchPlaceholder='Start typing country..'
      />
      {selected && clearable && <Close className={classes.close} onClick={onClear} />}
    </div>
  );
});
