import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../../../styles/colorVariables';
import { Sizes } from '../../../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    cell: {
      padding: '12px 5px',
    },
    row: {
      display: 'flex',
      padding: '0 5px 0 10px',
      alignItems: 'center',
      transition: 'all ease-in-out 0.2s',
      borderBottom: `1px solid ${colorVariables.steelGrey}`,
      '&:hover': {
        backgroundColor: 'rgba(228, 252, 245, 0.5)',
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        padding: '0 5px 0 10px',
      },
    },
    rowHighlighted: {
      [theme.breakpoints.up(Sizes.desktop)]: {
        backgroundColor: 'rgba(228, 252, 245, 0.5)',
      },
    },
    suppliersList: {
      display: 'flex',
      overflow: 'hidden',
      alignItems: 'flex-end',
      '& span': {
        transform: 'translate(-9px, -1px)',
        fontSize: 16,
      },
    },
    supplierLogo: {
      width: 39,
      height: 39,
      borderRadius: '50%',
      overflow: 'hidden',
      marginRight: 12,
      flexShrink: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colorVariables.white,
      border: `1px solid ${colorVariables.steelGrey}`,
      '& img': {
        width: '100%',
        flexShrink: 0,
      },
    },
    itemsCountText: {
      color: colorVariables.userNavy,
    },
    viewBtn: {
      height: 29,
      fontSize: 14,
      padding: 10,
      marginLeft: 'auto',
      marginRight: 50,
    },
    moreBtn: {
      opacity: 0.5,
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: 10,
      '&:hover': {
        opacity: 1,
      },
    },
    dialog: {
      right: 15,
      width: 360,
      top: -20,
    },
  }),
);
