import { createStyles, makeStyles } from '@material-ui/core';
import { colorVariables } from '../../../../styles/colorVariables';

export const useStyles = makeStyles((theme) =>
  createStyles({
    optionTrigger: {
      display: 'flex',
      alignItems: 'center',
      textTransform: 'capitalize',
      color: colorVariables.grey80,
      '& svg': {
        color: colorVariables.grey60,
      },
    },
    optionTriggerCustom: {
      '& span': {
        color: colorVariables.blue,
      },
    },
  }),
);
