import { isBefore } from 'date-fns';

export const transformChartData = (data: any) => {
  return (Object.entries(data) as [string, number][])
    .map(([key, value]: [string, number]) => ({
      date: new Date(key),
      value,
    }))
    .sort((a, b) => (isBefore(a.date, b.date) ? -1 : 1));
};
