import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import ReactDropzone from 'react-dropzone';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import SvgUploadIcon from '../../../assets/icons/UploadIcon';
import { useScreenSize } from '../../hooks/use-screen-size';
import { ThemedButton } from '../themed-button';
import { ProgressBar } from '../progress-bar';
import { useStyles } from './style';

enum UploadStages {
  DROP,
  UPLOAD,
  CROP,
}

interface Props {
  cropTitle?: string;
  saveImage: (blob: Blob) => void;
  closeModal?: () => void;
  skipCloseOnSave?: boolean;
  tipElement?: ReactElement;
  customAspect?: boolean;
}

export const ImageUpload: React.FC<Props> = ({ cropTitle, saveImage, closeModal, skipCloseOnSave, tipElement, customAspect }) => {
  const { isMobile } = useScreenSize();
  const classes = useStyles();

  const [uploadPercent, setUploadPercent] = useState(0);
  const [uploadingStage, setUploadingStage] = useState<UploadStages | null>(UploadStages.DROP);
  const [image, setImage] = useState<HTMLImageElement | null>(null);
  const [result, setResult] = useState<string | null>(null);
  const [fileToUpload, setFileToUpload] = useState<Blob | null>(null);
  const [fileUrl, setFileUrl] = useState('');
  const [uploaded, setUploaded] = useState(false);
  const [crop, setCrop] = useState<Partial<Crop>>({ unit: '%', width: 40, aspect: customAspect ? undefined : 1, x: 1, y: 1 });

  const isCropDisabled = !crop.width || !crop.height || crop.width < 20 || crop.height < 20;
  const imgRef = useRef<HTMLInputElement | null>(null);

  const onLoad = useCallback((img) => {
    setImage(img);
    imgRef.current = img;
  }, []);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const reader = new FileReader();
    reader.readAsDataURL(acceptedFiles[0]);
    const uploadedFileUrl = URL.createObjectURL(acceptedFiles[0]);
    setFileUrl(uploadedFileUrl);
    setUploadingStage(UploadStages.UPLOAD);
  }, []);

  const onCancel = () => {
    if (!result && closeModal) {
      closeModal();
      setFileUrl('');
      setImage(null);
    }
    setResult(null);
  };

  const onSave = () => {
    result && fileToUpload && saveImage(fileToUpload);
    closeModal && !skipCloseOnSave && closeModal();
  };

  useEffect(() => {
    if (uploadingStage !== UploadStages.UPLOAD) return;
    const uploadTimer = setInterval(() => {
      setUploadPercent((state) => {
        if (state < 100) {
          return state + 25;
        } else {
          setUploaded(true);
          clearInterval(uploadTimer);
          setUploadingStage(UploadStages.CROP);
          return 0;
        }
      });
    }, 100);
  }, [uploadingStage]);

  const getCroppedImg = () => {
    try {
      const canvas = document.createElement('canvas');
      if (!image) {
        return;
      }
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width as number;
      canvas.height = crop.height as number;
      const ctx = canvas.getContext('2d');
      if (!ctx || !crop.width || !crop.height || isCropDisabled) {
        return;
      }

      ctx.drawImage(
        image,
        (crop.x || 1) * scaleX,
        (crop.y || 1) * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height,
      );

      const base64Image = canvas.toDataURL('image/jpg', 1);
      canvas.toBlob((blob) => {
        setFileToUpload(blob);
      });
      setResult(base64Image);
    } catch (e) {
      console.log('crop the image error');
      closeModal && closeModal();
    }
  };

  const uploadingStageSwitch = () => {
    switch (uploadingStage) {
      case UploadStages.UPLOAD:
        return (
          <div className={classes.uploadLogoContainer}>
            <div className={classes.topBox}>
              <div className={classes.boldText}>Uploading</div>
              <div>{uploaded ? 'File uploaded successfully (tick)' : `${uploadPercent}%`}</div>
            </div>
            <ProgressBar percent={uploadPercent} />
          </div>
        );
      case UploadStages.CROP:
        return (
          <>
            <div className={classes.cropContainer}>
              <div className={classes.cropTitle}>{cropTitle ?? 'Crop your logo'}</div>
              {result ? <img src={result} /> : <ReactCrop src={fileUrl} onImageLoaded={onLoad} crop={crop as Crop} onChange={setCrop} />}
            </div>
            <div className={classes.btnBlock}>
              <ThemedButton title='Cancel' onClick={onCancel} buttonStyle='secondary' width={isMobile ? 120 : undefined} />
              {result ? (
                <ThemedButton title='Save image' onClick={onSave} width={isMobile ? 120 : 'auto'} />
              ) : (
                <ThemedButton title='Crop' onClick={getCroppedImg} disabled={isCropDisabled} width={isMobile ? 120 : undefined} />
              )}
            </div>
          </>
        );
      case UploadStages.DROP:
      default:
        return (
          <>
            <ReactDropzone onDrop={onDrop} accept='image/*'>
              {({ getRootProps, getInputProps }) => (
                <div className={classes.uploadLogoContainer} {...getRootProps()}>
                  <SvgUploadIcon className={classes.uploadIcon} />
                  <input {...getInputProps()} />
                  <div className={classes.uploadDescription}>
                    Drop file here to upload or <span className={classes.uploadDescriptionBtn}>choose a file</span>
                  </div>
                </div>
              )}
            </ReactDropzone>
            {tipElement}
          </>
        );
    }
  };

  return <div className={classes.root}>{uploadingStageSwitch()}</div>;
};
