import React from 'react';
import { useLocation } from 'react-router-dom';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import clsx from 'clsx';

import { useAppDispatch } from '../../../../store';
import { showPantryListExitWarning } from '../../../../store/pantry-lists';
import { showRecOrderExitWarning } from '../../../../store/recurring-order';

import { useStyles } from './style';

interface Props {
  items: React.ReactNode[];
  triggerBtn?: React.ReactNode;
  isSupplier?: boolean;
  disabled?: boolean;
}

export const NavBarMenu: React.FC<Props> = ({ items = [], triggerBtn, isSupplier, disabled }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { state: navState }: { state: { recOrderId?: number; pantryListId?: number } | null } = useLocation();
  const dispatch = useAppDispatch();

  const handleClick = (event: React.MouseEvent<any>) => {
    if (navState?.recOrderId) {
      dispatch(
        showRecOrderExitWarning({
          handler: setAnchorEl.bind(null, event.currentTarget),
        }),
      );
      return;
    }
    if (navState?.pantryListId) {
      dispatch(
        showPantryListExitWarning({
          handler: setAnchorEl.bind(null, event.currentTarget),
        }),
      );
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div
        aria-controls='top-menu'
        onClick={disabled ? undefined : handleClick}
        className={clsx(classes.triggerWrap, anchorEl && !isSupplier && classes.triggerWrapOpened)}
      >
        {triggerBtn}
      </div>
      <Menu
        autoFocus={false}
        id='top-menu'
        anchorEl={anchorEl}
        disableScrollLock={true}
        keepMounted={true}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ paper: clsx(classes.container, !isSupplier && classes.customerDropdown) }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {items.map((item, idx) => (
          <MenuItem onClick={handleClose} classes={{ root: clsx(classes.dropdownItem, !isSupplier && classes.customerMenuItem) }} key={idx}>
            {item}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
