import React, { useEffect, useState } from 'react';

import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx';

import { useUpdateSupplierProfileMutation } from '../../../../api/company';
import { DeliveryDay, SupplierProfile } from '../../../../api/company/types';
import { ButtonsBlock } from '../../../../shared/components/buttons-block';
import { OverlayModal } from '../../../../shared/components/overlay-modal';
import { week } from '../../../../shared/constants/week';
import { ProfileModals } from '../index';

import { useStyles } from './style';

interface Props {
  values: { days: DeliveryDay[]; delivery_detail_id?: number | null; supplier_id?: number };
  onClose: () => void;
  onSubmit: (data: Partial<SupplierProfile>) => void;
  isModalOpen: boolean;
  isOnBoarding: boolean;
  setNextStep: (step: ProfileModals) => void;
  companyId?: number;
}

export const DeliveryDaysBlock: React.FC<Props> = ({ values, companyId, onClose, onSubmit, isModalOpen, isOnBoarding, setNextStep }) => {
  const classes = useStyles();
  const [selectedDays, setSelectedDays] = useState<DeliveryDay[]>([]);

  const [updateProfile, { isSuccess, isLoading }] = useUpdateSupplierProfileMutation();

  const onSetDeliveryDay = (day: DeliveryDay) => {
    if (selectedDays.includes(day)) {
      setSelectedDays((state) => state.filter((item) => item !== day));
    } else {
      setSelectedDays((state) => [...state, day]);
    }
  };

  const handleSubmit = () => {
    if (isOnBoarding) {
      onSubmit({
        delivery_days: selectedDays,
      });
      setNextStep(ProfileModals.DELIVERY_DETAILS);
    } else {
      const formData = new FormData();
      week.forEach((day) => {
        formData.append(`company[delivery_details_attributes][0][${day.key}]`, selectedDays.includes(day.key).toString());
      });
      values.delivery_detail_id && formData.append('company[delivery_details_attributes][0][id]', values.delivery_detail_id.toString());
      values.supplier_id && formData.append('company[delivery_details_attributes][0][user_id]', values.supplier_id.toString());
      companyId && updateProfile({ formData, id: companyId });
    }
  };

  const closeHandler = () => {
    isOnBoarding ? setNextStep(ProfileModals.ORDER_DETAILS) : onClose();
  };

  useEffect(() => {
    setSelectedDays(values?.days || []);
  }, [values?.days, isModalOpen]);

  useEffect(() => {
    isSuccess && onClose();
  }, [isSuccess]);

  return (
    <OverlayModal isOpen={isModalOpen} onClose={onClose} title='SET YOUR GENERAL DELIVERY DAYS' animate={!isOnBoarding}>
      <div className={classes.container}>
        <div className={classes.subTitle}>Select all that apply</div>
        <div className={classes.daysBox}>
          {week.map(({ longTitle, key }) => (
            <div
              key={key}
              className={clsx({
                [classes.weekDayItem]: true,
                [classes.weekDayItemSelected]: selectedDays.includes(key),
              })}
              onClick={onSetDeliveryDay.bind(null, key)}
            >
              <CheckIcon className={classes.checkIcon} />
              {longTitle}
            </div>
          ))}
        </div>
        <ButtonsBlock
          isSubmitDisabled={isLoading || !selectedDays.length}
          onSave={handleSubmit}
          onCancel={closeHandler}
          saveTitle={isOnBoarding ? 'Next' : 'Update'}
          cancelTitle={isOnBoarding ? 'Back' : 'Cancel'}
        />
      </div>
    </OverlayModal>
  );
};
