import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import { Close } from '@material-ui/icons';
import clsx from 'clsx';

import { StyleProps, useStyles } from './style';

interface Props extends StyleProps {
  onClick?: () => void;
  customClass?: string;
}

export const CloseButton: React.FC<Props> = React.memo(({ onClick, customClass = '', ...styleProps }) => {
  const classes = useStyles(styleProps);
  return (
    <IconButton className={clsx([classes.button, customClass])} onClick={onClick}>
      <Close className={classes.icon} />
    </IconButton>
  );
});
