import React, { memo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import clsx from 'clsx';

import { useDeletePantryListMutation } from '../../../../../../api/pantry-list';
import { PantryList, SupplierInPantryList } from '../../../../../../api/pantry-list/types';
import { useGeIsTeammateViewer } from '../../../../../../api/teammates/hooks';
import { ThemedButton } from '../../../../../../shared/components/themed-button';
import { Dialog } from '../../../../../../shared/components/dialog';
import { Dropdown } from '../../../../../../shared/components/dropdown';
import { MoreButton } from '../../../../../../shared/components/more-button';
import { useScreenSize } from '../../../../../../shared/hooks/use-screen-size';
import { ToastService } from '../../../../../../shared/services/toastService';
import { useAppDispatch } from '../../../../../../store';
import { setSelectedPantryListId } from '../../../../../../store/pantry-lists';

import { useStyles as useParentStyles } from '../style';
import { useStyles } from './style';

interface Props {
  item: PantryList;
  onRename: (id: number) => void;
}

const Component: React.FC<Props> = ({ item, onRename }) => {
  const classes = useStyles();
  const parentClasses = useParentStyles();
  const dispatch = useAppDispatch();

  const { push } = useHistory();
  const { isDesktop, isTabletLandscape } = useScreenSize();

  const [isDropdownOpened, setIsDropDownOpened] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [deletePantryList] = useDeletePantryListMutation();
  const isViewer = useGeIsTeammateViewer('pantry_lists');

  const suppliers =
    item.suppliers_company.reduce<SupplierInPantryList[]>((acc, el, idx, arr) => {
      const index = arr.findIndex((arrItem) => arrItem.id === el.id);
      if (index === idx) {
        acc.push(el);
      }
      return acc;
    }, []) || [];

  const openDialog = () => {
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const onOpen = () => {
    setIsDropDownOpened(true);
  };

  const onClose = () => {
    setIsDropDownOpened(false);
  };

  const openDetails = () => {
    push('/pantry_list/details', item.id);
  };

  const onEdit = () => {
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    dispatch(setSelectedPantryListId(item.id));
    push({
      pathname: '/pantry_list/edit',
      state: {
        pantryListId: item.id,
      },
    });
  };

  const renameHandler = () => {
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    onRename(item.id);
  };

  const onDelete = () => {
    deletePantryList(item.id);
    closeDialog();
  };

  return (
    <tr key={item.id} className={clsx(classes.row, isDropdownOpened && classes.rowHighlighted)}>
      <td className={clsx(classes.cell, parentClasses.name)}>{item.name}</td>
      <td className={clsx(classes.cell, parentClasses.suppliers)}>
        <div className={classes.suppliersList}>
          {suppliers.slice(0, isDesktop ? 8 : isTabletLandscape ? 6 : 4).map(({ picture_url, id, name }) => (
            <div className={classes.supplierLogo} key={id}>
              {!!picture_url && <img src={picture_url} alt={name} />}
            </div>
          ))}
          {suppliers.length > (isDesktop ? 8 : isTabletLandscape ? 6 : 4) && <span>...</span>}
        </div>
      </td>
      <td className={clsx(classes.cell, parentClasses.items, classes.itemsCountText)}>{item.items_total}</td>
      <td className={clsx(classes.cell, parentClasses.actions)}>
        <ThemedButton title='View' onClick={openDetails} customClass={classes.viewBtn} width={92} />
        <Dropdown
          onOpen={onOpen}
          onClose={onClose}
          top={-10}
          closeOnClick={true}
          triggerBtn={<MoreButton customClass={classes.moreBtn} />}
          items={[
            { title: 'Edit', handler: onEdit },
            { title: 'Delete', handler: openDialog },
            { title: 'Rename', handler: renameHandler },
          ]}
        />
        {isDialogOpen && (
          <Dialog
            title={`Are you sure you want to remove the '${item.name}' from Pantry List`}
            onCancel={closeDialog}
            onConfirm={onDelete}
            customClass={classes.dialog}
          />
        )}
      </td>
    </tr>
  );
};

export const PantryListRow = memo(Component);
