import { CSSProperties, useState } from 'react';
import { colorVariables } from '../../styles/colorVariables';

interface ImageStyle {
  thumbnail: CSSProperties;
  fullSize: CSSProperties;
}

interface ImageOnLoadType {
  handleImageOnLoad: () => void;
  css: ImageStyle;
}

function useImageOnLoad(): ImageOnLoadType {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  // Triggered when full image will be loaded.
  const handleImageOnLoad = () => {
    setIsLoaded(true);
  };

  const css: ImageStyle = {
    // Thumbnail style.
    thumbnail: {
      visibility: isLoaded ? 'hidden' : 'visible',
      transition: 'visibility 0ms ease-out 500ms',
      backgroundColor: colorVariables.white,
    },
    // Full image style.
    fullSize: {
      opacity: isLoaded ? 1 : 0,
      transition: 'all 500ms ease-in 0ms',
    },
  };

  return { handleImageOnLoad, css };
}

export default useImageOnLoad;
