import { createEntityAdapter, createReducer, EntityState } from '@reduxjs/toolkit';
import isEqual from 'lodash/isEqual';

import { salesApi } from '../../api/sales';
import { Sale, SalesFilters, SalesSortBy } from '../../api/sales/types';
import { RangeOptions } from '../../shared/components/range-dropdown';
import { logoutThunk } from '../user/user.actions';
import { clearSalesList, setSalesKeyword, setSalesPage, setSalesSortFilters } from './sales.actions';

export const salesAdapter = createEntityAdapter<Sale>();

interface State {
  list: EntityState<Sale>;
  currentPage: number;
  sortBy?: SalesSortBy;
  filter?: SalesFilters;
  keyword?: string;
}

const initialState: State = {
  list: salesAdapter.getInitialState(),
  currentPage: 1,
  sortBy: undefined,
  filter: { selectedRange: RangeOptions.LAST_7 },
  keyword: undefined,
};

export const salesReducer = createReducer(initialState, (builder) => {
  builder.addCase(logoutThunk.fulfilled, () => {
    return initialState;
  });
  builder.addCase(setSalesPage, (state, { payload }) => {
    state.currentPage = payload;
  });
  builder.addCase(clearSalesList, (state, { payload }) => {
    state.currentPage = 1;
    state.list = salesAdapter.getInitialState();
    state.filter = payload ? { date: new Date().toString() } : { selectedRange: undefined };
    state.sortBy = undefined;
  });
  builder.addCase(setSalesSortFilters, (state, { payload }) => {
    if (!isEqual(payload.filter, state.filter) || !isEqual(payload.sortBy, state.sortBy)) {
      state.list = salesAdapter.getInitialState();
      state.sortBy = payload.sortBy;
      state.filter = payload.filter;
      state.currentPage = 1;
    }
  });
  builder.addCase(setSalesKeyword, (state, { payload }) => {
    if (state.keyword === payload) {
      return;
    }
    state.keyword = payload;
    state.currentPage = 1;
    state.list = salesAdapter.getInitialState();
  });
  builder.addMatcher(salesApi.endpoints.getSales.matchFulfilled, (state, action) => {
    state.list = salesAdapter.setMany(state.list, action.payload.sales);
  });
  builder.addMatcher(salesApi.endpoints.createSale.matchFulfilled, (state) => {
    state.list = salesAdapter.getInitialState();
    state.currentPage = 1;
  });
  builder.addMatcher(salesApi.endpoints.updateSale.matchFulfilled, (state, action) => {
    state.list = salesAdapter.setOne(state.list, action.payload.recipe_sale);
  });
  builder.addMatcher(salesApi.endpoints.importSales.matchFulfilled, (state) => {
    state.list = salesAdapter.getInitialState();
    state.currentPage = 1;
  });
  builder.addMatcher(salesApi.endpoints.deleteSale.matchFulfilled, (state, action) => {
    state.list = salesAdapter.removeOne(state.list, action.meta.arg.originalArgs);
  });
});
