import { createReducer } from '@reduxjs/toolkit';
import { ManualSupplierAction } from '../../api/supplier/types';
import { userApi } from '../../api/user';
import { SupplierUser } from '../../api/user/types';
import { ProductsCategories } from '../../shared/constants/products';
import { logoutThunk } from '../user';
import {
  setManualSupplierAction,
  setMySuppliersSort,
  setSelectedCategory,
  setSelectedSupplier,
  setSupplierKeyword,
} from './suppliers-categories.actions';

interface State {
  selectedCategory: ProductsCategories | null;
  selectedSupplier: SupplierUser | null;
  manualSupplierAction: { id: number; action: ManualSupplierAction } | null;
  keyword?: string;
  mySuppliersSort?: string;
}

const initialState: State = {
  selectedCategory: null,
  manualSupplierAction: null,
  selectedSupplier: null,
  keyword: '',
  mySuppliersSort: 'most_purchased desc',
};

export const suppliersCategoriesReducer = createReducer(initialState, (builder) => {
  builder.addCase(logoutThunk.fulfilled, () => {
    return initialState;
  });
  builder.addCase(setSelectedCategory, (state, { payload }) => {
    state.selectedCategory = payload;
  });
  builder.addCase(setSelectedSupplier, (state, { payload }) => {
    state.selectedSupplier = payload;
  });
  builder.addCase(setManualSupplierAction, (state, { payload }) => {
    state.manualSupplierAction = payload;
  });
  builder.addCase(setMySuppliersSort, (state, { payload }) => {
    state.mySuppliersSort = payload;
  });
  builder.addCase(setSupplierKeyword, (state, { payload }) => {
    state.keyword = payload;
  });
  builder.addMatcher(userApi.endpoints.onboarding.matchFulfilled, () => {
    return initialState;
  });
});
