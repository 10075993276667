import { createReducer } from '@reduxjs/toolkit';

import { AdminOrderFilters, AdminOrderSortBy } from '../../../api/admin-orders/types';
import { logoutThunk } from '../../user';
import { setOrdersKeyword, setOrdersPage, setOrdersSortFilter, setSelectedOrders } from './orders.actions';

interface State {
  sortBy?: AdminOrderSortBy;
  filterBy?: AdminOrderFilters;
  keyword?: string;
  currentPage: number;
  selectedOrders: number[];
}

const initialState: State = {
  sortBy: undefined,
  filterBy: {
    days: 0,
  },
  keyword: undefined,
  currentPage: 1,
  selectedOrders: [],
};

export const adminOrdersReducer = createReducer(initialState, (builder) => {
  builder.addCase(logoutThunk.fulfilled, () => {
    return initialState;
  });
  builder.addCase(setOrdersKeyword, (state, { payload }) => {
    if (state.keyword === payload) {
      return;
    }
    state.keyword = payload;
    state.currentPage = 1;
  });
  builder.addCase(setOrdersPage, (state, { payload }) => {
    state.currentPage = payload;
    state.selectedOrders = [];
  });
  builder.addCase(setSelectedOrders, (state, { payload }) => {
    state.selectedOrders = payload;
  });
  builder.addCase(setOrdersSortFilter, (state, { payload }) => {
    state.filterBy = payload.filter;
    state.sortBy = payload.sort;
    state.currentPage = 1;
  });
});
