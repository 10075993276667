import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';

export interface StyleProps {
  size?: number;
  iconFontSize?: number;
}

export const useStyles = makeStyles<Theme, StyleProps>(() =>
  createStyles({
    searchBtn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: ({ size }) => size || 49,
      width: ({ size }) => size || 49,
      borderRadius: '50%',
      background: 'linear-gradient(90deg, #51D9B0 0%, #33A8E2 100% )',
    },
    searchBtnDisabled: {
      background: colorVariables.steelGrey,
      cursor: 'default',
    },
    searchIcon: {
      color: colorVariables.white,
      fontSize: ({ iconFontSize }) => iconFontSize || 30,
    },
  }),
);
