import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../../../../styles/colorVariables';

export const useStyles = makeStyles((theme) =>
  createStyles({
    selectWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: 'pointer',
      minWidth: 110,
    },
    select: {
      minWidth: 80,
      height: 24,
      lineHeight: '24px',
      borderRadius: 15,
      backgroundColor: 'rgba(152, 165, 183, 0.3)',
      color: colorVariables.blue,
      fontSize: 12,
      fontWeight: 700,
      textTransform: 'uppercase',
      textAlign: 'center',
      padding: '0 5px',
      marginRight: 10,
    },
    menuItem: {
      minWidth: 100,
      fontSize: 14,
      minHeight: 34,
      '&:hover': {
        backgroundColor: colorVariables.blueLight,
      },
    },
    menuItemActive: {
      backgroundColor: colorVariables.blueLight,
    },
  }),
);
