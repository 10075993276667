import { createStyles, makeStyles } from '@material-ui/core';

import { Sizes } from '../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    formContainer: {
      display: 'block',
      paddingTop: 20,
      width: 600,
      [theme.breakpoints.down(Sizes.tablet)]: {
        width: 500,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        maxWidth: '100%',
      },
    },
    fieldWrap: {
      marginBottom: 20,
      paddingTop: 15,
      '&:hover + div': {
        transition: 'all ease-in-out 0.5s',
        visibility: 'visible',
        opacity: 1,
      },
    },
    deliveryFee: {
      width: 200,
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
      },
    },
    tooltipWrap: {
      width: 350,
      visibility: 'hidden',
      position: 'relative',
      zIndex: 300,
      transform: 'translateY(-100px)',
      opacity: 0,
      transition: 'all ease-in-out 0.7s',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 'auto',
        transform: 'translateY(-85px)',
      },
    },
  }),
);
