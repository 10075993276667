import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';

interface StyleProps {
  color?: string;
}

export const useStyles = makeStyles<Theme, StyleProps>(() =>
  createStyles({
    button: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 34,
      height: 34,
      '&>span': {
        display: 'flex',
        width: 34,
        height: 34,
      },
    },
    icon: {
      fontSize: 18,
      color: ({ color }) => color || colorVariables.navy,
    },
  }),
);
