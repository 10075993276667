import React from 'react';

import { Circle, GoogleMap, Marker } from '@react-google-maps/api';

import { colorVariables } from '../../../styles/colorVariables';
import { useStyles } from './style';

interface Props {
  radius: number;
  lat: number;
  lng: number;
  markerTitle?: string;
}

export const RadiusMap: React.FC<Props> = ({ radius, lat, lng, markerTitle }) => {
  const classes = useStyles();
  const options = {
    strokeColor: colorVariables.blue,
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: colorVariables.blueLight,
    fillOpacity: 0.4,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    zIndex: 1,
  };

  return (
    <GoogleMap mapContainerClassName={classes.container} center={{ lat, lng }} zoom={8}>
      <Circle center={{ lat, lng }} options={options} radius={radius * 1000} />
      <Marker position={{ lat, lng }} title={markerTitle} />
    </GoogleMap>
  );
};
