import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import { Add } from '@material-ui/icons';
import clsx from 'clsx';

import { useStyles } from './style';

interface Props {
  onClick?: () => void;
  isDark?: boolean;
}

export const AddButton: React.FC<Props> = React.memo(({ onClick, isDark = false }) => {
  const classes = useStyles();
  return (
    <IconButton className={clsx([classes.button, isDark && classes.buttonDark])} onClick={onClick}>
      <Add style={{ background: 'transparent' }} className={clsx([classes.icon, isDark && classes.iconDark])} />
    </IconButton>
  );
});
