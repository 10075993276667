import * as React from 'react';

function SvgPoultry(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M22.33 13.597l.113.118.163-.01c3.81-.247 6.69.733 8.778 2.78 2.098 2.058 3.44 5.237 4.07 9.476.691 4.652-2.524 8.797-6.643 8.797H4.821c-2.707 0-4.669-3.088-3.622-6.02.858-2.404 2.79-5.912 7.136-9.273l.201-.155-.086-.24-1.713-4.773a5.065 5.065 0 01.129-3.734l.091-.207-.15-.168-2.93-3.264-.104-.116H1.417C.862 6.808.35 6.295.35 5.58c0-.715.512-1.229 1.067-1.229H3.137V2.474c0-.716.512-1.23 1.067-1.23.555 0 1.067.514 1.067 1.23v2.585l.09.1 2.928 3.263.186.207.243-.134a3.643 3.643 0 013.274-.141l7.926 3.533.143-.32-.143.32c.9.4 1.714.98 2.412 1.71zm-11.106-2.95h0c-.802-.358-1.599-.083-2.098.474a2.22 2.22 0 00-.417 2.24s0 0 0 0l3.171 8.832.33-.118-.33.118c.842 2.347 2.868 3.896 5.186 3.896 3.112 0 5.58-2.808 5.58-6.178 0-2.542-1.378-4.787-3.495-5.731l-7.927-3.533zM9.534 22.09l-.167-.467-.384.315c-2.783 2.287-4.828 4.982-5.81 7.731-.434 1.215.351 2.63 1.648 2.63h23.99c2.887 0 4.993-2.87 4.54-5.926-.49-3.294-1.405-5.775-2.854-7.473-1.46-1.713-3.432-2.595-5.949-2.75l-.536-.034.186.504c2.112 5.725-1.709 11.926-7.132 11.926-3.133 0-5.977-2.124-7.159-5.417l-.372-1.038z'
        fill='#51D9B0'
        stroke='#fff'
        strokeWidth={0.7}
      />
    </svg>
  );
}

export default SvgPoultry;
