import { useEffect, useMemo } from 'react';

import { RangeOptions } from '../../shared/components/range-dropdown';
import { generateWastagesSheet } from '../../shared/helpers/generateWastagesSheet';
import { transformChartData } from '../../shared/helpers/transform-chart-data';
import { ToastService } from '../../shared/services/toastService';
import { useAppDispatch, useAppSelector } from '../../store';
import { getWastageSortFilters, setWastagePage } from '../../store/wastage';
import { useExportWastagesMutation, useGetWastageReportQuery, useGetWastagesQuery } from './index';
import { ExportWastagesParams, WastageGraphReqParams } from './types';

export const useGetWastagesList = (inView?: boolean) => {
  const dispatch = useAppDispatch();
  const { currentPage, sortBy, filter, keyword } = useAppSelector(getWastageSortFilters);

  const { loading, next_page } = useGetWastagesQuery(
    { page: currentPage, sortBy, filter, keyword },
    {
      selectFromResult: ({ data, isFetching }) => ({
        loading: isFetching,
        next_page: data?.next_page,
      }),
    },
  );

  useEffect(() => {
    inView && next_page && dispatch(setWastagePage(next_page));
  }, [inView, next_page]);

  return { loading };
};

export const useGetWastageReport = (params: WastageGraphReqParams) => {
  const { data, isLoading, isFetching } = useGetWastageReportQuery(params, {
    skip: params.days === undefined && (!params['[date_range][from_date]'] || !params['[date_range][to_date]']),
  });

  const {
    chartData,
    wasteByReasonData,
    wasteByMenuData,
    wasteByEmployeeData,
    previousChartData,
    wasteByItemsData,
    wasteByVarianceData,
    varianceGraph,
  } = useMemo(() => {
    return {
      chartData: data?.wastage_graph ? transformChartData(data.wastage_graph) : [],
      previousChartData: data?.previous_wastage_graph ? transformChartData(data.previous_wastage_graph) : [],
      wasteByReasonData: data?.wastage_by_options ? data?.wastage_by_options[0].wastage_by_reason : undefined,
      wasteByItemsData: data?.wastage_by_options ? data?.wastage_by_options[1].wastage_by_items : undefined,
      wasteByMenuData: data?.wastage_by_options ? data?.wastage_by_options[2].wastage_by_menu : undefined,
      wasteByEmployeeData: data?.wastage_by_options ? data?.wastage_by_options[3].wastage_by_employee : undefined,
      wasteByVarianceData: data?.wastage_by_options ? data?.wastage_by_options[4].stocktake_variances : undefined,
      varianceGraph: data?.variance_graph,
    };
  }, [data]);
  return {
    chartData,
    previousChartData,
    loading: isLoading,
    fetching: isFetching,
    wasteByReasonData,
    wasteByItemsData,
    wasteByMenuData,
    wasteByEmployeeData,
    wasteByVarianceData,
    varianceGraph,
  };
};

export const useExportWastages = (range: RangeOptions, from?: Date, to?: Date) => {
  const [exportWastages, { isLoading: loading }] = useExportWastagesMutation();

  const getWastages = (params?: ExportWastagesParams) => {
    exportWastages(params).then((res) => {
      if (!('data' in res)) {
        return;
      }
      if (res.data.data.length) {
        generateWastagesSheet(res.data, range, from, to).then(() => {
          ToastService.success('Your Wastage have been Exported', Math.random().toString());
        });
      } else {
        ToastService.error('‘Wastage’ list is empty');
      }
    });
  };

  return { getWastages, loading };
};
