import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { CircularProgress, IconButton } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import clsx from 'clsx';
import { Form, Formik, FormikHelpers } from 'formik';
import { useInView } from 'react-intersection-observer';

import { useGetPantryLists } from '../../../../../api/pantry-list/hooks';
import { PantryListSortBy } from '../../../../../api/pantry-list/types';
import SvgFilterIconCustomer from '../../../../../assets/icons/FilterIconCustomer';
import SvgPantryListEmpty from '../../../../../assets/images/svg-components/PantryListEmpty';
import { AddButtonDashed } from '../../../../../shared/components/add-button-dashed';
import { ThemedButton } from '../../../../../shared/components/themed-button';
import { CommonChip } from '../../../../../shared/components/common-chip';
import { FilterByCategory } from '../../../../../shared/components/filter-by-category';
import { FormikSortDropdown } from '../../../../../shared/components/formik-sort-dropdown';
import { NoSearchResultsRestaurant } from '../../../../../shared/components/no-search-results-restaurant';
import { OpLoading } from '../../../../../shared/components/op-loading';
import { SearchField } from '../../../../../shared/components/search-field';
import { ProductsCategories } from '../../../../../shared/constants/products';
import { useScreenSize } from '../../../../../shared/hooks/use-screen-size';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import {
  getPantryListChips,
  getPantryListSortFilters,
  hideCreatePantryListModal,
  pantryListsSelectors,
  setPantryListKeyword,
  setPantryListsSortFilters,
  setSelectedPantryListId,
  showCreatePantryListModal,
} from '../../../../../store/pantry-lists';
import { colorVariables } from '../../../../../styles/colorVariables';
import { PantryListItem } from './pantry-list-item';
import { PantryListRow } from './pantry-list-row';
import { CreatePantryListModal } from './PantryListDetails/create-modal';
import { RenamePantryListModal } from './PantryListDetails/rename-modal';
import { PantryListSortFilterModal } from './sort-filter-modal';

import { useStyles } from './style';
import { useGeIsTeammateViewer } from '../../../../../api/teammates/hooks';
import { ToastService } from '../../../../../shared/services/toastService';

interface Values {
  sortBy: string;
}

export const pantryListSortByOptions = [
  { label: 'Pantry List name (A - Z)', value: 'name asc' },
  { label: 'Pantry List name (Z - A)', value: 'name desc' },
  { label: 'Date created (Most recent to Old)', value: 'created_at desc' },
  { label: 'Recently used (Most recent to Old)', value: 'products_order_items_created_at desc' },
];

export const PantryList: React.FC = () => {
  const classes = useStyles();
  const { isMobile, isTabletPortrait } = useScreenSize();
  const dispatch = useAppDispatch();
  const [inViewRef, inView] = useInView();
  const { replace } = useHistory();
  const { state: createMode }: { state: boolean } = useLocation();

  const [isFilterModalOpened, setIsFilterModalOpened] = useState(false);
  const [isRenameModalOpened, setIsRenameModalOpened] = useState(false);

  const chips = useAppSelector(getPantryListChips);
  const { keyword, filter: selectedCategory, sort } = useAppSelector(getPantryListSortFilters);
  const isViewer = useGeIsTeammateViewer('pantry_lists');

  const isCreateModalShown = useAppSelector((state) => state.pantryList.isCreateModalShown);

  const pantryLists = useAppSelector(pantryListsSelectors.selectAll);
  const { loading } = useGetPantryLists(inView);

  const openRenameModal = useCallback((id: number) => {
    dispatch(setSelectedPantryListId(id));
    setIsRenameModalOpened(true);
  }, []);

  const setSelectedCategory = (category: ProductsCategories | null) => {
    dispatch(setPantryListsSortFilters({ filter: category, sort }));
  };

  const closeRenameModal = () => {
    setIsRenameModalOpened(false);
  };

  const openModal = () => {
    setIsFilterModalOpened(true);
  };

  const closeModal = () => {
    setIsFilterModalOpened(false);
  };

  const showCreateModal = useCallback(() => {
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    dispatch(showCreatePantryListModal());
  }, [isViewer]);

  const hideCreateModal = () => {
    dispatch(hideCreatePantryListModal());
  };

  const onSubmit = ({ sortBy }: Values, formikHelpers: FormikHelpers<Values>) => {
    dispatch(setPantryListsSortFilters({ sort: sortBy.split(' ') as PantryListSortBy, filter: selectedCategory }));
    formikHelpers.setSubmitting(false);
  };

  const onRemoveChip = (key: string) => {
    if (key === 'sort') {
      dispatch(setPantryListsSortFilters({ sort: undefined, filter: selectedCategory }));
    }
    if (key === 'category') {
      dispatch(setPantryListsSortFilters({ sort, filter: null }));
    }
  };

  const onSearchPantryList = (searchStr: string) => {
    dispatch(setPantryListKeyword(searchStr));
  };

  useEffect(() => {
    if (createMode) {
      showCreateModal();
      replace('/pantry_list');
    }
  }, [createMode]);

  return (
    <>
      {!keyword && !selectedCategory && pantryLists.length === 0 && !loading ? null : (
        <div className={classes.header}>
          <Formik
            onSubmit={onSubmit}
            initialValues={{ sortBy: sort?.join(' ') || pantryListSortByOptions[2].value }}
            enableReinitialize={true}
          >
            <Form className={classes.form}>
              <div className={classes.searchWrap}>
                <SearchField placeholder='Search pantry list' onChange={onSearchPantryList} searchValue={keyword} />
              </div>
              <IconButton className={classes.filterBtnMobile} onClick={openModal}>
                <SvgFilterIconCustomer width={20} height={20} color={colorVariables.userNavy} />
              </IconButton>
              <div className={classes.filterWrap}>
                <div className={classes.filterTitle}>Filter by Category</div>
                <FilterByCategory selectedCategory={selectedCategory} onApply={setSelectedCategory} />
              </div>
              <div className={classes.sortWrap}>
                <div className={clsx(classes.filterTitle, classes.sortTitle)}>Sort by</div>
                <FormikSortDropdown name='sortBy' options={pantryListSortByOptions} />
              </div>
              <ThemedButton
                customClass={classes.addBtn}
                title='CREATE PANTRY LIST'
                onClick={showCreateModal}
                buttonStyle='icon'
                startIcon={<Add className={classes.addIcon} />}
              />
            </Form>
          </Formik>
          <div className={classes.chipBox}>
            {chips
              .filter((el) => el.label !== 'Date created (Most recent to Old)')
              .map((chip) => (
                <CommonChip key={chip.key} title={chip.label} onClick={onRemoveChip.bind(null, chip.key)} />
              ))}
          </div>
        </div>
      )}
      {isMobile || isTabletPortrait ? (
        <>
          <div className={classes.addBtnWrap}>
            <AddButtonDashed title='CREATE PANTRY LIST' onClick={showCreateModal} />
          </div>
          {(keyword || selectedCategory) && pantryLists.length === 0 && !loading ? (
            <NoSearchResultsRestaurant />
          ) : !keyword && !selectedCategory && pantryLists.length === 0 && !loading ? (
            <div className={classes.emptyContainer}>
              <h2 className={classes.emptyTitle}>
                Create your Pantry List <br /> here
              </h2>
              <ThemedButton onClick={showCreateModal} title='Create Pantry List' />
              <div className={classes.emptySubTitle}>Create convenient lists of products and quickly add them to the cart</div>
              <SvgPantryListEmpty width={350} height={350} />
            </div>
          ) : (
            <OpLoading
              loading={loading && !pantryLists.length}
              dataLoaded={!!pantryLists.length}
              boxClasses={classes.loadingBox}
              resetProgress={true}
            >
              <>
                {pantryLists.map((item, idx, arr) => (
                  <div ref={idx === arr.length - 10 ? inViewRef : undefined} key={item.id}>
                    <PantryListItem item={item} />
                  </div>
                ))}
              </>
            </OpLoading>
          )}
        </>
      ) : (
        <>
          {(keyword || selectedCategory) && pantryLists.length === 0 && !loading ? (
            <NoSearchResultsRestaurant />
          ) : !keyword && !selectedCategory && pantryLists.length === 0 && !loading ? (
            <div className={classes.emptyContainer}>
              <h2 className={classes.emptyTitle}>
                Create your Pantry List <br /> here
              </h2>
              <ThemedButton onClick={showCreateModal} title='Create Pantry List' />
              <div className={classes.emptySubTitle}>Create convenient lists of products and quickly add them to the cart</div>
              <SvgPantryListEmpty width={350} height={350} />
            </div>
          ) : (
            <OpLoading
              loading={loading && !pantryLists.length}
              dataLoaded={!!pantryLists.length}
              resetProgress={true}
              boxClasses={classes.loadingBox}
            >
              <table className={classes.table}>
                <thead className={classes.head}>
                  <tr className={classes.headRow}>
                    <th className={clsx(classes.headCell, classes.name)}>Name</th>
                    <th className={clsx(classes.headCell, classes.suppliers)}>Suppliers</th>
                    <th className={clsx(classes.headCell, classes.items)}>Items</th>
                    <th className={clsx(classes.headCell, classes.actions)} />
                  </tr>
                </thead>
                <tbody>
                  {pantryLists.map((item, idx, arr) => (
                    <div key={item.id} ref={idx === arr.length - 10 ? inViewRef : undefined}>
                      <PantryListRow item={item} onRename={openRenameModal} />
                    </div>
                  ))}
                  {loading && !!pantryLists.length && (
                    <div className={classes.progressBox}>
                      <CircularProgress size={40} thickness={2} value={100} />
                    </div>
                  )}
                </tbody>
              </table>
            </OpLoading>
          )}
        </>
      )}
      <PantryListSortFilterModal isOpened={isFilterModalOpened} closeModal={closeModal} />
      <RenamePantryListModal isOpened={isRenameModalOpened} onClose={closeRenameModal} />
      <CreatePantryListModal isOpened={isCreateModalShown} onClose={hideCreateModal} />
    </>
  );
};
