import { createAction } from '@reduxjs/toolkit';
import { AdminOrderFilters, AdminOrderSortBy } from '../../../api/admin-orders/types';

export const setOrdersPage = createAction<number>('SET_A_ORDERS_PAGE');

export const setOrdersKeyword = createAction<string>('SET_A_ORDERS_KEYWORD');

export const setSelectedOrders = createAction<number[]>('SET_A_SELECTED_ORDERS');

export const setOrdersSortFilter = createAction<{ filter?: AdminOrderFilters; sort?: AdminOrderSortBy }>('SET_A_ORDERS_SORT_FILTER');
