import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Done } from '@material-ui/icons';

import { useAppDispatch, useAppSelector } from '../../../store';
import { getMySuccessMsg, setSuccessMsg } from '../../../store/user';
import { ThemedButton } from '../themed-button';
import { OverlayModal } from '../overlay-modal';

import { useStyles } from './style';

export const SuccessModal: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { push } = useHistory();

  const successMsg = useAppSelector(getMySuccessMsg);

  const onClose = useCallback(() => {
    dispatch(setSuccessMsg({ message: null }));
  }, []);

  const onCloseWithBtn = () => {
    onClose();
    if (pathname.includes('help/contact')) {
      push('/account/help');
    }
  };

  return (
    <OverlayModal isOpen={!!successMsg} onClose={onClose} boxClassName={classes.modalRoot}>
      <div className={classes.successInfo}>
        <div className={classes.contentWrap}>
          <div className={classes.doneIcon}>
            <Done />
          </div>
          <div className={classes.title}>{successMsg || ''}</div>
        </div>
        <ThemedButton onClick={onCloseWithBtn} title='Close' width={100} isSmall={true} />
      </div>
    </OverlayModal>
  );
};
