import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';

export const useStyles = makeStyles(() =>
  createStyles({
    label: {
      fontSize: 14,
      userSelect: 'none',
    },
    checkbox: {
      color: colorVariables.grey60,
    },
  }),
);
