import React from 'react';

import { Close } from '@material-ui/icons';
import clsx from 'clsx';
import { FastField, FieldProps, Form, Formik, FormikHelpers } from 'formik';

import { UsersSortBy } from '../../../../../api/admin-users/types';
import { Roles } from '../../../../../api/user/types';
import { ThemedButton } from '../../../../../shared/components/themed-button';
import { FormikDropdown } from '../../../../../shared/components/formik-dropdown';
import { useScreenSize } from '../../../../../shared/hooks/use-screen-size';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { getUsersSortFilters, setUsersSortFilters } from '../../../../../store/admin/users';
import { usersFilter } from '../users-list-header';

import { useStyles } from './style';

export const usersSortParams = [
  { label: 'User name (A - Z)', value: 'first_name asc' },
  { label: 'User name (Z - A)', value: 'first_name desc' },
  { label: 'Type (A - Z)', value: 'type asc' },
  { label: 'Type (Z - A)', value: 'type desc' },
  { label: 'Company name (A - Z)', value: 'company_name asc' },
  { label: 'Company name  (Z - A)', value: 'company_name desc' },
  { label: 'Created (Most recent to Old)', value: 'created_at desc' },
  { label: 'Created (Old to Most recent)', value: 'created_at asc' },
  { label: 'Verified (A - Z)', value: 'verified asc' },
  { label: 'Verified (Z - A)', value: 'verified desc' },
];

interface Values {
  sortBy: string;
  user_filter: Roles | 'all' | 'admin' | '0' | '1';
}

interface Props {
  handleCloseModal: () => void;
  isFullScreen?: boolean;
}

export const UsersFilter: React.FC<Props> = ({ handleCloseModal, isFullScreen }) => {
  const classes = useStyles();
  const { isMobile } = useScreenSize();
  const dispatch = useAppDispatch();

  const { sort, filter } = useAppSelector(getUsersSortFilters);

  const onSubmit = ({ sortBy, user_filter }: Values, formikHelpers: FormikHelpers<any>) => {
    handleCloseModal();
    dispatch(
      setUsersSortFilters({
        filter: {
          company_type: user_filter === 'all' || user_filter === '0' || user_filter === '1' ? undefined : user_filter,
          verified: user_filter === '1' ? true : user_filter === '0' ? false : undefined,
        },
        sort: sortBy.trim() ? (sortBy.split(' ') as UsersSortBy) : undefined,
      }),
    );
    formikHelpers.resetForm();
  };

  const resetFields = () => {
    dispatch(setUsersSortFilters({}));
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        sortBy: sort ? sort?.join(' ') : ' ',
        user_filter: filter?.verified !== undefined ? ((+filter.verified).toString() as '1' | '0') : filter?.company_type || 'all',
      }}
      enableReinitialize={true}
    >
      {({ submitForm }) => (
        <Form className={classes.formBox}>
          <div className={classes.root}>
            <div className={classes.sortBlock}>
              <div className={classes.blockTitle}>SORT BY</div>
              <FastField name='sortBy'>
                {(fieldProps: FieldProps) => (
                  <FormikDropdown
                    defaultValue={usersSortParams[0].value}
                    placeholder='Select sort option'
                    options={usersSortParams}
                    {...fieldProps}
                    height={36}
                    inputFontSize={14}
                  />
                )}
              </FastField>
            </div>
            <div className={classes.filterBlock}>
              <div className={classes.blockTitle}>FILTER BY</div>
              <FastField name='user_filter'>
                {(fieldProps: FieldProps) => (
                  <FormikDropdown
                    defaultValue={usersFilter[0].value}
                    placeholder='Select filter option'
                    options={usersFilter}
                    {...fieldProps}
                    height={36}
                    inputFontSize={14}
                  />
                )}
              </FastField>
            </div>
            <div className={clsx([classes.btnBlock, isFullScreen && classes.btnBlockFullScreen])}>
              <ThemedButton onClick={submitForm} title='Apply' buttonStyle='primary' isSmall={isMobile} />
              <ThemedButton
                type='reset'
                onClick={resetFields}
                title='CLEAR FILTERS'
                buttonStyle='icon'
                startIcon={<Close color={'inherit'} />}
                isSmall={isMobile}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
