export const helpCenterRestaurant = [
  {
    id: 101,
    path: 'help-center',
    title: 'How to view the dashboard',
    content: `
<p><a href="https://app.theopenpantry.com/dashboard">https://app.theopenpantry.com/dashboard</a></p>
<p>The “Home” section of Open Pantry is also the dashboard.</p>
<p>After your 10th purchase extra features in the dashboard will become available. </p>
<p>In the top left corner of your screen, select “Dashboard”</p>
<p><img src="/images/101-1-min.png" width="300"/></p>
<p>On the Dashboard you will find quick access to options such as “Browse by Supplier” where you can view all of your suppliers and make purchases from each store, your “Pantry Lists” which are are lists you can create of your pantry staples or frequently ordered products, and your current scheduled deliveries, along with their estimated delivery dates.</p>
<p>You can filter these in order of Most Purchased, Pricing or Alphabetically as well as by ‘recently purchased’ or ‘favourites’.</p>
<p><img src="/images/101-2-min.jpeg" width="300"/></p>
`,
  },
  {
    id: 102,
    path: 'help-center',
    title: 'How to browse multiple suppliers - Browse by Category',
    content: `
<p><h3 >How to Browse by Category:</h3></p>
<p><a href="https://app.theopenpantry.com/categories">https://app.theopenpantry.com/categories</a></p>
<p> 1. Click on the “☰” icon in the top left then click <b>“Browse by Category”</b></p>
<p>
<img src="/images/102-min.png" width="300"/>
</p>
<p> 2. The browse by category menu shows your chosen categories & the relevant suppliers for each category. You can select from:</p>
<p>
<div style="width: 24%; margin-bottom: 20px; display: inline-block; min-width: 200px">
Fruits & Vegetables
</div>
<div style="width: 24%; margin-bottom: 20px; display: inline-block; min-width: 200px">
Meats
</div>
<div style="width: 24%; margin-bottom: 20px; display: inline-block; min-width: 200px">
Poultry
</div>
<div style="width: 24%; margin-bottom: 20px; display: inline-block; min-width: 200px">
Alcohol & Beverages 
</div>
<div style="width: 24%; margin-bottom: 20px; display: inline-block; min-width: 200px">
Hospitality Supplies 
</div>
<div style="width: 24%; margin-bottom: 20px; display: inline-block; min-width: 200px">
Seafood 
</div>
<div style="width: 24%; margin-bottom: 20px; display: inline-block; min-width: 200px">
Small Goods 
</div>
<div style="width: 24%; margin-bottom: 20px; display: inline-block; min-width: 200px">
Dairy
</div>
<div style="width: 24%; margin-bottom: 20px; display: inline-block; min-width: 200px">
Dry & Frozen Goods    
</div>
<div style="width: 24%; margin-bottom: 20px; display: inline-block; min-width: 200px">
Bakery
</div>
<div style="width: 24%; margin-bottom: 20px; display: inline-block; min-width: 200px">
Coffee & Tea 
</div>
</p>
<p>Note: to simplify your viewing you can change, add or delete categories in the “Suppliers and Categories” section of the menu.</p>
<p><img src="/images/102-1-min.png" width="300"/></p>
<p> 3.  Select your preferred category to start browsing products from suppliers in that category, eg. select “Fruit & Vegetables” to see fruit & vegetable suppliers & their current offerings. You can select “All Products” to view all of the products from all of the suppliers you’re connected to, in that category. Further refine your search by selecting a specific supplier. To do this,select the supplier icon, this will filter the results to products specific to only your chosen supplier.</p>
<p>Add products to cart by  clicking on  a product and clicking the “Add to cart” button.</p>
<p><img src="/images/102-2-min.png" width="300"/></p>
`,
  },
  {
    id: 103,
    path: 'help-center',
    title: 'How to View your Pantry Lists',
    content: `
<p><h3 >How to view your Pantry lists</h3></p>
<a href="https://app.theopenpantry.com/pantry_list">https://app.theopenpantry.com/pantry_list</a>
<p>1. tSelect the “☰” icon in the top left corner of the screen. Or on the bottom menu on the second left.</p>
<p><img src="/images/103-1-min.jpeg" width="300"/></p>
<p>2. Click ‘Pantry Lists’ then select ‘Pantry List’ from the dropdown menu options. </p>
<p><img src="/images/103-2-min.jpeg" width="300"/></p>
<p>3. Click the “View” button of the pantry list you'd like to preview.</p>
<p><img src="/images/103-3-min.jpeg" width="300"/></p>
<p>4. When viewing a pantry list, you can add products from your list directly to your cart by pressing the button “Add to Cart” next to each product. From here, you can click view your current shopping cart, continue shopping or simply return to view other pantry lists by clicking ‘back’. </p>
<p><img src="/images/103-4-min.jpeg" width="300"/></p>
`,
  },
  {
    id: 104,
    path: 'help-center',
    title: 'How to Create a Pantry List',
    content: `
<a href="https://app.theopenpantry.com/pantry_list">https://app.theopenpantry.com/pantry_list</a>
<p>1. Select the “☰” icon in the top left-hand corner of the screen.</p>
<p><img src="/images/104-1-min.jpeg" width="300"/></p>
<p>2. Click ‘Pantry Lists’ & select ‘Pantry List’ from the dropdown menu. </p>
<p><img src="/images/104-2-min.jpeg" width="300"/></p>
<p>3. In the top centre of the screen, click “Create Pantry List.”</p>
<p><img src="/images/104-3-min.jpeg" width="300"/></p>
<p>4. Enter a name for this Pantry List  then select “Next.”</p>
<p>
<img src="/images/104-4-min.png" width="300"/>
<img src="/images/104-4-1-min.jpeg" width="300"/>
</p>
<p>5. Add  items to the pantry list by clicking the “Add to Pantry List” option. Use the search function to easily find specific  items.</p>
<p>
<img src="/images/104-5-min.png" width="300"/>
<img src="/images/104-5-1-min.jpeg" width="300"/>
</p>
<p>6. Once you've finished adding the products you’d like in the pantry list, click “Save Pantry List” - at the top of the screen.</p>
<p>
<img src="/images/104-6-min.png" width="300"/>
<img src="/images/104-6-1-min.jpeg" width="300"/>
</p>
<p>You can preview your pantry lists here. <a href="https://app.theopenpantry.com/pantry_list">https://app.theopenpantry.com/pantry_list</a></p>
`,
  },
  {
    id: 105,
    path: 'help-center',
    title: 'How to edit a Pantry List',
    content: `
<p><h3>How to edit/rename or delete a Pantry List</h3></p>
<a href="https://app.theopenpantry.com/pantry_list/edit">https://app.theopenpantry.com/pantry_list/edit</a>
<p>1. Simply click the ‘Pen’ Icon of the pantry list you want to edit, rename or delete.</p>
<p><img src="/images/105-1-min.png" width="300"/></p>
<p>2. To edit your pantry list, click the ‘Pen’ icon, from here you can edit quantities or remove existing items from your list & add other items from suppliers by clicking on the relevant option buttons.</p>
<p><img src="/images/105-2-min.png" width="300"/></p>
<p>3. To rename a Pantry List, select “View” and then “Rename” at the top of your list to rename.</p>
<p><img src="/images/105-3-min.png" width="300"/></p>
`,
  },
  {
    id: 106,
    path: 'help-center',
    title: 'How to create a Recurring Order',
    content: `
<a href="https://app.theopenpantry.com/pantry_list/recurring_orders">https://app.theopenpantry.com/pantry_list/recurring_orders</a>
<p>1. Select the “☰” icon in the top left-hand corner of the screen.</p>
<p><img src="/images/106-1-min.jpeg" width="300"/></p>
<p>2. Go to ‘Pantry Lists’ & select  ‘Recurring orders’</p>
<p><img src="/images/106-2-min.jpeg" width="300"/></p>
<p>3. Click “Create Recurring Order”</p>
<p><img src="/images/106-3-min.jpeg" width="300"/></p>
<p>4. Enter a name for the Recurring Order, select the frequency (how often you would like it to be ordered), and the date you’d like the recurring order to commence on, then click “Next.”</p>
<p><img src="/images/106-4-min.jpeg" width="300"/></p>
<p>5. Add the items you’d like  to be included in the recurring order by clicking the “Add to Recurring Order” option. Use the search function to easily find the items you'd like to add to the order.</p>
<p><img src="/images/106-5-min.png" width="300"/></p>
<p>6. Once you've added all of the products you’d like to receive on a recurring basis,  click “Save Recurring Order.”</p>
<p><img src="/images/106-6-min.png" width="300"/></p>
<p>You can preview your recurring order list here. <a href="https://app.theopenpantry.com/pantry_list/recurring_orders">https://app.theopenpantry.com/pantry_list/recurring_orders</a></p>
<p></p>
`,
  },
  {
    id: 107,
    path: 'help-center',
    title: 'How to edit my recurring orders',
    content: `
<a href="https://app.theopenpantry.com/pantry_list/recurring_orders">https://app.theopenpantry.com/pantry_list/recurring_orders</a>
<p>1. Simply click the ‘Pencil icon of the recurring order list you’d like to edit or delete & select the relevant action.</p>
<p><img src="/images/107-1-min.jpeg" width="300"/></p>
<p>2. To edit what items are included in your recurring order, click the pencil icon to “edit.” To  delete the list, click the bin icon to “delete.” To rename the list, click “view” then select “Rename.”</p>
`,
  },
  {
    id: 108,
    path: 'help-center',
    title: 'How to see my supplier List',
    content: `
<a href="https://app.theopenpantry.com/suppliers">https://app.theopenpantry.com/suppliers</a>
<p>1. Select the “☰” icon in the top left-hand corner of the screen.</p>
<p>2. Select “Suppliers & Categories”</p>
<p><img src="/images/108-1-min.png" width="300"/></p>
<p>3. Select “Supplier List” and scroll to view your current supplier list.</p>
<p><img src="/images/108-2-min.png" width="300"/></p>
    `,
  },
  {
    id: 109,
    path: 'help-center',
    title: 'How to add a supplier',
    content: `
<a href="https://app.theopenpantry.com/suppliers/add">https://app.theopenpantry.com/suppliers/add</a>
<p>1. Select the “☰” icon in the top left-hand corner of the screen.</p>
<p>2. Select “Suppliers & Categories”</p>
<p>3. Select “Add Supplier”</p>
<p><img src="/images/109-2-min.png" width="300"/></p>
<p>4. From here you can either use the search bar or scroll through the list below to see if the supplier is already available on Open Pantry, once located select the “Add Supplier” button to request to connect.</p>
<p><img src="/images/109-3-min.png" width="300"/></p>
<p>5. If you cannot find the supplier you are looking for simply click “Invite Supplier” bar at the top of your screen, fill in the suppliers basic details & invite them to join.</p>
 <p>
<img src="/images/109-4-min.png" width="300"/>
<img src="/images/109-5-min.png" width="300"/>
</p>
    `,
  },
  {
    id: 110,
    path: 'help-center',
    title: 'How to edit the categories I see',
    content: `
<a href="https://app.theopenpantry.com/suppliers/categories">https://app.theopenpantry.com/suppliers/categories</a>
<p>1. Select the “☰” icon in the top left-hand corner of the screen.</p>
<p>2. Select “Suppliers & Categories”</p>
<p>3. Select “Categories”</p>
<p><img src="/images/110-1-min.png" width="300"/></p>
<p>4. The categories you are viewing will be highlighted in green. Deselect any categories you no longer want to see by clicking on the category name. Click “Update” to save.  </p>
 <p>
<img src="/images/110-2-min.png" width="300"/>
<img src="/images/110-3-min.png" width="300"/>
</p>
    `,
  },
  {
    id: 111,
    path: 'help-center',
    title: 'How to see my Order History',
    content: `
<a href="https://app.theopenpantry.com/orders_history">https://app.theopenpantry.com/orders_history</a>
<p>1. Click the “☰” icon on the top left corner of the screen, then click <b>“Order History” </b>
</p>
<p><img src="/images/111-1-min.png" width="300"/></p>
<p>2. Once you click <b>“Order History”</b>, all of your previous orders will show. You can filter these by category or date.</p>
<p>Please note that multiple supplier orders will be separated into an individual orders for each supplier. (For example a single order with 3 different suppliers will show as 3 separate orders.</p>
<p><img src="/images/111-2-min.png" width="300"/></p>
<p><img src="/images/111-3-min.png" width="300"/></p>
<p>Within ‘Order History’ you can see products that have been previously ordered and place those items back into your cart to re-order by pressing ‘Add to Cart’.</p>
`,
  },
  {
    id: 112,
    path: 'help-center',
    title: 'Completing an order (Checkout)',
    content: `
<a href="https://app.theopenpantry.com/browse_by_supplier ">https://app.theopenpantry.com/browse_by_supplier </a><span> &gt; </span>
<a href="https://app.theopenpantry.com/cart ">https://app.theopenpantry.com/cart </a>
<p>1. Using the ‘Browse by Supplier’ view or ‘Browse by Category’ view select and add products to your Cart. (See ‘Browse by Category’ view if you need more info on this)</p>
<p>2. Click <b>“Place Order”</b> when you are finished & ready to place your order. </p>
<p><img src="/images/112-1-min.png" width="300"/></p>
<p>3. After clicking <b>“Checkout”</b>, you will be redirected to the summary of <b>“Your Order”</b>. Here you can confirm your order and add notes. You can further select a day for delivery or use the default “Next Available Date” which will select the earliest delivery for each supplier.</p>
<p>4. Click <b>“Place Order”</b> to finalize your purchase. </p>
<p><img src="/images/112-2-min.png" width="300"/></p>
<p>If you have a credit account with the supplier the order will go straight through to the supplier and you would be expected to make payment with them directly, according to your credit terms. Alternatively, you can pay online through the Open Pantry System via credit card and the payment will be forwarded on your behalf. You can view your payment method when reviewing your order.</p>
<p><img src="/images/112-3-min.png" width="300"/></p>
`,
  },
  {
    id: 113,
    path: 'help-center',
    title: 'How to edit my name or email - My account / Profile',
    content: `
<a href="https://app.theopenpantry.com/account">https://app.theopenpantry.com/account</a>
<p>1. Click the “☰” icon on the top left corner of the screen, then click your name at the bottom of the screen.</p>
<p>2. Click ‘Profile’ to edit your profile information.</p>
<p><img src="/images/113-1-min.png" width="300"/></p>
<p><img src="/images/113-2-min.png" width="300"/></p>
<p>3. Edit any fields you'd like to update and then click “Update” to save.</p>
<p><img src="/images/113-3-min.png" width="300" /></p>
`,
  },
  {
    id: 114,
    path: 'help-center',
    title: 'How to edit my delivery address',
    content: `
<a href="https://app.theopenpantry.com/account/venue">https://app.theopenpantry.com/account/venue</a>
<p>1.  Click the “☰” icon on the top left corner of the screen, then select your name at the bottom of the screen. </p>
<p><img src="/images/114-1-min.png"  width="300"/></p>
<p>2. Select “Venue Information”</p>
<p><img src="/images/114-2-min.png"  width="300"/></p>
<p>3.   If you’d like to edit your venue/delivery address, click on your current address & fill in the details of your new delivery address. Select ‘Set as default’ for your main address & select “remove address” on previous addresses you no longer wish to use. You can add any secondary addresses you wish to receive deliveries to by selecting add new address, above your existing/main address.</p>
<p><img src="/images/114-3-min.png" width="300"/></p>
<p><img src="/images/114-4-min.png" width="300" /></p>
<p>Click "Update" at the bottom of your screen once complete to save.</p>
<p><img src="/images/114-5-min.png"  width="300"/></p>
`,
  },
  {
    id: 115,
    path: 'help-center',
    title: 'How to Add a New Delivery Address',
    content: `
<a href="https://app.theopenpantry.com/account/venue/add">https://app.theopenpantry.com/account/venue/add</a>
<p>1. Click the “☰” icon on the top left corner of the screen, then select your name at the bottom of the screen. </p>
<p><img src="/images/115-1-min.png"  width="300"/></p>
<p>2. Select “Venue Information”</p>
<p><img src="/images/115-2-min.png" width="300"/></p>
<p>3. Click “Add new Address”.</p>
<p><img src="/images/115-3-min.png" width="300"/></p>
<p>4. Enter your address details then click “Add Address” once complete.</p>
<p><img src="/images/115-4-min.png" width="300"/></p>
<p>5. You can set another address as the default by selecting ‘Set as Default’ after adding/saving the address. this will make the address your primary address upon checkout.</p>
<p><img src="/images/115-5-min.png" width="300"/></p>
`,
  },
  {
    id: 116,
    path: 'help-center',
    title: 'Email Notifications',
    content: `
<a href="https://app.theopenpantry.com/account/notifications">https://app.theopenpantry.com/account/notifications</a>
<p>1.  Click the “☰” icon on the top left corner of the screen, then select your name at the bottom of the screen. </p>
<p><img src="/images/116-1-min.png" width="300"/></p>
<p>2.  Select “Email Notifications”</p>
<p><img src="/images/116-2-min.png" width="300"/></p>
<p>3. You may change your  email notification settings by clicking the on/off buttons beside which actions you’d like to receive notifications for. . Blue = On, Grey = Off</p>
<p><img src="/images/116-3-min.png" width="300"/></p>
<p>
a) Order receipts are a copy of the Order you placed.<br/>
b) Connection requests are notifications when a supplier wants to connect with you - we recommend leaving this on.<br/>
c) Transactional Emails are day to day emails that notify of changes like back orders/ substitutions etc.<br/>
d) Promotional Emails we keep to a minimum - this is daily specials and offers we might have<br/>
e) Important emails will be a selection of email types we believe to be the bare minimum and only those of importance to you.</p>
`,
  },
  {
    id: 117,
    path: 'help-center',
    title: 'Help',
    content: `
<a href="https://app.theopenpantry.com/account/help">https://app.theopenpantry.com/account/help</a>
<p>1.  For additional help, follow the below instructions to access the FAQ’s.</p>
<p>2. Click the “☰” icon on the top left corner of the screen, then select your name at the bottom of the screen.</p>
<p><img src="/images/117-1-min.png" width="300"/></p>
<p>3. Select “Help”</p>
<p><img src="/images/117-2-min.png" width="300"/></p>
<p>4. Type the subject of what you are requiring help with into the search bar, to search the FAQ’s. Here you can find a comprehensive list of information on all common tasks you may need help with within Open Pantry. Alternatively, click the “FAQ’s” tab & scroll through the main options.  </p>
<p><img src="/images/117-3-min.png" width="300"/></p>
`,
  },
  {
    id: 118,
    path: 'help-center',
    title: 'Contact',
    content: `
<a href="https://app.theopenpantry.com/account/help/contact">https://app.theopenpantry.com/account/help/contact</a>
<p>1.  Click the “☰” icon on the top left corner of the screen & select “Help” </p>
<p><img src="/images/118-1-min.png" width="300"/></p>
<p>2.  Fill out the help form by following the title prompts.  Type a keyword(s) summarising your enquiry in the “subject” box and type further details of your enquiry in the “message” box. </p>
<p><img src="/images/118-2-min.png" width="300"/></p>
<p>Click “send message” once complete. Alternatively, you can email Open Pantry directly at <a href="mailto:info@theopenpantry.com">info@theopenpantry.com</a> or select the blue Messenger icon to contact us on Facebook Messenger Note: There may be delays in receiving a response via Messenger. For more urgent enquiries, please use the help form or email directly.</p>
<p><img src="/images/118-3-min.png" width="300" /></p>
`,
  },
  {
    id: 119,
    path: 'help-center',
    title: 'How to view my Recurring Orders',
    content: `
<p>1. Select the “☰” icon in the top left-hand corner of the screen.</p>
<p><img src="/images/106-01-min.jpeg" width="300"/></p>
<p>2. Click ‘Pantry Lists’ then select ‘Recurring orders’ from the dropdown menu in the top left corner of the screen.</p>
<p><img src="/images/106-02-min.jpeg" width="300"/></p>
<p>3. Click the “View” button of the recurring order you’d like to preview, or the pencil icon if you’d like to quickly edit the order.</p>
<p><img src="/images/106-03-min.jpeg" width="300"/></p>
<p>4. Once you've verified that the recurring order is correct, click “Save recurring order””.</p>
<p><img src="/images/106-04-min.jpeg" width="300"/></p>
`,
  },
  {
    id: 120,
    path: 'help-center',
    title: 'How to change my password',
    content: `
<a href="https://app.theopenpantry.com/account">https://app.theopenpantry.com/account</a>
<p>1. Click the “☰” icon on the top left corner of the screen, then click your name at the bottom of the screen. </p>
<p><img src="/images/113-1-min.png" width="300" /></p>
<p>2. Click ‘Profile’ and scroll to the bottom of the screen.</p>
<p><img src="/images/113-2-min.png" width="300" /></p>
<p>3. To change your password, enter your current password under “Verify password” and then enter your new password under ‘New Password’ then repeat this password in ‘Confirm new Password’. Press ‘Update’ once complete to save.</p>
<p><img src="/images/113-3-min.png" width="300" /></p>
`,
  },
  {
    id: 121,
    path: 'help-center',
    title: 'How to reset my password',
    content: `
<a href="https://app.theopenpantry.com/reset_password">https://app.theopenpantry.com/reset_password</a>
<p>1. From the homescreen of app.theopenpantry.com select “Forgot your password? Reset it here.”</p>
<p><img src="/images/121-1-min.png" width="300" /></p>
<p>2. Enter the email address of the profile you’d like to reset the password for.</p>
<p>3. Click “Send me reset instructions”</p>
<p><img src="/images/121-2-min.png" width="300" /></p>
<p>4. Check your email address for the reset prompt, select “Click Here” to be redirected to the reset page in your chosen browser. Enter your new password & select “reset password” to finalise & save.</p>
<p><img src="/images/121-3-min.png" width="300" /></p>
<p><img src="/images/121-4-min.png" width="300" /></p>
`,
  },
  {
    id: 122,
    path: 'help-center',
    title: 'How to Browse by Supplier ',
    content: `
<p><a href="https://app.theopenpantry.com/browse_by_supplier">https://app.theopenpantry.com/browse_by_supplier</a></p>
<p>1. From the dashboard select “Start Shopping” under “Browse by Supplier”.</p>
<p><img src="/images/122-min.jpeg" width="300"/></p>
<p>Please note when you pick a store, you can only see products from that store - however you can also see "Sub-categories" for that store. These are sections that will help restaurants browse. i.e Cheese - clicking on that sub-category will show all the products that have the sub-category tag "Cheese" etc </p>
`,
  },
  {
    id: 123,
    path: 'help-center',
    title: 'How to set up menu costing ',
    content: `
<p>1. Click the “☰” icon on the top-left and select the <b>“Menu costing”</b> from the menu. Make sure you have purchased this package else it won’t be available.</p>
<p><img src="/images/123-1-min.png" width="300"/></p>
<p>2. Set your menu layout. You can customise this to reflect your own menu. (e.g. Breakfast, Mains, Sides, drinks etc.) You can always add more later.  </p>
<p><img src="/images/123-2-min.png" width="300"/></p>
<p>3. Start adding your own recipes for each menu category, click on <b>‘Add New Recipe’</b> in the appropriate category.</p>
<p><img src="/images/123-3-min.png" width="300"/></p>
`,
  },
  {
    id: 124,
    path: 'help-center',
    title: 'Adding recipes ',
    content: `
<p>1. Enter the recipe name, description, sell price and other necessary information. Then, click <b>“Save”</b>.</p>
<p><img src="/images/124-1-min.png" width="300"/></p>
<p>2. Browse through the products and tap the <b>“+”</b> icon next to each ingredient to add them.</p>
<p><img src="/images/124-2-min.png" width="300"/></p>
<p>3. Click <b>“Save ingredients” </b>when done.</p>
`,
  },
  {
    id: 125,
    path: 'help-center',
    title: 'Entering the measurements ',
    content: `
<p>In the first step you are adding the amount and measure it takes to make one serving of the dish. Note this is only text and any information can be entered here.</p>
<p>1. Select your portion sizes (for a single serve) for each dish. For example, 200 grams of steak. Then, click <b>“Save”</b></p>
<p><img src="/images/125-min.png" width="300"/></p>
`,
  },
  {
    id: 126,
    path: 'help-center',
    title: 'How to set yields and waste ',
    content: `
<p>In this section we calculate the cost per serve. We calculate this by working out how many serves you can make from the purchased product (after accounting for wastage).
<br> For instance, if a 1kg steak can yield 4.5 x 200 g portions and assuming you lose about 10% on off-cuts and waste, then you should enter “4.5” which includes your 10% waste or 90% yield. 
</p>
<p><img src="/images/126-1-min.png" width="300"/></p>
<p>2. Simply apply the same process for other ingredients in the recipe.
</p>
<p>3.Click <b> “Save Changes” </b> when done.
 </p>
 <p>This will calculate your per serve price by calculating the supplier unit price divided by how many units it will make. If you dish requires multiple units to create a single serve then feel free to add fractions. For example if you require two purchase units for the dish enter 0.5.</p>
<p>After setup, you will be able to view the total cost per recipe, automatically calculated. See the example.</p>
<p><img src="/images/126-2-min.png" /></p>
`,
  },
  {
    id: 127,
    path: 'help-center',
    title: 'How to add methods to your recipe',
    content: `
<p>1. You can add methods during the setup of your menu, as prompted, or you can do so in an existing recipe by selecting the recipe. Open the specific recipe you want to  modify.
</p>
<p><img src="/images/127-1-min.png" width="300"/></p>
<p>2. Click <b>“Methods”</b>, and select <b>“Edit Steps”</b> or use the <b>“+” </b> icon to add a step.
</p>
<p>3. Upload an image and describe each step in the provided description box.
</p>
<p><img src="/images/127-2-min.png" width="300"/></p>
<p>3. Click <b>“Save”</b> when done
</p>
`,
  },
  {
    id: 128,
    path: 'help-center',
    title: 'How to add allergens and dietary requirements to your recipe',
    content: `
<p><b>Managing Allergens for your recipe:</b></p>
<p>1. Click <b>“Allergens”</b> on your recipe and select the specific allergens from the provided list.
</p>
<p>2. Click <b>“Save” </b> to update allergens for your recipe.
</p>
<p><img src="/images/128-1-min.png" width="300"/></p>
<p><b>
Managing Dietary Requirements for your recipe:
</b></p>
<p>1. Click <b>“Dietary Requirements"</b>, and type in the specific dietary requirement without any space between words. For example: lowsodium, lowfat, etc.)
</p>
<p>2. Click “Add tag” to apply the dietary requirements for your recipe.
</p>
<p><img src="/images/128-2-min.png" width="300"/></p>
`,
  },
  {
    id: 129,
    path: 'help-center',
    title: 'How to multiply your recipe',
    content: `
<p>1. Click the <b>“Multiply this Recipe”</b> button on the top-right corner.</p>
<p><img src="/images/129-1-min.png" /></p>
<p>2. Specify how many portions or batch size you would like to make. For example, if you need 100 portions for an upcoming event, enter “100.”</p>
<p>The tool will automatically calculate the ingredient quantities required for your specified batch size.</p>
<p><img src="/images/129-2-min.png" /></p>
<p>3. Simply click <b> “Add to cart”</b> and proceed to <b>“Place your order”</b>.</p>
`,
  },
  {
    id: 130,
    path: 'help-center',
    title: 'How to set up your sub-recipes ',
    content: `
<p>A “sub-recipe” or sometimes called a “prep recipe”, is a recipe used within another recipe. It can include items such as sauces for your pasta dishes or steaks, dressings for salads, syrups for desserts or mixes for cocktails. When you create a sub-recipe it is no longer for a single serve but rather the cost for a whole batch. (calculated as cost per serve at the recipe level)</p>
<p><img src="/images/130-1-min.png" width="300"/></p>
<p>
1. Add a recipe name <br>
2. Specify the amount of yield you intend to make<br>
3. Upload an image, add a description and click “Next”. <br>
4. Click the <b>“+”</b> icon next to each product to add in the ingredients.
</p>
<p><img src="/images/130-2-min.png" width="300"/></p>
<p>5. Specify the amount of the product used in your recipe, considering the total yield after wastage.</p>
<p>
<img src="/images/130-3-min.png" width="300"/>
<img src="/images/130-3-1.png" width="300"/>
</p>
<p>6. Click <b>“Save”</b> for each ingredient and proceed to the next.
<br>
After setup, you will be able to view the total cost per sub-recipe, automatically calculated.
</p>
<p><img src="/images/130-4-min.png" /></p>
`,
  },
  {
    id: 131,
    path: 'help-center',
    title: 'How to add a sub-recipe to a main recipe ',
    content: `
<p>1. Navigate to the Menu tab and select the specific recipe you want to edit.</p>
<p>2. Under <b>“Ingredients”</b> click <b>“+Add new ingredient”</b>.
</p>
<p><img src="/images/131-1-min.png" width="300"/></p>
<p>3. Select <b>“Sub-recipes”</b> and choose the sub-recipe you wish to include in the main recipe (for example, steak sauce).
</p>
<p><img src="/images/131-2-min.png" width="300"/></p>
<p>4. Click <b>“Save Ingredients”</b></p>
<p>5. Indicate the quantity of the product used in this recipe (e.g., 50 mls)</p>
<p><img src="/images/131-3-min.png" width="300"/></p>
<p>6. Specify the amount of portions you can make from this product after accounting for wastage (e.g., 100).</p>
<p><img src="/images/131-4-min.png" width="300"/></p>
<p>7. Click <b>"Save"</b></p>
<p>
The cost per serving of the added sub-recipe will be automatically factored into your total cost.</p>
<p><img src="/images/131-5-min.png"/></p>
`,
  },
  {
    id: 132,
    path: 'help-center',
    title: 'How to set up Automatic Ordering',
    content: `
<p>1. Click “☰” on the top-left and go to Restaurant Pro Suite in the menu. Then select <b>“Automated Ordering”</b>.</p>
<p><img src="/images/132-1-min.png" width="300"/></p>
<p>2. Integrate sales data from your Point of Sale System (POS), upload it using a menu items spreadsheet, or manually enter the quantity sold for each menu item.</p>
<p><img src="/images/132-2-min.png" width="300" /></p>
<p>3.Enter the quantity sold of each menu item and upload the file <br>
4. Add any “waste” items manually (e.g. 1 carton of beer bottles was broken) or extract the information from your POS <br>
5. <b>“Save”</b> and <b>“continue”</b><br>
6. Add necessary items you need to replenish stocks to “par levels” from your suppliers to your cart
</p>
`,
  },
  {
    id: 133,
    path: 'help-center',
    title: 'How to set up inventory',
    content: `
<p>Once you have added your menu items in the menu costing section, you can now set up your inventory to help you automate inventory tracking – increases as you purchase products, and decreases as you sell menu items.</p>
<p><img src="/images/133-1-min.png" width="300"/></p>
<p>1. Perform an initial stock count for your ingredients and enter your current balance in the designated field. Enter this into the BALANCE section. <br>
<p>2. Next, specify your par level, indicating the desired quantity of products you should have on stock. </p>
<p>3. Add a trigger to set an alert for low stock items and be notified to purchase stocks when you go below these levels. </p>
<p>4. Click <b>"Save"</b></p>
</p>
<p><img src="/images/133-2-min.png" width="300"/></p>
<p>5. You can also set where the product is stored. Simply create or choose the location, add the shelf and the position. Utilise this to replicate your storage area. For best results use the list view.
<p>6. Click <b>"Save"</b></p>
</p>
`,
  },
  {
    id: 134,
    path: 'help-center',
    title: 'How to check off for your orders | Increasing stock',
    content: `
<p>When you receive orders, you can set automatic check off or manually check off orders as you receive them. This process adds the goods you purchase to your inventory. It also doubles as a way to send a credit request to your suppliers. </p>
<p>Here’s how to set up automatic check off</p>
<p>1. Click “☰” on the top-left corner and go to Order history and click on the Edit icon.
<br>2. Choose your desired schedule.
</p>
<p><img src="/images/134-1-min.png" width="300"/></p>
<p>To set it manually, choose “Off” from the schedule options. 
</p>
<p>When you receive your order, manually confirm your orders. Here’s how: <br>
1.Click the “Check off: button in the right corner.</p>
<p><img src="/images/134-2-min.png" /></p>
<p>2. Tick on the “✓” or “X” button to confirm your order and click “Save.
</p>
<p>For any delivery issue, you can send a credit request to your suppliers directly. Here’s how: <br>
1. Choose the specific reason on the “reason” field and add notes. (Example: Wrong item. item(s) missing, etc.)
<br>
2. Indicate the quantity of products that has an issue in the field provided.
<br>
3. Add your notes and click “Save notes”.

</p>
<p><img src="/images/134-3-min.png" /></p>
<p>4. Review all details, and click, “Save”.
</p>
<p><img src="/images/134-4-min.png" /></p>
`,
  },
  {
    id: 135,
    path: 'help-center',
    title: 'How to add sales',
    content: `
<p>1. Click the “☰” icon on the top left corner of the screen, go to Restaurant Pro Suite, and then Sales & Waste”</p>
<p><img src="/images/135-1-min.png"/></p>
<p>2. Under the “Sales” tab, you can either download the sales template to enter and upload your daily sales or import the data through POS integration. Contract Open Pantry direct to set up a POS integration before using it.
</p>
<p><img src="/images/135-2-min.png"/></p>
<p><img src="/images/135-3-min.png"/></p>
<p>3. You can also input your sales manually through the platform by adding the required information such as the Date, Product Name, Product ID, Quantity sold and price.
</p>
<p><img src="/images/135-4-min.png"/></p>
<p>5. Click “Add” when done.
</p>
`,
  },
  {
    id: 136,
    path: 'help-center',
    title: 'How to add wastage',
    content: `
<p>1. Click the “☰” icon on the top left corner of the screen, go to Restaurant Pro Suite, and then Sales & Waste”</p>
<p><img src="/images/136-1-min.png"/></p>
<p>2.  On the “Wastage” tab, enter the details such as the Date, Product/Recipe, Product ID, Quantity, Reason and Reporter’s name.

</p>
<p><img src="/images/136-2-min.png"/></p>
<p>3. Click “Add” when done.
</p>
`,
  },
  {
    id: 137,
    path: 'help-center',
    title: 'How to use reporting',
    content: `
<p>After importing your sales and wastage data, you can utilize the reporting feature to see your restaurant’s profit drivers and drainers and get in-depth insights all the way to the individual dishes and beverages.</p>
<p>1. Click the “☰” icon on the top left corner of the screen, go to ‘Restaurant Pro Suite’, and then ‘Reporting’”
</p>
<p><img src="/images/137-1-min.png"/></p>
<p>2.  Customize your desired report by navigating to the “Sales” or “Wastage” tab. <br>  
3. Choose the specific time period you would like to review. 
</p>
<p><img src="/images/137-2-min.png"/></p>
<p>In the sales report, you get an overview of sales, profit and cost. Dive deeper to analyse total sales, profits and costs categorized by menu item. Export reports by clicking the export button in the top right corner and select your report.
</p>
<p><img src="/images/137-3-min.png"/></p>
<div>You can also gain insights of your restaurant’s wastage on the ‘Wastage’ report tab.
</div>
<p><img src="/images/137-4-min.png"/></p>
`,
  },
  {
    id: 138,
    path: 'help-center',
    title: 'How to add self managed supplier',
    content: `
<p>You can also add your self managed supplier to your supplier list. Here’s how:</p>
<p>1. Select the “☰” icon in the top left-hand corner of the screen. <br>
2. Select “Browse by Supplier”
</p>
<p><img src="/images/138-1-min.png" width="300"/></p>
<p>3. Click on “Invite Your Supplier” at the top of the page.

</p>
<p><img src="/images/138-2-min.png" width="300"/></p>
<p>4. Click on the “+” icon on the top-right corner, and select “Add manually managed supplier”</p>
<div><img src="/images/138-3-min.png" width="300"/> <img src="/images/138-4-min.png" width="300" style="margin-bottom: -11px"/></div>
<div>5. Fill in the supplier’s business name along with the supplier’s basic details such as email, logo, delivery days, and prices with the supplier. 
</div>
<p><img src="/images/138-5-min.png" width="300"/></p>
`,
  },
];
