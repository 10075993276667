import React, { useEffect, useState } from 'react';

import clsx from 'clsx';

import { useUpdateSupplierProfileMutation } from '../../../../api/company';
import { SupplierProfile } from '../../../../api/company/types';
import SvgUploadPhoto from '../../../../assets/icons/UploadPhoto';
import { ThemedButton } from '../../../../shared/components/themed-button';
import { ImageUpload } from '../../../../shared/components/image-upload';
import { OverlayModal } from '../../../../shared/components/overlay-modal';
import { setGtmDatalayer } from '../../../../shared/helpers/setGtmDatalayer';
import { ProfileModals } from '../index';

import { useStyles } from './style';

interface Props {
  picture_url?: string;
  onClose: () => void;
  onSubmit: (data: Partial<SupplierProfile>) => void;
  isModalOpen: boolean;
  isOnBoarding: boolean;
  setNextStep: (step: ProfileModals) => void;
  companyId?: number;
}

export const UploadLogo: React.FC<Props> = ({ picture_url, onClose, companyId, onSubmit, isModalOpen, isOnBoarding, setNextStep }) => {
  const classes = useStyles();

  const [isImageUploadShown, changeIsImageUploadShown] = useState(false);
  const [logo, setLogo] = useState<Blob | null>(null);

  const [updateProfile, { isSuccess, isLoading }] = useUpdateSupplierProfileMutation();

  const handleSubmit = () => {
    if (isOnBoarding) {
      logo && onSubmit({ image: logo, picture_url: URL.createObjectURL(logo) });
      setNextStep(ProfileModals.COMPANY_DETAILS);
    } else if (logo && companyId) {
      setGtmDatalayer({
        event: 'profile',
        eventCategory: 'upload_photo',
      });
      const formData = new FormData();
      formData.append('company[picture]', logo);
      updateProfile({ formData, id: companyId });
    }
  };

  const closeHandler = () => {
    isOnBoarding ? setNextStep(ProfileModals.BUSINESS_DETAILS) : onClose();
  };

  const onUploadLogo = (file: Blob) => {
    setLogo(file);
    changeIsImageUploadShown(false);
  };

  useEffect(() => {
    if (!isModalOpen) {
      changeIsImageUploadShown(false);
      setLogo(null);
    }
  }, [isModalOpen]);

  useEffect(() => {
    isSuccess && onClose();
  }, [isSuccess]);

  return (
    <OverlayModal isOpen={isModalOpen} onClose={onClose} title='UPLOAD YOUR LOGO' animate={!isOnBoarding}>
      <div className={classes.container}>
        <div className={classes.uploadBox}>
          {isImageUploadShown ? (
            <ImageUpload
              saveImage={onUploadLogo}
              closeModal={isOnBoarding ? changeIsImageUploadShown.bind(null, false) : onClose}
              skipCloseOnSave={true}
            />
          ) : (
            <>
              <div className={clsx([classes.imgWrap])}>
                {picture_url || logo ? (
                  <>
                    <img src={logo ? URL.createObjectURL(logo) : picture_url} alt='logo' />
                  </>
                ) : (
                  <div className={classes.uploadPhoto}>
                    <SvgUploadPhoto />
                  </div>
                )}
              </div>
              <ThemedButton
                onClick={changeIsImageUploadShown.bind(null, true)}
                title='Upload a photo'
                buttonStyle='primary'
                customClass={classes.uploadButton}
              />
            </>
          )}
        </div>
        {!isImageUploadShown && (
          <div className={classes.btnBlock}>
            <ThemedButton title={isOnBoarding ? 'Back' : 'Cancel'} onClick={closeHandler} buttonStyle='secondary' />
            <ThemedButton
              title={isOnBoarding ? 'Next' : 'Update'}
              onClick={handleSubmit}
              buttonStyle='primary'
              disabled={(!logo && !isOnBoarding) || isLoading}
              customClass={classes.saveBtn}
            />
          </div>
        )}
      </div>
    </OverlayModal>
  );
};
