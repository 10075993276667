import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';
import { Sizes } from '../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: 512,
      height: 195,
      backgroundColor: colorVariables.white,
      marginTop: 40,
      marginBottom: 60,
      borderRadius: 27,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 30,
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: 160,
        height: 160,
        padding: 0,
        position: 'relative',
        boxShadow: '2px 2px 21px 3px rgba(12, 82, 112, 0.08)',
        marginBottom: 120,
      },
    },
    uploadLogoContainer: {
      border: '1px dashed #152144',
      borderRadius: 12,
      margin: '15px auto',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 30,
      [theme.breakpoints.down(Sizes.desktop)]: {
        border: 'none',
        margin: 0,
      },
    },
    uploadIcon: {
      width: 60,
      height: 60,
    },
    uploadDescription: {
      fontSize: 14,
      color: colorVariables.navy,
      marginTop: 10,
      fontWeight: 700,
      [theme.breakpoints.down(Sizes.desktop)]: {
        display: 'none',
      },
    },
    uploadDescriptionBtn: {
      textDecoration: 'underline',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    cropTitle: {
      fontSize: 16,
      marginBottom: 10,
    },
    cropContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      maxHeight: 500,
      overflow: 'scroll',
      [theme.breakpoints.down(Sizes.mobile)]: {
        maxHeight: 300,
      },
      '& img': {
        width: '100%',
        maxWidth: 450,
        [theme.breakpoints.down(Sizes.desktop)]: {
          maxWidth: 350,
        },
        [theme.breakpoints.down(Sizes.mobile)]: {
          maxWidth: 250,
        },
      },
    },
    btnBlock: {
      width: '100%',
      justifyContent: 'center',
      display: 'flex',
      marginTop: 30,
    },
    boldText: {
      fontWeight: 700,
    },
    topBox: {
      display: 'flex',
      width: '100%',
      marginBottom: 5,
      justifyContent: 'space-between',
    },
    previewContainer: {
      position: 'relative',
      width: 300,
      height: 300,
      marginBottom: 20,
      '& img': {
        width: '100%',
      },
    },
    uploadAnother: {
      marginBottom: 40,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    modal: {
      width: 450,
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '86vw',
        maxHeight: '75vh',
        maxWidth: '100vw',
      },
    },
    mobileUploadBtn: {
      display: 'none',
      [theme.breakpoints.down(Sizes.desktop)]: {
        display: 'flex',
        position: 'absolute',
        width: 177,
        bottom: -65,
        alignItems: 'center',
      },
    },
    uploadMobileTExt: {
      fontWeight: 700,
      textTransform: 'uppercase',
      marginLeft: 10,
    },
  }),
);
