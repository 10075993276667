import { getSalesQueryParamsHelper, setSuccessToast } from '../helpers';
import { HTTP, rootApi, SuccessResponse } from '../index';
import {
  CreateSaleReq,
  CreateSaleRes,
  ExportSaleResponse,
  ExportSalesParams,
  GetSalesReqParams,
  GetSalesResponse,
  SalesGraphParams,
  SalesReportRes,
  SyncSalesRes,
  UpdateSaleReq,
} from './types';

export const salesApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getSales: builder.query<GetSalesResponse, GetSalesReqParams>({
      query: (args) => {
        const params = {
          ...getSalesQueryParamsHelper(args),
          page: args.page,
          per_page: 20,
        };
        return {
          url: `/sales`,
          method: HTTP.GET,
          params,
        };
      },
      keepUnusedDataFor: 0,
      providesTags: ['Sales'],
    }),

    getAllSales: builder.query<GetSalesResponse, void>({
      query: () => {
        const params = {
          page: 1,
          per_page: 1,
        };
        return {
          url: `/sales`,
          method: HTTP.GET,
          params,
        };
      },
      providesTags: ['Sales'],
    }),

    createSale: builder.mutation<CreateSaleRes, CreateSaleReq>({
      query: (body) => {
        return {
          url: `/sales`,
          method: HTTP.POST,
          body,
        };
      },
      invalidatesTags: ['Sales', 'Inventories', 'Run_Out_Product'],
    }),

    updateSale: builder.mutation<CreateSaleRes, UpdateSaleReq>({
      query: ({ id, ...body }) => {
        return {
          url: `/sales/${id}`,
          method: HTTP.PATCH,
          body,
        };
      },
      invalidatesTags: ['Inventories', 'Run_Out_Product'],
    }),

    deleteSale: builder.mutation<SuccessResponse, number>({
      query: (id) => {
        return {
          url: `/sales/${id}`,
          method: HTTP.DELETE,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'Sale has been deleted');
      },
      invalidatesTags: ['Inventories', 'Run_Out_Product'],
    }),

    importSales: builder.mutation<SuccessResponse, FormData>({
      query: (body) => {
        return {
          url: `/import_sales`,
          method: HTTP.POST,
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'Sales have been imported');
      },
      invalidatesTags: ['Sales', 'Inventories', 'Run_Out_Product'],
    }),

    exportSales: builder.mutation<ExportSaleResponse, ExportSalesParams | void>({
      query: (params) => {
        return {
          url: `/export_sales`,
          method: HTTP.POST,
          params,
        };
      },
    }),

    exportSalesPdf: builder.query<{ pdf_file?: string; success: boolean }, ExportSalesParams | void>({
      query: (params) => {
        return {
          url: `/sales/export_sales_pdf`,
          method: HTTP.GET,
          params,
        };
      },
      keepUnusedDataFor: 0,
    }),

    getSalesReport: builder.query<SalesReportRes, SalesGraphParams>({
      query: (params) => {
        return {
          url: `/reports/sales`,
          method: HTTP.GET,
          params,
        };
      },
      keepUnusedDataFor: 0,
      providesTags: ['Sales'],
    }),

    syncSales: builder.mutation<SyncSalesRes, { auto_pos_fetch: boolean; pos_sync_time?: string }>({
      query: (user) => {
        return {
          url: `/sales/sync_pos_sales`,
          method: HTTP.PATCH,
          body: { user },
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'POS settings have been updated');
      },
      invalidatesTags: ['Me'],
    }),
  }),

  overrideExisting: true,
});

export const {
  useGetSalesQuery,
  useCreateSaleMutation,
  useUpdateSaleMutation,
  useDeleteSaleMutation,
  useExportSalesMutation,
  useImportSalesMutation,
  useGetSalesReportQuery,
  useGetAllSalesQuery,
  useLazyExportSalesPdfQuery,
  useSyncSalesMutation,
} = salesApi;
