import { createReducer } from '@reduxjs/toolkit';

import { SuppliersSortBy } from '../../../api/admin-suppliers/types';
import { logoutThunk } from '../../user';
import {
  resetASuppliersParams,
  setASuppliersKeyword,
  setASuppliersPage,
  setASuppliersSort,
  setSelectedSuppliers,
} from './suppliers.actions';

interface State {
  sortBy?: SuppliersSortBy;
  keyword?: string;
  currentPage: number;
  selectedUsers: number[];
}

const initialState: State = {
  sortBy: undefined,
  keyword: undefined,
  currentPage: 1,
  selectedUsers: [],
};

export const suppliersReducer = createReducer(initialState, (builder) => {
  builder.addCase(logoutThunk.fulfilled, () => {
    return initialState;
  });
  builder.addCase(setASuppliersKeyword, (state, { payload }) => {
    if (state.keyword === payload) {
      return;
    }
    state.keyword = payload;
    state.currentPage = 1;
  });
  builder.addCase(setASuppliersPage, (state, { payload }) => {
    state.currentPage = payload;
    state.selectedUsers = [];
  });
  builder.addCase(setSelectedSuppliers, (state, { payload }) => {
    state.selectedUsers = payload;
  });
  builder.addCase(setASuppliersSort, (state, { payload }) => {
    state.sortBy = payload.sort;
    state.currentPage = 1;
  });
  builder.addCase(resetASuppliersParams, () => {
    return initialState;
  });
});
