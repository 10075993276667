import * as React from 'react';

function SvgSupplier(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 64 65' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M26.379 5.222c0 .776 1.739 1.407 3.883 1.407 2.146 0 3.883-.63 3.883-1.407V2.406c0 .776-1.737 1.407-3.883 1.407-2.144 0-3.883-.631-3.883-1.407v2.816z'
        stroke='#51D9B0'
        strokeWidth={1.7}
        strokeMiterlimit={10}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M30.262 3.813c2.144 0 3.883-.63 3.883-1.407C34.145 1.63 32.406 1 30.262 1c-2.145 0-3.883.63-3.883 1.406 0 .777 1.738 1.407 3.883 1.407zM15.204 17.567v-.005a4.244 4.244 0 001.652-.329h.004a4.307 4.307 0 001.401-.934l.002.002-.002-.002a4.307 4.307 0 01-1.4.934h-.005a4.244 4.244 0 01-1.651.33v.004zM15.575 14.127a6.07 6.07 0 013.28-7.93 6.07 6.07 0 01-3.28 7.93z'
        stroke='#51D9B0'
        strokeWidth={1.7}
        strokeMiterlimit={10}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.856 17.235l-1.497-3.616a6.23 6.23 0 000 0l1.497 3.616zM50.912 18.945a12.195 12.195 0 010 0zM51.787 13.062a8.153 8.153 0 010 0zM52.797 28.729l-5.195-5.198L42.4 28.73l-5.2-5.198-5.202 5.198-5.199-5.198-5.201 5.198L16.4 23.53 11.2 28.73 6 23.53 9.466 63.4h45.066L58 23.531l-5.203 5.198z'
        stroke='#51D9B0'
        strokeWidth={1.7}
        strokeMiterlimit={10}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M37.056 16.836v.003h.012c.516.52.934 1.142 1.218 1.83.276.663.429 1.39.429 2.154v4.223l3.685 3.683 5.202-5.198H47.6h.002l5.195 5.198.503-.499c4.37-10.875 3.852-21.288-1.226-23.392-4.398-1.822-10.777 3.18-15.395 11.542.121.155.234.317.374.459l.003-.003z'
        stroke='#51D9B0'
        strokeWidth={1.7}
        strokeMiterlimit={10}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M29.816 25.459c-.107.252-.242.484-.37.718l2.551 2.552L37.2 23.53l1.515 1.515v-4.223c0-.764-.153-1.49-.428-2.154a5.688 5.688 0 00-1.218-1.83h-.013v-.003l-.002.003a5.659 5.659 0 01-1.223-1.825l.003-.01-.003-.014-.014.014a21.89 21.89 0 01-1.673-8.417v-4.17c-.015.774-1.746 1.397-3.883 1.397-2.144 0-3.883-.631-3.883-1.407h-.002v4.18c0 2.323-.387 4.648-1.125 6.881 4.426 2.148 6.47 7.386 4.566 11.992z'
        stroke='#51D9B0'
        strokeWidth={1.7}
        strokeMiterlimit={10}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.93 25.46l3.269 3.268L16.4 23.53l5.198 5.198L26.8 23.53l2.646 2.647c.128-.234.263-.466.37-.718a9.364 9.364 0 00-5.066-12.23v-.005a5.985 5.985 0 00-4.583-.002l-.003.009a5.958 5.958 0 00-1.943 1.3l-.004-.008a5.99 5.99 0 01-1.942 1.297h-.004a5.95 5.95 0 01-2.291.46l.004.006a5.928 5.928 0 00-2.293.454l-.01-.002a5.966 5.966 0 00-3.239 3.242l.007.004a9.303 9.303 0 00-.52 5.477z'
        stroke='#51D9B0'
        strokeWidth={1.7}
        strokeMiterlimit={10}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default SvgSupplier;
