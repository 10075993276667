import React, { MouseEvent, useEffect, useState } from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { KeyboardArrowDown } from '@material-ui/icons';
import clsx from 'clsx';
import { format, isSameDay, subDays, subMonths } from 'date-fns';

import { DateRangeComponent, IDateRange } from '../date-range';

import { useStyles } from './style';

interface Props {
  triggerBtn?: React.ReactNode;
  triggerClass?: string;
  defaultRange?: RangeOptions;
  hiddenOptions?: RangeOptions[];
  setRange?: (range: RangeOptions) => void;
  setDateRange?: (dateRange: Omit<IDateRange, 'key'>) => void;
  minDate?: Date;
  maxDate?: Date;
  triggerDateFormat?: string;
  onOpen?: () => void;
  onClose?: () => void;
  allTimeStartDate?: string;
  outSideRange?: Omit<IDateRange, 'key'>;
  skipDateUpdate?: boolean;
}

export enum RangeOptions {
  TODAY = 'Today',
  LAST_7 = 'Last 7 days',
  LAST_28 = 'Last 28 days',
  LAST_60 = 'Last 60 days',
  LAST_90 = 'Last 90 days',
  LAST_6M = 'Last 6 months',
  LAST_12M = 'Last 12 months',
  ALL_TIME = 'All time',
  CUSTOM = 'Custom date range',
}

export const RangeDropdown: React.FC<Props> = ({
  triggerBtn,
  defaultRange,
  hiddenOptions = [],
  setRange,
  setDateRange,
  minDate,
  maxDate,
  triggerClass = '',
  triggerDateFormat = 'MMM d,y',
  onOpen,
  onClose,
  allTimeStartDate,
  outSideRange,
  skipDateUpdate,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRange, setSelectedRange] = useState<RangeOptions | null>(null);
  const [selectedDateRange, setSelectedDateRange] = useState<Omit<IDateRange, 'key'>>({});

  const rangeOptions = Object.values(RangeOptions).filter((opt) => !hiddenOptions?.includes(opt));

  const selectRange = (option: RangeOptions | null) => {
    option !== RangeOptions.CUSTOM && setSelectedRange(option);
    setRange && option && setRange(option);
    switch (option) {
      case RangeOptions.TODAY: {
        setSelectedDateRange({
          startDate: new Date(),
          endDate: new Date(),
        });
        handleClose();
        break;
      }
      case RangeOptions.LAST_7: {
        setSelectedDateRange({
          startDate: subDays(new Date(), 7),
          endDate: new Date(),
        });
        handleClose();
        break;
      }
      case RangeOptions.LAST_28: {
        setSelectedDateRange({
          startDate: subDays(new Date(), 28),
          endDate: new Date(),
        });
        handleClose();
        break;
      }
      case RangeOptions.LAST_60: {
        setSelectedDateRange({
          startDate: subDays(new Date(), 60),
          endDate: new Date(),
        });
        handleClose();
        break;
      }
      case RangeOptions.LAST_90: {
        setSelectedDateRange({
          startDate: subDays(new Date(), 90),
          endDate: new Date(),
        });
        handleClose();
        break;
      }
      case RangeOptions.LAST_6M: {
        setSelectedDateRange({
          startDate: subMonths(new Date(), 6),
          endDate: new Date(),
        });
        handleClose();
        break;
      }
      case RangeOptions.LAST_12M: {
        setSelectedDateRange({
          startDate: subMonths(new Date(), 12),
          endDate: new Date(),
        });
        handleClose();
        break;
      }
      case RangeOptions.ALL_TIME: {
        setSelectedDateRange({
          startDate: allTimeStartDate ? new Date(allTimeStartDate) : subDays(new Date(), 750),
          endDate: new Date(),
        });
        handleClose();
        break;
      }
      case RangeOptions.CUSTOM: {
        setSelectedDateRange(outSideRange || {});
        break;
      }
      default:
        return;
    }
  };

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSetCustomRange = ({ key, ...data }: IDateRange) => {
    if (data.startDate && data.endDate) {
      setSelectedRange(RangeOptions.CUSTOM);
      setSelectedDateRange(data);
    }
    handleClose();
  };

  const onPickerClose = () => {
    selectRange(defaultRange || rangeOptions[0]);
  };

  useEffect(() => {
    !!anchorEl && onOpen && onOpen();
    !anchorEl && onClose && onClose();
  }, [anchorEl]);

  useEffect(() => {
    if (defaultRange !== RangeOptions.CUSTOM) {
      selectRange(defaultRange || null);
    } else if (defaultRange === RangeOptions.CUSTOM && outSideRange) {
      setSelectedRange(RangeOptions.CUSTOM);
      setSelectedDateRange(outSideRange);
    }
  }, [defaultRange, !!outSideRange]);

  useEffect(() => {
    if (
      selectedDateRange.startDate &&
      selectedDateRange.endDate &&
      isSameDay(selectedDateRange.startDate, selectedDateRange.endDate) &&
      hiddenOptions?.includes(RangeOptions.TODAY)
    ) {
      selectRange(defaultRange || rangeOptions[0]);
      return;
    }
    if (skipDateUpdate) {
      setDateRange && selectedRange === RangeOptions.CUSTOM && setDateRange(selectedDateRange);
      return;
    }
    setDateRange && setDateRange(selectedDateRange);
  }, [selectedDateRange]);

  return (
    <div className={classes.root}>
      <div onClick={handleClick} className={classes.triggerWrap}>
        {triggerBtn}
        <div className={clsx([classes.trigger, triggerClass])}>
          {selectedRange === RangeOptions.CUSTOM &&
          (selectedDateRange.startDate || outSideRange?.startDate) &&
          (selectedDateRange.endDate || outSideRange?.endDate)
            ? isSameDay(
                selectedDateRange.startDate || (outSideRange?.startDate as Date),
                selectedDateRange.endDate || (outSideRange?.endDate as Date),
              )
              ? format(selectedDateRange.endDate || (outSideRange?.endDate as Date), triggerDateFormat)
              : `${format(selectedDateRange.startDate || (outSideRange?.startDate as Date), triggerDateFormat)} - ${format(
                  selectedDateRange.endDate || (outSideRange?.endDate as Date),
                  triggerDateFormat,
                )}`
            : selectedRange}
          <KeyboardArrowDown className={classes.arrow} />
        </div>
      </div>
      <Menu
        autoFocus={false}
        anchorEl={anchorEl}
        disableScrollLock={true}
        keepMounted={true}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ paper: classes.container }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <div className={classes.rangeName}>{selectedRange}</div>
        {selectedDateRange.startDate && selectedDateRange.endDate && (
          <div className={classes.rangeDates}>
            {isSameDay(selectedDateRange.startDate, selectedDateRange.endDate)
              ? format(selectedDateRange.startDate, 'MMM d,y')
              : `${format(selectedDateRange.startDate, 'MMM d,y')} - ${format(selectedDateRange.endDate, 'MMM d,y')}`}
          </div>
        )}
        {rangeOptions.map((value, idx) => {
          return value === RangeOptions.CUSTOM ? (
            <DateRangeComponent
              clearOnApply={true}
              minDate={minDate}
              maxDate={maxDate}
              onPickerClose={onPickerClose}
              alignBottom={true}
              key={idx}
              trigger={
                <MenuItem classes={{ root: clsx([classes.dropdownItem, classes.customRangeItem]) }} onClick={selectRange.bind(null, value)}>
                  {value}
                </MenuItem>
              }
              onDateChange={onSetCustomRange}
            />
          ) : (
            <MenuItem classes={{ root: classes.dropdownItem }} key={idx} onClick={selectRange.bind(null, value)}>
              {value}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};
