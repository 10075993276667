import React from 'react';

import { Overlay } from '../../../shared/components/overlay';
import { useScreenSize } from '../../../shared/hooks/use-screen-size';
import { useAppDispatch, useAppSelector } from '../../../store';
import { closeAccountMenu, closeMainMenu, getIsAccountMenuOpened, getIsMainMenuOpened } from '../../../store/user';
import { getIsRestaurant } from '../../../store/user';
import { BottomBar } from './bottom-bar';
import { LeftNavBar } from './left-nav-bar';

interface Props {
  isAccount?: boolean;
}

export const MainNavBar: React.FC<Props> = ({ isAccount }) => {
  const { isMobile, isDesktop } = useScreenSize();
  const dispatch = useAppDispatch();

  const isMainMenuOpened = useAppSelector(getIsMainMenuOpened);
  const isAccountMenuOpened = useAppSelector(getIsAccountMenuOpened);
  const isRestaurant = useAppSelector(getIsRestaurant);

  const closeMenus = () => {
    isAccountMenuOpened && dispatch(closeAccountMenu());
    dispatch(closeMainMenu());
  };

  return (
    <>
      {((isAccount && !isDesktop) || !isAccount || isRestaurant) && <LeftNavBar />}
      {isMobile && <BottomBar isRestaurant={isRestaurant} />}
      {isMainMenuOpened && (!isDesktop || isRestaurant) && <Overlay onClick={closeMenus} />}
    </>
  );
};
