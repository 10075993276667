import { TokenService } from '../../shared/services/token.service';
import { setAppErrorToast, setAppSuccessToast } from '../../store/user';
import { getCustomersListQueryParamsHelper, setSuccessToast } from '../helpers';
import { HTTP, rootApi, SuccessResponse } from '../index';
import { ConnectionRequest, CustomerListResponse } from '../user/types';
import {
  CreateCustomerRequest,
  CreateCustomerResponse,
  CreateSocialCustomersRequest,
  ExportCustomersResponse,
  GetCustomerListReqParams,
  GetSocialContactsParams,
  GetSocialContactsResponse,
  PreviewCustomersResponse,
  UpdateCustomerRequest,
} from './types';

export const customerApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getCustomerList: builder.query<CustomerListResponse, GetCustomerListReqParams | void>({
      query: (args) => {
        const userId = TokenService.getUserId();
        const params = {
          ...getCustomersListQueryParamsHelper(args?.sort, args?.filter, args?.keyword),
          per_page: args?.perPage || 30,
          page: args?.currentPage || 1,
        };
        return {
          url: `/users/${userId}/customers`,
          method: HTTP.GET,
          params,
        };
      },
      keepUnusedDataFor: 0,
      providesTags: ['Customer'],
    }),

    getCustomerById: builder.query<ConnectionRequest, number>({
      query: (customer_id) => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/customers/${customer_id}/edit`,
          method: HTTP.GET,
        };
      },
      providesTags: ['Customer'],
    }),

    fetchSocialContacts: builder.query<GetSocialContactsResponse, GetSocialContactsParams>({
      query: (params) => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/customers/fetch_social_customers`,
          method: HTTP.GET,
          params,
        };
      },
      keepUnusedDataFor: 0,
    }),

    fetchSocialOutlookContacts: builder.query<GetSocialContactsResponse, GetSocialContactsParams>({
      query: (params) => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/customers/fetch_social_customers`,
          method: HTTP.GET,
          params,
        };
      },
      keepUnusedDataFor: 0,
    }),

    createSocialCustomers: builder.mutation<SuccessResponse, CreateSocialCustomersRequest>({
      query: (body) => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/customers/create_social_customers`,
          method: HTTP.POST,
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'Customers invited');
      },
      invalidatesTags: ['Customer', 'Customers_Count', 'Connection'],
    }),

    createCustomer: builder.mutation<CreateCustomerResponse, CreateCustomerRequest>({
      query: (body) => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/customers`,
          method: HTTP.POST,
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          if (!response.data.success) {
            return;
          }
          if (response.data.already_connected) {
            dispatch(setAppErrorToast('You are already connected with this customer'));
          } else {
            dispatch(setAppSuccessToast('Added New Customer'));
          }
        } catch (e) {
          console.log(e);
        }
      },
      invalidatesTags: ['Customer'],
    }),

    deleteCustomer: builder.mutation<SuccessResponse, number>({
      query: (customerId) => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/customers/${customerId}`,
          method: HTTP.DELETE,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'The Customer has been deleted');
      },
      invalidatesTags: ['Customer', 'Customers_Count', 'Connection'],
    }),

    setMinimumDelivery: builder.mutation<SuccessResponse, number>({
      query: (customerId) => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/customers/${customerId}/minimum_delivery_apply`,
          method: HTTP.PATCH,
        };
      },
    }),

    previewExportedCustomers: builder.mutation<PreviewCustomersResponse, FormData>({
      query: (body) => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/customers/preview_xls_data`,
          method: HTTP.POST,
          body,
        };
      },
    }),

    importCustomers: builder.mutation<SuccessResponse, FormData>({
      query: (body) => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/customers/import_xls_data`,
          method: HTTP.POST,
          body,
        };
      },
      invalidatesTags: ['Customer', 'Customers_Count', 'Connection'],
    }),

    updateCustomer: builder.mutation<SuccessResponse, UpdateCustomerRequest>({
      query: ({ updatedCustomerId, ...body }) => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/customers/${updatedCustomerId}`,
          method: HTTP.PATCH,
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'The Customer has been updated');
      },
      invalidatesTags: ['Customer', 'Customers_Count', 'Connection'],
    }),

    exportCustomers: builder.query<ExportCustomersResponse, void>({
      query: () => {
        return {
          url: `/export_customer_xls`,
          method: HTTP.GET,
        };
      },
      keepUnusedDataFor: 0,
    }),

    sendTestInvite: builder.mutation<SuccessResponse, string>({
      query: (email) => {
        return {
          url: `/send_test_invite`,
          method: HTTP.POST,
          body: {
            email,
          },
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'Test invite successfully sent');
      },
      invalidatesTags: ['Customer', 'Customers_Count', 'Connection'],
    }),
  }),

  overrideExisting: true,
});

export const {
  useCreateCustomerMutation,
  useGetCustomerListQuery,
  useDeleteCustomerMutation,
  useUpdateCustomerMutation,
  useFetchSocialContactsQuery,
  useFetchSocialOutlookContactsQuery,
  useLazyFetchSocialContactsQuery,
  useLazyFetchSocialOutlookContactsQuery,
  useCreateSocialCustomersMutation,
  usePreviewExportedCustomersMutation,
  useImportCustomersMutation,
  useExportCustomersQuery,
  useLazyExportCustomersQuery,
  useSetMinimumDeliveryMutation,
  useGetCustomerByIdQuery,
  useSendTestInviteMutation,
} = customerApi;
