import * as React from 'react';

function SvgLockIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 12 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M9.333 5.667V4.333a3.333 3.333 0 10-6.667 0v1.334m3.333 3V10m-2.133 3h4.267c1.12 0 1.68 0 2.108-.218a2 2 0 00.874-.874c.218-.428.218-.988.218-2.108v-.933c0-1.12 0-1.68-.218-2.108a2 2 0 00-.874-.874c-.428-.218-.988-.218-2.108-.218H3.866c-1.12 0-1.68 0-2.108.218a2 2 0 00-.874.874c-.218.428-.218.988-.218 2.108V9.8c0 1.12 0 1.68.218 2.108a2 2 0 00.874.874c.428.218.988.218 2.108.218z'
        stroke='currentColor'
        strokeWidth={1.2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default SvgLockIcon;
