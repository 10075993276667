import React, { useCallback, useEffect, useState } from 'react';

import DoneIcon from '@material-ui/icons/Done';
import clsx from 'clsx';
import ReactDropzone from 'react-dropzone';
import LinesEllipsis from 'react-lines-ellipsis';

import {
  useExportManualSupplierProductsMutation,
  useImportManualSupplierProductsMutation,
  usePreviewManualSupplierProductsMutation,
} from '../../../../api/product';
import SvgFile from '../../../../assets/icons/File';
import SvgUploadIcon from '../../../../assets/icons/UploadIcon';
import { BackArrowButton } from '../../../../shared/components/back-arrrow-button';
import { ButtonLoader } from '../../../../shared/components/button-loader';
import { ThemedButton } from '../../../../shared/components/themed-button';
import { ProgressBar } from '../../../../shared/components/progress-bar';
import { generateProductsSheetForManual } from '../../../../shared/helpers/generateProductsSheetForManual';
import { useScreenSize } from '../../../../shared/hooks/use-screen-size';
import { LocalStorageService } from '../../../../shared/services/localStorage.service';
import { OnBoardSteps } from '../index';
import { ImportManualProductPreview } from './import-preview';
import { PreviewProducts } from './preview-products';

import { useStyles } from './style';

export enum OnBoardManualSteps {
  DOWNLOAD_TEMPLATE = 'DOWNLOAD_TEMPLATE',
  PREVIEW = 'PREVIEW',
  IMPORT = 'IMPORT',
}

interface Props {
  setOnboardStep: (step: OnBoardSteps) => void;
  openNextCategory: () => void;
}

const OnboardManualSupplierTemplate: React.FC<Props> = ({ setOnboardStep: setTopStep, openNextCategory }) => {
  const classes = useStyles();
  const { isMobile } = useScreenSize();

  const [onBoardStep, setOnBoardStep] = useState<OnBoardManualSteps | null>(OnBoardManualSteps.DOWNLOAD_TEMPLATE);
  const [templateDownloaded, setTemplateDownloaded] = useState(false);
  const [supplier, setSupplier] = useState<{ name?: string; id?: number } | undefined>();

  const [fileName, setFileName] = useState('');
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [uploadPercent, setUploadPercent] = useState(0);

  const [getData, { data, error, isLoading }] = usePreviewManualSupplierProductsMutation();
  const [importProducts, { isLoading: importLoading }] = useImportManualSupplierProductsMutation();
  const [exportTemplate] = useExportManualSupplierProductsMutation();

  const onUpload = () => {
    if (uploadedFile && supplier?.id) {
      const xlsxData = new FormData();
      xlsxData.append('file', uploadedFile);
      xlsxData.append('supplier_id', supplier.id.toString());
      setOnBoardStep(OnBoardManualSteps.PREVIEW);
      getData(xlsxData);
    }
  };

  const onImport = () => {
    if (uploadedFile && supplier?.id) {
      const xlsxData = new FormData();
      xlsxData.append('file', uploadedFile);
      xlsxData.append('supplier_id', supplier.id.toString());
      importProducts(xlsxData).then((res) => {
        if ('data' in res && res?.data?.success) {
          onOnboardSuccess();
        }
      });
    }
  };

  const onOnboardSuccess = () => {
    setTopStep(OnBoardSteps.SUPPLIERS);
    openNextCategory();
    LocalStorageService.clear('manual_supplier_id');
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setUploadedFile(acceptedFiles[0]);
    setFileName(acceptedFiles[0].name);
  }, []);

  const onDownloadTemplate = async () => {
    await exportTemplate(supplier?.id as number);
    await generateProductsSheetForManual();
    setTemplateDownloaded(true);
  };

  useEffect(() => {
    if (onBoardStep !== OnBoardManualSteps.PREVIEW || error) {
      setUploadPercent(0);
      return;
    }
    const uploadTimer = setInterval(() => {
      setUploadPercent((state) => {
        if (state < 95) {
          return state + 5;
        } else {
          clearInterval(uploadTimer);
          return state;
        }
      });
      if (!isLoading) {
        setUploadPercent(100);
        clearInterval(uploadTimer);
      }
    }, 700);
  }, [onBoardStep, isLoading]);

  useEffect(() => {
    const savedSupplier = LocalStorageService.getItem('manual_supplier_id');
    setSupplier(savedSupplier || undefined);

    return () => {
      LocalStorageService.clear('manual_supplier_id');
    };
  }, []);

  return (
    <div className={classes.root}>
      {onBoardStep === OnBoardManualSteps.DOWNLOAD_TEMPLATE && (
        <>
          <div className={classes.title}>
            Upload your prices with this <br /> Supplier
          </div>
          <div className={clsx(classes.subTitle)}>
            Please download our template and then copy and paste your products and prices into the <br />
            spreadsheet. Please check it and press upload when you are ready to go.
          </div>
          <div className={classes.uploadContainerWrap}>
            <ReactDropzone onDrop={onDrop} accept='.xlsx'>
              {({ getRootProps, getInputProps, isDragActive }) => (
                <div className={clsx(classes.uploadContainer, isDragActive && classes.focused)} {...getRootProps()}>
                  <SvgUploadIcon className={classes.uploadIcon} />
                  <input {...getInputProps()} />
                  <div className={classes.uploadDescription}>
                    {!fileName ? (
                      templateDownloaded ? (
                        <span>
                          Drop file here to upload or <b className={classes.link}>choose a file</b>
                        </span>
                      ) : (
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                          }}
                        >
                          Please download our template{' '}
                          <b className={classes.link} onClick={onDownloadTemplate}>
                            here
                          </b>
                        </span>
                      )
                    ) : (
                      <span>{fileName}</span>
                    )}
                  </div>
                </div>
              )}
            </ReactDropzone>
          </div>
          <ThemedButton onClick={onDownloadTemplate} title='Download template' buttonStyle='white' customClass={classes.downTemplateBtn} />
          <div className={classes.btnsBox}>
            <BackArrowButton onClick={setTopStep.bind(null, OnBoardSteps.MANUAL_INFO)} />
            <ThemedButton onClick={onUpload} title='Upload' width={isMobile ? 150 : 215} disabled={isLoading} />
          </div>
          <div className={clsx([classes.notFoundText, classes.addLater])} onClick={onOnboardSuccess}>
            Skip - I will do this later
          </div>
        </>
      )}

      {onBoardStep === OnBoardManualSteps.PREVIEW && (
        <>
          <div className={classes.title}>Upload the completed template</div>
          <div className={clsx(classes.subTitle)}>Once you’ve added all of your data, save your spreadsheet and upload as</div>
          <div className={classes.formats}>
            <div>.XLSX</div>
          </div>
          <>
            <div className={classes.uploadProgressContainer}>
              <SvgFile className={classes.fileIcon} />
              <div>
                <div className={classes.topBox}>
                  <div className={classes.boldText}>{fileName}</div>
                  {error || data?.added?.length === 0 ? (
                    <div className={classes.error}>{data?.added?.length === 0 ? 'List is empty' : 'Errors in file data'}</div>
                  ) : uploadPercent < 100 || isLoading ? (
                    <div>{uploadPercent === 100 ? 99 : uploadPercent}%</div>
                  ) : (
                    <div>
                      {isMobile ? `${uploadPercent}%` : 'Products Loaded Successfully'} <DoneIcon className={classes.doneIcon} />
                    </div>
                  )}
                </div>
                <ProgressBar percent={uploadPercent} />
              </div>
            </div>
            {uploadPercent === 100 && data && (
              <>
                <div className={classes.errorsBlockWrap}>
                  <div className={classes.errorsBlock}>
                    {data?.error_data.length ? (
                      <LinesEllipsis
                        text={`${data?.error_data.length} File errors: ${data.error_data
                          .slice(0, 10)
                          .map((el) =>
                            'field_errors' in el
                              ? Object.entries(Object.values(el.field_errors)[0]).reduce((acc, [field, err]) => {
                                  acc += `${field} - ${err}, `;
                                  return acc;
                                }, '')
                              : '',
                          )
                          .join(' ')}`}
                        maxLine={2}
                        ellipsis='...'
                      />
                    ) : null}
                  </div>
                </div>
                <PreviewProducts data={data} />
              </>
            )}
          </>
          <div className={classes.btnsBox}>
            <BackArrowButton
              onClick={() => {
                setOnBoardStep(OnBoardManualSteps.DOWNLOAD_TEMPLATE);
                setUploadedFile(null);
                setFileName('');
              }}
            />
            <ThemedButton
              onClick={() => {
                setOnBoardStep(OnBoardManualSteps.IMPORT);
              }}
              title='Next'
              width={isMobile ? 150 : 215}
            />
          </div>
        </>
      )}

      {onBoardStep === OnBoardManualSteps.IMPORT && (
        <>
          <div className={classes.title}>Preview your data</div>
          <div className={classes.subTitle}>
            Double check to make sure your data is being uploaded into the correct Open Pantry fields. <br /> Once imported this step cannot
            be undone
          </div>
          <ImportManualProductPreview data={data?.added ? data.added.slice(0, isMobile ? 1 : 4) : []} />
          <div className={classes.btnsBox}>
            <BackArrowButton
              onClick={() => {
                setOnBoardStep(OnBoardManualSteps.DOWNLOAD_TEMPLATE);
                setUploadedFile(null);
                setFileName('');
              }}
            />
            <ThemedButton
              onClick={onImport}
              title={importLoading ? 'Loading...' : 'I’m happy with this'}
              width={215}
              disabled={importLoading}
              endIcon={importLoading ? <ButtonLoader /> : null}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default OnboardManualSupplierTemplate;
