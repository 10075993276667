import { useEffect, useRef, useState } from 'react';

export const useComponentVisible = (initialIsVisible: boolean, onClose: () => void) => {
  const [isComponentVisible, setIsComponentVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    initialIsVisible
      ? document.addEventListener('click', handleClickOutside, true)
      : document.removeEventListener('click', handleClickOutside, true);
    setIsComponentVisible(initialIsVisible);
  }, [initialIsVisible]);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      ref?.current &&
      !ref.current.contains(event.target as Node) &&
      triggerRef?.current &&
      !triggerRef.current.contains(event.target as Node)
    ) {
      setIsComponentVisible(false);
      onClose();
    }
  };

  useEffect(() => {
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return { ref, triggerRef, isComponentVisible, setIsComponentVisible };
};
