import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ClickAwayListener, Tooltip } from '@material-ui/core';
import clsx from 'clsx';

import { useAppDispatch } from '../../../store';
import { closeMainMenu } from '../../../store/user/user.actions';
import { useScreenSize } from '../../hooks/use-screen-size';
import { CloseButton } from '../close-button';
import { ThemedButton } from '../themed-button';

import { useStyles } from './style';

interface Props {
  text: string;
  disabled?: boolean;
  isOpened?: boolean;
  onClose?: () => void;
  isRestaurant?: boolean;
  closeMenu?: boolean;
  shiftArrowClass?: string;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
}

export const UpgradeSubscriptionTooltip: React.FC<Props> = ({
  children,
  text,
  placement = 'top',
  disabled,
  isOpened,
  onClose,
  isRestaurant,
  shiftArrowClass,
  closeMenu,
}) => {
  const classes = useStyles();
  const { isDesktop } = useScreenSize();
  const { push } = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();

  const onUpgrade = (e?: any) => {
    push('/account/billing/subscriptions');
    closeMenu && dispatch(closeMainMenu());
    closeTooltip();
  };

  const closeTooltip = (e?: any) => {
    e?.stopPropagation();
    onClose && onClose();
    setIsOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={isDesktop || onClose ? () => {} : closeTooltip}>
      <Tooltip
        disableTouchListener={true}
        disableFocusListener={true}
        disableHoverListener={!isDesktop || !!onClose}
        arrow={true}
        classes={{
          tooltip: classes.rootTooltip,
          arrow: clsx(classes.arrow, isRestaurant && classes.arrowRS, shiftArrowClass),
        }}
        title={
          <div
            className={clsx(classes.tooltip, isRestaurant && classes.tooltipRS)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <h2 className={clsx(classes.tooltipTitle, isRestaurant && classes.tooltipTitleRS)}>Upgrade Subscription plan</h2>
            <div className={clsx(classes.tooltipText, isRestaurant && classes.tooltipTextRS)}>{text}</div>
            <ThemedButton
              onClick={onUpgrade}
              title='Upgrade'
              width={240}
              buttonStyle={isRestaurant ? 'primary' : 'blueButton'}
              className={classes.upgradeBtn}
            />
            <CloseButton customClass={classes.closeTooltip} onClick={closeTooltip} />
          </div>
        }
        interactive={true}
        open={isOpened ? !!isOpened : isOpen && !disabled}
        onOpen={setIsOpen.bind(null, true)}
        onClose={setIsOpen.bind(null, false)}
        placement={placement}
      >
        <div onClick={isDesktop ? undefined : setIsOpen.bind(null, true)}>{children}</div>
      </Tooltip>
    </ClickAwayListener>
  );
};
