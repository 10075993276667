import React from 'react';

import IconButton from '@material-ui/core/IconButton';

import SvgFilter from '../../../assets/icons/Filter';

import { useStyles } from './style';

interface Props {
  onClick?: () => void;
  disabled?: boolean;
  color?: string;
}

export const FilterButton: React.FC<Props> = React.memo(({ onClick, disabled, color }) => {
  const classes = useStyles({ color });
  return (
    <IconButton className={classes.button} onClick={onClick} disabled={disabled}>
      <SvgFilter className={classes.icon} />
    </IconButton>
  );
});
