import * as React from 'react';

function SvgMeatCategory(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M23.233 3C15.541 3 9.281 9.326 9.281 17.1v.075c0 2.82-.867 4.345-2.94 5.107-.076 0-.114.038-.19.076-2.6 1.029-4.373 3.658-4.373 6.516a7.082 7.082 0 004.412 6.554h.037c.038.039.113.039.151.039.113.038.34.114.679.19l.15.038h.038c.868.229 1.773.305 2.79.305 7.504 0 17.911-5.525 19.231-6.211h.038c4.826-2.325 7.918-7.317 7.918-12.728C37.185 9.326 30.925 3 23.233 3zm5.317 25.455l-.038.038c-.566.305-13.99 7.507-20.927 5.792l-.113-.038c-.189-.038-.49-.152-.604-.19-2.149-.8-3.582-2.897-3.582-5.183 0-2.248 1.395-4.344 3.507-5.144 3.582-1.296 3.997-4.344 3.997-6.555V17.1c0-6.935 5.58-12.575 12.443-12.575 6.863 0 12.443 5.64 12.443 12.575 0 4.84-2.79 9.298-7.126 11.356z'
        fill='#51D9B0'
      />
      <path
        d='M23.234 6.125c-5.996 0-10.86 4.915-10.86 10.974v.076c0 4.154-1.697 6.86-5.052 8.04-1.471.572-2.451 2.058-2.451 3.659 0 1.562.98 3.048 2.45 3.658l.151.038c.038 0 .227.077.377.115l.114.038H8a8.328 8.328 0 002.036.228c5.43 0 13.688-3.658 17.685-5.83 0 0 .038 0 .038-.038l.15-.076c3.771-1.791 6.184-5.716 6.184-9.908 0-6.059-4.864-10.974-10.86-10.974zm3.997 19.51l-.189.076c-.038 0-.038.038-.075.038l-.038.038c-5.053 2.706-12.48 5.64-16.968 5.64a6.866 6.866 0 01-1.66-.19c-.037 0-.074-.039-.112-.039l-.226-.076-.114-.038c-.867-.343-1.47-1.257-1.47-2.21 0-.99.603-1.867 1.47-2.21 2.753-.991 6.034-3.43 6.034-9.489V17.1c0-5.22 4.185-9.45 9.35-9.45 5.167 0 9.352 4.23 9.352 9.45 0 3.62-2.111 6.974-5.354 8.536z'
        fill='#51D9B0'
      />
      <path
        d='M24.1 12.184c-1.81 0-3.28 1.486-3.28 3.315s1.47 3.315 3.28 3.315c1.81 0 3.281-1.486 3.281-3.315 0-1.83-1.47-3.315-3.28-3.315zm0 5.068c-.98 0-1.771-.8-1.771-1.791 0-.991.791-1.791 1.772-1.791.98 0 1.772.8 1.772 1.79a1.78 1.78 0 01-1.772 1.792z'
        fill='#51D9B0'
      />
    </svg>
  );
}

export default SvgMeatCategory;
