import { createStyles, makeStyles } from '@material-ui/core';
import { colorVariables } from '../../../../styles/colorVariables';

export const useStyles = makeStyles((theme) =>
  createStyles({
    stockTakeProgress: {
      backgroundColor: colorVariables.blueLight,
      borderRadius: 12,
      padding: '0 10px',
      lineHeight: '30px',
      color: colorVariables.blue,
      fontSize: 12,
      height: 30,
    },
  }),
);

