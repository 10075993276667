import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { colorVariables } from '../../../../styles/colorVariables';
import { Sizes } from '../../../../styles/sizes';

export interface StyleProps {
  textColor?: string;
  bgColor?: string;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: 265,
      paddingTop: 28,
      background: ({ bgColor }) => bgColor || colorVariables.navy,
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.1)',
      position: 'fixed',
      zIndex: 800,
      top: 0,
      bottom: 0,
      left: 0,
      overflow: 'hidden',
      transition: 'left ease-in-out 0.5s',
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: 270,
        transition: 'all ease-in-out 0.5s',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        paddingTop: 0,
        transition: 'left ease-in-out 0.5s',
      },
    },
    rootCustomer: {
      '&:before': {
        content: '""',
        display: 'block',
        position: 'relative',
        top: 36,
        height: 32,
        opacity: 0.2,
        background: 'linear-gradient(#030303 0%, rgba(0, 0, 0, 0) 100% )',
        [theme.breakpoints.down(Sizes.mobile)]: {
          top: 50,
        },
      },
    },
    rootCollapsed: {
      [theme.breakpoints.between(Sizes.mobile, Sizes.desktop)]: {
        width: 94,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        transition: 'left ease-in-out 0.5s',
        left: '-100%',
      },
    },
    rootCollapsedCustomer: {
      [theme.breakpoints.up(Sizes.desktop)]: {
        left: '-100%',
        transition: 'left ease-in-out 0.5s',
      },
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: 270,
        left: '-100%',
        transition: 'left ease-in-out 0.5s',
      },
    },
    top: {
      height: 40,
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      paddingLeft: 30,
    },
    topCollapsed: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    topLink: {
      marginLeft: 20,
      marginTop: 5,
    },
    topLinkCollapsed: {
      marginTop: 25,
    },
    profileWrapper: {
      display: 'none',
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      marginTop: 'auto',
      justifyContent: 'space-between',
      alignItems: 'center',
      overflow: 'hidden',
      [theme.breakpoints.down(Sizes.desktop)]: {
        display: 'flex',
        width: 94,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginTop: 'auto',
        height: '100vh',
        position: 'fixed',
        transition: 'all ease-in-out 0.5s',
        bottom: 'calc(110px - 100vh)',
        left: 0,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 270,
        bottom: 'calc(145px - 100vh)',
      },
    },
    profileWrapperOpened: {
      [theme.breakpoints.down(Sizes.desktop)]: {
        bottom: -90,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        bottom: -60,
        height: 'calc(100vh - 50px)',
        zIndex: 5,
      },
    },
    profileWrapBg: {
      backgroundColor: ({ bgColor }) => bgColor || '#293555',
      filter: 'brightness(1.1)',
      [theme.breakpoints.between(Sizes.mobile, Sizes.desktop)]: {
        width: 270,
      },
    },
    profileLogo: {
      width: 38,
      height: 38,
      borderRadius: '50%',
      flexShrink: 0,
    },
    profileLogoActive: {
      '& img': {
        boxShadow: `0 0 3px 1px ${colorVariables.green}`,
      },
    },
    profileLogoLink: {
      flexShrink: 0,
    },
    profileText: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      color: ({ textColor }) => textColor || colorVariables.grey60,
      fontSize: 11,
      fontWeight: 700,
      textTransform: 'uppercase',
      marginLeft: 10,
      height: 60,
      transition: 'all ease-in-out 0.5s',
      opacity: 1,
      [theme.breakpoints.between(Sizes.mobile, Sizes.desktop)]: {
        transform: 'translateX(0)',
        width: 130,
      },
    },
    profileTextCustomer: {
      fontSize: 14,
      textTransform: 'none',
      fontWeight: 400,
    },
    profileTextCollapsed: {
      [theme.breakpoints.between(Sizes.mobile, Sizes.desktop)]: {
        transition: 'all ease-in-out 0.5s',
        opacity: 0,
        transform: 'translateX(-200px)',
      },
    },
    profileChevron: {
      display: 'none',
      [theme.breakpoints.down(Sizes.desktop)]: {
        display: 'block',
        color: ({ textColor }) => textColor || colorVariables.grey60,
      },
    },
    profileMenuBox: {
      width: 270,
      display: 'flex',
      alignItems: 'center',
      padding: 25,
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: 15,
      },
    },
    linksBox: {
      paddingTop: 50,
      overflowY: 'scroll',
      paddingBottom: 20,
      transition: 'opacity ease-in-out 0.5s',
      [theme.breakpoints.down(Sizes.desktop)]: {
        height: 'calc(100vh - 140px)',
        paddingTop: 80,
      },
    },
    linksBoxCustomer: {
      paddingTop: 40,
      [theme.breakpoints.down(Sizes.desktop)]: {
        paddingTop: 40,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        paddingTop: 50,
      },
    },
    profileMenuBoxOpened: {
      '& *': {
        color: ({ textColor }) => textColor || colorVariables.white,
      },
    },
    profileIcon: {
      marginLeft: 5,
      marginRight: 5,
      color: ({ textColor }) => textColor || undefined,
    },
    transparent: {
      opacity: 0,
      transition: 'opacity ease-in-out 0.5s',
    },
    profileWrapperClosed: {
      [theme.breakpoints.down(Sizes.mobile)]: {
        left: '-100%',
      },
    },
    profileWrapperClosedRestaurant: {
      left: '-100%',
    },
  }),
);
