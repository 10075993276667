import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../../../styles/colorVariables';
import { Sizes } from '../../../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      width: '100%',
      borderRadius: 16,
      border: '1px solid #EDEFF6',
      boxShadow: '0 4px 10px rgba(52, 54, 90, 0.05)',
      marginBottom: 12,
      padding: '22px 20px 18px 20px',
      position: 'relative',
    },
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 15,
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginBottom: 12,
      },
      '&:not(:first-child)': {
        paddingRight: 10,
        marginBottom: 9,
        alignItems: 'center',
        [theme.breakpoints.down(Sizes.mobile)]: {
          paddingRight: 5,
        },
      },
      '&:first-child>*:first-child': {
        width: 'calc(100% - 90px)',
      },
    },
    label: {
      height: 24,
      padding: '0 10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 12,
      textTransform: 'uppercase',
      fontSize: 12,
      fontWeight: 700,
    },
    labelActive: {
      color: colorVariables.green,
      backgroundColor: colorVariables.greenLight,
    },
    labelPaused: {
      color: colorVariables.grey60,
      backgroundColor: 'rgba(152, 165, 183, 0.3)',
    },
    labelFrequency: {
      color: colorVariables.blue,
      backgroundColor: 'rgba(207, 241, 255, 0.5)',
    },
    title: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '24px',
    },
    greyFieldName: {
      color: colorVariables.grey60,
      lineHeight: '22px',
    },
    greyFieldValue: {
      color: colorVariables.grey60,
      lineHeight: '22px',
      fontWeight: 600,
    },
    totalText: {
      fontWeight: 600,
      color: colorVariables.userNavy,
    },
    totalValue: {
      fontWeight: 700,
    },
    suppliersBox: {
      width: '100%',
      display: 'flex',
      paddingTop: 5,
      marginBottom: 17,
      alignItems: 'flex-end',
      overflow: 'hidden',
      '& span': {
        transform: 'translate(-7px, 2px)',
        fontSize: 16,
      },
    },
    supplierLogo: {
      width: 29,
      height: 29,
      borderRadius: '50%',
      overflow: 'hidden',
      border: `1px solid ${colorVariables.steelGrey}`,
      marginRight: 8,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
      '& img': {
        width: '100%',
        flexShrink: 0,
      },
    },
    btnBox: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    btn: {
      height: 40,
      '&:first-child': {
        marginLeft: 0,
      },
    },
    iconBtnsWrap: {
      display: 'flex',
    },
    iconBtn: {
      width: 40,
      height: 40,
      padding: 0,
      marginRight: 14,
      backgroundColor: 'rgba(242, 243, 247, 0.5)',
      '&:hover': {
        backgroundColor: 'rgba(242, 243, 247, 0.5)',
      },
    },
    dialog: {
      left: 0,
      width: 300,
      bottom: 0,
    },
  }),
);
