import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';

export const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: 0,
      minWidth: 200,
      maxWidth: 500,
      marginTop: 10,
      boxShadow: '0 4px  10px  rgba(0, 0, 0, 0.1)',
    },
    dropdownItem: {
      overflow: 'hidden',
      padding: 0,
      '&:hover, &:focus': {
        background: colorVariables.blueLight,
        cursor: 'pointer',
      },
      '&>div': {
        width: '100%',
        fontSize: 14,
        padding: '7px 15px',
      },
    },
    disabled: {
      opacity: 0.5,
      '&:hover, &:focus': {
        background: colorVariables.white,
        cursor: 'default',
      },
    },
    triggerWrap: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
  }),
);
