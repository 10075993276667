import { createAction } from '@reduxjs/toolkit';
import { PantryListSortBy, SavedPantryListProdFilters } from '../../api/pantry-list/types';
import { ProductsCategories } from '../../shared/constants/products';

export const showCreatePantryListModal = createAction('SHOW_CREATE_PANTRY_LIST_MODAL');
export const hideCreatePantryListModal = createAction('HIDE_CREATE_PANTRY_LIST_MODAL');
export const showPantryListExitWarning = createAction<{ handler: () => void }>('SHOW_PANTRY_LIST_EXIT_WARNING');
export const hidePantryListExitWarning = createAction('HIDE_PANTRY_LIST_EXIT_WARNING');
export const setSelectedPantryListId = createAction<number>('SET_SELECTED_PANTRY_LIST_ID');
export const setPantryListKeyword = createAction<string>('SET_PANTRY_LIST_KEYWORD');
export const setPantryListPage = createAction<number>('SET_PANTRY_LIST_PAGE');
export const deletePantryList = createAction<number>('DELETE_PANTRY_LIST');
export const setPantryListProductFilter = createAction<SavedPantryListProdFilters>('SET_PANTRY_LIST_PRODUCT_FILTER');
export const setPantryListsSortFilters =
  createAction<{ sort?: PantryListSortBy; filter: ProductsCategories | null }>('SET_PANTRY_LIST_SORT_FILTERS');
