import { formatCurrency } from '../helpers/format-currency';

export const helpCenterSupplier = [
  {
    id: 201,
    path: 'help-center',
    title: 'Dashboard',
    content: `
<a href="https://app.theopenpantry.com/dashboard">https://app.theopenpantry.com/dashboard</a>
<p>1. Log in to <a href="https://app.theopenpantry.com/login">https://app.theopenpantry.com/login</a></p>
<p>2. Once logged in, you will arrive at your Dashboard. This provides an general overview of your online store. Here you can quickly see what orders you need to export, any connection requests, your sales trends and top performing and at risk customers.</p>
<p><img src="/images/201-min.png" /></p>`,
  },
  {
    id: 202,
    path: 'help-center',
    title: 'How To View / Edit Customer Details',
    content: `
 <a href="https://app.theopenpantry.com/customers/add"> https://app.theopenpantry.com/customers/add</a>
<p>1.  Click <b>“Customers”</b> on the top left of your screen, and  select <b>“Customer List”</b></p>
<p><img src="/images/202-1-min.png"/></p>
<p>Click “Customer List” to see a  list of your customers & their Contact Details. Select the arrow icon on the far right to access the  <b>“Actions”</b> </p>
<p>2. The “actions” dropdown menu will show the following 2 options.</p>
<p><img src="/images/202-2-min.png"/></p>
<p>3. Select <b>“Edit Details”</b></p>
<p><img src="/images/202-3-min.png"/></p>
<p>4. Input the Customer Details that you’d like to change and  click <b>“Save Changes”</b> when complete. Customers must change their primary details themselves (ie. Company name, email address) you can edit their pricing list & account actions. </p>
<p>To accept a customers connect request you must apply a price list to them.</p>
<p><img src="/images/202-4-min.png" /></p>
<p>5.  Default price list is the pricing tier you would like to apply to this customer. You can set these price tiers in Products and Pricing.</p>
`,
  },
  {
    id: 203,
    path: 'help-center',
    title: 'How to edit a product and the sell price',
    content: `
 <a href="https://app.theopenpantry.com/products">https://app.theopenpantry.com/products</a>
<p>1.  Select Products & Pricing on the top left corner of your screen, then select Product list.</p>
<p><img src="/images/203-1-min.png" /></p>
<p>2. Click the “⋮” icon of the product you’d like to edit or on desktop simply type into the relative field. For example if you want to edit the first price list type in the price area your sell price. (To the left the profit margin is shown, the right the sell price)</p>
<p><img src="/images/203-2-min.png" /></p>
<p><img src="/images/203-4-min.png" /></p>
<p>3. Update the product’s details by clicking on the white text boxes & typing the relevant information. Select <b>“Update”</b> at the bottom of your screen when complete to save.</p>
<p><img src="/images/203-3-min.png" /></p>
`,
  },
  {
    id: 204,
    path: 'help-center',
    title: 'What is a Price Exception?',
    content: `
<p>Price exceptions override the standard prices or profit margins on your products. Price exceptions can be used for customers who you have agreed on a specific price on a certain product, or for products you’d like to sell at a clearance price etc. A price exception enables you to further customise your pricing by enabling the product to be excluded from the standard pricing applied to that price list eg. For example the price of 1.5kg of Spinach for “Gold Tier” customers is ${formatCurrency(
      10,
      0,
    )} (30%) but a specific customer has negotiated a better price of ${formatCurrency(
      9,
      0,
    )} for 1.5kg, instead of giving everyone on gold tier that new price, you can edit the product price for that one customer exclusively, whilst the rest of their products remain at the standard gold tier profit margin. </p>
<p>Price exceptions can still be applied in the form of a margin eg. giving a gold teir 30% profit margin customer a certain specific product for just a 20% profit margin, again, this will not affect the pricing of that item for other customers or for the remainder of the products on their list. </p>
    `,
  },
  {
    id: 205,
    path: 'help-center',
    title: 'How to Add a Price Exceptions',
    content: `
<a href='https://app.theopenpantry.com/products/price-exceptions'>https://app.theopenpantry.com/products/price-exceptions</a>
<p>Select Products & Pricing on the top left corner of your screen, then select <b>“Price Exceptions”</b> and click the <b>“+”</b> icon on the top right of your screen. </p>
<p><img src="/images/205-1-min.png" /></p>
<p>2. Enter the information into the white text boxes, including the customer you’d like this price exception applied to & the new price you’d like to apply. Click <b>“Save Price Exception”</b> to save & apply. </p>
<p><img src="/images/205-2-min.png" /></p>   
    `,
  },
  {
    id: 206,
    path: 'help-center',
    title: 'How to export your orders',
    content: `
<a href="https://app.theopenpantry.com/orders">https://app.theopenpantry.com/orders</a>
<p>1. Orders are sent to the email for orders as a purchase order as default. However, if you would like to batch export your orders, you can click “orders” in the menu options shown on the top left corner of your screen, then Check the box of the order(s) you’d like to export. These will show a blue tick when selected.</p>
<p><img src="/images/206-1-min.png" /></p>
<p>2. Once you have selected the orders you'd like to export, click <b>"Export"</b> on the top right corner of the page. Select the relevant export option(s) & click "Next".</p>
<p><img src="/images/206-2-min.png" /></p>
<p>3. Choose the format you’d like to export the data in eg. PDF, CSV or Direct Integration, Click "Export" once complete.</p>
<p><img src="/images/206-3-min.png" /></p>
`,
  },
  {
    id: 207,
    path: 'help-center',
    title: 'Customer Performance',
    content: `
<a href="https://app.theopenpantry.com/tools/customer-performance">https://app.theopenpantry.com/tools/customer-performance</a>
<p>1. Click <b>“Tools”</b> in the menu options shown on the top left corner of your screen, then select <b>“Customer Performance”</b></p>
<p><img src="/images/207-1-min.png" /></p>
<p>Here you can see your customers and their performance. Change is a measurement of the amount of sales compared to the previous period (i.e 90 Days).</p>
<p><b>“Risk”</b> is a measurement of how likely you are to lose this customer. This is calculated using an algorithm based on the frequency of orders and volume of orders, compared to their baseline. Keep an eye on this to make sure you never lose a customer.</p>`,
  },
  {
    id: 208,
    path: 'help-center',
    title: 'Products Purchased',
    content: `
<a href="https://app.theopenpantry.com/tools/products-purchased">https://app.theopenpantry.com/tools/products-purchased</a>
<p>1. There are two ways to access your “Products Purchased, this is shown on the dashboard where you can click “View” to access.</p>
<p><img src="/images/208-1-min.png" /></p>
<p>Alternatively, select <b>“tools”</b> on the top left corner of your screen, then select <b>“Products Purchased”</b> From here, you can customise the delivery date range you’d like to search in of the Products Purchased, Search Products Purchased and Export it.</p>
<p><img src="/images/208-2-min.png" /></p>
`,
  },
  {
    id: 209,
    path: 'help-center',
    title: 'My Account / Profile',
    content: `
<a href="https://app.theopenpantry.com/account/">https://app.theopenpantry.com/account/</a>
<p>1. Click the down arrow next to your name & logo on the top right corner of your screen & then select <b>“Profile & Settings”</b></p>
<p><img src="/images/209-1-min.png" /></p>
<p>2. Click the pen icon next to the information you’d like to edit/update. Click the dropdown arrow next to sections to expand on the information in that department you’d like to check/edit.</p>
<p><img src="/images/209-2-min.png" /></p>
<p>3. Click <b>“Update”</b> to save when complete.</p>
<p><img src="/images/209-3-min.png" /></p>
`,
  },
  {
    id: 210,
    path: 'help-center',
    title: 'Notifications',
    content: `
<a href="https://app.theopenpantry.com/account/notifications">https://app.theopenpantry.com/account/notifications</a>    
<p>1. Click the down arrow next to your name & logo on the top right corner of your screen & then select <b>“Notifications”</b></p>  
<p><img src="/images/210-1-min.png" /></p>
<p>2. You may change your  email notification settings by clicking the on/off buttons beside which actions you’d like to receive notifications for. . Blue = On, Grey = Off</p>
<p><img src="/images/210-2-min.png" /></p>
`,
  },
  {
    id: 211,
    path: 'help-center',
    title: 'Help',
    content: `
<a href="https://app.theopenpantry.com/account/help">https://app.theopenpantry.com/account/help</a>
<p>1. Click the down arrow next to your name & logo on the top right corner of your screen & then select <b>“Help”</b></p>
<p><img src="/images/211-1-min.png" /></p>
<p>2. Click on the department you’d like to seek help with & follow the prompts.</p>
<p><img src="/images/211-2-min.png" /></p>
`,
  },
  {
    id: 212,
    path: 'help-center',
    title: 'Help Contact',
    content: `
<a href="https://app.theopenpantry.com/account/help">https://app.theopenpantry.com/account/help</a>
<p>1. From the help menu as above, select <b>“Contact Support”</b></p>
<p><img src="/images/212-1-min.png" /></p>
<p>Fill out the help form by following the title prompts.    Type a keyword(s) summarising your enquiry  in  the “subject” box and type further details of your enquiry in  the “message” box. Click “send message” once complete.</p>
<p>Alternatively, you can email Open Pantry directly at <a href="mailto:info@theopenpantry.com">info@theopenpantry.com</a> or select the blue Messenger icon to contact us on Facebook Messenger Note: There may be delays in receiving a response via Messenger. For more urgent enquiries, please use the help form or email directly.</p>
<p><img src="/images/212-2-min.png" /></p>
`,
  },
  {
    id: 213,
    path: 'help-center',
    title: 'How to add customers using a spreadsheet',
    content: `
<a href="https://app.theopenpantry.com/customers/import">https://app.theopenpantry.com/customers/import</a>
<p>1. Click <b>“Customers”</b> on the top left of your screen, and select <b>“Add New Customer”</b> then select <b>“Import Customer List xlsx”</b> in the top right of your screen. </p>
<p><img src="/images/213-1-min.png" /></p> 
<p>2. Select <b>“Download”</b> to download your <b>“Customer List”</b> & input your data, or skip this step if you already have an excel spreadsheet of your customers information.</p>  
<p><img src="/images/213-2-min.png" /></p> 
<p>3. Upload the completed customer list by dragging & dropping or selecting <b>“choose a file”</b> click <b>“upload”</b> when complete. The upload may take a couple of minutes.</p>
<p><img src="/images/213-3-min.png" /></p> 
<p>4. Any errors with customers information will be highlighted in red. Click the dropdown arrow to view - these errors can be rectified later. Next, select <b>“Preview your data”</b>.</p>
<p><img src="/images/213-4-min.png" /></p>
<p>Preview your information & confirm the information is in the correct column eg. emails are under the email title. When you are happy with the uploaded information click <b>“Im happy with this”</b> to proceed. The upload will now be complete.</p> 
<p><img src="/images/213-5-min.png" /></p>
`,
  },
  {
    id: 214,
    path: 'help-center',
    title: 'How to add customers by linking to your email',
    content: `
<a href="https://app.theopenpantry.com/customers/add">https://app.theopenpantry.com/customers/add</a>
<p>1. This is the easiest way to add your customers. Simply click <b>“Customers”</b> on the top left of your screen, and select <b>“Add New Customer”</b> then enter <b>your</b> business email address in the white <b>“Email Address”</b> tab. Then click <b>“Continue”</b> </p>
<p><img src="/images/214-1-min.png" /></p> 
<p>2. An alert may come up showing you what information will be accessed, confirm this access to proceed.  </p>  
<p><img src="/images/214-2-min.png" width="320" /></p> 
<p>3.Customers names & email addresses will be uploaded, deselect any non relevant customers by clicking the tick box to the right of each customer. Click <b>“Add customers”</b> to proceed.</p>
<p>This will send all those customers an invite to connect with you. If they aren’t on our system they will be prompted to join via email. (Its free for them too).</p>
<p><img src="/images/214-3-min.png" /></p> 
`,
  },
  {
    id: 215,
    path: 'help-center',
    title: 'How to add a customer manually',
    content: `
<a href="https://app.theopenpantry.com/customers/add-manually">https://app.theopenpantry.com/customers/add-manually</a>
<p>1. Click <b>“Customers”</b> on the top left of your screen, select <b>“Add New Customer”</b> scroll to the bottom of your screen & click <b>“Do you want to add a customer manually?”</b>  </p>
<p><img src="/images/215-1-min.png" /></p> 
<p>2. Fill in the customer information following the prompts, enter their email address & apply a pricing list then click <b>“Add Customer”</b> to proceed. If the customer already has an Open Pantry account they can accept your request, otherwise they will receive an email inviting them to create an account. The customer will then show up in your Customer List.</p>  
<p><img src="/images/215-2-min.png" /></p> 
`,
  },
  {
    id: 216,
    path: 'help-center',
    title: 'How to add products using a spreadsheet',
    content: `
<a href="https://app.theopenpantry.com/products/import">https://app.theopenpantry.com/products/import</a>
<p>1. Click <b>“Products & Pricing”</b> on the top left of your screen, and select <b>“Add New Product”</b>   </p>
<p>2. Select <b>“Download”</b> to download your <b>“Product List”</b> & input your data. We recommend downloading your current list when updating your prices and products each time to avoid any unwanted issues.</p>
<p><img src="/images/216-1-min.png" /></p> 
<p>3. Input your data into the spreadsheet (Name, Brand, Unit of Measure, Tax, Your cost -(this is the price you buy the products at - this is needed for profit based pricing) & Sub category if the item has one)</p>  
<p>4. Upload the completed product list by dragging & dropping or selecting <b>“choose a file”</b> click <b>“upload”</b> when complete. The upload may take a couple of minutes.</p>
<p><img src="/images/216-2-min.png" /></p> 
<p>5. Once the upload is complete, select <b>“Preview your data”</b> to proceed.</p>
<p><img src="/images/216-3-min.png" /></p> 
<p>6. Preview your information & confirm the information is in the correct column eg. product names are under the Product Names title. When you are happy with the uploaded information click <b>“Im happy with this”</b> to proceed. The upload will now be complete & you will be able to view all products under <b>“Product List”</b></p>
<p><img src="/images/216-4-min.png" /></p> 
`,
  },
  {
    id: 217,
    path: 'help-center',
    title: 'How to add a single product manually',
    content: `
<a href="https://app.theopenpantry.com/products/add">https://app.theopenpantry.com/products/add</a>
<p>1. Click <b> “Products & Pricing”</b> on the top left of your screen, and select <b>“Add New Product”</b> then select <b>“ + Add Single Product”</b> on the top right. </p>
<p><img src="/images/217-1-min.png" /></p> 
<p>2. Enter the product information following the prompts then select <b>“Save Product”</b> save product when complete.</p>
<p><img src="/images/217-2-min.png" /></p> 
`,
  },
  {
    id: 218,
    path: 'help-center',
    title: 'Information on product fields:',
    content: `
<p>Basic:</p>
<p>The ‘Product Name’ refers to the main name for the product i.e Red Delicious Apple.
‘Brand’ is optional. <br/>
‘Product Code’ is the Product Identifier or code (if you have one). <br/>
The ‘Unit of measure’ is how the item is Sold - i.e ‘Per Kg’. <br/>
Stock Level’ gives notice to the Restaurant of your stock level. If it's low or ‘out of stock’ it will notify the customer.<br/>
</p>
<p>Advanced:</p>
<p>‘Location’ - this is useful to help pickers identify where the product is held - same as ‘Shelf space’. ‘Charge unit’ - is useful for random weighted items like a leg of lamb - eg -1.4kg. <br/>
Sub-category’ can be used to help you create sections within your store to help customers navigate.
</p>
<p>‘Your cost’ refers to the cost you pay for the product. This is useful when you set profit based pricing. For example if I pay ${formatCurrency(
      1,
      0,
    )} for Apples and want to sell at a 30% margin I can set “Your Cost” to be ${formatCurrency(
      1,
      0,
    )} and in the price list set 30% where it will work out the price to sell to achieve that profit.</p>
<p><img src="/images/218-1-min.png" /></p> 
<p>Select <b>“Update”</b> at the bottom of your screen to save when complete.</p>
<p><img src="/images/218-2-min.png" width="300" /></p>
`,
  },
  {
    id: 219,
    path: 'help-center',
    title: 'What are price lists and how do they work',
    content: `
<p>Price lists enable you to apply different pricing to different customers. You can apply either a price or a profit margin for each product. </p> 
<p>To add a profit margin, first enter “Your Cost” and the “Profit Margin” you’d like that item to be sold with. You can then categorise these profit margins by grouping them with a title, eg. Gold = a 30% Profit margin.</p>
<p>If you want to further customise the pricing for each customer you can further customise the pricing under “Price Exceptions” </p>
`,
  },
  {
    id: 220,
    path: 'help-center',
    title: 'How to add a price list',
    content: `
<a href="https://app.theopenpantry.com/products">https://app.theopenpantry.com/products</a>
<p>1. Select Products & Pricing on the top left corner of your screen, then select Product list. In the top right corner, select the “+” icon. This will create a new price list tab which you can name by clicking on the prefilled name & retyping when the white tab appears. Click the “>” icon to scroll through your product lists. </p>
<p><img src="/images/220-1-min.png" /></p>
<p>2. To edit the profit margin on this price list, click on the dropdown arrow next to <b>“Custom”</b> & enter the percentage you’d like to apply to this list. </p>
<p><img src="/images/220-2-min.png" width="250"/></p>
`,
  },
  {
    id: 221,
    path: 'help-center',
    title: 'Finish Order',
    content: `
<p>Once an order has been completed, you can extract the picking slip & produce the invoice using the finish order process. </p>
<a href="https://app.theopenpantry.com/orders">https://app.theopenpantry.com/orders</a>
<p>1. Click <b>“Orders”</b> in the menu options shown on the top left corner of your screen, then select the order you’d like to finish by clicking anywhere on it’s order line when it is highlighted in blue. </p>
<p><img src="/images/221-1-min.png" /></p>
<p>2. The screen will now show the picking slip which you can download by clicking <b>“Picking Slip”</b> or to finish the order & produce the invoice, select <b>“Finish Order”</b> </p>
<p><img src="/images/221-2-min.png"/></p>
<p><b>Example of invoice:</b></p>
<p><img src="/images/221-3-min.png"/></p>
`,
  },
  {
    id: 222,
    path: 'help-center',
    title: 'Update Password',
    content: `
<a href="https://app.theopenpantry.com/account/">https://app.theopenpantry.com/account/</a>
<p>Click the down arrow next to your name & logo on the top right corner of your screen & then select <b>“Profile & Settings”</b></p>
<p><img src="/images/222-1-min.png"/></p>
<p>2. Next to “My Details” Click the “Show” dropdown arrow then select “Change Password”</p>
<p><img src="/images/222-2-min.png"/></p>
<p>Enter your old password in the Verify section then enter your new password, repeat to confirm & click <b>“Update”</b> to save.</p>
<p><img src="/images/222-3-min.png"/></p>
`,
  },
];
