import React, { useEffect, useRef, useState } from 'react';

import clsx from 'clsx';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';

import { RecOrderSortBy } from '../../../../../../api/recurring-order/types';
import { CloseButton } from '../../../../../../shared/components/close-button';
import { ThemedButton } from '../../../../../../shared/components/themed-button';
import { CommonCheckbox } from '../../../../../../shared/components/common-checkbox';
import { FormikDropdown } from '../../../../../../shared/components/formik-dropdown';
import { ProductsCategories, productsCategoriesList } from '../../../../../../shared/constants/products';
import { useScreenSize } from '../../../../../../shared/hooks/use-screen-size';
import { useAppDispatch, useAppSelector } from '../../../../../../store';
import { getRecurringOrderSortFilters, setRecurringOrderSortFilters } from '../../../../../../store/recurring-order';
import { recurringOrdersListSortByOptions } from '../index';

import { useStyles } from './style';

interface Props {
  isOpened: boolean;
  closeModal: () => void;
}

interface Values {
  sortBy: string;
}

export const RecurringOrdersSortFilterModal: React.FC<Props> = ({ isOpened, closeModal }) => {
  const classes = useStyles();
  const ref = useRef<HTMLFormElement | null>(null);
  const { isMobile } = useScreenSize();
  const dispatch = useAppDispatch();

  const [selectedCategory, setSelectedCategory] = useState<ProductsCategories | null>(null);

  const { sort, filter } = useAppSelector(getRecurringOrderSortFilters);

  const checkBoxHandler = (category: ProductsCategories | null) => {
    setSelectedCategory(category);
  };

  const onSubmit = ({ sortBy }: Values, formikHelpers: FormikHelpers<any>) => {
    formikHelpers.setSubmitting(false);
    dispatch(setRecurringOrderSortFilters({ sort: sortBy.split(' ') as RecOrderSortBy, filter: selectedCategory }));
    closeModal();
  };

  const onClear = () => {
    closeModal();
    ref.current?.reset();
    setSelectedCategory(null);
    dispatch(setRecurringOrderSortFilters({ sort: undefined, filter: null }));
  };

  useEffect(() => {
    if (!isOpened) {
      ref.current?.reset();
      setSelectedCategory(null);
    } else {
      setSelectedCategory(filter);
    }
  }, [isOpened]);

  return (
    <div className={clsx(classes.root, !isOpened && classes.rootClosed)}>
      {isOpened && (
        <Formik
          enableReinitialize={true}
          onSubmit={onSubmit}
          initialValues={{
            sortBy: sort?.join(' ') || recurringOrdersListSortByOptions[2].value,
          }}
        >
          {({ submitForm }) => (
            <Form ref={ref}>
              <div className={classes.head}>
                <CloseButton onClick={closeModal} />
                <h3 className={classes.modalTitle}>Filters</h3>
                <div className={classes.clearBtn} onClick={onClear}>
                  Clear
                </div>
              </div>
              <div className={classes.box}>
                <div className={classes.sortBlock}>
                  <h3 className={classes.blockTitle}>SORT BY</h3>
                  <Field name='sortBy'>
                    {(fieldProps: FieldProps) => (
                      <FormikDropdown options={recurringOrdersListSortByOptions} {...fieldProps} height={34} inputFontSize={14} />
                    )}
                  </Field>
                </div>
                <div>
                  <h3 className={classes.blockTitle}>filter BY</h3>
                  <div className={classes.categoriesBox}>
                    <div key='All' className={classes.categoryItem}>
                      <CommonCheckbox checked={!selectedCategory} name='All' onChange={checkBoxHandler.bind(null, null)} labelPlace='end' />
                    </div>
                    {productsCategoriesList.map((category) => (
                      <div key={category.title} className={classes.categoryItem}>
                        <CommonCheckbox
                          checked={selectedCategory === category.title}
                          name={category.title}
                          onChange={checkBoxHandler.bind(null, category.title)}
                          labelPlace='end'
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className={classes.btnBox}>
                  <ThemedButton type='reset' onClick={closeModal} title='Cancel' buttonStyle='secondary' isSmall={isMobile} />
                  <ThemedButton onClick={submitForm} title='Apply' isSmall={isMobile} />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};
