import { format, subDays } from 'date-fns';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

import { ExportWastagesResponse } from '../../api/wastages/types';
import { RangeOptions } from '../components/range-dropdown';
import { formatCurrency } from './format-currency';
import { getDaysFromRange } from './getDaysFromRange';

export const generateWastagesSheet = async (data: ExportWastagesResponse, range: RangeOptions, from?: Date, to?: Date) => {
  const workbook = new ExcelJS.Workbook();
  workbook.created = new Date();
  workbook.modified = new Date();
  const sheet = workbook.addWorksheet('Products', {
    views: [{ state: 'frozen', xSplit: 0, ySplit: 4 }],
    properties: { defaultColWidth: 20 },
  });
  sheet.getRow(1).height = 36;
  sheet.mergeCells('A1:K1');
  sheet.getRow(1).border = {
    top: { style: 'thin', color: { argb: '969696' } },
    left: { style: 'thin', color: { argb: '969696' } },
    bottom: { style: 'thin', color: { argb: '969696' } },
    right: { style: 'thin', color: { argb: '969696' } },
  };
  sheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };
  sheet.getCell('A1').value = 'Waste Log';
  sheet.getRow(1).font = {
    name: 'Calibri',
    size: 18,
    bold: true,
    color: {
      argb: '434343',
    },
  };

  sheet.getRow(2).height = 28;
  sheet.getCell('A2').value = 'Date Period';
  sheet.getCell('A2').font = {
    name: 'Calibri',
    size: 11,
    bold: true,
    color: {
      argb: '434343',
    },
  };
  sheet.mergeCells('B2:C2');
  sheet.getCell('B2').value =
    range === RangeOptions.ALL_TIME
      ? 'All time'
      : range === RangeOptions.CUSTOM && from && to
      ? `${format(from, 'dd/MM/yyyy')} - ${format(to, 'dd/MM/yyyy')}`
      : range === RangeOptions.TODAY
      ? `${format(new Date(), 'dd/MM/yyyy')} (Today)`
      : `${format(subDays(new Date(), getDaysFromRange(range)), 'dd/MM/yyyy')} - ${format(new Date(), 'dd/MM/yyyy')} (${range})`;
  sheet.getRow(4).border = {
    top: { style: 'thin', color: { argb: '969696' } },
    left: { style: 'thin', color: { argb: '969696' } },
    bottom: { style: 'thin', color: { argb: '969696' } },
    right: { style: 'thin', color: { argb: '969696' } },
  };
  sheet.getRow(4).height = 28;
  sheet.getRow(4).font = {
    name: 'Calibri',
    size: 11,
    bold: true,
    color: {
      argb: '434343',
    },
  };
  sheet.getRow(4).alignment = { vertical: 'middle' };

  sheet.getCell('A4').value = 'Date';
  sheet.getCell('A4').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'efefef' },
  };
  sheet.getColumn('A').width = 15;

  sheet.getCell('B4').value = 'Type';
  sheet.getCell('B4').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'efefef' },
  };
  sheet.getColumn('B').width = 15;

  sheet.getCell('C4').value = 'Reporter';
  sheet.getCell('C4').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'efefef' },
  };

  sheet.getCell('D4').value = 'Product/Recipe ID';
  sheet.getCell('D4').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'efefef' },
  };

  sheet.getCell('E4').value = 'Product/Recipe';
  sheet.getCell('E4').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'efefef' },
  };

  sheet.getCell('F4').value = 'Product Unit';
  sheet.getCell('F4').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'efefef' },
  };
  sheet.getCell('F4').alignment = { vertical: 'middle', horizontal: 'center' };

  sheet.getCell('G4').value = 'Product Supplier';
  sheet.getCell('G4').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'efefef' },
  };
  sheet.getCell('G4').alignment = { vertical: 'middle', horizontal: 'center' };

  sheet.getCell('H4').value = 'Menu type';
  sheet.getCell('H4').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'efefef' },
  };
  sheet.getCell('H4').alignment = { vertical: 'middle', horizontal: 'center' };

  sheet.getCell('I4').value = 'Quantity';
  sheet.getCell('I4').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'efefef' },
  };
  sheet.getColumn('I').width = 10;
  sheet.getCell('I4').alignment = { vertical: 'middle', horizontal: 'center' };

  sheet.getCell('J4').value = 'Cost Price';
  sheet.getCell('J4').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'efefef' },
  };
  sheet.getColumn('J').width = 15;
  sheet.getCell('J4').alignment = { vertical: 'middle', horizontal: 'center' };

  sheet.getCell('K4').value = 'Total';
  sheet.getCell('K4').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'efefef' },
  };
  sheet.getCell('K4').alignment = { vertical: 'middle', horizontal: 'center' };

  const nextRow = data.data.length + 5;

  data.data.forEach((item, idx) => {
    sheet.getRow(idx + 5).values = [
      format(new Date(item['Wastage Date']), 'dd/MM/yyyy'),
      (item.Type || '').replace(/^(.)(.*)$/, (_, firstLetter, restOfString) => {
        return firstLetter.toUpperCase() + restOfString;
      }),
      item.Reporter,
      item['Recipe ID / Product Code'],
      item['Recipe Name / Product Name'],
      item['Product Unit'],
      item['Product Supplier'],
      item['Recipe Menu'],
      item.Quantity,
      formatCurrency(item['Cost Price'] || 0),
      formatCurrency(item.Total || 0),
    ];
    sheet.getCell(`A${idx + 5}`).font = {
      color: { argb: '666666' },
    };
    sheet.getCell(`B${idx + 5}`).font = {
      color: { argb: '666666' },
    };
    sheet.getCell(`D${idx + 5}`).font = {
      color: { argb: '666666' },
    };
    sheet.getCell(`D${idx + 5}`).alignment = { horizontal: 'left' };
    sheet.getCell(`F${idx + 5}`).font = {
      color: { argb: '666666' },
    };
    sheet.getCell(`F${idx + 5}`).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
    sheet.getCell(`G${idx + 5}`).font = {
      color: { argb: '666666' },
    };
    sheet.getCell(`G${idx + 5}`).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
    sheet.getCell(`H${idx + 5}`).font = {
      color: { argb: '666666' },
    };
    sheet.getCell(`H${idx + 5}`).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
    sheet.getCell(`I${idx + 5}`).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
    sheet.getCell(`I${idx + 5}`).font = {
      bold: true,
    };
    sheet.getCell(`J${idx + 5}`).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
    sheet.getCell(`K${idx + 5}`).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
    sheet.getCell(`K${idx + 5}`).font = {
      bold: true,
    };
  });

  sheet.mergeCells(`A${nextRow}:J${nextRow}`);
  sheet.getRow(nextRow).border = {
    top: { style: 'thin', color: { argb: '969696' } },
    left: { style: 'thin', color: { argb: '969696' } },
    bottom: { style: 'thin', color: { argb: '969696' } },
    right: { style: 'thin', color: { argb: '969696' } },
  };
  sheet.getRow(nextRow).alignment = { vertical: 'middle', horizontal: 'center' };
  sheet.getRow(nextRow).height = 20;
  sheet.getRow(nextRow).font = {
    bold: true,
  };
  sheet.getCell(`A${nextRow}`).value = 'Total';
  sheet.getCell(`A${nextRow}`).alignment = { vertical: 'middle', horizontal: 'right' };
  sheet.getCell(`A${nextRow}`).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'efefef' },
  };

  sheet.getCell(`K${nextRow}`).value = formatCurrency(data?.total || 0);

  const bufferRes = await workbook.xlsx.writeBuffer();
  const blob = new Blob([bufferRes], { type: 'application/xlsx' });
  await saveAs(blob, 'Wastage.xlsx');
  return Promise.resolve();
};
