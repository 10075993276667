import remove from 'lodash/remove';
import store from '../../store';
import { setAppSuccessToast } from '../../store/user';
import { getDriversListQueryParamsHelper, setSuccessToast } from '../helpers';
import { HTTP, rootApi, SuccessResponse } from '../index';
import { CreateDriverRequest, GetDriversListReqParams, GetDriversResponse, UpdateDriverRequest } from './types';

export const driversApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getDrivers: builder.query<GetDriversResponse, GetDriversListReqParams>({
      query: (args) => {
        const params = {
          ...getDriversListQueryParamsHelper(args),
        };
        return {
          url: `/drivers`,
          method: HTTP.GET,
          params,
        };
      },
      keepUnusedDataFor: 0,
      providesTags: ['Drivers'],
    }),

    sendEmailToDriver: builder.query<SuccessResponse, number>({
      query: (id) => {
        return {
          url: `/drivers/${id}/send_mail_to_driver`,
          method: HTTP.GET,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const res = await queryFulfilled;
          if (res?.data?.success) {
            dispatch(setAppSuccessToast('Email has been sent'));
          }
        } catch (e) {
          console.log(e);
        }
      },
      keepUnusedDataFor: 0,
    }),

    createDriver: builder.mutation<SuccessResponse, CreateDriverRequest>({
      query: (driver) => {
        return {
          url: `/drivers`,
          method: HTTP.POST,
          body: { driver },
        };
      },
      async onQueryStarted(a, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'Driver has been added');
      },
      invalidatesTags: ['Drivers'],
    }),

    updateDriver: builder.mutation<SuccessResponse, UpdateDriverRequest>({
      query: ({ id, ...body }) => {
        return {
          url: `/drivers/${id}`,
          method: HTTP.PATCH,
          body,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const sort = store.getState().drivers.sortBy;
        const keyword = store.getState().drivers.keyword;
        const patchResult = dispatch(
          driversApi.util.updateQueryData('getDrivers', { sort, keyword }, (draft) => {
            const idx = draft.drivers.findIndex((el) => el.id === args.id);
            if (idx !== -1) {
              draft.drivers[idx].color = args.color || draft.drivers[idx].color;
              draft.drivers[idx].name = args.name || draft.drivers[idx].name;
              draft.drivers[idx].email = args.email || draft.drivers[idx].email;
              draft.drivers[idx].password = args.password || draft.drivers[idx].password;
            }
          }),
        );
        try {
          await queryFulfilled;
          dispatch(setAppSuccessToast('Driver has been updated'));
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: ['Drivers'],
    }),

    deleteDriver: builder.mutation<SuccessResponse, number>({
      query: (id) => {
        return {
          url: `/drivers/${id}`,
          method: HTTP.DELETE,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const sort = store.getState().drivers.sortBy;
        const keyword = store.getState().drivers.keyword;
        const patchResult = dispatch(
          driversApi.util.updateQueryData('getDrivers', { sort, keyword }, (draft) => {
            remove(draft.drivers, (el) => el.id === arg);
          }),
        );
        try {
          await queryFulfilled;
          await setSuccessToast(dispatch, queryFulfilled, 'Driver has been deleted');
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: ['Drivers'],
    }),
  }),

  overrideExisting: true,
});

export const {
  useGetDriversQuery,
  useUpdateDriverMutation,
  useDeleteDriverMutation,
  useLazySendEmailToDriverQuery,
  useCreateDriverMutation,
} = driversApi;
