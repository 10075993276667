import { RootState } from '../index';
import { wastageAdapter } from './wastage.reducer';

export const getWastageSortFilters = (state: RootState) => ({
  currentPage: state.wastage.currentPage,
  sortBy: state.wastage.sortBy,
  filter: state.wastage.filter,
  keyword: state.wastage.keyword,
});

export const wastageSelectors = wastageAdapter.getSelectors((state: RootState) => state.wastage.list);
