import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../styles/colorVariables';
import { Sizes } from '../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    searchFilterBox: {
      display: 'flex',
      alignItems: 'flex-end',
      backgroundColor: colorVariables.white,
      boxShadow: `0 -12.5px 0 12.5px ${colorVariables.white}`,
      [theme.breakpoints.down(Sizes.desktop)]: {
        boxShadow: `0 -10px 0 10px ${colorVariables.white}`,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        justifyContent: 'space-between',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        flexWrap: 'wrap',
      },
    },
    filterWrap: {
      display: 'flex',
      marginRight: 5,
      flexShrink: 0,
      alignItems: 'center',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    filterWrapCategorySelected: {
      '& #category_trigger': {
        color: colorVariables.blue,
      },
    },
    filterBlock: {
      display: 'flex',
    },
    filterTitle: {
      fontSize: 12,
      lineHeight: '23px',
      textTransform: 'uppercase',
      color: colorVariables.grey60,
      fontWeight: 700,
      marginRight: 20,
      flexShrink: 0,
    },
    sortTitle: {
      fontSize: 12,
      lineHeight: '23px',
      textTransform: 'uppercase',
      color: colorVariables.grey60,
      fontWeight: 700,
      marginLeft: 5,
      flexShrink: 0,
    },
    searchWrap: {
      width: 330,
      marginRight: 60,
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 'calc(80% - 20px)',
        marginRight: 20,
      },
    },
    emptyWrap: {
      paddingTop: '16vh',
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down(Sizes.mobile)]: {
        paddingTop: '10vh',
      },
    },
    emptyTitle: {
      textAlign: 'center',
      fontSize: 40,
      lineHeight: '56px',
      marginBottom: 15,
      fontWeight: 700,
      padding: '0 10%',
      [theme.breakpoints.down(Sizes.mobile)]: {
        fontSize: 28,
        lineHeight: '36px',
      },
    },
    emptyText: {
      marginBottom: 45,
      textAlign: 'center',
    },
    filterIconMobile: {
      display: 'none',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'block',
        width: '15%',
        textAlign: 'center',
      },
    },
    iconButton: {
      width: 40,
      height: 40,
      padding: 5,
      color: 'inherit',
    },
    suppliersBox: {
      paddingTop: 40,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      alignContent: 'flex-start',
      marginBottom: 50,
      /*      height: 'calc(100vh - 310px)',
      overflowY: 'scroll',*/
      [theme.breakpoints.down(Sizes.desktop)]: {
        // height: 'calc(100vh - 190px)',
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        justifyContent: 'space-between',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        paddingTop: 15,
        justifyContent: 'center',
        // height: 'calc(100vh - 265px)',
      },
    },
    supplierWrap: {
      minWidth: 235,
      maxWidth: 350,
      marginRight: 15,
      marginLeft: 15,
      marginBottom: 30,
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: 'calc(33% - 30px)',
        maxWidth: 'auto',
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        width: 'calc(50% - 30px)',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        maxWidth: 400,
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 18,
      },
    },
    chipsBox: {
      display: 'none',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'flex',
        width: '100%',
        backgroundColor: colorVariables.white,
        paddingTop: 5,
      },
    },
    loadingBox: {
      paddingTop: '15vh',
      [theme.breakpoints.down(Sizes.tablet)]: {
        paddingTop: '25vh',
      },
    },
    noResWrap: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      justifyContent: 'center',
    },
    addSupplier: {
      display: 'flex',
      alignItems: 'center',
      padding: '20px 15px',
      height: 116,
      borderRadius: 8,
      border: `2px dashed ${colorVariables.green}`,
      cursor: 'pointer',
      '&:hover': {
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.08)',
      },
      '& button': {
        marginRight: 18,
        width: 48,
        height: 48,
        '& svg': {
          fontSize: 36,
        },
        '&:hover': {
          boxShadow: 'none',
        },
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        maxWidth: 400,
      },
    },
    addSupplierText: {
      fontSize: 16,
      lineHeight: '22px',
      fontWeight: 600,
      userSelect: 'none',
    },
    addSubText: {
      fontSize: 13,
      color: colorVariables.grey60,
      paddingTop: 3,
    },
  }),
);
