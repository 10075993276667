import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import MuiTextField from '@material-ui/core/TextField';
import { Close } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import uniqBy from 'lodash/uniqBy';
import Highlighter from 'react-highlight-words';
import LinesEllipsis from 'react-lines-ellipsis';

import { useGetActiveRecipes } from '../../../../../../../api/menu/hooks';
import { Recipe } from '../../../../../../../api/menu/types';
import recipePlaceholder from '../../../../../../../assets/images/recipe-placeholder.png';
import { BlurredImage } from '../../../../../../../shared/components/blurred-image';

import { useStyles } from './style';

interface Props {
  setRecipe: (value: Recipe | null) => void;
  selectedRecipe: Recipe | null;
}

export const SearchRecipeByIdForSale: React.FC<Props> = ({ setRecipe, selectedRecipe }) => {
  const classes = useStyles();
  const ref = useRef<HTMLDivElement | null>(null);

  const [inputValue, setInputValue] = useState('');
  const [selectedValue, setSelectedValue] = useState<number | null>(null);

  const { list: recipies, loading } = useGetActiveRecipes(true);
  const list = uniqBy(recipies?.filter((el) => !!el.recipe_number) || [], 'recipe_number');

  const onInputChange = (event: ChangeEvent<{}>, newInputValue: string) => {
    setInputValue(newInputValue);
  };

  const onSelect = (event: ChangeEvent<any>, newValue?: string | null) => {
    const selected = list?.find((el) => newValue === el.recipe_number?.toString());
    setSelectedValue(selected?.id || null);
    setRecipe(selected || null);
  };

  useEffect(() => {
    if (selectedRecipe) {
      setInputValue(selectedRecipe.recipe_number?.toString() || '');
      setSelectedValue(selectedRecipe.id);
    } else {
      ref.current?.click();
    }
  }, [selectedRecipe]);

  return (
    <Autocomplete
      loading={loading}
      blurOnSelect={true}
      clearOnBlur={true}
      popupIcon={null}
      value={list?.find((el) => el.id === selectedValue)?.recipe_number?.toString()}
      onChange={onSelect}
      inputValue={inputValue}
      onInputChange={onInputChange}
      closeIcon={
        <div ref={ref} className={classes.closeIcon}>
          <Close />
        </div>
      }
      renderOption={(option) => {
        const recipe = list?.find((rec) => rec.recipe_number?.toString() === option);
        return recipe ? (
          <div className={classes.optionWrap}>
            <div className={classes.nameSection}>
              <div className={classes.imgWrap}>
                <BlurredImage src={recipe.picture_url || recipePlaceholder} />
              </div>
              <div className={classes.recipeName}>
                <Highlighter
                  highlightTag={'b'}
                  highlightClassName={classes.marked}
                  searchWords={[inputValue]}
                  autoEscape={true}
                  textToHighlight={recipe.name}
                />
              </div>
            </div>
            <div className={classes.categorySection}>
              <LinesEllipsis text={recipe.menu.name} maxLine={1} />
            </div>
            <div className={classes.typeSection}>{recipe.is_sub_recipe ? 'Sub-recipes' : 'Menu'}</div>
            <div className={classes.idSection}>{recipe.recipe_number && `ID ${recipe.recipe_number}`}</div>
          </div>
        ) : null;
      }}
      classes={{
        root: classes.rootField,
        paper: clsx(classes.searchBox, !inputValue && !loading && classes.searchBoxHidden),
        noOptions: classes.noOptions,
        loading: classes.noOptions,
        option: classes.option,
      }}
      noOptionsText={inputValue ? 'No Search Results' : 'Please type recipe name'}
      options={list?.map((el) => el.recipe_number?.toString()) || []}
      renderInput={(params) => (
        <MuiTextField
          {...params}
          placeholder={'Start typing ID...'}
          InputProps={{
            ...params.InputProps,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    />
  );
};
