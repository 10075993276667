import * as React from 'react';

function SvgTrash(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 18 21' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M17.215 2.36h-5.34V1.191C11.876.53 11.345 0 10.68 0H7.134C6.47 0 5.938.53 5.938 1.191v1.191H.598A.594.594 0 000 2.978v2.956c0 .33.266.595.598.595h.62L2.77 18.927A2.383 2.383 0 005.118 21h7.533c1.197 0 2.216-.882 2.349-2.073l1.55-12.398h.621a.594.594 0 00.598-.595V2.956a.558.558 0 00-.554-.596zM7.135 1.191h3.544v1.191H7.134v-1.19zm6.713 17.559a1.174 1.174 0 01-1.175 1.037H5.14a1.174 1.174 0 01-1.174-1.037L2.437 6.507H15.4L13.848 18.75zm2.77-13.434H1.195V3.551h15.421v1.765z'
        fill='currentColor'
      />
      <path
        d='M6.203 17.977h.045a.596.596 0 00.554-.64l-.62-8.382a.597.597 0 00-.643-.551.596.596 0 00-.554.64l.62 8.382a.602.602 0 00.598.551zM8.907 17.978a.594.594 0 00.598-.596V9a.594.594 0 00-.598-.596.594.594 0 00-.598.596v8.382c0 .331.266.596.598.596zM11.566 17.977h.044c.31 0 .576-.242.598-.551l.62-8.383a.596.596 0 00-.553-.64.597.597 0 00-.643.552l-.62 8.383a.596.596 0 00.554.64z'
        fill='currentColor'
      />
    </svg>
  );
}

export default SvgTrash;
