import * as React from 'react';

function SvgCalendar(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M14 0h-2v1H4V0H2v1H0v15h16V1h-2V0zm1 5v10H1V5h14z' fill='currentColor' />
      <path d='M14 6h-3v3h3V6zM14 10h-3v3h3v-3zM10 10H7v3h3v-3zM6 10H3v3h3v-3zM10 6H7v3h3V6zM6 6H3v3h3V6z' fill='currentColor' />
    </svg>
  );
}

export default SvgCalendar;
