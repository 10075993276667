import { ukDistrictsPostCodes } from '../constants/postcodesList';
import { LocalStorageService } from '../services/localStorage.service';

export const postCodeValidator = (postcode: string | number | null) => {
  const country = LocalStorageService.getItem('country');
  if (!postcode || !country) {
    return null;
  }
  const stringCode = postcode.toString();
  switch (country) {
    case 'AU': {
      const auPostcodeRegex = /^\d{4}$/;
      return auPostcodeRegex.test(stringCode) ? stringCode : null;
    }
    case 'NZ': {
      const nzPostcodeRegex = /^\d{4}$/;
      return nzPostcodeRegex.test(stringCode) ? stringCode : null;
    }
    case 'US': {
      const zipCodeRegex = /^(\d{5})(?:-\d{4})?$/;
      const zipCode = stringCode.substr(0, 5);
      return zipCodeRegex.test(stringCode) ? zipCode : null;
    }
    case 'CA': {
      const caPostcodeRegex = /^[A-Za-z]\d[A-Za-z]([- ]?\d[A-Za-z]\d)?$/;
      return caPostcodeRegex.test(stringCode) ? stringCode.substr(0, 3) : null;
    }
    case 'IE': {
      const iePostcodeRegex = /^[a-zA-Z0-9]{3}([-\s]?[a-zA-Z0-9]{4})?$/;
      return iePostcodeRegex.test(stringCode) ? stringCode.substr(0, 3) : null;
    }
    case 'GB': {
      const extractUKPostcodeDistrict = (ukCode: string) => {
        const outCode = stringCode.length > 4 ? ukCode.replace(/\d\w{2}$/, '') : ukCode;
        return ukDistrictsPostCodes.reduce((acc: null | string, el) => {
          if (outCode.toUpperCase().startsWith(el.postcode.toUpperCase())) {
            acc = el.postcode;
          }
          return acc;
        }, null);
      };
      return extractUKPostcodeDistrict(stringCode);
    }
    default: {
      return stringCode;
    }
  }
};

export const postCodeValidatorExcluded = (postcode: string | number | null) => {
  const country = LocalStorageService.getItem('country');
  if (!postcode || !country) {
    return null;
  }
  const stringCode = postcode.toString();
  switch (country) {
    case 'AU': {
      const auPostcodeRegex = /^\d{4}$/;
      return auPostcodeRegex.test(stringCode) ? stringCode : null;
    }
    case 'NZ': {
      const nzPostcodeRegex = /^\d{4}$/;
      return nzPostcodeRegex.test(stringCode) ? stringCode : null;
    }
    case 'US': {
      const zipCodeRegex = /^(\d{5})(?:-\d{4})?$/;
      return zipCodeRegex.test(stringCode) ? stringCode : null;
    }
    case 'CA': {
      const caPostcodeRegex = /^[A-Za-z]\d[A-Za-z]([- ]?\d[A-Za-z]\d)?$/;
      return caPostcodeRegex.test(stringCode) ? stringCode : null;
    }
    case 'IE': {
      const iePostcodeRegex = /^[a-zA-Z0-9]{3}([-\s]?[a-zA-Z0-9]{4})?$/;
      return iePostcodeRegex.test(stringCode) ? stringCode : null;
    }
    case 'GB': {
      const extractUKPostcodeDistrict = (ukCode: string) => {
        return ukDistrictsPostCodes.reduce((acc: boolean, el) => {
          if (ukCode.toUpperCase().startsWith(el.postcode.toUpperCase())) {
            acc = true;
          }
          return acc;
        }, false);
      };
      return extractUKPostcodeDistrict(stringCode) ? stringCode.toUpperCase() : null;
    }
    default: {
      return stringCode;
    }
  }
};
