import { TokenService } from '../../shared/services/token.service';
import { getPriceExceptionQueryParamsHelper, setSuccessToast } from '../helpers';
import { HTTP, rootApi, SuccessResponse } from '../index';
import { CreatePriceExceptionRequest, GetPriceExceptionReqParams, GetPriceExceptionsResponse, UpdatePriceExceptionRequest } from './types';

export const priceExceptionApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getPriceExceptions: builder.query<GetPriceExceptionsResponse, GetPriceExceptionReqParams>({
      query: (args) => {
        const userId = TokenService.getUserId() as number;
        const params = {
          ...getPriceExceptionQueryParamsHelper(args),
          per_page: 30,
          page: args?.currentPage || 1,
        };
        return {
          url: `/users/${userId}/price_exceptions`,
          method: HTTP.GET,
          params,
        };
      },
      providesTags: ['Price_Exception'],
    }),

    createPriceException: builder.mutation<SuccessResponse, CreatePriceExceptionRequest>({
      query(body) {
        const userId = TokenService.getUserId() as number;
        return {
          url: `/users/${userId}/price_exceptions`,
          method: HTTP.POST,
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'Created New Price Exception');
      },
      invalidatesTags: ['Price_Exception'],
    }),

    updatePriceException: builder.mutation<SuccessResponse, UpdatePriceExceptionRequest>({
      query({ id, ...body }) {
        const userId = TokenService.getUserId() as number;
        return {
          url: `/users/${userId}/price_exceptions/${id}`,
          method: HTTP.PATCH,
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'Updated Price Exception');
      },
      invalidatesTags: ['Price_Exception'],
    }),

    deletePriceException: builder.mutation<SuccessResponse, number>({
      query(id) {
        const userId = TokenService.getUserId() as number;
        return {
          url: `/users/${userId}/price_exceptions/${id}`,
          method: HTTP.DELETE,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'Deleted Price Exception');
      },
      invalidatesTags: ['Price_Exception'],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetPriceExceptionsQuery,
  useDeletePriceExceptionMutation,
  useCreatePriceExceptionMutation,
  useUpdatePriceExceptionMutation,
} = priceExceptionApi;
