import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../styles/colorVariables';
import { Sizes } from '../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      // height: 'calc(100vh - 280px)',
      // overflowY: 'scroll',
      [theme.breakpoints.down(Sizes.desktop)]: {
        // height: 'calc(100vh - 190px)',
      },
    },
    title: {
      fontSize: 40,
      lineHeight: '50px',
      fontWeight: 700,
      marginBottom: 30,
      textAlign: 'center',
      [theme.breakpoints.down(Sizes.desktop)]: {
        fontSize: 32,
        marginBottom: 25,
        marginTop: 10,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        fontSize: 28,
        lineHeight: '40px',
      },
    },
    subTitle: {
      opacity: 0.7,
      marginBottom: 35,
      textAlign: 'center',
      [theme.breakpoints.down(Sizes.desktop)]: {
        marginBottom: 20,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        marginBottom: 30,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    productsBox: {
      width: 695,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      marginBottom: 20,
      [theme.breakpoints.down(Sizes.tablet)]: {
        width: 600,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        minWidth: 300,
        maxWidth: '95%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
      },
    },
    productItem: {
      display: 'flex',
      alignItems: 'center',
      width: 330,
      height: 64,
      backgroundColor: colorVariables.white,
      boxShadow: '1px 1px 15px rgba(12, 82, 112, 0.08)',
      border: `1px solid ${colorVariables.transparent}`,
      borderRadius: 10,
      padding: '10px 20px',
      fontSize: 16,
      marginBottom: 20,
      cursor: 'pointer',
      transition: 'all ease-in-out 0.5s',
      '&:hover': {
        boxShadow: '1px 1px 15px 5px rgba(12, 82, 112, 0.08)',
        borderColor: colorVariables.steelGrey,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        width: 285,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        marginBottom: 12,
      },
    },
    productItemSelected: {
      border: `1px solid ${colorVariables.green}`,
      backgroundColor: colorVariables.greenLight,
      '& svg': {
        color: colorVariables.green,
      },
      '&:hover': {
        boxShadow: '1px 1px 15px 5px rgba(12, 82, 112, 0.08)',
      },
    },
    checkIcon: {
      marginLeft: 'auto',
      color: colorVariables.transparent,
    },
    productIcon: {
      marginRight: 10,
      display: 'flex',
      '&>svg': {
        width: 40,
        height: 40,
      },
    },
    btnBox: {
      display: 'flex',
      marginBottom: 40,
      '&>button': {
        margin: '0 12px',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        '&>button': {
          margin: '0 5px 15px 5px',
        },
      },
    },
  }),
);
