import * as React from 'react';

function SvgMenuIconLarge(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 34 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <rect width={34} height={3} rx={1.5} fill='currentColor' />
      <rect y={11} width={34} height={3} rx={1.5} fill='currentColor' />
      <rect y={22} width={34} height={3} rx={1.5} fill='currentColor' />
    </svg>
  );
}

export default SvgMenuIconLarge;
