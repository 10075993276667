import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      fontSize: 14,
      fontWeight: 400,
      height: 36,
      padding: 10,
      borderRadius: 24,
      margin: 5,
      color: colorVariables.navy,
      backgroundColor: colorVariables.greenLight,
      '&:hover': {
        backgroundColor: colorVariables.greenLight,
      },
      '&:active': {
        backgroundColor: colorVariables.greenLight,
      },
      '&:focus': {
        backgroundColor: colorVariables.greenLight,
      },
      '&:disabled': {
        opacity: 0.3,
      },

      '& svg': {
        color: colorVariables.steelGrey,
      },
      '& svg:hover': {
        color: colorVariables.navy,
      },
    },
    tagRoot: {
      fontSize: 14,
      fontWeight: 400,
      height: 28,
      padding: '0 10px',
      borderRadius: 18,
      marginRight: 5,
      marginBottom: 12,
      color: colorVariables.blue,
      backgroundColor: 'rgba(207, 241, 255, 0.5)',
      '&:hover': {
        backgroundColor: colorVariables.blueLight,
      },
      '&:active': {
        backgroundColor: colorVariables.blueLight,
      },
      '&:focus': {
        backgroundColor: colorVariables.blueLight,
      },
      '&:disabled': {
        opacity: 0.3,
      },

      '& svg': {
        color: colorVariables.steelGrey,
        scale: 0.9,
      },
      '& svg:hover': {
        color: colorVariables.grey60,
      },
    },
  }),
);
