import { createReducer } from '@reduxjs/toolkit';

import isEqual from 'lodash/isEqual';
import { AdminConnectionsFilters, AdminConnectionsSortBy } from '../../../api/admin-connections/types';
import { logoutThunk } from '../../user';
import { setAdminConnectionsKeyword, setAdminConnectionsPage, setAdminConnectionsSortFilters } from './connections.actions';

interface State {
  sortBy?: AdminConnectionsSortBy;
  keyword?: string;
  currentPage: number;
  filter?: AdminConnectionsFilters;
}

const initialState: State = {
  sortBy: undefined,
  filter: undefined,
  keyword: undefined,
  currentPage: 1,
};

export const adminConnectionsReducer = createReducer(initialState, (builder) => {
  builder.addCase(logoutThunk.fulfilled, () => {
    return initialState;
  });
  builder.addCase(setAdminConnectionsSortFilters, (state, { payload }) => {
    if (isEqual(state.sortBy, payload)) {
      return;
    }
    state.sortBy = payload.sort;
    state.filter = payload.filter;
    state.currentPage = 1;
  });

  builder.addCase(setAdminConnectionsKeyword, (state, { payload }) => {
    if (state.keyword === payload) {
      return;
    }
    state.keyword = payload;
    state.currentPage = 1;
  });

  builder.addCase(setAdminConnectionsPage, (state, { payload }) => {
    state.currentPage = payload;
  });
});
