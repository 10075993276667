import { useEffect, useRef } from 'react';

export const Privacy = () => {
  const linkRef = useRef<HTMLAnchorElement | null>(null);

  useEffect(() => {
    linkRef?.current?.click();
  }, []);
  return (
    <a
      href={
        window.location.hostname === 'app.kitchenmate.com.au'
          ? 'https://my.kitchenmate.com.au/privacy_policy'
          : 'https://theopenpantry.com/privacy'
      }
      ref={linkRef}
    />
  );
};
