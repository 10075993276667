import { createAction } from '@reduxjs/toolkit';
import { SalesFilters, SalesSortBy } from '../../api/sales/types';

export const setSalesPage = createAction<number>('SET_SALES_PAGE');

export const setSalesKeyword = createAction<string | undefined>('SET_SALES_KEYWORD');

export const clearSalesList = createAction<boolean>('CLEAR_SALES_LIST');

export const setSalesSortFilters = createAction<{ sortBy?: SalesSortBy; filter?: SalesFilters }>('SET_SALES_SORT_FILTERS');
