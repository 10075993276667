import * as React from 'react';

function SvgFile(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 34 44' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M29.688 44C32.072 44 34 42.057 34 39.703V10.064a.931.931 0 00-.28-.657L24.526.308a.924.924 0 00-.57-.267l-.239-.03c-.031 0-.062-.011-.093-.011H4.312C1.938 0 0 1.943 0 4.297v35.396c0 2.364 1.938 4.297 4.312 4.297h25.376V44zM24.774 3.177l6.064 5.973h-6.064V3.177zM1.866 39.703V4.297A2.458 2.458 0 014.312 1.85h18.596v8.235c0 .514.425.915.933.915h8.293v28.703a2.458 2.458 0 01-2.446 2.447H4.312a2.458 2.458 0 01-2.446-2.447z'
        fill='currentColor'
      />
    </svg>
  );
}

export default SvgFile;
