import React, { useEffect, useRef } from 'react';

import { FastField, Form, Formik, FormikHelpers } from 'formik';

import { useUpdateRecOrderMutation } from '../../../../../../../api/recurring-order';
import { useGetSelectedRecOrder } from '../../../../../../../api/recurring-order/hooks';
import { ThemedButton } from '../../../../../../../shared/components/themed-button';
import { FormikInput } from '../../../../../../../shared/components/formik-input';
import { OverlayModal } from '../../../../../../../shared/components/overlay-modal';
import { useScreenSize } from '../../../../../../../shared/hooks/use-screen-size';

import { useStyles } from './style';

interface Props {
  isOpened: boolean;
  onClose: () => void;
}

interface Values {
  name: string;
}

export const RenameRecOrderModal: React.FC<Props> = ({ isOpened, onClose }) => {
  const classes = useStyles();
  const { isMobile } = useScreenSize();
  const ref = useRef<HTMLFormElement | null>(null);

  const { selectedRecOrder } = useGetSelectedRecOrder();
  const [renameRecOrder, { isSuccess, isLoading }] = useUpdateRecOrderMutation();

  const validateForm = (values: Values) => {
    const errors: Partial<Values> = {};
    if (values.name.length > 20) {
      errors.name = 'Too long';
    }
    return errors;
  };

  const onSubmit = (values: Values, formikHelpers: FormikHelpers<Values>) => {
    formikHelpers.setSubmitting(false);
    if (!values.name.trim()) {
      return;
    }
    selectedRecOrder &&
      renameRecOrder({
        id: selectedRecOrder.id,
        name: values.name,
      });
  };

  const resetField = () => {
    onClose();
    ref.current?.reset();
  };

  useEffect(() => {
    if (isSuccess) {
      onClose();
      ref.current?.reset();
    }
  }, [isSuccess]);

  return (
    <OverlayModal onClose={onClose} isOpen={isOpened} boxClassName={classes.modalBox}>
      <>
        <h3 className={classes.modalTitle}>Rename Recurring Order</h3>
        <Formik
          enableReinitialize={true}
          validate={validateForm}
          onSubmit={onSubmit}
          initialValues={{
            name: selectedRecOrder?.name || '',
          }}
        >
          {({ submitForm }) => (
            <Form ref={ref}>
              <div className={classes.field}>
                <FastField component={FormikInput} name='name' />
              </div>
              <div className={classes.btnBox}>
                <ThemedButton onClick={resetField} title='Cancel' buttonStyle='secondary' isSmall={isMobile} width={105} />
                <ThemedButton onClick={submitForm} title='Save Name' isSmall={isMobile} width={105} disabled={isLoading} />
              </div>
            </Form>
          )}
        </Formik>
      </>
    </OverlayModal>
  );
};
