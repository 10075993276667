import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';
import { Sizes } from '../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      marginBottom: 16,
      width: 430,
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: '100%',
        display: 'block',
      },
    },
    root: {
      width: 218,
      height: 102,
      backgroundColor: colorVariables.white,
      borderRadius: 12,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 6,
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: 250,
      },
    },
    uploadLogoContainer: {
      border: '0.6px dashed #152144',
      borderRadius: 10,
      margin: '15px auto',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px 10px',
      textAlign: 'center',
    },
    uploadIcon: {
      width: 60,
      height: 60,
    },
    uploadDescription: {
      fontSize: 9.6,
      color: colorVariables.navy,
      marginTop: 10,
      fontWeight: 700,
      [theme.breakpoints.down(Sizes.desktop)]: {
        display: 'none',
      },
    },
    uploadDescriptionBtn: {
      fontSize: 8.5,
      textDecoration: 'underline',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    cropTitle: {
      fontSize: 16,
      marginBottom: 10,
    },
    cropContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      maxHeight: 500,
      overflow: 'scroll',
      [theme.breakpoints.down(Sizes.mobile)]: {
        maxHeight: 300,
      },
      '& img': {
        width: '100%',
        maxWidth: 450,
        [theme.breakpoints.down(Sizes.mobile)]: {
          maxWidth: 250,
        },
      },
    },
    btnBlock: {
      width: '100%',
      justifyContent: 'center',
      display: 'flex',
      marginTop: 30,
    },
    boldText: {
      fontWeight: 700,
      fontSize: 12,
    },
    topBox: {
      display: 'flex',
      width: '100%',
      marginBottom: 5,
      justifyContent: 'space-between',
    },
    previewContainer: {
      position: 'relative',
      width: '100%',
      maxHeight: 200,
      marginBottom: 5,
      overflow: 'hidden',
      '& img': {
        maxWidth: '100%',
      },
    },
    uploadAnother: {
      textDecoration: 'underline',
      cursor: 'pointer',
      fontSize: 11,
      fontWeight: 700,
      opacity: 0.7,
    },
    modal: {
      width: 450,
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '86vw',
        maxHeight: '75vh',
        maxWidth: '100vw',
      },
    },
    mobileUploadBtn: {
      display: 'none',
      [theme.breakpoints.down(Sizes.desktop)]: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
    uploadMobileTExt: {
      fontWeight: 700,
      textTransform: 'uppercase',
      marginTop: 5,
    },
    uploadTitle: {
      fontSize: 16,
      lineHeight: '22px',
      fontWeight: 700,
      marginBottom: 8,
    },
    uploadText: {
      fontSize: 12,
      lineHeight: '22px',
      opacity: 0.7,
      '&>span': {
        fontWeight: 700,
        textDecoration: 'underline',
        cursor: 'pointer',
      },
      [theme.breakpoints.down(Sizes.desktop)]: {
        maxWidth: 300,
        marginBottom: 10,
      },
    },
  }),
);
