import * as React from 'react';

function SvgCustomerIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M18.333 0H5a3.333 3.333 0 00-3.333 3.333v.834H.833a.833.833 0 100 1.666h.834v3.334H.833a.833.833 0 100 1.666h.834v3.334H.833a.833.833 0 100 1.666h.834v.834A3.333 3.333 0 005 20h13.333c.92 0 1.667-.746 1.667-1.667V1.667C20 .747 19.254 0 18.333 0zm0 18.333H5c-.92 0-1.667-.746-1.667-1.666v-.834h.834a.833.833 0 100-1.666h-.834v-3.334h.834a.833.833 0 100-1.666h-.834V5.833h.834a.833.833 0 100-1.666h-.834v-.834c0-.92.747-1.666 1.667-1.666h13.333v16.666z'
        fill='currentColor'
      />
      <path
        d='M7.5 15.833c.46 0 .833-.373.833-.833v-.833a3.333 3.333 0 016.667 0V15a.833.833 0 101.667 0v-.833a5 5 0 00-2.588-4.38 3.333 3.333 0 10-4.825.001 4.999 4.999 0 00-2.587 4.379V15c0 .46.373.833.833.833zM10 7.5a1.667 1.667 0 113.333 0A1.667 1.667 0 0110 7.5z'
        fill='currentColor'
      />
    </svg>
  );
}

export default SvgCustomerIcon;
