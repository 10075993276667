import { useEffect } from 'react';

import { useAppSelector } from '../../store';
import { getCustomersSortFilters } from '../../store/customers';
import { getCustomersPerformanceSortFilters } from '../../store/customers-performance';
import { getOrdersSortFilters } from '../../store/orders';
import { getProductsPurchasedSortFilters } from '../../store/products-purchased';
import { getRecurringOrderSortFilters } from '../../store/recurring-order';
import { getSupplierKeyword } from '../../store/suppliers-categories';
import { setGtmDatalayer } from '../helpers/setGtmDatalayer';

export const useSetKeywordToDatalayer = () => {
  const { keyword: customersKeyword } = useAppSelector(getCustomersSortFilters);
  const { keyword: ordersKeyword } = useAppSelector(getOrdersSortFilters);
  const { keyword: performanceKeyword } = useAppSelector(getCustomersPerformanceSortFilters);
  const { keyword: purchasedKeyword } = useAppSelector(getProductsPurchasedSortFilters);
  const { keyword: recOrderKeyword } = useAppSelector(getRecurringOrderSortFilters);
  const supplierKeyword = useAppSelector(getSupplierKeyword);

  useEffect(() => {
    if (customersKeyword) {
      setGtmDatalayer({
        event: 'search',
        eventCategory: customersKeyword,
      });
    }
  }, [customersKeyword]);

  useEffect(() => {
    if (ordersKeyword) {
      setGtmDatalayer({
        event: 'search',
        eventCategory: ordersKeyword,
      });
    }
  }, [ordersKeyword]);

  useEffect(() => {
    if (performanceKeyword) {
      setGtmDatalayer({
        event: 'search',
        eventCategory: performanceKeyword,
      });
    }
  }, [performanceKeyword]);

  useEffect(() => {
    if (purchasedKeyword) {
      setGtmDatalayer({
        event: 'search',
        eventCategory: purchasedKeyword,
      });
    }
  }, [purchasedKeyword]);

  useEffect(() => {
    if (supplierKeyword) {
      setGtmDatalayer({
        event: 'search',
        eventCategory: supplierKeyword,
      });
    }
  }, [supplierKeyword]);

  useEffect(() => {
    if (recOrderKeyword) {
      setGtmDatalayer({
        event: 'search',
        eventCategory: recOrderKeyword,
      });
    }
  }, [recOrderKeyword]);
};
