import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../styles/colorVariables';

export const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: 0,
      minWidth: 200,
      maxWidth: 500,
      marginTop: 10,
    },
    dropdownItem: {
      overflow: 'hidden',
      padding: 0,
      '&:hover, &:focus': {
        backgroundColor: 'rgba(51, 168, 226, 0.05)',
      },
      '&:hover a, &:focus a': {
        color: colorVariables.blue,
      },
    },
    triggerWrap: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    customerDropdown: {
      // backgroundColor: colorVariables.navy,
    },
    customerMenuItem: {
      overflow: 'hidden',
      padding: 0,
      /*     '&:hover, &:focus': {
        background: 'linear-gradient(90deg, rgba(81, 217, 176, 0.1) 0%, rgba(21, 33, 68, 0.5) 100%)',
      },
      '&:hover a, &:focus a, &:hover svg, &:focus svg': {
        color: colorVariables.white,
      },*/
    },
    triggerWrapOpened: {
      '&>div': {
        backgroundColor: 'rgba(152, 165, 183, 0.15)',
        '& *': {
          color: colorVariables.white,
        },
      },
    },
  }),
);
