import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../../../styles/colorVariables';
import { Sizes } from '../../../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'none',
      [theme.breakpoints.down(Sizes.desktop)]: {
        display: 'block',
        position: 'fixed',
        top: 63,
        right: 0,
        left: 0,
        height: 'calc(100vh - 63px)',
        backgroundColor: colorVariables.white,
        zIndex: 999,
        transition: 'top ease-in-out 0.5s',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        top: 50,
        height: 'calc(100vh - 50px)',
      },
    },
    rootClosed: {
      [theme.breakpoints.down(Sizes.desktop)]: {
        top: '120vh',
        transition: 'top ease-in-out 0.5s',
      },
    },
    head: {
      height: 56,
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      padding: '0 15px',
      justifyContent: 'space-between',
      borderBottom: `1px solid ${colorVariables.grey60}`,
    },
    modalTitle: {
      fontSize: 16,
      lineHeight: '22px',
      color: colorVariables.userNavy,
      fontWeight: 700,
    },
    clearBtn: {
      fontSize: 16,
      lineHeight: '22px',
      color: colorVariables.navy,
    },
    box: {
      padding: '30px 20px',
      height: 'calc(100vh - 106px)',
      overflowY: 'scroll',
    },
    sortBlock: {
      marginBottom: 50,
    },
    blockTitle: {
      fontSize: 12,
      lineHeight: '22px',
      textTransform: 'uppercase',
      color: colorVariables.navy,
      fontWeight: 700,
      marginBottom: 15,
    },
    btnBox: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: 40,
    },
    categoriesBox: {
      display: 'flex',
      padding: '0 20px',
      margin: '0 auto 20px auto',
      flexWrap: 'wrap',
    },
    categoryItem: {
      width: '45%',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
      },
    },
  }),
);
