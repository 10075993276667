import { restaurantsSortParams } from '../../../components/AdminDrawer/Restaurants/RestaurantsList/restaurants-filter';
import { RootState } from '../../index';

export const getRestaurantsSortFilters = (state: RootState) => ({
  sort: state.restaurants.sortBy,
  keyword: state.restaurants.keyword,
  currentPage: state.restaurants.currentPage,
});

export const getRestaurantsChips = (state: RootState) => {
  const sortLabel = restaurantsSortParams.find((param) => param.value === state.restaurants.sortBy?.join(' '))?.label || '';

  const sortChip = {
    label: sortLabel,
    key: sortLabel ? 'sort' : '',
  };

  return sortChip.label && sortChip.key ? [sortChip] : [];
};

export const getSelectedRestaurants = (state: RootState) => state.restaurants.selectedUsers;
