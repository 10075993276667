import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';
import { Sizes } from '../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    dropdownContainer: {
      width: 450,
      padding: '30px 20px',
      position: 'relative',
      borderRadius: 10,
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        paddingBottom: 10,
      },
    },
    trigger: {
      display: 'flex',
      width: 165,
      alignItems: 'center',
      cursor: 'pointer',
    },
    categoriesBox: {
      marginBottom: 30,
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginBottom: 20,
      },
    },
    categoryItem: {
      width: '48%',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
      },
    },
    btnBox: {
      display: 'flex',
      justifyContent: 'center',
    },
    chevron: {
      color: colorVariables.grey60,
    },
    categoryText: {
      color: colorVariables.userNavy,
      fontWeight: 600,
      lineHeight: '24px',
    },
  }),
);
