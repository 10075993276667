import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import { format } from 'date-fns';

import { useGeIsTeammateAdmin } from '../../../api/teammates/hooks';
import { useGetAddressQuery, useMeQuery, useUpdateDeliveryWindowsMutation } from '../../../api/user';
import { DeliveryWindows } from '../../../api/user/types';
import { AddTitledButton } from '../../../shared/components/add-titled-button';
import { ThemedButton } from '../../../shared/components/themed-button';
import { NavbarTitle } from '../../../shared/components/navbar-title';
import { week } from '../../../shared/constants/week';
import { setGtmDatalayer } from '../../../shared/helpers/setGtmDatalayer';
import { useScreenSize } from '../../../shared/hooks/use-screen-size';
import { ToastService } from '../../../shared/services/toastService';
import { useAppDispatch, useAppSelector } from '../../../store';
import { getIsRestaurant, openAccountMenu, openMainMenu } from '../../../store/user';
import { MainNavBar } from '../../AppDrawer/MainNavBar';
import { TopNavBar } from '../../AppDrawer/MainNavBar/top-nav-bar';
import { AccountMenu } from '../account-menu';

import { useStyles } from './style';

export const VenueInfo: React.FC = () => {
  const classes = useStyles();
  const { push } = useHistory();
  const { isMobile, isTablet } = useScreenSize();
  const dispatch = useAppDispatch();

  const [deliveryTime, setDeliveryTime] = useState<DeliveryWindows[]>([]);

  const isRestaurant = useAppSelector(getIsRestaurant);
  const isAdminTeammate = useGeIsTeammateAdmin();
  const isViewer = !isAdminTeammate;

  const [updateDeliveryWindows] = useUpdateDeliveryWindowsMutation();
  const { addresses = [] } = useGetAddressQuery(undefined, {
    selectFromResult: ({ data }) => ({
      addresses: data?.addresses,
    }),
  });
  const { deliveryWindows } = useMeQuery(undefined, {
    selectFromResult: ({ data }) => ({
      deliveryWindows: data?.delivery_windows,
    }),
  });

  const openAddAddress = () => {
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    push('/account/venue/add');
  };

  const openEditAddress = (id: number) => {
    push(`/account/venue/edit`, id);
  };

  const openMenus = () => {
    dispatch(openMainMenu());
    dispatch(openAccountMenu());
  };

  const getTime = (date: string | undefined): Date | null => {
    return date ? new Date(date) : null;
  };

  const updateDeliveryWindowsHandler = () => {
    setGtmDatalayer({
      event: 'delivery',
      eventCategory: 'delivery_window_update',
    });
    if (deliveryTime) {
      updateDeliveryWindows({
        user: {
          delivery_windows_attributes: deliveryTime,
        },
      });
    }
  };

  useEffect(() => {
    deliveryWindows && setDeliveryTime(deliveryWindows);
  }, [deliveryWindows]);

  return (
    <>
      <TopNavBar
        isAccount={true}
        bottomComponent={isMobile ? <NavbarTitle title='Venue Information' showBackBtn={true} backHandler={openMenus} /> : undefined}
      />
      <MainNavBar isAccount={true} />
      <div className={clsx(classes.accountContainer, isRestaurant && classes.accountContainerCustomer)}>
        <div className={classes.accountHeader}>
          <div className={classes.accountTitle}>My Account</div>
        </div>
        <div className={classes.accountBody}>
          <AccountMenu showBack={!isRestaurant} />
          <div className={classes.accountContent}>
            {isRestaurant && isTablet && (
              <div className={classes.tabletTitleCustomer}>
                <NavbarTitle title='Venue Information' showBackBtn={true} backHandler={openMenus} />
              </div>
            )}
            <div className={classes.titleBox}>
              <h2 className={classes.title}>Venue Information</h2>
            </div>
            <div className={classes.manageAddressBox}>
              <div className={classes.sectionHead}>
                <h4 className={classes.sectionTitle}>Manage Address</h4>
                {!isMobile && <ThemedButton title='Add new address' onClick={openAddAddress} buttonStyle='blueTransparent' />}
              </div>
              <AddTitledButton title='Add new address' customClass={classes.mobileAddBtn} onClick={openAddAddress} />
              <div>
                {addresses?.map(({ full_address, default_address, id, state, postcode, city, first_name, street_address1 }, idx) => (
                  <div className={classes.addressItem} key={id} onClick={openEditAddress.bind(null, id)}>
                    <div>
                      <div className={classes.addressLineName}>
                        <span>{first_name || `Delivery Address ${idx + 1}`}</span>
                        {default_address && <span className={classes.defaultBadge}>Default</span>}
                      </div>
                      <div className={classes.addressLineInfo}>
                        <span className={classes.greyText}>{street_address1 || full_address},&nbsp;</span>
                        <span className={classes.greyText}>{city}&nbsp;</span>
                        <span className={classes.greyText}>{state.toUpperCase()}&nbsp;</span>
                        <span className={classes.greyText}>{postcode}</span>
                      </div>
                    </div>
                    <ChevronRightIcon className={classes.chevron} />
                  </div>
                ))}
              </div>
            </div>
            {false && (
              <div className={classes.deliveryBox}>
                <div className={classes.sectionHead}>
                  <h4 className={classes.sectionTitle}>Delivery window</h4>
                </div>
                <div>
                  <div className={classes.daysHead}>
                    <div>FROM</div>
                    <div>TO</div>
                  </div>
                  {week.map((day) => {
                    const selectedDay = deliveryTime?.find((item) => item.day === day.longTitle);
                    return (
                      <div key={day.key} className={classes.dayRow}>
                        <div className={classes.dayName}>{day.longTitle}</div>
                        <div className={classes.disabledTime}>{format(new Date(getTime(selectedDay?.from_time) || 0), 'HH:mm')}</div>
                        <div className={classes.disabledTime}>{format(new Date(getTime(selectedDay?.to_time) || 0), 'HH:mm')}</div>
                        {/*<CustomTimePicker*/}
                        {/*  time={getTime(selectedDay?.from_time)}*/}
                        {/*  onSetTime={(value) => onChangeStartTime(value, day.longTitle)}*/}
                        {/*/>*/}
                        {/*<CustomTimePicker*/}
                        {/*  error={*/}
                        {/*    selectedDay?.to_time && selectedDay?.from_time && isTimeBefore(selectedDay?.to_time, selectedDay?.from_time)*/}
                        {/*      ? 'Invalid value'*/}
                        {/*      : undefined*/}
                        {/*  }*/}
                        {/*  time={getTime(selectedDay?.to_time)}*/}
                        {/*  onSetTime={(value) => onChangeEndTime(value, day.longTitle)}*/}
                        {/*/>*/}
                      </div>
                    );
                  })}
                  <div className={classes.updateBtn}>
                    <ThemedButton
                      onClick={updateDeliveryWindowsHandler}
                      disabled={true}
                      title='Update'
                      buttonStyle='secondary'
                      width={280}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
