import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';

export const useStyles = makeStyles(() =>
  createStyles({
    addBtnBox: {
      display: 'flex',
      cursor: 'pointer',
    },
    addIconWrap: {
      width: 34,
      height: 34,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      backgroundColor: colorVariables.blueLight,
      marginRight: 10,
      '&:hover': {
        backgroundColor: colorVariables.blueLight,
      },
      '& svg': {
        color: colorVariables.blue,
      },
    },
    addText: {
      textTransform: 'uppercase',
      fontSize: 12,
      color: colorVariables.blue,
      fontWeight: 600,
      lineHeight: '34px',
    },
  }),
);
