import { useMemo } from 'react';

import { categoriesForPermission, ProductsCategories } from '../../shared/constants/products';
import { useAppSelector } from '../../store';
import { getTeammatesKeyword, getTeammatesSort } from '../../store/teammates';
import { useMeQuery } from '../user';
import { Roles } from '../user/types';
import { useGetTeammatesQuery } from './index';
import { TeammatePermission } from './types';

export const useGetTeammatesList = () => {
  const sort = useAppSelector(getTeammatesSort);
  const keyword = useAppSelector(getTeammatesKeyword);

  const { data, isFetching } = useGetTeammatesQuery({ sort, keyword });

  return { list: data?.teammates, loading: isFetching };
};

export const useSelectedTeammateById = (id: number | null) => {
  const { list } = useGetTeammatesList();

  return useMemo(() => {
    return list?.find((el) => el.id === id);
  }, [list, id]);
};

export const useGeIsTeammateViewer = (perPagePermission?: string) => {
  const { data: user } = useMeQuery();

  return (
    user?.is_teammate &&
    user.company?.company_type === Roles.CUSTOMER &&
    (user.permission === TeammatePermission.VIEWER ||
      (user.permission === TeammatePermission.CUSTOM &&
        user?.custom_permission &&
        perPagePermission &&
        // @ts-ignore
        (user.custom_permission[perPagePermission]?.endsWith('_view') ||
          // @ts-ignore
          user.custom_permission[perPagePermission]?.endsWith('_hidden'))))
  );
};

export const useGeHiddenPages = (pageToCheck?: string) => {
  const { data: user } = useMeQuery();

  const hiddenPages = useMemo(() => {
    const hiddenListByPermission =
      user?.is_teammate &&
      user.company?.company_type === Roles.CUSTOMER &&
      user.permission === TeammatePermission.CUSTOM &&
      user?.custom_permission
        ? Object.entries(user.custom_permission).reduce((acc: string[], el) => {
            if (typeof el[1] === 'string' && el[1].endsWith('_hidden')) {
              acc.push(el[0]);
            }
            return acc;
          }, [])
        : [];
    if (
      user?.is_teammate &&
      user.company?.company_type === Roles.CUSTOMER &&
      (user.permission === TeammatePermission.VIEWER ||
        (user.permission === TeammatePermission.CUSTOM && user.custom_permission?.reports === 'reports_view'))
    ) {
      hiddenListByPermission.push('reports');
    }
    return hiddenListByPermission;
  }, [user]);

  if (user?.company?.company_type && user?.company.company_type !== Roles.CUSTOMER) {
    return false;
  }

  if (pageToCheck) {
    return hiddenPages.includes(pageToCheck);
  } else {
    return hiddenPages as string[];
  }
};

export const useGeIsTeammateAdmin = () => {
  const { data: user } = useMeQuery();
  return (
    (user?.is_teammate && user.permission === TeammatePermission.ADMIN && user.company?.company_type === Roles.CUSTOMER) ||
    !user?.is_teammate
  );
};

export const useGetAvailableCategories = () => {
  const { data: user } = useMeQuery();
  return useMemo(() => {
    if (user?.is_teammate && user.company?.company_type === Roles.CUSTOMER && user.custom_permission) {
      return Object.entries(user.custom_permission.product_type_categories).reduce((acc: ProductsCategories[], el) => {
        const category = categoriesForPermission.find((cat) => cat.value === el[0]);
        if (el[1] && category) {
          acc.push(category.label);
        }
        return acc;
      }, []);
    } else {
      return null;
    }
  }, [user]);
};
