import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../../styles/colorVariables';
import { Sizes } from '../../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    formBox: {
      height: 'calc(100% - 88px)',
      [theme.breakpoints.up(Sizes.mobile)]: {
        width: 350,
      },
      [theme.breakpoints.between(Sizes.mobile, Sizes.desktop)]: {
        maxHeight: 'calc(100vh - 140px)',
        overflowY: 'scroll',
      },
    },
    root: {
      position: 'relative',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    sortBlock: {
      width: '90%',
      margin: '20px auto',
      [theme.breakpoints.up(Sizes.desktop)]: {
        display: 'none',
      },
    },
    blockTitle: {
      fontSize: 12,
      fontWeight: 700,
      color: colorVariables.navy,
      marginBottom: 15,
      textTransform: 'uppercase',
      [theme.breakpoints.up(Sizes.desktop)]: {
        marginBottom: 25,
      },
    },
    btnBlock: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 20,
      padding: '20px 0',
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: '40px 20px',
        justifyContent: 'center',
      },
      [theme.breakpoints.down(400)]: {
        padding: '30px 10px',
        '& button': {
          minWidth: '40%',
        },
      },
    },
    btnBlockFullScreen: {
      marginTop: 'auto',
    },
  }),
);
