import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import useTimeout from '../../shared/hooks/use-timeout';
import { RootState, useAppDispatch, useAppSelector } from '../../store';
import {
  getRecurringOrderSortFilters,
  getSelectedRecurringOrderId,
  recOrderSelectors,
  setRecOrderKeyword,
  setRecOrderPage,
  setRecurringOrderSortFilters,
} from '../../store/recurring-order';
import { getIsRestaurant } from '../../store/user';
import { useGetRecOrderByIdQuery, useGetRecOrdersQuery } from './index';

export const useGetSelectedRecOrder = () => {
  const id = useAppSelector(getSelectedRecurringOrderId) as number;
  const selectedRecOrder = useAppSelector((state: RootState) => recOrderSelectors.selectById(state, id || 0));
  const { data: recOrder } = useGetRecOrderByIdQuery(id, { skip: !id || !!selectedRecOrder });
  return { selectedRecOrder: selectedRecOrder || recOrder };
};

export const useGetProductsQuantityInRecOrder = (productId: number) => {
  const { selectedRecOrder } = useGetSelectedRecOrder();
  return selectedRecOrder ? selectedRecOrder.recurring_order_items.find((el) => el.product.id === productId)?.quantity || 0 : 0;
};

export const useGetRecOrders = (inView: boolean) => {
  const dispatch = useAppDispatch();
  const { keyword, currentPage, sort, filter } = useAppSelector(getRecurringOrderSortFilters);
  const { next_page, loading } = useGetRecOrdersQuery(
    { keyword, currentPage, sort, filter },
    {
      selectFromResult: ({ data, isFetching }) => ({
        loading: isFetching,
        next_page: data?.next_page,
      }),
    },
  );
  useEffect(() => {
    inView && next_page && dispatch(setRecOrderPage(next_page));
  }, [inView, next_page]);
  return { loading };
};

export const useResetRecOrdersSearch = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const isRestaurant = useAppSelector(getIsRestaurant);
  const { keyword, sort, filter } = useAppSelector(getRecurringOrderSortFilters);
  useTimeout(
    () => {
      dispatch(setRecOrderKeyword(''));
    },
    pathname.includes('/recurring_orders') || !keyword ? null : 300000,
  );
  if (pathname !== '/pantry_list/recurring_orders' && pathname !== '/pantry_list/recurring_orders/details' && isRestaurant && filter) {
    dispatch(setRecurringOrderSortFilters({ filter: null, sort }));
  }
};
