import { SuccessResponse } from '../index';
import { SortDir } from '../product/types';
import { Roles } from '../user/types';

export interface CreateCustomerRequest {
  customer_id?: string;
  delivery_number?: string;
  company: {
    name?: string;
    users_attributes: [
      {
        first_name?: string;
        email?: string;
        phone_number?: string;
        connection_details_attributes: [
          {
            payment_method?: number;
          },
        ];
      },
    ];
  };
  price_list_name?: string;
  price_list_id?: number;
}

export interface CreateCustomerResponse extends SuccessResponse {
  already_connected: boolean;
}

export interface UpdateCustomerRequest {
  updatedCustomerId: number;
  company?: {
    id: number;
    users_attributes: [
      {
        id: number;
        connection_details_attributes?: [
          {
            id?: number;
            charge_delivery?: boolean;
            stop_credit?: boolean;
            payment_method?: number;
          },
        ];
      },
    ];
  };
  customer_id?: string;
  delivery_number?: string;
  price_list_name?: string;
  price_list_id?: number;
}

export enum CustomerConnectionStatus {
  ACCEPTED = 'accepted',
  PENDING = 'pending',
  NEW_REQUEST = 'new_request',
}

export interface GetCustomerListReqParams {
  sort?: CustomersSortBy;
  filter?: CustomerFilterValues;
  keyword?: string;
  currentPage?: number;
  perPage?: number;
}

export interface CustomerFilterValues {
  user_price_lists_name?: string[];
  status_in?: string[];
}

export type CustomerField =
  | 'user_company_name'
  | 'user_first_name'
  | 'user_price_lists_name'
  | 'sales_to_date'
  | 'status'
  | 'last_order_date';

export type CustomersSortBy = [CustomerField, SortDir];

export interface CustomerQueryParams {
  'q[sort_by]'?: string;
  'q[user_price_lists_name]'?: string;
  'q[status_in]'?: string;
  'q[keyword]'?: string;
}

export interface SocialContact {
  id?: number;
  first_name?: string;
  last_name?: string;
  email: string;
  company_type?: Roles;
  company_name?: string;
  company_id?: number;
  auth_from?: string;
  display_name?: string;
}

export interface GetSocialContactsParams {
  social_auth_type: string;
  access_token: string;
}

export interface GetSocialContactsResponse extends SuccessResponse {
  on_system_users: SocialContact[];
  not_on_system_users: { email: string; display_name?: string; auth_from?: string }[];
}

export interface CreateSocialCustomersRequest {
  customers: {
    email: string;
    sign_up_status?: string;
    first_name?: string;
    last_name?: string;
  }[];
}

export interface ExportedCustomer {
  'First Name'?: string;
  'Last Name'?: string;
  'Company Name'?: string;
  'Email*'?: string;
  'Phone Number'?: string;
}

export interface ExportCustomersResponse extends SuccessResponse {
  data: ExportedCustomer[];
}

export interface PreviewCustomersResponse extends SuccessResponse {
  added: PreviewCustomer[];
  changed: PreviewCustomer[];
  error_data: PreviewCustomerWithError[];
  unchanged: PreviewCustomer[];
}

export interface PreviewCustomer {
  company_name: string | null;
  email?: string;
  first_name: string | null;
  last_name: string | null;
  phone_number: string | null;
}

export interface PreviewCustomerWithError extends PreviewCustomer {
  field_errors: { [key: string]: { [key: string]: string } };
}
