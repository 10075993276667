import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import clsx from 'clsx';

import { useUpdateRestaurantProfileMutation } from '../../../../api/company';
import { useGeIsTeammateAdmin } from '../../../../api/teammates/hooks';
import {
  useGetPaymentMethodsQuery,
  useMeQuery,
  useRemovePaymentMethodMutation,
  useSetDefaultPaymentMethodMutation,
} from '../../../../api/user';
import { RestaurantUser } from '../../../../api/user/types';
import SvgTrash from '../../../../assets/icons/Trash';
import { AddTitledButton } from '../../../../shared/components/add-titled-button';
import { ThemedButton } from '../../../../shared/components/themed-button';
import { Dialog } from '../../../../shared/components/dialog';
import { useScreenSize } from '../../../../shared/hooks/use-screen-size';
import { ToastService } from '../../../../shared/services/toastService';
import { useAppSelector } from '../../../../store';
import { getIsRestaurant } from '../../../../store/user';

import { useStyles } from './style';

export enum MethodType {
  card = 'credit_card',
  account = 'on_account',
}

export const PaymentMethods: React.FC = () => {
  const { push } = useHistory();
  const classes = useStyles();
  const { isTablet } = useScreenSize();
  const isRestaurant = useAppSelector(getIsRestaurant);

  const [currentId, setCurrentId] = useState<string | undefined>();
  const [selectedMethod, setSelectedMethod] = useState<MethodType>(MethodType.account);

  const { data: user } = useMeQuery();
  const isAdminTeammate = useGeIsTeammateAdmin();
  const isViewer = !isAdminTeammate;
  const stripe_id = isRestaurant ? (user as RestaurantUser)?.stripe_id : null;
  const { data } = useGetPaymentMethodsQuery((user as RestaurantUser)?.stripe_id as string, { skip: !(user as RestaurantUser)?.stripe_id });
  const [removeCard] = useRemovePaymentMethodMutation();
  const [setDefault] = useSetDefaultPaymentMethodMutation();
  const [updateProfile] = useUpdateRestaurantProfileMutation();

  const onMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    const valueToSet = event.target.value as MethodType;
    setSelectedMethod(valueToSet);
    user &&
      updateProfile({
        id: user.company.id,
        company: {
          users_attributes: {
            id: user.id,
            payment_method_type: valueToSet === MethodType.account ? 0 : 1,
          },
        },
      });
  };

  const onRemoveCard = () => {
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    currentId && stripe_id && removeCard({ method_id: currentId, stripe_id });
    setCurrentId(undefined);
  };
  const openAddPayment = () => {
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    push(selectedMethod === MethodType.card ? '/account/billing/payment/add' : '/account/billing/payment/add/account');
  };

  const onSetDef = (e: any, method_id: string) => {
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    e.stopPropagation();
    stripe_id &&
      setDefault({
        stripe_id,
        method_id,
      });
  };

  useEffect(() => {
    if (user && 'payment_method_type' in user && user?.payment_method_type) {
      setSelectedMethod(user.payment_method_type);
    }
  }, [user]);

  return (
    <div className={classes.root}>
      <div className={classes.head}>
        <div className={classes.headtitle}>Payment Method</div>
        <ThemedButton
          onClick={openAddPayment}
          title='Add payment method'
          buttonStyle='blueTransparent'
          customClass={classes.addPaymentBtn}
          width={200}
          disabled={selectedMethod === MethodType.account}
        />
      </div>
      <div className={classes.methodTypes}>
        <div>How would you normally like to pay for your purchases?</div>
        <RadioGroup value={selectedMethod} name='metjod' className={classes.radioRootPlan} onChange={onMethodChange}>
          <div className={clsx([classes.radioWrapPlan, selectedMethod === MethodType.card && classes.checkedBoxPlan])}>
            <FormControlLabel
              value={MethodType.card}
              control={<Radio size={'small'} classes={{ root: classes.radio, checked: classes.checked }} color='default' />}
              label='Credit Card'
            />
          </div>
          <div className={clsx([classes.radioWrapPlan, selectedMethod === MethodType.account && classes.checkedBoxPlan])}>
            <FormControlLabel
              value={MethodType.account}
              control={<Radio size={'small'} classes={{ root: classes.radio, checked: classes.checked }} color='default' />}
              label='On Account'
            />
          </div>
        </RadioGroup>
      </div>
      {selectedMethod === MethodType.card && (
        <AddTitledButton title='Add payment method' customClass={classes.addPaymentMobile} onClick={openAddPayment} />
      )}
      <div className={clsx({ [classes.mobileDivider]: !isRestaurant && !isTablet })} />
      <div className={classes.paymentMethodsList}>
        {Array.isArray(data?.payment_methods)
          ? data?.payment_methods.map((el) => (
              <div className={classes.paymentMethod} key={el.id}>
                <div className={classes.paymentMethodContent}>
                  <div className={classes.paymentMethodName}>
                    <span>{el.billing_details?.name}</span>
                  </div>
                  <div className={classes.paymentMethodInfo}>
                    <span className={classes.greyText}>{el.card?.brand || 'Card'}</span>
                    <span className={classes.greyText}>**** {el.card?.last4}</span>
                  </div>
                </div>
                <div className={classes.action}>
                  {data?.default_method === el.id ? (
                    <div className={classes.defaultBadge}>Default</div>
                  ) : (
                    <ThemedButton onClick={(e) => onSetDef(e, el.id as string)} title='Set as Default' buttonStyle='blueTransparent' />
                  )}
                </div>
                <SvgTrash className={classes.deletePaymentMethod} onClick={setCurrentId.bind(null, el.id)} />
              </div>
            ))
          : null}
      </div>
      {!!currentId && (
        <Dialog
          title={`Are you sure you want to delete this payment method`}
          onConfirm={onRemoveCard}
          onCancel={setCurrentId.bind(null, undefined)}
          customClass={classes.dialog}
        />
      )}
    </div>
  );
};
