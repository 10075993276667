import { createStyles, makeStyles } from '@material-ui/core';
import { colorVariables } from '../../../../../../styles/colorVariables';
import { Sizes } from '../../../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      padding: '30px 50px 0 50px',
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: '0 0 20px 0',
      },
    },
    headAction: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    filtersBlock: {
      display: 'flex',
      alignItems: 'center',
    },
    dropdownTrigger: {
      color: colorVariables.navy,
      fontWeight: 400,
      '& svg': {
        color: colorVariables.grey60,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        margin: '8px 0 8px 15px',
      },
    },
    filterIcon: {
      position: 'relative',
      marginRight: 20,
    },
    formControl: {
      maxWidth: 280,
    },
    tableHead: {
      marginTop: 20,
      display: 'flex',
      backgroundColor: 'rgba(242, 243, 247, 0.6)',
      minHeight: 38,
      padding: '11px 8px 11px 0',
      '&>div': {
        fontSize: 12,
        color: colorVariables.grey60,
        textTransform: 'uppercase',
        fontWeight: 700,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    date: {
      width: '10%',
      paddingLeft: 15,
    },
    recipe: {
      width: '22%',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        paddingRight: 3,
      },
    },
    recipeId: {
      width: '13%',
      paddingLeft: 5,
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 'calc(100% - 130px)',
      },
    },
    sold: {
      width: '7%',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
    reason: {
      width: '14%',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 180,
        marginLeft: -30,
      },
    },
    reporter: {
      width: '14%',
      paddingLeft: 10,
    },
    type: {
      width: '16%',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
    actions: {
      width: '4%',
    },
    list: {
      position: 'relative',
      zIndex: 1,
      height: 'calc(100vh - 345px)',
      overflowY: 'scroll',
      [theme.breakpoints.down(Sizes.mobile)]: {
        height: 'calc(100vh - 205px)',
      },
    },
    boldText: {
      fontWeight: 700,
    },
    addRow: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'rgba(207, 241, 255, 0.5)',
      padding: '15px 0',
    },
    dateTrigger: {
      width: 117,
      height: 26,
      backgroundColor: colorVariables.white,
      border: `1px solid ${colorVariables.steelGrey}`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: 'pointer',
      paddingLeft: 5,
      color: colorVariables.grey60,
    },
    dateWrap: {
      '&>div': {
        width: '100%',
        paddingRight: 10,
      },
    },
    addActionBtn: {
      height: 28,
      fontSize: 14,
      padding: 0,
    },
    addBtnCell: {
      display: 'flex',
      justifyContent: 'center',
    },
    cellInputWrap: {
      transform: 'translateY(-8px)',
      width: 72,
      '& input': {
        textAlign: 'center',
        fontWeight: 600,
        '&::placeholder': {
          color: `${colorVariables.steelGrey} !important`,
        },
      },
      '& .MuiInputBase-formControl.MuiInput-formControl': {
        borderColor: `${colorVariables.green} !important`,
        padding: 5,
      },
      '& .MuiInputBase-formControl.MuiInput-formControl:hover': {
        [theme.breakpoints.up(Sizes.desktop)]: {
          borderColor: `${colorVariables.blue} !important`,
        },
      },
      '& .MuiInputBase-formControl.MuiInput-formControl.Mui-focused': {
        borderColor: `${colorVariables.blue} !important`,
      },
    },
    cellInputWrapReporter: {
      paddingRight: 10,
      transform: 'translateY(-5px)',
      width: '96%',
      '& input': {
        '&::placeholder': {
          color: `${colorVariables.grey60} !important`,
        },
      },
      '& .MuiInputBase-formControl.MuiInput-formControl': {
        borderRadius: 0,
        borderColor: `${colorVariables.steelGrey} !important`,
        padding: 5,
      },
      '& .MuiInputBase-formControl.MuiInput-formControl:hover': {
        [theme.breakpoints.up(Sizes.desktop)]: {
          borderColor: `${colorVariables.blue} !important`,
        },
      },
      '& .MuiInputBase-formControl.MuiInput-formControl.Mui-focused': {
        borderColor: `${colorVariables.blue} !important`,
      },
    },
    greyCell: {
      color: colorVariables.grey60,
    },
    addNewSaleM: {
      backgroundColor: 'rgba(207, 241, 255, 0.5)',
      padding: 15,
    },
    addSaleBtnM: {
      display: 'flex',
      alignItems: 'center',
      userSelect: 'none',
      '& span': {
        fontSize: 14,
        fontWeight: 700,
        marginRight: 10,
      },
    },
    addChevronRotated: {
      transform: 'rotate(90deg)',
      color: colorVariables.blue,
    },
    addNewBox: {
      padding: '10px 0 0 0 ',
    },
    addNewDateWrap: {
      display: 'flex',
      width: '100%',
      position: 'relative',
      marginBottom: 14,
    },
    addNewMRows: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 14,
      '&>div:first-child': {
        width: '40%',
        fontSize: 12,
        textTransform: 'uppercase',
        color: colorVariables.grey60,
        fontWeight: 700,
      },
      '&>div:last-child': {
        width: '60%',
      },
    },
    actionsM: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end',
      paddingTop: 15,
    },
    fixedHead: {
      position: 'sticky',
      backgroundColor: colorVariables.white,
      top: 0,
      zIndex: 1,
    },
    colHeadText: {
      fontSize: 12,
      fontWeight: 700,
      textTransform: 'uppercase',
      color: colorVariables.grey60,
    },
    sortTrigger: {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        color: colorVariables.grey60,
        fontSize: 16,
      },
    },
    loadingRow: {
      display: 'flex',
      width: '100%',
      padding: '18px 0',
      height: 71,
      borderBottom: `1px solid ${colorVariables.steelGrey}`,
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: 10,
        display: 'block',
        height: 92,
        '&>div': {
          marginBottom: 7,
        },
      },
    },
    cellEmpty: {
      marginTop: -5,
      width: 72,
      height: 40,
      borderRadius: 5,
      border: `1px solid ${colorVariables.steelGrey}`,
    },
    totalSkeletonWrap: {
      display: 'flex',
      alignItems: 'center',
      '&>div:first-child': {
        marginRight: 10,
      },
    },
    centeredSkeleton: {
      marginTop: 10,
    },
    bottomSkeletonRow: {
      display: 'flex',
      justifyContent: 'space-between',
      '&>div': {
        width: '30%',
        flexShrink: 0,
      },
    },
  }),
);
