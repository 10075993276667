import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx';

import { useGetMyCompanyQuery, useUpdateRestaurantProfileMutation } from '../../../../api/company';
import { useGeIsTeammateViewer, useGetAvailableCategories } from '../../../../api/teammates/hooks';
import { useMeQuery } from '../../../../api/user';
import { ThemedButton } from '../../../../shared/components/themed-button';
import { ProductsCategories, productsCategoriesList } from '../../../../shared/constants/products';
import { getCategoriesFromIds, getCategoryId } from '../../../../shared/helpers/getCategoryId';
import { useScreenSize } from '../../../../shared/hooks/use-screen-size';
import { ToastService } from '../../../../shared/services/toastService';

import { useStyles } from './style';

export const CategoriesTab: React.FC = () => {
  const classes = useStyles();
  const { isMobile } = useScreenSize();
  const { goBack } = useHistory();

  const [categories, setCategories] = useState<ProductsCategories[]>([]);
  const availableCategories = useGetAvailableCategories();

  const { data: user } = useMeQuery();
  const { data: company } = useGetMyCompanyQuery(user?.company?.id, {
    skip: !user?.company.id,
  });
  const isViewer = useGeIsTeammateViewer('supplier_and_categories');
  const [updateProfile, { isLoading: updateLoading }] = useUpdateRestaurantProfileMutation();

  const onSetCategory = (category: ProductsCategories) => {
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    if (Array.isArray(availableCategories) && !availableCategories.includes(category)) {
      ToastService.error('You have been restricted from selecting this category.');
      return;
    }
    if (categories.includes(category)) {
      setCategories((state) => state.filter((item) => item !== category));
    } else {
      setCategories((state) => [...state, category]);
    }
  };

  const onSaveCategory = () => {
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    const categoriesToAdd = categories
      .filter((category) => !company?.company_categories.some((cc) => cc.category_id === getCategoryId(category)))
      .map((cat) => ({ category_id: getCategoryId(cat) }));
    const categoriesToRemove =
      company?.company_categories
        .filter((cc) => !categories.some((category) => cc.category_id === getCategoryId(category as ProductsCategories)))
        .map((cc) => ({
          _destroy: true,
          category_id: cc.category_id,
          id: cc.id,
        })) || [];

    company &&
      updateProfile({
        id: company.id,
        company: {
          users_attributes: {
            id: user?.id,
          },
          company_categories_attributes: [...categoriesToAdd, ...categoriesToRemove],
        },
      }).then((res) => {
        if ('data' in res && res.data.success) {
          ToastService.success('Categories have been updated');
        }
      });
  };

  const onCancel = () => {
    company && setCategories(getCategoriesFromIds(company.categories));
    goBack();
  };

  useEffect(() => {
    if (Array.isArray(availableCategories)) {
      setCategories(availableCategories);
      return;
    }
    if (company?.categories.length) {
      setCategories(getCategoriesFromIds(company.categories));
    }
  }, [company?.categories, availableCategories]);

  return (
    <div className={classes.root}>
      <div className={classes.title}>What type of products do you buy?</div>
      <div className={classes.subTitle}>Select all that apply, and don’t worry you can change this later.</div>
      <div className={classes.productsBox}>
        {productsCategoriesList.map(({ title, icon }) => (
          <div
            key={title}
            className={clsx({
              [classes.productItem]: true,
              [classes.productItemSelected]: categories.includes(title),
            })}
            onClick={onSetCategory.bind(null, title)}
          >
            <div className={classes.productIcon}>{icon}</div>
            {title}
            <CheckIcon className={classes.checkIcon} />
          </div>
        ))}
      </div>
      <div className={classes.btnBox}>
        <ThemedButton onClick={onCancel} title='Cancel' buttonStyle='secondary' width={isMobile ? 140 : 178} />
        <ThemedButton onClick={onSaveCategory} title='Update' width={isMobile ? 140 : 178} disabled={!categories.length || updateLoading} />
      </div>
    </div>
  );
};
