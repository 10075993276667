import React, { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import clsx from 'clsx';
import { format } from 'date-fns';

import { useDeleteRecOrderMutation } from '../../../../../../api/recurring-order';
import { RecOrder, RecOrderStatus } from '../../../../../../api/recurring-order/types';
import { useGeIsTeammateViewer } from '../../../../../../api/teammates/hooks';
import { ThemedButton } from '../../../../../../shared/components/themed-button';
import { Dialog } from '../../../../../../shared/components/dialog';
import { Dropdown } from '../../../../../../shared/components/dropdown';
import { MoreButton } from '../../../../../../shared/components/more-button';
import { formatCurrency } from '../../../../../../shared/helpers/format-currency';
import { useScreenSize } from '../../../../../../shared/hooks/use-screen-size';
import { ToastService } from '../../../../../../shared/services/toastService';
import { useAppDispatch } from '../../../../../../store';
import { deleteRecurringOrder, setSelectedRecOrder } from '../../../../../../store/recurring-order';

import { useStyles as useParentStyles } from '../style';
import { useStyles } from './style';

interface Props {
  item: RecOrder;
  onRename: () => void;
}

const Component: React.FC<Props> = ({ item, onRename }) => {
  const classes = useStyles();
  const parentClasses = useParentStyles();
  const { push } = useHistory();
  const { isDesktop } = useScreenSize();
  const dispatch = useAppDispatch();

  const [isDropdownOpened, setIsDropDownOpened] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [deleteRecOrder, { isSuccess }] = useDeleteRecOrderMutation();
  const isViewer = useGeIsTeammateViewer();

  const openDialog = () => {
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const onOpen = () => {
    setIsDropDownOpened(true);
  };

  const onClose = () => {
    setIsDropDownOpened(false);
  };

  const openDetails = () => {
    push(`/pantry_list/recurring_orders/details`, item.id);
  };

  const onEdit = () => {
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    push({
      pathname: `/pantry_list/recurring_orders/edit`,
      state: {
        recOrderId: item.id,
      },
    });
  };

  const onRenameRecOrder = () => {
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    onRename();
    dispatch(setSelectedRecOrder(item.id));
  };

  const onDelete = () => {
    closeDialog();
    deleteRecOrder(item.id);
  };

  useEffect(() => {
    isSuccess && dispatch(deleteRecurringOrder(item.id));
  }, [isSuccess]);

  return (
    <tr key={item.id} className={clsx(classes.row, isDropdownOpened && classes.rowHighlighted)}>
      <td className={clsx(classes.cell, parentClasses.name)}>{item.name}</td>
      <td className={clsx(classes.cell, parentClasses.delivery)}>
        {format(new Date(item.next_delivery_date || item.start_date), 'd MMMM y')}
      </td>
      <td className={clsx(classes.cell, parentClasses.total, classes.total)}>
        {formatCurrency(item?.supplier?.show_prices ? item.total_by_order : 0)}
      </td>
      <td className={clsx(classes.cell, parentClasses.status)}>
        <div
          className={clsx({
            [classes.label]: true,
            [classes.labelActive]: item.status === RecOrderStatus.ACTIVE,
            [classes.labelPaused]: item.status === RecOrderStatus.PAUSED,
          })}
        >
          {item.status}
        </div>
      </td>
      <td className={clsx(classes.cell, parentClasses.frequency)}>
        <div className={clsx(classes.label, classes.labelFrequency)}>{item.frequency}</div>
      </td>
      <td className={clsx(classes.cell, parentClasses.suppliers)}>
        <div className={classes.suppliersList}>
          {item.suppliers_company.slice(0, isDesktop ? 4 : 5).map(({ picture_url, id, name }) => (
            <div className={classes.supplierLogo} key={id}>
              {!!picture_url && <img src={picture_url} alt={name} />}
            </div>
          ))}
          {item.suppliers_company.length > (isDesktop ? 4 : 5) && <span>...</span>}
        </div>
      </td>
      <td className={clsx(classes.cell, parentClasses.items, classes.itemsCountText)}>{item.recurring_order_items.length}</td>
      <td className={clsx(classes.cell, parentClasses.actions)}>
        <ThemedButton title='View' onClick={openDetails} customClass={classes.viewBtn} width={92} />
        <Dropdown
          onOpen={onOpen}
          onClose={onClose}
          top={-10}
          closeOnClick={true}
          triggerBtn={<MoreButton customClass={classes.moreBtn} />}
          items={[
            { title: 'Edit', handler: onEdit },
            { title: 'Delete', handler: openDialog },
            {
              title: 'Rename',
              handler: onRenameRecOrder,
            },
          ]}
        />
        {isDialogOpen && (
          <Dialog
            title={`Are you sure you want to remove the '${item.name} order' from recurring order `}
            onCancel={closeDialog}
            onConfirm={onDelete}
            customClass={classes.dialog}
          />
        )}
      </td>
    </tr>
  );
};

export const RecurringOrderRow = memo(Component);
