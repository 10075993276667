import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { colorVariables } from '../../../../styles/colorVariables';
import { Sizes } from '../../../../styles/sizes';

export interface StyleProps {
  textColor?: string;
  bgColor?: string;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    accountMenuBox: {
      display: 'none',
      [theme.breakpoints.down(Sizes.desktop)]: {
        display: 'block',
      },
    },
    accountLinkWrap: {
      display: 'flex',
      alignItems: 'center',
      width: 270,
      flexGrow: 1,
      height: 48,
      color: ({ textColor }) => textColor || colorVariables.grey60,
      paddingLeft: 32,
      paddingRight: 20,
      fontSize: 14,
      '& img': {
        opacity: 0.6,
      },
      '&:hover': {
        color: ({ textColor }) => textColor || colorVariables.steelGrey,
        filter: ({ bgColor }) => (bgColor ? 'brightness(85%)' : undefined),
        backgroundColor: ({ bgColor }) => bgColor || 'rgba(45, 154, 212, 0.05)',
      },
      '&:hover img': {
        opacity: 1,
      },
    },
    disabled: {
      opacity: 0.4,
      '&:hover': {
        color: colorVariables.grey60,
        backgroundColor: 'transparent',
      },
    },
    accountLinkIcon: {
      fontSize: 20,
      marginRight: 15,
      display: 'flex',
      alignItems: 'center',
    },
    accountLinkActive: {
      borderLeft: `3px solid ${colorVariables.green}`,
      filter: ({ bgColor }) => (bgColor ? 'brightness(90%)' : undefined),
      background: ({ bgColor }) => bgColor || 'linear-gradient(90deg, rgba(81, 217, 176, 0.1) 0%, rgba(21, 33, 68, 0.5) 100%)',
      '& span:first-child': {
        color: colorVariables.white,
      },
      '& img': {
        opacity: 1,
      },
    },
    ordersCount: {
      display: 'inline-block',
      width: 20,
      height: 20,
      marginLeft: 7,
      backgroundColor: colorVariables.green,
      fontSize: 11,
      fontWeight: 700,
      lineHeight: '20px',
      borderRadius: '50%',
      textAlign: 'center',
      color: colorVariables.navy,
    },
    lockIcon: {
      color: colorVariables.white,
      opacity: 1,
      fontSize: 16,
    },
    lockIconWrap: {
      height: 25,
      width: 40,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      marginLeft: 'auto',
    },
  }),
);
