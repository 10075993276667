import { createStyles, makeStyles } from '@material-ui/core';
import { colorVariables } from '../../../styles/colorVariables';
import { Sizes } from '../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    arrow: {
      color: '#CFF1FF',
      fontSize: 32,
    },
    arrowRS: {
      color: '#34365A',
    },
    tooltip: {
      position: 'relative',
      width: 340,
      minHeight: 120,
      backgroundColor: '#CFF1FF',
      borderRadius: 10,
      padding: '20px 30px',
      boxShadow: '0 0 8px 1px rgba(0, 0, 0, 0.1)',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 310,
        padding: 20,
      },
    },
    tooltipRS: {
      backgroundColor: '#34365A',
    },
    tooltipText: {
      fontSize: 14,
      color: colorVariables.grey80,
      paddingTop: 5,
      marginBottom: 20,
      lineHeight: '22px',
      fontWeight: 400,
    },
    tooltipTextRS: {
      color: colorVariables.grey40,
    },
    closeTooltip: {
      width: 17,
      height: 17,
      position: 'absolute',
      top: 5,
      right: 5,
      '& svg': {
        color: colorVariables.grey60,
        fontSize: 18,
      },
    },
    rootTooltip: {
      backgroundColor: colorVariables.transparent,
      padding: 0,
      color: colorVariables.userNavy,
      maxWidth: 340,
      [theme.breakpoints.down(Sizes.mobile)]: {
        maxWidth: 310,
      },
    },
    upgradeBtn: {
      height: 36,
      fontSize: 14,
    },
    tooltipTitle: {
      fontSize: 16,
      fontWeight: 600,
      marginBottom: 10,
    },
    tooltipTitleRS: {
      color: colorVariables.white,
    },
  }),
);
