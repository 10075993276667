import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../styles/colorVariables';
import { Sizes } from '../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    formContainer: {
      paddingTop: 30,
      width: 600,
      [theme.breakpoints.down(Sizes.tablet)]: {
        width: 500,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        maxWidth: '100%',
      },
    },
    fieldWrap: {
      marginBottom: 30,
      display: 'inline-block',
      '& .MuiInputBase-root': {
        width: 210,
      },
      '& input': {
        textAlign: 'center',
      },
      '&:hover + div': {
        transition: 'all ease-in-out 0.5s',
        visibility: 'visible',
        opacity: 1,
      },
    },
    label: {
      marginBottom: 10,
      fontSize: 12,
      lineHeight: '16px',
      color: colorVariables.navy,
    },
    btnBlock: {
      paddingTop: 20,
      alignSelf: 'flex-start',
      display: 'flex',
      [theme.breakpoints.down(Sizes.mobile)]: {
        justifyContent: 'center',
      },
      '& button': {
        minWidth: 120,
        [theme.breakpoints.down(Sizes.mobile)]: {
          minWidth: 110,
        },
      },
    },
    saveBtn: {
      marginLeft: 15,
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginLeft: 7,
      },
    },
    tooltipWrap: {
      width: 350,
      visibility: 'hidden',
      position: 'relative',
      zIndex: 300,
      transform: 'translateY(-115px)',
      opacity: 0,
      transition: 'all ease-in-out 0.7s',
      [theme.breakpoints.down(Sizes.desktop)]: {
        transform: 'translateY(-120px)',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 'auto',
      },
    },
    track: {
      backgroundColor: colorVariables.steelGrey,
      opacity: 1,
    },
    thumb: {
      backgroundColor: colorVariables.white,
      width: 20,
      height: 20,
    },
    checked: {
      '&.MuiSwitch-switchBase + .MuiSwitch-track': {
        backgroundColor: '#44B5E6',
        opacity: 1,
      },
    },
  }),
);
