import { SupplierProfile } from '../company/types';
import { PaginationResult } from '../index';
import { SortDir } from '../product/types';
import { SupplierUser } from '../user/types';

export enum SupplierType {
  NORMAL = 'normal_supplier',
  SELF_MANAGED = 'self_supplier',
}

export interface SupplierForAdmin {
  supplier_id: number;
  name: Array<string | null>;
  customers_count: number;
  sales: number;
  last_order: Array<string | null>;
  on_boarding: Array<string | null>;
  last_login: null | string;
  last_price_change: null | string;
  verified: boolean;
  manual_supplier: boolean;
  product_photos: string;
  created_at: string;
}

export interface GetSuppliersResponse extends PaginationResult {
  data: SupplierForAdmin[];
}

export type SuppliersSortField =
  | 'name'
  | 'customer_count'
  | 'sales'
  | 'last_order'
  | 'last_login'
  | 'on_boarding'
  | 'last_price_change'
  | 'verified'
  | 'product_photos'
  | 'created_at'
  | 'manual_supplier';

export type SuppliersSortBy = [SuppliersSortField, SortDir];

export interface SuppliersQueryParams {
  sort_by?: string;
  keyword?: string;
}

export interface SuppliersListReqParams {
  keyword?: string;
  currentPage?: number;
  sort?: SuppliersSortBy;
  supplier_type: SupplierType;
}

export interface GetAdminSupplier extends SupplierUser {
  undiscoverable: boolean;
  hide_supplier: boolean;
  auto_accept_requests: boolean;
  order_whats_app_notification?: boolean;
}

export interface SupplierProfileForAdmin extends SupplierProfile {
  latitude: number;
  longitude: number;
  abn?: string;
}

export interface UpdateLatLongRequest {
  id: number;
  address_id: number;
  latitude?: number;
  longitude?: number;
}
