import { useGetCurrentThemeQuery, useGetDomainsQuery, useGetThemesQuery } from './index';
import { useMemo } from 'react';

export const useCurrentTheme = () => {
  const { data, isLoading } = useGetCurrentThemeQuery(undefined);

  return useMemo(
    () => ({
      colors: Object.fromEntries(data?.theme?.theme_colors?.map((el: any) => [el.name, el.color_code]) || []),
      logoUrl: data?.theme?.logo_url || '',
      loading: isLoading,
      name: isLoading ? undefined : data?.theme?.name || '',
    }),
    [data, isLoading],
  );
};

export const useSelectThemeById = (id?: number) => {
  const { data } = useGetThemesQuery();
  return useMemo(() => data?.themes?.find((el) => el.id === id), [data, id]);
};

export const useSelectDomainById = (id?: number) => {
  const { data } = useGetDomainsQuery();
  return useMemo(() => data?.domains?.find((el) => el.id === id), [data, id]);
};
