import { UsersFilters } from '../../../api/admin-users/types';
import { Roles } from '../../../api/user/types';
import { usersSortParams } from '../../../components/AdminDrawer/Users/UsersList/users-filter';
import { RootState } from '../../index';

export const getUsersSortFilters = (state: RootState) => ({
  sort: state.users.sortBy,
  filter: state.users.filterBy,
  keyword: state.users.keyword,
  currentPage: state.users.currentPage,
});

export const getUsersChips = (state: RootState) => {
  const sortLabel = usersSortParams.find((param) => param.value === state.users.sortBy?.join(' '))?.label || '';

  const sortChip = {
    label: sortLabel,
    key: sortLabel ? 'sort' : '',
  };
  const filterChips = Object.entries(state.users.filterBy || {}).reduce((acc, [key, value]: [string, string[] | string | boolean]) => {
    switch (key as keyof UsersFilters) {
      case 'company_type': {
        if (!key) {
          return acc;
        }
        acc.push({
          label: value === Roles.CUSTOMER ? 'Restaurants' : value === Roles.SUPPLIER ? 'Suppliers' : value === 'admin' ? 'Admins' : '',
          key: `company_type`,
        });
        break;
      }
      case 'verified': {
        if (!key) {
          return acc;
        }
        acc.push({
          label: value === false ? 'Pending' : value === true ? 'Verified' : '',
          key: `company_type`,
        });
        break;
      }
    }
    return acc;
  }, [] as { label: string; key: string }[]);
  return [sortChip, ...filterChips].filter((chip) => !!chip.label && !!chip.key);
};

export const getSelectedUsersIds = (state: RootState) => state.users.selectedUsers;

export const getSelectedUserId = (state: RootState) => state.users.selectedUserId;
