import React, { memo } from 'react';

import { PreviewManualProductsResponse } from '../../../../api/product/types';
import { useScreenSize } from '../../../../shared/hooks/use-screen-size';
import { PreviewProductRow } from '../../../AppDrawer/SupplierDrawer/Product/ImportProduct/preview-product-row';

import { useStyles } from './style';

interface Props {
  data?: PreviewManualProductsResponse;
}

const Component: React.FC<Props> = ({ data }) => {
  const classes = useStyles();
  const { isMobile } = useScreenSize();

  return (
    <div className={classes.box}>
      <div className={classes.boxHead}>
        <div className={classes.idCol}>Product Code</div>
        <div className={classes.actionCol}>Action</div>
        <div className={classes.nameCol}>{isMobile ? 'Items' : 'Product Name'}</div>
        <div className={classes.unitCol}>Unit of measure</div>
        <div className={classes.msgCol}>Message</div>
        <div className={classes.rowCol}>Row</div>
      </div>
      <div className={classes.boxContent}>
        <PreviewProductRow rowData={data?.added || []} title='Added' manualProducts={true} />
        <PreviewProductRow rowData={data?.changed || []} title='Changed' manualProducts={true} />
        <PreviewProductRow rowData={data?.error_data || []} title='Errors' isError={!!data?.error_data.length} manualProducts={true} />
      </div>
    </div>
  );
};

export const PreviewProducts = memo(Component);
