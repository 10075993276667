import * as React from 'react';

function SvgHelpIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#HelpIcon_svg__clip0_1224_14899)' fill='currentColor'>
        <path d='M9.87 5.468c-1.837 0-2.682 1.09-2.682 1.825 0 .53.45.776.817.776.736 0 .436-1.049 1.825-1.049.68 0 1.225.3 1.225.926 0 .736-.762 1.158-1.211 1.54-.395.34-.913.898-.913 2.07 0 .707.19.911.75.911.666 0 .803-.3.803-.558 0-.708.013-1.117.762-1.702.368-.286 1.525-1.212 1.525-2.492s-1.157-2.247-2.9-2.247zM9.694 13.407c-.572 0-1.035.477-1.035 1.049 0 .558.45 1.048 1.035 1.048s1.048-.49 1.048-1.048c0-.572-.476-1.049-1.048-1.049z' />
        <path d='M17.489 3.82L11.609.43a3.231 3.231 0 00-3.219 0L2.511 3.82A3.232 3.232 0 00.898 6.612v6.776c0 1.148.618 2.218 1.613 2.792l5.88 3.39a3.231 3.231 0 003.219 0l5.879-3.39a3.232 3.232 0 001.613-2.792V6.612a3.232 3.232 0 00-1.613-2.792zm.05 9.568c0 .592-.318 1.143-.83 1.438l-5.88 3.39a1.664 1.664 0 01-1.658 0l-5.88-3.39a1.664 1.664 0 01-.83-1.438V6.612c0-.592.318-1.143.83-1.438l5.88-3.39a1.664 1.664 0 011.658 0l5.88 3.39c.512.295.83.846.83 1.438v6.776z' />
      </g>
    </svg>
  );
}

export default SvgHelpIcon;
