import * as React from 'react';

function SvgCategoryMeat(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M13.94 2.3c-4.616 0-8.371 3.795-8.371 8.46v.045c0 1.692-.52 2.607-1.765 3.064-.045 0-.068.023-.113.046-1.561.617-2.624 2.195-2.624 3.91a4.25 4.25 0 002.647 3.932h.022c.023.023.068.023.09.023.069.023.204.069.408.114l.09.023h.023c.52.137 1.064.183 1.674.183 4.503 0 10.747-3.315 11.539-3.727h.022c2.896-1.394 4.751-4.39 4.751-7.636C22.311 6.095 18.555 2.3 13.94 2.3zm3.19 15.273l-.023.023c-.34.183-8.393 4.504-12.556 3.475l-.068-.023c-.113-.022-.294-.091-.362-.114a3.332 3.332 0 01-2.15-3.11c0-1.348.838-2.606 2.105-3.086 2.149-.777 2.398-2.606 2.398-3.933v-.045c0-4.162 3.348-7.545 7.466-7.545 4.117 0 7.466 3.383 7.466 7.545 0 2.903-1.674 5.578-4.276 6.813z'
        fill='#fff'
      />
      <path
        d='M13.94 4.175c-3.597 0-6.516 2.95-6.516 6.585v.045c0 2.492-1.018 4.116-3.031 4.825-.883.342-1.47 1.234-1.47 2.194 0 .938.587 1.83 1.47 2.195l.09.023c.023 0 .136.046.227.069l.067.023H4.8c.362.091.77.137 1.222.137 3.258 0 8.212-2.195 10.61-3.498 0 0 .023 0 .023-.023l.09-.046c2.263-1.075 3.71-3.43 3.71-5.944 0-3.636-2.918-6.585-6.515-6.585zm2.398 11.706l-.113.046c-.022 0-.022.023-.045.023l-.023.023c-3.031 1.623-7.488 3.383-10.18 3.383-.385 0-.725-.045-.996-.114-.023 0-.045-.023-.068-.023l-.136-.046-.067-.022c-.52-.206-.883-.755-.883-1.326 0-.595.362-1.12.883-1.327 1.651-.594 3.62-2.057 3.62-5.693v-.045c0-3.133 2.51-5.67 5.61-5.67 3.1 0 5.61 2.537 5.61 5.67a5.72 5.72 0 01-3.212 5.121z'
        fill='#fff'
      />
      <path
        d='M14.46 7.81a1.98 1.98 0 00-1.968 1.99 1.98 1.98 0 001.968 1.989 1.98 1.98 0 001.969-1.99A1.98 1.98 0 0014.46 7.81zm0 3.041c-.588 0-1.063-.48-1.063-1.074 0-.595.475-1.075 1.063-1.075.589 0 1.064.48 1.064 1.075 0 .594-.475 1.074-1.064 1.074z'
        fill='#fff'
      />
    </svg>
  );
}

export default SvgCategoryMeat;
