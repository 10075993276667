import React, { useEffect } from 'react';

import { FastField, Form, Formik, FormikHelpers } from 'formik';

import { useRenamePantryListMutation } from '../../../../../../../api/pantry-list';
import { useGetSelectedPantryList } from '../../../../../../../api/pantry-list/hooks';
import { ThemedButton } from '../../../../../../../shared/components/themed-button';
import { FormikInput } from '../../../../../../../shared/components/formik-input';
import { OverlayModal } from '../../../../../../../shared/components/overlay-modal';
import { useScreenSize } from '../../../../../../../shared/hooks/use-screen-size';

import { useStyles } from './style';

interface Props {
  isOpened: boolean;
  onClose: () => void;
}

interface Values {
  name: string;
}

export const RenamePantryListModal: React.FC<Props> = ({ isOpened, onClose }) => {
  const classes = useStyles();
  const { isMobile } = useScreenSize();

  const { pantryList } = useGetSelectedPantryList();
  const [renamePantryList, { isSuccess, isLoading }] = useRenamePantryListMutation();

  const validateForm = (values: Values) => {
    const errors: Partial<Values> = {};
    if (values.name.length > 20) {
      errors.name = 'Too long';
    }
    return errors;
  };

  const onSubmit = (values: Values, formikHelpers: FormikHelpers<Values>) => {
    formikHelpers.setSubmitting(false);
    if (!values.name.trim()) {
      return;
    }
    pantryList &&
      renamePantryList({
        name: values.name,
        id: pantryList.id,
      });
  };

  useEffect(() => {
    isSuccess && onClose();
  }, [isSuccess]);

  return (
    <OverlayModal onClose={onClose} isOpen={isOpened} boxClassName={classes.modalBox}>
      <>
        <>
          <h3 className={classes.modalTitle}>Rename Pantry List</h3>
          {isOpened ? (
            <Formik
              validate={validateForm}
              enableReinitialize={true}
              onSubmit={onSubmit}
              initialValues={{
                name: pantryList?.name || '',
              }}
            >
              {({ submitForm }) => (
                <Form>
                  <div className={classes.field}>
                    <FastField component={FormikInput} name='name' />
                  </div>
                  <div className={classes.btnBox}>
                    <ThemedButton onClick={onClose} title='Cancel' buttonStyle='secondary' isSmall={isMobile} width={105} />
                    <ThemedButton onClick={submitForm} title='Save Name' isSmall={isMobile} width={105} disabled={isLoading} />
                  </div>
                </Form>
              )}
            </Formik>
          ) : null}
        </>
      </>
    </OverlayModal>
  );
};
