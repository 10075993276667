import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';
import { Sizes } from '../../../styles/sizes';

export interface StyleProps {
  width?: number | 'auto';
  bordered?: boolean;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    root: {
      minWidth: ({ width }) => width || 100,
      overflow: 'hidden',
      boxShadow: 'none',
      textTransform: 'none',
      fontSize: 16,
      fontWeight: 600,
      height: 48,
      padding: '16px 24px',
      borderRadius: 24,
      borderWidth: 2,
      borderStyle: 'solid',
      lineHeight: '16px',
      marginLeft: 5,
      marginRight: 5,
      '&:hover': {
        boxShadow: 'none',
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: colorVariables.transparent,
      },
      '&:active': {
        boxShadow: 'none',
        borderWidth: 2,
        borderStyle: 'solid',
      },
      '&:disabled': {
        opacity: 0.5,
      },
    },
    primary: {
      borderColor: ({ bordered }) => (bordered ? colorVariables.green : colorVariables.transparent),
      borderWidth: ({ bordered }) => (bordered ? 1 : 2),
      color: ({ bordered }) => (bordered ? colorVariables.green : colorVariables.white),
      backgroundColor: ({ bordered }) => (bordered ? colorVariables.white : colorVariables.green),
      '&:hover': {
        borderWidth: ({ bordered }) => (bordered ? 1 : 2),
        backgroundColor: ({ bordered }) => (bordered ? colorVariables.greenLight : colorVariables.greenDark),
        [theme.breakpoints.down(Sizes.desktop)]: {
          borderColor: ({ bordered }) => (bordered ? colorVariables.green : colorVariables.transparent),
          borderWidth: ({ bordered }) => (bordered ? 1 : 2),
          color: ({ bordered }) => (bordered ? colorVariables.green : colorVariables.white),
          backgroundColor: ({ bordered }) => (bordered ? colorVariables.white : colorVariables.green),
        },
      },
      '&:active': {
        backgroundColor: colorVariables.greenDark,
        borderColor: colorVariables.greenLight,
      },
      '&:disabled, &:hover:disabled': {
        color: colorVariables.white,
        backgroundColor: ({ bordered }) => (bordered ? colorVariables.greenLight : colorVariables.green),
      },
    },
    secondary: {
      borderColor: ({ bordered }) => (bordered ? colorVariables.grey40 : colorVariables.transparent),
      borderWidth: ({ bordered }) => (bordered ? 1 : 2),
      color: ({ bordered }) => (bordered ? colorVariables.grey40 : colorVariables.navy),
      backgroundColor: ({ bordered }) => (bordered ? colorVariables.white : 'rgba(152, 165, 183, 0.3)'),
      '&:hover': {
        backgroundColor: ({ bordered }) => (bordered ? 'rgba(152, 165, 183, 0.1)' : colorVariables.grey40),
        [theme.breakpoints.down(Sizes.desktop)]: {
          backgroundColor: ({ bordered }) => (bordered ? colorVariables.white : 'rgba(152, 165, 183, 0.3)'),
        },
      },
      '&:active': {
        backgroundColor: colorVariables.grey60,
        borderColor: 'rgba(152, 165, 183, 0.3)',
      },
      '&:disabled, &:hover:disabled': {
        backgroundColor: ({ bordered }) => (bordered ? colorVariables.steelGrey : colorVariables.grey60),
      },
    },
    white: {
      borderColor: ({ bordered }) => (bordered ? colorVariables.navy : colorVariables.white),
      borderWidth: ({ bordered }) => (bordered ? 1 : 2),
      color: colorVariables.navy,
      backgroundColor: colorVariables.white,
      '&:hover': {
        backgroundColor: colorVariables.grey20,
        [theme.breakpoints.down(Sizes.desktop)]: {
          backgroundColor: colorVariables.white,
        },
      },
      '&:active': {
        backgroundColor: colorVariables.grey20,
      },
      '&:disabled, &:hover:disabled': {
        backgroundColor: colorVariables.grey10,
        borderColor: colorVariables.grey10,
      },
    },
    icon: {
      backgroundColor: colorVariables.transparent,
      borderColor: colorVariables.transparent,
      color: colorVariables.navy,
      padding: '16px 10px',
    },
    blueButton: {
      borderColor: colorVariables.transparent,
      color: colorVariables.white,
      backgroundColor: colorVariables.blue,
      '&:hover': {
        backgroundColor: colorVariables.blue,
        opacity: 0.7,
        [theme.breakpoints.down(Sizes.desktop)]: {
          backgroundColor: colorVariables.blue,
        },
      },
      '&:active': {
        backgroundColor: colorVariables.blue,
      },
      '&:disabled, &:hover:disabled': {
        color: colorVariables.white,
        backgroundColor: colorVariables.blue,
        opacity: 0.4,
      },
    },
    smallButton: {
      fontSize: 14,
      fontWeight: 600,
      height: 34,
      lineHeight: '34px',
      padding: '0 24px',
      borderRadius: 17,
    },
    blueTransparent: {
      fontWeight: 600,
      color: colorVariables.blue,
      cursor: 'pointer',
      height: 36,
      lineHeight: '36px',
      padding: '16px 10px',
      width: ({ width }) => width || 'auto',
      textAlign: 'center',
      textTransform: 'none',
      borderRadius: 18,
      border: 'none',
      fontSize: 14,
      '&:hover': {
        border: 'none',
        backgroundColor: colorVariables.blueLight,
        [theme.breakpoints.down(Sizes.desktop)]: {
          backgroundColor: colorVariables.blueLight,
        },
      },
    },
  }),
);
