import * as React from 'react';

function SvgShopIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M19.786 5.38v.002a1.84 1.84 0 01-.232 1.708 2.023 2.023 0 01-1.561.805l-.096.004V18.99c0 .516-.418.91-.91.91H3.007a.908.908 0 01-.91-.91V7.895h-.1c-.62 0-1.192-.31-1.557-.803C.101 6.569 0 5.948.206 5.382h0L1.873.687s0 0 0 0A.904.904 0 012.706.1h14.58c.397 0 .74.247.862.588 0 0 0 0 0 0l1.638 4.693zm-10.772.723h.1V1.992h-.1v-.073H7.24l-.018.079L6.35 5.98l-.026.122H9.014zm1.893-.1v.1h2.791l-.027-.122-.873-4.01-.018-.08H10.907V6.004zm-7.49-4.111h-.071l-.024.066-1.42 4.011-.047.134H4.479l.018-.079.874-4.01.026-.122H3.416zM7.902 17.98v.1H12.09V11.878H7.903v6.103zm8.1.1h.1v-.1l-.027-9.987v-.1H3.89v10.187h2.193v-7.085c0-.516.417-.91.91-.91H13c.517 0 .91.418.91.91v7.085h2.094zm-.507-12.058l.017.08h2.429a.204.204 0 00.062-.01.17.17 0 00.04-.018h.122l-.048-.133-1.42-3.984-.023-.066h-2.051l.026.12.846 4.011z'
        fill='currentColor'
        stroke='#152144'
        strokeWidth={0.2}
      />
    </svg>
  );
}

export default SvgShopIcon;
