import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { colorVariables } from '../../../styles/colorVariables';

export interface StyleProps {
  right?: boolean;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    infoIcon: {
      width: 15,
      height: 15,
      position: 'absolute',
      left: 10,
      top: 10,
    },
    toolTip: {
      display: 'block',
      width: '100%',
      minHeight: 24,
      borderRadius: 4,
      padding: 10,
      paddingLeft: 30,
      textAlign: 'center',
      fontSize: 11,
      lineHeight: 1.3,
      color: colorVariables.white,
      backgroundColor: colorVariables.navy,
      position: 'absolute',
      bottom: 5,
      left: 0,

      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: -15,
        left: ({ right }) => (right ? 'auto' : 24),
        right: ({ right }) => (right ? 24 : 'auto'),
        borderWidth: 8,
        borderStyle: 'solid',
        borderColor: `${colorVariables.navy} transparent transparent transparent`,
      },
    },
    rightSideArrow: {
      '&::after': {
        display: 'none',
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        bottom: '50%',
        left: 'auto',
        right: -15,
        borderWidth: 8,
        borderStyle: 'solid',
        borderColor: `transparent transparent transparent ${colorVariables.navy}`,
      },
    },
  }),
);
