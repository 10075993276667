import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { getCategoryId } from '../../shared/helpers/getCategoryId';
import useTimeout from '../../shared/hooks/use-timeout';
import { useAppDispatch, useAppSelector } from '../../store';
import { getProductCategoryFilter } from '../../store/products';
import { getSupplierKeyword, setSupplierKeyword } from '../../store/suppliers-categories';
import { getImpersonatedCustomer } from '../../store/user';
import { useGetConnectionRequestsQuery } from '../company';
import { useGetAvailableCategories } from '../teammates/hooks';
import { useMeQuery } from '../user';
import { SupplierUser } from '../user/types';
import { useGetSupplierListQuery } from './index';

export const useGetMySuppliers = (ignoreFilter?: boolean, connected?: boolean) => {
  const category_id = useAppSelector(getProductCategoryFilter);
  const impersonatedCustomer = useAppSelector(getImpersonatedCustomer);
  const availableCategories = useGetAvailableCategories();

  const { data: requests } = useGetConnectionRequestsQuery();

  const { connectedSuppliers, connectedLoading, connectedLoaded } = useGetSupplierListQuery(
    {
      category_id: category_id && !ignoreFilter ? category_id : undefined,
      per_page: 200,
      sort_by: 'most_purchased desc',
      keyword: '',
    },
    {
      selectFromResult: ({ data, isLoading, isSuccess }) => ({
        connectedSuppliers: data?.suppliers || [],
        connectedLoading: isLoading,
        connectedLoaded: isSuccess,
      }),
      skip: !!impersonatedCustomer,
    },
  );

  const { pendingSuppliers, pendingLoading, pendingLoaded } = useGetSupplierListQuery(
    {
      category_id: category_id && !ignoreFilter ? category_id : undefined,
      pending_supplier: true,
      per_page: 200,
      keyword: '',
    },
    {
      selectFromResult: ({ data, isLoading, isSuccess }) => ({
        pendingSuppliers: data?.suppliers || [],
        pendingLoading: isLoading,
        pendingLoaded: isSuccess,
      }),
      skip: !!impersonatedCustomer,
    },
  );

  const loaded = pendingLoaded && connectedLoaded;

  return useMemo(() => {
    const suppliers = connected ? connectedSuppliers : [...connectedSuppliers, ...pendingSuppliers];
    const suppliersToShow = Array.isArray(availableCategories)
      ? suppliers.reduce((acc: SupplierUser[], el) => {
          const supplierConnection = requests?.find((req) => req.supplier.id === el.id);
          if (
            supplierConnection &&
            Array.isArray(availableCategories) &&
            availableCategories.some(
              (pCat) => supplierConnection.categories[0] && getCategoryId(pCat) === supplierConnection.categories[0].id,
            )
          ) {
            acc.push(el);
          }
          return acc;
        }, [])
      : suppliers;
    return {
      suppliers: suppliersToShow,
      suppliersLoading: connectedLoading || pendingLoading,
      loaded,
    };
  }, [connected, connectedSuppliers, pendingSuppliers, connectedLoading, pendingLoading, availableCategories, requests, loaded]);
};

export const useGetMySupplierById = (id: number, ignoreFilter?: boolean) => {
  const impersonatedCustomer = useAppSelector(getImpersonatedCustomer);
  const { suppliers } = useGetMySuppliers(ignoreFilter);
  const { data: user } = useMeQuery(undefined, {
    skip: !impersonatedCustomer,
  });
  return useMemo(
    () => (impersonatedCustomer && user?.id === id ? user : suppliers.find((supplierItem) => supplierItem.id === id)),
    [suppliers, id, user, impersonatedCustomer],
  ) as SupplierUser | undefined;
};

export const useSetSuppliersDefaults = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const keyword = useAppSelector(getSupplierKeyword);
  useTimeout(
    () => {
      dispatch(setSupplierKeyword(''));
    },
    pathname.includes('/suppliers') || !keyword ? null : 300000,
  );
};
