import * as React from 'react';

function SvgNotifications(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 16 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M15.586 15.916c-.06-.038-.502-.348-.945-1.286-.813-1.722-.984-4.147-.984-5.879v-.023a5.675 5.675 0 00-3.34-5.146V2.228A2.229 2.229 0 008.091 0h-.185C6.681 0 5.683 1 5.683 2.228v1.354a5.675 5.675 0 00-3.34 5.169c0 1.732-.171 4.157-.985 5.88-.443.937-.886 1.247-.944 1.285a.57.57 0 00-.313.648c.057.271.31.458.587.458h4.295A3.023 3.023 0 008 20a3.023 3.023 0 003.016-2.978h4.295c.277 0 .53-.187.588-.458a.57.57 0 00-.313-.648zM6.856 2.228c0-.581.471-1.054 1.051-1.054h.185c.58 0 1.052.473 1.052 1.054v.973a5.65 5.65 0 00-2.289 0v-.973zM8 18.826a1.849 1.849 0 01-1.845-1.804h3.69A1.849 1.849 0 018 18.826zm2.359-2.978H2.024c.101-.158.204-.337.307-.539.785-1.548 1.183-3.755 1.183-6.558A4.494 4.494 0 018 4.258a4.494 4.494 0 014.486 4.495v.022c.002 2.791.4 4.99 1.184 6.534.102.202.205.381.307.54h-3.617z'
        fill='currentColor'
      />
    </svg>
  );
}

export default SvgNotifications;
