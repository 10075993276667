import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';

export interface StyleProps {
  textColor?: string;
  bgColor?: string;
}

export const useStyles = makeStyles<Theme, StyleProps>(() =>
  createStyles({
    root: {
      width: 110,
      overflow: 'hidden',
      boxShadow: 'none',
      textTransform: 'none',
      fontSize: 14,
      fontWeight: 600,
      height: 34,
      padding: '9px 24px',
      borderRadius: 24,
      borderStyle: 'solid',
      lineHeight: '16px',
      color: ({ textColor }) => textColor || colorVariables.white,
      backgroundColor: ({ bgColor }) => bgColor || colorVariables.green,
      borderColor: colorVariables.transparent,
      '& .MuiButton-endIcon': {
        marginLeft: 3,
      },
      '&:hover': {
        borderColor: colorVariables.transparent,
        borderWidth: 2,
        filter: 'brightness(0.85)',
        color: ({ textColor }) => textColor || colorVariables.white,
        backgroundColor: ({ bgColor }) => bgColor || colorVariables.green,
      },
      '&:disabled, &:hover:disabled': {
        opacity: 0.6,
        color: ({ textColor }) => textColor || colorVariables.white,
      },
    },
  }),
);
