import { createStyles, makeStyles } from '@material-ui/core';
import { colorVariables } from '../../../styles/colorVariables';
import { Sizes } from '../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      border: 'none',
      '&>div': {
        scale: 1.15,
        [theme.breakpoints.down(Sizes.mobile)]: {
          scale: 1.4,
        },
      },
      '& span.sd:hover': {
        backgroundColor: `${colorVariables.transparent} !important`,
        color: `${colorVariables.navy} !important`,
        border: `1px solid ${colorVariables.green} !important`,
        [theme.breakpoints.down(Sizes.mobile)]: {
          border: `none !important`,
          backgroundColor: `${colorVariables.greenLight} !important`,
        },
      },
      '& div.rmdp-week:first-child': {
        borderBottom: `1px solid ${colorVariables.steelGrey} !important`,
      },
      '& div.rmdp-week-day': {
        color: `${colorVariables.grey60} !important`,
        fontSize: 14,
        fontWeight: 600,
        [theme.breakpoints.down(Sizes.mobile)]: {
          fontSize: 12,
        },
      },
      '& .rmdp-arrow-container': {
        width: '28px !important',
        height: '28px !important',
        backgroundColor: `rgba(152, 165, 183, 0.15) !important`,
        fontSize: '20px !important',
        [theme.breakpoints.down(Sizes.mobile)]: {
          width: '24px !important',
          height: '24px !important',
        },
        '& .rmdp-arrow': {
          width: '10px !important',
          height: '10px !important',
          marginTop: '8px !important',
          borderColor: `${colorVariables.grey60} !important`,
          [theme.breakpoints.down(Sizes.mobile)]: {
            width: '8px !important',
            height: '8px !important',
            marginTop: '8px !important',
          },
        },
        '&:hover': {
          backgroundColor: `rgba(152, 165, 183, 0.3) !important`,
        },
      },
      '& .rmdp-header-values>span': {
        fontSize: 18,
        fontWeight: 600,
        color: colorVariables.navy,
        [theme.breakpoints.down(Sizes.mobile)]: {
          fontSize: 14,
        },
      },
    },
  }),
);
