import React, { memo, MouseEvent, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import clsx from 'clsx';
import { format, subDays } from 'date-fns';
import { FastField, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import LinesEllipsis from 'react-lines-ellipsis';

import { useDeleteSaleMutation, useUpdateSaleMutation } from '../../../../../../../api/sales';
import { Sale } from '../../../../../../../api/sales/types';
import { useGeIsTeammateViewer } from '../../../../../../../api/teammates/hooks';
import { ThemedButton } from '../../../../../../../shared/components/themed-button';
import { DatePickerComponent } from '../../../../../../../shared/components/date-picker';
import { Dialog } from '../../../../../../../shared/components/dialog';
import { DropdownMenu } from '../../../../../../../shared/components/dropdown-menu';
import { FormikInput } from '../../../../../../../shared/components/formik-input';
import { MoreButton } from '../../../../../../../shared/components/more-button';
import { formatCurrency } from '../../../../../../../shared/helpers/format-currency';
import { useScreenSize } from '../../../../../../../shared/hooks/use-screen-size';
import { ToastService } from '../../../../../../../shared/services/toastService';

import { useStyles } from './style';

interface Props {
  item: Sale;
}

const Component: React.FC<Props> = ({ item }) => {
  const classes = useStyles();
  const { isMobile } = useScreenSize();

  const [isEditMode, setIsEditMode] = useState(false);
  const [deleteDialogPosition, setDeleteDialogPosition] = useState<{ x: number; y: number } | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const [updateSale, { isLoading: updateLoading }] = useUpdateSaleMutation();
  const [deleteSale] = useDeleteSaleMutation();
  const isViewer = useGeIsTeammateViewer('sales_and_wastages');

  const onCancelEdit = () => {
    setIsEditMode(false);
    setSelectedDate(null);
  };

  const onDeleteSale = () => {
    deleteSale(item.id);
    setDeleteDialogPosition(null);
  };

  const deleteOptionHandler = (e: MouseEvent<any>) => {
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    setDeleteDialogPosition({
      x: e.clientX,
      y: e.clientY,
    });
  };

  const onSaveSale = ({ qty, price }: { qty: number; price: number }, formikHelpers: FormikHelpers<any>) => {
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    formikHelpers.setSubmitting(false);
    updateSale({
      id: item.id,
      recipe_sale: { price, quantity: qty, sale_date: selectedDate ? selectedDate.toString() : undefined },
    }).then(() => {
      setIsEditMode(false);
    });
  };

  useEffect(() => {
    setSelectedDate(new Date(item.sale_date || Date.now()));
  }, [item.sale_date]);

  return isMobile ? (
    <div className={classes.row}>
      <div className={classes.rowHead}>
        <div className={classes.saleDateText}>{format(item.sale_date ? new Date(item.sale_date) : new Date(), 'd/MM/y')}</div>
        <div className={classes.rowHeadInfo}>
          <div className={classes.menuName}>
            <LinesEllipsis text={item.menu_name || ''} maxLine={1} />
          </div>
          <div className={classes.menuId}>{item.recipe.number}</div>
        </div>
        <DropdownMenu
          items={[
            <div key='delete' onClick={deleteOptionHandler}>
              Delete
            </div>,
          ]}
          triggerBtn={<MoreButton customClass={classes.moreDots} />}
        />
      </div>
      <div className={classes.recipeNameM}>
        <LinesEllipsis text={item.recipe.name || ''} maxLine={1} />
      </div>
      <div className={classes.rowTotalsM}>
        <div>
          <span className={classes.rowTotalsMLabel}>QNT SOLD</span>
          <span className={classes.boldText}>{item.quantity}</span>
        </div>
        <div>
          <span className={classes.rowTotalsMLabel}>COST</span>
          <span>{formatCurrency(item.cost || 0)}</span>
        </div>
        <div>
          <span className={classes.rowTotalsMLabel}>PRICE</span>
          <span className={classes.boldText}>{formatCurrency(item.price || 0)}</span>
        </div>
      </div>
      {deleteDialogPosition &&
        createPortal(
          <Dialog
            style={{
              left: 10,
              top: deleteDialogPosition ? deleteDialogPosition.y - 140 : '45%',
              width: 320,
              minHeight: 140,
            }}
            onCancel={setDeleteDialogPosition.bind(null, null)}
            onConfirm={onDeleteSale}
            title='Are you sure you want to delete this sale?'
          />,
          document.body,
        )}
    </div>
  ) : (
    <Formik
      initialValues={{ qty: item.quantity || 0, price: item.price ? +item.price : 0 }}
      onSubmit={onSaveSale}
      enableReinitialize={true}
    >
      {({ submitForm, values }) => (
        <Form>
          <div className={clsx(classes.row, isEditMode && classes.rowEditMode)}>
            <div className={classes.sale}>
              <div>
                {isEditMode ? (
                  <div className={classes.dateWrap}>
                    <DatePickerComponent
                      onDateChange={(date) => {
                        setSelectedDate(date);
                      }}
                      selectedDate={selectedDate}
                      minDate={subDays(new Date(), 360)}
                      trigger={
                        <div className={classes.dateTrigger}>
                          {selectedDate ? format(selectedDate, 'd/MM/y') : format(new Date(item.sale_date || Date.now()), 'd/MM/y')}
                          <KeyboardArrowDownIcon />
                        </div>
                      }
                    />
                  </div>
                ) : (
                  <div className={classes.saleDateText}>{format(item.sale_date ? new Date(item.sale_date) : new Date(), 'd/MM/y')}</div>
                )}
              </div>
            </div>
            <div className={classes.recipe}>
              <div className={classes.recipeName}>{item.recipe.name}</div>
            </div>
            <div className={classes.recipeId}>
              <div className={classes.recipeIdText}>{item.recipe.number}</div>
            </div>
            <div className={classes.menu}>{item.menu_name}</div>
            <div className={classes.sold}>
              {isEditMode ? (
                <div className={classes.cellInputWrap}>
                  <FastField name='qty'>
                    {(fieldProps: FieldProps) => <FormikInput {...fieldProps} placeholder={`0`} label='' height={40} type='number' />}
                  </FastField>
                </div>
              ) : (
                <div className={classes.soldText}>{item.quantity}</div>
              )}
            </div>
            <div className={classes.cost}>{formatCurrency(item.cost || 0)}</div>
            <div className={classes.price}>
              {isEditMode ? (
                <div className={clsx(classes.cellInputWrap, classes.cellInputWrapPrice)}>
                  <FastField name='price'>
                    {(fieldProps: FieldProps) => <FormikInput {...fieldProps} placeholder={`$0.00`} label='' height={40} type='number' />}
                  </FastField>
                </div>
              ) : (
                <div className={classes.sellPriceText}>{formatCurrency(item.price || 0)}</div>
              )}
            </div>
            <div className={classes.tax}>
              <div className={classes.taxText}>{formatCurrency(item.tax * item.quantity)}</div>
              <div className={clsx(classes.addBtnCell, classes.addBtnCellEnd)}>
                {isEditMode && (
                  <ThemedButton
                    onClick={onCancelEdit}
                    title='Cancel'
                    buttonStyle='secondary'
                    className={classes.addActionBtn}
                    width={98}
                    type='reset'
                  />
                )}
              </div>
            </div>
            <div className={classes.total}>
              <div className={classes.totalText}>{formatCurrency(+item.price * item.quantity)}</div>
              {isEditMode && (
                <div className={classes.addBtnCell}>
                  <ThemedButton
                    onClick={submitForm}
                    title='Save'
                    className={classes.addActionBtn}
                    width={98}
                    disabled={!+values.qty || !values.price || updateLoading}
                  />
                </div>
              )}
            </div>
            <div className={classes.actions}>
              {!isEditMode && (
                <DropdownMenu
                  items={[
                    <div
                      key='edit'
                      onClick={() => {
                        if (isViewer) {
                          ToastService.error('You have been restricted from making edits.');
                          return;
                        }
                        setIsEditMode(true);
                      }}
                    >
                      Edit
                    </div>,
                    <div key='delete' onClick={deleteOptionHandler}>
                      Delete
                    </div>,
                  ]}
                  triggerBtn={<MoreButton customClass={classes.moreDots} />}
                />
              )}
              {deleteDialogPosition &&
                createPortal(
                  <Dialog
                    style={{
                      left: deleteDialogPosition ? deleteDialogPosition.x - 300 : 20,
                      top: deleteDialogPosition ? deleteDialogPosition.y - 140 : '45%',
                      width: 320,
                      minHeight: 140,
                    }}
                    onCancel={setDeleteDialogPosition.bind(null, null)}
                    onConfirm={onDeleteSale}
                    title='Are you sure you want to delete this sale?'
                  />,
                  document.body,
                )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export const SalesTableRow = memo(Component);
