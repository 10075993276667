import { createSelector } from '@reduxjs/toolkit';

import { ProductFilterValues } from '../../api/product/types';
import { Roles } from '../../api/user/types';
import { restaurantProductSortParams } from '../../components/AppDrawer/RestaurantDrawer/BrowseByCategory/search-block';
import { productSortParams } from '../../components/AppDrawer/SupplierDrawer/Product/ProductList/product-filter';
import { formatCurrency } from '../../shared/helpers/format-currency';
import { RootState } from '../index';
import { productsAdapter } from './products.reducer';

const getProductsState = (state: RootState) => state.products;

const getIsRestaurant = (state: RootState) => state.user.role === Roles.CUSTOMER;

export const getProductSortFilters = (state: RootState) => ({
  sort: state.products.sortBy,
  filter: state.products.filterBy,
  keyword: state.products.keyword,
  currentPage: state.products.currentPage,
});

export const getFavoritesFilter = (state: RootState) => state.products.filterBy?.favourites;

export const getProductCategoryFilter = (state: RootState) => state.products.filterBy?.category_id;

export const getProductSupplierFilter = (state: RootState) => state.products.filterBy?.supplier_id;

export const getLastAddedProductId = (state: RootState) => state.products.lastAddedToCartId;

export const getProductsCount = (state: RootState) => state.products.resultsCount;

export const getIsDeletePriceListMode = (state: RootState) => state.products.isDeletePriceListMode;

export const getProductChips = createSelector([getIsRestaurant, getProductsState], (isRestaurant, products) => {
  const sortLabel =
    (isRestaurant ? restaurantProductSortParams : productSortParams).find(
      (param) => param.value !== 'relevance' && param.value !== 'order_items_count desc' && param.value === products.sortBy?.join(' '),
    )?.label || '';
  const sortChip = {
    label: sortLabel,
    key: sortLabel ? 'sort' : '',
  };
  const filterChips = Object.entries(products.filterBy || {}).reduce((acc, [key, value]: [string, string[] | string]) => {
    switch (key as keyof ProductFilterValues) {
      case 'status': {
        if (!Array.isArray(value)) {
          return acc;
        }
        acc.push(
          ...value.map((el) => ({
            label: `Products - ${el.split('_')[0]}`,
            key: `status ${el}`,
          })),
        );
        break;
      }
      case 'stock': {
        if (!Array.isArray(value)) {
          return acc;
        }
        acc.push(
          ...value.map((el) => ({
            label: `Stock level - ${el === '2' ? 'In stock' : el === '1' ? 'Low' : 'Out of stock'}`,
            key: `stock ${el}`,
          })),
        );
        break;
      }
      case 'gst': {
        if (!Array.isArray(value)) {
          return acc;
        }
        acc.push(
          ...value.map((el) => ({
            label: `Tax - ${el === '0' ? 'NO' : 'YES'}`,
            key: `gst ${el}`,
          })),
        );
        break;
      }
      case 'min_cost': {
        acc.push({
          label: `Your Cost - more than ${formatCurrency(value as string)}`,
          key,
        });
        break;
      }
      case 'max_cost': {
        acc.push({
          label: `Your Cost - less than ${formatCurrency(value as string)}`,
          key,
        });
        break;
      }
      case 'favourites': {
        value &&
          acc.push({
            label: 'Favourites',
            key,
          });
        break;
      }
      case 'recently_purchased': {
        value &&
          acc.push({
            label: 'Recently purchased',
            key,
          });
        break;
      }
    }
    return acc;
  }, [] as { label: string; key: string }[]);
  return [sortChip, ...filterChips].filter((chip) => !!chip.label && !!chip.key);
});

export const getIsPriceListActionsDisabled = (state: RootState) => state.products.isPriceListActionsDisabled;

export const getImportProductsLoading = (state: RootState) => state.products.importLoading;

export const getAddPriceListLoadingLoading = (state: RootState) => state.products.addPriceListLoading;

export const productsSelectors = productsAdapter.getSelectors((state: RootState) => state.products.list);
