import React from 'react';

import { IconButton } from '@material-ui/core';

import SvgEditIcon from '../../../assets/icons/EditIcon';

import { colorVariables } from '../../../styles/colorVariables';
import { useStyles } from './style';

interface Props {
  onClick?: () => void;
  disabled?: boolean;
}

export const EditIcon: React.FC<Props> = React.memo(({ onClick, disabled = false }) => {
  const classes = useStyles();
  return (
    <IconButton className={classes.button} onClick={onClick} disabled={disabled}>
      <SvgEditIcon className={classes.icon} color={colorVariables.steelGrey} />
    </IconButton>
  );
});
