import { BaseQueryFn, HTTP, rootApi, SuccessResponse } from '../index';
import { CreateThemeResponse, GetDomainsRes, GetThemeRes, GetThemesRes } from './types';
import { setSuccessToast } from '../helpers';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

export const currentThemeApi = createApi({
  reducerPath: 'THEME-API',
  tagTypes: ['Current_Theme'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: async (headers) => {
      headers.set('domain-url', window.location.hostname);
      return headers;
    },
  }) as BaseQueryFn,
  endpoints: (builder) => ({
    getCurrentTheme: builder.query<GetThemeRes, undefined>({
      query() {
        return {
          url: `/current_theme`,
          method: HTTP.GET,
        };
      },
      providesTags: ['Current_Theme'],
      keepUnusedDataFor: 0,
    }),
  }),
});

export const adminThemesApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getThemes: builder.query<GetThemesRes, void>({
      query() {
        return {
          url: `/admin/themes`,
          method: HTTP.GET,
        };
      },
      providesTags: ['Theme'],
    }),

    getTheme: builder.query<GetThemeRes, number>({
      query(id) {
        return {
          url: `/admin/themes/${id}`,
          method: HTTP.GET,
        };
      },
      providesTags: ['Theme'],
    }),

    createTheme: builder.mutation<CreateThemeResponse, { formData: FormData }>({
      query({ formData }) {
        return {
          url: `/admin/themes`,
          method: HTTP.POST,
          body: formData,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'Theme has been created');
      },
      invalidatesTags: ['Theme'],
    }),

    updateTheme: builder.mutation<SuccessResponse, { formData: FormData; id: number }>({
      query({ formData, id }) {
        return {
          url: `/admin/themes/${id}`,
          method: HTTP.PATCH,
          body: formData,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(currentThemeApi.endpoints.getCurrentTheme.initiate(undefined, { forceRefetch: true }));
      },
      invalidatesTags: ['Theme'],
    }),

    deleteTheme: builder.mutation<SuccessResponse, number>({
      query(id) {
        return {
          url: `/admin/themes/${id}`,
          method: HTTP.DELETE,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'Theme has been deleted');
        dispatch(currentThemeApi.endpoints.getCurrentTheme.initiate(undefined, { forceRefetch: true }));
      },
      invalidatesTags: ['Theme'],
    }),

    getDomains: builder.query<GetDomainsRes, void>({
      query() {
        return {
          url: `/admin/domains`,
          method: HTTP.GET,
        };
      },
      providesTags: ['Theme'],
    }),

    createDomain: builder.mutation<SuccessResponse, { theme_id: string | null; domain_url: string }>({
      query(domain) {
        return {
          url: `/admin/domains`,
          method: HTTP.POST,
          body: { domain },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'Domain has been created');
        if (!args.theme_id) {
          window.location.reload();
        }
        dispatch(currentThemeApi.endpoints.getCurrentTheme.initiate(undefined, { forceRefetch: true }));
      },
      invalidatesTags: ['Theme'],
    }),

    updateDomain: builder.mutation<SuccessResponse, { theme_id: string | null; domain_url: string; id: number }>({
      query({ id, ...body }) {
        return {
          url: `/admin/domains/${id}`,
          method: HTTP.PATCH,
          body,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'Domain has been updated');
        if (!args.theme_id) {
          window.location.reload();
        }
        dispatch(currentThemeApi.endpoints.getCurrentTheme.initiate(undefined, { forceRefetch: true }));
      },
      invalidatesTags: ['Theme'],
    }),
  }),

  overrideExisting: true,
});

export const {
  useGetThemesQuery,
  useGetThemeQuery,
  useCreateThemeMutation,
  useUpdateThemeMutation,
  useDeleteThemeMutation,
  useGetDomainsQuery,
  useCreateDomainMutation,
  useUpdateDomainMutation,
} = adminThemesApi;

export const { useGetCurrentThemeQuery, usePrefetch } = currentThemeApi;
