import { differenceInDays, differenceInMonths, differenceInWeeks } from 'date-fns';

export const formatDateDifference = (date?: string | null): string => {
  if (!date) {
    return '-';
  }
  const currentDate = new Date();
  const targetDate = new Date(date);

  const daysDifference = differenceInDays(currentDate, targetDate);
  const weeksDifference = differenceInWeeks(currentDate, targetDate);
  const monthsDifference = differenceInMonths(currentDate, targetDate);

  if (daysDifference === 0) {
    return 'Today';
  } else if (daysDifference === 1) {
    return 'Yesterday';
  } else if (daysDifference <= 7) {
    return `${daysDifference} day${daysDifference === 1 ? '' : 's'} ago`;
  } else if (weeksDifference <= 4) {
    return `${weeksDifference} week${weeksDifference === 1 ? '' : 's'} ago`;
  } else if (monthsDifference <= 12) {
    return `${monthsDifference} month${monthsDifference === 1 ? '' : 's'} ago`;
  } else {
    return 'Long time ago';
  }
};
