import React, { useEffect, useState } from 'react';

import { Collapse } from '@material-ui/core';
import { ChevronRight, KeyboardArrowDown } from '@material-ui/icons';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import clsx from 'clsx';
import { format, subDays } from 'date-fns';
import { FastField, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import ContentLoader from 'react-content-loader';

import { useInView } from 'react-intersection-observer';
import { Recipe } from '../../../../../../api/menu/types';
import { useCreateSaleMutation } from '../../../../../../api/sales';
import { useGetImportSalesTemplate, useGetSalesList } from '../../../../../../api/sales/hooks';
import { SalesSortBy } from '../../../../../../api/sales/types';
import { useGeIsTeammateViewer } from '../../../../../../api/teammates/hooks';
import { ThemedButton } from '../../../../../../shared/components/themed-button';
import { DatePickerComponent } from '../../../../../../shared/components/date-picker';
import { IDateRange } from '../../../../../../shared/components/date-range';
import { DropdownMenu } from '../../../../../../shared/components/dropdown-menu';
import { FilterButton } from '../../../../../../shared/components/filter-button';
import { FormikInput } from '../../../../../../shared/components/formik-input';
import { RangeDropdown, RangeOptions } from '../../../../../../shared/components/range-dropdown';
import { CustomTooltip } from '../../../../../../shared/components/tooltip';
import { formatCurrency } from '../../../../../../shared/helpers/format-currency';
import { useScreenSize } from '../../../../../../shared/hooks/use-screen-size';
import { LocalStorageService } from '../../../../../../shared/services/localStorage.service';
import { ToastService } from '../../../../../../shared/services/toastService';
import { useAppDispatch, useAppSelector } from '../../../../../../store';
import { getSalesSortFilters, salesSelectors } from '../../../../../../store/sales';
import { setSalesSortFilters } from '../../../../../../store/sales/sales.actions';
import { colorVariables } from '../../../../../../styles/colorVariables';
import { ImportSalesModal } from './ImportSalesModal';
import { SaleFilter } from './sale-filter';
import { SalesTableRow } from './SalesTableRow';
import { SearchRecipeByIdForSale } from './search-recipe-byid-for-sale';
import { SearchRecipeForSale } from './search-recipe-for-sale';

import { useStyles } from './style';

export const salesSortParams = [
  { label: 'Date (Most recent to Old)', value: 'sale_date desc' },
  { label: 'Date (Old to Most recent)', value: 'sale_date asc' },
  { label: 'Product/Recipe (A - Z)', value: 'name asc' },
  { label: 'Product/Recipe (Z - A)', value: 'name desc' },
  { label: 'Product/Recipe ID (A - Z)', value: 'recipe_id asc' },
  { label: 'Product/Recipe ID (Z - A)', value: 'recipe_id desc' },
  { label: 'Qty sold (Low - High)', value: 'quantity asc' },
  { label: 'Qty sold (High - Low)', value: 'quantity desc' },
  { label: 'Cost (Low - High)', value: 'cost asc' },
  { label: 'Cost (High - Low)', value: 'cost desc' },
  { label: 'Price (Low - High)', value: 'price asc' },
  { label: 'Price (High - Low)', value: 'price desc' },
  { label: 'Tax (Low - High)', value: 'tax asc' },
  { label: 'Tax (High - Low)', value: 'tax desc' },
  { label: 'Total (Low - High)', value: 'total asc' },
  { label: 'Total (High - Low)', value: 'total desc' },
];

export const SalesPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [inViewRef, inView] = useInView();
  const { isMobile } = useScreenSize();

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedRecipe, setSelectedRecipe] = useState<Recipe | null>(null);
  const [importModalOpened, setImportModalOpened] = useState(false);
  const [addNewExpanded, setAddNewExpanded] = useState(false);
  const [isFilterOpened, setIsFilterOpened] = useState(false);

  const { tax, subtotal, menuData, loading } = useGetSalesList(inView);
  const { filter, sortBy, currentPage } = useAppSelector(getSalesSortFilters);
  const list = useAppSelector(salesSelectors.selectAll);
  const isViewer = useGeIsTeammateViewer('sales_and_wastages');

  const [createSale, { isLoading: createLoading }] = useCreateSaleMutation();
  const { onDownload, loading: getRecipesLoading } = useGetImportSalesTemplate();

  const setSalesRange = (range: RangeOptions) => {
    if (range !== RangeOptions.CUSTOM) {
      dispatch(setSalesSortFilters({ filter: { selectedRange: range, menu: filter?.menu }, sortBy }));
    }
  };

  const onApplySort = (param: SalesSortBy) => {
    dispatch(setSalesSortFilters({ sortBy: param, filter }));
  };

  const setSalesDateRange = (dateRange: IDateRange) => {
    dispatch(
      setSalesSortFilters({
        filter: {
          fromDate: dateRange.startDate?.toDateString(),
          toDate: dateRange.endDate?.toDateString(),
          selectedRange: dateRange.startDate ? RangeOptions.CUSTOM : filter?.selectedRange || RangeOptions.LAST_7,
          menu: filter?.menu,
        },
        sortBy,
      }),
    );
  };

  const onAddSale = ({ qty, price }: { qty: number; price: number }, formikHelpers: FormikHelpers<any>) => {
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    formikHelpers.setSubmitting(false);
    selectedRecipe &&
      createSale({
        recipe_sale: {
          sale_date: (selectedDate || new Date()).toString(),
          recipe_id: selectedRecipe.id,
          quantity: qty,
          price,
        },
      });
    formikHelpers.resetForm(undefined);
    setSelectedDate(null);
    setSelectedRecipe(null);
  };

  const onCancel = () => {
    setSelectedDate(null);
    setSelectedRecipe(null);
    setAddNewExpanded(false);
  };

  const toggleAddNewExpanded = () => {
    setAddNewExpanded((prev) => !prev);
  };

  const handleToggleFilter = () => {
    setIsFilterOpened((prev) => !prev);
  };

  const FilterToolTip = (
    <CustomTooltip
      right={isMobile ? 0 : -45}
      onClose={setIsFilterOpened.bind(null, false)}
      triggerBtn={<FilterButton color={colorVariables.grey60} onClick={handleToggleFilter} />}
      clickOutsideUnTrackable={true}
      isOpen={isFilterOpened}
    >
      <SaleFilter handleCloseModal={setIsFilterOpened.bind(null, false)} />
    </CustomTooltip>
  );

  useEffect(() => {
    LocalStorageService.getItem('sales_range') &&
      dispatch(setSalesSortFilters({ filter: { selectedRange: LocalStorageService.getItem('sales_range') } }));
    LocalStorageService.clear('sales_range');
  }, []);

  return (
    <div className={classes.box}>
      <div className={classes.headAction}>
        <div className={classes.filtersBlock}>
          <RangeDropdown
            defaultRange={filter?.selectedRange || RangeOptions.LAST_7}
            triggerClass={classes.dropdownTrigger}
            setRange={setSalesRange}
            setDateRange={setSalesDateRange}
            triggerDateFormat={'d/MM/yy'}
            skipDateUpdate={true}
          />
        </div>
        <div className={classes.importBlock}>
          <div className={classes.filterIcon}>{FilterToolTip}</div>
          <ThemedButton
            onClick={() => {
              if (isViewer) {
                ToastService.error('You have been restricted from making edits.');
                return;
              }
              onDownload();
            }}
            title={getRecipesLoading ? 'Loading...' : 'Download template'}
            buttonStyle='blueTransparent'
            isSmall={true}
            disabled={getRecipesLoading}
            width={180}
          />
          <ThemedButton
            onClick={() => {
              if (isViewer) {
                ToastService.error('You have been restricted from making edits.');
                return;
              }
              setImportModalOpened(true);
            }}
            title='Import sales'
            isSmall={true}
          />
        </div>
      </div>
      <div className={classes.tableHead}>
        <div className={classes.sale}>
          <DropdownMenu
            items={[
              <div key='asc' onClick={onApplySort.bind(null, ['sale_date', 'desc'])}>
                Most recent to Old
              </div>,
              <div key='desc' onClick={onApplySort.bind(null, ['sale_date', 'asc'])}>
                Old to Most recent
              </div>,
            ]}
            triggerBtn={
              <div className={classes.sortTrigger}>
                <span className={classes.colHeadText}>Date</span>
                <KeyboardArrowDown />
              </div>
            }
          />
        </div>
        <div className={classes.recipe}>
          <DropdownMenu
            items={[
              <div key='asc' onClick={onApplySort.bind(null, ['name', 'asc'])}>
                Sort A-Z
              </div>,
              <div key='desc' onClick={onApplySort.bind(null, ['name', 'desc'])}>
                Sort Z-A
              </div>,
            ]}
            triggerBtn={
              <div className={classes.sortTrigger}>
                <span className={classes.colHeadText}> Product/Recipe</span>
                <KeyboardArrowDown />
              </div>
            }
          />
        </div>
        <div className={classes.recipeId}>
          <DropdownMenu
            items={[
              <div key='asc' onClick={onApplySort.bind(null, ['recipe_id', 'asc'])}>
                Sort A-Z
              </div>,
              <div key='desc' onClick={onApplySort.bind(null, ['recipe_id', 'desc'])}>
                Sort Z-A
              </div>,
            ]}
            triggerBtn={
              <div className={classes.sortTrigger}>
                <span className={classes.colHeadText}> Product/Recipe ID</span>
                <KeyboardArrowDown />
              </div>
            }
          />
        </div>
        <div className={classes.menu}>Menu type</div>
        <div className={classes.sold}>
          <DropdownMenu
            items={[
              <div key='asc' onClick={onApplySort.bind(null, ['quantity', 'asc'])}>
                Low to High
              </div>,
              <div key='desc' onClick={onApplySort.bind(null, ['quantity', 'desc'])}>
                High to Low
              </div>,
            ]}
            triggerBtn={
              <div className={classes.sortTrigger}>
                <span className={classes.colHeadText}> Qty sold</span>
                <KeyboardArrowDown />
              </div>
            }
          />
        </div>
        <div className={classes.cost}>
          <DropdownMenu
            items={[
              <div key='asc' onClick={onApplySort.bind(null, ['cost', 'asc'])}>
                Low to High
              </div>,
              <div key='desc' onClick={onApplySort.bind(null, ['cost', 'desc'])}>
                High to Low
              </div>,
            ]}
            triggerBtn={
              <div className={classes.sortTrigger}>
                <span className={classes.colHeadText}> Cost</span>
                <KeyboardArrowDown />
              </div>
            }
          />
        </div>
        <div className={classes.price}>
          <DropdownMenu
            items={[
              <div key='asc' onClick={onApplySort.bind(null, ['price', 'asc'])}>
                Low to High
              </div>,
              <div key='desc' onClick={onApplySort.bind(null, ['price', 'desc'])}>
                High to Low
              </div>,
            ]}
            triggerBtn={
              <div className={classes.sortTrigger}>
                <span className={classes.colHeadText}> Price</span>
                <KeyboardArrowDown />
              </div>
            }
          />
        </div>
        <div className={classes.tax}>
          <DropdownMenu
            items={[
              <div key='asc' onClick={onApplySort.bind(null, ['tax', 'asc'])}>
                Low to High
              </div>,
              <div key='desc' onClick={onApplySort.bind(null, ['tax', 'desc'])}>
                High to Low
              </div>,
            ]}
            triggerBtn={
              <div className={classes.sortTrigger}>
                <span className={classes.colHeadText}> Tax</span>
                <KeyboardArrowDown />
              </div>
            }
          />
        </div>
        <div className={classes.total}>
          <DropdownMenu
            items={[
              <div key='asc' onClick={onApplySort.bind(null, ['total', 'asc'])}>
                Low to High
              </div>,
              <div key='desc' onClick={onApplySort.bind(null, ['total', 'desc'])}>
                High to Low
              </div>,
            ]}
            triggerBtn={
              <div className={classes.sortTrigger}>
                <span className={classes.colHeadText}> total</span>
                <KeyboardArrowDown />
              </div>
            }
          />
        </div>
        <div className={classes.actions} />
      </div>
      <div className={classes.list}>
        {isMobile ? (
          <div className={classes.fixedHead}>
            <RangeDropdown
              defaultRange={filter?.selectedRange || RangeOptions.LAST_7}
              triggerClass={classes.dropdownTrigger}
              setRange={setSalesRange}
              setDateRange={setSalesDateRange}
              triggerDateFormat={'d/MM/yy'}
              skipDateUpdate={true}
            />
            <div className={classes.addNewSaleM}>
              <div className={classes.addSaleBtnM} onClick={toggleAddNewExpanded}>
                <span>ADD NEW SALE</span>
                <ChevronRight className={addNewExpanded ? classes.addChevronRotated : undefined} />
              </div>
              <Collapse in={addNewExpanded} timeout={500}>
                <Formik
                  initialValues={{ qty: 0, price: selectedRecipe?.price_cents ? selectedRecipe.price_cents / 100 : 0 }}
                  onSubmit={onAddSale}
                  enableReinitialize={true}
                >
                  {({ submitForm, values }) => (
                    <Form className={classes.addNewBox}>
                      <div className={classes.addNewDateWrap}>
                        <div className={classes.dateWrap}>
                          <DatePickerComponent
                            onDateChange={(date) => {
                              setSelectedDate(date);
                            }}
                            selectedDate={selectedDate}
                            minDate={subDays(new Date(), 360)}
                            trigger={
                              <div className={classes.dateTrigger}>
                                {selectedDate ? format(selectedDate, 'd/MM/y') : 'Today'}
                                <KeyboardArrowDownIcon />
                              </div>
                            }
                          />
                        </div>
                        <div className={classes.recipeId}>
                          <SearchRecipeByIdForSale
                            setRecipe={(recipe) => {
                              setSelectedRecipe(recipe);
                            }}
                            selectedRecipe={selectedRecipe}
                          />
                        </div>
                      </div>
                      <div className={classes.recipe}>
                        <SearchRecipeForSale
                          setRecipe={(recipe) => {
                            setSelectedRecipe(recipe);
                          }}
                          selectedRecipe={selectedRecipe}
                        />
                      </div>
                      <div className={classes.addNewInputsM}>
                        <div className={classes.sold}>
                          <div className={classes.cellInputWrap}>
                            <FastField name='qty'>
                              {(fieldProps: FieldProps) => (
                                <FormikInput {...fieldProps} placeholder={`0`} label='QNT SOLD' height={40} type='number' />
                              )}
                            </FastField>
                          </div>
                        </div>
                        <div className={classes.cost}>
                          <div className={classes.costLabel}>COST</div>
                          <div className={classes.costValue}> {formatCurrency(selectedRecipe ? selectedRecipe.food_cost : 0)}</div>
                        </div>
                        <div className={classes.price}>
                          <div className={classes.cellInputWrap}>
                            <FastField name='price'>
                              {(fieldProps: FieldProps) => (
                                <FormikInput {...fieldProps} placeholder={`$0.00`} label='PRICE' height={40} type='number' />
                              )}
                            </FastField>
                          </div>
                        </div>
                      </div>
                      <div className={classes.addNewActions}>
                        <div className={classes.menuNameM}>{selectedRecipe?.menu.name || ' '}</div>
                        <div>
                          <ThemedButton
                            onClick={onCancel}
                            title='Cancel'
                            buttonStyle='secondary'
                            className={classes.addActionBtn}
                            width={98}
                            type='reset'
                          />
                          <ThemedButton
                            onClick={submitForm}
                            title='Add'
                            className={classes.addActionBtn}
                            width={98}
                            disabled={!+values.qty || !values.price || !selectedRecipe || createLoading}
                          />
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Collapse>
            </div>
          </div>
        ) : (
          <Formik
            initialValues={{ qty: 0, price: selectedRecipe?.price_cents ? selectedRecipe.price_cents / 100 : 0 }}
            onSubmit={onAddSale}
            enableReinitialize={true}
          >
            {({ submitForm, values }) => (
              <Form className={classes.addRow}>
                <div className={classes.sale}>
                  <div className={classes.dateWrap}>
                    <DatePickerComponent
                      onDateChange={(date) => {
                        setSelectedDate(date);
                      }}
                      selectedDate={selectedDate}
                      minDate={subDays(new Date(), 360)}
                      trigger={
                        <div className={classes.dateTrigger}>
                          {selectedDate ? format(selectedDate, 'd/MM/y') : 'Today'}
                          <KeyboardArrowDownIcon />
                        </div>
                      }
                    />
                  </div>
                </div>
                <div className={classes.recipe}>
                  <SearchRecipeForSale
                    setRecipe={(recipe) => {
                      setSelectedRecipe(recipe);
                    }}
                    selectedRecipe={selectedRecipe}
                  />
                </div>
                <div className={classes.recipeId}>
                  <SearchRecipeByIdForSale
                    setRecipe={(recipe) => {
                      setSelectedRecipe(recipe);
                    }}
                    selectedRecipe={selectedRecipe}
                  />
                </div>
                <div className={clsx(classes.menu, classes.greyCell)}>{selectedRecipe ? selectedRecipe.menu.name : ''}</div>
                <div className={classes.sold}>
                  <div className={classes.cellInputWrap}>
                    <FastField name='qty'>
                      {(fieldProps: FieldProps) => <FormikInput {...fieldProps} placeholder={`0`} label='' height={40} type='number' />}
                    </FastField>
                  </div>
                </div>
                <div className={clsx(classes.cost, classes.greyCell)}>{selectedRecipe ? formatCurrency(selectedRecipe.food_cost) : ''}</div>
                <div className={classes.price}>
                  <div className={clsx(classes.cellInputWrap, classes.cellInputWrapPrice)}>
                    <FastField name='price'>
                      {(fieldProps: FieldProps) => <FormikInput {...fieldProps} placeholder={`$0.00`} label='' height={40} type='number' />}
                    </FastField>
                  </div>
                </div>
                <div className={clsx(classes.tax, classes.addBtnCell)}>
                  <ThemedButton
                    onClick={onCancel}
                    title='Cancel'
                    buttonStyle='secondary'
                    className={classes.addActionBtn}
                    width={98}
                    type='reset'
                  />
                </div>
                <div className={clsx(classes.total)}>
                  <ThemedButton
                    onClick={submitForm}
                    title='Add'
                    className={classes.addActionBtn}
                    width={98}
                    disabled={!+values.qty || !values.price || !selectedRecipe || createLoading}
                  />
                </div>
                <div className={classes.actions} />
              </Form>
            )}
          </Formik>
        )}
        {loading && currentPage === 1
          ? new Array(15).fill(1).map((_, idx) => <LoadingRow key={idx} />)
          : list.map((item, idx, arr) => (
              <div ref={idx === arr.length - 10 ? inViewRef : undefined} key={item.id}>
                <SalesTableRow item={item} />
              </div>
            ))}
        {loading && currentPage > 1 && new Array(15).fill(1).map((_, idx) => <LoadingRow key={idx} />)}
      </div>
      <div className={classes.totalInfoRow}>
        <div className={classes.menuTotalBox}>
          {menuData.map((el) => (
            <div className={classes.menuTotal} key={el.name}>
              <div className={classes.menuName}>{el.name}</div>
              <div className={classes.boldText}>{formatCurrency(el.total || 0)}</div>
            </div>
          ))}
        </div>
        <div className={classes.salesTotal}>
          <div className={classes.salesTotalRow}>
            <div className={classes.salesTotalLabel}>Subtotal</div>
            <div className={classes.salesTotalValue}>{formatCurrency(subtotal)}</div>
          </div>
          <div className={classes.salesTotalRow}>
            <div className={classes.salesTotalLabel}>Tax</div>
            <div className={classes.salesTotalValue}>{formatCurrency(tax)}</div>
          </div>
          <div className={clsx(classes.salesTotalRow, classes.boldText)}>
            <div className={classes.salesTotalLabel}>Total</div>
            <div className={classes.salesTotalValue}>{formatCurrency(+tax + +subtotal)}</div>
          </div>
        </div>
      </div>
      {importModalOpened && <ImportSalesModal onClose={setImportModalOpened.bind(null, false)} isOpen={importModalOpened} />}
    </div>
  );
};

const LoadingRow = () => {
  const classes = useStyles();
  const { isMobile } = useScreenSize();

  return isMobile ? (
    <div className={classes.loadingRow}>
      <div>
        <ContentLoader
          title={''}
          speed={1.8}
          width={74}
          height={13}
          backgroundColor='rgba(152, 165, 183, 0.3)'
          foregroundColor='rgba(152, 165, 183, 0.15)'
        >
          <rect x='0' y='0' rx='7' ry='7' width='74' height='13' />
        </ContentLoader>
      </div>
      <div>
        <ContentLoader
          title={''}
          speed={1.8}
          width={60}
          height={13}
          backgroundColor='rgba(152, 165, 183, 0.2)'
          foregroundColor='rgba(152, 165, 183, 0.05)'
        >
          <rect x='0' y='0' rx='7' ry='7' width='60' height='13' />
        </ContentLoader>
      </div>
      <div className={classes.bottomSkeletonRow}>
        <div>
          <ContentLoader
            title={''}
            speed={1.8}
            width={100}
            height={13}
            backgroundColor='rgba(152, 165, 183, 0.3)'
            foregroundColor='rgba(152, 165, 183, 0.15)'
          >
            <rect x='0' y='0' rx='7' ry='7' width='100' height='13' />
          </ContentLoader>
        </div>
        <div>
          <ContentLoader
            title={''}
            speed={1.8}
            width={100}
            height={13}
            backgroundColor='rgba(152, 165, 183, 0.15)'
            foregroundColor='rgba(152, 165, 183, 0.05)'
          >
            <rect x='0' y='0' rx='7' ry='7' width='100' height='13' />
          </ContentLoader>
        </div>
        <div>
          <ContentLoader
            title={''}
            speed={1.8}
            width={100}
            height={13}
            backgroundColor='rgba(152, 165, 183, 0.3)'
            foregroundColor='rgba(152, 165, 183, 0.15)'
          >
            <rect x='0' y='0' rx='7' ry='7' width='100' height='13' />
          </ContentLoader>
        </div>
      </div>
    </div>
  ) : (
    <div className={classes.loadingRow}>
      <div className={classes.sale}>
        <div>
          <div>
            <ContentLoader
              title={''}
              speed={1.8}
              width={74}
              height={13}
              backgroundColor='rgba(152, 165, 183, 0.3)'
              foregroundColor='rgba(152, 165, 183, 0.1)'
            >
              <rect x='0' y='0' rx='7' ry='7' width='74' height='13' />
            </ContentLoader>
          </div>
          <div>
            <ContentLoader
              title={''}
              speed={1.8}
              width={63}
              height={13}
              backgroundColor='rgba(152, 165, 183, 0.15)'
              foregroundColor='rgba(152, 165, 183, 0.05)'
            >
              <rect x='0' y='0' rx='7' ry='7' width='63' height='13' />
            </ContentLoader>
          </div>
        </div>
      </div>
      <div className={classes.recipe}>
        <div>
          <div>
            <ContentLoader
              title={''}
              speed={1.8}
              width={'90%'}
              height={13}
              backgroundColor='rgba(152, 165, 183, 0.3)'
              foregroundColor='rgba(152, 165, 183, 0.1)'
            >
              <rect x='0' y='0' rx='7' ry='7' width='90%' height='13' />
            </ContentLoader>
          </div>
          <div>
            <ContentLoader
              title={''}
              speed={1.8}
              width={'60%'}
              height={13}
              backgroundColor='rgba(152, 165, 183, 0.15)'
              foregroundColor='rgba(152, 165, 183, 0.05)'
            >
              <rect x='0' y='0' rx='7' ry='7' width='60%' height='13' />
            </ContentLoader>
          </div>
        </div>
      </div>
      <div className={classes.recipeId}>
        <div className={classes.centeredSkeleton}>
          <ContentLoader
            title={''}
            speed={1.8}
            width={'90%'}
            height={13}
            backgroundColor='rgba(152, 165, 183, 0.15)'
            foregroundColor='rgba(152, 165, 183, 0.05)'
          >
            <rect x='0' y='0' rx='7' ry='7' width='90%' height='13' />
          </ContentLoader>
        </div>
      </div>
      <div className={classes.menu}>
        <div className={classes.centeredSkeleton}>
          <ContentLoader
            title={''}
            speed={1.8}
            width={'90%'}
            height={13}
            backgroundColor='rgba(152, 165, 183, 0.3)'
            foregroundColor='rgba(152, 165, 183, 0.15)'
          >
            <rect x='0' y='0' rx='7' ry='7' width='90%' height='13' />
          </ContentLoader>
        </div>
      </div>
      <div className={classes.sold}>
        <div className={classes.cellEmpty} />
      </div>
      <div className={classes.cost}>
        <div className={classes.centeredSkeleton}>
          <ContentLoader
            title={''}
            speed={1.8}
            width={80}
            height={13}
            backgroundColor='rgba(152, 165, 183, 0.2)'
            foregroundColor='rgba(152, 165, 183, 0.1)'
          >
            <rect x='0' y='0' rx='7' ry='7' width='80' height='13' />
          </ContentLoader>
        </div>
      </div>
      <div className={classes.price}>
        <div className={clsx(classes.cellEmpty, classes.cellEmptyPrice)} />
      </div>
      <div className={classes.tax}>
        <div className={classes.centeredSkeleton}>
          <ContentLoader
            title={''}
            speed={1.8}
            width={80}
            height={13}
            backgroundColor='rgba(152, 165, 183, 0.3)'
            foregroundColor='rgba(152, 165, 183, 0.15)'
          >
            <rect x='0' y='0' rx='7' ry='7' width='80' height='13' />
          </ContentLoader>
        </div>
      </div>
      <div className={classes.total}>
        <div className={classes.centeredSkeleton}>
          <ContentLoader
            title={''}
            speed={1.8}
            width={'80%'}
            height={13}
            backgroundColor='rgba(152, 165, 183, 0.3)'
            foregroundColor='rgba(152, 165, 183, 0.15)'
          >
            <rect x='0' y='0' rx='7' ry='7' width='80%' height='13' />
          </ContentLoader>
        </div>
      </div>
      <div className={classes.actions} />
    </div>
  );
};
