import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import useTimeout from '../../shared/hooks/use-timeout';
import { RootState, useAppDispatch, useAppSelector } from '../../store';
import {
  getPantryListSortFilters,
  getSelectedPantryListId,
  pantryListsSelectors,
  setPantryListKeyword,
  setPantryListPage,
  setPantryListsSortFilters,
} from '../../store/pantry-lists';
import { getPantryListProductFilter } from '../../store/pantry-lists/pantry-lists.selectors';
import { getIsRestaurant } from '../../store/user';
import { useGetPantryListProductsQuery, useGetPantryListsQuery } from './index';

export const useGetSelectedPantryList = () => {
  const id = useAppSelector(getSelectedPantryListId) as number;
  const pantryList = useAppSelector((state: RootState) => pantryListsSelectors.selectById(state, id || 0));
  const { data } = useGetPantryListProductsQuery(
    { id },
    {
      skip: !!pantryList || !id,
    },
  );

  return { pantryList: pantryList || data };
};

export const useGetPantryLists = (inView: boolean) => {
  const { currentPage, keyword, sort, filter } = useAppSelector(getPantryListSortFilters);
  const dispatch = useAppDispatch();

  const { loading, next_page } = useGetPantryListsQuery(
    { keyword, currentPage, sort, filter },
    {
      selectFromResult: ({ data, isFetching }) => ({
        loading: isFetching,
        next_page: data?.next_page,
      }),
    },
  );
  useEffect(() => {
    inView && next_page && dispatch(setPantryListPage(next_page));
  }, [inView, next_page]);

  return { loading };
};

export const useGetPantryListById = () => {
  const id = useAppSelector(getSelectedPantryListId) as number;
  const { data: pantryList } = useGetPantryListProductsQuery(
    { id },
    {
      skip: !id,
    },
  );
  return pantryList;
};

export const useSetPantryListsDefaults = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const isRestaurant = useAppSelector(getIsRestaurant);
  const { keyword, filter, sort } = useAppSelector(getPantryListSortFilters);
  useTimeout(
    () => {
      dispatch(setPantryListKeyword(''));
    },
    pathname.includes('/pantry_list') || !keyword ? null : 300000,
  );
  if (pathname !== '/pantry_list/details' && pathname !== '/pantry_list' && isRestaurant && filter) {
    dispatch(setPantryListsSortFilters({ filter: null, sort }));
  }
};

export const useGetPantryListProductsList = () => {
  const id = useAppSelector(getSelectedPantryListId) as number;
  const filter = useAppSelector(getPantryListProductFilter);
  const { data, isFetching } = useGetPantryListProductsQuery(
    {
      id,
      ...filter,
      favourites: filter.favourites || undefined,
      recently_purchased: filter.recently_purchased || undefined,
      sort_by: filter.sort_by,
    },
    { skip: !id },
  );

  return useMemo(() => {
    return {
      savedFilters: data?.search_keyword,
      suppliers: data?.suppliers_company || [],
      pantryListName: data?.name || '',
      products: data?.pantry_list_items.map((el) => ({ ...el.product, price_per_unit: el.total_price })) || [],
      isFetching,
    };
  }, [data, isFetching]);
};
