import * as React from 'react';

function SvgSubRecipeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx={12} cy={12} r={11.5} fill='#fff' stroke='#CFD7E6' />
      <path
        d='M14.571 15.857v-1.244a5.761 5.761 0 001.52-1.093 5.768 5.768 0 001.694-4.092H6.214a5.768 5.768 0 002.576 4.815c.205.137.42.261.642.372l-.004 1.242h5.143z'
        fill='#CFD7E6'
        stroke='#CFD7E6'
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default SvgSubRecipeIcon;
