import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';

export const useStyles = makeStyles(() =>
  createStyles({
    menu: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 34,
      height: 34,
    },
    menuActive: {
      backgroundColor: 'rgba(152, 165, 183, 0.25) !important',
    },
    icon: {
      color: colorVariables.grey60,
    },
  }),
);
