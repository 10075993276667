import { createAction } from '@reduxjs/toolkit';
import { UsersFilters, UsersSortBy } from '../../../api/admin-users/types';

export const setUsersPage = createAction<number>('SET_USERS_PAGE');

export const setUsersKeyword = createAction<string | undefined>('SET_USERS_KEYWORD');

export const resetUsersParams = createAction('RESET_USERS_PARAMS');

export const setSelectedUsers = createAction<number[]>('SET_SELECTED_USERS');

export const setSelectedUserId = createAction<number | null>('SET_SELECTED_USER_ID');

export const setUsersSortFilters = createAction<{ filter?: UsersFilters; sort?: UsersSortBy }>('SET_USERS_SORT_FILTERS');
