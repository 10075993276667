import * as React from 'react';

function SvgTeammates(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M17.07 10.476h-1.957c-.267 0-.525.037-.77.104a2.932 2.932 0 00-2.613-1.606H8.27c-1.14 0-2.128.654-2.613 1.606a2.924 2.924 0 00-.77-.104H2.93A2.933 2.933 0 000 13.406v3.13c0 .969.789 1.758 1.758 1.758h16.484A1.76 1.76 0 0020 16.536v-3.13a2.933 2.933 0 00-2.93-2.93zM5.34 11.903v5.219H1.758a.587.587 0 01-.586-.586v-3.13c0-.97.788-1.758 1.758-1.758h1.957c.16 0 .314.022.46.062a2.942 2.942 0 00-.007.193zm8.148 5.219H6.512v-5.219c0-.969.788-1.758 1.758-1.758h3.46c.97 0 1.758.79 1.758 1.758v5.219zm5.34-.586a.587.587 0 01-.586.586H14.66v-5.219c0-.065-.003-.13-.007-.193.146-.04.3-.062.46-.062h1.957c.97 0 1.758.789 1.758 1.758v3.13z'
        fill='currentColor'
      />
      <path
        d='M3.909 4.943a2.606 2.606 0 00-2.604 2.604A2.606 2.606 0 003.91 10.15a2.606 2.606 0 002.603-2.603 2.606 2.606 0 00-2.603-2.604zm0 4.035c-.79 0-1.432-.642-1.432-1.431 0-.79.642-1.432 1.432-1.432.789 0 1.431.642 1.431 1.432 0 .79-.642 1.431-1.431 1.431zM10 1.71a3.482 3.482 0 00-3.478 3.478A3.482 3.482 0 0010 8.666a3.482 3.482 0 003.478-3.478A3.482 3.482 0 0010 1.71zm0 5.784a2.309 2.309 0 01-2.306-2.306A2.309 2.309 0 0110 2.882a2.309 2.309 0 012.306 2.306A2.309 2.309 0 0110 7.494zM16.092 4.943a2.606 2.606 0 00-2.604 2.604 2.606 2.606 0 002.604 2.603 2.606 2.606 0 002.603-2.603 2.606 2.606 0 00-2.603-2.604zm0 4.035c-.79 0-1.432-.642-1.432-1.431 0-.79.642-1.432 1.432-1.432.789 0 1.431.642 1.431 1.432 0 .79-.642 1.431-1.431 1.431z'
        fill='currentColor'
      />
    </svg>
  );
}

export default SvgTeammates;
