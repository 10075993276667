import { createAction } from '@reduxjs/toolkit';
import { RestaurantSortBy } from '../../../api/admin-restaurants/types';

export const setRestaurantsPage = createAction<number>('SET_RESTAURANTS_PAGE');

export const setRestaurantsKeyword = createAction<string | undefined>('SET_RESTAURANTS_KEYWORD');

export const resetRestaurantsParams = createAction('RESET_RESTAURANTS_PARAMS');

export const setRestaurantsSort = createAction<{ sort?: RestaurantSortBy }>('SET_RESTAURANTS_SORT');

export const setSelectedRestaurants = createAction<number[]>('SET_SELECTED_RESTAURANTS');
