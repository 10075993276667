import { createEntityAdapter, createReducer, EntityState } from '@reduxjs/toolkit';

import isEqual from 'lodash/isEqual';

import { productsApi } from '../../api/product';
import { Product, ProductFilterValues, ProductsSortBy, UpdateManualSupplierProduct } from '../../api/product/types';
import { logoutThunk } from '../user';
import {
  clearManualProductsList,
  clearManualProductsUpdateData,
  setManualProductsFilterBy,
  setManualProductsKeyword,
  setManualProductsPage,
  setManualProductsSortBy,
  setManualProductsUpdateData,
} from './manual-products.actions';

export const manualProductsAdapter = createEntityAdapter<Product>();

interface State {
  list: EntityState<Product>;
  sortBy?: ProductsSortBy;
  filterBy?: ProductFilterValues;
  keyword?: string;
  currentPage: number;
  resultsCount: number;
  updateData: UpdateManualSupplierProduct[];
}

const initialState: State = {
  sortBy: undefined,
  filterBy: undefined,
  keyword: undefined,
  list: manualProductsAdapter.getInitialState(),
  currentPage: 1,
  resultsCount: 0,
  updateData: [],
};

export const manualProductsReducer = createReducer(initialState, (builder) => {
  builder.addCase(logoutThunk.fulfilled, () => {
    return { ...initialState, filterBy: undefined };
  });
  builder.addCase(setManualProductsSortBy, (state, { payload }) => {
    if (isEqual(state.sortBy, payload)) {
      return;
    }
    state.sortBy = payload;
    state.currentPage = 1;
    state.list = manualProductsAdapter.getInitialState();
  });
  builder.addCase(setManualProductsFilterBy, (state, { payload }) => {
    if (isEqual(state.filterBy, payload)) {
      return;
    }
    state.filterBy = payload;
    state.currentPage = 1;
    state.list = manualProductsAdapter.getInitialState();
  });
  builder.addCase(setManualProductsKeyword, (state, { payload }) => {
    if (state.keyword === payload.keyword) {
      return;
    }
    state.keyword = payload.keyword;
    state.currentPage = 1;
    state.list = manualProductsAdapter.getInitialState();
  });
  builder.addCase(setManualProductsPage, (state, { payload }) => {
    state.currentPage = payload;
  });
  builder.addCase(clearManualProductsUpdateData, (state, { payload }) => {
    state.updateData = [];
  });
  builder.addCase(setManualProductsUpdateData, (state, { payload }) => {
    const idx = state.updateData.findIndex((el) => el.id === payload.id);
    if (idx === -1) {
      state.updateData.push(payload);
    } else {
      state.updateData[idx] = payload;
    }
  });
  builder.addCase(clearManualProductsList, (state) => {
    state.list = manualProductsAdapter.getInitialState();
    state.keyword = undefined;
    state.filterBy = undefined;
    state.sortBy = undefined;
    state.currentPage = 1;
    state.resultsCount = 0;
  });
  builder.addMatcher(productsApi.endpoints.getManualProducts.matchFulfilled, (state, action) => {
    if (
      isEqual(action.meta.arg.originalArgs!.filter, state.filterBy) &&
      isEqual(action.meta.arg.originalArgs!.sort, state.sortBy) &&
      action.meta.arg.originalArgs!.keyword === state.keyword &&
      action.meta.arg.originalArgs!.currentPage === state.currentPage
    ) {
      state.list = manualProductsAdapter.setMany(state.list, action.payload.data.products);
      state.resultsCount = action.payload.products_result;
    }
  });
  builder.addMatcher(productsApi.endpoints.deleteManualProduct.matchFulfilled, (state, action) => {
    state.list = manualProductsAdapter.removeOne(state.list, action.meta.arg.originalArgs.productId);
  });
  builder.addMatcher(productsApi.endpoints.archiveManualProduct.matchFulfilled, (state, action) => {
    state.list = manualProductsAdapter.updateOne(state.list, {
      id: action.meta.arg.originalArgs.productId,
      changes: { archived_at: new Date().toString() },
    });
  });
  builder.addMatcher(productsApi.endpoints.archiveAllManualSupplerProducts.matchFulfilled, (state) => {
    state.list = manualProductsAdapter.updateMany(
      state.list,
      state.list.ids.map((id) => ({
        id,
        changes: { archived_at: new Date().toString() },
      })),
    );
  });
  builder.addMatcher(productsApi.endpoints.addManualProduct.matchFulfilled, (state) => {
    state.list = manualProductsAdapter.getInitialState();
    state.currentPage = 1;
  });
  builder.addMatcher(productsApi.endpoints.importManualSupplierProducts.matchFulfilled, (state) => {
    state.list = manualProductsAdapter.getInitialState();
    state.keyword = undefined;
    state.sortBy = undefined;
    state.currentPage = 1;
    state.resultsCount = 0;
  });
  builder.addMatcher(productsApi.endpoints.restoreAllArchived.matchFulfilled, (state) => {
    state.list = manualProductsAdapter.updateMany(
      state.list,
      state.list.ids.map((id) => ({
        id,
        changes: { archived_at: null },
      })),
    );
  });
  builder.addMatcher(productsApi.endpoints.deleteAllManualProducts.matchFulfilled, (state) => {
    state.list = manualProductsAdapter.getInitialState();
  });
  builder.addMatcher(productsApi.endpoints.restoreManualProduct.matchFulfilled, (state, action) => {
    state.list = manualProductsAdapter.updateOne(state.list, {
      id: action.meta.arg.originalArgs.productId,
      changes: { archived_at: null },
    });
  });
});
