import { unstable_createMuiStrictModeTheme as createTheme } from '@material-ui/core';

export const THEME = createTheme({
  typography: {
    fontFamily: '"Open Sans", sans-serif;',
    fontSize: 14,
    fontWeightRegular: 400,
  },
  palette: {
    primary: {
      main: '#33A8E2',
    },
  },
});
