import React from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { useStyles } from './style';

interface Props {
  checked: boolean;
  name: string;
  onChange: (param: string) => void;
  labelPlace?: 'start' | 'end';
  disabled?: boolean;
  formControlClassName?: string;
}

export const CommonCheckbox: React.FC<Props> = ({
  checked,
  onChange,
  labelPlace = 'start',
  name,
  disabled = false,
  formControlClassName = '',
}) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      className={formControlClassName}
      labelPlacement={labelPlace}
      label={name}
      classes={{ label: classes.label }}
      control={
        <Checkbox
          classes={{ root: classes.checkbox }}
          disabled={disabled}
          checked={checked}
          onChange={onChange.bind(null, name)}
          name={name}
          color='primary'
        />
      }
    />
  );
};
