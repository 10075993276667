import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { colorVariables } from '../../../../../styles/colorVariables';
import { Sizes } from '../../../../../styles/sizes';

export interface StyleProps {
  textColor?: string;
  bgColor?: string;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    menuItem: {
      display: 'flex',
      padding: '14px 22px',
      alignItems: 'center',
      justifyContent: 'flex-start',
      background: ({ bgColor }) => bgColor || colorVariables.navy,
      position: 'relative',
      height: 48,
      overflow: 'hidden',
      [theme.breakpoints.between(Sizes.mobile, Sizes.desktop)]: {
        transition: 'padding ease-out 0.5s',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: '14px 30px 14px 25px',
      },
      '& svg': {
        opacity: 0.6,
      },
      '&:hover': {
        background: ({ bgColor }) => bgColor || 'rgba(45, 154, 212, 0.05)',
        filter: ({ bgColor }) => (bgColor ? 'brightness(0.9)' : undefined),
      },
      '&:hover svg': {
        opacity: 0.9,
      },
    },
    menuItemTabletSupplier: {
      [theme.breakpoints.between(Sizes.mobile, Sizes.desktop)]: {
        padding: '14px 36px',
        transition: 'padding ease-out 0.5s',
      },
    },
    menuItemWrap: {
      width: '100%',
      [theme.breakpoints.down(Sizes.desktop)]: {
        transform: 'translateX(0)',
        transition: 'transform ease-out 0.5s',
      },
    },
    menuItemWrapCollapsed: {
      [theme.breakpoints.down(Sizes.desktop)]: {
        transform: 'translateX(-1000px)',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        transform: 'translateX(0)',
      },
    },
    menuItemWrapCollapsedCustomer: {
      [theme.breakpoints.between(Sizes.mobile, Sizes.desktop)]: {
        transform: 'translateX(0)',
        width: '100%',
      },
    },
    menuItemActive: {
      borderLeft: `3px solid ${colorVariables.green}`,
      filter: ({ bgColor }) => (bgColor ? 'brightness(0.93)' : undefined),
      background: ({ bgColor }) => (bgColor ? bgColor : 'linear-gradient(90deg, rgba(81, 217, 176, 0.1) 0%, rgba(21, 33, 68, 0.5) 100%)'),
      '& span:first-child': {
        color: ({ textColor }) => textColor || colorVariables.white,
      },
      '& svg': {
        opacity: 1,
        color: ({ textColor }) => textColor || colorVariables.white,
      },
    },
    menuItemText: {
      fontSize: 14,
      lineHeight: '16px',
      color: ({ textColor }) => textColor || colorVariables.grey60,
      marginLeft: 10,
    },
    menuItemTextCollapsed: {
      display: 'inline',
      [theme.breakpoints.down(Sizes.desktop)]: {
        display: 'none',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'inline',
      },
    },
    menuItemChevron: {
      position: 'absolute',
      right: 20,
      top: 15,
      color: ({ textColor }) => textColor || colorVariables.grey60,
      transition: 'transform ease-in-out 0.5s',
      [theme.breakpoints.down(Sizes.desktop)]: {
        right: -25,
        top: 0,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        right: -22,
      },
    },
    menuItemChevronRestaurant: {
      right: 20,
      [theme.breakpoints.down(Sizes.desktop)]: {
        right: -10,
        top: 0,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        right: -22,
      },
    },
    menuItemChevronRotated: {
      transform: 'rotate(-180deg)',
    },
    menuIconWrap: {
      display: 'flex',
      '& svg': {
        fontSize: 20,
        flexShrink: 0,
        color: ({ textColor }) => textColor || colorVariables.grey60,
      },
    },
    nestedItem: {
      padding: '12px 36px 12px 45px',
      position: 'relative',
      [theme.breakpoints.down(Sizes.mobile)]: {
        height: 44,
      },
    },
    nestedItemOpened: {
      transform: 'translateX(0)',
      position: 'static',
    },
    nestedItemText: {
      fontSize: 12,
      lineHeight: '16px',
      color: ({ textColor }) => textColor || colorVariables.grey60,
    },
    nestedItemActive: {
      background: ({ bgColor }) => bgColor,
      filter: ({ bgColor }) => (bgColor ? 'brightness(0.96)' : undefined),
      '& *': {
        color: ({ textColor }) => textColor || colorVariables.green,
      },
    },
    nestedItemArrow: {
      color: ({ textColor }) => textColor || colorVariables.grey60,
      fontSize: 13,
      marginRight: 10,
    },
    counter: {
      display: 'inline-block',
      width: 20,
      height: 20,
      marginLeft: 5,
      backgroundColor: colorVariables.green,
      fontSize: 11,
      fontWeight: 700,
      lineHeight: '20px',
      borderRadius: '50%',
      textAlign: 'center',
      color: `${colorVariables.navy} !important`,
      boxShadow: `0 0 2px ${colorVariables.green}`,
    },
    newLabel: {
      display: 'inline-block',
      width: 44,
      height: 22,
      marginLeft: 10,
      backgroundColor: colorVariables.green,
      fontSize: 12,
      fontWeight: 700,
      lineHeight: '22px',
      borderRadius: 6,
      textAlign: 'center',
      color: colorVariables.white,
    },
    tabletCounter: {
      display: 'none',
      [theme.breakpoints.between(Sizes.mobile, Sizes.desktop)]: {
        display: 'inline-block',
        width: 18,
        height: 18,
        lineHeight: '18px',
        marginLeft: 0,
        position: 'absolute',
        textAlign: 'center',
        fontSize: 11,
        top: 5,
        right: 7,
      },
    },
    menuItemChevronNested: {
      color: ({ textColor }) => `${textColor || colorVariables.white} !important`,
      [theme.breakpoints.down(Sizes.desktop)]: {
        top: 8,
        right: 9,
      },
    },
    disabledLink: {
      pointerEvents: 'none',
      opacity: 0.5,
    },
  }),
);
