import * as React from 'react';

function SvgCarIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='107' height='68' viewBox='0 0 107 68' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#CarIcon_svg__clip0_4339_42130)' fill='#4BCFBB'>
        <path d='M32.447 0h30.036c1.569 0 2.45.88 2.45 2.444v52.714c0 1.535-.875 2.419-2.396 2.419h-26.66c-1.23 0-2.202-.869-2.36-2.082-.342-2.622-1.435-4.835-3.372-6.69-1.537-1.474-3.335-2.352-5.343-2.86-1.398-.356-2.848-.518-4.276-.312-1.552.224-3.025.728-4.447 1.452-2.999 1.527-4.6 4.07-5.502 7.167-.185.633-.206 1.316-.395 1.948-.252.843-.97 1.36-1.86 1.367-2.133.02-4.266.017-6.399.003-.852-.006-1.852-1.016-1.916-1.888-.014-.197-.006-.396-.006-.593V2.492C.003.875.87 0 2.471 0h29.976zM68.576 34.6V14.044c0-1.719.946-2.666 2.667-2.666 4.977 0 9.954.01 14.93-.008 1.174-.004 2.005.477 2.668 1.433.58.837 1.265 1.602 1.928 2.377.805.942 1.658 1.842 2.454 2.792.719.857 1.376 1.766 2.083 2.633.67.823 1.372 1.618 2.053 2.43.957 1.147 1.905 2.3 2.859 3.45.526.634 1.053 1.272 1.586 1.902 1.166 1.383 2.325 2.771 3.508 4.138.54.623.834 1.296.833 2.14-.014 6.851-.008 13.704-.008 20.556 0 1.453-.902 2.355-2.35 2.355-.948 0-1.897.012-2.844-.005-.811-.014-1.815-1.01-1.923-1.812a13.335 13.335 0 00-1.175-3.925c-.946-2.016-2.436-3.539-4.332-4.616-1.024-.583-2.201-.91-3.324-1.307-1.295-.458-2.668-.447-3.981-.276-1.823.237-3.605.69-5.218 1.707-1.54.97-2.768 2.198-3.701 3.734a11.117 11.117 0 00-1.577 4.577c-.1.906-1.112 1.911-2.022 1.92-1.007.011-2.014.008-3.021.002-1.097-.006-2.093-1.014-2.093-2.126-.002-6.95 0-13.9 0-20.852v.003zm15.67-4.146c2.98 0 5.962.004 8.942-.002 1.337-.002 2.172-1.237 1.64-2.452-.144-.332-.405-.623-.65-.9-.937-1.06-1.911-2.088-2.83-3.164-.891-1.044-1.73-2.134-2.598-3.199-.769-.942-1.547-1.877-2.322-2.815-.47-.57-.85-1.25-1.745-1.247-3.237.008-6.475.01-9.713-.004-.604-.002-.99.297-1.203.787-.15.349-.209.762-.21 1.146-.015 3.296-.01 6.593-.008 9.889 0 1.247.724 1.959 1.991 1.959h8.707l-.001.002z' />
        <path d='M31.077 58.155c.208 1.473-.094 2.84-.7 4.155-.841 1.825-2.1 3.239-3.966 4.07-.444.198-.857.48-1.315.632-2.205.727-4.439.614-6.61-.061-.87-.271-1.678-.864-2.406-1.446-1.064-.85-2.014-1.866-2.6-3.101-.413-.876-.712-1.861-.807-2.822-.31-3.14.414-5.94 2.813-8.21 1.342-1.272 2.894-2.146 4.65-2.375 1.67-.217 3.436-.375 5.07.364 1.724.781 3.403 1.663 4.435 3.347 1.023 1.669 1.757 3.436 1.435 5.446v.001zm-7.069.038c0-1.342-.748-2.155-1.985-2.155-1.171 0-2.28 1.043-2.28 2.146 0 1.101 1.07 2.11 2.242 2.111 1.316.002 2.023-.732 2.023-2.102zM96.582 58.096c.18 2.599-.752 4.75-2.613 6.679-2.14 2.218-4.671 2.756-7.603 2.682-3.606-.09-8.853-3.998-8.329-10.2.317-3.747 4.06-8.887 10.275-8.412 3.773.288 8.895 4.552 8.27 9.25zm-7.107.109c0-1.364-.79-2.17-2.13-2.169-1.247 0-2.137.901-2.132 2.16.003 1.304.873 2.098 2.295 2.098 1.301 0 1.965-.707 1.967-2.09z' />
      </g>
      <defs>
        <clipPath id='CarIcon_svg__clip0_4339_42130'>
          <path fill='#fff' d='M0 0h106.145v67.508H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgCarIcon;
