import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../../styles/colorVariables';
import { Sizes } from '../../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      marginBottom: 30,
      [theme.breakpoints.down(Sizes.desktop)]: {
        display: 'none',
      },
    },
    title: {
      minWidth: 200,
      fontSize: 20,
      fontWeight: 700,
      color: colorVariables.navy,
      flexShrink: 0,
    },
    searchInputContainer: {
      width: 350,
      marginRight: 'auto',
    },
    actions: {
      display: 'flex',
      position: 'relative',
      '&>*:not(:last-child)': {
        marginRight: 15,
      },
    },
    filterBlock: {
      width: 150,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginRight: 25,
    },
    filterTitle: {
      fontSize: 12,
      fontWeight: 700,
      textTransform: 'uppercase',
      color: colorVariables.grey60,
    },
    moreOptions: {
      border: `2px solid ${colorVariables.grey60} !important`,
    },
    optionsBlock: {
      width: 320,
    },
    optionsTitle: {
      fontSize: 12,
      fontWeight: 700,
      marginBottom: 20,
    },
    switchBlock: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    track: {
      backgroundColor: colorVariables.steelGrey,
      opacity: 1,
    },
    thumb: {
      backgroundColor: colorVariables.white,
      width: 20,
      height: 20,
    },
    checked: {
      '&.MuiSwitch-switchBase + .MuiSwitch-track': {
        backgroundColor: '#44B5E6',
        opacity: 1,
      },
    },
    switchText: {
      fontSize: 12,
    },
  }),
);
