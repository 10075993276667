import * as React from 'react';

function SvgAppleCategory(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.742 1c-.332.042-.622.294-.746.63-.705 2.774-.456 5.127.787 6.934.208.336.456.63.746.924-.58-.084-1.16-.084-1.699-.084-2.735 0-4.932.84-6.506 2.353-2.819 2.647-3.689 7.018-3.192 11.346.498 4.328 2.363 8.74 5.347 11.681 2.9 2.858 7.004 4.202 11.521 2.395 4.517 1.807 8.62.463 11.521-2.395 2.984-2.9 4.85-7.311 5.347-11.681.497-4.329-.373-8.699-3.192-11.346-1.574-1.47-3.77-2.353-6.506-2.353-1.865 0-3.937.378-6.3 1.26v-.672c.166-2.059.83-3.11 1.907-3.95 1.077-.84 2.735-1.428 4.724-2.143.373-.126.622-.546.58-.924-.04-.378-.414-.714-.787-.714-.083 0-.207 0-.29.042-1.99.672-3.813 1.26-5.222 2.395-.539.42-.995.924-1.368 1.555a7.81 7.81 0 00-.87-1.891c-1.036-1.597-2.818-2.732-5.429-3.278h-.166C13.783 1 13.742 1 13.742 1zm.704 1.849c1.824.504 2.902 1.303 3.606 2.353.746 1.177 1.078 2.731 1.12 4.706v.673c-1.866-.84-3.234-1.807-3.98-2.984-.87-1.218-1.16-2.69-.745-4.748zM12.873 11c1.823 0 4.061.504 6.714 1.555.29.126.538.084.828 0 6.093-2.48 9.864-1.765 12.143.378 2.28 2.143 3.109 5.925 2.653 9.917-.456 3.991-2.238 8.11-4.849 10.673-2.611 2.563-5.927 3.655-9.988 1.932-.249-.126-.456-.084-.787 0-4.062 1.723-7.377.589-9.947-1.975-2.61-2.563-4.393-6.68-4.849-10.673-.456-3.991.415-7.815 2.652-9.916C8.687 11.757 10.468 11 12.872 11z'
        fill='#51D9B0'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.395 16a.965.965 0 00-.735.312c-2.161 2.575-1.945 5.617-.864 8.231 1.124 2.614 3.112 4.876 4.755 6.242a.899.899 0 001.21-.04.705.705 0 00-.043-1.092h-.043c-1.427-1.209-3.329-3.354-4.323-5.656-.994-2.302-1.124-4.681.605-6.749.303-.35.216-.819-.13-1.092-.086-.117-.259-.156-.432-.156z'
        fill='#51D9B0'
      />
    </svg>
  );
}

export default SvgAppleCategory;
