import { createAction } from '@reduxjs/toolkit';
import { SuppliersSortBy } from '../../../api/admin-suppliers/types';

export const setASuppliersPage = createAction<number>('SET_A_SUPPLIERS_PAGE');

export const setASuppliersKeyword = createAction<string | undefined>('SET_A_SUPPLIERS_KEYWORD');

export const resetASuppliersParams = createAction('RESET_A_SUPPLIERS_PARAMS');

export const setASuppliersSort = createAction<{ sort?: SuppliersSortBy }>('SET_A_SUPPLIERS_SORT');

export const setSelectedSuppliers = createAction<number[]>('SET_SELECTED_SUPPLIERS');
