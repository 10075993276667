import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../styles/colorVariables';
import { Sizes } from '../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    modalRoot: {
      padding: 40,
      width: 750,
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '90vw',
        padding: '30px 20px',
      },
    },
    modalHead: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 30,
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'block',
        marginBottom: 20,
      },
    },
    title: {
      fontSize: 24,
      fontWeight: 700,
    },
    label: {
      fontWeight: 600,
      marginBottom: 12,
    },
    nameFields: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'block',
      },
    },
    fieldWrap: {
      width: '48%',
      marginBottom: 15,
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        marginBottom: 10,
        '& label': {
          fontWeight: 'normal !important',
        },
      },
    },
    fieldWrapDisabled: {
      '& .MuiInput-formControl.Mui-disabled': {
        backgroundColor: 'rgba(152, 165, 183, 0.15) !important',
      },
    },
    bottomRow: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: 10,
      alignItems: 'center',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'block',
      },
    },
    btnsBox: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        justifyContent: 'space-between',
        marginTop: 20,
      },
    },
    permLabel: {
      fontSize: 12,
      marginRight: 10,
      textTransform: 'uppercase',
      color: colorVariables.grey60,
      fontWeight: 600,
    },
    dropdownWrap: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    dropdownWrapM: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      margin: '10px 0',
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginTop: 20,
        justifyContent: 'space-between',
        '&>div': {
          width: 'auto',
        },
      },
    },
    permissionText: {
      textTransform: 'capitalize',
      marginLeft: 10,
    },
    subTitle: {
      fontSize: 12,
      textTransform: 'uppercase',
      fontWeight: 700,
      color: colorVariables.userGrey,
      marginBottom: 18,
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
  }),
);
