import { StocksEnum } from '../../api/product/types';

export const getStockLevelTitle = (stock: StocksEnum) => {
  switch (stock) {
    case StocksEnum.IN: {
      return 'In stock';
    }
    case StocksEnum.LOW: {
      return 'Low';
    }
    case StocksEnum.OUT: {
      return 'Out of stock';
    }
    default: {
      return '';
    }
  }
};
