import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { formatCurrency } from './format-currency';
import { ExportManualSupplierProductsResponse } from '../../api/product/types';

export const generateProductsSheetForManual = async (data?: ExportManualSupplierProductsResponse) => {
  const workbook = new ExcelJS.Workbook();
  workbook.created = new Date();
  workbook.modified = new Date();
  const sheet = workbook.addWorksheet('Products', {
    views: [{ state: 'frozen', xSplit: 0, ySplit: 4 }],
    properties: { defaultColWidth: 20 },
  });

  sheet.getColumn('A').font = { color: { argb: '969696' } };
  sheet.mergeCells('A1:I1');

  sheet.getRow(1).height = 30;

  sheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
  sheet.getCell('A1').value = 'Basic Info';
  sheet.getCell('A1').font = {
    name: 'Calibri',
    size: 18,
    bold: true,
  };
  sheet.getCell('A1').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'defcf2' },
  };
  sheet.getCell('A1').border = {
    top: { style: 'medium', color: { argb: '969696' } },
    left: { style: 'medium', color: { argb: '969696' } },
    bottom: { style: 'medium', color: { argb: '969696' } },
    right: { style: 'medium', color: { argb: '969696' } },
  };

  sheet.getRow(2).height = 45;
  sheet.getRow(2).alignment = { vertical: 'middle', horizontal: 'center' };
  sheet.getRow(2).font = {
    name: 'Calibri',
    size: 11,
    bold: true,
  };
  sheet.getCell('A2').value = 'Product ID';
  sheet.getCell('A2').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'defcf2' },
  };
  sheet.getCell('A2').border = {
    top: { style: 'medium', color: { argb: '969696' } },
    left: { style: 'medium', color: { argb: '969696' } },
    bottom: { style: 'medium', color: { argb: '969696' } },
    right: { style: 'medium', color: { argb: '969696' } },
  };
  sheet.getCell('B2').value = 'Product Name*';
  sheet.getCell('B2').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'defcf2' },
  };
  sheet.getCell('B2').border = {
    top: { style: 'medium', color: { argb: '969696' } },
    left: { style: 'medium', color: { argb: '969696' } },
    bottom: { style: 'medium', color: { argb: '969696' } },
    right: { style: 'medium', color: { argb: '969696' } },
  };
  sheet.getCell('C2').value = 'Brand';
  sheet.getCell('C2').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'defcf2' },
  };
  sheet.getCell('C2').border = {
    top: { style: 'medium', color: { argb: '969696' } },
    left: { style: 'medium', color: { argb: '969696' } },
    bottom: { style: 'medium', color: { argb: '969696' } },
    right: { style: 'medium', color: { argb: '969696' } },
  };
  sheet.getCell('D2').value = 'Unit of Measure*';
  sheet.getCell('D2').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'defcf2' },
  };
  sheet.getCell('D2').border = {
    top: { style: 'medium', color: { argb: '969696' } },
    left: { style: 'medium', color: { argb: '969696' } },
    bottom: { style: 'medium', color: { argb: '969696' } },
    right: { style: 'medium', color: { argb: '969696' } },
  };
  sheet.getCell('E2').value = 'Barcode';
  sheet.getCell('E2').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'defcf2' },
  };
  sheet.getCell('E2').border = {
    top: { style: 'medium', color: { argb: '969696' } },
    left: { style: 'medium', color: { argb: '969696' } },
    bottom: { style: 'medium', color: { argb: '969696' } },
    right: { style: 'medium', color: { argb: '969696' } },
  };

  sheet.getCell('F2').alignment = {
    vertical: 'middle',
    horizontal: 'center',
    wrapText: true,
  };
  sheet.getCell('F2').border = {
    top: { style: 'medium', color: { argb: '969696' } },
    left: { style: 'medium', color: { argb: '969696' } },
    bottom: { style: 'medium', color: { argb: '969696' } },
    right: { style: 'medium', color: { argb: '969696' } },
  };
  sheet.getCell('F2').value = {
    richText: [
      {
        font: { bold: true, size: 10, color: { argb: '000000' } },
        text: 'Tax* ',
      },
      {
        font: { size: 10 },
        text: '\r\n',
      },
      {
        font: { size: 9, color: { argb: '00A5FD' } },
        text: 'Yes/No',
      },
    ],
  };
  sheet.getCell('F2').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'defcf2' },
  };

  sheet.getCell('G2').value = 'Price';
  sheet.getCell('G2').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'defcf2' },
  };
  sheet.getCell('G2').border = {
    top: { style: 'medium', color: { argb: '969696' } },
    left: { style: 'medium', color: { argb: '969696' } },
    bottom: { style: 'medium', color: { argb: '969696' } },
    right: { style: 'medium', color: { argb: '969696' } },
  };

  sheet.getCell('H2').value = 'Sub category';
  sheet.getCell('H2').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'defcf2' },
  };
  sheet.getCell('H2').border = {
    top: { style: 'medium', color: { argb: '969696' } },
    left: { style: 'medium', color: { argb: '969696' } },
    bottom: { style: 'medium', color: { argb: '969696' } },
    right: { style: 'medium', color: { argb: '969696' } },
  };

  sheet.getCell('I2').value = 'Picture URL';
  sheet.getCell('I2').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'defcf2' },
  };
  sheet.getCell('I2').border = {
    top: { style: 'medium', color: { argb: '969696' } },
    left: { style: 'medium', color: { argb: '969696' } },
    bottom: { style: 'medium', color: { argb: '969696' } },
    right: { style: 'medium', color: { argb: '969696' } },
  };

  sheet.getRow(3).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

  sheet.getRow(3).values = [
    'Example123',
    'Example: Striploin YG 120+ Day Grain Fed Beef aged 200 days',
    'Black Angus',
    'Kg',
    '1234567890',
    'YES',
    formatCurrency(25),
    'Beef',
    'www.imageofbeef.com',
  ];
  sheet.getRow(4).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
  sheet.getRow(4).values = [
    'Example124',
    'Example: Ribeye PR Grain Fed',
    'Black Angus',
    'Kg',
    '0987654321',
    'NO',
    formatCurrency(20),
    'Beef',
    'www.imageofbeef.com',
  ];
console.log(data);
  if (data) {
    data.data.forEach((item, idx) => {
      if (item.Price !== 'hidden') {
        sheet.getRow(idx + 5).values = (data?.headers || []).map(({ key }: { label: string; key: string }) =>
          // @ts-ignore
          key in item ? item[key] : null,
        );
      }
    });
  }

  new Array(10).fill(1).forEach((_, idx) => {
    sheet.getColumn(idx + 1).protection = {
      locked: false,
    };
  });

  sheet.getColumn('A').width = 14;
  sheet.getColumn('B').width = 40;
  sheet.getColumn('C').width = 14;
  sheet.getColumn('D').width = 18;
  sheet.getColumn('E').width = 12;
  sheet.getColumn('G').width = 18;
  sheet.getColumn('H').width = 14;
  sheet.getColumn('I').width = 20;
  sheet.eachRow((row) => {
    if (row.number < 5) {
      row.protection = {
        locked: true,
      };
    }
    if (row.number > 4) {
      row.border = {
        top: { style: 'thin', color: { argb: '969696' } },
        left: { style: 'thin', color: { argb: '969696' } },
        bottom: { style: 'thin', color: { argb: '969696' } },
        right: { style: 'thin', color: { argb: '969696' } },
      };
      row.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
    }
  });
  sheet.getColumn('B').eachCell((cell, row) => {
    if (row > 2) {
      cell.alignment = {
        wrapText: true,
        vertical: 'middle',
        horizontal: 'left',
      };
    }
  });
  await sheet.protect('pass', {
    selectLockedCells: false,
    selectUnlockedCells: true,
    formatColumns: true,
    deleteRows: true,
    deleteColumns: true,
    formatCells: true,
    formatRows: true,
    insertRows: true,
  });
  const bufferRes = await workbook.xlsx.writeBuffer();
  const blob = new Blob([bufferRes], { type: 'application/xlsx' });
  await saveAs(blob, 'Prices.xlsx');
  return Promise.resolve();
};
