import * as React from 'react';

function SvgChooseStore(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M16.5 0h-12a3 3 0 00-3 3v.75H.75a.75.75 0 100 1.5h.75v3H.75a.75.75 0 100 1.5h.75v3H.75a.75.75 0 100 1.5h.75V15a3 3 0 003 3h12a1.5 1.5 0 001.5-1.5v-15A1.5 1.5 0 0016.5 0zm0 16.5h-12A1.5 1.5 0 013 15v-.75h.75a.75.75 0 100-1.5H3v-3h.75a.75.75 0 100-1.5H3v-3h.75a.75.75 0 100-1.5H3V3a1.5 1.5 0 011.5-1.5h12v15z'
        fill='currentColor'
      />
      <path
        d='M6.75 14.25a.75.75 0 00.75-.75v-.75a3 3 0 016 0v.75a.75.75 0 101.5 0v-.75a4.5 4.5 0 00-2.329-3.942 3 3 0 10-4.342 0A4.5 4.5 0 006 12.75v.75c0 .415.336.75.75.75zM9 6.75a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z'
        fill='currentColor'
      />
    </svg>
  );
}

export default SvgChooseStore;
