import { createReducer } from '@reduxjs/toolkit';

import isEqual from 'lodash/isEqual';
import { AdminProductsSortBy } from '../../../api/admin-products/types';
import { logoutThunk } from '../../user';
import { setAdminProductsKeyword, setAdminProductsPage, setAdminProductsSortBy } from './products.actions';

interface State {
  sortBy?: AdminProductsSortBy;
  keyword?: string;
  currentPage: number;
}

const initialState: State = {
  sortBy: undefined,
  keyword: undefined,
  currentPage: 1,
};

export const adminProductsReducer = createReducer(initialState, (builder) => {
  builder.addCase(logoutThunk.fulfilled, () => {
    return initialState;
  });
  builder.addCase(setAdminProductsSortBy, (state, { payload }) => {
    if (isEqual(state.sortBy, payload)) {
      return;
    }
    state.sortBy = payload;
    state.currentPage = 1;
  });

  builder.addCase(setAdminProductsKeyword, (state, { payload }) => {
    if (state.keyword === payload.keyword) {
      return;
    }
    state.keyword = payload.keyword;
    state.currentPage = 1;
  });

  builder.addCase(setAdminProductsPage, (state, { payload }) => {
    state.currentPage = payload;
  });
});
