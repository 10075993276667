import { euCountriesList } from '../constants/countries';
import { LocalStorageService } from '../services/localStorage.service';

export const useRSPricing = () => {
  const country = LocalStorageService.getItem('country')?.toLowerCase() as string;

  if (euCountriesList.find(({ value }) => value.toLowerCase() === country)) {
    return {
      mc: 110,
      rps: 59,
      currency: '€',
    };
  }

  switch (country) {
    case 'sg': {
      return {
        mc: 150,
        rps: 99,
        currency: 'SGD $',
      };
    }
    case 'ph': {
      return {
        mc: 5999,
        rps: 3999,
        currency: '₱',
      };
    }
    case 'my': {
      return {
        mc: 499,
        rps: 299,
        currency: 'RM',
      };
    }
    case 'cn': {
      return {
        mc: 888,
        rps: 488,
        currency: 'HKD $',
      };
    }
    case 'in': {
      return {
        mc: 9994,
        rps: 4994,
        currency: '₹',
      };
    }
    case 'th': {
      return {
        mc: 3999,
        rps: 1999,
        currency: '฿',
      };
    }
    case 'id': {
      return {
        mc: '1.4m',
        rps: '700k',
        currency: 'Rp',
      };
    }
    case 'vn': {
      return {
        mc: '2.9m',
        rps: '1.4m',
        currency: '₫',
      };
    }
    case 'gb': {
      return {
        mc: 99,
        rps: 99,
        currency: '£',
      };
    }
    case 'ie': {
      return {
        mc: 110,
        rps: 59,
        currency: '€',
      };
    }
    case 'ca': {
      return {
        mc: 149,
        rps: 99,
        currency: 'CAD $',
      };
    }
    default: {
      return {
        mc: 99,
        rps: 199,
        currency: '$',
      };
    }
  }
};
