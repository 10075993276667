import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: 0,
      boxShadow: 'none',
      textTransform: 'uppercase',
      border: 'none',
      fontSize: 11,
      fontWeight: 700,
      height: 24,
      lineHeight: '20px',
      backgroundColor: colorVariables.transparent,
      borderRadius: 10,
      '&:hover': {
        border: 'none',
        boxShadow: 'none',
        backgroundColor: colorVariables.transparent,
      },
      '&:active': {
        boxShadow: 'none',
        border: 'none',
        backgroundColor: colorVariables.transparent,
      },
    },
    icon: {
      color: colorVariables.grey60,
      marginLeft: -10,
      height: 14,
    },
  }),
);
