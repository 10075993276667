import { createReducer } from '@reduxjs/toolkit';

import { isSameDay, isToday } from 'date-fns';
import { DeliveryFilters, DeliverySortBy } from '../../api/delivery/types';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { TokenService } from '../../shared/services/token.service';
import { logoutThunk } from '../user';
import {
  setAddDeliveryMode,
  setDeliveriesFilter,
  setDeliveriesKeyword,
  setDeliveriesPage,
  setDeliveriesSort,
  setDeliveriesSortFilter,
} from './deliveries.actions';

interface State {
  sortBy?: DeliverySortBy;
  filterBy?: DeliveryFilters;
  keyword?: string;
  currentPage: number;
  addDeliveryMode: boolean;
}

const initialState: State = {
  sortBy: ['delivery_number', 'ASC'],
  filterBy: undefined,
  keyword: undefined,
  currentPage: 1,
  addDeliveryMode: false,
};

export const deliveriesReducer = createReducer(initialState, (builder) => {
  builder.addCase(logoutThunk.fulfilled, () => {
    return { ...initialState, filterBy: undefined };
  });
  builder.addCase(setDeliveriesKeyword, (state, { payload }) => {
    if (state.keyword === payload) {
      return;
    }
    state.keyword = payload;
    state.currentPage = 1;
  });
  builder.addCase(setDeliveriesPage, (state, { payload }) => {
    state.currentPage = payload;
  });
  builder.addCase(setDeliveriesSort, (state, { payload }) => {
    state.sortBy = payload;
    state.currentPage = 1;
  });
  builder.addCase(setDeliveriesFilter, (state, { payload }) => {
    state.filterBy = payload;
    if (
      (payload?.delivery_date && !isToday(new Date(payload.delivery_date))) ||
      (payload?.delivery_date &&
        state.filterBy?.delivery_date &&
        !isSameDay(new Date(payload.delivery_date), new Date(state.filterBy.delivery_date)))
    ) {
      state.sortBy = ['delivery_number', 'ASC'];
    }
    if (!payload?.delivery_date || (payload?.delivery_date && isToday(new Date(payload.delivery_date)))) {
      const userKey = TokenService.getUserId()?.toString();
      const deliverySort = LocalStorageService.getItem('delivery_sort');
      state.sortBy = userKey && deliverySort && userKey in deliverySort ? deliverySort[userKey] : ['delivery_number', 'ASC'];
    }
    state.currentPage = 1;
  });
  builder.addCase(setDeliveriesSortFilter, (state, { payload }) => {
    state.filterBy = payload.filter;
    state.sortBy = payload.sort;
    state.currentPage = 1;
  });
  builder.addCase(setAddDeliveryMode, (state, { payload }) => {
    state.addDeliveryMode = payload;
  });
});
