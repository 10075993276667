export const ukDistrictsPostCodes = [
  {
    postcode: 'AB1',
    uk_region: 'Scotland',
    postal_town: '',
  },
  {
    postcode: 'AB2',
    uk_region: 'Scotland',
    postal_town: '',
  },
  {
    postcode: 'AB3',
    uk_region: 'Scotland',
    postal_town: '',
  },
  {
    postcode: 'AB4',
    uk_region: 'Scotland',
    postal_town: '',
  },
  {
    postcode: 'AB5',
    uk_region: 'Scotland',
    postal_town: '',
  },
  {
    postcode: 'AB9',
    uk_region: 'Scotland',
    postal_town: '',
  },
  {
    postcode: 'AB10',
    uk_region: 'Scotland',
    postal_town: 'Aberdeen',
  },
  {
    postcode: 'AB11',
    uk_region: 'Scotland',
    postal_town: 'Aberdeen',
  },
  {
    postcode: 'AB12',
    uk_region: 'Scotland',
    postal_town: 'Aberdeen',
  },
  {
    postcode: 'AB13',
    uk_region: 'Scotland',
    postal_town: 'Milltimber',
  },
  {
    postcode: 'AB14',
    uk_region: 'Scotland',
    postal_town: 'Peterculter',
  },
  {
    postcode: 'AB15',
    uk_region: 'Scotland',
    postal_town: 'Aberdeen',
  },
  {
    postcode: 'AB16',
    uk_region: 'Scotland',
    postal_town: 'Aberdeen',
  },
  {
    postcode: 'AB21',
    uk_region: 'Scotland',
    postal_town: 'Aberdeen',
  },
  {
    postcode: 'AB22',
    uk_region: 'Scotland',
    postal_town: 'Aberdeen',
  },
  {
    postcode: 'AB23',
    uk_region: 'Scotland',
    postal_town: 'Aberdeen',
  },
  {
    postcode: 'AB24',
    uk_region: 'Scotland',
    postal_town: 'Aberdeen',
  },
  {
    postcode: 'AB25',
    uk_region: 'Scotland',
    postal_town: 'Aberdeen',
  },
  {
    postcode: 'AB30',
    uk_region: 'Scotland',
    postal_town: 'Laurencekirk',
  },
  {
    postcode: 'AB31',
    uk_region: 'Scotland',
    postal_town: 'Banchory',
  },
  {
    postcode: 'AB32',
    uk_region: 'Scotland',
    postal_town: 'Westhill',
  },
  {
    postcode: 'AB33',
    uk_region: 'Scotland',
    postal_town: 'Alford',
  },
  {
    postcode: 'AB34',
    uk_region: 'Scotland',
    postal_town: 'Aboyne',
  },
  {
    postcode: 'AB35',
    uk_region: 'Scotland',
    postal_town: 'Ballater',
  },
  {
    postcode: 'AB36',
    uk_region: 'Scotland',
    postal_town: 'Strathdon',
  },
  {
    postcode: 'AB37',
    uk_region: 'Scotland',
    postal_town: 'Ballindalloch',
  },
  {
    postcode: 'AB38',
    uk_region: 'Scotland',
    postal_town: 'Aberlour',
  },
  {
    postcode: 'AB39',
    uk_region: 'Scotland',
    postal_town: 'Stonehaven',
  },
  {
    postcode: 'AB41',
    uk_region: 'Scotland',
    postal_town: 'Ellon',
  },
  {
    postcode: 'AB42',
    uk_region: 'Scotland',
    postal_town: 'Peterhead',
  },
  {
    postcode: 'AB43',
    uk_region: 'Scotland',
    postal_town: 'Fraserburgh',
  },
  {
    postcode: 'AB44',
    uk_region: 'Scotland',
    postal_town: 'Macduff',
  },
  {
    postcode: 'AB45',
    uk_region: 'Scotland',
    postal_town: 'Banff',
  },
  {
    postcode: 'AB51',
    uk_region: 'Scotland',
    postal_town: 'Inverurie',
  },
  {
    postcode: 'AB52',
    uk_region: 'Scotland',
    postal_town: 'Insch',
  },
  {
    postcode: 'AB53',
    uk_region: 'Scotland',
    postal_town: 'Turriff',
  },
  {
    postcode: 'AB54',
    uk_region: 'Scotland',
    postal_town: 'Huntly',
  },
  {
    postcode: 'AB55',
    uk_region: 'Scotland',
    postal_town: 'Keith',
  },
  {
    postcode: 'AB56',
    uk_region: 'Scotland',
    postal_town: 'Buckie',
  },
  {
    postcode: 'AB99',
    uk_region: 'Scotland',
    postal_town: 'Aberdeen',
  },
  {
    postcode: 'AL1',
    uk_region: 'East of England',
    postal_town: 'St. Albans',
  },
  {
    postcode: 'AL2',
    uk_region: 'East of England',
    postal_town: 'St. Albans',
  },
  {
    postcode: 'AL3',
    uk_region: 'East of England',
    postal_town: 'St. Albans',
  },
  {
    postcode: 'AL4',
    uk_region: 'East of England',
    postal_town: 'St. Albans',
  },
  {
    postcode: 'AL5',
    uk_region: 'East of England',
    postal_town: 'Harpenden',
  },
  {
    postcode: 'AL6',
    uk_region: 'East of England',
    postal_town: 'Welwyn',
  },
  {
    postcode: 'AL7',
    uk_region: 'East of England',
    postal_town: '"Welwyn, Welwyn Garden City"',
  },
  {
    postcode: 'AL8',
    uk_region: 'East of England',
    postal_town: 'Welwyn Garden City',
  },
  {
    postcode: 'AL9',
    uk_region: 'East of England',
    postal_town: 'Hatfield',
  },
  {
    postcode: 'AL10',
    uk_region: 'East of England',
    postal_town: 'Hatfield',
  },
  {
    postcode: 'B1',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B2',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B3',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B4',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B5',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B6',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B7',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B8',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B9',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B10',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B11',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B12',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B13',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B14',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B15',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B16',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B17',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B18',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B19',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B20',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B21',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B22',
    uk_region: 'West Midlands',
    postal_town: '',
  },
  {
    postcode: 'B23',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B24',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B25',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B26',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B27',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B28',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B29',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B30',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B31',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B32',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B33',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B34',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B35',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B36',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B37',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B38',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B40',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B42',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B43',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B44',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B45',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B46',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B47',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B48',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'B49',
    uk_region: 'West Midlands',
    postal_town: 'Alcester',
  },
  {
    postcode: 'B50',
    uk_region: 'West Midlands',
    postal_town: 'Alcester',
  },
  {
    postcode: 'B60',
    uk_region: 'West Midlands',
    postal_town: 'Bromsgrove',
  },
  {
    postcode: 'B61',
    uk_region: 'West Midlands',
    postal_town: 'Bromsgrove',
  },
  {
    postcode: 'B62',
    uk_region: 'West Midlands',
    postal_town: 'Halesowen',
  },
  {
    postcode: 'B63',
    uk_region: 'West Midlands',
    postal_town: 'Halesowen',
  },
  {
    postcode: 'B64',
    uk_region: 'West Midlands',
    postal_town: 'Cradley Heath',
  },
  {
    postcode: 'B65',
    uk_region: 'West Midlands',
    postal_town: 'Rowley Regis',
  },
  {
    postcode: 'B66',
    uk_region: 'West Midlands',
    postal_town: 'Smethwick',
  },
  {
    postcode: 'B67',
    uk_region: 'West Midlands',
    postal_town: 'Smethwick',
  },
  {
    postcode: 'B68',
    uk_region: 'West Midlands',
    postal_town: 'Oldbury',
  },
  {
    postcode: 'B69',
    uk_region: 'West Midlands',
    postal_town: 'Oldbury',
  },
  {
    postcode: 'B70',
    uk_region: 'West Midlands',
    postal_town: 'West Bromwich',
  },
  {
    postcode: 'B71',
    uk_region: 'West Midlands',
    postal_town: 'West Bromwich',
  },
  {
    postcode: 'B72',
    uk_region: 'West Midlands',
    postal_town: 'Sutton Coldfield',
  },
  {
    postcode: 'B73',
    uk_region: 'West Midlands',
    postal_town: 'Sutton Coldfield',
  },
  {
    postcode: 'B74',
    uk_region: 'West Midlands',
    postal_town: 'Sutton Coldfield',
  },
  {
    postcode: 'B75',
    uk_region: 'West Midlands',
    postal_town: 'Sutton Coldfield',
  },
  {
    postcode: 'B76',
    uk_region: 'West Midlands',
    postal_town: 'Sutton Coldfield',
  },
  {
    postcode: 'B77',
    uk_region: 'West Midlands',
    postal_town: 'Tamworth',
  },
  {
    postcode: 'B78',
    uk_region: 'West Midlands',
    postal_town: 'Tamworth',
  },
  {
    postcode: 'B79',
    uk_region: 'West Midlands',
    postal_town: 'Tamworth',
  },
  {
    postcode: 'B80',
    uk_region: 'West Midlands',
    postal_town: 'Studley',
  },
  {
    postcode: 'B90',
    uk_region: 'West Midlands',
    postal_town: 'Solihull',
  },
  {
    postcode: 'B91',
    uk_region: 'West Midlands',
    postal_town: 'Solihull',
  },
  {
    postcode: 'B92',
    uk_region: 'West Midlands',
    postal_town: 'Solihull',
  },
  {
    postcode: 'B93',
    uk_region: 'West Midlands',
    postal_town: 'Solihull',
  },
  {
    postcode: 'B94',
    uk_region: 'West Midlands',
    postal_town: 'Solihull',
  },
  {
    postcode: 'B95',
    uk_region: 'West Midlands',
    postal_town: 'Henley-in-Arden',
  },
  {
    postcode: 'B96',
    uk_region: 'West Midlands',
    postal_town: 'Redditch',
  },
  {
    postcode: 'B97',
    uk_region: 'West Midlands',
    postal_town: 'Redditch',
  },
  {
    postcode: 'B98',
    uk_region: 'West Midlands',
    postal_town: 'Redditch',
  },
  {
    postcode: 'B99',
    uk_region: 'West Midlands',
    postal_town: 'Birmingham',
  },
  {
    postcode: 'BA1',
    uk_region: 'South West',
    postal_town: 'Bath',
  },
  {
    postcode: 'BA2',
    uk_region: 'South West',
    postal_town: 'Bath',
  },
  {
    postcode: 'BA3',
    uk_region: 'South West',
    postal_town: 'Radstock',
  },
  {
    postcode: 'BA4',
    uk_region: 'South West',
    postal_town: 'Shepton Mallet',
  },
  {
    postcode: 'BA5',
    uk_region: 'South West',
    postal_town: 'Wells',
  },
  {
    postcode: 'BA6',
    uk_region: 'South West',
    postal_town: 'Glastonbury',
  },
  {
    postcode: 'BA7',
    uk_region: 'South West',
    postal_town: 'Castle Cary',
  },
  {
    postcode: 'BA8',
    uk_region: 'South West',
    postal_town: 'Templecombe',
  },
  {
    postcode: 'BA9',
    uk_region: 'South West',
    postal_town: '"Wincanton, Bruton"',
  },
  {
    postcode: 'BA10',
    uk_region: 'South West',
    postal_town: 'Bruton',
  },
  {
    postcode: 'BA11',
    uk_region: 'South West',
    postal_town: 'Frome',
  },
  {
    postcode: 'BA12',
    uk_region: 'South West',
    postal_town: 'Warminster',
  },
  {
    postcode: 'BA13',
    uk_region: 'South West',
    postal_town: 'Westbury',
  },
  {
    postcode: 'BA14',
    uk_region: 'South West',
    postal_town: 'Trowbridge',
  },
  {
    postcode: 'BA15',
    uk_region: 'South West',
    postal_town: 'Bradford-on-Avon',
  },
  {
    postcode: 'BA16',
    uk_region: 'South West',
    postal_town: 'Street',
  },
  {
    postcode: 'BA20',
    uk_region: 'South West',
    postal_town: 'Yeovil',
  },
  {
    postcode: 'BA21',
    uk_region: 'South West',
    postal_town: 'Yeovil',
  },
  {
    postcode: 'BA22',
    uk_region: 'South West',
    postal_town: 'Yeovil',
  },
  {
    postcode: 'BB0',
    uk_region: 'North West',
    postal_town: '',
  },
  {
    postcode: 'BB1',
    uk_region: 'North West',
    postal_town: 'Blackburn',
  },
  {
    postcode: 'BB2',
    uk_region: 'North West',
    postal_town: 'Blackburn',
  },
  {
    postcode: 'BB3',
    uk_region: 'North West',
    postal_town: 'Darwen',
  },
  {
    postcode: 'BB4',
    uk_region: 'North West',
    postal_town: 'Rossendale',
  },
  {
    postcode: 'BB5',
    uk_region: 'North West',
    postal_town: 'Accrington',
  },
  {
    postcode: 'BB6',
    uk_region: 'North West',
    postal_town: 'Blackburn',
  },
  {
    postcode: 'BB7',
    uk_region: 'North West',
    postal_town: 'Clitheroe',
  },
  {
    postcode: 'BB8',
    uk_region: 'North West',
    postal_town: 'Colne',
  },
  {
    postcode: 'BB9',
    uk_region: 'North West',
    postal_town: 'Nelson',
  },
  {
    postcode: 'BB10',
    uk_region: 'North West',
    postal_town: 'Burnley',
  },
  {
    postcode: 'BB11',
    uk_region: 'North West',
    postal_town: 'Burnley',
  },
  {
    postcode: 'BB12',
    uk_region: 'North West',
    postal_town: 'Burnley',
  },
  {
    postcode: 'BB18',
    uk_region: 'North West',
    postal_town: 'Barnoldswick',
  },
  {
    postcode: 'BB94',
    uk_region: 'North West',
    postal_town: 'Barnoldswick',
  },
  {
    postcode: 'BD1',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Bradford',
  },
  {
    postcode: 'BD2',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Bradford',
  },
  {
    postcode: 'BD3',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Bradford',
  },
  {
    postcode: 'BD4',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Bradford',
  },
  {
    postcode: 'BD5',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Bradford',
  },
  {
    postcode: 'BD6',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Bradford',
  },
  {
    postcode: 'BD7',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Bradford',
  },
  {
    postcode: 'BD8',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Bradford',
  },
  {
    postcode: 'BD9',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Bradford',
  },
  {
    postcode: 'BD10',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Bradford',
  },
  {
    postcode: 'BD11',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Bradford',
  },
  {
    postcode: 'BD12',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Bradford',
  },
  {
    postcode: 'BD13',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Bradford',
  },
  {
    postcode: 'BD14',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Bradford',
  },
  {
    postcode: 'BD15',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Bradford',
  },
  {
    postcode: 'BD16',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Bingley',
  },
  {
    postcode: 'BD17',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Shipley',
  },
  {
    postcode: 'BD18',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Shipley',
  },
  {
    postcode: 'BD19',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Cleckheaton',
  },
  {
    postcode: 'BD20',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Keighley',
  },
  {
    postcode: 'BD21',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Keighley',
  },
  {
    postcode: 'BD22',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Keighley',
  },
  {
    postcode: 'BD23',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Skipton',
  },
  {
    postcode: 'BD24',
    uk_region: 'Yorkshire and The Humber',
    postal_town: '"Skipton, Settle"',
  },
  {
    postcode: 'BD97',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Bingley',
  },
  {
    postcode: 'BD98',
    uk_region: 'Yorkshire and The Humber',
    postal_town: '"Bradford, Shipley"',
  },
  {
    postcode: 'BD99',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Bradford',
  },
  {
    postcode: 'BF1',
    uk_region: '',
    postal_town: 'BFPO',
  },
  {
    postcode: 'BH1',
    uk_region: 'South West',
    postal_town: 'Bournemouth',
  },
  {
    postcode: 'BH2',
    uk_region: 'South West',
    postal_town: 'Bournemouth',
  },
  {
    postcode: 'BH3',
    uk_region: 'South West',
    postal_town: 'Bournemouth',
  },
  {
    postcode: 'BH4',
    uk_region: 'South West',
    postal_town: 'Bournemouth',
  },
  {
    postcode: 'BH5',
    uk_region: 'South West',
    postal_town: 'Bournemouth',
  },
  {
    postcode: 'BH6',
    uk_region: 'South West',
    postal_town: 'Bournemouth',
  },
  {
    postcode: 'BH7',
    uk_region: 'South West',
    postal_town: 'Bournemouth',
  },
  {
    postcode: 'BH8',
    uk_region: 'South West',
    postal_town: 'Bournemouth',
  },
  {
    postcode: 'BH9',
    uk_region: 'South West',
    postal_town: 'Bournemouth',
  },
  {
    postcode: 'BH10',
    uk_region: 'South West',
    postal_town: 'Bournemouth',
  },
  {
    postcode: 'BH11',
    uk_region: 'South West',
    postal_town: 'Bournemouth',
  },
  {
    postcode: 'BH12',
    uk_region: 'South West',
    postal_town: 'Poole',
  },
  {
    postcode: 'BH13',
    uk_region: 'South West',
    postal_town: 'Poole',
  },
  {
    postcode: 'BH14',
    uk_region: 'South West',
    postal_town: 'Poole',
  },
  {
    postcode: 'BH15',
    uk_region: 'South West',
    postal_town: 'Poole',
  },
  {
    postcode: 'BH16',
    uk_region: 'South West',
    postal_town: 'Poole',
  },
  {
    postcode: 'BH17',
    uk_region: 'South West',
    postal_town: 'Poole',
  },
  {
    postcode: 'BH18',
    uk_region: 'South West',
    postal_town: 'Broadstone',
  },
  {
    postcode: 'BH19',
    uk_region: 'South West',
    postal_town: 'Swanage',
  },
  {
    postcode: 'BH20',
    uk_region: 'South West',
    postal_town: 'Wareham',
  },
  {
    postcode: 'BH21',
    uk_region: 'South West',
    postal_town: 'Wimborne',
  },
  {
    postcode: 'BH22',
    uk_region: 'South West',
    postal_town: 'Ferndown',
  },
  {
    postcode: 'BH23',
    uk_region: 'South West',
    postal_town: 'Christchurch',
  },
  {
    postcode: 'BH24',
    uk_region: 'South East',
    postal_town: 'Ringwood',
  },
  {
    postcode: 'BH25',
    uk_region: 'South East',
    postal_town: 'New Milton',
  },
  {
    postcode: 'BH31',
    uk_region: 'South West',
    postal_town: 'Verwood',
  },
  {
    postcode: 'BL0',
    uk_region: 'North West',
    postal_town: 'Bury',
  },
  {
    postcode: 'BL1',
    uk_region: 'North West',
    postal_town: 'Bolton',
  },
  {
    postcode: 'BL2',
    uk_region: 'North West',
    postal_town: 'Bolton',
  },
  {
    postcode: 'BL3',
    uk_region: 'North West',
    postal_town: 'Bolton',
  },
  {
    postcode: 'BL4',
    uk_region: 'North West',
    postal_town: 'Bolton',
  },
  {
    postcode: 'BL5',
    uk_region: 'North West',
    postal_town: 'Bolton',
  },
  {
    postcode: 'BL6',
    uk_region: 'North West',
    postal_town: 'Bolton',
  },
  {
    postcode: 'BL7',
    uk_region: 'North West',
    postal_town: 'Bolton',
  },
  {
    postcode: 'BL8',
    uk_region: 'North West',
    postal_town: 'Bury',
  },
  {
    postcode: 'BL9',
    uk_region: 'North West',
    postal_town: 'Bury',
  },
  {
    postcode: 'BL11',
    uk_region: 'North West',
    postal_town: 'Bolton',
  },
  {
    postcode: 'BL78',
    uk_region: 'North West',
    postal_town: 'Bolton',
  },
  {
    postcode: 'BN1',
    uk_region: 'South East',
    postal_town: 'Brighton',
  },
  {
    postcode: 'BN2',
    uk_region: 'South East',
    postal_town: 'Brighton',
  },
  {
    postcode: 'BN3',
    uk_region: 'South East',
    postal_town: 'Hove',
  },
  {
    postcode: 'BN4',
    uk_region: 'South East',
    postal_town: '',
  },
  {
    postcode: 'BN5',
    uk_region: 'South East',
    postal_town: 'Henfield',
  },
  {
    postcode: 'BN6',
    uk_region: 'South East',
    postal_town: 'Hassocks',
  },
  {
    postcode: 'BN7',
    uk_region: 'South East',
    postal_town: 'Lewes',
  },
  {
    postcode: 'BN8',
    uk_region: 'South East',
    postal_town: 'Lewes',
  },
  {
    postcode: 'BN9',
    uk_region: 'South East',
    postal_town: 'Newhaven',
  },
  {
    postcode: 'BN10',
    uk_region: 'South East',
    postal_town: 'Peacehaven',
  },
  {
    postcode: 'BN11',
    uk_region: 'South East',
    postal_town: 'Worthing',
  },
  {
    postcode: 'BN12',
    uk_region: 'South East',
    postal_town: 'Worthing',
  },
  {
    postcode: 'BN13',
    uk_region: 'South East',
    postal_town: 'Worthing',
  },
  {
    postcode: 'BN14',
    uk_region: 'South East',
    postal_town: 'Worthing',
  },
  {
    postcode: 'BN15',
    uk_region: 'South East',
    postal_town: 'Lancing',
  },
  {
    postcode: 'BN16',
    uk_region: 'South East',
    postal_town: 'Littlehampton',
  },
  {
    postcode: 'BN17',
    uk_region: 'South East',
    postal_town: 'Littlehampton',
  },
  {
    postcode: 'BN18',
    uk_region: 'South East',
    postal_town: 'Arundel',
  },
  {
    postcode: 'BN20',
    uk_region: 'South East',
    postal_town: 'Eastbourne',
  },
  {
    postcode: 'BN21',
    uk_region: 'South East',
    postal_town: 'Eastbourne',
  },
  {
    postcode: 'BN22',
    uk_region: 'South East',
    postal_town: 'Eastbourne',
  },
  {
    postcode: 'BN23',
    uk_region: 'South East',
    postal_town: 'Eastbourne',
  },
  {
    postcode: 'BN24',
    uk_region: 'South East',
    postal_town: 'Pevensey',
  },
  {
    postcode: 'BN25',
    uk_region: 'South East',
    postal_town: 'Seaford',
  },
  {
    postcode: 'BN26',
    uk_region: 'South East',
    postal_town: 'Polegate',
  },
  {
    postcode: 'BN27',
    uk_region: 'South East',
    postal_town: 'Hailsham',
  },
  {
    postcode: 'BN41',
    uk_region: 'South East',
    postal_town: 'Brighton',
  },
  {
    postcode: 'BN42',
    uk_region: 'South East',
    postal_town: 'Brighton',
  },
  {
    postcode: 'BN43',
    uk_region: 'South East',
    postal_town: 'Shoreham-by-Sea',
  },
  {
    postcode: 'BN44',
    uk_region: 'South East',
    postal_town: 'Steyning',
  },
  {
    postcode: 'BN45',
    uk_region: 'South East',
    postal_town: 'Brighton',
  },
  {
    postcode: 'BN50',
    uk_region: 'South East',
    postal_town: 'Brighton',
  },
  {
    postcode: 'BN51',
    uk_region: 'South East',
    postal_town: 'Brighton',
  },
  {
    postcode: 'BN52',
    uk_region: 'South East',
    postal_town: 'Hove',
  },
  {
    postcode: 'BN88',
    uk_region: 'South East',
    postal_town: 'Brighton',
  },
  {
    postcode: 'BN91',
    uk_region: '',
    postal_town: 'Worthing',
  },
  {
    postcode: 'BN95',
    uk_region: 'South East',
    postal_town: '',
  },
  {
    postcode: 'BN99',
    uk_region: 'South East',
    postal_town: '"Worthing, Lancing"',
  },
  {
    postcode: 'BR1',
    uk_region: 'London',
    postal_town: 'Bromley',
  },
  {
    postcode: 'BR2',
    uk_region: 'London',
    postal_town: '"Bromley, Keston"',
  },
  {
    postcode: 'BR3',
    uk_region: 'London',
    postal_town: 'Beckenham',
  },
  {
    postcode: 'BR4',
    uk_region: 'London',
    postal_town: 'West Wickham',
  },
  {
    postcode: 'BR5',
    uk_region: 'London',
    postal_town: 'Orpington',
  },
  {
    postcode: 'BR6',
    uk_region: 'London',
    postal_town: 'Orpington',
  },
  {
    postcode: 'BR7',
    uk_region: 'London',
    postal_town: 'Chislehurst',
  },
  {
    postcode: 'BR8',
    uk_region: 'South East',
    postal_town: 'Swanley',
  },
  {
    postcode: 'BR98',
    uk_region: '',
    postal_town: '',
  },
  {
    postcode: 'BS0',
    uk_region: 'South West',
    postal_town: 'Bristol',
  },
  {
    postcode: 'BS1',
    uk_region: 'South West',
    postal_town: 'Bristol',
  },
  {
    postcode: 'BS2',
    uk_region: 'South West',
    postal_town: 'Bristol',
  },
  {
    postcode: 'BS3',
    uk_region: 'South West',
    postal_town: 'Bristol',
  },
  {
    postcode: 'BS4',
    uk_region: 'South West',
    postal_town: 'Bristol',
  },
  {
    postcode: 'BS5',
    uk_region: 'South West',
    postal_town: 'Bristol',
  },
  {
    postcode: 'BS6',
    uk_region: 'South West',
    postal_town: 'Bristol',
  },
  {
    postcode: 'BS7',
    uk_region: 'South West',
    postal_town: 'Bristol',
  },
  {
    postcode: 'BS8',
    uk_region: 'South West',
    postal_town: 'Bristol',
  },
  {
    postcode: 'BS9',
    uk_region: 'South West',
    postal_town: 'Bristol',
  },
  {
    postcode: 'BS10',
    uk_region: 'South West',
    postal_town: 'Bristol',
  },
  {
    postcode: 'BS11',
    uk_region: 'South West',
    postal_town: 'Bristol',
  },
  {
    postcode: 'BS12',
    uk_region: 'South West',
    postal_town: '',
  },
  {
    postcode: 'BS13',
    uk_region: 'South West',
    postal_town: 'Bristol',
  },
  {
    postcode: 'BS14',
    uk_region: 'South West',
    postal_town: 'Bristol',
  },
  {
    postcode: 'BS15',
    uk_region: 'South West',
    postal_town: 'Bristol',
  },
  {
    postcode: 'BS16',
    uk_region: 'South West',
    postal_town: 'Bristol',
  },
  {
    postcode: 'BS17',
    uk_region: 'South West',
    postal_town: '',
  },
  {
    postcode: 'BS18',
    uk_region: 'South West',
    postal_town: '',
  },
  {
    postcode: 'BS19',
    uk_region: 'South West',
    postal_town: '',
  },
  {
    postcode: 'BS20',
    uk_region: 'South West',
    postal_town: 'Bristol',
  },
  {
    postcode: 'BS21',
    uk_region: 'South West',
    postal_town: 'Clevedon',
  },
  {
    postcode: 'BS22',
    uk_region: 'South West',
    postal_town: 'Weston-super-Mare',
  },
  {
    postcode: 'BS23',
    uk_region: 'South West',
    postal_town: 'Weston-super-Mare',
  },
  {
    postcode: 'BS24',
    uk_region: 'South West',
    postal_town: 'Weston-super-Mare',
  },
  {
    postcode: 'BS25',
    uk_region: 'South West',
    postal_town: 'Winscombe',
  },
  {
    postcode: 'BS26',
    uk_region: 'South West',
    postal_town: 'Axbridge',
  },
  {
    postcode: 'BS27',
    uk_region: 'South West',
    postal_town: 'Cheddar',
  },
  {
    postcode: 'BS28',
    uk_region: 'South West',
    postal_town: 'Wedmore',
  },
  {
    postcode: 'BS29',
    uk_region: 'South West',
    postal_town: 'Banwell',
  },
  {
    postcode: 'BS30',
    uk_region: 'South West',
    postal_town: 'Bristol',
  },
  {
    postcode: 'BS31',
    uk_region: 'South West',
    postal_town: 'Bristol',
  },
  {
    postcode: 'BS32',
    uk_region: 'South West',
    postal_town: 'Bristol',
  },
  {
    postcode: 'BS34',
    uk_region: 'South West',
    postal_town: 'Bristol',
  },
  {
    postcode: 'BS35',
    uk_region: 'South West',
    postal_town: 'Bristol',
  },
  {
    postcode: 'BS36',
    uk_region: 'South West',
    postal_town: 'Bristol',
  },
  {
    postcode: 'BS37',
    uk_region: 'South West',
    postal_town: 'Bristol',
  },
  {
    postcode: 'BS38',
    uk_region: '',
    postal_town: '',
  },
  {
    postcode: 'BS39',
    uk_region: 'South West',
    postal_town: 'Bristol',
  },
  {
    postcode: 'BS40',
    uk_region: 'South West',
    postal_town: 'Bristol',
  },
  {
    postcode: 'BS41',
    uk_region: 'South West',
    postal_town: 'Bristol',
  },
  {
    postcode: 'BS48',
    uk_region: 'South West',
    postal_town: 'Bristol',
  },
  {
    postcode: 'BS49',
    uk_region: 'South West',
    postal_town: 'Bristol',
  },
  {
    postcode: 'BS77',
    uk_region: 'South West',
    postal_town: '',
  },
  {
    postcode: 'BS80',
    uk_region: 'South West',
    postal_town: 'Bristol',
  },
  {
    postcode: 'BS98',
    uk_region: 'South West',
    postal_town: 'Bristol',
  },
  {
    postcode: 'BS99',
    uk_region: 'South West',
    postal_town: 'Bristol',
  },
  {
    postcode: 'BT1',
    uk_region: 'Northern Ireland',
    postal_town: 'Belfast',
  },
  {
    postcode: 'BT2',
    uk_region: 'Northern Ireland',
    postal_town: 'Belfast',
  },
  {
    postcode: 'BT3',
    uk_region: 'Northern Ireland',
    postal_town: 'Belfast',
  },
  {
    postcode: 'BT4',
    uk_region: 'Northern Ireland',
    postal_town: 'Belfast',
  },
  {
    postcode: 'BT5',
    uk_region: 'Northern Ireland',
    postal_town: 'Belfast',
  },
  {
    postcode: 'BT6',
    uk_region: 'Northern Ireland',
    postal_town: 'Belfast',
  },
  {
    postcode: 'BT7',
    uk_region: 'Northern Ireland',
    postal_town: 'Belfast',
  },
  {
    postcode: 'BT8',
    uk_region: 'Northern Ireland',
    postal_town: 'Belfast',
  },
  {
    postcode: 'BT9',
    uk_region: 'Northern Ireland',
    postal_town: 'Belfast',
  },
  {
    postcode: 'BT10',
    uk_region: 'Northern Ireland',
    postal_town: 'Belfast',
  },
  {
    postcode: 'BT11',
    uk_region: 'Northern Ireland',
    postal_town: 'Belfast',
  },
  {
    postcode: 'BT12',
    uk_region: 'Northern Ireland',
    postal_town: 'Belfast',
  },
  {
    postcode: 'BT13',
    uk_region: 'Northern Ireland',
    postal_town: 'Belfast',
  },
  {
    postcode: 'BT14',
    uk_region: 'Northern Ireland',
    postal_town: 'Belfast',
  },
  {
    postcode: 'BT15',
    uk_region: 'Northern Ireland',
    postal_town: 'Belfast',
  },
  {
    postcode: 'BT16',
    uk_region: 'Northern Ireland',
    postal_town: 'Belfast',
  },
  {
    postcode: 'BT17',
    uk_region: 'Northern Ireland',
    postal_town: 'Belfast',
  },
  {
    postcode: 'BT18',
    uk_region: 'Northern Ireland',
    postal_town: 'Holywood',
  },
  {
    postcode: 'BT19',
    uk_region: 'Northern Ireland',
    postal_town: 'Bangor',
  },
  {
    postcode: 'BT20',
    uk_region: 'Northern Ireland',
    postal_town: 'Bangor',
  },
  {
    postcode: 'BT21',
    uk_region: 'Northern Ireland',
    postal_town: 'Donaghadee',
  },
  {
    postcode: 'BT22',
    uk_region: 'Northern Ireland',
    postal_town: 'Newtownards',
  },
  {
    postcode: 'BT23',
    uk_region: 'Northern Ireland',
    postal_town: 'Newtownards',
  },
  {
    postcode: 'BT24',
    uk_region: 'Northern Ireland',
    postal_town: 'Ballynahinch',
  },
  {
    postcode: 'BT25',
    uk_region: 'Northern Ireland',
    postal_town: 'Dromore',
  },
  {
    postcode: 'BT26',
    uk_region: 'Northern Ireland',
    postal_town: 'Hillsborough',
  },
  {
    postcode: 'BT27',
    uk_region: 'Northern Ireland',
    postal_town: 'Lisburn',
  },
  {
    postcode: 'BT28',
    uk_region: 'Northern Ireland',
    postal_town: 'Lisburn',
  },
  {
    postcode: 'BT29',
    uk_region: 'Northern Ireland',
    postal_town: '"Belfast, Crumlin"',
  },
  {
    postcode: 'BT30',
    uk_region: 'Northern Ireland',
    postal_town: 'Downpatrick',
  },
  {
    postcode: 'BT31',
    uk_region: 'Northern Ireland',
    postal_town: 'Castlewellan',
  },
  {
    postcode: 'BT32',
    uk_region: 'Northern Ireland',
    postal_town: 'Banbridge',
  },
  {
    postcode: 'BT33',
    uk_region: 'Northern Ireland',
    postal_town: 'Newcastle',
  },
  {
    postcode: 'BT34',
    uk_region: 'Northern Ireland',
    postal_town: 'Newry',
  },
  {
    postcode: 'BT35',
    uk_region: 'Northern Ireland',
    postal_town: 'Newry',
  },
  {
    postcode: 'BT36',
    uk_region: 'Northern Ireland',
    postal_town: 'Newtownabbey',
  },
  {
    postcode: 'BT37',
    uk_region: 'Northern Ireland',
    postal_town: 'Newtownabbey',
  },
  {
    postcode: 'BT38',
    uk_region: 'Northern Ireland',
    postal_town: 'Carrickfergus',
  },
  {
    postcode: 'BT39',
    uk_region: 'Northern Ireland',
    postal_town: 'Ballyclare',
  },
  {
    postcode: 'BT40',
    uk_region: 'Northern Ireland',
    postal_town: 'Larne',
  },
  {
    postcode: 'BT41',
    uk_region: 'Northern Ireland',
    postal_town: 'Antrim',
  },
  {
    postcode: 'BT42',
    uk_region: 'Northern Ireland',
    postal_town: 'Ballymena',
  },
  {
    postcode: 'BT43',
    uk_region: 'Northern Ireland',
    postal_town: 'Ballymena',
  },
  {
    postcode: 'BT44',
    uk_region: 'Northern Ireland',
    postal_town: 'Ballymena',
  },
  {
    postcode: 'BT45',
    uk_region: 'Northern Ireland',
    postal_town: 'Magherafelt',
  },
  {
    postcode: 'BT46',
    uk_region: 'Northern Ireland',
    postal_town: 'Maghera',
  },
  {
    postcode: 'BT47',
    uk_region: 'Northern Ireland',
    postal_town: 'Londonderry',
  },
  {
    postcode: 'BT48',
    uk_region: 'Northern Ireland',
    postal_town: 'Londonderry',
  },
  {
    postcode: 'BT49',
    uk_region: 'Northern Ireland',
    postal_town: 'Limavady',
  },
  {
    postcode: 'BT51',
    uk_region: 'Northern Ireland',
    postal_town: 'Coleraine',
  },
  {
    postcode: 'BT52',
    uk_region: 'Northern Ireland',
    postal_town: 'Coleraine',
  },
  {
    postcode: 'BT53',
    uk_region: 'Northern Ireland',
    postal_town: 'Ballymoney',
  },
  {
    postcode: 'BT54',
    uk_region: 'Northern Ireland',
    postal_town: 'Ballycastle',
  },
  {
    postcode: 'BT55',
    uk_region: 'Northern Ireland',
    postal_town: 'Portstewart',
  },
  {
    postcode: 'BT56',
    uk_region: 'Northern Ireland',
    postal_town: 'Portrush',
  },
  {
    postcode: 'BT57',
    uk_region: 'Northern Ireland',
    postal_town: 'Bushmills',
  },
  {
    postcode: 'BT58',
    uk_region: 'Northern Ireland',
    postal_town: 'Newtownabbey',
  },
  {
    postcode: 'BT60',
    uk_region: 'Northern Ireland',
    postal_town: 'Armagh',
  },
  {
    postcode: 'BT61',
    uk_region: 'Northern Ireland',
    postal_town: 'Armagh',
  },
  {
    postcode: 'BT62',
    uk_region: 'Northern Ireland',
    postal_town: 'Craigavon',
  },
  {
    postcode: 'BT63',
    uk_region: 'Northern Ireland',
    postal_town: 'Craigavon',
  },
  {
    postcode: 'BT64',
    uk_region: 'Northern Ireland',
    postal_town: 'Craigavon',
  },
  {
    postcode: 'BT65',
    uk_region: 'Northern Ireland',
    postal_town: 'Craigavon',
  },
  {
    postcode: 'BT66',
    uk_region: 'Northern Ireland',
    postal_town: 'Craigavon',
  },
  {
    postcode: 'BT67',
    uk_region: 'Northern Ireland',
    postal_town: 'Craigavon',
  },
  {
    postcode: 'BT68',
    uk_region: 'Northern Ireland',
    postal_town: 'Caledon',
  },
  {
    postcode: 'BT69',
    uk_region: 'Northern Ireland',
    postal_town: 'Aughnacloy',
  },
  {
    postcode: 'BT70',
    uk_region: 'Northern Ireland',
    postal_town: 'Dungannon',
  },
  {
    postcode: 'BT71',
    uk_region: 'Northern Ireland',
    postal_town: 'Dungannon',
  },
  {
    postcode: 'BT74',
    uk_region: 'Northern Ireland',
    postal_town: 'Enniskillen',
  },
  {
    postcode: 'BT75',
    uk_region: 'Northern Ireland',
    postal_town: 'Fivemiletown',
  },
  {
    postcode: 'BT76',
    uk_region: 'Northern Ireland',
    postal_town: 'Clogher',
  },
  {
    postcode: 'BT77',
    uk_region: 'Northern Ireland',
    postal_town: 'Augher',
  },
  {
    postcode: 'BT78',
    uk_region: 'Northern Ireland',
    postal_town: 'Omagh',
  },
  {
    postcode: 'BT79',
    uk_region: 'Northern Ireland',
    postal_town: 'Omagh',
  },
  {
    postcode: 'BT80',
    uk_region: 'Northern Ireland',
    postal_town: 'Cookstown',
  },
  {
    postcode: 'BT81',
    uk_region: 'Northern Ireland',
    postal_town: 'Castlederg',
  },
  {
    postcode: 'BT82',
    uk_region: 'Northern Ireland',
    postal_town: 'Strabane',
  },
  {
    postcode: 'BT92',
    uk_region: 'Northern Ireland',
    postal_town: 'Enniskillen',
  },
  {
    postcode: 'BT93',
    uk_region: 'Northern Ireland',
    postal_town: 'Enniskillen',
  },
  {
    postcode: 'BT94',
    uk_region: 'Northern Ireland',
    postal_town: 'Enniskillen',
  },
  {
    postcode: 'BT99',
    uk_region: 'Northern Ireland',
    postal_town: '',
  },
  {
    postcode: 'CA1',
    uk_region: 'North West',
    postal_town: 'Carlisle',
  },
  {
    postcode: 'CA2',
    uk_region: 'North West',
    postal_town: 'Carlisle',
  },
  {
    postcode: 'CA3',
    uk_region: 'North West',
    postal_town: 'Carlisle',
  },
  {
    postcode: 'CA4',
    uk_region: 'North West',
    postal_town: 'Carlisle',
  },
  {
    postcode: 'CA5',
    uk_region: 'North West',
    postal_town: 'Carlisle',
  },
  {
    postcode: 'CA6',
    uk_region: 'North West',
    postal_town: 'Carlisle',
  },
  {
    postcode: 'CA7',
    uk_region: 'North West',
    postal_town: 'Wigton',
  },
  {
    postcode: 'CA8',
    uk_region: 'North West',
    postal_town: 'Brampton',
  },
  {
    postcode: 'CA9',
    uk_region: 'North West',
    postal_town: 'Alston',
  },
  {
    postcode: 'CA10',
    uk_region: 'North West',
    postal_town: 'Penrith',
  },
  {
    postcode: 'CA11',
    uk_region: 'North West',
    postal_town: 'Penrith',
  },
  {
    postcode: 'CA12',
    uk_region: 'North West',
    postal_town: 'Keswick',
  },
  {
    postcode: 'CA13',
    uk_region: 'North West',
    postal_town: 'Cockermouth',
  },
  {
    postcode: 'CA14',
    uk_region: 'North West',
    postal_town: 'Workington',
  },
  {
    postcode: 'CA15',
    uk_region: 'North West',
    postal_town: 'Maryport',
  },
  {
    postcode: 'CA16',
    uk_region: 'North West',
    postal_town: 'Appleby-in-Westmorland',
  },
  {
    postcode: 'CA17',
    uk_region: 'North West',
    postal_town: 'Kirkby Stephen',
  },
  {
    postcode: 'CA18',
    uk_region: 'North West',
    postal_town: 'Ravenglass',
  },
  {
    postcode: 'CA19',
    uk_region: 'North West',
    postal_town: 'Holmrook',
  },
  {
    postcode: 'CA20',
    uk_region: 'North West',
    postal_town: 'Seascale',
  },
  {
    postcode: 'CA21',
    uk_region: 'North West',
    postal_town: 'Beckermet',
  },
  {
    postcode: 'CA22',
    uk_region: 'North West',
    postal_town: 'Egremont',
  },
  {
    postcode: 'CA23',
    uk_region: 'North West',
    postal_town: 'Cleator',
  },
  {
    postcode: 'CA24',
    uk_region: 'North West',
    postal_town: 'Moor Row',
  },
  {
    postcode: 'CA25',
    uk_region: 'North West',
    postal_town: 'Cleator Moor',
  },
  {
    postcode: 'CA26',
    uk_region: 'North West',
    postal_town: 'Frizington',
  },
  {
    postcode: 'CA27',
    uk_region: 'North West',
    postal_town: 'St. Bees',
  },
  {
    postcode: 'CA28',
    uk_region: 'North West',
    postal_town: 'Whitehaven',
  },
  {
    postcode: 'CA95',
    uk_region: 'North West',
    postal_town: 'Workington',
  },
  {
    postcode: 'CA99',
    uk_region: 'North West',
    postal_town: 'Carlisle',
  },
  {
    postcode: 'CB1',
    uk_region: 'East of England',
    postal_town: 'Cambridge',
  },
  {
    postcode: 'CB2',
    uk_region: 'East of England',
    postal_town: 'Cambridge',
  },
  {
    postcode: 'CB3',
    uk_region: 'East of England',
    postal_town: 'Cambridge',
  },
  {
    postcode: 'CB4',
    uk_region: 'East of England',
    postal_town: 'Cambridge',
  },
  {
    postcode: 'CB5',
    uk_region: 'East of England',
    postal_town: 'Cambridge',
  },
  {
    postcode: 'CB6',
    uk_region: 'East of England',
    postal_town: 'Ely',
  },
  {
    postcode: 'CB7',
    uk_region: 'East of England',
    postal_town: 'Ely',
  },
  {
    postcode: 'CB8',
    uk_region: 'East of England',
    postal_town: 'Newmarket',
  },
  {
    postcode: 'CB9',
    uk_region: 'East of England',
    postal_town: 'Haverhill',
  },
  {
    postcode: 'CB10',
    uk_region: 'East of England',
    postal_town: 'Saffron Walden',
  },
  {
    postcode: 'CB11',
    uk_region: 'East of England',
    postal_town: 'Saffron Walden',
  },
  {
    postcode: 'CB21',
    uk_region: 'East of England',
    postal_town: 'Cambridge',
  },
  {
    postcode: 'CB22',
    uk_region: 'East of England',
    postal_town: 'Cambridge',
  },
  {
    postcode: 'CB23',
    uk_region: 'East of England',
    postal_town: 'Cambridge',
  },
  {
    postcode: 'CB24',
    uk_region: 'East of England',
    postal_town: 'Cambridge',
  },
  {
    postcode: 'CB25',
    uk_region: 'East of England',
    postal_town: 'Cambridge',
  },
  {
    postcode: 'CF1',
    uk_region: 'Wales',
    postal_town: '',
  },
  {
    postcode: 'CF2',
    uk_region: 'Wales',
    postal_town: '',
  },
  {
    postcode: 'CF3',
    uk_region: 'Wales',
    postal_town: 'Cardiff',
  },
  {
    postcode: 'CF4',
    uk_region: 'Wales',
    postal_town: '',
  },
  {
    postcode: 'CF5',
    uk_region: 'Wales',
    postal_town: 'Cardiff',
  },
  {
    postcode: 'CF6',
    uk_region: 'Wales',
    postal_town: '',
  },
  {
    postcode: 'CF7',
    uk_region: 'Wales',
    postal_town: '',
  },
  {
    postcode: 'CF8',
    uk_region: 'Wales',
    postal_town: '',
  },
  {
    postcode: 'CF10',
    uk_region: 'Wales',
    postal_town: 'Cardiff',
  },
  {
    postcode: 'CF11',
    uk_region: 'Wales',
    postal_town: 'Cardiff',
  },
  {
    postcode: 'CF14',
    uk_region: 'Wales',
    postal_town: 'Cardiff',
  },
  {
    postcode: 'CF15',
    uk_region: 'Wales',
    postal_town: 'Cardiff',
  },
  {
    postcode: 'CF21',
    uk_region: 'Wales',
    postal_town: '',
  },
  {
    postcode: 'CF23',
    uk_region: 'Wales',
    postal_town: 'Cardiff',
  },
  {
    postcode: 'CF24',
    uk_region: 'Wales',
    postal_town: 'Cardiff',
  },
  {
    postcode: 'CF30',
    uk_region: 'Wales',
    postal_town: 'Cardiff',
  },
  {
    postcode: 'CF31',
    uk_region: 'Wales',
    postal_town: 'Bridgend',
  },
  {
    postcode: 'CF32',
    uk_region: 'Wales',
    postal_town: 'Bridgend',
  },
  {
    postcode: 'CF33',
    uk_region: 'Wales',
    postal_town: 'Bridgend',
  },
  {
    postcode: 'CF34',
    uk_region: 'Wales',
    postal_town: 'Maesteg',
  },
  {
    postcode: 'CF35',
    uk_region: 'Wales',
    postal_town: 'Bridgend',
  },
  {
    postcode: 'CF36',
    uk_region: 'Wales',
    postal_town: 'Porthcawl',
  },
  {
    postcode: 'CF37',
    uk_region: 'Wales',
    postal_town: 'Pontypridd',
  },
  {
    postcode: 'CF38',
    uk_region: 'Wales',
    postal_town: 'Pontypridd',
  },
  {
    postcode: 'CF39',
    uk_region: 'Wales',
    postal_town: 'Porth',
  },
  {
    postcode: 'CF40',
    uk_region: 'Wales',
    postal_town: 'Tonypandy',
  },
  {
    postcode: 'CF41',
    uk_region: 'Wales',
    postal_town: 'Pentre',
  },
  {
    postcode: 'CF42',
    uk_region: 'Wales',
    postal_town: 'Treorchy',
  },
  {
    postcode: 'CF43',
    uk_region: 'Wales',
    postal_town: 'Ferndale',
  },
  {
    postcode: 'CF44',
    uk_region: 'Wales',
    postal_town: 'Aberdare',
  },
  {
    postcode: 'CF45',
    uk_region: 'Wales',
    postal_town: 'Mountain Ash',
  },
  {
    postcode: 'CF46',
    uk_region: 'Wales',
    postal_town: 'Treharris',
  },
  {
    postcode: 'CF47',
    uk_region: 'Wales',
    postal_town: 'Merthyr Tydfil',
  },
  {
    postcode: 'CF48',
    uk_region: 'Wales',
    postal_town: 'Merthyr Tydfil',
  },
  {
    postcode: 'CF61',
    uk_region: 'Wales',
    postal_town: 'Llantwit Major',
  },
  {
    postcode: 'CF62',
    uk_region: 'Wales',
    postal_town: 'Barry',
  },
  {
    postcode: 'CF63',
    uk_region: 'Wales',
    postal_town: 'Barry',
  },
  {
    postcode: 'CF64',
    uk_region: 'Wales',
    postal_town: '"Dinas Powys, Penarth"',
  },
  {
    postcode: 'CF71',
    uk_region: 'Wales',
    postal_town: '"Llantwit Major, Cowbridge"',
  },
  {
    postcode: 'CF72',
    uk_region: 'Wales',
    postal_town: 'Pontyclun',
  },
  {
    postcode: 'CF81',
    uk_region: 'Wales',
    postal_town: 'Bargoed',
  },
  {
    postcode: 'CF82',
    uk_region: 'Wales',
    postal_town: 'Hengoed',
  },
  {
    postcode: 'CF83',
    uk_region: 'Wales',
    postal_town: 'Caerphilly',
  },
  {
    postcode: 'CF91',
    uk_region: 'Wales',
    postal_town: 'Cardiff',
  },
  {
    postcode: 'CF95',
    uk_region: 'Wales',
    postal_town: 'Cardiff',
  },
  {
    postcode: 'CF99',
    uk_region: 'Wales',
    postal_town: 'Cardiff',
  },
  {
    postcode: 'CH1',
    uk_region: 'North West',
    postal_town: 'Chester',
  },
  {
    postcode: 'CH2',
    uk_region: 'North West',
    postal_town: 'Chester',
  },
  {
    postcode: 'CH3',
    uk_region: 'North West',
    postal_town: 'Chester',
  },
  {
    postcode: 'CH4',
    uk_region: 'North West',
    postal_town: 'Chester',
  },
  {
    postcode: 'CH5',
    uk_region: 'Wales',
    postal_town: 'Deeside',
  },
  {
    postcode: 'CH6',
    uk_region: 'Wales',
    postal_town: '"Bagillt, Flint"',
  },
  {
    postcode: 'CH7',
    uk_region: 'Wales',
    postal_town: '"Buckley, Mold"',
  },
  {
    postcode: 'CH8',
    uk_region: 'Wales',
    postal_town: 'Holywell',
  },
  {
    postcode: 'CH25',
    uk_region: 'North West',
    postal_town: 'Birkenhead',
  },
  {
    postcode: 'CH26',
    uk_region: 'North West',
    postal_town: 'Prenton',
  },
  {
    postcode: 'CH27',
    uk_region: 'North West',
    postal_town: 'Wallasey',
  },
  {
    postcode: 'CH28',
    uk_region: 'North West',
    postal_town: 'Wirral',
  },
  {
    postcode: 'CH29',
    uk_region: 'North West',
    postal_town: 'Wirral',
  },
  {
    postcode: 'CH30',
    uk_region: 'North West',
    postal_town: 'Wirral',
  },
  {
    postcode: 'CH31',
    uk_region: 'North West',
    postal_town: 'Wirral',
  },
  {
    postcode: 'CH32',
    uk_region: 'North West',
    postal_town: 'Wirral',
  },
  {
    postcode: 'CH33',
    uk_region: 'North West',
    postal_town: 'Neston',
  },
  {
    postcode: 'CH34',
    uk_region: 'North West',
    postal_town: 'Ellesmere Port',
  },
  {
    postcode: 'CH41',
    uk_region: 'North West',
    postal_town: 'Birkenhead',
  },
  {
    postcode: 'CH42',
    uk_region: 'North West',
    postal_town: 'Birkenhead',
  },
  {
    postcode: 'CH43',
    uk_region: 'North West',
    postal_town: 'Prenton',
  },
  {
    postcode: 'CH44',
    uk_region: 'North West',
    postal_town: 'Wallasey',
  },
  {
    postcode: 'CH45',
    uk_region: 'North West',
    postal_town: 'Wallasey',
  },
  {
    postcode: 'CH46',
    uk_region: 'North West',
    postal_town: 'Wirral',
  },
  {
    postcode: 'CH47',
    uk_region: 'North West',
    postal_town: 'Wirral',
  },
  {
    postcode: 'CH48',
    uk_region: 'North West',
    postal_town: 'Wirral',
  },
  {
    postcode: 'CH49',
    uk_region: 'North West',
    postal_town: 'Wirral',
  },
  {
    postcode: 'CH60',
    uk_region: 'North West',
    postal_town: 'Wirral',
  },
  {
    postcode: 'CH61',
    uk_region: 'North West',
    postal_town: 'Wirral',
  },
  {
    postcode: 'CH62',
    uk_region: 'North West',
    postal_town: 'Wirral',
  },
  {
    postcode: 'CH63',
    uk_region: 'North West',
    postal_town: 'Wirral',
  },
  {
    postcode: 'CH64',
    uk_region: 'North West',
    postal_town: 'Neston',
  },
  {
    postcode: 'CH65',
    uk_region: 'North West',
    postal_town: 'Ellesmere Port',
  },
  {
    postcode: 'CH66',
    uk_region: 'North West',
    postal_town: 'Ellesmere Port',
  },
  {
    postcode: 'CH70',
    uk_region: 'North West',
    postal_town: 'Chester',
  },
  {
    postcode: 'CH88',
    uk_region: 'North West',
    postal_town: 'Chester',
  },
  {
    postcode: 'CH99',
    uk_region: 'North West',
    postal_town: 'Chester',
  },
  {
    postcode: 'CM0',
    uk_region: 'East of England',
    postal_town: '"Burnham-on-Crouch, Southminster"',
  },
  {
    postcode: 'CM1',
    uk_region: 'East of England',
    postal_town: 'Chelmsford',
  },
  {
    postcode: 'CM2',
    uk_region: 'East of England',
    postal_town: 'Chelmsford',
  },
  {
    postcode: 'CM3',
    uk_region: 'East of England',
    postal_town: 'Chelmsford',
  },
  {
    postcode: 'CM4',
    uk_region: 'East of England',
    postal_town: 'Ingatestone',
  },
  {
    postcode: 'CM5',
    uk_region: 'East of England',
    postal_town: 'Ongar',
  },
  {
    postcode: 'CM6',
    uk_region: 'East of England',
    postal_town: 'Dunmow',
  },
  {
    postcode: 'CM7',
    uk_region: 'East of England',
    postal_town: '"Dunmow, Braintree"',
  },
  {
    postcode: 'CM8',
    uk_region: 'East of England',
    postal_town: 'Witham',
  },
  {
    postcode: 'CM9',
    uk_region: 'East of England',
    postal_town: 'Maldon',
  },
  {
    postcode: 'CM11',
    uk_region: 'East of England',
    postal_town: 'Billericay',
  },
  {
    postcode: 'CM12',
    uk_region: 'East of England',
    postal_town: 'Billericay',
  },
  {
    postcode: 'CM13',
    uk_region: 'East of England',
    postal_town: 'Brentwood',
  },
  {
    postcode: 'CM14',
    uk_region: 'East of England',
    postal_town: 'Brentwood',
  },
  {
    postcode: 'CM15',
    uk_region: 'East of England',
    postal_town: 'Brentwood',
  },
  {
    postcode: 'CM16',
    uk_region: 'East of England',
    postal_town: 'Epping',
  },
  {
    postcode: 'CM17',
    uk_region: 'East of England',
    postal_town: 'Harlow',
  },
  {
    postcode: 'CM18',
    uk_region: 'East of England',
    postal_town: 'Harlow',
  },
  {
    postcode: 'CM19',
    uk_region: 'East of England',
    postal_town: 'Harlow',
  },
  {
    postcode: 'CM20',
    uk_region: 'East of England',
    postal_town: 'Harlow',
  },
  {
    postcode: 'CM21',
    uk_region: 'East of England',
    postal_town: 'Sawbridgeworth',
  },
  {
    postcode: 'CM22',
    uk_region: 'East of England',
    postal_town: "Bishop's Stortford",
  },
  {
    postcode: 'CM23',
    uk_region: 'East of England',
    postal_town: "Bishop's Stortford",
  },
  {
    postcode: 'CM24',
    uk_region: 'East of England',
    postal_town: 'Stansted',
  },
  {
    postcode: 'CM77',
    uk_region: 'East of England',
    postal_town: 'Braintree',
  },
  {
    postcode: 'CM92',
    uk_region: 'East of England',
    postal_town: 'Chelmsford',
  },
  {
    postcode: 'CM98',
    uk_region: 'East of England',
    postal_town: 'Chelmsford',
  },
  {
    postcode: 'CM99',
    uk_region: 'East of England',
    postal_town: 'Chelmsford',
  },
  {
    postcode: 'CO1',
    uk_region: 'East of England',
    postal_town: 'Colchester',
  },
  {
    postcode: 'CO2',
    uk_region: 'East of England',
    postal_town: 'Colchester',
  },
  {
    postcode: 'CO3',
    uk_region: 'East of England',
    postal_town: 'Colchester',
  },
  {
    postcode: 'CO4',
    uk_region: 'East of England',
    postal_town: 'Colchester',
  },
  {
    postcode: 'CO5',
    uk_region: 'East of England',
    postal_town: 'Colchester',
  },
  {
    postcode: 'CO6',
    uk_region: 'East of England',
    postal_town: 'Colchester',
  },
  {
    postcode: 'CO7',
    uk_region: 'East of England',
    postal_town: 'Colchester',
  },
  {
    postcode: 'CO8',
    uk_region: 'East of England',
    postal_town: 'Bures',
  },
  {
    postcode: 'CO9',
    uk_region: 'East of England',
    postal_town: 'Halstead',
  },
  {
    postcode: 'CO10',
    uk_region: 'East of England',
    postal_town: 'Sudbury',
  },
  {
    postcode: 'CO11',
    uk_region: 'East of England',
    postal_town: 'Manningtree',
  },
  {
    postcode: 'CO12',
    uk_region: 'East of England',
    postal_town: 'Harwich',
  },
  {
    postcode: 'CO13',
    uk_region: 'East of England',
    postal_town: 'Frinton-on-Sea',
  },
  {
    postcode: 'CO14',
    uk_region: 'East of England',
    postal_town: 'Walton-on-the-Naze',
  },
  {
    postcode: 'CO15',
    uk_region: 'East of England',
    postal_town: 'Clacton-on-Sea',
  },
  {
    postcode: 'CO16',
    uk_region: 'East of England',
    postal_town: 'Clacton-on-Sea',
  },
  {
    postcode: 'CR0',
    uk_region: 'London',
    postal_town: 'Croydon',
  },
  {
    postcode: 'CR2',
    uk_region: 'London',
    postal_town: 'South Croydon',
  },
  {
    postcode: 'CR3',
    uk_region: 'South East',
    postal_town: '"Caterham, Whyteleafe"',
  },
  {
    postcode: 'CR4',
    uk_region: 'London',
    postal_town: 'Mitcham',
  },
  {
    postcode: 'CR5',
    uk_region: 'London',
    postal_town: 'Coulsdon',
  },
  {
    postcode: 'CR6',
    uk_region: 'South East',
    postal_town: 'Warlingham',
  },
  {
    postcode: 'CR7',
    uk_region: 'London',
    postal_town: 'Thornton Heath',
  },
  {
    postcode: 'CR8',
    uk_region: 'London',
    postal_town: '"Kenley, Purley"',
  },
  {
    postcode: 'CR9',
    uk_region: 'London',
    postal_town: 'Croydon',
  },
  {
    postcode: 'CR44',
    uk_region: 'London',
    postal_town: 'Croydon',
  },
  {
    postcode: 'CR90',
    uk_region: 'London',
    postal_town: 'Croydon',
  },
  {
    postcode: 'CT1',
    uk_region: 'South East',
    postal_town: 'Canterbury',
  },
  {
    postcode: 'CT2',
    uk_region: 'South East',
    postal_town: 'Canterbury',
  },
  {
    postcode: 'CT3',
    uk_region: 'South East',
    postal_town: 'Canterbury',
  },
  {
    postcode: 'CT4',
    uk_region: 'South East',
    postal_town: 'Canterbury',
  },
  {
    postcode: 'CT5',
    uk_region: 'South East',
    postal_town: 'Whitstable',
  },
  {
    postcode: 'CT6',
    uk_region: 'South East',
    postal_town: 'Herne Bay',
  },
  {
    postcode: 'CT7',
    uk_region: 'South East',
    postal_town: 'Birchington',
  },
  {
    postcode: 'CT8',
    uk_region: 'South East',
    postal_town: 'Westgate-on-Sea',
  },
  {
    postcode: 'CT9',
    uk_region: 'South East',
    postal_town: '"Margate, Birchington"',
  },
  {
    postcode: 'CT10',
    uk_region: 'South East',
    postal_town: 'Broadstairs',
  },
  {
    postcode: 'CT11',
    uk_region: 'South East',
    postal_town: 'Ramsgate',
  },
  {
    postcode: 'CT12',
    uk_region: 'South East',
    postal_town: 'Ramsgate',
  },
  {
    postcode: 'CT13',
    uk_region: 'South East',
    postal_town: 'Sandwich',
  },
  {
    postcode: 'CT14',
    uk_region: 'South East',
    postal_town: 'Deal',
  },
  {
    postcode: 'CT15',
    uk_region: 'South East',
    postal_town: 'Dover',
  },
  {
    postcode: 'CT16',
    uk_region: 'South East',
    postal_town: 'Dover',
  },
  {
    postcode: 'CT17',
    uk_region: 'South East',
    postal_town: 'Dover',
  },
  {
    postcode: 'CT18',
    uk_region: 'South East',
    postal_town: 'Folkestone',
  },
  {
    postcode: 'CT19',
    uk_region: 'South East',
    postal_town: 'Folkestone',
  },
  {
    postcode: 'CT20',
    uk_region: 'South East',
    postal_town: 'Folkestone',
  },
  {
    postcode: 'CT21',
    uk_region: 'South East',
    postal_town: 'Hythe',
  },
  {
    postcode: 'CT50',
    uk_region: 'South East',
    postal_town: 'Folkestone',
  },
  {
    postcode: 'CV1',
    uk_region: 'West Midlands',
    postal_town: 'Coventry',
  },
  {
    postcode: 'CV2',
    uk_region: 'West Midlands',
    postal_town: 'Coventry',
  },
  {
    postcode: 'CV3',
    uk_region: 'West Midlands',
    postal_town: 'Coventry',
  },
  {
    postcode: 'CV4',
    uk_region: 'West Midlands',
    postal_town: 'Coventry',
  },
  {
    postcode: 'CV5',
    uk_region: 'West Midlands',
    postal_town: 'Coventry',
  },
  {
    postcode: 'CV6',
    uk_region: 'West Midlands',
    postal_town: 'Coventry',
  },
  {
    postcode: 'CV7',
    uk_region: 'West Midlands',
    postal_town: 'Coventry',
  },
  {
    postcode: 'CV8',
    uk_region: 'West Midlands',
    postal_town: '"Coventry, Kenilworth"',
  },
  {
    postcode: 'CV9',
    uk_region: 'West Midlands',
    postal_town: 'Atherstone',
  },
  {
    postcode: 'CV10',
    uk_region: 'West Midlands',
    postal_town: 'Nuneaton',
  },
  {
    postcode: 'CV11',
    uk_region: 'West Midlands',
    postal_town: 'Nuneaton',
  },
  {
    postcode: 'CV12',
    uk_region: 'West Midlands',
    postal_town: 'Bedworth',
  },
  {
    postcode: 'CV13',
    uk_region: 'East Midlands',
    postal_town: 'Nuneaton',
  },
  {
    postcode: 'CV21',
    uk_region: 'West Midlands',
    postal_town: 'Rugby',
  },
  {
    postcode: 'CV22',
    uk_region: 'West Midlands',
    postal_town: 'Rugby',
  },
  {
    postcode: 'CV23',
    uk_region: 'West Midlands',
    postal_town: 'Rugby',
  },
  {
    postcode: 'CV31',
    uk_region: 'West Midlands',
    postal_town: 'Leamington Spa',
  },
  {
    postcode: 'CV32',
    uk_region: 'West Midlands',
    postal_town: 'Leamington Spa',
  },
  {
    postcode: 'CV33',
    uk_region: 'West Midlands',
    postal_town: 'Leamington Spa',
  },
  {
    postcode: 'CV34',
    uk_region: 'West Midlands',
    postal_town: 'Warwick',
  },
  {
    postcode: 'CV35',
    uk_region: 'West Midlands',
    postal_town: 'Warwick',
  },
  {
    postcode: 'CV36',
    uk_region: 'West Midlands',
    postal_town: 'Shipston-on-Stour',
  },
  {
    postcode: 'CV37',
    uk_region: 'West Midlands',
    postal_town: '"Shipston-on-Stour, Stratford-upon-Avon"',
  },
  {
    postcode: 'CV47',
    uk_region: 'West Midlands',
    postal_town: 'Southam',
  },
  {
    postcode: 'CW1',
    uk_region: 'North West',
    postal_town: 'Crewe',
  },
  {
    postcode: 'CW2',
    uk_region: 'North West',
    postal_town: 'Crewe',
  },
  {
    postcode: 'CW3',
    uk_region: 'West Midlands',
    postal_town: 'Crewe',
  },
  {
    postcode: 'CW4',
    uk_region: 'North West',
    postal_town: 'Crewe',
  },
  {
    postcode: 'CW5',
    uk_region: 'North West',
    postal_town: 'Nantwich',
  },
  {
    postcode: 'CW6',
    uk_region: 'North West',
    postal_town: 'Tarporley',
  },
  {
    postcode: 'CW7',
    uk_region: 'North West',
    postal_town: 'Winsford',
  },
  {
    postcode: 'CW8',
    uk_region: 'North West',
    postal_town: 'Northwich',
  },
  {
    postcode: 'CW9',
    uk_region: 'North West',
    postal_town: 'Northwich',
  },
  {
    postcode: 'CW10',
    uk_region: 'North West',
    postal_town: 'Middlewich',
  },
  {
    postcode: 'CW11',
    uk_region: 'North West',
    postal_town: 'Sandbach',
  },
  {
    postcode: 'CW12',
    uk_region: 'North West',
    postal_town: 'Congleton',
  },
  {
    postcode: 'CW98',
    uk_region: 'North West',
    postal_town: 'Crewe',
  },
  {
    postcode: 'DA1',
    uk_region: 'South East',
    postal_town: 'Dartford',
  },
  {
    postcode: 'DA2',
    uk_region: 'South East',
    postal_town: 'Dartford',
  },
  {
    postcode: 'DA3',
    uk_region: 'South East',
    postal_town: 'Longfield',
  },
  {
    postcode: 'DA4',
    uk_region: 'South East',
    postal_town: 'Dartford',
  },
  {
    postcode: 'DA5',
    uk_region: 'London',
    postal_town: 'Bexley',
  },
  {
    postcode: 'DA6',
    uk_region: 'London',
    postal_town: 'Bexleyheath',
  },
  {
    postcode: 'DA7',
    uk_region: 'London',
    postal_town: '"Bexleyheath, Welling"',
  },
  {
    postcode: 'DA8',
    uk_region: 'London',
    postal_town: 'Erith',
  },
  {
    postcode: 'DA9',
    uk_region: 'South East',
    postal_town: 'Greenhithe',
  },
  {
    postcode: 'DA10',
    uk_region: 'South East',
    postal_town: '"Dartford, Swanscombe"',
  },
  {
    postcode: 'DA11',
    uk_region: 'South East',
    postal_town: 'Gravesend',
  },
  {
    postcode: 'DA12',
    uk_region: 'South East',
    postal_town: 'Gravesend',
  },
  {
    postcode: 'DA13',
    uk_region: 'South East',
    postal_town: 'Gravesend',
  },
  {
    postcode: 'DA14',
    uk_region: 'London',
    postal_town: 'Sidcup',
  },
  {
    postcode: 'DA15',
    uk_region: 'London',
    postal_town: 'Sidcup',
  },
  {
    postcode: 'DA16',
    uk_region: 'London',
    postal_town: 'Welling',
  },
  {
    postcode: 'DA17',
    uk_region: 'London',
    postal_town: 'Belvedere',
  },
  {
    postcode: 'DA18',
    uk_region: 'London',
    postal_town: 'Erith',
  },
  {
    postcode: 'DD1',
    uk_region: 'Scotland',
    postal_town: 'Dundee',
  },
  {
    postcode: 'DD2',
    uk_region: 'Scotland',
    postal_town: 'Dundee',
  },
  {
    postcode: 'DD3',
    uk_region: 'Scotland',
    postal_town: 'Dundee',
  },
  {
    postcode: 'DD4',
    uk_region: 'Scotland',
    postal_town: 'Dundee',
  },
  {
    postcode: 'DD5',
    uk_region: 'Scotland',
    postal_town: 'Dundee',
  },
  {
    postcode: 'DD6',
    uk_region: 'Scotland',
    postal_town: '"Newport-on-Tay, Tayport"',
  },
  {
    postcode: 'DD7',
    uk_region: 'Scotland',
    postal_town: 'Carnoustie',
  },
  {
    postcode: 'DD8',
    uk_region: 'Scotland',
    postal_town: '"Forfar, Kirriemuir"',
  },
  {
    postcode: 'DD9',
    uk_region: 'Scotland',
    postal_town: 'Brechin',
  },
  {
    postcode: 'DD10',
    uk_region: 'Scotland',
    postal_town: 'Montrose',
  },
  {
    postcode: 'DD11',
    uk_region: 'Scotland',
    postal_town: 'Arbroath',
  },
  {
    postcode: 'DE1',
    uk_region: 'East Midlands',
    postal_town: 'Derby',
  },
  {
    postcode: 'DE2',
    uk_region: 'East Midlands',
    postal_town: '',
  },
  {
    postcode: 'DE3',
    uk_region: 'East Midlands',
    postal_town: 'Derby',
  },
  {
    postcode: 'DE4',
    uk_region: 'East Midlands',
    postal_town: 'Matlock',
  },
  {
    postcode: 'DE5',
    uk_region: 'East Midlands',
    postal_town: 'Ripley',
  },
  {
    postcode: 'DE6',
    uk_region: 'East Midlands',
    postal_town: 'Ashbourne',
  },
  {
    postcode: 'DE7',
    uk_region: 'East Midlands',
    postal_town: 'Ilkeston',
  },
  {
    postcode: 'DE11',
    uk_region: 'East Midlands',
    postal_town: 'Swadlincote',
  },
  {
    postcode: 'DE12',
    uk_region: 'East Midlands',
    postal_town: 'Swadlincote',
  },
  {
    postcode: 'DE13',
    uk_region: 'West Midlands',
    postal_town: 'Burton-on-Trent',
  },
  {
    postcode: 'DE14',
    uk_region: 'West Midlands',
    postal_town: 'Burton-on-Trent',
  },
  {
    postcode: 'DE15',
    uk_region: 'West Midlands',
    postal_town: 'Burton-on-Trent',
  },
  {
    postcode: 'DE21',
    uk_region: 'East Midlands',
    postal_town: 'Derby',
  },
  {
    postcode: 'DE22',
    uk_region: 'East Midlands',
    postal_town: 'Derby',
  },
  {
    postcode: 'DE23',
    uk_region: 'East Midlands',
    postal_town: 'Derby',
  },
  {
    postcode: 'DE24',
    uk_region: 'East Midlands',
    postal_town: 'Derby',
  },
  {
    postcode: 'DE45',
    uk_region: 'East Midlands',
    postal_town: 'Bakewell',
  },
  {
    postcode: 'DE55',
    uk_region: 'East Midlands',
    postal_town: 'Alfreton',
  },
  {
    postcode: 'DE56',
    uk_region: 'East Midlands',
    postal_town: 'Belper',
  },
  {
    postcode: 'DE65',
    uk_region: 'East Midlands',
    postal_town: 'Derby',
  },
  {
    postcode: 'DE72',
    uk_region: 'East Midlands',
    postal_town: 'Derby',
  },
  {
    postcode: 'DE73',
    uk_region: 'East Midlands',
    postal_town: 'Derby',
  },
  {
    postcode: 'DE74',
    uk_region: 'East Midlands',
    postal_town: 'Derby',
  },
  {
    postcode: 'DE75',
    uk_region: 'East Midlands',
    postal_town: 'Heanor',
  },
  {
    postcode: 'DE99',
    uk_region: 'East Midlands',
    postal_town: 'Derby',
  },
  {
    postcode: 'DG1',
    uk_region: 'Scotland',
    postal_town: 'Dumfries',
  },
  {
    postcode: 'DG2',
    uk_region: 'Scotland',
    postal_town: 'Dumfries',
  },
  {
    postcode: 'DG3',
    uk_region: 'Scotland',
    postal_town: 'Thornhill',
  },
  {
    postcode: 'DG4',
    uk_region: 'Scotland',
    postal_town: 'Sanquhar',
  },
  {
    postcode: 'DG5',
    uk_region: 'Scotland',
    postal_town: 'Dalbeattie',
  },
  {
    postcode: 'DG6',
    uk_region: 'Scotland',
    postal_town: 'Kirkcudbright',
  },
  {
    postcode: 'DG7',
    uk_region: 'Scotland',
    postal_town: 'Castle Douglas',
  },
  {
    postcode: 'DG8',
    uk_region: 'Scotland',
    postal_town: 'Newton Stewart',
  },
  {
    postcode: 'DG9',
    uk_region: 'Scotland',
    postal_town: 'Stranraer',
  },
  {
    postcode: 'DG10',
    uk_region: 'Scotland',
    postal_town: 'Moffat',
  },
  {
    postcode: 'DG11',
    uk_region: 'Scotland',
    postal_town: 'Lockerbie',
  },
  {
    postcode: 'DG12',
    uk_region: 'Scotland',
    postal_town: 'Annan',
  },
  {
    postcode: 'DG13',
    uk_region: 'Scotland',
    postal_town: 'Langholm',
  },
  {
    postcode: 'DG14',
    uk_region: 'Scotland',
    postal_town: 'Canonbie',
  },
  {
    postcode: 'DG16',
    uk_region: 'Scotland',
    postal_town: 'Gretna',
  },
  {
    postcode: 'DH1',
    uk_region: 'North East',
    postal_town: 'Durham',
  },
  {
    postcode: 'DH2',
    uk_region: 'North East',
    postal_town: 'Chester le Street',
  },
  {
    postcode: 'DH3',
    uk_region: 'North East',
    postal_town: 'Chester le Street',
  },
  {
    postcode: 'DH4',
    uk_region: 'North East',
    postal_town: 'Houghton le Spring',
  },
  {
    postcode: 'DH5',
    uk_region: 'North East',
    postal_town: 'Houghton le Spring',
  },
  {
    postcode: 'DH6',
    uk_region: 'North East',
    postal_town: 'Durham',
  },
  {
    postcode: 'DH7',
    uk_region: 'North East',
    postal_town: 'Durham',
  },
  {
    postcode: 'DH8',
    uk_region: 'North East',
    postal_town: '"Durham, Stanley, Consett"',
  },
  {
    postcode: 'DH9',
    uk_region: 'North East',
    postal_town: 'Stanley',
  },
  {
    postcode: 'DH97',
    uk_region: 'North East',
    postal_town: 'Durham',
  },
  {
    postcode: 'DH98',
    uk_region: 'North East',
    postal_town: 'Durham',
  },
  {
    postcode: 'DH99',
    uk_region: 'North East',
    postal_town: 'Durham',
  },
  {
    postcode: 'DL1',
    uk_region: 'North East',
    postal_town: 'Darlington',
  },
  {
    postcode: 'DL2',
    uk_region: 'North East',
    postal_town: 'Darlington',
  },
  {
    postcode: 'DL3',
    uk_region: 'North East',
    postal_town: 'Darlington',
  },
  {
    postcode: 'DL4',
    uk_region: 'North East',
    postal_town: 'Shildon',
  },
  {
    postcode: 'DL5',
    uk_region: 'North East',
    postal_town: 'Newton Aycliffe',
  },
  {
    postcode: 'DL6',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Northallerton',
  },
  {
    postcode: 'DL7',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Northallerton',
  },
  {
    postcode: 'DL8',
    uk_region: 'Yorkshire and The Humber',
    postal_town: '"Bedale, Hawes, Leyburn"',
  },
  {
    postcode: 'DL9',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Catterick Garrison',
  },
  {
    postcode: 'DL10',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Richmond',
  },
  {
    postcode: 'DL11',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Richmond',
  },
  {
    postcode: 'DL12',
    uk_region: 'North East',
    postal_town: 'Barnard Castle',
  },
  {
    postcode: 'DL13',
    uk_region: 'North East',
    postal_town: 'Bishop Auckland',
  },
  {
    postcode: 'DL14',
    uk_region: 'North East',
    postal_town: 'Bishop Auckland',
  },
  {
    postcode: 'DL15',
    uk_region: 'North East',
    postal_town: 'Crook',
  },
  {
    postcode: 'DL16',
    uk_region: 'North East',
    postal_town: '"Spennymoor, Ferryhill"',
  },
  {
    postcode: 'DL17',
    uk_region: 'North East',
    postal_town: 'Ferryhill',
  },
  {
    postcode: 'DL98',
    uk_region: 'North East',
    postal_town: 'Darlington',
  },
  {
    postcode: 'DN1',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Doncaster',
  },
  {
    postcode: 'DN2',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Doncaster',
  },
  {
    postcode: 'DN3',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Doncaster',
  },
  {
    postcode: 'DN4',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Doncaster',
  },
  {
    postcode: 'DN5',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Doncaster',
  },
  {
    postcode: 'DN6',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Doncaster',
  },
  {
    postcode: 'DN7',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Doncaster',
  },
  {
    postcode: 'DN8',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Doncaster',
  },
  {
    postcode: 'DN9',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Doncaster',
  },
  {
    postcode: 'DN10',
    uk_region: 'East Midlands',
    postal_town: 'Doncaster',
  },
  {
    postcode: 'DN11',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Doncaster',
  },
  {
    postcode: 'DN12',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Doncaster',
  },
  {
    postcode: 'DN14',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Goole',
  },
  {
    postcode: 'DN15',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Scunthorpe',
  },
  {
    postcode: 'DN16',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Scunthorpe',
  },
  {
    postcode: 'DN17',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Scunthorpe',
  },
  {
    postcode: 'DN18',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Barton-upon-Humber',
  },
  {
    postcode: 'DN19',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Barrow-upon-Humber',
  },
  {
    postcode: 'DN20',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Brigg',
  },
  {
    postcode: 'DN21',
    uk_region: 'East Midlands',
    postal_town: 'Gainsborough',
  },
  {
    postcode: 'DN22',
    uk_region: 'East Midlands',
    postal_town: 'Retford',
  },
  {
    postcode: 'DN31',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Grimsby',
  },
  {
    postcode: 'DN32',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Grimsby',
  },
  {
    postcode: 'DN33',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Grimsby',
  },
  {
    postcode: 'DN34',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Grimsby',
  },
  {
    postcode: 'DN35',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Cleethorpes',
  },
  {
    postcode: 'DN36',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Grimsby',
  },
  {
    postcode: 'DN37',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Grimsby',
  },
  {
    postcode: 'DN38',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Barnetby',
  },
  {
    postcode: 'DN39',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Ulceby',
  },
  {
    postcode: 'DN40',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Immingham',
  },
  {
    postcode: 'DN41',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Grimsby',
  },
  {
    postcode: 'DN55',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Doncaster',
  },
  {
    postcode: 'DT1',
    uk_region: 'South West',
    postal_town: 'Dorchester',
  },
  {
    postcode: 'DT2',
    uk_region: 'South West',
    postal_town: 'Dorchester',
  },
  {
    postcode: 'DT3',
    uk_region: 'South West',
    postal_town: 'Weymouth',
  },
  {
    postcode: 'DT4',
    uk_region: 'South West',
    postal_town: 'Weymouth',
  },
  {
    postcode: 'DT5',
    uk_region: 'South West',
    postal_town: 'Portland',
  },
  {
    postcode: 'DT6',
    uk_region: 'South West',
    postal_town: 'Bridport',
  },
  {
    postcode: 'DT7',
    uk_region: 'South West',
    postal_town: 'Lyme Regis',
  },
  {
    postcode: 'DT8',
    uk_region: 'South West',
    postal_town: 'Beaminster',
  },
  {
    postcode: 'DT9',
    uk_region: 'South West',
    postal_town: 'Sherborne',
  },
  {
    postcode: 'DT10',
    uk_region: 'South West',
    postal_town: 'Sturminster Newton',
  },
  {
    postcode: 'DT11',
    uk_region: 'South West',
    postal_town: 'Blandford Forum',
  },
  {
    postcode: 'DY1',
    uk_region: 'West Midlands',
    postal_town: 'Dudley',
  },
  {
    postcode: 'DY2',
    uk_region: 'West Midlands',
    postal_town: 'Dudley',
  },
  {
    postcode: 'DY3',
    uk_region: 'West Midlands',
    postal_town: 'Dudley',
  },
  {
    postcode: 'DY4',
    uk_region: 'West Midlands',
    postal_town: 'Tipton',
  },
  {
    postcode: 'DY5',
    uk_region: 'West Midlands',
    postal_town: 'Brierley Hill',
  },
  {
    postcode: 'DY6',
    uk_region: 'West Midlands',
    postal_town: 'Kingswinford',
  },
  {
    postcode: 'DY7',
    uk_region: 'West Midlands',
    postal_town: 'Stourbridge',
  },
  {
    postcode: 'DY8',
    uk_region: 'West Midlands',
    postal_town: 'Stourbridge',
  },
  {
    postcode: 'DY9',
    uk_region: 'West Midlands',
    postal_town: 'Stourbridge',
  },
  {
    postcode: 'DY10',
    uk_region: 'West Midlands',
    postal_town: 'Kidderminster',
  },
  {
    postcode: 'DY11',
    uk_region: 'West Midlands',
    postal_town: 'Kidderminster',
  },
  {
    postcode: 'DY12',
    uk_region: 'West Midlands',
    postal_town: 'Bewdley',
  },
  {
    postcode: 'DY13',
    uk_region: 'West Midlands',
    postal_town: 'Stourport-on-Severn',
  },
  {
    postcode: 'DY14',
    uk_region: 'West Midlands',
    postal_town: 'Kidderminster',
  },
  {
    postcode: 'E1',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'E1W',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'E2',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'E3',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'E4',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'E5',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'E6',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'E7',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'E8',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'E9',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'E10',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'E11',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'E12',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'E13',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'E14',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'E15',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'E16',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'E17',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'E18',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'E20',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'E22',
    uk_region: '',
    postal_town: '',
  },
  {
    postcode: 'E77',
    uk_region: 'London',
    postal_town: '',
  },
  {
    postcode: 'E98',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'EC1',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'EC1A',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'EC1M',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'EC1N',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'EC1P',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'EC1R',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'EC1V',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'EC1Y',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'EC2',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'EC2A',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'EC2M',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'EC2N',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'EC2P',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'EC2R',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'EC2V',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'EC2Y',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'EC3',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'EC3A',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'EC3B',
    uk_region: 'London',
    postal_town: '',
  },
  {
    postcode: 'EC3M',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'EC3N',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'EC3P',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'EC3R',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'EC3V',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'EC4',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'EC4A',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'EC4M',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'EC4N',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'EC4P',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'EC4R',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'EC4V',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'EC4Y',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'EC50',
    uk_region: 'London',
    postal_town: '',
  },
  {
    postcode: 'EC88',
    uk_region: 'London',
    postal_town: '',
  },
  {
    postcode: 'EH1',
    uk_region: 'Scotland',
    postal_town: 'Edinburgh',
  },
  {
    postcode: 'EH2',
    uk_region: 'Scotland',
    postal_town: 'Edinburgh',
  },
  {
    postcode: 'EH3',
    uk_region: 'Scotland',
    postal_town: 'Edinburgh',
  },
  {
    postcode: 'EH4',
    uk_region: 'Scotland',
    postal_town: 'Edinburgh',
  },
  {
    postcode: 'EH5',
    uk_region: 'Scotland',
    postal_town: 'Edinburgh',
  },
  {
    postcode: 'EH6',
    uk_region: 'Scotland',
    postal_town: 'Edinburgh',
  },
  {
    postcode: 'EH7',
    uk_region: 'Scotland',
    postal_town: 'Edinburgh',
  },
  {
    postcode: 'EH8',
    uk_region: 'Scotland',
    postal_town: 'Edinburgh',
  },
  {
    postcode: 'EH9',
    uk_region: 'Scotland',
    postal_town: 'Edinburgh',
  },
  {
    postcode: 'EH10',
    uk_region: 'Scotland',
    postal_town: 'Edinburgh',
  },
  {
    postcode: 'EH11',
    uk_region: 'Scotland',
    postal_town: 'Edinburgh',
  },
  {
    postcode: 'EH12',
    uk_region: 'Scotland',
    postal_town: 'Edinburgh',
  },
  {
    postcode: 'EH13',
    uk_region: 'Scotland',
    postal_town: 'Edinburgh',
  },
  {
    postcode: 'EH14',
    uk_region: 'Scotland',
    postal_town: '"Edinburgh, Balerno, Currie, Juniper Green"',
  },
  {
    postcode: 'EH15',
    uk_region: 'Scotland',
    postal_town: 'Edinburgh',
  },
  {
    postcode: 'EH16',
    uk_region: 'Scotland',
    postal_town: 'Edinburgh',
  },
  {
    postcode: 'EH17',
    uk_region: 'Scotland',
    postal_town: 'Edinburgh',
  },
  {
    postcode: 'EH18',
    uk_region: 'Scotland',
    postal_town: 'Lasswade',
  },
  {
    postcode: 'EH19',
    uk_region: 'Scotland',
    postal_town: 'Bonnyrigg',
  },
  {
    postcode: 'EH20',
    uk_region: 'Scotland',
    postal_town: 'Loanhead',
  },
  {
    postcode: 'EH21',
    uk_region: 'Scotland',
    postal_town: 'Musselburgh',
  },
  {
    postcode: 'EH22',
    uk_region: 'Scotland',
    postal_town: 'Dalkeith',
  },
  {
    postcode: 'EH23',
    uk_region: 'Scotland',
    postal_town: 'Gorebridge',
  },
  {
    postcode: 'EH24',
    uk_region: 'Scotland',
    postal_town: 'Rosewell',
  },
  {
    postcode: 'EH25',
    uk_region: 'Scotland',
    postal_town: 'Roslin',
  },
  {
    postcode: 'EH26',
    uk_region: 'Scotland',
    postal_town: 'Penicuik',
  },
  {
    postcode: 'EH27',
    uk_region: 'Scotland',
    postal_town: 'Kirknewton',
  },
  {
    postcode: 'EH28',
    uk_region: 'Scotland',
    postal_town: 'Newbridge',
  },
  {
    postcode: 'EH29',
    uk_region: 'Scotland',
    postal_town: 'Kirkliston',
  },
  {
    postcode: 'EH30',
    uk_region: 'Scotland',
    postal_town: 'South Queensferry',
  },
  {
    postcode: 'EH31',
    uk_region: 'Scotland',
    postal_town: 'Gullane',
  },
  {
    postcode: 'EH32',
    uk_region: 'Scotland',
    postal_town: '"Longniddry, Prestonpans"',
  },
  {
    postcode: 'EH33',
    uk_region: 'Scotland',
    postal_town: 'Tranent',
  },
  {
    postcode: 'EH34',
    uk_region: 'Scotland',
    postal_town: 'Tranent',
  },
  {
    postcode: 'EH35',
    uk_region: 'Scotland',
    postal_town: 'Tranent',
  },
  {
    postcode: 'EH36',
    uk_region: 'Scotland',
    postal_town: 'Humbie',
  },
  {
    postcode: 'EH37',
    uk_region: 'Scotland',
    postal_town: 'Pathhead',
  },
  {
    postcode: 'EH38',
    uk_region: 'Scotland',
    postal_town: 'Heriot',
  },
  {
    postcode: 'EH39',
    uk_region: 'Scotland',
    postal_town: 'North Berwick',
  },
  {
    postcode: 'EH40',
    uk_region: 'Scotland',
    postal_town: 'East Linton',
  },
  {
    postcode: 'EH41',
    uk_region: 'Scotland',
    postal_town: 'Haddington',
  },
  {
    postcode: 'EH42',
    uk_region: 'Scotland',
    postal_town: 'Dunbar',
  },
  {
    postcode: 'EH43',
    uk_region: 'Scotland',
    postal_town: 'Walkerburn',
  },
  {
    postcode: 'EH44',
    uk_region: 'Scotland',
    postal_town: 'Innerleithen',
  },
  {
    postcode: 'EH45',
    uk_region: 'Scotland',
    postal_town: 'Peebles',
  },
  {
    postcode: 'EH46',
    uk_region: 'Scotland',
    postal_town: 'West Linton',
  },
  {
    postcode: 'EH47',
    uk_region: 'Scotland',
    postal_town: 'Bathgate',
  },
  {
    postcode: 'EH48',
    uk_region: 'Scotland',
    postal_town: 'Bathgate',
  },
  {
    postcode: 'EH49',
    uk_region: 'Scotland',
    postal_town: 'Linlithgow',
  },
  {
    postcode: 'EH51',
    uk_region: 'Scotland',
    postal_town: "Bo'ness",
  },
  {
    postcode: 'EH52',
    uk_region: 'Scotland',
    postal_town: 'Broxburn',
  },
  {
    postcode: 'EH53',
    uk_region: 'Scotland',
    postal_town: 'Livingston',
  },
  {
    postcode: 'EH54',
    uk_region: 'Scotland',
    postal_town: 'Livingston',
  },
  {
    postcode: 'EH55',
    uk_region: 'Scotland',
    postal_town: 'West Calder',
  },
  {
    postcode: 'EH77',
    uk_region: 'Scotland',
    postal_town: '',
  },
  {
    postcode: 'EH91',
    uk_region: 'Scotland',
    postal_town: 'Edinburgh',
  },
  {
    postcode: 'EH95',
    uk_region: 'Scotland',
    postal_town: 'Edinburgh',
  },
  {
    postcode: 'EH99',
    uk_region: 'Scotland',
    postal_town: 'Edinburgh',
  },
  {
    postcode: 'EN1',
    uk_region: 'London',
    postal_town: 'Enfield',
  },
  {
    postcode: 'EN2',
    uk_region: 'London',
    postal_town: 'Enfield',
  },
  {
    postcode: 'EN3',
    uk_region: 'London',
    postal_town: 'Enfield',
  },
  {
    postcode: 'EN4',
    uk_region: 'London',
    postal_town: 'Barnet',
  },
  {
    postcode: 'EN5',
    uk_region: 'London',
    postal_town: 'Barnet',
  },
  {
    postcode: 'EN6',
    uk_region: 'East of England',
    postal_town: 'Potters Bar',
  },
  {
    postcode: 'EN7',
    uk_region: 'East of England',
    postal_town: 'Waltham Cross',
  },
  {
    postcode: 'EN8',
    uk_region: 'East of England',
    postal_town: 'Waltham Cross',
  },
  {
    postcode: 'EN9',
    uk_region: 'East of England',
    postal_town: 'Waltham Abbey',
  },
  {
    postcode: 'EN10',
    uk_region: 'East of England',
    postal_town: 'Broxbourne',
  },
  {
    postcode: 'EN11',
    uk_region: 'East of England',
    postal_town: '"Broxbourne, Hoddesdon"',
  },
  {
    postcode: 'EN77',
    uk_region: '',
    postal_town: 'Waltham Cross',
  },
  {
    postcode: 'EX1',
    uk_region: 'South West',
    postal_town: 'Exeter',
  },
  {
    postcode: 'EX2',
    uk_region: 'South West',
    postal_town: 'Exeter',
  },
  {
    postcode: 'EX3',
    uk_region: 'South West',
    postal_town: 'Exeter',
  },
  {
    postcode: 'EX4',
    uk_region: 'South West',
    postal_town: 'Exeter',
  },
  {
    postcode: 'EX5',
    uk_region: 'South West',
    postal_town: 'Exeter',
  },
  {
    postcode: 'EX6',
    uk_region: 'South West',
    postal_town: 'Exeter',
  },
  {
    postcode: 'EX7',
    uk_region: 'South West',
    postal_town: 'Dawlish',
  },
  {
    postcode: 'EX8',
    uk_region: 'South West',
    postal_town: 'Exmouth',
  },
  {
    postcode: 'EX9',
    uk_region: 'South West',
    postal_town: 'Budleigh Salterton',
  },
  {
    postcode: 'EX10',
    uk_region: 'South West',
    postal_town: 'Sidmouth',
  },
  {
    postcode: 'EX11',
    uk_region: 'South West',
    postal_town: 'Ottery St. Mary',
  },
  {
    postcode: 'EX12',
    uk_region: 'South West',
    postal_town: 'Seaton',
  },
  {
    postcode: 'EX13',
    uk_region: 'South West',
    postal_town: 'Axminster',
  },
  {
    postcode: 'EX14',
    uk_region: 'South West',
    postal_town: 'Honiton',
  },
  {
    postcode: 'EX15',
    uk_region: 'South West',
    postal_town: 'Cullompton',
  },
  {
    postcode: 'EX16',
    uk_region: 'South West',
    postal_town: 'Tiverton',
  },
  {
    postcode: 'EX17',
    uk_region: 'South West',
    postal_town: 'Crediton',
  },
  {
    postcode: 'EX18',
    uk_region: 'South West',
    postal_town: 'Chulmleigh',
  },
  {
    postcode: 'EX19',
    uk_region: 'South West',
    postal_town: 'Winkleigh',
  },
  {
    postcode: 'EX20',
    uk_region: 'South West',
    postal_town: '"North Tawton, Okehampton"',
  },
  {
    postcode: 'EX21',
    uk_region: 'South West',
    postal_town: 'Beaworthy',
  },
  {
    postcode: 'EX22',
    uk_region: 'South West',
    postal_town: 'Holsworthy',
  },
  {
    postcode: 'EX23',
    uk_region: 'South West',
    postal_town: 'Bude',
  },
  {
    postcode: 'EX24',
    uk_region: 'South West',
    postal_town: 'Colyton',
  },
  {
    postcode: 'EX31',
    uk_region: 'South West',
    postal_town: 'Barnstaple',
  },
  {
    postcode: 'EX32',
    uk_region: 'South West',
    postal_town: 'Barnstaple',
  },
  {
    postcode: 'EX33',
    uk_region: 'South West',
    postal_town: 'Braunton',
  },
  {
    postcode: 'EX34',
    uk_region: 'South West',
    postal_town: '"Ilfracombe, Woolacombe"',
  },
  {
    postcode: 'EX35',
    uk_region: 'South West',
    postal_town: '"Lynmouth, Lynton"',
  },
  {
    postcode: 'EX36',
    uk_region: 'South West',
    postal_town: 'South Molton',
  },
  {
    postcode: 'EX37',
    uk_region: 'South West',
    postal_town: 'Umberleigh',
  },
  {
    postcode: 'EX38',
    uk_region: 'South West',
    postal_town: 'Torrington',
  },
  {
    postcode: 'EX39',
    uk_region: 'South West',
    postal_town: 'Bideford',
  },
  {
    postcode: 'FK1',
    uk_region: 'Scotland',
    postal_town: 'Falkirk',
  },
  {
    postcode: 'FK2',
    uk_region: 'Scotland',
    postal_town: 'Falkirk',
  },
  {
    postcode: 'FK3',
    uk_region: 'Scotland',
    postal_town: 'Grangemouth',
  },
  {
    postcode: 'FK4',
    uk_region: 'Scotland',
    postal_town: 'Bonnybridge',
  },
  {
    postcode: 'FK5',
    uk_region: 'Scotland',
    postal_town: 'Larbert',
  },
  {
    postcode: 'FK6',
    uk_region: 'Scotland',
    postal_town: 'Denny',
  },
  {
    postcode: 'FK7',
    uk_region: 'Scotland',
    postal_town: 'Stirling',
  },
  {
    postcode: 'FK8',
    uk_region: 'Scotland',
    postal_town: 'Stirling',
  },
  {
    postcode: 'FK9',
    uk_region: 'Scotland',
    postal_town: 'Stirling',
  },
  {
    postcode: 'FK10',
    uk_region: 'Scotland',
    postal_town: '"Alloa, Clackmannan"',
  },
  {
    postcode: 'FK11',
    uk_region: 'Scotland',
    postal_town: 'Menstrie',
  },
  {
    postcode: 'FK12',
    uk_region: 'Scotland',
    postal_town: 'Alva',
  },
  {
    postcode: 'FK13',
    uk_region: 'Scotland',
    postal_town: 'Tillicoultry',
  },
  {
    postcode: 'FK14',
    uk_region: 'Scotland',
    postal_town: 'Dollar',
  },
  {
    postcode: 'FK15',
    uk_region: 'Scotland',
    postal_town: 'Dunblane',
  },
  {
    postcode: 'FK16',
    uk_region: 'Scotland',
    postal_town: 'Doune',
  },
  {
    postcode: 'FK17',
    uk_region: 'Scotland',
    postal_town: 'Callander',
  },
  {
    postcode: 'FK18',
    uk_region: 'Scotland',
    postal_town: 'Callander',
  },
  {
    postcode: 'FK19',
    uk_region: 'Scotland',
    postal_town: 'Lochearnhead',
  },
  {
    postcode: 'FK20',
    uk_region: 'Scotland',
    postal_town: 'Crianlarich',
  },
  {
    postcode: 'FK21',
    uk_region: 'Scotland',
    postal_town: 'Killin',
  },
  {
    postcode: 'FY0',
    uk_region: 'North West',
    postal_town: 'Blackpool',
  },
  {
    postcode: 'FY1',
    uk_region: 'North West',
    postal_town: 'Blackpool',
  },
  {
    postcode: 'FY2',
    uk_region: 'North West',
    postal_town: 'Blackpool',
  },
  {
    postcode: 'FY3',
    uk_region: 'North West',
    postal_town: 'Blackpool',
  },
  {
    postcode: 'FY4',
    uk_region: 'North West',
    postal_town: 'Blackpool',
  },
  {
    postcode: 'FY5',
    uk_region: 'North West',
    postal_town: 'Thornton-Cleveleys',
  },
  {
    postcode: 'FY6',
    uk_region: 'North West',
    postal_town: 'Poulton-le-Fylde',
  },
  {
    postcode: 'FY7',
    uk_region: 'North West',
    postal_town: 'Fleetwood',
  },
  {
    postcode: 'FY8',
    uk_region: 'North West',
    postal_town: 'Lytham St. Annes',
  },
  {
    postcode: 'G1',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G2',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G3',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G4',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G5',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G9',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G11',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G12',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G13',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G14',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G15',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G20',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G21',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G22',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G23',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G31',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G32',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G33',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G34',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G40',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G41',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G42',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G43',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G44',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G45',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G46',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G51',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G52',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G53',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G58',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G59',
    uk_region: 'Scotland',
    postal_town: '',
  },
  {
    postcode: 'G60',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G61',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G62',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G63',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G64',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G65',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G66',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G67',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G68',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G69',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G70',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G71',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G72',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G73',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G74',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G75',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G76',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G77',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G78',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G79',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'G80',
    uk_region: 'Scotland',
    postal_town: '',
  },
  {
    postcode: 'G81',
    uk_region: 'Scotland',
    postal_town: 'Clydebank',
  },
  {
    postcode: 'G82',
    uk_region: 'Scotland',
    postal_town: 'Dumbarton',
  },
  {
    postcode: 'G83',
    uk_region: 'Scotland',
    postal_town: '"Alexandria, Arrochar"',
  },
  {
    postcode: 'G84',
    uk_region: 'Scotland',
    postal_town: 'Helensburgh',
  },
  {
    postcode: 'G90',
    uk_region: 'Scotland',
    postal_town: 'Glasgow',
  },
  {
    postcode: 'GIR',
    uk_region: '',
    postal_town: 'Bootle',
  },
  {
    postcode: 'GL1',
    uk_region: 'South West',
    postal_town: 'Gloucester',
  },
  {
    postcode: 'GL2',
    uk_region: 'South West',
    postal_town: 'Gloucester',
  },
  {
    postcode: 'GL3',
    uk_region: 'South West',
    postal_town: 'Gloucester',
  },
  {
    postcode: 'GL4',
    uk_region: 'South West',
    postal_town: 'Gloucester',
  },
  {
    postcode: 'GL5',
    uk_region: 'South West',
    postal_town: 'Stroud',
  },
  {
    postcode: 'GL6',
    uk_region: 'South West',
    postal_town: 'Stroud',
  },
  {
    postcode: 'GL7',
    uk_region: 'South West',
    postal_town: '"Cirencester, Fairford, Lechlade"',
  },
  {
    postcode: 'GL8',
    uk_region: 'South West',
    postal_town: 'Tetbury',
  },
  {
    postcode: 'GL9',
    uk_region: 'South West',
    postal_town: 'Badminton',
  },
  {
    postcode: 'GL10',
    uk_region: 'South West',
    postal_town: 'Stonehouse',
  },
  {
    postcode: 'GL11',
    uk_region: 'South West',
    postal_town: '"Dursley, Wotton-under-Edge"',
  },
  {
    postcode: 'GL12',
    uk_region: 'South West',
    postal_town: 'Wotton-under-Edge',
  },
  {
    postcode: 'GL13',
    uk_region: 'South West',
    postal_town: 'Berkeley',
  },
  {
    postcode: 'GL14',
    uk_region: 'South West',
    postal_town: '"Cinderford, Newnham, Westbury-on-Severn"',
  },
  {
    postcode: 'GL15',
    uk_region: 'South West',
    postal_town: '"Blakeney, Lydney"',
  },
  {
    postcode: 'GL16',
    uk_region: 'South West',
    postal_town: 'Coleford',
  },
  {
    postcode: 'GL17',
    uk_region: 'South West',
    postal_town: '"Drybrook, Longhope, Lydbrook, Mitcheldean, Ruardean"',
  },
  {
    postcode: 'GL18',
    uk_region: 'South West',
    postal_town: '"Dymock, Newent"',
  },
  {
    postcode: 'GL19',
    uk_region: 'South West',
    postal_town: 'Gloucester',
  },
  {
    postcode: 'GL20',
    uk_region: 'South West',
    postal_town: 'Tewkesbury',
  },
  {
    postcode: 'GL50',
    uk_region: 'South West',
    postal_town: 'Cheltenham',
  },
  {
    postcode: 'GL51',
    uk_region: 'South West',
    postal_town: 'Cheltenham',
  },
  {
    postcode: 'GL52',
    uk_region: 'South West',
    postal_town: 'Cheltenham',
  },
  {
    postcode: 'GL53',
    uk_region: 'South West',
    postal_town: 'Cheltenham',
  },
  {
    postcode: 'GL54',
    uk_region: 'South West',
    postal_town: 'Cheltenham',
  },
  {
    postcode: 'GL55',
    uk_region: 'South West',
    postal_town: 'Chipping Campden',
  },
  {
    postcode: 'GL56',
    uk_region: 'South West',
    postal_town: 'Moreton-in-Marsh',
  },
  {
    postcode: 'GU1',
    uk_region: 'South East',
    postal_town: 'Guildford',
  },
  {
    postcode: 'GU2',
    uk_region: 'South East',
    postal_town: 'Guildford',
  },
  {
    postcode: 'GU3',
    uk_region: 'South East',
    postal_town: 'Guildford',
  },
  {
    postcode: 'GU4',
    uk_region: 'South East',
    postal_town: 'Guildford',
  },
  {
    postcode: 'GU5',
    uk_region: 'South East',
    postal_town: 'Guildford',
  },
  {
    postcode: 'GU6',
    uk_region: 'South East',
    postal_town: 'Cranleigh',
  },
  {
    postcode: 'GU7',
    uk_region: 'South East',
    postal_town: 'Godalming',
  },
  {
    postcode: 'GU8',
    uk_region: 'South East',
    postal_town: 'Godalming',
  },
  {
    postcode: 'GU9',
    uk_region: 'South East',
    postal_town: 'Farnham',
  },
  {
    postcode: 'GU10',
    uk_region: 'South East',
    postal_town: 'Farnham',
  },
  {
    postcode: 'GU11',
    uk_region: 'South East',
    postal_town: 'Aldershot',
  },
  {
    postcode: 'GU12',
    uk_region: 'South East',
    postal_town: 'Aldershot',
  },
  {
    postcode: 'GU13',
    uk_region: 'South East',
    postal_town: '',
  },
  {
    postcode: 'GU14',
    uk_region: 'South East',
    postal_town: 'Farnborough',
  },
  {
    postcode: 'GU15',
    uk_region: 'South East',
    postal_town: 'Camberley',
  },
  {
    postcode: 'GU16',
    uk_region: 'South East',
    postal_town: 'Camberley',
  },
  {
    postcode: 'GU17',
    uk_region: 'South East',
    postal_town: '"Farnborough, Camberley"',
  },
  {
    postcode: 'GU18',
    uk_region: 'South East',
    postal_town: 'Lightwater',
  },
  {
    postcode: 'GU19',
    uk_region: 'South East',
    postal_town: 'Bagshot',
  },
  {
    postcode: 'GU20',
    uk_region: 'South East',
    postal_town: 'Windlesham',
  },
  {
    postcode: 'GU21',
    uk_region: 'South East',
    postal_town: 'Woking',
  },
  {
    postcode: 'GU22',
    uk_region: 'South East',
    postal_town: 'Woking',
  },
  {
    postcode: 'GU23',
    uk_region: 'South East',
    postal_town: 'Woking',
  },
  {
    postcode: 'GU24',
    uk_region: 'South East',
    postal_town: 'Woking',
  },
  {
    postcode: 'GU25',
    uk_region: 'South East',
    postal_town: 'Virginia Water',
  },
  {
    postcode: 'GU26',
    uk_region: 'South East',
    postal_town: 'Hindhead',
  },
  {
    postcode: 'GU27',
    uk_region: 'South East',
    postal_town: '"Hindhead, Haslemere"',
  },
  {
    postcode: 'GU28',
    uk_region: 'South East',
    postal_town: 'Petworth',
  },
  {
    postcode: 'GU29',
    uk_region: 'South East',
    postal_town: 'Midhurst',
  },
  {
    postcode: 'GU30',
    uk_region: 'South East',
    postal_town: 'Liphook',
  },
  {
    postcode: 'GU31',
    uk_region: 'South East',
    postal_town: 'Petersfield',
  },
  {
    postcode: 'GU32',
    uk_region: 'South East',
    postal_town: 'Petersfield',
  },
  {
    postcode: 'GU33',
    uk_region: 'South East',
    postal_town: 'Liss',
  },
  {
    postcode: 'GU34',
    uk_region: 'South East',
    postal_town: 'Alton',
  },
  {
    postcode: 'GU35',
    uk_region: 'South East',
    postal_town: 'Bordon',
  },
  {
    postcode: 'GU46',
    uk_region: 'South East',
    postal_town: 'Yateley',
  },
  {
    postcode: 'GU47',
    uk_region: 'South East',
    postal_town: 'Sandhurst',
  },
  {
    postcode: 'GU51',
    uk_region: 'South East',
    postal_town: 'Fleet',
  },
  {
    postcode: 'GU52',
    uk_region: 'South East',
    postal_town: 'Fleet',
  },
  {
    postcode: 'GU95',
    uk_region: 'South East',
    postal_town: 'Camberley',
  },
  {
    postcode: 'GY1',
    uk_region: '',
    postal_town: '',
  },
  {
    postcode: 'GY2',
    uk_region: '',
    postal_town: '',
  },
  {
    postcode: 'GY3',
    uk_region: '',
    postal_town: '',
  },
  {
    postcode: 'GY4',
    uk_region: '',
    postal_town: '',
  },
  {
    postcode: 'GY5',
    uk_region: '',
    postal_town: '',
  },
  {
    postcode: 'GY6',
    uk_region: '',
    postal_town: '',
  },
  {
    postcode: 'GY7',
    uk_region: '',
    postal_town: '',
  },
  {
    postcode: 'GY8',
    uk_region: '',
    postal_town: '',
  },
  {
    postcode: 'GY9',
    uk_region: '',
    postal_town: '',
  },
  {
    postcode: 'GY10',
    uk_region: '',
    postal_town: '',
  },
  {
    postcode: 'HA0',
    uk_region: 'London',
    postal_town: 'Wembley',
  },
  {
    postcode: 'HA1',
    uk_region: 'London',
    postal_town: 'Harrow',
  },
  {
    postcode: 'HA2',
    uk_region: 'London',
    postal_town: 'Harrow',
  },
  {
    postcode: 'HA3',
    uk_region: 'London',
    postal_town: 'Harrow',
  },
  {
    postcode: 'HA4',
    uk_region: 'London',
    postal_town: 'Ruislip',
  },
  {
    postcode: 'HA5',
    uk_region: 'London',
    postal_town: 'Pinner',
  },
  {
    postcode: 'HA6',
    uk_region: 'London',
    postal_town: 'Northwood',
  },
  {
    postcode: 'HA7',
    uk_region: 'London',
    postal_town: 'Stanmore',
  },
  {
    postcode: 'HA8',
    uk_region: 'London',
    postal_town: 'Edgware',
  },
  {
    postcode: 'HA9',
    uk_region: 'London',
    postal_town: 'Wembley',
  },
  {
    postcode: 'HD1',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Huddersfield',
  },
  {
    postcode: 'HD2',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Huddersfield',
  },
  {
    postcode: 'HD3',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Huddersfield',
  },
  {
    postcode: 'HD4',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Huddersfield',
  },
  {
    postcode: 'HD5',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Huddersfield',
  },
  {
    postcode: 'HD6',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Brighouse',
  },
  {
    postcode: 'HD7',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Huddersfield',
  },
  {
    postcode: 'HD8',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Huddersfield',
  },
  {
    postcode: 'HD9',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Holmfirth',
  },
  {
    postcode: 'HG1',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Harrogate',
  },
  {
    postcode: 'HG2',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Harrogate',
  },
  {
    postcode: 'HG3',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Harrogate',
  },
  {
    postcode: 'HG4',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Ripon',
  },
  {
    postcode: 'HG5',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Knaresborough',
  },
  {
    postcode: 'HP1',
    uk_region: 'East of England',
    postal_town: 'Hemel Hempstead',
  },
  {
    postcode: 'HP2',
    uk_region: 'East of England',
    postal_town: 'Hemel Hempstead',
  },
  {
    postcode: 'HP3',
    uk_region: 'East of England',
    postal_town: 'Hemel Hempstead',
  },
  {
    postcode: 'HP4',
    uk_region: 'East of England',
    postal_town: 'Berkhamsted',
  },
  {
    postcode: 'HP5',
    uk_region: 'South East',
    postal_town: 'Chesham',
  },
  {
    postcode: 'HP6',
    uk_region: 'South East',
    postal_town: 'Amersham',
  },
  {
    postcode: 'HP7',
    uk_region: 'South East',
    postal_town: 'Amersham',
  },
  {
    postcode: 'HP8',
    uk_region: 'South East',
    postal_town: 'Chalfont St. Giles',
  },
  {
    postcode: 'HP9',
    uk_region: 'South East',
    postal_town: 'Beaconsfield',
  },
  {
    postcode: 'HP10',
    uk_region: 'South East',
    postal_town: 'High Wycombe',
  },
  {
    postcode: 'HP11',
    uk_region: 'South East',
    postal_town: 'High Wycombe',
  },
  {
    postcode: 'HP12',
    uk_region: 'South East',
    postal_town: 'High Wycombe',
  },
  {
    postcode: 'HP13',
    uk_region: 'South East',
    postal_town: 'High Wycombe',
  },
  {
    postcode: 'HP14',
    uk_region: 'South East',
    postal_town: 'High Wycombe',
  },
  {
    postcode: 'HP15',
    uk_region: 'South East',
    postal_town: 'High Wycombe',
  },
  {
    postcode: 'HP16',
    uk_region: 'South East',
    postal_town: 'Great Missenden',
  },
  {
    postcode: 'HP17',
    uk_region: 'South East',
    postal_town: 'Aylesbury',
  },
  {
    postcode: 'HP18',
    uk_region: 'South East',
    postal_town: 'Aylesbury',
  },
  {
    postcode: 'HP19',
    uk_region: 'South East',
    postal_town: 'Aylesbury',
  },
  {
    postcode: 'HP20',
    uk_region: 'South East',
    postal_town: 'Aylesbury',
  },
  {
    postcode: 'HP21',
    uk_region: 'South East',
    postal_town: 'Aylesbury',
  },
  {
    postcode: 'HP22',
    uk_region: 'South East',
    postal_town: '"Aylesbury, Princes Risborough"',
  },
  {
    postcode: 'HP23',
    uk_region: 'East of England',
    postal_town: 'Tring',
  },
  {
    postcode: 'HP27',
    uk_region: 'South East',
    postal_town: 'Princes Risborough',
  },
  {
    postcode: 'HR1',
    uk_region: 'West Midlands',
    postal_town: 'Hereford',
  },
  {
    postcode: 'HR2',
    uk_region: 'West Midlands',
    postal_town: 'Hereford',
  },
  {
    postcode: 'HR3',
    uk_region: 'West Midlands',
    postal_town: 'Hereford',
  },
  {
    postcode: 'HR4',
    uk_region: 'West Midlands',
    postal_town: 'Hereford',
  },
  {
    postcode: 'HR5',
    uk_region: 'West Midlands',
    postal_town: 'Kington',
  },
  {
    postcode: 'HR6',
    uk_region: 'West Midlands',
    postal_town: 'Leominster',
  },
  {
    postcode: 'HR7',
    uk_region: 'West Midlands',
    postal_town: 'Bromyard',
  },
  {
    postcode: 'HR8',
    uk_region: 'West Midlands',
    postal_town: 'Ledbury',
  },
  {
    postcode: 'HR9',
    uk_region: 'West Midlands',
    postal_town: 'Ross-on-Wye',
  },
  {
    postcode: 'HS1',
    uk_region: 'Scotland',
    postal_town: 'Stornoway',
  },
  {
    postcode: 'HS2',
    uk_region: 'Scotland',
    postal_town: 'Isle of Lewis',
  },
  {
    postcode: 'HS3',
    uk_region: 'Scotland',
    postal_town: 'Isle of Harris',
  },
  {
    postcode: 'HS4',
    uk_region: 'Scotland',
    postal_town: 'Isle of Scalpay',
  },
  {
    postcode: 'HS5',
    uk_region: 'Scotland',
    postal_town: 'Isle of Harris',
  },
  {
    postcode: 'HS6',
    uk_region: 'Scotland',
    postal_town: 'Isle of North Uist',
  },
  {
    postcode: 'HS7',
    uk_region: 'Scotland',
    postal_town: 'Isle of Benbecula',
  },
  {
    postcode: 'HS8',
    uk_region: 'Scotland',
    postal_town: 'Isle of South Uist',
  },
  {
    postcode: 'HS9',
    uk_region: 'Scotland',
    postal_town: 'Isle of Barra',
  },
  {
    postcode: 'HU1',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Hull',
  },
  {
    postcode: 'HU2',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Hull',
  },
  {
    postcode: 'HU3',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Hull',
  },
  {
    postcode: 'HU4',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Hull',
  },
  {
    postcode: 'HU5',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Hull',
  },
  {
    postcode: 'HU6',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Hull',
  },
  {
    postcode: 'HU7',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Hull',
  },
  {
    postcode: 'HU8',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Hull',
  },
  {
    postcode: 'HU9',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Hull',
  },
  {
    postcode: 'HU10',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Hull',
  },
  {
    postcode: 'HU11',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Hull',
  },
  {
    postcode: 'HU12',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Hull',
  },
  {
    postcode: 'HU13',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Hessle',
  },
  {
    postcode: 'HU14',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'North Ferriby',
  },
  {
    postcode: 'HU15',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Brough',
  },
  {
    postcode: 'HU16',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Cottingham',
  },
  {
    postcode: 'HU17',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Beverley',
  },
  {
    postcode: 'HU18',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Hornsea',
  },
  {
    postcode: 'HU19',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Withernsea',
  },
  {
    postcode: 'HU20',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Cottingham',
  },
  {
    postcode: 'HU55',
    uk_region: '',
    postal_town: '',
  },
  {
    postcode: 'HX1',
    uk_region: 'Yorkshire and The Humber',
    postal_town: '"Halifax, Elland"',
  },
  {
    postcode: 'HX2',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Halifax',
  },
  {
    postcode: 'HX3',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Halifax',
  },
  {
    postcode: 'HX4',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Halifax',
  },
  {
    postcode: 'HX5',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Elland',
  },
  {
    postcode: 'HX6',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Sowerby Bridge',
  },
  {
    postcode: 'HX7',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Hebden Bridge',
  },
  {
    postcode: 'IG1',
    uk_region: 'London',
    postal_town: 'Ilford',
  },
  {
    postcode: 'IG2',
    uk_region: 'London',
    postal_town: 'Ilford',
  },
  {
    postcode: 'IG3',
    uk_region: 'London',
    postal_town: 'Ilford',
  },
  {
    postcode: 'IG4',
    uk_region: 'London',
    postal_town: 'Ilford',
  },
  {
    postcode: 'IG5',
    uk_region: 'London',
    postal_town: 'Ilford',
  },
  {
    postcode: 'IG6',
    uk_region: 'London',
    postal_town: 'Ilford',
  },
  {
    postcode: 'IG7',
    uk_region: 'East of England',
    postal_town: 'Chigwell',
  },
  {
    postcode: 'IG8',
    uk_region: 'London',
    postal_town: '"Chigwell, Woodford Green"',
  },
  {
    postcode: 'IG9',
    uk_region: 'East of England',
    postal_town: 'Buckhurst Hill',
  },
  {
    postcode: 'IG10',
    uk_region: 'East of England',
    postal_town: 'Loughton',
  },
  {
    postcode: 'IG11',
    uk_region: 'London',
    postal_town: 'Barking',
  },
  {
    postcode: 'IM1',
    uk_region: '',
    postal_town: '',
  },
  {
    postcode: 'IM2',
    uk_region: '',
    postal_town: '',
  },
  {
    postcode: 'IM3',
    uk_region: '',
    postal_town: '',
  },
  {
    postcode: 'IM4',
    uk_region: '',
    postal_town: '',
  },
  {
    postcode: 'IM5',
    uk_region: '',
    postal_town: '',
  },
  {
    postcode: 'IM6',
    uk_region: '',
    postal_town: '',
  },
  {
    postcode: 'IM7',
    uk_region: '',
    postal_town: '',
  },
  {
    postcode: 'IM8',
    uk_region: '',
    postal_town: '',
  },
  {
    postcode: 'IM9',
    uk_region: '',
    postal_town: '',
  },
  {
    postcode: 'IP1',
    uk_region: 'East of England',
    postal_town: 'Ipswich',
  },
  {
    postcode: 'IP2',
    uk_region: 'East of England',
    postal_town: 'Ipswich',
  },
  {
    postcode: 'IP3',
    uk_region: 'East of England',
    postal_town: 'Ipswich',
  },
  {
    postcode: 'IP4',
    uk_region: 'East of England',
    postal_town: 'Ipswich',
  },
  {
    postcode: 'IP5',
    uk_region: 'East of England',
    postal_town: 'Ipswich',
  },
  {
    postcode: 'IP6',
    uk_region: 'East of England',
    postal_town: 'Ipswich',
  },
  {
    postcode: 'IP7',
    uk_region: 'East of England',
    postal_town: 'Ipswich',
  },
  {
    postcode: 'IP8',
    uk_region: 'East of England',
    postal_town: 'Ipswich',
  },
  {
    postcode: 'IP9',
    uk_region: 'East of England',
    postal_town: 'Ipswich',
  },
  {
    postcode: 'IP10',
    uk_region: 'East of England',
    postal_town: 'Ipswich',
  },
  {
    postcode: 'IP11',
    uk_region: 'East of England',
    postal_town: 'Felixstowe',
  },
  {
    postcode: 'IP12',
    uk_region: 'East of England',
    postal_town: 'Woodbridge',
  },
  {
    postcode: 'IP13',
    uk_region: 'East of England',
    postal_town: 'Woodbridge',
  },
  {
    postcode: 'IP14',
    uk_region: 'East of England',
    postal_town: 'Stowmarket',
  },
  {
    postcode: 'IP15',
    uk_region: 'East of England',
    postal_town: 'Aldeburgh',
  },
  {
    postcode: 'IP16',
    uk_region: 'East of England',
    postal_town: 'Leiston',
  },
  {
    postcode: 'IP17',
    uk_region: 'East of England',
    postal_town: 'Saxmundham',
  },
  {
    postcode: 'IP18',
    uk_region: 'East of England',
    postal_town: 'Southwold',
  },
  {
    postcode: 'IP19',
    uk_region: 'East of England',
    postal_town: 'Halesworth',
  },
  {
    postcode: 'IP20',
    uk_region: 'East of England',
    postal_town: 'Harleston',
  },
  {
    postcode: 'IP21',
    uk_region: 'East of England',
    postal_town: '"Diss, Eye"',
  },
  {
    postcode: 'IP22',
    uk_region: 'East of England',
    postal_town: 'Diss',
  },
  {
    postcode: 'IP23',
    uk_region: 'East of England',
    postal_town: 'Eye',
  },
  {
    postcode: 'IP24',
    uk_region: 'East of England',
    postal_town: 'Thetford',
  },
  {
    postcode: 'IP25',
    uk_region: 'East of England',
    postal_town: 'Thetford',
  },
  {
    postcode: 'IP26',
    uk_region: 'East of England',
    postal_town: 'Thetford',
  },
  {
    postcode: 'IP27',
    uk_region: 'East of England',
    postal_town: 'Brandon',
  },
  {
    postcode: 'IP28',
    uk_region: 'East of England',
    postal_town: 'Bury St. Edmunds',
  },
  {
    postcode: 'IP29',
    uk_region: 'East of England',
    postal_town: 'Bury St. Edmunds',
  },
  {
    postcode: 'IP30',
    uk_region: 'East of England',
    postal_town: 'Bury St. Edmunds',
  },
  {
    postcode: 'IP31',
    uk_region: 'East of England',
    postal_town: 'Bury St. Edmunds',
  },
  {
    postcode: 'IP32',
    uk_region: 'East of England',
    postal_town: 'Bury St. Edmunds',
  },
  {
    postcode: 'IP33',
    uk_region: 'East of England',
    postal_town: 'Bury St. Edmunds',
  },
  {
    postcode: 'IP98',
    uk_region: 'East of England',
    postal_town: 'Diss',
  },
  {
    postcode: 'IV1',
    uk_region: 'Scotland',
    postal_town: 'Inverness',
  },
  {
    postcode: 'IV2',
    uk_region: 'Scotland',
    postal_town: 'Inverness',
  },
  {
    postcode: 'IV3',
    uk_region: 'Scotland',
    postal_town: 'Inverness',
  },
  {
    postcode: 'IV4',
    uk_region: 'Scotland',
    postal_town: 'Beauly',
  },
  {
    postcode: 'IV5',
    uk_region: 'Scotland',
    postal_town: 'Inverness',
  },
  {
    postcode: 'IV6',
    uk_region: 'Scotland',
    postal_town: 'Muir of Ord',
  },
  {
    postcode: 'IV7',
    uk_region: 'Scotland',
    postal_town: 'Dingwall',
  },
  {
    postcode: 'IV8',
    uk_region: 'Scotland',
    postal_town: 'Munlochy',
  },
  {
    postcode: 'IV9',
    uk_region: 'Scotland',
    postal_town: 'Avoch',
  },
  {
    postcode: 'IV10',
    uk_region: 'Scotland',
    postal_town: 'Fortrose',
  },
  {
    postcode: 'IV11',
    uk_region: 'Scotland',
    postal_town: 'Cromarty',
  },
  {
    postcode: 'IV12',
    uk_region: 'Scotland',
    postal_town: 'Nairn',
  },
  {
    postcode: 'IV13',
    uk_region: 'Scotland',
    postal_town: 'Inverness',
  },
  {
    postcode: 'IV14',
    uk_region: 'Scotland',
    postal_town: 'Strathpeffer',
  },
  {
    postcode: 'IV15',
    uk_region: 'Scotland',
    postal_town: 'Dingwall',
  },
  {
    postcode: 'IV16',
    uk_region: 'Scotland',
    postal_town: 'Dingwall',
  },
  {
    postcode: 'IV17',
    uk_region: 'Scotland',
    postal_town: 'Alness',
  },
  {
    postcode: 'IV18',
    uk_region: 'Scotland',
    postal_town: 'Invergordon',
  },
  {
    postcode: 'IV19',
    uk_region: 'Scotland',
    postal_town: 'Tain',
  },
  {
    postcode: 'IV20',
    uk_region: 'Scotland',
    postal_town: 'Tain',
  },
  {
    postcode: 'IV21',
    uk_region: 'Scotland',
    postal_town: 'Gairloch',
  },
  {
    postcode: 'IV22',
    uk_region: 'Scotland',
    postal_town: 'Achnasheen',
  },
  {
    postcode: 'IV23',
    uk_region: 'Scotland',
    postal_town: 'Garve',
  },
  {
    postcode: 'IV24',
    uk_region: 'Scotland',
    postal_town: 'Ardgay',
  },
  {
    postcode: 'IV25',
    uk_region: 'Scotland',
    postal_town: 'Dornoch',
  },
  {
    postcode: 'IV26',
    uk_region: 'Scotland',
    postal_town: 'Ullapool',
  },
  {
    postcode: 'IV27',
    uk_region: 'Scotland',
    postal_town: 'Lairg',
  },
  {
    postcode: 'IV28',
    uk_region: 'Scotland',
    postal_town: 'Rogart',
  },
  {
    postcode: 'IV30',
    uk_region: 'Scotland',
    postal_town: 'Elgin',
  },
  {
    postcode: 'IV31',
    uk_region: 'Scotland',
    postal_town: 'Lossiemouth',
  },
  {
    postcode: 'IV32',
    uk_region: 'Scotland',
    postal_town: 'Fochabers',
  },
  {
    postcode: 'IV33',
    uk_region: 'Scotland',
    postal_town: '',
  },
  {
    postcode: 'IV34',
    uk_region: 'Scotland',
    postal_town: '',
  },
  {
    postcode: 'IV35',
    uk_region: 'Scotland',
    postal_town: '',
  },
  {
    postcode: 'IV36',
    uk_region: 'Scotland',
    postal_town: 'Forres',
  },
  {
    postcode: 'IV40',
    uk_region: 'Scotland',
    postal_town: 'Kyle',
  },
  {
    postcode: 'IV41',
    uk_region: 'Scotland',
    postal_town: 'Isle of Skye',
  },
  {
    postcode: 'IV42',
    uk_region: 'Scotland',
    postal_town: 'Isle of Skye',
  },
  {
    postcode: 'IV43',
    uk_region: 'Scotland',
    postal_town: 'Isle of Skye',
  },
  {
    postcode: 'IV44',
    uk_region: 'Scotland',
    postal_town: 'Isle of Skye',
  },
  {
    postcode: 'IV45',
    uk_region: 'Scotland',
    postal_town: 'Isle of Skye',
  },
  {
    postcode: 'IV46',
    uk_region: 'Scotland',
    postal_town: 'Isle of Skye',
  },
  {
    postcode: 'IV47',
    uk_region: 'Scotland',
    postal_town: 'Isle of Skye',
  },
  {
    postcode: 'IV48',
    uk_region: 'Scotland',
    postal_town: 'Isle of Skye',
  },
  {
    postcode: 'IV49',
    uk_region: 'Scotland',
    postal_town: 'Isle of Skye',
  },
  {
    postcode: 'IV51',
    uk_region: 'Scotland',
    postal_town: 'Portree',
  },
  {
    postcode: 'IV52',
    uk_region: 'Scotland',
    postal_town: 'Plockton',
  },
  {
    postcode: 'IV53',
    uk_region: 'Scotland',
    postal_town: 'Strome Ferry',
  },
  {
    postcode: 'IV54',
    uk_region: 'Scotland',
    postal_town: 'Strathcarron',
  },
  {
    postcode: 'IV55',
    uk_region: 'Scotland',
    postal_town: 'Isle of Skye',
  },
  {
    postcode: 'IV56',
    uk_region: 'Scotland',
    postal_town: 'Isle of Skye',
  },
  {
    postcode: 'IV63',
    uk_region: 'Scotland',
    postal_town: 'Inverness',
  },
  {
    postcode: 'IV99',
    uk_region: 'Scotland',
    postal_town: 'Inverness',
  },
  {
    postcode: 'JE2',
    uk_region: '',
    postal_town: '',
  },
  {
    postcode: 'JE3',
    uk_region: '',
    postal_town: '',
  },
  {
    postcode: 'KA1',
    uk_region: 'Scotland',
    postal_town: 'Kilmarnock',
  },
  {
    postcode: 'KA2',
    uk_region: 'Scotland',
    postal_town: 'Kilmarnock',
  },
  {
    postcode: 'KA3',
    uk_region: 'Scotland',
    postal_town: 'Kilmarnock',
  },
  {
    postcode: 'KA4',
    uk_region: 'Scotland',
    postal_town: 'Galston',
  },
  {
    postcode: 'KA5',
    uk_region: 'Scotland',
    postal_town: 'Mauchline',
  },
  {
    postcode: 'KA6',
    uk_region: 'Scotland',
    postal_town: 'Ayr',
  },
  {
    postcode: 'KA7',
    uk_region: 'Scotland',
    postal_town: 'Ayr',
  },
  {
    postcode: 'KA8',
    uk_region: 'Scotland',
    postal_town: 'Ayr',
  },
  {
    postcode: 'KA9',
    uk_region: 'Scotland',
    postal_town: 'Prestwick',
  },
  {
    postcode: 'KA10',
    uk_region: 'Scotland',
    postal_town: 'Troon',
  },
  {
    postcode: 'KA11',
    uk_region: 'Scotland',
    postal_town: 'Irvine',
  },
  {
    postcode: 'KA12',
    uk_region: 'Scotland',
    postal_town: 'Irvine',
  },
  {
    postcode: 'KA13',
    uk_region: 'Scotland',
    postal_town: 'Kilwinning',
  },
  {
    postcode: 'KA14',
    uk_region: 'Scotland',
    postal_town: 'Beith',
  },
  {
    postcode: 'KA15',
    uk_region: 'Scotland',
    postal_town: 'Beith',
  },
  {
    postcode: 'KA16',
    uk_region: 'Scotland',
    postal_town: 'Newmilns',
  },
  {
    postcode: 'KA17',
    uk_region: 'Scotland',
    postal_town: 'Darvel',
  },
  {
    postcode: 'KA18',
    uk_region: 'Scotland',
    postal_town: 'Cumnock',
  },
  {
    postcode: 'KA19',
    uk_region: 'Scotland',
    postal_town: 'Maybole',
  },
  {
    postcode: 'KA20',
    uk_region: 'Scotland',
    postal_town: 'Stevenston',
  },
  {
    postcode: 'KA21',
    uk_region: 'Scotland',
    postal_town: 'Saltcoats',
  },
  {
    postcode: 'KA22',
    uk_region: 'Scotland',
    postal_town: 'Ardrossan',
  },
  {
    postcode: 'KA23',
    uk_region: 'Scotland',
    postal_town: 'West Kilbride',
  },
  {
    postcode: 'KA24',
    uk_region: 'Scotland',
    postal_town: 'Dalry',
  },
  {
    postcode: 'KA25',
    uk_region: 'Scotland',
    postal_town: 'Kilbirnie',
  },
  {
    postcode: 'KA26',
    uk_region: 'Scotland',
    postal_town: 'Girvan',
  },
  {
    postcode: 'KA27',
    uk_region: 'Scotland',
    postal_town: 'Isle of Arran',
  },
  {
    postcode: 'KA28',
    uk_region: 'Scotland',
    postal_town: 'Isle of Cumbrae',
  },
  {
    postcode: 'KA29',
    uk_region: 'Scotland',
    postal_town: 'Largs',
  },
  {
    postcode: 'KA30',
    uk_region: 'Scotland',
    postal_town: 'Largs',
  },
  {
    postcode: 'KT1',
    uk_region: 'London',
    postal_town: 'Kingston upon Thames',
  },
  {
    postcode: 'KT2',
    uk_region: 'London',
    postal_town: 'Kingston upon Thames',
  },
  {
    postcode: 'KT3',
    uk_region: 'London',
    postal_town: 'New Malden',
  },
  {
    postcode: 'KT4',
    uk_region: 'London',
    postal_town: 'Worcester Park',
  },
  {
    postcode: 'KT5',
    uk_region: 'London',
    postal_town: 'Surbiton',
  },
  {
    postcode: 'KT6',
    uk_region: 'London',
    postal_town: 'Surbiton',
  },
  {
    postcode: 'KT7',
    uk_region: 'South East',
    postal_town: 'Thames Ditton',
  },
  {
    postcode: 'KT8',
    uk_region: 'South East',
    postal_town: '"East Molesey, West Molesey"',
  },
  {
    postcode: 'KT9',
    uk_region: 'London',
    postal_town: 'Chessington',
  },
  {
    postcode: 'KT10',
    uk_region: 'South East',
    postal_town: 'Esher',
  },
  {
    postcode: 'KT11',
    uk_region: 'South East',
    postal_town: 'Cobham',
  },
  {
    postcode: 'KT12',
    uk_region: 'South East',
    postal_town: 'Walton-on-Thames',
  },
  {
    postcode: 'KT13',
    uk_region: 'South East',
    postal_town: 'Weybridge',
  },
  {
    postcode: 'KT14',
    uk_region: 'South East',
    postal_town: 'West Byfleet',
  },
  {
    postcode: 'KT15',
    uk_region: 'South East',
    postal_town: 'Addlestone',
  },
  {
    postcode: 'KT16',
    uk_region: 'South East',
    postal_town: 'Chertsey',
  },
  {
    postcode: 'KT17',
    uk_region: 'South East',
    postal_town: 'Epsom',
  },
  {
    postcode: 'KT18',
    uk_region: 'South East',
    postal_town: 'Epsom',
  },
  {
    postcode: 'KT19',
    uk_region: 'South East',
    postal_town: 'Epsom',
  },
  {
    postcode: 'KT20',
    uk_region: 'South East',
    postal_town: 'Tadworth',
  },
  {
    postcode: 'KT21',
    uk_region: 'South East',
    postal_town: 'Ashtead',
  },
  {
    postcode: 'KT22',
    uk_region: 'South East',
    postal_town: 'Leatherhead',
  },
  {
    postcode: 'KT23',
    uk_region: 'South East',
    postal_town: 'Leatherhead',
  },
  {
    postcode: 'KT24',
    uk_region: 'South East',
    postal_town: 'Leatherhead',
  },
  {
    postcode: 'KW1',
    uk_region: 'Scotland',
    postal_town: 'Wick',
  },
  {
    postcode: 'KW2',
    uk_region: 'Scotland',
    postal_town: 'Lybster',
  },
  {
    postcode: 'KW3',
    uk_region: 'Scotland',
    postal_town: 'Lybster',
  },
  {
    postcode: 'KW5',
    uk_region: 'Scotland',
    postal_town: 'Latheron',
  },
  {
    postcode: 'KW6',
    uk_region: 'Scotland',
    postal_town: 'Dunbeath',
  },
  {
    postcode: 'KW7',
    uk_region: 'Scotland',
    postal_town: 'Berriedale',
  },
  {
    postcode: 'KW8',
    uk_region: 'Scotland',
    postal_town: 'Helmsdale',
  },
  {
    postcode: 'KW9',
    uk_region: 'Scotland',
    postal_town: 'Brora',
  },
  {
    postcode: 'KW10',
    uk_region: 'Scotland',
    postal_town: 'Golspie',
  },
  {
    postcode: 'KW11',
    uk_region: 'Scotland',
    postal_town: 'Kinbrace',
  },
  {
    postcode: 'KW12',
    uk_region: 'Scotland',
    postal_town: 'Halkirk',
  },
  {
    postcode: 'KW13',
    uk_region: 'Scotland',
    postal_town: 'Forsinard',
  },
  {
    postcode: 'KW14',
    uk_region: 'Scotland',
    postal_town: 'Thurso',
  },
  {
    postcode: 'KW15',
    uk_region: 'Scotland',
    postal_town: 'Kirkwall',
  },
  {
    postcode: 'KW16',
    uk_region: 'Scotland',
    postal_town: 'Stromness',
  },
  {
    postcode: 'KW17',
    uk_region: 'Scotland',
    postal_town: 'Orkney',
  },
  {
    postcode: 'KY1',
    uk_region: 'Scotland',
    postal_town: 'Kirkcaldy',
  },
  {
    postcode: 'KY2',
    uk_region: 'Scotland',
    postal_town: 'Kirkcaldy',
  },
  {
    postcode: 'KY3',
    uk_region: 'Scotland',
    postal_town: 'Burntisland',
  },
  {
    postcode: 'KY4',
    uk_region: 'Scotland',
    postal_town: '"Cowdenbeath, Kelty"',
  },
  {
    postcode: 'KY5',
    uk_region: 'Scotland',
    postal_town: 'Lochgelly',
  },
  {
    postcode: 'KY6',
    uk_region: 'Scotland',
    postal_town: 'Glenrothes',
  },
  {
    postcode: 'KY7',
    uk_region: 'Scotland',
    postal_town: 'Glenrothes',
  },
  {
    postcode: 'KY8',
    uk_region: 'Scotland',
    postal_town: 'Leven',
  },
  {
    postcode: 'KY9',
    uk_region: 'Scotland',
    postal_town: 'Leven',
  },
  {
    postcode: 'KY10',
    uk_region: 'Scotland',
    postal_town: 'Anstruther',
  },
  {
    postcode: 'KY11',
    uk_region: 'Scotland',
    postal_town: '"Dunfermline, Inverkeithing"',
  },
  {
    postcode: 'KY12',
    uk_region: 'Scotland',
    postal_town: 'Dunfermline',
  },
  {
    postcode: 'KY13',
    uk_region: 'Scotland',
    postal_town: 'Kinross',
  },
  {
    postcode: 'KY14',
    uk_region: 'Scotland',
    postal_town: 'Cupar',
  },
  {
    postcode: 'KY15',
    uk_region: 'Scotland',
    postal_town: 'Cupar',
  },
  {
    postcode: 'KY16',
    uk_region: 'Scotland',
    postal_town: 'St. Andrews',
  },
  {
    postcode: 'KY99',
    uk_region: 'Scotland',
    postal_town: 'Dunfermline',
  },
  {
    postcode: 'L1',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L2',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L3',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L4',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L5',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L6',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L7',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L8',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L9',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L10',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L11',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L12',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L13',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L14',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L15',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L16',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L17',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L18',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L19',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L20',
    uk_region: 'North West',
    postal_town: '"Liverpool, Bootle"',
  },
  {
    postcode: 'L21',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L22',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L23',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L24',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L25',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L26',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L27',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L28',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L29',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L30',
    uk_region: 'North West',
    postal_town: 'Bootle',
  },
  {
    postcode: 'L31',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L32',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L33',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L34',
    uk_region: 'North West',
    postal_town: 'Prescot',
  },
  {
    postcode: 'L35',
    uk_region: 'North West',
    postal_town: 'Prescot',
  },
  {
    postcode: 'L36',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L37',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L38',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L39',
    uk_region: 'North West',
    postal_town: 'Ormskirk',
  },
  {
    postcode: 'L40',
    uk_region: 'North West',
    postal_town: 'Ormskirk',
  },
  {
    postcode: 'L41',
    uk_region: 'North West',
    postal_town: '',
  },
  {
    postcode: 'L42',
    uk_region: 'North West',
    postal_town: '',
  },
  {
    postcode: 'L43',
    uk_region: 'North West',
    postal_town: '',
  },
  {
    postcode: 'L44',
    uk_region: 'North West',
    postal_town: '',
  },
  {
    postcode: 'L45',
    uk_region: 'North West',
    postal_town: '',
  },
  {
    postcode: 'L46',
    uk_region: 'North West',
    postal_town: '',
  },
  {
    postcode: 'L47',
    uk_region: 'North West',
    postal_town: '',
  },
  {
    postcode: 'L48',
    uk_region: 'North West',
    postal_town: '',
  },
  {
    postcode: 'L49',
    uk_region: 'North West',
    postal_town: '',
  },
  {
    postcode: 'L60',
    uk_region: 'North West',
    postal_town: '',
  },
  {
    postcode: 'L61',
    uk_region: 'North West',
    postal_town: '',
  },
  {
    postcode: 'L62',
    uk_region: 'North West',
    postal_town: '',
  },
  {
    postcode: 'L63',
    uk_region: 'North West',
    postal_town: '',
  },
  {
    postcode: 'L64',
    uk_region: 'North West',
    postal_town: '',
  },
  {
    postcode: 'L65',
    uk_region: 'North West',
    postal_town: '',
  },
  {
    postcode: 'L66',
    uk_region: 'North West',
    postal_town: '',
  },
  {
    postcode: 'L67',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L68',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L69',
    uk_region: 'North West',
    postal_town: '"Liverpool, Bootle"',
  },
  {
    postcode: 'L70',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L71',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L72',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L73',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L74',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L75',
    uk_region: 'North West',
    postal_town: 'Liverpool',
  },
  {
    postcode: 'L80',
    uk_region: 'North West',
    postal_town: 'Bootle',
  },
  {
    postcode: 'LA1',
    uk_region: 'North West',
    postal_town: 'Lancaster',
  },
  {
    postcode: 'LA2',
    uk_region: 'North West',
    postal_town: 'Lancaster',
  },
  {
    postcode: 'LA3',
    uk_region: 'North West',
    postal_town: 'Morecambe',
  },
  {
    postcode: 'LA4',
    uk_region: 'North West',
    postal_town: 'Morecambe',
  },
  {
    postcode: 'LA5',
    uk_region: 'North West',
    postal_town: 'Carnforth',
  },
  {
    postcode: 'LA6',
    uk_region: 'North West',
    postal_town: 'Carnforth',
  },
  {
    postcode: 'LA7',
    uk_region: 'North West',
    postal_town: 'Milnthorpe',
  },
  {
    postcode: 'LA8',
    uk_region: 'North West',
    postal_town: 'Kendal',
  },
  {
    postcode: 'LA9',
    uk_region: 'North West',
    postal_town: 'Kendal',
  },
  {
    postcode: 'LA10',
    uk_region: 'North West',
    postal_town: 'Sedbergh',
  },
  {
    postcode: 'LA11',
    uk_region: 'North West',
    postal_town: 'Grange-over-Sands',
  },
  {
    postcode: 'LA12',
    uk_region: 'North West',
    postal_town: 'Ulverston',
  },
  {
    postcode: 'LA13',
    uk_region: 'North West',
    postal_town: 'Barrow-in-Furness',
  },
  {
    postcode: 'LA14',
    uk_region: 'North West',
    postal_town: '"Barrow-in-Furness, Dalton-in-Furness"',
  },
  {
    postcode: 'LA15',
    uk_region: 'North West',
    postal_town: 'Dalton-in-Furness',
  },
  {
    postcode: 'LA16',
    uk_region: 'North West',
    postal_town: 'Askam-in-Furness',
  },
  {
    postcode: 'LA17',
    uk_region: 'North West',
    postal_town: 'Kirkby-in-Furness',
  },
  {
    postcode: 'LA18',
    uk_region: 'North West',
    postal_town: 'Millom',
  },
  {
    postcode: 'LA19',
    uk_region: 'North West',
    postal_town: 'Millom',
  },
  {
    postcode: 'LA20',
    uk_region: 'North West',
    postal_town: 'Broughton-in-Furness',
  },
  {
    postcode: 'LA21',
    uk_region: 'North West',
    postal_town: 'Coniston',
  },
  {
    postcode: 'LA22',
    uk_region: 'North West',
    postal_town: 'Ambleside',
  },
  {
    postcode: 'LA23',
    uk_region: 'North West',
    postal_town: 'Windermere',
  },
  {
    postcode: 'LD1',
    uk_region: 'Wales',
    postal_town: 'Llandrindod Wells',
  },
  {
    postcode: 'LD2',
    uk_region: 'Wales',
    postal_town: 'Builth Wells',
  },
  {
    postcode: 'LD3',
    uk_region: 'Wales',
    postal_town: 'Brecon',
  },
  {
    postcode: 'LD4',
    uk_region: 'Wales',
    postal_town: 'Llangammarch Wells',
  },
  {
    postcode: 'LD5',
    uk_region: 'Wales',
    postal_town: 'Llanwrtyd Wells',
  },
  {
    postcode: 'LD6',
    uk_region: 'Wales',
    postal_town: 'Rhayader',
  },
  {
    postcode: 'LD7',
    uk_region: 'Wales',
    postal_town: 'Knighton',
  },
  {
    postcode: 'LD8',
    uk_region: 'Wales',
    postal_town: 'Presteigne',
  },
  {
    postcode: 'LE1',
    uk_region: 'East Midlands',
    postal_town: 'Leicester',
  },
  {
    postcode: 'LE2',
    uk_region: 'East Midlands',
    postal_town: 'Leicester',
  },
  {
    postcode: 'LE3',
    uk_region: 'East Midlands',
    postal_town: 'Leicester',
  },
  {
    postcode: 'LE4',
    uk_region: 'East Midlands',
    postal_town: 'Leicester',
  },
  {
    postcode: 'LE5',
    uk_region: 'East Midlands',
    postal_town: 'Leicester',
  },
  {
    postcode: 'LE6',
    uk_region: 'East Midlands',
    postal_town: 'Leicester',
  },
  {
    postcode: 'LE7',
    uk_region: 'East Midlands',
    postal_town: 'Leicester',
  },
  {
    postcode: 'LE8',
    uk_region: 'East Midlands',
    postal_town: 'Leicester',
  },
  {
    postcode: 'LE9',
    uk_region: 'East Midlands',
    postal_town: 'Leicester',
  },
  {
    postcode: 'LE10',
    uk_region: 'East Midlands',
    postal_town: 'Hinckley',
  },
  {
    postcode: 'LE11',
    uk_region: 'East Midlands',
    postal_town: 'Loughborough',
  },
  {
    postcode: 'LE12',
    uk_region: 'East Midlands',
    postal_town: 'Loughborough',
  },
  {
    postcode: 'LE13',
    uk_region: 'East Midlands',
    postal_town: 'Melton Mowbray',
  },
  {
    postcode: 'LE14',
    uk_region: 'East Midlands',
    postal_town: 'Melton Mowbray',
  },
  {
    postcode: 'LE15',
    uk_region: 'East Midlands',
    postal_town: 'Oakham',
  },
  {
    postcode: 'LE16',
    uk_region: 'East Midlands',
    postal_town: 'Market Harborough',
  },
  {
    postcode: 'LE17',
    uk_region: 'East Midlands',
    postal_town: 'Lutterworth',
  },
  {
    postcode: 'LE18',
    uk_region: 'East Midlands',
    postal_town: 'Wigston',
  },
  {
    postcode: 'LE19',
    uk_region: 'East Midlands',
    postal_town: 'Leicester',
  },
  {
    postcode: 'LE21',
    uk_region: 'East Midlands',
    postal_town: 'Leicester',
  },
  {
    postcode: 'LE41',
    uk_region: 'East Midlands',
    postal_town: 'Leicester',
  },
  {
    postcode: 'LE55',
    uk_region: 'East Midlands',
    postal_town: 'Leicester',
  },
  {
    postcode: 'LE65',
    uk_region: 'East Midlands',
    postal_town: 'Ashby-de-la-Zouch',
  },
  {
    postcode: 'LE67',
    uk_region: 'East Midlands',
    postal_town: '"Coalville, Ibstock, Markfield"',
  },
  {
    postcode: 'LE87',
    uk_region: 'East Midlands',
    postal_town: 'Leicester',
  },
  {
    postcode: 'LE94',
    uk_region: 'East Midlands',
    postal_town: 'Leicester',
  },
  {
    postcode: 'LE95',
    uk_region: 'East Midlands',
    postal_town: 'Leicester',
  },
  {
    postcode: 'LE99',
    uk_region: '',
    postal_town: '',
  },
  {
    postcode: 'LL11',
    uk_region: 'Wales',
    postal_town: 'Wrexham',
  },
  {
    postcode: 'LL12',
    uk_region: 'Wales',
    postal_town: 'Wrexham',
  },
  {
    postcode: 'LL13',
    uk_region: 'Wales',
    postal_town: 'Wrexham',
  },
  {
    postcode: 'LL14',
    uk_region: 'Wales',
    postal_town: 'Wrexham',
  },
  {
    postcode: 'LL15',
    uk_region: 'Wales',
    postal_town: 'Ruthin',
  },
  {
    postcode: 'LL16',
    uk_region: 'Wales',
    postal_town: 'Denbigh',
  },
  {
    postcode: 'LL17',
    uk_region: 'Wales',
    postal_town: 'St. Asaph',
  },
  {
    postcode: 'LL18',
    uk_region: 'Wales',
    postal_town: '"St. Asaph, Rhyl"',
  },
  {
    postcode: 'LL19',
    uk_region: 'Wales',
    postal_town: 'Prestatyn',
  },
  {
    postcode: 'LL20',
    uk_region: 'Wales',
    postal_town: 'Llangollen',
  },
  {
    postcode: 'LL21',
    uk_region: 'Wales',
    postal_town: 'Corwen',
  },
  {
    postcode: 'LL22',
    uk_region: 'Wales',
    postal_town: 'Abergele',
  },
  {
    postcode: 'LL23',
    uk_region: 'Wales',
    postal_town: 'Bala',
  },
  {
    postcode: 'LL24',
    uk_region: 'Wales',
    postal_town: 'Betws-y-Coed',
  },
  {
    postcode: 'LL25',
    uk_region: 'Wales',
    postal_town: 'Dolwyddelan',
  },
  {
    postcode: 'LL26',
    uk_region: 'Wales',
    postal_town: 'Llanrwst',
  },
  {
    postcode: 'LL27',
    uk_region: 'Wales',
    postal_town: 'Trefriw',
  },
  {
    postcode: 'LL28',
    uk_region: 'Wales',
    postal_town: 'Colwyn Bay',
  },
  {
    postcode: 'LL29',
    uk_region: 'Wales',
    postal_town: 'Colwyn Bay',
  },
  {
    postcode: 'LL30',
    uk_region: 'Wales',
    postal_town: 'Llandudno',
  },
  {
    postcode: 'LL31',
    uk_region: 'Wales',
    postal_town: '"Conwy, Llandudno Junction"',
  },
  {
    postcode: 'LL32',
    uk_region: 'Wales',
    postal_town: 'Conwy',
  },
  {
    postcode: 'LL33',
    uk_region: 'Wales',
    postal_town: 'Llanfairfechan',
  },
  {
    postcode: 'LL34',
    uk_region: 'Wales',
    postal_town: 'Penmaenmawr',
  },
  {
    postcode: 'LL35',
    uk_region: 'Wales',
    postal_town: 'Aberdyfi',
  },
  {
    postcode: 'LL36',
    uk_region: 'Wales',
    postal_town: 'Tywyn',
  },
  {
    postcode: 'LL37',
    uk_region: 'Wales',
    postal_town: 'Llwyngwril',
  },
  {
    postcode: 'LL38',
    uk_region: 'Wales',
    postal_town: 'Fairbourne',
  },
  {
    postcode: 'LL39',
    uk_region: 'Wales',
    postal_town: 'Arthog',
  },
  {
    postcode: 'LL40',
    uk_region: 'Wales',
    postal_town: 'Dolgellau',
  },
  {
    postcode: 'LL41',
    uk_region: 'Wales',
    postal_town: 'Blaenau Ffestiniog',
  },
  {
    postcode: 'LL42',
    uk_region: 'Wales',
    postal_town: 'Barmouth',
  },
  {
    postcode: 'LL43',
    uk_region: 'Wales',
    postal_town: 'Talybont',
  },
  {
    postcode: 'LL44',
    uk_region: 'Wales',
    postal_town: 'Dyffryn Ardudwy',
  },
  {
    postcode: 'LL45',
    uk_region: 'Wales',
    postal_town: 'Llanbedr',
  },
  {
    postcode: 'LL46',
    uk_region: 'Wales',
    postal_town: 'Harlech',
  },
  {
    postcode: 'LL47',
    uk_region: 'Wales',
    postal_town: 'Talsarnau',
  },
  {
    postcode: 'LL48',
    uk_region: 'Wales',
    postal_town: 'Penrhyndeudraeth',
  },
  {
    postcode: 'LL49',
    uk_region: 'Wales',
    postal_town: 'Porthmadog',
  },
  {
    postcode: 'LL51',
    uk_region: 'Wales',
    postal_town: 'Garndolbenmaen',
  },
  {
    postcode: 'LL52',
    uk_region: 'Wales',
    postal_town: 'Criccieth',
  },
  {
    postcode: 'LL53',
    uk_region: 'Wales',
    postal_town: 'Pwllheli',
  },
  {
    postcode: 'LL54',
    uk_region: 'Wales',
    postal_town: 'Caernarfon',
  },
  {
    postcode: 'LL55',
    uk_region: 'Wales',
    postal_town: 'Caernarfon',
  },
  {
    postcode: 'LL56',
    uk_region: 'Wales',
    postal_town: 'Y Felinheli',
  },
  {
    postcode: 'LL57',
    uk_region: 'Wales',
    postal_town: 'Bangor',
  },
  {
    postcode: 'LL58',
    uk_region: 'Wales',
    postal_town: 'Beaumaris',
  },
  {
    postcode: 'LL59',
    uk_region: 'Wales',
    postal_town: 'Menai Bridge',
  },
  {
    postcode: 'LL60',
    uk_region: 'Wales',
    postal_town: 'Gaerwen',
  },
  {
    postcode: 'LL61',
    uk_region: 'Wales',
    postal_town: 'Llanfairpwllgwyngyll',
  },
  {
    postcode: 'LL62',
    uk_region: 'Wales',
    postal_town: 'Bodorgan',
  },
  {
    postcode: 'LL63',
    uk_region: 'Wales',
    postal_town: 'Ty Croes',
  },
  {
    postcode: 'LL64',
    uk_region: 'Wales',
    postal_town: 'Rhosneigr',
  },
  {
    postcode: 'LL65',
    uk_region: 'Wales',
    postal_town: 'Holyhead',
  },
  {
    postcode: 'LL66',
    uk_region: 'Wales',
    postal_town: 'Rhosgoch',
  },
  {
    postcode: 'LL67',
    uk_region: 'Wales',
    postal_town: 'Cemaes Bay',
  },
  {
    postcode: 'LL68',
    uk_region: 'Wales',
    postal_town: 'Amlwch',
  },
  {
    postcode: 'LL69',
    uk_region: 'Wales',
    postal_town: 'Penysarn',
  },
  {
    postcode: 'LL70',
    uk_region: 'Wales',
    postal_town: 'Dulas',
  },
  {
    postcode: 'LL71',
    uk_region: 'Wales',
    postal_town: 'Llanerchymedd',
  },
  {
    postcode: 'LL72',
    uk_region: 'Wales',
    postal_town: 'Moelfre',
  },
  {
    postcode: 'LL73',
    uk_region: 'Wales',
    postal_town: 'Marianglas',
  },
  {
    postcode: 'LL74',
    uk_region: 'Wales',
    postal_town: 'Tyn-y-Gongl',
  },
  {
    postcode: 'LL75',
    uk_region: 'Wales',
    postal_town: 'Pentraeth',
  },
  {
    postcode: 'LL76',
    uk_region: 'Wales',
    postal_town: 'Llanbedrgoch',
  },
  {
    postcode: 'LL77',
    uk_region: 'Wales',
    postal_town: '"Rhosneigr, Llangefni"',
  },
  {
    postcode: 'LL78',
    uk_region: 'Wales',
    postal_town: 'Brynteg',
  },
  {
    postcode: 'LN1',
    uk_region: 'East Midlands',
    postal_town: 'Lincoln',
  },
  {
    postcode: 'LN2',
    uk_region: 'East Midlands',
    postal_town: 'Lincoln',
  },
  {
    postcode: 'LN3',
    uk_region: 'East Midlands',
    postal_town: 'Lincoln',
  },
  {
    postcode: 'LN4',
    uk_region: 'East Midlands',
    postal_town: 'Lincoln',
  },
  {
    postcode: 'LN5',
    uk_region: 'East Midlands',
    postal_town: 'Lincoln',
  },
  {
    postcode: 'LN6',
    uk_region: 'East Midlands',
    postal_town: 'Lincoln',
  },
  {
    postcode: 'LN7',
    uk_region: 'East Midlands',
    postal_town: 'Market Rasen',
  },
  {
    postcode: 'LN8',
    uk_region: 'East Midlands',
    postal_town: 'Market Rasen',
  },
  {
    postcode: 'LN9',
    uk_region: 'East Midlands',
    postal_town: 'Horncastle',
  },
  {
    postcode: 'LN10',
    uk_region: 'East Midlands',
    postal_town: 'Woodhall Spa',
  },
  {
    postcode: 'LN11',
    uk_region: 'East Midlands',
    postal_town: 'Louth',
  },
  {
    postcode: 'LN12',
    uk_region: 'East Midlands',
    postal_town: 'Mablethorpe',
  },
  {
    postcode: 'LN13',
    uk_region: 'East Midlands',
    postal_town: 'Alford',
  },
  {
    postcode: 'LS1',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Leeds',
  },
  {
    postcode: 'LS2',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Leeds',
  },
  {
    postcode: 'LS3',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Leeds',
  },
  {
    postcode: 'LS4',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Leeds',
  },
  {
    postcode: 'LS5',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Leeds',
  },
  {
    postcode: 'LS6',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Leeds',
  },
  {
    postcode: 'LS7',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Leeds',
  },
  {
    postcode: 'LS8',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Leeds',
  },
  {
    postcode: 'LS9',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Leeds',
  },
  {
    postcode: 'LS10',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Leeds',
  },
  {
    postcode: 'LS11',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Leeds',
  },
  {
    postcode: 'LS12',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Leeds',
  },
  {
    postcode: 'LS13',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Leeds',
  },
  {
    postcode: 'LS14',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Leeds',
  },
  {
    postcode: 'LS15',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Leeds',
  },
  {
    postcode: 'LS16',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Leeds',
  },
  {
    postcode: 'LS17',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Leeds',
  },
  {
    postcode: 'LS18',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Leeds',
  },
  {
    postcode: 'LS19',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Leeds',
  },
  {
    postcode: 'LS20',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Leeds',
  },
  {
    postcode: 'LS21',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Otley',
  },
  {
    postcode: 'LS22',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Wetherby',
  },
  {
    postcode: 'LS23',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Wetherby',
  },
  {
    postcode: 'LS24',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Tadcaster',
  },
  {
    postcode: 'LS25',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Leeds',
  },
  {
    postcode: 'LS26',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Leeds',
  },
  {
    postcode: 'LS27',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Leeds',
  },
  {
    postcode: 'LS28',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Pudsey',
  },
  {
    postcode: 'LS29',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Ilkley',
  },
  {
    postcode: 'LS88',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Leeds',
  },
  {
    postcode: 'LS98',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Leeds',
  },
  {
    postcode: 'LS99',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Leeds',
  },
  {
    postcode: 'LU1',
    uk_region: 'East of England',
    postal_town: 'Luton',
  },
  {
    postcode: 'LU2',
    uk_region: 'East of England',
    postal_town: 'Luton',
  },
  {
    postcode: 'LU3',
    uk_region: 'East of England',
    postal_town: 'Luton',
  },
  {
    postcode: 'LU4',
    uk_region: 'East of England',
    postal_town: 'Luton',
  },
  {
    postcode: 'LU5',
    uk_region: 'East of England',
    postal_town: 'Dunstable',
  },
  {
    postcode: 'LU6',
    uk_region: 'East of England',
    postal_town: 'Dunstable',
  },
  {
    postcode: 'LU7',
    uk_region: 'East of England',
    postal_town: 'Leighton Buzzard',
  },
  {
    postcode: 'LU95',
    uk_region: 'East of England',
    postal_town: '',
  },
  {
    postcode: 'M1',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'M2',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'M3',
    uk_region: 'North West',
    postal_town: '"Manchester, Salford"',
  },
  {
    postcode: 'M4',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'M5',
    uk_region: 'North West',
    postal_town: 'Salford',
  },
  {
    postcode: 'M6',
    uk_region: 'North West',
    postal_town: 'Salford',
  },
  {
    postcode: 'M7',
    uk_region: 'North West',
    postal_town: 'Salford',
  },
  {
    postcode: 'M8',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'M9',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'M10',
    uk_region: 'North West',
    postal_town: '',
  },
  {
    postcode: 'M11',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'M12',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'M13',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'M14',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'M15',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'M16',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'M17',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'M18',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'M19',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'M20',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'M21',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'M22',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'M23',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'M24',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'M25',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'M26',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'M27',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'M28',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'M29',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'M30',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'M31',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'M32',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'M33',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'M34',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'M35',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'M38',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'M40',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'M41',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'M43',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'M44',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'M45',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'M46',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'M50',
    uk_region: 'North West',
    postal_town: 'Salford',
  },
  {
    postcode: 'M52',
    uk_region: 'North West',
    postal_town: '',
  },
  {
    postcode: 'M60',
    uk_region: 'North West',
    postal_town: '"Manchester, Salford"',
  },
  {
    postcode: 'M61',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'M90',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'M99',
    uk_region: 'North West',
    postal_town: 'Manchester',
  },
  {
    postcode: 'ME1',
    uk_region: 'South East',
    postal_town: 'Rochester',
  },
  {
    postcode: 'ME2',
    uk_region: 'South East',
    postal_town: 'Rochester',
  },
  {
    postcode: 'ME3',
    uk_region: 'South East',
    postal_town: 'Rochester',
  },
  {
    postcode: 'ME4',
    uk_region: 'South East',
    postal_town: 'Chatham',
  },
  {
    postcode: 'ME5',
    uk_region: 'South East',
    postal_town: 'Chatham',
  },
  {
    postcode: 'ME6',
    uk_region: 'South East',
    postal_town: '"Aylesford, Snodland, West Malling"',
  },
  {
    postcode: 'ME7',
    uk_region: 'South East',
    postal_town: 'Gillingham',
  },
  {
    postcode: 'ME8',
    uk_region: 'South East',
    postal_town: 'Gillingham',
  },
  {
    postcode: 'ME9',
    uk_region: 'South East',
    postal_town: 'Sittingbourne',
  },
  {
    postcode: 'ME10',
    uk_region: 'South East',
    postal_town: 'Sittingbourne',
  },
  {
    postcode: 'ME11',
    uk_region: 'South East',
    postal_town: 'Queenborough',
  },
  {
    postcode: 'ME12',
    uk_region: 'South East',
    postal_town: 'Sheerness',
  },
  {
    postcode: 'ME13',
    uk_region: 'South East',
    postal_town: 'Faversham',
  },
  {
    postcode: 'ME14',
    uk_region: 'South East',
    postal_town: 'Maidstone',
  },
  {
    postcode: 'ME15',
    uk_region: 'South East',
    postal_town: 'Maidstone',
  },
  {
    postcode: 'ME16',
    uk_region: 'South East',
    postal_town: 'Maidstone',
  },
  {
    postcode: 'ME17',
    uk_region: 'South East',
    postal_town: 'Maidstone',
  },
  {
    postcode: 'ME18',
    uk_region: 'South East',
    postal_town: 'Maidstone',
  },
  {
    postcode: 'ME19',
    uk_region: 'South East',
    postal_town: 'West Malling',
  },
  {
    postcode: 'ME20',
    uk_region: 'South East',
    postal_town: 'Aylesford',
  },
  {
    postcode: 'ME99',
    uk_region: 'South East',
    postal_town: 'Maidstone',
  },
  {
    postcode: 'MK1',
    uk_region: 'South East',
    postal_town: 'Milton Keynes',
  },
  {
    postcode: 'MK2',
    uk_region: 'South East',
    postal_town: 'Milton Keynes',
  },
  {
    postcode: 'MK3',
    uk_region: 'South East',
    postal_town: 'Milton Keynes',
  },
  {
    postcode: 'MK4',
    uk_region: 'South East',
    postal_town: 'Milton Keynes',
  },
  {
    postcode: 'MK5',
    uk_region: 'South East',
    postal_town: 'Milton Keynes',
  },
  {
    postcode: 'MK6',
    uk_region: 'South East',
    postal_town: 'Milton Keynes',
  },
  {
    postcode: 'MK7',
    uk_region: 'South East',
    postal_town: 'Milton Keynes',
  },
  {
    postcode: 'MK8',
    uk_region: 'South East',
    postal_town: 'Milton Keynes',
  },
  {
    postcode: 'MK9',
    uk_region: 'South East',
    postal_town: 'Milton Keynes',
  },
  {
    postcode: 'MK10',
    uk_region: 'South East',
    postal_town: 'Milton Keynes',
  },
  {
    postcode: 'MK11',
    uk_region: 'South East',
    postal_town: 'Milton Keynes',
  },
  {
    postcode: 'MK12',
    uk_region: 'South East',
    postal_town: 'Milton Keynes',
  },
  {
    postcode: 'MK13',
    uk_region: 'South East',
    postal_town: 'Milton Keynes',
  },
  {
    postcode: 'MK14',
    uk_region: 'South East',
    postal_town: 'Milton Keynes',
  },
  {
    postcode: 'MK15',
    uk_region: 'South East',
    postal_town: 'Milton Keynes',
  },
  {
    postcode: 'MK16',
    uk_region: 'South East',
    postal_town: 'Newport Pagnell',
  },
  {
    postcode: 'MK17',
    uk_region: 'South East',
    postal_town: 'Milton Keynes',
  },
  {
    postcode: 'MK18',
    uk_region: 'South East',
    postal_town: 'Buckingham',
  },
  {
    postcode: 'MK19',
    uk_region: 'South East',
    postal_town: 'Milton Keynes',
  },
  {
    postcode: 'MK40',
    uk_region: 'East of England',
    postal_town: 'Bedford',
  },
  {
    postcode: 'MK41',
    uk_region: 'East of England',
    postal_town: 'Bedford',
  },
  {
    postcode: 'MK42',
    uk_region: 'East of England',
    postal_town: 'Bedford',
  },
  {
    postcode: 'MK43',
    uk_region: 'East of England',
    postal_town: 'Bedford',
  },
  {
    postcode: 'MK44',
    uk_region: 'East of England',
    postal_town: 'Bedford',
  },
  {
    postcode: 'MK45',
    uk_region: 'East of England',
    postal_town: 'Bedford',
  },
  {
    postcode: 'MK46',
    uk_region: 'South East',
    postal_town: 'Olney',
  },
  {
    postcode: 'MK77',
    uk_region: 'South East',
    postal_town: 'Milton Keynes',
  },
  {
    postcode: 'MK98',
    uk_region: 'South East',
    postal_town: '',
  },
  {
    postcode: 'ML1',
    uk_region: 'Scotland',
    postal_town: 'Motherwell',
  },
  {
    postcode: 'ML2',
    uk_region: 'Scotland',
    postal_town: 'Wishaw',
  },
  {
    postcode: 'ML3',
    uk_region: 'Scotland',
    postal_town: 'Hamilton',
  },
  {
    postcode: 'ML4',
    uk_region: 'Scotland',
    postal_town: 'Bellshill',
  },
  {
    postcode: 'ML5',
    uk_region: 'Scotland',
    postal_town: 'Coatbridge',
  },
  {
    postcode: 'ML6',
    uk_region: 'Scotland',
    postal_town: 'Airdrie',
  },
  {
    postcode: 'ML7',
    uk_region: 'Scotland',
    postal_town: 'Shotts',
  },
  {
    postcode: 'ML8',
    uk_region: 'Scotland',
    postal_town: 'Carluke',
  },
  {
    postcode: 'ML9',
    uk_region: 'Scotland',
    postal_town: 'Larkhall',
  },
  {
    postcode: 'ML10',
    uk_region: 'Scotland',
    postal_town: 'Strathaven',
  },
  {
    postcode: 'ML11',
    uk_region: 'Scotland',
    postal_town: 'Lanark',
  },
  {
    postcode: 'ML12',
    uk_region: 'Scotland',
    postal_town: 'Biggar',
  },
  {
    postcode: 'N1',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'N1C',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'N1P',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'N2',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'N3',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'N4',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'N5',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'N6',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'N7',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'N8',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'N9',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'N10',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'N11',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'N12',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'N13',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'N14',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'N15',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'N16',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'N17',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'N18',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'N19',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'N20',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'N21',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'N22',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'N81',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'NE1',
    uk_region: 'North East',
    postal_town: 'Newcastle upon Tyne',
  },
  {
    postcode: 'NE2',
    uk_region: 'North East',
    postal_town: 'Newcastle upon Tyne',
  },
  {
    postcode: 'NE3',
    uk_region: 'North East',
    postal_town: 'Newcastle upon Tyne',
  },
  {
    postcode: 'NE4',
    uk_region: 'North East',
    postal_town: 'Newcastle upon Tyne',
  },
  {
    postcode: 'NE5',
    uk_region: 'North East',
    postal_town: 'Newcastle upon Tyne',
  },
  {
    postcode: 'NE6',
    uk_region: 'North East',
    postal_town: 'Newcastle upon Tyne',
  },
  {
    postcode: 'NE7',
    uk_region: 'North East',
    postal_town: 'Newcastle upon Tyne',
  },
  {
    postcode: 'NE8',
    uk_region: 'North East',
    postal_town: 'Gateshead',
  },
  {
    postcode: 'NE9',
    uk_region: 'North East',
    postal_town: 'Gateshead',
  },
  {
    postcode: 'NE10',
    uk_region: 'North East',
    postal_town: 'Gateshead',
  },
  {
    postcode: 'NE11',
    uk_region: 'North East',
    postal_town: 'Gateshead',
  },
  {
    postcode: 'NE12',
    uk_region: 'North East',
    postal_town: 'Newcastle upon Tyne',
  },
  {
    postcode: 'NE13',
    uk_region: 'North East',
    postal_town: 'Newcastle upon Tyne',
  },
  {
    postcode: 'NE15',
    uk_region: 'North East',
    postal_town: 'Newcastle upon Tyne',
  },
  {
    postcode: 'NE16',
    uk_region: 'North East',
    postal_town: 'Newcastle upon Tyne',
  },
  {
    postcode: 'NE17',
    uk_region: 'North East',
    postal_town: 'Newcastle upon Tyne',
  },
  {
    postcode: 'NE18',
    uk_region: 'North East',
    postal_town: 'Newcastle upon Tyne',
  },
  {
    postcode: 'NE19',
    uk_region: 'North East',
    postal_town: 'Newcastle upon Tyne',
  },
  {
    postcode: 'NE20',
    uk_region: 'North East',
    postal_town: 'Newcastle upon Tyne',
  },
  {
    postcode: 'NE21',
    uk_region: 'North East',
    postal_town: 'Blaydon-on-Tyne',
  },
  {
    postcode: 'NE22',
    uk_region: 'North East',
    postal_town: 'Bedlington',
  },
  {
    postcode: 'NE23',
    uk_region: 'North East',
    postal_town: 'Cramlington',
  },
  {
    postcode: 'NE24',
    uk_region: 'North East',
    postal_town: 'Blyth',
  },
  {
    postcode: 'NE25',
    uk_region: 'North East',
    postal_town: 'Whitley Bay',
  },
  {
    postcode: 'NE26',
    uk_region: 'North East',
    postal_town: 'Whitley Bay',
  },
  {
    postcode: 'NE27',
    uk_region: 'North East',
    postal_town: 'Newcastle upon Tyne',
  },
  {
    postcode: 'NE28',
    uk_region: 'North East',
    postal_town: 'Wallsend',
  },
  {
    postcode: 'NE29',
    uk_region: 'North East',
    postal_town: 'North Shields',
  },
  {
    postcode: 'NE30',
    uk_region: 'North East',
    postal_town: 'North Shields',
  },
  {
    postcode: 'NE31',
    uk_region: 'North East',
    postal_town: 'Hebburn',
  },
  {
    postcode: 'NE32',
    uk_region: 'North East',
    postal_town: 'Jarrow',
  },
  {
    postcode: 'NE33',
    uk_region: 'North East',
    postal_town: 'South Shields',
  },
  {
    postcode: 'NE34',
    uk_region: 'North East',
    postal_town: 'South Shields',
  },
  {
    postcode: 'NE35',
    uk_region: 'North East',
    postal_town: 'Boldon Colliery',
  },
  {
    postcode: 'NE36',
    uk_region: 'North East',
    postal_town: 'East Boldon',
  },
  {
    postcode: 'NE37',
    uk_region: 'North East',
    postal_town: 'Washington',
  },
  {
    postcode: 'NE38',
    uk_region: 'North East',
    postal_town: 'Washington',
  },
  {
    postcode: 'NE39',
    uk_region: 'North East',
    postal_town: 'Rowlands Gill',
  },
  {
    postcode: 'NE40',
    uk_region: 'North East',
    postal_town: 'Ryton',
  },
  {
    postcode: 'NE41',
    uk_region: 'North East',
    postal_town: 'Wylam',
  },
  {
    postcode: 'NE42',
    uk_region: 'North East',
    postal_town: 'Prudhoe',
  },
  {
    postcode: 'NE43',
    uk_region: 'North East',
    postal_town: 'Stocksfield',
  },
  {
    postcode: 'NE44',
    uk_region: 'North East',
    postal_town: 'Riding Mill',
  },
  {
    postcode: 'NE45',
    uk_region: 'North East',
    postal_town: 'Corbridge',
  },
  {
    postcode: 'NE46',
    uk_region: 'North East',
    postal_town: 'Hexham',
  },
  {
    postcode: 'NE47',
    uk_region: 'North East',
    postal_town: 'Hexham',
  },
  {
    postcode: 'NE48',
    uk_region: 'North East',
    postal_town: 'Hexham',
  },
  {
    postcode: 'NE49',
    uk_region: 'North East',
    postal_town: 'Haltwhistle',
  },
  {
    postcode: 'NE61',
    uk_region: 'North East',
    postal_town: 'Morpeth',
  },
  {
    postcode: 'NE62',
    uk_region: 'North East',
    postal_town: 'Choppington',
  },
  {
    postcode: 'NE63',
    uk_region: 'North East',
    postal_town: 'Ashington',
  },
  {
    postcode: 'NE64',
    uk_region: 'North East',
    postal_town: 'Newbiggin-by-the-Sea',
  },
  {
    postcode: 'NE65',
    uk_region: 'North East',
    postal_town: 'Morpeth',
  },
  {
    postcode: 'NE66',
    uk_region: 'North East',
    postal_town: '"Alnwick, Bamburgh"',
  },
  {
    postcode: 'NE67',
    uk_region: 'North East',
    postal_town: 'Chathill',
  },
  {
    postcode: 'NE68',
    uk_region: 'North East',
    postal_town: 'Seahouses',
  },
  {
    postcode: 'NE69',
    uk_region: 'North East',
    postal_town: 'Bamburgh',
  },
  {
    postcode: 'NE70',
    uk_region: 'North East',
    postal_town: 'Belford',
  },
  {
    postcode: 'NE71',
    uk_region: 'North East',
    postal_town: 'Wooler',
  },
  {
    postcode: 'NE82',
    uk_region: 'North East',
    postal_town: 'Newcastle upon Tyne',
  },
  {
    postcode: 'NE83',
    uk_region: 'North East',
    postal_town: 'Newcastle upon Tyne',
  },
  {
    postcode: 'NE85',
    uk_region: 'North East',
    postal_town: 'Newcastle upon Tyne',
  },
  {
    postcode: 'NE88',
    uk_region: 'North East',
    postal_town: 'Newcastle upon Tyne',
  },
  {
    postcode: 'NE89',
    uk_region: 'North East',
    postal_town: '',
  },
  {
    postcode: 'NE92',
    uk_region: 'North East',
    postal_town: 'Gateshead',
  },
  {
    postcode: 'NE98',
    uk_region: 'North East',
    postal_town: 'Newcastle upon Tyne',
  },
  {
    postcode: 'NE99',
    uk_region: 'North East',
    postal_town: 'Newcastle upon Tyne',
  },
  {
    postcode: 'NG1',
    uk_region: 'East Midlands',
    postal_town: 'Nottingham',
  },
  {
    postcode: 'NG2',
    uk_region: 'East Midlands',
    postal_town: 'Nottingham',
  },
  {
    postcode: 'NG3',
    uk_region: 'East Midlands',
    postal_town: 'Nottingham',
  },
  {
    postcode: 'NG4',
    uk_region: 'East Midlands',
    postal_town: 'Nottingham',
  },
  {
    postcode: 'NG5',
    uk_region: 'East Midlands',
    postal_town: 'Nottingham',
  },
  {
    postcode: 'NG6',
    uk_region: 'East Midlands',
    postal_town: 'Nottingham',
  },
  {
    postcode: 'NG7',
    uk_region: 'East Midlands',
    postal_town: 'Nottingham',
  },
  {
    postcode: 'NG8',
    uk_region: 'East Midlands',
    postal_town: 'Nottingham',
  },
  {
    postcode: 'NG9',
    uk_region: 'East Midlands',
    postal_town: 'Nottingham',
  },
  {
    postcode: 'NG10',
    uk_region: 'East Midlands',
    postal_town: 'Nottingham',
  },
  {
    postcode: 'NG11',
    uk_region: 'East Midlands',
    postal_town: 'Nottingham',
  },
  {
    postcode: 'NG12',
    uk_region: 'East Midlands',
    postal_town: 'Nottingham',
  },
  {
    postcode: 'NG13',
    uk_region: 'East Midlands',
    postal_town: 'Nottingham',
  },
  {
    postcode: 'NG14',
    uk_region: 'East Midlands',
    postal_town: 'Nottingham',
  },
  {
    postcode: 'NG15',
    uk_region: 'East Midlands',
    postal_town: 'Nottingham',
  },
  {
    postcode: 'NG16',
    uk_region: 'East Midlands',
    postal_town: 'Nottingham',
  },
  {
    postcode: 'NG17',
    uk_region: 'East Midlands',
    postal_town: '"Nottingham, Sutton-in-Ashfield"',
  },
  {
    postcode: 'NG18',
    uk_region: 'East Midlands',
    postal_town: 'Mansfield',
  },
  {
    postcode: 'NG19',
    uk_region: 'East Midlands',
    postal_town: 'Mansfield',
  },
  {
    postcode: 'NG20',
    uk_region: 'East Midlands',
    postal_town: 'Mansfield',
  },
  {
    postcode: 'NG21',
    uk_region: 'East Midlands',
    postal_town: 'Mansfield',
  },
  {
    postcode: 'NG22',
    uk_region: 'East Midlands',
    postal_town: 'Newark',
  },
  {
    postcode: 'NG23',
    uk_region: 'East Midlands',
    postal_town: 'Newark',
  },
  {
    postcode: 'NG24',
    uk_region: 'East Midlands',
    postal_town: 'Newark',
  },
  {
    postcode: 'NG25',
    uk_region: 'East Midlands',
    postal_town: 'Southwell',
  },
  {
    postcode: 'NG31',
    uk_region: 'East Midlands',
    postal_town: 'Grantham',
  },
  {
    postcode: 'NG32',
    uk_region: 'East Midlands',
    postal_town: 'Grantham',
  },
  {
    postcode: 'NG33',
    uk_region: 'East Midlands',
    postal_town: 'Grantham',
  },
  {
    postcode: 'NG34',
    uk_region: 'East Midlands',
    postal_town: 'Sleaford',
  },
  {
    postcode: 'NG70',
    uk_region: 'East Midlands',
    postal_town: 'Mansfield',
  },
  {
    postcode: 'NG80',
    uk_region: 'East Midlands',
    postal_town: 'Nottingham',
  },
  {
    postcode: 'NG90',
    uk_region: 'East Midlands',
    postal_town: 'Nottingham',
  },
  {
    postcode: 'NN1',
    uk_region: 'East Midlands',
    postal_town: 'Northampton',
  },
  {
    postcode: 'NN2',
    uk_region: 'East Midlands',
    postal_town: 'Northampton',
  },
  {
    postcode: 'NN3',
    uk_region: 'East Midlands',
    postal_town: 'Northampton',
  },
  {
    postcode: 'NN4',
    uk_region: 'East Midlands',
    postal_town: 'Northampton',
  },
  {
    postcode: 'NN5',
    uk_region: 'East Midlands',
    postal_town: 'Northampton',
  },
  {
    postcode: 'NN6',
    uk_region: 'East Midlands',
    postal_town: 'Northampton',
  },
  {
    postcode: 'NN7',
    uk_region: 'East Midlands',
    postal_town: 'Northampton',
  },
  {
    postcode: 'NN8',
    uk_region: 'East Midlands',
    postal_town: 'Wellingborough',
  },
  {
    postcode: 'NN9',
    uk_region: 'East Midlands',
    postal_town: 'Wellingborough',
  },
  {
    postcode: 'NN10',
    uk_region: 'East Midlands',
    postal_town: 'Rushden',
  },
  {
    postcode: 'NN11',
    uk_region: 'East Midlands',
    postal_town: 'Daventry',
  },
  {
    postcode: 'NN12',
    uk_region: 'East Midlands',
    postal_town: 'Towcester',
  },
  {
    postcode: 'NN13',
    uk_region: 'East Midlands',
    postal_town: 'Brackley',
  },
  {
    postcode: 'NN14',
    uk_region: 'East Midlands',
    postal_town: 'Kettering',
  },
  {
    postcode: 'NN15',
    uk_region: 'East Midlands',
    postal_town: 'Kettering',
  },
  {
    postcode: 'NN16',
    uk_region: 'East Midlands',
    postal_town: 'Kettering',
  },
  {
    postcode: 'NN17',
    uk_region: 'East Midlands',
    postal_town: 'Corby',
  },
  {
    postcode: 'NN18',
    uk_region: 'East Midlands',
    postal_town: 'Corby',
  },
  {
    postcode: 'NN29',
    uk_region: 'East Midlands',
    postal_town: 'Wellingborough',
  },
  {
    postcode: 'NN99',
    uk_region: 'East Midlands',
    postal_town: '',
  },
  {
    postcode: 'NPT',
    uk_region: 'Wales',
    postal_town: '',
  },
  {
    postcode: 'NP1',
    uk_region: 'Wales',
    postal_town: '',
  },
  {
    postcode: 'NP2',
    uk_region: 'Wales',
    postal_town: '',
  },
  {
    postcode: 'NP3',
    uk_region: 'Wales',
    postal_town: '',
  },
  {
    postcode: 'NP4',
    uk_region: 'Wales',
    postal_town: 'Pontypool',
  },
  {
    postcode: 'NP5',
    uk_region: 'Wales',
    postal_town: '',
  },
  {
    postcode: 'NP6',
    uk_region: 'Wales',
    postal_town: '',
  },
  {
    postcode: 'NP7',
    uk_region: 'Wales',
    postal_town: '"Abergavenny, Crickhowell"',
  },
  {
    postcode: 'NP8',
    uk_region: 'Wales',
    postal_town: 'Crickhowell',
  },
  {
    postcode: 'NP9',
    uk_region: 'Wales',
    postal_town: '',
  },
  {
    postcode: 'NP10',
    uk_region: 'Wales',
    postal_town: 'Newport',
  },
  {
    postcode: 'NP11',
    uk_region: 'Wales',
    postal_town: 'Newport',
  },
  {
    postcode: 'NP12',
    uk_region: 'Wales',
    postal_town: 'Blackwood',
  },
  {
    postcode: 'NP13',
    uk_region: 'Wales',
    postal_town: 'Abertillery',
  },
  {
    postcode: 'NP15',
    uk_region: 'Wales',
    postal_town: 'Usk',
  },
  {
    postcode: 'NP16',
    uk_region: 'Wales',
    postal_town: 'Chepstow',
  },
  {
    postcode: 'NP18',
    uk_region: 'Wales',
    postal_town: 'Newport',
  },
  {
    postcode: 'NP19',
    uk_region: 'Wales',
    postal_town: 'Newport',
  },
  {
    postcode: 'NP20',
    uk_region: 'Wales',
    postal_town: 'Newport',
  },
  {
    postcode: 'NP22',
    uk_region: 'Wales',
    postal_town: 'Tredegar',
  },
  {
    postcode: 'NP23',
    uk_region: 'Wales',
    postal_town: 'Ebbw Vale',
  },
  {
    postcode: 'NP24',
    uk_region: 'Wales',
    postal_town: 'New Tredegar',
  },
  {
    postcode: 'NP25',
    uk_region: 'Wales',
    postal_town: 'Monmouth',
  },
  {
    postcode: 'NP26',
    uk_region: 'Wales',
    postal_town: 'Caldicot',
  },
  {
    postcode: 'NP44',
    uk_region: 'Wales',
    postal_town: 'Cwmbran',
  },
  {
    postcode: 'NR1',
    uk_region: 'East of England',
    postal_town: 'Norwich',
  },
  {
    postcode: 'NR2',
    uk_region: 'East of England',
    postal_town: 'Norwich',
  },
  {
    postcode: 'NR3',
    uk_region: 'East of England',
    postal_town: 'Norwich',
  },
  {
    postcode: 'NR4',
    uk_region: 'East of England',
    postal_town: 'Norwich',
  },
  {
    postcode: 'NR5',
    uk_region: 'East of England',
    postal_town: 'Norwich',
  },
  {
    postcode: 'NR6',
    uk_region: 'East of England',
    postal_town: 'Norwich',
  },
  {
    postcode: 'NR7',
    uk_region: 'East of England',
    postal_town: 'Norwich',
  },
  {
    postcode: 'NR8',
    uk_region: 'East of England',
    postal_town: 'Norwich',
  },
  {
    postcode: 'NR9',
    uk_region: 'East of England',
    postal_town: 'Norwich',
  },
  {
    postcode: 'NR10',
    uk_region: 'East of England',
    postal_town: 'Norwich',
  },
  {
    postcode: 'NR11',
    uk_region: 'East of England',
    postal_town: 'Norwich',
  },
  {
    postcode: 'NR12',
    uk_region: 'East of England',
    postal_town: 'Norwich',
  },
  {
    postcode: 'NR13',
    uk_region: 'East of England',
    postal_town: 'Norwich',
  },
  {
    postcode: 'NR14',
    uk_region: 'East of England',
    postal_town: 'Norwich',
  },
  {
    postcode: 'NR15',
    uk_region: 'East of England',
    postal_town: 'Norwich',
  },
  {
    postcode: 'NR16',
    uk_region: 'East of England',
    postal_town: 'Norwich',
  },
  {
    postcode: 'NR17',
    uk_region: 'East of England',
    postal_town: 'Attleborough',
  },
  {
    postcode: 'NR18',
    uk_region: 'East of England',
    postal_town: '"Norwich, Wymondham"',
  },
  {
    postcode: 'NR19',
    uk_region: 'East of England',
    postal_town: '"Norwich, Dereham"',
  },
  {
    postcode: 'NR20',
    uk_region: 'East of England',
    postal_town: 'Dereham',
  },
  {
    postcode: 'NR21',
    uk_region: 'East of England',
    postal_town: 'Fakenham',
  },
  {
    postcode: 'NR22',
    uk_region: 'East of England',
    postal_town: 'Walsingham',
  },
  {
    postcode: 'NR23',
    uk_region: 'East of England',
    postal_town: 'Wells-next-the-Sea',
  },
  {
    postcode: 'NR24',
    uk_region: 'East of England',
    postal_town: 'Melton Constable',
  },
  {
    postcode: 'NR25',
    uk_region: 'East of England',
    postal_town: 'Holt',
  },
  {
    postcode: 'NR26',
    uk_region: 'East of England',
    postal_town: '"Norwich, Sheringham"',
  },
  {
    postcode: 'NR27',
    uk_region: 'East of England',
    postal_town: 'Cromer',
  },
  {
    postcode: 'NR28',
    uk_region: 'East of England',
    postal_town: '"Norwich, North Walsham"',
  },
  {
    postcode: 'NR29',
    uk_region: 'East of England',
    postal_town: 'Great Yarmouth',
  },
  {
    postcode: 'NR30',
    uk_region: 'East of England',
    postal_town: 'Great Yarmouth',
  },
  {
    postcode: 'NR31',
    uk_region: 'East of England',
    postal_town: 'Great Yarmouth',
  },
  {
    postcode: 'NR32',
    uk_region: 'East of England',
    postal_town: 'Lowestoft',
  },
  {
    postcode: 'NR33',
    uk_region: 'East of England',
    postal_town: 'Lowestoft',
  },
  {
    postcode: 'NR34',
    uk_region: 'East of England',
    postal_town: 'Beccles',
  },
  {
    postcode: 'NR35',
    uk_region: 'East of England',
    postal_town: 'Bungay',
  },
  {
    postcode: 'NR99',
    uk_region: 'East of England',
    postal_town: 'Norwich',
  },
  {
    postcode: 'NW1',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'NW1W',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'NW2',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'NW3',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'NW4',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'NW5',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'NW6',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'NW7',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'NW8',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'NW9',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'NW10',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'NW11',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'NW26',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'OL1',
    uk_region: 'North West',
    postal_town: 'Oldham',
  },
  {
    postcode: 'OL2',
    uk_region: 'North West',
    postal_town: 'Oldham',
  },
  {
    postcode: 'OL3',
    uk_region: 'North West',
    postal_town: 'Oldham',
  },
  {
    postcode: 'OL4',
    uk_region: 'North West',
    postal_town: 'Oldham',
  },
  {
    postcode: 'OL5',
    uk_region: 'North West',
    postal_town: 'Ashton-under-Lyne',
  },
  {
    postcode: 'OL6',
    uk_region: 'North West',
    postal_town: 'Ashton-under-Lyne',
  },
  {
    postcode: 'OL7',
    uk_region: 'North West',
    postal_town: 'Ashton-under-Lyne',
  },
  {
    postcode: 'OL8',
    uk_region: 'North West',
    postal_town: 'Oldham',
  },
  {
    postcode: 'OL9',
    uk_region: 'North West',
    postal_town: 'Oldham',
  },
  {
    postcode: 'OL10',
    uk_region: 'North West',
    postal_town: 'Heywood',
  },
  {
    postcode: 'OL11',
    uk_region: 'North West',
    postal_town: 'Rochdale',
  },
  {
    postcode: 'OL12',
    uk_region: 'North West',
    postal_town: 'Rochdale',
  },
  {
    postcode: 'OL13',
    uk_region: 'North West',
    postal_town: 'Bacup',
  },
  {
    postcode: 'OL14',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Todmorden',
  },
  {
    postcode: 'OL15',
    uk_region: 'North West',
    postal_town: 'Littleborough',
  },
  {
    postcode: 'OL16',
    uk_region: 'North West',
    postal_town: '"Rochdale, Littleborough"',
  },
  {
    postcode: 'OL95',
    uk_region: 'North West',
    postal_town: 'Oldham',
  },
  {
    postcode: 'OX1',
    uk_region: 'South East',
    postal_town: 'Oxford',
  },
  {
    postcode: 'OX2',
    uk_region: 'South East',
    postal_town: 'Oxford',
  },
  {
    postcode: 'OX3',
    uk_region: 'South East',
    postal_town: 'Oxford',
  },
  {
    postcode: 'OX4',
    uk_region: 'South East',
    postal_town: 'Oxford',
  },
  {
    postcode: 'OX5',
    uk_region: 'South East',
    postal_town: 'Kidlington',
  },
  {
    postcode: 'OX6',
    uk_region: 'South East',
    postal_town: '',
  },
  {
    postcode: 'OX7',
    uk_region: 'South East',
    postal_town: 'Chipping Norton',
  },
  {
    postcode: 'OX8',
    uk_region: 'South East',
    postal_town: '',
  },
  {
    postcode: 'OX9',
    uk_region: 'South East',
    postal_town: 'Thame',
  },
  {
    postcode: 'OX10',
    uk_region: 'South East',
    postal_town: 'Wallingford',
  },
  {
    postcode: 'OX11',
    uk_region: 'South East',
    postal_town: 'Didcot',
  },
  {
    postcode: 'OX12',
    uk_region: 'South East',
    postal_town: 'Wantage',
  },
  {
    postcode: 'OX13',
    uk_region: 'South East',
    postal_town: 'Abingdon',
  },
  {
    postcode: 'OX14',
    uk_region: 'South East',
    postal_town: 'Abingdon',
  },
  {
    postcode: 'OX15',
    uk_region: 'South East',
    postal_town: 'Banbury',
  },
  {
    postcode: 'OX16',
    uk_region: 'South East',
    postal_town: 'Banbury',
  },
  {
    postcode: 'OX17',
    uk_region: 'East Midlands',
    postal_town: 'Banbury',
  },
  {
    postcode: 'OX18',
    uk_region: 'South East',
    postal_town: '"Bampton, Burford, Carterton"',
  },
  {
    postcode: 'OX20',
    uk_region: 'South East',
    postal_town: 'Woodstock',
  },
  {
    postcode: 'OX25',
    uk_region: 'South East',
    postal_town: 'Bicester',
  },
  {
    postcode: 'OX26',
    uk_region: 'South East',
    postal_town: 'Bicester',
  },
  {
    postcode: 'OX27',
    uk_region: 'South East',
    postal_town: 'Bicester',
  },
  {
    postcode: 'OX28',
    uk_region: 'South East',
    postal_town: 'Witney',
  },
  {
    postcode: 'OX29',
    uk_region: 'South East',
    postal_town: 'Witney',
  },
  {
    postcode: 'OX33',
    uk_region: 'South East',
    postal_town: 'Oxford',
  },
  {
    postcode: 'OX39',
    uk_region: 'South East',
    postal_town: 'Chinnor',
  },
  {
    postcode: 'OX44',
    uk_region: 'South East',
    postal_town: 'Oxford',
  },
  {
    postcode: 'OX49',
    uk_region: 'South East',
    postal_town: 'Watlington',
  },
  {
    postcode: 'PA1',
    uk_region: 'Scotland',
    postal_town: 'Paisley',
  },
  {
    postcode: 'PA2',
    uk_region: 'Scotland',
    postal_town: 'Paisley',
  },
  {
    postcode: 'PA3',
    uk_region: 'Scotland',
    postal_town: 'Paisley',
  },
  {
    postcode: 'PA4',
    uk_region: 'Scotland',
    postal_town: 'Renfrew',
  },
  {
    postcode: 'PA5',
    uk_region: 'Scotland',
    postal_town: 'Johnstone',
  },
  {
    postcode: 'PA6',
    uk_region: 'Scotland',
    postal_town: 'Johnstone',
  },
  {
    postcode: 'PA7',
    uk_region: 'Scotland',
    postal_town: 'Bishopton',
  },
  {
    postcode: 'PA8',
    uk_region: 'Scotland',
    postal_town: 'Erskine',
  },
  {
    postcode: 'PA9',
    uk_region: 'Scotland',
    postal_town: 'Johnstone',
  },
  {
    postcode: 'PA10',
    uk_region: 'Scotland',
    postal_town: 'Johnstone',
  },
  {
    postcode: 'PA11',
    uk_region: 'Scotland',
    postal_town: 'Bridge of Weir',
  },
  {
    postcode: 'PA12',
    uk_region: 'Scotland',
    postal_town: 'Lochwinnoch',
  },
  {
    postcode: 'PA13',
    uk_region: 'Scotland',
    postal_town: 'Kilmacolm',
  },
  {
    postcode: 'PA14',
    uk_region: 'Scotland',
    postal_town: 'Port Glasgow',
  },
  {
    postcode: 'PA15',
    uk_region: 'Scotland',
    postal_town: 'Greenock',
  },
  {
    postcode: 'PA16',
    uk_region: 'Scotland',
    postal_town: 'Greenock',
  },
  {
    postcode: 'PA17',
    uk_region: 'Scotland',
    postal_town: 'Skelmorlie',
  },
  {
    postcode: 'PA18',
    uk_region: 'Scotland',
    postal_town: 'Wemyss Bay',
  },
  {
    postcode: 'PA19',
    uk_region: 'Scotland',
    postal_town: 'Gourock',
  },
  {
    postcode: 'PA20',
    uk_region: 'Scotland',
    postal_town: 'Isle of Bute',
  },
  {
    postcode: 'PA21',
    uk_region: 'Scotland',
    postal_town: 'Tighnabruaich',
  },
  {
    postcode: 'PA22',
    uk_region: 'Scotland',
    postal_town: 'Colintraive',
  },
  {
    postcode: 'PA23',
    uk_region: 'Scotland',
    postal_town: 'Dunoon',
  },
  {
    postcode: 'PA24',
    uk_region: 'Scotland',
    postal_town: 'Cairndow',
  },
  {
    postcode: 'PA25',
    uk_region: 'Scotland',
    postal_town: 'Cairndow',
  },
  {
    postcode: 'PA26',
    uk_region: 'Scotland',
    postal_town: 'Cairndow',
  },
  {
    postcode: 'PA27',
    uk_region: 'Scotland',
    postal_town: 'Cairndow',
  },
  {
    postcode: 'PA28',
    uk_region: 'Scotland',
    postal_town: 'Campbeltown',
  },
  {
    postcode: 'PA29',
    uk_region: 'Scotland',
    postal_town: 'Tarbert',
  },
  {
    postcode: 'PA30',
    uk_region: 'Scotland',
    postal_town: 'Lochgilphead',
  },
  {
    postcode: 'PA31',
    uk_region: 'Scotland',
    postal_town: 'Lochgilphead',
  },
  {
    postcode: 'PA32',
    uk_region: 'Scotland',
    postal_town: 'Inveraray',
  },
  {
    postcode: 'PA33',
    uk_region: 'Scotland',
    postal_town: 'Dalmally',
  },
  {
    postcode: 'PA34',
    uk_region: 'Scotland',
    postal_town: 'Oban',
  },
  {
    postcode: 'PA35',
    uk_region: 'Scotland',
    postal_town: 'Taynuilt',
  },
  {
    postcode: 'PA36',
    uk_region: 'Scotland',
    postal_town: 'Bridge of Orchy',
  },
  {
    postcode: 'PA37',
    uk_region: 'Scotland',
    postal_town: 'Oban',
  },
  {
    postcode: 'PA38',
    uk_region: 'Scotland',
    postal_town: 'Appin',
  },
  {
    postcode: 'PA39',
    uk_region: 'Scotland',
    postal_town: '',
  },
  {
    postcode: 'PA40',
    uk_region: 'Scotland',
    postal_town: '',
  },
  {
    postcode: 'PA41',
    uk_region: 'Scotland',
    postal_town: 'Isle of Gigha',
  },
  {
    postcode: 'PA42',
    uk_region: 'Scotland',
    postal_town: 'Isle of Islay',
  },
  {
    postcode: 'PA43',
    uk_region: 'Scotland',
    postal_town: 'Isle of Islay',
  },
  {
    postcode: 'PA44',
    uk_region: 'Scotland',
    postal_town: 'Isle of Islay',
  },
  {
    postcode: 'PA45',
    uk_region: 'Scotland',
    postal_town: 'Isle of Islay',
  },
  {
    postcode: 'PA46',
    uk_region: 'Scotland',
    postal_town: 'Isle of Islay',
  },
  {
    postcode: 'PA47',
    uk_region: 'Scotland',
    postal_town: 'Isle of Islay',
  },
  {
    postcode: 'PA48',
    uk_region: 'Scotland',
    postal_town: 'Isle of Islay',
  },
  {
    postcode: 'PA49',
    uk_region: 'Scotland',
    postal_town: 'Isle of Islay',
  },
  {
    postcode: 'PA60',
    uk_region: 'Scotland',
    postal_town: 'Isle of Jura',
  },
  {
    postcode: 'PA61',
    uk_region: 'Scotland',
    postal_town: 'Isle of Colonsay',
  },
  {
    postcode: 'PA62',
    uk_region: 'Scotland',
    postal_town: 'Isle of Mull',
  },
  {
    postcode: 'PA63',
    uk_region: 'Scotland',
    postal_town: 'Isle of Mull',
  },
  {
    postcode: 'PA64',
    uk_region: 'Scotland',
    postal_town: 'Isle of Mull',
  },
  {
    postcode: 'PA65',
    uk_region: 'Scotland',
    postal_town: 'Isle of Mull',
  },
  {
    postcode: 'PA66',
    uk_region: 'Scotland',
    postal_town: 'Isle of Mull',
  },
  {
    postcode: 'PA67',
    uk_region: 'Scotland',
    postal_town: 'Isle of Mull',
  },
  {
    postcode: 'PA68',
    uk_region: 'Scotland',
    postal_town: 'Isle of Mull',
  },
  {
    postcode: 'PA69',
    uk_region: 'Scotland',
    postal_town: 'Isle of Mull',
  },
  {
    postcode: 'PA70',
    uk_region: 'Scotland',
    postal_town: 'Isle of Mull',
  },
  {
    postcode: 'PA71',
    uk_region: 'Scotland',
    postal_town: 'Isle of Mull',
  },
  {
    postcode: 'PA72',
    uk_region: 'Scotland',
    postal_town: 'Isle of Mull',
  },
  {
    postcode: 'PA73',
    uk_region: 'Scotland',
    postal_town: 'Isle of Mull',
  },
  {
    postcode: 'PA74',
    uk_region: 'Scotland',
    postal_town: 'Isle of Mull',
  },
  {
    postcode: 'PA75',
    uk_region: 'Scotland',
    postal_town: 'Isle of Mull',
  },
  {
    postcode: 'PA76',
    uk_region: 'Scotland',
    postal_town: 'Isle of Iona',
  },
  {
    postcode: 'PA77',
    uk_region: 'Scotland',
    postal_town: 'Isle of Tiree',
  },
  {
    postcode: 'PA78',
    uk_region: 'Scotland',
    postal_town: 'Isle of Coll',
  },
  {
    postcode: 'PA80',
    uk_region: 'Scotland',
    postal_town: 'Oban',
  },
  {
    postcode: 'PA81',
    uk_region: 'Scotland',
    postal_town: '',
  },
  {
    postcode: 'PA82',
    uk_region: 'Scotland',
    postal_town: '',
  },
  {
    postcode: 'PA83',
    uk_region: 'Scotland',
    postal_town: '',
  },
  {
    postcode: 'PA84',
    uk_region: 'Scotland',
    postal_town: '',
  },
  {
    postcode: 'PA85',
    uk_region: 'Scotland',
    postal_town: '',
  },
  {
    postcode: 'PA86',
    uk_region: 'Scotland',
    postal_town: '',
  },
  {
    postcode: 'PA87',
    uk_region: 'Scotland',
    postal_town: '',
  },
  {
    postcode: 'PA88',
    uk_region: 'Scotland',
    postal_town: '',
  },
  {
    postcode: 'PA98',
    uk_region: 'Scotland',
    postal_town: '',
  },
  {
    postcode: 'PE1',
    uk_region: 'East of England',
    postal_town: 'Peterborough',
  },
  {
    postcode: 'PE2',
    uk_region: 'East of England',
    postal_town: 'Peterborough',
  },
  {
    postcode: 'PE3',
    uk_region: 'East of England',
    postal_town: 'Peterborough',
  },
  {
    postcode: 'PE4',
    uk_region: 'East of England',
    postal_town: 'Peterborough',
  },
  {
    postcode: 'PE5',
    uk_region: 'East of England',
    postal_town: 'Peterborough',
  },
  {
    postcode: 'PE6',
    uk_region: 'East Midlands',
    postal_town: 'Peterborough',
  },
  {
    postcode: 'PE7',
    uk_region: 'East of England',
    postal_town: 'Peterborough',
  },
  {
    postcode: 'PE8',
    uk_region: 'East Midlands',
    postal_town: 'Peterborough',
  },
  {
    postcode: 'PE9',
    uk_region: 'East Midlands',
    postal_town: 'Stamford',
  },
  {
    postcode: 'PE10',
    uk_region: 'East Midlands',
    postal_town: 'Bourne',
  },
  {
    postcode: 'PE11',
    uk_region: 'East Midlands',
    postal_town: 'Spalding',
  },
  {
    postcode: 'PE12',
    uk_region: 'East Midlands',
    postal_town: 'Spalding',
  },
  {
    postcode: 'PE13',
    uk_region: 'East of England',
    postal_town: 'Wisbech',
  },
  {
    postcode: 'PE14',
    uk_region: 'East of England',
    postal_town: 'Wisbech',
  },
  {
    postcode: 'PE15',
    uk_region: 'East of England',
    postal_town: 'March',
  },
  {
    postcode: 'PE16',
    uk_region: 'East of England',
    postal_town: 'Chatteris',
  },
  {
    postcode: 'PE17',
    uk_region: 'East of England',
    postal_town: '',
  },
  {
    postcode: 'PE18',
    uk_region: 'East of England',
    postal_town: '',
  },
  {
    postcode: 'PE19',
    uk_region: 'East of England',
    postal_town: 'St. Neots',
  },
  {
    postcode: 'PE20',
    uk_region: 'East Midlands',
    postal_town: 'Boston',
  },
  {
    postcode: 'PE21',
    uk_region: 'East Midlands',
    postal_town: 'Boston',
  },
  {
    postcode: 'PE22',
    uk_region: 'East Midlands',
    postal_town: 'Boston',
  },
  {
    postcode: 'PE23',
    uk_region: 'East Midlands',
    postal_town: 'Spilsby',
  },
  {
    postcode: 'PE24',
    uk_region: 'East Midlands',
    postal_town: 'Skegness',
  },
  {
    postcode: 'PE25',
    uk_region: 'East Midlands',
    postal_town: 'Skegness',
  },
  {
    postcode: 'PE26',
    uk_region: 'East of England',
    postal_town: 'Huntingdon',
  },
  {
    postcode: 'PE27',
    uk_region: 'East of England',
    postal_town: 'St. Ives',
  },
  {
    postcode: 'PE28',
    uk_region: 'East of England',
    postal_town: 'Huntingdon',
  },
  {
    postcode: 'PE29',
    uk_region: 'East of England',
    postal_town: 'Huntingdon',
  },
  {
    postcode: 'PE30',
    uk_region: 'East of England',
    postal_town: "King's Lynn",
  },
  {
    postcode: 'PE31',
    uk_region: 'East of England',
    postal_town: "King's Lynn",
  },
  {
    postcode: 'PE32',
    uk_region: 'East of England',
    postal_town: "King's Lynn",
  },
  {
    postcode: 'PE33',
    uk_region: 'East of England',
    postal_town: "King's Lynn",
  },
  {
    postcode: 'PE34',
    uk_region: 'East of England',
    postal_town: "King's Lynn",
  },
  {
    postcode: 'PE35',
    uk_region: 'East of England',
    postal_town: 'Sandringham',
  },
  {
    postcode: 'PE36',
    uk_region: 'East of England',
    postal_town: 'Hunstanton',
  },
  {
    postcode: 'PE37',
    uk_region: 'East of England',
    postal_town: 'Swaffham',
  },
  {
    postcode: 'PE38',
    uk_region: 'East of England',
    postal_town: 'Downham Market',
  },
  {
    postcode: 'PE99',
    uk_region: 'East of England',
    postal_town: 'Peterborough',
  },
  {
    postcode: 'PH1',
    uk_region: 'Scotland',
    postal_town: 'Perth',
  },
  {
    postcode: 'PH2',
    uk_region: 'Scotland',
    postal_town: 'Perth',
  },
  {
    postcode: 'PH3',
    uk_region: 'Scotland',
    postal_town: 'Auchterarder',
  },
  {
    postcode: 'PH4',
    uk_region: 'Scotland',
    postal_town: 'Auchterarder',
  },
  {
    postcode: 'PH5',
    uk_region: 'Scotland',
    postal_town: 'Crieff',
  },
  {
    postcode: 'PH6',
    uk_region: 'Scotland',
    postal_town: 'Crieff',
  },
  {
    postcode: 'PH7',
    uk_region: 'Scotland',
    postal_town: 'Crieff',
  },
  {
    postcode: 'PH8',
    uk_region: 'Scotland',
    postal_town: 'Dunkeld',
  },
  {
    postcode: 'PH9',
    uk_region: 'Scotland',
    postal_town: 'Pitlochry',
  },
  {
    postcode: 'PH10',
    uk_region: 'Scotland',
    postal_town: 'Blairgowrie',
  },
  {
    postcode: 'PH11',
    uk_region: 'Scotland',
    postal_town: 'Blairgowrie',
  },
  {
    postcode: 'PH12',
    uk_region: 'Scotland',
    postal_town: 'Blairgowrie',
  },
  {
    postcode: 'PH13',
    uk_region: 'Scotland',
    postal_town: 'Blairgowrie',
  },
  {
    postcode: 'PH14',
    uk_region: 'Scotland',
    postal_town: 'Perth',
  },
  {
    postcode: 'PH15',
    uk_region: 'Scotland',
    postal_town: 'Aberfeldy',
  },
  {
    postcode: 'PH16',
    uk_region: 'Scotland',
    postal_town: 'Pitlochry',
  },
  {
    postcode: 'PH17',
    uk_region: 'Scotland',
    postal_town: 'Pitlochry',
  },
  {
    postcode: 'PH18',
    uk_region: 'Scotland',
    postal_town: 'Pitlochry',
  },
  {
    postcode: 'PH19',
    uk_region: 'Scotland',
    postal_town: 'Dalwhinnie',
  },
  {
    postcode: 'PH20',
    uk_region: 'Scotland',
    postal_town: 'Newtonmore',
  },
  {
    postcode: 'PH21',
    uk_region: 'Scotland',
    postal_town: 'Kingussie',
  },
  {
    postcode: 'PH22',
    uk_region: 'Scotland',
    postal_town: 'Aviemore',
  },
  {
    postcode: 'PH23',
    uk_region: 'Scotland',
    postal_town: 'Carrbridge',
  },
  {
    postcode: 'PH24',
    uk_region: 'Scotland',
    postal_town: 'Boat of Garten',
  },
  {
    postcode: 'PH25',
    uk_region: 'Scotland',
    postal_town: 'Nethy Bridge',
  },
  {
    postcode: 'PH26',
    uk_region: 'Scotland',
    postal_town: 'Grantown-on-Spey',
  },
  {
    postcode: 'PH30',
    uk_region: 'Scotland',
    postal_town: 'Corrour',
  },
  {
    postcode: 'PH31',
    uk_region: 'Scotland',
    postal_town: 'Roy Bridge',
  },
  {
    postcode: 'PH32',
    uk_region: 'Scotland',
    postal_town: 'Fort Augustus',
  },
  {
    postcode: 'PH33',
    uk_region: 'Scotland',
    postal_town: 'Fort William',
  },
  {
    postcode: 'PH34',
    uk_region: 'Scotland',
    postal_town: 'Spean Bridge',
  },
  {
    postcode: 'PH35',
    uk_region: 'Scotland',
    postal_town: 'Invergarry',
  },
  {
    postcode: 'PH36',
    uk_region: 'Scotland',
    postal_town: 'Acharacle',
  },
  {
    postcode: 'PH37',
    uk_region: 'Scotland',
    postal_town: 'Glenfinnan',
  },
  {
    postcode: 'PH38',
    uk_region: 'Scotland',
    postal_town: 'Lochailort',
  },
  {
    postcode: 'PH39',
    uk_region: 'Scotland',
    postal_town: 'Arisaig',
  },
  {
    postcode: 'PH40',
    uk_region: 'Scotland',
    postal_town: 'Mallaig',
  },
  {
    postcode: 'PH41',
    uk_region: 'Scotland',
    postal_town: 'Mallaig',
  },
  {
    postcode: 'PH42',
    uk_region: 'Scotland',
    postal_town: 'Isle of Eigg',
  },
  {
    postcode: 'PH43',
    uk_region: 'Scotland',
    postal_town: 'Isle of Rum',
  },
  {
    postcode: 'PH44',
    uk_region: 'Scotland',
    postal_town: 'Isle of Canna',
  },
  {
    postcode: 'PH49',
    uk_region: 'Scotland',
    postal_town: 'Ballachulish',
  },
  {
    postcode: 'PH50',
    uk_region: 'Scotland',
    postal_town: 'Kinlochleven',
  },
  {
    postcode: 'PL1',
    uk_region: 'South West',
    postal_town: 'Plymouth',
  },
  {
    postcode: 'PL2',
    uk_region: 'South West',
    postal_town: 'Plymouth',
  },
  {
    postcode: 'PL3',
    uk_region: 'South West',
    postal_town: 'Plymouth',
  },
  {
    postcode: 'PL4',
    uk_region: 'South West',
    postal_town: 'Plymouth',
  },
  {
    postcode: 'PL5',
    uk_region: 'South West',
    postal_town: 'Plymouth',
  },
  {
    postcode: 'PL6',
    uk_region: 'South West',
    postal_town: 'Plymouth',
  },
  {
    postcode: 'PL7',
    uk_region: 'South West',
    postal_town: 'Plymouth',
  },
  {
    postcode: 'PL8',
    uk_region: 'South West',
    postal_town: 'Plymouth',
  },
  {
    postcode: 'PL9',
    uk_region: 'South West',
    postal_town: 'Plymouth',
  },
  {
    postcode: 'PL10',
    uk_region: 'South West',
    postal_town: 'Torpoint',
  },
  {
    postcode: 'PL11',
    uk_region: 'South West',
    postal_town: 'Torpoint',
  },
  {
    postcode: 'PL12',
    uk_region: 'South West',
    postal_town: 'Saltash',
  },
  {
    postcode: 'PL13',
    uk_region: 'South West',
    postal_town: 'Looe',
  },
  {
    postcode: 'PL14',
    uk_region: 'South West',
    postal_town: 'Liskeard',
  },
  {
    postcode: 'PL15',
    uk_region: 'South West',
    postal_town: 'Launceston',
  },
  {
    postcode: 'PL16',
    uk_region: 'South West',
    postal_town: 'Lifton',
  },
  {
    postcode: 'PL17',
    uk_region: 'South West',
    postal_town: 'Callington',
  },
  {
    postcode: 'PL18',
    uk_region: 'South West',
    postal_town: '"Calstock, Gunnislake"',
  },
  {
    postcode: 'PL19',
    uk_region: 'South West',
    postal_town: 'Tavistock',
  },
  {
    postcode: 'PL20',
    uk_region: 'South West',
    postal_town: 'Yelverton',
  },
  {
    postcode: 'PL21',
    uk_region: 'South West',
    postal_town: 'Ivybridge',
  },
  {
    postcode: 'PL22',
    uk_region: 'South West',
    postal_town: 'Lostwithiel',
  },
  {
    postcode: 'PL23',
    uk_region: 'South West',
    postal_town: 'Fowey',
  },
  {
    postcode: 'PL24',
    uk_region: 'South West',
    postal_town: 'Par',
  },
  {
    postcode: 'PL25',
    uk_region: 'South West',
    postal_town: 'St. Austell',
  },
  {
    postcode: 'PL26',
    uk_region: 'South West',
    postal_town: 'St. Austell',
  },
  {
    postcode: 'PL27',
    uk_region: 'South West',
    postal_town: 'Wadebridge',
  },
  {
    postcode: 'PL28',
    uk_region: 'South West',
    postal_town: 'Padstow',
  },
  {
    postcode: 'PL29',
    uk_region: 'South West',
    postal_town: 'Port Isaac',
  },
  {
    postcode: 'PL30',
    uk_region: 'South West',
    postal_town: 'Bodmin',
  },
  {
    postcode: 'PL31',
    uk_region: 'South West',
    postal_town: 'Bodmin',
  },
  {
    postcode: 'PL32',
    uk_region: 'South West',
    postal_town: 'Camelford',
  },
  {
    postcode: 'PL33',
    uk_region: 'South West',
    postal_town: 'Delabole',
  },
  {
    postcode: 'PL34',
    uk_region: 'South West',
    postal_town: 'Tintagel',
  },
  {
    postcode: 'PL35',
    uk_region: 'South West',
    postal_town: 'Boscastle',
  },
  {
    postcode: 'PL95',
    uk_region: 'South West',
    postal_town: 'Plymouth',
  },
  {
    postcode: 'PO1',
    uk_region: 'South East',
    postal_town: 'Portsmouth',
  },
  {
    postcode: 'PO2',
    uk_region: 'South East',
    postal_town: 'Portsmouth',
  },
  {
    postcode: 'PO3',
    uk_region: 'South East',
    postal_town: 'Portsmouth',
  },
  {
    postcode: 'PO4',
    uk_region: 'South East',
    postal_town: 'Southsea',
  },
  {
    postcode: 'PO5',
    uk_region: 'South East',
    postal_town: 'Southsea',
  },
  {
    postcode: 'PO6',
    uk_region: 'South East',
    postal_town: 'Portsmouth',
  },
  {
    postcode: 'PO7',
    uk_region: 'South East',
    postal_town: 'Waterlooville',
  },
  {
    postcode: 'PO8',
    uk_region: 'South East',
    postal_town: 'Waterlooville',
  },
  {
    postcode: 'PO9',
    uk_region: 'South East',
    postal_town: '"Havant, Rowland\'s Castle"',
  },
  {
    postcode: 'PO10',
    uk_region: 'South East',
    postal_town: 'Emsworth',
  },
  {
    postcode: 'PO11',
    uk_region: 'South East',
    postal_town: 'Hayling Island',
  },
  {
    postcode: 'PO12',
    uk_region: 'South East',
    postal_town: '"Gosport, Lee-on-the-Solent"',
  },
  {
    postcode: 'PO13',
    uk_region: 'South East',
    postal_town: '"Gosport, Lee-on-the-Solent"',
  },
  {
    postcode: 'PO14',
    uk_region: 'South East',
    postal_town: 'Fareham',
  },
  {
    postcode: 'PO15',
    uk_region: 'South East',
    postal_town: 'Fareham',
  },
  {
    postcode: 'PO16',
    uk_region: 'South East',
    postal_town: 'Fareham',
  },
  {
    postcode: 'PO17',
    uk_region: 'South East',
    postal_town: 'Fareham',
  },
  {
    postcode: 'PO18',
    uk_region: 'South East',
    postal_town: 'Chichester',
  },
  {
    postcode: 'PO19',
    uk_region: 'South East',
    postal_town: 'Chichester',
  },
  {
    postcode: 'PO20',
    uk_region: 'South East',
    postal_town: 'Chichester',
  },
  {
    postcode: 'PO21',
    uk_region: 'South East',
    postal_town: 'Bognor Regis',
  },
  {
    postcode: 'PO22',
    uk_region: 'South East',
    postal_town: 'Bognor Regis',
  },
  {
    postcode: 'PO24',
    uk_region: '',
    postal_town: '',
  },
  {
    postcode: 'PO30',
    uk_region: 'South East',
    postal_town: '"Newport, Yarmouth"',
  },
  {
    postcode: 'PO31',
    uk_region: 'South East',
    postal_town: 'Cowes',
  },
  {
    postcode: 'PO32',
    uk_region: 'South East',
    postal_town: 'East Cowes',
  },
  {
    postcode: 'PO33',
    uk_region: 'South East',
    postal_town: 'Ryde',
  },
  {
    postcode: 'PO34',
    uk_region: 'South East',
    postal_town: 'Seaview',
  },
  {
    postcode: 'PO35',
    uk_region: 'South East',
    postal_town: 'Bembridge',
  },
  {
    postcode: 'PO36',
    uk_region: 'South East',
    postal_town: '"Sandown, Shanklin"',
  },
  {
    postcode: 'PO37',
    uk_region: 'South East',
    postal_town: 'Shanklin',
  },
  {
    postcode: 'PO38',
    uk_region: 'South East',
    postal_town: 'Ventnor',
  },
  {
    postcode: 'PO39',
    uk_region: 'South East',
    postal_town: 'Totland Bay',
  },
  {
    postcode: 'PO40',
    uk_region: 'South East',
    postal_town: 'Freshwater',
  },
  {
    postcode: 'PO41',
    uk_region: 'South East',
    postal_town: 'Yarmouth',
  },
  {
    postcode: 'PR0',
    uk_region: 'North West',
    postal_town: 'Preston',
  },
  {
    postcode: 'PR1',
    uk_region: 'North West',
    postal_town: 'Preston',
  },
  {
    postcode: 'PR2',
    uk_region: 'North West',
    postal_town: 'Preston',
  },
  {
    postcode: 'PR3',
    uk_region: 'North West',
    postal_town: 'Preston',
  },
  {
    postcode: 'PR4',
    uk_region: 'North West',
    postal_town: 'Preston',
  },
  {
    postcode: 'PR5',
    uk_region: 'North West',
    postal_town: 'Preston',
  },
  {
    postcode: 'PR6',
    uk_region: 'North West',
    postal_town: 'Chorley',
  },
  {
    postcode: 'PR7',
    uk_region: 'North West',
    postal_town: 'Chorley',
  },
  {
    postcode: 'PR8',
    uk_region: 'North West',
    postal_town: 'Southport',
  },
  {
    postcode: 'PR9',
    uk_region: 'North West',
    postal_town: 'Southport',
  },
  {
    postcode: 'PR11',
    uk_region: 'North West',
    postal_town: 'Preston',
  },
  {
    postcode: 'PR25',
    uk_region: 'North West',
    postal_town: 'Leyland',
  },
  {
    postcode: 'PR26',
    uk_region: 'North West',
    postal_town: 'Leyland',
  },
  {
    postcode: 'RG1',
    uk_region: 'South East',
    postal_town: 'Reading',
  },
  {
    postcode: 'RG2',
    uk_region: 'South East',
    postal_town: 'Reading',
  },
  {
    postcode: 'RG3',
    uk_region: 'South East',
    postal_town: '',
  },
  {
    postcode: 'RG4',
    uk_region: 'South East',
    postal_town: 'Reading',
  },
  {
    postcode: 'RG5',
    uk_region: 'South East',
    postal_town: 'Reading',
  },
  {
    postcode: 'RG6',
    uk_region: 'South East',
    postal_town: 'Reading',
  },
  {
    postcode: 'RG7',
    uk_region: 'South East',
    postal_town: 'Reading',
  },
  {
    postcode: 'RG8',
    uk_region: 'South East',
    postal_town: 'Reading',
  },
  {
    postcode: 'RG9',
    uk_region: 'South East',
    postal_town: 'Henley-on-Thames',
  },
  {
    postcode: 'RG10',
    uk_region: 'South East',
    postal_town: 'Reading',
  },
  {
    postcode: 'RG11',
    uk_region: 'South East',
    postal_town: '',
  },
  {
    postcode: 'RG12',
    uk_region: 'South East',
    postal_town: 'Bracknell',
  },
  {
    postcode: 'RG13',
    uk_region: 'South East',
    postal_town: '',
  },
  {
    postcode: 'RG14',
    uk_region: 'South East',
    postal_town: 'Newbury',
  },
  {
    postcode: 'RG15',
    uk_region: 'South East',
    postal_town: '',
  },
  {
    postcode: 'RG16',
    uk_region: 'South East',
    postal_town: '',
  },
  {
    postcode: 'RG17',
    uk_region: 'South East',
    postal_town: 'Hungerford',
  },
  {
    postcode: 'RG18',
    uk_region: 'South East',
    postal_town: 'Thatcham',
  },
  {
    postcode: 'RG19',
    uk_region: 'South East',
    postal_town: '"Reading, Thatcham"',
  },
  {
    postcode: 'RG20',
    uk_region: 'South East',
    postal_town: 'Newbury',
  },
  {
    postcode: 'RG21',
    uk_region: 'South East',
    postal_town: 'Basingstoke',
  },
  {
    postcode: 'RG22',
    uk_region: 'South East',
    postal_town: 'Basingstoke',
  },
  {
    postcode: 'RG23',
    uk_region: 'South East',
    postal_town: 'Basingstoke',
  },
  {
    postcode: 'RG24',
    uk_region: 'South East',
    postal_town: 'Basingstoke',
  },
  {
    postcode: 'RG25',
    uk_region: 'South East',
    postal_town: 'Basingstoke',
  },
  {
    postcode: 'RG26',
    uk_region: 'South East',
    postal_town: 'Tadley',
  },
  {
    postcode: 'RG27',
    uk_region: 'South East',
    postal_town: 'Hook',
  },
  {
    postcode: 'RG28',
    uk_region: 'South East',
    postal_town: '"Basingstoke, Whitchurch"',
  },
  {
    postcode: 'RG29',
    uk_region: 'South East',
    postal_town: 'Hook',
  },
  {
    postcode: 'RG30',
    uk_region: 'South East',
    postal_town: 'Reading',
  },
  {
    postcode: 'RG31',
    uk_region: 'South East',
    postal_town: 'Reading',
  },
  {
    postcode: 'RG40',
    uk_region: 'South East',
    postal_town: 'Wokingham',
  },
  {
    postcode: 'RG41',
    uk_region: 'South East',
    postal_town: 'Wokingham',
  },
  {
    postcode: 'RG42',
    uk_region: 'South East',
    postal_town: 'Bracknell',
  },
  {
    postcode: 'RG45',
    uk_region: 'South East',
    postal_town: 'Crowthorne',
  },
  {
    postcode: 'RH1',
    uk_region: 'South East',
    postal_town: 'Redhill',
  },
  {
    postcode: 'RH2',
    uk_region: 'South East',
    postal_town: 'Reigate',
  },
  {
    postcode: 'RH3',
    uk_region: 'South East',
    postal_town: 'Betchworth',
  },
  {
    postcode: 'RH4',
    uk_region: 'South East',
    postal_town: '"Betchworth, Dorking"',
  },
  {
    postcode: 'RH5',
    uk_region: 'South East',
    postal_town: 'Dorking',
  },
  {
    postcode: 'RH6',
    uk_region: 'South East',
    postal_town: '"Gatwick, Horley"',
  },
  {
    postcode: 'RH7',
    uk_region: 'South East',
    postal_town: 'Lingfield',
  },
  {
    postcode: 'RH8',
    uk_region: 'South East',
    postal_town: 'Oxted',
  },
  {
    postcode: 'RH9',
    uk_region: 'South East',
    postal_town: 'Godstone',
  },
  {
    postcode: 'RH10',
    uk_region: 'South East',
    postal_town: 'Crawley',
  },
  {
    postcode: 'RH11',
    uk_region: 'South East',
    postal_town: 'Crawley',
  },
  {
    postcode: 'RH12',
    uk_region: 'South East',
    postal_town: 'Horsham',
  },
  {
    postcode: 'RH13',
    uk_region: 'South East',
    postal_town: 'Horsham',
  },
  {
    postcode: 'RH14',
    uk_region: 'South East',
    postal_town: 'Billingshurst',
  },
  {
    postcode: 'RH15',
    uk_region: 'South East',
    postal_town: 'Burgess Hill',
  },
  {
    postcode: 'RH16',
    uk_region: 'South East',
    postal_town: 'Haywards Heath',
  },
  {
    postcode: 'RH17',
    uk_region: 'South East',
    postal_town: 'Haywards Heath',
  },
  {
    postcode: 'RH18',
    uk_region: 'South East',
    postal_town: 'Forest Row',
  },
  {
    postcode: 'RH19',
    uk_region: 'South East',
    postal_town: 'East Grinstead',
  },
  {
    postcode: 'RH20',
    uk_region: 'South East',
    postal_town: 'Pulborough',
  },
  {
    postcode: 'RH77',
    uk_region: 'South East',
    postal_town: 'Crawley',
  },
  {
    postcode: 'RM1',
    uk_region: 'London',
    postal_town: 'Romford',
  },
  {
    postcode: 'RM2',
    uk_region: 'London',
    postal_town: 'Romford',
  },
  {
    postcode: 'RM3',
    uk_region: 'London',
    postal_town: 'Romford',
  },
  {
    postcode: 'RM4',
    uk_region: 'East of England',
    postal_town: 'Romford',
  },
  {
    postcode: 'RM5',
    uk_region: 'London',
    postal_town: 'Romford',
  },
  {
    postcode: 'RM6',
    uk_region: 'London',
    postal_town: 'Romford',
  },
  {
    postcode: 'RM7',
    uk_region: 'London',
    postal_town: 'Romford',
  },
  {
    postcode: 'RM8',
    uk_region: 'London',
    postal_town: 'Dagenham',
  },
  {
    postcode: 'RM9',
    uk_region: 'London',
    postal_town: 'Dagenham',
  },
  {
    postcode: 'RM10',
    uk_region: 'London',
    postal_town: 'Dagenham',
  },
  {
    postcode: 'RM11',
    uk_region: 'London',
    postal_town: 'Hornchurch',
  },
  {
    postcode: 'RM12',
    uk_region: 'London',
    postal_town: 'Hornchurch',
  },
  {
    postcode: 'RM13',
    uk_region: 'London',
    postal_town: 'Rainham',
  },
  {
    postcode: 'RM14',
    uk_region: 'London',
    postal_town: 'Upminster',
  },
  {
    postcode: '---',
    uk_region: 'London',
    postal_town: 'Upminster',
  },
  {
    postcode: 'RM15',
    uk_region: 'East of England',
    postal_town: 'South Ockendon',
  },
  {
    postcode: 'RM16',
    uk_region: 'East of England',
    postal_town: 'Grays',
  },
  {
    postcode: 'RM17',
    uk_region: 'East of England',
    postal_town: 'Grays',
  },
  {
    postcode: 'RM18',
    uk_region: 'East of England',
    postal_town: 'Tilbury',
  },
  {
    postcode: 'RM19',
    uk_region: 'East of England',
    postal_town: 'Purfleet',
  },
  {
    postcode: 'RM20',
    uk_region: 'East of England',
    postal_town: 'Grays',
  },
  {
    postcode: 'RM50',
    uk_region: 'London',
    postal_town: '',
  },
  {
    postcode: 'S1',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Sheffield',
  },
  {
    postcode: 'S2',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Sheffield',
  },
  {
    postcode: 'S3',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Sheffield',
  },
  {
    postcode: 'S4',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Sheffield',
  },
  {
    postcode: 'S5',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Sheffield',
  },
  {
    postcode: 'S6',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Sheffield',
  },
  {
    postcode: 'S7',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Sheffield',
  },
  {
    postcode: 'S8',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Sheffield',
  },
  {
    postcode: 'S9',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Sheffield',
  },
  {
    postcode: 'S10',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Sheffield',
  },
  {
    postcode: 'S11',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Sheffield',
  },
  {
    postcode: 'S12',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Sheffield',
  },
  {
    postcode: 'S13',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Sheffield',
  },
  {
    postcode: 'S14',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Sheffield',
  },
  {
    postcode: 'S17',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Sheffield',
  },
  {
    postcode: 'S18',
    uk_region: 'East Midlands',
    postal_town: 'Dronfield',
  },
  {
    postcode: 'S19',
    uk_region: 'Yorkshire and The Humber',
    postal_town: '',
  },
  {
    postcode: 'S20',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Sheffield',
  },
  {
    postcode: 'S21',
    uk_region: 'East Midlands',
    postal_town: 'Sheffield',
  },
  {
    postcode: 'S25',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Sheffield',
  },
  {
    postcode: 'S26',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Sheffield',
  },
  {
    postcode: 'S30',
    uk_region: 'Yorkshire and The Humber',
    postal_town: '',
  },
  {
    postcode: 'S31',
    uk_region: 'Yorkshire and The Humber',
    postal_town: '',
  },
  {
    postcode: 'S32',
    uk_region: 'East Midlands',
    postal_town: 'Hope Valley',
  },
  {
    postcode: 'S33',
    uk_region: 'East Midlands',
    postal_town: 'Hope Valley',
  },
  {
    postcode: 'S35',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Sheffield',
  },
  {
    postcode: 'S36',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Sheffield',
  },
  {
    postcode: 'S40',
    uk_region: 'East Midlands',
    postal_town: 'Chesterfield',
  },
  {
    postcode: 'S41',
    uk_region: 'East Midlands',
    postal_town: 'Chesterfield',
  },
  {
    postcode: 'S42',
    uk_region: 'East Midlands',
    postal_town: 'Chesterfield',
  },
  {
    postcode: 'S43',
    uk_region: 'East Midlands',
    postal_town: 'Chesterfield',
  },
  {
    postcode: 'S44',
    uk_region: 'East Midlands',
    postal_town: 'Chesterfield',
  },
  {
    postcode: 'S45',
    uk_region: 'East Midlands',
    postal_town: 'Chesterfield',
  },
  {
    postcode: 'S49',
    uk_region: 'East Midlands',
    postal_town: 'Chesterfield',
  },
  {
    postcode: 'S60',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Rotherham',
  },
  {
    postcode: 'S61',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Rotherham',
  },
  {
    postcode: 'S62',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Rotherham',
  },
  {
    postcode: 'S63',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Rotherham',
  },
  {
    postcode: 'S64',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Mexborough',
  },
  {
    postcode: 'S65',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Rotherham',
  },
  {
    postcode: 'S66',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Rotherham',
  },
  {
    postcode: 'S69',
    uk_region: '',
    postal_town: '',
  },
  {
    postcode: 'S70',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Barnsley',
  },
  {
    postcode: 'S71',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Barnsley',
  },
  {
    postcode: 'S72',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Barnsley',
  },
  {
    postcode: 'S73',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Barnsley',
  },
  {
    postcode: 'S74',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Barnsley',
  },
  {
    postcode: 'S75',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Barnsley',
  },
  {
    postcode: 'S80',
    uk_region: 'East Midlands',
    postal_town: 'Worksop',
  },
  {
    postcode: 'S81',
    uk_region: 'East Midlands',
    postal_town: 'Worksop',
  },
  {
    postcode: 'S94',
    uk_region: '',
    postal_town: '',
  },
  {
    postcode: 'S95',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Sheffield',
  },
  {
    postcode: 'S96',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Sheffield',
  },
  {
    postcode: 'S97',
    uk_region: 'Yorkshire and The Humber',
    postal_town: '"Sheffield, Rotherham"',
  },
  {
    postcode: 'S98',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Sheffield',
  },
  {
    postcode: 'S99',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Sheffield',
  },
  {
    postcode: 'SA1',
    uk_region: 'Wales',
    postal_town: 'Swansea',
  },
  {
    postcode: 'SA2',
    uk_region: 'Wales',
    postal_town: 'Swansea',
  },
  {
    postcode: 'SA3',
    uk_region: 'Wales',
    postal_town: 'Swansea',
  },
  {
    postcode: 'SA4',
    uk_region: 'Wales',
    postal_town: 'Swansea',
  },
  {
    postcode: 'SA5',
    uk_region: 'Wales',
    postal_town: 'Swansea',
  },
  {
    postcode: 'SA6',
    uk_region: 'Wales',
    postal_town: 'Swansea',
  },
  {
    postcode: 'SA7',
    uk_region: 'Wales',
    postal_town: 'Swansea',
  },
  {
    postcode: 'SA8',
    uk_region: 'Wales',
    postal_town: 'Swansea',
  },
  {
    postcode: 'SA9',
    uk_region: 'Wales',
    postal_town: 'Swansea',
  },
  {
    postcode: 'SA10',
    uk_region: 'Wales',
    postal_town: 'Neath',
  },
  {
    postcode: 'SA11',
    uk_region: 'Wales',
    postal_town: 'Neath',
  },
  {
    postcode: 'SA12',
    uk_region: 'Wales',
    postal_town: 'Port Talbot',
  },
  {
    postcode: 'SA13',
    uk_region: 'Wales',
    postal_town: 'Port Talbot',
  },
  {
    postcode: 'SA14',
    uk_region: 'Wales',
    postal_town: 'Llanelli',
  },
  {
    postcode: 'SA15',
    uk_region: 'Wales',
    postal_town: 'Llanelli',
  },
  {
    postcode: 'SA16',
    uk_region: 'Wales',
    postal_town: 'Burry Port',
  },
  {
    postcode: 'SA17',
    uk_region: 'Wales',
    postal_town: '"Ferryside, Kidwelly"',
  },
  {
    postcode: 'SA18',
    uk_region: 'Wales',
    postal_town: 'Ammanford',
  },
  {
    postcode: 'SA19',
    uk_region: 'Wales',
    postal_town: '"Llandeilo, Llangadog, Llanwrda"',
  },
  {
    postcode: 'SA20',
    uk_region: 'Wales',
    postal_town: 'Llandovery',
  },
  {
    postcode: 'SA31',
    uk_region: 'Wales',
    postal_town: 'Carmarthen',
  },
  {
    postcode: 'SA32',
    uk_region: 'Wales',
    postal_town: 'Carmarthen',
  },
  {
    postcode: 'SA33',
    uk_region: 'Wales',
    postal_town: 'Carmarthen',
  },
  {
    postcode: 'SA34',
    uk_region: 'Wales',
    postal_town: 'Whitland',
  },
  {
    postcode: 'SA35',
    uk_region: 'Wales',
    postal_town: 'Llanfyrnach',
  },
  {
    postcode: 'SA36',
    uk_region: 'Wales',
    postal_town: 'Glogue',
  },
  {
    postcode: 'SA37',
    uk_region: 'Wales',
    postal_town: 'Boncath',
  },
  {
    postcode: 'SA38',
    uk_region: 'Wales',
    postal_town: 'Newcastle Emlyn',
  },
  {
    postcode: 'SA39',
    uk_region: 'Wales',
    postal_town: 'Pencader',
  },
  {
    postcode: 'SA40',
    uk_region: 'Wales',
    postal_town: 'Llanybydder',
  },
  {
    postcode: 'SA41',
    uk_region: 'Wales',
    postal_town: 'Crymych',
  },
  {
    postcode: 'SA42',
    uk_region: 'Wales',
    postal_town: 'Newport',
  },
  {
    postcode: 'SA43',
    uk_region: 'Wales',
    postal_town: 'Cardigan',
  },
  {
    postcode: 'SA44',
    uk_region: 'Wales',
    postal_town: 'Llandysul',
  },
  {
    postcode: 'SA45',
    uk_region: 'Wales',
    postal_town: 'New Quay',
  },
  {
    postcode: 'SA46',
    uk_region: 'Wales',
    postal_town: 'Aberaeron',
  },
  {
    postcode: 'SA47',
    uk_region: 'Wales',
    postal_town: 'Llanarth',
  },
  {
    postcode: 'SA48',
    uk_region: 'Wales',
    postal_town: '"Aberaeron, Lampeter"',
  },
  {
    postcode: 'SA61',
    uk_region: 'Wales',
    postal_town: 'Haverfordwest',
  },
  {
    postcode: 'SA62',
    uk_region: 'Wales',
    postal_town: 'Haverfordwest',
  },
  {
    postcode: 'SA63',
    uk_region: 'Wales',
    postal_town: 'Clarbeston Road',
  },
  {
    postcode: 'SA64',
    uk_region: 'Wales',
    postal_town: 'Goodwick',
  },
  {
    postcode: 'SA65',
    uk_region: 'Wales',
    postal_town: 'Fishguard',
  },
  {
    postcode: 'SA66',
    uk_region: 'Wales',
    postal_town: 'Clynderwen',
  },
  {
    postcode: 'SA67',
    uk_region: 'Wales',
    postal_town: 'Narberth',
  },
  {
    postcode: 'SA68',
    uk_region: 'Wales',
    postal_town: 'Kilgetty',
  },
  {
    postcode: 'SA69',
    uk_region: 'Wales',
    postal_town: 'Saundersfoot',
  },
  {
    postcode: 'SA70',
    uk_region: 'Wales',
    postal_town: 'Tenby',
  },
  {
    postcode: 'SA71',
    uk_region: 'Wales',
    postal_town: 'Pembroke',
  },
  {
    postcode: 'SA72',
    uk_region: 'Wales',
    postal_town: '"Pembroke, Pembroke Dock"',
  },
  {
    postcode: 'SA73',
    uk_region: 'Wales',
    postal_town: 'Milford Haven',
  },
  {
    postcode: 'SA80',
    uk_region: 'Wales',
    postal_town: 'Swansea',
  },
  {
    postcode: 'SA99',
    uk_region: 'Wales',
    postal_town: 'Swansea',
  },
  {
    postcode: 'SE1',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SE1P',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SE2',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SE3',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SE4',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SE5',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SE6',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SE7',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SE8',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SE9',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SE10',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SE11',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SE12',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SE13',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SE14',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SE15',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SE16',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SE17',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SE18',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SE19',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SE20',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SE21',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SE22',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SE23',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SE24',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SE25',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SE26',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SE27',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SE28',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SE99',
    uk_region: 'London',
    postal_town: '',
  },
  {
    postcode: 'SG1',
    uk_region: 'East of England',
    postal_town: 'Stevenage',
  },
  {
    postcode: 'SG2',
    uk_region: 'East of England',
    postal_town: 'Stevenage',
  },
  {
    postcode: 'SG3',
    uk_region: 'East of England',
    postal_town: 'Knebworth',
  },
  {
    postcode: 'SG4',
    uk_region: 'East of England',
    postal_town: 'Hitchin',
  },
  {
    postcode: 'SG5',
    uk_region: 'East of England',
    postal_town: 'Hitchin',
  },
  {
    postcode: 'SG6',
    uk_region: 'East of England',
    postal_town: '"Hitchin, Letchworth Garden City"',
  },
  {
    postcode: 'SG7',
    uk_region: 'East of England',
    postal_town: 'Baldock',
  },
  {
    postcode: 'SG8',
    uk_region: 'East of England',
    postal_town: 'Royston',
  },
  {
    postcode: 'SG9',
    uk_region: 'East of England',
    postal_town: 'Buntingford',
  },
  {
    postcode: 'SG10',
    uk_region: 'East of England',
    postal_town: 'Much Hadham',
  },
  {
    postcode: 'SG11',
    uk_region: 'East of England',
    postal_town: 'Ware',
  },
  {
    postcode: 'SG12',
    uk_region: 'East of England',
    postal_town: 'Ware',
  },
  {
    postcode: 'SG13',
    uk_region: 'East of England',
    postal_town: 'Hertford',
  },
  {
    postcode: 'SG14',
    uk_region: 'East of England',
    postal_town: 'Hertford',
  },
  {
    postcode: 'SG15',
    uk_region: 'East of England',
    postal_town: 'Arlesey',
  },
  {
    postcode: 'SG16',
    uk_region: 'East of England',
    postal_town: 'Henlow',
  },
  {
    postcode: 'SG17',
    uk_region: 'East of England',
    postal_town: 'Shefford',
  },
  {
    postcode: 'SG18',
    uk_region: 'East of England',
    postal_town: 'Biggleswade',
  },
  {
    postcode: 'SG19',
    uk_region: 'East of England',
    postal_town: 'Sandy',
  },
  {
    postcode: 'SK1',
    uk_region: 'North West',
    postal_town: 'Stockport',
  },
  {
    postcode: 'SK2',
    uk_region: 'North West',
    postal_town: 'Stockport',
  },
  {
    postcode: 'SK3',
    uk_region: 'North West',
    postal_town: 'Stockport',
  },
  {
    postcode: 'SK4',
    uk_region: 'North West',
    postal_town: 'Stockport',
  },
  {
    postcode: 'SK5',
    uk_region: 'North West',
    postal_town: 'Stockport',
  },
  {
    postcode: 'SK6',
    uk_region: 'North West',
    postal_town: 'Stockport',
  },
  {
    postcode: 'SK7',
    uk_region: 'North West',
    postal_town: 'Stockport',
  },
  {
    postcode: 'SK8',
    uk_region: 'North West',
    postal_town: 'Cheadle',
  },
  {
    postcode: 'SK9',
    uk_region: 'North West',
    postal_town: '"Alderley Edge, Wilmslow"',
  },
  {
    postcode: 'SK10',
    uk_region: 'North West',
    postal_town: 'Macclesfield',
  },
  {
    postcode: 'SK11',
    uk_region: 'North West',
    postal_town: 'Macclesfield',
  },
  {
    postcode: 'SK12',
    uk_region: 'East Midlands',
    postal_town: 'Stockport',
  },
  {
    postcode: 'SK13',
    uk_region: 'East Midlands',
    postal_town: 'Glossop',
  },
  {
    postcode: 'SK14',
    uk_region: 'North West',
    postal_town: 'Hyde',
  },
  {
    postcode: 'SK15',
    uk_region: 'North West',
    postal_town: 'Stalybridge',
  },
  {
    postcode: 'SK16',
    uk_region: 'North West',
    postal_town: 'Dukinfield',
  },
  {
    postcode: 'SK17',
    uk_region: 'East Midlands',
    postal_town: 'Buxton',
  },
  {
    postcode: 'SK22',
    uk_region: 'East Midlands',
    postal_town: 'High Peak',
  },
  {
    postcode: 'SK23',
    uk_region: 'East Midlands',
    postal_town: 'High Peak',
  },
  {
    postcode: 'SL0',
    uk_region: 'South East',
    postal_town: 'Iver',
  },
  {
    postcode: 'SL1',
    uk_region: 'South East',
    postal_town: 'Slough',
  },
  {
    postcode: 'SL2',
    uk_region: 'South East',
    postal_town: 'Slough',
  },
  {
    postcode: 'SL3',
    uk_region: 'South East',
    postal_town: 'Slough',
  },
  {
    postcode: 'SL4',
    uk_region: 'South East',
    postal_town: 'Windsor',
  },
  {
    postcode: 'SL5',
    uk_region: 'South East',
    postal_town: 'Ascot',
  },
  {
    postcode: 'SL6',
    uk_region: 'South East',
    postal_town: 'Maidenhead',
  },
  {
    postcode: 'SL7',
    uk_region: 'South East',
    postal_town: 'Marlow',
  },
  {
    postcode: 'SL8',
    uk_region: 'South East',
    postal_town: 'Bourne End',
  },
  {
    postcode: 'SL9',
    uk_region: 'South East',
    postal_town: 'Gerrards Cross',
  },
  {
    postcode: 'SL60',
    uk_region: 'South East',
    postal_town: 'Maidenhead',
  },
  {
    postcode: 'SL95',
    uk_region: 'South East',
    postal_town: 'Slough',
  },
  {
    postcode: 'SM1',
    uk_region: 'London',
    postal_town: 'Sutton',
  },
  {
    postcode: 'SM2',
    uk_region: 'London',
    postal_town: 'Sutton',
  },
  {
    postcode: 'SM3',
    uk_region: 'London',
    postal_town: 'Sutton',
  },
  {
    postcode: 'SM4',
    uk_region: 'London',
    postal_town: 'Morden',
  },
  {
    postcode: 'SM5',
    uk_region: 'London',
    postal_town: 'Carshalton',
  },
  {
    postcode: 'SM6',
    uk_region: 'London',
    postal_town: 'Wallington',
  },
  {
    postcode: 'SM7',
    uk_region: 'South East',
    postal_town: 'Banstead',
  },
  {
    postcode: 'SN1',
    uk_region: 'South West',
    postal_town: 'Swindon',
  },
  {
    postcode: 'SN2',
    uk_region: 'South West',
    postal_town: 'Swindon',
  },
  {
    postcode: 'SN3',
    uk_region: 'South West',
    postal_town: 'Swindon',
  },
  {
    postcode: 'SN4',
    uk_region: 'South West',
    postal_town: 'Swindon',
  },
  {
    postcode: 'SN5',
    uk_region: 'South West',
    postal_town: 'Swindon',
  },
  {
    postcode: 'SN6',
    uk_region: 'South West',
    postal_town: 'Swindon',
  },
  {
    postcode: 'SN7',
    uk_region: 'South East',
    postal_town: 'Faringdon',
  },
  {
    postcode: 'SN8',
    uk_region: 'South West',
    postal_town: 'Marlborough',
  },
  {
    postcode: 'SN9',
    uk_region: 'South West',
    postal_town: 'Pewsey',
  },
  {
    postcode: 'SN10',
    uk_region: 'South West',
    postal_town: 'Devizes',
  },
  {
    postcode: 'SN11',
    uk_region: 'South West',
    postal_town: 'Calne',
  },
  {
    postcode: 'SN12',
    uk_region: 'South West',
    postal_town: 'Melksham',
  },
  {
    postcode: 'SN13',
    uk_region: 'South West',
    postal_town: 'Corsham',
  },
  {
    postcode: 'SN14',
    uk_region: 'South West',
    postal_town: 'Chippenham',
  },
  {
    postcode: 'SN15',
    uk_region: 'South West',
    postal_town: '"Corsham, Chippenham"',
  },
  {
    postcode: 'SN16',
    uk_region: 'South West',
    postal_town: 'Malmesbury',
  },
  {
    postcode: 'SN17',
    uk_region: 'South West',
    postal_town: '',
  },
  {
    postcode: 'SN25',
    uk_region: 'South West',
    postal_town: 'Swindon',
  },
  {
    postcode: 'SN26',
    uk_region: 'South West',
    postal_town: 'Swindon',
  },
  {
    postcode: 'SN38',
    uk_region: 'South West',
    postal_town: 'Swindon',
  },
  {
    postcode: 'SN42',
    uk_region: '',
    postal_town: '',
  },
  {
    postcode: 'SN86',
    uk_region: '',
    postal_town: '',
  },
  {
    postcode: 'SN99',
    uk_region: 'South West',
    postal_town: 'Swindon',
  },
  {
    postcode: 'SO1',
    uk_region: 'South East',
    postal_town: '',
  },
  {
    postcode: 'SO2',
    uk_region: 'South East',
    postal_town: '',
  },
  {
    postcode: 'SO3',
    uk_region: 'South East',
    postal_town: '',
  },
  {
    postcode: 'SO4',
    uk_region: 'South East',
    postal_town: '',
  },
  {
    postcode: 'SO5',
    uk_region: 'South East',
    postal_town: '',
  },
  {
    postcode: 'SO9',
    uk_region: 'South East',
    postal_town: '',
  },
  {
    postcode: 'SO14',
    uk_region: 'South East',
    postal_town: 'Southampton',
  },
  {
    postcode: 'SO15',
    uk_region: 'South East',
    postal_town: 'Southampton',
  },
  {
    postcode: 'SO16',
    uk_region: 'South East',
    postal_town: 'Southampton',
  },
  {
    postcode: 'SO17',
    uk_region: 'South East',
    postal_town: 'Southampton',
  },
  {
    postcode: 'SO18',
    uk_region: 'South East',
    postal_town: 'Southampton',
  },
  {
    postcode: 'SO19',
    uk_region: 'South East',
    postal_town: 'Southampton',
  },
  {
    postcode: 'SO20',
    uk_region: 'South East',
    postal_town: 'Stockbridge',
  },
  {
    postcode: 'SO21',
    uk_region: 'South East',
    postal_town: 'Winchester',
  },
  {
    postcode: 'SO22',
    uk_region: 'South East',
    postal_town: 'Winchester',
  },
  {
    postcode: 'SO23',
    uk_region: 'South East',
    postal_town: 'Winchester',
  },
  {
    postcode: 'SO24',
    uk_region: 'South East',
    postal_town: 'Alresford',
  },
  {
    postcode: 'SO25',
    uk_region: 'South East',
    postal_town: 'Winchester',
  },
  {
    postcode: 'SO30',
    uk_region: 'South East',
    postal_town: 'Southampton',
  },
  {
    postcode: 'SO31',
    uk_region: 'South East',
    postal_town: 'Southampton',
  },
  {
    postcode: 'SO32',
    uk_region: 'South East',
    postal_town: 'Southampton',
  },
  {
    postcode: 'SO40',
    uk_region: 'South East',
    postal_town: '"Southampton, Lyndhurst"',
  },
  {
    postcode: 'SO41',
    uk_region: 'South East',
    postal_town: 'Lymington',
  },
  {
    postcode: 'SO42',
    uk_region: 'South East',
    postal_town: 'Brockenhurst',
  },
  {
    postcode: 'SO43',
    uk_region: 'South East',
    postal_town: 'Lyndhurst',
  },
  {
    postcode: 'SO45',
    uk_region: 'South East',
    postal_town: 'Southampton',
  },
  {
    postcode: 'SO50',
    uk_region: 'South East',
    postal_town: 'Eastleigh',
  },
  {
    postcode: 'SO51',
    uk_region: 'South East',
    postal_town: 'Romsey',
  },
  {
    postcode: 'SO52',
    uk_region: 'South East',
    postal_town: 'Southampton',
  },
  {
    postcode: 'SO53',
    uk_region: 'South East',
    postal_town: 'Eastleigh',
  },
  {
    postcode: 'SO97',
    uk_region: 'South East',
    postal_town: 'Southampton',
  },
  {
    postcode: 'SP1',
    uk_region: 'South West',
    postal_town: 'Salisbury',
  },
  {
    postcode: 'SP2',
    uk_region: 'South West',
    postal_town: 'Salisbury',
  },
  {
    postcode: 'SP3',
    uk_region: 'South West',
    postal_town: 'Salisbury',
  },
  {
    postcode: 'SP4',
    uk_region: 'South West',
    postal_town: 'Salisbury',
  },
  {
    postcode: 'SP5',
    uk_region: 'South West',
    postal_town: 'Salisbury',
  },
  {
    postcode: 'SP6',
    uk_region: 'South East',
    postal_town: 'Fordingbridge',
  },
  {
    postcode: 'SP7',
    uk_region: 'South West',
    postal_town: 'Shaftesbury',
  },
  {
    postcode: 'SP8',
    uk_region: 'South West',
    postal_town: 'Gillingham',
  },
  {
    postcode: 'SP9',
    uk_region: 'South West',
    postal_town: 'Tidworth',
  },
  {
    postcode: 'SP10',
    uk_region: 'South East',
    postal_town: 'Andover',
  },
  {
    postcode: 'SP11',
    uk_region: 'South East',
    postal_town: 'Andover',
  },
  {
    postcode: 'SR1',
    uk_region: 'North East',
    postal_town: 'Sunderland',
  },
  {
    postcode: 'SR2',
    uk_region: 'North East',
    postal_town: 'Sunderland',
  },
  {
    postcode: 'SR3',
    uk_region: 'North East',
    postal_town: 'Sunderland',
  },
  {
    postcode: 'SR4',
    uk_region: 'North East',
    postal_town: 'Sunderland',
  },
  {
    postcode: 'SR5',
    uk_region: 'North East',
    postal_town: 'Sunderland',
  },
  {
    postcode: 'SR6',
    uk_region: 'North East',
    postal_town: 'Sunderland',
  },
  {
    postcode: 'SR7',
    uk_region: 'North East',
    postal_town: 'Seaham',
  },
  {
    postcode: 'SR8',
    uk_region: 'North East',
    postal_town: 'Peterlee',
  },
  {
    postcode: 'SR9',
    uk_region: 'North East',
    postal_town: 'Sunderland',
  },
  {
    postcode: 'SR43',
    uk_region: 'North East',
    postal_town: '',
  },
  {
    postcode: 'SR88',
    uk_region: 'North East',
    postal_town: '',
  },
  {
    postcode: 'SS0',
    uk_region: 'East of England',
    postal_town: 'Westcliff-on-Sea',
  },
  {
    postcode: 'SS1',
    uk_region: 'East of England',
    postal_town: '"Westcliff-on-Sea, Southend-on-Sea"',
  },
  {
    postcode: 'SS2',
    uk_region: 'East of England',
    postal_town: 'Southend-on-Sea',
  },
  {
    postcode: 'SS3',
    uk_region: 'East of England',
    postal_town: 'Southend-on-Sea',
  },
  {
    postcode: 'SS4',
    uk_region: 'East of England',
    postal_town: 'Rochford',
  },
  {
    postcode: 'SS5',
    uk_region: 'East of England',
    postal_town: 'Hockley',
  },
  {
    postcode: 'SS6',
    uk_region: 'East of England',
    postal_town: 'Rayleigh',
  },
  {
    postcode: 'SS7',
    uk_region: 'East of England',
    postal_town: 'Benfleet',
  },
  {
    postcode: 'SS8',
    uk_region: 'East of England',
    postal_town: 'Canvey Island',
  },
  {
    postcode: 'SS9',
    uk_region: 'East of England',
    postal_town: 'Leigh-on-Sea',
  },
  {
    postcode: 'SS11',
    uk_region: 'East of England',
    postal_town: 'Wickford',
  },
  {
    postcode: 'SS12',
    uk_region: 'East of England',
    postal_town: 'Wickford',
  },
  {
    postcode: 'SS13',
    uk_region: 'East of England',
    postal_town: 'Basildon',
  },
  {
    postcode: 'SS14',
    uk_region: 'East of England',
    postal_town: 'Basildon',
  },
  {
    postcode: 'SS15',
    uk_region: 'East of England',
    postal_town: 'Basildon',
  },
  {
    postcode: 'SS16',
    uk_region: 'East of England',
    postal_town: 'Basildon',
  },
  {
    postcode: 'SS17',
    uk_region: 'East of England',
    postal_town: 'Stanford-le-Hope',
  },
  {
    postcode: 'SS22',
    uk_region: 'East of England',
    postal_town: 'Southend-on-Sea',
  },
  {
    postcode: 'SS99',
    uk_region: 'East of England',
    postal_town: 'Southend-on-Sea',
  },
  {
    postcode: 'ST1',
    uk_region: 'West Midlands',
    postal_town: 'Stoke-on-Trent',
  },
  {
    postcode: 'ST2',
    uk_region: 'West Midlands',
    postal_town: 'Stoke-on-Trent',
  },
  {
    postcode: 'ST3',
    uk_region: 'West Midlands',
    postal_town: 'Stoke-on-Trent',
  },
  {
    postcode: 'ST4',
    uk_region: 'West Midlands',
    postal_town: 'Stoke-on-Trent',
  },
  {
    postcode: 'ST5',
    uk_region: 'West Midlands',
    postal_town: 'Newcastle',
  },
  {
    postcode: 'ST6',
    uk_region: 'West Midlands',
    postal_town: 'Stoke-on-Trent',
  },
  {
    postcode: 'ST7',
    uk_region: 'West Midlands',
    postal_town: 'Stoke-on-Trent',
  },
  {
    postcode: 'ST8',
    uk_region: 'West Midlands',
    postal_town: 'Stoke-on-Trent',
  },
  {
    postcode: 'ST9',
    uk_region: 'West Midlands',
    postal_town: 'Stoke-on-Trent',
  },
  {
    postcode: 'ST10',
    uk_region: 'West Midlands',
    postal_town: 'Stoke-on-Trent',
  },
  {
    postcode: 'ST11',
    uk_region: 'West Midlands',
    postal_town: 'Stoke-on-Trent',
  },
  {
    postcode: 'ST12',
    uk_region: 'West Midlands',
    postal_town: 'Stoke-on-Trent',
  },
  {
    postcode: 'ST13',
    uk_region: 'West Midlands',
    postal_town: 'Leek',
  },
  {
    postcode: 'ST14',
    uk_region: 'West Midlands',
    postal_town: 'Uttoxeter',
  },
  {
    postcode: 'ST15',
    uk_region: 'West Midlands',
    postal_town: 'Stone',
  },
  {
    postcode: 'ST16',
    uk_region: 'West Midlands',
    postal_town: 'Stafford',
  },
  {
    postcode: 'ST17',
    uk_region: 'West Midlands',
    postal_town: 'Stafford',
  },
  {
    postcode: 'ST18',
    uk_region: 'West Midlands',
    postal_town: 'Stafford',
  },
  {
    postcode: 'ST19',
    uk_region: 'West Midlands',
    postal_town: 'Stafford',
  },
  {
    postcode: 'ST20',
    uk_region: 'West Midlands',
    postal_town: 'Stafford',
  },
  {
    postcode: 'ST21',
    uk_region: 'West Midlands',
    postal_town: 'Stafford',
  },
  {
    postcode: 'ST55',
    uk_region: 'West Midlands',
    postal_town: 'Newcastle',
  },
  {
    postcode: 'SW1',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SW1A',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SW1E',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SW1H',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SW1P',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SW1V',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SW1W',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SW1X',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SW1Y',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SW2',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SW3',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SW4',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SW5',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SW6',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SW7',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SW8',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SW9',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SW10',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SW11',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SW12',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SW13',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SW14',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SW15',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SW16',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SW17',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SW18',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SW19',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SW20',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SW95',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'SW99',
    uk_region: 'London',
    postal_town: '',
  },
  {
    postcode: 'SY1',
    uk_region: 'West Midlands',
    postal_town: 'Shrewsbury',
  },
  {
    postcode: 'SY2',
    uk_region: 'West Midlands',
    postal_town: 'Shrewsbury',
  },
  {
    postcode: 'SY3',
    uk_region: 'West Midlands',
    postal_town: 'Shrewsbury',
  },
  {
    postcode: 'SY4',
    uk_region: 'West Midlands',
    postal_town: 'Shrewsbury',
  },
  {
    postcode: 'SY5',
    uk_region: 'West Midlands',
    postal_town: 'Shrewsbury',
  },
  {
    postcode: 'SY6',
    uk_region: 'West Midlands',
    postal_town: 'Church Stretton',
  },
  {
    postcode: 'SY7',
    uk_region: 'West Midlands',
    postal_town: '"Bucknell, Craven Arms, Lydbury North"',
  },
  {
    postcode: 'SY8',
    uk_region: 'West Midlands',
    postal_town: 'Ludlow',
  },
  {
    postcode: 'SY9',
    uk_region: 'West Midlands',
    postal_town: 'Bishops Castle',
  },
  {
    postcode: 'SY10',
    uk_region: 'West Midlands',
    postal_town: 'Oswestry',
  },
  {
    postcode: 'SY11',
    uk_region: 'West Midlands',
    postal_town: 'Oswestry',
  },
  {
    postcode: 'SY12',
    uk_region: 'West Midlands',
    postal_town: 'Ellesmere',
  },
  {
    postcode: 'SY13',
    uk_region: 'West Midlands',
    postal_town: 'Whitchurch',
  },
  {
    postcode: 'SY14',
    uk_region: 'North West',
    postal_town: 'Malpas',
  },
  {
    postcode: 'SY15',
    uk_region: 'Wales',
    postal_town: 'Montgomery',
  },
  {
    postcode: 'SY16',
    uk_region: 'Wales',
    postal_town: 'Newtown',
  },
  {
    postcode: 'SY17',
    uk_region: 'Wales',
    postal_town: '"Caersws, Llandinam"',
  },
  {
    postcode: 'SY18',
    uk_region: 'Wales',
    postal_town: 'Llanidloes',
  },
  {
    postcode: 'SY19',
    uk_region: 'Wales',
    postal_town: 'Llanbrynmair',
  },
  {
    postcode: 'SY20',
    uk_region: 'Wales',
    postal_town: 'Machynlleth',
  },
  {
    postcode: 'SY21',
    uk_region: 'Wales',
    postal_town: 'Welshpool',
  },
  {
    postcode: 'SY22',
    uk_region: 'Wales',
    postal_town: '"Llanfechain, Llanfyllin, Llansantffraid, Llanymynech, Meifod"',
  },
  {
    postcode: 'SY23',
    uk_region: 'Wales',
    postal_town: '"Aberystwyth, Llanon, Llanrhystud"',
  },
  {
    postcode: 'SY24',
    uk_region: 'Wales',
    postal_town: '"Borth, Bow Street, Talybont"',
  },
  {
    postcode: 'SY25',
    uk_region: 'Wales',
    postal_town: '"Tregaron, Ystrad Meurig"',
  },
  {
    postcode: 'SY99',
    uk_region: 'West Midlands',
    postal_town: 'Shrewsbury',
  },
  {
    postcode: 'TA1',
    uk_region: 'South West',
    postal_town: 'Taunton',
  },
  {
    postcode: 'TA2',
    uk_region: 'South West',
    postal_town: 'Taunton',
  },
  {
    postcode: 'TA3',
    uk_region: 'South West',
    postal_town: 'Taunton',
  },
  {
    postcode: 'TA4',
    uk_region: 'South West',
    postal_town: 'Taunton',
  },
  {
    postcode: 'TA5',
    uk_region: 'South West',
    postal_town: 'Bridgwater',
  },
  {
    postcode: 'TA6',
    uk_region: 'South West',
    postal_town: 'Bridgwater',
  },
  {
    postcode: 'TA7',
    uk_region: 'South West',
    postal_town: 'Bridgwater',
  },
  {
    postcode: 'TA8',
    uk_region: 'South West',
    postal_town: 'Burnham-on-Sea',
  },
  {
    postcode: 'TA9',
    uk_region: 'South West',
    postal_town: 'Highbridge',
  },
  {
    postcode: 'TA10',
    uk_region: 'South West',
    postal_town: 'Langport',
  },
  {
    postcode: 'TA11',
    uk_region: 'South West',
    postal_town: 'Somerton',
  },
  {
    postcode: 'TA12',
    uk_region: 'South West',
    postal_town: 'Martock',
  },
  {
    postcode: 'TA13',
    uk_region: 'South West',
    postal_town: 'South Petherton',
  },
  {
    postcode: 'TA14',
    uk_region: 'South West',
    postal_town: 'Stoke-sub-Hamdon',
  },
  {
    postcode: 'TA15',
    uk_region: 'South West',
    postal_town: 'Montacute',
  },
  {
    postcode: 'TA16',
    uk_region: 'South West',
    postal_town: 'Merriott',
  },
  {
    postcode: 'TA17',
    uk_region: 'South West',
    postal_town: 'Hinton St. George',
  },
  {
    postcode: 'TA18',
    uk_region: 'South West',
    postal_town: 'Crewkerne',
  },
  {
    postcode: 'TA19',
    uk_region: 'South West',
    postal_town: 'Ilminster',
  },
  {
    postcode: 'TA20',
    uk_region: 'South West',
    postal_town: 'Chard',
  },
  {
    postcode: 'TA21',
    uk_region: 'South West',
    postal_town: 'Wellington',
  },
  {
    postcode: 'TA22',
    uk_region: 'South West',
    postal_town: 'Dulverton',
  },
  {
    postcode: 'TA23',
    uk_region: 'South West',
    postal_town: 'Watchet',
  },
  {
    postcode: 'TA24',
    uk_region: 'South West',
    postal_town: 'Minehead',
  },
  {
    postcode: 'TD1',
    uk_region: 'Scotland',
    postal_town: 'Galashiels',
  },
  {
    postcode: 'TD2',
    uk_region: 'Scotland',
    postal_town: 'Lauder',
  },
  {
    postcode: 'TD3',
    uk_region: 'Scotland',
    postal_town: 'Gordon',
  },
  {
    postcode: 'TD4',
    uk_region: 'Scotland',
    postal_town: 'Earlston',
  },
  {
    postcode: 'TD5',
    uk_region: 'Scotland',
    postal_town: 'Kelso',
  },
  {
    postcode: 'TD6',
    uk_region: 'Scotland',
    postal_town: 'Melrose',
  },
  {
    postcode: 'TD7',
    uk_region: 'Scotland',
    postal_town: 'Selkirk',
  },
  {
    postcode: 'TD8',
    uk_region: 'Scotland',
    postal_town: 'Jedburgh',
  },
  {
    postcode: 'TD9',
    uk_region: 'Scotland',
    postal_town: '"Hawick, Newcastleton"',
  },
  {
    postcode: 'TD10',
    uk_region: 'Scotland',
    postal_town: 'Duns',
  },
  {
    postcode: 'TD11',
    uk_region: 'Scotland',
    postal_town: 'Duns',
  },
  {
    postcode: 'TD12',
    uk_region: 'Scotland',
    postal_town: '"Coldstream, Cornhill-on-Tweed, Mindrum"',
  },
  {
    postcode: 'TD13',
    uk_region: 'Scotland',
    postal_town: 'Cockburnspath',
  },
  {
    postcode: 'TD14',
    uk_region: 'Scotland',
    postal_town: 'Eyemouth',
  },
  {
    postcode: 'TD15',
    uk_region: 'North East',
    postal_town: 'Berwick-upon-Tweed',
  },
  {
    postcode: 'TF1',
    uk_region: 'West Midlands',
    postal_town: 'Telford',
  },
  {
    postcode: 'TF2',
    uk_region: 'West Midlands',
    postal_town: 'Telford',
  },
  {
    postcode: 'TF3',
    uk_region: 'West Midlands',
    postal_town: 'Telford',
  },
  {
    postcode: 'TF4',
    uk_region: 'West Midlands',
    postal_town: 'Telford',
  },
  {
    postcode: 'TF5',
    uk_region: 'West Midlands',
    postal_town: 'Telford',
  },
  {
    postcode: 'TF6',
    uk_region: 'West Midlands',
    postal_town: 'Telford',
  },
  {
    postcode: 'TF7',
    uk_region: 'West Midlands',
    postal_town: 'Telford',
  },
  {
    postcode: 'TF8',
    uk_region: 'West Midlands',
    postal_town: 'Telford',
  },
  {
    postcode: 'TF9',
    uk_region: 'West Midlands',
    postal_town: 'Market Drayton',
  },
  {
    postcode: 'TF10',
    uk_region: 'West Midlands',
    postal_town: 'Newport',
  },
  {
    postcode: 'TF11',
    uk_region: 'West Midlands',
    postal_town: 'Shifnal',
  },
  {
    postcode: 'TF12',
    uk_region: 'West Midlands',
    postal_town: 'Broseley',
  },
  {
    postcode: 'TF13',
    uk_region: 'West Midlands',
    postal_town: 'Much Wenlock',
  },
  {
    postcode: 'TN1',
    uk_region: 'South East',
    postal_town: 'Tunbridge Wells',
  },
  {
    postcode: 'TN2',
    uk_region: 'South East',
    postal_town: '"Tunbridge Wells, Wadhurst"',
  },
  {
    postcode: 'TN3',
    uk_region: 'South East',
    postal_town: 'Tunbridge Wells',
  },
  {
    postcode: 'TN4',
    uk_region: 'South East',
    postal_town: 'Tunbridge Wells',
  },
  {
    postcode: 'TN5',
    uk_region: 'South East',
    postal_town: 'Wadhurst',
  },
  {
    postcode: 'TN6',
    uk_region: 'South East',
    postal_town: 'Crowborough',
  },
  {
    postcode: 'TN7',
    uk_region: 'South East',
    postal_town: 'Hartfield',
  },
  {
    postcode: 'TN8',
    uk_region: 'South East',
    postal_town: 'Edenbridge',
  },
  {
    postcode: 'TN9',
    uk_region: 'South East',
    postal_town: 'Tonbridge',
  },
  {
    postcode: 'TN10',
    uk_region: 'South East',
    postal_town: 'Tonbridge',
  },
  {
    postcode: 'TN11',
    uk_region: 'South East',
    postal_town: 'Tonbridge',
  },
  {
    postcode: 'TN12',
    uk_region: 'South East',
    postal_town: 'Tonbridge',
  },
  {
    postcode: 'TN13',
    uk_region: 'South East',
    postal_town: 'Sevenoaks',
  },
  {
    postcode: 'TN14',
    uk_region: 'South East',
    postal_town: 'Sevenoaks',
  },
  {
    postcode: 'TN15',
    uk_region: 'South East',
    postal_town: 'Sevenoaks',
  },
  {
    postcode: 'TN16',
    uk_region: 'London',
    postal_town: 'Westerham',
  },
  {
    postcode: 'TN17',
    uk_region: 'South East',
    postal_town: 'Cranbrook',
  },
  {
    postcode: 'TN18',
    uk_region: 'South East',
    postal_town: 'Cranbrook',
  },
  {
    postcode: 'TN19',
    uk_region: 'South East',
    postal_town: 'Etchingham',
  },
  {
    postcode: 'TN20',
    uk_region: 'South East',
    postal_town: 'Mayfield',
  },
  {
    postcode: 'TN21',
    uk_region: 'South East',
    postal_town: 'Heathfield',
  },
  {
    postcode: 'TN22',
    uk_region: 'South East',
    postal_town: 'Uckfield',
  },
  {
    postcode: 'TN23',
    uk_region: 'South East',
    postal_town: 'Ashford',
  },
  {
    postcode: 'TN24',
    uk_region: 'South East',
    postal_town: 'Ashford',
  },
  {
    postcode: 'TN25',
    uk_region: 'South East',
    postal_town: 'Ashford',
  },
  {
    postcode: 'TN26',
    uk_region: 'South East',
    postal_town: 'Ashford',
  },
  {
    postcode: 'TN27',
    uk_region: 'South East',
    postal_town: 'Ashford',
  },
  {
    postcode: 'TN28',
    uk_region: 'South East',
    postal_town: 'New Romney',
  },
  {
    postcode: 'TN29',
    uk_region: 'South East',
    postal_town: 'Romney Marsh',
  },
  {
    postcode: 'TN30',
    uk_region: 'South East',
    postal_town: 'Tenterden',
  },
  {
    postcode: 'TN31',
    uk_region: 'South East',
    postal_town: 'Rye',
  },
  {
    postcode: 'TN32',
    uk_region: 'South East',
    postal_town: 'Robertsbridge',
  },
  {
    postcode: 'TN33',
    uk_region: 'South East',
    postal_town: 'Battle',
  },
  {
    postcode: 'TN34',
    uk_region: 'South East',
    postal_town: 'Hastings',
  },
  {
    postcode: 'TN35',
    uk_region: 'South East',
    postal_town: 'Hastings',
  },
  {
    postcode: 'TN36',
    uk_region: 'South East',
    postal_town: 'Winchelsea',
  },
  {
    postcode: 'TN37',
    uk_region: 'South East',
    postal_town: 'St. Leonards-on-Sea',
  },
  {
    postcode: 'TN38',
    uk_region: 'South East',
    postal_town: 'St. Leonards-on-Sea',
  },
  {
    postcode: 'TN39',
    uk_region: 'South East',
    postal_town: 'Bexhill-on-Sea',
  },
  {
    postcode: 'TN40',
    uk_region: 'South East',
    postal_town: 'Bexhill-on-Sea',
  },
  {
    postcode: 'TQ1',
    uk_region: 'South West',
    postal_town: 'Torquay',
  },
  {
    postcode: 'TQ2',
    uk_region: 'South West',
    postal_town: 'Torquay',
  },
  {
    postcode: 'TQ3',
    uk_region: 'South West',
    postal_town: 'Paignton',
  },
  {
    postcode: 'TQ4',
    uk_region: 'South West',
    postal_town: 'Paignton',
  },
  {
    postcode: 'TQ5',
    uk_region: 'South West',
    postal_town: 'Brixham',
  },
  {
    postcode: 'TQ6',
    uk_region: 'South West',
    postal_town: 'Dartmouth',
  },
  {
    postcode: 'TQ7',
    uk_region: 'South West',
    postal_town: 'Kingsbridge',
  },
  {
    postcode: 'TQ8',
    uk_region: 'South West',
    postal_town: 'Salcombe',
  },
  {
    postcode: 'TQ9',
    uk_region: 'South West',
    postal_town: '"Totnes, South Brent"',
  },
  {
    postcode: 'TQ10',
    uk_region: 'South West',
    postal_town: 'South Brent',
  },
  {
    postcode: 'TQ11',
    uk_region: 'South West',
    postal_town: 'Buckfastleigh',
  },
  {
    postcode: 'TQ12',
    uk_region: 'South West',
    postal_town: 'Newton Abbot',
  },
  {
    postcode: 'TQ13',
    uk_region: 'South West',
    postal_town: 'Newton Abbot',
  },
  {
    postcode: 'TQ14',
    uk_region: 'South West',
    postal_town: 'Teignmouth',
  },
  {
    postcode: 'TR1',
    uk_region: 'South West',
    postal_town: 'Truro',
  },
  {
    postcode: 'TR2',
    uk_region: 'South West',
    postal_town: 'Truro',
  },
  {
    postcode: 'TR3',
    uk_region: 'South West',
    postal_town: 'Truro',
  },
  {
    postcode: 'TR4',
    uk_region: 'South West',
    postal_town: 'Truro',
  },
  {
    postcode: 'TR5',
    uk_region: 'South West',
    postal_town: 'St. Agnes',
  },
  {
    postcode: 'TR6',
    uk_region: 'South West',
    postal_town: 'Perranporth',
  },
  {
    postcode: 'TR7',
    uk_region: 'South West',
    postal_town: 'Newquay',
  },
  {
    postcode: 'TR8',
    uk_region: 'South West',
    postal_town: 'Newquay',
  },
  {
    postcode: 'TR9',
    uk_region: 'South West',
    postal_town: 'St. Columb',
  },
  {
    postcode: 'TR10',
    uk_region: 'South West',
    postal_town: 'Penryn',
  },
  {
    postcode: 'TR11',
    uk_region: 'South West',
    postal_town: 'Falmouth',
  },
  {
    postcode: 'TR12',
    uk_region: 'South West',
    postal_town: 'Helston',
  },
  {
    postcode: 'TR13',
    uk_region: 'South West',
    postal_town: 'Helston',
  },
  {
    postcode: 'TR14',
    uk_region: 'South West',
    postal_town: 'Camborne',
  },
  {
    postcode: 'TR15',
    uk_region: 'South West',
    postal_town: 'Redruth',
  },
  {
    postcode: 'TR16',
    uk_region: 'South West',
    postal_town: 'Redruth',
  },
  {
    postcode: 'TR17',
    uk_region: 'South West',
    postal_town: 'Marazion',
  },
  {
    postcode: 'TR18',
    uk_region: 'South West',
    postal_town: 'Penzance',
  },
  {
    postcode: 'TR19',
    uk_region: 'South West',
    postal_town: 'Penzance',
  },
  {
    postcode: 'TR20',
    uk_region: 'South West',
    postal_town: 'Penzance',
  },
  {
    postcode: 'TR21',
    uk_region: 'South West',
    postal_town: 'Isles of Scilly',
  },
  {
    postcode: 'TR22',
    uk_region: 'South West',
    postal_town: 'Isles of Scilly',
  },
  {
    postcode: 'TR23',
    uk_region: 'South West',
    postal_town: 'Isles of Scilly',
  },
  {
    postcode: 'TR24',
    uk_region: 'South West',
    postal_town: 'Isles of Scilly',
  },
  {
    postcode: 'TR25',
    uk_region: 'South West',
    postal_town: 'Isles of Scilly',
  },
  {
    postcode: 'TR26',
    uk_region: 'South West',
    postal_town: 'St. Ives',
  },
  {
    postcode: 'TR27',
    uk_region: 'South West',
    postal_town: 'Hayle',
  },
  {
    postcode: 'TR93',
    uk_region: 'South West',
    postal_town: '',
  },
  {
    postcode: 'TS1',
    uk_region: 'North East',
    postal_town: 'Middlesbrough',
  },
  {
    postcode: 'TS2',
    uk_region: 'North East',
    postal_town: 'Middlesbrough',
  },
  {
    postcode: 'TS3',
    uk_region: 'North East',
    postal_town: 'Middlesbrough',
  },
  {
    postcode: 'TS4',
    uk_region: 'North East',
    postal_town: 'Middlesbrough',
  },
  {
    postcode: 'TS5',
    uk_region: 'North East',
    postal_town: 'Middlesbrough',
  },
  {
    postcode: 'TS6',
    uk_region: 'North East',
    postal_town: 'Middlesbrough',
  },
  {
    postcode: 'TS7',
    uk_region: 'North East',
    postal_town: 'Middlesbrough',
  },
  {
    postcode: 'TS8',
    uk_region: 'North East',
    postal_town: 'Middlesbrough',
  },
  {
    postcode: 'TS9',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Middlesbrough',
  },
  {
    postcode: 'TS10',
    uk_region: 'North East',
    postal_town: 'Redcar',
  },
  {
    postcode: 'TS11',
    uk_region: 'North East',
    postal_town: 'Redcar',
  },
  {
    postcode: 'TS12',
    uk_region: 'North East',
    postal_town: 'Saltburn-by-the-Sea',
  },
  {
    postcode: 'TS13',
    uk_region: 'North East',
    postal_town: 'Saltburn-by-the-Sea',
  },
  {
    postcode: 'TS14',
    uk_region: 'North East',
    postal_town: 'Guisborough',
  },
  {
    postcode: 'TS15',
    uk_region: 'North East',
    postal_town: 'Yarm',
  },
  {
    postcode: 'TS16',
    uk_region: 'North East',
    postal_town: 'Stockton-on-Tees',
  },
  {
    postcode: 'TS17',
    uk_region: 'North East',
    postal_town: 'Stockton-on-Tees',
  },
  {
    postcode: 'TS18',
    uk_region: 'North East',
    postal_town: 'Stockton-on-Tees',
  },
  {
    postcode: 'TS19',
    uk_region: 'North East',
    postal_town: 'Stockton-on-Tees',
  },
  {
    postcode: 'TS20',
    uk_region: 'North East',
    postal_town: 'Stockton-on-Tees',
  },
  {
    postcode: 'TS21',
    uk_region: 'North East',
    postal_town: 'Stockton-on-Tees',
  },
  {
    postcode: 'TS22',
    uk_region: 'North East',
    postal_town: 'Billingham',
  },
  {
    postcode: 'TS23',
    uk_region: 'North East',
    postal_town: 'Billingham',
  },
  {
    postcode: 'TS24',
    uk_region: 'North East',
    postal_town: 'Hartlepool',
  },
  {
    postcode: 'TS25',
    uk_region: 'North East',
    postal_town: 'Hartlepool',
  },
  {
    postcode: 'TS26',
    uk_region: 'North East',
    postal_town: 'Hartlepool',
  },
  {
    postcode: 'TS27',
    uk_region: 'North East',
    postal_town: 'Hartlepool',
  },
  {
    postcode: 'TS28',
    uk_region: 'North East',
    postal_town: 'Wingate',
  },
  {
    postcode: 'TS29',
    uk_region: 'North East',
    postal_town: 'Trimdon Station',
  },
  {
    postcode: 'TS90',
    uk_region: 'North East',
    postal_town: '',
  },
  {
    postcode: 'TW1',
    uk_region: 'London',
    postal_town: 'Twickenham',
  },
  {
    postcode: 'TW2',
    uk_region: 'London',
    postal_town: 'Twickenham',
  },
  {
    postcode: 'TW3',
    uk_region: 'London',
    postal_town: 'Hounslow',
  },
  {
    postcode: 'TW4',
    uk_region: 'London',
    postal_town: 'Hounslow',
  },
  {
    postcode: 'TW5',
    uk_region: 'London',
    postal_town: 'Hounslow',
  },
  {
    postcode: 'TW6',
    uk_region: 'London',
    postal_town: 'Hounslow',
  },
  {
    postcode: 'TW7',
    uk_region: 'London',
    postal_town: 'Isleworth',
  },
  {
    postcode: 'TW8',
    uk_region: 'London',
    postal_town: 'Brentford',
  },
  {
    postcode: 'TW9',
    uk_region: 'London',
    postal_town: 'Richmond',
  },
  {
    postcode: 'TW10',
    uk_region: 'London',
    postal_town: 'Richmond',
  },
  {
    postcode: 'TW11',
    uk_region: 'London',
    postal_town: 'Teddington',
  },
  {
    postcode: 'TW12',
    uk_region: 'London',
    postal_town: 'Hampton',
  },
  {
    postcode: 'TW13',
    uk_region: 'London',
    postal_town: 'Feltham',
  },
  {
    postcode: 'TW14',
    uk_region: 'London',
    postal_town: 'Feltham',
  },
  {
    postcode: 'TW15',
    uk_region: 'South East',
    postal_town: 'Ashford',
  },
  {
    postcode: 'TW16',
    uk_region: 'South East',
    postal_town: 'Sunbury-on-Thames',
  },
  {
    postcode: 'TW17',
    uk_region: 'South East',
    postal_town: 'Shepperton',
  },
  {
    postcode: 'TW18',
    uk_region: 'South East',
    postal_town: 'Staines-upon-Thames',
  },
  {
    postcode: 'TW19',
    uk_region: 'South East',
    postal_town: 'Staines-upon-Thames',
  },
  {
    postcode: 'TW20',
    uk_region: 'South East',
    postal_town: 'Egham',
  },
  {
    postcode: 'UB1',
    uk_region: 'London',
    postal_town: 'Southall',
  },
  {
    postcode: 'UB2',
    uk_region: 'London',
    postal_town: 'Southall',
  },
  {
    postcode: 'UB3',
    uk_region: 'London',
    postal_town: '"Southall, Hayes"',
  },
  {
    postcode: 'UB4',
    uk_region: 'London',
    postal_town: 'Hayes',
  },
  {
    postcode: 'UB5',
    uk_region: 'London',
    postal_town: '"Northolt, Greenford"',
  },
  {
    postcode: 'UB6',
    uk_region: 'London',
    postal_town: 'Greenford',
  },
  {
    postcode: 'UB7',
    uk_region: 'London',
    postal_town: 'West Drayton',
  },
  {
    postcode: 'UB8',
    uk_region: 'London',
    postal_town: '"West Drayton, Uxbridge"',
  },
  {
    postcode: 'UB9',
    uk_region: 'London',
    postal_town: 'Uxbridge',
  },
  {
    postcode: 'UB10',
    uk_region: 'London',
    postal_town: 'Uxbridge',
  },
  {
    postcode: 'UB11',
    uk_region: 'London',
    postal_town: 'Uxbridge',
  },
  {
    postcode: 'UB18',
    uk_region: 'London',
    postal_town: 'Greenford',
  },
  {
    postcode: 'W1',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'W1A',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'W1B',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'W1C',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'W1D',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'W1E',
    uk_region: '',
    postal_town: '',
  },
  {
    postcode: 'W1F',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'W1G',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'W1H',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'W1J',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'W1K',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'W1M',
    uk_region: 'London',
    postal_town: '',
  },
  {
    postcode: 'W1N',
    uk_region: 'London',
    postal_town: '',
  },
  {
    postcode: 'W1P',
    uk_region: 'London',
    postal_town: '',
  },
  {
    postcode: 'W1R',
    uk_region: 'London',
    postal_town: '',
  },
  {
    postcode: 'W1S',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'W1T',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'W1U',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'W1V',
    uk_region: 'London',
    postal_town: '',
  },
  {
    postcode: 'W1W',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'W1X',
    uk_region: 'London',
    postal_town: '',
  },
  {
    postcode: 'W1Y',
    uk_region: 'London',
    postal_town: '',
  },
  {
    postcode: 'W2',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'W3',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'W4',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'W5',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'W6',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'W7',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'W8',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'W9',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'W10',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'W11',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'W12',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'W13',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'W14',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'WA1',
    uk_region: 'North West',
    postal_town: 'Warrington',
  },
  {
    postcode: 'WA2',
    uk_region: 'North West',
    postal_town: 'Warrington',
  },
  {
    postcode: 'WA3',
    uk_region: 'North West',
    postal_town: 'Warrington',
  },
  {
    postcode: 'WA4',
    uk_region: 'North West',
    postal_town: 'Warrington',
  },
  {
    postcode: 'WA5',
    uk_region: 'North West',
    postal_town: 'Warrington',
  },
  {
    postcode: 'WA6',
    uk_region: 'North West',
    postal_town: 'Frodsham',
  },
  {
    postcode: 'WA7',
    uk_region: 'North West',
    postal_town: 'Runcorn',
  },
  {
    postcode: 'WA8',
    uk_region: 'North West',
    postal_town: 'Widnes',
  },
  {
    postcode: 'WA9',
    uk_region: 'North West',
    postal_town: 'St. Helens',
  },
  {
    postcode: 'WA10',
    uk_region: 'North West',
    postal_town: 'St. Helens',
  },
  {
    postcode: 'WA11',
    uk_region: 'North West',
    postal_town: 'St. Helens',
  },
  {
    postcode: 'WA12',
    uk_region: 'North West',
    postal_town: 'Newton-le-Willows',
  },
  {
    postcode: 'WA13',
    uk_region: 'North West',
    postal_town: 'Lymm',
  },
  {
    postcode: 'WA14',
    uk_region: 'North West',
    postal_town: 'Altrincham',
  },
  {
    postcode: 'WA15',
    uk_region: 'North West',
    postal_town: 'Altrincham',
  },
  {
    postcode: 'WA16',
    uk_region: 'North West',
    postal_town: 'Knutsford',
  },
  {
    postcode: 'WA55',
    uk_region: 'North West',
    postal_town: 'Warrington',
  },
  {
    postcode: 'WA88',
    uk_region: 'North West',
    postal_town: 'Widnes',
  },
  {
    postcode: 'WC1',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'WC1A',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'WC1B',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'WC1E',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'WC1H',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'WC1N',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'WC1R',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'WC1V',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'WC1X',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'WC2',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'WC2A',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'WC2B',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'WC2E',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'WC2H',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'WC2N',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'WC2R',
    uk_region: 'London',
    postal_town: 'London',
  },
  {
    postcode: 'WC99',
    uk_region: 'London',
    postal_town: '',
  },
  {
    postcode: 'WD1',
    uk_region: 'East of England',
    postal_town: '',
  },
  {
    postcode: 'WD2',
    uk_region: 'East of England',
    postal_town: '',
  },
  {
    postcode: 'WD3',
    uk_region: 'East of England',
    postal_town: 'Rickmansworth',
  },
  {
    postcode: 'WD4',
    uk_region: 'East of England',
    postal_town: 'Kings Langley',
  },
  {
    postcode: 'WD5',
    uk_region: 'East of England',
    postal_town: 'Abbots Langley',
  },
  {
    postcode: 'WD6',
    uk_region: 'East of England',
    postal_town: 'Borehamwood',
  },
  {
    postcode: 'WD7',
    uk_region: 'East of England',
    postal_town: 'Radlett',
  },
  {
    postcode: 'WD17',
    uk_region: 'East of England',
    postal_town: 'Watford',
  },
  {
    postcode: 'WD18',
    uk_region: 'East of England',
    postal_town: '"Kings Langley, Watford"',
  },
  {
    postcode: 'WD19',
    uk_region: 'East of England',
    postal_town: 'Watford',
  },
  {
    postcode: 'WD23',
    uk_region: 'East of England',
    postal_town: 'Bushey',
  },
  {
    postcode: 'WD24',
    uk_region: 'East of England',
    postal_town: 'Watford',
  },
  {
    postcode: 'WD25',
    uk_region: 'East of England',
    postal_town: 'Watford',
  },
  {
    postcode: 'WD99',
    uk_region: 'East of England',
    postal_town: 'Watford',
  },
  {
    postcode: 'WF1',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Wakefield',
  },
  {
    postcode: 'WF2',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Wakefield',
  },
  {
    postcode: 'WF3',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Wakefield',
  },
  {
    postcode: 'WF4',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Wakefield',
  },
  {
    postcode: 'WF5',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Ossett',
  },
  {
    postcode: 'WF6',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Normanton',
  },
  {
    postcode: 'WF7',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Pontefract',
  },
  {
    postcode: 'WF8',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Pontefract',
  },
  {
    postcode: 'WF9',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Pontefract',
  },
  {
    postcode: 'WF10',
    uk_region: 'Yorkshire and The Humber',
    postal_town: '"Normanton, Castleford"',
  },
  {
    postcode: 'WF11',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Knottingley',
  },
  {
    postcode: 'WF12',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Dewsbury',
  },
  {
    postcode: 'WF13',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Dewsbury',
  },
  {
    postcode: 'WF14',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Mirfield',
  },
  {
    postcode: 'WF15',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Liversedge',
  },
  {
    postcode: 'WF16',
    uk_region: 'Yorkshire and The Humber',
    postal_town: '"Liversedge, Heckmondwike"',
  },
  {
    postcode: 'WF17',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Batley',
  },
  {
    postcode: 'WF90',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Wakefield',
  },
  {
    postcode: 'WN1',
    uk_region: 'North West',
    postal_town: 'Wigan',
  },
  {
    postcode: 'WN2',
    uk_region: 'North West',
    postal_town: 'Wigan',
  },
  {
    postcode: 'WN3',
    uk_region: 'North West',
    postal_town: 'Wigan',
  },
  {
    postcode: 'WN4',
    uk_region: 'North West',
    postal_town: 'Wigan',
  },
  {
    postcode: 'WN5',
    uk_region: 'North West',
    postal_town: 'Wigan',
  },
  {
    postcode: 'WN6',
    uk_region: 'North West',
    postal_town: 'Wigan',
  },
  {
    postcode: 'WN7',
    uk_region: 'North West',
    postal_town: 'Leigh',
  },
  {
    postcode: 'WN8',
    uk_region: 'North West',
    postal_town: '"Wigan, Skelmersdale"',
  },
  {
    postcode: 'WR1',
    uk_region: 'West Midlands',
    postal_town: 'Worcester',
  },
  {
    postcode: 'WR2',
    uk_region: 'West Midlands',
    postal_town: 'Worcester',
  },
  {
    postcode: 'WR3',
    uk_region: 'West Midlands',
    postal_town: 'Worcester',
  },
  {
    postcode: 'WR4',
    uk_region: 'West Midlands',
    postal_town: 'Worcester',
  },
  {
    postcode: 'WR5',
    uk_region: 'West Midlands',
    postal_town: 'Worcester',
  },
  {
    postcode: 'WR6',
    uk_region: 'West Midlands',
    postal_town: 'Worcester',
  },
  {
    postcode: 'WR7',
    uk_region: 'West Midlands',
    postal_town: 'Worcester',
  },
  {
    postcode: 'WR8',
    uk_region: 'West Midlands',
    postal_town: 'Worcester',
  },
  {
    postcode: 'WR9',
    uk_region: 'West Midlands',
    postal_town: 'Droitwich',
  },
  {
    postcode: 'WR10',
    uk_region: 'West Midlands',
    postal_town: 'Pershore',
  },
  {
    postcode: 'WR11',
    uk_region: 'West Midlands',
    postal_town: '"Evesham, Broadway"',
  },
  {
    postcode: 'WR12',
    uk_region: 'West Midlands',
    postal_town: 'Broadway',
  },
  {
    postcode: 'WR13',
    uk_region: 'West Midlands',
    postal_town: 'Malvern',
  },
  {
    postcode: 'WR14',
    uk_region: 'West Midlands',
    postal_town: 'Malvern',
  },
  {
    postcode: 'WR15',
    uk_region: 'West Midlands',
    postal_town: 'Tenbury Wells',
  },
  {
    postcode: 'WR78',
    uk_region: 'West Midlands',
    postal_town: 'Worcester',
  },
  {
    postcode: 'WR99',
    uk_region: 'West Midlands',
    postal_town: 'Worcester',
  },
  {
    postcode: 'WS1',
    uk_region: 'West Midlands',
    postal_town: 'Walsall',
  },
  {
    postcode: 'WS2',
    uk_region: 'West Midlands',
    postal_town: 'Walsall',
  },
  {
    postcode: 'WS3',
    uk_region: 'West Midlands',
    postal_town: 'Walsall',
  },
  {
    postcode: 'WS4',
    uk_region: 'West Midlands',
    postal_town: 'Walsall',
  },
  {
    postcode: 'WS5',
    uk_region: 'West Midlands',
    postal_town: 'Walsall',
  },
  {
    postcode: 'WS6',
    uk_region: 'West Midlands',
    postal_town: 'Walsall',
  },
  {
    postcode: 'WS7',
    uk_region: 'West Midlands',
    postal_town: 'Burntwood',
  },
  {
    postcode: 'WS8',
    uk_region: 'West Midlands',
    postal_town: 'Walsall',
  },
  {
    postcode: 'WS9',
    uk_region: 'West Midlands',
    postal_town: 'Walsall',
  },
  {
    postcode: 'WS10',
    uk_region: 'West Midlands',
    postal_town: 'Wednesbury',
  },
  {
    postcode: 'WS11',
    uk_region: 'West Midlands',
    postal_town: 'Cannock',
  },
  {
    postcode: 'WS12',
    uk_region: 'West Midlands',
    postal_town: 'Cannock',
  },
  {
    postcode: 'WS13',
    uk_region: 'West Midlands',
    postal_town: 'Lichfield',
  },
  {
    postcode: 'WS14',
    uk_region: 'West Midlands',
    postal_town: 'Lichfield',
  },
  {
    postcode: 'WS15',
    uk_region: 'West Midlands',
    postal_town: 'Rugeley',
  },
  {
    postcode: 'WV1',
    uk_region: 'West Midlands',
    postal_town: '"Wolverhampton, Willenhall"',
  },
  {
    postcode: 'WV2',
    uk_region: 'West Midlands',
    postal_town: 'Wolverhampton',
  },
  {
    postcode: 'WV3',
    uk_region: 'West Midlands',
    postal_town: 'Wolverhampton',
  },
  {
    postcode: 'WV4',
    uk_region: 'West Midlands',
    postal_town: 'Wolverhampton',
  },
  {
    postcode: 'WV5',
    uk_region: 'West Midlands',
    postal_town: 'Wolverhampton',
  },
  {
    postcode: 'WV6',
    uk_region: 'West Midlands',
    postal_town: 'Wolverhampton',
  },
  {
    postcode: 'WV7',
    uk_region: 'West Midlands',
    postal_town: 'Wolverhampton',
  },
  {
    postcode: 'WV8',
    uk_region: 'West Midlands',
    postal_town: 'Wolverhampton',
  },
  {
    postcode: 'WV9',
    uk_region: 'West Midlands',
    postal_town: 'Wolverhampton',
  },
  {
    postcode: 'WV10',
    uk_region: 'West Midlands',
    postal_town: 'Wolverhampton',
  },
  {
    postcode: 'WV11',
    uk_region: 'West Midlands',
    postal_town: 'Wolverhampton',
  },
  {
    postcode: 'WV12',
    uk_region: 'West Midlands',
    postal_town: 'Willenhall',
  },
  {
    postcode: 'WV13',
    uk_region: 'West Midlands',
    postal_town: 'Willenhall',
  },
  {
    postcode: 'WV14',
    uk_region: 'West Midlands',
    postal_town: 'Bilston',
  },
  {
    postcode: 'WV15',
    uk_region: 'West Midlands',
    postal_town: 'Bridgnorth',
  },
  {
    postcode: 'WV16',
    uk_region: 'West Midlands',
    postal_town: 'Bridgnorth',
  },
  {
    postcode: 'WV98',
    uk_region: 'West Midlands',
    postal_town: 'Wolverhampton',
  },
  {
    postcode: 'WV99',
    uk_region: 'West Midlands',
    postal_town: '',
  },
  {
    postcode: 'YO1',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'York',
  },
  {
    postcode: 'YO2',
    uk_region: 'Yorkshire and The Humber',
    postal_town: '',
  },
  {
    postcode: 'YO3',
    uk_region: 'Yorkshire and The Humber',
    postal_town: '',
  },
  {
    postcode: 'YO4',
    uk_region: 'Yorkshire and The Humber',
    postal_town: '',
  },
  {
    postcode: 'YO5',
    uk_region: 'Yorkshire and The Humber',
    postal_town: '',
  },
  {
    postcode: 'YO6',
    uk_region: 'Yorkshire and The Humber',
    postal_town: '',
  },
  {
    postcode: 'YO7',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Thirsk',
  },
  {
    postcode: 'YO8',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Selby',
  },
  {
    postcode: 'YO10',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'York',
  },
  {
    postcode: 'YO11',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Scarborough',
  },
  {
    postcode: 'YO12',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Scarborough',
  },
  {
    postcode: 'YO13',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Scarborough',
  },
  {
    postcode: 'YO14',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Filey',
  },
  {
    postcode: 'YO15',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Bridlington',
  },
  {
    postcode: 'YO16',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Bridlington',
  },
  {
    postcode: 'YO17',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Malton',
  },
  {
    postcode: 'YO18',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Pickering',
  },
  {
    postcode: 'YO19',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'York',
  },
  {
    postcode: 'YO21',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Whitby',
  },
  {
    postcode: 'YO22',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Whitby',
  },
  {
    postcode: 'YO23',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'York',
  },
  {
    postcode: 'YO24',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'York',
  },
  {
    postcode: 'YO25',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'Driffield',
  },
  {
    postcode: 'YO26',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'York',
  },
  {
    postcode: 'YO30',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'York',
  },
  {
    postcode: 'YO31',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'York',
  },
  {
    postcode: 'YO32',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'York',
  },
  {
    postcode: 'YO41',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'York',
  },
  {
    postcode: 'YO42',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'York',
  },
  {
    postcode: 'YO43',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'York',
  },
  {
    postcode: 'YO51',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'York',
  },
  {
    postcode: 'YO60',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'York',
  },
  {
    postcode: 'YO61',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'York',
  },
  {
    postcode: 'YO62',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'York',
  },
  {
    postcode: 'YO90',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'York',
  },
  {
    postcode: 'YO91',
    uk_region: 'Yorkshire and The Humber',
    postal_town: 'York',
  },
  {
    postcode: 'YO95',
    uk_region: 'Yorkshire and The Humber',
    postal_town: '',
  },
  {
    postcode: 'ZE1',
    uk_region: 'Scotland',
    postal_town: 'Shetland',
  },
  {
    postcode: 'ZE2',
    uk_region: 'Scotland',
    postal_town: 'Shetland',
  },
  {
    postcode: 'ZE3',
    uk_region: 'Scotland',
    postal_town: 'Shetland',
  },
];
