import { useLocation } from 'react-router-dom';

import useTimeout from '../../shared/hooks/use-timeout';
import { useAppDispatch, useAppSelector } from '../../store';
import { priceExceptionFilters, resetPriceExceptionsFilters, setPriceExceptionKeyword } from '../../store/price-exception';
import { RestaurantUser } from '../user/types';
import { useGetPriceExceptionsQuery } from './index';
import { PriceExceptionItem } from './types';

export const useGetPriceExceptions = () => {
  const { keyword, currentPage, filter, sort } = useAppSelector(priceExceptionFilters);
  const { list, totalPages, loading, totalCount } = useGetPriceExceptionsQuery(
    { keyword, currentPage, filter, sort },
    {
      selectFromResult: ({ data, isLoading }) => ({
        list: data?.price_exceptions || [],
        totalPages: data?.total_pages || 0,
        loading: isLoading,
        totalCount: data?.total_price_exceptions || 0,
      }),
    },
  );
  return { list, totalPages, loading, totalCount };
};

export const useGetPriceExceptionById = (id: number) => {
  const { keyword, currentPage, filter } = useAppSelector(priceExceptionFilters);
  const { priceException } = useGetPriceExceptionsQuery(
    { keyword, currentPage, filter },
    {
      selectFromResult: ({ data }) => ({
        priceException:
          data?.price_exceptions.reduce<(PriceExceptionItem & { restaurant: RestaurantUser }) | null>((acc, exception) => {
            const currentPriceException = exception[1].find((el) => el.id === id);
            if (currentPriceException) {
              acc = { ...currentPriceException, restaurant: exception[0] };
            }
            return acc;
          }, null) || null,
      }),
    },
  );
  return { priceException };
};

export const useSetPriceExceptionsDefaults = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { keyword, sort, filter, currentPage } = useAppSelector(priceExceptionFilters);
  useTimeout(
    () => {
      dispatch(setPriceExceptionKeyword(''));
    },
    pathname.includes('/products') || !keyword ? null : 300000,
  );
  if (!pathname.includes('/products')) {
    (sort || filter || currentPage !== 1) &&
      dispatch(
        resetPriceExceptionsFilters({
          page: true,
          filter: true,
          sort: true,
        }),
      );
  }
};
