import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../../../../styles/colorVariables';
import { Sizes } from '../../../../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    searchBox: {
      width: 750,
      backgroundColor: colorVariables.white,
      position: 'absolute',
      borderRadius: 4,
      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
      marginTop: 10,
      flexShrink: 0,
      left: -30,
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '90vw',
        left: -130,
      },
    },
    searchBoxHidden: {
      height: 0,
      overflow: 'hidden',
      opacity: 0,
    },
    rootField: {
      width: '90%',
      margin: 0,
      padding: 0,
      '& legend': {
        width: 0,
      },
      '& .MuiAutocomplete-inputRoot': {
        paddingRight: '0 !important',
        '&::before, &::after': {
          display: 'none !important',
        },
      },
      '& .MuiInputBase-input.MuiInput-input.MuiAutocomplete-input': {
        fontSize: 14,
        fontWeight: 400,
        color: colorVariables.navy,
        lineHeight: '24px',
        height: 24,
        padding: '0 30px 0 10px !important',
        backgroundColor: colorVariables.white,
        border: `1px solid ${colorVariables.steelGrey}`,
        '&:hover': {
          [theme.breakpoints.up(Sizes.desktop)]: {
            borderColor: colorVariables.blue,
          },
        },
        '&:focus': {
          borderColor: colorVariables.blue,
        },
      },
      '& .MuiAutocomplete-inputRoot.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd': {
        display: 'none',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
      },
    },
    optionWrap: {
      display: 'flex',
      backgroundColor: colorVariables.transparent,
      padding: 10,
      alignItems: 'center',
      [theme.breakpoints.down(Sizes.mobile)]: {
        flexWrap: 'wrap',
        padding: '5px 10px',
      },
    },
    option: {
      backgroundColor: colorVariables.white,
      fontSize: 14,
      height: 40,
      paddingRight: 3,
      marginTop: 2,
      '&:hover': {
        backgroundColor: colorVariables.blueLight,
      },
      '&:focus': {
        backgroundColor: colorVariables.blueLight,
      },
      '&[data-focus="true"]': {
        backgroundColor: colorVariables.blueLight,
      },
      '&:active': {
        backgroundColor: colorVariables.blueLight,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        height: 'auto',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        paddingRight: 0,
        marginBottom: 5,
      },
    },
    noOptions: {
      fontSize: 14,
    },
    nameSection: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        position: 'relative',
        paddingLeft: 35,
      },
    },
    imgWrap: {
      width: 28,
      height: 28,
      borderRadius: 4,
      overflow: 'hidden',
      fontSize: 0,
      flexShrink: 0,
      marginRight: 17,
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginTop: 3,
        width: 36,
        height: 36,
        marginRight: 12,
        position: 'absolute',
        top: 0,
        left: -15,
      },
    },
    recipeName: {
      width: 280,
      flexShrink: 0,
      overflow: 'hidden',
      borderRight: `1px solid ${colorVariables.grey40}`,
      [theme.breakpoints.down(Sizes.tablet)]: {
        width: 200,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        borderRight: 'none',
        width: '100%',
        marginBottom: 3,
      },
    },
    marked: {
      color: colorVariables.blue,
    },
    categorySection: {
      flexShrink: 0,
      padding: '0 20px',
      width: 140,
      fontSize: 12,
      color: colorVariables.grey60,
      borderRight: `1px solid ${colorVariables.grey40}`,
      [theme.breakpoints.down(Sizes.tablet)]: {
        padding: '0 10px',
        width: 130,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '25%',
        marginLeft: 28,
        padding: '0 5px',
        lineHeight: '14px',
      },
    },
    typeSection: {
      flexShrink: 0,
      padding: '0 20px',
      width: 120,
      fontSize: 12,
      color: colorVariables.grey60,
      borderRight: `1px solid ${colorVariables.grey40}`,
      [theme.breakpoints.down(Sizes.tablet)]: {
        padding: '0 10px',
        width: 90,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '23%',
        padding: '0 5px',
        lineHeight: '14px',
      },
    },
    idSection: {
      flexShrink: 0,
      padding: '0 20px',
      fontSize: 12,
      color: '#5B6578',
    },
    closeIcon: {
      width: 18,
      height: 18,
      '& svg': {
        fontSize: 18,
        color: colorVariables.grey60,
      },
    },
  }),
);
