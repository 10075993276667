import { createStyles, makeStyles } from '@material-ui/core';
import { colorVariables } from '../../../styles/colorVariables';
import { Sizes } from '../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: 'fixed',
      zIndex: 9999,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      paddingTop: 60,
      paddingBottom: 60,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflowY: 'scroll',
      backgroundColor: colorVariables.white,
      [theme.breakpoints.down(Sizes.desktop)]: {
        paddingTop: 40,
      },
    },
    title: {
      width: 500,
      textAlign: 'center',
      fontSize: 30,
      fontWeight: 700,
      lineHeight: '42px',
      margin: '50px 0',
      [theme.breakpoints.down(Sizes.desktop)]: {
        fontSize: 24,
        lineHeight: '36px',
        margin: '30px 0',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 'auto',
        maxWidth: 320,
        fontSize: 22,
        lineHeight: '30px',
        margin: '45px 0',
      },
    },
    btnBlock: {
      display: 'flex',
      justifyContent: 'space-between',
      width: 338,
      marginTop: 44,
      [theme.breakpoints.down(Sizes.desktop)]: {
        marginTop: 30,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 'auto',
        maxWidth: 320,
      },
    },
  }),
);
