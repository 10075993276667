import { RangeOptions } from '../components/range-dropdown';

export const getDaysFromRange = (range: RangeOptions) => {
  switch (range) {
    case RangeOptions.TODAY:
      return 1;
    case RangeOptions.LAST_7:
      return 7;
    case RangeOptions.LAST_28:
      return 28;
    case RangeOptions.LAST_60:
      return 60;
    case RangeOptions.LAST_90:
      return 90;
    case RangeOptions.LAST_6M:
      return 180;
    case RangeOptions.LAST_12M:
      return 365;
    case RangeOptions.ALL_TIME:
      return 0;
    default:
      return 60;
  }
};
