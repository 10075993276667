import * as React from 'react';

function SvgCategoryBakery(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M17.022 22.263H2.874a.413.413 0 01-.414-.413V9.14C1.226 8.723.6 8.036.6 7.097c0-2.374 4.106-4.233 9.347-4.233 5.242 0 9.35 1.859 9.35 4.233 0 .938-.627 1.626-1.861 2.044v12.71a.413.413 0 01-.414.413zm-13.734-.827h13.32v-12.6c0-.185.123-.347.301-.398 1.398-.396 1.56-.955 1.56-1.341 0-1.645-3.425-3.406-8.521-3.406-5.097 0-8.52 1.761-8.52 3.406 0 .386.162.946 1.56 1.341.177.05.3.213.3.399v12.599z'
        fill='#fff'
      />
      <path
        d='M17.022 22.263a.414.414 0 01-.185-.784l1.896-.948V7.773c0-.185.123-.347.301-.398 1.398-.396 1.56-.955 1.56-1.342 0-1.644-3.424-3.405-8.52-3.405-2.76 0-5.374.562-6.993 1.503a.415.415 0 01-.416-.716C6.431 2.39 9.131 1.8 12.074 1.8c5.241 0 9.347 1.86 9.347 4.233 0 .94-.625 1.626-1.86 2.045v12.708c0 .157-.088.3-.229.37l-2.125 1.063a.403.403 0 01-.184.044zM21.008 19.885a.414.414 0 01-.414-.414v-1.139a.413.413 0 11.827 0v1.139a.414.414 0 01-.413.414z'
        fill='#fff'
      />
      <path
        d='M21.008 17.09a.414.414 0 01-.414-.413V8.814c0-.174.108-.33.27-.39 1.782-.653 1.71-2.258 1.706-2.326a.415.415 0 01.386-.438.403.403 0 01.44.383c.052.748-.306 2.297-1.974 3.049v7.585a.414.414 0 01-.414.414zM11.09 18.384a.413.413 0 01-.183-.784c.403-.199 1.828-1.534 1.952-2.017-.112-.025-.419-.043-1.142.131-3.207.769-4.273.803-4.577.145-.24-.522.106-1.204 2.566-2.984 1.65-1.192 2.144-1.748 2.293-1.978-.316-.036-1.277.031-4.067.87-.888.267-1.749.438-2.03-.118-.127-.253-.514-1.023 4.026-4.409a.413.413 0 11.495.663c-1.562 1.166-3.265 2.65-3.67 3.282.166-.013.455-.064.942-.21 3.906-1.176 4.84-1.068 5.127-.482.26.523-.09 1.217-2.63 3.054-1.638 1.184-2.102 1.723-2.233 1.938.307.052 1.173-.002 3.565-.576.855-.206 1.874-.352 2.129.353.382 1.058-2.062 2.923-2.379 3.08a.414.414 0 01-.184.042z'
        fill='#fff'
      />
    </svg>
  );
}

export default SvgCategoryBakery;
