import * as React from 'react';

function SvgShop(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='22' height='20' viewBox='0 0 22 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#shop_svg__clip0_4479_67987)' fill='currentColor'>
        <path d='M11.062 8.584c1.96 1.986 3.998 1.947 5.838.046.957.993 2.089 1.578 3.597 1.27.015.324.037.578.037.833.001 2.414.005 4.827-.002 7.241-.004 1.43-.594 2.021-2.009 2.022-4.99.003-9.983.004-14.975 0-1.378 0-1.973-.592-1.979-1.963-.01-2.467-.012-4.933-.014-7.398 0-.203.017-.407.03-.68 1.362.176 2.487-.195 3.336-1.322.88.906 1.839 1.565 3.116 1.544 1.273-.021 2.266-.599 3.025-1.592v-.001zm-6.116 2.667v8.02h5.16v-8.02h-5.16zm7.865 2.197h-.004c0 .523.015 1.048-.004 1.57-.018.494.192.727.694.724 1.053-.009 2.107-.012 3.16-.004.467.003.67-.218.67-.668-.003-1.047-.003-2.093.001-3.14.002-.47-.175-.73-.699-.72-1.053.02-2.107.015-3.16.003-.473-.005-.669.212-.661.666.009.523.002 1.047.002 1.57zM10.634 6.268H5.793L6.957.047h3.677v6.22zM.207 6.305c.754-1.99 1.44-3.875 2.2-5.73.106-.258.567-.497.882-.52.913-.07 1.836-.026 2.855-.026-.377 2.027-.733 3.992-1.122 5.952-.026.131-.286.307-.44.31-1.412.024-2.825.013-4.375.013v.001zM16.17 6.27H11.56V.073h3.483c.37 2.037.745 4.087 1.129 6.195zM15.84.013c.875 0 1.665-.04 2.448.022.267.022.638.24.751.472.923 1.876 1.795 3.775 2.72 5.75H16.98L15.84.014V.013z' />
        <path d='M10.845 7.29c-.225.425-.36.94-.69 1.26-1.217 1.177-3.08 1.151-4.295.062-.446-.4-.411-.856-.23-1.275.077-.175.511-.269.786-.274 1.39-.027 2.782-.012 4.172-.012l.257.239zM16.232 7.095c.465.859.021 1.33-.602 1.735-1.117.724-2.553.614-3.592-.255-.468-.392-.68-.839-.441-1.48h4.634zM0 7.056h4.848c-.117 1.275-1.15 2.197-2.436 2.21C1.184 9.278.18 8.389.001 7.056H0zM17.164 7.106H22c-.217 1.336-1.281 2.243-2.513 2.194-1.273-.05-2.195-.892-2.322-2.194z' />
      </g>
      <defs>
        <clipPath id='shop_svg__clip0_4479_67987'>
          <path fill='#fff' d='M0 0h22v20H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgShop;
