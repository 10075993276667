import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../../../styles/colorVariables';

export const useStyles = makeStyles(() =>
  createStyles({
    box: {
      width: '100%',
      display: 'flex',
      borderRadius: 16,
      border: '1px solid #EDEFF6',
      boxShadow: '0 4px 10px rgba(52, 54, 90, 0.05)',
      marginBottom: 12,
      padding: '22px 20px 18px 20px',
      flexWrap: 'wrap',
      position: 'relative',
    },
    title: {
      width: '70%',
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '20px',
      marginBottom: 15,
    },
    itemsCountBox: {
      width: '25%',
      marginLeft: 'auto',
      textAlign: 'right',
      color: colorVariables.grey60,
      '& span': {
        fontWeight: 600,
      },
    },
    suppliersBox: {
      width: '100%',
      display: 'flex',
      marginBottom: 23,
      alignItems: 'flex-end',
      overflow: 'hidden',
      '& span': {
        transform: 'translate(-7px, -1px)',
        fontSize: 16,
      },
    },
    supplierLogo: {
      width: 29,
      height: 29,
      borderRadius: '50%',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colorVariables.white,
      border: `1px solid ${colorVariables.steelGrey}`,
      marginRight: 8,
      flexShrink: 0,
      '& img': {
        width: '100%',
        flexShrink: 0,
      },
    },
    actionsBox: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    },
    iconBtnsWrap: {
      display: 'flex',
    },
    iconBtn: {
      width: 40,
      height: 40,
      padding: 0,
      marginRight: 14,
      backgroundColor: 'rgba(242, 243, 247, 0.5)',
      '&:hover': {
        backgroundColor: 'rgba(242, 243, 247, 0.5)',
      },
    },
    btn: {
      height: 40,
      '&:first-child': {
        marginLeft: 0,
      },
    },
    dialog: {
      width: 300,
      right: 0,
      bottom: 0,
    },
  }),
);
