import React, { ChangeEvent, ReactNode } from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { CheckboxProps, fieldToCheckbox } from 'formik-material-ui';

import { useStyles } from './style';

interface Props {
  labelPlace?: 'start' | 'end';
  formControlClassName?: string;
  label?: ReactNode;
  submitOnChange?: boolean;
}

export const FormikCheckbox: React.FC<Props & CheckboxProps> = (props) => {
  const classes = useStyles();
  const { formControlClassName = '', labelPlace = 'start', label, submitOnChange, ...checkboxProps } = props;

  const handleSubmit = (e: ChangeEvent<HTMLInputElement>) => {
    checkboxProps.form.setFieldValue(checkboxProps.field.name, e.target.checked);
    checkboxProps.form.submitForm();
  };

  return (
    <FormControlLabel
      className={formControlClassName}
      labelPlacement={labelPlace}
      label={label}
      classes={{ label: classes.label }}
      control={
        <Checkbox
          {...fieldToCheckbox(checkboxProps)}
          color='primary'
          onChange={submitOnChange ? handleSubmit : fieldToCheckbox(checkboxProps).onChange}
        />
      }
    />
  );
};
