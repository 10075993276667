import React from 'react';
import { useHistory } from 'react-router-dom';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import clsx from 'clsx';

import { useStyles } from './style';

interface Props {
  onClickHandler?: () => void;
  isRestaurant?: boolean;
  title?: string;
  showOnMobile?: boolean;
}

export const BackButton: React.FC<Props> = ({ onClickHandler, isRestaurant, title = 'Back', showOnMobile }) => {
  const { goBack } = useHistory();
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.backLink, isRestaurant && classes.backLinkCustomer, showOnMobile && classes.visible)}
      onClick={onClickHandler || goBack}
    >
      <ChevronLeftIcon className={classes.backChevron} />
      <span>{title}</span>
    </div>
  );
};
