import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';

export const useStyles = makeStyles(() =>
  createStyles({
    box: {
      width: '100%',
      position: 'relative',
    },
    label: {
      textTransform: 'uppercase',
      fontSize: 12,
      fontWeight: 700,
      color: colorVariables.grey60,
      lineHeight: '20px',
      marginBottom: 4,
    },
    rootField: {
      width: '100%',
      margin: 0,
      padding: 0,
      '& .MuiOutlinedInput-root.MuiAutocomplete-inputRoot': {
        padding: '5px',
        fontSize: 13,
        fontWeight: 400,
        color: colorVariables.navy,
        lineHeight: '32px',
        height: 32,
        backgroundColor: colorVariables.white,
        borderRadius: 0,
        '&::before, &::after': {
          display: 'none',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: colorVariables.steelGrey,
          borderWidth: 1,
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: colorVariables.blue,
        },
        '&:focus .MuiOutlinedInput-notchedOutline': {
          borderColor: colorVariables.blue,
        },
        '& input': {
          padding: '3px 0 !important',
        },
      },
      '& .MuiAutocomplete-inputRoot.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd': {
        paddingRight: 23,
      },
    },
    paper: {
      backgroundColor: colorVariables.white,
    },
    option: {
      backgroundColor: colorVariables.white,
      fontSize: 13,
      marginTop: 2,
      minHeight: 32,
      '&:hover': {
        backgroundColor: colorVariables.blueLight,
      },
      '&:focus': {
        backgroundColor: colorVariables.blueLight,
      },
      '&[data-focus="true"]': {
        backgroundColor: colorVariables.blueLight,
      },
      '&:active': {
        backgroundColor: colorVariables.blueLight,
      },
    },
    noOptions: {
      fontSize: 13,
      padding: '7px 1px',
      textAlign: 'center',
    },
    clearIndicator: {
      position: 'absolute',
      right: -6,
      color: colorVariables.steelGrey,
      cursor: 'pointer',
    },
    openBtn: {
      position: 'absolute',
      right: 1,
      color: colorVariables.steelGrey,
      cursor: 'pointer',
    },
  }),
);
