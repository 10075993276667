import React from 'react';

import { Close } from '@material-ui/icons';
import clsx from 'clsx';
import { FastField, FieldProps, Form, Formik, FormikHelpers } from 'formik';

import { RestaurantSortBy } from '../../../../../api/admin-restaurants/types';
import { ThemedButton } from '../../../../../shared/components/themed-button';
import { FormikDropdown } from '../../../../../shared/components/formik-dropdown';
import { useScreenSize } from '../../../../../shared/hooks/use-screen-size';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { getRestaurantsSortFilters, setRestaurantsSort } from '../../../../../store/admin/restaurants';

import { useStyles } from './style';

export const restaurantsSortParams = [
  { label: 'Restaurant name (A - Z)', value: 'name asc' },
  { label: 'Restaurant name (Z - A)', value: 'name desc' },
  { label: 'Suppliers (High to Low)', value: 'suppliers_count desc' },
  { label: 'Suppliers (Low to High)', value: 'suppliers_count asc' },
  { label: 'Orders to date (High to Low)', value: 'orders_to_date desc' },
  { label: 'Orders to date (Low to High)', value: 'orders_to_date asc' },
  { label: 'Last order placed (Most recent to Old)', value: 'last_order desc' },
  { label: 'Last order placed (Old to Most recent)', value: 'last_order asc' },
  { label: 'Onboarding (High to Low)', value: 'on_boarding asc' },
  { label: 'Onboarding (Low to High)', value: 'on_boarding desc' },
  { label: 'Last login (Most recent to Old)', value: 'last_login desc' },
  { label: 'Last login (Old to Most recent)', value: 'last_login asc' },
  { label: 'Created (Most recent to Old)', value: 'created_at desc' },
  { label: 'Created (Old to Most recent)', value: 'created_at asc' },
];

interface Values {
  sortBy: string;
}

interface Props {
  handleCloseModal: () => void;
  isFullScreen?: boolean;
}

export const RestaurantsFilter: React.FC<Props> = ({ handleCloseModal, isFullScreen }) => {
  const classes = useStyles();
  const { isMobile } = useScreenSize();
  const dispatch = useAppDispatch();

  const { sort } = useAppSelector(getRestaurantsSortFilters);

  const onSubmit = ({ sortBy }: Values, formikHelpers: FormikHelpers<any>) => {
    handleCloseModal();
    dispatch(
      setRestaurantsSort({
        sort: sortBy.trim() ? (sortBy.split(' ') as RestaurantSortBy) : undefined,
      }),
    );
    formikHelpers.resetForm();
  };

  const resetFields = () => {
    dispatch(setRestaurantsSort({}));
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        sortBy: sort ? sort?.join(' ') : restaurantsSortParams[12].value,
      }}
      enableReinitialize={true}
    >
      {({ submitForm }) => (
        <Form className={classes.formBox}>
          <div className={classes.root}>
            <div className={classes.sortBlock}>
              <div className={classes.blockTitle}>SORT BY</div>
              <FastField name='sortBy'>
                {(fieldProps: FieldProps) => (
                  <FormikDropdown
                    defaultValue={restaurantsSortParams[0].value}
                    placeholder='Select sort option'
                    options={restaurantsSortParams}
                    {...fieldProps}
                    height={36}
                    inputFontSize={14}
                  />
                )}
              </FastField>
            </div>
            <div className={clsx([classes.btnBlock, isFullScreen && classes.btnBlockFullScreen])}>
              <ThemedButton onClick={submitForm} title='Apply' buttonStyle='primary' isSmall={isMobile} />
              <ThemedButton
                type='reset'
                onClick={resetFields}
                title='CLEAR'
                buttonStyle='icon'
                startIcon={<Close color={'inherit'} />}
                isSmall={isMobile}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
