import { useEffect, useState } from 'react';

export const useScrollDirection = () => {
  const [scrollDir, setScrollDir] = useState<-1 | 0 | 1>(0);

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold || !scrollY) {
        ticking = false;
        return;
      }
      Math.abs(lastScrollY - scrollY) > 3 && setScrollDir(scrollY > lastScrollY ? 1 : -1);
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollDir]);

  return scrollDir;
};
