import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: 300,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    container: {
      width: 300,
      padding: 10,
      position: 'relative',
    },
    calendar: {
      fontSize: 14,
      height: 400,
    },
    dayPassive: {
      visibility: 'hidden',
    },
    dayDisabled: {
      backgroundColor: colorVariables.transparent,
    },
    month: {
      width: 276,
    },
    weekDays: {
      borderBottom: `1px solid ${colorVariables.steelGrey}`,
    },
    weekDay: {
      color: colorVariables.grey60,
      fontWeight: 700,
    },
    day: {
      flexShrink: 0,
      color: colorVariables.grey80,
      '& *': {
        border: `none !important`,
      },
      '& span.rdrSelected': {
        backgroundColor: colorVariables.green,
        color: colorVariables.white,
      },
      '& span.rdrSelected ~ span': {
        fontWeight: 700,
      },
    },
    today: {
      '& .rdrDayNumber span:after': {
        backgroundColor: colorVariables.navy,
      },
    },
    dayHovered: {
      '& *:after': {
        borderColor: `${colorVariables.green} !important`,
      },
    },
    monthAndYearWrapper: {
      width: '100%',
    },
    nextPrevButton: {
      width: 26,
      height: 26,
      borderRadius: 13,
    },
    monthAndYearPickers: {
      fontSize: 16,
      color: colorVariables.navy,
      fontWeight: 700,
    },
    prevButton: {
      position: 'relative',
      '&::after': {
        content: '"<"',
        fontSize: 18,
        width: 26,
        lineHeight: '26px',
        height: 26,
        display: 'block',
        textAlign: 'center',
        color: colorVariables.grey60,
        fontWeight: 700,
      },
      '& i': {
        display: 'none',
      },
    },
    nextButton: {
      position: 'relative',
      '&::after': {
        content: '">"',
        fontSize: 18,
        width: 26,
        lineHeight: '26px',
        height: 26,
        display: 'block',
        textAlign: 'center',
        color: colorVariables.grey60,
        fontWeight: 700,
      },
      '& i': {
        display: 'none',
      },
    },
    btnBox: {
      position: 'absolute',
      bottom: 15,
      display: 'flex',
      right: 0,
      left: 0,
      justifyContent: 'flex-end',
    },
    triggerBox: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  }),
);
