import React from 'react';

import SvgProductsNoRes from '../../../assets/images/svg-components/ProductsNoRes';
import { useScreenSize } from '../../hooks/use-screen-size';

import { useStyles } from './style';

export const NoSearchResultsRestaurant: React.FC = () => {
  const classes = useStyles();
  const { isDesktop } = useScreenSize();

  return (
    <div className={classes.noResBlock}>
      <h3 className={classes.noResTitle}>Hmmm.. we couldn’t find any results for you.</h3>
      <p className={classes.noResText}>Please check the spelling or try a broader search.</p>
      <SvgProductsNoRes width={isDesktop ? 180 : 160} height={isDesktop ? 180 : 160} />
    </div>
  );
};
