import * as React from 'react';

function SvgUploadPhoto(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 43 43' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M1.516 15.5c.2 0 .383-.068.516-.2a.716.716 0 00.2-.516V2.232h12.552c.2 0 .383-.067.516-.2a.716.716 0 00.2-.516c0-.2-.068-.383-.2-.515A.716.716 0 0014.784.8H1.516c-.2 0-.383.068-.515.2a.716.716 0 00-.201.516v13.268c0 .2.068.383.2.516.133.132.316.2.516.2zM1.516 42.5h13.268c.2 0 .383-.068.516-.2a.716.716 0 00.2-.516c0-.2-.068-.383-.2-.516a.716.716 0 00-.516-.2H2.232V28.516c0-.2-.067-.383-.2-.515a.716.716 0 00-.516-.201c-.2 0-.383.068-.515.2a.716.716 0 00-.201.516v13.268c0 .2.068.383.2.516.133.133.316.2.516.2zM41.784 27.8c-.2 0-.383.068-.516.2a.716.716 0 00-.2.516v12.552H28.516c-.2 0-.383.067-.515.2a.716.716 0 00-.201.516c0 .2.068.383.2.516.133.133.316.2.516.2h13.268c.2 0 .383-.068.516-.2a.716.716 0 00.2-.516V28.516c0-.2-.068-.383-.2-.515a.716.716 0 00-.516-.201zM41.784.8H28.516c-.2 0-.383.068-.515.2a.716.716 0 00-.201.516c0 .2.068.383.2.516.133.133.316.2.516.2h12.552v12.552c0 .2.067.383.2.516.133.132.316.2.516.2s.383-.068.516-.2a.716.716 0 00.2-.516V1.516c0-.2-.068-.383-.2-.515A.716.716 0 0041.784.8zM24.85 20.934h-2.484v-2.485c0-.2-.068-.382-.2-.515a.716.716 0 00-.516-.2c-.2 0-.383.067-.516.2a.716.716 0 00-.2.515v2.485h-2.485c-.2 0-.383.067-.516.2a.716.716 0 00-.2.516c0 .2.067.383.2.516s.316.2.516.2h2.484v2.485c0 .2.068.383.2.516.134.133.316.2.517.2.2 0 .382-.067.515-.2a.716.716 0 00.2-.516v-2.485h2.485c.2 0 .383-.067.516-.2a.716.716 0 00.2-.516c0-.2-.067-.383-.2-.516a.716.716 0 00-.516-.2z'
        fill='#51D9B0'
        stroke='#51D9B0'
        strokeWidth={0.4}
      />
      <path
        d='M31.555 21.65c0-5.427-4.425-9.906-9.905-9.906-5.48 0-9.906 4.479-9.906 9.906 0 5.428 4.478 9.905 9.906 9.905 5.428 0 9.905-4.477 9.905-9.905zm-9.905 8.473c-4.64 0-8.473-3.783-8.473-8.473 0-4.69 3.833-8.473 8.473-8.473 4.639 0 8.473 3.834 8.473 8.473 0 4.639-3.834 8.473-8.473 8.473z'
        fill='#51D9B0'
        stroke='#51D9B0'
        strokeWidth={0.4}
      />
    </svg>
  );
}

export default SvgUploadPhoto;
