import { createStyles, makeStyles } from '@material-ui/core';

import { Sizes } from '../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: 780,
      height: 300,
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: 500,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 260,
        height: 220,
      },
    },
    box: {
      padding: 0,
    },
    wrap: {
      padding: 50,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: '30px 10px',
      },
    },
    doneIcon: {
      width: 48,
      height: 48,
    },
    title: {
      fontSize: 20,
      fontWeight: 700,
      marginTop: 20,
      marginBottom: 30,
      maxWidth: '80%',
      textAlign: 'center',
      [theme.breakpoints.down(Sizes.mobile)]: {
        fontSize: 16,
      },
    },
    btnBox: {
      display: 'flex',
      width: '70%',
      justifyContent: 'space-around',
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: '90%',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
      },
    },
    btn: {
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: 10,
        minWidth: 'auto',
        height: 36,
        fontSize: 13,
      },
    },
  }),
);
