import React from 'react';

import { Close, Done, PriorityHigh } from '@material-ui/icons';
import clsx from 'clsx';
import { ToastContentProps } from 'react-toastify/dist/types';

import { ToastType } from '../../services/toastService';

import { useStyles } from './style';

interface Props extends ToastContentProps {
  text: string;
  type?: ToastType;
  title?: string;
}

export const CommonToast: React.FC<Props> = React.memo(({ text, type = ToastType.SUCCESS, title }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div
        className={clsx({
          [classes.border]: true,
          [classes.borderSuccess]: type === ToastType.SUCCESS,
          [classes.borderError]: type === ToastType.ERROR,
          [classes.borderWarning]: type === ToastType.WARNING,
          [classes.borderInfo]: type === ToastType.INFO,
        })}
      />
      <div
        className={clsx({
          [classes.iconWrap]: true,
          [classes.iconWrapSuccess]: type === ToastType.SUCCESS,
          [classes.iconWrapError]: type === ToastType.ERROR,
          [classes.iconWrapWarning]: type === ToastType.WARNING,
          [classes.iconWrapInfo]: type === ToastType.INFO,
        })}
      >
        {type === ToastType.SUCCESS && <Done className={classes.icon} />}
        {type === ToastType.ERROR && <Close className={classes.icon} />}
        {type === ToastType.WARNING && <PriorityHigh className={classes.icon} />}
        {type === ToastType.INFO && <PriorityHigh className={clsx(classes.icon, classes.iconRotated)} />}
      </div>

      <div>
        <div className={classes.toastTitle}>{title || type}</div>
        <div className={classes.toastText}>{text}</div>
      </div>
    </div>
  );
});
