import React from 'react';
import { useLocation } from 'react-router-dom';

import { IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import LinesEllipsis from 'react-lines-ellipsis';

import { useGetPantryListById } from '../../../../../api/pantry-list/hooks';
import { ProductsSortBy } from '../../../../../api/product/types';
import { useGetSelectedRecOrder } from '../../../../../api/recurring-order/hooks';
import SvgFavorite from '../../../../../assets/icons/Favorite';
import SvgFavoriteFill from '../../../../../assets/icons/FavoriteFill';
import SvgFilterIconCustomer from '../../../../../assets/icons/FilterIconCustomer';
import SvgRecentlyCart from '../../../../../assets/icons/RecentlyCart';
import SvgRecentlyCartFilled from '../../../../../assets/icons/RecentlyCartFilled';
import { CommonChip } from '../../../../../shared/components/common-chip';
import { FormikSortDropdown } from '../../../../../shared/components/formik-sort-dropdown';
import { ProductsCategories } from '../../../../../shared/constants/products';
import { useScreenSize } from '../../../../../shared/hooks/use-screen-size';
import { useScrollDirection } from '../../../../../shared/hooks/use-scroll-direction';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import {
  getProductChips,
  getProductsCount,
  getProductSortFilters,
  setProductsFilterBy,
  setProductsSortBy,
} from '../../../../../store/products';
import { showEditRecOrderModal } from '../../../../../store/recurring-order';
import { getImpersonatedCustomer } from '../../../../../store/user';
import { colorVariables } from '../../../../../styles/colorVariables';
import { SearchProductsField } from '../search-products-field';
import { capitalizeFirstLetter } from '../../../../../shared/helpers/capitalize-first-letter';

import { useStyles } from './style';

export enum ProductsFilters {
  FAVOURITES = 'Favourites',
  RECENTLY = 'Recently purchased (2 months)',
}

interface Values {
  sortBy: string;
}

interface Props {
  category: number | null;
  toggleFilter: () => void;
  disableAnimation?: boolean;
  isSearchMode?: boolean;
  subCatName?: string;
  supplierInfoExpanded?: boolean;
  supplierMode?: boolean;
}

export const restaurantProductSortParams = [
  { label: 'Relevance', value: 'relevance' },
  { label: 'Most Purchased', value: 'order_items_count desc' },
  { label: 'Product Name (A - Z)', value: 'name asc' },
  { label: 'Product Name (Z - A)', value: 'name desc' },
  { label: 'Price (Low - High)', value: 'price_per_unit asc' },
  { label: 'Price (High - Low)', value: 'price_per_unit desc' },
];

export const SearchBlock: React.FC<Props> = ({
  category,
  toggleFilter,
  disableAnimation,
  isSearchMode,
  subCatName,
  supplierInfoExpanded,
  supplierMode,
}) => {
  const classes = useStyles();
  const scrollDir = useScrollDirection();
  const { isDesktop } = useScreenSize();
  const dispatch = useAppDispatch();
  const { state }: { state: { recOrderId?: number; pantryListId?: number; redirect?: boolean } | null } = useLocation();

  const pantryList = useGetPantryListById();
  const { selectedRecOrder } = useGetSelectedRecOrder();

  const chips = useAppSelector(getProductChips);
  const productsCount = useAppSelector(getProductsCount);
  const impersonatedCustomer = useAppSelector(getImpersonatedCustomer);

  const { filter: productFilter, sort } = useAppSelector(getProductSortFilters);
  const isFavoritesChecked = productFilter?.favourites;

  const setFiltersHandler = (filter: ProductsFilters) => {
    switch (filter) {
      case ProductsFilters.FAVOURITES: {
        dispatch(
          setProductsFilterBy({
            ...productFilter,
            favourites: !productFilter?.favourites,
          }),
        );
        break;
      }
      case ProductsFilters.RECENTLY: {
        dispatch(
          setProductsFilterBy({
            ...productFilter,
            recently_purchased: !productFilter?.recently_purchased,
          }),
        );
        break;
      }
    }
  };

  const removeFilter = (key: string) => {
    switch (key.split(' ')[0]) {
      case 'sort': {
        dispatch(setProductsSortBy(undefined));
        break;
      }
      case 'favourites': {
        dispatch(
          setProductsFilterBy({
            ...productFilter,
            favourites: false,
          }),
        );
        break;
      }
      case 'recently_purchased': {
        dispatch(
          setProductsFilterBy({
            ...productFilter,
            recently_purchased: false,
          }),
        );
        break;
      }
      default: {
        dispatch(setProductsFilterBy(undefined));
      }
    }
  };

  const getSearchTitle = (cat: number | null) => {
    switch (cat) {
      case 1:
        return {
          search: `Search all ${ProductsCategories.MEAT}`,
          results: 'Meat products',
        };
      case 2:
        return {
          search: `Search all ${ProductsCategories.FRUIT_VEGETABLES}`,
          results: 'Fruit & vegetable products',
        };
      case 3:
        return {
          search: `Search all ${ProductsCategories.POULTRY}`,
          results: 'Poultry products',
        };
      case 4:
        return {
          search: `Search all ${ProductsCategories.DRY_FROZEN}`,
          results: 'Dry & Frozen goods products',
        };
      case 5:
        return {
          search: `Search all ${ProductsCategories.SEAFOOD}`,
          results: 'Seafood products',
        };
      case 6:
        return {
          search: `Search all ${ProductsCategories.HOSPITALITY}`,
          results: `${ProductsCategories.HOSPITALITY} products`,
        };
      case 7:
        return {
          search: `Search all ${ProductsCategories.SMALL_GOODS}`,
          results: `Small Goods products`,
        };
      case 8:
        return {
          search: `Search all ${ProductsCategories.COFFEE_TEA}`,
          results: `Coffee & Tea products`,
        };
      case 9:
        return {
          search: `Search all ${ProductsCategories.DAIRY}`,
          results: `Dairy products`,
        };
      case 10:
        return {
          search: `Search all ${ProductsCategories.ALCOHOL_BEVERAGES}`,
          results: `Alcohol & Beverage products`,
        };
      case 11:
        return {
          search: `Search all ${ProductsCategories.BAKERY}`,
          results: `Bakery products`,
        };
      default: {
        return {
          search: subCatName ? `Search ${capitalizeFirstLetter(subCatName).substr(0, 26)}` : 'Search all products',
          results: subCatName ? `${capitalizeFirstLetter(subCatName).substr(0, 26)}` : 'All Products',
        };
      }
    }
  };

  const onEditRecOrder = () => {
    dispatch(showEditRecOrderModal());
  };

  const onSubmit = ({ sortBy }: Values) => {
    sortBy?.trim() ? dispatch(setProductsSortBy(sortBy.split(' ') as ProductsSortBy)) : dispatch(setProductsSortBy(undefined));
  };

  return (
    <div
      className={clsx({
        [classes.searchBlock]: true,
        [classes.scrollUp]: scrollDir === -1 && !disableAnimation,
        [classes.scrollDown]: scrollDir === 1 && !disableAnimation,
        [classes.searchBlockSupplierInfoExpanded]: supplierInfoExpanded && scrollDir !== 1,
      })}
    >
      <div className={classes.searchWrap}>
        <div className={classes.searchField}>
          <SearchProductsField placeholder={getSearchTitle(category).search} redirect={!!state?.redirect} supplierMode={supplierMode} />
        </div>
        <div className={classes.filterIconMobile}>
          <IconButton className={classes.iconButton} onClick={toggleFilter}>
            <SvgFilterIconCustomer width={22} height={22} color={chips.length ? colorVariables.green : colorVariables.userNavy} />
          </IconButton>
        </div>
        <div className={classes.filterBlock}>
          <div className={classes.blockTitle}>Filter by</div>
          <div
            className={clsx(classes.filterOpt, isFavoritesChecked && classes.filterOptSelected)}
            onClick={setFiltersHandler.bind(null, ProductsFilters.FAVOURITES)}
          >
            <span>{ProductsFilters.FAVOURITES}</span>
            {isFavoritesChecked ? <SvgFavoriteFill className={classes.starIconFilled} /> : <SvgFavorite className={classes.starIcon} />}
          </div>
          <div
            className={clsx(
              classes.filterOpt,
              productFilter?.recently_purchased && classes.filterOptSelected,
              impersonatedCustomer && classes.inactive,
            )}
            onClick={impersonatedCustomer ? undefined : setFiltersHandler.bind(null, ProductsFilters.RECENTLY)}
          >
            <span>{ProductsFilters.RECENTLY}</span>
            {productFilter?.recently_purchased ? (
              <SvgRecentlyCartFilled width={24} height={24} color={colorVariables.yellow} />
            ) : (
              <SvgRecentlyCart color={colorVariables.grey40} width={24} height={24} />
            )}
          </div>
        </div>
      </div>
      <div className={classes.chipBox}>
        {chips.map((chip) => (
          <CommonChip key={chip.key} title={chip.label} onClick={removeFilter.bind(null, chip.key)} />
        ))}
      </div>
      <div className={clsx(classes.sortWrap, isSearchMode && classes.sortWrapHidden)}>
        {state?.pantryListId && pantryList?.name && !isDesktop && (
          <h2 className={clsx(classes.sectionTitle, classes.sectionTitlePantry)}>
            <LinesEllipsis text={pantryList.name} maxLine={1} ellipsis='...' />
          </h2>
        )}
        {state?.recOrderId && selectedRecOrder?.name && !isDesktop && (
          <h2 className={clsx(classes.sectionTitle, classes.sectionTitleRec)}>
            <LinesEllipsis text={selectedRecOrder.name} ellipsis='...' maxLine={1} />
          </h2>
        )}
        {((!state?.pantryListId && !state?.recOrderId) || isDesktop) && (
          <>
            <h2 className={classes.sectionTitle}>{getSearchTitle(category).results}</h2>
            <span className={classes.resultsCount}>{productsCount} Results</span>
          </>
        )}
        {!!state?.recOrderId && !isDesktop && (
          <div className={classes.editSettingsBtn} onClick={onEditRecOrder}>
            Edit Settings
          </div>
        )}
        <div className={classes.sortBlock}>
          <div className={classes.blockTitle}>sort by</div>
          <Formik
            onSubmit={onSubmit}
            initialValues={{ sortBy: sort?.join(' ') || restaurantProductSortParams[1].value }}
            enableReinitialize={true}
          >
            <Form>
              <FormikSortDropdown name='sortBy' options={restaurantProductSortParams} />
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};
