import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      fontSize: 16,
      alignItems: 'center',
      '& svg': {
        marginRight: 10,
        flexShrink: 0,
      },
      backgroundColor: 'transparent !important',
      borderRadius: 40,
      textTransform: 'none',
      marginRight: 20,
    },
  }),
);
