import { createSelector } from '@reduxjs/toolkit';
import { format, isToday } from 'date-fns';
import { DeliveryFilters } from '../../api/delivery/types';
import { deliverySortParams } from '../../components/AppDrawer/SupplierDrawer/Orders/Delivery/delivery-filter';
import { RootState } from '../index';

export const getDeliveriesPage = (state: RootState) => state.deliveries.currentPage;

export const getDeliveriesKeyword = (state: RootState) => state.deliveries.keyword;

export const getDeliveriesSort = (state: RootState) => state.deliveries.sortBy;

export const getDeliveriesFilter = (state: RootState) => state.deliveries.filterBy;

export const getIsAddDeliveryMode = (state: RootState) => state.deliveries.addDeliveryMode;

export const getDeliveriesParams = createSelector(
  [getDeliveriesPage, getDeliveriesKeyword, getDeliveriesSort, getDeliveriesFilter],
  (currentPage, keyword, sort, filter) => ({
    currentPage,
    keyword,
    sort,
    filter,
  }),
);

export const getDeliveryChips = (state: RootState) => {
  const sortLabel = deliverySortParams.find((param) => param.value === state.deliveries.sortBy?.join(' '))?.label || '';

  const sortChip = {
    label: sortLabel,
    key: sortLabel ? 'sort' : '',
  };

  const filterChips = Object.entries(state.deliveries.filterBy || {}).reduce((acc, [key, value]: [string, string]) => {
    switch (key as keyof DeliveryFilters) {
      case 'delivery_date': {
        if (!value) {
          return acc;
        }
        if (!isToday(new Date(value))) {
          acc.push({
            label: `Delivery date - ${format(new Date(value), 'd MMM yy')}`,
            key: `delivery_date`,
          });
        }

        break;
      }

      case 'delivery_status': {
        if (!Array.isArray(value)) {
          return acc;
        }
        acc.push(
          ...value
            .filter((st) => st !== '-1')
            .map((el) => ({
              label: el === '1' ? 'Eta' : el === '0' ? 'Delivered' : el === '2' ? 'For delivery' : '',
              key: `delivery_status ${el}`,
            })),
        );
        break;
      }
    }
    return acc;
  }, [] as { label: string; key: string }[]);

  return [sortChip, ...filterChips].filter((chip) => !!chip.label && !!chip.key);
};
