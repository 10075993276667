import * as React from 'react';

function SvgCategoryFruits(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='24' height='25' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.245.6a.572.572 0 00-.447.378c-.423 1.664-.274 3.076.472 4.16.124.202.274.378.448.555-.348-.05-.697-.05-1.02-.05-1.641 0-2.959.504-3.904 1.411-1.69 1.589-2.213 4.21-1.915 6.808.299 2.597 1.418 5.244 3.208 7.009 1.74 1.714 4.203 2.52 6.913 1.437 2.71 1.084 5.172.277 6.913-1.437 1.79-1.74 2.91-4.387 3.208-7.01.298-2.596-.224-5.218-1.915-6.807-.945-.882-2.263-1.411-3.904-1.411-1.12 0-2.362.226-3.78.756v-.404c.1-1.235.498-1.865 1.144-2.37.647-.504 1.641-.857 2.835-1.285a.546.546 0 00.348-.555.506.506 0 00-.473-.429c-.05 0-.124 0-.174.026-1.193.403-2.287.756-3.133 1.437a3.286 3.286 0 00-.82.933 4.69 4.69 0 00-.523-1.135C11.105 1.66 10.037.978 8.47.651h-.1C8.27.6 8.246.6 8.246.6zm.423 1.11c1.094.302 1.74.781 2.163 1.411.448.706.647 1.64.672 2.824v.403c-1.12-.504-1.94-1.084-2.387-1.79-.523-.73-.697-1.613-.448-2.849zM7.723 6.6c1.094 0 2.437.303 4.028.933.174.076.324.05.498 0 3.655-1.487 5.918-1.058 7.286.227 1.367 1.286 1.864 3.555 1.59 5.95-.273 2.395-1.342 4.866-2.908 6.404-1.567 1.538-3.556 2.194-5.993 1.16-.15-.076-.274-.05-.473 0-2.437 1.034-4.426.353-5.968-1.185-1.566-1.538-2.635-4.009-2.909-6.404-.273-2.395.249-4.69 1.591-5.95.746-.68 1.816-1.134 3.258-1.134z'
        fill='#fff'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.237 9.6a.579.579 0 00-.44.187c-1.298 1.545-1.168 3.37-.52 4.939.675 1.568 1.868 2.926 2.854 3.745a.54.54 0 00.726-.024.423.423 0 00-.026-.655h-.026c-.856-.726-1.997-2.013-2.594-3.394-.596-1.38-.674-2.808.363-4.049.182-.21.13-.491-.077-.655-.052-.07-.156-.094-.26-.094z'
        fill='#fff'
      />
    </svg>
  );
}

export default SvgCategoryFruits;
