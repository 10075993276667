import React, { ImgHTMLAttributes } from 'react';

import clsx from 'clsx';

import useImageOnLoad from '../../hooks/use-image-on-load';

import { useStyles } from './style';

export const BlurredImage: React.FC<ImgHTMLAttributes<HTMLImageElement>> = (props) => {
  const { handleImageOnLoad, css } = useImageOnLoad();

  const classes = useStyles();
  return (
    <div className={classes.box}>
      <div className={clsx(classes.thumbnail, props.className && props.className)} style={css.thumbnail} />
      <img
        onLoad={handleImageOnLoad}
        alt='fullImage'
        {...props}
        className={clsx(classes.fullImage, props.className && props.className)}
        style={css.fullSize}
      />
    </div>
  );
};
