import * as React from 'react';

function SvgFavoriteFill(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M4.306 11.373l5.248 4.881c.096.09.145.135.194.163a.5.5 0 00.499 0c.05-.028.098-.073.194-.163l5.248-4.88a3.926 3.926 0 00.416-5.297l-.213-.273c-1.495-1.908-4.473-1.586-5.526.598a.41.41 0 01-.737 0c-1.053-2.184-4.03-2.506-5.526-.598l-.214.273a3.926 3.926 0 00.417 5.296z'
        fill='#FFD54F'
        stroke='#FFD54F'
        strokeWidth={2}
      />
    </svg>
  );
}

export default SvgFavoriteFill;
