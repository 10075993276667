import { useLocation } from 'react-router-dom';

import useTimeout from '../../shared/hooks/use-timeout';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { useAppDispatch, useAppSelector } from '../../store';
import { getCustomersSortFilters, resetCustomersFilters, setCustomersKeyword } from '../../store/customers';
import { ConnectionRequest } from '../user/types';
import { useGetCustomerListQuery } from './index';

export const useGetCustomersList = () => {
  const { sort, filter, currentPage, keyword } = useAppSelector(getCustomersSortFilters);
  const { list, customerLoading, totalPages, customerFetching, customersLoaded } = useGetCustomerListQuery(
    { sort, filter, currentPage, keyword },
    {
      selectFromResult: ({ data, isLoading, isFetching, isSuccess }) => ({
        list: data?.customers || [],
        customerLoading: isLoading,
        customerFetching: isFetching,
        totalPages: data?.total_pages || 0,
        customersLoaded: isSuccess,
      }),
    },
  );
  return { list, customerLoading, totalPages, customerFetching, customersLoaded };
};

export const useSelectCustomerById = (id: number) => {
  const { sort, filter, currentPage, keyword } = useAppSelector(getCustomersSortFilters);
  const { customer } = useGetCustomerListQuery(
    { sort, filter, currentPage, keyword },
    {
      selectFromResult: ({ data }) => ({
        customer: data?.customers.find((customerItem) => customerItem.id === id) as ConnectionRequest,
      }),
    },
  );
  return { customer };
};

export const useSearchCustomerByName = (name: string) => {
  const { customers, customerLoading } = useGetCustomerListQuery(
    { keyword: name },
    {
      selectFromResult: ({ data, isLoading, isFetching }) => ({
        customers: data?.customers || [],
        customerLoading: isLoading || isFetching,
      }),
      skip: name.trim().length < 1,
    },
  );
  return { customers, customerLoading };
};

export const useSetCustomersDefaults = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { keyword, sort, filter, currentPage } = useAppSelector(getCustomersSortFilters);
  useTimeout(
    () => {
      dispatch(setCustomersKeyword({ keyword: undefined }));
    },
    pathname.includes('/customers') || !keyword ? null : 300000,
  );
  if (!pathname.includes('/customers')) {
    LocalStorageService.clear('customers_scroll');
    (sort || filter || currentPage !== 1) && dispatch(resetCustomersFilters({ page: true, filter: true, sort: true }));
  }
};
