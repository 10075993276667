import { useEffect, useMemo, useState } from 'react';

import { generateSalesImportSheet } from '../../shared/helpers/generateSalesImportSheet';
import { transformChartData } from '../../shared/helpers/transform-chart-data';
import { useAppDispatch, useAppSelector } from '../../store';
import { getSalesSortFilters, setSalesPage } from '../../store/sales';
import { useGetAllRecipesMutation } from '../menu';
import { Recipe } from '../menu/types';
import { useGetSalesQuery, useGetSalesReportQuery } from './index';
import { SalesGraphParams } from './types';

export const useGetSalesList = (inView?: boolean) => {
  const dispatch = useAppDispatch();
  const { currentPage, sortBy, filter, keyword } = useAppSelector(getSalesSortFilters);

  const { loading, next_page, subtotal, tax, menuData, list } = useGetSalesQuery(
    { page: currentPage, sortBy, filter, keyword },
    {
      selectFromResult: ({ data, isFetching }) => ({
        loading: isFetching,
        next_page: data?.next_page,
        subtotal: data?.sub_total || 0,
        list: data?.sales,
        tax: data?.tax || 0,
        menuData: data?.menu_data ? Object.entries(data.menu_data).map(([name, total]) => ({ name, total })) : [],
      }),
    },
  );

  useEffect(() => {
    inView && next_page && dispatch(setSalesPage(next_page));
  }, [inView, next_page]);

  return { loading, subtotal, tax, menuData, list };
};

export const useGetImportSalesTemplate = () => {
  const [getData, { isLoading: loading }] = useGetAllRecipesMutation();
  const [list, setList] = useState<Recipe[]>([]);

  const onDownload = () => {
    if (list.length) {
      generateSalesImportSheet(
        list.map(({ recipe_number, name, price_cents }) => ({
          id: recipe_number || '',
          name,
          price: (price_cents || 0) / 100,
        })),
      );
      return;
    }
    getData().then((res) => {
      if ('data' in res && res.data.recipies.length) {
        const listToSet = res.data.recipies.reduce((acc: Recipe[], el: Recipe) => {
          if (!el.archived_at && !el.is_sub_recipe && !el.menu.deleted_at && !el.menu.archived_at) {
            acc.push(el);
          }
          return acc;
        }, []);
        setList(listToSet);
        generateSalesImportSheet(
          listToSet.map(({ recipe_number, name, price_cents }) => ({
            id: recipe_number || '',
            name,
            price: (price_cents || 0) / 100,
          })),
        );
      }
    });
  };

  return { onDownload, loading };
};

export const useGetSalesReportData = (params: SalesGraphParams) => {
  const { data, isLoading, isFetching, isSuccess } = useGetSalesReportQuery(params, {
    skip: params.days === undefined && (!params['[date_range][from_date]'] || !params['[date_range][to_date]']),
  });

  const {
    dynamicData,
    sales,
    previousSales,
    cost,
    previousCost,
    profit,
    previousProfit,
    salesByMenu,
    salesByMenuItem,
    profitByMenu,
    costByMenu,
  } = useMemo(() => {
    return {
      dynamicData: {
        sales: {
          last: data?.last_sales ? +data.last_sales : 0,
          previous: data?.previous_sales ? +data.previous_sales : 0,
          percentage: data?.sales_percentage ? +data.sales_percentage : 0,
        },
        profit: {
          last: data?.last_profit ? +data.last_profit : 0,
          previous: data?.previous_profit ? +data.previous_profit : 0,
          percentage: data?.profit_percentage ? +data.profit_percentage : 0,
        },
        cost: {
          last: data?.last_cost ? +data.last_cost : 0,
          previous: data?.previous_cost ? +data.previous_cost : 0,
          percentage: data?.cost_percentage ? +data.cost_percentage : 0,
        },
      },
      sales: data?.sales_graph ? transformChartData(data.sales_graph) : [],
      previousSales: data?.previous_sales_graph ? transformChartData(data.previous_sales_graph) : [],
      cost: data?.cost_graph ? transformChartData(data.cost_graph) : [],
      previousCost: data?.previous_cost_graph ? transformChartData(data.previous_cost_graph) : [],
      profit: data?.profit_graph ? transformChartData(data.profit_graph) : [],
      previousProfit: data?.previous_profit_graph ? transformChartData(data.previous_profit_graph) : [],
      salesByMenu:
        data?.sales_by_options && Array.isArray(data.sales_by_options) && data.sales_by_options[0]?.sales_by_menu
          ? Object.entries(data.sales_by_options[0].sales_by_menu).map(([key, value]) => ({ name: key, ...value }))
          : [],
      profitByMenu:
        data?.sales_by_options && Array.isArray(data.sales_by_options) && data.sales_by_options[1]?.profit_by_menu
          ? Object.entries(data.sales_by_options[1].profit_by_menu).map(([key, value]) => ({ name: key, ...value }))
          : [],
      costByMenu:
        data?.sales_by_options && Array.isArray(data.sales_by_options) && data.sales_by_options[2]?.cost_by_menu
          ? Object.entries(data.sales_by_options[2].cost_by_menu).map(([key, value]) => ({ name: key, ...value }))
          : [],
      salesByMenuItem:
        data?.sales_by_options && Array.isArray(data.sales_by_options) && data.sales_by_options[3]?.by_menu_item
          ? Object.entries(data.sales_by_options[3].by_menu_item).map(([key, value]) => ({ name: key, ...value }))
          : [],
    };
  }, [data]);

  return {
    reportLoading: isLoading,
    dynamicData,
    sales,
    previousSales,
    cost,
    previousCost,
    profit,
    previousProfit,
    salesByMenu,
    profitByMenu,
    costByMenu,
    salesByMenuItem,
    reportFetching: isFetching,
    reportLoaded: isSuccess,
  };
};
