import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';

import clsx from 'clsx';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { useMeQuery, useSendContactMsgMutation } from '../../../../api/user';
import { BackButton } from '../../../../shared/components/back-button';
import { ThemedButton } from '../../../../shared/components/themed-button';
import { FormikInput } from '../../../../shared/components/formik-input';
import { NavbarTitle } from '../../../../shared/components/navbar-title';
import { useScreenSize } from '../../../../shared/hooks/use-screen-size';
import { LocalStorageService } from '../../../../shared/services/localStorage.service';
import { useAppSelector } from '../../../../store';
import { getIsRestaurant } from '../../../../store/user';
import { TopNavBar } from '../../../AppDrawer/MainNavBar/top-nav-bar';

import { useStyles } from './style';

interface Values {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  help_subject: string;
  help_message: string;
}

export const ContactUs: React.FC = () => {
  const classes = useStyles();
  const { goBack } = useHistory();
  const ref = useRef<HTMLFormElement | null>(null);
  const { isMobile } = useScreenSize();

  const isRestaurant = useAppSelector(getIsRestaurant);
  const isAu = LocalStorageService.getItem('country') === 'AU';

  const [sendMsg, { isLoading }] = useSendContactMsgMutation();
  const { data: user } = useMeQuery();

  const contactSchema = Yup.object().shape({
    first_name: Yup.string().required('Required field'),
    last_name: Yup.string().required('Required field'),
    email: Yup.string().required('Required field').email('Enter correct email!'),
    phone_number: Yup.string().required('Required field'),
    help_subject: Yup.string().required('Required field'),
    help_message: Yup.string().required('Required field'),
  });

  const onSubmit = (values: Values, formikHelpers: FormikHelpers<Values>) => {
    sendMsg({
      user: values,
    });
    formikHelpers.setSubmitting(false);
  };

  return (
    <>
      <TopNavBar
        isAccount={true}
        leftComponent={<NavbarTitle title='Help' showBackBtn={true} />}
        bottomComponent={isMobile ? <NavbarTitle title='Help' showBackBtn={true} /> : undefined}
      />
      <div className={classes.root}>
        <BackButton onClickHandler={goBack} />
        <div className={classes.title}>We are here for you</div>
        <div className={classes.subTitle}>Get in touch</div>
        <Formik
          initialValues={{
            first_name: user?.first_name || '',
            last_name: user?.last_name || '',
            phone_number: user?.phone_number || '',
            email: user?.email || '',
            help_subject: '',
            help_message: '',
          }}
          onSubmit={onSubmit}
          validationSchema={contactSchema}
        >
          {({ submitForm }) => (
            <Form className={classes.formBox} ref={ref}>
              <div className={classes.fieldsBox}>
                <div className={clsx(classes.fieldWrap, isRestaurant && classes.fieldWrapCustomer)}>
                  <Field name='first_name'>
                    {(fieldProps: FieldProps) => <FormikInput {...fieldProps} label='First Name *' placeholder='First Name' />}
                  </Field>
                </div>
                <div className={clsx(classes.fieldWrap, isRestaurant && classes.fieldWrapCustomer)}>
                  <Field name='last_name'>
                    {(fieldProps: FieldProps) => <FormikInput {...fieldProps} label='Last Name *' placeholder='Last Name' />}
                  </Field>
                </div>
              </div>
              <div className={classes.fieldsBox}>
                <div className={clsx(classes.fieldWrap, isRestaurant && classes.fieldWrapCustomer)}>
                  <Field name='email'>
                    {(fieldProps: FieldProps) => <FormikInput {...fieldProps} label='Email Address *' placeholder='Email Address' />}
                  </Field>
                </div>
                <div className={clsx(classes.fieldWrap, isRestaurant && classes.fieldWrapCustomer)}>
                  <Field name='phone_number'>
                    {(fieldProps: FieldProps) => (
                      <FormikInput {...fieldProps} type='number' label='Phone Number *' placeholder='Phone Number ' />
                    )}
                  </Field>
                </div>
                <div className={clsx(classes.fieldWrap, isRestaurant && classes.fieldWrapCustomer)}>
                  <Field name='help_subject'>
                    {(fieldProps: FieldProps) => <FormikInput {...fieldProps} label='Subject *' placeholder='Subject' />}
                  </Field>
                </div>
              </div>
              <div className={classes.fullWidthFieldWrap}>
                <Field
                  multiline={true}
                  minRows={4}
                  maxRows={4}
                  component={FormikInput}
                  name='help_message'
                  label='Message *'
                  placeholder='Please enter your inquiry here'
                />
              </div>
              <div className={classes.formFooter}>
                <ThemedButton title='Send Message' onClick={submitForm} width={280} customClass={classes.sendButton} disabled={isLoading} />
                {isAu && (
                  <div className={classes.contactBox}>
                    <div className={classes.contactBoxTitle}>Or contact us on</div>
                    <a href='tel:03 9020 9266'>03 9020 9266</a>&nbsp;|&nbsp;
                    <a href='mailto:info@theopenpantry.com'>info@theopenpantry.com</a>
                    <div className={clsx([classes.contactBoxTitle, classes.address])}>Address</div>
                    <span>41-43 Stewart St Richmond 3121 Vic, Australia</span>
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};
