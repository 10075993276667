import React from 'react';
import { Route, Switch } from 'react-router-dom';

import clsx from 'clsx';
import { useAppSelector } from '../../../store';
import { getIsRestaurant } from '../../../store/user';
import { MainNavBar } from '../../AppDrawer/MainNavBar';
import { AccountMenu } from '../account-menu';
import { ContactUs } from './contact-us';
import { Faqs } from './faqs';
import { FaqsDetails } from './faqsDetails';
import { HelpOptions } from './help-options';
import { HelpCenter } from './helpCenter';
import { HelpDetails } from './helpDetails';

import { useStyles } from './style';

export const HelpPage: React.FC = () => {
  const classes = useStyles();

  const isRestaurant = useAppSelector(getIsRestaurant);

  return (
    <>
      <MainNavBar isAccount={true} />
      <div className={clsx(classes.accountContainer, isRestaurant && classes.accountContainerCustomer)}>
        <div className={classes.accountHeader}>
          <div className={classes.accountTitle}>My Account</div>
        </div>
        <div className={classes.accountBody}>
          <AccountMenu showBack={!isRestaurant} />
          <div className={classes.accountContent}>
            <Switch>
              <Route path='/account/help/faqs/:id' component={FaqsDetails} />
              <Route path='/account/help/faqs' component={Faqs} />
              <Route path='/account/help/help-center/:id' component={HelpDetails} />
              <Route path='/account/help/help-center' component={HelpCenter} />
              <Route path='/account/help/contact' component={ContactUs} />
              <Route path='/account/help/' component={HelpOptions} />
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
};
