import { RootState } from '../index';
import { recipeProductsAdapter } from './products.reducer';

export const getRecipeProductsCount = (state: RootState) => state.recipeProducts.resultsCount;

export const getRecipeProductSortFilters = (state: RootState) => ({
  sort: state.recipeProducts.sortBy,
  filter: state.recipeProducts.filterBy,
  keyword: state.recipeProducts.keyword,
  currentPage: state.recipeProducts.currentPage,
});

export const getRecipeProductCategoryFilter = (state: RootState) => state.recipeProducts.filterBy?.category_id;

export const getRecipeProductFavourite = (state: RootState) => state.recipeProducts.filterBy?.favourites;

export const recipeProductsSelectors = recipeProductsAdapter.getSelectors((state: RootState) => state.recipeProducts.list);
