import { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';

import { isToday } from 'date-fns';
import { Roles } from '../api/user/types';
import { LocalStorageService } from '../shared/services/localStorage.service';

export const localStorageMiddleware: Middleware =
  ({ getState }: MiddlewareAPI) =>
  (next) =>
  (action) => {
    const userId = getState().user.userId;
    switch (action.type) {
      case 'SET_PRODUCTS_FILTER_BY': {
        const isRestaurant = getState().user.role === Roles.CUSTOMER;
        if (!isRestaurant) {
          return next(action);
        }
        LocalStorageService.setItem('products_filter', {
          ...(LocalStorageService.getItem('products_filter') || {}),
          [userId.toString()]: {
            favourites: action.payload?.favourites,
            recently_purchased: action.payload?.recently_purchased,
          },
        });
        break;
      }
      case 'SET_PRODUCTS_BY_CATEGORY_KEYWORD': {
        const sortBy = getState().products.sortBy;
        action.payload.keyword && sortBy && sortBy.length === 2 && LocalStorageService.setItem('last_product_sort', sortBy);
        break;
      }
      case 'SET_PRODUCTS_SORT_BY': {
        const isRestaurant = getState().user.role === Roles.CUSTOMER;
        if (!isRestaurant) {
          return next(action);
        }
        const keyword = getState().products.keyword;
        keyword ? LocalStorageService.setItem('last_product_sort', action.payload) : LocalStorageService.clear('last_product_sort');
        LocalStorageService.setItem('products_sort', {
          ...(LocalStorageService.getItem('products_sort') || {}),
          [userId.toString()]: action.payload,
        });
        break;
      }
      case 'SET_ORDERS_SORT_FILTER': {
        const isRestaurant = getState().user.role === Roles.CUSTOMER;
        if (isRestaurant) {
          return next(action);
        }
        LocalStorageService.setItem('orders_filter', {
          ...(LocalStorageService.getItem('orders_filter') || {}),
          [userId.toString()]: action.payload.filter,
        });
        LocalStorageService.setItem('orders_sort', {
          ...(LocalStorageService.getItem('orders_sort') || {}),
          [userId.toString()]: action.payload.sort,
        });
        break;
      }
      case 'SET_DELIVERIES_SORT_FILTER': {
        const isRestaurant = getState().user.role === Roles.CUSTOMER;
        if (isRestaurant) {
          return next(action);
        }
        LocalStorageService.setItem('delivery_filter', {
          ...(LocalStorageService.getItem('delivery_filter') || {}),
          [userId.toString()]: action.payload.filter,
        });
        const deliveryDate = getState().deliveries.filterBy?.delivery_date;
        if (!deliveryDate || (!!deliveryDate && isToday(new Date(deliveryDate)))) {
          LocalStorageService.setItem('delivery_sort', {
            ...(LocalStorageService.getItem('delivery_sort') || {}),
            [userId.toString()]: action.payload.sort,
          });
        }
        break;
      }
      case 'SET_DELIVERIES_FILTER': {
        const isRestaurant = getState().user.role === Roles.CUSTOMER;
        if (isRestaurant) {
          return next(action);
        }
        LocalStorageService.setItem('delivery_filter', {
          ...(LocalStorageService.getItem('delivery_filter') || {}),
          [userId.toString()]: action.payload,
        });
        break;
      }
      case 'SET_CUSTOMERS_PERFORMANCE_SORT_FILTER': {
        const isRestaurant = getState().user.role === Roles.CUSTOMER;
        if (isRestaurant) {
          return next(action);
        }
        LocalStorageService.setItem('performance_filter', action.payload.filter);
        break;
      }
      case 'SET_DELIVERIES_SORT': {
        const isRestaurant = getState().user.role === Roles.CUSTOMER;
        if (isRestaurant) {
          return next(action);
        }
        const deliveryDate = getState().deliveries.filterBy?.delivery_date;
        if (!deliveryDate || (!!deliveryDate && isToday(new Date(deliveryDate)))) {
          LocalStorageService.setItem('delivery_sort', {
            ...(LocalStorageService.getItem('delivery_sort') || {}),
            [userId.toString()]: action.payload,
          });
        }
        break;
      }
      case 'SET_INVENTORY_SORT': {
        const isRestaurant = getState().user.role === Roles.CUSTOMER;
        if (!isRestaurant) {
          return next(action);
        }
        LocalStorageService.setItem('inventory_sort', {
          ...(LocalStorageService.getItem('inventory_sort') || {}),
          [userId.toString()]: action.payload,
        });
        break;
      }
      case 'SET_INVENTORY_FILTER': {
        const isRestaurant = getState().user.role === Roles.CUSTOMER;
        if (!isRestaurant) {
          return next(action);
        }
        LocalStorageService.setItem('inventory_filter', {
          ...(LocalStorageService.getItem('inventory_filter') || {}),
          [userId.toString()]: action.payload,
        });
        break;
      }
      case 'SET_INVENTORY_SORT_FILTER': {
        const isRestaurant = getState().user.role === Roles.CUSTOMER;
        if (!isRestaurant) {
          return next(action);
        }
        LocalStorageService.setItem('inventory_filter', {
          ...(LocalStorageService.getItem('inventory_filter') || {}),
          [userId.toString()]: action.payload.filter,
        });
        LocalStorageService.setItem('inventory_sort', {
          ...(LocalStorageService.getItem('inventory_sort') || {}),
          [userId.toString()]: action.payload.sort,
        });
        break;
      }
    }
    return next(action);
  };
