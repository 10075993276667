import React, { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { format } from 'date-fns';
import LinesEllipsis from 'react-lines-ellipsis';

import { useDeleteRecOrderMutation } from '../../../../../../api/recurring-order';
import { RecOrder, RecOrderStatus } from '../../../../../../api/recurring-order/types';
import { useGeIsTeammateViewer } from '../../../../../../api/teammates/hooks';
import SvgEditIcon from '../../../../../../assets/icons/EditIcon';
import SvgTrash from '../../../../../../assets/icons/Trash';
import { ThemedButton } from '../../../../../../shared/components/themed-button';
import { Dialog } from '../../../../../../shared/components/dialog';
import { formatCurrency } from '../../../../../../shared/helpers/format-currency';
import { useScreenSize } from '../../../../../../shared/hooks/use-screen-size';
import { ToastService } from '../../../../../../shared/services/toastService';
import { useAppDispatch } from '../../../../../../store';
import { deleteRecurringOrder } from '../../../../../../store/recurring-order';
import { colorVariables } from '../../../../../../styles/colorVariables';

import { useStyles } from './style';

interface Props {
  item: RecOrder;
}

const Component: React.FC<Props> = ({ item }) => {
  const classes = useStyles();
  const { isMobile } = useScreenSize();
  const { push } = useHistory();
  const dispatch = useAppDispatch();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [deleteRecOrder, { isLoading, isSuccess }] = useDeleteRecOrderMutation();
  const isViewer = useGeIsTeammateViewer();

  const openDialog = () => {
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const openDetails = () => {
    push(`/pantry_list/recurring_orders/details`, item.id);
  };

  const onEdit = () => {
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    push({
      pathname: `/pantry_list/recurring_orders/edit`,
      state: {
        recOrderId: item.id,
      },
    });
  };

  const onDelete = () => {
    closeDialog();
    deleteRecOrder(item.id);
  };

  useEffect(() => {
    isSuccess && dispatch(deleteRecurringOrder(item.id));
  }, [isSuccess]);

  return (
    <div className={classes.box}>
      <div className={classes.row}>
        <h4 className={classes.title}>
          <LinesEllipsis text={item.name} ellipsis='...' maxLine={2} />
        </h4>
        <div
          className={clsx({
            [classes.label]: true,
            [classes.labelActive]: item.status === RecOrderStatus.ACTIVE,
            [classes.labelPaused]: item.status === RecOrderStatus.PAUSED,
          })}
        >
          {item.status}
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.greyFieldName}>Items</div>
        <div className={classes.greyFieldValue}>{item.recurring_order_items.length}</div>
      </div>
      <div className={classes.row}>
        <div className={classes.greyFieldName}>Next delivery</div>
        <div className={classes.greyFieldValue}>{format(new Date(item.next_delivery_date || item.start_date), 'd MMMM y')}</div>
      </div>
      <div className={classes.row}>
        <div className={classes.greyFieldName}>Frequency</div>
        <div className={clsx(classes.label, classes.labelFrequency)}>{item.frequency}</div>
      </div>
      <div className={classes.row}>
        <div className={classes.totalText}>Total</div>
        <div className={classes.totalValue}>{formatCurrency(item.supplier?.show_prices ? item.total_by_order : 0)}</div>
      </div>
      <div className={classes.suppliersBox}>
        {item.suppliers_company.slice(0, isMobile ? 5 : 12).map((supplier) => (
          <div className={classes.supplierLogo} key={supplier.id}>
            {supplier.picture_url && <img src={supplier.picture_url} alt={supplier.name} />}
          </div>
        ))}
        {item.suppliers_company.length > (isMobile ? 5 : 12) && <span>...</span>}
      </div>
      <div className={classes.btnBox}>
        {isMobile ? (
          <div className={classes.iconBtnsWrap}>
            <IconButton className={classes.iconBtn} onClick={openDialog} disabled={isLoading}>
              <SvgTrash width={19} height={19} color={colorVariables.navy} />
            </IconButton>
            <IconButton className={classes.iconBtn} onClick={onEdit}>
              <SvgEditIcon width={16} height={16} color={colorVariables.navy} />
            </IconButton>
          </div>
        ) : (
          <div>
            <ThemedButton
              disabled={isLoading}
              title='Delete'
              onClick={openDialog}
              width={136}
              buttonStyle='secondary'
              customClass={classes.btn}
              startIcon={<SvgTrash width={19} height={19} color={colorVariables.navy} />}
            />
            <ThemedButton
              title='Edit'
              onClick={onEdit}
              width={136}
              buttonStyle='secondary'
              customClass={classes.btn}
              startIcon={<SvgEditIcon width={16} height={16} color={colorVariables.navy} />}
            />
          </div>
        )}
        <ThemedButton title='View' onClick={openDetails} width={isMobile ? 130 : 280} customClass={classes.btn} />
      </div>
      {isDialogOpen && (
        <Dialog
          title={`Are you sure you want to remove the '${item.name} order' from recurring order `}
          onCancel={closeDialog}
          onConfirm={onDelete}
          customClass={classes.dialog}
        />
      )}
    </div>
  );
};

export const RecurringOrderListItem = memo(Component);
