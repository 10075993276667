import React from 'react';

import SvgAppleCategory from '../../assets/icons/AppleCategory';
import SvgBakery from '../../assets/icons/BakeryIcon';
import SvgCategoryBakery from '../../assets/icons/categories-light/CategoryBakery';
import SvgCategoryCoffee from '../../assets/icons/categories-light/CategoryCoffee';
import SvgCategoryDairy from '../../assets/icons/categories-light/CategoryDairy';
import SvgCategoryDrink from '../../assets/icons/categories-light/CategoryDrink';
import SvgCategoryDry from '../../assets/icons/categories-light/CategoryDry';
import SvgCategoryFruits from '../../assets/icons/categories-light/CategoryFruits';
import SvgCategoryGoods from '../../assets/icons/categories-light/CategoryGoods';
import SvgCategoryHospitality from '../../assets/icons/categories-light/CategoryHospitality';
import SvgCategoryMeat from '../../assets/icons/categories-light/CategoryMeat';
import SvgCategoryPoultry from '../../assets/icons/categories-light/CategoryPoultry';
import SvgCategorySeafood from '../../assets/icons/categories-light/CategorySeafood';
import SvgCheeseCategory from '../../assets/icons/CheeseCategory';
import SvgCoffeeCategory from '../../assets/icons/CoffeeCategory';
import SvgDrinkCategory from '../../assets/icons/DrinkCategory';
import SvgHospitalityCategory from '../../assets/icons/HospitalityCategory';
import SvgMeatCategory from '../../assets/icons/MeatCategory';
import SvgPastaCategory from '../../assets/icons/PastaCategory';
import SvgPoultry from '../../assets/icons/Poultry';
import SvgSeafoodCategory from '../../assets/icons/SeafoodCategory';
import SvgSmallGoods from '../../assets/icons/SmallGoods';

export enum ProductsCategories {
  FRUIT_VEGETABLES = 'Fruit & Vegetables',
  MEAT = 'Meats',
  POULTRY = 'Poultry',
  DRY_FROZEN = 'Dry & Frozen goods',
  SEAFOOD = 'Seafood',
  HOSPITALITY = 'Hospitality Supplies',
  SMALL_GOODS = 'Small Goods',
  DAIRY = 'Dairy',
  BAKERY = 'Bakery',
  ALCOHOL_BEVERAGES = 'Alcohol & Beverages',
  COFFEE_TEA = 'Coffee & Tea',
}

export const productsCategoriesList: { title: ProductsCategories; icon: React.ReactElement; lightIcon?: React.ReactElement }[] = [
  { title: ProductsCategories.FRUIT_VEGETABLES, icon: <SvgAppleCategory />, lightIcon: <SvgCategoryFruits /> },
  { title: ProductsCategories.MEAT, icon: <SvgMeatCategory />, lightIcon: <SvgCategoryMeat /> },
  { title: ProductsCategories.POULTRY, icon: <SvgPoultry />, lightIcon: <SvgCategoryPoultry /> },
  { title: ProductsCategories.SMALL_GOODS, icon: <SvgSmallGoods />, lightIcon: <SvgCategoryGoods /> },
  { title: ProductsCategories.DAIRY, icon: <SvgCheeseCategory />, lightIcon: <SvgCategoryDairy /> },
  { title: ProductsCategories.HOSPITALITY, icon: <SvgHospitalityCategory />, lightIcon: <SvgCategoryHospitality /> },
  { title: ProductsCategories.SEAFOOD, icon: <SvgSeafoodCategory />, lightIcon: <SvgCategorySeafood /> },
  { title: ProductsCategories.BAKERY, icon: <SvgBakery />, lightIcon: <SvgCategoryBakery /> },
  { title: ProductsCategories.COFFEE_TEA, icon: <SvgCoffeeCategory />, lightIcon: <SvgCategoryCoffee /> },
  { title: ProductsCategories.DRY_FROZEN, icon: <SvgPastaCategory />, lightIcon: <SvgCategoryDry /> },
  { title: ProductsCategories.ALCOHOL_BEVERAGES, icon: <SvgDrinkCategory />, lightIcon: <SvgCategoryDrink /> },
];

export const categoriesForPermission = [
  { label: ProductsCategories.DRY_FROZEN, value: 'dry_and_frozen_goods' },
  { label: ProductsCategories.MEAT, value: 'meat' },
  { label: ProductsCategories.POULTRY, value: 'poultry' },
  { label: ProductsCategories.FRUIT_VEGETABLES, value: 'fruits_and_vegetables' },
  { label: ProductsCategories.SEAFOOD, value: 'seafood' },
  { label: ProductsCategories.BAKERY, value: 'bakery' },
  { label: ProductsCategories.COFFEE_TEA, value: 'coffee_and_tea' },
  { label: ProductsCategories.DAIRY, value: 'dairy' },
  { label: ProductsCategories.ALCOHOL_BEVERAGES, value: 'alchohol_and_beverages' },
  { label: ProductsCategories.SMALL_GOODS, value: 'small_goods' },
  { label: ProductsCategories.HOSPITALITY, value: 'hospitality_supplies' },
];

export const categoriesNames = [
  { label: ProductsCategories.DRY_FROZEN, value: 'dry_and_frozen_goods' },
  { label: ProductsCategories.MEAT, value: 'meat' },
  { label: ProductsCategories.POULTRY, value: 'poultry' },
  { label: ProductsCategories.FRUIT_VEGETABLES, value: 'fruits_and_vegetables' },
  { label: ProductsCategories.SEAFOOD, value: 'seafood' },
  { label: ProductsCategories.BAKERY, value: 'bakery' },
  { label: ProductsCategories.COFFEE_TEA, value: 'coffe_and_tea' },
  { label: ProductsCategories.DAIRY, value: 'dairy' },
  { label: ProductsCategories.ALCOHOL_BEVERAGES, value: 'alcohol_and_beverages' },
  { label: ProductsCategories.SMALL_GOODS, value: 'small_goods' },
  { label: ProductsCategories.HOSPITALITY, value: 'hospitality_supplies' },
];
