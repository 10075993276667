import React from 'react';
import { useHistory } from 'react-router-dom';

import SvgDone from '../../../../assets/icons/Done';
import { ThemedButton } from '../../../../shared/components/themed-button';
import { OverlayModal } from '../../../../shared/components/overlay-modal';

import { useStyles } from './style';

interface Props {
  onClose: () => void;
  isModalOpen: boolean;
}

export const SetUpCompleted: React.FC<Props> = ({ onClose, isModalOpen }) => {
  const classes = useStyles();
  const { push } = useHistory();

  const openImportProducts = () => {
    push('/products/import');
  };

  return (
    <OverlayModal onClose={onClose} isOpen={isModalOpen} boxClassName={classes.box} animate={true} closeOnClickOutside={true}>
      <div className={classes.root}>
        <div className={classes.wrap}>
          <SvgDone className={classes.doneIcon} />
          <div className={classes.title}>Profile set up complete</div>
          <div className={classes.btnBox}>
            <ThemedButton title='My Profile' onClick={onClose} buttonStyle='secondary' customClass={classes.btn} />
            <ThemedButton title='Import Products' onClick={openImportProducts} customClass={classes.btn} />
          </div>
        </div>
      </div>
    </OverlayModal>
  );
};
