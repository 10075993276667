import React, { useState } from 'react';

import { Collapse } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import clsx from 'clsx';
import { FastField, FieldProps, Form, Formik } from 'formik';

import { ProductFilterValues, ProductsSortBy, StockLevelForRequest, StocksEnum } from '../../../../../../api/product/types';
import { ThemedButton } from '../../../../../../shared/components/themed-button';
import { FormikCheckbox } from '../../../../../../shared/components/formik-checkbox';
import { FormikDropdown } from '../../../../../../shared/components/formik-dropdown';
import { FormikInput } from '../../../../../../shared/components/formik-input';
import { getCurrency } from '../../../../../../shared/helpers/getCurrency';
import { getStockLevelTitle } from '../../../../../../shared/helpers/getStockLevelTitle';
import { useScreenSize } from '../../../../../../shared/hooks/use-screen-size';
import { useAppDispatch, useAppSelector } from '../../../../../../store';
import { getProductSortFilters, setProductsFilterBy, setProductsSortBy } from '../../../../../../store/products';

import { useStyles } from './style';

export const productSortParams = [
  { label: 'Product Name (A - Z)', value: 'name asc' },
  { label: 'Product Name (Z - A)', value: 'name desc' },
  { label: 'Brand (A - Z)', value: 'brand asc' },
  { label: 'Brand (Z - A)', value: 'brand desc' },
  { label: 'Stock level (Low-High)', value: 'stock_level asc' },
  { label: 'Stock level (High to low)', value: 'stock_level desc' },
  { label: 'Your Cost (Lowest to Highest)', value: 'price_per_unit asc' },
  { label: 'Your Cost (Highest to Lowest)', value: 'price_per_unit desc' },
  { label: 'Location (A - Z)', value: 'location asc' },
  { label: 'Location (Z - A)', value: 'location desc' },
  { label: 'Shelf space (A - Z)', value: 'shelf_space asc' },
  { label: 'Shelf space (Z - A)', value: 'shelf_space desc' },
  { label: 'Unit (A - Z)', value: 'unit asc' },
  { label: 'Unit (Z - A)', value: 'unit desc' },
  { label: 'Charge unit (A - Z)', value: 'unit_size asc' },
  { label: 'Charge unit (Z - A)', value: 'unit_size desc' },
  { label: 'Sub-category (A - Z)', value: 'sub_category asc' },
  { label: 'Sub-category (Z - A)', value: 'sub_category desc' },
  { label: 'Product Code (A - Z)', value: 'product_id asc' },
  { label: 'Product Code (Z - A)', value: 'product_id desc' },
];

interface Values extends ProductFilterValues {
  sortBy?: string;
}

interface Props {
  handleCloseModal: () => void;
  isFullScreen?: boolean;
}

export const ProductFilter: React.FC<Props> = ({ handleCloseModal, isFullScreen }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);
  const { isMobile, isDesktop } = useScreenSize();
  const dispatch = useAppDispatch();

  const { filter = {}, sort } = useAppSelector(getProductSortFilters);

  const onSubmit = ({ sortBy, ...data }: Values) => {
    sortBy?.trim() && dispatch(setProductsSortBy(sortBy.split(' ') as ProductsSortBy));
    Object.values(data).length && dispatch(setProductsFilterBy(data));
    handleCloseModal();
  };

  const onReset = () => {
    dispatch(setProductsSortBy(undefined));
    dispatch(setProductsFilterBy(undefined));
  };

  const validateForm = (values: Values) => {
    const errors: Partial<Values> = {};
    if (values.min_cost && values.max_cost && +values.min_cost > +values.max_cost) {
      errors.max_cost = 'Max value is too low';
    }
    return errors;
  };

  const expandHandler = () => {
    setExpanded(!expanded);
  };

  return (
    <Formik
      enableReinitialize={true}
      onSubmit={onSubmit}
      initialValues={{
        sortBy: sort?.join(' ') || productSortParams[0].value,
        ...filter,
      }}
      validate={validateForm}
    >
      {({ submitForm }) => (
        <Form className={classes.formBox}>
          <div className={classes.root}>
            <div className={classes.sortBlock}>
              <div className={classes.blockTitle}>SORT BY</div>
              <FastField name='sortBy'>
                {(fieldProps: FieldProps) => (
                  <FormikDropdown
                    placeholder='Select sort option'
                    options={productSortParams}
                    {...fieldProps}
                    height={36}
                    inputFontSize={14}
                  />
                )}
              </FastField>
            </div>
            <div className={classes.filterBlock}>
              <div className={classes.triggerBox}>
                <div className={classes.blockTitle}>FILTER BY</div>
                {expanded ? (
                  <div className={classes.actions} onClick={expandHandler}>
                    <span className={classes.actionsText}>Hide</span>
                    <KeyboardArrowDownIcon className={classes.actionsIcon} />
                  </div>
                ) : (
                  <div className={classes.actions} onClick={expandHandler}>
                    <span className={classes.actionsText}>Show</span>
                    <ChevronRightIcon className={classes.actionsIcon} />
                  </div>
                )}
              </div>
              <Collapse in={expanded || isMobile} timeout={500}>
                <div className={clsx([classes.blockTitle, classes.blockSubTitle])}>PRODUCTS</div>
                <div className={classes.statusBlock}>
                  <FastField type='checkbox' name='status' value='active_product'>
                    {(fieldProps: FieldProps) => <FormikCheckbox {...fieldProps} labelPlace='end' label='Active Products' />}
                  </FastField>
                  <FastField type='checkbox' name='status' value='archived_product'>
                    {(fieldProps: FieldProps) => <FormikCheckbox {...fieldProps} labelPlace='end' label='Archived Products' />}
                  </FastField>
                </div>
                <div className={clsx([classes.blockTitle, classes.blockSubTitle])}>STOCK</div>
                <div className={classes.stockBlock}>
                  <FastField type='checkbox' name='stock' value={StockLevelForRequest.LOW}>
                    {(fieldProps: FieldProps) => (
                      <FormikCheckbox {...fieldProps} labelPlace='end' label={getStockLevelTitle(StocksEnum.LOW)} />
                    )}
                  </FastField>
                  <FastField type='checkbox' name='stock' value={StockLevelForRequest.OUT}>
                    {(fieldProps: FieldProps) => (
                      <FormikCheckbox {...fieldProps} labelPlace='end' label={getStockLevelTitle(StocksEnum.OUT)} />
                    )}
                  </FastField>
                  <FastField type='checkbox' name='stock' value={StockLevelForRequest.IN}>
                    {(fieldProps: FieldProps) => (
                      <FormikCheckbox {...fieldProps} labelPlace='end' label={getStockLevelTitle(StocksEnum.IN)} />
                    )}
                  </FastField>
                </div>
                <div className={clsx([classes.blockTitle, classes.blockSubTitle])}>Tax</div>
                <div className={classes.gstBlock}>
                  <FastField type='checkbox' name='gst' value='1'>
                    {(fieldProps: FieldProps) => <FormikCheckbox {...fieldProps} labelPlace='end' label='YES' />}
                  </FastField>
                  <FastField type='checkbox' name='gst' value='0'>
                    {(fieldProps: FieldProps) => <FormikCheckbox {...fieldProps} labelPlace='end' label='NO' />}
                  </FastField>
                </div>
                <div className={classes.costBlock}>
                  <div className={clsx([classes.blockTitle, classes.blockSubTitle])}>Your Cost</div>
                  <div className={classes.costFields}>
                    <div className={classes.costFieldWrap}>
                      <FastField name='min_cost'>
                        {(fieldProps: FieldProps) => (
                          <FormikInput
                            {...fieldProps}
                            height={isDesktop ? undefined : 36}
                            inputFontSize={isDesktop ? undefined : 14}
                            placeholder={`${getCurrency()} min`}
                            type='number'
                          />
                        )}
                      </FastField>
                    </div>
                    <div className={classes.divider}>&#8211;</div>
                    <div className={classes.costFieldWrap}>
                      <FastField name='max_cost'>
                        {(fieldProps: FieldProps) => (
                          <FormikInput
                            {...fieldProps}
                            height={isDesktop ? undefined : 36}
                            inputFontSize={isDesktop ? undefined : 14}
                            placeholder={`${getCurrency()} max`}
                            type='number'
                          />
                        )}
                      </FastField>
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>
            <div className={clsx([classes.btnBlock, isFullScreen && classes.btnBlockFullScreen])}>
              <ThemedButton onClick={submitForm} title='Apply' buttonStyle='primary' isSmall={isMobile} />
              <ThemedButton
                onClick={onReset}
                title='CLEAR FILTERS'
                buttonStyle='icon'
                type='reset'
                startIcon={<Close color={'inherit'} />}
                isSmall={isMobile}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
