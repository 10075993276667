import React, { memo, useEffect, useState } from 'react';

import { ClickAwayListener, Menu, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import LinesEllipsis from 'react-lines-ellipsis';

import {
  useAcceptConnectionRequestMutation,
  useDeclineConnectionRequestMutation,
  useGetConnectionRequestsQuery,
  useGetMyCompanyQuery,
  useSendConnectionRequestMutation,
  useUpdateConnectionRequestMutation,
} from '../../../../../api/company';
import { CustomerConnectionStatus } from '../../../../../api/customer/types';
import { Category } from '../../../../../api/product/types';
import { useGetAvailableCategories } from '../../../../../api/teammates/hooks';
import { useMeQuery } from '../../../../../api/user';
import { Roles } from '../../../../../api/user/types';
import { BlurredImage } from '../../../../../shared/components/blurred-image';
import { CloseButton } from '../../../../../shared/components/close-button';
import { ThemedButton } from '../../../../../shared/components/themed-button';
import { CommonCheckbox } from '../../../../../shared/components/common-checkbox';
import { Dialog } from '../../../../../shared/components/dialog';
import { InfoTooltip } from '../../../../../shared/components/info-tooltip';
import { OverlayModal } from '../../../../../shared/components/overlay-modal';
import { ProductsCategories, productsCategoriesList } from '../../../../../shared/constants/products';
import { getCategoriesFromIds, getCategoryId } from '../../../../../shared/helpers/getCategoryId';
import { useScreenSize } from '../../../../../shared/hooks/use-screen-size';
import { ToastService } from '../../../../../shared/services/toastService';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { getSelectedSupplier } from '../../../../../store/suppliers-categories/suppliers-categories.selectors';
import { setSuccessMsg } from '../../../../../store/user';

import { useStyles } from './style';
import { CommonButton } from '../../../../../shared/components/common-button';

interface Props {
  isDropdownOpened?: boolean;
  handleClose: () => void;
  anchorEl?: Element | null;
}

const Component: React.FC<Props> = ({ isDropdownOpened, handleClose, anchorEl }) => {
  const classes = useStyles();
  const { isDesktop, isMobile } = useScreenSize();
  const dispatch = useAppDispatch();

  const [isTooltipShownFor, setIsTooltipShownFor] = useState<ProductsCategories | null>(null);
  const [isDisconnectDialogOpened, setIsDisconnectDialogOpened] = useState(false);
  const [isVerifiedTooltipShown, setIsVerifiedTooltipShown] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState<ProductsCategories | null>(null);
  const supplier = useAppSelector(getSelectedSupplier);
  const supplierId = supplier?.id;
  const availableCategories = useGetAvailableCategories();

  const { request } = useGetConnectionRequestsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      request: data?.find((req) => req.supplier?.id === supplierId),
    }),
  });

  const { data: user } = useMeQuery();
  const { categories } = useGetMyCompanyQuery(user?.company?.id, {
    skip: !user?.company?.id,
    selectFromResult: ({ data }) => ({
      categories: getCategoriesFromIds(
        data?.company_categories.map(
          (el) =>
            ({
              id: el.category_id,
              name: '',
            } as unknown as Category),
        ) || [],
      ),
    }),
  });

  const [sendRequest, { isSuccess: sendReqSuccess, isLoading: sendReqLoading }] = useSendConnectionRequestMutation();
  const [acceptRequest, { isLoading: acceptReqLoading, isSuccess: acceptSuccess }] = useAcceptConnectionRequestMutation();
  const [declineRequest, { isLoading: declineReqLoading, isSuccess: declineSuccess }] = useDeclineConnectionRequestMutation();
  const [updateRequest, { isLoading: updateReqLoading, isSuccess: updateSuccess }] = useUpdateConnectionRequestMutation();

  const checkBoxHandler = (category: ProductsCategories) => {
    if (Array.isArray(availableCategories) && !availableCategories.includes(category)) {
      ToastService.error('You have been restricted from selecting this category.');
      return;
    }
    if (categories.includes(category)) {
      setSelectedCategory(category);
    } else {
      setIsTooltipShownFor(category);
    }
  };

  const showTooltip = () => {
    setIsVerifiedTooltipShown(true);
  };

  const hideTooltip = () => {
    setIsVerifiedTooltipShown(false);
  };

  const checkIsCategoryAvailable = (category: ProductsCategories): boolean => {
    return supplier && supplier?.categories.length ? supplier.categories.some((cat) => cat.id === getCategoryId(category)) : true;
  };

  const onSendRequest = async (companyName?: string) => {
    if (!request && supplier) {
      await sendRequest({
        showSuccessModal: false,
        connection_request: {
          supplier_id: supplier.id,
          connection_categories_attributes: [
            {
              category_id: selectedCategory ? getCategoryId(selectedCategory) : undefined,
            },
          ],
        },
      });
      ToastService.success(`${companyName || 'Supplier'} has been added`);
    }
  };

  const onAccept = () => {
    if (request && selectedCategory) {
      acceptRequest({
        connection_request: {
          id: request.id,
          status: CustomerConnectionStatus.ACCEPTED,
          connection_categories_attributes: [
            {
              category_id: getCategoryId(selectedCategory),
            },
          ],
        },
      });
    }
  };

  const onDecline = () => {
    setIsDisconnectDialogOpened(false);
    request &&
      declineRequest({
        connection_request_id: request.id,
      });
  };

  const onUpdateConnectionRequest = () => {
    const connectionCategory = request?.connection_categories?.find((cc) => cc.connection_request_id === request?.id);
    if (request && selectedCategory) {
      updateRequest({
        connection_request: {
          id: request.id,
          connection_categories_attributes: [
            {
              id: connectionCategory?.id,
              category_id: getCategoryId(selectedCategory),
            },
          ],
        },
      });
    }
  };

  useEffect(() => {
    if (request?.categories[0]) {
      setSelectedCategory(getCategoriesFromIds([request?.categories[0]])[0]);
    }
    if ((!anchorEl && isDesktop) || (!isDropdownOpened && !isDesktop)) {
      setSelectedCategory(null);
    }
  }, [request?.categories, anchorEl, isDropdownOpened]);

  useEffect(() => {
    if (sendReqSuccess) {
      handleClose();
    }
    acceptSuccess && handleClose();
  }, [sendReqSuccess, acceptSuccess]);

  useEffect(() => {
    if (declineSuccess) {
      handleClose();
      return;
    }
    if (updateSuccess) {
      handleClose();
      dispatch(setSuccessMsg({ message: 'Updated' }));
    }
  }, [declineSuccess, updateSuccess]);

  const renderDropdownContent = () => (
    <div>
      <CloseButton customClass={classes.closeBtn} onClick={handleClose} />
      {request ? (
        <h3 className={classes.dropDownTitle}>{isDesktop ? 'You have a new connection request:' : 'Your Supplier'}</h3>
      ) : (
        <h3 className={classes.dropDownTitle}>Add Supplier</h3>
      )}
      {supplier?.manual_supplier && (
        <div className={classes.manualSupplierWarning}>
          NOTE: The prices for this supplier are managed by the individual restaurants. Please ensure your own prices are kept up to date.
          You can manage your prices in the drop down menu.
        </div>
      )}
      <div className={clsx(classes.supplierInfo, classes.supplierInfoModal)}>
        <div className={clsx([classes.avatarWrap, classes.avatarWrapBig, request && classes.avatarWrapModal])}>
          {!!supplier?.company.picture_url && <BlurredImage src={supplier.company.picture_url} />}
        </div>
        <div className={classes.supplierDetailsBox}>
          <div className={classes.supplierName}>
            <LinesEllipsis text={supplier?.company.name || ''} ellipsis='...' />
          </div>
          <div className={clsx([classes.supplierAddress, request && classes.supplierAddressInModal])}>
            {supplier?.company_addresses[0]?.city && supplier?.company_addresses[0]?.state
              ? `${supplier?.company_addresses[0]?.city?.slice(0, 20)}, ${supplier.company_addresses[0]?.state?.toUpperCase()}`
              : ''}
          </div>
          {request && (
            <div
              className={clsx({
                [classes.label]: true,
                [classes.connectedLabel]: request?.status === CustomerConnectionStatus.ACCEPTED,
                [classes.requestedLabel]: request?.status === CustomerConnectionStatus.PENDING,
              })}
            >
              {request?.status === CustomerConnectionStatus.ACCEPTED ? 'Connected' : 'Requested'}
            </div>
          )}
        </div>
      </div>
      <h4 className={classes.dropDownSubTitle}>Where would you like to see this supplier?</h4>
      <div
        className={clsx(
          classes.categoriesBox,
          !request && classes.categoriesBoxExtraHeight,
          supplier?.manual_supplier && classes.categoriesBoxManual,
        )}
      >
        {productsCategoriesList.map((category) => (
          <div key={category.title} className={classes.categoryItem}>
            <CommonCheckbox
              disabled={!checkIsCategoryAvailable(category.title)}
              formControlClassName={classes.mobileCheckbox}
              name={category.title}
              checked={selectedCategory === category.title}
              onChange={checkBoxHandler.bind(null, category.title)}
              labelPlace={isMobile ? 'start' : 'end'}
            />
            {isTooltipShownFor === category.title && (
              <ClickAwayListener onClickAway={setIsTooltipShownFor.bind(null, null)}>
                <div className={classes.tooltipWrap}>
                  <InfoTooltip text='Unable to add this supplier here. Please add this category.' rightSide={isMobile} />
                </div>
              </ClickAwayListener>
            )}
          </div>
        ))}
      </div>
      {
        <div className={classes.btnBox}>
          <>
            {(!request || (request?.send_by === Roles.CUSTOMER && request?.status === CustomerConnectionStatus.NEW_REQUEST)) && (
              <ThemedButton width={130} title='Cancel' onClick={handleClose} buttonStyle='secondary' isSmall={true} />
            )}
          </>
          <>
            {request?.status === CustomerConnectionStatus.ACCEPTED && (
              <ThemedButton
                width={130}
                title='Disconnect'
                onClick={setIsDisconnectDialogOpened.bind(null, true)}
                buttonStyle='secondary'
                isSmall={true}
                disabled={declineReqLoading}
              />
            )}
          </>
          <>
            {request?.send_by === Roles.SUPPLIER && request?.status === CustomerConnectionStatus.PENDING && (
              <ThemedButton
                width={130}
                title='Ignore'
                onClick={onDecline}
                buttonStyle='secondary'
                isSmall={true}
                disabled={declineReqLoading}
              />
            )}
          </>
          <>
            {request?.status === CustomerConnectionStatus.ACCEPTED && (
              <>
                {(request?.categories[0] && selectedCategory !== getCategoriesFromIds([request?.categories[0]])[0]) ||
                !request?.categories[0] ? (
                  <ThemedButton isSmall={true} title='Update' onClick={onUpdateConnectionRequest} width={130} disabled={updateReqLoading} />
                ) : (
                  <div className={classes.acceptedText}>Accepted</div>
                )}
              </>
            )}
          </>
          <>
            {request?.status === CustomerConnectionStatus.NEW_REQUEST && request?.send_by === Roles.CUSTOMER && (
              <>
                {request?.categories[0] && selectedCategory !== getCategoriesFromIds([request?.categories[0]])[0] ? (
                  <ThemedButton isSmall={true} title='Update' onClick={onUpdateConnectionRequest} width={130} disabled={updateReqLoading} />
                ) : (
                  <ThemedButton onClick={onDecline} title='Cancel Request' width={130} isSmall={true} disabled={declineReqLoading} />
                )}
              </>
            )}
          </>
          <>
            {request && request?.send_by === Roles.SUPPLIER && request?.status === CustomerConnectionStatus.PENDING && (
              <ClickAwayListener onClickAway={hideTooltip}>
                <Tooltip
                  disableTouchListener={true}
                  disableHoverListener={true}
                  disableFocusListener={true}
                  arrow={true}
                  classes={{
                    tooltip: classes.rootTooltip,
                    arrow: classes.arrow,
                  }}
                  title={
                    <div className={classes.tooltip}>
                      <div className={classes.tooltipTitle}>Supplier is not "verified"</div>
                      <div className={classes.tooltipText}>
                        You will be able to accept the connection request after our verification of this supplier.
                      </div>
                      <CommonButton onClick={() => {}} title='OK' isSmall={true} width={200} className={classes.redBtn} />
                      <CloseButton customClass={classes.closeTBtn} onClick={hideTooltip} />
                    </div>
                  }
                  interactive={true}
                  open={isVerifiedTooltipShown}
                  onOpen={showTooltip}
                  onClose={hideTooltip}
                  placement='top-end'
                >
                  <div onClick={showTooltip}>
                    <ThemedButton
                      isSmall={true}
                      title='Accept'
                      onClick={supplier?.verified ? onAccept : () => {}}
                      width={130}
                      disabled={!selectedCategory || acceptReqLoading}
                    />
                  </div>
                </Tooltip>
              </ClickAwayListener>
            )}
          </>
          <>
            {!request && (
              <ThemedButton
                isSmall={true}
                title='Add Supplier'
                onClick={onSendRequest.bind(null, supplier?.company.name)}
                width={130}
                disabled={!selectedCategory || sendReqLoading}
              />
            )}
          </>
        </div>
      }
      {isDisconnectDialogOpened && (
        <Dialog
          title='Are you sure you want to disconnect this supplier?'
          onConfirm={onDecline}
          onCancel={setIsDisconnectDialogOpened.bind(null, false)}
          customClass={classes.dialog}
        />
      )}
    </div>
  );

  return isDesktop ? (
    <Menu
      autoFocus={false}
      anchorEl={anchorEl}
      disableScrollLock={true}
      keepMounted={true}
      open={Boolean(anchorEl) && isDesktop}
      onClose={handleClose}
      classes={{ paper: classes.dropdownContainer }}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      {renderDropdownContent()}
    </Menu>
  ) : (
    <OverlayModal
      isOpen={!!isDropdownOpened}
      onClose={handleClose}
      animate={true}
      closeOnClickOutside={true}
      boxClassName={classes.dropdownModal}
      contentWrapClassName={isMobile ? classes.dropdownModalContent : ''}
    >
      {renderDropdownContent()}
    </OverlayModal>
  );
};

export const ConnectionDropdown = memo(Component);
