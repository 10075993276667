import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      fontSize: 20,
      fontWeight: 700,
      color: colorVariables.navy,
    },
    backArrow: {
      marginRight: 3,
    },
  }),
);
