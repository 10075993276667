import React from 'react';
import { Link } from 'react-router-dom';

import { ChevronRight } from '@material-ui/icons';
import Highlighter from 'react-highlight-words';

import { useStyles } from './style';

interface Props {
  list: { id: number; content: string; title: string; path: string }[];
  request: string;
}

export const HelpInfoSearchResults: React.FC<Props> = ({ list, request }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.resCount}>{`${list.length} Results for "${request}"`}</div>
      <div>
        {list.map((res) => (
          <div key={res.id} className={classes.searchResItem}>
            <Link className={classes.title} to={`/account/help/${res.path}/${res.id}`}>
              <div className={classes.linkHead}>
                <Highlighter
                  highlightTag={'b'}
                  className={classes.titleText}
                  highlightClassName={classes.marked}
                  searchWords={[request]}
                  autoEscape={true}
                  textToHighlight={res.title || ''}
                />
                <ChevronRight />
              </div>
              <div className={classes.text}>
                <Highlighter
                  highlightTag={'b'}
                  highlightClassName={classes.marked}
                  searchWords={[request]}
                  autoEscape={true}
                  textToHighlight={res.content.substr(0, 300) + '...' || ''}
                />
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};
