import * as React from 'react';

function SvgCoffeeCategory(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M12.408 38.091l.017.007h.018a.496.496 0 01.053.018l.027.01c.05.02.118.044.208.044h8.926c.036 0 .08 0 .135-.011a.77.77 0 00.192-.07c3.75-1.447 6.848-4.474 8.872-8.36h3.322a4.928 4.928 0 004.922-4.934 4.928 4.928 0 00-4.922-4.933h-.762c.004-.179.019-.358.034-.541v-.002c.018-.215.036-.435.036-.656v-3.319c0-.239-.08-.446-.227-.593a.819.819 0 00-.592-.228H1.72a.819.819 0 00-.593.228.822.822 0 00-.227.594v3.318c0 8.976 4.622 16.812 11.508 19.428zm21.67-16.659v.073h.1a3.283 3.283 0 013.283 3.29c0 1.821-1.467 3.291-3.283 3.291h-2.586c.828-2.037 1.45-4.277 1.67-6.654h.816zm-3.126 3.463c-1.522 5.467-5.005 9.852-9.46 11.632h-8.598c-4.455-1.78-7.938-6.165-9.46-11.632h27.518zM2.54 16.165h29.308v2.498c0 1.555-.139 3.109-.484 4.662H3.023c-.345-1.553-.484-3.107-.484-4.662v-2.497zM14.947 12.298c.236 0 .44-.08.586-.228a.826.826 0 00.224-.593.827.827 0 00-.224-.593.804.804 0 00-.586-.228c-.65 0-1.107-.526-1.107-1.125 0-.663.52-1.125 1.107-1.125 1.55 0 2.728-1.274 2.728-2.767 0-1.492-1.248-2.84-2.728-2.84-.236 0-.44.081-.586.229a.827.827 0 00-.224.593c0 .238.079.445.224.592.145.148.35.228.586.228.65 0 1.107.527 1.107 1.126 0 .595-.524 1.125-1.107 1.125-1.55 0-2.728 1.273-2.728 2.767 0 1.49 1.175 2.839 2.728 2.839zM21.01 12.298c.237 0 .441-.08.587-.228a.826.826 0 00.224-.593.827.827 0 00-.224-.593.804.804 0 00-.587-.228c-.65 0-1.107-.526-1.107-1.125 0-.663.521-1.125 1.108-1.125 1.55 0 2.727-1.274 2.727-2.767 0-1.492-1.247-2.84-2.727-2.84-.237 0-.441.081-.587.229a.826.826 0 00-.224.593c0 .238.079.445.224.592.146.148.35.228.587.228.65 0 1.107.527 1.107 1.126 0 .595-.525 1.125-1.107 1.125-1.55 0-2.728 1.273-2.728 2.767 0 1.49 1.174 2.839 2.727 2.839z'
        fill='#51D9B0'
        stroke='#51D9B0'
        strokeWidth={0.2}
      />
    </svg>
  );
}

export default SvgCoffeeCategory;
