import React, { memo, useState } from 'react';

import { format } from 'date-fns';

import { useDeleteTeammateMutation } from '../../../../api/teammates';
import { useGeIsTeammateAdmin } from '../../../../api/teammates/hooks';
import { Teammate } from '../../../../api/teammates/types';
import { Dialog } from '../../../../shared/components/dialog';
import { DropdownMenu } from '../../../../shared/components/dropdown-menu';
import { MoreButton } from '../../../../shared/components/more-button';
import { formatDateDifference } from '../../../../shared/helpers/format-date-difference';
import { useScreenSize } from '../../../../shared/hooks/use-screen-size';
import { ToastService } from '../../../../shared/services/toastService';
import { EditTeammateModal } from '../edit-teammate-modal';
import { SubmitPermissionDropdown } from '../submit-permission-dropdown';

import { useStyles } from './style';

interface Props {
  item: Teammate;
}

const Component: React.FC<Props> = ({ item }) => {
  const classes = useStyles();
  const { isMobile } = useScreenSize();
  const [deleteDialogShown, setDeleteDialogShown] = useState(false);
  const [editModalOpened, setEditModalOpened] = useState(false);

  const [deleteTeammate] = useDeleteTeammateMutation();
  const isViewer = !useGeIsTeammateAdmin();

  const onDelete = () => {
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    deleteTeammate(item.id).then((res) => {
      if ('data' in res && res.data.success) {
        ToastService.success(
          `You removed ${item.first_name} ${item.last_name || ''} from your team`,
          `remove_teammate_${item.id}`,
          'Teammate removed',
        );
      }
    });
    setDeleteDialogShown(false);
  };

  return (
    <div className={classes.row}>
      <div className={classes.name}>
        <div className={classes.nameText}>{`${item.first_name} ${item.last_name}`}</div>
        <div className={classes.emailText}>{item.email}</div>
      </div>
      <div className={classes.lastActive}>{formatDateDifference(item.last_login_at)}</div>
      <div className={classes.permission}>
        <SubmitPermissionDropdown item={item} />
        <div className={classes.createdDate}>{format(new Date(item.created_at), 'd MMMM yy')}</div>
      </div>
      <div className={classes.actions}>
        <DropdownMenu
          items={[
            <div key='View' onClick={setEditModalOpened.bind(null, true)}>
              View Profile
            </div>,
            <div key='Delete' onClick={setDeleteDialogShown.bind(null, true)}>
              Remove Teammate
            </div>,
          ]}
          triggerBtn={<MoreButton customClass={classes.moreEllipsis} horizontal={!isMobile} />}
        />
      </div>
      {deleteDialogShown && (
        <Dialog
          title={`Are you sure you want to remove ${item.first_name} ${item.last_name || ''} from team? `}
          onConfirm={onDelete}
          onCancel={setDeleteDialogShown.bind(null, false)}
          customClass={classes.dialog}
        />
      )}
      <EditTeammateModal isOpen={editModalOpened} onClose={setEditModalOpened.bind(null, false)} item={item} />
    </div>
  );
};

export const TeammateListItem = memo(Component);
