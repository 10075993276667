import React, { useEffect, useState } from 'react';

import { Menu } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import clsx from 'clsx';

import SvgEditPen from '../../../../../../../assets/icons/EditPen';

import { useStyles } from './style';

interface Props {
  options: string[];
  defaultOption: string;
  onSelect: (option: string) => void;
}

export const CustomSelect: React.FC<Props> = ({ options, defaultOption, onSelect }) => {
  const classes = useStyles();

  const [selectedOption, setSelectedOption] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const onOptionClick = (option: string) => {
    setSelectedOption(option);
    onSelect(option);
    handleClose();
  };

  useEffect(() => {
    defaultOption !== selectedOption && setSelectedOption(defaultOption);
  }, [defaultOption]);

  return (
    <>
      <div className={classes.selectWrap} onClick={handleOpen}>
        <div className={classes.select}>{selectedOption}</div>
        <SvgEditPen />
      </div>
      <Menu
        autoFocus={false}
        anchorEl={anchorEl}
        disableScrollLock={true}
        keepMounted={true}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {options.map((option, idx) => (
          <MenuItem
            onClick={onOptionClick.bind(null, option)}
            key={idx}
            classes={{
              root: clsx(classes.menuItem, selectedOption === option && classes.menuItemActive),
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
