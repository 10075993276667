import React from 'react';

import { Chip } from '@material-ui/core';
import CloseButton from '@material-ui/icons/Close';

import { useStyles } from './style';

interface Props {
  title: string;
  onClick: () => void;
  tagMode?: boolean;
}

export const CommonChip: React.FC<Props> = ({ title, onClick, tagMode }) => {
  const classes = useStyles();
  return <Chip className={tagMode ? classes.tagRoot : classes.root} label={title} onDelete={onClick} deleteIcon={<CloseButton />} />;
};
