import * as React from 'react';

function SvgShopIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='74' height='67' viewBox='0 0 74 67' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#ShopIcon_svg__clip0_4339_42117)' fill='#4BCFBB'>
        <path d='M37.21 28.758c6.59 6.652 13.448 6.522 19.639.153 3.219 3.327 7.024 5.289 12.099 4.255.05 1.086.122 1.939.122 2.791.006 8.086.017 16.173-.006 24.259-.014 4.79-1.998 6.77-6.756 6.772-16.789.012-33.581.015-50.37.003C7.3 66.99 5.3 65.007 5.28 60.411c-.035-8.261-.04-16.523-.05-24.782 0-.68.059-1.363.1-2.279 4.582.59 8.37-.65 11.224-4.428 2.957 3.037 6.185 5.243 10.48 5.174 4.282-.072 7.623-2.008 10.175-5.335v-.003zm-20.573 8.933v26.866h17.357V37.691H16.637zm26.455 7.364h-.011c0 1.751.05 3.508-.015 5.257-.061 1.656.647 2.437 2.334 2.425 3.542-.028 7.088-.04 10.63-.014 1.57.012 2.255-.729 2.252-2.238-.009-3.506-.009-7.01.006-10.515.006-1.578-.588-2.446-2.351-2.414-3.543.063-7.088.049-10.63.009-1.591-.018-2.25.711-2.223 2.232.029 1.752.005 3.506.005 5.258h.003zM35.766 20.998H19.483C20.81 13.948 22.097 7.089 23.4.158h12.367v20.84zM.7 21.122c2.534-6.666 4.844-12.98 7.402-19.195.355-.864 1.905-1.665 2.963-1.745 3.073-.23 6.176-.084 9.605-.084-1.268 6.79-2.468 13.373-3.776 19.938-.087.44-.961 1.028-1.477 1.037-4.752.08-9.503.046-14.718.046v.003zM54.39 21.003H38.877V.252h11.717l3.796 20.752zM53.277.043c2.946 0 5.603-.135 8.233.075.9.072 2.147.807 2.529 1.582 3.103 6.283 6.036 12.646 9.15 19.26H57.115L53.277.046V.043z' />
        <path d='M36.48 24.42c-.758 1.423-1.216 3.151-2.323 4.22-4.093 3.944-10.36 3.858-14.447.21-1.5-1.339-1.383-2.869-.772-4.272.257-.587 1.72-.901 2.643-.919 4.675-.089 9.357-.04 14.033-.04l.865.801zM54.598 23.769c1.564 2.878.072 4.46-2.025 5.813-3.758 2.426-8.588 2.057-12.081-.855-1.573-1.314-2.287-2.81-1.486-4.958h15.592zM0 23.637h16.308c-.396 4.272-3.871 7.36-8.195 7.403-4.13.04-7.507-2.935-8.11-7.403H0zM57.734 23.806H74c-.731 4.474-4.311 7.513-8.454 7.35-4.283-.17-7.385-2.988-7.81-7.35z' />
      </g>
      <defs>
        <clipPath id='ShopIcon_svg__clip0_4339_42117'>
          <path fill='#fff' d='M0 0h74v67H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgShopIcon;
