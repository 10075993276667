import React, { useEffect, useRef } from 'react';
import { NavLink, Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import clsx from 'clsx';

import { useConnectStripeMutation, useGetStripeLoginLinkMutation, useMeQuery } from '../../../api/user';
import stripeLogo from '../../../assets/images/stripeLogo.png';
import { NavbarTitle } from '../../../shared/components/navbar-title';
import { useScreenSize } from '../../../shared/hooks/use-screen-size';
import { useAppDispatch, useAppSelector } from '../../../store';
import { getIsRestaurant, openAccountMenu, openMainMenu } from '../../../store/user';
import { MainNavBar } from '../../AppDrawer/MainNavBar';
import { TopNavBar } from '../../AppDrawer/MainNavBar/top-nav-bar';
import { AccountMenu } from '../account-menu';
import { PaymentMethods } from './PaymentMethods';
import { AddPaymentMethod } from './PaymentMethods/add-payment-method';
import { Subscriptions } from './Subscriptions';

import { useStyles } from './style';

export const Billing: React.FC = () => {
  const classes = useStyles();
  const { pathname, search } = useLocation();
  const { replace } = useHistory();
  const code = search.split('code=')[1];
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISABLE_KEY as string, { apiVersion: '2023-08-16' });
  const stripeLink = useRef<HTMLAnchorElement | null>(null);

  const { isMobile, isTablet } = useScreenSize();
  const dispatch = useAppDispatch();
  const isRestaurant = useAppSelector(getIsRestaurant);
  const [connectStripe] = useConnectStripeMutation();
  const { data: user } = useMeQuery();

  const isStripeConnected = user && 'stripe_user_id' in user && user?.stripe_user_id;
  const [getLoginLink] = useGetStripeLoginLinkMutation();

  const openMenus = () => {
    dispatch(openMainMenu());
    dispatch(openAccountMenu());
  };

  const onLoginStripe = () => {
    getLoginLink().then((res) => {
      if ('data' in res && res.data.success && res.data.login_link && stripeLink?.current) {
        stripeLink.current!.href = res.data.login_link;
        stripeLink.current!.click();
      }
    });
  };

  const isAddPaymentPage = pathname.includes('/account/billing/payment/add');

  useEffect(() => {
    code &&
      connectStripe(code).then(() => {
        replace('/account/billing/payment');
      });
  }, [code]);

  return (
    <Elements stripe={stripePromise}>
      <TopNavBar
        withTopNavigation={!isAddPaymentPage}
        isAccount={true}
        leftComponent={
          <div>
            <NavbarTitle title='Billing' showBackBtn={true} backHandler={openMenus} classNames={classes.topNavBarTitle} />
            <div className={classes.headerNavBox}>
              <NavLink to='/account/billing/subscriptions' className={classes.headerLink} activeClassName={classes.headerLinkActive}>
                Subscriptions
              </NavLink>
              <NavLink
                to='/account/billing/transactions'
                className={clsx(classes.headerLink, classes.tabLinkDisabled)}
                activeClassName={classes.headerLinkActive}
                exact={true}
              >
                Transactions
              </NavLink>
              <NavLink to='/account/billing/payment' className={classes.headerLink} activeClassName={classes.headerLinkActive}>
                Payment Methods
              </NavLink>
            </div>
          </div>
        }
        bottomComponent={
          isMobile ? (
            <div>
              <div className={classes.titleWrap}>
                <NavbarTitle
                  title={isAddPaymentPage ? 'Add payment method' : 'Billing'}
                  showBackBtn={true}
                  backHandler={isAddPaymentPage ? undefined : openMenus}
                  classNames={classes.topNavBarTitle}
                />
                {pathname.includes('default') && <span className={classes.defaultBadge}>Default</span>}
              </div>
              {!isAddPaymentPage && (
                <div className={classes.headerNavBox}>
                  <NavLink to='/account/billing/subscriptions' className={classes.headerLink} activeClassName={classes.headerLinkActive}>
                    Subscriptions
                  </NavLink>
                  <NavLink
                    to='/account/billing/transactions'
                    className={classes.headerLink}
                    activeClassName={classes.headerLinkActive}
                    exact={true}
                  >
                    Transactions
                  </NavLink>
                  <NavLink to='/account/billing/payment' className={classes.headerLink} activeClassName={classes.headerLinkActive}>
                    Payment Methods
                  </NavLink>
                </div>
              )}
            </div>
          ) : undefined
        }
      />
      <MainNavBar isAccount={true} />
      <div className={clsx(classes.accountContainer, isRestaurant && classes.accountContainerCustomer)}>
        <div className={clsx(classes.accountHeader)}>
          <div className={classes.accountTitle}>My Account</div>
        </div>
        <div className={classes.accountBody}>
          <AccountMenu showBack={!isRestaurant} />
          <div className={classes.accountContent}>
            {isRestaurant && isTablet && (
              <div className={classes.tabletTitleCustomer}>
                <NavbarTitle
                  title={isAddPaymentPage ? 'Add payment method' : 'Billing'}
                  showBackBtn={true}
                  backHandler={isAddPaymentPage ? undefined : openMenus}
                />
              </div>
            )}
            <>
              <div className={classes.titleBox}>
                <div className={classes.title}>Billing</div>
              </div>
              <div className={clsx(classes.tabs, isRestaurant && classes.tabsCustomer)}>
                <NavLink to='/account/billing/subscriptions' className={classes.tabLink} activeClassName={classes.tabLinkActive}>
                  Subscriptions
                </NavLink>
                <NavLink to='/account/billing/payment' className={classes.tabLink} activeClassName={classes.tabLinkActive}>
                  Payment Methods
                </NavLink>
                <NavLink
                  to='/account/billing/transactions'
                  className={clsx(classes.tabLink, classes.tabLinkDisabled)}
                  activeClassName={classes.tabLinkActive}
                  exact={true}
                >
                  Transactions
                </NavLink>
              </div>
            </>
            <Switch>
              <Route path='/account/billing/payment/add'>
                <AddPaymentMethod />
              </Route>
              {/*<Route path='/account/billing/transactions'>*/}
              {/*  <Transactions />*/}
              {/*</Route>*/}
              <Route path='/account/billing/payment'>
                {isRestaurant ? (
                  <PaymentMethods />
                ) : (
                  <div className={classes.supplierPmBox}>
                    {isStripeConnected ? (
                      <>
                        <div className={classes.stripeTitle}>Your account is set up to receive credit card payments</div>
                        <div className={classes.stripeSubText}>To edit your banking details please log into stripe</div>
                        <div className={classes.loginStripBtn} onClick={onLoginStripe}>
                          <img src={stripeLogo} alt='Stripe login' width={26} height={32} />
                          <span>Stripe Login</span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={classes.stripeTitle}>Connect Stripe Account</div>
                        <div className={classes.stripeSubText}>Connect Your Stripe Account for Easy Customer Payments Processing!</div>
                        <a
                          href={`https://connect.stripe.com/express/oauth/authorize?redirect_uri=${process.env.REACT_APP_STRIPE_EXPRESS_REDIRECT_URI}&client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}&stripe_user[email]=${user?.email}&stripe_user[first_name]=${user?.first_name}&stripe_user[last_name]=${user?.last_name}&stripe_user[business_name]=${user?.company?.name}`}
                          className={classes.connectStripBtn}
                        >
                          Connect with Stripe
                        </a>
                      </>
                    )}
                  </div>
                )}
              </Route>
              <Route path='/account/billing/subscriptions' component={Subscriptions} />
              <Route path='/account/billing'>
                <Redirect to='/account/billing/subscriptions' />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
      <a href='' className={classes.hiddenLink} target='_blank' ref={stripeLink} />
    </Elements>
  );
};
