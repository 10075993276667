import React from 'react';

import { endOfYear, startOfYear } from 'date-fns';
import gregorian from 'react-date-object/calendars/gregorian';
import { Calendar, DateObject } from 'react-multi-date-picker';

import { colorVariables } from '../../../styles/colorVariables';

import { useStyles } from './style';

interface Props {
  dates: Date[] | string[];
  onSetDates: (dates: Date[] | string[]) => void;
}

export const MultiDatePicker: React.FC<Props> = ({ dates, onSetDates }) => {
  const classes = useStyles();
  const weekDays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

  const setDates = (selectedDates: DateObject | DateObject[] | null) => {
    if (Array.isArray(selectedDates)) {
      onSetDates(selectedDates.map((el) => el.toString()));
    }
  };

  return (
    <Calendar
      minDate={startOfYear(new Date())}
      maxDate={endOfYear(new Date())}
      className={classes.box}
      value={dates}
      onChange={setDates}
      multiple={true}
      weekStartDayIndex={1}
      calendar={gregorian}
      shadow={false}
      disableYearPicker={true}
      disableMonthPicker={true}
      hideYear={true}
      currentDate={new DateObject()}
      weekDays={weekDays}
      mapDays={({ date, selectedDate, isSameDate }) => {
        const props: any = {
          style: {
            fontFamily: '"Open Sans", sans-serif;',
            color: '#5B6578',
          },
        };
        if (Array.isArray(selectedDate) && selectedDate.some((el) => isSameDate(date, el))) {
          props.style.color = colorVariables.navy;
          props.style.backgroundColor = colorVariables.greenLight;
          props.style.fontWeight = 600;
          props.style.border = 'none';
          props.style.boxShadow = 'none';
        }
        if (isSameDate(date, new DateObject())) {
          props.style.color = 'white';
          props.style.backgroundColor = colorVariables.green;
        }

        return props;
      }}
    />
  );
};
