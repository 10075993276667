import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import clsx from 'clsx';
import { addDays, format } from 'date-fns';
import { FastField, Form, Formik, FormikHelpers } from 'formik';

import { useCreateRecOrderMutation } from '../../../../../../../api/recurring-order';
import { FrequencyEnum, RecOrderStatus } from '../../../../../../../api/recurring-order/types';
import SvgCalendar from '../../../../../../../assets/icons/Calendar';
import { ThemedButton } from '../../../../../../../shared/components/themed-button';
import { DatePickerComponent } from '../../../../../../../shared/components/date-picker';
import { FormikInput } from '../../../../../../../shared/components/formik-input';
import { OverlayModal } from '../../../../../../../shared/components/overlay-modal';
import { useScreenSize } from '../../../../../../../shared/hooks/use-screen-size';
import { useAppDispatch } from '../../../../../../../store';
import { hideCreateRecOrderModal } from '../../../../../../../store/recurring-order';
import { CustomSelect } from '../custom-select';

import { useStyles } from './style';

interface Props {
  isOpened: boolean;
  onClose: () => void;
}

interface Values {
  name: string;
  frequency: FrequencyEnum;
  status: RecOrderStatus;
  startDate: string;
}

export const CreateRecOrderModal: React.FC<Props> = ({ isOpened, onClose }) => {
  const classes = useStyles();
  const { isMobile } = useScreenSize();
  const { push } = useHistory();
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLFormElement | null>(null);

  const [createRecOrder, { isLoading, isSuccess, data }] = useCreateRecOrderMutation();

  const onSubmit = ({ startDate, ...values }: Values, formikHelpers: FormikHelpers<Values>) => {
    formikHelpers.setSubmitting(false);
    createRecOrder({ ...values, start_date: format(new Date(startDate), 'yyyy-MM-dd') });
  };

  const validateForm = (values: Values) => {
    const errors: Partial<Values> = {};
    if (!values.name) {
      errors.name = 'Required Field';
    }
    if (values.name.length > 50) {
      errors.name = 'Too long';
    }
    return errors;
  };

  useEffect(() => {
    if (isSuccess) {
      ref.current?.reset();
      dispatch(hideCreateRecOrderModal());
    }
    data &&
      push({
        pathname: '/pantry_list/recurring_orders/edit',
        state: {
          recOrderId: data.recurring_order.id,
        },
      });
  }, [isSuccess, data]);

  return (
    <OverlayModal onClose={onClose} isOpen={isOpened} boxClassName={classes.modalBox} animate={true}>
      <>
        <h3 className={classes.modalTitle}>Create Recurring Order</h3>
        <Formik
          enableReinitialize={true}
          onSubmit={onSubmit}
          validate={validateForm}
          initialValues={{
            name: '',
            frequency: FrequencyEnum.DAYS7,
            status: RecOrderStatus.ACTIVE,
            startDate: addDays(new Date(), 1).toDateString(),
          }}
        >
          {({ submitForm, values, setFieldValue }) => (
            <Form ref={ref}>
              <div className={classes.field}>
                <FastField component={FormikInput} name='name' label='Enter Recurring Order Name' placeholder='Recurring Order' />
              </div>
              <div className={clsx(classes.field, classes.fieldBox)}>
                <div>Frequency</div>
                <CustomSelect
                  options={Object.values(FrequencyEnum)}
                  defaultOption={values.frequency}
                  onSelect={(value) => setFieldValue('frequency', value)}
                />
              </div>
              <div className={clsx(classes.field, classes.fieldBox)}>
                <div>Status</div>
                <CustomSelect
                  options={Object.values(RecOrderStatus)}
                  defaultOption={values.status}
                  onSelect={(value) => setFieldValue('status', value)}
                />
              </div>
              <div className={clsx(classes.field, classes.fieldBox)}>
                <div className={classes.dateLabel}>Start delivery date</div>
                <DatePickerComponent
                  onDateChange={(date) => setFieldValue('startDate', date ? date.toString() : new Date().toString())}
                  selectedDate={new Date(values.startDate)}
                  minDate={addDays(new Date(), 1)}
                  trigger={
                    <div className={classes.calendarTrigger}>
                      <span>{format(new Date(values.startDate), 'dd/MM/yyyy')}</span>
                      <SvgCalendar className={classes.calendarIcon} />
                    </div>
                  }
                />
              </div>
              <div className={classes.btnBox}>
                <ThemedButton onClick={onClose} title='Cancel' buttonStyle='secondary' isSmall={isMobile} width={isMobile ? 105 : 180} />
                <ThemedButton
                  onClick={submitForm}
                  title='Next'
                  isSmall={isMobile}
                  width={isMobile ? 105 : 180}
                  disabled={!values.name || isLoading}
                />
              </div>
            </Form>
          )}
        </Formik>
      </>
    </OverlayModal>
  );
};
