import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { colorVariables } from '../../../../styles/colorVariables';

export interface StyleProps {
  textColor?: string;
  bgColor?: string;
}

export const useStyles = makeStyles<Theme, StyleProps>(() =>
  createStyles({
    container: {
      height: 60,
      position: 'fixed',
      zIndex: 900,
      bottom: -1,
      width: '100%',
      backgroundColor: ({ bgColor }) => bgColor || colorVariables.navy,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.1)',
      padding: '0 10px',
      '-webkit-transform': 'translate3d(0,0,0)',
    },
    customerContainer: {
      backgroundColor: ({ bgColor }) => bgColor || '#F2F3F7',
      boxShadow: '0 -1px 3px 0 rgba(0, 0, 0, 0.1)',
    },
    item: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-end',
      width: '18%',
      opacity: 0.7,
      padding: '0 4px 8px 4px',
      position: 'relative',
    },
    itemCustomer: {
      width: '24%',
      minWidth: 66,
    },
    menuIcon: {
      fontSize: 20,
      color: ({ textColor }) => textColor || colorVariables.grey60,
    },
    menuIconCustomer: {
      color: ({ textColor }) => textColor || colorVariables.darkGrey,
    },
    itemActive: {
      backgroundColor: ({ bgColor }) => bgColor || colorVariables.transparent,
      filter: ({ bgColor }) => (bgColor ? 'brightness(0.9)' : undefined),
      '& span': {
        color: ({ textColor }) => textColor || colorVariables.white,
      },
      '& svg': {
        color: ({ textColor }) => textColor || colorVariables.green,
      },
    },
    linkText: {
      fontSize: 9,
      lineHeight: '16px',
      marginTop: 4,
      color: ({ textColor }) => textColor || colorVariables.grey60,
    },
    linkTextCustomer: {
      color: ({ textColor }) => textColor || colorVariables.userGrey,
    },
    itemActiveCustomer: {
      backgroundColor: ({ bgColor }) => bgColor || colorVariables.transparent,
      filter: ({ bgColor }) => (bgColor ? 'brightness(0.9)' : undefined),
      '& span': {
        color: ({ textColor }) => textColor || colorVariables.userNavy,
      },
      '& svg': {
        color: ({ textColor }) => textColor || colorVariables.green,
      },
    },
    itemFavoritesActiveCustomer: {
      '& span': {
        color: ({ textColor }) => textColor || colorVariables.userNavy,
      },
    },
    searchIconBox: {
      width: '18%',
      position: 'relative',
      '&>div': {
        top: -12,
        boxShadow: '0 0 6px 3px rgba(0, 0, 0, 0.1)',
        position: 'absolute',
        transform: 'translateX(-50%)',
        left: '50%',
      },
    },
    starIcon: {
      fontSize: 26,
    },
    counter: {
      display: 'inline-block',
      position: 'absolute',
      top: 5,
      right: 5,
      width: 18,
      height: 18,
      backgroundColor: colorVariables.green,
      fontSize: 10,
      fontWeight: 700,
      lineHeight: '18px',
      borderRadius: '50%',
      textAlign: 'center',
      color: `${colorVariables.navy} !important`,
    },
    itemCustomerDisabled: {
      opacity: 0.5,
      pointerEvents: 'none',
    },
  }),
);
