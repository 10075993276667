import React, { Suspense, useEffect, useLayoutEffect, useRef } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';

import TagManager from 'react-gtm-module';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { SupplierType } from '../../api/admin-suppliers/types';
import { useSetCustomersDefaults } from '../../api/customer/hooks';
import { useAcceptCheckOffOrderMutation } from '../../api/order';
import { useSetOrdersDefaults } from '../../api/order/hooks';
import { useSetPantryListsDefaults } from '../../api/pantry-list/hooks';
import { useSetPriceExceptionsDefaults } from '../../api/price-exception/hooks';
import { useSetProductsDefaults } from '../../api/product/hooks';
import { useResetRecOrdersSearch } from '../../api/recurring-order/hooks';
import { useSetSuppliersDefaults } from '../../api/supplier/hooks';
import { useGetCategoriesQuery, useMeQuery, useSetPwaInstalledMutation, useVerifySupplierMutation } from '../../api/user';
import { SubscriptionPlanEnum } from '../../api/user/types';
import { Loader } from '../../shared/components/loader';
import { SuccessModal } from '../../shared/components/success-modal';
import { useAIChat } from '../../shared/hooks/use-ai-chat';
import { useSetKeywordToDatalayer } from '../../shared/hooks/use-set-keyword-to-datalayer';
import { useWSConnection } from '../../shared/hooks/use-ws-connection';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { SessionStorageService } from '../../shared/services/sessionStorage.service';
import { ToastService } from '../../shared/services/toastService';
import { TokenService } from '../../shared/services/token.service';
import { useAppDispatch, useAppSelector } from '../../store';
import { setRestaurantsKeyword, setRestaurantsPage, setRestaurantsSort } from '../../store/admin/restaurants';
import { setASuppliersKeyword, setASuppliersPage, setASuppliersSort } from '../../store/admin/suppliers';
import { setUsersKeyword, setUsersPage, setUsersSortFilters } from '../../store/admin/users';
import {
  getAppError,
  getAppSuccess,
  getImpersonatedCustomer,
  getIsRestaurant,
  getMyId,
  logoutThunk,
  setAppErrorToast,
  setAppSuccessToast,
} from '../../store/user';
import AccountDrawer from '../AccountDrawer';
import { Referral } from '../AccountDrawer/Referral';
import { Privacy } from '../AppDrawer/Privacy';
import { Terms } from '../AppDrawer/Terms';
import { AuthDrawer } from '../AuthDrawer';
import { useCurrentTheme } from '../../api/admin-themes/hooks';
import { getAppToastTitle } from '../../store/user/user.selectors';

const AdminDrawer = React.lazy(() => import('../AdminDrawer'));
const RestaurantDrawer = React.lazy(() => import('../AppDrawer/RestaurantDrawer'));
const SupplierDrawer = React.lazy(() => import('../AppDrawer/SupplierDrawer'));
const OnBoarding = React.lazy(() => import('../AccountDrawer/Onboarding'));
const OnBoardDemo = React.lazy(() => import('../AuthDrawer/OnBoardDemo'));
const ChangeEmail = React.lazy(() => import('../AccountDrawer/ChangeEmail'));
const Subscribe = React.lazy(() => import('../AuthDrawer/Subscribe'));
const ActivateSubscription = React.lazy(() => import('../AuthDrawer/ActivateSubscription'));
const SubscribeProSuiteInAppThanks = React.lazy(() => import('../AuthDrawer/SubscribeProSuiteInAppThanks'));
const SubscribeMenuInAppThanks = React.lazy(() => import('../AuthDrawer/SubscribeMenuInAppThanks'));
const AlternateEmail = React.lazy(() => import('../AccountDrawer/AlternateEmail'));
const UnsubscribePage = React.lazy(() => import('../AccountDrawer/UnsubscribePage'));

export const Routes: React.FC = () => {
  const { replace } = useHistory();
  const { pathname, search } = useLocation();
  const rootRef = useRef<HTMLDivElement>(null);
  const isToken = !!TokenService.getToken();
  const dispatch = useAppDispatch();
  const deferredPromptRef = useRef<any>();
  const subscribe = search.split('subscribe=')[1];
  const { name } = useCurrentTheme();

  useSetKeywordToDatalayer();
  useWSConnection();
  useAIChat();
  useGetCategoriesQuery(undefined, {
    skip: !isToken || Array.isArray(SessionStorageService.getItem('categories')),
  });

  const {
    isSuccess,
    isLoading,
    data: user,
  } = useMeQuery(undefined, {
    skip: !isToken,
  });

  const isRestaurant = useAppSelector(getIsRestaurant);
  const userId = useAppSelector(getMyId);
  const error = useAppSelector(getAppError);
  const appToastTitle = useAppSelector(getAppToastTitle);
  const successToastText = useAppSelector(getAppSuccess);
  const impersonatedCustomer = useAppSelector(getImpersonatedCustomer);
  const [verifySupplier, { isLoading: verifyLoading }] = useVerifySupplierMutation();
  const [setPWAInstalled] = useSetPwaInstalledMutation();
  const [acceptCheckOff] = useAcceptCheckOffOrderMutation();

  const menuOnboardingPending = !!(
    user &&
    'menu_costing_onboarding' in user &&
    Array.isArray(user.menu_costing_onboarding) &&
    user.menu_costing_onboarding.length > 0 &&
    user?.sign_up_status === 'menu_costing' &&
    user?.subscription_type === SubscriptionPlanEnum.MENU
  );

  useSetProductsDefaults();
  useSetCustomersDefaults();
  useSetPriceExceptionsDefaults();
  useSetOrdersDefaults();
  useSetPantryListsDefaults();
  useResetRecOrdersSearch();
  useSetSuppliersDefaults();

  const onPromptPwa = () => {
    if (SessionStorageService.getItem('prompt_pwa') && userId && deferredPromptRef.current) {
      deferredPromptRef.current.prompt();
      SessionStorageService.clear('prompt_pwa');
    }
  };

  useEffect(() => {
    if (userId && !user?.on_boarded && !user?.is_admin) {
      replace('/onboard');
      LocalStorageService.clear('reg_email');
      return;
    }
    if (SessionStorageService.getItem('subscribe_login') === 'menu_costing' && userId) {
      SessionStorageService.clear('subscribe_login');
      if (isRestaurant && !user?.is_admin) {
        replace('/menu_subscribe_in_app');
        return;
      }
    }
    if (
      userId &&
      (user?.on_boarded || !!user?.is_admin) &&
      (pathname === '/login' ||
        pathname === '/procal-signin' ||
        pathname === '/onboard' ||
        pathname === '/reset_password' ||
        pathname === '/claim' ||
        pathname === '/signup' ||
        pathname === '/change-email' ||
        pathname.includes('/alternative_email'))
    ) {
      if (SessionStorageService.getItem('usersState')?.adminId === userId) {
        const usersState = SessionStorageService.getItem('usersState')?.state;
        dispatch(setUsersSortFilters({ sort: usersState?.sortBy, filter: usersState?.filterBy }));
        dispatch(setUsersKeyword(usersState?.keyword));
        dispatch(setUsersPage(usersState?.currentPage || 1));
        replace('/users');
        SessionStorageService.clear('usersState');
        return;
      }
      if (SessionStorageService.getItem('suppliersState')?.adminId === userId) {
        const suppliersState = SessionStorageService.getItem('suppliersState')?.state;
        const supplierType = SessionStorageService.getItem('suppliersState')?.type;
        dispatch(setASuppliersSort({ sort: suppliersState?.sortBy }));
        dispatch(setASuppliersKeyword(suppliersState?.keyword));
        dispatch(setASuppliersPage(suppliersState?.currentPage || 1));
        replace(supplierType === SupplierType.SELF_MANAGED ? '/suppliers/self-managed' : '/suppliers');
        SessionStorageService.clear('suppliersState');
        return;
      }
      if (SessionStorageService.getItem('restaurantsState')?.adminId === userId) {
        const restaurantsState = SessionStorageService.getItem('restaurantsState')?.state;
        dispatch(setRestaurantsSort({ sort: restaurantsState?.sortBy }));
        dispatch(setRestaurantsKeyword(restaurantsState?.keyword));
        dispatch(setRestaurantsPage(restaurantsState?.currentPage || 1));
        replace('/restaurants');
        SessionStorageService.clear('restaurantsState');
        return;
      }
      if (!!user?.is_admin) {
        SessionStorageService.clear('restaurantsState');
        SessionStorageService.clear('suppliersState');
        SessionStorageService.clear('usersState');
      }
      const redirectPath = !!user?.is_admin
        ? '/dashboard'
        : !isRestaurant
        ? user.supplier_blocked && !user.verified
          ? '/onboard-demo'
          : '/dashboard'
        : isRestaurant && menuOnboardingPending
        ? '/menu_costing'
        : isRestaurant
        ? '/browse_by_supplier'
        : '/';
      const refSupplierId = LocalStorageService.getItem('referral_supplier');
      const refRestaurantId = LocalStorageService.getItem('referral_restaurant');
      const alternateEmailData = SessionStorageService.getItem('alternate_email_data');
      if (refSupplierId && +refSupplierId === userId) {
        replace(`/referral_supplier?user_id=${refSupplierId}`);
        return;
      }
      if (refRestaurantId && +refRestaurantId === userId) {
        replace(`/referral_restaurant?user_id=${refRestaurantId}`);
        return;
      }
      if (alternateEmailData && +alternateEmailData?.user_id === userId) {
        replace(
          `/users/${alternateEmailData.user_id}/alternative_email?connection_id=${alternateEmailData.connection_id}&password=${alternateEmailData.password}`,
        );
        return;
      }
      if (SessionStorageService.getItem('open_settings')) {
        replace('/account/notifications');
        SessionStorageService.clear('open_settings');
        return;
      }
      if (SessionStorageService.getItem('accept_check_off_for')) {
        acceptCheckOff({
          accept_request: true,
          order_id: parseFloat(SessionStorageService.getItem('accept_check_off_for')),
        }).then(() => {
          SessionStorageService.clear('accept_check_off_for');
          replace('/orders');
        });
        return;
      }
      replace(redirectPath);
    }
  }, [!!user?.on_boarded, !!userId]);

  useEffect(() => {
    if (rootRef?.current && !pathname.includes('/menu_costing/recipe')) {
      rootRef.current.scrollIntoView();
    }
    if (pathname.includes('/install-pwa')) {
      SessionStorageService.setItem('prompt_pwa', true);
      replace('/');
    }
  }, [pathname]);

  useEffect(() => {
    const supplierId = search.split('supplier_id=')[1];
    const refUserId = search.split('user_id=')[1];
    const order_id = parseFloat(search.split('order_id=')[1]);
    const accept_request = search.split('accept_request=')[1];
    if (pathname.includes('/referral_supplier') && !userId && refUserId) {
      LocalStorageService.setItem('referral_supplier', refUserId);
    }
    if (pathname.includes('/referral_restaurant') && !userId && refUserId) {
      LocalStorageService.setItem('referral_restaurant', refUserId);
    }
    if (pathname.includes('/verify-supplier') && supplierId) {
      userId && !verifyLoading ? verifySupplier({ supplier_id: +supplierId }) : LocalStorageService.setItem('verify_supplier', +supplierId);
      replace(userId ? '/' : '/login');
      return;
    }
    if (pathname.includes('/accept_or_declined') && order_id) {
      TokenService.getToken()
        ? acceptCheckOff({ accept_request: accept_request === 'true', order_id })
        : SessionStorageService.setItem('accept_check_off_for', order_id);
      replace(TokenService.getToken() ? '/orders' : '/login');
      return;
    }
    if (userId && LocalStorageService.getItem('verify_supplier') && !verifyLoading) {
      verifySupplier({ supplier_id: LocalStorageService.getItem('verify_supplier') });
    }
  }, [pathname, isRestaurant, userId]);

  useEffect(() => {
    if (error) {
      ToastService.error(error);
      dispatch(setAppErrorToast(null));
    }
  }, [error]);

  useEffect(() => {
    if (successToastText) {
      if(appToastTitle) {
        ToastService.success(successToastText, undefined, appToastTitle);
      } else {
        ToastService.success(successToastText);
      }
      dispatch(setAppSuccessToast(null));
    }
  }, [successToastText]);

  useEffect(() => {
    if (user?.company?.company_type) {
      TagManager.dataLayer({
        dataLayer: {
          userType: user?.company?.company_type,
          userId: user.id,
        },
      });
    }
  }, [user?.id, pathname]);

  useEffect(() => {
    !isToken && dispatch(logoutThunk());
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      deferredPromptRef.current = e;
    });
    window.addEventListener('appinstalled', (e) => {
      isToken && setPWAInstalled();
    });

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then((registration) => {
          console.log('Service worker registered:', registration);
        })
        .catch((err) => {
          console.error('Service worker error:', err);
        });
    }

    if (!('Notification' in window)) {
      console.log('This browser does not support system notifications!');
    } else if (Notification.permission === 'granted') {
      console.log('granted');
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission();
    }
  }, []);

  useEffect(() => {
    subscribe && SessionStorageService.setItem('subscribe_login', subscribe);
  }, [subscribe]);

  useLayoutEffect(() => {
    if (!pathname.includes('/login') && !pathname.includes('/signup')) {
      document.title = name === undefined ? ' ' : name || 'Open Pantry';
    }
  }, [name, pathname]);

  return (
    <div ref={rootRef} id='root_scroll_box' onClick={onPromptPwa}>
      <Switch>
        <Route path='/terms' component={Terms} />
        <Route path='/privacy' component={Privacy} />
        {pathname.endsWith('/change-email') && (
          <Suspense fallback={<Loader />}>
            <Route path='/change-email' component={ChangeEmail} />
          </Suspense>
        )}
        <Route path='/install-pwa' component={() => null} />
        {pathname.endsWith('/menu_subscribe') && (
          <Suspense fallback={<Loader />}>
            <Route path='/menu_subscribe'>
              <Subscribe type='menu_costing' />
            </Route>
          </Suspense>
        )}
        {pathname.endsWith('/activate_subscription') && (
          <Suspense fallback={<Loader />}>
            <Route path='/activate_subscription' component={ActivateSubscription} />
          </Suspense>
        )}
        {/*       {pathname.endsWith('/mcfree') && (
          <Suspense fallback={<Loader />}>
            <Route path='/mcfree' component={McFree} />
          </Suspense>
        )}*/}
        {pathname.endsWith('/pro_suite_subscribe') && (
          <Suspense fallback={<Loader />}>
            <Route path='/pro_suite_subscribe'>
              <Subscribe type='restaurent_pro_suite' />
            </Route>
          </Suspense>
        )}
        {pathname.endsWith('/pro_suite_subscribe_thanks') && (
          <Suspense fallback={<Loader />}>
            <Route path='/pro_suite_subscribe_thanks' component={SubscribeProSuiteInAppThanks} />
          </Suspense>
        )}
        {pathname.endsWith('/menu_subscribe_thanks') && (
          <Suspense fallback={<Loader />}>
            <Route path='/menu_subscribe_thanks' component={SubscribeMenuInAppThanks} />
          </Suspense>
        )}
        {!user?.is_admin && pathname.endsWith('/alternative_email') && (
          <Suspense fallback={<Loader />}>
            <Route path='/users/:id/alternative_email' component={AlternateEmail} />
          </Suspense>
        )}
        {pathname.endsWith('/unsubscribe') && (
          <Suspense fallback={<Loader />}>
            <Route path='/unsubscribe' component={UnsubscribePage} />
          </Suspense>
        )}
        {(!isToken || (!isSuccess && !isLoading)) && <Route path='/' component={AuthDrawer} />}
        {isSuccess && userId && (
          <>
            {!user?.is_admin && (
              <Suspense fallback={<Loader />}>
                <Route path='/onboard'>
                  <OnBoarding deferredPromptRef={deferredPromptRef} />
                </Route>
              </Suspense>
            )}
            <Suspense fallback={<Loader />}>
              <Route path='/onboard-demo'>
                {user?.supplier_blocked && !user?.verified ? <OnBoardDemo /> : <Redirect to='/dashboard' />}
              </Route>
            </Suspense>
            <Route path='/'>
              {!user?.on_boarded && !user?.is_admin ? (
                <Redirect to='/onboard' />
              ) : user?.is_admin ? (
                <Suspense fallback={<Loader />}>
                  <Route path='/' component={AdminDrawer} />
                </Suspense>
              ) : user?.supplier_blocked && !user?.verified ? (
                <Redirect to='/onboard-demo' />
              ) : (
                <Switch>
                  {!impersonatedCustomer && <Route path='/account' component={AccountDrawer} />}
                  <Route path='/referral_restaurant' component={Referral} />
                  <Route path='/referral_supplier' component={Referral} />
                  {isRestaurant ? (
                    <Suspense fallback={<Loader />}>
                      <Route path='/' component={RestaurantDrawer} />
                    </Suspense>
                  ) : (
                    <Suspense fallback={<Loader />}>
                      <Route path='/' component={SupplierDrawer} />
                    </Suspense>
                  )}
                </Switch>
              )}
            </Route>
          </>
        )}
        <Route path='/' component={Loader} />
      </Switch>
      <SuccessModal />
      <ToastContainer
        transition={Slide}
        limit={1}
        position='top-right'
        autoClose={3000}
        hideProgressBar={true}
        closeOnClick={true}
        rtl={false}
      />
    </div>
  );
};
