import React, { useCallback, useEffect, useRef, useState } from 'react';

import { IconButton } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { AddressFormValues } from '../../../components/AccountDrawer/VenueInfo/address-form';
import { getAddressFromCoordinates, parseAddressString } from '../../helpers/getCountry';
import { LocalStorageService } from '../../services/localStorage.service';
import { ToastService } from '../../services/toastService';
import { ThemedButton } from '../themed-button';
import { FormikInput } from '../formik-input';
import { OverlayModal } from '../overlay-modal';

import { useStyles } from './style';

interface Props {
  lat?: number;
  lng?: number;
  markerTitle?: string;
  isOpen: boolean;
  onClose: () => void;
  address?: string;
  setData: (args: Partial<AddressFormValues>) => void;
  setLocationData: (loc: { lat: number; lng: number }) => void;
  hideInput?: boolean;
}

export const LocationModal: React.FC<Props> = ({
  lat,
  lng,
  markerTitle,
  isOpen,
  onClose,
  address,
  setData,
  setLocationData,
  hideInput,
}) => {
  const classes = useStyles();
  const [location, setLocation] = useState<{ lat: number; lng: number } | null>(null);
  const markerRef = useRef<any>(null);
  const inputWrapRef = useRef<any>(null);
  const [locationValid, setLocationValid] = useState(true);
  const [addressData, setAddressData] = useState<Partial<AddressFormValues>>({});

  const onDragEnd = async (
    e: google.maps.MapMouseEvent,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  ) => {
    try {
      const markerLat = markerRef.current.position.lat();
      const markerLng = markerRef.current.position.lng();
      if (markerLat && markerLng) {
        const results = await getAddressFromCoordinates(markerLat, markerLng);
        setLocation({ lat: markerLat, lng: markerLng });
        const code = results?.find((el: any) => el.types.includes('country'))?.address_components[0]?.short_name;
        if (code && LocalStorageService.getItem('country') !== code.toUpperCase()) {
          ToastService.error('Address outside your country can`t be selected');
          setLocationValid(false);
          lat && lng && setLocation({ lat, lng });
          setFieldValue('address', '');
          return;
        }
        if (!results) {
          ToastService.error('Location unavailable');
          lat && lng && setLocation({ lat, lng });
          setLocationValid(false);
          return;
        }
        const streetAddress = results?.find((el: any) => el.types.includes('street_address'));
        if (streetAddress) {
          setFieldValue('address', streetAddress?.formatted_address || '');
          if (Array.isArray(streetAddress?.address_components)) {
            const state = streetAddress.address_components?.find((item: any) =>
              item.types.includes('administrative_area_level_1'),
            )?.long_name;
            const city = streetAddress.address_components?.find((item: any) => item.types.includes('locality'))?.long_name;
            const postcode = streetAddress.address_components?.find((item: any) => item.types.includes('postal_code'))?.long_name;
            const street_number = streetAddress.address_components?.find((item: any) => item.types.includes('street_number'))?.long_name;
            const route = streetAddress.address_components?.find((item: any) => item.types.includes('route'))?.long_name;
            setAddressData({
              state,
              postcode,
              full_address: streetAddress?.formatted_address,
              city,
              street_address1: `${street_number || ''} ${route || ''}`.trim(),
            });
          }
        } else {
          if (results[0] && results[0]?.geometry?.location) {
            setLocationValid(true);
            setLocation({ lat: results[0]?.geometry?.location.latitude, lng: results[0]?.geometry?.location.longitude });
          }
          ToastService.warning('Selected location doesn`t have a street address. Please enter your address details');
        }
        setLocation({ lat: markerLat, lng: markerLng });
        setLocationValid(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit = async (values: { address?: string }, formikHelpers: FormikHelpers<any>) => {
    formikHelpers.setSubmitting(false);
    try {
      if (values.address) {
        const addressRes = await parseAddressString(values.address);
        if (addressRes && Array.isArray(addressRes) && addressRes.length) {
          setLocation(addressRes[0]?.geometry.location);
          setLocationValid(true);
          if (Array.isArray(addressRes[0]?.address_components)) {
            const state = addressRes[0].address_components?.find((item: any) =>
              item.types.includes('administrative_area_level_1'),
            )?.long_name;
            const city = addressRes[0].address_components?.find((item: any) => item.types.includes('locality'))?.long_name;
            const postcode = addressRes[0].address_components?.find((item: any) => item.types.includes('postal_code'))?.long_name;
            const street_number = addressRes[0].address_components?.find((item: any) => item.types.includes('street_number'))?.long_name;
            const route = addressRes[0].address_components?.find((item: any) => item.types.includes('route'))?.long_name;
            setAddressData({
              state,
              postcode,
              full_address: addressRes[0]?.formatted_address,
              city,
              street_address1: `${street_number} ${route}`.trim(),
            });
          }
        } else {
          setAddressData((prev) => ({ ...prev, full_address: values.address, street_address1: values.address }));
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onMarkerLoad = useCallback(
    (marker: google.maps.Marker) => {
      markerRef.current = marker;
    },
    [onDragEnd],
  );

  const onSaveAddress = async (currentValue?: string) => {
    try {
      if (currentValue?.trim()) {
        const addressRes = await parseAddressString(currentValue);
        if (addressRes && Array.isArray(addressRes) && addressRes.length) {
          if (Array.isArray(addressRes[0]?.address_components)) {
            const state = addressRes[0].address_components?.find((item: any) =>
              item.types.includes('administrative_area_level_1'),
            )?.long_name;
            const city = addressRes[0].address_components?.find((item: any) => item.types.includes('locality'))?.long_name;
            const postcode = addressRes[0].address_components?.find((item: any) => item.types.includes('postal_code'))?.long_name;
            const street_number = addressRes[0].address_components?.find((item: any) => item.types.includes('street_number'))?.long_name;
            const route = addressRes[0].address_components?.find((item: any) => item.types.includes('route'))?.long_name;
            setData({
              state,
              postcode,
              full_address: addressRes[0]?.formatted_address,
              city,
              street_address1: `${street_number || ''} ${route || ''}`.trim(),
            });
          }
        } else {
          setData({ ...addressData, full_address: currentValue, street_address1: currentValue });
        }
      } else {
        setData(addressData);
      }
    } catch (e) {
      console.log(e);
    }
    location && setLocationData(location);
    onClose();
  };

  useEffect(() => {
    lat && lng && setLocation({ lat, lng });
  }, [lat, lng]);

  return (
    <OverlayModal isOpen={isOpen} onClose={onClose} boxClassName={classes.box}>
      <div className={classes.title}>Please drag the marker to select your location</div>
      <Formik initialValues={{ address }} onSubmit={onSubmit} enableReinitialize={true}>
        {({ submitForm, setFieldValue, values }) => (
          <Form>
            {hideInput ? null : (
              <div className={classes.searchWrap}>
                <div
                  className={classes.fieldWrap}
                  ref={inputWrapRef}
                  onKeyDown={(e) => {
                    alert(JSON.stringify(e));
                  }}
                >
                  <Field name='address'>
                    {(fieldProps: FieldProps) => (
                      <FormikInput {...fieldProps} label='Search for Address' placeholder='Enter your Address' autoComplete='chrome-off' />
                    )}
                  </Field>
                </div>
                <IconButton onClick={submitForm}>
                  <Search />
                </IconButton>
              </div>
            )}
            {location && (
              <GoogleMap mapContainerClassName={classes.container} center={location} zoom={8}>
                <Marker
                  position={location}
                  title={markerTitle}
                  draggable={true}
                  onLoad={onMarkerLoad}
                  onDragEnd={(e) => onDragEnd(e, setFieldValue)}
                />
              </GoogleMap>
            )}
            <div className={classes.btnsBlock}>
              <ThemedButton onClick={onClose} title='Cancel' buttonStyle={'secondary'} />
              <ThemedButton onClick={onSaveAddress.bind(null, values.address)} title='Apply' disabled={!locationValid} />
            </div>
          </Form>
        )}
      </Formik>
    </OverlayModal>
  );
};
