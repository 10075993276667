import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../../styles/colorVariables';
import { Sizes } from '../../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    supplierInfo: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    supplierInfoModal: {
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: '0 20px',
      },
    },
    supplierDetailsBox: {
      flex: 1,
    },
    avatarWrap: {
      width: 47,
      height: 47,
      borderRadius: '50%',
      overflow: 'hidden',
      flexShrink: 0,
      marginRight: 15,
      boxShadow: `0 0 0 1px ${colorVariables.steelGrey}`,
    },
    avatarWrapModal: {
      marginBottom: 40,
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 49,
        height: 49,
      },
    },
    avatarWrapBig: {
      width: 67,
      height: 67,
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 57,
        height: 57,
      },
    },
    supplierName: {
      flexGrow: 1,
      fontSize: 16,
      lineHeight: '20px',
      fontWeight: 600,
      marginBottom: 3,
    },
    supplierAddress: {
      fontSize: 13,
      lineHeight: '22px',
      color: colorVariables.grey60,
    },
    supplierAddressInModal: {
      marginBottom: 12,
    },
    dropdownContainer: {
      width: 450,
      padding: 20,
      position: 'relative',
      borderRadius: 10,
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        paddingBottom: 10,
      },
    },
    closeBtn: {
      position: 'absolute',
      top: -10,
      right: -10,
      '& svg': {
        color: colorVariables.grey60,
      },
      [theme.breakpoints.down(Sizes.desktop)]: {
        display: 'none',
      },
    },
    dropDownSubTitle: {
      marginTop: 20,
      fontWeight: 700,
      marginBottom: 15,
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginBottom: 10,
        marginTop: 15,
        padding: '0 20px',
      },
    },
    dropDownTitle: {
      fontSize: 18,
      fontWeight: 700,
      marginBottom: 30,
      [theme.breakpoints.down(Sizes.desktop)]: {
        marginBottom: 28,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginBottom: 15,
        padding: '0 20px',
      },
    },
    categoriesBox: {
      marginBottom: 20,
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      [theme.breakpoints.down(Sizes.mobile)]: {
        height: 'calc(90vh - 260px)',
        backgroundColor: 'rgba(152, 165, 183, 0.15)',
        overflowY: 'scroll',
        marginBottom: 15,
      },
    },
    categoriesBoxExtraHeight: {
      [theme.breakpoints.down(Sizes.mobile)]: {
        height: 'calc(90vh - 230px)',
      },
    },
    categoriesBoxManual: {
      [theme.breakpoints.down(Sizes.mobile)]: {
        height: 'calc(90vh - 330px)',
      },
    },
    categoryItem: {
      width: '48%',
      position: 'relative',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        padding: '0 20px 0 10px',
      },
    },
    btnBox: {
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        justifyContent: 'center',
      },
    },
    acceptedText: {
      height: 34,
      lineHeight: '34px',
      color: colorVariables.green,
      fontWeight: 600,
      width: 140,
      textAlign: 'center',
    },
    requestedText: {
      height: 34,
      lineHeight: '34px',
      color: colorVariables.grey60,
      fontWeight: 600,
      width: 140,
      textAlign: 'center',
    },
    dropdownModal: {
      width: 475,
      padding: 27,
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '96vw',
        height: '90vh',
        padding: '20px 0 5px 0',
        overflowY: 'hidden !important',
      },
    },
    dropdownModalContent: {
      [theme.breakpoints.down(Sizes.desktop)]: {
        maxHeight: '100%',
        maxWidth: '100%',
        overflowY: 'hidden',
        overflowX: 'hidden',
      },
    },
    label: {
      display: 'inline-block',
      width: 95,
      height: 30,
      textAlign: 'center',
      lineHeight: '30px',
      borderRadius: 15,
      fontWeight: 700,
      fontSize: 13,
    },
    connectedLabel: {
      backgroundColor: colorVariables.greenLight,
      color: colorVariables.green,
    },
    requestedLabel: {
      backgroundColor: colorVariables.grey20,
      color: colorVariables.grey60,
    },
    mobileCheckbox: {
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
    tooltipWrap: {
      position: 'absolute',
      width: '100%',
      zIndex: 2,
      left: 0,
      top: 5,
      [theme.breakpoints.down(Sizes.mobile)]: {
        position: 'absolute',
        right: 48,
        top: 55,
        left: 'auto',
        width: 280,
      },
    },
    dialog: {
      position: 'absolute',
      bottom: 5,
    },
    arrow: {
      color: 'rgba(70, 82, 116, 1)',
      fontSize: 32,
    },
    tooltipText: {
      fontSize: 14,
      color: colorVariables.white,
      paddingTop: 5,
      marginBottom: 20,
      lineHeight: '22px',
      fontWeight: 400,
    },
    rootTooltip: {
      backgroundColor: colorVariables.transparent,
      padding: 0,
    },
    closeTBtn: {
      width: 15,
      height: 15,
      position: 'absolute',
      top: 5,
      right: -40,
      '& svg': {
        color: colorVariables.white,
        fontSize: 16,
      },
    },
    tooltip: {
      width: 350,
      minHeight: 120,
      backgroundColor: 'rgba(52, 54, 90, 0.9)',
      boxShadow: '0 0 0 1px rgba(70, 82, 116, 1)',
      borderRadius: 10,
      padding: 20,
    },
    redBtn: {
      backgroundColor: '#EA6363',
      '&:hover': {
        backgroundColor: '#EA6363',
      },
    },
    tooltipTitle: {
      color: '#EA6363',
      fontWeight: 600,
      fontSize: 16,
      marginBottom: 7,
    },
    manualSupplierWarning: {
      fontSize: 12,
      backgroundColor: colorVariables.blueLight,
      borderRadius: 12,
      padding: 15,
      marginBottom: 20,
      marginTop: -10,
    },
  }),
);
