import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { FastField, Form, Formik, FormikHelpers } from 'formik';

import { useAddProductsToPantryListMutation, useCreatePantryListMutation } from '../../../../../../../api/pantry-list';
import { ThemedButton } from '../../../../../../../shared/components/themed-button';
import { FormikInput } from '../../../../../../../shared/components/formik-input';
import { OverlayModal } from '../../../../../../../shared/components/overlay-modal';
import { useScreenSize } from '../../../../../../../shared/hooks/use-screen-size';
import { useAppDispatch } from '../../../../../../../store';
import { hideCreatePantryListModal } from '../../../../../../../store/pantry-lists';
import { setAppSuccessToast } from '../../../../../../../store/user';

import { useStyles } from './style';

interface Props {
  isOpened: boolean;
  onClose: () => void;
  productIds?: number[];
}

interface Values {
  name: string;
}

export const CreatePantryListModal: React.FC<Props> = ({ isOpened, onClose, productIds }) => {
  const classes = useStyles();
  const { isMobile } = useScreenSize();
  const { push } = useHistory();
  const dispatch = useAppDispatch();

  const ref = useRef<HTMLFormElement | null>(null);

  const [createPantryList, { isSuccess, data }] = useCreatePantryListMutation();
  const [addProducts] = useAddProductsToPantryListMutation();

  const onSubmit = (values: Values, formikHelpers: FormikHelpers<Values>) => {
    formikHelpers.setSubmitting(false);
    createPantryList({ name: values.name, skipInvalidation: !!productIds });
  };

  const validateForm = (values: Values) => {
    const errors: Partial<Values> = {};
    if (!values.name) {
      errors.name = 'Required Field';
    }
    if (values.name.length > 20) {
      errors.name = 'Too long';
    }
    return errors;
  };

  useEffect(() => {
    !isOpened && ref.current?.reset();
  }, [isOpened]);

  useEffect(() => {
    if (productIds) {
      data &&
        productIds &&
        addProducts({
          id: data.pantry_list.id,
          product_ids: productIds.join(','),
        }).then(() => {
          dispatch(hideCreatePantryListModal());
          dispatch(setAppSuccessToast('Pantry List Saved'));
        });
    } else {
      data &&
        push({
          pathname: '/pantry_list/create',
          state: {
            pantryListId: data.pantry_list.id,
          },
        });
      isSuccess && dispatch(hideCreatePantryListModal());
    }
  }, [isSuccess, data, !!productIds]);

  return (
    <OverlayModal onClose={onClose} isOpen={isOpened} boxClassName={classes.modalBox} animate={true}>
      <>
        <h3 className={classes.modalTitle}>Create Pantry List</h3>
        <Formik
          onSubmit={onSubmit}
          validate={validateForm}
          initialValues={{
            name: '',
          }}
        >
          {({ submitForm, values }) => (
            <Form ref={ref}>
              <div className={classes.field}>
                <FastField component={FormikInput} name='name' label='Enter Pantry List Name' placeholder='Pantry List' />
              </div>
              <div className={classes.btnBox}>
                <ThemedButton onClick={onClose} title='Cancel' buttonStyle='secondary' isSmall={isMobile} width={isMobile ? 105 : 180} />
                <ThemedButton onClick={submitForm} title='Next' isSmall={isMobile} width={isMobile ? 105 : 180} disabled={!values.name} />
              </div>
            </Form>
          )}
        </Formik>
      </>
    </OverlayModal>
  );
};
