import { LocalStorageService } from '../../shared/services/localStorage.service';
import { TokenService } from '../../shared/services/token.service';
import store from '../../store';
import { setImportProductsLoading } from '../../store/products';
import { setAppSuccessToast } from '../../store/user';
import { PlaceBySupplierCartReq } from '../cart/types';
import { getManualProductQueryParamsHelper, getProductQueryParamsHelper, setSuccessToast } from '../helpers';
import { HTTP, rootApi, SuccessResponse } from '../index';
import {
  ExportManualSupplierProductsResponse,
  ExportProductsResponse,
  FavouriteResponse,
  GetProductByCategoryResponse,
  GetProductRequestParams,
  GetProductsSuggestionsRequest,
  ImportProductStatus,
  PreviewManualProductsResponse,
  PreviewProductsResponse,
  Product,
  ProductListResponse,
  SupplierSubCategoryResponse,
  UpdateProductRequest,
  UpdateProductRequestJson,
  UpdateProductResponse,
} from './types';

export const productsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query<ProductListResponse, GetProductRequestParams | void>({
      query: (args) => {
        const userId = TokenService.getUserId();
        const params = {
          ...getProductQueryParamsHelper(args?.sort, args?.filter),
          per_page: 20,
          page: args?.currentPage || 1,
        };
        const searchParams = args?.keyword ? { 'q[keyword]': args.keyword } : {};
        return {
          url: `/users/${userId}/products`,
          method: HTTP.GET,
          params: { ...params, ...searchParams },
        };
      },
      keepUnusedDataFor: 0,
      providesTags: (result) => {
        return result
          ? [
              ...result.data.products.map(({ id }) => ({ type: 'Product' as const, id })),
              {
                type: 'Product',
                id: 'LIST',
              },
            ]
          : [{ type: 'Product', id: 'LIST' }];
      },
    }),

    getManualProducts: builder.query<ProductListResponse, GetProductRequestParams | void>({
      query: (args) => {
        const params = {
          ...getManualProductQueryParamsHelper(args?.sort),
          per_page: 20,
          page: args?.currentPage || 1,
        };
        const searchParams = args?.keyword ? { 'q[keyword]': args.keyword } : {};
        return {
          url: `/users/${args?.filter?.supplier_id}/products`,
          method: HTTP.GET,
          params: { ...params, ...searchParams },
        };
      },
      keepUnusedDataFor: 0,
      providesTags: ['Manual_Product'],
    }),

    exportManualSupplierProducts: builder.mutation<ExportManualSupplierProductsResponse, number>({
      query: (supplier_id) => {
        return {
          url: `/export_manual_csv`,
          method: HTTP.GET,
          params: {
            supplier_id,
          },
        };
      },
    }),

    getProductsByCategory: builder.query<GetProductByCategoryResponse, GetProductRequestParams | void>({
      query: (args) => {
        const filterParams = args?.filter ? Object.fromEntries(Object.entries(args.filter).filter(([_, value]) => !!value)) : {};
        const sortParams =
          args?.sort && Array.isArray(args.sort) && args.sort.length > 1
            ? { order: args?.sort?.join(' ') }
            : { order: 'order_items_count desc' };
        const searchParams = args?.keyword ? { keyword: args?.keyword } : {};
        const paginationParams = args?.currentPage ? { page: args.currentPage, per_page: 20 } : { per_page: 20 };
        return {
          url: `/products_by_categories`,
          method: HTTP.GET,
          params: { ...sortParams, ...filterParams, ...searchParams, ...paginationParams },
        };
      },
      keepUnusedDataFor: 0,
    }),

    getProductsBySupplier: builder.query<GetProductByCategoryResponse, GetProductRequestParams | void>({
      query: (args) => {
        const filterParams = args?.filter ? Object.fromEntries(Object.entries(args.filter).filter(([_, value]) => !!value)) : {};
        const sortParams =
          args?.sort && Array.isArray(args.sort) && args.sort.length > 1
            ? { order: args?.sort?.join(' ') }
            : { order: 'order_items_count desc' };
        const searchParams = args?.keyword ? { keyword: args?.keyword } : {};
        const paginationParams = args?.currentPage ? { page: args.currentPage, per_page: 20 } : { per_page: 20 };
        const impersonatedParams: PlaceBySupplierCartReq = {};
        if (LocalStorageService.getItem('impersonated_customer')) {
          impersonatedParams.place_by_supplier = true;
          impersonatedParams.customer_id = LocalStorageService.getItem('impersonated_customer')?.customer_id;
        }
        return {
          url: `/products_by_supplier`,
          method: HTTP.GET,
          params: { ...sortParams, ...filterParams, ...searchParams, ...paginationParams, ...impersonatedParams },
        };
      },
      keepUnusedDataFor: 0,
    }),

    getProductsSuggestions: builder.query<GetProductByCategoryResponse, GetProductsSuggestionsRequest>({
      query: ({ supplierMode, ...args }) => {
        const filterParams = args?.filter ? Object.fromEntries(Object.entries(args.filter).filter(([_, value]) => !!value)) : {};
        const sortParams = args?.sort ? { order: args?.sort?.join(' ') } : {};
        const searchParams = args?.keyword ? { keyword: args?.keyword } : {};
        const impersonatedParams: PlaceBySupplierCartReq = {};
        if (LocalStorageService.getItem('impersonated_customer') && supplierMode) {
          impersonatedParams.place_by_supplier = true;
          impersonatedParams.customer_id = LocalStorageService.getItem('impersonated_customer')?.customer_id;
        }
        return {
          url: supplierMode ? '/products_by_supplier' : '/products_by_categories',
          method: HTTP.GET,
          params: { ...sortParams, ...filterParams, ...searchParams, ...impersonatedParams },
        };
      },
      keepUnusedDataFor: 0,
    }),

    favoriteProduct: builder.mutation<FavouriteResponse, number>({
      query: (product_id) => {
        const userId = TokenService.getUserId();
        const params: PlaceBySupplierCartReq = {};
        if (LocalStorageService.getItem('impersonated_customer')) {
          params.place_by_supplier = true;
          params.customer_id = LocalStorageService.getItem('impersonated_customer')?.customer_id;
        }
        return {
          url: `/users/${userId}/products/${product_id}/favorite`,
          method: HTTP.POST,
          body: {
            product_id,
            ...params,
          },
        };
      },
    }),

    unFavoriteProduct: builder.mutation<FavouriteResponse, number>({
      query: (product_id) => {
        const userId = TokenService.getUserId();
        const params: PlaceBySupplierCartReq = {};
        if (LocalStorageService.getItem('impersonated_customer')) {
          params.place_by_supplier = true;
          params.customer_id = LocalStorageService.getItem('impersonated_customer')?.customer_id;
        }
        return {
          url: `/users/${userId}/products/${product_id}/unfavorite`,
          method: HTTP.POST,
          body: {
            product_id,
            ...params,
          },
        };
      },
    }),

    getSupplierSubCategories: builder.query<SupplierSubCategoryResponse, number>({
      query: (supplier_id) => {
        return {
          url: `/supplier_sub_categories`,
          method: HTTP.GET,
          params: {
            supplier_id,
          },
        };
      },
    }),

    getIsFirstDownload: builder.query<
      {
        success: boolean;
        firstly_download: boolean;
      },
      number | undefined
    >({
      query: (supplier_id) => {
        return {
          url: `/first_download`,
          method: HTTP.GET,
          params: {
            supplier_id,
          },
        };
      },
      providesTags: ['Manual_Template'],
    }),

    getProductById: builder.query<Product, number>({
      query: (productId) => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/products/${productId}/get_product`,
          method: HTTP.GET,
        };
      },
      providesTags: (result, _, args) => {
        return result
          ? [
              { type: 'Product' as const, id: args },
              { type: 'Product', id: 'LIST' },
            ]
          : [{ type: 'Product', id: 'LIST' }];
      },
    }),

    deleteProduct: builder.mutation<SuccessResponse, number>({
      query: (productId) => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/products/${productId}`,
          method: HTTP.DELETE,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'Deleted Product');
      },
      invalidatesTags: [{ type: 'Product', id: 'LIST' }, 'Price_List'],
    }),

    deleteAllProducts: builder.mutation<SuccessResponse, void>({
      query: () => {
        return {
          url: `/delete_products`,
          method: HTTP.DELETE,
        };
      },
      invalidatesTags: [{ type: 'Product', id: 'LIST' }, 'Price_List'],
    }),

    deleteProductImage: builder.mutation<SuccessResponse, number>({
      query: (productId) => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/products/${productId}/remove_product_image`,
          method: HTTP.DELETE,
        };
      },
      invalidatesTags: (result, error, arg) => [{ type: 'Product', id: arg }],
    }),

    archiveProduct: builder.mutation<SuccessResponse, number>({
      query: (productId) => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/products/${productId}/archive`,
          method: HTTP.PATCH,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'Archived Product');
      },
      invalidatesTags: (result, error, arg) => [{ type: 'Product', id: arg }],
    }),

    restoreProduct: builder.mutation<SuccessResponse, number>({
      query: (productId) => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/products/${productId}/restore_archive`,
          method: HTTP.PATCH,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'Restored Product');
      },
      invalidatesTags: (result, error, arg) => [{ type: 'Product', id: arg }],
    }),

    createProduct: builder.mutation<SuccessResponse, FormData>({
      query: (body) => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/products`,
          method: HTTP.POST,
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'Created New Product');
      },
      invalidatesTags: [{ type: 'Product', id: 'LIST' }, 'Price_List'],
    }),

    updateProduct: builder.mutation<UpdateProductResponse, UpdateProductRequest | UpdateProductRequestJson>({
      query: ({ id, showSuccessModal, refetchPriceList, ...data }) => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/products/${id}`,
          method: HTTP.PATCH,
          body: 'formData' in data ? data.formData : data,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const keyword = store.getState().products.keyword;
        const currentPage = store.getState().products.currentPage;
        const filter = store.getState().products.filterBy;
        const sort = store.getState().products.sortBy;
        let patchResult;
        try {
          const { data } = await queryFulfilled;
          patchResult = dispatch(
            productsApi.util.updateQueryData('getProducts', { keyword, currentPage, filter, sort }, (draft) => {
              const idx = draft.data?.products?.findIndex((el) => el.id === data?.product?.id);
              if (idx !== -1 && data?.product) {
                draft.data.products[idx] = data.product;
              } else {
                return draft;
              }
            }),
          );
          arg.showSuccessModal && dispatch(setAppSuccessToast('Product has been updated'));
        } catch {
          patchResult?.undo();
        }
      },
      invalidatesTags: (result, error, arg) => (arg.refetchPriceList ? ['Price_List', 'Price_Exception'] : ['Price_Exception']),
    }),

    previewImportedProducts: builder.mutation<PreviewProductsResponse, FormData>({
      query: (body) => {
        return {
          url: `/preview_csv_data`,
          method: HTTP.POST,
          body,
        };
      },
    }),

    importProducts: builder.mutation<any, FormData>({
      query: (body) => {
        return {
          url: `/import_csv`,
          method: HTTP.POST,
          body,
        };
      },
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          dispatch(setImportProductsLoading(false));
          const importStatus = LocalStorageService.getItem('product_import_status');
          importStatus === ImportProductStatus.IMPORTED_PENDING &&
            LocalStorageService.setItem('product_import_status', ImportProductStatus.IMPORTED);
          importStatus === ImportProductStatus.NOT_IMPORTED_PENDING &&
            LocalStorageService.setItem('product_import_status', ImportProductStatus.NOT_IMPORTED);
        } catch (e) {
          console.log(e);
        }
      },
      invalidatesTags: [{ type: 'Product', id: 'LIST' }, 'Price_List'],
    }),

    exportProducts: builder.query<ExportProductsResponse, void>({
      query: () => {
        return {
          url: `/export_csv`,
          method: HTTP.GET,
        };
      },
      keepUnusedDataFor: 0,
      providesTags: [{ type: 'Product', id: 'LIST' }],
    }),

    previewManualSupplierProducts: builder.mutation<PreviewManualProductsResponse, FormData>({
      query: (body) => {
        return {
          url: `/preview_csv_products`,
          method: HTTP.POST,
          body,
        };
      },
    }),

    importManualSupplierProducts: builder.mutation<SuccessResponse, FormData>({
      query: (body) => {
        return {
          url: `/import_csv_products`,
          method: HTTP.POST,
          body,
        };
      },
      invalidatesTags: ['Manual_Product', 'Manual_Template'],
    }),

    archiveAllManualSupplerProducts: builder.mutation<SuccessResponse, number>({
      query: (supplier_id) => {
        return {
          url: `/archive_all`,
          method: HTTP.PATCH,
          body: { supplier_id },
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setAppSuccessToast('Products successfully archived'));
        } catch (e) {
          console.log(e);
        }
      },
      invalidatesTags: ['Manual_Product', 'Menu', 'Recipe', 'Recipes'],
    }),

    deleteManualProduct: builder.mutation<SuccessResponse, { supplierId: number; productId: number }>({
      query: ({ supplierId, productId }) => {
        return {
          url: `/users/${supplierId}/products/${productId}`,
          method: HTTP.DELETE,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'Deleted Product');
      },
      invalidatesTags: ['Menu', 'Recipe', 'Recipes'],
    }),

    restoreManualProduct: builder.mutation<SuccessResponse, { supplierId: number; productId: number }>({
      query: ({ productId, supplierId }) => {
        return {
          url: `/users/${supplierId}/products/${productId}/restore_archive`,
          method: HTTP.PATCH,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'Restored Product');
      },
    }),

    archiveManualProduct: builder.mutation<SuccessResponse, { supplierId: number; productId: number }>({
      query: ({ supplierId, productId }) => {
        return {
          url: `/users/${supplierId}/products/${productId}/archive`,
          method: HTTP.PATCH,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'Product Archived');
      },
      invalidatesTags: ['Menu', 'Recipe', 'Recipes'],
    }),

    deleteAllManualProducts: builder.mutation<SuccessResponse, number>({
      query: (supplier_id) => {
        return {
          url: `/delete_products`,
          method: HTTP.DELETE,
          body: {
            supplier_id,
          },
        };
      },
      invalidatesTags: ['Manual_Product', 'Menu', 'Recipe', 'Recipes'],
    }),

    restoreAllArchived: builder.mutation<SuccessResponse, number>({
      query: (supplier_id) => {
        return {
          url: `/restore_all_archive`,
          method: HTTP.PATCH,
          body: {
            supplier_id,
          },
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'Products Restored');
      },
      invalidatesTags: ['Menu', 'Recipe', 'Recipes'],
    }),

    updateManualProducts: builder.mutation<SuccessResponse, FormData>({
      query: (body) => {
        return {
          url: `/update_multiple_products`,
          method: HTTP.PATCH,
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setAppSuccessToast('Products updated successfully'));
        } catch (e) {
          console.log(e);
        }
      },
      invalidatesTags: ['Menu', 'Recipe', 'Recipes'],
    }),

    addManualProduct: builder.mutation<SuccessResponse, FormData>({
      query: (body) => {
        return {
          url: `/manual_product`,
          method: HTTP.POST,
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setAppSuccessToast('Product has been added'));
        } catch (e) {
          console.log(e);
        }
      },
    }),
  }),

  overrideExisting: true,
});

export const {
  useGetProductsQuery,
  useGetManualProductsQuery,
  useDeleteProductMutation,
  useArchiveProductMutation,
  useCreateProductMutation,
  useUpdateProductMutation,
  useRestoreProductMutation,
  useDeleteProductImageMutation,
  useGetProductByIdQuery,
  useGetProductsByCategoryQuery,
  useGetProductsBySupplierQuery,
  useFavoriteProductMutation,
  useUnFavoriteProductMutation,
  useLazyGetProductsSuggestionsQuery,
  usePreviewImportedProductsMutation,
  useImportProductsMutation,
  useLazyExportProductsQuery,
  useDeleteAllProductsMutation,
  useGetSupplierSubCategoriesQuery,
  usePreviewManualSupplierProductsMutation,
  useImportManualSupplierProductsMutation,
  useArchiveAllManualSupplerProductsMutation,
  useDeleteManualProductMutation,
  useArchiveManualProductMutation,
  useRestoreManualProductMutation,
  useUpdateManualProductsMutation,
  useDeleteAllManualProductsMutation,
  useRestoreAllArchivedMutation,
  useExportManualSupplierProductsMutation,
  useAddManualProductMutation,
  useGetIsFirstDownloadQuery,
} = productsApi;
