import { TokenService } from '../../shared/services/token.service';
import { setAddPriceListLoading, setPriceListActionsDisabled } from '../../store/products';
import { setSuccessMsgHelper, setSuccessToast } from '../helpers';
import { HTTP, rootApi, SuccessResponse } from '../index';
import { CreatePriceListRequest, PriceList, UpdatePriceListRequest } from './types';

export const priceListApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getPriceLists: builder.query<PriceList[], void>({
      query: () => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/price_lists`,
          method: HTTP.GET,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setPriceListActionsDisabled(false));
          dispatch(setAddPriceListLoading(false));
        } catch (e) {
          console.log(e);
        }
      },
      providesTags: ['Price_List'],
    }),

    createPriceList: builder.mutation<SuccessResponse, CreatePriceListRequest>({
      query: ({ skipInvalidation, ...body }) => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/price_lists`,
          method: HTTP.POST,
          body,
        };
      },
      async onQueryStarted(_, { dispatch }) {
        dispatch(setPriceListActionsDisabled(true));
        dispatch(setAddPriceListLoading(true));
      },
      invalidatesTags: (res, _, args) => (args.skipInvalidation ? [] : ['Price_List', 'Product']),
    }),

    updatePriceList: builder.mutation<SuccessResponse, UpdatePriceListRequest>({
      query: ({ id, skipInvalidation, ...body }) => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/price_lists/${id}`,
          method: HTTP.PATCH,
          body,
        };
      },
      invalidatesTags: (res, _, args) => (args.skipInvalidation ? [] : ['Price_List', 'Product']),
    }),

    deletePriceList: builder.mutation<SuccessResponse, number>({
      query: (arg) => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/price_lists/${arg}`,
          method: HTTP.DELETE,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        dispatch(setPriceListActionsDisabled(true));
        await setSuccessToast(dispatch, queryFulfilled, 'Your Price List have been deleted');
      },
      invalidatesTags: ['Price_List', 'Product'],
    }),

    deleteMultiplePriceLists: builder.mutation<SuccessResponse, number[]>({
      query: (arg) => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/price_lists/destroy_multiple`,
          method: HTTP.POST,
          body: { ids: arg },
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessMsgHelper(dispatch, queryFulfilled, 'Price Lists deleted');
      },
      invalidatesTags: ['Price_List', 'Product'],
    }),
  }),

  overrideExisting: true,
});

export const {
  useGetPriceListsQuery,
  useCreatePriceListMutation,
  useUpdatePriceListMutation,
  useDeletePriceListMutation,
  useDeleteMultiplePriceListsMutation,
} = priceListApi;
