import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../styles/colorVariables';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: '102px 0 70px 0',
    },
    header: {
      fontSize: 13,
      color: colorVariables.grey80,
      padding: '25px 20px',
    },
    postCodeTableCell: {
      minWidth: 50,
      width: '25%',
      textAlign: 'right',
      paddingRight: 30,
      margin: '8px 0',
      position: 'relative',
      flexShrink: 0,
      overflow: 'hidden',
    },
    closeIconWrap: {
      width: 18,
      height: 18,
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0,
      border: '1px solid #DADADA',
      cursor: 'pointer',
      position: 'absolute',
      right: 5,
      top: 1,
      backgroundColor: colorVariables.white,
      boxShadow: '0 0 0 6px #ffffff',
    },
    closeIcon: {
      color: '#DADADA',
      fontSize: 14,
    },
    bottomNavWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    addBtnBox: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: 27,
      paddingRight: 20,
      borderTop: `1px solid ${colorVariables.steelGrey}`,
    },
    addBox: {
      borderRadius: 20,
      backgroundColor: 'rgba(152, 165, 183, 0.15)',
      padding: 20,
      marginBottom: 10,
    },
    inputWrap: {
      width: '100%',
      marginBottom: 20,
    },
    addBoxBtns: {
      paddingBottom: 7,
    },
    addedCodesList: {
      padding: 15,
      display: 'flex',
      flexWrap: 'wrap',
      alignContent: 'flex-start',
      height: 'calc(100vh - 540px)',
      overflowY: 'scroll',
    },
    saveBtn: {
      opacity: 0.3,
    },
  }),
);
