import { createStyles, makeStyles } from '@material-ui/core';

import whiteDotes from '../../../../assets/images/bg/Auth_bg_dots.svg';
import lines from '../../../../assets/images/bg/Auth_bg_lines.svg';
import dotes from '../../../../assets/images/bg/dots_grey.png';
import { colorVariables } from '../../../../styles/colorVariables';
import { Sizes } from '../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      paddingTop: 40,
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down(Sizes.desktop)]: {
        paddingTop: 0,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        paddingTop: 40,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
      },
    },
    rootShifted: {
      position: 'relative',
      top: -85,
      paddingTop: 0,
      [theme.breakpoints.down(Sizes.desktop)]: {
        paddingTop: 0,
        top: 0,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        paddingTop: 0,
      },
    },
    planBox: {
      position: 'relative',
      width: 280,
      border: '1px solid rgba(152, 165, 183, 0.15)',
      padding: '58px 22px 38px 22px',
      margin: '0 5px',
      borderRadius: 14,
      backgroundColor: '#F5F7FA',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      '&:hover': {
        boxShadow: '0 0 8px rgba(0, 0, 0, 0.1)',
      },
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: 250,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        padding: '45px 10px 25px 10px',
        width: 200,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: 24,
        width: 328,
        alignItems: 'flex-start',
        marginBottom: 20,
      },
    },
    planBoxContent: {
      textAlign: 'center',
      marginBottom: 27,
      [theme.breakpoints.down(Sizes.mobile)]: {
        textAlign: 'left',
        marginBottom: 10,
      },
    },
    contactBtn: {
      fontSize: 16,
      width: 'calc(100% - 10px)',
    },
    demoBtn: {
      marginTop: 12,
    },
    planName: {
      fontSize: 22,
      fontWeight: 700,
      color: colorVariables.black,
      lineHeight: '30px',
      marginBottom: 12,
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginBottom: 20,
      },
    },
    planSubTitle: {
      fontWeight: 600,
      textTransform: 'uppercase',
      color: colorVariables.blue,
      height: 25,
      marginBottom: 20,
      [theme.breakpoints.down(Sizes.mobile)]: {
        position: 'absolute',
        top: 25,
        right: 14,
      },
    },
    planSubTitlePrice: {
      [theme.breakpoints.down(Sizes.mobile)]: {
        top: 67,
        right: 14,
      },
    },
    planSubTitlePriceLower: {
      textTransform: 'none',
    },
    divider: {
      width: 60,
      height: 2,
      backgroundColor: colorVariables.steelGrey,
      margin: '0 auto 27px auto',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    benefitsTitle: {
      lineHeight: '22px',
      fontWeight: 700,
      paddingBottom: 14,
    },
    benefitsItem: {
      color: colorVariables.userNavy,
      marginBottom: 8,
      lineHeight: '22px',
    },
    mostPopularLabel: {
      position: 'absolute',
      top: 20,
      fontSize: 12,
      fontWeight: 700,
      textTransform: 'uppercase',
      textAlign: 'center',
      background: '-webkit-linear-gradient(right, #33A8E2, #51D9B0)',
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
      [theme.breakpoints.down(Sizes.mobile)]: {
        left: '50%',
        transform: 'translateX(-50%)',
      },
    },
    mostPopularBoxBg: {
      borderColor: colorVariables.transparent,
      background: 'linear-gradient(#ECFDF8, #ECFDF8) padding-box, linear-gradient(90deg, #51D9B0, #33A8E2) border-box',
      [theme.breakpoints.down(Sizes.mobile)]: {
        paddingTop: 60,
        '& h4': {
          top: 68,
        },
      },
    },
    modalRoot: {
      padding: '40px 0 60px 0',
      [theme.breakpoints.down(Sizes.desktop)]: {
        padding: '40px 0 0 0',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        backgroundColor: colorVariables.white,
      },
    },
    modalContent: {
      backgroundImage: `url(${lines}), url(${dotes})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '185px 218px, 160px 220px',
      backgroundPositionY: '100px, 260px',
      backgroundPositionX: '0px, 100%',
      width: 720,
      minHeight: 500,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      [theme.breakpoints.down(Sizes.desktop)]: {
        paddingBottom: 30,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        width: 520,
        paddingBottom: 50,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        backgroundImage: `url(${lines}), url(${whiteDotes})`,
        width: '100vw',
        backgroundSize: '120px 158px, 150px 180px',
        backgroundPositionY: '170px, 300px',
        backgroundPositionX: '-40px, 110%',
      },
    },
    subscribeImage: {
      width: 270,
      height: 180,
      marginTop: 30,
      marginBottom: 35,
    },
    modalTitle: {
      fontSize: 20,
      fontWeight: 700,
      color: colorVariables.black,
      marginBottom: 25,
    },
    phoneBtn: {
      marginTop: 15,
      marginBottom: 30,
    },
    emailBlock: {
      display: 'flex',
      marginTop: 30,
      marginBottom: 15,
      '& a': {
        color: colorVariables.blue,
      },
    },
    modalContentWrap: {
      [theme.breakpoints.down(Sizes.desktop)]: {
        maxHeight: '90vh',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        maxWidth: '100vw',
        backgroundColor: 'rgba(207, 215, 230, 0.5)',
        background: 'linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))',
      },
    },
    planNameFull: {
      transform: 'translateY(-30px)',
      marginBottom: 0,
      [theme.breakpoints.down(Sizes.mobile)]: {
        transform: 'none',
        marginBottom: 20,
      },
    },
    planSubTitleExtraMargin: {
      marginBottom: 48,
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginBottom: 0,
      },
    },
  }),
);
