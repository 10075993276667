import { createStyles, makeStyles } from '@material-ui/core';
import { Sizes } from '../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: 600,
      height: 400,
      '& a[rel="noopener"]': {
        display: 'none',
      },
      '& button[title="Keyboard shortcuts"]': {
        display: 'none !important',
      },
      '& button[title="Map Data"] + span': {
        display: 'none !important',
      },
      '& .gm-style-cc>div': {
        opacity: '0 !important',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        height: 300,
      },
    },
    box: {
      padding: '40px 30px',
    },
    title: {
      fontSize: 20,
      fontWeight: 700,
      textAlign: 'center',
      marginBottom: 20,
    },
    btnsBlock: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 20,
    },
    fieldWrap: {
      marginBottom: 20,
      width: 'calc(100% - 60px)',
    },
    searchWrap: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }),
);
