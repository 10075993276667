import React from 'react';

import { useStyles } from './style';
import { useCurrentTheme } from '../../../api/admin-themes/hooks';
import { ThemeColors } from '../../../api/admin-themes/types';

interface Props {
  percent: number;
}

export const ProgressBar: React.FC<Props> = ({ percent }) => {
  const { colors } = useCurrentTheme();
  const classes = useStyles({ color: colors[ThemeColors.btnPrimaryBg] });
  return (
    <div className={classes.root}>
      <div className={classes.filter} style={{ width: `${percent}%` }} />
    </div>
  );
};
