import * as React from 'react';

function SvgOrderIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M1.667 1.667a1.513 1.513 0 011.42 1.004l3.234 8.995a2.5 2.5 0 002.354 1.667h6.79a2.5 2.5 0 002.372-1.709l2.12-6.362a.833.833 0 00-.791-1.096h-3.333V.833A.833.833 0 0014.999 0H8.333A.833.833 0 007.5.833v3.333H5.416l-.733-2.058A3.183 3.183 0 001.667 0H.833a.833.833 0 000 1.667h.834zm7.5 0h4.999v2.5h-5v-2.5zm-.834 4.166h9.679l-1.763 5.262a.833.833 0 01-.783.571H8.675a.833.833 0 01-.784-.554L6.004 5.833h2.329zM5.833 17.5a2.5 2.5 0 105 0 2.5 2.5 0 00-5 0zm3.334 0a.833.833 0 11-1.666 0 .833.833 0 011.666 0zM15 15a2.5 2.5 0 100 5 2.5 2.5 0 000-5zm0 3.333a.833.833 0 110-1.666.833.833 0 010 1.666z'
        fill='currentColor'
      />
    </svg>
  );
}

export default SvgOrderIcon;
