import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import { Add } from '@material-ui/icons';
import clsx from 'clsx';

import { useStyles } from './style';

interface Props {
  onClick?: () => void;
  title: string;
  customClass?: string;
}

export const AddTitledButton: React.FC<Props> = React.memo(({ title, onClick, customClass = '' }) => {
  const classes = useStyles();
  return (
    <div className={clsx([classes.addBtnBox, customClass])} onClick={onClick}>
      <IconButton className={classes.addIconWrap}>
        <Add />
      </IconButton>
      <span className={classes.addText}>{title}</span>
    </div>
  );
});
