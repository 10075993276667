import { createReducer } from '@reduxjs/toolkit';

import { ProductsPurchasedFilters, ProductsPurchaseSortBy } from '../../api/dashboard/types';
import { logoutThunk } from '../user';
import {
  resetProductsPurchasedParams,
  setProductsPurchasedDaysFilter,
  setProductsPurchasedKeyword,
  setProductsPurchasedPage,
  setProductsPurchasedRangeFilter,
  setProductsPurchasedSelectedRange,
  setProductsPurchasedSortFilter,
} from './products-purchased.actions';

interface State {
  sortBy?: ProductsPurchaseSortBy;
  filterBy?: ProductsPurchasedFilters;
  keyword?: string;
  currentPage: number;
}

const initialState: State = {
  sortBy: undefined,
  filterBy: { days: 1 },
  keyword: undefined,
  currentPage: 1,
};

export const productsPurchasedReducer = createReducer(initialState, (builder) => {
  builder.addCase(logoutThunk.fulfilled, () => {
    return initialState;
  });
  builder.addCase(setProductsPurchasedKeyword, (state, { payload }) => {
    if (state.keyword === payload) {
      return;
    }
    state.keyword = payload;
    state.currentPage = 1;
  });
  builder.addCase(setProductsPurchasedPage, (state, { payload }) => {
    state.currentPage = payload;
  });
  builder.addCase(setProductsPurchasedDaysFilter, (state, { payload }) => {
    state.filterBy = { ...state.filterBy, days: payload.days };
  });
  builder.addCase(setProductsPurchasedSelectedRange, (state, { payload }) => {
    state.filterBy = { ...state.filterBy, selectedRange: payload };
  });
  builder.addCase(setProductsPurchasedRangeFilter, (state, { payload }) => {
    state.filterBy = { ...state.filterBy, ...payload };
  });
  builder.addCase(resetProductsPurchasedParams, () => {
    return initialState;
  });
  builder.addCase(setProductsPurchasedSortFilter, (state, { payload }) => {
    state.filterBy = payload.filter;
    state.sortBy = payload.sort;
    state.currentPage = 1;
  });
});
