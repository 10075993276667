import React, { useRef } from 'react';

import { Close } from '@material-ui/icons';
import clsx from 'clsx';
import { FastField, Field, FieldProps, Form, Formik } from 'formik';

import { FormikDropdown } from '../../../../../../../shared/components/formik-dropdown';
import { ThemedButton } from '../../../../../../../shared/components/themed-button';
import { useScreenSize } from '../../../../../../../shared/hooks/use-screen-size';
import { useAppDispatch, useAppSelector } from '../../../../../../../store';
import { useGetMenuList } from '../../../../../../../api/menu/hooks';
import { getWastageSortFilters } from '../../../../../../../store/wastage';
import { setWastageSortFilters } from '../../../../../../../store/wastage/wastage.actions';
import { WastageSortBy } from '../../../../../../../api/wastages/types';

import { useStyles } from './style';
import { wastagesSortParams } from '../index';

interface Values {
  sortBy?: string;
  menu: string;
}

interface Props {
  handleCloseModal: () => void;
  isFullScreen?: boolean;
}

export const WasteFilter: React.FC<Props> = ({ handleCloseModal, isFullScreen }) => {
  const classes = useStyles();
  const ref = useRef<HTMLFormElement | null>(null);
  const { isMobile } = useScreenSize();
  const dispatch = useAppDispatch();

  const { filter, sortBy: sort } = useAppSelector(getWastageSortFilters);
  const { menus } = useGetMenuList();

  const onSubmit = ({ sortBy, menu }: Values) => {
    dispatch(
      setWastageSortFilters({
        filter: { ...(filter || {}), menu: menu === '_' ? undefined : menu },
        sortBy: sortBy ? (sortBy.split(' ') as WastageSortBy) : undefined,
      }),
    );
    handleCloseModal();
  };

  const onClear = () => {
    dispatch(setWastageSortFilters({ filter: { ...(filter || {}), menu: undefined }, sortBy: undefined }));
    ref.current?.reset();
    handleCloseModal();
  };

  return (
    <Formik
      enableReinitialize={true}
      onSubmit={onSubmit}
      initialValues={{
        sortBy: sort?.join(' ') || wastagesSortParams[0].value,
        menu: filter?.menu || '_',
      }}
    >
      {({ submitForm }) => (
        <Form className={classes.formBox}>
          <div className={classes.root}>
            <div className={classes.sortBlock}>
              <div className={classes.blockTitle}>SORT BY</div>
              <FastField name='sortBy'>
                {(fieldProps: FieldProps) => (
                  <FormikDropdown
                    placeholder='Select sort option'
                    options={wastagesSortParams}
                    {...fieldProps}
                    height={36}
                    inputFontSize={14}
                  />
                )}
              </FastField>
            </div>
            <div className={classes.filterBlock}>
              <div className={classes.blockTitle}>FILTER BY</div>
              <div className={classes.statusField}>
                <Field name='menu'>
                  {(fieldProps: FieldProps) => (
                    <FormikDropdown
                      options={[{ label: 'All Menu', value: '_' }, ...(menus || []).map(({ name }) => ({ label: name, value: name }))]}
                      {...fieldProps}
                      height={34}
                      inputFontSize={14}
                    />
                  )}
                </Field>
              </div>
            </div>
            <div className={clsx([classes.btnBlock, !isFullScreen && classes.btnBlockFullScreen])}>
              <ThemedButton onClick={submitForm} title='Apply' buttonStyle='primary' isSmall={isMobile} />
              <ThemedButton
                onClick={onClear}
                title='CLEAR FILTERS'
                buttonStyle='icon'
                startIcon={<Close color='inherit' />}
                isSmall={isMobile}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
