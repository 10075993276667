import * as React from 'react';

function SvgCategoryGoods(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M21.712 18.782l-.666-.313c.14-.193.224-.312.23-.312l.029-.044c.938-1.844.69-3.704-.71-5.102l-2.945-2.945-5.705-5.711a5.29 5.29 0 00-6.227-.916L5.49 1.85a.757.757 0 00-1.41-.263l-.938 1.673-.825.275a.757.757 0 00-.072 1.407l1.338.61a5.28 5.28 0 00.894 6.251l8.388 8.379.271.275a4.093 4.093 0 002.927 1.288 4.511 4.511 0 001.694-.344l.15 1.047a.812.812 0 00.67.697.887.887 0 00.155 0 .81.81 0 00.72-.428l1.137-2.057 1.041-.347a.825.825 0 00.082-1.538v.006zM2.803 4.267l.644-.215a.76.76 0 00.425-.35l.82-1.476.247 1.729-.154.125c-.106.09-.21.18-.312.275a5.324 5.324 0 00-.41.465l-1.26-.553zM4.49 5.69l.06-.091c.08-.119.165-.231.253-.34l.059-.063c.072-.085.144-.17.222-.244l.047-.047a4.839 4.839 0 01.51-.419l.171-.122a4.43 4.43 0 015.174.263c-2.36.313-5.646 1.876-6.211 6.252a4.439 4.439 0 01-.285-5.19zm1.05 5.996c.313-5.759 5.259-6.237 6.253-6.274l1.895 1.894c-2.373.313-5.69 1.876-6.253 6.287L5.54 11.686zm2.686 2.685c.313-5.768 5.27-6.236 6.252-6.274l1.898 1.898c-2.366.312-5.693 1.876-6.252 6.28l-1.898-1.904zm4.602 4.59l-1.907-1.904c.312-5.768 5.264-6.253 6.252-6.274l1.901 1.897c-2.373.313-5.69 1.876-6.246 6.28zm4.864.365l-.21.15.15 1.054c-.937.422-2.5.75-3.898-.66l-.128-.131c.313-5.756 5.258-6.234 6.252-6.272l.138.138c1.132 1.132 1.332 2.585.566 4.095a7.083 7.083 0 01-.313.422l-1.025-.466-.2.294a5.38 5.38 0 01-1.332 1.376zm2.632.544a.829.829 0 00-.462.385l-1.12 2.07-.35-2.455a5.94 5.94 0 00.591-.522c.097-.097.19-.2.285-.313.094-.112.181-.212.266-.312l.994.453.831.366-1.035.328z'
        fill='#fff'
      />
      <path
        d='M4.777 7.944a.413.413 0 00.591.038.422.422 0 00.04-.595l-.181-.197a1.187 1.187 0 01-.34-.55.423.423 0 00-.798.281c.1.319.275.607.513.841.05.05.103.11.165.182h.01zM6.403 8.956a.422.422 0 000 .597l1.048 1.048a.426.426 0 00.312.125.425.425 0 00.312-.722L7.029 8.956a.425.425 0 00-.597 0h-.028zM10.154 13.305a.429.429 0 00.313.122.423.423 0 00.312-.719l-1.088-1.09a.422.422 0 10-.597.596l1.06 1.091zM12.915 16.065a.432.432 0 00.61 0 .422.422 0 00-.001-.597l-1.035-1.034a.421.421 0 10-.597.597l1.023 1.034zM15.159 17.176a.422.422 0 00-.591.603c.618.618 1.29 1.18 2.007 1.679a.422.422 0 10.475-.697 13.74 13.74 0 01-1.875-1.585h-.016zM6.537 5.067c.244.095.46.25.625.453l.075.075c.082.085.195.13.313.125a.421.421 0 00.312-.719l-.071-.072c-.244-.28-.549-.499-.892-.64a.422.422 0 00-.328.778h-.034zM9.848 8.235a.419.419 0 00.597 0 .422.422 0 000-.597L9.29 6.484a.422.422 0 10-.597.594l1.154 1.157zM12.48 10.867c.079.078.185.122.296.122a.422.422 0 00.313-.72l-1.057-1.028a.421.421 0 10-.597.597l1.044 1.029zM15.163 13.55a.432.432 0 00.61 0 .426.426 0 000-.598l-1.045-1.041a.422.422 0 00-.594.597l1.029 1.041zM18.528 17.229a.42.42 0 00.768-.125.422.422 0 00-.052-.32 12.25 12.25 0 00-1.766-2.134.422.422 0 10-.604.59c.623.6 1.177 1.268 1.654 1.989z'
        fill='#fff'
      />
    </svg>
  );
}

export default SvgCategoryGoods;
