import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../../../styles/colorVariables';
import { Sizes } from '../../../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: 460,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
      },
    },
    uploadBox: {
      width: '100%',
      minHeight: 160,
      display: 'flex',
      alignItems: 'center',
      marginBottom: 15,
    },
    title: {
      width: '100%',
      textAlign: 'center',
    },
    tipBox: {
      display: 'flex',
      justifyContent: 'center',
      flex: 1,
    },
    tipIcon: {
      width: 20,
      height: 20,
      flexShrink: 0,
      borderRadius: '50%',
      textAlign: 'center',
      lineHeight: '20px',
      color: colorVariables.white,
      fontWeight: 700,
      fontSize: 16,
      backgroundColor: colorVariables.green,
      marginRight: 5,
    },
  }),
);
