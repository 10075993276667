import React from 'react';

import { Close } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';

import { useRippleStyles } from '../../../styles/customRipple';
import { StyleProps, useStyles } from './style';

interface Props extends StyleProps {
  isSearchOpened: boolean;
  toggleSearch: () => void;
  disabled?: boolean;
}

export const CustomerSearchIcon: React.FC<Props> = ({ isSearchOpened, toggleSearch, size, iconFontSize, disabled }) => {
  const classes = useStyles({ size, iconFontSize });
  const rippleClasses = useRippleStyles();

  return (
    <div
      className={clsx(classes.searchBtn, !disabled && rippleClasses.ripple, disabled && classes.searchBtnDisabled)}
      onClick={toggleSearch}
    >
      {isSearchOpened ? <Close className={classes.searchIcon} /> : <SearchIcon className={classes.searchIcon} />}
    </div>
  );
};
