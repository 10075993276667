import parsePhoneNumber from 'libphonenumber-js';
import { LocalStorageService } from '../services/localStorage.service';

export default function isValidPhoneNumberForCountry(phoneNumberString: string) {
  const country = LocalStorageService.getItem('country') || undefined;
  const mobilePrefixesID = ['81', '82', '83', '85', '86', '87', '88', '89'];
  const phoneNumber = parsePhoneNumber(phoneNumberString, {
    defaultCountry: country,
    // Demand that the entire input string must be a phone number.
    // Otherwise, it would "extract" a phone number from an input string.
    extract: false,
  });
  if (!phoneNumber) {
    return false;
  }
  if (phoneNumber && phoneNumber.country === 'ID' && !mobilePrefixesID.some((pref) => phoneNumber.nationalNumber.startsWith(pref))) {
    return false;
  }
  if (phoneNumber.country !== country) {
    return false;
  }
  return phoneNumber.isValid();
}
