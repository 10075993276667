import { HTTP, rootApi, SuccessResponse } from '../index';

import { deletePantryList } from '../../store/pantry-lists';
import { setLastAddedProductId } from '../../store/products';
import { getPantryListQueryParamsHelper, setSuccessToast } from '../helpers';
import {
  AddProductsToPantryListRequest,
  AddProductsToPantryListResponse,
  AddToPantryListRequest,
  AddToPantryListResponse,
  CreatePantryListRequest,
  CreatePantryListResponse,
  GetPantryListProductsReq,
  GetPantryListReqParams,
  PantryListDetailsRes,
  PantryListsResponse,
  RenamePantryListRequest,
} from './types';

export const pantryListApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getPantryLists: builder.query<PantryListsResponse, GetPantryListReqParams>({
      query: (args) => {
        const params = {
          ...getPantryListQueryParamsHelper(args),
          page: args.currentPage,
          per_page: 20,
        };
        return {
          url: `/pantry_lists`,
          method: HTTP.GET,
          params,
        };
      },
      keepUnusedDataFor: 0,
    }),

    getPantryListProducts: builder.query<PantryListDetailsRes, GetPantryListProductsReq>({
      query: ({ id, ...params }) => {
        return {
          url: `/pantry_lists/${id}/get_pantry_list`,
          method: HTTP.GET,
          params,
        };
      },
      providesTags: ['Pantry_List'],
      keepUnusedDataFor: 0,
    }),

    createPantryList: builder.mutation<CreatePantryListResponse, CreatePantryListRequest>({
      query({ name }) {
        return {
          url: `/pantry_lists`,
          method: HTTP.POST,
          body: {
            pantry_list: {
              name,
            },
          },
        };
      },
    }),

    renamePantryList: builder.mutation<CreatePantryListResponse, RenamePantryListRequest>({
      query({ id, name }) {
        return {
          url: `/pantry_lists/${id}`,
          method: HTTP.PATCH,
          body: {
            pantry_list: {
              name,
            },
          },
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, 'Pantry List Name Saved');
      },
    }),

    deletePantryList: builder.mutation<SuccessResponse, number>({
      query(id) {
        return {
          url: `/pantry_lists/${id}`,
          method: HTTP.DELETE,
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        dispatch(deletePantryList(id));
        await setSuccessToast(dispatch, queryFulfilled, 'Pantry List Deleted');
      },
    }),

    addToPantryList: builder.mutation<AddToPantryListResponse, AddToPantryListRequest>({
      query({ pantry_list_id, ...body }) {
        return {
          url: `/pantry_lists/${pantry_list_id}/add_to_pantry_list`,
          method: HTTP.POST,
          body,
        };
      },
      async onQueryStarted(args, { dispatch }) {
        dispatch(setLastAddedProductId(args.product_id));
      },
      invalidatesTags: ['Pantry_List'],
    }),

    removeFromPantryList: builder.mutation<SuccessResponse, AddToPantryListRequest>({
      query({ pantry_list_id, ...body }) {
        return {
          url: `/pantry_lists/${pantry_list_id}/remove_from_pantry_list`,
          method: HTTP.DELETE,
          body,
        };
      },
      invalidatesTags: (result, error, arg) => [{ type: 'Pantry_List' }],
    }),

    addProductsToPantryList: builder.mutation<AddProductsToPantryListResponse, AddProductsToPantryListRequest>({
      query({ id, ...body }) {
        return {
          url: `/pantry_lists/${id}/add_multiple_products`,
          method: HTTP.POST,
          body,
        };
      },
      invalidatesTags: (result, error, arg) => [{ type: 'Pantry_List' }],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetPantryListsQuery,
  useCreatePantryListMutation,
  useRenamePantryListMutation,
  useDeletePantryListMutation,
  useAddToPantryListMutation,
  useRemoveFromPantryListMutation,
  useAddProductsToPantryListMutation,
  useGetPantryListProductsQuery,
} = pantryListApi;
