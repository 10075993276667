import { createEntityAdapter, createReducer, EntityState } from '@reduxjs/toolkit';

import { recOrderApi } from '../../api/recurring-order';
import { RecOrder, RecOrderSortBy } from '../../api/recurring-order/types';
import { ProductsCategories } from '../../shared/constants/products';
import { logoutThunk, setImpersonated } from '../user';
import {
  deleteRecurringOrder,
  hideCreateRecOrderModal,
  hideEditRecOrderModal,
  hideRecOrderExitWarning,
  setRecOrderKeyword,
  setRecOrderPage,
  setRecurringOrderSortFilters,
  setSelectedRecOrder,
  showCreateRecOrderModal,
  showEditRecOrderModal,
  showRecOrderExitWarning,
} from './recurring-order.actions';

export const recOrderAdapter = createEntityAdapter<RecOrder>();

interface State {
  list: EntityState<RecOrder>;
  selectedOrderId?: number;
  isCreateModalShown: boolean;
  isEditModalShown: boolean;
  exitWarning: {
    handler: (() => void) | null;
  };
  keyword?: string;
  currentPage: number;
  sortBy?: RecOrderSortBy;
  filterBy: ProductsCategories | null;
}

const initialState: State = {
  list: recOrderAdapter.getInitialState(),
  isCreateModalShown: false,
  isEditModalShown: false,
  exitWarning: {
    handler: null,
  },
  keyword: '',
  currentPage: 1,
  sortBy: undefined,
  filterBy: null,
};

export const recurringOrderReducer = createReducer(initialState, (builder) => {
  builder.addCase(logoutThunk.fulfilled, () => {
    return initialState;
  });
  builder.addCase(setImpersonated, () => {
    return initialState;
  });
  builder.addCase(setSelectedRecOrder, (state, { payload }) => {
    state.selectedOrderId = payload;
  });
  builder.addCase(setRecOrderPage, (state, { payload }) => {
    state.currentPage = payload;
  });
  builder.addCase(showCreateRecOrderModal, (state) => {
    state.isCreateModalShown = true;
  });
  builder.addCase(hideCreateRecOrderModal, (state) => {
    state.isCreateModalShown = false;
  });
  builder.addCase(showEditRecOrderModal, (state) => {
    state.isEditModalShown = true;
  });
  builder.addCase(hideEditRecOrderModal, (state) => {
    state.isEditModalShown = false;
  });
  builder.addCase(showRecOrderExitWarning, (state, { payload }) => {
    state.exitWarning = payload;
  });
  builder.addCase(setRecOrderKeyword, (state, { payload }) => {
    if (state.keyword === payload) {
      return;
    }
    state.keyword = payload;
    state.currentPage = 1;
    state.list = recOrderAdapter.getInitialState();
  });
  builder.addCase(setRecurringOrderSortFilters, (state, { payload }) => {
    state.list = recOrderAdapter.getInitialState();
    state.filterBy = payload.filter;
    state.sortBy = payload.sort;
    state.currentPage = 1;
  });
  builder.addCase(deleteRecurringOrder, (state, { payload }) => {
    state.list = recOrderAdapter.removeOne(state.list, payload);
  });
  builder.addCase(hideRecOrderExitWarning, (state) => {
    state.exitWarning = {
      handler: null,
    };
  });
  builder.addMatcher(recOrderApi.endpoints.createRecOrder.matchFulfilled, (state, { payload }) => {
    state.selectedOrderId = payload.recurring_order.id;
    state.currentPage = 1;
    state.list = recOrderAdapter.getInitialState();
  });
  builder.addMatcher(recOrderApi.endpoints.getRecOrders.matchFulfilled, (state, { payload }) => {
    state.list = recOrderAdapter.setMany(state.list, payload.recurring_orders);
  });
  builder.addMatcher(recOrderApi.endpoints.updateRecOrder.matchFulfilled, (state, { payload }) => {
    state.list = recOrderAdapter.upsertOne(state.list, payload.recurring_order);
  });
  builder.addMatcher(recOrderApi.endpoints.updateRecOrderData.matchFulfilled, (state, { payload }) => {
    state.list = recOrderAdapter.upsertOne(state.list, payload.recurring_order);
  });
  builder.addMatcher(recOrderApi.endpoints.updateRecOrderQuantity.matchFulfilled, (state, { payload }) => {
    state.list = recOrderAdapter.upsertOne(state.list, payload.recurring_order);
  });
});
