import * as React from 'react';

function SvgCategoryIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g fill='currentColor'>
        <path d='M17.158 1.953h-4.333C12.462.825 11.333 0 10 0 8.667 0 7.538.825 7.175 1.953H2.842c-.465 0-.842.35-.842.781V19.22c0 .431.377.781.842.781h14.316c.465 0 .842-.35.842-.781V2.734c0-.431-.377-.78-.842-.78zm-.842 16.485H3.684V3.515h4.21c.466 0 .843-.35.843-.782 0-.646.566-1.171 1.263-1.171s1.263.525 1.263 1.171c0 .432.377.782.842.782h4.21v14.921z' />
        <path d='M13.125 11.953h-6.25a.781.781 0 000 1.563h6.25a.781.781 0 100-1.563zM6.094 9.61c0 .43.35.78.781.78h6.25a.781.781 0 100-1.562h-6.25a.781.781 0 00-.781.781z' />
      </g>
    </svg>
  );
}

export default SvgCategoryIcon;
