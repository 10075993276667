import { differenceInDays } from 'date-fns';

import { RangeOptions } from '../../shared/components/range-dropdown';
import { RootState } from '../index';

export const getSelectedDate = (state: RootState) => state.dashboard.selectedDate;

export const getDashboardMode = (state: RootState) => state.dashboard.dashboardMode;

export const getSalesRange = (state: RootState) => {
  let days;
  switch (state.dashboard.sales.range) {
    case RangeOptions.LAST_7:
      days = 7;
      break;
    case RangeOptions.LAST_28:
      days = 28;
      break;
    case RangeOptions.LAST_60:
      days = 60;
      break;
    case RangeOptions.LAST_90:
      days = 90;
      break;
    case RangeOptions.LAST_6M:
      days = 180;
      break;
    case RangeOptions.LAST_12M:
      days = 365;
      break;
    case RangeOptions.ALL_TIME:
      const userCreated = new Date(state.user.createdAt || '06/01/22');
      days = differenceInDays(new Date(), userCreated);
      break;
    case RangeOptions.CUSTOM:
      days = 7;
      break;
    default:
      days = 7;
  }

  return {
    days,
    range: state.dashboard.sales.range,
    startDate: state.dashboard.sales.startDate,
    endDate: state.dashboard.sales.endDate,
  };
};
