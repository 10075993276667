import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: 330,
      height: 275,
      '& a[rel="noopener"]': {
        display: 'none',
      },
      '& button[title="Keyboard shortcuts"]': {
        display: 'none !important',
      },
      '& button[title="Map Data"] + span': {
        display: 'none !important',
      },
      '& .gm-style-cc>div': {
        opacity: '0 !important',
      },
    },
  }),
);
