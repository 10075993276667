import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../../styles/colorVariables';
import { Sizes } from '../../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      paddingTop: 30,
      paddingBottom: 60,
      [theme.breakpoints.down(Sizes.mobile)]: {
        backgroundColor: colorVariables.steelGrey15,
        boxShadow: `0 0 0 25px ${colorVariables.steelGrey15}`,
        paddingTop: 10,
        paddingBottom: 20,
      },
    },
    addPaymentHead: {
      marginTop: 20,
      marginBottom: 20,
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    addPaymentTitle: {
      fontSize: 20,
      lineHeight: '36px',
      fontWeight: 700,
    },
    formBox: {
      width: '100%',
    },
    billingAddressBox: {
      marginTop: 25,
      paddingTop: 30,
      borderTop: `1px solid ${colorVariables.steelGrey}`,
      [theme.breakpoints.down(Sizes.mobile)]: {
        borderTop: 'none',
        marginTop: 0,
        paddingTop: 20,
      },
    },
    billingAddressTitle: {
      fontSize: 12,
      lineHeight: '22px',
      fontWeight: 700,
      textTransform: 'uppercase',
      color: colorVariables.grey60,
      marginBottom: 10,
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginBottom: 0,
      },
    },
    itemTitle: {
      fontSize: 16,
    },
    billingAddressNew: {
      borderBottom: `1px solid ${colorVariables.steelGrey}`,
      padding: '10px 0',
      cursor: 'pointer',
      marginBottom: 35,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down(Sizes.desktop)]: {
        padding: '15px 0',
        marginBottom: 15,
      },
    },
    chevron: {
      display: 'block',
      color: colorVariables.steelGrey,
      fontSize: 30,
      transform: 'rotate(0deg)',
      transition: 'transform ease-in-out 0.5s',
    },
    chevronRotated: {
      transform: 'rotate(90deg)',
    },
    btnBlock: {
      marginTop: 30,
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginTop: 10,
        '& button': {
          width: '100%',
        },
      },
    },
    fieldsBox: {
      display: 'flex',
      flexWrap: 'wrap',
      maxWidth: 900,
    },
    fieldWrap: {
      width: '48%',
      maxWidth: 380,
      marginBottom: 15,
      marginRight: 30,
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        marginRight: 0,
      },
    },
    baseClass: {
      width: 380,
      border: `1px solid ${colorVariables.steelGrey}`,
      borderRadius: 6,
      height: 48,
      padding: '15px 10px',
      '&:hover': {
        border: `1px solid ${colorVariables.blue}`,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        backgroundColor: colorVariables.white,
      },
    },
    baseClassExp: {
      width: 180,
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 150,
        marginRight: 20,
      },
    },
    baseClassCvc: {
      width: 180,
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 150,
      },
    },
    focus: {
      border: `1px solid ${colorVariables.blue}`,
    },
    invalid: {
      border: `1px solid ${colorVariables.red}`,
      '&:hover': {
        border: `1px solid ${colorVariables.red}`,
      },
    },
    label: {
      fontSize: 12,
      marginBottom: 8,
      display: 'flex',
      marginTop: 5,
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
      },
    },
    fieldWrapFlex: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down(Sizes.mobile)]: {
        justifyContent: 'flex-start',
      },
    },
    dialog: {
      position: 'fixed',
      top: '30%',
      right: 100,
      [theme.breakpoints.down(Sizes.mobile)]: {
        right: 10,
      },
    },
    fullWidthFieldWrap: {
      marginBottom: 15,
      width: '100%',
    },
    smallField: {
      width: '24%',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        marginBottom: 15,
      },
    },
    smallFieldsBox: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      marginBottom: 20,
      width: '100%',
    },
    centeredWrap: {
      width: '48%',
      marginBottom: 15,
      [theme.breakpoints.down(Sizes.desktop)]: {
        marginBottom: 10,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        marginBottom: 20,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        marginBottom: 15,
      },
    },
  }),
);
