import { createStyles, makeStyles } from '@material-ui/core';
import { colorVariables } from '../../../../styles/colorVariables';
import { Sizes } from '../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    searchBox: {
      width: 420,
      backgroundColor: colorVariables.white,
      position: 'absolute',
      borderRadius: 4,
      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
      flexShrink: 0,
      right: 0,
      marginTop: 0,
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
      },
    },
    searchBoxHidden: {
      height: 0,
      overflow: 'hidden',
      opacity: 0,
    },
    rootField: {
      width: 420,
      margin: 0,
      padding: 0,
      position: 'relative',
      '& legend': {
        width: 0,
      },
      '& .MuiAutocomplete-inputRoot': {
        paddingRight: '0 !important',
        '&::before, &::after': {
          display: 'none !important',
        },
      },
      '& .MuiInputBase-input.MuiInput-input.MuiAutocomplete-input': {
        width: '100%',
        fontSize: 15,
        fontWeight: 400,
        color: colorVariables.navy,
        lineHeight: '36px',
        height: 40,
        padding: '0 40px 0 10px !important',
        backgroundColor: colorVariables.white,
        border: `1px solid ${colorVariables.userLightGrey}`,
        borderRadius: 6,
        [theme.breakpoints.down(Sizes.mobile)]: {
          fontSize: 14,
        },
        '&:hover': {
          [theme.breakpoints.up(Sizes.desktop)]: {
            borderColor: colorVariables.blue,
          },
        },
        '&:focus': {
          borderColor: colorVariables.blue,
        },
      },
      '& .MuiAutocomplete-inputRoot.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd': {
        display: 'none',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
      },
    },
    optionWrap: {
      display: 'flex',
      backgroundColor: colorVariables.transparent,
      padding: '5px 10px',
      width: '100%',
      justifyContent: 'space-between',
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: 5,
      },
    },
    option: {
      backgroundColor: colorVariables.white,
      fontSize: 14,
      minHeight: 40,
      paddingRight: 0,
      marginTop: 2,
      '&:hover': {
        backgroundColor: colorVariables.blueLight,
      },
      '&:focus': {
        backgroundColor: colorVariables.blueLight,
      },
      '&[data-focus="true"]': {
        backgroundColor: colorVariables.blueLight,
      },
      '&:active': {
        backgroundColor: colorVariables.blueLight,
      },
    },
    noOptions: {
      fontSize: 14,
    },
    nameSection: {
      display: 'flex',
      alignItems: 'center',
    },
    addressSection: {
      fontSize: 11,
      color: colorVariables.grey60,
    },
    imgWrap: {
      width: 28,
      height: 28,
      overflow: 'hidden',
      border: `1px solid ${colorVariables.steelGrey}`,
      fontSize: 0,
      flexShrink: 0,
      marginRight: 7,
      marginTop: 4,
      borderRadius: '50%',
      marginLeft: 'auto',
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {},
    },
    addBtnSupplier: {
      scale: 0.85,
    },
    closeIcon: {
      width: 18,
      height: 18,
      '& svg': {
        fontSize: 18,
        color: colorVariables.grey60,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    searchContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    searchWrap: {
      width: 300,
      transition: 'width ease-in-out 0.5s',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
      },
    },
    searchIcon: {
      position: 'absolute',
      right: 10,
      top: 10,
    },
    label: {
      fontSize: 12,
      lineHeight: '16px',
      paddingLeft: 2,
      marginBottom: 13,
    },
    noResblock: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }),
);
