import { createStyles, makeStyles } from '@material-ui/core';
import { colorVariables } from '../../../../../styles/colorVariables';

export const useStyles = makeStyles((theme) =>
  createStyles({
    row: {
      borderBottom: '1px solid #CFD7E6',
      padding: '13px 0',
    },
    head: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 13px',
    },
    productImgBox: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 38,
      height: 38,
      borderRadius: '50%',
      marginRight: 10,
      backgroundColor: colorVariables.grey40,
      flexShrink: 0,
      cursor: 'pointer',
      overflow: 'hidden',
      '&:hover div': {
        visibility: 'visible',
      },
    },
    productImgBoxArchived: {
      cursor: 'default',
      opacity: 0.3,
      '&:hover div': {
        visibility: 'hidden',
      },
    },
    productImage: {
      backgroundColor: colorVariables.white,
    },
    editImage: {
      position: 'absolute',
      width: 38,
      height: 38,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(191, 201, 213, 0.6)',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    },
    editIcon: {
      color: colorVariables.white,
      fontSize: 14,
    },
    textarea: {
      resize: 'none',
      width: '100%',
      border: `1px solid ${colorVariables.steelGrey}`,
      padding: 5,
      '&:hover, &:focus': {
        border: `1px solid ${colorVariables.blue}`,
      },
    },
    dialog: {
      position: 'fixed',
      top: '40%',
      right: 'auto',
    },
    nameField: {
      flexGrow: 1,
    },
    chevron: {
      fontSize: 30,
      color: colorVariables.grey60,
      marginLeft: 'auto',
      transition: 'transform ease-in-out 0.5s',
    },
    chevronDown: {
      transform: 'rotate(90deg)',
    },
    expandedContent: {
      backgroundColor: 'rgba(152, 165, 183, 0.075)',
      borderRadius: 20,
      margin: '10px 10px 0 10px',
      padding: 20,
    },
    detailsRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 10,
      '&>div:last-child': {
        textAlign: 'right',
        width: '50%',
      },
    },
    detailLabel: {
      fontSize: 12,
      lineHeight: '22px',
      fontWeight: 700,
      color: colorVariables.grey60,
      width: '50%',
    },
    priceBox: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '50%',
    },
    priceCell: {
      fontWeight: 600,
    },
    gstBox: {
      '& span.MuiFormControlLabel-label': {
        fontSize: '12px !important',
        lineHeight: '22px',
        fontWeight: 'bold !important',
        color: colorVariables.grey60,
      },
    },
    btns: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 10,
      justifyContent: 'space-between',
    },
    btnsEdit: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 10,
      justifyContent: 'center',
    },
    actionBtn: {
      fontSize: 11,
      padding: 5,
      margin: 0,
    },
    input: {
      width: '100%',
      border: `1px solid ${colorVariables.steelGrey}`,
      padding: 5,
      borderRadius: 4,
      textAlign: 'right',
      '&:hover, &:focus': {
        border: `1px solid ${colorVariables.blue}`,
      },
      '&[type=number]': {
        '-moz-appearance': 'textfield',
      },
      '&[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
    priceInput: {
      textAlign: 'left',
      fontWeight: 600,
    },
  }),
);
