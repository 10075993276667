import React from 'react';

import clsx from 'clsx';

import SvgWarning from '../../../assets/icons/Warning';

import { useStyles } from './style';

interface Props {
  text: string;
  right?: boolean;
  rightSide?: boolean;
  hideIcon?: boolean;
}

export const InfoTooltip: React.FC<Props> = ({ text, right, rightSide, hideIcon }) => {
  const classes = useStyles({ right });

  return (
    <div className={classes.root}>
      <div className={clsx(classes.toolTip, rightSide && classes.rightSideArrow)}>
        {hideIcon ? null : <SvgWarning className={classes.infoIcon} />}
        {text}
      </div>
    </div>
  );
};
