import React, { useCallback, useEffect, useState } from 'react';
import { NavLink, Route, Switch, useHistory, useLocation } from 'react-router-dom';

import clsx from 'clsx';

import { useGetConnectionRequestsQuery, useGetMyCompanyQuery } from '../../../api/company';
import { CustomerConnectionStatus } from '../../../api/customer/types';
import { useGeIsTeammateViewer } from '../../../api/teammates/hooks';
import { useMeQuery } from '../../../api/user';
import { Roles } from '../../../api/user/types';
import { AddButton } from '../../../shared/components/add-button';
import { DropdownMenu } from '../../../shared/components/dropdown-menu';
import { NavbarTitle } from '../../../shared/components/navbar-title';
import { getCategoriesFromIds } from '../../../shared/helpers/getCategoryId';
import { useScreenSize } from '../../../shared/hooks/use-screen-size';
import { ToastService } from '../../../shared/services/toastService';
import { useAppDispatch } from '../../../store';
import { setSelectedCategory } from '../../../store/suppliers-categories';
import { setSelectedSupplier } from '../../../store/suppliers-categories/suppliers-categories.actions';
import { openAccountMenu, openMainMenu } from '../../../store/user';
import { MainNavBar } from '../../AppDrawer/MainNavBar';
import { TopNavBar } from '../../AppDrawer/MainNavBar/top-nav-bar';
import { AccountMenu } from '../account-menu';
import { AddSupplierTab } from './AddSupplierTab';
import { CategoriesTab } from './CategoriesTab';
import { ConnectionDropdown } from './common/connection-dropdown';
import { ManualSupplierProducts } from './common/manual-supplier-products';
import { SetupManualSuppliersModal } from './common/setup-manual-suppliers-modal';
import { UploadPricingModal } from './common/upload-pricing';
import { ConnectionRequestsModal } from './ConnectionRequestsModal';
import { SuppliersTab } from './SuppliersTab';

import { useStyles } from './style';

export const SuppliersPage: React.FC = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { push } = useHistory();
  const dispatch = useAppDispatch();
  const { isDesktop, isMobile } = useScreenSize();

  const [isConnectionReqModalOpened, setIsConnectionReqModalOpened] = useState(false);
  const [reqCount, setReqCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);

  const isAddPage = pathname.includes('/account/suppliers/add');
  const isCarPage = pathname.includes('/account/suppliers/categories');

  const { data: user } = useMeQuery();
  const { data: company } = useGetMyCompanyQuery(user?.company?.id, {
    skip: !user?.company.id,
  });
  const { connectionRequests } = useGetConnectionRequestsQuery(undefined, {
    selectFromResult: ({ data = [] }) => ({
      connectionRequests: data.filter((req) => req.status === CustomerConnectionStatus.PENDING && req.send_by === Roles.SUPPLIER),
    }),
    skip: pathname.includes('suppliers/categories'),
  });
  const isViewer = useGeIsTeammateViewer('supplier_and_categories');

  const handleClose = useCallback(() => {
    isDesktop ? setAnchorEl(null) : setIsDropdownOpened(false);
    dispatch(setSelectedSupplier(null));
  }, [isDesktop]);

  const handleClick = useCallback(
    (event: React.MouseEvent<any>) => {
      isDesktop ? setAnchorEl(event.currentTarget) : setIsDropdownOpened(true);
    },
    [isDesktop],
  );

  const openMenus = () => {
    dispatch(openMainMenu());
    dispatch(openAccountMenu());
  };

  const openAddSupplier = () => {
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    push('/account/suppliers/add');
  };

  useEffect(() => {
    if (connectionRequests.length && connectionRequests.length > reqCount && !pathname.includes('suppliers/categories')) {
      setIsConnectionReqModalOpened(true);
    }
    if (connectionRequests.length === 0) {
      setIsConnectionReqModalOpened(false);
    }
    setReqCount(connectionRequests.length);
  }, [connectionRequests.length]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedCategory(null));
    };
  }, []);

  return (
    <>
      <TopNavBar
        isAccount={true}
        bottomComponent={
          isMobile ? (
            <div className={classes.headBottomBox}>
              <NavbarTitle title='Suppliers & Categories' showBackBtn={true} backHandler={openMenus} />
              <DropdownMenu
                items={[
                  <div key='Invite supplier' onClick={openAddSupplier}>
                    Invite supplier
                  </div>,
                ]}
                triggerBtn={
                  <div>
                    <AddButton isDark={true} />
                  </div>
                }
              />
            </div>
          ) : undefined
        }
      />
      <MainNavBar isAccount={true} />
      <div className={clsx(classes.accountContainer, classes.accountContainerCustomer)}>
        <div className={classes.accountHeader}>
          <div className={classes.accountTitle}>My Account</div>
        </div>
        <div className={classes.accountBody}>
          <AccountMenu showBack={false} />
          <div className={classes.accountContent}>
            <div className={classes.titleBox}>
              <h2 className={classes.title}>{isAddPage ? 'Find and add suppliers' : isCarPage ? 'Categories' : 'Your Suppliers'}</h2>
            </div>
            <div className={classes.head}>
              <div className={classes.tabsBox}>
                <NavLink exact={true} to='/account/suppliers' className={classes.headerLink} activeClassName={classes.headerLinkActive}>
                  Supplier List
                </NavLink>
                <NavLink exact={true} to='/account/suppliers/add' className={classes.headerLink} activeClassName={classes.headerLinkActive}>
                  Add Supplier
                </NavLink>
                <NavLink
                  exact={true}
                  to='/account/suppliers/categories'
                  className={classes.headerLink}
                  activeClassName={classes.headerLinkActive}
                >
                  Categories
                </NavLink>
              </div>
            </div>
            <div className={classes.content}>
              <Switch>
                <Route path={'/account/suppliers/add'} exact={true}>
                  <AddSupplierTab />
                </Route>
                <Route path={'/account/suppliers/categories'} component={CategoriesTab} />
                <Route path={'/account/suppliers'} exact={true}>
                  <SuppliersTab handleClick={handleClick} openAddSupplier={openAddSupplier} />
                </Route>
              </Switch>
              <ConnectionRequestsModal
                selectedCategories={company ? getCategoriesFromIds(company.categories) : undefined}
                isModalOpen={isConnectionReqModalOpened}
                closeModal={setIsConnectionReqModalOpened.bind(null, false)}
                connectionRequests={connectionRequests}
              />
              <ConnectionDropdown isDropdownOpened={isDropdownOpened} handleClose={handleClose} anchorEl={anchorEl} />
              <SetupManualSuppliersModal />
              <UploadPricingModal />
              {!isMobile && <ManualSupplierProducts />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
