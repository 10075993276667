import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useAIChat = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const scriptToRemove = document.getElementById('ai-script');
    if (!pathname.includes('/account/help') && scriptToRemove) {
      scriptToRemove?.remove();
      document.querySelector('.chat-bubble')?.remove();
      document.getElementById('chatBubble')?.remove();
    }
    if (pathname.includes('/account/help')) {
      scriptToRemove?.remove();
      const script = document.createElement('script');
      script.id = 'ai-script';
      script.src = 'https://staging-server.primecx.ai/assets/js/custom-chatapp.js';
      script.dataset.id = 'MTcwMjkzNzA3MS1DQjE0LVVJMTc=';
      script.dataset.domain = 'https://aicxagent.com';
      script.defer = true;
      document.body.appendChild(script);
    }

    return () => {
      scriptToRemove?.remove();
      document.querySelector('.chat-bubble')?.remove();
      document.getElementById('chatBubble')?.remove();
    };
  }, [pathname]);
};
