import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { colorVariables } from '../../../../../styles/colorVariables';
import { Sizes } from '../../../../../styles/sizes';

export interface StyleProps {
  textColor?: string;
  bgColor?: string;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    cart: {
      padding: 12,
      height: 41,
      display: 'flex',
      alignItems: 'center',
      borderRadius: 4,
      border: ({ textColor }) => (textColor ? `1px solid ${textColor}` : '1px solid rgba(255, 255, 255, 0.1)'),
      marginRight: 15,
      transition: 'all ease-in-out 0.4s',
      [theme.breakpoints.down(Sizes.desktop)]: {
        marginRight: 0,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        height: 36,
        padding: '10px 8px 10px 5px',
        transform: 'translateY(-4px) translateX(8px)',
        backgroundColor: ({ bgColor }) => bgColor || colorVariables.navy,
      },
      [theme.breakpoints.up(Sizes.desktop)]: {
        '&:hover': {
          '& svg': {
            color: colorVariables.green,
          },
          borderColor: colorVariables.green,
          cursor: 'pointer',
        },
      },
    },
    cartActive: {
      borderColor: colorVariables.transparent,
      backgroundColor: 'rgba(152, 165, 183, 0.15)',
    },
    cartAmount: {
      display: 'flex',
      alignItems: 'center',
      flexShrink: 0,
      justifyContent: 'center',
      minWidth: 20,
      height: 20,
      paddingLeft: 2,
      paddingRight: 2,
      lineHeight: '20px',
      backgroundColor: colorVariables.green,
      borderRadius: 6,
      fontSize: 11,
      fontWeight: 700,
      marginRight: 8,
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginRight: 3,
      },
    },
    cartTotal: {
      color: ({ textColor }) => textColor || colorVariables.white,
      marginLeft: 8,
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginLeft: 3,
      },
    },
    rotation: {
      animation: `$rotation 1.5s infinite `,
      marginLeft: 5,
      marginRight: 5,
    },
    '@keyframes rotation': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(359deg)',
      },
    },
  }),
);
