import { createStyles, makeStyles } from '@material-ui/core';
import { Sizes } from '../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    noResBlock: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: '10vh',
      flexDirection: 'column',
      [theme.breakpoints.down(Sizes.desktop)]: {
        paddingTop: 30,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        paddingTop: '10vh',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        paddingTop: '4vh',
      },
    },
    noResTitle: {
      textAlign: 'center',
      fontSize: 40,
      lineHeight: '56px',
      fontWeight: 700,
      marginBottom: 30,
      [theme.breakpoints.down(Sizes.desktop)]: {
        marginBottom: 20,
        fontSize: 34,
        lineHeight: '42px',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        fontSize: 28,
        maxWidth: 320,
        lineHeight: '39px',
      },
    },
    noResText: {
      textAlign: 'center',
      opacity: 0.7,
      marginBottom: 15,
      [theme.breakpoints.down(Sizes.mobile)]: {
        maxWidth: 300,
      },
    },
  }),
);
