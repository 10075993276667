import { RootState } from '../../index';

export const getOrdersSortFilters = (state: RootState) => ({
  sort: state.adminOrders.sortBy,
  filter: state.adminOrders.filterBy,
  keyword: state.adminOrders.keyword,
  currentPage: state.adminOrders.currentPage,
});

export const getSelectedOrdersIds = (state: RootState) => state.adminOrders.selectedOrders;
