import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { ChevronRight } from '@material-ui/icons';
import clsx from 'clsx';

import SvgFaqIcon from '../../../../assets/icons/Faq';
import { BackButton } from '../../../../shared/components/back-button';
import { NavbarTitle } from '../../../../shared/components/navbar-title';
import { SearchField } from '../../../../shared/components/search-field';
import { faqsListRestaurant } from '../../../../shared/constants/faqsRestaurant';
import { faqsListSupplier } from '../../../../shared/constants/faqsSupplier';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { getIsRestaurant, setFaqKeyword } from '../../../../store/user';
import { getHelpInfo, getHelpKeywords } from '../../../../store/user/user.selectors';
import { TopNavBar } from '../../../AppDrawer/MainNavBar/top-nav-bar';
import { HelpInfoSearchResults } from '../help-info-search-results';

import { useStyles } from './style';

export const Faqs: React.FC = () => {
  const classes = useStyles();
  const { goBack } = useHistory();
  const dispatch = useAppDispatch();

  const isRestaurant = useAppSelector(getIsRestaurant);
  const { faq } = useAppSelector(getHelpKeywords);

  const onKeywordChange = (value: string) => {
    dispatch(setFaqKeyword(value));
  };

  const searchList = useAppSelector(getHelpInfo('faq'));

  return (
    <>
      <TopNavBar isAccount={true} leftComponent={<NavbarTitle title='Help' showBackBtn={true} />} />
      <div className={classes.root}>
        <BackButton onClickHandler={goBack} />
        <div className={classes.mobileHead}>
          <NavbarTitle title='Frequently Asked Questions' showBackBtn={true} />
        </div>
        <div className={classes.title}>Frequently Asked Questions</div>
        <SvgFaqIcon className={classes.icon} width={32} height={32} />
        <div className={classes.searchWrap}>
          <SearchField placeholder={'Search help articles...'} onChange={onKeywordChange} searchValue={faq} clearIcon={true} />
        </div>
        {faq ? (
          <HelpInfoSearchResults list={searchList} request={faq} />
        ) : (
          <>
            {(isRestaurant ? faqsListRestaurant : faqsListSupplier).map((el) => (
              <Link to={`/account/help/faqs/${el.id}`} className={clsx(classes.rowItem)} key={el.id}>
                <h2 className={classes.tileTitle}>{el.title}</h2>
                <ChevronRight />
              </Link>
            ))}
          </>
        )}
      </div>
    </>
  );
};
