import React, { useEffect, useRef } from 'react';

import { Field, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { useUpdateSupplierProfileMutation } from '../../../../api/company';
import { Contact, ContactType, SupplierProfile } from '../../../../api/company/types';
import { ButtonsBlock } from '../../../../shared/components/buttons-block';
import { FormikInput } from '../../../../shared/components/formik-input';
import { OverlayModal } from '../../../../shared/components/overlay-modal';
import isValidPhoneNumberForCountry from '../../../../shared/helpers/validateIdMobileNumber';
import { LocalStorageService } from '../../../../shared/services/localStorage.service';
import { ProfileModals } from '../index';

import { useStyles } from './style';

interface Values {
  office_phone: string;
  office_email: string;
  order_email: string;
  order_phone: string;
}

interface Props {
  values: Partial<Contact>[];
  onClose: () => void;
  onSubmit: (formValues: Partial<SupplierProfile>) => void;
  isModalOpen: boolean;
  isOnBoarding: boolean;
  setNextStep: (step: ProfileModals) => void;
  companyId?: number;
}

const ContactsDetailsSchema = Yup.object().shape({
  office_email: Yup.string().email('Enter correct email!'),
  order_email: Yup.string().email('Enter correct email!'),
});

export const CompanyDetailsForm: React.FC<Props> = ({ values, companyId, onClose, onSubmit, isModalOpen, isOnBoarding, setNextStep }) => {
  const classes = useStyles();
  const ref = useRef<HTMLFormElement | null>(null);
  const isWhatsAppOrdering = LocalStorageService.getItem('country') === 'ID';

  const [updateProfile, { isSuccess, isLoading }] = useUpdateSupplierProfileMutation();

  const validateForm = (formData: Values) => {
    const errors: Partial<Values> = {};
    if (!formData.order_phone) {
      errors.order_phone = 'Required for What`sApp notifications';
    }
    if (formData.order_phone && !isValidPhoneNumberForCountry(formData.order_phone)) {
      errors.order_phone = 'Invalid mobile phone number';
    }
    return errors;
  };

  const officeEmail = values.find((el) => el.contact_type === ContactType.OFFICE)?.email || '';
  const orderEmail = values.find((el) => el.contact_type === ContactType.ORDER)?.email || '';
  const orderPhone = values.find((el) => el.contact_type === ContactType.ORDER)?.phone_number || '';
  const officePhone = values.find((el) => el.contact_type === ContactType.OFFICE)?.phone_number || '';

  const onSubmitForm = ({ office_email, order_email, office_phone, order_phone }: Values, formikHelpers: FormikHelpers<Values>) => {
    if (isOnBoarding) {
      setNextStep(ProfileModals.ORDER_DETAILS);
      const officeContact: Partial<Contact> = {
        contact_type: ContactType.OFFICE,
        email: office_email,
        name: null,
        phone_number: office_phone,
        company_id: companyId,
      };
      const orderContact: Partial<Contact> = {
        contact_type: ContactType.ORDER,
        email: order_email,
        name: null,
        phone_number: order_phone,
        company_id: companyId,
      };
      const contacts: Partial<Contact>[] = [];
      (office_email || office_phone) && contacts.push(officeContact);
      (order_email || order_phone) && contacts.push(orderContact);
      contacts.length && onSubmit({ contacts });
    } else {
      const formData = new FormData();
      const officeContactId = values.find((el) => el.contact_type === ContactType.OFFICE)?.id;
      const orderContactId = values.find((el) => el.contact_type === ContactType.ORDER)?.id;
      (office_email || office_phone) && formData.append(`company[contacts_attributes][0][contact_type]`, ContactType.OFFICE);
      formData.append('company[contacts_attributes][0][phone_number]', office_phone || '');
      formData.append('company[contacts_attributes][0][email]', office_email || '');
      officeContactId && formData.append('company[contacts_attributes][0][id]', officeContactId.toString());

      const isOfficeDataExists = office_email || office_phone || officeContactId;

      (order_email || order_phone) &&
        formData.append(`company[contacts_attributes][${isOfficeDataExists ? 1 : 0}][contact_type]`, ContactType.ORDER);
      formData.append(`company[contacts_attributes][${isOfficeDataExists ? 1 : 0}][phone_number]`, order_phone || '');
      formData.append(`company[contacts_attributes][${isOfficeDataExists ? 1 : 0}][email]`, order_email || '');
      order_email && formData.append(`company[contacts_attributes][${isOfficeDataExists ? 1 : 0}][is_order_email]`, 'true');
      orderContactId && formData.append(`company[contacts_attributes][${isOfficeDataExists ? 1 : 0}][id]`, orderContactId.toString());
      companyId && updateProfile({ formData, id: companyId });
    }
    formikHelpers.setSubmitting(false);
  };

  const closeHandler = () => {
    isOnBoarding ? setNextStep(ProfileModals.UPLOAD_LOGO) : onClose();
  };

  useEffect(() => {
    !isModalOpen && ref.current?.reset();
  }, [isModalOpen]);

  useEffect(() => {
    isSuccess && onClose();
  }, [isSuccess]);

  return (
    <OverlayModal title='Edit Company Details' onClose={onClose} isOpen={isModalOpen} animate={!isOnBoarding}>
      <Formik
        initialValues={{
          office_email: officeEmail,
          order_email: orderEmail,
          office_phone: officePhone,
          order_phone: orderPhone,
        }}
        validationSchema={ContactsDetailsSchema}
        onSubmit={onSubmitForm}
        enableReinitialize={true}
        validate={isWhatsAppOrdering ? validateForm : undefined}
      >
        {({ submitForm }) => (
          <Form className={classes.formContainer} ref={ref}>
            <div className={classes.fieldWrap}>
              <Field component={FormikInput} name='office_email' label='Office Email' placeholder='Office Email' />
            </div>
            <div className={classes.fieldWrap}>
              <Field component={FormikInput} name='order_email' label='Email for Orders' placeholder='Email for Orders' />
            </div>
            <div className={classes.fieldWrap}>
              <Field
                component={FormikInput}
                name='office_phone'
                type='tel'
                label={isWhatsAppOrdering ? 'Contact Phone Number' : 'Office Phone Number'}
                placeholder='Contact Number'
              />
            </div>
            <div className={classes.fieldWrap}>
              <Field
                component={FormikInput}
                name='order_phone'
                type='tel'
                label={isWhatsAppOrdering ? 'Phone number for orders' : 'Contact Phone Number'}
                placeholder='Contact Number'
              />
            </div>
            <ButtonsBlock
              isSubmitDisabled={isLoading && !isOnBoarding}
              onSave={submitForm}
              onCancel={closeHandler}
              saveTitle={isOnBoarding ? 'Next' : 'Update'}
              cancelTitle={isOnBoarding ? 'Back' : 'Cancel'}
            />
          </Form>
        )}
      </Formik>
    </OverlayModal>
  );
};
