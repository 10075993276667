import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';
import { Sizes } from '../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    wrap: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
    },
    wrapClearable: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    countrySearch: {
      '& ul': {
        minHeight: 300,
        borderColor: colorVariables.steelGrey,
        '& li:hover': {
          background: colorVariables.blueLight,
        },
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '250px !important',
      },
      '& input': {
        border: `1px solid ${colorVariables.steelGrey}`,
        borderRadius: 5,
        '&:hover': {
          border: `1px solid ${colorVariables.blue}`,
        },
        '&:focus': {
          border: `1px solid ${colorVariables.blue}`,
        },
        '&::placeholder': {
          color: `${colorVariables.grey60}`,
        },
      },
    },
    countrySearchClearable: {
      '& ul': {
        width: 220,
        minHeight: 300,
        borderColor: colorVariables.steelGrey,
        transform: 'translateX(-40px)',
        '& li:hover': {
          background: colorVariables.blueLight,
        },
        [theme.breakpoints.down(Sizes.mobile)]: {
          width: 180,
          '& li': {
            fontSize: '14px !important',
          },
        },
      },
      '& input': {
        border: `1px solid ${colorVariables.steelGrey}`,
        borderRadius: 5,
        '&:hover': {
          border: `1px solid ${colorVariables.blue}`,
        },
        '&:focus': {
          border: `1px solid ${colorVariables.blue}`,
        },
        '&::placeholder': {
          color: `${colorVariables.grey60}`,
        },
        [theme.breakpoints.down(Sizes.mobile)]: {
          fontSize: '14px !important',
        },
      },
    },
    btn: {
      width: '300px !important',
      color: `${colorVariables.navy} !important`,
      borderColor: `${colorVariables.steelGrey} !important`,
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '250px !important',
      },
    },
    btnClearable: {
      color: `${colorVariables.navy} !important`,
      borderColor: 'transparent !important',
      '&>span': {
        marginRight: '15px !important',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        fontSize: '14px !important',
      },
    },
    close: {
      position: 'absolute',
      top: 12,
      right: 25,
      cursor: 'pointer',
      color: colorVariables.grey60,
      fontSize: 18,
      '&:hover': {
        color: colorVariables.grey80,
      },
    },
  }),
);
