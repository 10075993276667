import React, { useEffect, useState } from 'react';

import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx';

import { useUpdateSupplierProfileMutation } from '../../../../api/company';
import { CompanyCategories, SupplierProfile } from '../../../../api/company/types';
import { ButtonsBlock } from '../../../../shared/components/buttons-block';
import { OverlayModal } from '../../../../shared/components/overlay-modal';
import { ProductsCategories, productsCategoriesList } from '../../../../shared/constants/products';
import { getCategoryId } from '../../../../shared/helpers/getCategoryId';
import { setGtmDatalayer } from '../../../../shared/helpers/setGtmDatalayer';
import { ProfileModals } from '../index';

import { useStyles } from './style';

interface Props {
  values: ProductsCategories[];
  onClose: () => void;
  onSubmit: (data: Partial<SupplierProfile>) => void;
  isModalOpen: boolean;
  isOnBoarding: boolean;
  setNextStep: (step: ProfileModals | null) => void;
  companyCategories: CompanyCategories[];
  companyId?: number;
  saveUpdates?: () => void;
  disableSubmit?: boolean;
}

export const ProductCategories: React.FC<Props> = ({
  companyCategories,
  values,
  onClose,
  onSubmit,
  isModalOpen,
  isOnBoarding,
  setNextStep,
  companyId,
  saveUpdates,
  disableSubmit,
}) => {
  const classes = useStyles();
  const [categories, setCategories] = useState<ProductsCategories[]>([]);

  const [updateProfile, { isSuccess, isLoading }] = useUpdateSupplierProfileMutation();

  const onSetCategory = (category: ProductsCategories) => {
    if (categories.includes(category)) {
      setCategories((state) => state.filter((item) => item !== category));
    } else {
      setCategories((state) => [...state, category]);
    }
  };

  const handleSubmit = () => {
    const formData = new FormData();
    const categoriesToAdd = categories.filter((category) => !companyCategories.some((cc) => cc.category_id === getCategoryId(category)));
    const categoriesToRemove = companyCategories.filter((cc) => !categories.some((category) => cc.category_id === getCategoryId(category)));
    categoriesToAdd.forEach((category, idx) => {
      formData.append(`company[company_categories_attributes][${idx}][category_id]`, getCategoryId(category).toString());
    });
    categoriesToRemove.forEach((category, idx) => {
      formData.append(`company[company_categories_attributes][${idx + categoriesToAdd.length}][id]`, category.id.toString());
      formData.append(`company[company_categories_attributes][${idx + categoriesToAdd.length}][_destroy]`, 'true');
    });

    if (isOnBoarding) {
      setNextStep(null);
    } else if (companyId && (categoriesToAdd.length || categoriesToRemove.length)) {
      updateProfile({ formData, id: companyId });
      setGtmDatalayer({
        event: 'profile',
        eventCategory: 'product_type',
      });
    }
  };

  const closeHandler = () => {
    isOnBoarding ? setNextStep(ProfileModals.DELIVERY_DETAILS) : onClose();
  };

  useEffect(() => {
    setCategories(values);
  }, [values, isModalOpen]);

  useEffect(() => {
    isSuccess && onClose();
  }, [isSuccess]);

  useEffect(() => {
    isOnBoarding &&
      categories.length &&
      onSubmit({
        categories,
      });
  }, [isOnBoarding, categories]);

  return (
    <OverlayModal title='WHAT PRODUCTS DO YOU SELL' isOpen={isModalOpen} onClose={onClose} animate={!isOnBoarding}>
      <div className={classes.container}>
        <div className={classes.subTitle}>Select all that apply</div>
        <div className={classes.productsBox}>
          {productsCategoriesList.map(({ title, icon }) => (
            <div
              key={title}
              className={clsx({
                [classes.productItem]: true,
                [classes.productItemSelected]: categories.includes(title),
              })}
              onClick={onSetCategory.bind(null, title)}
            >
              <div className={classes.productIcon}>{icon}</div>
              {title}
              <CheckIcon className={classes.checkIcon} />
            </div>
          ))}
        </div>
        <ButtonsBlock
          isSubmitDisabled={(!categories.length && !isOnBoarding) || isLoading || disableSubmit}
          onSave={isOnBoarding && saveUpdates ? saveUpdates : handleSubmit}
          onCancel={closeHandler}
          saveTitle={isOnBoarding ? 'Save Profile' : 'Update'}
          cancelTitle={isOnBoarding ? 'Back' : 'Cancel'}
        />
      </div>
    </OverlayModal>
  );
};
