import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../../../styles/colorVariables';
import { Sizes } from '../../../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: 480,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
      },
    },
    title: {
      fontSize: 20,
      fontWeight: 700,
      textAlign: 'center',
      marginBottom: 10,
    },
    subTitle: {
      textAlign: 'center',
      marginBottom: 20,
    },
    deleteIcon: {
      width: 48,
      height: 48,
      marginBottom: 25,
      color: colorVariables.green,
    },
  }),
);
