import React, { useEffect, useState } from 'react';

import clsx from 'clsx';
import { useDebouncedCallback } from 'use-debounce';

import SvgLogoWhite from '../../../assets/images/logo/LogoWhite';

import { useStyles } from './style';
import { useCurrentTheme } from '../../../api/admin-themes/hooks';
import { ThemeColors } from '../../../api/admin-themes/types';

interface Props {
  loading: boolean;
  dataLoaded: boolean;
  boxClasses?: string;
  resetProgress?: boolean;
}

export const OpLoading: React.FC<Props> = ({ loading, children, boxClasses = '', dataLoaded, resetProgress }) => {
  const [progress, setProgress] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [initialLoaded, setInitialLoaded] = useState(false);
  const { logoUrl, colors } = useCurrentTheme();
  const classes = useStyles({ progress, color: colors[ThemeColors.btnPrimaryBg] });

  const debouncedHideLoading = useDebouncedCallback(() => {
    setLoaded(true);
  }, 150);

  const updateProgress = (isLoading: boolean) => {
    if (isLoading && progress <= 50) {
      const interval = setInterval(() => {
        setProgress((prev) => {
          if (prev + 10 > 49) {
            clearInterval(interval);
            return 51;
          }
          return prev + 10;
        });
      }, 300);
    }
    if (isLoading && progress > 50 && progress <= 95) {
      const interval = setInterval(() => {
        setProgress((prev) => {
          if (prev + 2 > 95) {
            clearInterval(interval);
            return 95;
          }
          return prev + 2;
        });
      }, 1800);
    }
    if (!isLoading && progress < 100 && progress > 0) {
      const interval = setInterval(() => {
        setProgress((prev) => {
          if (prev + 20 >= 100) {
            clearInterval(interval);
            return 100;
          }
          return prev + 20;
        });
      }, 200);
    }
  };

  useEffect(() => {
    updateProgress(loading);
  }, [loading]);

  useEffect(() => {
    if (progress === 51) {
      updateProgress(loading);
    }
    progress === 100 && debouncedHideLoading();
  }, [progress]);

  useEffect(() => {
    if (!loading && dataLoaded) {
      setInitialLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (!resetProgress) {
      return;
    }
    if (progress === 100 && loading) {
      setProgress(0);
      setLoaded(false);
    }
    if (progress === 0 && loading) {
      setProgress(17);
    }
  }, [progress, loading, resetProgress]);

  return (
    <>
      {loaded || initialLoaded ? (
        children
      ) : progress > 0 ? (
        <div className={clsx(classes.root, boxClasses)}>
          <div className={classes.loadingBox}>
            <div className={classes.logoBoxWrap}>
              <div className={classes.logoBox}>
                {logoUrl ? (
                  <img src={logoUrl} alt='logo' style={{ objectFit: 'contain', maxWidth: 140, maxHeight: 140 }} />
                ) : (
                  <SvgLogoWhite />
                )}
              </div>
            </div>
            <div className={classes.progress}>
              <div className={classes.filter} />-
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
