import React from 'react';
import { ImageUpload } from '../../../../../../shared/components/image-upload';
import { OverlayModal } from '../../../../../../shared/components/overlay-modal';

import { useStyles } from './style';

interface Props {
  onClose: () => void;
  onSubmit: (blob: Blob) => void;
  isModalOpen: boolean;
}

export const UploadProductLogo: React.FC<Props> = React.memo(({ onClose, onSubmit, isModalOpen }) => {
  const classes = useStyles();

  return (
    <OverlayModal animate={true} isOpen={isModalOpen} onClose={onClose} title={<div className={classes.title}>Upload product image</div>}>
      <div className={classes.container}>
        <div className={classes.uploadBox}>
          {isModalOpen && (
            <ImageUpload
              saveImage={onSubmit}
              cropTitle=''
              closeModal={onClose}
              tipElement={
                <div className={classes.tipBox}>
                  <div className={classes.tipIcon}>!</div>
                  <div> Tip: square images with a white background work best</div>
                </div>
              }
            />
          )}
        </div>
      </div>
    </OverlayModal>
  );
});
