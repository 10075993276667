import { createAction } from '@reduxjs/toolkit';
import { Inventory, StocktakeMode } from '../../api/inventory/types';

export const setStocktakeMode = createAction<StocktakeMode | null>('SET_STOCKTAKE_MODE');

export const setStocktakeBarcode = createAction<string>('SET_STOCKTAKE_BARCODE');

export const setStocktakeProducts = createAction<Inventory[]>('SET_STOCKTAKE_PRODUCTS');

export const setStocktakeAppliedProduct = createAction<number>('SET_STOCKTAKE_DEFAULT_PRODUCT');

export const setStockCount = createAction<number>('SET_STOCK_COUNT');

export const setProductSettings = createAction<{supplierId: number, isDefault: boolean, isTransfer: boolean}>('SET_SUPPLIER_SETTINGS');

export const setStocktakeSettings = createAction<boolean>('SET_STOCKTAKE_SETTINGS');
