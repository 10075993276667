import * as React from 'react';

function SvgDone(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M39.941 0H8C3.588 0 0 3.588 0 8v32c0 4.412 3.588 8 8 8h32c4.412 0 8-3.588 8-8V8c-.059-4.412-3.647-8-8.059-8zM8 2.059h32c3.235 0 5.882 2.647 5.882 5.941v32c0 3.235-2.647 5.882-5.94 5.882H8a5.925 5.925 0 01-5.941-5.94V8A5.925 5.925 0 018 2.059zm2.177 23.53a1.085 1.085 0 01-.295-.707c0-.294.118-.53.295-.706.176-.176.47-.294.705-.294.236 0 .53.118.706.294l7.294 7.295L35 15.353c.176-.176.47-.294.706-.294.294 0 .53.118.706.294.176.176.294.47.294.706 0 .235-.118.53-.294.706L19.647 33.588c-.176.177-.47.294-.706.294-.235 0-.53-.117-.706-.294l-8.058-8z'
        fill='#51D9B0'
      />
    </svg>
  );
}

export default SvgDone;
