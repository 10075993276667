import React, { CSSProperties } from 'react';

import { IconButton } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseButton from '@material-ui/icons/Close';
import clsx from 'clsx';

import { useStyles } from './style';

interface Props {
  title: string;
  onConfirm: () => void;
  onCancel: () => void;
  onClose?: () => void;
  customClass?: string;
  style?: CSSProperties;
  skipContinueText?: boolean;
  continueText?: string;
  redMode?: boolean;
}

export const Dialog: React.FC<Props> = React.memo(
  ({
    title,
    onConfirm,
    onCancel,
    customClass = '',
    style,
    skipContinueText,
    onClose,
    continueText = 'Do you want to continue?',
    redMode,
  }) => {
    const classes = useStyles();
    return (
      <div>
        <div className={clsx([classes.root, customClass, redMode && classes.redMode])} style={style}>
          <div className={classes.title}>{title}</div>
          {skipContinueText ? null : <div className={classes.confirmText}>{continueText}</div>}
          <div className={classes.actions}>
            <IconButton onClick={onConfirm} className={classes.confirmBtn}>
              <CheckIcon />
            </IconButton>
            <IconButton onClick={onCancel} className={clsx(classes.cancelBtn, redMode && classes.cancelBtnRed)}>
              <CloseButton />
            </IconButton>
          </div>
        </div>
        <div className={classes.overlay} onClick={onClose || onCancel} />
      </div>
    );
  },
);
