import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../styles/colorVariables';
import { Sizes } from '../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: '102px 0 70px 0',
      [theme.breakpoints.up(Sizes.mobile)]: {
        paddingTop: 120,
        paddingLeft: 90,
      },
    },
    header: {
      fontSize: 13,
      backgroundColor: 'rgba(152, 165, 183, 0.1)',
      padding: '18px 24px',
      marginTop: -5,
      color: colorVariables.grey80,
      marginBottom: 50,
    },
    bottomNavWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    addBtnBox: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: 27,
      paddingRight: 20,
      borderTop: `1px solid ${colorVariables.steelGrey}`,
    },
    saveBtn: {},
    calendarWrap: {
      minHeight: 340,
    },
    daysListHead: {
      height: 48,
      backgroundColor: 'rgba(152, 165, 183, 0.1)',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '10px 24px',
      alignItems: 'center',
    },
    daysListHeadTitle: {
      color: colorVariables.grey60,
      fontWeight: 700,
      fontSize: 12,
      textTransform: 'uppercase',
    },
    daysList: {
      padding: '20px 5px',
      width: 'calc(100% - 36px)',
      margin: '0 auto',
      borderBottom: `1px solid ${colorVariables.steelGrey}`,
    },
    holidaysCell: {
      position: 'relative',
      display: 'inline-flex',
      width: '25%',
      maxWidth: 90,
      height: 26,
    },
    closeIconWrap: {
      width: 18,
      height: 18,
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0,
      border: '1px solid #DADADA',
      cursor: 'pointer',
      position: 'absolute',
      right: 25,
      top: 1,
      backgroundColor: colorVariables.white,
      boxShadow: '0 0 0 6px #ffffff',
      '&:hover': {
        border: `1px solid ${colorVariables.grey60}`,
      },
      '&:hover svg': {
        color: colorVariables.grey60,
      },
    },
    closeIcon: {
      color: '#DADADA',
      fontSize: 13,
    },
    saveBtnDisabled: {
      opacity: 0.4,
    },
  }),
);
