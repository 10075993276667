import * as React from 'react';

function SvgFilterIconCustomer(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M7.19 15.612h0a2.887 2.887 0 002.01 2.066V19.1a.8.8 0 001.6 0v-1.423a2.887 2.887 0 000-5.554V.9a.8.8 0 10-1.6 0v11.223a2.887 2.887 0 00-2.01 3.49zm4.11-.712a1.3 1.3 0 11-2.6 0 1.3 1.3 0 012.6 0zM.19 6.512h0A2.887 2.887 0 002.2 8.577V19.1a.8.8 0 001.6 0V8.578a2.887 2.887 0 000-5.555V.9a.8.8 0 10-1.6 0v2.123a2.887 2.887 0 00-2.01 3.49zM4.3 5.8a1.3 1.3 0 11-2.6 0 1.3 1.3 0 012.6 0zM16.2.9v3.523a2.887 2.887 0 000 5.555V19.1a.8.8 0 001.6 0V9.978a2.887 2.887 0 000-5.555V.9a.8.8 0 10-1.6 0zm2.1 6.3a1.3 1.3 0 11-2.6 0 1.3 1.3 0 012.6 0z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth={0.2}
      />
    </svg>
  );
}

export default SvgFilterIconCustomer;
