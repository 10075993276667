import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../../../styles/colorVariables';
import { Sizes } from '../../../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    formBox: {
      height: 'calc(100% - 88px)',
      [theme.breakpoints.up(Sizes.mobile)]: {
        width: 475,
      },
      [theme.breakpoints.between(Sizes.mobile, Sizes.desktop)]: {
        maxHeight: 'calc(100vh - 140px)',
        overflowY: 'scroll',
      },
    },
    root: {
      position: 'relative',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    sortBlock: {
      width: '90%',
      margin: '20px auto',
      [theme.breakpoints.up(Sizes.desktop)]: {
        display: 'none',
      },
    },
    blockTitle: {
      fontSize: 12,
      fontWeight: 700,
      color: colorVariables.navy,
      marginBottom: 15,
      textTransform: 'uppercase',
      [theme.breakpoints.up(Sizes.desktop)]: {
        marginBottom: 25,
      },
    },
    blockSubTitle: {
      color: colorVariables.grey60,
      [theme.breakpoints.up(Sizes.desktop)]: {
        marginBottom: 10,
      },
    },
    stockBlock: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 20,
      '& label': {
        width: '30%',
        marginRight: 0,
        flexShrink: 0,
        marginLeft: 0,
      },
      '& label:nth-child(2)': {
        width: '40%',
      },
    },
    statusBlock: {
      display: 'flex',
      marginBottom: 20,
      '& label': {
        width: '33%',
        marginRight: 0,
        flexShrink: 0,
        marginLeft: 0,
      },
    },
    filterBlock: {
      width: '90%',
      margin: '0 auto',
    },
    btnBlock: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 20,
      padding: 20,
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: '40px 20px',
        justifyContent: 'center',
      },
      [theme.breakpoints.down(400)]: {
        padding: '30px 10px',
        '& button': {
          minWidth: '40%',
        },
      },
    },
    btnBlockFullScreen: {
      marginTop: 'auto',
    },
    actions: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    actionsText: {
      textTransform: 'uppercase',
      fontSize: 11,
      fontWeight: 700,
      color: colorVariables.grey80,
    },
    actionsIcon: {
      fontSize: 16,
      color: colorVariables.navy,
      opacity: 0.5,
    },
    triggerBox: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      marginTop: 15,
    },
    costBlock: {
      marginBottom: 10,
    },
    costFields: {
      display: 'flex',
      alignItems: 'center',
    },
    costFieldWrap: {
      width: 130,
      [theme.breakpoints.up(Sizes.mobile)]: {
        width: 90,
      },
    },
    divider: {
      width: 32,
      textAlign: 'center',
      color: colorVariables.grey60,
    },
    gstBox: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 15,
      [theme.breakpoints.up(Sizes.mobile)]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        flex: 1,
        paddingLeft: 40,
        marginTop: 0,
      },
    },
    gstTitle: {
      marginRight: 50,
      marginBottom: 12,
    },
    gstFields: {
      margin: 0,
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      paddingRight: 20,
      marginTop: -5,
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'block',
        '&>*': {
          marginRight: 40,
        },
      },
    },
    notesBox: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      paddingLeft: 10,
    },
    notesItemWrap: {
      width: '45%',
    },
    costBoxWrap: {
      [theme.breakpoints.up(Sizes.mobile)]: {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
    datesItem: {
      width: 130,
      height: 48,
      border: `1px solid ${colorVariables.steelGrey}`,
      borderRadius: 5,
      lineHeight: '48px',
      padding: '0 15px',
      fontSize: 16,
      color: colorVariables.navy,
      '&:hover': {
        borderColor: colorVariables.blue,
        cursor: 'pointer',
      },
      [theme.breakpoints.down(Sizes.desktop)]: {
        height: 36,
        lineHeight: '36px',
        fontSize: 14,
      },
    },
    datesWrap: {
      marginBottom: 20,
    },
    placeholder: {
      color: colorVariables.grey60,
    },
    datesBox: {
      display: 'flex',
      alignItems: 'center',
    },
    costTitle: {
      marginBottom: 7,
    },
    deliveryToday: {
      padding: '8px 0',
    },
  }),
);
