import * as React from 'react';

function SvgBilling(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 16 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M13.703 20h-.106a1.36 1.36 0 01-1.267-.858.622.622 0 00-.58-.392.623.623 0 00-.58.392A1.36 1.36 0 019.904 20h-.058a1.36 1.36 0 01-1.266-.858.623.623 0 00-.58-.392.623.623 0 00-.58.392A1.36 1.36 0 016.154 20h-.058a1.36 1.36 0 01-1.266-.858.623.623 0 00-.58-.392.623.623 0 00-.58.392A1.36 1.36 0 012.404 20h-.107C1.307 20 .5 19.194.5 18.203V1.797C.5.807 1.306 0 2.297 0h.107c.56 0 1.056.337 1.266.858a.623.623 0 00.58.392.623.623 0 00.58-.392A1.36 1.36 0 016.096 0h.058c.56 0 1.056.337 1.266.858A.623.623 0 008 1.25a.623.623 0 00.58-.392A1.36 1.36 0 019.846 0h.058c.56 0 1.056.337 1.266.858a.623.623 0 00.58.392.623.623 0 00.58-.392A1.36 1.36 0 0113.597 0h.106c.99 0 1.797.806 1.797 1.797v16.406c0 .99-.806 1.797-1.797 1.797zm-1.953-2.5c.77 0 1.453.462 1.74 1.176.018.045.06.074.107.074h.106a.547.547 0 00.547-.547V1.797a.547.547 0 00-.547-.547h-.106a.115.115 0 00-.107.074A1.867 1.867 0 0111.75 2.5c-.77 0-1.453-.461-1.74-1.176a.115.115 0 00-.106-.074h-.058a.115.115 0 00-.106.074A1.867 1.867 0 018 2.5c-.77 0-1.453-.461-1.74-1.176a.115.115 0 00-.106-.074h-.058a.115.115 0 00-.106.074A1.867 1.867 0 014.25 2.5c-.77 0-1.453-.461-1.74-1.176a.115.115 0 00-.106-.074h-.107a.547.547 0 00-.547.547v16.406c0 .302.245.547.547.547h.107c.046 0 .088-.03.106-.074A1.867 1.867 0 014.25 17.5c.77 0 1.453.462 1.74 1.176.018.045.06.074.106.074h.058c.046 0 .088-.03.106-.074A1.867 1.867 0 018 17.5c.77 0 1.453.462 1.74 1.176.018.045.06.074.106.074h.058c.046 0 .088-.03.106-.074a1.867 1.867 0 011.74-1.176z'
        fill='currentColor'
      />
      <path
        d='M8 9.375c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25a.625.625 0 001.25 0 2.504 2.504 0 00-1.875-2.42V5a.625.625 0 00-1.25 0v.704A2.505 2.505 0 005.5 8.125c0 1.379 1.121 2.5 2.5 2.5.69 0 1.25.56 1.25 1.25s-.56 1.25-1.25 1.25-1.25-.56-1.25-1.25a.625.625 0 10-1.25 0c0 1.163.798 2.143 1.875 2.42V15a.625.625 0 101.25 0v-.704a2.504 2.504 0 001.875-2.421c0-1.379-1.121-2.5-2.5-2.5z'
        fill='currentColor'
      />
    </svg>
  );
}

export default SvgBilling;
