import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';
import { Sizes } from '../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    accountContainer: {
      paddingTop: 63,
      backgroundColor: colorVariables.white,
      [theme.breakpoints.down(Sizes.desktop)]: {
        padding: '70px 0 0 94px',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: '110px 0 80px 0',
      },
    },
    accountContainerCustomer: {
      [theme.breakpoints.between(Sizes.mobile, Sizes.desktop)]: {
        padding: '50px 0 0 0',
      },
    },
    accountHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      backgroundColor: colorVariables.white,
      height: 127,
      borderBottom: `1px solid ${colorVariables.steelGrey}`,
      paddingBottom: 30,
      paddingLeft: 55,
      paddingRight: 55,
      [theme.breakpoints.down(Sizes.desktop)]: {
        display: 'none',
      },
    },
    accountTitle: {
      fontSize: 32,
      lineHeight: '50px',
      fontWeight: 700,
    },
    accountBody: {
      display: 'flex',
    },
    accountContent: {
      width: '80%',
      padding: '25px 200px 50px 50px',
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: '100%',
        padding: '40px 50px',
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        padding: 40,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: 0,
      },
    },
    title: {
      fontSize: 24,
      lineHeight: '36px',
      fontWeight: 700,
      marginBottom: 30,
      [theme.breakpoints.down(Sizes.desktop)]: {
        display: 'none',
      },
    },
    tableHead: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flex: 1,
      backgroundColor: 'rgba(242, 243, 247, 0.6)',
      height: 46,
      padding: '12px 16px',
    },
    titleBox: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down(Sizes.desktop)]: {
        paddingBottom: 15,
        justifyContent: 'flex-end',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        justifyContent: 'center',
        padding: 10,
        marginBottom: 10,
      },
    },
    titleBoxBtns: {
      display: 'flex',
      [theme.breakpoints.down(Sizes.mobile)]: {
        justifyContent: 'space-between',
        flex: 1,
      },
    },
    searchWrap: {
      width: 380,
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 250,
      },
    },
    importBtn: {
      fontSize: 14,
      textTransform: 'uppercase',
      '& svg': {
        color: colorVariables.green,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        fontSize: 12,
        height: 36,
        padding: 0,
        lineHeight: '36px',
        minWidth: 95,
        marginRight: -5,
        marginLeft: 10,
      },
      [theme.breakpoints.between(Sizes.mobile, Sizes.desktop)]: {
        marginTop: 8,
      },
    },
    hCell: {
      display: 'flex',
    },
    colHeadText: {
      fontSize: 12,
      fontWeight: 700,
      textTransform: 'uppercase',
      color: colorVariables.grey60,
    },
    sortTrigger: {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        color: colorVariables.grey60,
        fontSize: 16,
      },
    },
    name: {
      width: '50%',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '45%',
      },
    },
    lastActive: {
      width: '20%',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    permission: {
      width: '25%',
      display: 'flex',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '40%',
        justifyContent: 'center',
      },
    },
    actions: {
      width: '5%',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '10%',
      },
    },
  }),
);
