import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../../styles/colorVariables';
import { Sizes } from '../../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      width: '100%',
      backgroundColor: colorVariables.white,
      borderRadius: 4,
      padding: '0 20px',
      [theme.breakpoints.down(Sizes.mobile)]: {
        borderRadius: 21,
        padding: 20,
      },
    },
    boxHead: {
      height: 50,
      display: 'flex',
      alignItems: 'center',
      borderBottom: `1px solid rgba(207, 215, 230, 0.3)`,
      fontSize: 12,
      fontWeight: 600,
      [theme.breakpoints.down(Sizes.mobile)]: {
        justifyContent: 'space-between',
        height: 'auto',
        paddingBottom: 10,
        borderBottom: 'none',
      },
    },
    idCol: {
      width: '10%',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    unitCol: {
      width: '12%',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    actionCol: {
      width: '15%',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 150,
      },
    },
    nameCol: {
      width: '20%',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 60,
        paddingRight: 30,
        textAlign: 'center',
      },
    },
    msgCol: {
      width: '38%',
      paddingLeft: 10,
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    rowCol: {
      width: '5%',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    boxContent: {
      height: 205,
      overflowY: 'scroll',
      paddingBottom: 10,
      [theme.breakpoints.down(Sizes.mobile)]: {
        height: 'auto',
        overflowY: 'auto',
        paddingBottom: 0,
      },
    },
  }),
);
