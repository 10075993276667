import React from 'react';

import clsx from 'clsx';
import { Field, FieldProps } from 'formik';

import { FormikDropdown } from '../formik-dropdown';

import { useStyles } from './style';

interface Props {
  name: string;
  options: { label: string; value: string }[];
  customSelectFormControlClass?: string;
  placeholder?: string;
  submitOnChange?: boolean;
}

export const FormikSortDropdown: React.FC<Props> = ({
  name,
  options,
  submitOnChange = true,
  placeholder,
  customSelectFormControlClass = '',
}) => {
  const classes = useStyles();
  return (
    <Field name={name}>
      {(fieldProps: FieldProps) => (
        <FormikDropdown
          customClasses={{
            customSelectClass: classes.customSelectBtn,
            customFormControlClass: clsx(classes.customSelectFormControl, customSelectFormControlClass),
            placeholderClass: '',
          }}
          submitOnChange={submitOnChange}
          options={options}
          {...fieldProps}
          height={34}
          inputFontSize={14}
          placeholder={placeholder}
        />
      )}
    </Field>
  );
};
