import { createStyles, makeStyles } from '@material-ui/core';
import { colorVariables } from '../../../../../../../styles/colorVariables';
import { Sizes } from '../../../../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: 790,
      minHeight: 600,
    },
    title: {
      fontSize: 30,
      lineHeight: '36px',
      fontWeight: 700,
      textAlign: 'center',
      maxWidth: 475,
      margin: '30px auto',
      [theme.breakpoints.down(Sizes.mobile)]: {
        fontSize: 20,
        lineHeight: '26px',
      },
    },
    tabsBox: {
      paddingTop: 20,
      display: 'flex',
      width: 440,
      margin: '0 auto',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      borderBottom: `1px solid #F2F3F7`,
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
      },
    },
    tabItem: {
      userSelect: 'none',
      cursor: 'pointer',
      width: '45%',
      borderBottom: `3px solid ${colorVariables.transparent}`,
      fontWeight: 400,
      paddingBottom: 16,
      textAlign: 'center',
      [theme.breakpoints.down(Sizes.mobile)]: {
        paddingBottom: 10,
        width: '50%',
        fontSize: 12,
      },
    },
    tabItemActive: {
      borderBottom: `3px solid ${colorVariables.green}`,
      fontWeight: 700,
    },
    box: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    posSubTitle: {
      color: '#5B6578',
      maxWidth: 353,
      paddingTop: 35,
      textAlign: 'center',
      lineHeight: '24px',
      marginBottom: 35,
    },
    posBtns: {
      marginTop: 60,
      display: 'flex',
    },
    xlsxBtns: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      paddingTop: 38,
      [theme.breakpoints.down(Sizes.mobile)]: {
        flexDirection: 'column-reverse',
        alignItems: 'center',
      },
    },
    empty: {
      width: 180,
    },
    downloadBtn: {
      height: 48,
      borderRadius: 24,
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginTop: 30,
      },
    },
    actionBtn1: {
      marginLeft: 'auto',
    },
    actionBtn2: {
      marginRight: 'auto',
    },
    uploadContainer: {
      border: '1px dashed #152144',
      borderRadius: 12,
      marginBottom: 20,
      width: '100%',
      height: 170,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 20,
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: 160,
        height: 140,
        borderRadius: 10,
        backgroundColor: colorVariables.white,
        boxShadow: '1px 1px 15px 5px rgba(12, 82, 112, 0.08)',
        border: 'none',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 116,
        height: 116,
        marginBottom: 10,
      },
    },
    uploadIcon: {
      width: 40,
      height: 40,
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 30,
        height: 30,
      },
    },
    uploadDescription: {
      fontSize: 14,
      color: colorVariables.navy,
      marginTop: 20,
      fontWeight: 700,
      [theme.breakpoints.down(Sizes.desktop)]: {
        textAlign: 'center',
        marginTop: 15,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginTop: 10,
        fontSize: 12,
      },
    },
    uploadDescriptionBtn: {
      textDecoration: 'underline',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    focused: {
      border: `2px dashed ${colorVariables.green}`,
    },
    link: {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    formatsBlock: {
      display: 'flex',
      width: 170,
      margin: '50px 0 30px 0',
      justifyContent: 'space-between',
    },
    formatLabel: {
      width: 79,
      textAlign: 'center',
      height: 30,
      lineHeight: '30px',
      borderRadius: 15,
      backgroundColor: '#CFD7E6',
      fontSize: 12,
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    topBox: {
      display: 'flex',
      width: '100%',
      marginBottom: 5,
      justifyContent: 'space-between',
    },
    uploadProgressContainer: {
      width: '100%',
      height: 170,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      marginBottom: 10,
      '&>div': {
        flexGrow: 1,
        marginLeft: 25,
      },
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: 710,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        width: 520,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        '&>div': {
          flexGrow: 1,
          marginLeft: 0,
        },
      },
    },
    fileIcon: {
      width: 32,
      height: 44,
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    doneIcon: {
      fontSize: 18,
      color: colorVariables.blue,
      marginBottom: -2,
    },
    error: {
      color: colorVariables.red,
      width: '100%',
      fontStyle: 'italic',
      '& span': {
        fontWeight: 600,
      },
    },
    boldText: {
      fontWeight: 700,
    },
    uploadWrap: {
      width: '100%',
    },
  }),
);
