import * as React from 'react';

function SvgCategoryHospitality(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M15.627 7.994l.002-.036c-.65-.077-1.155-.355-1.5-.804-.344-.45-.53-1.075-.53-1.85 0-1.03.318-2.08.782-2.87.233-.396.5-.726.782-.956.282-.23.575-.358.857-.358.294 0 .592.129.876.358.284.23.55.56.78.955.459.791.765 1.84.765 2.87 0 .777-.175 1.402-.509 1.852-.332.448-.827.726-1.477.803h0l-.008.002a.605.605 0 00-.199.097c-.056.044-.102.106-.102.186v.001l.397 14.226s0 0 0 0c0 .14-.06.243-.152.313a.583.583 0 01-.349.107.583.583 0 01-.349-.107.377.377 0 01-.152-.313s0 0 0 0l.352-14.226h0v-.001c0-.15-.114-.241-.256-.283l-.01.034zm3.443-2.69a6.52 6.52 0 00-.862-3.186C17.67 1.206 16.907.55 16.02.55c-.865 0-1.628.656-2.172 1.568a6.477 6.477 0 00-.878 3.185c0 .712.156 1.428.526 2.006.365.57.938 1.005 1.767 1.17l-.352 13.95s0 0 0 0a.91.91 0 00.337.768c.21.17.493.253.772.253.28 0 .561-.083.772-.253a.91.91 0 00.337-.768s0 0 0 0l-.308-13.95c.83-.165 1.39-.6 1.745-1.17.36-.579.504-1.294.504-2.006zM9.397.835v.05h0v4.523h-.52V.835a.26.26 0 00-.11-.217.387.387 0 00-.226-.068.387.387 0 00-.227.068.26.26 0 00-.11.217v4.573h-.568V.835a.267.267 0 00-.1-.215.341.341 0 00-.212-.07.341.341 0 00-.212.07.267.267 0 00-.101.215v4.573h-.568V.835a.26.26 0 00-.11-.217.387.387 0 00-.227-.068.387.387 0 00-.226.068.26.26 0 00-.11.217v6.033c0 .721.528 1.356 1.335 1.604l-.38 13.986s0 0 0 0a.85.85 0 00.365.748c.226.164.53.244.83.244.3 0 .604-.08.83-.244a.85.85 0 00.365-.748s0 0 0 0l-.38-13.986c.806-.248 1.335-.883 1.335-1.604V.835a.26.26 0 00-.11-.217.388.388 0 00-.226-.068.387.387 0 00-.227.068.26.26 0 00-.11.217zM8.34 7.995h0-.003a.427.427 0 00-.197.117.259.259 0 00-.079.167v.001l.382 14.181h0v.003a.334.334 0 01-.139.308.622.622 0 01-.366.108.663.663 0 01-.376-.108.367.367 0 01-.164-.311v-.001l.381-14.18h0v-.001a.28.28 0 00-.07-.164.404.404 0 00-.151-.118h0l-.01-.003c-.651-.114-1.105-.607-1.105-1.126v-.89h2.955v.89c0 .52-.455 1.012-1.058 1.126z'
        fill='#fff'
        stroke='#fff'
        strokeWidth={0.1}
      />
    </svg>
  );
}

export default SvgCategoryHospitality;
