import React, { useEffect, useRef, useState } from 'react';

import { Switch } from '@material-ui/core';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { useUpdateSupplierProfileMutation } from '../../../../api/company';
import { SupplierProfile } from '../../../../api/company/types';
import { ThemedButton } from '../../../../shared/components/themed-button';
import { FormikInput } from '../../../../shared/components/formik-input';
import { InfoTooltip } from '../../../../shared/components/info-tooltip';
import { OverlayModal } from '../../../../shared/components/overlay-modal';
import { CustomTimePicker } from '../../../../shared/components/time-picker';
import { getCurrency } from '../../../../shared/helpers/getCurrency';
import { setGtmDatalayer } from '../../../../shared/helpers/setGtmDatalayer';
import { ProfileModals } from '../index';

import { useStyles } from './style';

interface Values {
  cutoff?: string;
  min_order_value: string;
  supplier_id?: number;
  delivery_detail_id?: number;
}

interface Props {
  values: Values;
  onClose: () => void;
  onSubmit: (data: Partial<SupplierProfile>) => void;
  isModalOpen: boolean;
  isOnBoarding: boolean;
  setNextStep: (step: ProfileModals) => void;
  companyId?: number;
}

const OrderDetailsSchema = Yup.object().shape({
  min_order_value: Yup.number().moreThan(-1, 'Should be positive'),
});

export const OrderDetails: React.FC<Props> = ({ values, onClose, onSubmit, isModalOpen, isOnBoarding, setNextStep, companyId }) => {
  const classes = useStyles();
  const ref = useRef<HTMLFormElement | null>(null);
  const [time, setTime] = useState<Date>();
  const [useMinimumOrder, setUseMinimumOrder] = useState(false);

  const onChangeTime = (value: Date) => {
    setTime(value);
  };

  const [updateProfile, { isSuccess, isLoading }] = useUpdateSupplierProfileMutation();

  const onSubmitForm = ({ min_order_value }: Partial<Values>, formikHelpers: FormikHelpers<Partial<Values>>) => {
    if (isOnBoarding) {
      onSubmit({
        min_order_value: useMinimumOrder ? parseFloat(min_order_value || '') : 0,
        cutoff: time ? time.toISOString() : undefined,
      });
      setNextStep(ProfileModals.DELIVERY_DAYS);
    } else {
      const formData = new FormData();
      formData.append(
        'company[delivery_details_attributes][0][minimum_order_value_cents]',
        useMinimumOrder ? (parseFloat(min_order_value || '') * 100).toString() : '0',
      );
      values?.delivery_detail_id && formData.append('company[delivery_details_attributes][0][id]', values.delivery_detail_id.toString());
      values?.supplier_id && formData.append('company[delivery_details_attributes][0][user_id]', values.supplier_id.toString());
      time && formData.append('company[delivery_details_attributes][0][cutoff]', new Date(time).toISOString());
      companyId && updateProfile({ formData, id: companyId });
      values.min_order_value !== min_order_value &&
        setGtmDatalayer({
          event: 'profile',
          eventCategory: 'minimum_order_value_update',
        });
    }
    formikHelpers.setSubmitting(false);
  };

  const closeHandler = () => {
    isOnBoarding ? setNextStep(ProfileModals.COMPANY_DETAILS) : onClose();
  };

  useEffect(() => {
    isSuccess && onClose();
  }, [isSuccess]);

  useEffect(() => {
    values.cutoff && setTime(new Date(values.cutoff));
  }, [values.cutoff]);

  useEffect(() => {
    if (!isModalOpen) {
      !isSuccess && setTime(values?.cutoff ? new Date(values.cutoff) : undefined);
      ref?.current?.reset();
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (!isOnBoarding && values?.min_order_value === '0') {
      setUseMinimumOrder(false);
    } else {
      setUseMinimumOrder(true);
    }
  }, [isOnBoarding, values.min_order_value, isModalOpen]);

  return (
    <OverlayModal onClose={onClose} isOpen={isModalOpen} title='Edit Order Details' animate={!isOnBoarding}>
      <Formik
        initialValues={{ min_order_value: values?.min_order_value || '0' }}
        onSubmit={onSubmitForm}
        validationSchema={OrderDetailsSchema}
        enableReinitialize={true}
      >
        {({ submitForm }) => (
          <Form className={classes.formContainer} ref={ref}>
            <div className={classes.fieldWrap}>
              {!useMinimumOrder ? (
                <Field
                  component={FormikInput}
                  name='min_order_value1'
                  placeholder={`-`}
                  label='Minimum order value'
                  disabled={true}
                  value={''}
                />
              ) : (
                <Field
                  component={FormikInput}
                  name='min_order_value'
                  type='number'
                  placeholder={`${getCurrency()} Minimum order value`}
                  label='Minimum order value'
                />
              )}
              <div>
                <span>Don't use minimum order value</span>
                <Switch
                  checked={!useMinimumOrder}
                  onChange={() => setUseMinimumOrder(!useMinimumOrder)}
                  color='primary'
                  classes={{
                    thumb: classes.thumb,
                    track: classes.track,
                    checked: classes.checked,
                  }}
                />
              </div>
            </div>
            <div className={classes.tooltipWrap}>
              {useMinimumOrder && (
                <InfoTooltip text={`Your customers will be notified if they do not reach the minimum order value ${getCurrency()}.`} />
              )}
            </div>
            <div className={classes.fieldWrap}>
              <div className={classes.label}>Order cut-off time</div>
              <CustomTimePicker time={time || null} onSetTime={onChangeTime} />
            </div>
            <div className={classes.tooltipWrap}>
              <InfoTooltip text='Order cut-off times are for the next available day - Order cut-off times between midnight and 4am will be considered as part of the previous day' />
            </div>
            <div className={classes.btnBlock}>
              <ThemedButton title={isOnBoarding ? 'Back' : 'Cancel'} onClick={closeHandler} buttonStyle='secondary' />
              <ThemedButton
                title={isOnBoarding ? 'Next' : 'Update'}
                onClick={submitForm}
                buttonStyle='primary'
                disabled={isLoading}
                customClass={classes.saveBtn}
              />
            </div>
          </Form>
        )}
      </Formik>
    </OverlayModal>
  );
};
