import { MutableRefObject, useRef } from 'react';

const useFocus = (): [any, () => void, () => void] => {
  const htmlElRef: MutableRefObject<any> = useRef(null);
  const setFocus = (): void => {
    const input = htmlElRef?.current?.querySelector('input') as HTMLInputElement;
    input?.focus();
  };

  const blurInput = (): void => {
    const input = htmlElRef?.current?.querySelector('input') as HTMLInputElement;
    input?.blur();
  };

  return [htmlElRef, setFocus, blurInput];
};

export default useFocus;
