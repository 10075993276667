import { getTeammtesListQueryParamsHelper } from '../helpers';
import { HTTP, rootApi, SuccessResponse } from '../index';
import {
  GetTeammatesListReqParams,
  GetTeammatesResponse,
  InviteTeammateReq,
  InviteTeammateRes,
  Teammate,
  UpdateCustomPermission,
  UpdateTeammateReq,
} from './types';

export const teammatesApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getTeammates: builder.query<GetTeammatesResponse, GetTeammatesListReqParams>({
      query: (args) => {
        const params = {
          ...getTeammtesListQueryParamsHelper(args),
        };
        return {
          url: `/teammates`,
          method: HTTP.GET,
          params,
        };
      },
      providesTags: ['Teammate'],
    }),

    getTeammateById: builder.query<Teammate, number>({
      query: (id) => {
        return {
          url: `/teammates/${id}`,
          method: HTTP.GET,
        };
      },
      providesTags: ['Teammate'],
    }),

    inviteTeammate: builder.mutation<InviteTeammateRes, InviteTeammateReq>({
      query: ({ skipInvalidation, ...body }) => {
        return {
          url: `/teammates`,
          method: HTTP.POST,
          body,
        };
      },
      invalidatesTags: (res, _, args) => (args.skipInvalidation ? [] : ['Teammate']),
    }),

    updateTeammate: builder.mutation<SuccessResponse, UpdateTeammateReq>({
      query: ({ id, skipInvalidation, ...body }) => {
        return {
          url: `/teammates/${id}`,
          method: HTTP.PATCH,
          body,
        };
      },
      invalidatesTags: (res, _, args) => (args.skipInvalidation ? [] : ['Teammate']),
    }),

    deleteTeammate: builder.mutation<SuccessResponse, number>({
      query: (id) => {
        return {
          url: `/teammates/${id}`,
          method: HTTP.DELETE,
        };
      },
      invalidatesTags: ['Teammate'],
    }),

    resetTeammatePassword: builder.mutation<SuccessResponse, number>({
      query: (id) => {
        return {
          url: `/teammates/${id}/reset_password`,
          method: HTTP.PATCH,
        };
      },
      invalidatesTags: ['Teammate'],
    }),

    updateCustomPermissions: builder.mutation<SuccessResponse, UpdateCustomPermission>({
      query: ({ id, ...body }) => {
        return {
          url: `/teammates/${id}/update_custom_permission`,
          method: HTTP.PATCH,
          body,
        };
      },
      invalidatesTags: ['Teammate'],
    }),
  }),

  overrideExisting: true,
});

export const {
  useGetTeammatesQuery,
  useInviteTeammateMutation,
  useDeleteTeammateMutation,
  useResetTeammatePasswordMutation,
  useUpdateTeammateMutation,
  useGetTeammateByIdQuery,
  useUpdateCustomPermissionsMutation,
} = teammatesApi;
