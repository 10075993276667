import * as React from 'react';

function SvgArchive(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M18 5.825h-.175V16A1.825 1.825 0 0116 17.825H4A1.825 1.825 0 012.175 16V5.825H2A1.825 1.825 0 01.175 4V2A1.83 1.83 0 012 .175h16A1.825 1.825 0 0119.825 2v2A1.825 1.825 0 0118 5.825zm0-1.65h.175V1.825H1.825V4.175H18zM4 5.825h-.175V16.175H16.175V5.825H4zm4 2.35h4a.825.825 0 010 1.65H8a.825.825 0 110-1.65z'
        fill='#152144'
        stroke='#fff'
        strokeWidth={0.35}
      />
    </svg>
  );
}

export default SvgArchive;
