import React, { useEffect, useState } from 'react';

import { Menu } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

import { ProductsCategories, productsCategoriesList } from '../../constants/products';
import { ThemedButton } from '../themed-button';
import { CommonCheckbox } from '../common-checkbox';

import { useStyles } from './style';

interface Props {
  onApply: (cat: ProductsCategories | null) => void;
  selectedCategory: ProductsCategories | null;
}

export const FilterByCategory: React.FC<Props> = ({ onApply, selectedCategory }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [category, setCategory] = useState<ProductsCategories | null>(null);

  const handleClick = (event: React.MouseEvent<any>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const checkBoxHandler = (cat: ProductsCategories | null) => {
    setCategory(cat);
  };

  const onSubmit = () => {
    setAnchorEl(null);
    onApply(category);
  };

  const onReset = () => {
    handleClose();
    onApply(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCategory(selectedCategory);
  };

  useEffect(() => {
    setCategory(selectedCategory);
  }, [selectedCategory]);

  return (
    <>
      <div className={classes.trigger} onClick={handleClick}>
        <div className={classes.categoryText} id='category_trigger'>
          {selectedCategory || 'All categories'}
        </div>
        {!anchorEl ? <KeyboardArrowDown className={classes.chevron} /> : <KeyboardArrowUp className={classes.chevron} />}
      </div>
      <Menu
        autoFocus={false}
        anchorEl={anchorEl}
        disableScrollLock={true}
        keepMounted={true}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ paper: classes.dropdownContainer }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <div>
          <div className={classes.categoriesBox}>
            <div key='All' className={classes.categoryItem}>
              <CommonCheckbox checked={!category} name='All' onChange={checkBoxHandler.bind(null, null)} labelPlace='end' />
            </div>
            {productsCategoriesList.map((catItem) => (
              <div key={catItem.title} className={classes.categoryItem}>
                <CommonCheckbox
                  checked={category === catItem.title}
                  name={catItem.title}
                  onChange={checkBoxHandler.bind(null, catItem.title)}
                  labelPlace='end'
                />
              </div>
            ))}
          </div>
          <div className={classes.btnBox}>
            <ThemedButton title='Apply' onClick={onSubmit} width={120} isSmall={true} />
            <ThemedButton width={120} title='Cancel' onClick={onReset} buttonStyle='secondary' isSmall={true} />
          </div>
        </div>
      </Menu>
    </>
  );
};
