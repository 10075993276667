import * as React from 'react';

function SvgPinIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g fill='currentColor'>
        <path d='M10.001 0A7.04 7.04 0 002.97 7.031c0 1.31.363 2.589 1.05 3.698l5.58 8.994A.586.586 0 0010.1 20h.004a.586.586 0 00.498-.285l5.44-9.081a7.023 7.023 0 00.991-3.603A7.04 7.04 0 0010.001 0zm5.034 10.032l-4.945 8.256-5.075-8.177a5.852 5.852 0 01-.881-3.08c0-3.23 2.636-5.867 5.867-5.867 3.23 0 5.863 2.636 5.863 5.867 0 1.06-.29 2.097-.83 3.001z' />
        <path d='M10.001 3.516A3.52 3.52 0 006.485 7.03a3.511 3.511 0 003.516 3.516 3.509 3.509 0 003.516-3.516A3.52 3.52 0 0010 3.516zm0 5.867A2.353 2.353 0 017.649 7.03a2.36 2.36 0 012.352-2.351 2.356 2.356 0 012.348 2.351A2.349 2.349 0 0110 9.383z' />
      </g>
    </svg>
  );
}

export default SvgPinIcon;
