import * as React from 'react';

function SvgSmallGoods(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 34 37' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M33.186 29.304l-1.11-.521c.234-.324.375-.522.385-.522l.047-.073c1.563-3.074 1.152-6.174-1.182-8.503l-4.909-4.908-9.508-9.52a8.817 8.817 0 00-10.38-1.526l-.38-2.647A1.261 1.261 0 003.8.647L2.237 3.434l-1.375.46v-.001a1.261 1.261 0 00-.12 2.345l2.23 1.016a8.8 8.8 0 001.49 10.42l13.98 13.964.453.458a6.821 6.821 0 004.877 2.147 7.518 7.518 0 002.824-.573l.25 1.745a1.353 1.353 0 001.115 1.162c.087.008.174.008.26 0a1.352 1.352 0 001.2-.714l1.896-3.428 1.735-.579a1.375 1.375 0 00.135-2.563v.01zM1.674 5.112l1.073-.36c.3-.098.554-.307.709-.583L4.82 1.71l.412 2.882-.256.208c-.177.151-.349.302-.52.459h-.001c-.243.245-.47.504-.683.776l-2.1-.922zm2.808 2.37l.099-.15c.135-.198.276-.386.422-.568l.099-.105c.12-.14.24-.281.37-.406l.078-.078a8.072 8.072 0 01.85-.698l.286-.203a7.385 7.385 0 018.623.437c-3.934.522-9.41 3.127-10.353 10.421a7.398 7.398 0 01-.474-8.65zm1.75 9.994C6.755 7.88 14.997 7.082 16.654 7.02l3.158 3.157c-3.955.521-9.483 3.127-10.42 10.479l-3.158-3.18zm4.476 4.476c.522-9.613 8.785-10.394 10.421-10.457l3.163 3.163c-3.944.521-9.489 3.126-10.42 10.467l-3.164-3.173zm7.67 7.649L15.2 26.428c.52-9.613 8.774-10.42 10.42-10.457l3.168 3.162c-3.954.521-9.483 3.127-10.41 10.467zm8.107.61l-.35.25.25 1.755c-1.562.703-4.167 1.25-6.496-1.1l-.214-.218c.521-9.593 8.764-10.389 10.42-10.452l.23.229c1.886 1.886 2.22 4.309.943 6.825-.062.094-.245.355-.521.704l-1.709-.777-.334.49a8.968 8.968 0 01-2.219 2.293zm4.387.906c-.328.11-.603.338-.771.64l-1.865 3.45-.584-4.09c.347-.268.676-.559.985-.87a7.84 7.84 0 00.474-.521c.157-.188.302-.355.443-.521l1.657.755 1.386.61-1.725.547z'
        fill='#51D9B0'
      />
      <path
        d='M4.961 11.24a.688.688 0 00.985.063.703.703 0 00.068-.99l-.303-.33a1.978 1.978 0 01-.568-.916.705.705 0 00-1.328.469c.165.53.458 1.012.854 1.402.083.083.172.182.276.301h.016zM7.672 12.927a.703.703 0 000 .995l1.746 1.745a.71.71 0 00.52.209.708.708 0 00.52-1.203l-1.744-1.746a.708.708 0 00-.995 0h-.047zM13.924 20.175c.138.136.327.21.52.203a.704.704 0 00.521-1.198l-1.813-1.819a.704.704 0 00-.995.995l1.767 1.819zM18.524 24.775a.72.72 0 001.016 0 .704.704 0 000-.995l-1.724-1.724a.702.702 0 10-.995.995l1.703 1.724zM22.265 26.627a.704.704 0 00-.985 1.005 24.662 24.662 0 003.345 2.798.703.703 0 10.792-1.162 22.907 22.907 0 01-3.126-2.642h-.026zM7.895 6.446c.406.157.766.418 1.042.755l.125.125c.136.14.325.216.52.208a.702.702 0 00.522-1.198l-.12-.12A3.976 3.976 0 008.5 5.148a.704.704 0 00-.547 1.298h-.057zM13.413 11.725a.698.698 0 00.995 0 .704.704 0 000-.996l-1.922-1.922a.703.703 0 10-.995.99l1.922 1.928zM17.8 16.111c.131.131.309.204.494.204a.703.703 0 00.521-1.199l-1.76-1.714a.708.708 0 00-.996 0 .702.702 0 000 .996l1.74 1.713zM22.271 20.582a.72.72 0 001.016 0 .71.71 0 000-.995l-1.74-1.735a.703.703 0 00-.99.995l1.714 1.735zM27.88 26.715a.7.7 0 00.964.23.704.704 0 00.23-.97 20.42 20.42 0 00-2.945-3.559.704.704 0 00-1.005.985c1.037 1 1.96 2.112 2.756 3.314z'
        fill='#51D9B0'
      />
    </svg>
  );
}

export default SvgSmallGoods;
