import { createAction } from '@reduxjs/toolkit';
import { DeliveryFilters, DeliverySortBy } from '../../api/delivery/types';

export const setDeliveriesPage = createAction<number>('SET_DELIVERIES_PAGE');

export const setDeliveriesKeyword = createAction<string>('SET_DELIVERIES_KEYWORD');

export const setDeliveriesSort = createAction<DeliverySortBy | undefined>('SET_DELIVERIES_SORT');

export const setDeliveriesFilter = createAction<DeliveryFilters | undefined>('SET_DELIVERIES_FILTER');

export const setDeliveriesSortFilter = createAction<{ sort?: DeliverySortBy; filter?: DeliveryFilters }>('SET_DELIVERIES_SORT_FILTER');

export const setAddDeliveryMode = createAction<boolean>('SET_ADD_DELIVERY_MODE');
