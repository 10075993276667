export const faqsListSupplier = [
  {
    id: 1,
    path: 'faqs',
    title: 'How do I join?',
    content: `
<p>Joining is easy! Simply click “Get Open Pantry Free” and follow the prompts. Set up takes just a few minutes.</p>
<p>Open Pantry is designed to be easy to set up, and it is!. Follow our simple onboarding process where we walk you through the whole process, with prompts to show you how to y add your products and prices, add your customers and let the orders roll in. If time is of the essence or perhaps you’re not too tech savvy, we offer our services to do the complete set up for you for a nominal fee. This process will complete the entire setup of your profile, including your products, price lists, images etc.  so all you have to do is login & start receiving orders! To access this service please email <a href="mailto:info@theopenpantry.com">info@theopenpantry.com</a> and mention you would like our “complete set-up package” to get the ball rolling immediately.</p>
`,
  },
  {
    id: 2,
    path: 'faqs',
    title: 'What suppliers work with Open Pantry?',
    content: `
<p>Open Pantry is the ideal wholesale ordering app for all things hospitality. 
Fresh Produce - Fruit & Vegetables, Meats, Poultry - Eggs, Seafood, Dry & Frozen Goods, Food Service & Hospitality Supplies - Packaging & Paper products,Cleaning Products, Delicatessen - Small Goods, Dairy - Cheese, Dairy Milk & Alternative Milks, Bakery goods from Bread to Cakes & Sweets, Alcohol & Beverages - Soft Drinks, Juices, Kombucha, Coffee & Tea - Chai & Hot Chocolate, Lollies, Ice cream & Gelato suppliers, Gluten Free suppliers, sustainability & Vegan focussed suppliers. To put it simply, any & all  food and beverage suppliers you can think of!
</p>
<p>If you’re a whole supplier or distributor, Open Pantry is the ideal wholesale ordering app to easily take your business  online to sell your goods to your restaurant venues. </p>
`,
  },
  {
    id: 3,
    path: 'faqs',
    title: '"How is Open Pantry free?"',
    content: `
<p> Open Pantry is free to use for both wholesale suppliers and for their customers. The free service allows you to access an abundance of essential business tools, such as the ability to customise your available products, edit & apply price lists, receive online orders & more! For those who like things a little extra we have features available that take out even more leg work., As a progressive platform we have developed & enabled a number of additional services on the platform that are accessible for a nominal cost, such as direct order integration, this is ideal for those receiving an abundance of orders or facing staff shortage, as it will save you countless hours entering in your new orders.
</p>
`,
  },
  {
    id: 4,
    path: 'faqs',
    title: 'Why use this ordering platform? ',
    content: `
<p>Open Pantry exists to simplify the most essential part of your business, the back end. </p>
<p>The platform offers customisable pricing margins for sellers, concise business setup that accommodates to your delivery timeframes & minimum orders, is free to use for you & your customers & is constantly evolving to ensure we remain the #1 platform for hospitality businesses Australia wide.  </p>
<p>Some of our most loved features are:</p>
<ul>
<li>Fully customisable pricing tiers to ensure the right profit margin applies to your products.</li>
<li>Customer engagement statistics - never lose a customer again!</li>
<li>Integrated payment systems & direct invoices that link directly to your ERP’s. </li>
<li>Quick notifications to let your customers know an items out of stock or back ordered.</li>
</ul>
<p>Open Pantry was designed by long term hospitality professionals, to be the complete solution to achieving simple and efficient ordering for all hospitality supplies. Chefs thrive with efficiency & the Open Pantry platform offers just that on both ends. </p>
`,
  },
  {
    id: 5,
    path: 'faqs',
    title: 'How does it all work?',
    content: `
<p> Open Pantry was born help Suppliers receive accurate online orders from their existing restaurant customers. To do this we need to follow a simple set up which includes </p>
<ol>
<li>Setting up your products</li>
<li>Customizing your prices </li>
<li>Invite your customers (you can invite them through our system) </li>
<li>Receive orders - you can export them or print them off - up to you</li>
</ol>
<p>And thats it, all you need to do is update prices when you need and let the orders flow in.</p>
`,
  },
  {
    id: 6,
    path: 'faqs',
    title: 'Can I integrate Open Pantry into my current ordering system?',
    content: `
<p> Open Pantry is made to work alongside your current systems so you can simply integrate your new Open Pantry orders directly into your ERP or accounting system. I.e Xero, MYOB etc. Please contact us for more information <a href="mailto:info@theopenpantry.com">info@theopenpantry.com</a>

</p>
`,
  },
  {
    id: 7,
    path: 'faqs',
    title: 'Does Open Pantry work where I live?',
    content: `
<p>Open Pantry is a wholesale stock ordering system born in Melbourne, servicing the whole of Australia. </p>
<p>This includes Victoria (VIC) including Metropolitan Melbourne to Geelong & Rural areas. We are also connecting wholesale suppliers with their customers in New South Wales (NSW) including Sydney, Newcastle, Central Coast, Wollongong. </p>
<p>We work in (QLD) Queensland currently servicing Brisbane, Gold Coast - Tweed Heads, Townsville, Cairns, Toowoomba, as well as (NT) Northern Territory - Alice Springs and Darwin. </p>
<p>We service Perth, Adelaide and Tasmania and are accessible by the whole of Australia. Suppliers are able to set up, invite their customers & set their delivery parameters, no matter where they are based.</p>
`,
  },
  {
    id: 8,
    path: 'faqs',
    title: 'Can I trial Open Pantry?',
    content: `
<p>It's easy to trial Open pantry, simply click “try Open Pantry Free” and follow the prompts to explore. We are free to use after all, theres nothing to lose! No lock in contracts, no up front costs, just jump on & check it out.  </p>
<p>Open Pantry was designed to make placing & receiving a wholesale stock order simpler & easier for food & beverage suppliers, distributors & their customers, if you have any questions we have a huge team of passionate professionals offering plenty of training including step by step help docs to walk you through everything you could need to know!</p>
`,
  },
  {
    id: 9,
    path: 'faqs',
    title: 'Can other suppliers see my customers?',
    content: `
<p>Open Pantry is a private platform & we understand the need for both privacy & exclusivity. Other suppliers cannot see your customers, nor is any of your sales/order information accessible to anyone. Customers cannot see your profit margins, or your other available price lists.   </p>
<p>Customers can request to connect with you on the platform, or you can invite them yourself, once approved you can set them up to view the pricing structure you choose to suit.</p>
`,
  },
  {
    id: 10,
    path: 'faqs',
    title: 'Why choose Open Pantry? What are the benefits? ',
    content: `
<p>Unlike developing your own software, there are no upfront costs. You can fully customise your venue information & supply lists in the app making it essentially your own! You will have access to the extensive free service and we have a team of developers constantly working to ensure our software is the best on the market! You can easily customise your pricing tiers & product groups for each customer as well as edit your available ‘in stock’ products with the click of a button.</p>
<ul>
<li>There are loads of benefits with using the Open Pantry platform. </li>
<li>There are no development costs passed on to you, even as we continue to develop the platform to ensure it remains the best on the market. Your profile & supply lists are fully customisable, you don’t need to spend thousands creating your own custom built solution, having a multi supplier platform means your customers are more likely to choose to order in the one place with the same consistent format as they can place all of their orders easier & you can receive them just the same. No more untraceable calls, missed emails & incorrect delivery information!</li>
<li>Save time & admin costs! You can easily track, process & integrate all orders into your current dispatch & accounting systems.</li>
<li>Increased cash flow. Payment is processed by the customer upon ordering, this eliminates the need to chase up late payments & reduces all discrepancies when it comes to ensuring if an order has been paid.</li>
</ul>
`,
  },
  {
    id: 11,
    path: 'faqs',
    title: 'How do Open Pantry orders work with my wholesale distribution/delivery process?',
    content: `
<p>Once an order is placed by a customer you will receive an order form that can be integrated into your current systems to seamlessly operate your end to end packing & distribution. The order form will state the customers details, their order list including item numbers & pricing, the requested delivery date & any notes the customer may have regarding delivery.
</p>
`,
  },
  {
    id: 12,
    path: 'faqs',
    title: 'Do I need to contact my customers to receive orders?',
    content: `
<p>Once your profile is set up on Open Pantry, you have an option to invite your customers directly from our system (through our email API) this is the easiest method to invite your customers. You can also add them via xlsx. However it might take a nudge or a call to remind them to complete the set up. If your customers have any issues at all, they will contact us first. This process not only saves you admin time but the hassle of processing refunds etc.</p>
<p>You can think of Open Pantry as having a free external admin team supporting your business goals. Short staffed? No problem with Open Pantry! </p>
`,
  },
  {
    id: 13,
    path: 'faqs',
    title: 'As a wholesaler/distributor, what are the main benefits of using Open Pantry?',
    content: `
<p>If you’ve been thinking of developing your own platform, this is for you - we’ve done the hard work leaving you with your own quick & easy customisable platform, that easily integrates with all of your current systems - from pricing to ordering & accounting. </p>
<p>Theres built in accounting software making it easy to snapshot your growth & sales, you can customise your pricing tiers based on profitability & stock levels, easily editing items that are out of stock, saving time dealing with discrepancies.</p>
<p>Currently integrated with Xero, Myob, Sage, Quickbooks & more. </p>
<p>The platform has been developed out of necessity with the direct input of hospitality professionals to ensure it serves exactly the purpose our industry needs, on both ends. </p>
<p>Still need more information? for everything you need to know, please view <a href="https://www.youtube.com/watch?v=dXWGYWzyQts" target="_blank">our explainer video</a>. </p>
`,
  },
  {
    id: 14,
    path: 'faqs',
    title: 'All my customers have different prices, can this work with you?',
    content: `
<p>We have all the tools you need to completely customize your prices for each and every customer. This is using our pricing tiers alongside our pricing exceptions. Its really easy but if you need a hand getting set up, give us a call or email and we will help.
</p>
`,
  },
];
