import { useEffect, useRef } from 'react';

export const Terms = () => {
  const linkRef = useRef<HTMLAnchorElement | null>(null);

  useEffect(() => {
    linkRef?.current?.click();
  }, []);
  return <a href='https://theopenpantry.com/terms' ref={linkRef} />;
};
