import React from 'react';

import Button from '@material-ui/core/Button';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';

import { useStyles } from './style';

interface Props {
  title: string;
  onClick: () => void;
  className?: string;
}

export const ChevronButton: React.FC<Props> = React.memo(({ onClick, title, className = '' }) => {
  const classes = useStyles();
  return (
    <Button
      classes={{
        root: clsx(classes.root, className),
      }}
      onClick={onClick}
      endIcon={<ChevronRightIcon className={classes.icon} />}
    >
      {title}
    </Button>
  );
});
