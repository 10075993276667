import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';

export interface StyleProps {
  color?: string;
  size?: number;
  bordered?: boolean;
  buttonSize?: number;
}

export const useStyles = makeStyles<Theme, StyleProps>(() =>
  createStyles({
    button: {
      width: ({ buttonSize }) => buttonSize || 34,
      height: ({ buttonSize }) => buttonSize || 34,
      border: ({ bordered, color }) => `1px solid ${bordered ? color : colorVariables.transparent}`,
    },
    icon: {
      color: ({ color }) => color || colorVariables.grey60,
      fontSize: ({ size }) => size,
    },
  }),
);
