import React, { useEffect, useState } from 'react';

import { useAcceptConnectionRequestMutation } from '../../../../api/company';
import { CustomerConnectionStatus } from '../../../../api/customer/types';
import { useGeIsTeammateViewer } from '../../../../api/teammates/hooks';
import { ConnectionRequest } from '../../../../api/user/types';
import { ThemedButton } from '../../../../shared/components/themed-button';
import { OverlayModal } from '../../../../shared/components/overlay-modal';
import { ProductsCategories } from '../../../../shared/constants/products';
import { ToastService } from '../../../../shared/services/toastService';
import { ConnectionReqItem } from './connections-req-item';

import { useStyles } from './style';

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
  connectionRequests: ConnectionRequest[];
  selectedCategories?: ProductsCategories[];
}

export const ConnectionRequestsModal: React.FC<Props> = ({ isModalOpen, closeModal, connectionRequests, selectedCategories }) => {
  const classes = useStyles();

  const [requestWithCategoriesIds, setRequestWithCategoriesIsd] = useState<{ requestId: number; categoryId: number }[]>([]);

  const [acceptRequest, { isLoading: acceptReqLoading, isSuccess: acceptSuccess }] = useAcceptConnectionRequestMutation();
  const isViewer = useGeIsTeammateViewer('supplier_and_categories');

  const onSetRequestId = (data: { requestId: number; categoryId: number }) => {
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    setRequestWithCategoriesIsd((prev) =>
      data.categoryId
        ? [...prev.filter((el) => el.requestId !== data.requestId), data].filter(
            (el) => !!connectionRequests.find((req) => req.id === el.requestId)?.supplier.verified,
          )
        : prev.filter((el) => el.requestId !== data.requestId),
    );
  };

  const onAcceptAll = () => {
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    requestWithCategoriesIds.forEach((request) => {
      acceptRequest({
        connection_request: {
          id: request.requestId,
          status: CustomerConnectionStatus.ACCEPTED,
          connection_categories_attributes: [
            {
              category_id: request.categoryId,
            },
          ],
        },
      });
    });
  };

  useEffect(() => {
    acceptSuccess && closeModal();
  }, [acceptSuccess]);

  const isAcceptAllEnabled = connectionRequests.length && connectionRequests.length === requestWithCategoriesIds.length;

  return (
    <OverlayModal
      isOpen={isModalOpen}
      onClose={closeModal}
      animate={true}
      boxClassName={classes.modalBox}
      contentWrapClassName={classes.modalContent}
    >
      <div className={classes.root}>
        <div className={classes.header}>
          <div className={classes.titleText}>Connection requests</div>
          <div className={classes.headerTitle}>You have {connectionRequests.length} new connection requests</div>
          <div className={classes.subTitle}>Please select where you would like to see this supplier</div>
        </div>
        <ThemedButton
          customClass={classes.acceptAllMobile}
          title='Accept All'
          onClick={onAcceptAll}
          isSmall={true}
          width={129}
          disabled={!isAcceptAllEnabled || acceptReqLoading}
        />
        <div className={classes.table}>
          {connectionRequests.map((connection) => (
            <ConnectionReqItem
              item={connection}
              key={connection.id}
              onSetRequestId={onSetRequestId}
              selectedCategories={selectedCategories}
            />
          ))}
        </div>
        <div className={classes.btnBox}>
          <ThemedButton title='Cancel' onClick={closeModal} isSmall={true} width='auto' buttonStyle='secondary' />
          <ThemedButton
            title='Accept All'
            onClick={onAcceptAll}
            isSmall={true}
            width='auto'
            disabled={!isAcceptAllEnabled || acceptReqLoading}
          />
        </div>
      </div>
    </OverlayModal>
  );
};
