import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../../../styles/colorVariables';
import { Sizes } from '../../../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    formBox: {
      height: 'calc(100% - 88px)',
      [theme.breakpoints.up(Sizes.mobile)]: {
        width: 375,
      },
    },
    root: {
      position: 'relative',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    sortBlock: {
      width: '90%',
      margin: '20px auto',
      [theme.breakpoints.up(Sizes.desktop)]: {
        display: 'none',
      },
    },
    blockTitle: {
      fontSize: 12,
      fontWeight: 700,
      color: colorVariables.navy,
      marginBottom: 15,
      textTransform: 'uppercase',
      [theme.breakpoints.up(Sizes.desktop)]: {
        marginBottom: 25,
      },
    },
    blockSubTitle: {
      color: colorVariables.grey60,
      [theme.breakpoints.up(Sizes.desktop)]: {
        marginBottom: 10,
      },
    },
    productName: {
      '& label': {
        fontSize: 12,
        fontWeight: 700,
        marginBottom: 15,
        textTransform: 'uppercase',
        color: `${colorVariables.grey60} !important`,
      },
    },
    filterBlock: {
      width: '90%',
      margin: '0 auto',
    },
    btnBlock: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 20,
      padding: 20,
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: '45px 20px',
      },
      [theme.breakpoints.down(400)]: {
        padding: '40px 10px',
        '& button': {
          minWidth: '40%',
        },
      },
    },
    btnBlockFullScreen: {
      marginTop: 'auto',
    },
    actions: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.up(Sizes.desktop)]: {
        display: 'none',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    actionsText: {
      textTransform: 'uppercase',
      fontSize: 11,
      fontWeight: 700,
      color: colorVariables.grey80,
    },
    actionsIcon: {
      fontSize: 16,
      color: colorVariables.navy,
      opacity: 0.5,
    },
    triggerBox: {
      [theme.breakpoints.between(Sizes.mobile, Sizes.desktop)]: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
      },
    },
    costBlock: {
      marginBottom: 20,
      '&>div:first-child': {
        marginBottom: 0,
      },
    },
    costFields: {
      display: 'flex',
      alignItems: 'center',
    },
    costFieldWrap: {
      width: 130,
    },
    divider: {
      width: 32,
      textAlign: 'center',
      color: colorVariables.grey60,
    },
    fieldWrap: {
      marginBottom: 20,
    },
    lastUpdatedBox: {
      display: 'flex',
      alignItems: 'center',
    },
    lastUpdatedItem: {
      width: 130,
      height: 48,
      border: `1px solid ${colorVariables.steelGrey}`,
      borderRadius: 5,
      lineHeight: '48px',
      padding: '0 15px',
      fontSize: 16,
      color: colorVariables.navy,
      '&:hover': {
        borderColor: colorVariables.blue,
        cursor: 'pointer',
      },
      [theme.breakpoints.down(Sizes.desktop)]: {
        height: 36,
        lineHeight: '36px',
        fontSize: 14,
      },
    },
    lastUpdatedWrap: {
      marginBottom: 20,
    },
    placeholder: {
      color: colorVariables.grey60,
    },
    productNameField: {
      '& .MuiOutlinedInput-root.MuiAutocomplete-inputRoot': {
        [theme.breakpoints.down(Sizes.desktop)]: {
          height: 36,
          fontSize: 14,
        },
      },
    },
  }),
);
