import { createAction } from '@reduxjs/toolkit';
import { ProductsPurchasedFilters, ProductsPurchaseSortBy } from '../../api/dashboard/types';
import { RangeOptions } from '../../shared/components/range-dropdown';

export const setProductsPurchasedPage = createAction<number>('SET_PRODUCTS_PURCHASED_PAGE');

export const setProductsPurchasedKeyword = createAction<string>('SET_PRODUCTS_PURCHASED_KEYWORD');

export const resetProductsPurchasedParams = createAction('RESET_PRODUCTS_PURCHASED_PARAMS');

export const setProductsPurchasedDaysFilter = createAction<{ days?: number }>('SET_PRODUCTS_PURCHASED_DAYS_FILTER');

export const setProductsPurchasedSelectedRange = createAction<RangeOptions>('SET_PRODUCTS_PURCHASED_SELECTED_RANGE');

export const setProductsPurchasedRangeFilter = createAction<{ fromDate?: string; toDate?: string }>('SET_PRODUCTS_PURCHASED_RANGE_FILTER');

export const setProductsPurchasedSortFilter = createAction<{ filter?: ProductsPurchasedFilters; sort?: ProductsPurchaseSortBy }>(
  'SET_PRODUCTS_PURCHASED_SORT_FILTER',
);
