import React, { useEffect, useRef, useState } from 'react';

import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { useUpdateSupplierProfileMutation } from '../../../api/company';
import { useMeQuery, useUpdatePasswordMutation } from '../../../api/user';
import { ThemedButton } from '../../../shared/components/themed-button';
import { FormikInput } from '../../../shared/components/formik-input';
import { NavbarTitle } from '../../../shared/components/navbar-title';
import { useScreenSize } from '../../../shared/hooks/use-screen-size';
import { ToastService } from '../../../shared/services/toastService';
import { useAppDispatch } from '../../../store';
import { openAccountMenu, openMainMenu } from '../../../store/user';
import { MainNavBar } from '../../AppDrawer/MainNavBar';
import { TopNavBar } from '../../AppDrawer/MainNavBar/top-nav-bar';
import { AccountMenu } from '../account-menu';

import { useStyles } from './style';

interface BasicInfoValues {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  company: string;
  employee: string;
}

interface ChangePasswordValues {
  password: string;
  newPassword: string;
  confirmPassword: string;
}

export const SupplierProfile: React.FC = () => {
  const classes = useStyles();
  const { isMobile } = useScreenSize();
  const dispatch = useAppDispatch();
  const passwordRef = useRef<HTMLFormElement | null>(null);

  const [myEmail, setMyEmail] = useState('');
  const [emailToChange, setEmailToChange] = useState('');

  const [updatePassword, { isSuccess: isPasswordUpdated, isLoading: isPasswordLoading, error: updatePasswordError }] =
    useUpdatePasswordMutation();
  const [updateProfile, { isSuccess, isLoading }] = useUpdateSupplierProfileMutation();

  const { data: user } = useMeQuery();

  const basicSchema = Yup.object().shape({
    email: Yup.string().required('Required field').email('Enter correct email!'),
    firstName: Yup.string().required('Required field'),
    lastName: Yup.string().required('Required field'),
    company: Yup.string().required('Required field'),
  });

  const changePasswordSchema = Yup.object().shape({
    password: Yup.string().required('Required field'),
    newPassword: Yup.string().required('Required field').min(8, 'Too short'),
    confirmPassword: Yup.string()
      .required('Required field')
      .oneOf([Yup.ref('newPassword'), null], 'Does not match'),
  });

  const openMenus = () => {
    dispatch(openMainMenu());
    dispatch(openAccountMenu());
  };

  const onSubmitForm = ({ firstName, lastName, email, phone }: BasicInfoValues, formikHelpers: FormikHelpers<BasicInfoValues>) => {
    setMyEmail(user?.email || '');
    setEmailToChange(email || '');
    const formData = new FormData();
    user?.id && formData.append('company[suppliers_attributes][0][id]', user.id.toString());
    firstName && formData.append('company[suppliers_attributes][0][first_name]', firstName);
    lastName && formData.append('company[suppliers_attributes][0][last_name]', lastName);
    email && formData.append('company[suppliers_attributes][0][email]', email);
    user?.company.id && updateProfile({ formData, id: user.company.id });
    formikHelpers.setSubmitting(false);
  };

  const onChangePasswordSubmit = (data: ChangePasswordValues, formikHelpers: FormikHelpers<ChangePasswordValues>) => {
    updatePassword({
      user: {
        current_password: data.password,
        password: data.newPassword,
        password_confirmation: data.confirmPassword,
      },
    });
    formikHelpers.setSubmitting(false);
  };

  useEffect(() => {
    isPasswordUpdated && passwordRef.current?.reset();
  }, [isPasswordUpdated]);

  useEffect(() => {
    if (isSuccess) {
      ToastService.success('Profile Updated');
    }
    if (isSuccess && emailToChange && myEmail !== emailToChange) {
      ToastService.success('We have sent you an email to confirm your email change');
      setMyEmail('');
      setEmailToChange('');
    }
  }, [isSuccess]);

  if (!user) {
    return null;
  }

  return (
    <>
      <TopNavBar
        isAccount={true}
        bottomComponent={isMobile ? <NavbarTitle title='My Profile' showBackBtn={true} backHandler={openMenus} /> : undefined}
        leftComponent={<NavbarTitle title='My Profile' showBackBtn={true} backHandler={openMenus} />}
      />
      <MainNavBar isAccount={true} />
      <div className={classes.accountContainer}>
        <div className={classes.accountHeader}>
          <div className={classes.accountTitle}>My Account</div>
        </div>
        <div className={classes.accountBody}>
          <AccountMenu showBack={true} />
          <div className={classes.accountContent}>
            <div className={classes.titleBox}>
              <h2 className={classes.title}>Profile</h2>
            </div>
            <div className={classes.basicInfo}>
              <h4 className={classes.sectionTitle}>Basic information</h4>
              <Formik
                initialValues={{
                  firstName: user.first_name,
                  lastName: user.last_name,
                  phone: user.phone_number || '',
                  email: user.email,
                  company: user.company.name,
                  employee: '',
                }}
                onSubmit={onSubmitForm}
                validationSchema={basicSchema}
              >
                {({ submitForm }) => (
                  <Form className={classes.formBox}>
                    <div className={classes.fieldsBox}>
                      <div className={classes.fieldWrap}>
                        <Field name='company'>
                          {(fieldProps: FieldProps) => (
                            <FormikInput {...fieldProps} label='Company name' placeholder='Company name' disabled={true} />
                          )}
                        </Field>
                      </div>
                      <div className={classes.fieldWrap}>
                        <Field name='employee'>
                          {(fieldProps: FieldProps) => (
                            <FormikInput {...fieldProps} label='Employee position' placeholder='Employee position' />
                          )}
                        </Field>
                      </div>
                      <div className={classes.fieldWrap}>
                        <Field name='firstName'>
                          {(fieldProps: FieldProps) => <FormikInput {...fieldProps} label='First name*' placeholder='First name' />}
                        </Field>
                      </div>
                      <div className={classes.fieldWrap}>
                        <Field name='lastName'>
                          {(fieldProps: FieldProps) => <FormikInput {...fieldProps} label='Last name*' placeholder='Last name' />}
                        </Field>
                      </div>
                      <div className={classes.fieldWrap}>
                        <Field name='email'>
                          {(fieldProps: FieldProps) => <FormikInput {...fieldProps} label='Email Address*' placeholder='Email Address' />}
                        </Field>
                      </div>
                      <div className={classes.fieldWrap}>
                        <Field name='phone'>
                          {(fieldProps: FieldProps) => (
                            <FormikInput {...fieldProps} type='number' label='Phone Number' placeholder='Phone Number ' />
                          )}
                        </Field>
                      </div>
                    </div>
                    <ThemedButton title='Update' onClick={submitForm} width={280} customClass={classes.submitBtn} disabled={isLoading} />
                  </Form>
                )}
              </Formik>
            </div>
            <div className={classes.changePassword}>
              <h4 className={classes.sectionTitle}>Change password</h4>
              <Formik
                initialValues={{
                  password: '',
                  newPassword: '',
                  confirmPassword: '',
                }}
                validationSchema={changePasswordSchema}
                onSubmit={onChangePasswordSubmit}
              >
                {({ submitForm }) => (
                  <Form className={classes.formBox} ref={passwordRef}>
                    <div className={classes.fieldsBox}>
                      <div className={classes.fieldWrap}>
                        <Field name='password'>
                          {(fieldProps: FieldProps) => (
                            <FormikInput
                              {...fieldProps}
                              type='password'
                              label='Verify password'
                              placeholder='Verify password'
                              customError={
                                updatePasswordError && 'data' in updatePasswordError ? updatePasswordError.data.message : undefined
                              }
                            />
                          )}
                        </Field>
                      </div>
                      <div className={classes.fieldWrap}>
                        <Field name='newPassword'>
                          {(fieldProps: FieldProps) => (
                            <FormikInput {...fieldProps} type='password' label='New password' placeholder='New password' />
                          )}
                        </Field>
                      </div>
                      <div className={classes.fieldWrap}>
                        <Field name='confirmPassword'>
                          {(fieldProps: FieldProps) => (
                            <FormikInput {...fieldProps} type='password' label='Confirm new password' placeholder='Confirm new password' />
                          )}
                        </Field>
                      </div>
                    </div>
                    <ThemedButton
                      title='Update'
                      onClick={submitForm}
                      width={280}
                      customClass={classes.submitBtn}
                      disabled={isPasswordLoading}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
