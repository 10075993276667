import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { AddSuppliersNew } from '../../Onboarding/add-suppliers-new';

import clsx from 'clsx';
import SvgCarIcon from '../../../../assets/images/svg-components/CarIcon';
import SvgShopIcon from '../../../../assets/images/svg-components/ShopIcon';
import { CommonButton } from '../../../../shared/components/common-button';
import { LocalStorageService } from '../../../../shared/services/localStorage.service';
import { useStyles } from './style';

export const AddSupplierTab: React.FC = () => {
  const classes = useStyles();
  const { state: navState } = useLocation<{ addForm: boolean } | null>();

  const { push } = useHistory();

  useEffect(() => {
    LocalStorageService.clear('manual_supplier_id');
    LocalStorageService.clear('manualSupplierName');
  }, []);

  return navState?.addForm ? (
    <div className={classes.root}>
      <div className={classes.title}>Lets add your suppliers</div>
      <div className={classes.subtitle}>Please search and select your suppliers to proceed with the next step</div>
      <AddSuppliersNew
        category={null}
        categories={[]}
        onboarded={true}
        openNextCategory={() => {
          push('/account/suppliers');
        }}
        setManualSupplier={() => {
          push('/account/onboard-manual-supplier');
        }}
      />
    </div>
  ) : (
    <div className={clsx(classes.root, classes.typeScreen)}>
      <div className={clsx(classes.title, classes.supplierPageTitle)}>Two ways to manage your products & prices:</div>
      <div className={classes.supplierTypeWrap}>
        <div className={classes.recWrap}>
          <div className={classes.supplierTypeTile}>
            <SvgCarIcon />
            <span>Supplier Managed</span>
          </div>
          <div className={classes.recommendedLabel}>Recommended</div>
        </div>

        <div>OR</div>
        <div className={classes.supplierTypeTile}>
          <SvgShopIcon />
          <span>
            Self <br />
            Managed
          </span>
        </div>
      </div>
      <div className={classes.supplierTypeText}>
        At Open Pantry we offer you two ways to manage your products & pricing. You can select manage your pricing your self, or you can
        invite your suppliers to manage your products & prices themselves.
      </div>
      <CommonButton
        onClick={() => {
          push('/account/suppliers/add', { addForm: true });
        }}
        title='Next'
        width={215}
      />
    </div>
  );
};
