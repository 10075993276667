import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colorVariables } from '../../../styles/colorVariables';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'relative',
      '& .MuiInputBase-input.MuiOutlinedInput-input': {
        textAlign: 'center',
        cursor: 'pointer',
        padding: '0 4px',
        fontSize: 16,
        fontWeight: 400,
        color: colorVariables.navy,
        lineHeight: '24px',
        height: 48,
        backgroundColor: colorVariables.white,
      },
      '& input::before, &::after': {
        display: 'none',
      },
      '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        borderColor: colorVariables.blue,
        borderWidth: 1,
      },
      '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        borderColor: colorVariables.steelGrey,
        borderWidth: 1,
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: colorVariables.blue,
        borderWidth: 1,
      },
      '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: colorVariables.steelGrey,
        borderWidth: 1,
      },
    },
    rootEmpty: {
      '& .MuiInputBase-input.MuiOutlinedInput-input': {
        color: colorVariables.grey60,
      },
    },
    toolTip: {
      display: 'block',
      width: 125,
      minHeight: 24,
      borderRadius: 4,
      textAlign: 'center',
      fontSize: 11,
      lineHeight: '24px',
      padding: '0 5px',
      color: `${colorVariables.white} !important`,
      backgroundColor: colorVariables.navy,
      position: 'absolute',
      top: -40,
      left: 0,

      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: -16,
        left: 24,
        borderWidth: 8,
        borderStyle: 'solid',
        borderColor: `${colorVariables.navy} transparent transparent transparent`,
      },
    },
  }),
);
