import { createAction } from '@reduxjs/toolkit';
import { AdminEmailsFilter, AdminEmailsSortBy } from '../../../api/admin-emails/types';

export const setAdminEmailsSort = createAction<{ sort?: AdminEmailsSortBy }>('SET_ADMIN_EMAILS_SORT');

export const setAdminEmailsFilter = createAction<{ filter?: AdminEmailsFilter }>('SET_ADMIN_EMAILS_FILTER');

export const setAdminEmailsSortFilter =
  createAction<{ filter?: AdminEmailsFilter; sort?: AdminEmailsSortBy }>('SET_ADMIN_EMAILS_FILTER_SORT');

export const setAdminEmailsPage = createAction<number>('SET_ADMIN_EMAILS_PAGE');

export const setAdminEmailsKeyword = createAction<string | undefined>('SET_ADMIN_EMAILS_KEYWORD');
