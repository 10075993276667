import * as React from 'react';

function SvgNoSuppliers(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='200' height='200' viewBox='0 0 200 200' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#no-suppliers_svg__clip0_5194_171185)'>
        <path
          opacity={0.1}
          d='M160.645 168.022c33.69-24.06 34.471-99.364 9.114-125.104-59.974-60.88-66.494 33.855-131.125 40.961C11.8 96.771-3.886 114.854 17.46 144.741c21.344 29.888 109.506 47.334 143.186 23.281z'
          fill='url(#no-suppliers_svg__paint0_linear_5194_171185)'
        />
        <mask
          id='no-suppliers_svg__a'
          style={{
            maskType: 'alpha',
          }}
          maskUnits='userSpaceOnUse'
          x={9}
          y={15}
          width={166}
          height={168}
        >
          <path
            d='M51.063 15.28L174.22 55.34l-41.344 127.103s-21.058 3.301-67.701-10.31c-46.644-13.61-55.457-29.751-55.457-29.751L51.062 15.279z'
            fill='#C4C4C4'
          />
        </mask>
        <g mask='url(#no-suppliers_svg__a)'>
          <path
            d='M142.631 103.83s7.825-.492 10.007-1.794c1.484-.888 6.74-5.193 6.255-5.9-.687-1-4.814.51-5.977.772-1.159.27-2.182.328-2.456-.048-.278-.382 4.018-1.666 3.035-2.213-.974-.544-5.152.366-6.004.792-.852.426-2.648 3.066-3.914 3.345-1.266.27-.946 5.046-.946 5.046z'
            fill='#FFD2BA'
          />
          <path
            d='M81.199 87.521c3.34 4.482 7.245 8.024 11.256 10.817 12.762 8.875 26.605 10.199 26.605 10.199 3.31-1.061 13.5-2.171 19.523-3.143 2.632-.419 4.458-.818 4.585-1.169l-.149-5.244s-22.11-1.169-25.763-2.867c-5.777-4.973-10.869-11.43-14.193-16.47-2.803-4.263-5.942-7.406-11.123-9.255-7.484-2.667-21.103 3.216-10.741 17.132z'
            fill='#009DBD'
          />
          <path
            d='M91.337 95.04l1.129 3.298c12.758 8.876 26.601 10.199 26.601 10.199 2.659 0 13.501-2.17 19.524-3.144-.237.02-16.39 1.285-19.962.915-3.602-.369-20.348-14.884-20.348-14.884l-6.944 3.616z'
            fill='#00537D'
          />
          <path
            d='M99.185 142.885s-1.1 13.697-2.393 30.158c-1.746 22.368-6.48 77.819-6.48 77.819s-3.104 2.642-9.966 1.197c0 0-5.848-19.128-7.594-40.966-.036-.354-.057-.714-.086-1.074-1.624-22.228-4.153-69.032-4.153-69.032l30.672 1.898z'
            fill='#152144'
          />
          <path
            d='M99.56 138.155s-1.472 18.432-2.766 34.893l-24.04 38.05c-.036-.355-.058-.715-.087-1.076-1.624-22.227-4.154-69.03-4.154-69.03l31.048-2.837z'
            fill='#091630'
          />
          <path
            d='M92.55 138.153s-17.438 43.417-23.017 59.873c-3.965 11.722-15.167 52.843-15.167 52.843s-4.872 1.484-8.972-1.382c0 0-4.166-42.011 4.257-53.859 4.465-6.287 1.108-65.339 19.21-65.84 18.11-.506 23.69 8.365 23.69 8.365z'
            fill='#152144'
          />
          <path
            d='M84.763 74.15c-2.664 1.402-5.257 1.49-7.516 1.012-2.06-.432-3.83-1.342-5.087-2.15-1.292-.827-2.053-1.542-2.053-1.542s.086-.132.235-.39c.788-1.343 3.175-6.18 2.117-13.955-.014-.102.753.044 1.918.316 3.46.823 10.414 2.793 10.727 2.88h.007s.092 1.636.376 4.57c.029.258.05.526.078.808.036.34.071.705.114 1.08.32 3.046-.916 7.372-.916 7.372z'
            fill='#FFD2BA'
          />
          <path
            d='M85.482 64.897c-5.17 1.183-12.88-6.447-12.88-6.447a8.409 8.409 0 011.767-1.002c3.465.822 10.417 2.793 10.73 2.88h.006s.093 1.636.377 4.569z'
            fill='#EDBFB2'
          />
          <path
            d='M87.229 59.917s-2.118.76-4.828 1.124c-3.958.535-9.164.22-10.804-4.574-2.76-8.062-6.276-12.898 1.832-16.192 4.343-1.766 7.118-1.859 9.007-1.188 1.64.584 2.603 1.742 3.338 2.89 1.576 2.467 4.742 16.524 1.455 17.94z'
            fill='#FFD2BA'
          />
          <path
            d='M92.16 42.615c-4.7 3.376-11.192.058-13.213.953-2.02.9-2.378 4.282-2.414 5.353-.035 1.08-.178 1.985-2.92 1.343-.443-.108-.772-.078-.993.058-1.136.671.314 3.942.75 4.647 0 0 .635 2.34-.764 3.479-.165.136-2.914 1.338-9.542-8.793-1.042-1.59-2.035-5.172-.421-8.622 1.6-3.452 8.313-6.786 14.005-8.553 2.128-.663 3.713-.639 4.949-.185 2.064.754 3.163 2.703 4.256 4.662 1.828 3.293 6.306 5.658 6.306 5.658z'
            fill='#152144'
          />
          <path
            d='M101.918 130.845c-.374 4.973-1.426 8.54-3.73 8.54 0 0-10.25 3.605-22.496 3.703-2.391.015-5.776-.448-8.593-.92-2.867-.486-5.15-.978-5.15-.978-2.456-.569-5.172-2.087-4.812-4.895.21-1.591.353-3.664.454-6.068.267-6.505.223-15.468.252-24.041.03-5.27.08-10.394.252-14.714 0 0-2.413-16.973.274-19.755 3.14-3.256 12.375-4.258 12.951-4.132.173-.039.346-.083.526-.112 0 0 8.031 4.38 13.873 1.1 0 0 8.521 1.947 10.977 4.535.843.895 4.221 6.374 4.315 12.388.122 8.389-.202 17.921-.202 17.921.087 1.362.238 3.119.396 5.124.173 2.136.36 4.549.519 7.065.079 1.27.158 2.564.216 3.859.18 3.994.238 8.009-.022 11.38z'
            fill='#009DBD'
          />
          <path d='M71.354 67.589s8.703 8.972 14.932 7.133c0 0 .825-1.211-1.977-1.601-2.801-.389-5.823-.228-12.955-5.532z' fill='#00537D' />
          <path d='M87.304 54.708s.17 1.046-2.255 1.878c0 0-2.04.701-2.41-.37 0 0 1.614-1.542 4.665-1.508z' fill='#fff' />
          <path d='M65.837 99.138s.014 24.27-8.257 31.088c.265-6.507.223-15.469.258-24.042l8-7.046z' fill='#00537D' />
          <path
            d='M40.395 144.583s-4.212 6.613-4.445 9.143c-.157 1.722.226 8.506 1.082 8.53 1.213.034 2.425-4.19 2.897-5.284.466-1.096 1.021-1.956 1.487-1.956.472 0-1.02 4.227 0 3.756 1.013-.468 2.74-4.38 2.897-5.319.157-.939-.918-3.945-.397-5.133.527-1.182-3.52-3.737-3.52-3.737z'
            fill='#FFD2BA'
          />
          <path
            d='M59.166 70.795S53.47 73.485 48.7 93.392c-3.598 15.006-10.487 53.329-10.487 53.329l5.823 2.394s21.338-48.974 24.39-55.864c3.051-6.886-9.26-22.456-9.26-22.456z'
            fill='#009DBD'
          />
        </g>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M159.392 76.467h5.77c.923 0 1.846-.693 1.846-1.731v-1.731c0-1.038.692-1.846 1.73-2.308 7.5-2.769 12.462-10.038 12.116-18.115-.346-9.577-8.192-17.423-17.769-17.77-5.077-.115-9.808 1.732-13.5 5.193-3.116 3.115-5.077 7.038-5.539 11.423-.115 1.039.692 1.846 1.731 1.846h5.769c.923 0 1.616-.577 1.731-1.269.231-1.962 1.154-3.692 2.654-5.077 1.846-1.73 4.269-2.654 6.807-2.538 6.924.23 12.231 8.538 6.462 15.461a8.6 8.6 0 01-3.692 2.423c-4.616 1.616-7.731 6-7.731 10.846v1.731c0 .923.692 1.616 1.615 1.616zm5.883 13.847c.923 0 1.73-.808 1.73-1.731v-5.77c0-.922-.807-1.73-1.73-1.73h-5.77c-.923 0-1.73.808-1.73 1.73v5.77c0 .923.807 1.73 1.73 1.73h5.77z'
          fill='url(#no-suppliers_svg__paint1_linear_5194_171185)'
        />
      </g>
      <defs>
        <linearGradient
          id='no-suppliers_svg__paint0_linear_5194_171185'
          x1={35.86}
          y1={170.508}
          x2={185.277}
          y2={63.799}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#33A8E2' />
          <stop offset={1} stopColor='#51D9B0' />
        </linearGradient>
        <linearGradient
          id='no-suppliers_svg__paint1_linear_5194_171185'
          x1={181.82}
          y1={90.314}
          x2={144.033}
          y2={90.31}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#33A8E2' />
          <stop offset={1} stopColor='#51D9B0' />
        </linearGradient>
        <clipPath id='no-suppliers_svg__clip0_5194_171185'>
          <path fill='#fff' d='M0 0h200v200H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgNoSuppliers;
