import { ProductsCategories } from '../../shared/constants/products';
import { PaginationResult, SuccessResponse } from '../index';
import { SortDir } from '../product/types';
import { BaseUser, SupplierUser } from '../user/types';

export enum FrequencyEnum {
  // DAYS1 = 'Daily',
  DAYS7 = '7 days',
  DAYS14 = '14 days',
  DAYS28 = '28 days',
}

export enum RecOrderStatus {
  ACTIVE = 'Active',
  PAUSED = 'Paused',
}

export interface CreateRecOrderRequest {
  name: string;
  status: RecOrderStatus;
  frequency: FrequencyEnum;
  start_date: string;
}

export interface UpdateRecOrderRequest extends Partial<CreateRecOrderRequest> {
  id: number;
}

export interface UpdateRecOrderResponse extends SuccessResponse {
  recurring_order: RecOrder;
}

export interface RecOrder {
  id: number;
  name: string;
  status: RecOrderStatus;
  frequency: FrequencyEnum;
  start_date: string;
  created_at: string;
  next_delivery_date: string;
  suppliers_company: SupplierInRecOrder[];
  total_by_order: number;
  recurring_order_items: RecOrderItem[];
  user: BaseUser;
  supplier: SupplierUser;
}

export interface SupplierInRecOrder {
  id: number;
  name: string;
  picture_url: string | null;
  supplier_id: number;
}

export interface RecOrderItem {
  id: number;
  quantity: number;
  unit_size: string;
  total_price: number;
  order_minimum_value: number;
  product: {
    id: number;
    name: string;
    brand: string;
    unit: string;
    price_per_unit: string;
    picture_url: string | null;
    product_supplier_id: number;
    product_supplier_name: string;
    product_id: string | null;
  };
}

export interface RecOrdersListResponse extends PaginationResult {
  recurring_orders: RecOrder[];
}

export interface GetRecOrdersReqParams {
  keyword?: string;
  currentPage: number;
  sort?: RecOrderSortBy;
  filter?: ProductsCategories | null;
}

export interface RecOrdersQueryParams {
  'q[keyword]'?: string;
  'q[category_id]'?: number;
  'q[sort_by]'?: string;
}

export type RecOrderSortFields = 'name' | 'created_at' | 'products_order_items_created_at';

export type RecOrderSortBy = [RecOrderSortFields, SortDir];

export interface CreateRecOrderResponse extends SuccessResponse {
  recurring_order: RecOrder;
}

export interface UpdateRecOrderQuantityRequest {
  id: number;
  product_id: number;
  quantity: number;
}
