import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';
import { Sizes } from '../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    overlay: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'fixed',
      zIndex: 980,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'rgba(152, 165, 183, 0.5)',
    },
    modalBox: {
      backgroundColor: colorVariables.white,
      padding: '50px 80px',
      [theme.breakpoints.down(Sizes.desktop)]: {
        padding: '50px 60px',
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        padding: '50px 40px',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: '40px 20px 20px 20px',
      },
      borderRadius: 12,
      position: 'relative',
      zIndex: 990,
    },
    contentBox: {
      margin: '0 auto',
      [theme.breakpoints.down(Sizes.desktop)]: {
        maxHeight: '75vh',
        maxWidth: '80vw',
        overflowY: 'scroll',
        overflowX: 'hidden',
      },
      // hide scroll bar
      'ms-overflow-style': 'none',
      'scrollbar-width': 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    closeBtn: {
      position: 'absolute',
      top: 15,
      right: 15,
    },
    title: {
      fontSize: 20,
      lineHeight: '22px',
      fontWeight: 700,
      marginTop: 10,
      marginBottom: 30,
      maxWidth: 600,
      [theme.breakpoints.down(Sizes.tablet)]: {
        maxWidth: 500,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        paddingLeft: 5,
        fontSize: 18,
        marginBottom: 10,
        maxWidth: '80vw',
      },
    },
  }),
);
