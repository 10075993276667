import { TokenService } from '../../shared/services/token.service';
import { getCustomerPerformanceQueryParamsHelper, getProductsPurchasedQueryParamsHelper } from '../helpers';
import { HTTP, rootApi, SuccessResponse } from '../index';
import { userApi } from '../user';
import { ConnectionRequest } from '../user/types';
import {
  AtRiskCustomersReqParams,
  AtRiskCustomersResponse,
  CustomerPerformanceResponse,
  CustomersWithoutPriceListsRes,
  ExportPurchasedRequest,
  ExportPurchasedResponse,
  GetCustomerPerformanceReqParams,
  GetOrdersDashboardRequest,
  GetOrderStatusResponse,
  GetProductsPurchasedListReqParams,
  OrdersDashboardResponse,
  ProductPurchasedDeliveryResponse,
  ProductsPurchasedResponse,
  PurchasedByCategoryReqParams,
  PurchasedByCategoryResponse,
  PurchasesGraphResponse,
  SalesGraphReqParams,
  SalesGraphResponse,
  TopPerformingCustomersReqParams,
  TopPerformingCustomersResponse,
  TopSuppliersResponse,
} from './types';

export const dashboardApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getCustomersPerformance: builder.query<CustomerPerformanceResponse, GetCustomerPerformanceReqParams>({
      query: (args) => {
        const userId = TokenService.getUserId();
        const params = {
          ...getCustomerPerformanceQueryParamsHelper(args),
          per_page: 30,
          page: args?.currentPage || 1,
        };
        return {
          url: `/users/${userId}/dashboard/customer_perfomance`,
          method: HTTP.GET,
          params,
        };
      },
    }),

    getSalesGraph: builder.query<SalesGraphResponse, SalesGraphReqParams>({
      query: (params) => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/dashboard/sales_graph`,
          method: HTTP.GET,
          params,
        };
      },
      providesTags: ['Orders_Count'],
      keepUnusedDataFor: 0,
    }),

    getPurchasesGraph: builder.query<PurchasesGraphResponse, SalesGraphReqParams>({
      query: (params) => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/dashboard/purchase_graph`,
          method: HTTP.GET,
          params,
        };
      },
      keepUnusedDataFor: 0,
    }),

    getCustomersWithoutPriceLists: builder.query<CustomersWithoutPriceListsRes, undefined>({
      query: () => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/dashboard/customers_without_price_lists`,
          method: HTTP.GET,
        };
      },
      providesTags: ['Customers_Count'],
    }),

    getTopPerformingSuppliers: builder.query<TopSuppliersResponse, void>({
      query: () => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/dashboard/top_performing_suppliers`,
          method: HTTP.GET,
        };
      },
      keepUnusedDataFor: 0,
    }),

    getTopPerformingCustomers: builder.query<TopPerformingCustomersResponse, TopPerformingCustomersReqParams>({
      query: (params) => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/dashboard/top_performing_customers`,
          method: HTTP.GET,
          params,
        };
      },
      keepUnusedDataFor: 0,
    }),

    getAtRiskCustomers: builder.query<AtRiskCustomersResponse, AtRiskCustomersReqParams>({
      query: (params) => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/dashboard/at_risk_customers`,
          method: HTTP.GET,
          params,
        };
      },
      keepUnusedDataFor: 0,
    }),

    getPurchasedByCategory: builder.query<PurchasedByCategoryResponse, PurchasedByCategoryReqParams>({
      query: (params) => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/dashboard/purchased_by_category`,
          method: HTTP.GET,
          params,
        };
      },
      keepUnusedDataFor: 0,
    }),

    getOrderStatus: builder.query<GetOrderStatusResponse, boolean | void>({
      query: (view_all) => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/dashboard/upcoming_order_status`,
          method: HTTP.GET,
          params: {
            view_all,
          },
        };
      },
      keepUnusedDataFor: 0,
    }),

    getRestaurantDashboardStatus: builder.query<{ restaurant_dashboard: boolean }, void>({
      query: () => {
        return {
          url: `/restaurant_dashboard`,
          method: HTTP.GET,
        };
      },
    }),

    getSupplierConnectionRequests: builder.query<ConnectionRequest[] | number, boolean>({
      query: (view) => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/dashboard/connection_requests`,
          method: HTTP.GET,
          params: { view },
        };
      },
      providesTags: ['Connection'],
    }),

    getOrdersDashboard: builder.query<OrdersDashboardResponse, GetOrdersDashboardRequest | undefined>({
      query: (params) => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/dashboard`,
          method: HTTP.GET,
          params,
        };
      },
      providesTags: ['Orders_Count'],
      keepUnusedDataFor: 0,
    }),

    getInventoryNotice: builder.query<{ notice: boolean; products_count: number }, undefined>({
      query: () => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/dashboard/running_out_noitce`,
          method: HTTP.GET,
        };
      },
      keepUnusedDataFor: 0,
    }),

    getProductsPurchased: builder.query<ProductsPurchasedResponse, GetProductsPurchasedListReqParams>({
      query: (args) => {
        const userId = TokenService.getUserId();
        const params = {
          ...getProductsPurchasedQueryParamsHelper(args),
          per_page: 30,
          page: args?.currentPage || 1,
        };
        return {
          url: `/users/${userId}/dashboard/export_products_ordered`,
          method: HTTP.GET,
          params,
        };
      },
      providesTags: ['Products_Purchased'],
    }),

    getProductsPurchasedDelivery: builder.query<ProductPurchasedDeliveryResponse, GetProductsPurchasedListReqParams>({
      query: (args) => {
        const params = {
          ...getProductsPurchasedQueryParamsHelper(args),
        };
        return {
          url: `/purchased_products_delivery_fee`,
          method: HTTP.GET,
          params,
        };
      },
      providesTags: ['Products_Purchased'],
    }),

    exportProductsPurchased: builder.query<ExportPurchasedResponse, ExportPurchasedRequest>({
      query: ({ export_product, ...args }) => {
        const userId = TokenService.getUserId();
        const params = {
          ...getProductsPurchasedQueryParamsHelper(args),
          export_product,
        };
        return {
          url: `/users/${userId}/dashboard/export_products_ordered`,
          method: HTTP.GET,
          params,
        };
      },
      providesTags: ['Products_Purchased'],
      keepUnusedDataFor: 10,
    }),

    exitFromDemo: builder.mutation<SuccessResponse, void>({
      query: () => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/demo_exit`,
          method: HTTP.POST,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          userApi.util.updateQueryData('me', undefined, (draft) => {
            draft.demo_exited = true;
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: ['Me'],
    }),
  }),

  overrideExisting: true,
});

export const {
  useGetCustomersPerformanceQuery,
  useGetCustomersWithoutPriceListsQuery,
  useGetOrdersDashboardQuery,
  useGetSalesGraphQuery,
  useGetPurchasesGraphQuery,
  useGetTopPerformingCustomersQuery,
  useGetAtRiskCustomersQuery,
  useGetSupplierConnectionRequestsQuery,
  useGetProductsPurchasedQuery,
  useLazyExportProductsPurchasedQuery,
  useGetProductsPurchasedDeliveryQuery,
  useGetPurchasedByCategoryQuery,
  useGetTopPerformingSuppliersQuery,
  useGetOrderStatusQuery,
  useExitFromDemoMutation,
  useGetInventoryNoticeQuery,
} = dashboardApi;
