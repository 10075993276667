import * as React from 'react';

function SvgCartSpinner(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='16' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M.5 6.802c0 1.295 1.011 2.366 2.234 2.366s2.229-1.07 2.229-2.366-1.006-2.36-2.229-2.36S.5 5.505.5 6.801zm2.1 5.46c.011.498.195.993.55 1.369a1.821 1.821 0 002.67-.001c.732-.777.736-2.055.003-2.832a1.829 1.829 0 00-2.676 0c-.378.4-.56.935-.548 1.465zm1.436-9.547a.49.49 0 00.131.326.434.434 0 00.636-.001.498.498 0 00-.001-.673.433.433 0 00-.635 0 .492.492 0 00-.13.348zm3.221 11.472c0 .995.777 1.813 1.716 1.813.94 0 1.711-.818 1.711-1.813 0-.996-.77-1.82-1.71-1.82s-1.717.824-1.717 1.82zM8.278.735c0 .402.314.732.694.732.38 0 .691-.33.691-.732C9.663.332 9.351 0 8.972 0c-.38 0-.694.332-.694.735zm3.681 11.479c0 .41.146.82.438 1.13a1.454 1.454 0 002.129-.004 1.668 1.668 0 000-2.252 1.454 1.454 0 00-2.13-.004c-.291.31-.437.72-.437 1.13zm.629-9.488c.005.23.09.46.255.633.34.36.897.36 1.237 0a.972.972 0 00.001-1.311.847.847 0 00-1.24 0 .954.954 0 00-.253.678zm1.55 4.763c.016.672.544 1.221 1.182 1.221.647 0 1.18-.568 1.18-1.254s-.533-1.248-1.18-1.248c-.648 0-1.183.562-1.183 1.248v.033z'
        fill='#51D9B0'
      />
    </svg>
  );
}

export default SvgCartSpinner;
