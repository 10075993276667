import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';

export const useStyles = makeStyles(() =>
  createStyles({
    customSelectBtn: {
      height: 32,
      padding: '0 10px',
      display: 'block',
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '32px',
      color: colorVariables.navy,
    },
    customSelectFormControl: {
      maxWidth: 200,
      marginLeft: 10,
      height: 32,
      textAlign: 'center',
      fill: colorVariables.grey60,
      '& *:focus': {
        backgroundColor: 'transparent !important',
      },
    },
  }),
);
