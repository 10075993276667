import React from 'react';

import { Close } from '@material-ui/icons';
import { Slide, toast } from 'react-toastify';
import { ToastOptions } from 'react-toastify/dist/types';

import { CommonToast } from '../../components/common-toast';

import styles from './style.module.scss';

export enum ToastType {
  SUCCESS = 'Success',
  ERROR = 'Error',
  WARNING = 'Warning',
  INFO = 'Info',
}

export const toastOptions = {
  autoClose: 3000,
  className: styles.op_toast,
  transition: Slide,
  closeButton: <Close className={styles.op_toast_close} />,
};

export class ToastService {
  static success(text: string, idx?: string, title?: string, options: Partial<ToastOptions> = {}) {
    toast((props) => <CommonToast {...props} text={text} type={ToastType.SUCCESS} title={title} />, {
      ...{ ...toastOptions, ...options },
      toastId: idx ? `${text}_${idx}` : text,
    });
  }

  static error(text: string, idx?: string, title?: string, options: Partial<ToastOptions> = {}) {
    toast((props) => <CommonToast {...props} text={text} type={ToastType.ERROR} title={title} />, {
      ...{ ...toastOptions, ...options },
      toastId: idx ? `${text}_${idx}` : text,
    });
  }

  static warning(text: string, idx?: string, title?: string, options: Partial<ToastOptions> = {}) {
    toast((props) => <CommonToast {...props} text={text} type={ToastType.WARNING} title={title} />, {
      ...{ ...toastOptions, ...options },
      toastId: idx ? `${text}_${idx}` : text,
    });
  }

  static info(text: string, idx?: string, title?: string, options: Partial<ToastOptions> = {}) {
    toast((props) => <CommonToast {...props} text={text} type={ToastType.INFO} title={title} />, {
      ...{ ...toastOptions, ...options },
      toastId: idx ? `${text}_${idx}` : text,
    });
  }
}
