import { createStyles, makeStyles, Theme } from '@material-ui/core';

interface StyleProps {
  color?: string;
}

export const useStyles = makeStyles<Theme, StyleProps>(() =>
  createStyles({
    root: {
      width: '100%',
      height: 10,
      background: '#DADADA',
      borderRadius: 4,
      overflow: 'hidden',
    },
    filter: {
      background: ({ color }) => color || 'linear-gradient(90deg, #51D9B0 0%, #33A8E2 100% )',
      height: 'inherit',
      borderRadius: 'inherit',
      transition: '0.3s width ease-in',
    },
  }),
);
