import { CountryCurrencyInfo } from '../../api/admin-countries/types';
import store from '../../store';
import { countries, euCountriesList } from '../constants/countries';
import { LocalStorageService } from '../services/localStorage.service';
import { SessionStorageService } from '../services/sessionStorage.service';

const intlNumberFormat = (maxFraction?: number, formatStyle?: keyof Intl.NumberFormatOptionsStyleRegistry) => {
  const countryCode = LocalStorageService.getItem('country');
  const fullCountryName = countries.find((el) => el.value === countryCode)?.label;

  const countryInfo = SessionStorageService.getItem('country_currency') as CountryCurrencyInfo | null;
  const countryCurrency = countryInfo?.sub_countries?.find((el) => el.name === fullCountryName)?.currency || countryInfo?.currency;

  if (euCountriesList.some((el) => el.value === countryCode)) {
    switch (countryCode) {
      case 'BG': {
        return new Intl.NumberFormat('bg-BG', {
          style: formatStyle ?? 'currency',
          currency: countryCurrency || 'BGN',
          maximumFractionDigits: maxFraction,
          currencyDisplay: 'narrowSymbol',
        });
      }
      case 'HR': {
        return new Intl.NumberFormat('hr-HR', {
          style: formatStyle ?? 'currency',
          currency: countryCurrency || 'HRK',
          maximumFractionDigits: maxFraction,
          currencyDisplay: 'narrowSymbol',
        });
      }
      case 'CZ': {
        return new Intl.NumberFormat('cs-CZ', {
          style: formatStyle ?? 'currency',
          currency: countryCurrency || 'CZK',
          maximumFractionDigits: maxFraction,
          currencyDisplay: 'narrowSymbol',
        });
      }
      case 'DK': {
        return new Intl.NumberFormat('da-DK', {
          style: formatStyle ?? 'currency',
          currency: countryCurrency || 'DKK',
          maximumFractionDigits: maxFraction,
          currencyDisplay: 'narrowSymbol',
        });
      }
      case 'HU': {
        return new Intl.NumberFormat('hu-HU', {
          style: formatStyle ?? 'currency',
          currency: countryCurrency || 'HUF',
          maximumFractionDigits: maxFraction,
          currencyDisplay: 'narrowSymbol',
        });
      }
      case 'PL': {
        return new Intl.NumberFormat('pl-PL', {
          style: formatStyle ?? 'currency',
          currency: countryCurrency || 'PLN',
          maximumFractionDigits: maxFraction,
          currencyDisplay: 'narrowSymbol',
        });
      }
      case 'RO': {
        return new Intl.NumberFormat('ro-RO', {
          style: formatStyle ?? 'currency',
          currency: countryCurrency || 'RON',
          maximumFractionDigits: maxFraction,
          currencyDisplay: 'narrowSymbol',
        });
      }
      case 'SE': {
        return new Intl.NumberFormat('sv-SE', {
          style: formatStyle ?? 'currency',
          currency: countryCurrency || 'SEK',
          maximumFractionDigits: maxFraction,
          currencyDisplay: 'narrowSymbol',
        });
      }
      default:
        return new Intl.NumberFormat('en-US', {
          style: formatStyle ?? 'currency',
          currency: countryCurrency || 'EUR',
          maximumFractionDigits: maxFraction,
          currencyDisplay: 'narrowSymbol',
        });
    }
  }

  switch (countryCode) {
    case 'IE': {
      return new Intl.NumberFormat('en-US', {
        style: formatStyle ?? 'currency',
        currency: countryCurrency || 'EUR',
        maximumFractionDigits: maxFraction,
        currencyDisplay: 'narrowSymbol',
      });
    }
    case 'GB': {
      return new Intl.NumberFormat('en-GB', {
        style: formatStyle ?? 'currency',
        currency: countryCurrency || 'GBP',
        maximumFractionDigits: maxFraction,
        currencyDisplay: 'narrowSymbol',
      });
    }
    case 'ID': {
      return new Intl.NumberFormat('id-ID', {
        style: formatStyle ?? 'currency',
        currency: countryCurrency || 'IDR',
        maximumFractionDigits: maxFraction,
        currencyDisplay: 'narrowSymbol',
      });
    }
    case 'PH': {
      return new Intl.NumberFormat('fil-PH', {
        style: formatStyle ?? 'currency',
        currency: countryCurrency || 'PHP',
        maximumFractionDigits: maxFraction,
        currencyDisplay: 'narrowSymbol',
      });
    }
    case 'SG': {
      return new Intl.NumberFormat('en-SG', {
        style: formatStyle ?? 'currency',
        currency: countryCurrency || 'SGD',
        maximumFractionDigits: maxFraction,
        currencyDisplay: 'narrowSymbol',
      });
    }
    case 'MY': {
      return new Intl.NumberFormat('ms-MY', {
        style: formatStyle ?? 'currency',
        currency: countryCurrency || 'MYR',
        maximumFractionDigits: maxFraction,
        currencyDisplay: 'narrowSymbol',
      });
    }
    case 'CN': {
      return new Intl.NumberFormat('zh-CN', {
        style: formatStyle ?? 'currency',
        currency: countryCurrency || 'CNY',
        maximumFractionDigits: maxFraction,
        currencyDisplay: 'narrowSymbol',
      });
    }
    case 'IN': {
      return new Intl.NumberFormat('en-IN', {
        style: formatStyle ?? 'currency',
        currency: countryCurrency || 'INR',
        maximumFractionDigits: maxFraction,
        currencyDisplay: 'narrowSymbol',
      });
    }
    case 'TH': {
      return new Intl.NumberFormat('th-TH', {
        style: formatStyle ?? 'currency',
        currency: countryCurrency || 'THB',
        maximumFractionDigits: maxFraction,
        currencyDisplay: 'narrowSymbol',
      });
    }
    case 'VN': {
      return new Intl.NumberFormat('vi-VN', {
        style: formatStyle ?? 'currency',
        currency: countryCurrency || 'VND',
        maximumFractionDigits: maxFraction,
        currencyDisplay: 'narrowSymbol',
      });
    }
    case 'AU': {
      return new Intl.NumberFormat('en-AU', {
        style: formatStyle ?? 'currency',
        currency: countryCurrency || 'AUD',
        maximumFractionDigits: maxFraction,
        currencyDisplay: 'narrowSymbol',
      });
    }
    default:
      return store?.getState()?.user?.adminCountryCode === 'eu'
        ? new Intl.NumberFormat('en-US', {
            style: formatStyle ?? 'currency',
            currency: countryCurrency || 'EUR',
            maximumFractionDigits: maxFraction,
            currencyDisplay: 'narrowSymbol',
          })
        : new Intl.NumberFormat('en-US', {
            style: formatStyle ?? 'currency',
            currency: countryCurrency || 'USD',
            maximumFractionDigits: maxFraction,
            currencyDisplay: 'narrowSymbol',
          });
  }
};

export const formatNumber = (value: string | number, maxFraction?: number) => {
  const formatter = intlNumberFormat(maxFraction, 'decimal' );
  return formatter.format(+value);
}

export const formatCurrency = (value: string | number, maxFraction?: number) => {
  const formatter = intlNumberFormat(maxFraction);
  return formatter.format(+value);
}

export const currencySymbol = () => {
  const formatter = intlNumberFormat(0);
  const parts = formatter.formatToParts?.(0) ?? [];
  return parts.find(part => part.type === 'currency')?.value || '';
}