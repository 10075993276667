import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../styles/colorVariables';
import { Sizes } from '../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      fontSize: 40,
      lineHeight: '50px',
      fontWeight: 700,
      marginBottom: 10,
      textAlign: 'center',
      fontFamily: 'Sofia Pro',
      [theme.breakpoints.down(Sizes.desktop)]: {
        fontSize: 36,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        fontSize: 30,
        lineHeight: '40px',
        padding: '0 15px',
      },
    },
    subTitle: {
      opacity: 0.7,
      fontSize: 12,
      marginBottom: 25,
      textAlign: 'center',
      lineHeight: '28px',
      [theme.breakpoints.down(Sizes.desktop)]: {
        marginBottom: 20,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        marginBottom: 30,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: '0 30px',
      },
    },

    formBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    weekDaysBox: {
      display: 'grid',
      gridTemplateColumns: 'repeat(7, 52px)',
      columnGap: 10,
      paddingTop: 20,
      marginBottom: 20,
      [theme.breakpoints.down(Sizes.desktop)]: {
        marginBottom: 20,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        marginBottom: 35,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginBottom: 25,
        width: '100%',
        gridTemplateColumns: 'repeat(7, minmax(40px, 56px))',
        justifyContent: 'space-between',
        columnGap: 6,
      },
    },
    weekDayItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: 84,
      borderRadius: 10,
      backgroundColor: colorVariables.white,
      boxShadow: '1px 1px 15px rgba(12, 82, 112, 0.08)',
      paddingTop: 15,
      textTransform: 'uppercase',
      border: '1px solid transparent',
      cursor: 'pointer',
      '& svg': {
        color: colorVariables.steelGrey15,
      },
      [theme.breakpoints.down(Sizes.desktop)]: {
        height: 72,
        fontSize: 11,
      },
    },
    weekDayItemSelected: {
      border: `1px solid ${colorVariables.green}`,
      backgroundColor: colorVariables.greenLight,
      '& svg': {
        color: colorVariables.green,
      },
    },
    checkIcon: {
      fontSize: 22,
      marginBottom: 8,
      color: colorVariables.transparent,
      [theme.breakpoints.down(Sizes.desktop)]: {
        fontSize: 16,
      },
    },
    detailsBox: {
      marginBottom: 30,
    },
    deliveryBox: {
      backgroundColor: 'rgba(152, 165, 183, 0.15)',
      borderRadius: 20,
      padding: 28,
      marginBottom: 20,
      [theme.breakpoints.down(Sizes.desktop)]: {
        padding: '15px 10px',
      },
    },
    detailsTitle: {
      fontSize: 16,
      fontWeight: 700,
      marginBottom: 8,
    },
    detailsRow: {
      display: 'grid',
      paddingTop: 10,
      gridTemplateColumns: '130px 130px 130px',
      columnGap: 20,
      marginBottom: 32,
      '& input': {
        textAlign: 'center',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        gridTemplateColumns: '1fr 1fr 1fr',
        columnGap: 8,
      },
    },
    detailFieldWrap: {
      width: 130,
    },
    cutOffLabel: {
      fontSize: 12,
      marginBottom: 10,
      lineHeight: '16px',
      marginTop: 4,
    },
    detailsSupTitle: {
      fontSize: 12,
      opacity: 0.7,
    },
    deliverySettingsT: {
      cursor: 'pointer',
      display: 'inline-block',
      minWidth: 200,
      '& svg': {
        transition: 'transform ease-in-out 0.5s',
        marginLeft: 8,
        transform: 'rotate(0deg)',
      },
    },
    deliverySettingsTExpanded: {
      '& svg': {
        transform: 'rotate(90deg)',
      },
    },
    track: {
      backgroundColor: colorVariables.steelGrey,
      opacity: 1,
    },
    thumb: {
      backgroundColor: colorVariables.white,
      width: 20,
      height: 20,
    },
    checked: {
      '&.MuiSwitch-switchBase + .MuiSwitch-track': {
        backgroundColor: '#44B5E6',
        opacity: 1,
      },
    },
    orderEmailWrap: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      transform: 'translateY(10px)',
      '&>span:first-child': {
        fontWeight: 700,
        fontSize: 12,
        textTransform: 'uppercase',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        '&>span:first-child': {
          fontSize: 10,
        },
      },
    },
    ordersEmailNote: {
      fontSize: 12,
      marginTop: 8,
      marginBottom: 30,
    },
    field: {
      marginBottom: 10,
    },
    btnsBox: {
      justifyContent: 'center',
      marginBottom: 32,
      marginTop: 40,
      width: 500,
      display: 'grid',
      gridTemplateColumns: '1fr 230px 1fr',
      '&>*:first-child': {
        transform: 'translateX(-25px)',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        gridTemplateColumns: '1fr 160px 1fr',
        '&>*:first-child': {
          transform: 'translateX(5px)',
        },
      },
    },
  }),
);
