import * as React from 'react';

function SvgEditIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M.796 16h12.72a.798.798 0 00.797-.795v-5.98a.798.798 0 00-.797-.795.798.798 0 00-.796.794v5.186H1.592V3.283h4.44a.798.798 0 00.796-.794.798.798 0 00-.796-.795H.796A.798.798 0 000 2.489v12.716c0 .457.358.795.796.795z'
        fill='currentColor'
      />
      <path
        d='M11.506.76L5.614 6.681c-.1.1-.18.219-.22.358l-.855 3.477c-.08.278 0 .556.199.755.16.159.358.238.577.238l.18-.04 3.483-.873c.14-.04.259-.1.358-.2l5.912-5.92c1.016-1.034.996-2.683-.02-3.716a2.66 2.66 0 00-3.722 0zm2.608 1.133a1.054 1.054 0 010 1.47l-.04.04-1.473-1.47.04-.04a1.03 1.03 0 011.433-.04c.02.02.02.04.04.04zM8.36 9.105l-1.951.497.478-1.947 4.578-4.61 1.473 1.47-4.578 4.59z'
        fill='currentColor'
      />
    </svg>
  );
}

export default SvgEditIcon;
