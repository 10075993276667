import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../../../styles/colorVariables';
import { Sizes } from '../../../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    formBox: {
      height: 'calc(100% - 88px)',
      [theme.breakpoints.up(Sizes.mobile)]: {
        width: 375,
      },
    },
    root: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    sortBlock: {
      width: '90%',
      margin: '20px auto',
      [theme.breakpoints.up(Sizes.desktop)]: {
        display: 'none',
      },
    },
    blockTitle: {
      fontSize: 12,
      fontWeight: 700,
      color: colorVariables.navy,
      marginBottom: 12,
      textTransform: 'uppercase',
    },
    blockSubTitle: {
      color: colorVariables.steelGrey,
    },
    price: {
      marginTop: 20,
    },
    checkboxBlock: {
      paddingRight: 8,
      '& label': {
        width: '48%',
      },
    },
    checkboxBlockHeight: {
      paddingRight: 0,
      height: 180,
      overflowY: 'scroll',
    },
    filterBlock: {
      width: '90%',
      margin: '0 auto',
    },
    btnBlock: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 20,
      padding: 20,
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: '40px 20px',
      },
      [theme.breakpoints.down(400)]: {
        padding: '30px 10px',
        '& button': {
          minWidth: '40%',
        },
      },
    },
    btnBlockFullScreen: {
      marginTop: 'auto',
    },
    moreOptTrigger: {
      display: 'inline-flex',
      alignItems: 'center',
      fontSize: 11,
      textTransform: 'uppercase',
      fontWeight: 700,
      marginBottom: 15,
      marginTop: 7,
      cursor: 'pointer',
    },
    actionsIcon: {
      fontSize: 20,
      color: colorVariables.navy,
      opacity: 0.5,
      marginLeft: 10,
      transition: 'transform ease-in-out 0.5s',
    },
    actionsIconRotated: {
      transform: 'rotate(-90deg)',
    },
  }),
);
