import React, { useState } from 'react';

import { Close } from '@material-ui/icons';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import { FastField, FieldProps, Form, Formik } from 'formik';

import { CustomersSortBy } from '../../../../../../api/customer/types';
import { useGetPriceListsQuery } from '../../../../../../api/price-list';
import { ThemedButton } from '../../../../../../shared/components/themed-button';
import { FormikCheckbox } from '../../../../../../shared/components/formik-checkbox';
import { FormikDropdown } from '../../../../../../shared/components/formik-dropdown';
import { getCurrency } from '../../../../../../shared/helpers/getCurrency';
import { useScreenSize } from '../../../../../../shared/hooks/use-screen-size';
import { useAppDispatch, useAppSelector } from '../../../../../../store';
import {
  getCustomersSortFilters,
  resetCustomersFilters,
  setCustomersFilterBy,
  setCustomersSortBy,
} from '../../../../../../store/customers';

import { useStyles } from './style';

export const customerSortParams = [
  { label: 'Customer name (A - Z)', value: 'user_company_name asc' },
  { label: 'Customer name (Z - A)', value: 'user_company_name desc' },
  { label: 'Contact name (A - Z)', value: 'user_first_name asc' },
  { label: 'Contact name (Z - A)', value: 'user_first_name desc' },
  { label: 'Sort price lists (A - Z)', value: 'user_price_lists_name asc' },
  { label: 'Sort price lists (Z - A)', value: 'user_price_lists_name desc' },
  { label: 'Sales to date (Low to High)', value: 'sales_to_date asc' },
  { label: 'Sales to date (High to Low)', value: 'sales_to_date desc' },
  { label: 'Last order (Most recent to Old)', value: 'last_order_date asc' },
  { label: 'Last order (Old to Most recent ', value: 'last_order_date desc' },
];

interface Values {
  sortBy?: string;
  status_in?: string[];
  user_price_lists_name?: string[];
}

interface Props {
  handleCloseModal: () => void;
  isFullScreen?: boolean;
}

export const CustomerFilter: React.FC<Props> = ({ handleCloseModal, isFullScreen }) => {
  const classes = useStyles();
  const { isMobile } = useScreenSize();
  const dispatch = useAppDispatch();

  const [isAllPriceListsShown, setIsAllPriceListsShown] = useState(false);

  const { data: priceLists = [] } = useGetPriceListsQuery();
  const { filter = {}, sort } = useAppSelector(getCustomersSortFilters);

  const togglePriceLists = () => {
    setIsAllPriceListsShown((prev) => !prev);
  };

  const onReset = () => {
    dispatch(resetCustomersFilters({ sort: true, filter: true }));
  };

  const onSubmit = ({ sortBy, ...data }: Values) => {
    sortBy?.trim() && dispatch(setCustomersSortBy(sortBy.split(' ') as CustomersSortBy));
    Object.values(data).length && dispatch(setCustomersFilterBy(data));
    handleCloseModal();
  };

  return (
    <Formik
      enableReinitialize={true}
      onSubmit={onSubmit}
      initialValues={{
        sortBy: sort ? sort?.join(' ') : ' ',
        ...filter,
      }}
    >
      {({ submitForm }) => (
        <Form className={classes.formBox}>
          <div className={classes.root}>
            <div className={classes.sortBlock}>
              <div className={classes.blockTitle}>SORT BY</div>
              <FastField name='sortBy'>
                {(fieldProps: FieldProps) => (
                  <FormikDropdown
                    placeholder='Select sort option'
                    options={customerSortParams}
                    {...fieldProps}
                    height={36}
                    inputFontSize={14}
                  />
                )}
              </FastField>
            </div>
            <div className={classes.filterBlock}>
              <div className={classes.blockTitle}>FILTER BY</div>
              <div className={clsx([classes.blockTitle, classes.blockSubTitle])}>STATUS</div>
              <div className={classes.checkboxBlock}>
                <FastField type='checkbox' name='status_in' value='1'>
                  {(fieldProps: FieldProps) => <FormikCheckbox {...fieldProps} labelPlace='end' label='Connected' />}
                </FastField>
                <FastField type='checkbox' name='status_in' value='0'>
                  {(fieldProps: FieldProps) => <FormikCheckbox {...fieldProps} labelPlace='end' label='Requested' />}
                </FastField>
                <FastField type='checkbox' name='status_in' value='2'>
                  {(fieldProps: FieldProps) => <FormikCheckbox {...fieldProps} labelPlace='end' label='New request' />}
                </FastField>
              </div>
              <div className={clsx([classes.blockTitle, classes.blockSubTitle, classes.price])}>{`${getCurrency()} PRICE LIST`}</div>
              <div className={clsx(classes.checkboxBlock, isAllPriceListsShown && classes.checkboxBlockHeight)}>
                {priceLists.slice(0, isAllPriceListsShown ? priceLists.length : 6).map((item) => (
                  <FastField type='checkbox' name='user_price_lists_name' value={item.name} key={item.id}>
                    {(fieldProps: FieldProps) => (
                      <FormikCheckbox
                        {...fieldProps}
                        labelPlace='end'
                        label={item.name.length > 17 ? `${item.name.slice(0, 16).trimEnd()}...` : item.name}
                      />
                    )}
                  </FastField>
                ))}
              </div>
              {priceLists.length > 6 && (
                <div className={classes.moreOptTrigger} onClick={togglePriceLists}>
                  {isAllPriceListsShown ? 'SHOW LESS' : 'SHOW MORE'}
                  <ChevronRightIcon className={clsx(classes.actionsIcon, isAllPriceListsShown && classes.actionsIconRotated)} />
                </div>
              )}
            </div>
            <div className={clsx([classes.btnBlock, !isFullScreen && classes.btnBlockFullScreen])}>
              <ThemedButton onClick={submitForm} title='Apply' buttonStyle='primary' isSmall={isMobile} />
              <ThemedButton
                onClick={onReset}
                title='CLEAR FILTERS'
                buttonStyle='icon'
                startIcon={<Close color='inherit' />}
                isSmall={isMobile}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
