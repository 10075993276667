import React, { Suspense } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { Loader } from '../../shared/components/loader';
import { Login } from './Login';
import { SignUp } from './SignUp';

import { useStyles } from './style';
import { useCurrentTheme } from '../../api/admin-themes/hooks';
import clsx from 'clsx';

const ResetPassword = React.lazy(() => import('./ResetPassword'));
const ClaimAccount = React.lazy(() => import('./ClaimAccount'));

export const AuthDrawer: React.FC = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { loading } = useCurrentTheme();

  return (
    <>
      <div className={clsx(classes.authContainer, loading && classes.authContainerLoading)}>
        <Switch>
          <Route path='/login' component={Login} />
          <Route path='/signup' component={SignUp} />
          {pathname.endsWith('/claim') && (
            <Suspense fallback={<Loader />}>
              <Route path='/claim' component={ClaimAccount} />
            </Suspense>
          )}
          {pathname.endsWith('/reset_password') && (
            <Suspense fallback={<Loader />}>
              <Route path='/reset_password' component={ResetPassword} />
            </Suspense>
          )}
          <Redirect to='/login' />
        </Switch>
      </div>
      {loading && <Loader />}
    </>
  );
};
