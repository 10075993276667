import * as React from 'react';

function SvgCategoryPoultry(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M14.605 8.15l.065.068.093-.006c2.288-.148 4.02.44 5.274 1.671 1.261 1.237 2.067 3.147 2.445 5.691.416 2.796-1.517 5.29-3.995 5.29H4.092c-1.632 0-2.812-1.86-2.182-3.625l-.187-.067.187.067c.516-1.444 1.676-3.55 4.285-5.568l.115-.09-.05-.136-1.027-2.864a3.05 3.05 0 01.077-2.248l.053-.118-.087-.096L3.52 4.16l-.06-.067H2.05c-.34 0-.65-.313-.65-.747s.31-.748.65-.748h1.022V1.484c0-.434.311-.748.65-.748.34 0 .65.314.65.748V3.03l.052.057L6.18 5.046l.106.118.14-.076a2.196 2.196 0 011.972-.086s0 0 0 0l4.756 2.12.082-.182-.082.182c.54.241 1.03.59 1.45 1.029zM7.93 6.398h0c-.476-.213-.95-.05-1.247.282-.295.33-.428.833-.248 1.334l1.902 5.299.189-.067-.189.067c.505 1.404 1.717 2.33 3.102 2.33 1.861 0 3.339-1.678 3.339-3.696 0-1.521-.825-2.865-2.092-3.43L7.93 6.396zm-1.019 6.86l-.096-.266-.219.18c-1.669 1.37-2.894 2.986-3.483 4.634-.258.724.21 1.564.98 1.564h14.394c1.725 0 2.985-1.715 2.713-3.544-.293-1.975-.842-3.461-1.71-4.479-.873-1.025-2.054-1.553-3.562-1.646l-.306-.02.106.288c1.27 3.44-1.026 7.17-4.289 7.17-1.884 0-3.594-1.278-4.304-3.257l-.224-.623z'
        fill='#fff'
        stroke='#fff'
        strokeWidth={0.4}
      />
    </svg>
  );
}

export default SvgCategoryPoultry;
