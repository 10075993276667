import { RootState } from '../index';
import { manualProductsAdapter } from './manual-products.reducer';

export const getManualProductSortFilters = (state: RootState) => ({
  sort: state.manualProducts.sortBy,
  filter: state.manualProducts.filterBy,
  keyword: state.manualProducts.keyword,
  currentPage: state.manualProducts.currentPage,
});

export const getManualProductsCount = (state: RootState) => state.manualProducts.resultsCount;

export const getManualProductUpdateData = (state: RootState) => state.manualProducts.updateData;

export const selectManualProductUpdateData = (state: RootState, id: number) => state.manualProducts.updateData.find((el) => el.id === id);

export const manualProductsSelectors = manualProductsAdapter.getSelectors((state: RootState) => state.manualProducts.list);
