import React, { useState } from 'react';

import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx';
import { endOfDay, getHours, getMinutes, getSeconds } from 'date-fns';
import { FastField, Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';

import { Collapse, Switch } from '@material-ui/core';
import * as Yup from 'yup';
import { useOnboardManualSupplierMutation } from '../../../../api/company';
import { DeliveryDay } from '../../../../api/company/types';
import { useGetAddressQuery } from '../../../../api/user';
import { BackArrowButton } from '../../../../shared/components/back-arrrow-button';
import { ThemedButton } from '../../../../shared/components/themed-button';
import { FormikInput } from '../../../../shared/components/formik-input';
import { ImageUploadManualS } from '../../../../shared/components/image-upload-manual-s';
import { InfoTooltipComponent } from '../../../../shared/components/info-tooltip-component';
import { CustomTimePicker } from '../../../../shared/components/time-picker';
import { countries } from '../../../../shared/constants/countries';
import { ProductsCategories } from '../../../../shared/constants/products';
import { week } from '../../../../shared/constants/week';
import { getCategoryId } from '../../../../shared/helpers/getCategoryId';
import { useScreenSize } from '../../../../shared/hooks/use-screen-size';
import { LocalStorageService } from '../../../../shared/services/localStorage.service';
import { OnBoardSteps } from '../index';

import { useStyles } from './style';

interface DeliveryValues {
  minOrder?: string;
  freeDeliveryAt?: string;
  deliveryFee?: string;
  order_email?: string;
  phone_number?: string;
}

interface Props {
  setOnboardStep: (step: OnBoardSteps) => void;
}

const OnboardManualSupplierDetails: React.FC<Props> = ({ setOnboardStep }) => {
  const classes = useStyles();
  const { isMobile } = useScreenSize();

  const [moreExpanded, setMoreExpanded] = useState(false);
  const [isOrderEmail, setIsOrderEmail] = useState(false);
  const [deliveryDetails, setDeliveryDetails] = useState<DeliveryValues>({ minOrder: '0', freeDeliveryAt: '0', deliveryFee: '0' });
  const [logo, setLogo] = useState<Blob | null>(null);
  const [selectedDays, setSelectedDays] = useState<DeliveryDay[]>([
    DeliveryDay.MONDAY,
    DeliveryDay.TUESDAY,
    DeliveryDay.WEDNESDAY,
    DeliveryDay.THURSDAY,
    DeliveryDay.FRIDAY,
  ]);
  const [time, setTime] = useState<Date>(endOfDay(new Date()));
  const { defaultAddress } = useGetAddressQuery(undefined, {
    selectFromResult: ({ data }) => ({
      defaultAddress: data?.addresses?.find((el) => el.default_address)?.full_address || '',
    }),
  });

  const validationSchema = Yup.object().shape({
    order_email: Yup.string().email('Enter correct email!'),
  });

  const [onboard, { isLoading: onBoardLoading }] = useOnboardManualSupplierMutation();

  const onChangeTime = (value: Date) => {
    setTime(value);
  };

  const onSetDeliveryDay = (day: DeliveryDay) => {
    if (selectedDays.includes(day)) {
      setSelectedDays((state) => state.filter((item) => item !== day));
    } else {
      setSelectedDays((state) => [...state, day]);
    }
  };

  const finishOnBoarding = (values: DeliveryValues) => {
    const supplierName = LocalStorageService.getItem('manualSupplierName') || 'Supplier';
    const supplierCat = LocalStorageService.getItem('manualSupplierCategory');
    const supplierInfo = LocalStorageService.getItem('manualSupplierInfo');
    const country = countries.find((el) => el.value === LocalStorageService.getItem('country'))?.label || '';
    const address = supplierInfo?.name === supplierName && supplierInfo?.address ? `${supplierInfo.address}, ${country}` : defaultAddress;
    const reqBody = new FormData();
    reqBody.append('company[name]', supplierName);
    logo && reqBody.append('company[picture]', logo);
    reqBody.append('company[addresses_attributes][0][country]', country);
    reqBody.append('company[addresses_attributes][0][full_address]', address);
    values.minOrder !== undefined &&
      reqBody.append('company[delivery_details_attributes][0][minimum_order_value_cents]', (+values.minOrder * 100).toString());
    values.deliveryFee !== undefined &&
      reqBody.append('company[delivery_details_attributes][0][delivery_fee_cents]', (+values.deliveryFee * 100).toString());

    if (time) {
      const hours = getHours(time);
      const minutes = getMinutes(time);
      const seconds = getSeconds(time);
      const newDate = new Date(2000, 0, 1, hours, minutes, seconds, 0).toISOString();
      reqBody.append('company[delivery_details_attributes][0][cutoff]', newDate);
    }
    values.freeDeliveryAt !== undefined &&
      reqBody.append('company[delivery_details_attributes][0][amount_for_free_delivery_cents]', (+values.freeDeliveryAt * 100).toString());

    reqBody.append(`company[contacts_attributes][0][contact_type]`, 'order_contact');
    reqBody.append(`company[contacts_attributes][0][email]`, values.order_email || '');
    reqBody.append('company[contacts_attributes][0][is_order_email]', isOrderEmail.toString());
    reqBody.append(`company[contacts_attributes][1][contact_type]`, 'office');
    reqBody.append(`company[contacts_attributes][1][phone_number]`, values.phone_number || '');
    reqBody.append(`company[suppliers_attributes][0][email]`, values.order_email || '');

    reqBody.append(`company[company_categories_attributes][0][category_id]`, getCategoryId(supplierCat as ProductsCategories).toString());

    week.forEach((day) => {
      reqBody.append(`company[delivery_details_attributes][0][${day.key}]`, selectedDays.includes(day.key).toString());
    });
    onboard(reqBody).then((res) => {
      if ('data' in res && res.data?.success) {
        setOnboardStep(OnBoardSteps.MANUAL_UPLOAD);
        const supplierInfoToSave = {
          id: res.data?.company?.suppliers[0]?.id,
          name: res.data?.company?.name,
        };
        LocalStorageService.setItem('manual_supplier_id', supplierInfoToSave);
        LocalStorageService.clear('manualSupplierInfo');
        LocalStorageService.clear('manualSupplierCategory');
      }
    });
  };

  const onSubmitDeliveryDetails = (values: DeliveryValues, formikHelpers: FormikHelpers<DeliveryValues>) => {
    formikHelpers.setSubmitting(false);
    setDeliveryDetails(values);
    finishOnBoarding(values);
  };

  return (
    <Formik
      initialValues={deliveryDetails}
      onSubmit={onSubmitDeliveryDetails}
      enableReinitialize={true}
      validationSchema={validationSchema}
    >
      {({ submitForm, values, errors }) => (
        <Form className={classes.formBox}>
          <>
            <div className={classes.title}> Just a little more info...</div>
            <div className={classes.subTitle}>Don’t worry you can change this later</div>

            <div className={classes.detailsBox}>
              <div className={classes.deliveryBox}>
                <div className={classes.detailsTitle}>Delivery Information</div>
                <div className={classes.detailsSupTitle}>Select the days this supplier delivers</div>
                <div className={classes.weekDaysBox}>
                  {week.map(({ title, key }) => (
                    <div
                      key={key}
                      className={clsx({
                        [classes.weekDayItem]: true,
                        [classes.weekDayItemSelected]: selectedDays.includes(key),
                      })}
                      onClick={onSetDeliveryDay.bind(null, key)}
                    >
                      <CheckIcon className={classes.checkIcon} />
                      {title}
                    </div>
                  ))}
                </div>
                <div className={classes.detailFieldWrap}>
                  <div className={classes.cutOffLabel}>Order cut off time</div>
                  <CustomTimePicker time={time || endOfDay(new Date())} onSetTime={onChangeTime} />
                </div>
              </div>

              <div className={classes.field}>
                <FastField name='phone_number'>
                  {(fieldProps: FieldProps) => (
                    <FormikInput type='number' {...fieldProps} label='Contact Phone Number ' placeholder='Contact Number' />
                  )}
                </FastField>
              </div>
              <div className={classes.field}>
                <div className={classes.orderEmailWrap}>
                  <span>SEND orders as a purchase order email?</span>
                  <Switch
                    checked={isOrderEmail}
                    onChange={() => setIsOrderEmail(!isOrderEmail)}
                    color='primary'
                    classes={{
                      thumb: classes.thumb,
                      track: classes.track,
                      checked: classes.checked,
                    }}
                  />
                </div>
                <FastField name='order_email'>
                  {(fieldProps: FieldProps) => <FormikInput type='email' {...fieldProps} label='' placeholder='Email Address' />}
                </FastField>
                <div className={classes.ordersEmailNote}>
                  {isOrderEmail ? 'Orders will be sent as a purchase order to this email address' : 'No orders will be sent'}
                </div>
              </div>

              <InfoTooltipComponent text={'Optional Fields'}>
                <div
                  onClick={() => setMoreExpanded((prev) => !prev)}
                  className={clsx(classes.detailsTitle, classes.deliverySettingsT, moreExpanded && classes.deliverySettingsTExpanded)}
                >
                  More Delivery Settings
                  <svg width='6' height='9' viewBox='0 0 6 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M0.248959 0.502866C0.580905 0.17092 1.1191 0.17092 1.45104 0.502866L4.95104 4.00287C5.28299 4.33481 5.28299 4.873 4.95104 5.20495L1.45104 8.70495C1.11909 9.03689 0.580905 9.03689 0.248959 8.70495C-0.0829868 8.373 -0.0829867 7.83481 0.248959 7.50287L3.14792 4.60391L0.248959 1.70495C-0.0829865 1.373 -0.0829864 0.834811 0.248959 0.502866Z'
                      fill='#152144'
                    />
                  </svg>
                </div>
              </InfoTooltipComponent>
              <Collapse in={moreExpanded}>
                <div className={classes.detailsRow}>
                  <div>
                    <Field name='minOrder'>
                      {(fieldProps: FieldProps) => (
                        <FormikInput
                          {...fieldProps}
                          label={isMobile ? 'Min. order value' : 'Minimum order value'}
                          placeholder='0$'
                          type='number'
                        />
                      )}
                    </Field>
                  </div>
                  <div>
                    <Field name='deliveryFee'>
                      {(fieldProps: FieldProps) => <FormikInput {...fieldProps} label='Delivery Fee' placeholder='0$' type='number' />}
                    </Field>
                  </div>
                  <div>
                    <Field name='freeDeliveryAt'>
                      {(fieldProps: FieldProps) => <FormikInput {...fieldProps} label='Free Delivery at' placeholder='0$' type='number' />}
                    </Field>
                  </div>
                </div>
                <ImageUploadManualS saveImage={setLogo} clearLogo={setLogo.bind(null, null)} />
              </Collapse>
            </div>
            <div className={classes.btnsBox}>
              <BackArrowButton onClick={setOnboardStep.bind(null, OnBoardSteps.SUPPLIERS)} />
              <ThemedButton onClick={submitForm} title='Save' width={isMobile ? 150 : 215} disabled={onBoardLoading} />
            </div>
          </>
        </Form>
      )}
    </Formik>
  );
};

export default OnboardManualSupplierDetails;
