import { createReducer } from '@reduxjs/toolkit';

import { RangeOptions } from '../../shared/components/range-dropdown';
import { logoutThunk } from '../user/user.actions';
import { setDashboardMode, setDashboardSalesRange, setSelectedDate } from './dashboard.actions';

export enum DashboardMode {
  DEMO = 'DEMO',
  EMPTY = 'EMPTY',
  FILLED = 'FILLED',
}

interface State {
  selectedDate: string;
  dashboardMode: DashboardMode;
  sales: {
    days?: number;
    startDate?: string;
    endDate?: string;
    range: RangeOptions;
  };
}

const initialState: State = {
  selectedDate: new Date().toString(),
  dashboardMode: DashboardMode.EMPTY,
  sales: {
    range: RangeOptions.LAST_7,
    days: 0,
  },
};

export const dashboardReducer = createReducer(initialState, (builder) => {
  builder.addCase(logoutThunk.fulfilled, () => {
    return initialState;
  });
  builder.addCase(setSelectedDate, (state, { payload }) => {
    state.selectedDate = payload;
  });
  builder.addCase(setDashboardMode, (state, { payload }) => {
    state.dashboardMode = payload;
  });
  builder.addCase(setDashboardSalesRange, (state, { payload }) => {
    state.sales.range = payload.range;
    state.sales.days = payload.days;
    state.sales.startDate = payload.startDate;
    state.sales.endDate = payload.endDate;
  });
});
