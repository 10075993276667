import { format } from 'date-fns';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { getCurrency } from './getCurrency';

export const generateSalesImportSheet = async (dataArray: { id: string; name: string; price: number }[]) => {
  const workbook = new ExcelJS.Workbook();
  workbook.created = new Date();
  workbook.modified = new Date();
  const sheet = workbook.addWorksheet('Products', {
    views: [{ state: 'frozen', xSplit: 0, ySplit: 4 }],
  });
  sheet.getRow(1).height = 30;
  sheet.mergeCells('A1:E1');
  sheet.getCell('A1').border = {
    top: { style: 'medium', color: { argb: '969696' } },
    left: { style: 'medium', color: { argb: '969696' } },
    bottom: { style: 'medium', color: { argb: '969696' } },
    right: { style: 'medium', color: { argb: '969696' } },
  };
  sheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
  sheet.getCell('A1').font = {
    name: 'Calibri',
    size: 18,
    bold: true,
  };
  sheet.getCell('A1').value = 'Sales (for import)';
  sheet.getCell('A1').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'efefef' },
  };

  sheet.mergeCells('A2:E2');
  sheet.getCell('A2').border = {
    top: { style: 'medium', color: { argb: '969696' } },
    left: { style: 'medium', color: { argb: '969696' } },
    bottom: { style: 'medium', color: { argb: '969696' } },
    right: { style: 'medium', color: { argb: '969696' } },
  };

  sheet.getRow(3).height = 25;
  sheet.getRow(3).border = {
    top: { style: 'medium', color: { argb: '969696' } },
    left: { style: 'medium', color: { argb: '969696' } },
    bottom: { style: 'medium', color: { argb: '969696' } },
    right: { style: 'medium', color: { argb: '969696' } },
  };
  sheet.getRow(3).alignment = { vertical: 'middle', horizontal: 'center' };
  sheet.getRow(3).font = {
    name: 'Calibri',
    size: 11,
    bold: true,
  };
  sheet.getCell('A3').value = 'Date';
  sheet.getCell('A3').alignment = { vertical: 'middle', horizontal: 'left' };
  sheet.getCell('A3').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'efefef' },
  };
  sheet.getCell('B3').value = 'Product/Recipe ID';
  sheet.getCell('B3').alignment = { vertical: 'middle', horizontal: 'left' };
  sheet.getCell('B3').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'efefef' },
  };
  sheet.getCell('C3').value = 'Product/Recipe';
  sheet.getCell('C3').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'efefef' },
  };
  sheet.getCell('D3').value = `Price (${getCurrency()})`;
  sheet.getCell('D3').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'efefef' },
  };
  sheet.getCell('E3').value = `Quantity Sold`;
  sheet.getCell('E3').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'efefef' },
  };

  sheet.getRow(4).values = [format(new Date(), 'd/MM/yyyy'), 'EXAMPLE1234', 'Example Veggie Burger', '25', '18'];
  sheet.getRow(4).font = {
    name: 'Arial',
    size: 10,
    italic: true,
    color: {
      argb: 'bf9000',
    },
  };
  sheet.getCell('A4').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'fff2cc' },
  };
  sheet.getCell('B4').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'fff2cc' },
  };
  sheet.getCell('C4').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'fff2cc' },
  };
  sheet.getCell('D4').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'fff2cc' },
  };
  sheet.getCell('E4').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'fff2cc' },
  };

  dataArray.forEach((item, idx) => {
    sheet.getRow(idx + 5).values = [format(new Date(), 'dd/MM/yyyy'), item.id, item.name, item.price];
  });

  sheet.getColumn('A').protection = {
    locked: false,
  };
  sheet.getColumn('A').width = 20;
  sheet.getColumn('B').protection = {
    locked: false,
  };
  sheet.getColumn('B').width = 20;
  sheet.getColumn('C').protection = {
    locked: false,
  };
  sheet.getColumn('C').width = 20;
  sheet.getColumn('D').protection = {
    locked: false,
  };
  sheet.getColumn('D').width = 20;
  sheet.getColumn('E').protection = {
    locked: false,
  };
  sheet.getColumn('E').width = 20;

  sheet.eachRow((row) => {
    if (row.number < 5) {
      row.protection = {
        locked: true,
      };
    }
    if (row.number > 1) {
      row.border = {
        top: { style: 'thin', color: { argb: '969696' } },
        left: { style: 'thin', color: { argb: '969696' } },
        bottom: { style: 'thin', color: { argb: '969696' } },
        right: { style: 'thin', color: { argb: '969696' } },
      };
    }
    if (row.number > 3) {
      row.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      row.getCell(1).alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
      row.getCell(1).numFmt = 'dd/mm/yyyy';
      row.getCell(1).font = {
        color: {
          argb: '666666',
        },
      };
      row.getCell(2).alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
    }
  });

  sheet.getCell('A4').font = {
    name: 'Arial',
    size: 10,
    italic: true,
    color: {
      argb: 'bf9000',
    },
  };

  await sheet.protect('pass', {
    selectLockedCells: false,
    selectUnlockedCells: true,
    formatColumns: true,
    deleteRows: true,
    deleteColumns: true,
    formatCells: true,
    formatRows: true,
    insertRows: true,
  });

  const bufferRes = await workbook.xlsx.writeBuffer();
  const blob = new Blob([bufferRes], { type: 'application/xlsx' });
  await saveAs(blob, 'Import_Sales.xlsx');
  return Promise.resolve();
};
