import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';
import { Sizes } from '../../../styles/sizes';

export interface StyleProps {
  height?: number;
  inputFontSize?: number;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    root: {
      width: '100%',
      position: 'relative',
    },
    input: {
      margin: 0,
      padding: '5px 15px',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: colorVariables.steelGrey,
      borderRadius: 5,
      fontSize: ({ inputFontSize }) => inputFontSize || 16,
      fontWeight: 400,
      color: colorVariables.navy,
      lineHeight: ({ height }) => `${(height || 48) - 10}px`,
      height: ({ height }) => height || 48,
      width: '100%',
      backgroundColor: colorVariables.white,
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: '5px 10px',
      },
      '&::placeholder': {
        opacity: 1,
        color: colorVariables.steelGrey,
      },
      '&:hover': {
        borderColor: colorVariables.blue,
      },
      '&:focus': {
        borderColor: colorVariables.blue,
      },
      '&[type=number]': {
        '-moz-appearance': 'textfield',
      },
      '&[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
    label: {
      display: 'inline-block',
      fontSize: 12,
      lineHeight: '16px',
      marginBottom: 10,
    },
    toolTip: {
      display: 'block',
      minWidth: 125,
      maxWidth: '100%',
      minHeight: 24,
      borderRadius: 4,
      textAlign: 'center',
      fontSize: 11,
      lineHeight: '24px',
      padding: '0 5px',
      color: `${colorVariables.white} !important`,
      backgroundColor: colorVariables.navy,
      position: 'absolute',
      bottom: 60,
      left: 0,
      [theme.breakpoints.down(Sizes.mobile)]: {
        bottom: 58,
      },

      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: -15,
        left: 24,
        borderWidth: 8,
        borderStyle: 'solid',
        borderColor: `${colorVariables.navy} transparent transparent transparent`,
      },
    },
  }),
);
