import React from 'react';

import SvgTrash from '../../../../../../assets/icons/Trash';
import { ButtonsBlock } from '../../../../../../shared/components/buttons-block';
import { OverlayModal } from '../../../../../../shared/components/overlay-modal';

import { useStyles } from './style';

interface Props {
  onClose: () => void;
  onSubmit: (data: any) => void;
  isModalOpen: boolean;
  description: string;
}

export const DeleteProductLogo: React.FC<Props> = React.memo(({ onClose, onSubmit, isModalOpen, description }) => {
  const classes = useStyles();

  const handleSubmit = () => {
    onSubmit({});
  };

  const closeHandler = () => {
    onClose();
  };

  return (
    <OverlayModal isOpen={isModalOpen} onClose={closeHandler} title='' animate={true}>
      <div className={classes.container}>
        <SvgTrash className={classes.deleteIcon} />
        <div className={classes.title}>Are you sure you want to delete Product Image?</div>
        <div className={classes.subTitle}>{description}</div>
        <ButtonsBlock onSave={handleSubmit} onCancel={closeHandler} saveTitle='Delete Image' />
      </div>
    </OverlayModal>
  );
});
