import { createAction } from '@reduxjs/toolkit';
import { PriceExceptionFilter, PriceExceptionSortBy } from '../../api/price-exception/types';

export const setPriceExceptionKeyword = createAction<string | undefined>('SET_PRICE_EXCEPTION_KEYWORD');

export const setPriceExceptionsPage = createAction<number>('SET_PRICE_EXCEPTION_PAGE');

export const setPriceExceptionsFilterSort =
  createAction<{ filter?: PriceExceptionFilter; sort?: PriceExceptionSortBy }>('SET_PRICE_EXCEPTION_FILTER_SORT');

export const resetPriceExceptionsFilters =
  createAction<{ page?: boolean; filter?: boolean; sort?: boolean }>('RESET_PRICE_EXCEPTION_FILTERS');
