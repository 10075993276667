import React from 'react';

import Button from '@material-ui/core/Button';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import clsx from 'clsx';

import { useStyles } from './style';
import { useCurrentTheme } from '../../../api/admin-themes/hooks';

interface Props {
  onClick: () => void;
  className?: string;
}

export const ExportButton: React.FC<Props> = React.memo(({ onClick, className = '' }) => {
  const { colors } = useCurrentTheme();

  const textColor = colors[`primary_btnText`];
  const bgColor = colors[`primary_btnBg`];

  const classes = useStyles({ textColor, bgColor });

  return (
    <Button
      classes={{
        root: clsx(classes.root, className),
      }}
      variant='contained'
      onClick={onClick}
      endIcon={<KeyboardArrowDownIcon />}
    >
      Export
    </Button>
  );
});
