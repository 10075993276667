import isFuture from 'date-fns/isFuture';
import jwt_decode from 'jwt-decode';

import { LocalStorageService } from './localStorage.service';

interface Token {
  user_id: number;
  exp: number;
}

export class TokenService {
  static setToken(token: string) {
    LocalStorageService.setItem('auth_token', token);
  }

  static getToken(): string | null {
    const token = LocalStorageService.getItem('auth_token');
    if (!token && typeof token !== 'string') {
      this.clear();
      return null;
    }
    try {
      const { exp, user_id } = jwt_decode<Token>(token);
      if (isFuture(exp * 1000) && user_id) {
        return token;
      } else {
        this.clear();
        return null;
      }
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  static getUserId(): number | null {
    const token = this.getToken();
    if (!token) {
      return null;
    }
    const { user_id } = jwt_decode<Token>(token);
    return user_id;
  }

  static clear() {
    LocalStorageService.clear('auth_token');
  }
}
