import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../../styles/colorVariables';
import { Sizes } from '../../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    searchBlock: {
      display: 'flex',
      flexDirection: 'column',
      width: '65vw',
      minWidth: 860,
      padding: '30px 0 0 60px',
      backgroundColor: colorVariables.white,
      position: 'fixed',
      justifyContent: 'flex-end',
      zIndex: 2,
      top: 215,
      transition: 'top ease-in-out 0.8s',
      boxShadow: `0 -100px 0 50px ${colorVariables.white}`,
      [theme.breakpoints.down(Sizes.desktop)]: {
        padding: '30px 40px 0 40px',
        width: '100%',
        minWidth: 'auto',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: '20px 20px 0 20px',
        top: 195,
      },
    },
    searchField: {
      width: 320,
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: '85%',
      },
    },
    scrollUp: {
      top: 215,
      [theme.breakpoints.down(Sizes.mobile)]: {
        top: 195,
      },
    },
    scrollDown: {
      top: 63,
      [theme.breakpoints.down(Sizes.mobile)]: {
        top: 50,
      },
    },
    searchBlockSupplierInfoExpanded: {
      top: 295,
      [theme.breakpoints.down(Sizes.mobile)]: {
        top: 345,
      },
    },
    searchWrap: {
      marginBottom: 35,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down(Sizes.desktop)]: {
        marginBottom: 18,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginBottom: 16,
      },
    },
    sortWrap: {
      display: 'flex',
      alignItems: 'center',
      height: 50,
      borderBottom: `2px solid ${colorVariables.steelGrey}`,
      [theme.breakpoints.down(Sizes.desktop)]: {
        height: 32,
        width: '100%',
        justifyContent: 'space-between',
      },
    },
    sortWrapHidden: {
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    filterBlock: {
      backgroundColor: colorVariables.white,
      display: 'flex',
      [theme.breakpoints.down(Sizes.desktop)]: {
        display: 'none',
      },
    },
    blockTitle: {
      textTransform: 'uppercase',
      fontSize: 12,
      lineHeight: '24px',
      color: colorVariables.grey60,
      fontWeight: 700,
    },
    filterOpt: {
      display: 'flex',
      marginLeft: 30,
      cursor: 'pointer',
      borderRadius: 5,
      '& span': {
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '24px',
        marginRight: 5,
        color: colorVariables.userNavy,
      },
    },
    filterOptSelected: {
      '& span': {
        color: colorVariables.blue,
      },
    },
    sectionTitle: {
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 600,
      color: colorVariables.darkGrey,
      marginRight: 20,
      [theme.breakpoints.down(Sizes.desktop)]: {
        color: colorVariables.navy,
        fontWeight: 700,
      },
    },
    resultsCount: {
      fontSize: 14,
      lineHeight: '24px',
      color: colorVariables.userNavy,
    },
    sortBlock: {
      marginLeft: 'auto',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down(Sizes.desktop)]: {
        display: 'none',
      },
    },
    customSelectBtn: {
      height: 32,
      padding: '0 10px',
      display: 'block',
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '32px',
      color: colorVariables.navy,
    },
    customSelectFormControl: {
      width: 200,
      marginLeft: 10,
      height: 32,
      textAlign: 'right',
      fill: colorVariables.grey60,
      '& *:focus': {
        backgroundColor: 'transparent !important',
      },
    },
    filterIconMobile: {
      display: 'none',
      [theme.breakpoints.down(Sizes.desktop)]: {
        display: 'block',
        width: '15%',
        textAlign: 'center',
      },
    },
    iconButton: {
      width: 40,
      height: 40,
      padding: 5,
      color: 'inherit',
    },
    chipBox: {
      display: 'none',
      [theme.breakpoints.down(Sizes.desktop)]: {
        display: 'flex',
        width: '100%',
        overflowX: 'scroll',
        paddingBottom: 5,
      },
    },
    starIconFilled: {
      color: colorVariables.yellow,
      fontSize: 26,
    },
    starIcon: {
      color: colorVariables.grey40,
      fontSize: 26,
      '&:hover': {
        scale: 1.1,
        color: colorVariables.yellow,
      },
    },
    cartInfo: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 5,
      width: '100%',
      padding: '0 5px',
    },
    totalText: {
      color: colorVariables.userNavy,
    },
    totalCount: {
      fontSize: 16,
      fontWeight: 700,
      color: colorVariables.userNavy,
    },
    inactive: {
      opacity: 0.5,
      cursor: 'default',
      '&:hover': {
        opacity: 0.5,
        cursor: 'default',
      },
    },
    sectionTitlePantry: {
      width: '100%',
    },
    sectionTitleRec: {
      width: 'calc(100% - 120px)',
    },
    editSettingsBtn: {
      fontWeight: 600,
      color: colorVariables.blue,
      userSelect: 'none',
    },
  }),
);
