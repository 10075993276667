import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Switch } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Add from '@material-ui/icons/Add';
import clsx from 'clsx';
import { format, isSameDay } from 'date-fns';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import ReactDropzone from 'react-dropzone';
import { useDebouncedCallback } from 'use-debounce';

import {
  useAddHolidaysMutation,
  useChangeHolidaysDeliveryMutation,
  useCreatePostCodesMutation,
  useDeleteHolidaysMutation,
  useDeletePostCodesMutation,
  useExcludePostCodesMutation,
  useGetPostcodesQuery,
  useSetDeliveryDayByPostCodeMutation,
  useSetDeliveryFeeByDayMutation,
  useUploadPostcodesMutation,
} from '../../../../api/company';
import { DeliveryDay, PostCode } from '../../../../api/company/types';
import { useMeQuery } from '../../../../api/user';
import { HolidayDate } from '../../../../api/user/types';
import SvgUploadIcon from '../../../../assets/icons/UploadIcon';
import { AddButton } from '../../../../shared/components/add-button';
import { ThemedButton } from '../../../../shared/components/themed-button';
import { Dialog } from '../../../../shared/components/dialog';
import { FormikInput } from '../../../../shared/components/formik-input';
import { MultiDatePicker } from '../../../../shared/components/multi-date-picker';
import { NumberInput } from '../../../../shared/components/number-input';
import { OverlayModal } from '../../../../shared/components/overlay-modal';
import { PostCodeAutoComplete } from '../../../../shared/components/post-code-auto-complete';
import { week } from '../../../../shared/constants/week';
import { formatCurrency } from '../../../../shared/helpers/format-currency';
import { postCodeValidator, postCodeValidatorExcluded } from '../../../../shared/helpers/post-code-validator';
import { useScreenSize } from '../../../../shared/hooks/use-screen-size';
import { ToastService } from '../../../../shared/services/toastService';

import { useStyles } from './style';

interface Props {
  days: DeliveryDay[];
}

export const AdvancedDeliveryContent: React.FC<Props> = ({ days }) => {
  const classes = useStyles();
  const { isDesktop, isMobile, isTabletLandscape } = useScreenSize();
  const { push } = useHistory();

  const [fileName, setFileName] = useState('');
  const [selectedPostCode, setSelectedPostCode] = useState<PostCode | undefined>();
  const [isViewCurrentCodesShown, setIsViewCurrentCodesShown] = useState(false);
  const [isViewExcludedCodesShown, setIsViewExcludedCodesShown] = useState(false);
  const [isAddModalShown, setIsAddModalShown] = useState(false);
  const [isExcludeModalShown, setIsExcludeModalShown] = useState(false);
  const [isHolidaysModalShown, setIsHolidaysModalShown] = useState(false);
  const [isRemoveWarningShown, setIsRemoveWarningShown] = useState(false);
  const [postCodesToDelete, setPostCodesToDelete] = useState<number[]>([]);
  const [postCodesToAdd, setPostCodesToAdd] = useState<string[]>([]);
  const [localHolidays, setLocalHolidays] = useState<Partial<HolidayDate>[]>([]);

  const [uploadPostCodes, { isSuccess: uploadSuccess, isError: uploadError }] = useUploadPostcodesMutation();
  const [deletePostCodes, { isLoading: deleteLoading }] = useDeletePostCodesMutation();
  const [createPostCodes, { isLoading: createLoading }] = useCreatePostCodesMutation();
  const [excludePostCodes, { isLoading: excludeLoading }] = useExcludePostCodesMutation();
  const { data: currentPostcodes } = useGetPostcodesQuery({ current_postcode: true });
  const { data: excludedPostcodes } = useGetPostcodesQuery({});
  const [setFeeByDay] = useSetDeliveryFeeByDayMutation();
  const { data: user } = useMeQuery();
  const [setDeliveryDay] = useSetDeliveryDayByPostCodeMutation();
  const [changeHolidaysDelivery, { isLoading: changeHolidaysLoading }] = useChangeHolidaysDeliveryMutation();
  const [onDeleteHolidayDate] = useDeleteHolidaysMutation();
  const [onAddHolidayDate] = useAddHolidaysMutation();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFileName(acceptedFiles[0].name);
    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);
    uploadPostCodes(formData);
  }, []);

  const onChangeDeliveryFeeByDay = useDebouncedCallback((value: number, day: string) => {
    setFeeByDay({
      delivery_fee_by_day: {
        [`${day}_delivery_fee_cents`]: value * 100,
      },
    });
  }, 500);

  const handleHolidaysSwitch = () => {
    changeHolidaysDelivery();
  };

  const onRemoveAll = () => {
    setPostCodesToDelete(
      ((isViewExcludedCodesShown ? excludedPostcodes?.postcodes : currentPostcodes?.postcodes) || []).map((code) => code.id),
    );
    setIsRemoveWarningShown(false);
  };

  const onDeletePostcode = (id: number) => {
    setPostCodesToDelete((prev) => [...prev, id]);
  };

  const onSelectPostcode = (code: string | null) => {
    if (!currentPostcodes?.postcodes?.length) {
      return;
    }
    const selectedPostcode = currentPostcodes?.postcodes.find((postCode) => postCode.area_code === code);
    setSelectedPostCode(selectedPostcode?.area_code ? selectedPostcode : undefined);
  };

  const togglePostCodeDay = (day: DeliveryDay) => {
    setSelectedPostCode((prev) =>
      prev
        ? {
            ...prev,
            post_code_delivery_day: { ...prev.post_code_delivery_day, [day]: !prev.post_code_delivery_day[day] },
          }
        : undefined,
    );
  };

  const openPostCodesPage = (excluded: boolean) => {
    push('/account/view-postcodes', excluded);
  };

  const openAddPostCodesPage = (excluded: boolean) => {
    push('/account/add-postcodes', excluded);
  };

  const openAddHolidaysPage = () => {
    push('/account/add-holidays');
  };

  const onCloseAddModal = () => {
    isAddModalShown && setIsAddModalShown(false);
    isExcludeModalShown && setIsExcludeModalShown(false);
    setPostCodesToAdd([]);
  };

  const onRemoveCode = (code: string) => {
    setPostCodesToAdd((prev) => prev.filter((el) => el !== code));
  };

  const onCancelViewAll = () => {
    isViewCurrentCodesShown && setIsViewCurrentCodesShown(false);
    isViewExcludedCodesShown && setIsViewExcludedCodesShown(false);
    setPostCodesToDelete([]);
  };

  const onSaveViewAll = () => {
    if (deleteLoading) {
      return;
    }
    if (!postCodesToDelete.length) {
      onCancelViewAll();
      return;
    }
    deletePostCodes({ ids: postCodesToDelete });
    onCancelViewAll();
  };

  const onSubmit = ({ code }: { code: string }, formikHelpers: FormikHelpers<any>) => {
    const invalidCodes: string[] = [];
    setPostCodesToAdd((prev) => [
      ...prev,
      ...Array.from(
        new Set(
          code.split(' ').reduce((acc: string[], el) => {
            const validatedCode = isExcludeModalShown ? postCodeValidatorExcluded(el) : postCodeValidator(el);
            if (!validatedCode) {
              invalidCodes.push(el.toUpperCase());
            }
            if (validatedCode && !prev.includes(validatedCode)) {
              acc.push(validatedCode);
            }
            return acc;
          }, []),
        ),
      ),
    ]);
    invalidCodes.length && ToastService.error(`Invalid postcodes: ${invalidCodes.join(', ')} can't be saved`);
    formikHelpers.setSubmitting(false);
    formikHelpers.resetForm();
  };

  const onCreatePostCodes = () => {
    if (createLoading || !postCodesToAdd.length) {
      return;
    }
    createPostCodes({ postcodes: postCodesToAdd.join(',') });
    onCloseAddModal();
  };

  const onExcludePostCodes = () => {
    if (excludeLoading) {
      return;
    }
    excludePostCodes({ postcodes: postCodesToAdd.join(',') });
    onCloseAddModal();
  };

  const onCloseHolidayModal = () => {
    setLocalHolidays(user?.holidays || []);
    setIsHolidaysModalShown(false);
  };

  const onRemoveHoliday = (day?: string) => {
    setLocalHolidays((prev) => prev.filter((el) => el.holiday_date !== day));
  };

  const onSaveHolidays = () => {
    if (user?.holidays?.length) {
      const holidaysToDelete = user.holidays.filter(
        (el) => !localHolidays.some((item) => isSameDay(new Date(item.holiday_date as string), new Date(el.holiday_date))),
      );
      const holidaysToAdd = localHolidays.filter(
        (el) => !user?.holidays?.some((item) => isSameDay(new Date(item.holiday_date), new Date(el.holiday_date as string))),
      );
      holidaysToAdd.length &&
        onAddHolidayDate(holidaysToAdd.map((day) => format(new Date(day.holiday_date as string), 'dd/MM/yyyy')).join(','));
      holidaysToDelete.length && onDeleteHolidayDate(holidaysToDelete.map((day) => day.id));
    } else {
      onAddHolidayDate(localHolidays.map((day) => format(new Date(day.holiday_date as string), 'dd/MM/yyyy')).join(','));
    }
    onCloseHolidayModal();
  };

  const onSetHolidays = (dates: string[] | Date[]) => {
    setLocalHolidays(
      dates.map((el) => ({
        holiday_date: el.toString(),
      })),
    );
  };

  useEffect(() => {
    uploadSuccess && setFileName('');
  }, [uploadSuccess]);

  useEffect(() => {
    uploadError && ToastService.error('Errors in file data');
  }, [uploadError]);

  useEffect(() => {
    if (selectedPostCode) {
      const { id, ...post_code_delivery_day } = selectedPostCode.post_code_delivery_day;
      setDeliveryDay({
        delivery_postcode_id: id,
        post_code_delivery_day,
      });
    }
  }, [selectedPostCode]);

  useEffect(() => {
    user?.holidays && setLocalHolidays(user.holidays);
  }, [user?.holidays]);

  return (
    <div className={classes.root}>
      <div className={classes.subTitleText}>Post codes list</div>
      <div className={classes.advancedSectionHeadText}>
        Upload .xlsx file with post codes you deliver to - note that these are on top of your selected delivery radius
      </div>
      <ReactDropzone onDrop={onDrop} accept='.xlsx'>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div className={clsx(classes.uploadContainer, isDragActive && classes.focused)} {...getRootProps()}>
            <SvgUploadIcon className={classes.uploadIcon} />
            <input {...getInputProps()} />
            <div className={classes.uploadDescription}>
              {!fileName ? (
                isDesktop ? (
                  <div>
                    Drop file here to upload or <b className={classes.link}>choose a file</b>
                  </div>
                ) : (
                  <div>Choose file to upload</div>
                )
              ) : (
                <span>{fileName}</span>
              )}
            </div>
          </div>
        )}
      </ReactDropzone>
      <div className={classes.postCodeViewBox}>
        <div className={classes.postCodeView}>
          <div className={classes.postCodeViewHead}>
            <div className={classes.subTitleText}>Current Post codeS</div>
            {!isMobile ? (
              <div className={classes.addBtn} onClick={setIsAddModalShown.bind(null, true)}>
                <Add className={classes.addIcon} />
                Add Postcode
              </div>
            ) : (
              <div className={classes.addBtn} onClick={openAddPostCodesPage.bind(null, false)}>
                Add
                <Add className={classes.addIcon} />
              </div>
            )}
          </div>
          <div className={classes.postCodesList}>
            {currentPostcodes?.postcodes?.map((el) => (
              <span key={el.area_code}>{el.area_code}</span>
            ))}
            {currentPostcodes?.postcodes && currentPostcodes.postcodes.length > 24 ? <b>...</b> : ''}
          </div>
          <div className={classes.postCodesListBtnBox}>
            <ThemedButton
              onClick={!isMobile ? setIsViewCurrentCodesShown.bind(null, true) : openPostCodesPage.bind(null, false)}
              title='View All'
              customClass={classes.viewAllBtn}
              buttonStyle='primaryBordered'
              width={102}
            />
            <div className={classes.postcodesCount}>{currentPostcodes?.postcodes_count || 0} Current Post codes</div>
          </div>
        </div>
        <div className={clsx(classes.postCodeView, classes.borderBox)}>
          <div className={classes.postCodeViewHead}>
            <div className={classes.subTitleText}>Post codes to exclude</div>
            {!isMobile ? (
              <div className={classes.addBtn} onClick={setIsExcludeModalShown.bind(null, true)}>
                <Add className={classes.addIcon} />
                Add Postcode
              </div>
            ) : (
              <div className={classes.addBtn} onClick={openAddPostCodesPage.bind(null, true)}>
                Add
                <Add className={classes.addIcon} />
              </div>
            )}
          </div>
          <div className={clsx(classes.postCodesList, classes.excludedCodesList)}>
            {excludedPostcodes?.postcodes?.map((el) => (
              <span key={el.area_code}>{el.area_code}</span>
            ))}
            {excludedPostcodes?.postcodes && excludedPostcodes.postcodes.length > 24 ? <b>...</b> : ''}
          </div>
          <div className={classes.postCodesListBtnBox}>
            <ThemedButton
              onClick={!isMobile ? setIsViewExcludedCodesShown.bind(null, true) : openPostCodesPage.bind(null, true)}
              title='View All'
              customClass={classes.viewAllBtn}
              width={102}
              buttonStyle='primaryBordered'
            />
            <div className={classes.postcodesCount}>{excludedPostcodes?.postcodes_count || 0} Excluded Post codes</div>
          </div>
        </div>
      </div>
      <div className={classes.postCodeViewBox}>
        <div className={classes.postCodeView}>
          <div className={classes.subTitleText}>delivery FEE BY day</div>
          <div className={classes.advancedSectionHeadText}>Set various delivery fee’s depending on the day</div>
          <div className={classes.weekBlock}>
            {week.map((day) => (
              <div className={classes.weekDay} key={day.key}>
                <div className={clsx(classes.weekDayTitle, !days.includes(day.key) && classes.weekDayTitleDisabled)}>{day.title}</div>
                <NumberInput
                  onChange={(value) => onChangeDeliveryFeeByDay(value, day.longTitle)}
                  decimals={2}
                  disabled={!days.includes(day.key)}
                  placeholder={formatCurrency(0, 0)}
                  value={
                    user && 'delivery_fee_by_day' in user && `${day.longTitle}_delivery_fee_cents` in user.delivery_fee_by_day
                      ? (user.delivery_fee_by_day[`${day.longTitle}_delivery_fee_cents`] || 0) / 100
                      : 0
                  }
                />
              </div>
            ))}
          </div>
          <div className={classes.holidaysBlock}>
            <div className={classes.holidaysLabel}>HOLIDAYS (NO DELIVERY)</div>
            <Switch
              checked={!!user?.holidays_delivery}
              onChange={handleHolidaysSwitch}
              disabled={changeHolidaysLoading}
              color='primary'
              classes={{
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
              }}
            />
          </div>
          {!!user?.holidays_delivery && (
            <div className={classes.holidaysContent}>
              <div className={classes.holidaysContentHead}>
                <div className={classes.holidaysTitle}>Add holidays of which there will be no delivery</div>
                <div
                  className={clsx(classes.addBtn, classes.addBtnHolidays)}
                  onClick={isDesktop || isTabletLandscape ? setIsHolidaysModalShown.bind(null, true) : openAddHolidaysPage}
                >
                  <Add className={classes.addIcon} />
                  {isMobile ? 'ADD' : 'Add Days'}
                </div>
              </div>
              <div>
                {user?.holidays?.map((el) => (
                  <div key={el.id} className={classes.dateItem}>
                    {format(new Date(el.holiday_date), 'dd.LL')}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className={clsx(classes.postCodeView, classes.borderBox)}>
          <div className={classes.subTitleText}>Post code delivery days</div>
          <div className={classes.advancedSectionHeadText}>Set delivery days per post code</div>
          <div className={classes.postCodeDeliveryWrap}>
            <div className={classes.postcodeDropdown}>
              <PostCodeAutoComplete
                options={currentPostcodes?.postcodes?.map((el) => el.area_code)?.filter((el) => !!el && !!el?.trim()) || []}
                setValue={onSelectPostcode}
              />
            </div>
            <div className={clsx(classes.weekBlock, classes.weeksBlockPostcodes)}>
              {week.map((day) => (
                <div className={classes.weekDay} key={day.key}>
                  <div
                    className={clsx(
                      classes.weekDayTitle,
                      (!selectedPostCode || (selectedPostCode && !selectedPostCode.post_code_delivery_day[day.key])) &&
                        classes.weekDayTitleDisabled,
                    )}
                  >
                    {day.title}
                  </div>
                  <div className={classes.radioBox} onClick={togglePostCodeDay.bind(null, day.key)}>
                    <div
                      className={clsx(
                        classes.radioBtn,
                        selectedPostCode && selectedPostCode.post_code_delivery_day[day.key] && classes.radioBtnActive,
                      )}
                    >
                      <div />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {(isViewCurrentCodesShown || isViewExcludedCodesShown) && (
        <OverlayModal
          boxClassName={classes.viewAllModal}
          isOpen={isViewCurrentCodesShown || isViewExcludedCodesShown}
          onClose={onCancelViewAll}
        >
          <div className={classes.viewAllContent}>
            <h2 className={classes.viewAllTitle}>{`${isViewCurrentCodesShown ? 'Current' : 'Excluded'} Post Codes`}</h2>
            <div className={classes.viewAllHeader}>
              <div className={classes.allCodesText}>All Post codes:</div>
              <div>{isViewCurrentCodesShown ? currentPostcodes?.postcodes?.length || 0 : excludedPostcodes?.postcodes?.length || 0}</div>
            </div>
            <div className={classes.viewAllContent}>
              <div className={classes.viewAllContentHead}>POST CODE</div>
              <div className={classes.postCodesTable}>
                {(isViewCurrentCodesShown && currentPostcodes?.postcodes
                  ? currentPostcodes.postcodes
                  : isViewExcludedCodesShown && excludedPostcodes?.postcodes
                  ? excludedPostcodes.postcodes
                  : []
                ).map((codeItem) =>
                  !postCodesToDelete.includes(codeItem.id) ? (
                    <div key={codeItem.id} className={classes.postCodeTableCell}>
                      {codeItem.area_code}
                      <div className={classes.closeIconWrap} onClick={onDeletePostcode.bind(null, codeItem.id)}>
                        <Close className={classes.closeIcon} />
                      </div>
                    </div>
                  ) : null,
                )}
              </div>
            </div>
            <div className={classes.viewAllBtnBox}>
              <ThemedButton
                onClick={setIsRemoveWarningShown.bind(null, true)}
                title='Remove all post codes'
                isSmall={true}
                bordered={true}
                buttonStyle='primaryBordered'
              />
              <ThemedButton onClick={onSaveViewAll} title='Save' isSmall={true} width={100} disabled={deleteLoading} />
              <ThemedButton onClick={onCancelViewAll} title='Cancel' isSmall={true} buttonStyle='secondary' width={100} />
              {isRemoveWarningShown && (
                <Dialog
                  title='Are you sure you want to delete all post codes'
                  onConfirm={onRemoveAll}
                  onCancel={setIsRemoveWarningShown.bind(null, false)}
                  customClass={classes.removeWarning}
                />
              )}
            </div>
          </div>
        </OverlayModal>
      )}
      {(isAddModalShown || isExcludeModalShown) && (
        <OverlayModal boxClassName={classes.viewAllModal} isOpen={isAddModalShown || isExcludeModalShown} onClose={onCloseAddModal}>
          <div className={classes.viewAllContent}>
            <h2 className={classes.addTitle}>Add Post Codes</h2>
            <div className={classes.addHeader}>
              <div className={classes.addSubTitle}>Separate postcodes with a space</div>
              <div className={classes.postCodesTotal}>
                <span className={classes.allCodesText}>All Post codes:</span>
                <span>{isAddModalShown ? currentPostcodes?.postcodes?.length || 0 : excludedPostcodes?.postcodes?.length || 0}</span>
              </div>
            </div>
            <Formik
              initialValues={{
                code: '',
              }}
              onSubmit={onSubmit}
            >
              {({ submitForm }) => (
                <Form>
                  <div className={classes.addBox}>
                    <div className={classes.inputWrap}>
                      <Field name='code'>
                        {(fieldProps: FieldProps) => (
                          <FormikInput
                            {...fieldProps}
                            label={!isExcludeModalShown ? 'New Current Post Code' : 'New Excluded Post Code'}
                            placeholder='2316'
                            skipDebounce={true}
                          />
                        )}
                      </Field>
                    </div>
                    <div className={classes.addBoxBtns}>
                      <AddButton isDark={true} onClick={submitForm} />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
            <div className={clsx(classes.addedCodesList, postCodesToAdd.length > 50 && classes.addedCodesListScrolled)}>
              {postCodesToAdd.map((code, idx) => (
                <div key={idx} className={classes.postCodeTableCell}>
                  {code}
                  <div className={classes.closeIconWrap} onClick={onRemoveCode.bind(null, code)}>
                    <Close className={classes.closeIcon} />
                  </div>
                </div>
              ))}
            </div>
            <div className={classes.addBtnBox}>
              <ThemedButton
                onClick={isExcludeModalShown ? onExcludePostCodes : onCreatePostCodes}
                title='Save'
                isSmall={true}
                width={100}
                disabled={createLoading || excludeLoading || !postCodesToAdd.length}
              />
              <ThemedButton onClick={onCloseAddModal} title='Cancel' isSmall={true} buttonStyle='secondary' width={100} />
            </div>
          </div>
        </OverlayModal>
      )}
      {isHolidaysModalShown && (
        <OverlayModal boxClassName={classes.holidaysModal} isOpen={isHolidaysModalShown} onClose={onCloseHolidayModal}>
          <>
            <div className={classes.holidaysModalHead}>
              <h3 className={classes.holidaysModalTitle}>Add Holidays</h3>
              <div className={classes.holidaysSubtitle}>There will be no delivery on the specified days for the year 2023</div>
            </div>
            <div className={classes.holidaysModalContent}>
              <div>
                <MultiDatePicker dates={localHolidays.map((el) => el.holiday_date as string)} onSetDates={onSetHolidays} />
              </div>
              <div>
                <div className={classes.holidaysListHead}>
                  <div className={classes.holidaysListTitle}>SPECIFIED DAYS</div>
                  <div>{localHolidays.length}</div>
                </div>
                <div className={classes.holidaysListBox}>
                  {localHolidays.map((day, idx) => (
                    <div key={idx} className={classes.holidaysCell}>
                      {day.holiday_date ? format(new Date(day.holiday_date), 'dd.LL') : ''}
                      <div className={classes.closeIconWrap} onClick={onRemoveHoliday.bind(null, day.holiday_date)}>
                        <Close className={classes.closeIcon} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className={classes.holidaysBtns}>
              <ThemedButton onClick={onSaveHolidays} title='Save' isSmall={true} width={90} />
              <ThemedButton onClick={onCloseHolidayModal} title='Cancel' isSmall={true} buttonStyle='secondary' width={90} />
            </div>
          </>
        </OverlayModal>
      )}
    </div>
  );
};
