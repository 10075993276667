import React, { useEffect, useState } from 'react';

import SvgDone from '../../../../assets/icons/Done';
import { ThemedButton } from '../../../../shared/components/themed-button';
import { CountrySelect } from '../../../../shared/components/country-select';
import { OverlayModal } from '../../../../shared/components/overlay-modal';
import { countries } from '../../../../shared/constants/countries';
import { LocalStorageService } from '../../../../shared/services/localStorage.service';

import { useStyles } from './style';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
}

export const CountryModal: React.FC<Props> = ({ isOpen, closeModal }) => {
  const classes = useStyles();
  const [countryCode, setCountryCode] = useState('');

  const ipCountry = LocalStorageService.getItem('country_by_ip') as {
    country_code2: string;
    country_name: string;
  } | null;
  const isCountryAvailable = ipCountry && countries.some((el) => el.value === ipCountry.country_code2);

  const onSelect = (code: string): void => {
    setCountryCode(code);
  };

  const onConfirm = () => {
    LocalStorageService.setItem('country', countryCode);
    closeModal();
  };

  useEffect(() => {
    if (isCountryAvailable && ipCountry) {
      setCountryCode(ipCountry.country_code2);
    }
  }, [isCountryAvailable]);

  return (
    <OverlayModal isOpen={isOpen} onClose={countryCode ? onConfirm : () => {}} animate={true} closeOnClickOutside={false}>
      <div className={classes.modalRoot}>
        <div className={classes.modalWrap}>
          <SvgDone className={classes.doneIconModal} />
          <div className={classes.modalTitle}>
            {ipCountry?.country_name ? `It looks like you are visiting from ${ipCountry?.country_name}` : 'Please select your country'}
          </div>
          <div className={classes.modalDesc}>Is this correct? Please select your country</div>
          <div className={classes.selectWrap}>
            <CountrySelect selected={countryCode || 'au'} onSelect={onSelect} />
          </div>
          <div className={classes.btnBox}>
            <ThemedButton onClick={onConfirm} title='Continue' disabled={!countryCode} />
          </div>
        </div>
      </div>
    </OverlayModal>
  );
};
