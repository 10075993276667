import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../../styles/colorVariables';
import { Sizes } from '../../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      display: 'flex',
      alignItems: 'flex-end',
      position: 'sticky',
      top: 261,
      zIndex: 10,
      backgroundColor: colorVariables.white,
      boxShadow: `0 -12.5px 0 12.5px ${colorVariables.white}`,
      [theme.breakpoints.down(Sizes.desktop)]: {
        top: 231,
        boxShadow: `0 -10px 0 10px ${colorVariables.white}`,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        justifyContent: 'space-between',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        flexWrap: 'wrap',
        top: 167,
      },
    },
    form: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 15,
      paddingBottom: 15,
      [theme.breakpoints.down(Sizes.tablet)]: {
        paddingBottom: 0,
        marginBottom: 10,
      },
    },
    searchWrap: {
      width: 325,
      marginRight: 25,
      [theme.breakpoints.down(Sizes.tablet)]: {
        width: 250,
      },
    },
    filterWrap: {
      display: 'flex',
      marginRight: 5,
      flexShrink: 0,
      alignItems: 'center',
      [theme.breakpoints.down(Sizes.tablet)]: {
        display: 'none',
      },
    },
    filterTitle: {
      fontSize: 12,
      lineHeight: '23px',
      textTransform: 'uppercase',
      color: colorVariables.grey60,
      fontWeight: 700,
      marginRight: 5,
      flexShrink: 0,
      [theme.breakpoints.down(Sizes.desktop)]: {
        display: 'none',
      },
    },
    sortTitle: {
      transform: 'translateX(17px)',
    },
    sortWrap: {
      display: 'flex',
      marginRight: 5,
      alignItems: 'center',
      [theme.breakpoints.down(Sizes.tablet)]: {
        display: 'none',
      },
    },
    addIcon: {
      color: colorVariables.green,
      fontSize: '30px !important',
      [theme.breakpoints.down(Sizes.desktop)]: {
        fontSize: '28px !important',
      },
    },
    addBtn: {
      flexShrink: 0,
      transform: 'translateX(15px)',
      marginLeft: 'auto',
      fontSize: 14,
      [theme.breakpoints.down(Sizes.desktop)]: {
        height: 40,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        transform: 'translateX(10px)',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    headCell: {
      textAlign: 'left',
      color: colorVariables.grey60,
      fontSize: 12,
      fontWeight: 700,
      textTransform: 'uppercase',
      paddingLeft: 10,
    },
    headRow: {
      display: 'flex',
      padding: '5px 20px 10px 20px',
      flexGrow: 1,
      backgroundColor: colorVariables.grey10,
      height: 38,
      borderBottom: `2px solid ${colorVariables.steelGrey}`,
      borderRadius: 2,
      [theme.breakpoints.down(Sizes.tablet)]: {
        padding: '5px 10px 10px 10px',
      },
    },
    table: {
      display: 'flex',
      flexDirection: 'column',
    },
    head: {
      display: 'block',
      position: 'sticky',
      top: 339,
      zIndex: 2,
      boxShadow: `0 0 0 ${colorVariables.white}`,
      [theme.breakpoints.down(Sizes.desktop)]: {
        top: 300,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        top: 200,
      },
    },
    name: {
      width: 'calc((100% - 450px)*0.5)',
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: 'calc((100% - 330px)*0.5)',
      },
    },
    suppliers: {
      width: 450,
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: 330,
      },
    },
    items: {
      width: 'calc((100% - 450px)*0.15)',
      textAlign: 'center',
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: 'calc((100% - 330px)*0.15)',
      },
    },
    actions: {
      width: 'calc((100% - 450px)*0.35)',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'relative',
      paddingLeft: '5%',
      [theme.breakpoints.down(Sizes.desktop)]: {
        paddingLeft: '3%',
        width: 'calc((100% - 330px)*0.35)',
      },
    },
    filterBtnMobile: {
      display: 'none',
      [theme.breakpoints.down(Sizes.tablet)]: {
        display: 'inline-flex',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginLeft: 'auto',
      },
    },
    addBtnWrap: {
      display: 'none',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'block',
        marginBottom: 25,
      },
    },
    chipBox: {
      display: 'none',
      [theme.breakpoints.down(Sizes.tablet)]: {
        display: 'flex',
        width: '100%',
        overflowX: 'scroll',
        paddingBottom: 5,
      },
    },
    loadingBox: {
      paddingTop: '25vh',
    },
    progressBox: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      paddingTop: 30,
      paddingBottom: 30,
    },
    emptyContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: 65,
      [theme.breakpoints.down(Sizes.mobile)]: {
        paddingTop: 30,
      },
    },
    emptyTitle: {
      fontSize: 40,
      lineHeight: '56px',
      fontWeight: 700,
      textAlign: 'center',
      marginBottom: 25,
      [theme.breakpoints.down(Sizes.mobile)]: {
        fontSize: 28,
        lineHeight: '34px',
      },
    },
    emptySubTitle: {
      opacity: 0.7,
      marginTop: 30,
      textAlign: 'center',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 300,
      },
    },
  }),
);
