import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { colorVariables } from '../../../../../../styles/colorVariables';

export const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    rootField: {
      width: '100%',
      height: 40,
      position: 'relative',
      backgroundColor: colorVariables.white,
      color: colorVariables.navy,
      borderRadius: 4,
      overflow: 'hidden',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: colorVariables.steelGrey,
      },
      '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        borderColor: colorVariables.blue,
        borderWidth: 1,
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
        borderColor: colorVariables.blue,
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: colorVariables.blue,
        borderWidth: 1,
      },
      '& .MuiSelect-nativeInput': {
        border: 'none !important',
      },
    },
    select: {
      padding: '5px 15px',
      display: 'block',
      boxSizing: 'border-box',
      height: 40,
      lineHeight: '30px',
      fontSize: 16,
      fontWeight: 400,
      color: colorVariables.navy,
      backgroundColor: 'white !important',
      '&:hover': {
        backgroundColor: 'rgba(152, 165, 183, 0.06)',
      },
      '&:focus': {
        backgroundColor: 'rgba(152, 165, 183, 0.06)',
      },
    },
    menuItem: {
      fontSize: 14,
      fontWeight: 400,
      color: colorVariables.navy,
      padding: '5px 10px',
      marginTop: 2,
      minHeight: 40,
      lineHeight: '30px',
      backgroundColor: colorVariables.transparent,
      '&:hover': {
        backgroundColor: colorVariables.blueLight,
      },
      '&:focus': {
        backgroundColor: colorVariables.blueLight,
      },
      '&:active': {
        backgroundColor: colorVariables.blueLight,
      },
    },
    selected: {
      backgroundColor: colorVariables.blueLight,
      '&:hover': {
        backgroundColor: colorVariables.blueLight,
      },
      '&:focus': {
        backgroundColor: colorVariables.blueLight,
      },
      '&:active': {
        backgroundColor: colorVariables.blueLight,
      },
    },
    empty: {
      backgroundColor: 'transparent !important',
      zIndex: -1,
      position: 'absolute',
    },
    unselect: {
      borderBottom: `1px solid ${colorVariables.steelGrey}`,
    },
    icon: {
      fill: colorVariables.steelGrey,
    },
    iconDefault: {
      fill: 'inherit',
    },
    box: {
      width: '100%',
      position: 'relative',
    },
    driverColor: {
      width: 14,
      height: 14,
      borderRadius: 2,
      marginRight: 14,
    },
    selectWrap: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);
