import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'absolute',
      zIndex: 999,
      padding: '13px 22px',
      minWidth: 250,
      maxWidth: 400,
      margin: 20,
      backgroundColor: colorVariables.blue,
      color: colorVariables.white,
      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
      borderRadius: 10,
      fontSize: 14,
    },
    redMode: {
      backgroundColor: '#EA6363',
    },
    overlay: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 998,
    },
    title: {
      fontWeight: 400,
      marginBottom: 20,
    },
    confirmText: {
      fontWeight: 700,
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: 7,
    },
    confirmBtn: {
      width: 34,
      height: 34,
      backgroundColor: colorVariables.green,
      color: colorVariables.white,
      marginRight: 15,
      '&:hover': {
        backgroundColor: colorVariables.greenDark,
        boxShadow: `0 0 6px ${colorVariables.greenDark}`,
      },
    },
    cancelBtn: {
      width: 34,
      height: 34,
      backgroundColor: 'rgb(129,196,229)',
      color: colorVariables.white,
      marginRight: 10,
      '&:hover': {
        backgroundColor: 'rgb(38,148,201)',
        boxShadow: `0 0 6px ${colorVariables.blue}`,
      },
    },
    cancelBtnRed: {
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.4)',
        boxShadow: `0 0 6px ${colorVariables.red}`,
      },
    },
  }),
);
