import { CompanyCategories } from '../../api/company/types';
import { Category } from '../../api/product/types';
import { ProductsCategories } from '../constants/products';
import { SessionStorageService } from '../services/sessionStorage.service';

export const getCategoryId = (category: ProductsCategories): number => {
  const categories = SessionStorageService.getItem('categories') || [];
  return categories.find((el: any) => el.label === category)?.id as number;
};

export const getCategoriesFromIds = (catList: Category[] | CompanyCategories[]) => {
  const categories = SessionStorageService.getItem('categories') || [];
  return catList.map((el) => {
    return categories.find((item: any) => item.id === el.id)?.label;
  });
};
