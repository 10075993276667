import * as React from 'react';

function SvgListIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g fill='currentColor'>
        <path d='M6.25 5a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zM6.25 10a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zM6.25 15a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z' />
        <path d='M20 3.27c0-.867-.365-1.699-1.015-2.312A3.575 3.575 0 0016.533 0H3.467c-.92 0-1.802.345-2.452.958A3.18 3.18 0 000 3.27v13.46c0 .867.365 1.699 1.015 2.312.65.613 1.532.958 2.452.958h13.066c.92 0 1.801-.345 2.452-.958A3.18 3.18 0 0020 16.73V3.27zm-1.6 13.46c0 .467-.197.915-.547 1.245-.35.33-.825.516-1.32.516H3.467c-.495 0-.97-.186-1.32-.516A1.712 1.712 0 011.6 16.73V3.27c0-.467.197-.915.547-1.245.35-.33.825-.516 1.32-.516h13.066c.495 0 .97.186 1.32.516.35.33.547.778.547 1.245v13.46z' />
        <path d='M14.693 9.245H8.826a.825.825 0 00-.565.221.734.734 0 00-.235.534c0 .2.085.392.235.534.15.141.353.22.565.22h5.867c.212 0 .416-.079.566-.22a.734.734 0 00.234-.534c0-.2-.084-.392-.234-.534a.825.825 0 00-.566-.22zM14.693 4.214H8.826a.825.825 0 00-.565.22.734.734 0 00-.235.535c0 .2.085.392.235.533.15.142.353.221.565.221h5.867c.212 0 .416-.08.566-.22a.734.734 0 00.234-.534c0-.2-.084-.393-.234-.534a.825.825 0 00-.566-.221zM14.693 14.276H8.826a.825.825 0 00-.565.221.734.734 0 00-.235.534c0 .2.085.392.235.534.15.141.353.22.565.22h5.867c.212 0 .416-.079.566-.22a.734.734 0 00.234-.534c0-.2-.084-.392-.234-.534a.825.825 0 00-.566-.22z' />
      </g>
    </svg>
  );
}

export default SvgListIcon;
