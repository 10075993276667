import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../../../../styles/colorVariables';
import { Sizes } from '../../../../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    modalTitle: {
      fontSize: 18,
      fontWeight: 700,
      marginBottom: 25,
      marginTop: 5,
    },
    modalBox: {
      padding: 40,
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: 25,
      },
    },
    field: {
      width: 445,
      marginBottom: 30,
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        minWidth: 250,
      },
    },
    fieldBox: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 20,
    },
    btnBox: {
      marginTop: 50,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    calendarIcon: {
      color: colorVariables.navy,
      marginLeft: 20,
    },
    calendarTrigger: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
    },
    dateLabel: {
      width: 120,
      flexShrink: 0,
    },
  }),
);
