import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import clsx from 'clsx';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { useConfirmMutation, useGoogleLoginMutation, useSignUpMutation } from '../../../api/auth';
import { RegisterRequest } from '../../../api/auth/types';
import { userApi } from '../../../api/user';
import Logo from '../../../assets/images/logo/Logo_White.svg';
import { ThemedButton } from '../../../shared/components/themed-button';
import { FormikInput } from '../../../shared/components/formik-input';
import { GoogleIdentityService } from '../../../shared/services/google-identity.service';
import { LocalStorageService } from '../../../shared/services/localStorage.service';
import { TokenService } from '../../../shared/services/token.service';
import { useAppDispatch } from '../../../store';
import { useStyles } from './style';
import { useCurrentTheme } from '../../../api/admin-themes/hooks';
import { useIsWhiteLabeled } from '../../../shared/hooks/useIsWhiteLabeled';

export const SignUp: React.FC = () => {
  const classes = useStyles();
  const { search } = useLocation();
  const { replace } = useHistory();
  const dispatch = useAppDispatch();
  const isConfirm = search.includes('confirmation_token=');
  const token = search.split('confirmation_token=')[1];
  const reg_email = search.split('reg_email=')[1];
  const g_token = search.split('g_token=')[1];
  const { logoUrl, name } = useCurrentTheme();
  const isWhiteLabeled = useIsWhiteLabeled();

  const [email, setEmail] = useState<string | null>(null);
  const [isCheckEmailShown, setIsCheckEmailShown] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);

  const isLoginAvailable = GoogleIdentityService.checkIfGoogleAvailable();

  const [signUp, { error, isSuccess, isLoading }] = useSignUpMutation();
  const [confirmEmail] = useConfirmMutation();
  const [googleLogin, { isLoading: isGoogleLoading }] = useGoogleLoginMutation();

  const authSchema = Yup.object().shape({
    email: Yup.string().required('Required field').email('Please enter a valid email address'),
    password: Yup.string().required('Required field').min(8, 'Password too short'),
    first_name: Yup.string().required('Required field'),
    last_name: Yup.string().required('Required field'),
    phone_number: Yup.string().required('Required field'),
  });

  const onSubmit = (formValues: RegisterRequest, formikHelpers: FormikHelpers<RegisterRequest>) => {
    formikHelpers.setSubmitting(false);
    email !== formValues.email && setEmail(formValues.email);
    signUp(formValues);
  };

  const onGLogin = (id_token: string) => {
    googleLogin({
      id_token,
    });
  };

  const setEmailField = (value: string, field: string, isValid: boolean, validateHandler: (field: string) => void) => {
    validateHandler(field);
    !!value.trim() && isValid && setEmail(value);
  };

  const hideErrorMsg = () => {
    setIsErrorShown(false);
  };

  useEffect(() => {
    setIsErrorShown(!!error);
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      LocalStorageService.setItem('reg_email', email);
      setIsCheckEmailShown(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (reg_email) {
      LocalStorageService.setItem('reg_email', reg_email);
      setIsCheckEmailShown(true);
      setEmail(reg_email);
      replace('/signup');
    }
  }, [reg_email]);

  useEffect(() => {
    if (g_token) {
      TokenService.setToken(g_token);
      dispatch(userApi.endpoints.me.initiate(undefined, { forceRefetch: true })).then(() => {
        TokenService.setToken(g_token);
        replace('/');
      });
    }
  }, [g_token]);

  useEffect(() => {
    if (isConfirm) {
      setIsCheckEmailShown(true);
      confirmEmail({ token }).then((res) => {
        if ('data' in res && (res.data.sign_up_status === 'menu_costing' || res.data.sign_up_status === 'restaurent_pro_suite')) {
          replace('/activate_subscription', res.data.user_email);
        }
      });
    }
  }, [isConfirm]);

  useEffect(() => {
    GoogleIdentityService.getIdToken(onGLogin);

    return () => {
      GoogleIdentityService.onLogout();
      document.getElementById('gid-script')?.remove();
    };
  }, []);

  useLayoutEffect(() => {
    // Update the title
    document.title = `Sign up | Wholesale Online Ordering System for Restaurants | ${name === undefined ? ' ' : name || 'Open Pantry'}`;

    // Update the meta description
    const metaDescriptionTag = document.querySelector('meta[name="description"]') as HTMLMetaElement;
    if (metaDescriptionTag) {
      metaDescriptionTag.content = `Our restaurant management software includes many features such as managing COGS, menu costing, order automation, waste control, insights, etc. Subscribe now!`;
    } else {
      // If the meta tag doesn't exist, create and append it to the head
      const newMetaTag = document.createElement('meta');
      newMetaTag.name = 'description';
      newMetaTag.content = `Our restaurant management software includes many features such as managing COGS, menu costing, order automation, waste control, insights, etc. Subscribe now!`;
      document.head.appendChild(newMetaTag);
    }

    // Cleanup function (optional)
    return () => {
      document.title = name === undefined ? ' ' : name || 'Open Pantry';
      // You can also remove the meta description tag if necessary
      const existingMetaTag = document.querySelector('meta[name="description"]');
      if (existingMetaTag) {
        document.head.removeChild(existingMetaTag);
      }
    };
  }, [name]);

  return (
    <div className={classes.root}>
      <div className={classes.logoBox}>
        <img src={logoUrl || Logo} alt='OpenPantry' className={classes.logo} style={{ objectFit: 'contain', maxHeight: 70 }} />
      </div>
      {isCheckEmailShown ? (
        <>
          <div className={classes.signUpTitle}>Check your email</div>
          <div className={clsx([classes.authFormBox, classes.checkEmailBox])}>
            {email ? (
              <div className={classes.checkEmailContent}>
                We’ve sent an email to <br />
                <span className={classes.link}>{email}</span> <br />
                with a link to activate your account.
              </div>
            ) : (
              <div className={classes.checkEmailContent}>We’ve sent an email with a link to activate your account.</div>
            )}
            <a className={classes.blueLink} href='https://theopenpantry.com/contact-us'>
              Click here if you didn’t get an email from Open Pantry
            </a>
          </div>
        </>
      ) : (
        <>
          <div className={classes.signUpTitle}>Create your free account</div>
          <div className={classes.authFormBox}>
            <Formik
              initialValues={{ email: '', password: '', first_name: '', last_name: '', phone_number: '' }}
              onSubmit={onSubmit}
              validationSchema={authSchema}
              enableReinitialize={true}
            >
              {({ submitForm, errors, validateField, values }) => (
                <Form>
                  {isErrorShown && error && (
                    <div className={classes.errorBox}>
                      <IconButton className={classes.closeErrorBtn} onClick={hideErrorMsg}>
                        <Close className={classes.closeError} />
                      </IconButton>
                      <div className={classes.errorText}>
                        {'data' in error ? error.data.message : 'The email or password you have entered is incorrect'}
                      </div>
                    </div>
                  )}
                  {!email && (
                    <>
                      {isLoginAvailable && (
                        <>
                          <div id='googleSignInBtn' className={classes.gBtnBox} />
                          <div className={classes.orText}>OR</div>
                        </>
                      )}
                      <div className={classes.fieldBox}>
                        <Field name='email'>
                          {(fieldProps: FieldProps) => (
                            <FormikInput
                              {...fieldProps}
                              type='email'
                              label='Work Email'
                              placeholder='Email'
                              autoFocus={true}
                              autoComplete='off'
                              skipDebounce={true}
                            />
                          )}
                        </Field>
                      </div>
                    </>
                  )}
                  {!!email && (
                    <>
                      <div className={classes.fieldBox}>
                        <Field name='email'>
                          {(fieldProps: FieldProps) => (
                            <FormikInput
                              {...fieldProps}
                              type='email'
                              label='Work Email'
                              placeholder='Email'
                              autoComplete='username'
                              skipDebounce={true}
                            />
                          )}
                        </Field>
                      </div>
                      <div className={classes.nameFields}>
                        <div className={classes.fieldBox}>
                          <Field name='first_name'>
                            {(fieldProps: FieldProps) => <FormikInput {...fieldProps} label='First Name' placeholder='First Name' />}
                          </Field>
                        </div>
                        <div className={classes.fieldBox}>
                          <Field name='last_name'>
                            {(fieldProps: FieldProps) => <FormikInput {...fieldProps} label='Last Name' placeholder='Last Name' />}
                          </Field>
                        </div>
                      </div>
                      <div className={classes.fieldBox}>
                        <Field name='phone_number'>
                          {(fieldProps: FieldProps) => (
                            <FormikInput {...fieldProps} type='number' label='Phone Number' placeholder='Phone Number' />
                          )}
                        </Field>
                      </div>
                      <div className={classes.fieldBox}>
                        <Field name='password'>
                          {(fieldProps: FieldProps) => (
                            <FormikInput
                              {...fieldProps}
                              type='password'
                              label='Password'
                              placeholder='Password'
                              autoComplete='new-password'
                              skipDebounce={true}
                            />
                          )}
                        </Field>
                      </div>
                    </>
                  )}
                  <div className={classes.btnBox}>
                    <ThemedButton
                      disabled={isLoading || isGoogleLoading}
                      onClick={email ? submitForm : setEmailField.bind(null, values.email, 'email', !errors.email, validateField)}
                      title='Sign Up'
                    />
                  </div>
                </Form>
              )}
            </Formik>
            <div className={classes.bottomBox}>
              <span>Already have an account? </span>
              <Link className={classes.link} to='/login'>
                Sign in here
              </Link>
            </div>
          </div>
        </>
      )}
      <div className={classes.terms}>
        <div>By signing up to Open Pantry, you agree to our</div>
        <div className={classes.termsLinksBox}>
          <a href='https://theopenpantry.com/terms' className={classes.link} target='_blank' rel='noreferrer'>
            Terms of Service
          </a>
          <span> & </span>
          <a
            href={
              window.location.hostname === 'app.kitchenmate.com.au'
                ? 'https://my.kitchenmate.com.au/privacy_policy'
                : 'https://theopenpantry.com/privacy'
            }
            className={classes.link}
            target='_blank'
            rel='noreferrer'
          >
            Privacy Policy
          </a>
        </div>
      </div>

      {isWhiteLabeled && (
        <div className={classes.poweredBox}>
          <span>Powered by</span>
          <img src={Logo} alt='OpenPantry' width={136} />
        </div>
      )}
    </div>
  );
};
