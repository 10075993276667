import { pantryListSortByOptions } from '../../components/AppDrawer/RestaurantDrawer/PantryListsPage/PantryList';
import { RootState } from '../index';
import { pantryListAdapter } from './pantry-lists.reducer';

export const getPantryListExitWarningHandler = (state: RootState) => state.pantryList.exitWarning.handler;

export const getSelectedPantryListId = (state: RootState) => state.pantryList.selectedPantryListId;

export const getPantryListProductFilter = (state: RootState) => state.pantryList.detailFilter;

export const getPantryListSortFilters = (state: RootState) => ({
  currentPage: state.pantryList.currentPage,
  keyword: state.pantryList.keyword,
  sort: state.pantryList.sortBy,
  filter: state.pantryList.filterBy,
});

export const pantryListsSelectors = pantryListAdapter.getSelectors((state: RootState) => state.pantryList.list);

export const getPantryListChips = (state: RootState) => {
  const sortLabel = pantryListSortByOptions.find((param) => param.value === state.pantryList.sortBy?.join(' '))?.label || '';

  const sortChip = {
    label: sortLabel,
    key: sortLabel ? 'sort' : '',
  };

  const categoryChip = {
    label: state.pantryList.filterBy as string,
    key: state.pantryList.filterBy ? 'category' : '',
  };

  return [sortChip, categoryChip].filter((chip) => !!chip.label && !!chip.key);
};
