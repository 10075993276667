import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../styles/colorVariables';
import { Sizes } from '../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    title: {
      fontSize: 40,
      lineHeight: '50px',
      fontWeight: 700,
      marginBottom: 20,
      fontFamily: 'Sofia Pro',
      textAlign: 'center',
      [theme.breakpoints.down(Sizes.desktop)]: {
        fontSize: 36,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        fontSize: 30,
        lineHeight: '40px',
        padding: '0 15px',
      },
    },
    subTitle: {
      opacity: 0.7,
      marginBottom: 25,
      textAlign: 'center',
      lineHeight: '22px',
      [theme.breakpoints.down(Sizes.desktop)]: {
        marginBottom: 20,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        marginBottom: 30,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: '0 30px',
      },
    },
    boldText: {
      fontWeight: 700,
    },
    notFoundText: {
      marginTop: 10,
      marginBottom: 20,
      cursor: 'pointer',
      width: '100%',
      textAlign: 'center',
      '&:hover': {
        color: colorVariables.blue,
        [theme.breakpoints.down(Sizes.mobile)]: {
          color: 'inherit',
        },
      },
    },
    addLater: {
      width: 'fit-content',
      textAlign: 'center',
      margin: '0 auto',
      padding: '20px 0',
      [theme.breakpoints.down(Sizes.desktop)]: {
        paddingBottom: 40,
      },
    },
    uploadContainerWrap: {
      width: '100%',
      height: 195,
      backgroundColor: colorVariables.transparent,
      marginBottom: 20,
      borderRadius: 27,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: 20,
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: 160,
        height: 160,
        padding: 0,
        position: 'relative',
        boxShadow: '2px 2px 21px 3px rgba(12, 82, 112, 0.08)',
        marginBottom: 30,
      },
    },
    uploadContainer: {
      border: '1px dashed #152144',
      borderRadius: 12,
      margin: '15px auto',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 30,
      [theme.breakpoints.down(Sizes.desktop)]: {
        border: 'none',
        margin: 0,
      },
    },
    uploadIcon: {
      width: 50,
      height: 50,
      marginBottom: 15,
    },
    uploadDescription: {
      fontSize: 14,
      color: colorVariables.navy,
      marginTop: 10,
      fontWeight: 700,
      textAlign: 'center',
    },
    focused: {
      border: `2px dashed ${colorVariables.green}`,
    },
    link: {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    downTemplateBtn: {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      fontSize: 14,
      lineHeight: '25px',
      height: 25,
      marginBottom: 20,
    },
    formats: {
      display: 'flex',
      marginBottom: 30,
      '& div': {
        width: 75,
        height: 30,
        borderRadius: 15,
        backgroundColor: colorVariables.white,
        fontSize: 12,
        fontWeight: 700,
        lineHeight: '30px',
        textAlign: 'center',
        '&:first-child': {
          marginRight: 15,
        },
      },
    },
    topBox: {
      display: 'flex',
      width: '100%',
      marginBottom: 5,
      justifyContent: 'space-between',
    },
    uploadProgressContainer: {
      width: 885,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      marginBottom: 10,
      '&>div': {
        flexGrow: 1,
        marginLeft: 25,
      },
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: 710,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        width: 520,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        '&>div': {
          flexGrow: 1,
          marginLeft: 0,
        },
      },
    },
    fileIcon: {
      width: 32,
      height: 44,
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    doneIcon: {
      fontSize: 18,
      color: colorVariables.blue,
      marginBottom: -2,
    },

    error: {
      color: colorVariables.red,
    },
    errorsBlock: {
      maxWidth: 850,
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 15,
      fontStyle: 'italic',
      fontSize: 12,
      fontWeight: 600,
      color: '#EA6363',
      flexGrow: 0,
      [theme.breakpoints.down(Sizes.desktop)]: {
        maxWidth: 650,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        maxWidth: 545,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 'calc(100% - 45px)',
        flexShrink: 0,
      },
    },
    errorsBlockWrap: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    detailsSupTitle: {
      fontSize: 12,
      opacity: 0.7,
    },
    deliverySettingsT: {
      cursor: 'pointer',
      display: 'inline-block',
      minWidth: 200,
      '& svg': {
        transition: 'transform ease-in-out 0.5s',
        marginLeft: 8,
        transform: 'rotate(0deg)',
      },
    },
    deliverySettingsTExpanded: {
      '& svg': {
        transform: 'rotate(90deg)',
      },
    },
    btnsBox: {
      justifyContent: 'center',
      marginBottom: 32,
      marginTop: 40,
      width: 500,
      display: 'grid',
      gridTemplateColumns: '1fr 230px 1fr',
      '&>*:first-child': {
        transform: 'translateX(-25px)',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        gridTemplateColumns: '1fr 160px 1fr',
        '&>*:first-child': {
          transform: 'translateX(5px)',
        },
      },
    },
  }),
);
