import * as React from 'react';

function SvgImportIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 65 62' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M30.223 1.89l.013-.013.012-.013C30.818 1.246 31.62.9 32.5.9c.778 0 1.678.4 2.279.993l.002.002 14.933 14.973c1.233 1.28 1.21 3.284-.05 4.472-1.303 1.228-3.353 1.207-4.563-.045h0l-.008-.009-7.845-7.897-1.539-1.549v28.016c0 1.706-1.454 3.159-3.209 3.159-1.755 0-3.21-1.453-3.21-3.16V11.84l-1.538 1.549-7.845 7.897h0l-.008.01c-1.208 1.249-3.252 1.272-4.556.05-.67-.664-.995-1.398-.995-2.23 0-.864.35-1.667.928-2.238h0l.005-.005L30.223 1.89z'
        fill='#51D9B0'
        stroke='#fff'
        strokeWidth={1.8}
      />
      <path
        d='M56.782 54.781h.9v-15.44c0-1.707 1.454-3.16 3.209-3.16 1.765 0 3.209 1.39 3.209 3.16v18.6c0 1.706-1.454 3.159-3.21 3.159H4.11C2.353 61.1.9 59.647.9 57.94V39.341c0-1.707 1.454-3.16 3.21-3.16 1.754 0 3.208 1.453 3.208 3.16v15.44h49.464z'
        fill='#51D9B0'
        stroke='#fff'
        strokeWidth={1.8}
      />
    </svg>
  );
}

export default SvgImportIcon;
