import { createAction } from '@reduxjs/toolkit';

import { RecOrderSortBy } from '../../api/recurring-order/types';
import { ProductsCategories } from '../../shared/constants/products';

export const setSelectedRecOrder = createAction<number>('SET_SELECTED_RECURRING_ORDER');
export const showCreateRecOrderModal = createAction('SHOW_CREATE_RECURRING_ORDER_MODAL');
export const hideCreateRecOrderModal = createAction('HIDE_CREATE_RECURRING_ORDER_MODAL');
export const showEditRecOrderModal = createAction('SHOW_EDIT_RECURRING_ORDER_MODAL');
export const hideEditRecOrderModal = createAction('HIDE_EDIT_RECURRING_ORDER_MODAL');
export const showRecOrderExitWarning = createAction<{ handler: () => void }>('SHOW_RECURRING_ORDER_EXIT_WARNING');
export const hideRecOrderExitWarning = createAction('HIDE_RECURRING_ORDER_EXIT_WARNING');
export const setRecOrderKeyword = createAction<string>('SET_RECURRING_ORDER_KEYWORD');
export const setRecOrderPage = createAction<number>('SET_RECURRING_ORDER_PAGE');
export const deleteRecurringOrder = createAction<number>('DELETE_RECURRING_ORDER');
export const setRecurringOrderSortFilters =
  createAction<{ sort?: RecOrderSortBy; filter: ProductsCategories | null }>('SET_RECURRING_ORDER_SORT_FILTERS');
