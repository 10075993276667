import * as React from 'react';

function SvgHistoryIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g>
        <path
          d='M6.875 1.333c-1.36 0-2.689.43-3.82 1.236A7.252 7.252 0 00.523 5.86a7.789 7.789 0 00-.39 4.237 7.497 7.497 0 001.88 3.755 6.754 6.754 0 003.52 2.007 6.478 6.478 0 003.973-.418 6.992 6.992 0 003.085-2.7 7.686 7.686 0 001.159-4.075c-.001-1.944-.726-3.809-2.015-5.183-1.29-1.375-3.037-2.149-4.86-2.15zm0 13.006a5.085 5.085 0 01-2.955-.956 5.61 5.61 0 01-1.959-2.546A6.025 6.025 0 011.66 7.56a5.8 5.8 0 011.455-2.905 5.224 5.224 0 012.723-1.553 5.01 5.01 0 013.073.323 5.409 5.409 0 012.387 2.09c.585.933.896 2.03.896 3.151-.001 1.505-.562 2.947-1.559 4.01-.997 1.064-2.349 1.662-3.759 1.663z'
          fill='currentColor'
        />
        <path
          d='M7.8 9.253a.324.324 0 00.032-.06.963.963 0 00.031-.09v-.06a.84.84 0 000-.16v-3.76a.814.814 0 00-.23-.57.781.781 0 00-1.117 0 .814.814 0 00-.231.57v3.33l-1.227.832a.804.804 0 00-.31.406.821.821 0 00-.006.514.805.805 0 00.3.414c.142.1.312.15.485.143a.764.764 0 00.436-.134l1.58-1.074a.767.767 0 00.12-.102l.053-.065.047-.059a.33.33 0 00.037-.075z'
          fill='currentColor'
        />
        <path
          d='M13.75 2.667h5M13.75 14.667h5M16.25 6.667h2.5M16.25 10.667h2.5'
          stroke='currentColor'
          strokeWidth={1.2}
          strokeLinecap='round'
        />
      </g>
    </svg>
  );
}

export default SvgHistoryIcon;
