import React, { MouseEvent, useEffect } from 'react';

import Grow from '@material-ui/core/Grow';
import clsx from 'clsx';

import { CloseButton } from '../close-button';

import { colorVariables } from '../../../styles/colorVariables';
import { useStyles } from './style';

interface Props {
  onClose: () => void;
  isOpen: boolean;
  title?: string | React.ReactElement;
  boxClassName?: string;
  contentWrapClassName?: string;
  showClose?: boolean;
  animate?: boolean;
  closeOnClickOutside?: boolean;
}

export const OverlayModal: React.FC<Props> = ({
  onClose,
  children,
  title,
  isOpen,
  boxClassName = '',
  showClose = true,
  animate,
  closeOnClickOutside,
  contentWrapClassName = '',
}) => {
  const classes = useStyles();

  const preventClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  useEffect(() => {
    document.body.style.overflowY = isOpen ? 'hidden' : 'scroll';
  }, [isOpen]);

  useEffect(() => {
    return () => {
      document.body.style.overflowY = 'scroll';
    };
  }, []);

  const closeHandler = () => {
    document.body.style.overflowY = 'scroll';
    onClose();
  };

  return (
    <Grow in={isOpen} timeout={0}>
      <div className={classes.overlay} onClick={closeOnClickOutside ? closeHandler : undefined}>
        <div className={clsx([classes.modalBox, boxClassName])} onClick={closeOnClickOutside ? preventClick : undefined}>
          {title && <div className={classes.title}>{title}</div>}
          {showClose && <CloseButton onClick={closeHandler} customClass={classes.closeBtn} color={colorVariables.steelGrey} />}
          {isOpen && <div className={clsx(classes.contentBox, contentWrapClassName)}>{children}</div>}
        </div>
      </div>
    </Grow>
  );
};
