import { TokenService } from '../../shared/services/token.service';
import { clearProductsList } from '../../store/products';
import { HTTP, rootApi, SuccessResponse } from '../index';
import { CreateSupplierRequest, GetSupplierListParams, SupplierListResponse } from './types';

export const supplierApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getSupplierList: builder.query<SupplierListResponse, GetSupplierListParams>({
      query: ({ keyword, ...args }) => {
        const params = { ...args } as GetSupplierListParams;
        if (keyword) {
          params.keyword = keyword;
        }
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/suppliers`,
          method: HTTP.GET,
          params,
        };
      },
      keepUnusedDataFor: 30,
      providesTags: ['Supplier'],
    }),

    addSupplier: builder.mutation<SuccessResponse, CreateSupplierRequest>({
      query: (body) => {
        const userId = TokenService.getUserId();
        return {
          url: `/users/${userId}/suppliers`,
          method: HTTP.POST,
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(clearProductsList());
        } catch (e) {
          console.log(e);
        }
      },
      invalidatesTags: ['Connection', 'Supplier'],
    }),
  }),
  overrideExisting: true,
});

export const { useGetSupplierListQuery, useLazyGetSupplierListQuery, useAddSupplierMutation } = supplierApi;
