import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { formatCurrency } from './format-currency';
import { ExportStockTakeRes } from '../../api/stocktake/types';
import { format } from 'date-fns';
import { roundNumbers } from './roundNumbers';

export const generateStockTakeSheet = async (data: ExportStockTakeRes) => {
  const workbook = new ExcelJS.Workbook();
  workbook.created = new Date();
  workbook.modified = new Date();
  const noChangeRow = 5 + data?.data_with_variances.length;
  const sheet = workbook.addWorksheet('Products', {
    views: [{ state: 'frozen', xSplit: 0, ySplit: 3 }],
    properties: { defaultColWidth: 20 },
  });
  sheet.getRow(1).height = 36;
  sheet.mergeCells('A1:L1');
  sheet.getRow(1).border = {
    top: { style: 'thin', color: { argb: '969696' } },
    left: { style: 'thin', color: { argb: '969696' } },
    bottom: { style: 'thin', color: { argb: '969696' } },
    right: { style: 'thin', color: { argb: '969696' } },
  };
  sheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };

  sheet.getCell('A1').value = 'STOCKTAKE REPORT';
  sheet.getCell('A1').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'efefef' },
  };
  sheet.getRow(1).font = {
    name: 'Calibri',
    size: 18,
    bold: true,
    color: {
      argb: '000000',
    },
  };

  sheet.getRow(2).height = 28;
  sheet.getRow(2).border = {
    top: { style: 'thin', color: { argb: '969696' } },
    left: { style: 'thin', color: { argb: '969696' } },
    bottom: { style: 'thin', color: { argb: '969696' } },
    right: { style: 'thin', color: { argb: '969696' } },
  };
  sheet.getRow(2).alignment = { vertical: 'middle', horizontal: 'center' };
  sheet.getCell('A2').value = 'CREATED';
  sheet.getCell('A2').font = {
    name: 'Calibri',
    size: 12,
    color: {
      argb: '808080',
    },
  };
  sheet.getCell('B2').font = {
    name: 'Calibri',
    size: 12,
    bold: true,
    color: {
      argb: '000000',
    },
  };
  sheet.getCell('B2').value = data?.created
    ? `${format(new Date(data?.created), 'd MMMM yyyy')} | ${format(new Date(data?.created), 'HH:mm')}`
    : '';

  sheet.getCell('J2').value = 'EMPLOYEE';
  sheet.getCell('J2').font = {
    name: 'Calibri',
    size: 12,
    color: {
      argb: '808080',
    },
  };
  sheet.mergeCells('K2:L2');
  sheet.getCell('K2').value = data?.employee || '';
  sheet.getCell('K2').font = {
    name: 'Calibri',
    size: 12,
    bold: true,
    color: {
      argb: '000000',
    },
  };

  sheet.getRow(3).height = 28;
  sheet.getRow(3).alignment = { vertical: 'middle', horizontal: 'center' };
  sheet.getRow(3).font = {
    name: 'Calibri',
    size: 11,
    bold: true,
    color: {
      argb: '000000',
    },
  };
  sheet.getRow(3).border = {
    top: { style: 'thin', color: { argb: '000000' } },
    left: { style: 'thin', color: { argb: '000000' } },
    bottom: { style: 'thin', color: { argb: '000000' } },
    right: { style: 'thin', color: { argb: '000000' } },
  };

  data?.headers.forEach((header, idx) => {
    sheet.getCell(2, idx + 1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'efefef' },
    };
    sheet.getCell(3, idx + 1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'efefef' },
    };
    sheet.getCell(4, idx + 1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'efefef' },
    };
    sheet.getCell(noChangeRow, idx + 1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'efefef' },
    };
    sheet.getCell(3, idx + 1).value = header.label;
    sheet.getCell(3, idx + 1);
  });

  sheet.getColumn('A').width = 15;
  sheet.getColumn('B').width = 40;
  sheet.getColumn('D').width = 13;
  sheet.getColumn('E').width = 12;
  sheet.getColumn('F').width = 25;
  sheet.getColumn('H').width = 12;
  sheet.getColumn('I').width = 12;
  sheet.getColumn('J').width = 13;
  sheet.getColumn('K').width = 13;
  sheet.getColumn('L').width = 12;

  sheet.getRow(4).height = 20;
  sheet.getRow(4).border = {
    left: { style: 'thin', color: { argb: '969696' } },
    bottom: { style: 'thin', color: { argb: '969696' } },
    right: { style: 'thin', color: { argb: '969696' } },
  };
  sheet.getCell('A4').font = {
    name: 'Arial',
    size: 9,
    color: {
      argb: '0000ff',
    },
  };
  sheet.getCell('A4').value = 'WITH VARIANCES';
  sheet.getCell('A4').alignment = { vertical: 'middle', horizontal: 'left' };
  sheet.getCell('L4').value = data?.data_with_variances.length;
  sheet.getCell('L4').alignment = { vertical: 'middle', horizontal: 'center' };
  sheet.getCell('L4').font = {
    name: 'Arial',
    size: 9,
    color: {
      argb: '0000ff',
    },
  };

  const generateCells = (rows: any[], startFrom: number) => {
    rows.forEach((row, rowIdx) => {
      data?.headers.forEach((header, colIdx) => {
        sheet.getCell(rowIdx + startFrom, colIdx + 1).value =
          header.key === 'Price'
            ? formatCurrency(row[header.key])
            : colIdx === 11
            ? `${row[header.key]}%`
            : colIdx === 10 || colIdx === 9
            ? roundNumbers(row[header.key])
            : row[header.key];
        switch (colIdx) {
          case 0: {
            sheet.getCell(rowIdx + startFrom, colIdx + 1).font = {
              color: {
                argb: '808080',
              },
            };
            break;
          }
          case 1: {
            sheet.getCell(rowIdx + startFrom, colIdx + 1).alignment = { wrapText: true };
            break;
          }
          case 2:
          case 3:
          case 4:
          case 5:
          case 6:
          case 7:
          case 8: {
            sheet.getCell(rowIdx + startFrom, colIdx + 1).alignment = { vertical: 'middle', horizontal: 'center' };
            sheet.getCell(rowIdx + startFrom, colIdx + 1).font = {
              color: {
                argb: '434343',
              },
            };
            if (colIdx === 4) {
              sheet.getCell(rowIdx + startFrom, colIdx + 1).font = {
                color: {
                  argb: '000000',
                },
              };
            }
            break;
          }
          case 9:
          case 10: {
            sheet.getCell(rowIdx + startFrom, colIdx + 1).alignment = { vertical: 'middle', horizontal: 'center' };
            sheet.getCell(rowIdx + startFrom, colIdx + 1).font = {
              bold: true,
            };
            break;
          }
          case 11: {
            sheet.getCell(rowIdx + startFrom, colIdx + 1).font = {
              color: {
                argb: row[header.key] > 0 ? '38761d' : row[header.key] < 0 ? 'cc0000' : 'b7b7b7',
              },
            };
            sheet.getCell(rowIdx + startFrom, colIdx + 1).alignment = { vertical: 'middle', horizontal: 'center' };
            break;
          }

          default: {
          }
        }
      });
    });
  };

  generateCells(data?.data_with_variances || [], 5);

  sheet.getRow(noChangeRow).height = 20;
  sheet.getRow(noChangeRow).border = {
    top: { style: 'thin', color: { argb: '969696' } },
    left: { style: 'thin', color: { argb: '969696' } },
    bottom: { style: 'thin', color: { argb: '969696' } },
    right: { style: 'thin', color: { argb: '969696' } },
  };
  sheet.getCell(`A${noChangeRow}`).font = {
    name: 'Arial',
    size: 9,
    color: {
      argb: '0000ff',
    },
  };
  sheet.getCell(`A${noChangeRow}`).value = 'WITH NO CHANGE';
  sheet.getCell(`A${noChangeRow}`).alignment = { vertical: 'middle', horizontal: 'left' };
  sheet.getCell(`L${noChangeRow}`).value = data?.data_without_variances.length;
  sheet.getCell(`L${noChangeRow}`).alignment = { vertical: 'middle', horizontal: 'center' };
  sheet.getCell(`L${noChangeRow}`).font = {
    name: 'Arial',
    size: 9,
    color: {
      argb: '0000ff',
    },
  };

  generateCells(data?.data_without_variances || [], 6 + data?.data_with_variances.length);

  const bufferRes = await workbook.xlsx.writeBuffer();
  const blob = new Blob([bufferRes], { type: 'application/xlsx' });
  await saveAs(blob, `StockTake_${data.created}.xlsx`);
  return Promise.resolve();
};
