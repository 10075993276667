import * as React from 'react';

function SvgCar(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='30' height='18' viewBox='0 0 30 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#car_svg__clip0_4479_68003)' fill='currentColor'>
        <path d='M9.558 0h7.95c.416 0 .65.233.65.647V14.6c0 .406-.233.64-.635.64h-7.057a.62.62 0 01-.625-.551 2.887 2.887 0 00-.892-1.771c-.407-.39-.883-.623-1.415-.757a2.912 2.912 0 00-1.132-.083c-.41.06-.8.193-1.177.385-.794.404-1.217 1.077-1.456 1.897-.05.167-.055.348-.105.515a.5.5 0 01-.492.362c-.565.006-1.13.005-1.694.001-.225-.002-.49-.269-.507-.5-.004-.052-.002-.104-.002-.157V.66c0-.428.23-.66.654-.66h7.935zM19.123 9.159V3.718c0-.455.25-.706.706-.706 1.318 0 2.635.002 3.952-.002.31-.001.531.126.707.38.153.22.334.423.51.629.213.249.439.487.65.738.19.227.364.468.55.697.178.218.364.429.544.644.254.303.505.608.757.913.14.168.279.337.42.503.308.366.615.734.929 1.096.143.165.22.343.22.566-.004 1.814-.002 3.628-.002 5.442 0 .384-.239.623-.622.623-.251 0-.502.003-.753-.001-.215-.004-.48-.268-.51-.48a3.53 3.53 0 00-.31-1.039 2.716 2.716 0 00-1.147-1.222c-.27-.154-.582-.24-.88-.346-.342-.121-.706-.118-1.053-.073-.483.063-.955.183-1.382.452a2.982 2.982 0 00-.98.988 2.941 2.941 0 00-.417 1.212c-.026.24-.294.506-.535.508-.267.003-.533.002-.8 0a.581.581 0 01-.554-.562v-5.52zm4.148-1.097l2.367-.001c.354 0 .575-.327.435-.649-.039-.088-.108-.165-.173-.238-.248-.28-.506-.553-.749-.838-.236-.276-.458-.565-.688-.847-.203-.25-.41-.497-.614-.745-.125-.15-.225-.33-.462-.33-.857.002-1.714.003-2.571 0a.322.322 0 00-.319.207.79.79 0 00-.055.304c-.004.872-.003 1.745-.002 2.617 0 .33.191.519.527.519h2.305z' />
        <path d='M9.198 15.394c.055.39-.025.752-.185 1.1-.223.483-.556.857-1.05 1.077-.118.053-.227.127-.348.167-.584.193-1.175.163-1.75-.016-.23-.071-.444-.229-.637-.382-.281-.225-.533-.494-.688-.821a2.318 2.318 0 01-.214-.747c-.081-.831.11-1.573.745-2.174.355-.336.766-.568 1.231-.628.442-.058.91-.1 1.342.096.456.207.9.44 1.174.886.27.442.465.91.38 1.442zm-1.871.01c0-.355-.198-.57-.526-.57-.31 0-.603.276-.603.568 0 .291.283.558.593.559.349 0 .536-.194.536-.557zM26.536 15.379c.047.688-.2 1.257-.692 1.768-.566.587-1.236.73-2.013.71-.954-.024-2.343-1.059-2.204-2.7.084-.992 1.074-2.353 2.72-2.227.998.076 2.354 1.205 2.189 2.449zm-1.882.029c0-.361-.209-.575-.563-.574a.546.546 0 00-.565.571c.001.345.231.556.608.556.344 0 .52-.187.52-.553z' />
      </g>
      <defs>
        <clipPath id='car_svg__clip0_4479_68003'>
          <path fill='#fff' transform='translate(.97)' d='M0 0h28.097v17.87H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgCar;
