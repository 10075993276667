import { recurringOrdersListSortByOptions } from '../../components/AppDrawer/RestaurantDrawer/PantryListsPage/RecurringOrder';
import { RootState } from '../index';
import { recOrderAdapter } from './recurring-order.reducer';

export const getSelectedRecurringOrderId = (state: RootState) => state.recurringOrders.selectedOrderId;

export const getIsEditRecOrderModalShown = (state: RootState) => state.recurringOrders.isEditModalShown;

export const getRecOrderExitWarningHandler = (state: RootState) => state.recurringOrders.exitWarning.handler;

export const recOrderSelectors = recOrderAdapter.getSelectors((state: RootState) => state.recurringOrders.list);

export const getRecurringOrderSortFilters = (state: RootState) => ({
  currentPage: state.recurringOrders.currentPage,
  keyword: state.recurringOrders.keyword,
  sort: state.recurringOrders.sortBy,
  filter: state.recurringOrders.filterBy,
});

export const getRecurringOrderChips = (state: RootState) => {
  const sortLabel = recurringOrdersListSortByOptions.find((param) => param.value === state.recurringOrders.sortBy?.join(' '))?.label || '';

  const sortChip = {
    label: sortLabel,
    key: sortLabel ? 'sort' : '',
  };

  const categoryChip = {
    label: state.recurringOrders.filterBy as string,
    key: state.recurringOrders.filterBy ? 'category' : '',
  };

  return [sortChip, categoryChip].filter((chip) => !!chip.label && !!chip.key);
};
