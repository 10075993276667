import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';

import { IconButton, Input, InputAdornment } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseButton from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import { useDebouncedCallback } from 'use-debounce';

import useFocus from '../../hooks/use-focus';

import { useStyles } from './style';

interface Props {
  placeholder: string;
  onChange: (value: string) => void;
  onClose?: () => void;
  isOpen?: boolean;
  searchValue?: string;
  clearIcon?: boolean;
}

export const SearchField: React.FC<Props> = ({ placeholder, onChange, onClose, isOpen, searchValue, clearIcon }) => {
  const classes = useStyles();
  const [inputRef, setFocus, blurInput] = useFocus();
  const [value, setValue] = useState<string>('');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    debouncedHandleOnChange(e.target.value.trim());
  };

  const keyPressHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onChange(value.trim());
      blurInput();
    }
  };

  const hitSearch = () => {
    onChange(value.trim());
  };

  const debouncedHandleOnChange = useDebouncedCallback((fieldValue: string) => {
    onChange(fieldValue);
  }, 500);

  const clearText = () => {
    onChange('');
    setValue('');
    setFocus();
  };

  useEffect(() => {
    searchValue && setValue(searchValue);
  }, [searchValue]);

  useEffect(() => {
    if (isOpen) {
      setFocus();
    }
  }, [isOpen]);

  return (
    <Input
      ref={inputRef}
      classes={{
        input: classes.searchInput,
        root: clsx(onClose && classes.root, !isOpen && onClose && classes.rootClose, !onClose && classes.searchWrap),
      }}
      placeholder={placeholder}
      value={value}
      disableUnderline={!!onClose}
      onKeyPress={keyPressHandler}
      onChange={handleChange}
      startAdornment={
        onClose ? (
          <InputAdornment position='start' className={classes.iconButton} aria-label='search' onClick={hitSearch}>
            <ArrowBackIcon fontSize='small' onClick={onClose} />
          </InputAdornment>
        ) : (
          <InputAdornment position='start'>
            <SearchIcon className={classes.searchIcon} />
          </InputAdornment>
        )
      }
      endAdornment={
        (onClose || (clearIcon && value)) && (
          <InputAdornment position='end'>
            <IconButton className={classes.iconButton} aria-label='close' onClick={clearText}>
              <CloseButton />
            </IconButton>
          </InputAdornment>
        )
      }
    />
  );
};
