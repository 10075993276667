import React, { useEffect, useState } from 'react';

import { Collapse } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import clsx from 'clsx';
import { format } from 'date-fns';
import { FastField, FieldProps, Form, Formik, FormikHelpers } from 'formik';

import { ProductInCart } from '../../../../../../api/cart/types';
import { PriceExceptionSortBy } from '../../../../../../api/price-exception/types';
import { ThemedButton } from '../../../../../../shared/components/themed-button';
import { DateRangeComponent } from '../../../../../../shared/components/date-range';
import { FormikDropdown } from '../../../../../../shared/components/formik-dropdown';
import { FormikInput } from '../../../../../../shared/components/formik-input';
import { ProductNameAutoComplete } from '../../../../../../shared/components/product-name-auto-complete';
import { getCurrency } from '../../../../../../shared/helpers/getCurrency';
import { useScreenSize } from '../../../../../../shared/hooks/use-screen-size';
import { useAppDispatch, useAppSelector } from '../../../../../../store';
import { priceExceptionFilters, setPriceExceptionsFilterSort } from '../../../../../../store/price-exception';

import { useStyles } from './style';

export const priceExceptionSortParams = [
  { label: 'Customer name (A - Z)', value: 'user_company_name asc' },
  { label: 'Customer name (Z - A)', value: 'user_company_name desc' },
  { label: 'Last updated (Most recent to Old)', value: 'updated_at desc' },
  { label: 'Last updated (Old to Most recent )', value: 'updated_at asc' },
];

interface Values {
  sortBy: string;
  minCost?: number;
  maxCost?: number;
}

interface Props {
  handleCloseModal: () => void;
  isFullScreen?: boolean;
}

export const PriceExceptionFilter: React.FC<Props> = ({ handleCloseModal, isFullScreen }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [expanded, setExpanded] = useState(true);
  const { isMobile, isDesktop } = useScreenSize();
  const [lastUpdated, setLastUpdated] = useState<{ startDate?: Date; endDate?: Date }>({
    startDate: undefined,
    endDate: undefined,
  });
  const [productName, setProductName] = useState<string>('');

  const { filter, sort } = useAppSelector(priceExceptionFilters);

  const onSubmit = (values: Values, formikHelpers: FormikHelpers<any>) => {
    dispatch(
      setPriceExceptionsFilterSort({
        filter: {
          from_date: lastUpdated.startDate ? format(lastUpdated.startDate, 'yyyy-MM-dd') : undefined,
          to_date: lastUpdated.endDate ? format(lastUpdated.endDate, 'yyyy-MM-dd') : undefined,
          min_total_price: values.minCost,
          max_total_price: values.maxCost,
          product_name: productName,
        },
        sort: values.sortBy.split(' ') as PriceExceptionSortBy,
      }),
    );
    handleCloseModal();
    formikHelpers.resetForm();
  };

  const validateForm = (values: Values) => {
    const errors: any = {};
    if (values.minCost && values.maxCost && +values.minCost > +values.maxCost) {
      errors.maxCost = 'Max value is too low';
    }
    return errors;
  };

  const expandHandler = () => {
    setExpanded(!expanded);
  };

  const handleDateFields = (data: { startDate?: Date; endDate?: Date }) => {
    setLastUpdated(data);
  };

  const resetDate = () => {
    dispatch(setPriceExceptionsFilterSort({ filter: undefined, sort: undefined }));
  };

  const onSetProductName = (product?: ProductInCart) => {
    setProductName(product?.name || '');
  };

  useEffect(() => {
    setLastUpdated({
      startDate: filter?.from_date ? new Date(filter.from_date) : undefined,
      endDate: filter?.to_date ? new Date(filter.to_date) : undefined,
    });
    setProductName(filter?.product_name || '');
  }, [filter?.from_date, filter?.to_date, filter?.product_name]);

  return (
    <Formik
      onSubmit={onSubmit}
      enableReinitialize={true}
      initialValues={{
        sortBy: sort?.join(' ') || priceExceptionSortParams[0].value,
        minCost: filter?.min_total_price,
        maxCost: filter?.max_total_price,
      }}
      validate={validateForm}
    >
      {({ submitForm }) => (
        <Form className={classes.formBox}>
          <div className={classes.root}>
            <div className={classes.sortBlock}>
              <div className={classes.blockTitle}>SORT BY</div>
              <FastField name='sortBy'>
                {(fieldProps: FieldProps) => (
                  <FormikDropdown
                    defaultValue={priceExceptionSortParams[0].value}
                    placeholder='Select sort option'
                    options={priceExceptionSortParams}
                    {...fieldProps}
                    height={36}
                    inputFontSize={14}
                  />
                )}
              </FastField>
            </div>
            <div className={classes.filterBlock}>
              <div className={classes.triggerBox}>
                <div className={classes.blockTitle}>FILTER BY</div>
                {expanded ? (
                  <div className={classes.actions} onClick={expandHandler}>
                    <span className={classes.actionsText}>Hide</span>
                    <KeyboardArrowDownIcon className={classes.actionsIcon} />
                  </div>
                ) : (
                  <div className={classes.actions} onClick={expandHandler}>
                    <span className={classes.actionsText}>Show</span>
                    <ChevronRightIcon className={classes.actionsIcon} />
                  </div>
                )}
              </div>
              <Collapse in={expanded || isMobile} timeout={500}>
                <div className={clsx(classes.fieldWrap, classes.productName)}>
                  <ProductNameAutoComplete
                    value={productName}
                    label='Product Name'
                    onSetProduct={onSetProductName}
                    placeholder='Product Name'
                    isError={false}
                    resetField={!productName}
                    rootFieldClass={classes.productNameField}
                  />
                </div>
                <div className={classes.lastUpdatedWrap}>
                  <div className={clsx([classes.blockTitle, classes.blockSubTitle])}>LAST UPDATED</div>
                  <DateRangeComponent
                    startDate={lastUpdated.startDate}
                    endDate={lastUpdated.endDate}
                    onDateChange={handleDateFields}
                    trigger={
                      <div className={classes.lastUpdatedBox}>
                        <div className={classes.lastUpdatedItem}>
                          {lastUpdated.startDate ? (
                            format(lastUpdated.startDate, 'dd/MM/yy')
                          ) : (
                            <span className={classes.placeholder}>From date</span>
                          )}
                        </div>
                        <div className={classes.divider}>&#8211;</div>
                        <div className={classes.lastUpdatedItem}>
                          {lastUpdated.endDate ? (
                            format(lastUpdated.endDate, 'dd/MM/yy')
                          ) : (
                            <span className={classes.placeholder}>To date</span>
                          )}
                        </div>
                      </div>
                    }
                  />
                </div>
                <div className={classes.costBlock}>
                  <div className={clsx([classes.blockTitle, classes.blockSubTitle])}>TOTAL PRICE</div>
                  <div className={classes.costFields}>
                    <div className={classes.costFieldWrap}>
                      <FastField name='minCost'>
                        {(fieldProps: FieldProps) => (
                          <FormikInput
                            {...fieldProps}
                            height={isDesktop ? undefined : 36}
                            inputFontSize={isDesktop ? undefined : 14}
                            placeholder={`${getCurrency()} min`}
                            type='number'
                          />
                        )}
                      </FastField>
                    </div>
                    <div className={classes.divider}>&#8211;</div>
                    <div className={classes.costFieldWrap}>
                      <FastField name='maxCost'>
                        {(fieldProps: FieldProps) => (
                          <FormikInput
                            {...fieldProps}
                            height={isDesktop ? undefined : 36}
                            inputFontSize={isDesktop ? undefined : 14}
                            placeholder={`${getCurrency()} max`}
                            type='number'
                          />
                        )}
                      </FastField>
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>
            <div className={clsx([classes.btnBlock, isFullScreen && classes.btnBlockFullScreen])}>
              <ThemedButton onClick={submitForm} title='Apply' buttonStyle='primary' isSmall={isMobile} />
              <ThemedButton
                type='reset'
                onClick={resetDate}
                title='CLEAR FILTERS'
                buttonStyle='icon'
                startIcon={<Close color={'inherit'} />}
                isSmall={isMobile}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
