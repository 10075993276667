export const colorVariables = {
  white: '#ffffff',
  transparent: 'transparent',
  green: '#51D9B0',
  greenDark: '#5FB99E',
  greenLight: '#E4FCF5',
  navy: '#152144',
  blue: '#33A8E2',
  blueGrey: '#5E6F85',
  blueLight: '#CFF1FF',
  darkGrey: '#4E5468',
  steelGrey: '#CFD7E6',
  grey80: '#5B6578',
  grey60: '#98A5B7',
  grey40: '#BFC9D5',
  grey20: '#DFE3E9',
  grey10: '#F8F9FB',
  yellow: '#FFD54F',
  steelGrey15: '#edeeef',
  red: '#D31D04',
  black: '#000000',
  userGrey: '#8796AA',
  userNavy: '#34365A',
  userLightGrey: '#F2F3F7',
  pink: '#EF9595',
};
