import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../../../styles/colorVariables';
import { Sizes } from '../../../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      padding: '30px 50px',
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: '0 0 20px 0',
      },
    },
    headAction: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    filtersBlock: {
      display: 'flex',
      alignItems: 'center',
    },
    importBlock: {
      display: 'flex',
      alignItems: 'center',
    },
    dropdownTrigger: {
      color: colorVariables.navy,
      fontWeight: 400,
      '& svg': {
        color: colorVariables.grey60,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        margin: '8px 0 8px 15px',
      },
    },
    filterIcon: {
      position: 'relative',
      marginRight: 20,
    },
    tableHead: {
      marginTop: 20,
      display: 'flex',
      backgroundColor: 'rgba(242, 243, 247, 0.6)',
      minHeight: 38,
      padding: '11px 8px 11px 0',
      '&>div': {
        fontSize: 12,
        color: colorVariables.grey60,
        textTransform: 'uppercase',
        fontWeight: 700,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    sale: {
      width: '10%',
      paddingLeft: 15,
    },
    recipe: {
      width: '18%',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        paddingRight: 3,
      },
    },
    recipeId: {
      width: '13%',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 'calc(100% - 130px)',
      },
    },
    menu: {
      width: '10%',
    },
    sold: {
      width: '9%',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '30%',
      },
    },
    cost: {
      width: '9%',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '30%',
      },
    },
    price: {
      width: '9%',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '30%',
      },
    },
    tax: {
      width: '9%',
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
    },
    total: {
      width: '9%',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
    actions: {
      width: '4%',
    },
    list: {
      position: 'relative',
      zIndex: 1,
      height: 'calc(100vh - 500px)',
      overflowY: 'scroll',
      borderBottom: `1px solid ${colorVariables.steelGrey}`,
      [theme.breakpoints.down(Sizes.mobile)]: {
        height: 'calc(100vh - 230px)',
        paddingBottom: 25,
        borderBottom: 'none',
      },
    },
    totalInfoRow: {
      display: 'flex',
      paddingTop: 20,
      justifyContent: 'space-between',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    menuTotalBox: {
      display: 'flex',
      flexWrap: 'wrap',
      alignContent: 'flex-start',
      alignSelf: 'flex-start',
      borderRadius: 8,
      backgroundColor: '#F7F8FB',
      maxWidth: 'calc(100% - 480px)',
      minWidth: 200,
      maxHeight: 102,
      overflowY: 'scroll',
    },
    menuTotal: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px 20px',
      width: 196,
    },
    boldText: {
      fontWeight: 700,
    },
    menuName: {
      color: colorVariables.grey60,
      fontSize: 11,
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    addRow: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'rgba(207, 241, 255, 0.5)',
      padding: '15px 0',
    },
    dateTrigger: {
      width: 117,
      height: 26,
      backgroundColor: colorVariables.white,
      border: `1px solid ${colorVariables.steelGrey}`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: 'pointer',
      paddingLeft: 5,
      color: colorVariables.grey60,
    },
    dateWrap: {
      '&>div': {
        width: '100%',
        paddingRight: 10,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 115,
        marginRight: 12,
      },
    },
    addActionBtn: {
      height: 28,
      fontSize: 14,
      padding: 0,
      margin: 0,
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginLeft: 10,
      },
    },
    addBtnCell: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    cellInputWrap: {
      transform: 'translateY(-10px)',
      width: 72,
      '& input': {
        textAlign: 'center',
        fontWeight: 600,
        '&::placeholder': {
          color: `${colorVariables.steelGrey} !important`,
        },
      },
      '& .MuiInputBase-formControl.MuiInput-formControl': {
        borderColor: `${colorVariables.green} !important`,
        padding: 5,
      },
      '& .MuiInputBase-formControl.MuiInput-formControl:hover': {
        [theme.breakpoints.up(Sizes.desktop)]: {
          borderColor: `${colorVariables.blue} !important`,
        },
      },
      '& .MuiInputBase-formControl.MuiInput-formControl.Mui-focused': {
        borderColor: `${colorVariables.blue} !important`,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        transform: 'none',
        width: '100%',
        '& label': {
          color: `${colorVariables.grey60} !important`,
          fontSize: 12,
          fontWeight: 700,
          textAlign: 'center !important',
          width: '100%',
          marginBottom: '5px !important',
        },
      },
    },
    cellInputWrapPrice: {
      width: 90,
    },
    greyCell: {
      color: colorVariables.grey60,
    },
    salesTotal: {},
    salesTotalRow: {
      display: 'flex',
      marginBottom: 15,
    },
    salesTotalLabel: {
      width: 200,
      textAlign: 'right',
      marginRight: 60,
    },
    salesTotalValue: {
      width: 200,
      textAlign: 'right',
      paddingLeft: 20,
    },
    addNewSaleM: {
      backgroundColor: 'rgba(207, 241, 255, 0.5)',
      padding: 15,
    },
    addSaleBtnM: {
      display: 'flex',
      alignItems: 'center',
      userSelect: 'none',
      '& span': {
        fontSize: 14,
        fontWeight: 700,
        marginRight: 10,
      },
    },
    addChevronRotated: {
      transform: 'rotate(90deg)',
      color: colorVariables.blue,
    },
    addNewBox: {
      padding: '10px 0 0 0 ',
    },
    addNewDateWrap: {
      display: 'flex',
      width: '100%',
      position: 'relative',
      marginBottom: 14,
    },
    addNewInputsM: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: 10,
    },
    costLabel: {
      color: `${colorVariables.grey60}`,
      paddingTop: 4,
      fontSize: 12,
      fontWeight: 700,
      textAlign: 'center',
      width: '100%',
      marginBottom: 17,
    },
    costValue: {
      fontSize: 16,
      color: '#CFD7E6',
    },
    addNewActions: {
      display: 'flex',
      width: '100%',
      paddingTop: 10,
      alignItems: 'center',
    },
    menuNameM: {
      width: '30%',
      color: '#CFD7E6',
      marginRight: 'auto',
    },
    fixedHead: {
      position: 'sticky',
      backgroundColor: colorVariables.white,
      top: 0,
      zIndex: 1,
    },
    colHeadText: {
      fontSize: 12,
      fontWeight: 700,
      textTransform: 'uppercase',
      color: colorVariables.grey60,
    },
    sortTrigger: {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        color: colorVariables.grey60,
        fontSize: 16,
      },
    },
    loadingRow: {
      display: 'flex',
      width: '100%',
      padding: '18px 0',
      height: 71,
      borderBottom: `1px solid ${colorVariables.steelGrey}`,
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: 10,
        display: 'block',
        height: 92,
        '&>div': {
          marginBottom: 7,
        },
      },
    },
    cellEmpty: {
      marginTop: -5,
      width: 72,
      height: 40,
      borderRadius: 5,
      border: `1px solid ${colorVariables.steelGrey}`,
    },
    cellEmptyPrice: {
      width: 88,
    },
    centeredSkeleton: {
      marginTop: 10,
    },
    bottomSkeletonRow: {
      display: 'flex',
      justifyContent: 'space-between',
      '&>div': {
        width: '30%',
        flexShrink: 0,
      },
    },
  }),
);
