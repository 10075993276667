import React from 'react';

import clsx from 'clsx';

import { ThemedButton } from '../themed-button';

import { useStyles } from './style';

interface Props {
  onSave: () => void;
  onCancel: () => void;
  saveTitle?: string;
  cancelTitle?: string;
  customClass?: string;
  isSubmitDisabled?: boolean;
}

export const ButtonsBlock: React.FC<Props> = React.memo(
  ({ onCancel, onSave, saveTitle = 'Save', cancelTitle = 'Cancel', customClass = '', isSubmitDisabled }) => {
    const classes = useStyles();

    return (
      <div className={clsx([classes.btnBlock, customClass])}>
        <ThemedButton title={cancelTitle} onClick={onCancel} buttonStyle='secondary' />
        <ThemedButton title={saveTitle} onClick={onSave} buttonStyle='primary' className={classes.saveBtn} disabled={isSubmitDisabled} />
      </div>
    );
  },
);
