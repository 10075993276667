import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';
import { Sizes } from '../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    accountContainer: {
      paddingTop: 63,
      backgroundColor: colorVariables.white,
      [theme.breakpoints.down(Sizes.desktop)]: {
        padding: '70px 0 0 94px',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: '110px 0 0 0',
      },
    },
    accountContainerCustomer: {
      [theme.breakpoints.between(Sizes.mobile, Sizes.desktop)]: {
        padding: '50px 0 0 0',
      },
    },
    accountHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      backgroundColor: colorVariables.white,
      height: 127,
      borderBottom: `1px solid ${colorVariables.steelGrey}`,
      paddingBottom: 30,
      paddingLeft: 55,
      paddingRight: 55,
      [theme.breakpoints.down(Sizes.desktop)]: {
        display: 'none',
      },
    },
    accountTitle: {
      fontSize: 32,
      lineHeight: '50px',
      fontWeight: 700,
    },
    accountBody: {
      display: 'flex',
    },
    accountContent: {
      width: '80%',
      padding: '25px 100px 0 50px',
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: '100%',
        padding: '40px 50px',
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        padding: 40,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: '24px 15px',
        paddingTop: 0,
      },
    },
    head: {
      backgroundColor: colorVariables.white,
      borderBottom: `1px solid ${colorVariables.userLightGrey}`,
      [theme.breakpoints.down(Sizes.mobile)]: {
        borderColor: 'rgba(152, 165, 183, 0.3)',
        boxShadow: `0 3px 0 0 ${colorVariables.white}`,
      },
    },
    tabsBox: {
      display: 'flex',
      alignItems: 'flex-end',
      [theme.breakpoints.down(Sizes.mobile)]: {
        justifyContent: 'space-around',
      },
    },
    backBox: {
      borderBottom: `1px solid ${colorVariables.userLightGrey}`,
      padding: '12px 55px',
      [theme.breakpoints.down(Sizes.desktop)]: {
        padding: '12px 48px',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    title: {
      fontSize: 24,
      lineHeight: '36px',
      fontWeight: 700,
      marginBottom: 30,
      [theme.breakpoints.down(Sizes.desktop)]: {
        fontSize: 20,
        lineHeight: '28px',
        fontWeight: 700,
        marginBottom: 10,
      },
    },
    headerLink: {
      display: 'block',
      marginRight: 10,
      fontSize: 14,
      lineHeight: '22px',
      color: colorVariables.userNavy,
      width: 165,
      textAlign: 'center',
      paddingBottom: 12,
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: 150,
        paddingBottom: 10,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 'fit-content',
        marginRight: 0,
        display: 'block',
        fontSize: 12,
        lineHeight: '30px',
        fontWeight: 600,
        padding: '0 6px',
      },
    },
    headerLinkActive: {
      borderBottom: `2px solid ${colorVariables.green}`,
      fontWeight: 700,
    },
    content: {
      padding: '25px 0 0 0',
      [theme.breakpoints.down(Sizes.desktop)]: {
        padding: '20px 0 0 0',
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        padding: '20px 0 0 0',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: '20px 0 0 0',
      },
    },
    titleBox: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down(Sizes.mobile)]: {
        flexDirection: 'column',
      },
    },
    headBottomBox: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }),
);
