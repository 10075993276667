import { RangeOptions } from '../../shared/components/range-dropdown';
import { PaginationResult, SuccessResponse } from '../index';
import { SortDir } from '../product/types';

export interface Wastage {
  id: number;
  wastage_date: string;
  number: string;
  quantity: number;
  reason: string;
  wastage_type: 'recipe' | 'product';
  waste_item: ProductWastageItem | RecipeWastageItem;
  reporter_name: null | string;
  employee: {
    id: number;
    name: string;
  } | null;
}

export type ProductWastageItem = {
  code: string;
  id: number;
  name: string;
  price_per_unit: string;
  unit: string;
  supplier_name: string;
};

export type RecipeWastageItem = {
  id: number;
  name: string;
  code: string;
  menu_category: string;
};

export interface GetWastagesResponse extends PaginationResult {
  wastages: Wastage[];
}

export interface GetWastagesReqParams {
  page: number;
  sortBy?: WastageSortBy;
  filter?: WastageFilters;
  keyword?: string;
}

export type WastageSortFields = 'quantity' | 'name' | 'wastage_date' | 'recipe_id' | 'reason' | 'reporter_name' | 'wastage_type';

export type WastageSortBy = [WastageSortFields, SortDir];

export interface WastageFilters {
  date?: string;
  days?: number;
  fromDate?: string;
  toDate?: string;
  selectedRange?: RangeOptions;
  menu?: string;
}

export interface WastageGraphReqParams {
  days?: number;
  '[date_range][from_date]'?: string;
  '[date_range][to_date]'?: string;
  'q[item_name]'?: string;
  'q[menu_name]'?: string;
}

export enum WastageType {
  recipe = 0,
  ingredient = 3,
  product = 1,
  sub_recipe = 2,
}

export enum WastageReason {
  dropped = 0,
  expired = 1,
  personal_use = 2,
  testing = 3,
  on_the_house = 3,
}

export interface WastageReportResponse extends SuccessResponse {
  wastage_graph: { [key: string]: number };
  previous_wastage_graph: { [key: string]: number };
  variance_graph: {
    storage_data: { [key: string]: number };
    variance_percent_value: number;
    variance_total_value: number;
  };

  wastage_by_options?: [
    {
      wastage_by_reason?: {
        [key: string]: {
          proportion: number;
          quantity: number;
          cost: string;
        };
      };
    },
    {
      wastage_by_items?: {
        [key: string]: {
          proportion: number;
          quantity: number;
          cost: string;
        };
      };
    },
    {
      wastage_by_menu?: {
        [key: string]: {
          proportion: number;
          quantity: number;
          cost: string;
        };
      };
    },
    {
      wastage_by_employee?: {
        [key: string]: {
          proportion: number;
          quantity: number;
          cost: string;
        };
      };
    },
    {
      stocktake_variances: {
        [key: string]: {
          closing_stock: number;
          opening_stock: number;
          variance: string;
        };
      };
    },
  ];
}

export interface CreateWastageReq {
  wastage: {
    waste_id: number;
    wastage_type: WastageType;
    reason: WastageReason;
    quantity: number;
    employee_id?: number;
    wastage_date: string;
    reporter_name?: string;
  };
}

export interface UpdateWastageReq {
  id: number;
  wastage: {
    waste_id?: number;
    reason?: WastageReason;
    quantity?: number;
    employee_id?: number;
    wastage_date?: string;
    reporter_name?: string;
  };
}

export interface ExportWastagesResponse {
  data: {
    'Wastage Date': string;
    Type: string;
    Reporter: string;
    'Recipe ID / Product Code': string | null;
    'Recipe Name / Product Name': null | string;
    'Product Unit': null | string;
    'Product Supplier': null | string;
    'Recipe Menu': string;
    Quantity: string;
    'Cost Price': string;
    Total: string;
  }[];
  total: string;
}

export interface ExportWastagesParams {
  'q[days]'?: number;
  'q[from_date]'?: string;
  'q[to_date]'?: string;
  'q[wastage_date]'?: string;
}

export interface WastageQueryParams {
  'q[sort_by]'?: string;
  'q[from_date]'?: string;
  'q[to_date]'?: string;
  'q[days]'?: number;
  'q[wastage_date]'?: string;
  'q[menu_name]'?: string;
  'q[keyword]'?: string;
}

export interface CreateWastageRes extends SuccessResponse {
  wastage: Wastage;
}
