import { createAction } from '@reduxjs/toolkit';

import { RangeOptions } from '../../shared/components/range-dropdown';
import { DashboardMode } from './dashboard.reducer';

export const setSelectedDate = createAction<string>('SET_SELECTED_DATE');

export const setDashboardMode = createAction<DashboardMode>('SET_DASHBOARD_MODE');

export const setDashboardSalesRange =
  createAction<{ range: RangeOptions; days?: number; startDate?: string; endDate?: string }>('SET_DASHBOARD_SALES_RANGE');
