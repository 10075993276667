import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../../../styles/colorVariables';
import { Sizes } from '../../../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    formBox: {
      height: 'calc(100% - 88px)',
      [theme.breakpoints.up(Sizes.mobile)]: {
        width: 475,
      },
      [theme.breakpoints.between(Sizes.mobile, Sizes.desktop)]: {
        maxHeight: 'calc(100vh - 140px)',
        width: 370,
        overflowY: 'scroll',
      },
    },
    root: {
      position: 'relative',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down(Sizes.mobile)]: {
        paddingTop: 30,
      },
    },
    sortBlock: {
      width: '90%',
      margin: '20px auto 40px auto',
      [theme.breakpoints.up(Sizes.desktop)]: {
        display: 'none',
      },
    },
    blockTitle: {
      fontSize: 12,
      fontWeight: 700,
      color: colorVariables.navy,
      marginBottom: 15,
      textTransform: 'uppercase',
      [theme.breakpoints.up(Sizes.desktop)]: {
        marginBottom: 25,
      },
    },
    blockSubTitle: {
      color: colorVariables.grey60,
      fontWeight: 700,
      fontSize: 12,
      textTransform: 'uppercase',
      marginBottom: 10,
    },
    statusBlock: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: 20,
      '& label': {
        width: '45%',
        marginRight: 0,
        flexShrink: 0,
        marginLeft: 0,
      },
    },
    filterBlock: {
      width: '90%',
      margin: '0 auto',
    },
    btnBlock: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 20,
      padding: 20,
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: '40px 20px',
        justifyContent: 'center',
      },
      [theme.breakpoints.down(400)]: {
        padding: '30px 10px',
        '& button': {
          minWidth: '40%',
        },
      },
    },
    btnBlockFullScreen: {
      marginTop: 'auto',
    },
    driversBox: {
      marginBottom: 35,
    },
    dateTrigger: {
      width: 133,
      height: 36,
      borderRadius: 4,
      border: `1px solid ${colorVariables.steelGrey}`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginRight: 'auto',
      padding: 10,
    },
  }),
);
