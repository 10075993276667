import { createEntityAdapter, createReducer, EntityState, isAnyOf } from '@reduxjs/toolkit';

import isEqual from 'lodash/isEqual';

import { productsApi } from '../../api/product';
import { Product, ProductFilterValues, ProductsSortBy } from '../../api/product/types';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { logoutThunk } from '../user/user.actions';
import {
  clearRecipeProductsList,
  setRecipeProductsFilterBy,
  setRecipeProductsKeyword,
  setRecipeProductsPage,
  setRecipeProductsSortBy,
} from './products.actions';

export const recipeProductsAdapter = createEntityAdapter<Product>();

interface State {
  list: EntityState<Product>;
  sortBy?: ProductsSortBy;
  filterBy?: ProductFilterValues;
  keyword?: string;
  currentPage: number;
  resultsCount: number;
}

const initialState: State = {
  sortBy: undefined,
  filterBy: undefined,
  keyword: undefined,
  list: recipeProductsAdapter.getInitialState(),
  currentPage: 1,
  resultsCount: 0,
};

export const recipeProductsReducer = createReducer(initialState, (builder) => {
  builder.addCase(logoutThunk.fulfilled, () => {
    return initialState;
  });
  builder.addCase(setRecipeProductsSortBy, (state, { payload }) => {
    if (isEqual(state.sortBy, payload)) {
      return;
    }
    state.sortBy = payload;
    state.currentPage = 1;
    state.list = recipeProductsAdapter.getInitialState();
  });
  builder.addCase(setRecipeProductsFilterBy, (state, { payload }) => {
    if (isEqual(state.filterBy, payload)) {
      return;
    }
    if (state.filterBy?.supplier_id !== payload?.supplier_id && state.sortBy && state.sortBy.length === 1) {
      state.sortBy = LocalStorageService.getItem('last_product_sort') || undefined;
    }
    state.filterBy = payload;
    state.currentPage = 1;
    state.list = recipeProductsAdapter.getInitialState();
  });
  builder.addCase(setRecipeProductsKeyword, (state, { payload }) => {
    if (state.keyword === payload.keyword) {
      return;
    }
    state.sortBy = payload.keyword ? ['relevance'] : LocalStorageService.getItem('last_product_sort') || undefined;
    state.keyword = payload.keyword;
    state.currentPage = 1;
    state.list = recipeProductsAdapter.getInitialState();
  });
  builder.addCase(setRecipeProductsPage, (state, { payload }) => {
    state.currentPage = payload;
  });
  builder.addCase(clearRecipeProductsList, (state) => {
    state.list = recipeProductsAdapter.getInitialState();
    state.keyword = undefined;
    state.currentPage = 1;
    state.resultsCount = 0;
  });
  builder.addMatcher(productsApi.endpoints.getProductsByCategory.matchFulfilled, (state, action) => {
    if (
      isEqual(action.meta.arg.originalArgs!.filter, state.filterBy) &&
      isEqual(action.meta.arg.originalArgs!.sort, state.sortBy) &&
      action.meta.arg.originalArgs!.keyword === state.keyword &&
      action.meta.arg.originalArgs!.currentPage === state.currentPage
    ) {
      state.list = recipeProductsAdapter.setMany(state.list, action.payload.data.products);
      state.resultsCount = action.payload.products_result;
    }
  });
  builder.addMatcher(
    isAnyOf(productsApi.endpoints.unFavoriteProduct.matchFulfilled, productsApi.endpoints.favoriteProduct.matchFulfilled),
    (state, { payload }) => {
      if (state.filterBy?.favourites) {
        state.list = recipeProductsAdapter.removeOne(state.list, payload.product.id);
        state.resultsCount = state.resultsCount - 1;
      } else {
        state.list = recipeProductsAdapter.upsertOne(state.list, payload.product);
      }
    },
  );
});
