import { createStyles, makeStyles } from '@material-ui/core';
import { Sizes } from '../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    btnBlock: {
      paddingTop: 20,
      display: 'flex',
      [theme.breakpoints.down(Sizes.mobile)]: {
        justifyContent: 'center',
      },
      '& button': {
        minWidth: 120,
        [theme.breakpoints.down(Sizes.mobile)]: {
          minWidth: 110,
        },
      },
    },
    saveBtn: {
      marginLeft: 15,
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginLeft: 7,
      },
    },
  }),
);
