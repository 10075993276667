import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';
import { Sizes } from '../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      width: '100%',
      position: 'relative',
      paddingTop: 10,
    },
    paddingBox: {
      paddingTop: 30,
    },
    rootField: {
      width: '100%',
      margin: 0,
      padding: 0,
      '& .MuiFormLabel-root': {
        top: -26,
        marginBottom: 10,
        fontSize: 12,
        lineHeight: '16px',
        color: colorVariables.navy,
        transform: 'scale(1)',
      },
      '& legend': {
        width: 0,
      },
      '& .MuiOutlinedInput-root.MuiAutocomplete-inputRoot': {
        padding: '5px 15px',
        fontSize: 16,
        fontWeight: 400,
        color: colorVariables.navy,
        lineHeight: '24px',
        height: 48,
        backgroundColor: colorVariables.white,
        borderRadius: 5,
        [theme.breakpoints.down(Sizes.mobile)]: {
          padding: '5px 10px',
        },
        '&::before, &::after': {
          display: 'none',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: colorVariables.steelGrey,
          borderWidth: 1,
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: colorVariables.blue,
        },
        '&:focus .MuiOutlinedInput-notchedOutline': {
          borderColor: colorVariables.blue,
        },
        '& input': {
          padding: '3px 0 !important',
        },
      },
      '& .MuiAutocomplete-inputRoot.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd': {
        paddingRight: 40,
      },
    },
    paper: {
      backgroundColor: colorVariables.white,
    },
    hiddenPaper: {
      display: 'none',
    },
    option: {
      backgroundColor: colorVariables.white,
      fontSize: 14,
      height: 40,
      marginTop: 2,
      '&:hover': {
        backgroundColor: colorVariables.blueLight,
      },
      '&:focus': {
        backgroundColor: colorVariables.blueLight,
      },
      '&[data-focus="true"]': {
        backgroundColor: colorVariables.blueLight,
      },
      '&:active': {
        backgroundColor: colorVariables.blueLight,
      },
    },
    noOptions: {
      fontSize: 14,
    },
    clearIndicator: {
      color: colorVariables.steelGrey,
    },
    toolTip: {
      display: 'block',
      minWidth: 125,
      maxWidth: '100%',
      minHeight: 24,
      borderRadius: 4,
      textAlign: 'center',
      fontSize: 11,
      lineHeight: '24px',
      padding: '0 5px',
      color: `${colorVariables.white} !important`,
      backgroundColor: colorVariables.navy,
      position: 'absolute',
      top: -20,
      left: 0,

      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: -15,
        left: 24,
        borderWidth: 8,
        borderStyle: 'solid',
        borderColor: `${colorVariables.navy} transparent transparent transparent`,
      },
    },
  }),
);
