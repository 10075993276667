import { createStyles, makeStyles } from '@material-ui/core';
import { colorVariables } from '../../../../../../../styles/colorVariables';
import { Sizes } from '../../../../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    row: {
      display: 'flex',
      width: '100%',
      padding: '18px 0',
      borderBottom: `1px solid ${colorVariables.steelGrey}`,
      '&:last-child': {
        marginBottom: 20,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: 10,
        display: 'block',
        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
    rowEditMode: {
      backgroundColor: 'rgba(228, 252, 245, 0.5)',
    },
    date: {
      width: '10%',
      paddingLeft: 20,
    },
    recipe: {
      width: '22%',
    },
    recipeId: {
      width: '13%',
      paddingLeft: 5,
    },
    sold: {
      width: '7%',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
    reason: {
      width: '14%',
      textAlign: 'center',
    },
    reporter: {
      width: '14%',
      paddingLeft: 10,
    },
    type: {
      width: '16%',
      textAlign: 'center',
    },
    actions: {
      width: '4%',
      position: 'relative',
    },
    addActionBtn: {
      height: 28,
      fontSize: 14,
      padding: 0,
    },
    recipeIdText: {
      color: colorVariables.grey60,
    },
    soldText: {
      textAlign: 'center',
      fontSize: 16,
      fontWeight: 600,
    },
    moreDots: {
      transform: 'translate(10px, -8px)',
      '& svg': {
        color: colorVariables.grey40,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        transform: 'translate(5px, -2px)',
      },
    },
    saleDateText: {
      color: colorVariables.userNavy,
      marginBottom: 5,
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginRight: 10,
      },
    },
    addBtnCell: {
      paddingTop: 10,
      width: '100%',
      justifyContent: 'center',
      display: 'flex',
    },
    addBtnCellEnd: {
      justifyContent: 'flex-end',
    },
    dateTrigger: {
      width: 117,
      height: 26,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: 'pointer',
      paddingLeft: 5,
      '& svg': {
        color: colorVariables.grey60,
      },
    },
    dateWrap: {
      '&>div': {
        width: '98%',
        maxWidth: 100,
      },
    },
    cellInputWrap: {
      transform: 'translateY(-10px)',
      width: 72,
      '& input': {
        textAlign: 'center',
        fontWeight: 600,
        '&::placeholder': {
          color: `${colorVariables.steelGrey} !important`,
        },
      },
      '& .MuiInputBase-formControl.MuiInput-formControl': {
        borderColor: `${colorVariables.green} !important`,
        padding: 5,
      },
      '& .MuiInputBase-formControl.MuiInput-formControl:hover': {
        [theme.breakpoints.up(Sizes.desktop)]: {
          borderColor: `${colorVariables.blue} !important`,
        },
      },
      '& .MuiInputBase-formControl.MuiInput-formControl.Mui-focused': {
        borderColor: `${colorVariables.blue} !important`,
      },
    },
    cellInputWrapReporter: {
      width: '96%',
      paddingRight: 10,
      transform: 'translateY(1px)',
      '& input': {
        '&::placeholder': {
          color: `${colorVariables.grey60} !important`,
        },
      },
      '& .MuiInputBase-formControl.MuiInput-formControl': {
        borderRadius: 0,
        borderColor: `${colorVariables.steelGrey} !important`,
        padding: 5,
      },
      '& .MuiInputBase-formControl.MuiInput-formControl:hover': {
        [theme.breakpoints.up(Sizes.desktop)]: {
          borderColor: `${colorVariables.blue} !important`,
        },
      },
      '& .MuiInputBase-formControl.MuiInput-formControl.Mui-focused': {
        borderColor: `${colorVariables.blue} !important`,
      },
    },
    typeBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& span': {
        paddingLeft: 10,
      },
    },
    recipeName: {
      fontWeight: 600,
    },
    formControl: {
      marginTop: 5,
      maxWidth: 180,
    },
    reasonText: {},
    greyText: {
      color: colorVariables.grey60,
      paddingTop: 3,
    },
    productInfo: {
      display: 'flex',
      '& span': {
        color: colorVariables.steelGrey,
        padding: '0 3px',
      },
    },
    dateRow: {
      display: 'flex',
    },
    wasteType: {
      color: colorVariables.grey60,
      marginRight: 'auto',
    },
    wasteName: {
      fontWeight: 600,
    },
    wasteInfo: {
      backgroundColor: 'rgba(207, 215, 230, 0.3)',
      borderRadius: 8,
      padding: '7px 9px',
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 8,
    },
    wasteInfoLabel: {
      fontSize: 12,
      fontWeight: 700,
      textTransform: 'uppercase',
      color: colorVariables.grey60,
    },
    qtyTextM: {
      fontWeight: 700,
      textAlign: 'center',
    },
  }),
);
