import React, { useCallback, useState } from 'react';

import { CircularProgress, IconButton } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import clsx from 'clsx';
import { Form, Formik, FormikHelpers } from 'formik';
import { useInView } from 'react-intersection-observer';

import { useGetRecOrders } from '../../../../../api/recurring-order/hooks';
import { RecOrderSortBy } from '../../../../../api/recurring-order/types';
import { useGeIsTeammateViewer } from '../../../../../api/teammates/hooks';
import SvgFilterIconCustomer from '../../../../../assets/icons/FilterIconCustomer';
import SvgRecOrderEmpty from '../../../../../assets/images/svg-components/RecOrderEmpty';
import { AddButtonDashed } from '../../../../../shared/components/add-button-dashed';
import { ThemedButton } from '../../../../../shared/components/themed-button';
import { CommonChip } from '../../../../../shared/components/common-chip';
import { FilterByCategory } from '../../../../../shared/components/filter-by-category';
import { FormikSortDropdown } from '../../../../../shared/components/formik-sort-dropdown';
import { NoSearchResultsRestaurant } from '../../../../../shared/components/no-search-results-restaurant';
import { OpLoading } from '../../../../../shared/components/op-loading';
import { SearchField } from '../../../../../shared/components/search-field';
import { ProductsCategories } from '../../../../../shared/constants/products';
import { useScreenSize } from '../../../../../shared/hooks/use-screen-size';
import { ToastService } from '../../../../../shared/services/toastService';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import {
  getRecurringOrderChips,
  getRecurringOrderSortFilters,
  hideCreateRecOrderModal,
  recOrderSelectors,
  setRecOrderKeyword,
  setRecurringOrderSortFilters,
  showCreateRecOrderModal,
} from '../../../../../store/recurring-order';
import { colorVariables } from '../../../../../styles/colorVariables';
import { RecurringOrderListItem } from './recurring-order-list-item';
import { RecurringOrderRow } from './recurring-order-list-row';
import { CreateRecOrderModal } from './RecurringOrderDetails/create-modal';
import { RenameRecOrderModal } from './RecurringOrderDetails/rename-modal';
import { RecurringOrdersSortFilterModal } from './sort-filter-modal';

import { useStyles } from './style';

interface Values {
  sortBy: string;
}

export const recurringOrdersListSortByOptions = [
  { label: 'Order name (A - Z)', value: 'name asc' },
  { label: 'Order name (Z - A)', value: 'name desc' },
  { label: 'Date created (Most recent to Old)', value: 'created_at desc' },
  { label: 'Recently used (Most recent to Old)', value: 'orders_created_at asc' },
];

export const RecurringOrder: React.FC = () => {
  const classes = useStyles();
  const { isMobile, isTabletPortrait } = useScreenSize();
  const dispatch = useAppDispatch();
  const [inViewRef, inView] = useInView();

  const [isFilterModalOpened, setIsFilteredModalOpened] = useState(false);
  const [isRenameModalOpened, setIsRenameModalOpened] = useState(false);

  const list = useAppSelector(recOrderSelectors.selectAll);
  const { loading } = useGetRecOrders(inView);
  const chips = useAppSelector(getRecurringOrderChips);
  const isViewer = useGeIsTeammateViewer();

  const isCreateModalShown = useAppSelector((state) => state.recurringOrders.isCreateModalShown);
  const { keyword, filter: selectedCategory, sort } = useAppSelector(getRecurringOrderSortFilters);

  const openModal = () => {
    setIsFilteredModalOpened(true);
  };

  const closeModal = () => {
    setIsFilteredModalOpened(false);
  };

  const openRenameModal = useCallback(() => {
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    setIsRenameModalOpened(true);
  }, []);

  const setSelectedCategory = (category: ProductsCategories | null) => {
    dispatch(setRecurringOrderSortFilters({ filter: category, sort }));
  };

  const closeRenameModal = () => {
    setIsRenameModalOpened(false);
  };

  const showCreateModal = useCallback(() => {
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    dispatch(showCreateRecOrderModal());
  }, [isViewer]);

  const hideCreateModal = () => {
    dispatch(hideCreateRecOrderModal());
  };

  const onSubmit = ({ sortBy }: Values, formikHelpers: FormikHelpers<Values>) => {
    dispatch(setRecurringOrderSortFilters({ sort: sortBy.split(' ') as RecOrderSortBy, filter: selectedCategory }));
    formikHelpers.setSubmitting(false);
  };

  const onRemoveChip = (key: string) => {
    if (key === 'sort') {
      dispatch(setRecurringOrderSortFilters({ sort: undefined, filter: selectedCategory }));
    }
    if (key === 'category') {
      dispatch(setRecurringOrderSortFilters({ sort, filter: null }));
    }
  };

  const onSearchRecOrders = (searchStr: string) => {
    dispatch(setRecOrderKeyword(searchStr));
  };

  return (
    <>
      {!keyword && !selectedCategory && list.length === 0 && !loading ? null : (
        <div className={classes.header}>
          <Formik
            onSubmit={onSubmit}
            initialValues={{ sortBy: sort?.join(' ') || recurringOrdersListSortByOptions[2].value }}
            enableReinitialize={true}
          >
            <Form className={classes.form}>
              <div className={classes.searchWrap}>
                <SearchField placeholder='Search supplier' onChange={onSearchRecOrders} searchValue={keyword} />
              </div>
              <IconButton className={classes.filterBtnMobile} onClick={openModal}>
                <SvgFilterIconCustomer width={20} height={20} color={colorVariables.userNavy} />
              </IconButton>
              <div className={classes.filterWrap}>
                <div className={classes.filterTitle}>Filter by Category</div>
                <FilterByCategory selectedCategory={selectedCategory} onApply={setSelectedCategory} />
              </div>
              <div className={classes.sortWrap}>
                <div className={clsx(classes.filterTitle, classes.sortTitle)}>Sort by</div>
                <FormikSortDropdown name='sortBy' options={recurringOrdersListSortByOptions} />
              </div>
              <ThemedButton
                customClass={classes.addBtn}
                title='CREATE RECURRING ORDER'
                onClick={showCreateModal}
                buttonStyle='icon'
                startIcon={<Add className={classes.addIcon} />}
              />
            </Form>
          </Formik>
          <div className={classes.chipBox}>
            {chips
              .filter((el) => el.label !== 'Date created (Most recent to Old)')
              .map((chip) => (
                <CommonChip key={chip.key} title={chip.label} onClick={onRemoveChip.bind(null, chip.key)} />
              ))}
          </div>
        </div>
      )}
      {isMobile || isTabletPortrait ? (
        <>
          <div className={classes.addBtnWrap}>
            <AddButtonDashed title='CREATE RECURRING ORDER' onClick={showCreateModal} />
          </div>
          {(keyword || selectedCategory) && list.length === 0 && !loading ? (
            <NoSearchResultsRestaurant />
          ) : !keyword && !selectedCategory && list.length === 0 && !loading ? (
            <div className={classes.emptyContainer}>
              <h2 className={classes.emptyTitle}>
                Add your Recurring <br /> Orders here
              </h2>
              <ThemedButton onClick={showCreateModal} title='Create Recurring Order' />
              <div className={classes.emptySubTitle}>Set the start date and frequency for these orders.</div>
              <SvgRecOrderEmpty width={350} height={350} />
            </div>
          ) : (
            <OpLoading loading={loading && !list.length} dataLoaded={!!list.length} resetProgress={true} boxClasses={classes.loadingBox}>
              <>
                {list.map((item, idx, arr) => (
                  <div ref={idx === arr.length - 10 ? inViewRef : undefined} key={item.id}>
                    <RecurringOrderListItem item={item} />
                  </div>
                ))}
              </>
            </OpLoading>
          )}
        </>
      ) : (keyword || selectedCategory) && list.length === 0 && !loading ? (
        <NoSearchResultsRestaurant />
      ) : !keyword && !selectedCategory && list.length === 0 && !loading ? (
        <div className={classes.emptyContainer}>
          <h2 className={classes.emptyTitle}>
            Add your Recurring <br /> Orders here
          </h2>
          <ThemedButton onClick={showCreateModal} title='Create Recurring Order' />
          <div className={classes.emptySubTitle}>Set the start date and frequency for these orders.</div>
          <SvgRecOrderEmpty width={350} height={350} />
        </div>
      ) : (
        <OpLoading loading={loading && !list.length} dataLoaded={!!list.length} resetProgress={true} boxClasses={classes.loadingBox}>
          <table className={classes.table}>
            <thead className={classes.head}>
              <tr className={classes.headRow}>
                <th className={clsx(classes.headCell, classes.name)}>Name</th>
                <th className={clsx(classes.headCell, classes.delivery)}>Next delivery</th>
                <th className={clsx(classes.headCell, classes.total)}>Total</th>
                <th className={clsx(classes.headCell, classes.status)}>Status</th>
                <th className={clsx(classes.headCell, classes.frequency)}>Frequency</th>
                <th className={clsx(classes.headCell, classes.suppliers)}>Suppliers</th>
                <th className={clsx(classes.headCell, classes.items)}>Items</th>
                <th className={clsx(classes.headCell, classes.actions)} />
              </tr>
            </thead>
            <tbody>
              {list.map((item, idx, arr) => (
                <div ref={idx === arr.length - 10 ? inViewRef : undefined} key={item.id}>
                  <RecurringOrderRow item={item} onRename={openRenameModal} />
                </div>
              ))}
              {loading && !!list.length && (
                <div className={classes.progressBox}>
                  <CircularProgress size={40} thickness={2} value={100} />
                </div>
              )}
            </tbody>
          </table>
        </OpLoading>
      )}
      <RecurringOrdersSortFilterModal isOpened={isFilterModalOpened} closeModal={closeModal} />
      <RenameRecOrderModal isOpened={isRenameModalOpened} onClose={closeRenameModal} />
      <CreateRecOrderModal isOpened={isCreateModalShown} onClose={hideCreateModal} />
    </>
  );
};
