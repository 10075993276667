import { createStyles, makeStyles } from '@material-ui/core';

import AuthDots from '../../../assets/images/bg/Auth_bg_dots.svg';
import AuthLines from '../../../assets/images/bg/Auth_bg_lines.svg';
import { colorVariables } from '../../../styles/colorVariables';
import { Sizes } from '../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      flex: 1,
      minHeight: '100vh',
      padding: 80,
      paddingTop: 30,
      backgroundColor: 'rgba(207, 215, 230, 0.3)',
      background: 'linear-gradient rgba(255, 255, 255, 1) 100%, rgba(255, 255, 255, 0) 0%)',
      backgroundImage: `url(${AuthLines}), url(${AuthDots})`,
      backgroundRepeat: 'no-repeat, no-repeat',
      backgroundPosition: 'top 126px left 0px, bottom 250px right 0px',
      [theme.breakpoints.down(Sizes.desktop)]: {
        padding: 40,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: 20,
        paddingTop: 30,
        backgroundPosition: 'top 250px left -30%, bottom 260px right 0px',
        overflowY: 'scroll',
      },
    },
    logoWrap: {
      width: '100%',
    },
    logo: {
      width: 178,
      height: 34,
      marginBottom: 30,
      cursor: 'pointer',
      [theme.breakpoints.down(Sizes.desktop)]: {
        marginBottom: 20,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        marginBottom: 50,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginBottom: 30,
      },
    },
    title: {
      fontSize: 40,
      lineHeight: '56px',
      fontWeight: 700,
      marginBottom: 30,
      [theme.breakpoints.down(Sizes.mobile)]: {
        fontSize: 28,
        lineHeight: '36px',
        textAlign: 'center',
      },
    },
    subTitle: {
      maxWidth: 460,
      textAlign: 'center',
      opacity: 0.7,
      lineHeight: '24px',
      marginBottom: 10,
    },
    fieldsBox: {
      maxWidth: 790,
    },
    fieldsLine: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down(Sizes.mobile)]: {
        flexWrap: 'wrap',
      },
    },
    fieldTitle: {
      fontWeight: 700,
      color: colorVariables.grey60,
      marginTop: 20,
      [theme.breakpoints.down(Sizes.tablet)]: {
        marginTop: 30,
      },
    },
    nameWrap: {
      width: 320,
      marginRight: 16,
      [theme.breakpoints.down(Sizes.tablet)]: {
        width: '40%',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        marginRight: 0,
        marginBottom: 5,
      },
    },
    emailWrap: {
      width: 450,
      [theme.breakpoints.down(Sizes.tablet)]: {
        width: '55%',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
      },
    },
    addAnother: {
      display: 'flex',
      width: 170,
      alignItems: 'center',
      marginTop: 30,
      marginBottom: 50,
      cursor: 'pointer',
      '&:hover': {
        opacity: 0.8,
      },
    },
    addText: {
      color: '#33A8E2',
      fontWeight: 700,
    },
    plus: {
      width: 40,
      height: 40,
      fontSize: 32,
      fontWeight: 700,
      color: '#33A8E2',
      backgroundColor: 'rgba(152, 165, 183, 0.15)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
      borderRadius: '50%',
      lineHeight: '40px',
      marginRight: 15,
    },
    submitBtn: {
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        marginBottom: 40,
      },
    },
  }),
);
