import React, { memo, useState } from 'react';

import { Switch } from '@material-ui/core';
import { Form, Formik } from 'formik';

import { useDeleteUnverifiedUsersMutation } from '../../../../../api/admin-users';
import { useMeQuery } from '../../../../../api/user';
import { Roles } from '../../../../../api/user/types';
import { AddButton } from '../../../../../shared/components/add-button';
import { ExportButton } from '../../../../../shared/components/export-button';
import { FormikSortDropdown } from '../../../../../shared/components/formik-sort-dropdown';
import { MoreButton } from '../../../../../shared/components/more-button';
import { SearchField } from '../../../../../shared/components/search-field';
import { CustomTooltip } from '../../../../../shared/components/tooltip';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { getUsersSortFilters, setUsersKeyword, setUsersSortFilters } from '../../../../../store/admin/users';

import { useStyles } from './style';

interface Props {
  openExportModal: () => void;
  openAddModal: () => void;
}

export const usersFilter = [
  { label: 'All types', value: 'all' },
  { label: 'Restaurant', value: Roles.CUSTOMER as string },
  { label: 'Supplier', value: Roles.SUPPLIER as string },
  { label: 'Admin', value: 'admin' },
  { label: 'Verified', value: '1' },
  { label: 'Pending', value: '0' },
];

const Component: React.FC<Props> = ({ openExportModal, openAddModal }) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  const { keyword, filter, sort } = useAppSelector(getUsersSortFilters);
  const { data: user } = useMeQuery();
  const [deleteUnverified, { isLoading }] = useDeleteUnverifiedUsersMutation();

  const handleChange = (value: string) => {
    dispatch(setUsersKeyword(value || undefined));
  };

  const handleToggleOptions = () => {
    setIsOptionsOpen((prev) => !prev);
  };

  const onSubmit = (values: { user_filter: Roles | 'all' | 'admin' | '0' | '1' }) => {
    dispatch(
      setUsersSortFilters({
        sort,
        filter: {
          company_type:
            values.user_filter === 'all' || values.user_filter === '0' || values.user_filter === '1' ? undefined : values.user_filter,
          verified: values.user_filter === '1' ? true : values.user_filter === '0' ? false : undefined,
        },
      }),
    );
  };

  return (
    <div className={classes.root}>
      <h2 className={classes.title}>Users</h2>
      <div className={classes.searchInputContainer}>
        <SearchField placeholder='Search for name or company' onChange={handleChange} searchValue={keyword} />
      </div>
      <div className={classes.actions}>
        <div className={classes.filterBlock}>
          <div className={classes.filterTitle}>Filter</div>
          <Formik onSubmit={onSubmit} enableReinitialize={true} initialValues={{ user_filter: filter?.company_type || 'all' }}>
            <Form>
              <FormikSortDropdown name='user_filter' options={usersFilter} />
            </Form>
          </Formik>
        </div>
        <CustomTooltip
          right={125}
          onClose={setIsOptionsOpen.bind(null, false)}
          triggerBtn={<MoreButton horizontal={true} onClick={handleToggleOptions} customClass={classes.moreOptions} />}
          clickOutsideUnTrackable={true}
          isOpen={isOptionsOpen}
        >
          <div className={classes.optionsBlock}>
            <div className={classes.optionsTitle}>OPTIONS</div>
            <div className={classes.switchBlock}>
              <div className={classes.switchText}>Auto delete unverified users after 2 weeks</div>
              <Switch
                disabled={isLoading}
                checked={!!user?.auto_verify}
                onChange={() => {
                  deleteUnverified();
                }}
                color='primary'
                classes={{
                  thumb: classes.thumb,
                  track: classes.track,
                  checked: classes.checked,
                }}
              />
            </div>
          </div>
        </CustomTooltip>
        <AddButton onClick={openAddModal} />
        <ExportButton onClick={openExportModal} />
      </div>
    </div>
  );
};

export const UsersListHeader = memo(Component);
