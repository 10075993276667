import React from 'react';
import { useHistory } from 'react-router-dom';

import clsx from 'clsx';

import SvgFaqIcon from '../../../../assets/icons/Faq';
import SvgHowTo from '../../../../assets/icons/HowTo';
import SvgMessageIcon from '../../../../assets/icons/MessageIcon';
import SvgPointer from '../../../../assets/icons/Pointer';
import { NavbarTitle } from '../../../../shared/components/navbar-title';
import { SearchField } from '../../../../shared/components/search-field';
import { useScreenSize } from '../../../../shared/hooks/use-screen-size';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { getIsRestaurant, openAccountMenu, openMainMenu, setHelpKeyword } from '../../../../store/user';
import { getHelpInfo, getHelpKeywords } from '../../../../store/user/user.selectors';
import { TopNavBar } from '../../../AppDrawer/MainNavBar/top-nav-bar';
import { HelpInfoSearchResults } from '../help-info-search-results';

import { useStyles } from './style';

export const HelpOptions: React.FC = () => {
  const classes = useStyles();
  const { push } = useHistory();
  const { isMobile } = useScreenSize();
  const dispatch = useAppDispatch();

  const { general } = useAppSelector(getHelpKeywords);
  const isRestaurant = useAppSelector(getIsRestaurant);

  const onKeywordChange = (value: string) => {
    dispatch(setHelpKeyword(value));
  };

  const searchList = useAppSelector(getHelpInfo('all'));

  const openContactUs = () => {
    push(`/account/help/contact`);
  };

  const openFaqs = () => {
    push(`/account/help/faqs`);
  };

  const openHelpCenter = () => {
    push(`/account/help/help-center`);
  };

  const openMenus = () => {
    dispatch(openMainMenu());
    dispatch(openAccountMenu());
  };

  return (
    <>
      <TopNavBar
        isAccount={true}
        leftComponent={<NavbarTitle title='Help' showBackBtn={true} backHandler={openMenus} />}
        bottomComponent={isMobile ? <NavbarTitle title='Help' showBackBtn={true} backHandler={openMenus} /> : undefined}
      />
      <div className={classes.container}>
        <div className={classes.title}>Help</div>
        <div className={classes.searchWrap}>
          <SearchField placeholder={'Search help articles and FAQ’s'} onChange={onKeywordChange} searchValue={general} clearIcon={true} />
        </div>
        {general ? (
          <HelpInfoSearchResults list={searchList} request={general} />
        ) : (
          <div className={classes.tilesBox}>
            <div className={classes.tile} onClick={openFaqs}>
              <div className={classes.tileTitleRow}>
                <div className={classes.tileTitle}>FAQ’s</div>
                <SvgFaqIcon width={35} height={35} />
              </div>
              <div className={classes.tileText}>Frequently Asked Questions about our service</div>
            </div>

            {!isRestaurant && (
              <div className={classes.tile}>
                <div className={classes.tileTitleRow}>
                  <div className={classes.tileTitle}>How to get set up</div>
                  <SvgHowTo width={35} height={35} />
                </div>
                <div className={classes.tileText}>Video guide about getting started</div>
              </div>
            )}
            <div className={clsx(classes.tile, classes.greenTile)} onClick={openContactUs}>
              <div className={classes.tileTitleRow}>
                <div className={classes.tileTitle}>Contact support</div>
                <SvgMessageIcon width={35} height={35} />
              </div>
              <div className={classes.tileText}>Any other questions? You can contact us</div>
            </div>
            <div className={classes.tile} onClick={openHelpCenter}>
              <div className={classes.tileTitleRow}>
                <div className={classes.tileTitle}>Help Centre</div>
                <SvgPointer width={35} height={35} />
              </div>
              <div className={classes.tileText}>Here we have compiled a full guide to walk you through any issues you might have</div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
