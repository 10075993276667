import { createReducer } from '@reduxjs/toolkit';

import { RestaurantSortBy } from '../../../api/admin-restaurants/types';
import { logoutThunk } from '../../user';
import {
  resetRestaurantsParams,
  setRestaurantsKeyword,
  setRestaurantsPage,
  setRestaurantsSort,
  setSelectedRestaurants,
} from './restaurants.actions';

interface State {
  sortBy?: RestaurantSortBy;
  keyword?: string;
  currentPage: number;
  selectedUsers: number[];
}

const initialState: State = {
  sortBy: undefined,
  keyword: undefined,
  currentPage: 1,
  selectedUsers: [],
};

export const restaurantsReducer = createReducer(initialState, (builder) => {
  builder.addCase(logoutThunk.fulfilled, () => {
    return initialState;
  });
  builder.addCase(setRestaurantsKeyword, (state, { payload }) => {
    if (state.keyword === payload) {
      return;
    }
    state.keyword = payload;
    state.currentPage = 1;
  });
  builder.addCase(setRestaurantsPage, (state, { payload }) => {
    state.currentPage = payload;
    state.selectedUsers = [];
  });
  builder.addCase(setRestaurantsSort, (state, { payload }) => {
    state.sortBy = payload.sort;
    state.currentPage = 1;
  });
  builder.addCase(resetRestaurantsParams, () => {
    return initialState;
  });
  builder.addCase(setSelectedRestaurants, (state, { payload }) => {
    state.selectedUsers = payload;
  });
});
