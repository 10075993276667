import { CustomerFilterValues } from '../../api/customer/types';
import { customerSortParams } from '../../components/AppDrawer/SupplierDrawer/Customers/CustomerList/customer-filter';
import { RootState } from '../index';

export const getCustomersSortFilters = (state: RootState) => ({
  sort: state.customers.sortBy,
  filter: state.customers.filterBy,
  keyword: state.customers.keyword,
  currentPage: state.customers.currentPage,
});

export const getCustomersChips = (state: RootState) => {
  const sortLabel = customerSortParams.find((param) => param.value === state.customers.sortBy?.join(' '))?.label || '';

  const sortChip = {
    label: sortLabel,
    key: sortLabel ? 'sort' : '',
  };
  const filterChips = Object.entries(state.customers.filterBy || {}).reduce((acc, [key, value]: [string, string[] | string]) => {
    switch (key as keyof CustomerFilterValues) {
      case 'status_in': {
        if (!Array.isArray(value)) {
          return acc;
        }
        acc.push(
          ...value.map((el) => ({
            label: `Customers - ${el === '0' ? 'requested' : el === '1' ? 'connected' : el === '2' ? 'new request' : ''}`,
            key: `status ${el}`,
          })),
        );
        break;
      }
      case 'user_price_lists_name': {
        if (!Array.isArray(value)) {
          return acc;
        }
        acc.push(
          ...value.map((el) => ({
            label: `Price list - ${el}`,
            key: `price_list ${el}`,
          })),
        );
        break;
      }
    }
    return acc;
  }, [] as { label: string; key: string }[]);
  return [sortChip, ...filterChips].filter((chip) => !!chip.label && !!chip.key);
};
