import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useJsApiLoader } from '@react-google-maps/api';
import clsx from 'clsx';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { useGeIsTeammateAdmin } from '../../../../api/teammates/hooks';
import { useCreateAddressMutation, useDeleteAddressMutation, useGetAddressQuery, useUpdateAddressMutation } from '../../../../api/user';
import SvgTrash from '../../../../assets/icons/Trash';
import { AddressAutocomplete } from '../../../../shared/components/address-autocomplete';
import { BackButton } from '../../../../shared/components/back-button';
import { ThemedButton } from '../../../../shared/components/themed-button';
import { FormikInput } from '../../../../shared/components/formik-input';
import { LocationModal } from '../../../../shared/components/location-map-modal';
import { NavbarTitle } from '../../../../shared/components/navbar-title';
import { PostalCodePlaceAutocomplete } from '../../../../shared/components/postal-code-place-autocomplete';
import { getCoordinates } from '../../../../shared/helpers/getCountry';
import { useScreenSize } from '../../../../shared/hooks/use-screen-size';
import { ToastService } from '../../../../shared/services/toastService';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { getIsRestaurant, openAccountMenu, openMainMenu } from '../../../../store/user';
import { MainNavBar } from '../../../AppDrawer/MainNavBar';
import { TopNavBar } from '../../../AppDrawer/MainNavBar/top-nav-bar';
import { AccountMenu } from '../../account-menu';

import { useStyles } from './style';
import { CommonButton } from '../../../../shared/components/common-button';

export interface AddressFormValues {
  first_name: string;
  street_address1: string;
  street_address2?: string;
  city: string;
  state: string;
  postcode: string;
  full_address: string;
}

const initialValues: AddressFormValues = {
  street_address1: '',
  postcode: '',
  state: '',
  city: '',
  first_name: '',
  full_address: '',
};

export const AddressForm = () => {
  const classes = useStyles();
  const { isMobile, isTablet } = useScreenSize();
  const { goBack, push } = useHistory();
  const { state: id }: { state: number } = useLocation();
  const isEditMode = !!id;
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLFormElement | null>(null);

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('Required field'),
    street_address1: Yup.string().required('Required field'),
    city: Yup.string().required('Required field'),
  });

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
    libraries: ['places'],
  });

  const [isMapOpened, setIsMapOpened] = useState(false);
  const [location, setLocation] = useState<{ lat: number; lng: number }>({ lat: 0, lng: 0 });
  const [postalData, setPostalData] = useState<AddressFormValues>(initialValues);

  const isRestaurant = useAppSelector(getIsRestaurant);
  const isAdminTeammate = useGeIsTeammateAdmin();
  const isViewer = !isAdminTeammate;

  const { address, removeDisabled, country } = useGetAddressQuery(undefined, {
    selectFromResult: ({ data }) => ({
      address: data?.addresses.find((adr) => adr.id === id),
      removeDisabled: data?.addresses?.length === 1,
      country: data?.addresses[0]?.country || '',
    }),
  });

  const [createAddress, { isSuccess: createSuccess, isLoading: createLoading }] = useCreateAddressMutation();
  const [updateAddress, { isSuccess: updateSuccess, isLoading: updateLoading }] = useUpdateAddressMutation();
  const [deleteAddress, { isSuccess: deleteSuccess, isLoading: deleteLoading }] = useDeleteAddressMutation();

  const openMenus = () => {
    dispatch(openMainMenu());
    dispatch(openAccountMenu());
  };

  const onSubmitForm = (
    { first_name, street_address1, street_address2, city, state, full_address }: AddressFormValues,
    formikHelpers: FormikHelpers<any>,
  ) => {
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    const addressData = {
      first_name,
      street_address1,
      street_address2,
      city,
      full_address,
      postcode: postalData.postcode,
      state: state || ' ',
      default_address: !!address?.default_address,
      country,
    };
    !isEditMode && createAddress({ address: addressData });
    isEditMode && updateAddress({ address: { ...addressData, id }, addressId: id });
    formikHelpers.setSubmitting(false);
  };

  const onRemove = () => {
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    deleteAddress(id);
  };

  const onSetDefault = () => {
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    updateAddress({
      address: {
        id,
        default_address: true,
      },
      addressId: id,
    });
  };

  const onSetAddress = (place: google.maps.places.PlaceResult, formValues: AddressFormValues) => {
    const loc = place.geometry?.location;
    const postcode = place.address_components?.find((el) => el.types.includes('postal_code'))?.long_name || postalData.postcode;
    const state = place.address_components?.find((el) => el.types.includes('administrative_area_level_1'))?.long_name || '';
    const suburb = place.address_components?.find((el) => el.types.includes('locality'))?.long_name || '';
    const street_number = place.address_components?.find((el) => el.types.includes('street_number'))?.long_name || '';
    const route = place.address_components?.find((el) => el.types.includes('route'))?.long_name || '';
    setPostalData({
      ...formValues,
      postcode,
      state,
      city: suburb,
      street_address1: `${street_number} ${route}`.trim() || place.name || '',
      full_address: place.formatted_address || '',
    });
    loc?.lat() && loc?.lng() && setLocation({ lat: loc?.lat() as number, lng: loc?.lng() as number });
  };

  const onSetPostCodeLoc = (place: google.maps.places.PlaceResult, formValues: AddressFormValues) => {
    const postcode = place.address_components?.find((el) => el.types.includes('postal_code'))?.long_name || '';
    if (!postcode) {
      return;
    }
    const state = place.address_components?.find((el) => el.types.includes('administrative_area_level_1'))?.long_name || '';
    const suburb = place.address_components?.find((el) => el.types.includes('locality'))?.long_name || '';
    setPostalData({ ...formValues, postcode, state, city: suburb });
  };

  useEffect(() => {
    if (address) {
      setPostalData({
        first_name: address.first_name || '',
        state: address.state || '',
        postcode: address.postcode || '',
        street_address1: address.street_address1 || '',
        city: address.city || '',
        full_address: address.full_address,
      });
      if (address.latitude && address.longitude) {
        setLocation({ lat: parseFloat(address.latitude), lng: parseFloat(address.longitude) });
      } else {
        getCoordinates(country).then((res) => {
          res && setLocation({ lat: res.lat, lng: res.lng });
        });
      }
    }
  }, [address]);

  useEffect(() => {
    if (!isEditMode) {
      getCoordinates(country).then((res) => {
        res && setLocation({ lat: res.lat, lng: res.lng });
      });
    }
  }, [isEditMode]);

  useEffect(() => {
    if (createSuccess || updateSuccess || deleteSuccess) {
      push('/account/venue');
    }
  }, [createSuccess, updateSuccess, deleteSuccess]);

  return (
    <>
      <TopNavBar
        isAccount={true}
        bottomComponent={isMobile ? <NavbarTitle title={isEditMode ? 'Edit Address' : 'Add New Address'} showBackBtn={true} /> : undefined}
      />
      <MainNavBar isAccount={true} />
      <div className={clsx(classes.accountContainer, isRestaurant && classes.accountContainerCustomer)}>
        <div className={classes.accountHeader}>
          <div className={classes.accountTitle}>My Account</div>
        </div>
        <div className={classes.accountBody}>
          <AccountMenu showBack={!isRestaurant} />
          <div className={classes.accountContent}>
            {isRestaurant && isTablet && (
              <div className={classes.tabletTitleCustomer}>
                <NavbarTitle title='Venue Information' showBackBtn={true} backHandler={openMenus} />
              </div>
            )}
            <div className={classes.titleBox}>
              <h2 className={classes.title}>{isEditMode ? 'Edit Address' : 'Add New Address'}</h2>
              {isRestaurant && (
                <ThemedButton onClick={setIsMapOpened.bind(null, true)} title='Add Pin Pont' isSmall={true} disabled={isViewer} />
              )}
            </div>
            <div className={classes.titleBox}>
              <BackButton onClickHandler={goBack} />
              {isEditMode && (
                <div>
                  <ThemedButton
                    title='Remove address'
                    onClick={onRemove}
                    buttonStyle='blueTransparent'
                    width={135}
                    disabled={removeDisabled}
                  />
                  <ThemedButton
                    title='Set as default'
                    onClick={onSetDefault}
                    buttonStyle='blueTransparent'
                    width={135}
                    disabled={!!address?.default_address}
                  />
                </div>
              )}
            </div>

            <Formik initialValues={postalData} onSubmit={onSubmitForm} validationSchema={validationSchema} enableReinitialize={true}>
              {({ submitForm, isValid, setFieldError, values }) => (
                <Form className={classes.formBox} ref={ref}>
                  <div className={classes.centeredWrap}>
                    <Field name='first_name'>
                      {(fieldProps: FieldProps) => (
                        <FormikInput
                          {...fieldProps}
                          label='Delivery Address Label'
                          placeholder='Delivery Address Label'
                          autoComplete='chrome-off'
                          disabled={isViewer}
                        />
                      )}
                    </Field>
                  </div>
                  <div className={classes.fullWidthFieldWrap}>
                    {isLoaded && (
                      <AddressAutocomplete
                        initialValue={values.street_address1}
                        label='Address Line 1'
                        placeholder='Address Line 1'
                        autoComplete='chrome-off'
                        setPlace={(gData) => onSetAddress(gData, values)}
                      />
                    )}
                  </div>
                  <div className={classes.fullWidthFieldWrap}>
                    <Field name='street_address2'>
                      {(fieldProps: FieldProps) => (
                        <FormikInput
                          {...fieldProps}
                          label='Address line 2 (Optional)'
                          placeholder='Address Line 2'
                          autoComplete='chrome-off'
                        />
                      )}
                    </Field>
                  </div>
                  <div className={classes.smallFieldsBox}>
                    <div className={classes.smallField}>
                      {isLoaded && (
                        <PostalCodePlaceAutocomplete
                          initialValue={postalData.postcode}
                          setFieldError={setFieldError}
                          placeholder='Postcodе'
                          setPlace={(gData) => onSetPostCodeLoc(gData, values)}
                          label='Postcodе'
                        />
                      )}
                    </div>
                    <div className={classes.centeredWrap}>
                      <Field name='city'>
                        {(fieldProps: FieldProps) => (
                          <FormikInput {...fieldProps} label='City' placeholder='City' autoComplete='chrome-off' disabled={isViewer} />
                        )}
                      </Field>
                    </div>
                    <div className={classes.smallField}>
                      <Field name='state'>
                        {(fieldProps: FieldProps) => (
                          <FormikInput placeholder='State/City' label='State/City' {...fieldProps} disabled={isViewer} />
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className={classes.submitBtn}>
                    <ThemedButton
                      onClick={submitForm}
                      title={isEditMode ? 'Update' : 'Add address'}
                      buttonStyle='secondary'
                      width={isMobile ? 150 : 280}
                      disabled={!postalData.postcode || !isValid || createLoading || updateLoading || deleteLoading}
                    />
                    {isRestaurant && isMobile && <ThemedButton onClick={setIsMapOpened.bind(null, true)} title='Add Pin Pont' />}
                  </div>
                  {isEditMode && (
                    <div className={classes.btnBlockMobile}>
                      <CommonButton
                        onClick={onSetDefault}
                        title='Set as default'
                        width={150}
                        className={classes.setDefaultBtn}
                        disabled={!!address?.default_address || updateLoading || deleteLoading}
                      />
                      <ThemedButton
                        onClick={onRemove}
                        title='Remove'
                        width={150}
                        buttonStyle='icon'
                        startIcon={<SvgTrash />}
                        disabled={removeDisabled}
                      />
                    </div>
                  )}
                  <input type='text' name='postcode' placeholder='postcode' className='hiddenInput' />
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      {isMapOpened && (
        <LocationModal
          lat={location.lat}
          lng={location.lng}
          isOpen={isMapOpened}
          onClose={setIsMapOpened.bind(null, false)}
          setData={(addressToSet: Partial<AddressFormValues>) => {
            setPostalData((prev) => ({ ...prev, ...addressToSet, first_name: prev.first_name }));
          }}
          setLocationData={(loc: { lat: number; lng: number }) => {
            setLocation(loc);
          }}
          address={postalData.full_address}
        />
      )}
    </>
  );
};
