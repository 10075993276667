import { RootState } from '../index';

export const getSelectedCategory = (state: RootState) => state.suppliersCategories.selectedCategory;

export const getSelectedSupplier = (state: RootState) => state.suppliersCategories.selectedSupplier;

export const getManualSupplierAction = (state: RootState) => state.suppliersCategories.manualSupplierAction;

export const getSupplierKeyword = (state: RootState) => state.suppliersCategories.keyword;

export const getSuppliersSort = (state: RootState) => ({
  mySuppliersSort: state.suppliersCategories.mySuppliersSort,
});
