import { createReducer } from '@reduxjs/toolkit';

import { PriceExceptionFilter, PriceExceptionSortBy } from '../../api/price-exception/types';
import {
  resetPriceExceptionsFilters,
  setPriceExceptionKeyword,
  setPriceExceptionsFilterSort,
  setPriceExceptionsPage,
} from './price-exception.actions';

interface State {
  keyword?: string;
  currentPage: number;
  filter?: PriceExceptionFilter;
  sort?: PriceExceptionSortBy;
}

const initialState: State = {
  keyword: undefined,
  currentPage: 1,
  filter: undefined,
  sort: undefined,
};

export const priceExceptionReducer = createReducer(initialState, (builder) => {
  builder.addCase(setPriceExceptionKeyword, (state, { payload }) => {
    if (state.keyword === payload) {
      return;
    }
    state.keyword = payload;
    state.currentPage = 1;
  });
  builder.addCase(setPriceExceptionsPage, (state, { payload }) => {
    state.currentPage = payload;
  });
  builder.addCase(setPriceExceptionsFilterSort, (state, { payload }) => {
    state.filter = payload.filter;
    state.sort = payload.sort;
    state.currentPage = 1;
  });
  builder.addCase(resetPriceExceptionsFilters, (state, { payload }) => {
    if (payload.page) {
      state.currentPage = 1;
    }
    if (payload.filter) {
      state.filter = undefined;
    }
    if (payload.sort) {
      state.sort = undefined;
    }
  });
});
