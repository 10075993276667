import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';
import { Sizes } from '../../../styles/sizes';

export interface StyleProps {
  right: number;
  arrowPosition?: number;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    overlay: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 100,
    },
    trigger: {
      position: 'relative',
      zIndex: 101,
    },
    container: {
      position: 'absolute',
      top: 50,
      right: ({ right }) => right,
      minWidth: 150,
      backgroundColor: colorVariables.white,
      padding: 20,
      paddingTop: 40,
      borderRadius: 10,
      boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
      zIndex: 103,
      '&::after': {
        content: '""',
        position: 'absolute',
        display: 'block',
        top: -16,
        right: ({ arrowPosition }) => (arrowPosition || 53) - 3,
        width: 0,
        height: 0,
        zIndex: 101,
        borderLeft: '16px solid transparent',
        borderRight: '16px solid transparent',
        borderBottom: `16px solid rgba(0, 0, 0, 0.03)`,
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        display: 'block',
        top: -14,
        right: ({ arrowPosition }) => arrowPosition || 53,
        width: 0,
        height: 0,
        zIndex: 102,
        borderLeft: '14px solid transparent',
        borderRight: '14px solid transparent',
        borderBottom: `14px solid #fff`,
      },
    },
    closeBtn: {
      position: 'absolute',
      right: 10,
      top: 5,
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    closeBtnShown: {
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'block',
      },
    },
  }),
);
