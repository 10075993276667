import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../../styles/colorVariables';
import { Sizes } from '../../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down(Sizes.desktop)]: {
        position: 'relative',
      },
    },
    rootField: {
      width: 'calc(100% - 50px)',
      margin: 0,
      marginRight: 20,
      padding: 0,
      '& legend': {
        width: 0,
      },
      '& .MuiAutocomplete-inputRoot': {
        paddingRight: '0 !important',
        '&::before, &::after': {
          display: 'none !important',
        },
      },
      '& .MuiInputBase-input.MuiInput-input.MuiAutocomplete-input': {
        fontSize: 16,
        fontWeight: 400,
        color: colorVariables.navy,
        lineHeight: '30px',
        height: 30,
        paddingRight: '30px !important',
        backgroundColor: colorVariables.white,
        borderBottom: `1px solid ${colorVariables.steelGrey}`,
        '&:hover': {
          [theme.breakpoints.up(Sizes.desktop)]: {
            borderColor: colorVariables.blue,
          },
        },
        '&:focus': {
          borderColor: colorVariables.blue,
        },
        [theme.breakpoints.down(Sizes.desktop)]: {
          backgroundColor: 'rgba(152, 165, 183, 0.06)',
          border: `1px solid ${colorVariables.steelGrey}`,
          height: 30,
          lineHeight: '30px',
          borderRadius: 30,
          padding: '7px 80px 7px 20px !important',
        },
        [theme.breakpoints.down(Sizes.mobile)]: {
          fontSize: 14,
          padding: '4px 80px 4px 15px !important',
        },
      },
      '& .MuiAutocomplete-inputRoot.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd': {
        display: 'none',
      },
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: '100%',
        position: 'relative',
        marginRight: 0,
      },
    },
    paper: {
      backgroundColor: colorVariables.white,
    },
    option: {
      backgroundColor: colorVariables.white,
      fontSize: 14,
      minHeight: 34,
      marginTop: 2,
      '&:hover': {
        backgroundColor: colorVariables.blueLight,
      },
      '&:focus': {
        backgroundColor: colorVariables.blueLight,
      },
      '&[data-focus="true"]': {
        backgroundColor: colorVariables.blueLight,
      },
      '&:active': {
        backgroundColor: colorVariables.blueLight,
      },
    },
    noOptions: {
      fontSize: 14,
    },
    searchBtn: {
      [theme.breakpoints.down(Sizes.desktop)]: {
        position: 'absolute',
        right: 5,
        top: 5,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        right: 2,
        top: 2,
      },
    },
    closeIcon: {
      position: 'absolute',
      right: 5,
      bottom: 9,
      color: colorVariables.grey60,
      cursor: 'pointer',
      [theme.breakpoints.down(Sizes.desktop)]: {
        right: 50,
        bottom: 15,
        fontSize: 18,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        bottom: 12,
      },
    },
    hidden: {
      height: 0,
      width: 0,
      position: 'absolute',
      zIndex: -999,
      opacity: 0,
      border: 'none !important',
      outline: 'none !important',
      '*:focus': {
        border: 'none !important',
        outline: 'none !important',
      },
    },
  }),
);
