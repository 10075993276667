import { RootState } from '../index';
import { salesAdapter } from './sales.reducer';

export const getSalesSortFilters = (state: RootState) => ({
  currentPage: state.sales.currentPage,
  sortBy: state.sales.sortBy,
  filter: state.sales.filter,
  keyword: state.sales.keyword,
});

export const salesSelectors = salesAdapter.getSelectors((state: RootState) => state.sales.list);
