import React, { ChangeEvent, useEffect, useState } from 'react';

import MuiTextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import { useDebouncedCallback } from 'use-debounce';

import { ProductInCart } from '../../../api/cart/types';
import { useSearchByProductName } from '../../../api/product/hooks';

import { useStyles } from './style';

interface Props {
  value: string;
  onSetProduct: (product?: ProductInCart) => void;
  label: string;
  placeholder: string;
  searchById?: boolean;
  isError: boolean;
  resetField?: boolean;
  rootFieldClass?: string;
}

export const ProductNameAutoComplete: React.FC<Props> = ({
  value,
  onSetProduct,
  label,
  placeholder,
  searchById,
  resetField,
  isError,
  rootFieldClass = '',
}) => {
  const classes = useStyles();

  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [searchValue, setSearchValue] = useState('');

  const [isOpen, setIsOpen] = useState(false);

  const { products, productsLoading } = useSearchByProductName(searchValue);

  const debouncedHandleOnChange = useDebouncedCallback((searchStr: string) => {
    setSearchValue(searchStr);
  }, 300);

  const onInputChange = (event: ChangeEvent<{}>, newInputValue: string) => {
    setInputValue(newInputValue);
    debouncedHandleOnChange(newInputValue);
    if (newInputValue.trim() && selectedValue !== newInputValue) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  const onSelect = (event: ChangeEvent<{}>, newValue: string | null) => {
    setSelectedValue(newValue);
    setIsOpen(false);
  };

  useEffect(() => {
    if (selectedValue) {
      const product = products.find((el) => (searchById ? el.product_id : `${el.name} | ${el.unit}`) === selectedValue);
      product && onSetProduct(product);
    } else {
      onSetProduct(undefined);
    }
  }, [selectedValue]);

  useEffect(() => {
    if (value) {
      setInputValue(value);
      setSelectedValue(value);
    }
    if (!value && (searchById || resetField)) {
      setInputValue('');
      setSelectedValue(null);
    }
  }, [value]);

  return (
    <div className={clsx([classes.box, classes.paddingBox])}>
      {isError && <div className={classes.toolTip}>Required field</div>}
      <Autocomplete
        open={isOpen}
        loading={productsLoading}
        blurOnSelect={true}
        popupIcon={null}
        value={selectedValue}
        onChange={onSelect}
        inputValue={inputValue}
        onInputChange={onInputChange}
        classes={{
          root: clsx(classes.rootField, rootFieldClass),
          clearIndicator: classes.clearIndicator,
          paper: classes.paper,
          option: classes.option,
        }}
        noOptionsText='Not found'
        options={products.map((el) => (searchById ? el.product_id : `${el.name} | ${el.unit}`)).filter((el) => el)}
        renderInput={(params) => (
          <MuiTextField
            {...params}
            variant='outlined'
            label={label}
            placeholder={placeholder}
            InputLabelProps={{
              shrink: true,
            }}
            FormHelperTextProps={{
              className: classes.toolTip,
            }}
          />
        )}
      />
    </div>
  );
};
