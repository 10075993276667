import React from 'react';

import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import clsx from 'clsx';

import { useStyles } from './style';

interface Props {
  items: React.ReactNode[];
  triggerBtn?: React.ReactNode;
  disabledIdx?: number[];
  containerClass?: string;
}

export const DropdownMenu: React.FC<Props & Partial<MenuProps>> = ({
  items = [],
  triggerBtn,
  disabledIdx = [],
  containerClass = '',
  ...menuProps
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div aria-controls='menu' onClick={handleClick} className={classes.triggerWrap}>
        {triggerBtn}
      </div>
      <Menu
        id='menu'
        autoFocus={false}
        anchorEl={anchorEl}
        disableScrollLock={true}
        keepMounted={true}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ paper: clsx(classes.container, containerClass) }}
        getContentAnchorEl={null}
        {...menuProps}
      >
        {items.map((item, idx) => (
          <MenuItem
            onClick={disabledIdx.includes(idx) ? undefined : handleClose}
            classes={{ root: clsx(classes.dropdownItem, disabledIdx.includes(idx) && classes.disabled) }}
            key={idx}
          >
            {item}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
