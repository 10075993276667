import * as React from 'react';

function SvgRecentlyCartFilled(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M9.755 21.02a1.286 1.286 0 100-2.571 1.286 1.286 0 000 2.571zM16.303 21.02a1.286 1.286 0 100-2.571 1.286 1.286 0 000 2.571zM9.216 15.832l-.109-.7h8.794c.346 0 .652-.25.728-.593h0v-.002l1.355-7.05a.66.66 0 00-.153-.615.721.721 0 00-.575-.269H7.836l-.113-.756a1.72 1.72 0 00-1.72-1.467H4.129a.73.73 0 100 1.46h1.876c.14 0 .247.098.26.216h0v.003l1.511 9.986h0a2.106 2.106 0 002.084 1.797h7.226a.73.73 0 100-1.46H9.859a.67.67 0 01-.643-.55zm9.156-7.751l-1.067 5.591h-8.41l-.847-5.591h10.324z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth={0.14}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19 8H8l.5 6h9L19 8zm-6.796 4.51l-1.216-1.216a.681.681 0 010-.956.66.66 0 01.938 0l.747.747 1.858-1.84a.66.66 0 01.938 0 .66.66 0 010 .937l-2.327 2.327a.67.67 0 01-.938 0z'
        fill='currentColor'
      />
    </svg>
  );
}

export default SvgRecentlyCartFilled;
