import * as React from 'react';

function SvgFilter(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M10.025 3.736V1.728a1.025 1.025 0 00-2.05 0v2.008c-.84.01-1.519.695-1.519 1.537v.512c0 .842.68 1.527 1.52 1.537v8.948a1.025 1.025 0 102.049 0V7.322c.84-.01 1.519-.695 1.519-1.537v-.512c0-.842-.68-1.527-1.52-1.537zM16.48 8.62V1.729a1.025 1.025 0 10-2.049 0V8.62c-.839.01-1.519.695-1.519 1.536v.513c0 .841.68 1.527 1.52 1.537v4.063a1.025 1.025 0 002.049 0v-4.063c.84-.01 1.519-.696 1.519-1.537v-.512c0-.842-.68-1.527-1.52-1.537zM3.569 6.606V1.728a1.025 1.025 0 00-2.05 0v4.877C.68 6.615 0 7.301 0 8.142v.513c0 .841.68 1.527 1.52 1.536v6.08a1.025 1.025 0 102.049 0v-6.08c.84-.01 1.519-.695 1.519-1.536v-.513c0-.841-.68-1.527-1.52-1.536z'
        fill='currentColor'
      />
    </svg>
  );
}

export default SvgFilter;
