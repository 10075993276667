import { createAction } from '@reduxjs/toolkit';
import { WastageFilters, WastageSortBy } from '../../api/wastages/types';

export const setWastagePage = createAction<number>('SET_WASTAGE_PAGE');

export const clearWastageList = createAction<boolean>('CLEAR_WASTAGE_LIST');

export const setWastageKeyword = createAction<string | undefined>('SET_WASTAGE_KEYWORD');

export const setWastageSortFilters = createAction<{ sortBy?: WastageSortBy; filter?: WastageFilters }>('SET_WASTAGE_SORT_FILTERS');
