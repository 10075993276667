import React, { ReactNode, useEffect, useState } from 'react';

import { Menu } from '@material-ui/core';
import { Calendar } from 'react-date-range';
// @ts-ignore
import * as locales from 'react-date-range/dist/locale';
import { useDebouncedCallback } from 'use-debounce';

import { ThemedButton } from '../themed-button';

import { useStyles } from './style';

interface Props {
  onDateChange: (date: Date | null) => void;
  trigger: ReactNode;
  selectedDate: Date | null;
  minDate?: Date;
  maxDate?: Date;
  disabledDates?: Date[];
}

export const DatePickerComponent: React.FC<Props> = ({ onDateChange, selectedDate, trigger, minDate, maxDate, disabledDates }) => {
  const classes = useStyles();

  const [date, setDate] = useState<Date | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
    setDate(selectedDate);
  };

  const debouncedClearDate = useDebouncedCallback(() => {
    setDate(null);
  }, 100);

  const handleClose = () => {
    setAnchorEl(null);
    debouncedClearDate();
  };

  const onClear = () => {
    handleClose();
    onDateChange(null);
  };

  const onApply = () => {
    date && onDateChange(date);
    setAnchorEl(null);
  };

  const handleDateChange = (value: Date) => {
    setDate(value);
  };

  useEffect(() => {
    selectedDate && setDate(selectedDate);
  }, [selectedDate]);

  return (
    <div className={classes.root}>
      <div onClick={handleClick} className={classes.triggerBox}>
        {trigger}
      </div>
      <Menu
        autoFocus={false}
        anchorEl={anchorEl}
        disableScrollLock={true}
        keepMounted={true}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ paper: classes.container }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Calendar
          fixedHeight={true}
          locale={locales.enGB}
          classNames={{
            calendarWrapper: classes.calendar,
            month: classes.month,
            dayPassive: classes.dayPassive,
            dayDisabled: classes.dayDisabled,
            weekDays: classes.weekDays,
            weekDay: classes.weekDay,
            day: classes.day,
            dayToday: classes.today,
            dayHovered: classes.dayHovered,
            monthAndYearWrapper: classes.monthAndYearWrapper,
            nextPrevButton: classes.nextPrevButton,
            monthAndYearPickers: classes.monthAndYearPickers,
            prevButton: classes.prevButton,
            nextButton: classes.nextButton,
          }}
          date={date || undefined}
          disabledDates={disabledDates}
          onChange={handleDateChange}
          minDate={minDate || new Date()}
          maxDate={maxDate}
          showDateDisplay={false}
          showMonthAndYearPickers={false}
        />
        <div className={classes.btnBox}>
          <ThemedButton title='Clear' onClick={onClear} isSmall={true} width={50} buttonStyle='secondary' />
          <ThemedButton title='Apply' onClick={onApply} isSmall={true} width={50} />
        </div>
      </Menu>
    </div>
  );
};
