export const helpCenterGeneral = [
  {
    id: 301,
    path: 'help-center',
    title: 'Contact Help',
    content: `
<p>To contact us for help, go to your account & select <b>“Contact Help”</b> then fill out the help form by following the title prompts. Type a keyword(s) summarising your enquiry  in  the “subject” box and type further details of your enquiry in  the “message” box. Click “send message” once complete.</p>
<p>Alternatively, you can email Open Pantry directly at <a href="info@theopenpantry.com">info@theopenpantry.com</a> or select the blue Messenger icon to contact us on Facebook Messenger Note: There may be delays in receiving a response via Messenger. For more urgent enquiries, please use the help form or email directly. </p>
`,
  },
  {
    id: 302,
    path: 'help-center',
    title: 'Notifications',
    content: `
<p>To change which notifications you receive, go to your account & select <b>“Notifications”</b> or <b>“Email Notifications”</b> </p>
<p> You may change your  email notification settings by clicking the on/off buttons beside which actions you’d like to receive notifications for.  Blue = On, Grey = Off </p>
`,
  },
  {
    id: 303,
    path: 'help-center',
    title: 'Change Password',
    content: `
<p>To change your password, go to your account/profile & edit your information. Enter your current password under “Verify password” and then enter your new password under ‘New Password’ then repeat this password in ‘Confirm new Password’. Press ‘Update’ once complete to save.</p>`,
  },
  {
    id: 304,
    path: 'help-center',
    title: 'Reset Password',
    content: `
<p>Your password can be reset from the homepage, before logging in. Click on “Forgot Password” and an email reset process will begin. Follow the prompts to reset your password.</p>`,
  },
];
