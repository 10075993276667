import { createStyles, makeStyles } from '@material-ui/core';

import AuthDots from '../../assets/images/bg/Auth_bg_dots.svg';
import AuthLines from '../../assets/images/bg/Auth_bg_lines.svg';
import { Sizes } from '../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    authContainer: {
      display: 'flex',
      justifyContent: 'center',
      flex: 1,
      minHeight: '100vh',
      padding: 80,
      backgroundColor: 'rgba(207, 215, 230, 0.3)',
      background: 'linear-gradient rgba(255, 255, 255, 1) 100%, rgba(255, 255, 255, 0) 0%)',
      backgroundImage: `url(${AuthLines}), url(${AuthDots})`,
      backgroundRepeat: 'no-repeat, no-repeat',
      backgroundPosition: 'top 126px left 0px, bottom 250px right 0px',
      [theme.breakpoints.down(Sizes.desktop)]: {
        padding: 40,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: 20,
        paddingTop: 30,
        backgroundPosition: 'top 60px left 0px, bottom 60px right 0px',
        overflowY: 'scroll',
      },
    },
    authContainerLoading: {
      opacity: 0,
    },
  }),
);
