import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { colorVariables } from '../../../styles/colorVariables';

export interface StyleProps {
  right?: boolean;
  width?: number;
}

export const useStyles = makeStyles<Theme, StyleProps>(() =>
  createStyles({
    box: {
      position: 'relative',
      width: 'min-content',
    },
    root: {
      visibility: 'hidden',
      flexShrink: 0,
      '&:hover': {
        visibility: 'visible',
      },
    },
    toolTip: {
      display: 'block',
      width: ({ width }) => width || 'max-content',
      minHeight: 24,
      borderRadius: 4,
      padding: '5px 10px',
      textAlign: 'center',
      fontSize: 11,
      lineHeight: 1.3,
      color: colorVariables.white,
      backgroundColor: colorVariables.navy,
      position: 'absolute',
      bottom: 'calc(100% + 8px)',
      left: -20,
      zIndex: 999,

      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: -15,
        left: ({ right }) => (right ? 'auto' : 24),
        right: ({ right }) => (right ? 24 : 'auto'),
        borderWidth: 8,
        borderStyle: 'solid',
        borderColor: `${colorVariables.navy} transparent transparent transparent`,
      },
    },
    toolTipTop: {
      display: 'block',
      width: ({ width }) => width || 'max-content',
      minHeight: 24,
      borderRadius: 4,
      padding: '5px 10px',
      textAlign: 'center',
      fontSize: 11,
      lineHeight: 1.3,
      color: colorVariables.white,
      backgroundColor: colorVariables.navy,
      position: 'absolute',
      top: 'calc(100% + 8px)',
      left: 0,
      zIndex: 999,

      '&::after': {
        content: '""',
        position: 'absolute',
        top: -15,
        left: ({ right }) => (right ? 'auto' : 24),
        right: ({ right }) => (right ? 24 : 'auto'),
        borderWidth: 8,
        borderStyle: 'solid',
        borderColor: `transparent transparent ${colorVariables.navy} transparent`,
      },
    },
    rightSideArrow: {
      '&::after': {
        display: 'none',
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        bottom: '50%',
        left: 'auto',
        right: -15,
        borderWidth: 8,
        borderStyle: 'solid',
        borderColor: `transparent transparent transparent ${colorVariables.navy}`,
      },
    },
    trigger: {
      '&:hover+div': {
        visibility: 'visible !important',
      },
    },
  }),
);
