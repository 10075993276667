import { createAction } from '@reduxjs/toolkit';

import { ManualSupplierAction } from '../../api/supplier/types';
import { SupplierUser } from '../../api/user/types';
import { ProductsCategories } from '../../shared/constants/products';

export const setSelectedCategory = createAction<ProductsCategories | null>('SET_SELECTED_CATEGORY');

export const setSupplierKeyword = createAction<string>('SET_SUPPLIER_KEYWORD');

export const setMySuppliersSort = createAction<string>('SET_MY_SUPPLIERS_SORT');

export const setSelectedSupplier = createAction<SupplierUser | null>('SET_SELECTED_SUPPLIER');

export const setManualSupplierAction = createAction<{ action: ManualSupplierAction; id: number } | null>('SET_SELECTED_MANUAL_SUPPLIER_ID');
