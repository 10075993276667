import React, { MouseEvent } from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import { Collapse } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import clsx from 'clsx';

import { rootApi } from '../../../../../api';
import { orderApi } from '../../../../../api/order';
import { useGeHiddenPages } from '../../../../../api/teammates/hooks';
import { SubscriptionPlanEnum } from '../../../../../api/user/types';
import { useScreenSize } from '../../../../../shared/hooks/use-screen-size';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { getMySubscriptionType } from '../../../../../store/user';
import { LinkItem } from '../index';

import { useRippleStyles } from '../../../../../styles/customRipple';
import { useStyles } from './style';
import { useCurrentTheme } from '../../../../../api/admin-themes/hooks';
import { ThemeColors } from '../../../../../api/admin-themes/types';

interface Props {
  title: string;
  link: string;
  isMainMenuOpened: boolean;
  onClickHandler: (...args: unknown[]) => void;
  icon: React.ReactNode;
  exact?: boolean;
  expanded?: boolean;
  nestedLinks?: LinkItem[];
  nestedHandler: () => void;
  counter?: { [key: string]: number | undefined };
  isRestaurant?: boolean;
  permissionKey?: string;
}

const preventedRSLinks = ['/tools'];

const Component: React.FC<Props> = ({
  isMainMenuOpened,
  title,
  link,
  onClickHandler,
  icon,
  exact,
  expanded,
  nestedLinks,
  nestedHandler,
  counter,
  isRestaurant,
  permissionKey,
}) => {
  const rippleClass = useRippleStyles();
  const { isDesktop } = useScreenSize();
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const { colors } = useCurrentTheme();
  const classes = useStyles({ textColor: colors[ThemeColors.navBarText], bgColor: colors[ThemeColors.navBarBg] });

  const plan = useAppSelector(getMySubscriptionType);
  const hiddenPages = useGeHiddenPages();
  const disabledByPermission = Array.isArray(hiddenPages) && permissionKey && hiddenPages.includes(permissionKey);

  const onCounterClick = () => {
    if (link === '/orders') {
      dispatch(rootApi.util.invalidateTags(['Order']));
    }
    if (link === '/customers') {
      dispatch(rootApi.util.invalidateTags(['Customer']));
    }
  };

  const onLinkClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (
      (nestedLinks?.length && isRestaurant && preventedRSLinks.includes(link)) ||
      (Array.isArray(hiddenPages) && nestedLinks?.some((el) => hiddenPages.includes(el.permissionKey as string)))
    ) {
      e.preventDefault();
    }
    onClickHandler();
  };

  const preventRpsClick = (e: MouseEvent<HTMLAnchorElement>) => {
    push('/tools/restaurantpro');
    nestedHandler();
    e.preventDefault();
  };

  const onNestedLinkClick = () => {
    if (link === '/orders') {
      dispatch(orderApi.util.invalidateTags(['Order']));
    }
    nestedHandler();
  };

  return (
    <>
      <NavLink
        to={link}
        className={clsx([
          classes.menuItem,
          rippleClass.ripple,
          !isRestaurant && !isMainMenuOpened && classes.menuItemTabletSupplier,
          disabledByPermission && classes.disabledLink,
        ])}
        activeClassName={classes.menuItemActive}
        exact={!!exact}
        onClick={onLinkClick}
      >
        <div className={classes.menuIconWrap}>
          {icon}
          {!isRestaurant && !isMainMenuOpened && counter && counter[link] && (
            <span className={clsx(classes.counter, classes.tabletCounter)} onClick={onCounterClick}>
              {counter[link]}
            </span>
          )}
        </div>
        <div
          className={clsx({
            [classes.menuItemWrap]: isMainMenuOpened,
            [classes.menuItemWrapCollapsed]: !isMainMenuOpened,
            [classes.menuItemWrapCollapsedCustomer]: isRestaurant && !isMainMenuOpened,
          })}
        >
          <span
            className={clsx({
              [classes.menuItemText]: true,
              [classes.menuItemWrapCollapsed]: !isMainMenuOpened,
            })}
          >
            {title}
          </span>
          {counter && counter[link] && !expanded && (
            <span className={classes.counter} onClick={onCounterClick}>
              {counter[link]}
            </span>
          )}
          {!!nestedLinks?.length && (
            <KeyboardArrowDownIcon
              className={clsx([
                classes.menuItemChevron,
                isRestaurant && classes.menuItemChevronRestaurant,
                expanded && classes.menuItemChevronRotated,
              ])}
            />
          )}
        </div>
      </NavLink>
      {nestedLinks?.length ? (
        <Collapse in={expanded && (isMainMenuOpened || isDesktop)} timeout={500}>
          {nestedLinks.map((nestedLink) => (
            <NavLink
              key={nestedLink.link}
              to={nestedLink.link}
              exact={nestedLink.exact}
              className={clsx([
                classes.menuItem,
                classes.nestedItem,
                rippleClass.ripple,
                Array.isArray(hiddenPages) &&
                  nestedLink.permissionKey &&
                  hiddenPages.includes(nestedLink.permissionKey) &&
                  classes.disabledLink,
              ])}
              activeClassName={classes.nestedItemActive}
              onClick={
                isRestaurant &&
                nestedLink.link.includes('/tools/') &&
                (plan === SubscriptionPlanEnum.NORMAL || plan === SubscriptionPlanEnum.MENU)
                  ? preventRpsClick
                  : onNestedLinkClick
              }
            >
              <ArrowForwardIcon className={classes.nestedItemArrow} />
              <span className={classes.nestedItemText}>{nestedLink.title}</span>
              {nestedLink.showCounter && counter && counter[link] && expanded && (
                <span className={classes.counter} onClick={onCounterClick}>
                  {counter[link]}
                </span>
              )}
            </NavLink>
          ))}
        </Collapse>
      ) : null}
    </>
  );
};
export const NavLinkItem = React.memo(Component);
