import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../../../styles/colorVariables';
import { Sizes } from '../../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      width: '100%',
    },
    row: {
      width: '100%',
      display: 'flex',
      position: 'relative',
      justifyContent: 'space-between',
      padding: '45px 10px 25px 10px',
      borderBottom: `1px solid ${colorVariables.steelGrey}`,
      [theme.breakpoints.down(Sizes.desktop)]: {
        padding: '25px 10px 20px 10px',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        backgroundColor: colorVariables.white,
        borderBottom: 'none',
        padding: '20px 10px 12px 15px',
        margin: '0 auto 8px auto',
        width: '98%',
        borderRadius: 8,
        flexWrap: 'wrap',
      },
    },
    companyInfo: {
      display: 'flex',
      paddingRight: 20,
      position: 'relative',
      width: 'calc((100% - 230px)/2)',
      [theme.breakpoints.down(Sizes.desktop)]: {
        paddingRight: 10,
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        width: 'calc(100% - 300px)',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        alignItems: 'center',
      },
    },
    btnBlock: {
      width: 230,
      '& button': {
        padding: '0 10px',
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        width: 110,
        '& button': {
          marginTop: 7,
        },
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        paddingLeft: 48,
        marginTop: 15,
        '& button': {
          height: 30,
          lineHeight: '30px',
          fontSize: 13,
          marginTop: 0,
          padding: '0 5px',
          marginRight: 0,
        },
      },
    },
    btnWrap: {
      display: 'flex',
      width: '100%',
      [theme.breakpoints.down(Sizes.tablet)]: {
        flexWrap: 'wrap',
      },
    },
    avatarWrap: {
      width: 47,
      height: 47,
      marginRight: 25,
      borderRadius: '50%',
      overflow: 'hidden',
      flexShrink: 0,
      boxShadow: `0 0 0 1px ${colorVariables.steelGrey}`,
      [theme.breakpoints.down(Sizes.desktop)]: {
        marginRight: 15,
      },
    },
    boldText: {
      fontWeight: 600,
      [theme.breakpoints.down(Sizes.mobile)]: {
        fontSize: 16,
      },
    },
    greyText: {
      color: colorVariables.grey60,
    },
    acceptedText: {
      margin: '0 5px',
      display: 'inline-flex',
      width: 100,
      lineHeight: '30px',
      justifyContent: 'center',
      fontWeight: 600,
      color: colorVariables.green,
    },
    dropdownWrap: {
      position: 'relative',
      width: 'calc((100% - 230px)/2)',
      display: 'flex',
      justifyContent: 'center',
      flexShrink: 0,
      [theme.breakpoints.down(Sizes.tablet)]: {
        width: 200,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    customSelectBtn: {
      height: 32,
      padding: '0 15px',
      display: 'block',
      boxSizing: 'border-box',
      fontSize: 14,
      lineHeight: '32px',
      color: `${colorVariables.navy} !important`,
      '&:focus': {
        backgroundColor: colorVariables.transparent,
      },
    },
    customSelectFormControl: {
      textAlign: 'right',
      maxWidth: 200,
      color: `${colorVariables.navy} !important`,
      '& .MuiSvgIcon-root.MuiSelect-icon': {
        fill: colorVariables.grey60,
      },
    },
    customPlaceholder: {
      color: colorVariables.grey80,
      fontSize: 14,
      lineHeight: '32px',
    },
    acceptedBtnStyle: {
      backgroundColor: colorVariables.greenLight,
      color: colorVariables.green,
      '&:hover': {
        backgroundColor: colorVariables.greenLight,
      },
    },
    menuItem: {
      minHeight: 36,
      fontSize: 14,
    },
    fullWidth: {
      flex: 1,
    },
    tooltipWrap: {
      position: 'absolute',
      right: '-20%',
      bottom: 0,
      width: 300,
      [theme.breakpoints.down(Sizes.tablet)]: {
        bottom: 20,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 240,
        left: 30,
        bottom: 0,
        right: 'auto',
      },
    },
    arrow: {
      color: 'rgba(70, 82, 116, 1)',
      fontSize: 32,
    },
    tooltipText: {
      fontSize: 14,
      color: colorVariables.white,
      paddingTop: 5,
      marginBottom: 20,
      lineHeight: '22px',
      fontWeight: 400,
    },
    rootTooltip: {
      backgroundColor: colorVariables.transparent,
      padding: 0,
    },
    closeBtn: {
      width: 15,
      height: 15,
      position: 'absolute',
      top: 5,
      right: -40,
      '& svg': {
        color: colorVariables.white,
        fontSize: 16,
      },
    },
    tooltip: {
      width: 350,
      minHeight: 120,
      backgroundColor: 'rgba(52, 54, 90, 0.9)',
      boxShadow: '0 0 0 1px rgba(70, 82, 116, 1)',
      borderRadius: 10,
      padding: 20,
    },
    redBtn: {
      backgroundColor: '#EA6363',
      '&:hover': {
        backgroundColor: '#EA6363',
      },
    },
    tooltipTitle: {
      color: '#EA6363',
      fontWeight: 600,
      fontSize: 16,
      marginBottom: 7,
    },
    verificationText: {
      textAlign: 'center',
      position: 'absolute',
      color: '#EA6363',
      bottom: 10,
      right: 10,
      width: 230,
      [theme.breakpoints.down(Sizes.tablet)]: {
        bottom: 3,
        right: 5,
        width: 180,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 210,
        position: 'static',
        fontSize: 12,
      },
    },
  }),
);
