import { createStyles, makeStyles } from '@material-ui/core';
import { Sizes } from '../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    formContainer: {
      paddingTop: 20,
      width: 600,
      [theme.breakpoints.down(Sizes.tablet)]: {
        width: 500,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        maxWidth: '100%',
      },
    },
    fieldWrap: {
      marginBottom: 20,
    },
  }),
);
