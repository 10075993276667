import { createStyles, makeStyles } from '@material-ui/core';
import { colorVariables } from '../../../styles/colorVariables';
import { Sizes } from '../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    modalRoot: {
      padding: 40,
      paddingBottom: 25,
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: 25,
      },
    },
    title: {
      fontSize: 18,
      fontWeight: 700,
    },
    contentWrap: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 25,
      [theme.breakpoints.down(Sizes.mobile)]: {
        flexDirection: 'column',
      },
    },
    doneIcon: {
      width: 34,
      height: 34,
      borderRadius: '50%',
      backgroundColor: colorVariables.greenLight,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
      marginRight: 20,
      '& svg': {
        color: colorVariables.green,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginBottom: 15,
        marginRight: 0,
        marginTop: 15,
      },
    },
    successInfo: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: 350,
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        minWidth: 250,
      },
    },
  }),
);
