import { createStyles, makeStyles } from '@material-ui/core';
import { colorVariables } from '../../../../../../styles/colorVariables';
import { Sizes } from '../../../../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    row: {
      width: '100%',
      display: 'flex',
      height: 24,
      alignItems: 'center',
      backgroundColor: 'rgba(152, 165, 183, 0.075)',
      marginTop: 5,
      marginBottom: 9,
      cursor: 'pointer',
      [theme.breakpoints.down(Sizes.mobile)]: {
        justifyContent: 'space-between',
        backgroundColor: colorVariables.white,
      },
    },
    expandedRow: {
      backgroundColor: 'rgba(207, 241, 255, 0.5)',
      marginBottom: 4,
      [theme.breakpoints.down(Sizes.mobile)]: {
        backgroundColor: colorVariables.white,
      },
    },
    errorRow: {
      backgroundColor: 'rgba(252, 217, 217, 0.5)',
      '& *': {
        color: `#EA6363 !important`,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        backgroundColor: colorVariables.white,
      },
    },

    idCol: {
      width: '10%',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    unitCol: {
      width: '12%',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    actionCol: {
      width: '15%',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 150,
      },
    },
    actionHead: {
      color: colorVariables.grey60,
      textTransform: 'uppercase',
      fontSize: 11,
      fontWeight: 700,
    },
    nameCol: {
      width: '20%',
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: 60,
        paddingRight: 30,
        textAlign: 'center',
        position: 'relative',
      },
    },
    nameHead: {
      color: colorVariables.grey60,
      fontSize: 12,
      fontWeight: 400,
    },
    msgCol: {
      width: '38%',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    rowCol: {
      width: '5%',
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    chevronRight: {
      fontSize: 14,
      marginLeft: 4,
      marginTop: 3,
      color: colorVariables.grey60,
      transform: 'rotate(0deg)',
      transition: 'transform ease-in-out 0.5s',
      [theme.breakpoints.down(Sizes.mobile)]: {
        position: 'absolute',
        right: 0,
        bottom: 1,
      },
    },
    chevronDown: {
      transform: 'rotate(90deg)',
      color: colorVariables.blue,
    },
    lastRow: {
      textAlign: 'center',
      color: colorVariables.grey60,
    },
  }),
);
