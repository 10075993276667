import * as React from 'react';

function SvgHelp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M9.694 13.407c-.572 0-1.035.476-1.035 1.048 0 .558.45 1.049 1.035 1.049s1.048-.49 1.048-1.049c0-.572-.476-1.048-1.048-1.048zM9.87 5.469c-1.837 0-2.682 1.089-2.682 1.824 0 .531.45.776.817.776.736 0 .436-1.048 1.825-1.048.68 0 1.225.3 1.225.926 0 .735-.762 1.157-1.211 1.538-.395.34-.913.9-.913 2.07 0 .708.19.912.75.912.666 0 .803-.3.803-.558 0-.708.013-1.116.762-1.702.368-.286 1.525-1.212 1.525-2.492s-1.157-2.246-2.9-2.246z'
        fill='currentColor'
      />
      <path
        d='M10 0C4.473 0 0 4.473 0 10v9.219c0 .431.35.781.781.781H10c5.527 0 10-4.473 10-10S15.527 0 10 0zm0 18.438H1.562V10A8.433 8.433 0 0110 1.562 8.433 8.433 0 0118.438 10 8.433 8.433 0 0110 18.438z'
        fill='currentColor'
      />
    </svg>
  );
}

export default SvgHelp;
